// THIS FILE IS GENERATED, DO NOT EDIT!
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _int8: string;
  _text: string[];
  bigint: string;
  bpchar: any;
  date: string;
  daterange: string;
  inet: any;
  json: JsonObject;
  jsonb: JsonObject;
  name: any;
  numeric: string;
  oid: any;
  products_scalar: any;
  smallint: number;
  time: any;
  timestamp: string;
  timestamptz: string;
  uuid: string;
  xid: any;
  xml: any;
};

export type ActivateUserOutput = {
  __typename: 'ActivateUserOutput';
  success: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CalculateDismantlingPricingCpoOutput = {
  __typename: 'CalculateDismantlingPricingCPOOutput';
  costPricePerKg: Scalars['String'];
  cuttingPatternOutput?: Maybe<Cutting_Pattern_Outputs>;
  id: Scalars['bigint'];
  materialCostPricePerKg: Scalars['String'];
  valuationPriceFactor: Scalars['Float'];
  weightFactor: Scalars['Float'];
};

export type CalculateDismantlingPricingInput = {
  animalProductGroupId: Scalars['Int'];
  valid: Scalars['String'];
};

export type CalculateDismantlingPricingOutput = {
  __typename: 'CalculateDismantlingPricingOutput';
  cuttingPatternOutputs?: Maybe<Array<Maybe<CalculateDismantlingPricingCpoOutput>>>;
  temporalProducts?: Maybe<Array<Maybe<CalculateDismantlingPricingTpOutput>>>;
};

export type CalculateDismantlingPricingTpOutput = {
  __typename: 'CalculateDismantlingPricingTPOutput';
  costPricePerKg: Scalars['String'];
  id: Scalars['bigint'];
  temporalProduct?: Maybe<Temporal_Products>;
};

export type CreateUserOutput = {
  __typename: 'CreateUserOutput';
  user?: Maybe<Users>;
  userId: Scalars['String'];
};

export type DeactivateUserOutput = {
  __typename: 'DeactivateUserOutput';
  success: Scalars['Boolean'];
};

export type DeleteUserOutput = {
  __typename: 'DeleteUserOutput';
  success: Scalars['Boolean'];
};

export type DismantlingPlanPrepareOutput = {
  __typename: 'DismantlingPlanPrepareOutput';
  dismantlingPlan?: Maybe<Dismantling_Plans>;
  dismantlingPlanId: Scalars['String'];
};

export type FinishBizerbaPickingFlowInput = {
  customerOrderItemId: Scalars['String'];
  deviceId: Scalars['String'];
  lotNo?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  pickingLocationId: Scalars['String'];
  templateId: Scalars['String'];
};

export type FinishBizerbaPickingFlowOutput = {
  __typename: 'FinishBizerbaPickingFlowOutput';
  createdPickedItem?: Maybe<Picked_Items>;
  createdPickedItemId: Scalars['String'];
};

export type FinishLeichMehlPickingFlowInput = {
  customerOrderItemId: Scalars['String'];
  deviceId: Scalars['String'];
  lotNo?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  pickingLocationId: Scalars['String'];
  templateId: Scalars['String'];
};

export type FinishLeichMehlPickingFlowOutput = {
  __typename: 'FinishLeichMehlPickingFlowOutput';
  createdPickedItem?: Maybe<Picked_Items>;
  createdPickedItemId: Scalars['String'];
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Float']>;
  _gt?: InputMaybe<Scalars['Float']>;
  _gte?: InputMaybe<Scalars['Float']>;
  _in?: InputMaybe<Array<Scalars['Float']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Float']>;
  _lte?: InputMaybe<Scalars['Float']>;
  _neq?: InputMaybe<Scalars['Float']>;
  _nin?: InputMaybe<Array<Scalars['Float']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type PrintJscriptInput = {
  deviceId: Scalars['String'];
  payload: Scalars['json'];
  templateId: Scalars['String'];
};

export type PrintJscriptMultipleInput = {
  deviceId: Scalars['String'];
  payloads: Array<Scalars['json']>;
  templateId: Scalars['String'];
};

export type PrintJscriptOutput = {
  __typename: 'PrintJscriptOutput';
  success: Scalars['Boolean'];
};

export type PrintJscriptWithTemplateInput = {
  deviceId: Scalars['String'];
  payload: Scalars['json'];
  template: Scalars['String'];
};

export type PrintPayloadInput = {
  payload: Scalars['json'];
};

export type ResumeBizerbaPickingFlowInput = {
  customerOrderItemId: Scalars['String'];
  deviceId: Scalars['String'];
  pickingLocationId: Scalars['String'];
  templateId: Scalars['String'];
};

export type ResumeBizerbaPickingFlowOutput = {
  __typename: 'ResumeBizerbaPickingFlowOutput';
  success: Scalars['Boolean'];
};

export type ResumeLeichMehlPickingFlowInput = {
  customerOrderItemId: Scalars['String'];
  deviceId: Scalars['String'];
  pickingLocationId: Scalars['String'];
  templateId: Scalars['String'];
};

export type ResumeLeichMehlPickingFlowOutput = {
  __typename: 'ResumeLeichMehlPickingFlowOutput';
  success: Scalars['Boolean'];
};

export type SetupBizerbaPickingFlowInput = {
  customerOrderItemId: Scalars['String'];
  deviceId: Scalars['String'];
  pickingLocationId: Scalars['String'];
  templateId: Scalars['String'];
};

export type SetupBizerbaPickingFlowOutput = {
  __typename: 'SetupBizerbaPickingFlowOutput';
  success: Scalars['Boolean'];
};

export type SetupLeichMehlPickingFlowInput = {
  customerOrderItemId: Scalars['String'];
  deviceId: Scalars['String'];
  pickingLocationId: Scalars['String'];
  templateId: Scalars['String'];
};

export type SetupLeichMehlPickingFlowOutput = {
  __typename: 'SetupLeichMehlPickingFlowOutput';
  success: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type UpdateUserPasswordOutput = {
  __typename: 'UpdateUserPasswordOutput';
  success: Scalars['Boolean'];
};

export type UpdateUserRolesOutput = {
  __typename: 'UpdateUserRolesOutput';
  success: Scalars['Boolean'];
};

export type UserRolesOutput = {
  __typename: 'UserRolesOutput';
  roles: Array<Scalars['String']>;
};

/** Boolean expression to compare columns of type "_int8". All fields are combined with logical 'AND'. */
export type _Int8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['_int8']>;
  _gt?: InputMaybe<Scalars['_int8']>;
  _gte?: InputMaybe<Scalars['_int8']>;
  _in?: InputMaybe<Array<Scalars['_int8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['_int8']>;
  _lte?: InputMaybe<Scalars['_int8']>;
  _neq?: InputMaybe<Scalars['_int8']>;
  _nin?: InputMaybe<Array<Scalars['_int8']>>;
};

/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _Text_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['_text']>;
  _gt?: InputMaybe<Scalars['_text']>;
  _gte?: InputMaybe<Scalars['_text']>;
  _in?: InputMaybe<Array<Scalars['_text']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['_text']>;
  _lte?: InputMaybe<Scalars['_text']>;
  _neq?: InputMaybe<Scalars['_text']>;
  _nin?: InputMaybe<Array<Scalars['_text']>>;
};

/** columns and relationships of "abacus_exports" */
export type Abacus_Exports = {
  __typename: 'abacus_exports';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  domainId: Scalars['String'];
  /** An object relationship */
  exportS3Object?: Maybe<S3_Objects>;
  exportS3ObjectId?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  tenant: Scalars['String'];
};

/** aggregated selection of "abacus_exports" */
export type Abacus_Exports_Aggregate = {
  __typename: 'abacus_exports_aggregate';
  aggregate?: Maybe<Abacus_Exports_Aggregate_Fields>;
  nodes: Array<Abacus_Exports>;
};

export type Abacus_Exports_Aggregate_Bool_Exp = {
  count?: InputMaybe<Abacus_Exports_Aggregate_Bool_Exp_Count>;
};

export type Abacus_Exports_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Abacus_Exports_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Abacus_Exports_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "abacus_exports" */
export type Abacus_Exports_Aggregate_Fields = {
  __typename: 'abacus_exports_aggregate_fields';
  avg?: Maybe<Abacus_Exports_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Abacus_Exports_Max_Fields>;
  min?: Maybe<Abacus_Exports_Min_Fields>;
  stddev?: Maybe<Abacus_Exports_Stddev_Fields>;
  stddev_pop?: Maybe<Abacus_Exports_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Abacus_Exports_Stddev_Samp_Fields>;
  sum?: Maybe<Abacus_Exports_Sum_Fields>;
  var_pop?: Maybe<Abacus_Exports_Var_Pop_Fields>;
  var_samp?: Maybe<Abacus_Exports_Var_Samp_Fields>;
  variance?: Maybe<Abacus_Exports_Variance_Fields>;
};


/** aggregate fields of "abacus_exports" */
export type Abacus_Exports_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Abacus_Exports_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "abacus_exports" */
export type Abacus_Exports_Aggregate_Order_By = {
  avg?: InputMaybe<Abacus_Exports_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Abacus_Exports_Max_Order_By>;
  min?: InputMaybe<Abacus_Exports_Min_Order_By>;
  stddev?: InputMaybe<Abacus_Exports_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Abacus_Exports_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Abacus_Exports_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Abacus_Exports_Sum_Order_By>;
  var_pop?: InputMaybe<Abacus_Exports_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Abacus_Exports_Var_Samp_Order_By>;
  variance?: InputMaybe<Abacus_Exports_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Abacus_Exports_Avg_Fields = {
  __typename: 'abacus_exports_avg_fields';
  exportS3ObjectId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "abacus_exports" */
export type Abacus_Exports_Avg_Order_By = {
  exportS3ObjectId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "abacus_exports". All fields are combined with a logical 'AND'. */
export type Abacus_Exports_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Abacus_Exports_Bool_Exp>>;
  _not?: InputMaybe<Abacus_Exports_Bool_Exp>;
  _or?: InputMaybe<Array<Abacus_Exports_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  domainId?: InputMaybe<String_Comparison_Exp>;
  exportS3Object?: InputMaybe<S3_Objects_Bool_Exp>;
  exportS3ObjectId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Abacus_Exports_Max_Fields = {
  __typename: 'abacus_exports_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  domainId?: Maybe<Scalars['String']>;
  exportS3ObjectId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "abacus_exports" */
export type Abacus_Exports_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  exportS3ObjectId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Abacus_Exports_Min_Fields = {
  __typename: 'abacus_exports_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  domainId?: Maybe<Scalars['String']>;
  exportS3ObjectId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "abacus_exports" */
export type Abacus_Exports_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  exportS3ObjectId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "abacus_exports". */
export type Abacus_Exports_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  exportS3Object?: InputMaybe<S3_Objects_Order_By>;
  exportS3ObjectId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** select columns of table "abacus_exports" */
export enum Abacus_Exports_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DomainId = 'domainId',
  /** column name */
  ExportS3ObjectId = 'exportS3ObjectId',
  /** column name */
  Id = 'id',
  /** column name */
  Tenant = 'tenant'
}

/** aggregate stddev on columns */
export type Abacus_Exports_Stddev_Fields = {
  __typename: 'abacus_exports_stddev_fields';
  exportS3ObjectId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "abacus_exports" */
export type Abacus_Exports_Stddev_Order_By = {
  exportS3ObjectId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Abacus_Exports_Stddev_Pop_Fields = {
  __typename: 'abacus_exports_stddev_pop_fields';
  exportS3ObjectId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "abacus_exports" */
export type Abacus_Exports_Stddev_Pop_Order_By = {
  exportS3ObjectId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Abacus_Exports_Stddev_Samp_Fields = {
  __typename: 'abacus_exports_stddev_samp_fields';
  exportS3ObjectId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "abacus_exports" */
export type Abacus_Exports_Stddev_Samp_Order_By = {
  exportS3ObjectId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "abacus_exports" */
export type Abacus_Exports_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Abacus_Exports_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Abacus_Exports_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  domainId?: InputMaybe<Scalars['String']>;
  exportS3ObjectId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Abacus_Exports_Sum_Fields = {
  __typename: 'abacus_exports_sum_fields';
  exportS3ObjectId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "abacus_exports" */
export type Abacus_Exports_Sum_Order_By = {
  exportS3ObjectId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Abacus_Exports_Var_Pop_Fields = {
  __typename: 'abacus_exports_var_pop_fields';
  exportS3ObjectId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "abacus_exports" */
export type Abacus_Exports_Var_Pop_Order_By = {
  exportS3ObjectId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Abacus_Exports_Var_Samp_Fields = {
  __typename: 'abacus_exports_var_samp_fields';
  exportS3ObjectId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "abacus_exports" */
export type Abacus_Exports_Var_Samp_Order_By = {
  exportS3ObjectId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Abacus_Exports_Variance_Fields = {
  __typename: 'abacus_exports_variance_fields';
  exportS3ObjectId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "abacus_exports" */
export type Abacus_Exports_Variance_Order_By = {
  exportS3ObjectId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Abacus_Xml_Export_Args = {
  _credit_note_ids?: InputMaybe<Scalars['json']>;
  _customer_invoice_ids?: InputMaybe<Scalars['json']>;
  _expiration_in_sec?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "accounts" */
export type Accounts = {
  __typename: 'accounts';
  /** An object relationship */
  Tenant: Tenants;
  /** An object relationship */
  customer?: Maybe<Customers>;
  customerId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  dismantlingPlan?: Maybe<Dismantling_Plans>;
  dismantlingPlanId?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  /** An array relationship */
  inventoryItems: Array<Inventory_Items>;
  /** An aggregate relationship */
  inventoryItems_aggregate: Inventory_Items_Aggregate;
  /** An object relationship */
  location?: Maybe<Stock_Locations>;
  locationId?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  /** An array relationship */
  partialDeliveries: Array<Partial_Deliveries>;
  /** An aggregate relationship */
  partialDeliveries_aggregate: Partial_Deliveries_Aggregate;
  /** An array relationship */
  postings: Array<Postings>;
  /** An aggregate relationship */
  postings_aggregate: Postings_Aggregate;
  /** An array relationship */
  stockTransactionsViaSourceAccount: Array<Stock_Transactions>;
  /** An aggregate relationship */
  stockTransactionsViaSourceAccount_aggregate: Stock_Transactions_Aggregate;
  /** An array relationship */
  stockTransactionsViaTargetAccount: Array<Stock_Transactions>;
  /** An aggregate relationship */
  stockTransactionsViaTargetAccount_aggregate: Stock_Transactions_Aggregate;
  /** An object relationship */
  supplier?: Maybe<Suppliers>;
  supplierId?: Maybe<Scalars['bigint']>;
  tenant: Scalars['String'];
};


/** columns and relationships of "accounts" */
export type AccountsInventoryItemsArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsInventoryItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsPartialDeliveriesArgs = {
  distinct_on?: InputMaybe<Array<Partial_Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Deliveries_Order_By>>;
  where?: InputMaybe<Partial_Deliveries_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsPartialDeliveries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partial_Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Deliveries_Order_By>>;
  where?: InputMaybe<Partial_Deliveries_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsPostingsArgs = {
  distinct_on?: InputMaybe<Array<Postings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postings_Order_By>>;
  where?: InputMaybe<Postings_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsPostings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Postings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postings_Order_By>>;
  where?: InputMaybe<Postings_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsStockTransactionsViaSourceAccountArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsStockTransactionsViaSourceAccount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsStockTransactionsViaTargetAccountArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsStockTransactionsViaTargetAccount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};

/** aggregated selection of "accounts" */
export type Accounts_Aggregate = {
  __typename: 'accounts_aggregate';
  aggregate?: Maybe<Accounts_Aggregate_Fields>;
  nodes: Array<Accounts>;
};

export type Accounts_Aggregate_Bool_Exp = {
  count?: InputMaybe<Accounts_Aggregate_Bool_Exp_Count>;
};

export type Accounts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Accounts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "accounts" */
export type Accounts_Aggregate_Fields = {
  __typename: 'accounts_aggregate_fields';
  avg?: Maybe<Accounts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Accounts_Max_Fields>;
  min?: Maybe<Accounts_Min_Fields>;
  stddev?: Maybe<Accounts_Stddev_Fields>;
  stddev_pop?: Maybe<Accounts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Accounts_Stddev_Samp_Fields>;
  sum?: Maybe<Accounts_Sum_Fields>;
  var_pop?: Maybe<Accounts_Var_Pop_Fields>;
  var_samp?: Maybe<Accounts_Var_Samp_Fields>;
  variance?: Maybe<Accounts_Variance_Fields>;
};


/** aggregate fields of "accounts" */
export type Accounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "accounts" */
export type Accounts_Aggregate_Order_By = {
  avg?: InputMaybe<Accounts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Accounts_Max_Order_By>;
  min?: InputMaybe<Accounts_Min_Order_By>;
  stddev?: InputMaybe<Accounts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Accounts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Accounts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Accounts_Sum_Order_By>;
  var_pop?: InputMaybe<Accounts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Accounts_Var_Samp_Order_By>;
  variance?: InputMaybe<Accounts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "accounts" */
export type Accounts_Arr_Rel_Insert_Input = {
  data: Array<Accounts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};

/** aggregate avg on columns */
export type Accounts_Avg_Fields = {
  __typename: 'accounts_avg_fields';
  customerId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "accounts" */
export type Accounts_Avg_Order_By = {
  customerId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "accounts". All fields are combined with a logical 'AND'. */
export type Accounts_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Accounts_Bool_Exp>>;
  _not?: InputMaybe<Accounts_Bool_Exp>;
  _or?: InputMaybe<Array<Accounts_Bool_Exp>>;
  customer?: InputMaybe<Customers_Bool_Exp>;
  customerId?: InputMaybe<Bigint_Comparison_Exp>;
  dismantlingPlan?: InputMaybe<Dismantling_Plans_Bool_Exp>;
  dismantlingPlanId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  inventoryItems?: InputMaybe<Inventory_Items_Bool_Exp>;
  inventoryItems_aggregate?: InputMaybe<Inventory_Items_Aggregate_Bool_Exp>;
  location?: InputMaybe<Stock_Locations_Bool_Exp>;
  locationId?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  partialDeliveries?: InputMaybe<Partial_Deliveries_Bool_Exp>;
  partialDeliveries_aggregate?: InputMaybe<Partial_Deliveries_Aggregate_Bool_Exp>;
  postings?: InputMaybe<Postings_Bool_Exp>;
  postings_aggregate?: InputMaybe<Postings_Aggregate_Bool_Exp>;
  stockTransactionsViaSourceAccount?: InputMaybe<Stock_Transactions_Bool_Exp>;
  stockTransactionsViaSourceAccount_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Bool_Exp>;
  stockTransactionsViaTargetAccount?: InputMaybe<Stock_Transactions_Bool_Exp>;
  stockTransactionsViaTargetAccount_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Bool_Exp>;
  supplier?: InputMaybe<Suppliers_Bool_Exp>;
  supplierId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "accounts" */
export enum Accounts_Constraint {
  /** unique or primary key constraint on columns "tenant", "location_id", "dismantling_plan_id" */
  AccountsOneDismantlingPlanPerStockLocation = 'accounts_one_dismantling_plan_per_stock_location',
  /** unique or primary key constraint on columns "id" */
  AccountsPkey = 'accounts_pkey',
  /** unique or primary key constraint on columns "name", "tenant" */
  AccountsTenantSingletonAccountIdx = 'accounts_tenant_singleton_account_idx',
  /** unique or primary key constraint on columns "name", "tenant" */
  AccountsUniqueCustomerSupplierLocation = 'accounts_unique_customer_supplier_location'
}

/** input type for incrementing numeric columns in table "accounts" */
export type Accounts_Inc_Input = {
  customerId?: InputMaybe<Scalars['bigint']>;
  dismantlingPlanId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  locationId?: InputMaybe<Scalars['bigint']>;
  supplierId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "accounts" */
export type Accounts_Insert_Input = {
  customer?: InputMaybe<Customers_Obj_Rel_Insert_Input>;
  customerId?: InputMaybe<Scalars['bigint']>;
  dismantlingPlan?: InputMaybe<Dismantling_Plans_Obj_Rel_Insert_Input>;
  dismantlingPlanId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  inventoryItems?: InputMaybe<Inventory_Items_Arr_Rel_Insert_Input>;
  location?: InputMaybe<Stock_Locations_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  partialDeliveries?: InputMaybe<Partial_Deliveries_Arr_Rel_Insert_Input>;
  stockTransactionsViaSourceAccount?: InputMaybe<Stock_Transactions_Arr_Rel_Insert_Input>;
  stockTransactionsViaTargetAccount?: InputMaybe<Stock_Transactions_Arr_Rel_Insert_Input>;
  supplier?: InputMaybe<Suppliers_Obj_Rel_Insert_Input>;
  supplierId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Accounts_Max_Fields = {
  __typename: 'accounts_max_fields';
  customerId?: Maybe<Scalars['bigint']>;
  dismantlingPlanId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  locationId?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "accounts" */
export type Accounts_Max_Order_By = {
  customerId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Accounts_Min_Fields = {
  __typename: 'accounts_min_fields';
  customerId?: Maybe<Scalars['bigint']>;
  dismantlingPlanId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  locationId?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "accounts" */
export type Accounts_Min_Order_By = {
  customerId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "accounts" */
export type Accounts_Mutation_Response = {
  __typename: 'accounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Accounts>;
};

/** input type for inserting object relation for remote table "accounts" */
export type Accounts_Obj_Rel_Insert_Input = {
  data: Accounts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};

/** on_conflict condition type for table "accounts" */
export type Accounts_On_Conflict = {
  constraint: Accounts_Constraint;
  update_columns?: Array<Accounts_Update_Column>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "accounts". */
export type Accounts_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  customer?: InputMaybe<Customers_Order_By>;
  customerId?: InputMaybe<Order_By>;
  dismantlingPlan?: InputMaybe<Dismantling_Plans_Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inventoryItems_aggregate?: InputMaybe<Inventory_Items_Aggregate_Order_By>;
  location?: InputMaybe<Stock_Locations_Order_By>;
  locationId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  partialDeliveries_aggregate?: InputMaybe<Partial_Deliveries_Aggregate_Order_By>;
  postings_aggregate?: InputMaybe<Postings_Aggregate_Order_By>;
  stockTransactionsViaSourceAccount_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Order_By>;
  stockTransactionsViaTargetAccount_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Order_By>;
  supplier?: InputMaybe<Suppliers_Order_By>;
  supplierId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** primary key columns input for table: accounts */
export type Accounts_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "accounts" */
export enum Accounts_Select_Column {
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  DismantlingPlanId = 'dismantlingPlanId',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Name = 'name',
  /** column name */
  SupplierId = 'supplierId',
  /** column name */
  Tenant = 'tenant'
}

/** input type for updating data in table "accounts" */
export type Accounts_Set_Input = {
  customerId?: InputMaybe<Scalars['bigint']>;
  dismantlingPlanId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  locationId?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  supplierId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Accounts_Stddev_Fields = {
  __typename: 'accounts_stddev_fields';
  customerId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "accounts" */
export type Accounts_Stddev_Order_By = {
  customerId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Accounts_Stddev_Pop_Fields = {
  __typename: 'accounts_stddev_pop_fields';
  customerId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "accounts" */
export type Accounts_Stddev_Pop_Order_By = {
  customerId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Accounts_Stddev_Samp_Fields = {
  __typename: 'accounts_stddev_samp_fields';
  customerId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "accounts" */
export type Accounts_Stddev_Samp_Order_By = {
  customerId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "accounts" */
export type Accounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Accounts_Stream_Cursor_Value_Input = {
  customerId?: InputMaybe<Scalars['bigint']>;
  dismantlingPlanId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  locationId?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  supplierId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Accounts_Sum_Fields = {
  __typename: 'accounts_sum_fields';
  customerId?: Maybe<Scalars['bigint']>;
  dismantlingPlanId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  locationId?: Maybe<Scalars['bigint']>;
  supplierId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "accounts" */
export type Accounts_Sum_Order_By = {
  customerId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** update columns of table "accounts" */
export enum Accounts_Update_Column {
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  DismantlingPlanId = 'dismantlingPlanId',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Name = 'name',
  /** column name */
  SupplierId = 'supplierId'
}

export type Accounts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Accounts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Accounts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Accounts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Accounts_Var_Pop_Fields = {
  __typename: 'accounts_var_pop_fields';
  customerId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "accounts" */
export type Accounts_Var_Pop_Order_By = {
  customerId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Accounts_Var_Samp_Fields = {
  __typename: 'accounts_var_samp_fields';
  customerId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "accounts" */
export type Accounts_Var_Samp_Order_By = {
  customerId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Accounts_Variance_Fields = {
  __typename: 'accounts_variance_fields';
  customerId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "accounts" */
export type Accounts_Variance_Order_By = {
  customerId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

export type Activate_Price_List_Args = {
  _price_list_id?: InputMaybe<Scalars['bigint']>;
};

export type Add_Products_To_Supplier_Order_Args = {
  product_inputs?: InputMaybe<Scalars['json']>;
};

export type Aggregated_Demand_Plan_Args = {
  _config?: InputMaybe<Scalars['jsonb']>;
  _daterange?: InputMaybe<Scalars['daterange']>;
  _monetary_unit_id?: InputMaybe<Scalars['bigint']>;
  _product_id?: InputMaybe<Scalars['bigint']>;
  _stock_location_id?: InputMaybe<Scalars['bigint']>;
  _storage_unit_id?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "aggregated_demand_plan_incoming_goods_results" */
export type Aggregated_Demand_Plan_Incoming_Goods_Results = {
  __typename: 'aggregated_demand_plan_incoming_goods_results';
  day: Scalars['date'];
  /** An object relationship */
  supplier: Suppliers;
  supplierId: Scalars['bigint'];
  /** An object relationship */
  supplierOrder: Supplier_Orders;
  supplierOrderId: Scalars['bigint'];
  /** An object relationship */
  supplierOrderItem: Supplier_Order_Items;
  supplierOrderItemId: Scalars['bigint'];
};

/** aggregated selection of "aggregated_demand_plan_incoming_goods_results" */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Aggregate = {
  __typename: 'aggregated_demand_plan_incoming_goods_results_aggregate';
  aggregate?: Maybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Aggregate_Fields>;
  nodes: Array<Aggregated_Demand_Plan_Incoming_Goods_Results>;
};

export type Aggregated_Demand_Plan_Incoming_Goods_Results_Aggregate_Bool_Exp = {
  count?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Aggregate_Bool_Exp_Count>;
};

export type Aggregated_Demand_Plan_Incoming_Goods_Results_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "aggregated_demand_plan_incoming_goods_results" */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Aggregate_Fields = {
  __typename: 'aggregated_demand_plan_incoming_goods_results_aggregate_fields';
  avg?: Maybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Max_Fields>;
  min?: Maybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Min_Fields>;
  stddev?: Maybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Stddev_Fields>;
  stddev_pop?: Maybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Stddev_Samp_Fields>;
  sum?: Maybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Sum_Fields>;
  var_pop?: Maybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Var_Pop_Fields>;
  var_samp?: Maybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Var_Samp_Fields>;
  variance?: Maybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Variance_Fields>;
};


/** aggregate fields of "aggregated_demand_plan_incoming_goods_results" */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "aggregated_demand_plan_incoming_goods_results" */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Aggregate_Order_By = {
  avg?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Max_Order_By>;
  min?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Min_Order_By>;
  stddev?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Sum_Order_By>;
  var_pop?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Var_Samp_Order_By>;
  variance?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Avg_Fields = {
  __typename: 'aggregated_demand_plan_incoming_goods_results_avg_fields';
  supplierId?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "aggregated_demand_plan_incoming_goods_results" */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Avg_Order_By = {
  supplierId?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "aggregated_demand_plan_incoming_goods_results". All fields are combined with a logical 'AND'. */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp = {
  _and?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp>>;
  _not?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp>;
  _or?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp>>;
  day?: InputMaybe<Date_Comparison_Exp>;
  supplier?: InputMaybe<Suppliers_Bool_Exp>;
  supplierId?: InputMaybe<Bigint_Comparison_Exp>;
  supplierOrder?: InputMaybe<Supplier_Orders_Bool_Exp>;
  supplierOrderId?: InputMaybe<Bigint_Comparison_Exp>;
  supplierOrderItem?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
  supplierOrderItemId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Max_Fields = {
  __typename: 'aggregated_demand_plan_incoming_goods_results_max_fields';
  day?: Maybe<Scalars['date']>;
  supplierId?: Maybe<Scalars['bigint']>;
  supplierOrderId?: Maybe<Scalars['bigint']>;
  supplierOrderItemId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "aggregated_demand_plan_incoming_goods_results" */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Max_Order_By = {
  day?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Min_Fields = {
  __typename: 'aggregated_demand_plan_incoming_goods_results_min_fields';
  day?: Maybe<Scalars['date']>;
  supplierId?: Maybe<Scalars['bigint']>;
  supplierOrderId?: Maybe<Scalars['bigint']>;
  supplierOrderItemId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "aggregated_demand_plan_incoming_goods_results" */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Min_Order_By = {
  day?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "aggregated_demand_plan_incoming_goods_results". */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Order_By = {
  day?: InputMaybe<Order_By>;
  supplier?: InputMaybe<Suppliers_Order_By>;
  supplierId?: InputMaybe<Order_By>;
  supplierOrder?: InputMaybe<Supplier_Orders_Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItem?: InputMaybe<Supplier_Order_Items_Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
};

/** select columns of table "aggregated_demand_plan_incoming_goods_results" */
export enum Aggregated_Demand_Plan_Incoming_Goods_Results_Select_Column {
  /** column name */
  Day = 'day',
  /** column name */
  SupplierId = 'supplierId',
  /** column name */
  SupplierOrderId = 'supplierOrderId',
  /** column name */
  SupplierOrderItemId = 'supplierOrderItemId'
}

/** aggregate stddev on columns */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Stddev_Fields = {
  __typename: 'aggregated_demand_plan_incoming_goods_results_stddev_fields';
  supplierId?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "aggregated_demand_plan_incoming_goods_results" */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Stddev_Order_By = {
  supplierId?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Stddev_Pop_Fields = {
  __typename: 'aggregated_demand_plan_incoming_goods_results_stddev_pop_fields';
  supplierId?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "aggregated_demand_plan_incoming_goods_results" */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Stddev_Pop_Order_By = {
  supplierId?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Stddev_Samp_Fields = {
  __typename: 'aggregated_demand_plan_incoming_goods_results_stddev_samp_fields';
  supplierId?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "aggregated_demand_plan_incoming_goods_results" */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Stddev_Samp_Order_By = {
  supplierId?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "aggregated_demand_plan_incoming_goods_results" */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Aggregated_Demand_Plan_Incoming_Goods_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Stream_Cursor_Value_Input = {
  day?: InputMaybe<Scalars['date']>;
  supplierId?: InputMaybe<Scalars['bigint']>;
  supplierOrderId?: InputMaybe<Scalars['bigint']>;
  supplierOrderItemId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Sum_Fields = {
  __typename: 'aggregated_demand_plan_incoming_goods_results_sum_fields';
  supplierId?: Maybe<Scalars['bigint']>;
  supplierOrderId?: Maybe<Scalars['bigint']>;
  supplierOrderItemId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "aggregated_demand_plan_incoming_goods_results" */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Sum_Order_By = {
  supplierId?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Var_Pop_Fields = {
  __typename: 'aggregated_demand_plan_incoming_goods_results_var_pop_fields';
  supplierId?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "aggregated_demand_plan_incoming_goods_results" */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Var_Pop_Order_By = {
  supplierId?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Var_Samp_Fields = {
  __typename: 'aggregated_demand_plan_incoming_goods_results_var_samp_fields';
  supplierId?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "aggregated_demand_plan_incoming_goods_results" */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Var_Samp_Order_By = {
  supplierId?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Variance_Fields = {
  __typename: 'aggregated_demand_plan_incoming_goods_results_variance_fields';
  supplierId?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "aggregated_demand_plan_incoming_goods_results" */
export type Aggregated_Demand_Plan_Incoming_Goods_Results_Variance_Order_By = {
  supplierId?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
};

/** columns and relationships of "aggregated_demand_plan_outgoing_goods_results" */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results = {
  __typename: 'aggregated_demand_plan_outgoing_goods_results';
  /** An object relationship */
  customer: Customers;
  customerId: Scalars['bigint'];
  /** An object relationship */
  customerOrder: Customer_Orders;
  customerOrderId: Scalars['bigint'];
  /** An object relationship */
  customerOrderItem: Customer_Order_Items;
  customerOrderItemId: Scalars['bigint'];
  day: Scalars['date'];
  isBlacklisted: Scalars['Boolean'];
  isPicked: Scalars['Boolean'];
  isPromoted: Scalars['Boolean'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
};

/** aggregated selection of "aggregated_demand_plan_outgoing_goods_results" */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate = {
  __typename: 'aggregated_demand_plan_outgoing_goods_results_aggregate';
  aggregate?: Maybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Fields>;
  nodes: Array<Aggregated_Demand_Plan_Outgoing_Goods_Results>;
};

export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Bool_Exp_Count>;
};

export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Bool_Exp_Bool_And = {
  arguments: Aggregated_Demand_Plan_Outgoing_Goods_Results_Select_Column_Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Aggregated_Demand_Plan_Outgoing_Goods_Results_Select_Column_Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "aggregated_demand_plan_outgoing_goods_results" */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Fields = {
  __typename: 'aggregated_demand_plan_outgoing_goods_results_aggregate_fields';
  avg?: Maybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Max_Fields>;
  min?: Maybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Min_Fields>;
  stddev?: Maybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Stddev_Fields>;
  stddev_pop?: Maybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Stddev_Samp_Fields>;
  sum?: Maybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Sum_Fields>;
  var_pop?: Maybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Var_Pop_Fields>;
  var_samp?: Maybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Var_Samp_Fields>;
  variance?: Maybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Variance_Fields>;
};


/** aggregate fields of "aggregated_demand_plan_outgoing_goods_results" */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "aggregated_demand_plan_outgoing_goods_results" */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Order_By = {
  avg?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Max_Order_By>;
  min?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Min_Order_By>;
  stddev?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Sum_Order_By>;
  var_pop?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Var_Samp_Order_By>;
  variance?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Avg_Fields = {
  __typename: 'aggregated_demand_plan_outgoing_goods_results_avg_fields';
  customerId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "aggregated_demand_plan_outgoing_goods_results" */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Avg_Order_By = {
  customerId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "aggregated_demand_plan_outgoing_goods_results". All fields are combined with a logical 'AND'. */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp = {
  _and?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>>;
  _not?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
  _or?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>>;
  customer?: InputMaybe<Customers_Bool_Exp>;
  customerId?: InputMaybe<Bigint_Comparison_Exp>;
  customerOrder?: InputMaybe<Customer_Orders_Bool_Exp>;
  customerOrderId?: InputMaybe<Bigint_Comparison_Exp>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  customerOrderItemId?: InputMaybe<Bigint_Comparison_Exp>;
  day?: InputMaybe<Date_Comparison_Exp>;
  isBlacklisted?: InputMaybe<Boolean_Comparison_Exp>;
  isPicked?: InputMaybe<Boolean_Comparison_Exp>;
  isPromoted?: InputMaybe<Boolean_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Max_Fields = {
  __typename: 'aggregated_demand_plan_outgoing_goods_results_max_fields';
  customerId?: Maybe<Scalars['bigint']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  day?: Maybe<Scalars['date']>;
  productId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "aggregated_demand_plan_outgoing_goods_results" */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Max_Order_By = {
  customerId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Min_Fields = {
  __typename: 'aggregated_demand_plan_outgoing_goods_results_min_fields';
  customerId?: Maybe<Scalars['bigint']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  day?: Maybe<Scalars['date']>;
  productId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "aggregated_demand_plan_outgoing_goods_results" */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Min_Order_By = {
  customerId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "aggregated_demand_plan_outgoing_goods_results". */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Order_By = {
  customer?: InputMaybe<Customers_Order_By>;
  customerId?: InputMaybe<Order_By>;
  customerOrder?: InputMaybe<Customer_Orders_Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  isBlacklisted?: InputMaybe<Order_By>;
  isPicked?: InputMaybe<Order_By>;
  isPromoted?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** select columns of table "aggregated_demand_plan_outgoing_goods_results" */
export enum Aggregated_Demand_Plan_Outgoing_Goods_Results_Select_Column {
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  CustomerOrderId = 'customerOrderId',
  /** column name */
  CustomerOrderItemId = 'customerOrderItemId',
  /** column name */
  Day = 'day',
  /** column name */
  IsBlacklisted = 'isBlacklisted',
  /** column name */
  IsPicked = 'isPicked',
  /** column name */
  IsPromoted = 'isPromoted',
  /** column name */
  ProductId = 'productId'
}

/** select "aggregated_demand_plan_outgoing_goods_results_aggregate_bool_exp_bool_and_arguments_columns" columns of table "aggregated_demand_plan_outgoing_goods_results" */
export enum Aggregated_Demand_Plan_Outgoing_Goods_Results_Select_Column_Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsBlacklisted = 'isBlacklisted',
  /** column name */
  IsPicked = 'isPicked',
  /** column name */
  IsPromoted = 'isPromoted'
}

/** select "aggregated_demand_plan_outgoing_goods_results_aggregate_bool_exp_bool_or_arguments_columns" columns of table "aggregated_demand_plan_outgoing_goods_results" */
export enum Aggregated_Demand_Plan_Outgoing_Goods_Results_Select_Column_Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsBlacklisted = 'isBlacklisted',
  /** column name */
  IsPicked = 'isPicked',
  /** column name */
  IsPromoted = 'isPromoted'
}

/** aggregate stddev on columns */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Stddev_Fields = {
  __typename: 'aggregated_demand_plan_outgoing_goods_results_stddev_fields';
  customerId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "aggregated_demand_plan_outgoing_goods_results" */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Stddev_Order_By = {
  customerId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Stddev_Pop_Fields = {
  __typename: 'aggregated_demand_plan_outgoing_goods_results_stddev_pop_fields';
  customerId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "aggregated_demand_plan_outgoing_goods_results" */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Stddev_Pop_Order_By = {
  customerId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Stddev_Samp_Fields = {
  __typename: 'aggregated_demand_plan_outgoing_goods_results_stddev_samp_fields';
  customerId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "aggregated_demand_plan_outgoing_goods_results" */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Stddev_Samp_Order_By = {
  customerId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "aggregated_demand_plan_outgoing_goods_results" */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Aggregated_Demand_Plan_Outgoing_Goods_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Stream_Cursor_Value_Input = {
  customerId?: InputMaybe<Scalars['bigint']>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  day?: InputMaybe<Scalars['date']>;
  isBlacklisted?: InputMaybe<Scalars['Boolean']>;
  isPicked?: InputMaybe<Scalars['Boolean']>;
  isPromoted?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Sum_Fields = {
  __typename: 'aggregated_demand_plan_outgoing_goods_results_sum_fields';
  customerId?: Maybe<Scalars['bigint']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "aggregated_demand_plan_outgoing_goods_results" */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Sum_Order_By = {
  customerId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Var_Pop_Fields = {
  __typename: 'aggregated_demand_plan_outgoing_goods_results_var_pop_fields';
  customerId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "aggregated_demand_plan_outgoing_goods_results" */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Var_Pop_Order_By = {
  customerId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Var_Samp_Fields = {
  __typename: 'aggregated_demand_plan_outgoing_goods_results_var_samp_fields';
  customerId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "aggregated_demand_plan_outgoing_goods_results" */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Var_Samp_Order_By = {
  customerId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Variance_Fields = {
  __typename: 'aggregated_demand_plan_outgoing_goods_results_variance_fields';
  customerId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "aggregated_demand_plan_outgoing_goods_results" */
export type Aggregated_Demand_Plan_Outgoing_Goods_Results_Variance_Order_By = {
  customerId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** columns and relationships of "aggregated_demand_plan_results" */
export type Aggregated_Demand_Plan_Results = {
  __typename: 'aggregated_demand_plan_results';
  contents?: Maybe<Scalars['jsonb']>;
  expectedIncomingMonetaryQuantity?: Maybe<Scalars['bigint']>;
  expectedMonetaryQuantityUsage?: Maybe<Scalars['bigint']>;
  /** Returns a set of primary keys for walking the graph for the planned demand incoming goods. */
  incomingGoods?: Maybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results>>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  monetaryUnit: Product_Units;
  monetaryUnitId: Scalars['bigint'];
  notificationMonetaryQuantity?: Maybe<Scalars['bigint']>;
  /** Returns a set of primary keys for walking the graph for the planned demand outgoing goods. */
  outgoingGoods?: Maybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results>>;
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  purchasingSuggestion: Purchasing_Suggestions_Enum;
  recommendedPurchaseQuantity?: Maybe<Scalars['bigint']>;
  securityMonetaryQuantity?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  stockLocation: Stock_Locations;
  stockLocationId: Scalars['bigint'];
  storageQuantity?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  storageUnit: Product_Units;
  storageUnitId: Scalars['bigint'];
  /** An object relationship */
  supplier?: Maybe<Suppliers>;
  supplierId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "aggregated_demand_plan_results" */
export type Aggregated_Demand_Plan_ResultsContentsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "aggregated_demand_plan_results" */
export type Aggregated_Demand_Plan_ResultsIncomingGoodsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp>;
};


/** columns and relationships of "aggregated_demand_plan_results" */
export type Aggregated_Demand_Plan_ResultsOutgoingGoodsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
};

/** aggregated selection of "aggregated_demand_plan_results" */
export type Aggregated_Demand_Plan_Results_Aggregate = {
  __typename: 'aggregated_demand_plan_results_aggregate';
  aggregate?: Maybe<Aggregated_Demand_Plan_Results_Aggregate_Fields>;
  nodes: Array<Aggregated_Demand_Plan_Results>;
};

export type Aggregated_Demand_Plan_Results_Aggregate_Bool_Exp = {
  count?: InputMaybe<Aggregated_Demand_Plan_Results_Aggregate_Bool_Exp_Count>;
};

export type Aggregated_Demand_Plan_Results_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "aggregated_demand_plan_results" */
export type Aggregated_Demand_Plan_Results_Aggregate_Fields = {
  __typename: 'aggregated_demand_plan_results_aggregate_fields';
  avg?: Maybe<Aggregated_Demand_Plan_Results_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Aggregated_Demand_Plan_Results_Max_Fields>;
  min?: Maybe<Aggregated_Demand_Plan_Results_Min_Fields>;
  stddev?: Maybe<Aggregated_Demand_Plan_Results_Stddev_Fields>;
  stddev_pop?: Maybe<Aggregated_Demand_Plan_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Aggregated_Demand_Plan_Results_Stddev_Samp_Fields>;
  sum?: Maybe<Aggregated_Demand_Plan_Results_Sum_Fields>;
  var_pop?: Maybe<Aggregated_Demand_Plan_Results_Var_Pop_Fields>;
  var_samp?: Maybe<Aggregated_Demand_Plan_Results_Var_Samp_Fields>;
  variance?: Maybe<Aggregated_Demand_Plan_Results_Variance_Fields>;
};


/** aggregate fields of "aggregated_demand_plan_results" */
export type Aggregated_Demand_Plan_Results_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "aggregated_demand_plan_results" */
export type Aggregated_Demand_Plan_Results_Aggregate_Order_By = {
  avg?: InputMaybe<Aggregated_Demand_Plan_Results_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Aggregated_Demand_Plan_Results_Max_Order_By>;
  min?: InputMaybe<Aggregated_Demand_Plan_Results_Min_Order_By>;
  stddev?: InputMaybe<Aggregated_Demand_Plan_Results_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Aggregated_Demand_Plan_Results_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Aggregated_Demand_Plan_Results_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Aggregated_Demand_Plan_Results_Sum_Order_By>;
  var_pop?: InputMaybe<Aggregated_Demand_Plan_Results_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Aggregated_Demand_Plan_Results_Var_Samp_Order_By>;
  variance?: InputMaybe<Aggregated_Demand_Plan_Results_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Aggregated_Demand_Plan_Results_Avg_Fields = {
  __typename: 'aggregated_demand_plan_results_avg_fields';
  expectedIncomingMonetaryQuantity?: Maybe<Scalars['Float']>;
  expectedMonetaryQuantityUsage?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  notificationMonetaryQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  recommendedPurchaseQuantity?: Maybe<Scalars['Float']>;
  securityMonetaryQuantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "aggregated_demand_plan_results" */
export type Aggregated_Demand_Plan_Results_Avg_Order_By = {
  expectedIncomingMonetaryQuantity?: InputMaybe<Order_By>;
  expectedMonetaryQuantityUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  notificationMonetaryQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  recommendedPurchaseQuantity?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "aggregated_demand_plan_results". All fields are combined with a logical 'AND'. */
export type Aggregated_Demand_Plan_Results_Bool_Exp = {
  _and?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Bool_Exp>>;
  _not?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
  _or?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Bool_Exp>>;
  contents?: InputMaybe<Jsonb_Comparison_Exp>;
  expectedIncomingMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  expectedMonetaryQuantityUsage?: InputMaybe<Bigint_Comparison_Exp>;
  incomingGoods?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp>;
  monetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  monetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  monetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  notificationMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  outgoingGoods?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  purchasingSuggestion?: InputMaybe<Purchasing_Suggestions_Enum_Comparison_Exp>;
  recommendedPurchaseQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  securityMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  stockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  stockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  storageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  storageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  storageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  supplier?: InputMaybe<Suppliers_Bool_Exp>;
  supplierId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Aggregated_Demand_Plan_Results_Max_Fields = {
  __typename: 'aggregated_demand_plan_results_max_fields';
  expectedIncomingMonetaryQuantity?: Maybe<Scalars['bigint']>;
  expectedMonetaryQuantityUsage?: Maybe<Scalars['bigint']>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  notificationMonetaryQuantity?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  recommendedPurchaseQuantity?: Maybe<Scalars['bigint']>;
  securityMonetaryQuantity?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageQuantity?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  supplierId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "aggregated_demand_plan_results" */
export type Aggregated_Demand_Plan_Results_Max_Order_By = {
  expectedIncomingMonetaryQuantity?: InputMaybe<Order_By>;
  expectedMonetaryQuantityUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  notificationMonetaryQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  recommendedPurchaseQuantity?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Aggregated_Demand_Plan_Results_Min_Fields = {
  __typename: 'aggregated_demand_plan_results_min_fields';
  expectedIncomingMonetaryQuantity?: Maybe<Scalars['bigint']>;
  expectedMonetaryQuantityUsage?: Maybe<Scalars['bigint']>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  notificationMonetaryQuantity?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  recommendedPurchaseQuantity?: Maybe<Scalars['bigint']>;
  securityMonetaryQuantity?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageQuantity?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  supplierId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "aggregated_demand_plan_results" */
export type Aggregated_Demand_Plan_Results_Min_Order_By = {
  expectedIncomingMonetaryQuantity?: InputMaybe<Order_By>;
  expectedMonetaryQuantityUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  notificationMonetaryQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  recommendedPurchaseQuantity?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "aggregated_demand_plan_results". */
export type Aggregated_Demand_Plan_Results_Order_By = {
  contents?: InputMaybe<Order_By>;
  expectedIncomingMonetaryQuantity?: InputMaybe<Order_By>;
  expectedMonetaryQuantityUsage?: InputMaybe<Order_By>;
  incomingGoods_aggregate?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Aggregate_Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnit?: InputMaybe<Product_Units_Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  notificationMonetaryQuantity?: InputMaybe<Order_By>;
  outgoingGoods_aggregate?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingSuggestion?: InputMaybe<Order_By>;
  recommendedPurchaseQuantity?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocation?: InputMaybe<Stock_Locations_Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnit?: InputMaybe<Product_Units_Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  supplier?: InputMaybe<Suppliers_Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** select columns of table "aggregated_demand_plan_results" */
export enum Aggregated_Demand_Plan_Results_Select_Column {
  /** column name */
  Contents = 'contents',
  /** column name */
  ExpectedIncomingMonetaryQuantity = 'expectedIncomingMonetaryQuantity',
  /** column name */
  ExpectedMonetaryQuantityUsage = 'expectedMonetaryQuantityUsage',
  /** column name */
  MonetaryQuantity = 'monetaryQuantity',
  /** column name */
  MonetaryUnitId = 'monetaryUnitId',
  /** column name */
  NotificationMonetaryQuantity = 'notificationMonetaryQuantity',
  /** column name */
  ProductId = 'productId',
  /** column name */
  PurchasingSuggestion = 'purchasingSuggestion',
  /** column name */
  RecommendedPurchaseQuantity = 'recommendedPurchaseQuantity',
  /** column name */
  SecurityMonetaryQuantity = 'securityMonetaryQuantity',
  /** column name */
  StockLocationId = 'stockLocationId',
  /** column name */
  StorageQuantity = 'storageQuantity',
  /** column name */
  StorageUnitId = 'storageUnitId',
  /** column name */
  SupplierId = 'supplierId'
}

/** aggregate stddev on columns */
export type Aggregated_Demand_Plan_Results_Stddev_Fields = {
  __typename: 'aggregated_demand_plan_results_stddev_fields';
  expectedIncomingMonetaryQuantity?: Maybe<Scalars['Float']>;
  expectedMonetaryQuantityUsage?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  notificationMonetaryQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  recommendedPurchaseQuantity?: Maybe<Scalars['Float']>;
  securityMonetaryQuantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "aggregated_demand_plan_results" */
export type Aggregated_Demand_Plan_Results_Stddev_Order_By = {
  expectedIncomingMonetaryQuantity?: InputMaybe<Order_By>;
  expectedMonetaryQuantityUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  notificationMonetaryQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  recommendedPurchaseQuantity?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Aggregated_Demand_Plan_Results_Stddev_Pop_Fields = {
  __typename: 'aggregated_demand_plan_results_stddev_pop_fields';
  expectedIncomingMonetaryQuantity?: Maybe<Scalars['Float']>;
  expectedMonetaryQuantityUsage?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  notificationMonetaryQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  recommendedPurchaseQuantity?: Maybe<Scalars['Float']>;
  securityMonetaryQuantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "aggregated_demand_plan_results" */
export type Aggregated_Demand_Plan_Results_Stddev_Pop_Order_By = {
  expectedIncomingMonetaryQuantity?: InputMaybe<Order_By>;
  expectedMonetaryQuantityUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  notificationMonetaryQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  recommendedPurchaseQuantity?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Aggregated_Demand_Plan_Results_Stddev_Samp_Fields = {
  __typename: 'aggregated_demand_plan_results_stddev_samp_fields';
  expectedIncomingMonetaryQuantity?: Maybe<Scalars['Float']>;
  expectedMonetaryQuantityUsage?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  notificationMonetaryQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  recommendedPurchaseQuantity?: Maybe<Scalars['Float']>;
  securityMonetaryQuantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "aggregated_demand_plan_results" */
export type Aggregated_Demand_Plan_Results_Stddev_Samp_Order_By = {
  expectedIncomingMonetaryQuantity?: InputMaybe<Order_By>;
  expectedMonetaryQuantityUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  notificationMonetaryQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  recommendedPurchaseQuantity?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "aggregated_demand_plan_results" */
export type Aggregated_Demand_Plan_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Aggregated_Demand_Plan_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Aggregated_Demand_Plan_Results_Stream_Cursor_Value_Input = {
  contents?: InputMaybe<Scalars['jsonb']>;
  expectedIncomingMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  expectedMonetaryQuantityUsage?: InputMaybe<Scalars['bigint']>;
  monetaryQuantity?: InputMaybe<Scalars['bigint']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  notificationMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  purchasingSuggestion?: InputMaybe<Purchasing_Suggestions_Enum>;
  recommendedPurchaseQuantity?: InputMaybe<Scalars['bigint']>;
  securityMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  storageQuantity?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
  supplierId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Aggregated_Demand_Plan_Results_Sum_Fields = {
  __typename: 'aggregated_demand_plan_results_sum_fields';
  expectedIncomingMonetaryQuantity?: Maybe<Scalars['bigint']>;
  expectedMonetaryQuantityUsage?: Maybe<Scalars['bigint']>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  notificationMonetaryQuantity?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  recommendedPurchaseQuantity?: Maybe<Scalars['bigint']>;
  securityMonetaryQuantity?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageQuantity?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  supplierId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "aggregated_demand_plan_results" */
export type Aggregated_Demand_Plan_Results_Sum_Order_By = {
  expectedIncomingMonetaryQuantity?: InputMaybe<Order_By>;
  expectedMonetaryQuantityUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  notificationMonetaryQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  recommendedPurchaseQuantity?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Aggregated_Demand_Plan_Results_Var_Pop_Fields = {
  __typename: 'aggregated_demand_plan_results_var_pop_fields';
  expectedIncomingMonetaryQuantity?: Maybe<Scalars['Float']>;
  expectedMonetaryQuantityUsage?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  notificationMonetaryQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  recommendedPurchaseQuantity?: Maybe<Scalars['Float']>;
  securityMonetaryQuantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "aggregated_demand_plan_results" */
export type Aggregated_Demand_Plan_Results_Var_Pop_Order_By = {
  expectedIncomingMonetaryQuantity?: InputMaybe<Order_By>;
  expectedMonetaryQuantityUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  notificationMonetaryQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  recommendedPurchaseQuantity?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Aggregated_Demand_Plan_Results_Var_Samp_Fields = {
  __typename: 'aggregated_demand_plan_results_var_samp_fields';
  expectedIncomingMonetaryQuantity?: Maybe<Scalars['Float']>;
  expectedMonetaryQuantityUsage?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  notificationMonetaryQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  recommendedPurchaseQuantity?: Maybe<Scalars['Float']>;
  securityMonetaryQuantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "aggregated_demand_plan_results" */
export type Aggregated_Demand_Plan_Results_Var_Samp_Order_By = {
  expectedIncomingMonetaryQuantity?: InputMaybe<Order_By>;
  expectedMonetaryQuantityUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  notificationMonetaryQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  recommendedPurchaseQuantity?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Aggregated_Demand_Plan_Results_Variance_Fields = {
  __typename: 'aggregated_demand_plan_results_variance_fields';
  expectedIncomingMonetaryQuantity?: Maybe<Scalars['Float']>;
  expectedMonetaryQuantityUsage?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  notificationMonetaryQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  recommendedPurchaseQuantity?: Maybe<Scalars['Float']>;
  securityMonetaryQuantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "aggregated_demand_plan_results" */
export type Aggregated_Demand_Plan_Results_Variance_Order_By = {
  expectedIncomingMonetaryQuantity?: InputMaybe<Order_By>;
  expectedMonetaryQuantityUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  notificationMonetaryQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  recommendedPurchaseQuantity?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** columns and relationships of "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location = {
  __typename: 'aggregated_inventory_items_by_location';
  contents?: Maybe<Scalars['jsonb']>;
  costPricePerUnit?: Maybe<Scalars['bigint']>;
  costPriceUnitId?: Maybe<Scalars['bigint']>;
  currency?: Maybe<Currencies_Enum>;
  hasNegativeMonetaryQuantity?: Maybe<Scalars['Boolean']>;
  /** Unique identity for each aggregate record */
  hash?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  location: Stock_Locations;
  locationId: Scalars['bigint'];
  /** An object relationship */
  monetaryUnit: Product_Units;
  monetaryUnitId: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  /** An object relationship */
  productStockLocation?: Maybe<Product_Stock_Locations>;
  /** An object relationship */
  storageUnit: Product_Units;
  storageUnitId: Scalars['bigint'];
  sumAvailableMonetaryQuantity: Scalars['bigint'];
  sumAvailableStorageQuantity: Scalars['bigint'];
  sumMonetaryQuantity: Scalars['bigint'];
  sumReservedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumReservedStorageQuantity?: Maybe<Scalars['bigint']>;
  sumStorageQuantity: Scalars['bigint'];
  totalMonetaryCostPrice?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_LocationContentsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location_Aggregate = {
  __typename: 'aggregated_inventory_items_by_location_aggregate';
  aggregate?: Maybe<Aggregated_Inventory_Items_By_Location_Aggregate_Fields>;
  nodes: Array<Aggregated_Inventory_Items_By_Location>;
};

export type Aggregated_Inventory_Items_By_Location_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Aggregated_Inventory_Items_By_Location_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Aggregated_Inventory_Items_By_Location_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Aggregated_Inventory_Items_By_Location_Aggregate_Bool_Exp_Count>;
};

export type Aggregated_Inventory_Items_By_Location_Aggregate_Bool_Exp_Bool_And = {
  arguments: Aggregated_Inventory_Items_By_Location_Select_Column_Aggregated_Inventory_Items_By_Location_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Aggregated_Inventory_Items_By_Location_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Aggregated_Inventory_Items_By_Location_Select_Column_Aggregated_Inventory_Items_By_Location_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Aggregated_Inventory_Items_By_Location_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location_Aggregate_Fields = {
  __typename: 'aggregated_inventory_items_by_location_aggregate_fields';
  avg?: Maybe<Aggregated_Inventory_Items_By_Location_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Aggregated_Inventory_Items_By_Location_Max_Fields>;
  min?: Maybe<Aggregated_Inventory_Items_By_Location_Min_Fields>;
  stddev?: Maybe<Aggregated_Inventory_Items_By_Location_Stddev_Fields>;
  stddev_pop?: Maybe<Aggregated_Inventory_Items_By_Location_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Aggregated_Inventory_Items_By_Location_Stddev_Samp_Fields>;
  sum?: Maybe<Aggregated_Inventory_Items_By_Location_Sum_Fields>;
  var_pop?: Maybe<Aggregated_Inventory_Items_By_Location_Var_Pop_Fields>;
  var_samp?: Maybe<Aggregated_Inventory_Items_By_Location_Var_Samp_Fields>;
  variance?: Maybe<Aggregated_Inventory_Items_By_Location_Variance_Fields>;
};


/** aggregate fields of "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location_Aggregate_Order_By = {
  avg?: InputMaybe<Aggregated_Inventory_Items_By_Location_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Aggregated_Inventory_Items_By_Location_Max_Order_By>;
  min?: InputMaybe<Aggregated_Inventory_Items_By_Location_Min_Order_By>;
  stddev?: InputMaybe<Aggregated_Inventory_Items_By_Location_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Aggregated_Inventory_Items_By_Location_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Aggregated_Inventory_Items_By_Location_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Aggregated_Inventory_Items_By_Location_Sum_Order_By>;
  var_pop?: InputMaybe<Aggregated_Inventory_Items_By_Location_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Aggregated_Inventory_Items_By_Location_Var_Samp_Order_By>;
  variance?: InputMaybe<Aggregated_Inventory_Items_By_Location_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Aggregated_Inventory_Items_By_Location_Append_Input = {
  contents?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location_Arr_Rel_Insert_Input = {
  data: Array<Aggregated_Inventory_Items_By_Location_Insert_Input>;
};

/** aggregate avg on columns */
export type Aggregated_Inventory_Items_By_Location_Avg_Fields = {
  __typename: 'aggregated_inventory_items_by_location_avg_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumAvailableMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumAvailableStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumReservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumReservedStorageQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  totalMonetaryCostPrice?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location_Avg_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumAvailableMonetaryQuantity?: InputMaybe<Order_By>;
  sumAvailableStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedStorageQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "aggregated_inventory_items_by_location". All fields are combined with a logical 'AND'. */
export type Aggregated_Inventory_Items_By_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Bool_Exp>>;
  _not?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Bool_Exp>>;
  contents?: InputMaybe<Jsonb_Comparison_Exp>;
  costPricePerUnit?: InputMaybe<Bigint_Comparison_Exp>;
  costPriceUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  hasNegativeMonetaryQuantity?: InputMaybe<Boolean_Comparison_Exp>;
  hash?: InputMaybe<Bigint_Comparison_Exp>;
  location?: InputMaybe<Stock_Locations_Bool_Exp>;
  locationId?: InputMaybe<Bigint_Comparison_Exp>;
  monetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  monetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  productStockLocation?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
  storageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  storageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  sumAvailableMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  sumAvailableStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  sumMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  sumReservedMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  sumReservedStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  sumStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  totalMonetaryCostPrice?: InputMaybe<Bigint_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Aggregated_Inventory_Items_By_Location_Delete_At_Path_Input = {
  contents?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Aggregated_Inventory_Items_By_Location_Delete_Elem_Input = {
  contents?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Aggregated_Inventory_Items_By_Location_Delete_Key_Input = {
  contents?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location_Inc_Input = {
  costPricePerUnit?: InputMaybe<Scalars['bigint']>;
  costPriceUnitId?: InputMaybe<Scalars['bigint']>;
  locationId?: InputMaybe<Scalars['bigint']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
  sumAvailableMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sumAvailableStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sumMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sumReservedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sumReservedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sumStorageQuantity?: InputMaybe<Scalars['bigint']>;
  totalMonetaryCostPrice?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location_Insert_Input = {
  contents?: InputMaybe<Scalars['jsonb']>;
  costPricePerUnit?: InputMaybe<Scalars['bigint']>;
  costPriceUnitId?: InputMaybe<Scalars['bigint']>;
  currency?: InputMaybe<Currencies_Enum>;
  hasNegativeMonetaryQuantity?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Stock_Locations_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['bigint']>;
  monetaryUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  productStockLocation?: InputMaybe<Product_Stock_Locations_Obj_Rel_Insert_Input>;
  storageUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
  sumAvailableMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sumAvailableStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sumMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sumReservedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sumReservedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sumStorageQuantity?: InputMaybe<Scalars['bigint']>;
  totalMonetaryCostPrice?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Aggregated_Inventory_Items_By_Location_Max_Fields = {
  __typename: 'aggregated_inventory_items_by_location_max_fields';
  costPricePerUnit?: Maybe<Scalars['bigint']>;
  costPriceUnitId?: Maybe<Scalars['bigint']>;
  locationId?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  sumAvailableMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumAvailableStorageQuantity?: Maybe<Scalars['bigint']>;
  sumMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumReservedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumReservedStorageQuantity?: Maybe<Scalars['bigint']>;
  sumStorageQuantity?: Maybe<Scalars['bigint']>;
  totalMonetaryCostPrice?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location_Max_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumAvailableMonetaryQuantity?: InputMaybe<Order_By>;
  sumAvailableStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedStorageQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Aggregated_Inventory_Items_By_Location_Min_Fields = {
  __typename: 'aggregated_inventory_items_by_location_min_fields';
  costPricePerUnit?: Maybe<Scalars['bigint']>;
  costPriceUnitId?: Maybe<Scalars['bigint']>;
  locationId?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  sumAvailableMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumAvailableStorageQuantity?: Maybe<Scalars['bigint']>;
  sumMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumReservedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumReservedStorageQuantity?: Maybe<Scalars['bigint']>;
  sumStorageQuantity?: Maybe<Scalars['bigint']>;
  totalMonetaryCostPrice?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location_Min_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumAvailableMonetaryQuantity?: InputMaybe<Order_By>;
  sumAvailableStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedStorageQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location_Mutation_Response = {
  __typename: 'aggregated_inventory_items_by_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Aggregated_Inventory_Items_By_Location>;
};

/** Ordering options when selecting data from "aggregated_inventory_items_by_location". */
export type Aggregated_Inventory_Items_By_Location_Order_By = {
  contents?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  hasNegativeMonetaryQuantity?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  location?: InputMaybe<Stock_Locations_Order_By>;
  locationId?: InputMaybe<Order_By>;
  monetaryUnit?: InputMaybe<Product_Units_Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  productStockLocation?: InputMaybe<Product_Stock_Locations_Order_By>;
  storageUnit?: InputMaybe<Product_Units_Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumAvailableMonetaryQuantity?: InputMaybe<Order_By>;
  sumAvailableStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedStorageQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Aggregated_Inventory_Items_By_Location_Prepend_Input = {
  contents?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "aggregated_inventory_items_by_location" */
export enum Aggregated_Inventory_Items_By_Location_Select_Column {
  /** column name */
  Contents = 'contents',
  /** column name */
  CostPricePerUnit = 'costPricePerUnit',
  /** column name */
  CostPriceUnitId = 'costPriceUnitId',
  /** column name */
  Currency = 'currency',
  /** column name */
  HasNegativeMonetaryQuantity = 'hasNegativeMonetaryQuantity',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  MonetaryUnitId = 'monetaryUnitId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  StorageUnitId = 'storageUnitId',
  /** column name */
  SumAvailableMonetaryQuantity = 'sumAvailableMonetaryQuantity',
  /** column name */
  SumAvailableStorageQuantity = 'sumAvailableStorageQuantity',
  /** column name */
  SumMonetaryQuantity = 'sumMonetaryQuantity',
  /** column name */
  SumReservedMonetaryQuantity = 'sumReservedMonetaryQuantity',
  /** column name */
  SumReservedStorageQuantity = 'sumReservedStorageQuantity',
  /** column name */
  SumStorageQuantity = 'sumStorageQuantity',
  /** column name */
  TotalMonetaryCostPrice = 'totalMonetaryCostPrice'
}

/** select "aggregated_inventory_items_by_location_aggregate_bool_exp_bool_and_arguments_columns" columns of table "aggregated_inventory_items_by_location" */
export enum Aggregated_Inventory_Items_By_Location_Select_Column_Aggregated_Inventory_Items_By_Location_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasNegativeMonetaryQuantity = 'hasNegativeMonetaryQuantity'
}

/** select "aggregated_inventory_items_by_location_aggregate_bool_exp_bool_or_arguments_columns" columns of table "aggregated_inventory_items_by_location" */
export enum Aggregated_Inventory_Items_By_Location_Select_Column_Aggregated_Inventory_Items_By_Location_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasNegativeMonetaryQuantity = 'hasNegativeMonetaryQuantity'
}

/** input type for updating data in table "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location_Set_Input = {
  contents?: InputMaybe<Scalars['jsonb']>;
  costPricePerUnit?: InputMaybe<Scalars['bigint']>;
  costPriceUnitId?: InputMaybe<Scalars['bigint']>;
  currency?: InputMaybe<Currencies_Enum>;
  hasNegativeMonetaryQuantity?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['bigint']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
  sumAvailableMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sumAvailableStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sumMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sumReservedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sumReservedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sumStorageQuantity?: InputMaybe<Scalars['bigint']>;
  totalMonetaryCostPrice?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Aggregated_Inventory_Items_By_Location_Stddev_Fields = {
  __typename: 'aggregated_inventory_items_by_location_stddev_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumAvailableMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumAvailableStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumReservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumReservedStorageQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  totalMonetaryCostPrice?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location_Stddev_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumAvailableMonetaryQuantity?: InputMaybe<Order_By>;
  sumAvailableStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedStorageQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Aggregated_Inventory_Items_By_Location_Stddev_Pop_Fields = {
  __typename: 'aggregated_inventory_items_by_location_stddev_pop_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumAvailableMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumAvailableStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumReservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumReservedStorageQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  totalMonetaryCostPrice?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location_Stddev_Pop_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumAvailableMonetaryQuantity?: InputMaybe<Order_By>;
  sumAvailableStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedStorageQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Aggregated_Inventory_Items_By_Location_Stddev_Samp_Fields = {
  __typename: 'aggregated_inventory_items_by_location_stddev_samp_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumAvailableMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumAvailableStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumReservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumReservedStorageQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  totalMonetaryCostPrice?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location_Stddev_Samp_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumAvailableMonetaryQuantity?: InputMaybe<Order_By>;
  sumAvailableStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedStorageQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Aggregated_Inventory_Items_By_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Aggregated_Inventory_Items_By_Location_Stream_Cursor_Value_Input = {
  contents?: InputMaybe<Scalars['jsonb']>;
  costPricePerUnit?: InputMaybe<Scalars['bigint']>;
  costPriceUnitId?: InputMaybe<Scalars['bigint']>;
  currency?: InputMaybe<Currencies_Enum>;
  hasNegativeMonetaryQuantity?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['bigint']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
  sumAvailableMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sumAvailableStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sumMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sumReservedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sumReservedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sumStorageQuantity?: InputMaybe<Scalars['bigint']>;
  totalMonetaryCostPrice?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Aggregated_Inventory_Items_By_Location_Sum_Fields = {
  __typename: 'aggregated_inventory_items_by_location_sum_fields';
  costPricePerUnit?: Maybe<Scalars['bigint']>;
  costPriceUnitId?: Maybe<Scalars['bigint']>;
  locationId?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  sumAvailableMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumAvailableStorageQuantity?: Maybe<Scalars['bigint']>;
  sumMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumReservedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumReservedStorageQuantity?: Maybe<Scalars['bigint']>;
  sumStorageQuantity?: Maybe<Scalars['bigint']>;
  totalMonetaryCostPrice?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location_Sum_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumAvailableMonetaryQuantity?: InputMaybe<Order_By>;
  sumAvailableStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedStorageQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

export type Aggregated_Inventory_Items_By_Location_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Aggregated_Inventory_Items_By_Location_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Aggregated_Inventory_Items_By_Location_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Aggregated_Inventory_Items_By_Location_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Aggregated_Inventory_Items_By_Location_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Aggregated_Inventory_Items_By_Location_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Aggregated_Inventory_Items_By_Location_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Aggregated_Inventory_Items_By_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Aggregated_Inventory_Items_By_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Aggregated_Inventory_Items_By_Location_Var_Pop_Fields = {
  __typename: 'aggregated_inventory_items_by_location_var_pop_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumAvailableMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumAvailableStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumReservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumReservedStorageQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  totalMonetaryCostPrice?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location_Var_Pop_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumAvailableMonetaryQuantity?: InputMaybe<Order_By>;
  sumAvailableStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedStorageQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Aggregated_Inventory_Items_By_Location_Var_Samp_Fields = {
  __typename: 'aggregated_inventory_items_by_location_var_samp_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumAvailableMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumAvailableStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumReservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumReservedStorageQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  totalMonetaryCostPrice?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location_Var_Samp_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumAvailableMonetaryQuantity?: InputMaybe<Order_By>;
  sumAvailableStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedStorageQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Aggregated_Inventory_Items_By_Location_Variance_Fields = {
  __typename: 'aggregated_inventory_items_by_location_variance_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumAvailableMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumAvailableStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumReservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumReservedStorageQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  totalMonetaryCostPrice?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "aggregated_inventory_items_by_location" */
export type Aggregated_Inventory_Items_By_Location_Variance_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumAvailableMonetaryQuantity?: InputMaybe<Order_By>;
  sumAvailableStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedMonetaryQuantity?: InputMaybe<Order_By>;
  sumReservedStorageQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

export type Aggregated_List_Inventory_By_Location_Args = {
  on_date?: InputMaybe<Scalars['timestamptz']>;
  with_cost_price?: InputMaybe<Scalars['Boolean']>;
  with_psl?: InputMaybe<Scalars['Boolean']>;
  with_reservations?: InputMaybe<Scalars['Boolean']>;
};

export enum App_Modules_Enum {
  Customer = 'CUSTOMER',
  CustomerOrder = 'CUSTOMER_ORDER',
  Dismantling = 'DISMANTLING',
  GoodsIncome = 'GOODS_INCOME',
  Invoice = 'INVOICE',
  Logistic = 'LOGISTIC',
  Picking = 'PICKING',
  Price = 'PRICE',
  Product = 'PRODUCT',
  Production = 'PRODUCTION',
  Purchasing = 'PURCHASING',
  Stock = 'STOCK',
  Supplier = 'SUPPLIER',
  System = 'SYSTEM',
  TenantSetting = 'TENANT_SETTING'
}

/** Boolean expression to compare columns of type "app_modules_enum". All fields are combined with logical 'AND'. */
export type App_Modules_Enum_Comparison_Exp = {
  _eq?: InputMaybe<App_Modules_Enum>;
  _in?: InputMaybe<Array<App_Modules_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<App_Modules_Enum>;
  _nin?: InputMaybe<Array<App_Modules_Enum>>;
};

export type Approve_Cutting_Pattern_Drafts_Args = {
  _valid_from?: InputMaybe<Scalars['timestamptz']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
export type Bpchar_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bpchar']>;
  _gt?: InputMaybe<Scalars['bpchar']>;
  _gte?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['bpchar']>;
  _in?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['bpchar']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['bpchar']>;
  _lt?: InputMaybe<Scalars['bpchar']>;
  _lte?: InputMaybe<Scalars['bpchar']>;
  _neq?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['bpchar']>;
  _nin?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['bpchar']>;
};

export type Calculate_Coi_Sales_Quantity_From_Picking_Args = {
  _customer_order_id?: InputMaybe<Scalars['bigint']>;
};

export type Calculate_Cost_Price_For_Product_Args = {
  _as_of?: InputMaybe<Scalars['date']>;
  _product_id?: InputMaybe<Scalars['bigint']>;
  _tenant?: InputMaybe<Scalars['String']>;
};

export type Calculate_Cost_Price_For_Product_By_Product_Args = {
  _as_of?: InputMaybe<Scalars['date']>;
  _tenant?: InputMaybe<Scalars['String']>;
  pr?: InputMaybe<Scalars['products_scalar']>;
};

/** columns and relationships of "calculate_cost_price_for_product_results" */
export type Calculate_Cost_Price_For_Product_Results = {
  __typename: 'calculate_cost_price_for_product_results';
  averageCostPrice: Scalars['bigint'];
  /** An object relationship */
  costPriceUnit: Product_Units;
  costPriceUnitId: Scalars['bigint'];
  currency: Currencies_Enum;
  /** An object relationship */
  temporalProduct?: Maybe<Temporal_Products>;
  temporalProductId?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "calculate_cost_price_for_product_results" */
export type Calculate_Cost_Price_For_Product_Results_Aggregate = {
  __typename: 'calculate_cost_price_for_product_results_aggregate';
  aggregate?: Maybe<Calculate_Cost_Price_For_Product_Results_Aggregate_Fields>;
  nodes: Array<Calculate_Cost_Price_For_Product_Results>;
};

export type Calculate_Cost_Price_For_Product_Results_Aggregate_Bool_Exp = {
  count?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Aggregate_Bool_Exp_Count>;
};

export type Calculate_Cost_Price_For_Product_Results_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "calculate_cost_price_for_product_results" */
export type Calculate_Cost_Price_For_Product_Results_Aggregate_Fields = {
  __typename: 'calculate_cost_price_for_product_results_aggregate_fields';
  avg?: Maybe<Calculate_Cost_Price_For_Product_Results_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Calculate_Cost_Price_For_Product_Results_Max_Fields>;
  min?: Maybe<Calculate_Cost_Price_For_Product_Results_Min_Fields>;
  stddev?: Maybe<Calculate_Cost_Price_For_Product_Results_Stddev_Fields>;
  stddev_pop?: Maybe<Calculate_Cost_Price_For_Product_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Calculate_Cost_Price_For_Product_Results_Stddev_Samp_Fields>;
  sum?: Maybe<Calculate_Cost_Price_For_Product_Results_Sum_Fields>;
  var_pop?: Maybe<Calculate_Cost_Price_For_Product_Results_Var_Pop_Fields>;
  var_samp?: Maybe<Calculate_Cost_Price_For_Product_Results_Var_Samp_Fields>;
  variance?: Maybe<Calculate_Cost_Price_For_Product_Results_Variance_Fields>;
};


/** aggregate fields of "calculate_cost_price_for_product_results" */
export type Calculate_Cost_Price_For_Product_Results_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "calculate_cost_price_for_product_results" */
export type Calculate_Cost_Price_For_Product_Results_Aggregate_Order_By = {
  avg?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Max_Order_By>;
  min?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Min_Order_By>;
  stddev?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Sum_Order_By>;
  var_pop?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Var_Samp_Order_By>;
  variance?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Calculate_Cost_Price_For_Product_Results_Avg_Fields = {
  __typename: 'calculate_cost_price_for_product_results_avg_fields';
  averageCostPrice?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  temporalProductId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "calculate_cost_price_for_product_results" */
export type Calculate_Cost_Price_For_Product_Results_Avg_Order_By = {
  averageCostPrice?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  temporalProductId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "calculate_cost_price_for_product_results". All fields are combined with a logical 'AND'. */
export type Calculate_Cost_Price_For_Product_Results_Bool_Exp = {
  _and?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Bool_Exp>>;
  _not?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
  _or?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Bool_Exp>>;
  averageCostPrice?: InputMaybe<Bigint_Comparison_Exp>;
  costPriceUnit?: InputMaybe<Product_Units_Bool_Exp>;
  costPriceUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  temporalProduct?: InputMaybe<Temporal_Products_Bool_Exp>;
  temporalProductId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Calculate_Cost_Price_For_Product_Results_Max_Fields = {
  __typename: 'calculate_cost_price_for_product_results_max_fields';
  averageCostPrice?: Maybe<Scalars['bigint']>;
  costPriceUnitId?: Maybe<Scalars['bigint']>;
  temporalProductId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "calculate_cost_price_for_product_results" */
export type Calculate_Cost_Price_For_Product_Results_Max_Order_By = {
  averageCostPrice?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  temporalProductId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Calculate_Cost_Price_For_Product_Results_Min_Fields = {
  __typename: 'calculate_cost_price_for_product_results_min_fields';
  averageCostPrice?: Maybe<Scalars['bigint']>;
  costPriceUnitId?: Maybe<Scalars['bigint']>;
  temporalProductId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "calculate_cost_price_for_product_results" */
export type Calculate_Cost_Price_For_Product_Results_Min_Order_By = {
  averageCostPrice?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  temporalProductId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "calculate_cost_price_for_product_results". */
export type Calculate_Cost_Price_For_Product_Results_Order_By = {
  averageCostPrice?: InputMaybe<Order_By>;
  costPriceUnit?: InputMaybe<Product_Units_Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  temporalProduct?: InputMaybe<Temporal_Products_Order_By>;
  temporalProductId?: InputMaybe<Order_By>;
};

/** select columns of table "calculate_cost_price_for_product_results" */
export enum Calculate_Cost_Price_For_Product_Results_Select_Column {
  /** column name */
  AverageCostPrice = 'averageCostPrice',
  /** column name */
  CostPriceUnitId = 'costPriceUnitId',
  /** column name */
  Currency = 'currency',
  /** column name */
  TemporalProductId = 'temporalProductId'
}

/** aggregate stddev on columns */
export type Calculate_Cost_Price_For_Product_Results_Stddev_Fields = {
  __typename: 'calculate_cost_price_for_product_results_stddev_fields';
  averageCostPrice?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  temporalProductId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "calculate_cost_price_for_product_results" */
export type Calculate_Cost_Price_For_Product_Results_Stddev_Order_By = {
  averageCostPrice?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  temporalProductId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Calculate_Cost_Price_For_Product_Results_Stddev_Pop_Fields = {
  __typename: 'calculate_cost_price_for_product_results_stddev_pop_fields';
  averageCostPrice?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  temporalProductId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "calculate_cost_price_for_product_results" */
export type Calculate_Cost_Price_For_Product_Results_Stddev_Pop_Order_By = {
  averageCostPrice?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  temporalProductId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Calculate_Cost_Price_For_Product_Results_Stddev_Samp_Fields = {
  __typename: 'calculate_cost_price_for_product_results_stddev_samp_fields';
  averageCostPrice?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  temporalProductId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "calculate_cost_price_for_product_results" */
export type Calculate_Cost_Price_For_Product_Results_Stddev_Samp_Order_By = {
  averageCostPrice?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  temporalProductId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "calculate_cost_price_for_product_results" */
export type Calculate_Cost_Price_For_Product_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Calculate_Cost_Price_For_Product_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Calculate_Cost_Price_For_Product_Results_Stream_Cursor_Value_Input = {
  averageCostPrice?: InputMaybe<Scalars['bigint']>;
  costPriceUnitId?: InputMaybe<Scalars['bigint']>;
  currency?: InputMaybe<Currencies_Enum>;
  temporalProductId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Calculate_Cost_Price_For_Product_Results_Sum_Fields = {
  __typename: 'calculate_cost_price_for_product_results_sum_fields';
  averageCostPrice?: Maybe<Scalars['bigint']>;
  costPriceUnitId?: Maybe<Scalars['bigint']>;
  temporalProductId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "calculate_cost_price_for_product_results" */
export type Calculate_Cost_Price_For_Product_Results_Sum_Order_By = {
  averageCostPrice?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  temporalProductId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Calculate_Cost_Price_For_Product_Results_Var_Pop_Fields = {
  __typename: 'calculate_cost_price_for_product_results_var_pop_fields';
  averageCostPrice?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  temporalProductId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "calculate_cost_price_for_product_results" */
export type Calculate_Cost_Price_For_Product_Results_Var_Pop_Order_By = {
  averageCostPrice?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  temporalProductId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Calculate_Cost_Price_For_Product_Results_Var_Samp_Fields = {
  __typename: 'calculate_cost_price_for_product_results_var_samp_fields';
  averageCostPrice?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  temporalProductId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "calculate_cost_price_for_product_results" */
export type Calculate_Cost_Price_For_Product_Results_Var_Samp_Order_By = {
  averageCostPrice?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  temporalProductId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Calculate_Cost_Price_For_Product_Results_Variance_Fields = {
  __typename: 'calculate_cost_price_for_product_results_variance_fields';
  averageCostPrice?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  temporalProductId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "calculate_cost_price_for_product_results" */
export type Calculate_Cost_Price_For_Product_Results_Variance_Order_By = {
  averageCostPrice?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  temporalProductId?: InputMaybe<Order_By>;
};

export type Calculate_Customer_Pricing_Args = {
  _as_of?: InputMaybe<Scalars['date']>;
  _customer_id?: InputMaybe<Scalars['bigint']>;
  _product_id?: InputMaybe<Scalars['bigint']>;
  _sales_unit_id?: InputMaybe<Scalars['bigint']>;
};

export type CalculatedCostPrice_Products_Args = {
  _as_of?: InputMaybe<Scalars['date']>;
  _tenant?: InputMaybe<Scalars['String']>;
};

export type Cancel_Supplier_Order_Item_Args = {
  id?: InputMaybe<Scalars['bigint']>;
};

export type Change_Customer_Order_With_Token_Args = {
  _customer_order_id?: InputMaybe<Scalars['bigint']>;
  _customer_order_items?: InputMaybe<Scalars['json']>;
  _customer_order_state?: InputMaybe<Scalars['String']>;
  _delivery_date?: InputMaybe<Scalars['date']>;
};

/** columns and relationships of "change_customer_order_with_token_result" */
export type Change_Customer_Order_With_Token_Result = {
  __typename: 'change_customer_order_with_token_result';
  ok: Scalars['Boolean'];
};

/** aggregated selection of "change_customer_order_with_token_result" */
export type Change_Customer_Order_With_Token_Result_Aggregate = {
  __typename: 'change_customer_order_with_token_result_aggregate';
  aggregate?: Maybe<Change_Customer_Order_With_Token_Result_Aggregate_Fields>;
  nodes: Array<Change_Customer_Order_With_Token_Result>;
};

/** aggregate fields of "change_customer_order_with_token_result" */
export type Change_Customer_Order_With_Token_Result_Aggregate_Fields = {
  __typename: 'change_customer_order_with_token_result_aggregate_fields';
  count: Scalars['Int'];
};


/** aggregate fields of "change_customer_order_with_token_result" */
export type Change_Customer_Order_With_Token_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Change_Customer_Order_With_Token_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "change_customer_order_with_token_result". All fields are combined with a logical 'AND'. */
export type Change_Customer_Order_With_Token_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Change_Customer_Order_With_Token_Result_Bool_Exp>>;
  _not?: InputMaybe<Change_Customer_Order_With_Token_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Change_Customer_Order_With_Token_Result_Bool_Exp>>;
  ok?: InputMaybe<Boolean_Comparison_Exp>;
};

/** Ordering options when selecting data from "change_customer_order_with_token_result". */
export type Change_Customer_Order_With_Token_Result_Order_By = {
  ok?: InputMaybe<Order_By>;
};

/** select columns of table "change_customer_order_with_token_result" */
export enum Change_Customer_Order_With_Token_Result_Select_Column {
  /** column name */
  Ok = 'ok'
}

/** Streaming cursor of the table "change_customer_order_with_token_result" */
export type Change_Customer_Order_With_Token_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Change_Customer_Order_With_Token_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Change_Customer_Order_With_Token_Result_Stream_Cursor_Value_Input = {
  ok?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "contact_assigned_roles" */
export type Contact_Assigned_Roles = {
  __typename: 'contact_assigned_roles';
  /** An object relationship */
  Tenant: Tenants;
  /** An object relationship */
  contact: Contacts;
  contactId: Scalars['bigint'];
  contactRole: Contact_Roles_Enum;
  createdAt: Scalars['timestamptz'];
  tenant: Scalars['String'];
};

/** aggregated selection of "contact_assigned_roles" */
export type Contact_Assigned_Roles_Aggregate = {
  __typename: 'contact_assigned_roles_aggregate';
  aggregate?: Maybe<Contact_Assigned_Roles_Aggregate_Fields>;
  nodes: Array<Contact_Assigned_Roles>;
};

export type Contact_Assigned_Roles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Contact_Assigned_Roles_Aggregate_Bool_Exp_Count>;
};

export type Contact_Assigned_Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contact_Assigned_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Contact_Assigned_Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contact_assigned_roles" */
export type Contact_Assigned_Roles_Aggregate_Fields = {
  __typename: 'contact_assigned_roles_aggregate_fields';
  avg?: Maybe<Contact_Assigned_Roles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Contact_Assigned_Roles_Max_Fields>;
  min?: Maybe<Contact_Assigned_Roles_Min_Fields>;
  stddev?: Maybe<Contact_Assigned_Roles_Stddev_Fields>;
  stddev_pop?: Maybe<Contact_Assigned_Roles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contact_Assigned_Roles_Stddev_Samp_Fields>;
  sum?: Maybe<Contact_Assigned_Roles_Sum_Fields>;
  var_pop?: Maybe<Contact_Assigned_Roles_Var_Pop_Fields>;
  var_samp?: Maybe<Contact_Assigned_Roles_Var_Samp_Fields>;
  variance?: Maybe<Contact_Assigned_Roles_Variance_Fields>;
};


/** aggregate fields of "contact_assigned_roles" */
export type Contact_Assigned_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contact_Assigned_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contact_assigned_roles" */
export type Contact_Assigned_Roles_Aggregate_Order_By = {
  avg?: InputMaybe<Contact_Assigned_Roles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contact_Assigned_Roles_Max_Order_By>;
  min?: InputMaybe<Contact_Assigned_Roles_Min_Order_By>;
  stddev?: InputMaybe<Contact_Assigned_Roles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Contact_Assigned_Roles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Contact_Assigned_Roles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Contact_Assigned_Roles_Sum_Order_By>;
  var_pop?: InputMaybe<Contact_Assigned_Roles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Contact_Assigned_Roles_Var_Samp_Order_By>;
  variance?: InputMaybe<Contact_Assigned_Roles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contact_assigned_roles" */
export type Contact_Assigned_Roles_Arr_Rel_Insert_Input = {
  data: Array<Contact_Assigned_Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contact_Assigned_Roles_On_Conflict>;
};

/** aggregate avg on columns */
export type Contact_Assigned_Roles_Avg_Fields = {
  __typename: 'contact_assigned_roles_avg_fields';
  contactId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contact_assigned_roles" */
export type Contact_Assigned_Roles_Avg_Order_By = {
  contactId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contact_assigned_roles". All fields are combined with a logical 'AND'. */
export type Contact_Assigned_Roles_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Contact_Assigned_Roles_Bool_Exp>>;
  _not?: InputMaybe<Contact_Assigned_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Contact_Assigned_Roles_Bool_Exp>>;
  contact?: InputMaybe<Contacts_Bool_Exp>;
  contactId?: InputMaybe<Bigint_Comparison_Exp>;
  contactRole?: InputMaybe<Contact_Roles_Enum_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_assigned_roles" */
export enum Contact_Assigned_Roles_Constraint {
  /** unique or primary key constraint on columns "contact_role", "contact_id" */
  ContactAssignedRolesPkey = 'contact_assigned_roles_pkey'
}

/** input type for incrementing numeric columns in table "contact_assigned_roles" */
export type Contact_Assigned_Roles_Inc_Input = {
  contactId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "contact_assigned_roles" */
export type Contact_Assigned_Roles_Insert_Input = {
  contact?: InputMaybe<Contacts_Obj_Rel_Insert_Input>;
  contactId?: InputMaybe<Scalars['bigint']>;
  contactRole?: InputMaybe<Contact_Roles_Enum>;
};

/** aggregate max on columns */
export type Contact_Assigned_Roles_Max_Fields = {
  __typename: 'contact_assigned_roles_max_fields';
  contactId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "contact_assigned_roles" */
export type Contact_Assigned_Roles_Max_Order_By = {
  contactId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contact_Assigned_Roles_Min_Fields = {
  __typename: 'contact_assigned_roles_min_fields';
  contactId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "contact_assigned_roles" */
export type Contact_Assigned_Roles_Min_Order_By = {
  contactId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contact_assigned_roles" */
export type Contact_Assigned_Roles_Mutation_Response = {
  __typename: 'contact_assigned_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Assigned_Roles>;
};

/** on_conflict condition type for table "contact_assigned_roles" */
export type Contact_Assigned_Roles_On_Conflict = {
  constraint: Contact_Assigned_Roles_Constraint;
  update_columns?: Array<Contact_Assigned_Roles_Update_Column>;
  where?: InputMaybe<Contact_Assigned_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_assigned_roles". */
export type Contact_Assigned_Roles_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  contact?: InputMaybe<Contacts_Order_By>;
  contactId?: InputMaybe<Order_By>;
  contactRole?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contact_assigned_roles */
export type Contact_Assigned_Roles_Pk_Columns_Input = {
  contactId: Scalars['bigint'];
  contactRole: Contact_Roles_Enum;
};

/** select columns of table "contact_assigned_roles" */
export enum Contact_Assigned_Roles_Select_Column {
  /** column name */
  ContactId = 'contactId',
  /** column name */
  ContactRole = 'contactRole',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Tenant = 'tenant'
}

/** input type for updating data in table "contact_assigned_roles" */
export type Contact_Assigned_Roles_Set_Input = {
  contactId?: InputMaybe<Scalars['bigint']>;
  contactRole?: InputMaybe<Contact_Roles_Enum>;
};

/** aggregate stddev on columns */
export type Contact_Assigned_Roles_Stddev_Fields = {
  __typename: 'contact_assigned_roles_stddev_fields';
  contactId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contact_assigned_roles" */
export type Contact_Assigned_Roles_Stddev_Order_By = {
  contactId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contact_Assigned_Roles_Stddev_Pop_Fields = {
  __typename: 'contact_assigned_roles_stddev_pop_fields';
  contactId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contact_assigned_roles" */
export type Contact_Assigned_Roles_Stddev_Pop_Order_By = {
  contactId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contact_Assigned_Roles_Stddev_Samp_Fields = {
  __typename: 'contact_assigned_roles_stddev_samp_fields';
  contactId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contact_assigned_roles" */
export type Contact_Assigned_Roles_Stddev_Samp_Order_By = {
  contactId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "contact_assigned_roles" */
export type Contact_Assigned_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Assigned_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Assigned_Roles_Stream_Cursor_Value_Input = {
  contactId?: InputMaybe<Scalars['bigint']>;
  contactRole?: InputMaybe<Contact_Roles_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Contact_Assigned_Roles_Sum_Fields = {
  __typename: 'contact_assigned_roles_sum_fields';
  contactId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "contact_assigned_roles" */
export type Contact_Assigned_Roles_Sum_Order_By = {
  contactId?: InputMaybe<Order_By>;
};

/** update columns of table "contact_assigned_roles" */
export enum Contact_Assigned_Roles_Update_Column {
  /** column name */
  ContactId = 'contactId',
  /** column name */
  ContactRole = 'contactRole'
}

export type Contact_Assigned_Roles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Contact_Assigned_Roles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contact_Assigned_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Assigned_Roles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Contact_Assigned_Roles_Var_Pop_Fields = {
  __typename: 'contact_assigned_roles_var_pop_fields';
  contactId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contact_assigned_roles" */
export type Contact_Assigned_Roles_Var_Pop_Order_By = {
  contactId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contact_Assigned_Roles_Var_Samp_Fields = {
  __typename: 'contact_assigned_roles_var_samp_fields';
  contactId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contact_assigned_roles" */
export type Contact_Assigned_Roles_Var_Samp_Order_By = {
  contactId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Contact_Assigned_Roles_Variance_Fields = {
  __typename: 'contact_assigned_roles_variance_fields';
  contactId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contact_assigned_roles" */
export type Contact_Assigned_Roles_Variance_Order_By = {
  contactId?: InputMaybe<Order_By>;
};

/** columns and relationships of "contact_availability" */
export type Contact_Availability = {
  __typename: 'contact_availability';
  /** An object relationship */
  Tenant: Tenants;
  /** An object relationship */
  contact: Contacts;
  /** An array relationship */
  contactAvailabilityRanges: Array<Contact_Availability_Ranges>;
  /** An aggregate relationship */
  contactAvailabilityRanges_aggregate: Contact_Availability_Ranges_Aggregate;
  contactId: Scalars['bigint'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
  workingDay: Working_Days_Enum;
};


/** columns and relationships of "contact_availability" */
export type Contact_AvailabilityContactAvailabilityRangesArgs = {
  distinct_on?: InputMaybe<Array<Contact_Availability_Ranges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Availability_Ranges_Order_By>>;
  where?: InputMaybe<Contact_Availability_Ranges_Bool_Exp>;
};


/** columns and relationships of "contact_availability" */
export type Contact_AvailabilityContactAvailabilityRanges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Availability_Ranges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Availability_Ranges_Order_By>>;
  where?: InputMaybe<Contact_Availability_Ranges_Bool_Exp>;
};

/** aggregated selection of "contact_availability" */
export type Contact_Availability_Aggregate = {
  __typename: 'contact_availability_aggregate';
  aggregate?: Maybe<Contact_Availability_Aggregate_Fields>;
  nodes: Array<Contact_Availability>;
};

export type Contact_Availability_Aggregate_Bool_Exp = {
  count?: InputMaybe<Contact_Availability_Aggregate_Bool_Exp_Count>;
};

export type Contact_Availability_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contact_Availability_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Contact_Availability_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contact_availability" */
export type Contact_Availability_Aggregate_Fields = {
  __typename: 'contact_availability_aggregate_fields';
  avg?: Maybe<Contact_Availability_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Contact_Availability_Max_Fields>;
  min?: Maybe<Contact_Availability_Min_Fields>;
  stddev?: Maybe<Contact_Availability_Stddev_Fields>;
  stddev_pop?: Maybe<Contact_Availability_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contact_Availability_Stddev_Samp_Fields>;
  sum?: Maybe<Contact_Availability_Sum_Fields>;
  var_pop?: Maybe<Contact_Availability_Var_Pop_Fields>;
  var_samp?: Maybe<Contact_Availability_Var_Samp_Fields>;
  variance?: Maybe<Contact_Availability_Variance_Fields>;
};


/** aggregate fields of "contact_availability" */
export type Contact_Availability_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contact_Availability_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contact_availability" */
export type Contact_Availability_Aggregate_Order_By = {
  avg?: InputMaybe<Contact_Availability_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contact_Availability_Max_Order_By>;
  min?: InputMaybe<Contact_Availability_Min_Order_By>;
  stddev?: InputMaybe<Contact_Availability_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Contact_Availability_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Contact_Availability_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Contact_Availability_Sum_Order_By>;
  var_pop?: InputMaybe<Contact_Availability_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Contact_Availability_Var_Samp_Order_By>;
  variance?: InputMaybe<Contact_Availability_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contact_availability" */
export type Contact_Availability_Arr_Rel_Insert_Input = {
  data: Array<Contact_Availability_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contact_Availability_On_Conflict>;
};

/** aggregate avg on columns */
export type Contact_Availability_Avg_Fields = {
  __typename: 'contact_availability_avg_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contact_availability" */
export type Contact_Availability_Avg_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contact_availability". All fields are combined with a logical 'AND'. */
export type Contact_Availability_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Contact_Availability_Bool_Exp>>;
  _not?: InputMaybe<Contact_Availability_Bool_Exp>;
  _or?: InputMaybe<Array<Contact_Availability_Bool_Exp>>;
  contact?: InputMaybe<Contacts_Bool_Exp>;
  contactAvailabilityRanges?: InputMaybe<Contact_Availability_Ranges_Bool_Exp>;
  contactAvailabilityRanges_aggregate?: InputMaybe<Contact_Availability_Ranges_Aggregate_Bool_Exp>;
  contactId?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
  workingDay?: InputMaybe<Working_Days_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_availability" */
export enum Contact_Availability_Constraint {
  /** unique or primary key constraint on columns "working_day", "contact_id" */
  ContactAvailabilityContactIdWorkingDayKey = 'contact_availability_contact_id_working_day_key',
  /** unique or primary key constraint on columns "id" */
  ContactAvailabilityPkey = 'contact_availability_pkey'
}

/** input type for incrementing numeric columns in table "contact_availability" */
export type Contact_Availability_Inc_Input = {
  contactId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "contact_availability" */
export type Contact_Availability_Insert_Input = {
  contact?: InputMaybe<Contacts_Obj_Rel_Insert_Input>;
  contactAvailabilityRanges?: InputMaybe<Contact_Availability_Ranges_Arr_Rel_Insert_Input>;
  contactId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  workingDay?: InputMaybe<Working_Days_Enum>;
};

/** aggregate max on columns */
export type Contact_Availability_Max_Fields = {
  __typename: 'contact_availability_max_fields';
  contactId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "contact_availability" */
export type Contact_Availability_Max_Order_By = {
  contactId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contact_Availability_Min_Fields = {
  __typename: 'contact_availability_min_fields';
  contactId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "contact_availability" */
export type Contact_Availability_Min_Order_By = {
  contactId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contact_availability" */
export type Contact_Availability_Mutation_Response = {
  __typename: 'contact_availability_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Availability>;
};

/** input type for inserting object relation for remote table "contact_availability" */
export type Contact_Availability_Obj_Rel_Insert_Input = {
  data: Contact_Availability_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Contact_Availability_On_Conflict>;
};

/** on_conflict condition type for table "contact_availability" */
export type Contact_Availability_On_Conflict = {
  constraint: Contact_Availability_Constraint;
  update_columns?: Array<Contact_Availability_Update_Column>;
  where?: InputMaybe<Contact_Availability_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_availability". */
export type Contact_Availability_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  contact?: InputMaybe<Contacts_Order_By>;
  contactAvailabilityRanges_aggregate?: InputMaybe<Contact_Availability_Ranges_Aggregate_Order_By>;
  contactId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  workingDay?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contact_availability */
export type Contact_Availability_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** columns and relationships of "contact_availability_ranges" */
export type Contact_Availability_Ranges = {
  __typename: 'contact_availability_ranges';
  /** An object relationship */
  Tenant: Tenants;
  comment?: Maybe<Scalars['String']>;
  /** An object relationship */
  contactAvailability: Contact_Availability;
  contactAvailabilityId: Scalars['bigint'];
  createdAt: Scalars['timestamptz'];
  endTime: Scalars['time'];
  id: Scalars['bigint'];
  startTime: Scalars['time'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};

/** aggregated selection of "contact_availability_ranges" */
export type Contact_Availability_Ranges_Aggregate = {
  __typename: 'contact_availability_ranges_aggregate';
  aggregate?: Maybe<Contact_Availability_Ranges_Aggregate_Fields>;
  nodes: Array<Contact_Availability_Ranges>;
};

export type Contact_Availability_Ranges_Aggregate_Bool_Exp = {
  count?: InputMaybe<Contact_Availability_Ranges_Aggregate_Bool_Exp_Count>;
};

export type Contact_Availability_Ranges_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contact_Availability_Ranges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Contact_Availability_Ranges_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contact_availability_ranges" */
export type Contact_Availability_Ranges_Aggregate_Fields = {
  __typename: 'contact_availability_ranges_aggregate_fields';
  avg?: Maybe<Contact_Availability_Ranges_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Contact_Availability_Ranges_Max_Fields>;
  min?: Maybe<Contact_Availability_Ranges_Min_Fields>;
  stddev?: Maybe<Contact_Availability_Ranges_Stddev_Fields>;
  stddev_pop?: Maybe<Contact_Availability_Ranges_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contact_Availability_Ranges_Stddev_Samp_Fields>;
  sum?: Maybe<Contact_Availability_Ranges_Sum_Fields>;
  var_pop?: Maybe<Contact_Availability_Ranges_Var_Pop_Fields>;
  var_samp?: Maybe<Contact_Availability_Ranges_Var_Samp_Fields>;
  variance?: Maybe<Contact_Availability_Ranges_Variance_Fields>;
};


/** aggregate fields of "contact_availability_ranges" */
export type Contact_Availability_Ranges_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contact_Availability_Ranges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contact_availability_ranges" */
export type Contact_Availability_Ranges_Aggregate_Order_By = {
  avg?: InputMaybe<Contact_Availability_Ranges_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contact_Availability_Ranges_Max_Order_By>;
  min?: InputMaybe<Contact_Availability_Ranges_Min_Order_By>;
  stddev?: InputMaybe<Contact_Availability_Ranges_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Contact_Availability_Ranges_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Contact_Availability_Ranges_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Contact_Availability_Ranges_Sum_Order_By>;
  var_pop?: InputMaybe<Contact_Availability_Ranges_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Contact_Availability_Ranges_Var_Samp_Order_By>;
  variance?: InputMaybe<Contact_Availability_Ranges_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contact_availability_ranges" */
export type Contact_Availability_Ranges_Arr_Rel_Insert_Input = {
  data: Array<Contact_Availability_Ranges_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contact_Availability_Ranges_On_Conflict>;
};

/** aggregate avg on columns */
export type Contact_Availability_Ranges_Avg_Fields = {
  __typename: 'contact_availability_ranges_avg_fields';
  contactAvailabilityId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contact_availability_ranges" */
export type Contact_Availability_Ranges_Avg_Order_By = {
  contactAvailabilityId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contact_availability_ranges". All fields are combined with a logical 'AND'. */
export type Contact_Availability_Ranges_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Contact_Availability_Ranges_Bool_Exp>>;
  _not?: InputMaybe<Contact_Availability_Ranges_Bool_Exp>;
  _or?: InputMaybe<Array<Contact_Availability_Ranges_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  contactAvailability?: InputMaybe<Contact_Availability_Bool_Exp>;
  contactAvailabilityId?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  endTime?: InputMaybe<Time_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  startTime?: InputMaybe<Time_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_availability_ranges" */
export enum Contact_Availability_Ranges_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactAvailabilityRangesPkey = 'contact_availability_ranges_pkey'
}

/** input type for incrementing numeric columns in table "contact_availability_ranges" */
export type Contact_Availability_Ranges_Inc_Input = {
  contactAvailabilityId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "contact_availability_ranges" */
export type Contact_Availability_Ranges_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  contactAvailability?: InputMaybe<Contact_Availability_Obj_Rel_Insert_Input>;
  contactAvailabilityId?: InputMaybe<Scalars['bigint']>;
  endTime?: InputMaybe<Scalars['time']>;
  id?: InputMaybe<Scalars['bigint']>;
  startTime?: InputMaybe<Scalars['time']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Contact_Availability_Ranges_Max_Fields = {
  __typename: 'contact_availability_ranges_max_fields';
  comment?: Maybe<Scalars['String']>;
  contactAvailabilityId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "contact_availability_ranges" */
export type Contact_Availability_Ranges_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  contactAvailabilityId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contact_Availability_Ranges_Min_Fields = {
  __typename: 'contact_availability_ranges_min_fields';
  comment?: Maybe<Scalars['String']>;
  contactAvailabilityId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "contact_availability_ranges" */
export type Contact_Availability_Ranges_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  contactAvailabilityId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contact_availability_ranges" */
export type Contact_Availability_Ranges_Mutation_Response = {
  __typename: 'contact_availability_ranges_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Availability_Ranges>;
};

/** on_conflict condition type for table "contact_availability_ranges" */
export type Contact_Availability_Ranges_On_Conflict = {
  constraint: Contact_Availability_Ranges_Constraint;
  update_columns?: Array<Contact_Availability_Ranges_Update_Column>;
  where?: InputMaybe<Contact_Availability_Ranges_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_availability_ranges". */
export type Contact_Availability_Ranges_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  comment?: InputMaybe<Order_By>;
  contactAvailability?: InputMaybe<Contact_Availability_Order_By>;
  contactAvailabilityId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  endTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contact_availability_ranges */
export type Contact_Availability_Ranges_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "contact_availability_ranges" */
export enum Contact_Availability_Ranges_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  ContactAvailabilityId = 'contactAvailabilityId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "contact_availability_ranges" */
export type Contact_Availability_Ranges_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  contactAvailabilityId?: InputMaybe<Scalars['bigint']>;
  endTime?: InputMaybe<Scalars['time']>;
  id?: InputMaybe<Scalars['bigint']>;
  startTime?: InputMaybe<Scalars['time']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Contact_Availability_Ranges_Stddev_Fields = {
  __typename: 'contact_availability_ranges_stddev_fields';
  contactAvailabilityId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contact_availability_ranges" */
export type Contact_Availability_Ranges_Stddev_Order_By = {
  contactAvailabilityId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contact_Availability_Ranges_Stddev_Pop_Fields = {
  __typename: 'contact_availability_ranges_stddev_pop_fields';
  contactAvailabilityId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contact_availability_ranges" */
export type Contact_Availability_Ranges_Stddev_Pop_Order_By = {
  contactAvailabilityId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contact_Availability_Ranges_Stddev_Samp_Fields = {
  __typename: 'contact_availability_ranges_stddev_samp_fields';
  contactAvailabilityId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contact_availability_ranges" */
export type Contact_Availability_Ranges_Stddev_Samp_Order_By = {
  contactAvailabilityId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "contact_availability_ranges" */
export type Contact_Availability_Ranges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Availability_Ranges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Availability_Ranges_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  contactAvailabilityId?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  endTime?: InputMaybe<Scalars['time']>;
  id?: InputMaybe<Scalars['bigint']>;
  startTime?: InputMaybe<Scalars['time']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Contact_Availability_Ranges_Sum_Fields = {
  __typename: 'contact_availability_ranges_sum_fields';
  contactAvailabilityId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "contact_availability_ranges" */
export type Contact_Availability_Ranges_Sum_Order_By = {
  contactAvailabilityId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "contact_availability_ranges" */
export enum Contact_Availability_Ranges_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  ContactAvailabilityId = 'contactAvailabilityId',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  Version = 'version'
}

export type Contact_Availability_Ranges_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Contact_Availability_Ranges_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contact_Availability_Ranges_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Availability_Ranges_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Contact_Availability_Ranges_Var_Pop_Fields = {
  __typename: 'contact_availability_ranges_var_pop_fields';
  contactAvailabilityId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contact_availability_ranges" */
export type Contact_Availability_Ranges_Var_Pop_Order_By = {
  contactAvailabilityId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contact_Availability_Ranges_Var_Samp_Fields = {
  __typename: 'contact_availability_ranges_var_samp_fields';
  contactAvailabilityId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contact_availability_ranges" */
export type Contact_Availability_Ranges_Var_Samp_Order_By = {
  contactAvailabilityId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Contact_Availability_Ranges_Variance_Fields = {
  __typename: 'contact_availability_ranges_variance_fields';
  contactAvailabilityId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contact_availability_ranges" */
export type Contact_Availability_Ranges_Variance_Order_By = {
  contactAvailabilityId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** select columns of table "contact_availability" */
export enum Contact_Availability_Select_Column {
  /** column name */
  ContactId = 'contactId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version',
  /** column name */
  WorkingDay = 'workingDay'
}

/** input type for updating data in table "contact_availability" */
export type Contact_Availability_Set_Input = {
  contactId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  workingDay?: InputMaybe<Working_Days_Enum>;
};

/** aggregate stddev on columns */
export type Contact_Availability_Stddev_Fields = {
  __typename: 'contact_availability_stddev_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contact_availability" */
export type Contact_Availability_Stddev_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contact_Availability_Stddev_Pop_Fields = {
  __typename: 'contact_availability_stddev_pop_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contact_availability" */
export type Contact_Availability_Stddev_Pop_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contact_Availability_Stddev_Samp_Fields = {
  __typename: 'contact_availability_stddev_samp_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contact_availability" */
export type Contact_Availability_Stddev_Samp_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "contact_availability" */
export type Contact_Availability_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Availability_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Availability_Stream_Cursor_Value_Input = {
  contactId?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
  workingDay?: InputMaybe<Working_Days_Enum>;
};

/** aggregate sum on columns */
export type Contact_Availability_Sum_Fields = {
  __typename: 'contact_availability_sum_fields';
  contactId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "contact_availability" */
export type Contact_Availability_Sum_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "contact_availability" */
export enum Contact_Availability_Update_Column {
  /** column name */
  ContactId = 'contactId',
  /** column name */
  Id = 'id',
  /** column name */
  Version = 'version',
  /** column name */
  WorkingDay = 'workingDay'
}

export type Contact_Availability_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Contact_Availability_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contact_Availability_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Availability_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Contact_Availability_Var_Pop_Fields = {
  __typename: 'contact_availability_var_pop_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contact_availability" */
export type Contact_Availability_Var_Pop_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contact_Availability_Var_Samp_Fields = {
  __typename: 'contact_availability_var_samp_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contact_availability" */
export type Contact_Availability_Var_Samp_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Contact_Availability_Variance_Fields = {
  __typename: 'contact_availability_variance_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contact_availability" */
export type Contact_Availability_Variance_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "contact_roles" */
export type Contact_Roles = {
  __typename: 'contact_roles';
  /** An array relationship */
  contactAssignedRoles: Array<Contact_Assigned_Roles>;
  /** An aggregate relationship */
  contactAssignedRoles_aggregate: Contact_Assigned_Roles_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "contact_roles" */
export type Contact_RolesContactAssignedRolesArgs = {
  distinct_on?: InputMaybe<Array<Contact_Assigned_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Assigned_Roles_Order_By>>;
  where?: InputMaybe<Contact_Assigned_Roles_Bool_Exp>;
};


/** columns and relationships of "contact_roles" */
export type Contact_RolesContactAssignedRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Assigned_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Assigned_Roles_Order_By>>;
  where?: InputMaybe<Contact_Assigned_Roles_Bool_Exp>;
};

/** aggregated selection of "contact_roles" */
export type Contact_Roles_Aggregate = {
  __typename: 'contact_roles_aggregate';
  aggregate?: Maybe<Contact_Roles_Aggregate_Fields>;
  nodes: Array<Contact_Roles>;
};

/** aggregate fields of "contact_roles" */
export type Contact_Roles_Aggregate_Fields = {
  __typename: 'contact_roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Contact_Roles_Max_Fields>;
  min?: Maybe<Contact_Roles_Min_Fields>;
};


/** aggregate fields of "contact_roles" */
export type Contact_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contact_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contact_roles". All fields are combined with a logical 'AND'. */
export type Contact_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Contact_Roles_Bool_Exp>>;
  _not?: InputMaybe<Contact_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Contact_Roles_Bool_Exp>>;
  contactAssignedRoles?: InputMaybe<Contact_Assigned_Roles_Bool_Exp>;
  contactAssignedRoles_aggregate?: InputMaybe<Contact_Assigned_Roles_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Contact_Roles_Enum {
  Delivery = 'DELIVERY',
  Invoice = 'INVOICE',
  It = 'IT',
  Management = 'MANAGEMENT',
  Support = 'SUPPORT'
}

/** Boolean expression to compare columns of type "contact_roles_enum". All fields are combined with logical 'AND'. */
export type Contact_Roles_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Contact_Roles_Enum>;
  _in?: InputMaybe<Array<Contact_Roles_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Contact_Roles_Enum>;
  _nin?: InputMaybe<Array<Contact_Roles_Enum>>;
};

/** aggregate max on columns */
export type Contact_Roles_Max_Fields = {
  __typename: 'contact_roles_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Contact_Roles_Min_Fields = {
  __typename: 'contact_roles_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "contact_roles". */
export type Contact_Roles_Order_By = {
  contactAssignedRoles_aggregate?: InputMaybe<Contact_Assigned_Roles_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "contact_roles" */
export enum Contact_Roles_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "contact_roles" */
export type Contact_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Roles_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "contacts" */
export type Contacts = {
  __typename: 'contacts';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  contactAssignedRoles: Array<Contact_Assigned_Roles>;
  /** An aggregate relationship */
  contactAssignedRoles_aggregate: Contact_Assigned_Roles_Aggregate;
  /** An array relationship */
  contactAvailabilities: Array<Contact_Availability>;
  /** An aggregate relationship */
  contactAvailabilities_aggregate: Contact_Availability_Aggregate;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customer?: Maybe<Customers>;
  customerId?: Maybe<Scalars['bigint']>;
  domainId: Scalars['String'];
  /** An array relationship */
  emails: Array<Emails>;
  /** An aggregate relationship */
  emails_aggregate: Emails_Aggregate;
  gender?: Maybe<Gender_Enum>;
  givenNames?: Maybe<Scalars['String']>;
  /** An array relationship */
  globalSearchResults: Array<Global_Search_Result>;
  /** An aggregate relationship */
  globalSearchResults_aggregate: Global_Search_Result_Aggregate;
  id: Scalars['bigint'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  ordinalPosition: Scalars['Int'];
  /** An array relationship */
  phones: Array<Phones>;
  /** An aggregate relationship */
  phones_aggregate: Phones_Aggregate;
  /** An object relationship */
  supplier?: Maybe<Suppliers>;
  supplierId?: Maybe<Scalars['bigint']>;
  surname?: Maybe<Scalars['String']>;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "contacts" */
export type ContactsContactAssignedRolesArgs = {
  distinct_on?: InputMaybe<Array<Contact_Assigned_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Assigned_Roles_Order_By>>;
  where?: InputMaybe<Contact_Assigned_Roles_Bool_Exp>;
};


/** columns and relationships of "contacts" */
export type ContactsContactAssignedRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Assigned_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Assigned_Roles_Order_By>>;
  where?: InputMaybe<Contact_Assigned_Roles_Bool_Exp>;
};


/** columns and relationships of "contacts" */
export type ContactsContactAvailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<Contact_Availability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Availability_Order_By>>;
  where?: InputMaybe<Contact_Availability_Bool_Exp>;
};


/** columns and relationships of "contacts" */
export type ContactsContactAvailabilities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Availability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Availability_Order_By>>;
  where?: InputMaybe<Contact_Availability_Bool_Exp>;
};


/** columns and relationships of "contacts" */
export type ContactsEmailsArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


/** columns and relationships of "contacts" */
export type ContactsEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


/** columns and relationships of "contacts" */
export type ContactsGlobalSearchResultsArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


/** columns and relationships of "contacts" */
export type ContactsGlobalSearchResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


/** columns and relationships of "contacts" */
export type ContactsPhonesArgs = {
  distinct_on?: InputMaybe<Array<Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phones_Order_By>>;
  where?: InputMaybe<Phones_Bool_Exp>;
};


/** columns and relationships of "contacts" */
export type ContactsPhones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phones_Order_By>>;
  where?: InputMaybe<Phones_Bool_Exp>;
};

/** aggregated selection of "contacts" */
export type Contacts_Aggregate = {
  __typename: 'contacts_aggregate';
  aggregate?: Maybe<Contacts_Aggregate_Fields>;
  nodes: Array<Contacts>;
};

export type Contacts_Aggregate_Bool_Exp = {
  count?: InputMaybe<Contacts_Aggregate_Bool_Exp_Count>;
};

export type Contacts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contacts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Contacts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contacts" */
export type Contacts_Aggregate_Fields = {
  __typename: 'contacts_aggregate_fields';
  avg?: Maybe<Contacts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Contacts_Max_Fields>;
  min?: Maybe<Contacts_Min_Fields>;
  stddev?: Maybe<Contacts_Stddev_Fields>;
  stddev_pop?: Maybe<Contacts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contacts_Stddev_Samp_Fields>;
  sum?: Maybe<Contacts_Sum_Fields>;
  var_pop?: Maybe<Contacts_Var_Pop_Fields>;
  var_samp?: Maybe<Contacts_Var_Samp_Fields>;
  variance?: Maybe<Contacts_Variance_Fields>;
};


/** aggregate fields of "contacts" */
export type Contacts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contacts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contacts" */
export type Contacts_Aggregate_Order_By = {
  avg?: InputMaybe<Contacts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contacts_Max_Order_By>;
  min?: InputMaybe<Contacts_Min_Order_By>;
  stddev?: InputMaybe<Contacts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Contacts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Contacts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Contacts_Sum_Order_By>;
  var_pop?: InputMaybe<Contacts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Contacts_Var_Samp_Order_By>;
  variance?: InputMaybe<Contacts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contacts" */
export type Contacts_Arr_Rel_Insert_Input = {
  data: Array<Contacts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contacts_On_Conflict>;
};

/** aggregate avg on columns */
export type Contacts_Avg_Fields = {
  __typename: 'contacts_avg_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contacts" */
export type Contacts_Avg_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contacts". All fields are combined with a logical 'AND'. */
export type Contacts_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Contacts_Bool_Exp>>;
  _not?: InputMaybe<Contacts_Bool_Exp>;
  _or?: InputMaybe<Array<Contacts_Bool_Exp>>;
  contactAssignedRoles?: InputMaybe<Contact_Assigned_Roles_Bool_Exp>;
  contactAssignedRoles_aggregate?: InputMaybe<Contact_Assigned_Roles_Aggregate_Bool_Exp>;
  contactAvailabilities?: InputMaybe<Contact_Availability_Bool_Exp>;
  contactAvailabilities_aggregate?: InputMaybe<Contact_Availability_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer?: InputMaybe<Customers_Bool_Exp>;
  customerId?: InputMaybe<Bigint_Comparison_Exp>;
  domainId?: InputMaybe<String_Comparison_Exp>;
  emails?: InputMaybe<Emails_Bool_Exp>;
  emails_aggregate?: InputMaybe<Emails_Aggregate_Bool_Exp>;
  gender?: InputMaybe<Gender_Enum_Comparison_Exp>;
  givenNames?: InputMaybe<String_Comparison_Exp>;
  globalSearchResults?: InputMaybe<Global_Search_Result_Bool_Exp>;
  globalSearchResults_aggregate?: InputMaybe<Global_Search_Result_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  phones?: InputMaybe<Phones_Bool_Exp>;
  phones_aggregate?: InputMaybe<Phones_Aggregate_Bool_Exp>;
  supplier?: InputMaybe<Suppliers_Bool_Exp>;
  supplierId?: InputMaybe<Bigint_Comparison_Exp>;
  surname?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "contacts" */
export enum Contacts_Constraint {
  /** unique or primary key constraint on columns "customer_id", "ordinal_position" */
  ContactsCustomerIdOrdinalPositionKey = 'contacts_customer_id_ordinal_position_key',
  /** unique or primary key constraint on columns "id" */
  ContactsPkey = 'contacts_pkey',
  /** unique or primary key constraint on columns "supplier_id", "ordinal_position" */
  ContactsSupplierIdOrdinalPositionKey = 'contacts_supplier_id_ordinal_position_key',
  /** unique or primary key constraint on columns "tenant", "domain_id" */
  ContactsTenantDomainId = 'contacts_tenant_domain_id'
}

/** input type for incrementing numeric columns in table "contacts" */
export type Contacts_Inc_Input = {
  customerId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "contacts" */
export type Contacts_Insert_Input = {
  contactAssignedRoles?: InputMaybe<Contact_Assigned_Roles_Arr_Rel_Insert_Input>;
  contactAvailabilities?: InputMaybe<Contact_Availability_Arr_Rel_Insert_Input>;
  customer?: InputMaybe<Customers_Obj_Rel_Insert_Input>;
  customerId?: InputMaybe<Scalars['bigint']>;
  emails?: InputMaybe<Emails_Arr_Rel_Insert_Input>;
  gender?: InputMaybe<Gender_Enum>;
  givenNames?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  phones?: InputMaybe<Phones_Arr_Rel_Insert_Input>;
  supplier?: InputMaybe<Suppliers_Obj_Rel_Insert_Input>;
  supplierId?: InputMaybe<Scalars['bigint']>;
  surname?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Contacts_Max_Fields = {
  __typename: 'contacts_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerId?: Maybe<Scalars['bigint']>;
  domainId?: Maybe<Scalars['String']>;
  givenNames?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  supplierId?: Maybe<Scalars['bigint']>;
  surname?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "contacts" */
export type Contacts_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  givenNames?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  surname?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contacts_Min_Fields = {
  __typename: 'contacts_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerId?: Maybe<Scalars['bigint']>;
  domainId?: Maybe<Scalars['String']>;
  givenNames?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  supplierId?: Maybe<Scalars['bigint']>;
  surname?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "contacts" */
export type Contacts_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  givenNames?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  surname?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contacts" */
export type Contacts_Mutation_Response = {
  __typename: 'contacts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contacts>;
};

/** input type for inserting object relation for remote table "contacts" */
export type Contacts_Obj_Rel_Insert_Input = {
  data: Contacts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Contacts_On_Conflict>;
};

/** on_conflict condition type for table "contacts" */
export type Contacts_On_Conflict = {
  constraint: Contacts_Constraint;
  update_columns?: Array<Contacts_Update_Column>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

/** Ordering options when selecting data from "contacts". */
export type Contacts_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  contactAssignedRoles_aggregate?: InputMaybe<Contact_Assigned_Roles_Aggregate_Order_By>;
  contactAvailabilities_aggregate?: InputMaybe<Contact_Availability_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customer?: InputMaybe<Customers_Order_By>;
  customerId?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  emails_aggregate?: InputMaybe<Emails_Aggregate_Order_By>;
  gender?: InputMaybe<Order_By>;
  givenNames?: InputMaybe<Order_By>;
  globalSearchResults_aggregate?: InputMaybe<Global_Search_Result_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  phones_aggregate?: InputMaybe<Phones_Aggregate_Order_By>;
  supplier?: InputMaybe<Suppliers_Order_By>;
  supplierId?: InputMaybe<Order_By>;
  surname?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contacts */
export type Contacts_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "contacts" */
export enum Contacts_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  DomainId = 'domainId',
  /** column name */
  Gender = 'gender',
  /** column name */
  GivenNames = 'givenNames',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  SupplierId = 'supplierId',
  /** column name */
  Surname = 'surname',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "contacts" */
export type Contacts_Set_Input = {
  customerId?: InputMaybe<Scalars['bigint']>;
  gender?: InputMaybe<Gender_Enum>;
  givenNames?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['bigint']>;
  surname?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Contacts_Stddev_Fields = {
  __typename: 'contacts_stddev_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contacts" */
export type Contacts_Stddev_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contacts_Stddev_Pop_Fields = {
  __typename: 'contacts_stddev_pop_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contacts" */
export type Contacts_Stddev_Pop_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contacts_Stddev_Samp_Fields = {
  __typename: 'contacts_stddev_samp_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contacts" */
export type Contacts_Stddev_Samp_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "contacts" */
export type Contacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contacts_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerId?: InputMaybe<Scalars['bigint']>;
  domainId?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender_Enum>;
  givenNames?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['bigint']>;
  surname?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Contacts_Sum_Fields = {
  __typename: 'contacts_sum_fields';
  customerId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  supplierId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "contacts" */
export type Contacts_Sum_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "contacts" */
export enum Contacts_Update_Column {
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Gender = 'gender',
  /** column name */
  GivenNames = 'givenNames',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  SupplierId = 'supplierId',
  /** column name */
  Surname = 'surname',
  /** column name */
  Version = 'version'
}

export type Contacts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Contacts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contacts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Contacts_Var_Pop_Fields = {
  __typename: 'contacts_var_pop_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contacts" */
export type Contacts_Var_Pop_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contacts_Var_Samp_Fields = {
  __typename: 'contacts_var_samp_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contacts" */
export type Contacts_Var_Samp_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Contacts_Variance_Fields = {
  __typename: 'contacts_variance_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contacts" */
export type Contacts_Variance_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

export type Copy_Customer_Order_Args = {
  _delivery_date?: InputMaybe<Scalars['date']>;
  _id?: InputMaybe<Scalars['bigint']>;
};

export type Copy_Product_Args = {
  _id?: InputMaybe<Scalars['bigint']>;
  _suffix?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "cost_price_average_periods" */
export type Cost_Price_Average_Periods = {
  __typename: 'cost_price_average_periods';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  days: Scalars['smallint'];
  id: Scalars['bigint'];
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "cost_price_average_periods" */
export type Cost_Price_Average_PeriodsProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


/** columns and relationships of "cost_price_average_periods" */
export type Cost_Price_Average_PeriodsProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};

/** aggregated selection of "cost_price_average_periods" */
export type Cost_Price_Average_Periods_Aggregate = {
  __typename: 'cost_price_average_periods_aggregate';
  aggregate?: Maybe<Cost_Price_Average_Periods_Aggregate_Fields>;
  nodes: Array<Cost_Price_Average_Periods>;
};

export type Cost_Price_Average_Periods_Aggregate_Bool_Exp = {
  count?: InputMaybe<Cost_Price_Average_Periods_Aggregate_Bool_Exp_Count>;
};

export type Cost_Price_Average_Periods_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Cost_Price_Average_Periods_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cost_Price_Average_Periods_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "cost_price_average_periods" */
export type Cost_Price_Average_Periods_Aggregate_Fields = {
  __typename: 'cost_price_average_periods_aggregate_fields';
  avg?: Maybe<Cost_Price_Average_Periods_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Cost_Price_Average_Periods_Max_Fields>;
  min?: Maybe<Cost_Price_Average_Periods_Min_Fields>;
  stddev?: Maybe<Cost_Price_Average_Periods_Stddev_Fields>;
  stddev_pop?: Maybe<Cost_Price_Average_Periods_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cost_Price_Average_Periods_Stddev_Samp_Fields>;
  sum?: Maybe<Cost_Price_Average_Periods_Sum_Fields>;
  var_pop?: Maybe<Cost_Price_Average_Periods_Var_Pop_Fields>;
  var_samp?: Maybe<Cost_Price_Average_Periods_Var_Samp_Fields>;
  variance?: Maybe<Cost_Price_Average_Periods_Variance_Fields>;
};


/** aggregate fields of "cost_price_average_periods" */
export type Cost_Price_Average_Periods_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cost_Price_Average_Periods_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cost_price_average_periods" */
export type Cost_Price_Average_Periods_Aggregate_Order_By = {
  avg?: InputMaybe<Cost_Price_Average_Periods_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Cost_Price_Average_Periods_Max_Order_By>;
  min?: InputMaybe<Cost_Price_Average_Periods_Min_Order_By>;
  stddev?: InputMaybe<Cost_Price_Average_Periods_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Cost_Price_Average_Periods_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Cost_Price_Average_Periods_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Cost_Price_Average_Periods_Sum_Order_By>;
  var_pop?: InputMaybe<Cost_Price_Average_Periods_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Cost_Price_Average_Periods_Var_Samp_Order_By>;
  variance?: InputMaybe<Cost_Price_Average_Periods_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Cost_Price_Average_Periods_Avg_Fields = {
  __typename: 'cost_price_average_periods_avg_fields';
  days?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cost_price_average_periods" */
export type Cost_Price_Average_Periods_Avg_Order_By = {
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cost_price_average_periods". All fields are combined with a logical 'AND'. */
export type Cost_Price_Average_Periods_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Cost_Price_Average_Periods_Bool_Exp>>;
  _not?: InputMaybe<Cost_Price_Average_Periods_Bool_Exp>;
  _or?: InputMaybe<Array<Cost_Price_Average_Periods_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  days?: InputMaybe<Smallint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  products?: InputMaybe<Products_Bool_Exp>;
  products_aggregate?: InputMaybe<Products_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Cost_Price_Average_Periods_Max_Fields = {
  __typename: 'cost_price_average_periods_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  days?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "cost_price_average_periods" */
export type Cost_Price_Average_Periods_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Cost_Price_Average_Periods_Min_Fields = {
  __typename: 'cost_price_average_periods_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  days?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "cost_price_average_periods" */
export type Cost_Price_Average_Periods_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "cost_price_average_periods". */
export type Cost_Price_Average_Periods_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  products_aggregate?: InputMaybe<Products_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** select columns of table "cost_price_average_periods" */
export enum Cost_Price_Average_Periods_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Days = 'days',
  /** column name */
  Id = 'id',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** aggregate stddev on columns */
export type Cost_Price_Average_Periods_Stddev_Fields = {
  __typename: 'cost_price_average_periods_stddev_fields';
  days?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cost_price_average_periods" */
export type Cost_Price_Average_Periods_Stddev_Order_By = {
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cost_Price_Average_Periods_Stddev_Pop_Fields = {
  __typename: 'cost_price_average_periods_stddev_pop_fields';
  days?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cost_price_average_periods" */
export type Cost_Price_Average_Periods_Stddev_Pop_Order_By = {
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cost_Price_Average_Periods_Stddev_Samp_Fields = {
  __typename: 'cost_price_average_periods_stddev_samp_fields';
  days?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cost_price_average_periods" */
export type Cost_Price_Average_Periods_Stddev_Samp_Order_By = {
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "cost_price_average_periods" */
export type Cost_Price_Average_Periods_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cost_Price_Average_Periods_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cost_Price_Average_Periods_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  days?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Cost_Price_Average_Periods_Sum_Fields = {
  __typename: 'cost_price_average_periods_sum_fields';
  days?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "cost_price_average_periods" */
export type Cost_Price_Average_Periods_Sum_Order_By = {
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Cost_Price_Average_Periods_Var_Pop_Fields = {
  __typename: 'cost_price_average_periods_var_pop_fields';
  days?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cost_price_average_periods" */
export type Cost_Price_Average_Periods_Var_Pop_Order_By = {
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cost_Price_Average_Periods_Var_Samp_Fields = {
  __typename: 'cost_price_average_periods_var_samp_fields';
  days?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cost_price_average_periods" */
export type Cost_Price_Average_Periods_Var_Samp_Order_By = {
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Cost_Price_Average_Periods_Variance_Fields = {
  __typename: 'cost_price_average_periods_variance_fields';
  days?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cost_price_average_periods" */
export type Cost_Price_Average_Periods_Variance_Order_By = {
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

export type Create_Internal_Order_For_Dismantling_Plan_Args = {
  dismantling_plan_id?: InputMaybe<Scalars['bigint']>;
  internal_customer_id?: InputMaybe<Scalars['bigint']>;
  payload?: InputMaybe<Scalars['json']>;
};

export type Create_Partial_Delivery_Args = {
  _customer_order_id?: InputMaybe<Scalars['bigint']>;
  _picked_item_ids?: InputMaybe<Scalars['json']>;
  _target_account_id?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "credit_note_item_logged_actions" */
export type Credit_Note_Item_Logged_Actions = {
  __typename: 'credit_note_item_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "credit_note_item_logged_actions" */
export type Credit_Note_Item_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "credit_note_item_logged_actions" */
export type Credit_Note_Item_Logged_Actions_Aggregate = {
  __typename: 'credit_note_item_logged_actions_aggregate';
  aggregate?: Maybe<Credit_Note_Item_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Credit_Note_Item_Logged_Actions>;
};

/** aggregate fields of "credit_note_item_logged_actions" */
export type Credit_Note_Item_Logged_Actions_Aggregate_Fields = {
  __typename: 'credit_note_item_logged_actions_aggregate_fields';
  avg?: Maybe<Credit_Note_Item_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Credit_Note_Item_Logged_Actions_Max_Fields>;
  min?: Maybe<Credit_Note_Item_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Credit_Note_Item_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Credit_Note_Item_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Credit_Note_Item_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Credit_Note_Item_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Credit_Note_Item_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Credit_Note_Item_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Credit_Note_Item_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "credit_note_item_logged_actions" */
export type Credit_Note_Item_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Credit_Note_Item_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Credit_Note_Item_Logged_Actions_Avg_Fields = {
  __typename: 'credit_note_item_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "credit_note_item_logged_actions". All fields are combined with a logical 'AND'. */
export type Credit_Note_Item_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Credit_Note_Item_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Credit_Note_Item_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Credit_Note_Item_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Credit_Note_Item_Logged_Actions_Max_Fields = {
  __typename: 'credit_note_item_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Credit_Note_Item_Logged_Actions_Min_Fields = {
  __typename: 'credit_note_item_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "credit_note_item_logged_actions". */
export type Credit_Note_Item_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "credit_note_item_logged_actions" */
export enum Credit_Note_Item_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Role = 'role',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Credit_Note_Item_Logged_Actions_Stddev_Fields = {
  __typename: 'credit_note_item_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Credit_Note_Item_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'credit_note_item_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Credit_Note_Item_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'credit_note_item_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "credit_note_item_logged_actions" */
export type Credit_Note_Item_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Credit_Note_Item_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Credit_Note_Item_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  role?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Credit_Note_Item_Logged_Actions_Sum_Fields = {
  __typename: 'credit_note_item_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Credit_Note_Item_Logged_Actions_Var_Pop_Fields = {
  __typename: 'credit_note_item_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Credit_Note_Item_Logged_Actions_Var_Samp_Fields = {
  __typename: 'credit_note_item_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Credit_Note_Item_Logged_Actions_Variance_Fields = {
  __typename: 'credit_note_item_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "credit_note_items" */
export type Credit_Note_Items = {
  __typename: 'credit_note_items';
  /** An object relationship */
  Tenant: Tenants;
  amount: Scalars['bigint'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  creditNote: Credit_Notes;
  creditNoteId: Scalars['bigint'];
  currency: Currencies_Enum;
  description: Scalars['String'];
  id: Scalars['bigint'];
  /** An object relationship */
  product?: Maybe<Products>;
  productId?: Maybe<Scalars['bigint']>;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** Returns the correct VAT % using the combination of the credit date and VAT type of the item. */
  vatPct?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  vatType: Vat_Types;
  vatTypeId: Scalars['bigint'];
  version: Scalars['bigint'];
};

/** aggregated selection of "credit_note_items" */
export type Credit_Note_Items_Aggregate = {
  __typename: 'credit_note_items_aggregate';
  aggregate?: Maybe<Credit_Note_Items_Aggregate_Fields>;
  nodes: Array<Credit_Note_Items>;
};

export type Credit_Note_Items_Aggregate_Bool_Exp = {
  count?: InputMaybe<Credit_Note_Items_Aggregate_Bool_Exp_Count>;
};

export type Credit_Note_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Credit_Note_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Credit_Note_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "credit_note_items" */
export type Credit_Note_Items_Aggregate_Fields = {
  __typename: 'credit_note_items_aggregate_fields';
  avg?: Maybe<Credit_Note_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Credit_Note_Items_Max_Fields>;
  min?: Maybe<Credit_Note_Items_Min_Fields>;
  stddev?: Maybe<Credit_Note_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Credit_Note_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Credit_Note_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Credit_Note_Items_Sum_Fields>;
  var_pop?: Maybe<Credit_Note_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Credit_Note_Items_Var_Samp_Fields>;
  variance?: Maybe<Credit_Note_Items_Variance_Fields>;
};


/** aggregate fields of "credit_note_items" */
export type Credit_Note_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Credit_Note_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "credit_note_items" */
export type Credit_Note_Items_Aggregate_Order_By = {
  avg?: InputMaybe<Credit_Note_Items_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Credit_Note_Items_Max_Order_By>;
  min?: InputMaybe<Credit_Note_Items_Min_Order_By>;
  stddev?: InputMaybe<Credit_Note_Items_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Credit_Note_Items_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Credit_Note_Items_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Credit_Note_Items_Sum_Order_By>;
  var_pop?: InputMaybe<Credit_Note_Items_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Credit_Note_Items_Var_Samp_Order_By>;
  variance?: InputMaybe<Credit_Note_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "credit_note_items" */
export type Credit_Note_Items_Arr_Rel_Insert_Input = {
  data: Array<Credit_Note_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Credit_Note_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Credit_Note_Items_Avg_Fields = {
  __typename: 'credit_note_items_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "credit_note_items" */
export type Credit_Note_Items_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "credit_note_items". All fields are combined with a logical 'AND'. */
export type Credit_Note_Items_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Credit_Note_Items_Bool_Exp>>;
  _not?: InputMaybe<Credit_Note_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Credit_Note_Items_Bool_Exp>>;
  amount?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  creditNote?: InputMaybe<Credit_Notes_Bool_Exp>;
  creditNoteId?: InputMaybe<Bigint_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  vatPct?: InputMaybe<Numeric_Comparison_Exp>;
  vatType?: InputMaybe<Vat_Types_Bool_Exp>;
  vatTypeId?: InputMaybe<Bigint_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "credit_note_items" */
export enum Credit_Note_Items_Constraint {
  /** unique or primary key constraint on columns "id" */
  CreditNoteItemsPkey = 'credit_note_items_pkey'
}

/** input type for incrementing numeric columns in table "credit_note_items" */
export type Credit_Note_Items_Inc_Input = {
  amount?: InputMaybe<Scalars['bigint']>;
  creditNoteId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  vatTypeId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "credit_note_items" */
export type Credit_Note_Items_Insert_Input = {
  amount?: InputMaybe<Scalars['bigint']>;
  creditNote?: InputMaybe<Credit_Notes_Obj_Rel_Insert_Input>;
  creditNoteId?: InputMaybe<Scalars['bigint']>;
  currency?: InputMaybe<Currencies_Enum>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  vatTypeId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Credit_Note_Items_Max_Fields = {
  __typename: 'credit_note_items_max_fields';
  amount?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creditNoteId?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vatTypeId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "credit_note_items" */
export type Credit_Note_Items_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Credit_Note_Items_Min_Fields = {
  __typename: 'credit_note_items_min_fields';
  amount?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creditNoteId?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vatTypeId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "credit_note_items" */
export type Credit_Note_Items_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "credit_note_items" */
export type Credit_Note_Items_Mutation_Response = {
  __typename: 'credit_note_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Credit_Note_Items>;
};

/** on_conflict condition type for table "credit_note_items" */
export type Credit_Note_Items_On_Conflict = {
  constraint: Credit_Note_Items_Constraint;
  update_columns?: Array<Credit_Note_Items_Update_Column>;
  where?: InputMaybe<Credit_Note_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "credit_note_items". */
export type Credit_Note_Items_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creditNote?: InputMaybe<Credit_Notes_Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatType?: InputMaybe<Vat_Types_Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: credit_note_items */
export type Credit_Note_Items_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "credit_note_items" */
export enum Credit_Note_Items_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreditNoteId = 'creditNoteId',
  /** column name */
  Currency = 'currency',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VatTypeId = 'vatTypeId',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "credit_note_items" */
export type Credit_Note_Items_Set_Input = {
  amount?: InputMaybe<Scalars['bigint']>;
  creditNoteId?: InputMaybe<Scalars['bigint']>;
  currency?: InputMaybe<Currencies_Enum>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  vatTypeId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Credit_Note_Items_Stddev_Fields = {
  __typename: 'credit_note_items_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "credit_note_items" */
export type Credit_Note_Items_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Credit_Note_Items_Stddev_Pop_Fields = {
  __typename: 'credit_note_items_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "credit_note_items" */
export type Credit_Note_Items_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Credit_Note_Items_Stddev_Samp_Fields = {
  __typename: 'credit_note_items_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "credit_note_items" */
export type Credit_Note_Items_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "credit_note_items" */
export type Credit_Note_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Credit_Note_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Credit_Note_Items_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  creditNoteId?: InputMaybe<Scalars['bigint']>;
  currency?: InputMaybe<Currencies_Enum>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vatTypeId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Credit_Note_Items_Sum_Fields = {
  __typename: 'credit_note_items_sum_fields';
  amount?: Maybe<Scalars['bigint']>;
  creditNoteId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  vatTypeId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "credit_note_items" */
export type Credit_Note_Items_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "credit_note_items" */
export enum Credit_Note_Items_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreditNoteId = 'creditNoteId',
  /** column name */
  Currency = 'currency',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  VatTypeId = 'vatTypeId',
  /** column name */
  Version = 'version'
}

export type Credit_Note_Items_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Credit_Note_Items_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Credit_Note_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Credit_Note_Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Credit_Note_Items_Var_Pop_Fields = {
  __typename: 'credit_note_items_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "credit_note_items" */
export type Credit_Note_Items_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Credit_Note_Items_Var_Samp_Fields = {
  __typename: 'credit_note_items_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "credit_note_items" */
export type Credit_Note_Items_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Credit_Note_Items_Variance_Fields = {
  __typename: 'credit_note_items_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "credit_note_items" */
export type Credit_Note_Items_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "credit_note_logged_actions" */
export type Credit_Note_Logged_Actions = {
  __typename: 'credit_note_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "credit_note_logged_actions" */
export type Credit_Note_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "credit_note_logged_actions" */
export type Credit_Note_Logged_Actions_Aggregate = {
  __typename: 'credit_note_logged_actions_aggregate';
  aggregate?: Maybe<Credit_Note_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Credit_Note_Logged_Actions>;
};

/** aggregate fields of "credit_note_logged_actions" */
export type Credit_Note_Logged_Actions_Aggregate_Fields = {
  __typename: 'credit_note_logged_actions_aggregate_fields';
  avg?: Maybe<Credit_Note_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Credit_Note_Logged_Actions_Max_Fields>;
  min?: Maybe<Credit_Note_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Credit_Note_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Credit_Note_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Credit_Note_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Credit_Note_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Credit_Note_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Credit_Note_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Credit_Note_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "credit_note_logged_actions" */
export type Credit_Note_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Credit_Note_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Credit_Note_Logged_Actions_Avg_Fields = {
  __typename: 'credit_note_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "credit_note_logged_actions". All fields are combined with a logical 'AND'. */
export type Credit_Note_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Credit_Note_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Credit_Note_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Credit_Note_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Credit_Note_Logged_Actions_Max_Fields = {
  __typename: 'credit_note_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Credit_Note_Logged_Actions_Min_Fields = {
  __typename: 'credit_note_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "credit_note_logged_actions". */
export type Credit_Note_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "credit_note_logged_actions" */
export enum Credit_Note_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Role = 'role',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Credit_Note_Logged_Actions_Stddev_Fields = {
  __typename: 'credit_note_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Credit_Note_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'credit_note_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Credit_Note_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'credit_note_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "credit_note_logged_actions" */
export type Credit_Note_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Credit_Note_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Credit_Note_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  role?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Credit_Note_Logged_Actions_Sum_Fields = {
  __typename: 'credit_note_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Credit_Note_Logged_Actions_Var_Pop_Fields = {
  __typename: 'credit_note_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Credit_Note_Logged_Actions_Var_Samp_Fields = {
  __typename: 'credit_note_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Credit_Note_Logged_Actions_Variance_Fields = {
  __typename: 'credit_note_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "credit_note_state_transitions" */
export type Credit_Note_State_Transitions = {
  __typename: 'credit_note_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Credit_Note_States_Enum;
  toState: Credit_Note_States_Enum;
};

/** aggregated selection of "credit_note_state_transitions" */
export type Credit_Note_State_Transitions_Aggregate = {
  __typename: 'credit_note_state_transitions_aggregate';
  aggregate?: Maybe<Credit_Note_State_Transitions_Aggregate_Fields>;
  nodes: Array<Credit_Note_State_Transitions>;
};

/** aggregate fields of "credit_note_state_transitions" */
export type Credit_Note_State_Transitions_Aggregate_Fields = {
  __typename: 'credit_note_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Credit_Note_State_Transitions_Max_Fields>;
  min?: Maybe<Credit_Note_State_Transitions_Min_Fields>;
};


/** aggregate fields of "credit_note_state_transitions" */
export type Credit_Note_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Credit_Note_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "credit_note_state_transitions". All fields are combined with a logical 'AND'. */
export type Credit_Note_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Credit_Note_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Credit_Note_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Credit_Note_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Credit_Note_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Credit_Note_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Credit_Note_State_Transitions_Max_Fields = {
  __typename: 'credit_note_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Credit_Note_State_Transitions_Min_Fields = {
  __typename: 'credit_note_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "credit_note_state_transitions". */
export type Credit_Note_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "credit_note_state_transitions" */
export enum Credit_Note_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "credit_note_state_transitions" */
export type Credit_Note_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Credit_Note_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Credit_Note_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Credit_Note_States_Enum>;
  toState?: InputMaybe<Credit_Note_States_Enum>;
};

/** columns and relationships of "credit_note_states" */
export type Credit_Note_States = {
  __typename: 'credit_note_states';
  /** An array relationship */
  creditNotes: Array<Credit_Notes>;
  /** An aggregate relationship */
  creditNotes_aggregate: Credit_Notes_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "credit_note_states" */
export type Credit_Note_StatesCreditNotesArgs = {
  distinct_on?: InputMaybe<Array<Credit_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Notes_Order_By>>;
  where?: InputMaybe<Credit_Notes_Bool_Exp>;
};


/** columns and relationships of "credit_note_states" */
export type Credit_Note_StatesCreditNotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Notes_Order_By>>;
  where?: InputMaybe<Credit_Notes_Bool_Exp>;
};

/** aggregated selection of "credit_note_states" */
export type Credit_Note_States_Aggregate = {
  __typename: 'credit_note_states_aggregate';
  aggregate?: Maybe<Credit_Note_States_Aggregate_Fields>;
  nodes: Array<Credit_Note_States>;
};

/** aggregate fields of "credit_note_states" */
export type Credit_Note_States_Aggregate_Fields = {
  __typename: 'credit_note_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Credit_Note_States_Max_Fields>;
  min?: Maybe<Credit_Note_States_Min_Fields>;
};


/** aggregate fields of "credit_note_states" */
export type Credit_Note_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Credit_Note_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "credit_note_states". All fields are combined with a logical 'AND'. */
export type Credit_Note_States_Bool_Exp = {
  _and?: InputMaybe<Array<Credit_Note_States_Bool_Exp>>;
  _not?: InputMaybe<Credit_Note_States_Bool_Exp>;
  _or?: InputMaybe<Array<Credit_Note_States_Bool_Exp>>;
  creditNotes?: InputMaybe<Credit_Notes_Bool_Exp>;
  creditNotes_aggregate?: InputMaybe<Credit_Notes_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Credit_Note_States_Enum {
  Canceled = 'CANCELED',
  Open = 'OPEN',
  Prepared = 'PREPARED',
  SentToCustomer = 'SENT_TO_CUSTOMER'
}

/** Boolean expression to compare columns of type "credit_note_states_enum". All fields are combined with logical 'AND'. */
export type Credit_Note_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Credit_Note_States_Enum>;
  _in?: InputMaybe<Array<Credit_Note_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Credit_Note_States_Enum>;
  _nin?: InputMaybe<Array<Credit_Note_States_Enum>>;
};

/** aggregate max on columns */
export type Credit_Note_States_Max_Fields = {
  __typename: 'credit_note_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Credit_Note_States_Min_Fields = {
  __typename: 'credit_note_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "credit_note_states". */
export type Credit_Note_States_Order_By = {
  creditNotes_aggregate?: InputMaybe<Credit_Notes_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "credit_note_states" */
export enum Credit_Note_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "credit_note_states" */
export type Credit_Note_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Credit_Note_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Credit_Note_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "credit_note_vat_summary_results" */
export type Credit_Note_Vat_Summary_Results = {
  __typename: 'credit_note_vat_summary_results';
  vatBase: Scalars['bigint'];
  vatPct: Scalars['numeric'];
  vatTotal: Scalars['bigint'];
  vatType: Scalars['String'];
};

/** aggregated selection of "credit_note_vat_summary_results" */
export type Credit_Note_Vat_Summary_Results_Aggregate = {
  __typename: 'credit_note_vat_summary_results_aggregate';
  aggregate?: Maybe<Credit_Note_Vat_Summary_Results_Aggregate_Fields>;
  nodes: Array<Credit_Note_Vat_Summary_Results>;
};

/** aggregate fields of "credit_note_vat_summary_results" */
export type Credit_Note_Vat_Summary_Results_Aggregate_Fields = {
  __typename: 'credit_note_vat_summary_results_aggregate_fields';
  avg?: Maybe<Credit_Note_Vat_Summary_Results_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Credit_Note_Vat_Summary_Results_Max_Fields>;
  min?: Maybe<Credit_Note_Vat_Summary_Results_Min_Fields>;
  stddev?: Maybe<Credit_Note_Vat_Summary_Results_Stddev_Fields>;
  stddev_pop?: Maybe<Credit_Note_Vat_Summary_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Credit_Note_Vat_Summary_Results_Stddev_Samp_Fields>;
  sum?: Maybe<Credit_Note_Vat_Summary_Results_Sum_Fields>;
  var_pop?: Maybe<Credit_Note_Vat_Summary_Results_Var_Pop_Fields>;
  var_samp?: Maybe<Credit_Note_Vat_Summary_Results_Var_Samp_Fields>;
  variance?: Maybe<Credit_Note_Vat_Summary_Results_Variance_Fields>;
};


/** aggregate fields of "credit_note_vat_summary_results" */
export type Credit_Note_Vat_Summary_Results_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Credit_Note_Vat_Summary_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "credit_note_vat_summary_results" */
export type Credit_Note_Vat_Summary_Results_Aggregate_Order_By = {
  avg?: InputMaybe<Credit_Note_Vat_Summary_Results_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Credit_Note_Vat_Summary_Results_Max_Order_By>;
  min?: InputMaybe<Credit_Note_Vat_Summary_Results_Min_Order_By>;
  stddev?: InputMaybe<Credit_Note_Vat_Summary_Results_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Credit_Note_Vat_Summary_Results_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Credit_Note_Vat_Summary_Results_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Credit_Note_Vat_Summary_Results_Sum_Order_By>;
  var_pop?: InputMaybe<Credit_Note_Vat_Summary_Results_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Credit_Note_Vat_Summary_Results_Var_Samp_Order_By>;
  variance?: InputMaybe<Credit_Note_Vat_Summary_Results_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Credit_Note_Vat_Summary_Results_Avg_Fields = {
  __typename: 'credit_note_vat_summary_results_avg_fields';
  vatBase?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTotal?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "credit_note_vat_summary_results" */
export type Credit_Note_Vat_Summary_Results_Avg_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "credit_note_vat_summary_results". All fields are combined with a logical 'AND'. */
export type Credit_Note_Vat_Summary_Results_Bool_Exp = {
  _and?: InputMaybe<Array<Credit_Note_Vat_Summary_Results_Bool_Exp>>;
  _not?: InputMaybe<Credit_Note_Vat_Summary_Results_Bool_Exp>;
  _or?: InputMaybe<Array<Credit_Note_Vat_Summary_Results_Bool_Exp>>;
  vatBase?: InputMaybe<Bigint_Comparison_Exp>;
  vatPct?: InputMaybe<Numeric_Comparison_Exp>;
  vatTotal?: InputMaybe<Bigint_Comparison_Exp>;
  vatType?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Credit_Note_Vat_Summary_Results_Max_Fields = {
  __typename: 'credit_note_vat_summary_results_max_fields';
  vatBase?: Maybe<Scalars['bigint']>;
  vatPct?: Maybe<Scalars['numeric']>;
  vatTotal?: Maybe<Scalars['bigint']>;
  vatType?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "credit_note_vat_summary_results" */
export type Credit_Note_Vat_Summary_Results_Max_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
  vatType?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Credit_Note_Vat_Summary_Results_Min_Fields = {
  __typename: 'credit_note_vat_summary_results_min_fields';
  vatBase?: Maybe<Scalars['bigint']>;
  vatPct?: Maybe<Scalars['numeric']>;
  vatTotal?: Maybe<Scalars['bigint']>;
  vatType?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "credit_note_vat_summary_results" */
export type Credit_Note_Vat_Summary_Results_Min_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
  vatType?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "credit_note_vat_summary_results". */
export type Credit_Note_Vat_Summary_Results_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
  vatType?: InputMaybe<Order_By>;
};

/** select columns of table "credit_note_vat_summary_results" */
export enum Credit_Note_Vat_Summary_Results_Select_Column {
  /** column name */
  VatBase = 'vatBase',
  /** column name */
  VatPct = 'vatPct',
  /** column name */
  VatTotal = 'vatTotal',
  /** column name */
  VatType = 'vatType'
}

/** aggregate stddev on columns */
export type Credit_Note_Vat_Summary_Results_Stddev_Fields = {
  __typename: 'credit_note_vat_summary_results_stddev_fields';
  vatBase?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTotal?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "credit_note_vat_summary_results" */
export type Credit_Note_Vat_Summary_Results_Stddev_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Credit_Note_Vat_Summary_Results_Stddev_Pop_Fields = {
  __typename: 'credit_note_vat_summary_results_stddev_pop_fields';
  vatBase?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTotal?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "credit_note_vat_summary_results" */
export type Credit_Note_Vat_Summary_Results_Stddev_Pop_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Credit_Note_Vat_Summary_Results_Stddev_Samp_Fields = {
  __typename: 'credit_note_vat_summary_results_stddev_samp_fields';
  vatBase?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTotal?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "credit_note_vat_summary_results" */
export type Credit_Note_Vat_Summary_Results_Stddev_Samp_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "credit_note_vat_summary_results" */
export type Credit_Note_Vat_Summary_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Credit_Note_Vat_Summary_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Credit_Note_Vat_Summary_Results_Stream_Cursor_Value_Input = {
  vatBase?: InputMaybe<Scalars['bigint']>;
  vatPct?: InputMaybe<Scalars['numeric']>;
  vatTotal?: InputMaybe<Scalars['bigint']>;
  vatType?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Credit_Note_Vat_Summary_Results_Sum_Fields = {
  __typename: 'credit_note_vat_summary_results_sum_fields';
  vatBase?: Maybe<Scalars['bigint']>;
  vatPct?: Maybe<Scalars['numeric']>;
  vatTotal?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "credit_note_vat_summary_results" */
export type Credit_Note_Vat_Summary_Results_Sum_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Credit_Note_Vat_Summary_Results_Var_Pop_Fields = {
  __typename: 'credit_note_vat_summary_results_var_pop_fields';
  vatBase?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTotal?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "credit_note_vat_summary_results" */
export type Credit_Note_Vat_Summary_Results_Var_Pop_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Credit_Note_Vat_Summary_Results_Var_Samp_Fields = {
  __typename: 'credit_note_vat_summary_results_var_samp_fields';
  vatBase?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTotal?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "credit_note_vat_summary_results" */
export type Credit_Note_Vat_Summary_Results_Var_Samp_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Credit_Note_Vat_Summary_Results_Variance_Fields = {
  __typename: 'credit_note_vat_summary_results_variance_fields';
  vatBase?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTotal?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "credit_note_vat_summary_results" */
export type Credit_Note_Vat_Summary_Results_Variance_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
};

/** columns and relationships of "credit_notes" */
export type Credit_Notes = {
  __typename: 'credit_notes';
  /** An object relationship */
  Tenant: Tenants;
  /** Returns the calculated bigint hash of the contents of this credit note for PDF generation especially. */
  computedContentHash?: Maybe<Scalars['bigint']>;
  contentHash?: Maybe<Scalars['bigint']>;
  createdAt: Scalars['timestamptz'];
  creditDate: Scalars['date'];
  /** An array relationship */
  creditNoteItems: Array<Credit_Note_Items>;
  /** An aggregate relationship */
  creditNoteItems_aggregate: Credit_Note_Items_Aggregate;
  currency: Currencies_Enum;
  /** An object relationship */
  customer: Customers;
  customerId: Scalars['bigint'];
  customerNote?: Maybe<Scalars['String']>;
  /** An array relationship */
  customerOrderItemAdjustments: Array<Customer_Order_Item_Adjustments>;
  /** An aggregate relationship */
  customerOrderItemAdjustments_aggregate: Customer_Order_Item_Adjustments_Aggregate;
  /** An array relationship */
  customerOrderReturns: Array<Customer_Order_Returns>;
  /** An aggregate relationship */
  customerOrderReturns_aggregate: Customer_Order_Returns_Aggregate;
  domainId: Scalars['String'];
  /** An array relationship */
  globalSearchResults: Array<Global_Search_Result>;
  /** An aggregate relationship */
  globalSearchResults_aggregate: Global_Search_Result_Aggregate;
  /** Returns whether the current credit note documents references are outdated, to be re-generated. */
  hasOutdatedCreditNoteDocuments?: Maybe<Scalars['Boolean']>;
  id: Scalars['bigint'];
  /** An object relationship */
  pdfDocument?: Maybe<S3_Objects>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  state: Credit_Note_States_Enum;
  syncedToAccounting: Scalars['Boolean'];
  tenant: Scalars['String'];
  totalAmount?: Maybe<Scalars['bigint']>;
  totalAmountWithVat?: Maybe<Scalars['bigint']>;
  totalVatOnAmount?: Maybe<Scalars['bigint']>;
  updatedAt: Scalars['timestamptz'];
  /** Returns the VAT summary table for a credit note */
  vatSummary?: Maybe<Array<Credit_Note_Vat_Summary_Results>>;
  version: Scalars['bigint'];
  /** An object relationship */
  xmlDocument?: Maybe<S3_Objects>;
  xmlDocumentId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "credit_notes" */
export type Credit_NotesCreditNoteItemsArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Items_Order_By>>;
  where?: InputMaybe<Credit_Note_Items_Bool_Exp>;
};


/** columns and relationships of "credit_notes" */
export type Credit_NotesCreditNoteItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Items_Order_By>>;
  where?: InputMaybe<Credit_Note_Items_Bool_Exp>;
};


/** columns and relationships of "credit_notes" */
export type Credit_NotesCustomerOrderItemAdjustmentsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustments_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
};


/** columns and relationships of "credit_notes" */
export type Credit_NotesCustomerOrderItemAdjustments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustments_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
};


/** columns and relationships of "credit_notes" */
export type Credit_NotesCustomerOrderReturnsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
};


/** columns and relationships of "credit_notes" */
export type Credit_NotesCustomerOrderReturns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
};


/** columns and relationships of "credit_notes" */
export type Credit_NotesGlobalSearchResultsArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


/** columns and relationships of "credit_notes" */
export type Credit_NotesGlobalSearchResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


/** columns and relationships of "credit_notes" */
export type Credit_NotesVatSummaryArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Vat_Summary_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Vat_Summary_Results_Order_By>>;
  where?: InputMaybe<Credit_Note_Vat_Summary_Results_Bool_Exp>;
};

/** aggregated selection of "credit_notes" */
export type Credit_Notes_Aggregate = {
  __typename: 'credit_notes_aggregate';
  aggregate?: Maybe<Credit_Notes_Aggregate_Fields>;
  nodes: Array<Credit_Notes>;
};

export type Credit_Notes_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Credit_Notes_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Credit_Notes_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Credit_Notes_Aggregate_Bool_Exp_Count>;
};

export type Credit_Notes_Aggregate_Bool_Exp_Bool_And = {
  arguments: Credit_Notes_Select_Column_Credit_Notes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Credit_Notes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Credit_Notes_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Credit_Notes_Select_Column_Credit_Notes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Credit_Notes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Credit_Notes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Credit_Notes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Credit_Notes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "credit_notes" */
export type Credit_Notes_Aggregate_Fields = {
  __typename: 'credit_notes_aggregate_fields';
  avg?: Maybe<Credit_Notes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Credit_Notes_Max_Fields>;
  min?: Maybe<Credit_Notes_Min_Fields>;
  stddev?: Maybe<Credit_Notes_Stddev_Fields>;
  stddev_pop?: Maybe<Credit_Notes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Credit_Notes_Stddev_Samp_Fields>;
  sum?: Maybe<Credit_Notes_Sum_Fields>;
  var_pop?: Maybe<Credit_Notes_Var_Pop_Fields>;
  var_samp?: Maybe<Credit_Notes_Var_Samp_Fields>;
  variance?: Maybe<Credit_Notes_Variance_Fields>;
};


/** aggregate fields of "credit_notes" */
export type Credit_Notes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Credit_Notes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "credit_notes" */
export type Credit_Notes_Aggregate_Order_By = {
  avg?: InputMaybe<Credit_Notes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Credit_Notes_Max_Order_By>;
  min?: InputMaybe<Credit_Notes_Min_Order_By>;
  stddev?: InputMaybe<Credit_Notes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Credit_Notes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Credit_Notes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Credit_Notes_Sum_Order_By>;
  var_pop?: InputMaybe<Credit_Notes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Credit_Notes_Var_Samp_Order_By>;
  variance?: InputMaybe<Credit_Notes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "credit_notes" */
export type Credit_Notes_Arr_Rel_Insert_Input = {
  data: Array<Credit_Notes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Credit_Notes_On_Conflict>;
};

/** aggregate avg on columns */
export type Credit_Notes_Avg_Fields = {
  __typename: 'credit_notes_avg_fields';
  contentHash?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalAmountWithVat?: Maybe<Scalars['Float']>;
  totalVatOnAmount?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xmlDocumentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "credit_notes" */
export type Credit_Notes_Avg_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  totalAmount?: InputMaybe<Order_By>;
  totalAmountWithVat?: InputMaybe<Order_By>;
  totalVatOnAmount?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "credit_notes". All fields are combined with a logical 'AND'. */
export type Credit_Notes_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Credit_Notes_Bool_Exp>>;
  _not?: InputMaybe<Credit_Notes_Bool_Exp>;
  _or?: InputMaybe<Array<Credit_Notes_Bool_Exp>>;
  computedContentHash?: InputMaybe<Bigint_Comparison_Exp>;
  contentHash?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  creditDate?: InputMaybe<Date_Comparison_Exp>;
  creditNoteItems?: InputMaybe<Credit_Note_Items_Bool_Exp>;
  creditNoteItems_aggregate?: InputMaybe<Credit_Note_Items_Aggregate_Bool_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  customer?: InputMaybe<Customers_Bool_Exp>;
  customerId?: InputMaybe<Bigint_Comparison_Exp>;
  customerNote?: InputMaybe<String_Comparison_Exp>;
  customerOrderItemAdjustments?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
  customerOrderItemAdjustments_aggregate?: InputMaybe<Customer_Order_Item_Adjustments_Aggregate_Bool_Exp>;
  customerOrderReturns?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
  customerOrderReturns_aggregate?: InputMaybe<Customer_Order_Returns_Aggregate_Bool_Exp>;
  domainId?: InputMaybe<String_Comparison_Exp>;
  globalSearchResults?: InputMaybe<Global_Search_Result_Bool_Exp>;
  globalSearchResults_aggregate?: InputMaybe<Global_Search_Result_Aggregate_Bool_Exp>;
  hasOutdatedCreditNoteDocuments?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  pdfDocument?: InputMaybe<S3_Objects_Bool_Exp>;
  pdfDocumentId?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<Credit_Note_States_Enum_Comparison_Exp>;
  syncedToAccounting?: InputMaybe<Boolean_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  totalAmount?: InputMaybe<Bigint_Comparison_Exp>;
  totalAmountWithVat?: InputMaybe<Bigint_Comparison_Exp>;
  totalVatOnAmount?: InputMaybe<Bigint_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  vatSummary?: InputMaybe<Credit_Note_Vat_Summary_Results_Bool_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
  xmlDocument?: InputMaybe<S3_Objects_Bool_Exp>;
  xmlDocumentId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "credit_notes" */
export enum Credit_Notes_Constraint {
  /** unique or primary key constraint on columns "id" */
  CreditNotesPkey = 'credit_notes_pkey',
  /** unique or primary key constraint on columns "tenant", "domain_id" */
  CreditNotesTenantDomainId = 'credit_notes_tenant_domain_id',
  /** unique or primary key constraint on columns "credit_date", "customer_id" */
  UniqueOpenCreditNote = 'unique_open_credit_note'
}

/** input type for incrementing numeric columns in table "credit_notes" */
export type Credit_Notes_Inc_Input = {
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  totalAmount?: InputMaybe<Scalars['bigint']>;
  xmlDocumentId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "credit_notes" */
export type Credit_Notes_Insert_Input = {
  contentHash?: InputMaybe<Scalars['bigint']>;
  creditDate?: InputMaybe<Scalars['date']>;
  creditNoteItems?: InputMaybe<Credit_Note_Items_Arr_Rel_Insert_Input>;
  currency?: InputMaybe<Currencies_Enum>;
  customer?: InputMaybe<Customers_Obj_Rel_Insert_Input>;
  customerId?: InputMaybe<Scalars['bigint']>;
  customerNote?: InputMaybe<Scalars['String']>;
  customerOrderItemAdjustments?: InputMaybe<Customer_Order_Item_Adjustments_Arr_Rel_Insert_Input>;
  customerOrderReturns?: InputMaybe<Customer_Order_Returns_Arr_Rel_Insert_Input>;
  domainId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  pdfDocument?: InputMaybe<S3_Objects_Obj_Rel_Insert_Input>;
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Credit_Note_States_Enum>;
  syncedToAccounting?: InputMaybe<Scalars['Boolean']>;
  totalAmount?: InputMaybe<Scalars['bigint']>;
  totalAmountWithVat?: InputMaybe<Scalars['bigint']>;
  totalVatOnAmount?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  xmlDocument?: InputMaybe<S3_Objects_Obj_Rel_Insert_Input>;
  xmlDocumentId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Credit_Notes_Max_Fields = {
  __typename: 'credit_notes_max_fields';
  contentHash?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creditDate?: Maybe<Scalars['date']>;
  customerId?: Maybe<Scalars['bigint']>;
  customerNote?: Maybe<Scalars['String']>;
  domainId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['bigint']>;
  totalAmountWithVat?: Maybe<Scalars['bigint']>;
  totalVatOnAmount?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  xmlDocumentId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "credit_notes" */
export type Credit_Notes_Max_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creditDate?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  customerNote?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  totalAmount?: InputMaybe<Order_By>;
  totalAmountWithVat?: InputMaybe<Order_By>;
  totalVatOnAmount?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Credit_Notes_Min_Fields = {
  __typename: 'credit_notes_min_fields';
  contentHash?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creditDate?: Maybe<Scalars['date']>;
  customerId?: Maybe<Scalars['bigint']>;
  customerNote?: Maybe<Scalars['String']>;
  domainId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['bigint']>;
  totalAmountWithVat?: Maybe<Scalars['bigint']>;
  totalVatOnAmount?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  xmlDocumentId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "credit_notes" */
export type Credit_Notes_Min_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creditDate?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  customerNote?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  totalAmount?: InputMaybe<Order_By>;
  totalAmountWithVat?: InputMaybe<Order_By>;
  totalVatOnAmount?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "credit_notes" */
export type Credit_Notes_Mutation_Response = {
  __typename: 'credit_notes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Credit_Notes>;
};

/** input type for inserting object relation for remote table "credit_notes" */
export type Credit_Notes_Obj_Rel_Insert_Input = {
  data: Credit_Notes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Credit_Notes_On_Conflict>;
};

/** on_conflict condition type for table "credit_notes" */
export type Credit_Notes_On_Conflict = {
  constraint: Credit_Notes_Constraint;
  update_columns?: Array<Credit_Notes_Update_Column>;
  where?: InputMaybe<Credit_Notes_Bool_Exp>;
};

/** Ordering options when selecting data from "credit_notes". */
export type Credit_Notes_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  computedContentHash?: InputMaybe<Order_By>;
  contentHash?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creditDate?: InputMaybe<Order_By>;
  creditNoteItems_aggregate?: InputMaybe<Credit_Note_Items_Aggregate_Order_By>;
  currency?: InputMaybe<Order_By>;
  customer?: InputMaybe<Customers_Order_By>;
  customerId?: InputMaybe<Order_By>;
  customerNote?: InputMaybe<Order_By>;
  customerOrderItemAdjustments_aggregate?: InputMaybe<Customer_Order_Item_Adjustments_Aggregate_Order_By>;
  customerOrderReturns_aggregate?: InputMaybe<Customer_Order_Returns_Aggregate_Order_By>;
  domainId?: InputMaybe<Order_By>;
  globalSearchResults_aggregate?: InputMaybe<Global_Search_Result_Aggregate_Order_By>;
  hasOutdatedCreditNoteDocuments?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pdfDocument?: InputMaybe<S3_Objects_Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  syncedToAccounting?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  totalAmount?: InputMaybe<Order_By>;
  totalAmountWithVat?: InputMaybe<Order_By>;
  totalVatOnAmount?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vatSummary_aggregate?: InputMaybe<Credit_Note_Vat_Summary_Results_Aggregate_Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocument?: InputMaybe<S3_Objects_Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: credit_notes */
export type Credit_Notes_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "credit_notes" */
export enum Credit_Notes_Select_Column {
  /** column name */
  ContentHash = 'contentHash',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreditDate = 'creditDate',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  CustomerNote = 'customerNote',
  /** column name */
  DomainId = 'domainId',
  /** column name */
  Id = 'id',
  /** column name */
  PdfDocumentId = 'pdfDocumentId',
  /** column name */
  State = 'state',
  /** column name */
  SyncedToAccounting = 'syncedToAccounting',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  TotalAmount = 'totalAmount',
  /** column name */
  TotalAmountWithVat = 'totalAmountWithVat',
  /** column name */
  TotalVatOnAmount = 'totalVatOnAmount',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version',
  /** column name */
  XmlDocumentId = 'xmlDocumentId'
}

/** select "credit_notes_aggregate_bool_exp_bool_and_arguments_columns" columns of table "credit_notes" */
export enum Credit_Notes_Select_Column_Credit_Notes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  SyncedToAccounting = 'syncedToAccounting'
}

/** select "credit_notes_aggregate_bool_exp_bool_or_arguments_columns" columns of table "credit_notes" */
export enum Credit_Notes_Select_Column_Credit_Notes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  SyncedToAccounting = 'syncedToAccounting'
}

/** input type for updating data in table "credit_notes" */
export type Credit_Notes_Set_Input = {
  creditDate?: InputMaybe<Scalars['date']>;
  customerNote?: InputMaybe<Scalars['String']>;
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Credit_Note_States_Enum>;
  syncedToAccounting?: InputMaybe<Scalars['Boolean']>;
  totalAmount?: InputMaybe<Scalars['bigint']>;
  xmlDocumentId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Credit_Notes_Stddev_Fields = {
  __typename: 'credit_notes_stddev_fields';
  contentHash?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalAmountWithVat?: Maybe<Scalars['Float']>;
  totalVatOnAmount?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xmlDocumentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "credit_notes" */
export type Credit_Notes_Stddev_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  totalAmount?: InputMaybe<Order_By>;
  totalAmountWithVat?: InputMaybe<Order_By>;
  totalVatOnAmount?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Credit_Notes_Stddev_Pop_Fields = {
  __typename: 'credit_notes_stddev_pop_fields';
  contentHash?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalAmountWithVat?: Maybe<Scalars['Float']>;
  totalVatOnAmount?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xmlDocumentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "credit_notes" */
export type Credit_Notes_Stddev_Pop_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  totalAmount?: InputMaybe<Order_By>;
  totalAmountWithVat?: InputMaybe<Order_By>;
  totalVatOnAmount?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Credit_Notes_Stddev_Samp_Fields = {
  __typename: 'credit_notes_stddev_samp_fields';
  contentHash?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalAmountWithVat?: Maybe<Scalars['Float']>;
  totalVatOnAmount?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xmlDocumentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "credit_notes" */
export type Credit_Notes_Stddev_Samp_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  totalAmount?: InputMaybe<Order_By>;
  totalAmountWithVat?: InputMaybe<Order_By>;
  totalVatOnAmount?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "credit_notes" */
export type Credit_Notes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Credit_Notes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Credit_Notes_Stream_Cursor_Value_Input = {
  contentHash?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  creditDate?: InputMaybe<Scalars['date']>;
  currency?: InputMaybe<Currencies_Enum>;
  customerId?: InputMaybe<Scalars['bigint']>;
  customerNote?: InputMaybe<Scalars['String']>;
  domainId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Credit_Note_States_Enum>;
  syncedToAccounting?: InputMaybe<Scalars['Boolean']>;
  tenant?: InputMaybe<Scalars['String']>;
  totalAmount?: InputMaybe<Scalars['bigint']>;
  totalAmountWithVat?: InputMaybe<Scalars['bigint']>;
  totalVatOnAmount?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
  xmlDocumentId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Credit_Notes_Sum_Fields = {
  __typename: 'credit_notes_sum_fields';
  contentHash?: Maybe<Scalars['bigint']>;
  customerId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  totalAmount?: Maybe<Scalars['bigint']>;
  totalAmountWithVat?: Maybe<Scalars['bigint']>;
  totalVatOnAmount?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
  xmlDocumentId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "credit_notes" */
export type Credit_Notes_Sum_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  totalAmount?: InputMaybe<Order_By>;
  totalAmountWithVat?: InputMaybe<Order_By>;
  totalVatOnAmount?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** update columns of table "credit_notes" */
export enum Credit_Notes_Update_Column {
  /** column name */
  CreditDate = 'creditDate',
  /** column name */
  CustomerNote = 'customerNote',
  /** column name */
  PdfDocumentId = 'pdfDocumentId',
  /** column name */
  State = 'state',
  /** column name */
  SyncedToAccounting = 'syncedToAccounting',
  /** column name */
  TotalAmount = 'totalAmount',
  /** column name */
  XmlDocumentId = 'xmlDocumentId'
}

export type Credit_Notes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Credit_Notes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Credit_Notes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Credit_Notes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Credit_Notes_Var_Pop_Fields = {
  __typename: 'credit_notes_var_pop_fields';
  contentHash?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalAmountWithVat?: Maybe<Scalars['Float']>;
  totalVatOnAmount?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xmlDocumentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "credit_notes" */
export type Credit_Notes_Var_Pop_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  totalAmount?: InputMaybe<Order_By>;
  totalAmountWithVat?: InputMaybe<Order_By>;
  totalVatOnAmount?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Credit_Notes_Var_Samp_Fields = {
  __typename: 'credit_notes_var_samp_fields';
  contentHash?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalAmountWithVat?: Maybe<Scalars['Float']>;
  totalVatOnAmount?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xmlDocumentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "credit_notes" */
export type Credit_Notes_Var_Samp_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  totalAmount?: InputMaybe<Order_By>;
  totalAmountWithVat?: InputMaybe<Order_By>;
  totalVatOnAmount?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Credit_Notes_Variance_Fields = {
  __typename: 'credit_notes_variance_fields';
  contentHash?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalAmountWithVat?: Maybe<Scalars['Float']>;
  totalVatOnAmount?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xmlDocumentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "credit_notes" */
export type Credit_Notes_Variance_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  totalAmount?: InputMaybe<Order_By>;
  totalAmountWithVat?: InputMaybe<Order_By>;
  totalVatOnAmount?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** columns and relationships of "currencies" */
export type Currencies = {
  __typename: 'currencies';
  /** An array relationship */
  aggregatedInventoryItemsByLocations: Array<Aggregated_Inventory_Items_By_Location>;
  /** An aggregate relationship */
  aggregatedInventoryItemsByLocations_aggregate: Aggregated_Inventory_Items_By_Location_Aggregate;
  /** An array relationship */
  calculateCostPriceForProductResults: Array<Calculate_Cost_Price_For_Product_Results>;
  /** An aggregate relationship */
  calculateCostPriceForProductResults_aggregate: Calculate_Cost_Price_For_Product_Results_Aggregate;
  /** An array relationship */
  creditNoteItems: Array<Credit_Note_Items>;
  /** An aggregate relationship */
  creditNoteItems_aggregate: Credit_Note_Items_Aggregate;
  /** An array relationship */
  creditNotes: Array<Credit_Notes>;
  /** An aggregate relationship */
  creditNotes_aggregate: Credit_Notes_Aggregate;
  /** An array relationship */
  customerBillingSettings: Array<Customer_Billing_Settings>;
  /** An aggregate relationship */
  customerBillingSettings_aggregate: Customer_Billing_Settings_Aggregate;
  /** An array relationship */
  customerGroupPricings: Array<Customer_Group_Pricing>;
  /** An aggregate relationship */
  customerGroupPricings_aggregate: Customer_Group_Pricing_Aggregate;
  /** An array relationship */
  customerInvoices: Array<Customer_Invoices>;
  /** An aggregate relationship */
  customerInvoices_aggregate: Customer_Invoices_Aggregate;
  /** An array relationship */
  customerOrderItems: Array<Customer_Order_Items>;
  /** An aggregate relationship */
  customerOrderItems_aggregate: Customer_Order_Items_Aggregate;
  /** An array relationship */
  customerOrderReturnItems: Array<Customer_Order_Return_Items>;
  /** An aggregate relationship */
  customerOrderReturnItems_aggregate: Customer_Order_Return_Items_Aggregate;
  /** An array relationship */
  customerOrders: Array<Customer_Orders>;
  /** An aggregate relationship */
  customerOrders_aggregate: Customer_Orders_Aggregate;
  /** An array relationship */
  cuttingPatternOutputs: Array<Cutting_Pattern_Outputs>;
  /** An array relationship */
  cuttingPatternOutputsDrafts: Array<Cutting_Pattern_Outputs_Draft>;
  /** An aggregate relationship */
  cuttingPatternOutputsDrafts_aggregate: Cutting_Pattern_Outputs_Draft_Aggregate;
  /** An aggregate relationship */
  cuttingPatternOutputs_aggregate: Cutting_Pattern_Outputs_Aggregate;
  /** An array relationship */
  cuttingPatterns: Array<Cutting_Patterns>;
  /** An array relationship */
  cuttingPatternsDrafts: Array<Cutting_Patterns_Draft>;
  /** An aggregate relationship */
  cuttingPatternsDrafts_aggregate: Cutting_Patterns_Draft_Aggregate;
  /** An aggregate relationship */
  cuttingPatterns_aggregate: Cutting_Patterns_Aggregate;
  /** An array relationship */
  exchangeRatesViaCurrencyFrom: Array<Exchange_Rates>;
  /** An aggregate relationship */
  exchangeRatesViaCurrencyFrom_aggregate: Exchange_Rates_Aggregate;
  /** An array relationship */
  exchangeRatesViaCurrencyTo: Array<Exchange_Rates>;
  /** An aggregate relationship */
  exchangeRatesViaCurrencyTo_aggregate: Exchange_Rates_Aggregate;
  /** An array relationship */
  inventoryItemsByLocations: Array<Inventory_Items_By_Location>;
  /** An aggregate relationship */
  inventoryItemsByLocations_aggregate: Inventory_Items_By_Location_Aggregate;
  /** An array relationship */
  lotNumberProducts: Array<Lot_Number_Products>;
  /** An aggregate relationship */
  lotNumberProducts_aggregate: Lot_Number_Products_Aggregate;
  /** An array relationship */
  productRecipes: Array<Product_Recipes>;
  /** An aggregate relationship */
  productRecipes_aggregate: Product_Recipes_Aggregate;
  /** An array relationship */
  productSuppliers: Array<Product_Suppliers>;
  /** An aggregate relationship */
  productSuppliers_aggregate: Product_Suppliers_Aggregate;
  /** An array relationship */
  productUnitSalesPriceConfigs: Array<Product_Unit_Sales_Price_Config>;
  /** An aggregate relationship */
  productUnitSalesPriceConfigs_aggregate: Product_Unit_Sales_Price_Config_Aggregate;
  /** An array relationship */
  supplierOrderGoodsIncomeItems: Array<Supplier_Order_Goods_Income_Items>;
  /** An aggregate relationship */
  supplierOrderGoodsIncomeItems_aggregate: Supplier_Order_Goods_Income_Items_Aggregate;
  /** An array relationship */
  supplierOrderItems: Array<Supplier_Order_Items>;
  /** An aggregate relationship */
  supplierOrderItems_aggregate: Supplier_Order_Items_Aggregate;
  /** An array relationship */
  supplierOrders: Array<Supplier_Orders>;
  /** An aggregate relationship */
  supplierOrders_aggregate: Supplier_Orders_Aggregate;
  /** An array relationship */
  suppliers: Array<Suppliers>;
  /** An aggregate relationship */
  suppliers_aggregate: Suppliers_Aggregate;
  /** An array relationship */
  temporalProducts: Array<Temporal_Products>;
  /** An aggregate relationship */
  temporalProducts_aggregate: Temporal_Products_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "currencies" */
export type CurrenciesAggregatedInventoryItemsByLocationsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesAggregatedInventoryItemsByLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCalculateCostPriceForProductResultsArgs = {
  distinct_on?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Order_By>>;
  where?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCalculateCostPriceForProductResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Order_By>>;
  where?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCreditNoteItemsArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Items_Order_By>>;
  where?: InputMaybe<Credit_Note_Items_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCreditNoteItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Items_Order_By>>;
  where?: InputMaybe<Credit_Note_Items_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCreditNotesArgs = {
  distinct_on?: InputMaybe<Array<Credit_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Notes_Order_By>>;
  where?: InputMaybe<Credit_Notes_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCreditNotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Notes_Order_By>>;
  where?: InputMaybe<Credit_Notes_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCustomerBillingSettingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Settings_Order_By>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCustomerBillingSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Settings_Order_By>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCustomerGroupPricingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCustomerGroupPricings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCustomerInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoices_Order_By>>;
  where?: InputMaybe<Customer_Invoices_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCustomerInvoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoices_Order_By>>;
  where?: InputMaybe<Customer_Invoices_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCustomerOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCustomerOrderItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCustomerOrderReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCustomerOrderReturnItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCustomerOrdersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCustomerOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCuttingPatternOutputsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCuttingPatternOutputsDraftsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCuttingPatternOutputsDrafts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCuttingPatternOutputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCuttingPatternsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCuttingPatternsDraftsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCuttingPatternsDrafts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesCuttingPatterns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesExchangeRatesViaCurrencyFromArgs = {
  distinct_on?: InputMaybe<Array<Exchange_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Rates_Order_By>>;
  where?: InputMaybe<Exchange_Rates_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesExchangeRatesViaCurrencyFrom_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exchange_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Rates_Order_By>>;
  where?: InputMaybe<Exchange_Rates_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesExchangeRatesViaCurrencyToArgs = {
  distinct_on?: InputMaybe<Array<Exchange_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Rates_Order_By>>;
  where?: InputMaybe<Exchange_Rates_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesExchangeRatesViaCurrencyTo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exchange_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Rates_Order_By>>;
  where?: InputMaybe<Exchange_Rates_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesInventoryItemsByLocationsArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesInventoryItemsByLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesLotNumberProductsArgs = {
  distinct_on?: InputMaybe<Array<Lot_Number_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Number_Products_Order_By>>;
  where?: InputMaybe<Lot_Number_Products_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesLotNumberProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lot_Number_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Number_Products_Order_By>>;
  where?: InputMaybe<Lot_Number_Products_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesProductRecipesArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipes_Order_By>>;
  where?: InputMaybe<Product_Recipes_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesProductRecipes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipes_Order_By>>;
  where?: InputMaybe<Product_Recipes_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesProductSuppliersArgs = {
  distinct_on?: InputMaybe<Array<Product_Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Suppliers_Order_By>>;
  where?: InputMaybe<Product_Suppliers_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesProductSuppliers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Suppliers_Order_By>>;
  where?: InputMaybe<Product_Suppliers_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesProductUnitSalesPriceConfigsArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Order_By>>;
  where?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesProductUnitSalesPriceConfigs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Order_By>>;
  where?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesSupplierOrderGoodsIncomeItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesSupplierOrderGoodsIncomeItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesSupplierOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesSupplierOrderItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesSupplierOrdersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesSupplierOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesSuppliersArgs = {
  distinct_on?: InputMaybe<Array<Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Suppliers_Order_By>>;
  where?: InputMaybe<Suppliers_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesSuppliers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Suppliers_Order_By>>;
  where?: InputMaybe<Suppliers_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesTemporalProductsArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesTemporalProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};

/** aggregated selection of "currencies" */
export type Currencies_Aggregate = {
  __typename: 'currencies_aggregate';
  aggregate?: Maybe<Currencies_Aggregate_Fields>;
  nodes: Array<Currencies>;
};

/** aggregate fields of "currencies" */
export type Currencies_Aggregate_Fields = {
  __typename: 'currencies_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Currencies_Max_Fields>;
  min?: Maybe<Currencies_Min_Fields>;
};


/** aggregate fields of "currencies" */
export type Currencies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Currencies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "currencies". All fields are combined with a logical 'AND'. */
export type Currencies_Bool_Exp = {
  _and?: InputMaybe<Array<Currencies_Bool_Exp>>;
  _not?: InputMaybe<Currencies_Bool_Exp>;
  _or?: InputMaybe<Array<Currencies_Bool_Exp>>;
  aggregatedInventoryItemsByLocations?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
  aggregatedInventoryItemsByLocations_aggregate?: InputMaybe<Aggregated_Inventory_Items_By_Location_Aggregate_Bool_Exp>;
  calculateCostPriceForProductResults?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
  calculateCostPriceForProductResults_aggregate?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Aggregate_Bool_Exp>;
  creditNoteItems?: InputMaybe<Credit_Note_Items_Bool_Exp>;
  creditNoteItems_aggregate?: InputMaybe<Credit_Note_Items_Aggregate_Bool_Exp>;
  creditNotes?: InputMaybe<Credit_Notes_Bool_Exp>;
  creditNotes_aggregate?: InputMaybe<Credit_Notes_Aggregate_Bool_Exp>;
  customerBillingSettings?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
  customerBillingSettings_aggregate?: InputMaybe<Customer_Billing_Settings_Aggregate_Bool_Exp>;
  customerGroupPricings?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
  customerGroupPricings_aggregate?: InputMaybe<Customer_Group_Pricing_Aggregate_Bool_Exp>;
  customerInvoices?: InputMaybe<Customer_Invoices_Bool_Exp>;
  customerInvoices_aggregate?: InputMaybe<Customer_Invoices_Aggregate_Bool_Exp>;
  customerOrderItems?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  customerOrderItems_aggregate?: InputMaybe<Customer_Order_Items_Aggregate_Bool_Exp>;
  customerOrderReturnItems?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
  customerOrderReturnItems_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Bool_Exp>;
  customerOrders?: InputMaybe<Customer_Orders_Bool_Exp>;
  customerOrders_aggregate?: InputMaybe<Customer_Orders_Aggregate_Bool_Exp>;
  cuttingPatternOutputs?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
  cuttingPatternOutputsDrafts?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
  cuttingPatternOutputsDrafts_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Draft_Aggregate_Bool_Exp>;
  cuttingPatternOutputs_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Aggregate_Bool_Exp>;
  cuttingPatterns?: InputMaybe<Cutting_Patterns_Bool_Exp>;
  cuttingPatternsDrafts?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
  cuttingPatternsDrafts_aggregate?: InputMaybe<Cutting_Patterns_Draft_Aggregate_Bool_Exp>;
  cuttingPatterns_aggregate?: InputMaybe<Cutting_Patterns_Aggregate_Bool_Exp>;
  exchangeRatesViaCurrencyFrom?: InputMaybe<Exchange_Rates_Bool_Exp>;
  exchangeRatesViaCurrencyFrom_aggregate?: InputMaybe<Exchange_Rates_Aggregate_Bool_Exp>;
  exchangeRatesViaCurrencyTo?: InputMaybe<Exchange_Rates_Bool_Exp>;
  exchangeRatesViaCurrencyTo_aggregate?: InputMaybe<Exchange_Rates_Aggregate_Bool_Exp>;
  inventoryItemsByLocations?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
  inventoryItemsByLocations_aggregate?: InputMaybe<Inventory_Items_By_Location_Aggregate_Bool_Exp>;
  lotNumberProducts?: InputMaybe<Lot_Number_Products_Bool_Exp>;
  lotNumberProducts_aggregate?: InputMaybe<Lot_Number_Products_Aggregate_Bool_Exp>;
  productRecipes?: InputMaybe<Product_Recipes_Bool_Exp>;
  productRecipes_aggregate?: InputMaybe<Product_Recipes_Aggregate_Bool_Exp>;
  productSuppliers?: InputMaybe<Product_Suppliers_Bool_Exp>;
  productSuppliers_aggregate?: InputMaybe<Product_Suppliers_Aggregate_Bool_Exp>;
  productUnitSalesPriceConfigs?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
  productUnitSalesPriceConfigs_aggregate?: InputMaybe<Product_Unit_Sales_Price_Config_Aggregate_Bool_Exp>;
  supplierOrderGoodsIncomeItems?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
  supplierOrderGoodsIncomeItems_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Bool_Exp>;
  supplierOrderItems?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
  supplierOrderItems_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Bool_Exp>;
  supplierOrders?: InputMaybe<Supplier_Orders_Bool_Exp>;
  supplierOrders_aggregate?: InputMaybe<Supplier_Orders_Aggregate_Bool_Exp>;
  suppliers?: InputMaybe<Suppliers_Bool_Exp>;
  suppliers_aggregate?: InputMaybe<Suppliers_Aggregate_Bool_Exp>;
  temporalProducts?: InputMaybe<Temporal_Products_Bool_Exp>;
  temporalProducts_aggregate?: InputMaybe<Temporal_Products_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Currencies_Enum {
  Chf = 'CHF',
  Eur = 'EUR'
}

/** Boolean expression to compare columns of type "currencies_enum". All fields are combined with logical 'AND'. */
export type Currencies_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Currencies_Enum>;
  _in?: InputMaybe<Array<Currencies_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Currencies_Enum>;
  _nin?: InputMaybe<Array<Currencies_Enum>>;
};

/** aggregate max on columns */
export type Currencies_Max_Fields = {
  __typename: 'currencies_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Currencies_Min_Fields = {
  __typename: 'currencies_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "currencies". */
export type Currencies_Order_By = {
  aggregatedInventoryItemsByLocations_aggregate?: InputMaybe<Aggregated_Inventory_Items_By_Location_Aggregate_Order_By>;
  calculateCostPriceForProductResults_aggregate?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Aggregate_Order_By>;
  creditNoteItems_aggregate?: InputMaybe<Credit_Note_Items_Aggregate_Order_By>;
  creditNotes_aggregate?: InputMaybe<Credit_Notes_Aggregate_Order_By>;
  customerBillingSettings_aggregate?: InputMaybe<Customer_Billing_Settings_Aggregate_Order_By>;
  customerGroupPricings_aggregate?: InputMaybe<Customer_Group_Pricing_Aggregate_Order_By>;
  customerInvoices_aggregate?: InputMaybe<Customer_Invoices_Aggregate_Order_By>;
  customerOrderItems_aggregate?: InputMaybe<Customer_Order_Items_Aggregate_Order_By>;
  customerOrderReturnItems_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Order_By>;
  customerOrders_aggregate?: InputMaybe<Customer_Orders_Aggregate_Order_By>;
  cuttingPatternOutputsDrafts_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Draft_Aggregate_Order_By>;
  cuttingPatternOutputs_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Aggregate_Order_By>;
  cuttingPatternsDrafts_aggregate?: InputMaybe<Cutting_Patterns_Draft_Aggregate_Order_By>;
  cuttingPatterns_aggregate?: InputMaybe<Cutting_Patterns_Aggregate_Order_By>;
  exchangeRatesViaCurrencyFrom_aggregate?: InputMaybe<Exchange_Rates_Aggregate_Order_By>;
  exchangeRatesViaCurrencyTo_aggregate?: InputMaybe<Exchange_Rates_Aggregate_Order_By>;
  inventoryItemsByLocations_aggregate?: InputMaybe<Inventory_Items_By_Location_Aggregate_Order_By>;
  lotNumberProducts_aggregate?: InputMaybe<Lot_Number_Products_Aggregate_Order_By>;
  productRecipes_aggregate?: InputMaybe<Product_Recipes_Aggregate_Order_By>;
  productSuppliers_aggregate?: InputMaybe<Product_Suppliers_Aggregate_Order_By>;
  productUnitSalesPriceConfigs_aggregate?: InputMaybe<Product_Unit_Sales_Price_Config_Aggregate_Order_By>;
  supplierOrderGoodsIncomeItems_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Order_By>;
  supplierOrderItems_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Order_By>;
  supplierOrders_aggregate?: InputMaybe<Supplier_Orders_Aggregate_Order_By>;
  suppliers_aggregate?: InputMaybe<Suppliers_Aggregate_Order_By>;
  temporalProducts_aggregate?: InputMaybe<Temporal_Products_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "currencies" */
export enum Currencies_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "currencies" */
export type Currencies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Currencies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Currencies_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

export type CurrentTemporalProducts_Customer_Order_Items_Args = {
  _as_of?: InputMaybe<Scalars['date']>;
};

export type CurrentTemporalSalesTeams_Customers_Args = {
  on_date?: InputMaybe<Scalars['date']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "customer_addresses" */
export type Customer_Addresses = {
  __typename: 'customer_addresses';
  /** An object relationship */
  Tenant: Tenants;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  customersViaBillingAddress: Array<Customers>;
  /** An aggregate relationship */
  customersViaBillingAddress_aggregate: Customers_Aggregate;
  /** An array relationship */
  customersViaShippingAddress: Array<Customers>;
  /** An aggregate relationship */
  customersViaShippingAddress_aggregate: Customers_Aggregate;
  gln?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  name?: Maybe<Scalars['String']>;
  name2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
  zip?: Maybe<Scalars['String']>;
};


/** columns and relationships of "customer_addresses" */
export type Customer_AddressesCustomersViaBillingAddressArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "customer_addresses" */
export type Customer_AddressesCustomersViaBillingAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "customer_addresses" */
export type Customer_AddressesCustomersViaShippingAddressArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "customer_addresses" */
export type Customer_AddressesCustomersViaShippingAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};

/** aggregated selection of "customer_addresses" */
export type Customer_Addresses_Aggregate = {
  __typename: 'customer_addresses_aggregate';
  aggregate?: Maybe<Customer_Addresses_Aggregate_Fields>;
  nodes: Array<Customer_Addresses>;
};

export type Customer_Addresses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Addresses_Aggregate_Bool_Exp_Count>;
};

export type Customer_Addresses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Addresses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Addresses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_addresses" */
export type Customer_Addresses_Aggregate_Fields = {
  __typename: 'customer_addresses_aggregate_fields';
  avg?: Maybe<Customer_Addresses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Addresses_Max_Fields>;
  min?: Maybe<Customer_Addresses_Min_Fields>;
  stddev?: Maybe<Customer_Addresses_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Addresses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Addresses_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Addresses_Sum_Fields>;
  var_pop?: Maybe<Customer_Addresses_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Addresses_Var_Samp_Fields>;
  variance?: Maybe<Customer_Addresses_Variance_Fields>;
};


/** aggregate fields of "customer_addresses" */
export type Customer_Addresses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Addresses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_addresses" */
export type Customer_Addresses_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Addresses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Addresses_Max_Order_By>;
  min?: InputMaybe<Customer_Addresses_Min_Order_By>;
  stddev?: InputMaybe<Customer_Addresses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Addresses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Addresses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Addresses_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Addresses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Addresses_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Addresses_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Customer_Addresses_Avg_Fields = {
  __typename: 'customer_addresses_avg_fields';
  gln?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_addresses" */
export type Customer_Addresses_Avg_Order_By = {
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_addresses". All fields are combined with a logical 'AND'. */
export type Customer_Addresses_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Addresses_Bool_Exp>>;
  _not?: InputMaybe<Customer_Addresses_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Addresses_Bool_Exp>>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customersViaBillingAddress?: InputMaybe<Customers_Bool_Exp>;
  customersViaBillingAddress_aggregate?: InputMaybe<Customers_Aggregate_Bool_Exp>;
  customersViaShippingAddress?: InputMaybe<Customers_Bool_Exp>;
  customersViaShippingAddress_aggregate?: InputMaybe<Customers_Aggregate_Bool_Exp>;
  gln?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  name2?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  street1?: InputMaybe<String_Comparison_Exp>;
  street2?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
  zip?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_addresses" */
export enum Customer_Addresses_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerAddressesPkey = 'customer_addresses_pkey'
}

/** input type for incrementing numeric columns in table "customer_addresses" */
export type Customer_Addresses_Inc_Input = {
  gln?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_addresses" */
export type Customer_Addresses_Insert_Input = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  customersViaBillingAddress?: InputMaybe<Customers_Arr_Rel_Insert_Input>;
  customersViaShippingAddress?: InputMaybe<Customers_Arr_Rel_Insert_Input>;
  gln?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  name2?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Customer_Addresses_Max_Fields = {
  __typename: 'customer_addresses_max_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  gln?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  name2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "customer_addresses" */
export type Customer_Addresses_Max_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name2?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street1?: InputMaybe<Order_By>;
  street2?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  zip?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Addresses_Min_Fields = {
  __typename: 'customer_addresses_min_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  gln?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  name2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "customer_addresses" */
export type Customer_Addresses_Min_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name2?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street1?: InputMaybe<Order_By>;
  street2?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  zip?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_addresses" */
export type Customer_Addresses_Mutation_Response = {
  __typename: 'customer_addresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Addresses>;
};

/** input type for inserting object relation for remote table "customer_addresses" */
export type Customer_Addresses_Obj_Rel_Insert_Input = {
  data: Customer_Addresses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Addresses_On_Conflict>;
};

/** on_conflict condition type for table "customer_addresses" */
export type Customer_Addresses_On_Conflict = {
  constraint: Customer_Addresses_Constraint;
  update_columns?: Array<Customer_Addresses_Update_Column>;
  where?: InputMaybe<Customer_Addresses_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_addresses". */
export type Customer_Addresses_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customersViaBillingAddress_aggregate?: InputMaybe<Customers_Aggregate_Order_By>;
  customersViaShippingAddress_aggregate?: InputMaybe<Customers_Aggregate_Order_By>;
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name2?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street1?: InputMaybe<Order_By>;
  street2?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  zip?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_addresses */
export type Customer_Addresses_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customer_addresses" */
export enum Customer_Addresses_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Gln = 'gln',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Name2 = 'name2',
  /** column name */
  State = 'state',
  /** column name */
  Street1 = 'street1',
  /** column name */
  Street2 = 'street2',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version',
  /** column name */
  Zip = 'zip'
}

/** input type for updating data in table "customer_addresses" */
export type Customer_Addresses_Set_Input = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  gln?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  name2?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Customer_Addresses_Stddev_Fields = {
  __typename: 'customer_addresses_stddev_fields';
  gln?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_addresses" */
export type Customer_Addresses_Stddev_Order_By = {
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Addresses_Stddev_Pop_Fields = {
  __typename: 'customer_addresses_stddev_pop_fields';
  gln?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_addresses" */
export type Customer_Addresses_Stddev_Pop_Order_By = {
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Addresses_Stddev_Samp_Fields = {
  __typename: 'customer_addresses_stddev_samp_fields';
  gln?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_addresses" */
export type Customer_Addresses_Stddev_Samp_Order_By = {
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_addresses" */
export type Customer_Addresses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Addresses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Addresses_Stream_Cursor_Value_Input = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  gln?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  name2?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Customer_Addresses_Sum_Fields = {
  __typename: 'customer_addresses_sum_fields';
  gln?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_addresses" */
export type Customer_Addresses_Sum_Order_By = {
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "customer_addresses" */
export enum Customer_Addresses_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Gln = 'gln',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Name2 = 'name2',
  /** column name */
  State = 'state',
  /** column name */
  Street1 = 'street1',
  /** column name */
  Street2 = 'street2',
  /** column name */
  Version = 'version',
  /** column name */
  Zip = 'zip'
}

export type Customer_Addresses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Addresses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Addresses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Addresses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Addresses_Var_Pop_Fields = {
  __typename: 'customer_addresses_var_pop_fields';
  gln?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_addresses" */
export type Customer_Addresses_Var_Pop_Order_By = {
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Addresses_Var_Samp_Fields = {
  __typename: 'customer_addresses_var_samp_fields';
  gln?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_addresses" */
export type Customer_Addresses_Var_Samp_Order_By = {
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Addresses_Variance_Fields = {
  __typename: 'customer_addresses_variance_fields';
  gln?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_addresses" */
export type Customer_Addresses_Variance_Order_By = {
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_billing_format" */
export type Customer_Billing_Format = {
  __typename: 'customer_billing_format';
  /** An array relationship */
  customerBillingSettings: Array<Customer_Billing_Settings>;
  /** An aggregate relationship */
  customerBillingSettings_aggregate: Customer_Billing_Settings_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "customer_billing_format" */
export type Customer_Billing_FormatCustomerBillingSettingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Settings_Order_By>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};


/** columns and relationships of "customer_billing_format" */
export type Customer_Billing_FormatCustomerBillingSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Settings_Order_By>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};

/** aggregated selection of "customer_billing_format" */
export type Customer_Billing_Format_Aggregate = {
  __typename: 'customer_billing_format_aggregate';
  aggregate?: Maybe<Customer_Billing_Format_Aggregate_Fields>;
  nodes: Array<Customer_Billing_Format>;
};

/** aggregate fields of "customer_billing_format" */
export type Customer_Billing_Format_Aggregate_Fields = {
  __typename: 'customer_billing_format_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Billing_Format_Max_Fields>;
  min?: Maybe<Customer_Billing_Format_Min_Fields>;
};


/** aggregate fields of "customer_billing_format" */
export type Customer_Billing_Format_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Billing_Format_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_billing_format". All fields are combined with a logical 'AND'. */
export type Customer_Billing_Format_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Billing_Format_Bool_Exp>>;
  _not?: InputMaybe<Customer_Billing_Format_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Billing_Format_Bool_Exp>>;
  customerBillingSettings?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
  customerBillingSettings_aggregate?: InputMaybe<Customer_Billing_Settings_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Customer_Billing_Format_Enum {
  Compact = 'COMPACT',
  Detailed = 'DETAILED',
  DetailedWithDeliveryNote = 'DETAILED_WITH_DELIVERY_NOTE'
}

/** Boolean expression to compare columns of type "customer_billing_format_enum". All fields are combined with logical 'AND'. */
export type Customer_Billing_Format_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Customer_Billing_Format_Enum>;
  _in?: InputMaybe<Array<Customer_Billing_Format_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Customer_Billing_Format_Enum>;
  _nin?: InputMaybe<Array<Customer_Billing_Format_Enum>>;
};

/** aggregate max on columns */
export type Customer_Billing_Format_Max_Fields = {
  __typename: 'customer_billing_format_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Billing_Format_Min_Fields = {
  __typename: 'customer_billing_format_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "customer_billing_format". */
export type Customer_Billing_Format_Order_By = {
  customerBillingSettings_aggregate?: InputMaybe<Customer_Billing_Settings_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "customer_billing_format" */
export enum Customer_Billing_Format_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "customer_billing_format" */
export type Customer_Billing_Format_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Billing_Format_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Billing_Format_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "customer_billing_frequency" */
export type Customer_Billing_Frequency = {
  __typename: 'customer_billing_frequency';
  /** An array relationship */
  customerBillingSettings: Array<Customer_Billing_Settings>;
  /** An aggregate relationship */
  customerBillingSettings_aggregate: Customer_Billing_Settings_Aggregate;
  /** An array relationship */
  customerInvoices: Array<Customer_Invoices>;
  /** An aggregate relationship */
  customerInvoices_aggregate: Customer_Invoices_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "customer_billing_frequency" */
export type Customer_Billing_FrequencyCustomerBillingSettingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Settings_Order_By>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};


/** columns and relationships of "customer_billing_frequency" */
export type Customer_Billing_FrequencyCustomerBillingSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Settings_Order_By>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};


/** columns and relationships of "customer_billing_frequency" */
export type Customer_Billing_FrequencyCustomerInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoices_Order_By>>;
  where?: InputMaybe<Customer_Invoices_Bool_Exp>;
};


/** columns and relationships of "customer_billing_frequency" */
export type Customer_Billing_FrequencyCustomerInvoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoices_Order_By>>;
  where?: InputMaybe<Customer_Invoices_Bool_Exp>;
};

/** aggregated selection of "customer_billing_frequency" */
export type Customer_Billing_Frequency_Aggregate = {
  __typename: 'customer_billing_frequency_aggregate';
  aggregate?: Maybe<Customer_Billing_Frequency_Aggregate_Fields>;
  nodes: Array<Customer_Billing_Frequency>;
};

/** aggregate fields of "customer_billing_frequency" */
export type Customer_Billing_Frequency_Aggregate_Fields = {
  __typename: 'customer_billing_frequency_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Billing_Frequency_Max_Fields>;
  min?: Maybe<Customer_Billing_Frequency_Min_Fields>;
};


/** aggregate fields of "customer_billing_frequency" */
export type Customer_Billing_Frequency_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Billing_Frequency_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_billing_frequency". All fields are combined with a logical 'AND'. */
export type Customer_Billing_Frequency_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Billing_Frequency_Bool_Exp>>;
  _not?: InputMaybe<Customer_Billing_Frequency_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Billing_Frequency_Bool_Exp>>;
  customerBillingSettings?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
  customerBillingSettings_aggregate?: InputMaybe<Customer_Billing_Settings_Aggregate_Bool_Exp>;
  customerInvoices?: InputMaybe<Customer_Invoices_Bool_Exp>;
  customerInvoices_aggregate?: InputMaybe<Customer_Invoices_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Customer_Billing_Frequency_Enum {
  Day = 'DAY',
  DayDirect = 'DAY_DIRECT',
  Direct = 'DIRECT',
  HalfMonth = 'HALF_MONTH',
  Month = 'MONTH',
  Week = 'WEEK'
}

/** Boolean expression to compare columns of type "customer_billing_frequency_enum". All fields are combined with logical 'AND'. */
export type Customer_Billing_Frequency_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Customer_Billing_Frequency_Enum>;
  _in?: InputMaybe<Array<Customer_Billing_Frequency_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Customer_Billing_Frequency_Enum>;
  _nin?: InputMaybe<Array<Customer_Billing_Frequency_Enum>>;
};

/** aggregate max on columns */
export type Customer_Billing_Frequency_Max_Fields = {
  __typename: 'customer_billing_frequency_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Billing_Frequency_Min_Fields = {
  __typename: 'customer_billing_frequency_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "customer_billing_frequency". */
export type Customer_Billing_Frequency_Order_By = {
  customerBillingSettings_aggregate?: InputMaybe<Customer_Billing_Settings_Aggregate_Order_By>;
  customerInvoices_aggregate?: InputMaybe<Customer_Invoices_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "customer_billing_frequency" */
export enum Customer_Billing_Frequency_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "customer_billing_frequency" */
export type Customer_Billing_Frequency_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Billing_Frequency_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Billing_Frequency_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "customer_billing_send_strategies" */
export type Customer_Billing_Send_Strategies = {
  __typename: 'customer_billing_send_strategies';
  /** An array relationship */
  customerBillingSettings: Array<Customer_Billing_Settings>;
  /** An aggregate relationship */
  customerBillingSettings_aggregate: Customer_Billing_Settings_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "customer_billing_send_strategies" */
export type Customer_Billing_Send_StrategiesCustomerBillingSettingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Settings_Order_By>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};


/** columns and relationships of "customer_billing_send_strategies" */
export type Customer_Billing_Send_StrategiesCustomerBillingSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Settings_Order_By>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};

/** aggregated selection of "customer_billing_send_strategies" */
export type Customer_Billing_Send_Strategies_Aggregate = {
  __typename: 'customer_billing_send_strategies_aggregate';
  aggregate?: Maybe<Customer_Billing_Send_Strategies_Aggregate_Fields>;
  nodes: Array<Customer_Billing_Send_Strategies>;
};

/** aggregate fields of "customer_billing_send_strategies" */
export type Customer_Billing_Send_Strategies_Aggregate_Fields = {
  __typename: 'customer_billing_send_strategies_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Billing_Send_Strategies_Max_Fields>;
  min?: Maybe<Customer_Billing_Send_Strategies_Min_Fields>;
};


/** aggregate fields of "customer_billing_send_strategies" */
export type Customer_Billing_Send_Strategies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Billing_Send_Strategies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_billing_send_strategies". All fields are combined with a logical 'AND'. */
export type Customer_Billing_Send_Strategies_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Billing_Send_Strategies_Bool_Exp>>;
  _not?: InputMaybe<Customer_Billing_Send_Strategies_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Billing_Send_Strategies_Bool_Exp>>;
  customerBillingSettings?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
  customerBillingSettings_aggregate?: InputMaybe<Customer_Billing_Settings_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Customer_Billing_Send_Strategies_Enum {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL'
}

/** Boolean expression to compare columns of type "customer_billing_send_strategies_enum". All fields are combined with logical 'AND'. */
export type Customer_Billing_Send_Strategies_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Customer_Billing_Send_Strategies_Enum>;
  _in?: InputMaybe<Array<Customer_Billing_Send_Strategies_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Customer_Billing_Send_Strategies_Enum>;
  _nin?: InputMaybe<Array<Customer_Billing_Send_Strategies_Enum>>;
};

/** aggregate max on columns */
export type Customer_Billing_Send_Strategies_Max_Fields = {
  __typename: 'customer_billing_send_strategies_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Billing_Send_Strategies_Min_Fields = {
  __typename: 'customer_billing_send_strategies_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "customer_billing_send_strategies". */
export type Customer_Billing_Send_Strategies_Order_By = {
  customerBillingSettings_aggregate?: InputMaybe<Customer_Billing_Settings_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "customer_billing_send_strategies" */
export enum Customer_Billing_Send_Strategies_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "customer_billing_send_strategies" */
export type Customer_Billing_Send_Strategies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Billing_Send_Strategies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Billing_Send_Strategies_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "customer_billing_settings" */
export type Customer_Billing_Settings = {
  __typename: 'customer_billing_settings';
  /** An object relationship */
  Tenant: Tenants;
  billingFormat: Customer_Billing_Format_Enum;
  billingFrequency: Customer_Billing_Frequency_Enum;
  chargeVat: Scalars['Boolean'];
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  creditAccount: Scalars['String'];
  currency: Currencies_Enum;
  /** An array relationship */
  customers: Array<Customers>;
  /** An aggregate relationship */
  customers_aggregate: Customers_Aggregate;
  id: Scalars['bigint'];
  /** An object relationship */
  incomingBankAccount?: Maybe<Tenant_Bank_Accounts>;
  incomingBankAccountId?: Maybe<Scalars['bigint']>;
  paymentMethod: Customer_Payment_Method_Enum;
  /** An object relationship */
  paymentTerm?: Maybe<Customer_Payment_Terms>;
  paymentTermId?: Maybe<Scalars['bigint']>;
  sendStrategy: Customer_Billing_Send_Strategies_Enum;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "customer_billing_settings" */
export type Customer_Billing_SettingsCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "customer_billing_settings" */
export type Customer_Billing_SettingsCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};

/** aggregated selection of "customer_billing_settings" */
export type Customer_Billing_Settings_Aggregate = {
  __typename: 'customer_billing_settings_aggregate';
  aggregate?: Maybe<Customer_Billing_Settings_Aggregate_Fields>;
  nodes: Array<Customer_Billing_Settings>;
};

export type Customer_Billing_Settings_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Customer_Billing_Settings_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Customer_Billing_Settings_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Customer_Billing_Settings_Aggregate_Bool_Exp_Count>;
};

export type Customer_Billing_Settings_Aggregate_Bool_Exp_Bool_And = {
  arguments: Customer_Billing_Settings_Select_Column_Customer_Billing_Settings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Billing_Settings_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Customer_Billing_Settings_Select_Column_Customer_Billing_Settings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Billing_Settings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_billing_settings" */
export type Customer_Billing_Settings_Aggregate_Fields = {
  __typename: 'customer_billing_settings_aggregate_fields';
  avg?: Maybe<Customer_Billing_Settings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Billing_Settings_Max_Fields>;
  min?: Maybe<Customer_Billing_Settings_Min_Fields>;
  stddev?: Maybe<Customer_Billing_Settings_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Billing_Settings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Billing_Settings_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Billing_Settings_Sum_Fields>;
  var_pop?: Maybe<Customer_Billing_Settings_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Billing_Settings_Var_Samp_Fields>;
  variance?: Maybe<Customer_Billing_Settings_Variance_Fields>;
};


/** aggregate fields of "customer_billing_settings" */
export type Customer_Billing_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_billing_settings" */
export type Customer_Billing_Settings_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Billing_Settings_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Billing_Settings_Max_Order_By>;
  min?: InputMaybe<Customer_Billing_Settings_Min_Order_By>;
  stddev?: InputMaybe<Customer_Billing_Settings_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Billing_Settings_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Billing_Settings_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Billing_Settings_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Billing_Settings_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Billing_Settings_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Billing_Settings_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_billing_settings" */
export type Customer_Billing_Settings_Arr_Rel_Insert_Input = {
  data: Array<Customer_Billing_Settings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Billing_Settings_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Billing_Settings_Avg_Fields = {
  __typename: 'customer_billing_settings_avg_fields';
  id?: Maybe<Scalars['Float']>;
  incomingBankAccountId?: Maybe<Scalars['Float']>;
  paymentTermId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_billing_settings" */
export type Customer_Billing_Settings_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  incomingBankAccountId?: InputMaybe<Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_billing_settings". All fields are combined with a logical 'AND'. */
export type Customer_Billing_Settings_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Billing_Settings_Bool_Exp>>;
  _not?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Billing_Settings_Bool_Exp>>;
  billingFormat?: InputMaybe<Customer_Billing_Format_Enum_Comparison_Exp>;
  billingFrequency?: InputMaybe<Customer_Billing_Frequency_Enum_Comparison_Exp>;
  chargeVat?: InputMaybe<Boolean_Comparison_Exp>;
  contactEmail?: InputMaybe<String_Comparison_Exp>;
  contactName?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  creditAccount?: InputMaybe<String_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  customers?: InputMaybe<Customers_Bool_Exp>;
  customers_aggregate?: InputMaybe<Customers_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  incomingBankAccount?: InputMaybe<Tenant_Bank_Accounts_Bool_Exp>;
  incomingBankAccountId?: InputMaybe<Bigint_Comparison_Exp>;
  paymentMethod?: InputMaybe<Customer_Payment_Method_Enum_Comparison_Exp>;
  paymentTerm?: InputMaybe<Customer_Payment_Terms_Bool_Exp>;
  paymentTermId?: InputMaybe<Bigint_Comparison_Exp>;
  sendStrategy?: InputMaybe<Customer_Billing_Send_Strategies_Enum_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_billing_settings" */
export enum Customer_Billing_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerBillingSettingsPkey = 'customer_billing_settings_pkey'
}

/** input type for incrementing numeric columns in table "customer_billing_settings" */
export type Customer_Billing_Settings_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  incomingBankAccountId?: InputMaybe<Scalars['bigint']>;
  paymentTermId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_billing_settings" */
export type Customer_Billing_Settings_Insert_Input = {
  billingFormat?: InputMaybe<Customer_Billing_Format_Enum>;
  billingFrequency?: InputMaybe<Customer_Billing_Frequency_Enum>;
  chargeVat?: InputMaybe<Scalars['Boolean']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  creditAccount?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Currencies_Enum>;
  customers?: InputMaybe<Customers_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  incomingBankAccount?: InputMaybe<Tenant_Bank_Accounts_Obj_Rel_Insert_Input>;
  incomingBankAccountId?: InputMaybe<Scalars['bigint']>;
  paymentMethod?: InputMaybe<Customer_Payment_Method_Enum>;
  paymentTerm?: InputMaybe<Customer_Payment_Terms_Obj_Rel_Insert_Input>;
  paymentTermId?: InputMaybe<Scalars['bigint']>;
  sendStrategy?: InputMaybe<Customer_Billing_Send_Strategies_Enum>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Customer_Billing_Settings_Max_Fields = {
  __typename: 'customer_billing_settings_max_fields';
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creditAccount?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  incomingBankAccountId?: Maybe<Scalars['bigint']>;
  paymentTermId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_billing_settings" */
export type Customer_Billing_Settings_Max_Order_By = {
  contactEmail?: InputMaybe<Order_By>;
  contactName?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creditAccount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incomingBankAccountId?: InputMaybe<Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Billing_Settings_Min_Fields = {
  __typename: 'customer_billing_settings_min_fields';
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creditAccount?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  incomingBankAccountId?: Maybe<Scalars['bigint']>;
  paymentTermId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_billing_settings" */
export type Customer_Billing_Settings_Min_Order_By = {
  contactEmail?: InputMaybe<Order_By>;
  contactName?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creditAccount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incomingBankAccountId?: InputMaybe<Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_billing_settings" */
export type Customer_Billing_Settings_Mutation_Response = {
  __typename: 'customer_billing_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Billing_Settings>;
};

/** input type for inserting object relation for remote table "customer_billing_settings" */
export type Customer_Billing_Settings_Obj_Rel_Insert_Input = {
  data: Customer_Billing_Settings_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Billing_Settings_On_Conflict>;
};

/** on_conflict condition type for table "customer_billing_settings" */
export type Customer_Billing_Settings_On_Conflict = {
  constraint: Customer_Billing_Settings_Constraint;
  update_columns?: Array<Customer_Billing_Settings_Update_Column>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_billing_settings". */
export type Customer_Billing_Settings_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  billingFormat?: InputMaybe<Order_By>;
  billingFrequency?: InputMaybe<Order_By>;
  chargeVat?: InputMaybe<Order_By>;
  contactEmail?: InputMaybe<Order_By>;
  contactName?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creditAccount?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  customers_aggregate?: InputMaybe<Customers_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  incomingBankAccount?: InputMaybe<Tenant_Bank_Accounts_Order_By>;
  incomingBankAccountId?: InputMaybe<Order_By>;
  paymentMethod?: InputMaybe<Order_By>;
  paymentTerm?: InputMaybe<Customer_Payment_Terms_Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  sendStrategy?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_billing_settings */
export type Customer_Billing_Settings_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customer_billing_settings" */
export enum Customer_Billing_Settings_Select_Column {
  /** column name */
  BillingFormat = 'billingFormat',
  /** column name */
  BillingFrequency = 'billingFrequency',
  /** column name */
  ChargeVat = 'chargeVat',
  /** column name */
  ContactEmail = 'contactEmail',
  /** column name */
  ContactName = 'contactName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreditAccount = 'creditAccount',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  IncomingBankAccountId = 'incomingBankAccountId',
  /** column name */
  PaymentMethod = 'paymentMethod',
  /** column name */
  PaymentTermId = 'paymentTermId',
  /** column name */
  SendStrategy = 'sendStrategy',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** select "customer_billing_settings_aggregate_bool_exp_bool_and_arguments_columns" columns of table "customer_billing_settings" */
export enum Customer_Billing_Settings_Select_Column_Customer_Billing_Settings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ChargeVat = 'chargeVat'
}

/** select "customer_billing_settings_aggregate_bool_exp_bool_or_arguments_columns" columns of table "customer_billing_settings" */
export enum Customer_Billing_Settings_Select_Column_Customer_Billing_Settings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ChargeVat = 'chargeVat'
}

/** input type for updating data in table "customer_billing_settings" */
export type Customer_Billing_Settings_Set_Input = {
  billingFormat?: InputMaybe<Customer_Billing_Format_Enum>;
  billingFrequency?: InputMaybe<Customer_Billing_Frequency_Enum>;
  chargeVat?: InputMaybe<Scalars['Boolean']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  creditAccount?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Currencies_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  incomingBankAccountId?: InputMaybe<Scalars['bigint']>;
  paymentMethod?: InputMaybe<Customer_Payment_Method_Enum>;
  paymentTermId?: InputMaybe<Scalars['bigint']>;
  sendStrategy?: InputMaybe<Customer_Billing_Send_Strategies_Enum>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Customer_Billing_Settings_Stddev_Fields = {
  __typename: 'customer_billing_settings_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  incomingBankAccountId?: Maybe<Scalars['Float']>;
  paymentTermId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_billing_settings" */
export type Customer_Billing_Settings_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  incomingBankAccountId?: InputMaybe<Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Billing_Settings_Stddev_Pop_Fields = {
  __typename: 'customer_billing_settings_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  incomingBankAccountId?: Maybe<Scalars['Float']>;
  paymentTermId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_billing_settings" */
export type Customer_Billing_Settings_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  incomingBankAccountId?: InputMaybe<Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Billing_Settings_Stddev_Samp_Fields = {
  __typename: 'customer_billing_settings_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  incomingBankAccountId?: Maybe<Scalars['Float']>;
  paymentTermId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_billing_settings" */
export type Customer_Billing_Settings_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  incomingBankAccountId?: InputMaybe<Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_billing_settings" */
export type Customer_Billing_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Billing_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Billing_Settings_Stream_Cursor_Value_Input = {
  billingFormat?: InputMaybe<Customer_Billing_Format_Enum>;
  billingFrequency?: InputMaybe<Customer_Billing_Frequency_Enum>;
  chargeVat?: InputMaybe<Scalars['Boolean']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  creditAccount?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Currencies_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  incomingBankAccountId?: InputMaybe<Scalars['bigint']>;
  paymentMethod?: InputMaybe<Customer_Payment_Method_Enum>;
  paymentTermId?: InputMaybe<Scalars['bigint']>;
  sendStrategy?: InputMaybe<Customer_Billing_Send_Strategies_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Billing_Settings_Sum_Fields = {
  __typename: 'customer_billing_settings_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  incomingBankAccountId?: Maybe<Scalars['bigint']>;
  paymentTermId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_billing_settings" */
export type Customer_Billing_Settings_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  incomingBankAccountId?: InputMaybe<Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "customer_billing_settings" */
export enum Customer_Billing_Settings_Update_Column {
  /** column name */
  BillingFormat = 'billingFormat',
  /** column name */
  BillingFrequency = 'billingFrequency',
  /** column name */
  ChargeVat = 'chargeVat',
  /** column name */
  ContactEmail = 'contactEmail',
  /** column name */
  ContactName = 'contactName',
  /** column name */
  CreditAccount = 'creditAccount',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  IncomingBankAccountId = 'incomingBankAccountId',
  /** column name */
  PaymentMethod = 'paymentMethod',
  /** column name */
  PaymentTermId = 'paymentTermId',
  /** column name */
  SendStrategy = 'sendStrategy',
  /** column name */
  Version = 'version'
}

export type Customer_Billing_Settings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Billing_Settings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Billing_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Billing_Settings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Billing_Settings_Var_Pop_Fields = {
  __typename: 'customer_billing_settings_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  incomingBankAccountId?: Maybe<Scalars['Float']>;
  paymentTermId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_billing_settings" */
export type Customer_Billing_Settings_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  incomingBankAccountId?: InputMaybe<Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Billing_Settings_Var_Samp_Fields = {
  __typename: 'customer_billing_settings_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  incomingBankAccountId?: Maybe<Scalars['Float']>;
  paymentTermId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_billing_settings" */
export type Customer_Billing_Settings_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  incomingBankAccountId?: InputMaybe<Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Billing_Settings_Variance_Fields = {
  __typename: 'customer_billing_settings_variance_fields';
  id?: Maybe<Scalars['Float']>;
  incomingBankAccountId?: Maybe<Scalars['Float']>;
  paymentTermId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_billing_settings" */
export type Customer_Billing_Settings_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  incomingBankAccountId?: InputMaybe<Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_delivery_availability" */
export type Customer_Delivery_Availability = {
  __typename: 'customer_delivery_availability';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customer: Customers;
  customerId: Scalars['bigint'];
  id: Scalars['bigint'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
  workingDay: Working_Days_Enum;
};

/** aggregated selection of "customer_delivery_availability" */
export type Customer_Delivery_Availability_Aggregate = {
  __typename: 'customer_delivery_availability_aggregate';
  aggregate?: Maybe<Customer_Delivery_Availability_Aggregate_Fields>;
  nodes: Array<Customer_Delivery_Availability>;
};

export type Customer_Delivery_Availability_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Delivery_Availability_Aggregate_Bool_Exp_Count>;
};

export type Customer_Delivery_Availability_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Delivery_Availability_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Delivery_Availability_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_delivery_availability" */
export type Customer_Delivery_Availability_Aggregate_Fields = {
  __typename: 'customer_delivery_availability_aggregate_fields';
  avg?: Maybe<Customer_Delivery_Availability_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Delivery_Availability_Max_Fields>;
  min?: Maybe<Customer_Delivery_Availability_Min_Fields>;
  stddev?: Maybe<Customer_Delivery_Availability_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Delivery_Availability_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Delivery_Availability_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Delivery_Availability_Sum_Fields>;
  var_pop?: Maybe<Customer_Delivery_Availability_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Delivery_Availability_Var_Samp_Fields>;
  variance?: Maybe<Customer_Delivery_Availability_Variance_Fields>;
};


/** aggregate fields of "customer_delivery_availability" */
export type Customer_Delivery_Availability_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Delivery_Availability_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_delivery_availability" */
export type Customer_Delivery_Availability_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Delivery_Availability_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Delivery_Availability_Max_Order_By>;
  min?: InputMaybe<Customer_Delivery_Availability_Min_Order_By>;
  stddev?: InputMaybe<Customer_Delivery_Availability_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Delivery_Availability_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Delivery_Availability_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Delivery_Availability_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Delivery_Availability_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Delivery_Availability_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Delivery_Availability_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_delivery_availability" */
export type Customer_Delivery_Availability_Arr_Rel_Insert_Input = {
  data: Array<Customer_Delivery_Availability_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Delivery_Availability_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Delivery_Availability_Avg_Fields = {
  __typename: 'customer_delivery_availability_avg_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_delivery_availability" */
export type Customer_Delivery_Availability_Avg_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_delivery_availability". All fields are combined with a logical 'AND'. */
export type Customer_Delivery_Availability_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Delivery_Availability_Bool_Exp>>;
  _not?: InputMaybe<Customer_Delivery_Availability_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Delivery_Availability_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer?: InputMaybe<Customers_Bool_Exp>;
  customerId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
  workingDay?: InputMaybe<Working_Days_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_delivery_availability" */
export enum Customer_Delivery_Availability_Constraint {
  /** unique or primary key constraint on columns "working_day", "customer_id" */
  CustomerDeliveryAvailabilityCustomerIdWorkingDayKey = 'customer_delivery_availability_customer_id_working_day_key',
  /** unique or primary key constraint on columns "id" */
  CustomerDeliveryAvailabilityPkey = 'customer_delivery_availability_pkey'
}

/** input type for incrementing numeric columns in table "customer_delivery_availability" */
export type Customer_Delivery_Availability_Inc_Input = {
  customerId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_delivery_availability" */
export type Customer_Delivery_Availability_Insert_Input = {
  customer?: InputMaybe<Customers_Obj_Rel_Insert_Input>;
  customerId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  workingDay?: InputMaybe<Working_Days_Enum>;
};

/** aggregate max on columns */
export type Customer_Delivery_Availability_Max_Fields = {
  __typename: 'customer_delivery_availability_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_delivery_availability" */
export type Customer_Delivery_Availability_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Delivery_Availability_Min_Fields = {
  __typename: 'customer_delivery_availability_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_delivery_availability" */
export type Customer_Delivery_Availability_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_delivery_availability" */
export type Customer_Delivery_Availability_Mutation_Response = {
  __typename: 'customer_delivery_availability_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Delivery_Availability>;
};

/** on_conflict condition type for table "customer_delivery_availability" */
export type Customer_Delivery_Availability_On_Conflict = {
  constraint: Customer_Delivery_Availability_Constraint;
  update_columns?: Array<Customer_Delivery_Availability_Update_Column>;
  where?: InputMaybe<Customer_Delivery_Availability_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_delivery_availability". */
export type Customer_Delivery_Availability_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customer?: InputMaybe<Customers_Order_By>;
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  workingDay?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_delivery_availability */
export type Customer_Delivery_Availability_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customer_delivery_availability" */
export enum Customer_Delivery_Availability_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Id = 'id',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version',
  /** column name */
  WorkingDay = 'workingDay'
}

/** input type for updating data in table "customer_delivery_availability" */
export type Customer_Delivery_Availability_Set_Input = {
  customerId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  workingDay?: InputMaybe<Working_Days_Enum>;
};

/** aggregate stddev on columns */
export type Customer_Delivery_Availability_Stddev_Fields = {
  __typename: 'customer_delivery_availability_stddev_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_delivery_availability" */
export type Customer_Delivery_Availability_Stddev_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Delivery_Availability_Stddev_Pop_Fields = {
  __typename: 'customer_delivery_availability_stddev_pop_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_delivery_availability" */
export type Customer_Delivery_Availability_Stddev_Pop_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Delivery_Availability_Stddev_Samp_Fields = {
  __typename: 'customer_delivery_availability_stddev_samp_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_delivery_availability" */
export type Customer_Delivery_Availability_Stddev_Samp_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_delivery_availability" */
export type Customer_Delivery_Availability_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Delivery_Availability_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Delivery_Availability_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
  workingDay?: InputMaybe<Working_Days_Enum>;
};

/** aggregate sum on columns */
export type Customer_Delivery_Availability_Sum_Fields = {
  __typename: 'customer_delivery_availability_sum_fields';
  customerId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_delivery_availability" */
export type Customer_Delivery_Availability_Sum_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "customer_delivery_availability" */
export enum Customer_Delivery_Availability_Update_Column {
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Id = 'id',
  /** column name */
  Version = 'version',
  /** column name */
  WorkingDay = 'workingDay'
}

export type Customer_Delivery_Availability_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Delivery_Availability_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Delivery_Availability_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Delivery_Availability_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Delivery_Availability_Var_Pop_Fields = {
  __typename: 'customer_delivery_availability_var_pop_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_delivery_availability" */
export type Customer_Delivery_Availability_Var_Pop_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Delivery_Availability_Var_Samp_Fields = {
  __typename: 'customer_delivery_availability_var_samp_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_delivery_availability" */
export type Customer_Delivery_Availability_Var_Samp_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Delivery_Availability_Variance_Fields = {
  __typename: 'customer_delivery_availability_variance_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_delivery_availability" */
export type Customer_Delivery_Availability_Variance_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_delivery_note_formats" */
export type Customer_Delivery_Note_Formats = {
  __typename: 'customer_delivery_note_formats';
  /** An array relationship */
  customers: Array<Customers>;
  /** An aggregate relationship */
  customers_aggregate: Customers_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "customer_delivery_note_formats" */
export type Customer_Delivery_Note_FormatsCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "customer_delivery_note_formats" */
export type Customer_Delivery_Note_FormatsCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};

/** aggregated selection of "customer_delivery_note_formats" */
export type Customer_Delivery_Note_Formats_Aggregate = {
  __typename: 'customer_delivery_note_formats_aggregate';
  aggregate?: Maybe<Customer_Delivery_Note_Formats_Aggregate_Fields>;
  nodes: Array<Customer_Delivery_Note_Formats>;
};

/** aggregate fields of "customer_delivery_note_formats" */
export type Customer_Delivery_Note_Formats_Aggregate_Fields = {
  __typename: 'customer_delivery_note_formats_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Delivery_Note_Formats_Max_Fields>;
  min?: Maybe<Customer_Delivery_Note_Formats_Min_Fields>;
};


/** aggregate fields of "customer_delivery_note_formats" */
export type Customer_Delivery_Note_Formats_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Delivery_Note_Formats_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_delivery_note_formats". All fields are combined with a logical 'AND'. */
export type Customer_Delivery_Note_Formats_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Delivery_Note_Formats_Bool_Exp>>;
  _not?: InputMaybe<Customer_Delivery_Note_Formats_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Delivery_Note_Formats_Bool_Exp>>;
  customers?: InputMaybe<Customers_Bool_Exp>;
  customers_aggregate?: InputMaybe<Customers_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Customer_Delivery_Note_Formats_Enum {
  WithoutPriceWithContainer = 'WITHOUT_PRICE_WITH_CONTAINER',
  WithExpirationDays = 'WITH_EXPIRATION_DAYS',
  WithPriceWithContainer = 'WITH_PRICE_WITH_CONTAINER'
}

/** Boolean expression to compare columns of type "customer_delivery_note_formats_enum". All fields are combined with logical 'AND'. */
export type Customer_Delivery_Note_Formats_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Customer_Delivery_Note_Formats_Enum>;
  _in?: InputMaybe<Array<Customer_Delivery_Note_Formats_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Customer_Delivery_Note_Formats_Enum>;
  _nin?: InputMaybe<Array<Customer_Delivery_Note_Formats_Enum>>;
};

/** aggregate max on columns */
export type Customer_Delivery_Note_Formats_Max_Fields = {
  __typename: 'customer_delivery_note_formats_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Delivery_Note_Formats_Min_Fields = {
  __typename: 'customer_delivery_note_formats_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "customer_delivery_note_formats". */
export type Customer_Delivery_Note_Formats_Order_By = {
  customers_aggregate?: InputMaybe<Customers_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "customer_delivery_note_formats" */
export enum Customer_Delivery_Note_Formats_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "customer_delivery_note_formats" */
export type Customer_Delivery_Note_Formats_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Delivery_Note_Formats_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Delivery_Note_Formats_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "customer_group_pricing" */
export type Customer_Group_Pricing = {
  __typename: 'customer_group_pricing';
  /** An object relationship */
  Tenant: Tenants;
  activatedAt?: Maybe<Scalars['timestamptz']>;
  costPriceCalculatedAt?: Maybe<Scalars['date']>;
  costPricePerUnit?: Maybe<Scalars['bigint']>;
  createdAt: Scalars['timestamptz'];
  currency: Currencies_Enum;
  /** An object relationship */
  customerGroup: Customer_Groups;
  customerGroupId: Scalars['bigint'];
  /** An array relationship */
  dailyPriceListsViaCustomerGroupPricing: Array<Daily_Price_Lists>;
  /** An aggregate relationship */
  dailyPriceListsViaCustomerGroupPricing_aggregate: Daily_Price_Lists_Aggregate;
  /** An array relationship */
  dailyPriceListsViaLastCustomerGroupPricing: Array<Daily_Price_Lists>;
  /** An aggregate relationship */
  dailyPriceListsViaLastCustomerGroupPricing_aggregate: Daily_Price_Lists_Aggregate;
  id: Scalars['bigint'];
  isPromotion: Scalars['Boolean'];
  margin?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  salesPricePerUnit: Scalars['bigint'];
  /** An object relationship */
  salesUnit: Product_Units;
  salesUnitId: Scalars['bigint'];
  state: Temporal_Pricing_States_Enum;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  valid: Scalars['daterange'];
  version: Scalars['bigint'];
};


/** columns and relationships of "customer_group_pricing" */
export type Customer_Group_PricingDailyPriceListsViaCustomerGroupPricingArgs = {
  distinct_on?: InputMaybe<Array<Daily_Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Price_Lists_Order_By>>;
  where?: InputMaybe<Daily_Price_Lists_Bool_Exp>;
};


/** columns and relationships of "customer_group_pricing" */
export type Customer_Group_PricingDailyPriceListsViaCustomerGroupPricing_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Price_Lists_Order_By>>;
  where?: InputMaybe<Daily_Price_Lists_Bool_Exp>;
};


/** columns and relationships of "customer_group_pricing" */
export type Customer_Group_PricingDailyPriceListsViaLastCustomerGroupPricingArgs = {
  distinct_on?: InputMaybe<Array<Daily_Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Price_Lists_Order_By>>;
  where?: InputMaybe<Daily_Price_Lists_Bool_Exp>;
};


/** columns and relationships of "customer_group_pricing" */
export type Customer_Group_PricingDailyPriceListsViaLastCustomerGroupPricing_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Price_Lists_Order_By>>;
  where?: InputMaybe<Daily_Price_Lists_Bool_Exp>;
};

/** aggregated selection of "customer_group_pricing" */
export type Customer_Group_Pricing_Aggregate = {
  __typename: 'customer_group_pricing_aggregate';
  aggregate?: Maybe<Customer_Group_Pricing_Aggregate_Fields>;
  nodes: Array<Customer_Group_Pricing>;
};

export type Customer_Group_Pricing_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Customer_Group_Pricing_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Customer_Group_Pricing_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Customer_Group_Pricing_Aggregate_Bool_Exp_Count>;
};

export type Customer_Group_Pricing_Aggregate_Bool_Exp_Bool_And = {
  arguments: Customer_Group_Pricing_Select_Column_Customer_Group_Pricing_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Group_Pricing_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Customer_Group_Pricing_Select_Column_Customer_Group_Pricing_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Group_Pricing_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_group_pricing" */
export type Customer_Group_Pricing_Aggregate_Fields = {
  __typename: 'customer_group_pricing_aggregate_fields';
  avg?: Maybe<Customer_Group_Pricing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Group_Pricing_Max_Fields>;
  min?: Maybe<Customer_Group_Pricing_Min_Fields>;
  stddev?: Maybe<Customer_Group_Pricing_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Group_Pricing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Group_Pricing_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Group_Pricing_Sum_Fields>;
  var_pop?: Maybe<Customer_Group_Pricing_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Group_Pricing_Var_Samp_Fields>;
  variance?: Maybe<Customer_Group_Pricing_Variance_Fields>;
};


/** aggregate fields of "customer_group_pricing" */
export type Customer_Group_Pricing_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_group_pricing" */
export type Customer_Group_Pricing_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Group_Pricing_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Group_Pricing_Max_Order_By>;
  min?: InputMaybe<Customer_Group_Pricing_Min_Order_By>;
  stddev?: InputMaybe<Customer_Group_Pricing_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Group_Pricing_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Group_Pricing_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Group_Pricing_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Group_Pricing_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Group_Pricing_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Group_Pricing_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_group_pricing" */
export type Customer_Group_Pricing_Arr_Rel_Insert_Input = {
  data: Array<Customer_Group_Pricing_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Group_Pricing_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Group_Pricing_Avg_Fields = {
  __typename: 'customer_group_pricing_avg_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  margin?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesPricePerUnit?: Maybe<Scalars['Float']>;
  salesUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_group_pricing" */
export type Customer_Group_Pricing_Avg_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  margin?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPricePerUnit?: InputMaybe<Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_group_pricing". All fields are combined with a logical 'AND'. */
export type Customer_Group_Pricing_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Group_Pricing_Bool_Exp>>;
  _not?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Group_Pricing_Bool_Exp>>;
  activatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  costPriceCalculatedAt?: InputMaybe<Date_Comparison_Exp>;
  costPricePerUnit?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  customerGroup?: InputMaybe<Customer_Groups_Bool_Exp>;
  customerGroupId?: InputMaybe<Bigint_Comparison_Exp>;
  dailyPriceListsViaCustomerGroupPricing?: InputMaybe<Daily_Price_Lists_Bool_Exp>;
  dailyPriceListsViaCustomerGroupPricing_aggregate?: InputMaybe<Daily_Price_Lists_Aggregate_Bool_Exp>;
  dailyPriceListsViaLastCustomerGroupPricing?: InputMaybe<Daily_Price_Lists_Bool_Exp>;
  dailyPriceListsViaLastCustomerGroupPricing_aggregate?: InputMaybe<Daily_Price_Lists_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  isPromotion?: InputMaybe<Boolean_Comparison_Exp>;
  margin?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  salesPricePerUnit?: InputMaybe<Bigint_Comparison_Exp>;
  salesUnit?: InputMaybe<Product_Units_Bool_Exp>;
  salesUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<Temporal_Pricing_States_Enum_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid?: InputMaybe<Daterange_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_group_pricing" */
export enum Customer_Group_Pricing_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerGroupPricingPkey = 'customer_group_pricing_pkey',
  /** unique or primary key constraint on columns "valid", "state", "customer_group_id", "sales_unit_id", "product_id", "tenant" */
  CustomerGroupPricingTenantCustomerGroupIdProductIdIdx = 'customer_group_pricing_tenant_customer_group_id_product_id__idx'
}

/** input type for incrementing numeric columns in table "customer_group_pricing" */
export type Customer_Group_Pricing_Inc_Input = {
  costPricePerUnit?: InputMaybe<Scalars['bigint']>;
  customerGroupId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  salesPricePerUnit?: InputMaybe<Scalars['bigint']>;
  salesUnitId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_group_pricing" */
export type Customer_Group_Pricing_Insert_Input = {
  activatedAt?: InputMaybe<Scalars['timestamptz']>;
  costPriceCalculatedAt?: InputMaybe<Scalars['date']>;
  costPricePerUnit?: InputMaybe<Scalars['bigint']>;
  currency?: InputMaybe<Currencies_Enum>;
  customerGroup?: InputMaybe<Customer_Groups_Obj_Rel_Insert_Input>;
  customerGroupId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  isPromotion?: InputMaybe<Scalars['Boolean']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  salesPricePerUnit?: InputMaybe<Scalars['bigint']>;
  salesUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  salesUnitId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Temporal_Pricing_States_Enum>;
  valid?: InputMaybe<Scalars['daterange']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Customer_Group_Pricing_Max_Fields = {
  __typename: 'customer_group_pricing_max_fields';
  activatedAt?: Maybe<Scalars['timestamptz']>;
  costPriceCalculatedAt?: Maybe<Scalars['date']>;
  costPricePerUnit?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerGroupId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  margin?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  salesPricePerUnit?: Maybe<Scalars['bigint']>;
  salesUnitId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_group_pricing" */
export type Customer_Group_Pricing_Max_Order_By = {
  activatedAt?: InputMaybe<Order_By>;
  costPriceCalculatedAt?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  margin?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPricePerUnit?: InputMaybe<Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Group_Pricing_Min_Fields = {
  __typename: 'customer_group_pricing_min_fields';
  activatedAt?: Maybe<Scalars['timestamptz']>;
  costPriceCalculatedAt?: Maybe<Scalars['date']>;
  costPricePerUnit?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerGroupId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  margin?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  salesPricePerUnit?: Maybe<Scalars['bigint']>;
  salesUnitId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_group_pricing" */
export type Customer_Group_Pricing_Min_Order_By = {
  activatedAt?: InputMaybe<Order_By>;
  costPriceCalculatedAt?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  margin?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPricePerUnit?: InputMaybe<Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_group_pricing" */
export type Customer_Group_Pricing_Mutation_Response = {
  __typename: 'customer_group_pricing_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Group_Pricing>;
};

/** on_conflict condition type for table "customer_group_pricing" */
export type Customer_Group_Pricing_On_Conflict = {
  constraint: Customer_Group_Pricing_Constraint;
  update_columns?: Array<Customer_Group_Pricing_Update_Column>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_group_pricing". */
export type Customer_Group_Pricing_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  activatedAt?: InputMaybe<Order_By>;
  costPriceCalculatedAt?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  customerGroup?: InputMaybe<Customer_Groups_Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  dailyPriceListsViaCustomerGroupPricing_aggregate?: InputMaybe<Daily_Price_Lists_Aggregate_Order_By>;
  dailyPriceListsViaLastCustomerGroupPricing_aggregate?: InputMaybe<Daily_Price_Lists_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  isPromotion?: InputMaybe<Order_By>;
  margin?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPricePerUnit?: InputMaybe<Order_By>;
  salesUnit?: InputMaybe<Product_Units_Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  valid?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_group_pricing */
export type Customer_Group_Pricing_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customer_group_pricing" */
export enum Customer_Group_Pricing_Select_Column {
  /** column name */
  ActivatedAt = 'activatedAt',
  /** column name */
  CostPriceCalculatedAt = 'costPriceCalculatedAt',
  /** column name */
  CostPricePerUnit = 'costPricePerUnit',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerGroupId = 'customerGroupId',
  /** column name */
  Id = 'id',
  /** column name */
  IsPromotion = 'isPromotion',
  /** column name */
  Margin = 'margin',
  /** column name */
  ProductId = 'productId',
  /** column name */
  SalesPricePerUnit = 'salesPricePerUnit',
  /** column name */
  SalesUnitId = 'salesUnitId',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Valid = 'valid',
  /** column name */
  Version = 'version'
}

/** select "customer_group_pricing_aggregate_bool_exp_bool_and_arguments_columns" columns of table "customer_group_pricing" */
export enum Customer_Group_Pricing_Select_Column_Customer_Group_Pricing_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPromotion = 'isPromotion'
}

/** select "customer_group_pricing_aggregate_bool_exp_bool_or_arguments_columns" columns of table "customer_group_pricing" */
export enum Customer_Group_Pricing_Select_Column_Customer_Group_Pricing_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPromotion = 'isPromotion'
}

/** input type for updating data in table "customer_group_pricing" */
export type Customer_Group_Pricing_Set_Input = {
  activatedAt?: InputMaybe<Scalars['timestamptz']>;
  costPriceCalculatedAt?: InputMaybe<Scalars['date']>;
  costPricePerUnit?: InputMaybe<Scalars['bigint']>;
  currency?: InputMaybe<Currencies_Enum>;
  customerGroupId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  isPromotion?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['bigint']>;
  salesPricePerUnit?: InputMaybe<Scalars['bigint']>;
  salesUnitId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Temporal_Pricing_States_Enum>;
  valid?: InputMaybe<Scalars['daterange']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Customer_Group_Pricing_Stddev_Fields = {
  __typename: 'customer_group_pricing_stddev_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  margin?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesPricePerUnit?: Maybe<Scalars['Float']>;
  salesUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_group_pricing" */
export type Customer_Group_Pricing_Stddev_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  margin?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPricePerUnit?: InputMaybe<Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Group_Pricing_Stddev_Pop_Fields = {
  __typename: 'customer_group_pricing_stddev_pop_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  margin?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesPricePerUnit?: Maybe<Scalars['Float']>;
  salesUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_group_pricing" */
export type Customer_Group_Pricing_Stddev_Pop_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  margin?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPricePerUnit?: InputMaybe<Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Group_Pricing_Stddev_Samp_Fields = {
  __typename: 'customer_group_pricing_stddev_samp_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  margin?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesPricePerUnit?: Maybe<Scalars['Float']>;
  salesUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_group_pricing" */
export type Customer_Group_Pricing_Stddev_Samp_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  margin?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPricePerUnit?: InputMaybe<Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_group_pricing" */
export type Customer_Group_Pricing_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Group_Pricing_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Group_Pricing_Stream_Cursor_Value_Input = {
  activatedAt?: InputMaybe<Scalars['timestamptz']>;
  costPriceCalculatedAt?: InputMaybe<Scalars['date']>;
  costPricePerUnit?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Currencies_Enum>;
  customerGroupId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  isPromotion?: InputMaybe<Scalars['Boolean']>;
  margin?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  salesPricePerUnit?: InputMaybe<Scalars['bigint']>;
  salesUnitId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Temporal_Pricing_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  valid?: InputMaybe<Scalars['daterange']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Group_Pricing_Sum_Fields = {
  __typename: 'customer_group_pricing_sum_fields';
  costPricePerUnit?: Maybe<Scalars['bigint']>;
  customerGroupId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  margin?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  salesPricePerUnit?: Maybe<Scalars['bigint']>;
  salesUnitId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_group_pricing" */
export type Customer_Group_Pricing_Sum_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  margin?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPricePerUnit?: InputMaybe<Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "customer_group_pricing" */
export enum Customer_Group_Pricing_Update_Column {
  /** column name */
  ActivatedAt = 'activatedAt',
  /** column name */
  CostPriceCalculatedAt = 'costPriceCalculatedAt',
  /** column name */
  CostPricePerUnit = 'costPricePerUnit',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerGroupId = 'customerGroupId',
  /** column name */
  Id = 'id',
  /** column name */
  IsPromotion = 'isPromotion',
  /** column name */
  ProductId = 'productId',
  /** column name */
  SalesPricePerUnit = 'salesPricePerUnit',
  /** column name */
  SalesUnitId = 'salesUnitId',
  /** column name */
  State = 'state',
  /** column name */
  Valid = 'valid',
  /** column name */
  Version = 'version'
}

export type Customer_Group_Pricing_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Group_Pricing_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Group_Pricing_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Group_Pricing_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Group_Pricing_Var_Pop_Fields = {
  __typename: 'customer_group_pricing_var_pop_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  margin?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesPricePerUnit?: Maybe<Scalars['Float']>;
  salesUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_group_pricing" */
export type Customer_Group_Pricing_Var_Pop_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  margin?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPricePerUnit?: InputMaybe<Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Group_Pricing_Var_Samp_Fields = {
  __typename: 'customer_group_pricing_var_samp_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  margin?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesPricePerUnit?: Maybe<Scalars['Float']>;
  salesUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_group_pricing" */
export type Customer_Group_Pricing_Var_Samp_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  margin?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPricePerUnit?: InputMaybe<Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Group_Pricing_Variance_Fields = {
  __typename: 'customer_group_pricing_variance_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  margin?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesPricePerUnit?: Maybe<Scalars['Float']>;
  salesUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_group_pricing" */
export type Customer_Group_Pricing_Variance_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  margin?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPricePerUnit?: InputMaybe<Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

export type Customer_Group_Pricing_Within_Range_Args = {
  _range?: InputMaybe<Scalars['daterange']>;
};

/** columns and relationships of "customer_group_state_transitions" */
export type Customer_Group_State_Transitions = {
  __typename: 'customer_group_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Customer_Group_States_Enum;
  toState: Customer_Group_States_Enum;
};

/** aggregated selection of "customer_group_state_transitions" */
export type Customer_Group_State_Transitions_Aggregate = {
  __typename: 'customer_group_state_transitions_aggregate';
  aggregate?: Maybe<Customer_Group_State_Transitions_Aggregate_Fields>;
  nodes: Array<Customer_Group_State_Transitions>;
};

/** aggregate fields of "customer_group_state_transitions" */
export type Customer_Group_State_Transitions_Aggregate_Fields = {
  __typename: 'customer_group_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Group_State_Transitions_Max_Fields>;
  min?: Maybe<Customer_Group_State_Transitions_Min_Fields>;
};


/** aggregate fields of "customer_group_state_transitions" */
export type Customer_Group_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Group_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_group_state_transitions". All fields are combined with a logical 'AND'. */
export type Customer_Group_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Group_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Customer_Group_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Group_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Customer_Group_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Customer_Group_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Customer_Group_State_Transitions_Max_Fields = {
  __typename: 'customer_group_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Group_State_Transitions_Min_Fields = {
  __typename: 'customer_group_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "customer_group_state_transitions". */
export type Customer_Group_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "customer_group_state_transitions" */
export enum Customer_Group_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "customer_group_state_transitions" */
export type Customer_Group_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Group_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Group_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Customer_Group_States_Enum>;
  toState?: InputMaybe<Customer_Group_States_Enum>;
};

/** columns and relationships of "customer_group_states" */
export type Customer_Group_States = {
  __typename: 'customer_group_states';
  /** An array relationship */
  customerGroups: Array<Customer_Groups>;
  /** An aggregate relationship */
  customerGroups_aggregate: Customer_Groups_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "customer_group_states" */
export type Customer_Group_StatesCustomerGroupsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Groups_Order_By>>;
  where?: InputMaybe<Customer_Groups_Bool_Exp>;
};


/** columns and relationships of "customer_group_states" */
export type Customer_Group_StatesCustomerGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Groups_Order_By>>;
  where?: InputMaybe<Customer_Groups_Bool_Exp>;
};

/** aggregated selection of "customer_group_states" */
export type Customer_Group_States_Aggregate = {
  __typename: 'customer_group_states_aggregate';
  aggregate?: Maybe<Customer_Group_States_Aggregate_Fields>;
  nodes: Array<Customer_Group_States>;
};

/** aggregate fields of "customer_group_states" */
export type Customer_Group_States_Aggregate_Fields = {
  __typename: 'customer_group_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Group_States_Max_Fields>;
  min?: Maybe<Customer_Group_States_Min_Fields>;
};


/** aggregate fields of "customer_group_states" */
export type Customer_Group_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Group_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_group_states". All fields are combined with a logical 'AND'. */
export type Customer_Group_States_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Group_States_Bool_Exp>>;
  _not?: InputMaybe<Customer_Group_States_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Group_States_Bool_Exp>>;
  customerGroups?: InputMaybe<Customer_Groups_Bool_Exp>;
  customerGroups_aggregate?: InputMaybe<Customer_Groups_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Customer_Group_States_Enum {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE'
}

/** Boolean expression to compare columns of type "customer_group_states_enum". All fields are combined with logical 'AND'. */
export type Customer_Group_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Customer_Group_States_Enum>;
  _in?: InputMaybe<Array<Customer_Group_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Customer_Group_States_Enum>;
  _nin?: InputMaybe<Array<Customer_Group_States_Enum>>;
};

/** aggregate max on columns */
export type Customer_Group_States_Max_Fields = {
  __typename: 'customer_group_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Group_States_Min_Fields = {
  __typename: 'customer_group_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "customer_group_states". */
export type Customer_Group_States_Order_By = {
  customerGroups_aggregate?: InputMaybe<Customer_Groups_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "customer_group_states" */
export enum Customer_Group_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "customer_group_states" */
export type Customer_Group_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Group_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Group_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "customer_groups" */
export type Customer_Groups = {
  __typename: 'customer_groups';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  customerGroupPricings: Array<Customer_Group_Pricing>;
  /** An aggregate relationship */
  customerGroupPricings_aggregate: Customer_Group_Pricing_Aggregate;
  /** An array relationship */
  customerProductAvailabilities: Array<Customer_Product_Availabilities>;
  /** An aggregate relationship */
  customerProductAvailabilities_aggregate: Customer_Product_Availabilities_Aggregate;
  /** An array relationship */
  customers: Array<Customers>;
  /** An array relationship */
  customersViaPriceCustomerGroup: Array<Customers>;
  /** An aggregate relationship */
  customersViaPriceCustomerGroup_aggregate: Customers_Aggregate;
  /** An aggregate relationship */
  customers_aggregate: Customers_Aggregate;
  domainId: Scalars['String'];
  /** An array relationship */
  globalSearchResults: Array<Global_Search_Result>;
  /** An aggregate relationship */
  globalSearchResults_aggregate: Global_Search_Result_Aggregate;
  i18n: Scalars['jsonb'];
  id: Scalars['bigint'];
  /** The name used internally for searching customer groups, never communicated externally */
  internalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ordinalPosition: Scalars['Int'];
  /** An array relationship */
  priceLists: Array<Price_Lists>;
  /** An aggregate relationship */
  priceLists_aggregate: Price_Lists_Aggregate;
  protected: Scalars['Boolean'];
  state: Customer_Group_States_Enum;
  tenant: Scalars['String'];
};


/** columns and relationships of "customer_groups" */
export type Customer_GroupsCustomerGroupPricingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


/** columns and relationships of "customer_groups" */
export type Customer_GroupsCustomerGroupPricings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


/** columns and relationships of "customer_groups" */
export type Customer_GroupsCustomerProductAvailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Product_Availabilities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Product_Availabilities_Order_By>>;
  where?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
};


/** columns and relationships of "customer_groups" */
export type Customer_GroupsCustomerProductAvailabilities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Product_Availabilities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Product_Availabilities_Order_By>>;
  where?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
};


/** columns and relationships of "customer_groups" */
export type Customer_GroupsCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "customer_groups" */
export type Customer_GroupsCustomersViaPriceCustomerGroupArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "customer_groups" */
export type Customer_GroupsCustomersViaPriceCustomerGroup_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "customer_groups" */
export type Customer_GroupsCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "customer_groups" */
export type Customer_GroupsGlobalSearchResultsArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


/** columns and relationships of "customer_groups" */
export type Customer_GroupsGlobalSearchResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


/** columns and relationships of "customer_groups" */
export type Customer_GroupsI18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "customer_groups" */
export type Customer_GroupsPriceListsArgs = {
  distinct_on?: InputMaybe<Array<Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Lists_Order_By>>;
  where?: InputMaybe<Price_Lists_Bool_Exp>;
};


/** columns and relationships of "customer_groups" */
export type Customer_GroupsPriceLists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Lists_Order_By>>;
  where?: InputMaybe<Price_Lists_Bool_Exp>;
};

/** aggregated selection of "customer_groups" */
export type Customer_Groups_Aggregate = {
  __typename: 'customer_groups_aggregate';
  aggregate?: Maybe<Customer_Groups_Aggregate_Fields>;
  nodes: Array<Customer_Groups>;
};

export type Customer_Groups_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Customer_Groups_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Customer_Groups_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Customer_Groups_Aggregate_Bool_Exp_Count>;
};

export type Customer_Groups_Aggregate_Bool_Exp_Bool_And = {
  arguments: Customer_Groups_Select_Column_Customer_Groups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Groups_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Groups_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Customer_Groups_Select_Column_Customer_Groups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Groups_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Groups_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Groups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_groups" */
export type Customer_Groups_Aggregate_Fields = {
  __typename: 'customer_groups_aggregate_fields';
  avg?: Maybe<Customer_Groups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Groups_Max_Fields>;
  min?: Maybe<Customer_Groups_Min_Fields>;
  stddev?: Maybe<Customer_Groups_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Groups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Groups_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Groups_Sum_Fields>;
  var_pop?: Maybe<Customer_Groups_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Groups_Var_Samp_Fields>;
  variance?: Maybe<Customer_Groups_Variance_Fields>;
};


/** aggregate fields of "customer_groups" */
export type Customer_Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_groups" */
export type Customer_Groups_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Groups_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Groups_Max_Order_By>;
  min?: InputMaybe<Customer_Groups_Min_Order_By>;
  stddev?: InputMaybe<Customer_Groups_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Groups_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Groups_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Groups_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Groups_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Groups_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Groups_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Customer_Groups_Append_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Customer_Groups_Avg_Fields = {
  __typename: 'customer_groups_avg_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_groups" */
export type Customer_Groups_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_groups". All fields are combined with a logical 'AND'. */
export type Customer_Groups_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Groups_Bool_Exp>>;
  _not?: InputMaybe<Customer_Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Groups_Bool_Exp>>;
  customerGroupPricings?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
  customerGroupPricings_aggregate?: InputMaybe<Customer_Group_Pricing_Aggregate_Bool_Exp>;
  customerProductAvailabilities?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
  customerProductAvailabilities_aggregate?: InputMaybe<Customer_Product_Availabilities_Aggregate_Bool_Exp>;
  customers?: InputMaybe<Customers_Bool_Exp>;
  customersViaPriceCustomerGroup?: InputMaybe<Customers_Bool_Exp>;
  customersViaPriceCustomerGroup_aggregate?: InputMaybe<Customers_Aggregate_Bool_Exp>;
  customers_aggregate?: InputMaybe<Customers_Aggregate_Bool_Exp>;
  domainId?: InputMaybe<String_Comparison_Exp>;
  globalSearchResults?: InputMaybe<Global_Search_Result_Bool_Exp>;
  globalSearchResults_aggregate?: InputMaybe<Global_Search_Result_Aggregate_Bool_Exp>;
  i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  internalName?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  priceLists?: InputMaybe<Price_Lists_Bool_Exp>;
  priceLists_aggregate?: InputMaybe<Price_Lists_Aggregate_Bool_Exp>;
  protected?: InputMaybe<Boolean_Comparison_Exp>;
  state?: InputMaybe<Customer_Group_States_Enum_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_groups" */
export enum Customer_Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerGroupsPkey = 'customer_groups_pkey',
  /** unique or primary key constraint on columns "tenant", "domain_id" */
  CustomerGroupsTenantDomainId = 'customer_groups_tenant_domain_id',
  /** unique or primary key constraint on columns "name", "tenant" */
  CustomerGroupsTenantNameKey = 'customer_groups_tenant_name_key',
  /** unique or primary key constraint on columns "tenant", "ordinal_position" */
  CustomerGroupsTenantOrdinalPositionUniq = 'customer_groups_tenant_ordinal_position_uniq'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Customer_Groups_Delete_At_Path_Input = {
  i18n?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Customer_Groups_Delete_Elem_Input = {
  i18n?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Customer_Groups_Delete_Key_Input = {
  i18n?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "customer_groups" */
export type Customer_Groups_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "customer_groups" */
export type Customer_Groups_Insert_Input = {
  customerGroupPricings?: InputMaybe<Customer_Group_Pricing_Arr_Rel_Insert_Input>;
  customerProductAvailabilities?: InputMaybe<Customer_Product_Availabilities_Arr_Rel_Insert_Input>;
  customers?: InputMaybe<Customers_Arr_Rel_Insert_Input>;
  customersViaPriceCustomerGroup?: InputMaybe<Customers_Arr_Rel_Insert_Input>;
  domainId?: InputMaybe<Scalars['String']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  priceLists?: InputMaybe<Price_Lists_Arr_Rel_Insert_Input>;
  protected?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Customer_Group_States_Enum>;
};

/** aggregate max on columns */
export type Customer_Groups_Max_Fields = {
  __typename: 'customer_groups_max_fields';
  domainId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "customer_groups" */
export type Customer_Groups_Max_Order_By = {
  domainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Groups_Min_Fields = {
  __typename: 'customer_groups_min_fields';
  domainId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "customer_groups" */
export type Customer_Groups_Min_Order_By = {
  domainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_groups" */
export type Customer_Groups_Mutation_Response = {
  __typename: 'customer_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Groups>;
};

/** input type for inserting object relation for remote table "customer_groups" */
export type Customer_Groups_Obj_Rel_Insert_Input = {
  data: Customer_Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Groups_On_Conflict>;
};

/** on_conflict condition type for table "customer_groups" */
export type Customer_Groups_On_Conflict = {
  constraint: Customer_Groups_Constraint;
  update_columns?: Array<Customer_Groups_Update_Column>;
  where?: InputMaybe<Customer_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_groups". */
export type Customer_Groups_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  customerGroupPricings_aggregate?: InputMaybe<Customer_Group_Pricing_Aggregate_Order_By>;
  customerProductAvailabilities_aggregate?: InputMaybe<Customer_Product_Availabilities_Aggregate_Order_By>;
  customersViaPriceCustomerGroup_aggregate?: InputMaybe<Customers_Aggregate_Order_By>;
  customers_aggregate?: InputMaybe<Customers_Aggregate_Order_By>;
  domainId?: InputMaybe<Order_By>;
  globalSearchResults_aggregate?: InputMaybe<Global_Search_Result_Aggregate_Order_By>;
  i18n?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internalName?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  priceLists_aggregate?: InputMaybe<Price_Lists_Aggregate_Order_By>;
  protected?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_groups */
export type Customer_Groups_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Customer_Groups_Prepend_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "customer_groups" */
export enum Customer_Groups_Select_Column {
  /** column name */
  DomainId = 'domainId',
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  Protected = 'protected',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant'
}

/** select "customer_groups_aggregate_bool_exp_bool_and_arguments_columns" columns of table "customer_groups" */
export enum Customer_Groups_Select_Column_Customer_Groups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Protected = 'protected'
}

/** select "customer_groups_aggregate_bool_exp_bool_or_arguments_columns" columns of table "customer_groups" */
export enum Customer_Groups_Select_Column_Customer_Groups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Protected = 'protected'
}

/** input type for updating data in table "customer_groups" */
export type Customer_Groups_Set_Input = {
  domainId?: InputMaybe<Scalars['String']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  protected?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Customer_Group_States_Enum>;
};

/** aggregate stddev on columns */
export type Customer_Groups_Stddev_Fields = {
  __typename: 'customer_groups_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_groups" */
export type Customer_Groups_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Groups_Stddev_Pop_Fields = {
  __typename: 'customer_groups_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_groups" */
export type Customer_Groups_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Groups_Stddev_Samp_Fields = {
  __typename: 'customer_groups_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_groups" */
export type Customer_Groups_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_groups" */
export type Customer_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Groups_Stream_Cursor_Value_Input = {
  domainId?: InputMaybe<Scalars['String']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  protected?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Customer_Group_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Customer_Groups_Sum_Fields = {
  __typename: 'customer_groups_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "customer_groups" */
export type Customer_Groups_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** update columns of table "customer_groups" */
export enum Customer_Groups_Update_Column {
  /** column name */
  DomainId = 'domainId',
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  Protected = 'protected',
  /** column name */
  State = 'state'
}

export type Customer_Groups_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Customer_Groups_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Customer_Groups_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Customer_Groups_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Customer_Groups_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Groups_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Customer_Groups_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Groups_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Groups_Var_Pop_Fields = {
  __typename: 'customer_groups_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_groups" */
export type Customer_Groups_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Groups_Var_Samp_Fields = {
  __typename: 'customer_groups_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_groups" */
export type Customer_Groups_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Groups_Variance_Fields = {
  __typename: 'customer_groups_variance_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_groups" */
export type Customer_Groups_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_invoice_logged_actions" */
export type Customer_Invoice_Logged_Actions = {
  __typename: 'customer_invoice_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  entity?: Maybe<Customer_Invoices>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "customer_invoice_logged_actions" */
export type Customer_Invoice_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "customer_invoice_logged_actions" */
export type Customer_Invoice_Logged_Actions_Aggregate = {
  __typename: 'customer_invoice_logged_actions_aggregate';
  aggregate?: Maybe<Customer_Invoice_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Customer_Invoice_Logged_Actions>;
};

export type Customer_Invoice_Logged_Actions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Invoice_Logged_Actions_Aggregate_Bool_Exp_Count>;
};

export type Customer_Invoice_Logged_Actions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Invoice_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Invoice_Logged_Actions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_invoice_logged_actions" */
export type Customer_Invoice_Logged_Actions_Aggregate_Fields = {
  __typename: 'customer_invoice_logged_actions_aggregate_fields';
  avg?: Maybe<Customer_Invoice_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Invoice_Logged_Actions_Max_Fields>;
  min?: Maybe<Customer_Invoice_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Customer_Invoice_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Invoice_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Invoice_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Invoice_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Customer_Invoice_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Invoice_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Customer_Invoice_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "customer_invoice_logged_actions" */
export type Customer_Invoice_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Invoice_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_invoice_logged_actions" */
export type Customer_Invoice_Logged_Actions_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Invoice_Logged_Actions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Invoice_Logged_Actions_Max_Order_By>;
  min?: InputMaybe<Customer_Invoice_Logged_Actions_Min_Order_By>;
  stddev?: InputMaybe<Customer_Invoice_Logged_Actions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Invoice_Logged_Actions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Invoice_Logged_Actions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Invoice_Logged_Actions_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Invoice_Logged_Actions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Invoice_Logged_Actions_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Invoice_Logged_Actions_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Customer_Invoice_Logged_Actions_Avg_Fields = {
  __typename: 'customer_invoice_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_invoice_logged_actions" */
export type Customer_Invoice_Logged_Actions_Avg_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_invoice_logged_actions". All fields are combined with a logical 'AND'. */
export type Customer_Invoice_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Invoice_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Customer_Invoice_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Invoice_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entity?: InputMaybe<Customer_Invoices_Bool_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Customer_Invoice_Logged_Actions_Max_Fields = {
  __typename: 'customer_invoice_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_invoice_logged_actions" */
export type Customer_Invoice_Logged_Actions_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Invoice_Logged_Actions_Min_Fields = {
  __typename: 'customer_invoice_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_invoice_logged_actions" */
export type Customer_Invoice_Logged_Actions_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "customer_invoice_logged_actions". */
export type Customer_Invoice_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entity?: InputMaybe<Customer_Invoices_Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "customer_invoice_logged_actions" */
export enum Customer_Invoice_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Role = 'role',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Customer_Invoice_Logged_Actions_Stddev_Fields = {
  __typename: 'customer_invoice_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_invoice_logged_actions" */
export type Customer_Invoice_Logged_Actions_Stddev_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Invoice_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'customer_invoice_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_invoice_logged_actions" */
export type Customer_Invoice_Logged_Actions_Stddev_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Invoice_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'customer_invoice_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_invoice_logged_actions" */
export type Customer_Invoice_Logged_Actions_Stddev_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_invoice_logged_actions" */
export type Customer_Invoice_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Invoice_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Invoice_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  role?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Invoice_Logged_Actions_Sum_Fields = {
  __typename: 'customer_invoice_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_invoice_logged_actions" */
export type Customer_Invoice_Logged_Actions_Sum_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Customer_Invoice_Logged_Actions_Var_Pop_Fields = {
  __typename: 'customer_invoice_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_invoice_logged_actions" */
export type Customer_Invoice_Logged_Actions_Var_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Invoice_Logged_Actions_Var_Samp_Fields = {
  __typename: 'customer_invoice_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_invoice_logged_actions" */
export type Customer_Invoice_Logged_Actions_Var_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Invoice_Logged_Actions_Variance_Fields = {
  __typename: 'customer_invoice_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_invoice_logged_actions" */
export type Customer_Invoice_Logged_Actions_Variance_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_invoice_orders" */
export type Customer_Invoice_Orders = {
  __typename: 'customer_invoice_orders';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customerInvoice: Customer_Invoices;
  customerInvoiceId: Scalars['bigint'];
  /** An object relationship */
  customerOrder: Customer_Orders;
  customerOrderId: Scalars['bigint'];
  id: Scalars['bigint'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};

/** aggregated selection of "customer_invoice_orders" */
export type Customer_Invoice_Orders_Aggregate = {
  __typename: 'customer_invoice_orders_aggregate';
  aggregate?: Maybe<Customer_Invoice_Orders_Aggregate_Fields>;
  nodes: Array<Customer_Invoice_Orders>;
};

export type Customer_Invoice_Orders_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Invoice_Orders_Aggregate_Bool_Exp_Count>;
};

export type Customer_Invoice_Orders_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Invoice_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Invoice_Orders_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_invoice_orders" */
export type Customer_Invoice_Orders_Aggregate_Fields = {
  __typename: 'customer_invoice_orders_aggregate_fields';
  avg?: Maybe<Customer_Invoice_Orders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Invoice_Orders_Max_Fields>;
  min?: Maybe<Customer_Invoice_Orders_Min_Fields>;
  stddev?: Maybe<Customer_Invoice_Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Invoice_Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Invoice_Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Invoice_Orders_Sum_Fields>;
  var_pop?: Maybe<Customer_Invoice_Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Invoice_Orders_Var_Samp_Fields>;
  variance?: Maybe<Customer_Invoice_Orders_Variance_Fields>;
};


/** aggregate fields of "customer_invoice_orders" */
export type Customer_Invoice_Orders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Invoice_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_invoice_orders" */
export type Customer_Invoice_Orders_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Invoice_Orders_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Invoice_Orders_Max_Order_By>;
  min?: InputMaybe<Customer_Invoice_Orders_Min_Order_By>;
  stddev?: InputMaybe<Customer_Invoice_Orders_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Invoice_Orders_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Invoice_Orders_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Invoice_Orders_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Invoice_Orders_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Invoice_Orders_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Invoice_Orders_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_invoice_orders" */
export type Customer_Invoice_Orders_Arr_Rel_Insert_Input = {
  data: Array<Customer_Invoice_Orders_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Invoice_Orders_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Invoice_Orders_Avg_Fields = {
  __typename: 'customer_invoice_orders_avg_fields';
  customerInvoiceId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_invoice_orders" */
export type Customer_Invoice_Orders_Avg_Order_By = {
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_invoice_orders". All fields are combined with a logical 'AND'. */
export type Customer_Invoice_Orders_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Invoice_Orders_Bool_Exp>>;
  _not?: InputMaybe<Customer_Invoice_Orders_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Invoice_Orders_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerInvoice?: InputMaybe<Customer_Invoices_Bool_Exp>;
  customerInvoiceId?: InputMaybe<Bigint_Comparison_Exp>;
  customerOrder?: InputMaybe<Customer_Orders_Bool_Exp>;
  customerOrderId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_invoice_orders" */
export enum Customer_Invoice_Orders_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerInvoiceOrdersPkey = 'customer_invoice_orders_pkey'
}

/** input type for incrementing numeric columns in table "customer_invoice_orders" */
export type Customer_Invoice_Orders_Inc_Input = {
  customerInvoiceId?: InputMaybe<Scalars['bigint']>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_invoice_orders" */
export type Customer_Invoice_Orders_Insert_Input = {
  customerInvoiceId?: InputMaybe<Scalars['bigint']>;
  customerOrder?: InputMaybe<Customer_Orders_Obj_Rel_Insert_Input>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Customer_Invoice_Orders_Max_Fields = {
  __typename: 'customer_invoice_orders_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerInvoiceId?: Maybe<Scalars['bigint']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_invoice_orders" */
export type Customer_Invoice_Orders_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Invoice_Orders_Min_Fields = {
  __typename: 'customer_invoice_orders_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerInvoiceId?: Maybe<Scalars['bigint']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_invoice_orders" */
export type Customer_Invoice_Orders_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_invoice_orders" */
export type Customer_Invoice_Orders_Mutation_Response = {
  __typename: 'customer_invoice_orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Invoice_Orders>;
};

/** on_conflict condition type for table "customer_invoice_orders" */
export type Customer_Invoice_Orders_On_Conflict = {
  constraint: Customer_Invoice_Orders_Constraint;
  update_columns?: Array<Customer_Invoice_Orders_Update_Column>;
  where?: InputMaybe<Customer_Invoice_Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_invoice_orders". */
export type Customer_Invoice_Orders_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerInvoice?: InputMaybe<Customer_Invoices_Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrder?: InputMaybe<Customer_Orders_Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_invoice_orders */
export type Customer_Invoice_Orders_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customer_invoice_orders" */
export enum Customer_Invoice_Orders_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerInvoiceId = 'customerInvoiceId',
  /** column name */
  CustomerOrderId = 'customerOrderId',
  /** column name */
  Id = 'id',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "customer_invoice_orders" */
export type Customer_Invoice_Orders_Set_Input = {
  customerInvoiceId?: InputMaybe<Scalars['bigint']>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Customer_Invoice_Orders_Stddev_Fields = {
  __typename: 'customer_invoice_orders_stddev_fields';
  customerInvoiceId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_invoice_orders" */
export type Customer_Invoice_Orders_Stddev_Order_By = {
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Invoice_Orders_Stddev_Pop_Fields = {
  __typename: 'customer_invoice_orders_stddev_pop_fields';
  customerInvoiceId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_invoice_orders" */
export type Customer_Invoice_Orders_Stddev_Pop_Order_By = {
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Invoice_Orders_Stddev_Samp_Fields = {
  __typename: 'customer_invoice_orders_stddev_samp_fields';
  customerInvoiceId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_invoice_orders" */
export type Customer_Invoice_Orders_Stddev_Samp_Order_By = {
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_invoice_orders" */
export type Customer_Invoice_Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Invoice_Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Invoice_Orders_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerInvoiceId?: InputMaybe<Scalars['bigint']>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Invoice_Orders_Sum_Fields = {
  __typename: 'customer_invoice_orders_sum_fields';
  customerInvoiceId?: Maybe<Scalars['bigint']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_invoice_orders" */
export type Customer_Invoice_Orders_Sum_Order_By = {
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "customer_invoice_orders" */
export enum Customer_Invoice_Orders_Update_Column {
  /** column name */
  CustomerInvoiceId = 'customerInvoiceId',
  /** column name */
  CustomerOrderId = 'customerOrderId',
  /** column name */
  Id = 'id',
  /** column name */
  Version = 'version'
}

export type Customer_Invoice_Orders_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Invoice_Orders_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Invoice_Orders_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Invoice_Orders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Invoice_Orders_Var_Pop_Fields = {
  __typename: 'customer_invoice_orders_var_pop_fields';
  customerInvoiceId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_invoice_orders" */
export type Customer_Invoice_Orders_Var_Pop_Order_By = {
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Invoice_Orders_Var_Samp_Fields = {
  __typename: 'customer_invoice_orders_var_samp_fields';
  customerInvoiceId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_invoice_orders" */
export type Customer_Invoice_Orders_Var_Samp_Order_By = {
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Invoice_Orders_Variance_Fields = {
  __typename: 'customer_invoice_orders_variance_fields';
  customerInvoiceId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_invoice_orders" */
export type Customer_Invoice_Orders_Variance_Order_By = {
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_invoice_state_transitions" */
export type Customer_Invoice_State_Transitions = {
  __typename: 'customer_invoice_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Customer_Invoice_States_Enum;
  toState: Customer_Invoice_States_Enum;
};

/** aggregated selection of "customer_invoice_state_transitions" */
export type Customer_Invoice_State_Transitions_Aggregate = {
  __typename: 'customer_invoice_state_transitions_aggregate';
  aggregate?: Maybe<Customer_Invoice_State_Transitions_Aggregate_Fields>;
  nodes: Array<Customer_Invoice_State_Transitions>;
};

/** aggregate fields of "customer_invoice_state_transitions" */
export type Customer_Invoice_State_Transitions_Aggregate_Fields = {
  __typename: 'customer_invoice_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Invoice_State_Transitions_Max_Fields>;
  min?: Maybe<Customer_Invoice_State_Transitions_Min_Fields>;
};


/** aggregate fields of "customer_invoice_state_transitions" */
export type Customer_Invoice_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Invoice_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_invoice_state_transitions". All fields are combined with a logical 'AND'. */
export type Customer_Invoice_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Invoice_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Customer_Invoice_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Invoice_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Customer_Invoice_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Customer_Invoice_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Customer_Invoice_State_Transitions_Max_Fields = {
  __typename: 'customer_invoice_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Invoice_State_Transitions_Min_Fields = {
  __typename: 'customer_invoice_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "customer_invoice_state_transitions". */
export type Customer_Invoice_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "customer_invoice_state_transitions" */
export enum Customer_Invoice_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "customer_invoice_state_transitions" */
export type Customer_Invoice_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Invoice_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Invoice_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Customer_Invoice_States_Enum>;
  toState?: InputMaybe<Customer_Invoice_States_Enum>;
};

/** columns and relationships of "customer_invoice_states" */
export type Customer_Invoice_States = {
  __typename: 'customer_invoice_states';
  /** An array relationship */
  customerInvoices: Array<Customer_Invoices>;
  /** An aggregate relationship */
  customerInvoices_aggregate: Customer_Invoices_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "customer_invoice_states" */
export type Customer_Invoice_StatesCustomerInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoices_Order_By>>;
  where?: InputMaybe<Customer_Invoices_Bool_Exp>;
};


/** columns and relationships of "customer_invoice_states" */
export type Customer_Invoice_StatesCustomerInvoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoices_Order_By>>;
  where?: InputMaybe<Customer_Invoices_Bool_Exp>;
};

/** aggregated selection of "customer_invoice_states" */
export type Customer_Invoice_States_Aggregate = {
  __typename: 'customer_invoice_states_aggregate';
  aggregate?: Maybe<Customer_Invoice_States_Aggregate_Fields>;
  nodes: Array<Customer_Invoice_States>;
};

/** aggregate fields of "customer_invoice_states" */
export type Customer_Invoice_States_Aggregate_Fields = {
  __typename: 'customer_invoice_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Invoice_States_Max_Fields>;
  min?: Maybe<Customer_Invoice_States_Min_Fields>;
};


/** aggregate fields of "customer_invoice_states" */
export type Customer_Invoice_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Invoice_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_invoice_states". All fields are combined with a logical 'AND'. */
export type Customer_Invoice_States_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Invoice_States_Bool_Exp>>;
  _not?: InputMaybe<Customer_Invoice_States_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Invoice_States_Bool_Exp>>;
  customerInvoices?: InputMaybe<Customer_Invoices_Bool_Exp>;
  customerInvoices_aggregate?: InputMaybe<Customer_Invoices_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Customer_Invoice_States_Enum {
  Canceled = 'CANCELED',
  Open = 'OPEN',
  Prepared = 'PREPARED',
  SentToCustomer = 'SENT_TO_CUSTOMER'
}

/** Boolean expression to compare columns of type "customer_invoice_states_enum". All fields are combined with logical 'AND'. */
export type Customer_Invoice_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Customer_Invoice_States_Enum>;
  _in?: InputMaybe<Array<Customer_Invoice_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Customer_Invoice_States_Enum>;
  _nin?: InputMaybe<Array<Customer_Invoice_States_Enum>>;
};

/** aggregate max on columns */
export type Customer_Invoice_States_Max_Fields = {
  __typename: 'customer_invoice_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Invoice_States_Min_Fields = {
  __typename: 'customer_invoice_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "customer_invoice_states". */
export type Customer_Invoice_States_Order_By = {
  customerInvoices_aggregate?: InputMaybe<Customer_Invoices_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "customer_invoice_states" */
export enum Customer_Invoice_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "customer_invoice_states" */
export type Customer_Invoice_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Invoice_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Invoice_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "customer_invoice_vat_summary_results" */
export type Customer_Invoice_Vat_Summary_Results = {
  __typename: 'customer_invoice_vat_summary_results';
  vatBase: Scalars['bigint'];
  vatPct: Scalars['numeric'];
  vatTotal: Scalars['bigint'];
  vatType: Scalars['String'];
};

/** aggregated selection of "customer_invoice_vat_summary_results" */
export type Customer_Invoice_Vat_Summary_Results_Aggregate = {
  __typename: 'customer_invoice_vat_summary_results_aggregate';
  aggregate?: Maybe<Customer_Invoice_Vat_Summary_Results_Aggregate_Fields>;
  nodes: Array<Customer_Invoice_Vat_Summary_Results>;
};

/** aggregate fields of "customer_invoice_vat_summary_results" */
export type Customer_Invoice_Vat_Summary_Results_Aggregate_Fields = {
  __typename: 'customer_invoice_vat_summary_results_aggregate_fields';
  avg?: Maybe<Customer_Invoice_Vat_Summary_Results_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Invoice_Vat_Summary_Results_Max_Fields>;
  min?: Maybe<Customer_Invoice_Vat_Summary_Results_Min_Fields>;
  stddev?: Maybe<Customer_Invoice_Vat_Summary_Results_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Invoice_Vat_Summary_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Invoice_Vat_Summary_Results_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Invoice_Vat_Summary_Results_Sum_Fields>;
  var_pop?: Maybe<Customer_Invoice_Vat_Summary_Results_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Invoice_Vat_Summary_Results_Var_Samp_Fields>;
  variance?: Maybe<Customer_Invoice_Vat_Summary_Results_Variance_Fields>;
};


/** aggregate fields of "customer_invoice_vat_summary_results" */
export type Customer_Invoice_Vat_Summary_Results_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Invoice_Vat_Summary_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_invoice_vat_summary_results" */
export type Customer_Invoice_Vat_Summary_Results_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Invoice_Vat_Summary_Results_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Invoice_Vat_Summary_Results_Max_Order_By>;
  min?: InputMaybe<Customer_Invoice_Vat_Summary_Results_Min_Order_By>;
  stddev?: InputMaybe<Customer_Invoice_Vat_Summary_Results_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Invoice_Vat_Summary_Results_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Invoice_Vat_Summary_Results_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Invoice_Vat_Summary_Results_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Invoice_Vat_Summary_Results_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Invoice_Vat_Summary_Results_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Invoice_Vat_Summary_Results_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Customer_Invoice_Vat_Summary_Results_Avg_Fields = {
  __typename: 'customer_invoice_vat_summary_results_avg_fields';
  vatBase?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTotal?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_invoice_vat_summary_results" */
export type Customer_Invoice_Vat_Summary_Results_Avg_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_invoice_vat_summary_results". All fields are combined with a logical 'AND'. */
export type Customer_Invoice_Vat_Summary_Results_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Invoice_Vat_Summary_Results_Bool_Exp>>;
  _not?: InputMaybe<Customer_Invoice_Vat_Summary_Results_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Invoice_Vat_Summary_Results_Bool_Exp>>;
  vatBase?: InputMaybe<Bigint_Comparison_Exp>;
  vatPct?: InputMaybe<Numeric_Comparison_Exp>;
  vatTotal?: InputMaybe<Bigint_Comparison_Exp>;
  vatType?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Customer_Invoice_Vat_Summary_Results_Max_Fields = {
  __typename: 'customer_invoice_vat_summary_results_max_fields';
  vatBase?: Maybe<Scalars['bigint']>;
  vatPct?: Maybe<Scalars['numeric']>;
  vatTotal?: Maybe<Scalars['bigint']>;
  vatType?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "customer_invoice_vat_summary_results" */
export type Customer_Invoice_Vat_Summary_Results_Max_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
  vatType?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Invoice_Vat_Summary_Results_Min_Fields = {
  __typename: 'customer_invoice_vat_summary_results_min_fields';
  vatBase?: Maybe<Scalars['bigint']>;
  vatPct?: Maybe<Scalars['numeric']>;
  vatTotal?: Maybe<Scalars['bigint']>;
  vatType?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "customer_invoice_vat_summary_results" */
export type Customer_Invoice_Vat_Summary_Results_Min_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
  vatType?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "customer_invoice_vat_summary_results". */
export type Customer_Invoice_Vat_Summary_Results_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
  vatType?: InputMaybe<Order_By>;
};

/** select columns of table "customer_invoice_vat_summary_results" */
export enum Customer_Invoice_Vat_Summary_Results_Select_Column {
  /** column name */
  VatBase = 'vatBase',
  /** column name */
  VatPct = 'vatPct',
  /** column name */
  VatTotal = 'vatTotal',
  /** column name */
  VatType = 'vatType'
}

/** aggregate stddev on columns */
export type Customer_Invoice_Vat_Summary_Results_Stddev_Fields = {
  __typename: 'customer_invoice_vat_summary_results_stddev_fields';
  vatBase?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTotal?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_invoice_vat_summary_results" */
export type Customer_Invoice_Vat_Summary_Results_Stddev_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Invoice_Vat_Summary_Results_Stddev_Pop_Fields = {
  __typename: 'customer_invoice_vat_summary_results_stddev_pop_fields';
  vatBase?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTotal?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_invoice_vat_summary_results" */
export type Customer_Invoice_Vat_Summary_Results_Stddev_Pop_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Invoice_Vat_Summary_Results_Stddev_Samp_Fields = {
  __typename: 'customer_invoice_vat_summary_results_stddev_samp_fields';
  vatBase?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTotal?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_invoice_vat_summary_results" */
export type Customer_Invoice_Vat_Summary_Results_Stddev_Samp_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_invoice_vat_summary_results" */
export type Customer_Invoice_Vat_Summary_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Invoice_Vat_Summary_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Invoice_Vat_Summary_Results_Stream_Cursor_Value_Input = {
  vatBase?: InputMaybe<Scalars['bigint']>;
  vatPct?: InputMaybe<Scalars['numeric']>;
  vatTotal?: InputMaybe<Scalars['bigint']>;
  vatType?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Customer_Invoice_Vat_Summary_Results_Sum_Fields = {
  __typename: 'customer_invoice_vat_summary_results_sum_fields';
  vatBase?: Maybe<Scalars['bigint']>;
  vatPct?: Maybe<Scalars['numeric']>;
  vatTotal?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_invoice_vat_summary_results" */
export type Customer_Invoice_Vat_Summary_Results_Sum_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Customer_Invoice_Vat_Summary_Results_Var_Pop_Fields = {
  __typename: 'customer_invoice_vat_summary_results_var_pop_fields';
  vatBase?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTotal?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_invoice_vat_summary_results" */
export type Customer_Invoice_Vat_Summary_Results_Var_Pop_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Invoice_Vat_Summary_Results_Var_Samp_Fields = {
  __typename: 'customer_invoice_vat_summary_results_var_samp_fields';
  vatBase?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTotal?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_invoice_vat_summary_results" */
export type Customer_Invoice_Vat_Summary_Results_Var_Samp_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Invoice_Vat_Summary_Results_Variance_Fields = {
  __typename: 'customer_invoice_vat_summary_results_variance_fields';
  vatBase?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTotal?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_invoice_vat_summary_results" */
export type Customer_Invoice_Vat_Summary_Results_Variance_Order_By = {
  vatBase?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTotal?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_invoice_version_logged_actions" */
export type Customer_Invoice_Version_Logged_Actions = {
  __typename: 'customer_invoice_version_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  entity?: Maybe<Customer_Invoice_Versions>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "customer_invoice_version_logged_actions" */
export type Customer_Invoice_Version_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "customer_invoice_version_logged_actions" */
export type Customer_Invoice_Version_Logged_Actions_Aggregate = {
  __typename: 'customer_invoice_version_logged_actions_aggregate';
  aggregate?: Maybe<Customer_Invoice_Version_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Customer_Invoice_Version_Logged_Actions>;
};

export type Customer_Invoice_Version_Logged_Actions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Aggregate_Bool_Exp_Count>;
};

export type Customer_Invoice_Version_Logged_Actions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Invoice_Version_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_invoice_version_logged_actions" */
export type Customer_Invoice_Version_Logged_Actions_Aggregate_Fields = {
  __typename: 'customer_invoice_version_logged_actions_aggregate_fields';
  avg?: Maybe<Customer_Invoice_Version_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Invoice_Version_Logged_Actions_Max_Fields>;
  min?: Maybe<Customer_Invoice_Version_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Customer_Invoice_Version_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Invoice_Version_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Invoice_Version_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Invoice_Version_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Customer_Invoice_Version_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Invoice_Version_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Customer_Invoice_Version_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "customer_invoice_version_logged_actions" */
export type Customer_Invoice_Version_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Invoice_Version_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_invoice_version_logged_actions" */
export type Customer_Invoice_Version_Logged_Actions_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Max_Order_By>;
  min?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Min_Order_By>;
  stddev?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Customer_Invoice_Version_Logged_Actions_Avg_Fields = {
  __typename: 'customer_invoice_version_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_invoice_version_logged_actions" */
export type Customer_Invoice_Version_Logged_Actions_Avg_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_invoice_version_logged_actions". All fields are combined with a logical 'AND'. */
export type Customer_Invoice_Version_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Invoice_Version_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Invoice_Version_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entity?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Customer_Invoice_Version_Logged_Actions_Max_Fields = {
  __typename: 'customer_invoice_version_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_invoice_version_logged_actions" */
export type Customer_Invoice_Version_Logged_Actions_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Invoice_Version_Logged_Actions_Min_Fields = {
  __typename: 'customer_invoice_version_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_invoice_version_logged_actions" */
export type Customer_Invoice_Version_Logged_Actions_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "customer_invoice_version_logged_actions". */
export type Customer_Invoice_Version_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entity?: InputMaybe<Customer_Invoice_Versions_Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "customer_invoice_version_logged_actions" */
export enum Customer_Invoice_Version_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Role = 'role',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Customer_Invoice_Version_Logged_Actions_Stddev_Fields = {
  __typename: 'customer_invoice_version_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_invoice_version_logged_actions" */
export type Customer_Invoice_Version_Logged_Actions_Stddev_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Invoice_Version_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'customer_invoice_version_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_invoice_version_logged_actions" */
export type Customer_Invoice_Version_Logged_Actions_Stddev_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Invoice_Version_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'customer_invoice_version_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_invoice_version_logged_actions" */
export type Customer_Invoice_Version_Logged_Actions_Stddev_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_invoice_version_logged_actions" */
export type Customer_Invoice_Version_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Invoice_Version_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Invoice_Version_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  role?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Invoice_Version_Logged_Actions_Sum_Fields = {
  __typename: 'customer_invoice_version_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_invoice_version_logged_actions" */
export type Customer_Invoice_Version_Logged_Actions_Sum_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Customer_Invoice_Version_Logged_Actions_Var_Pop_Fields = {
  __typename: 'customer_invoice_version_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_invoice_version_logged_actions" */
export type Customer_Invoice_Version_Logged_Actions_Var_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Invoice_Version_Logged_Actions_Var_Samp_Fields = {
  __typename: 'customer_invoice_version_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_invoice_version_logged_actions" */
export type Customer_Invoice_Version_Logged_Actions_Var_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Invoice_Version_Logged_Actions_Variance_Fields = {
  __typename: 'customer_invoice_version_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_invoice_version_logged_actions" */
export type Customer_Invoice_Version_Logged_Actions_Variance_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_invoice_versions" */
export type Customer_Invoice_Versions = {
  __typename: 'customer_invoice_versions';
  /** An object relationship */
  Tenant: Tenants;
  contentHash?: Maybe<Scalars['bigint']>;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customerInvoice: Customer_Invoices;
  customerInvoiceId: Scalars['bigint'];
  id: Scalars['bigint'];
  invoiceVersion: Scalars['bigint'];
  /** An array relationship */
  loggedActions: Array<Customer_Invoice_Version_Logged_Actions>;
  /** An aggregate relationship */
  loggedActions_aggregate: Customer_Invoice_Version_Logged_Actions_Aggregate;
  /** An object relationship */
  pdfDocument?: Maybe<S3_Objects>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  pdfPrintDocument?: Maybe<S3_Objects>;
  pdfPrintDocumentId?: Maybe<Scalars['bigint']>;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
  /** An object relationship */
  xmlDocument?: Maybe<S3_Objects>;
  xmlDocumentId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "customer_invoice_versions" */
export type Customer_Invoice_VersionsLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Version_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Version_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "customer_invoice_versions" */
export type Customer_Invoice_VersionsLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Version_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Version_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Bool_Exp>;
};

/** aggregated selection of "customer_invoice_versions" */
export type Customer_Invoice_Versions_Aggregate = {
  __typename: 'customer_invoice_versions_aggregate';
  aggregate?: Maybe<Customer_Invoice_Versions_Aggregate_Fields>;
  nodes: Array<Customer_Invoice_Versions>;
};

export type Customer_Invoice_Versions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Invoice_Versions_Aggregate_Bool_Exp_Count>;
};

export type Customer_Invoice_Versions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Invoice_Versions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_invoice_versions" */
export type Customer_Invoice_Versions_Aggregate_Fields = {
  __typename: 'customer_invoice_versions_aggregate_fields';
  avg?: Maybe<Customer_Invoice_Versions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Invoice_Versions_Max_Fields>;
  min?: Maybe<Customer_Invoice_Versions_Min_Fields>;
  stddev?: Maybe<Customer_Invoice_Versions_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Invoice_Versions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Invoice_Versions_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Invoice_Versions_Sum_Fields>;
  var_pop?: Maybe<Customer_Invoice_Versions_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Invoice_Versions_Var_Samp_Fields>;
  variance?: Maybe<Customer_Invoice_Versions_Variance_Fields>;
};


/** aggregate fields of "customer_invoice_versions" */
export type Customer_Invoice_Versions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Invoice_Versions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_invoice_versions" */
export type Customer_Invoice_Versions_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Invoice_Versions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Invoice_Versions_Max_Order_By>;
  min?: InputMaybe<Customer_Invoice_Versions_Min_Order_By>;
  stddev?: InputMaybe<Customer_Invoice_Versions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Invoice_Versions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Invoice_Versions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Invoice_Versions_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Invoice_Versions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Invoice_Versions_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Invoice_Versions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_invoice_versions" */
export type Customer_Invoice_Versions_Arr_Rel_Insert_Input = {
  data: Array<Customer_Invoice_Versions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Invoice_Versions_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Invoice_Versions_Avg_Fields = {
  __typename: 'customer_invoice_versions_avg_fields';
  contentHash?: Maybe<Scalars['Float']>;
  customerInvoiceId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoiceVersion?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  pdfPrintDocumentId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xmlDocumentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_invoice_versions" */
export type Customer_Invoice_Versions_Avg_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceVersion?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  pdfPrintDocumentId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_invoice_versions". All fields are combined with a logical 'AND'. */
export type Customer_Invoice_Versions_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Invoice_Versions_Bool_Exp>>;
  _not?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Invoice_Versions_Bool_Exp>>;
  contentHash?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerInvoice?: InputMaybe<Customer_Invoices_Bool_Exp>;
  customerInvoiceId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  invoiceVersion?: InputMaybe<Bigint_Comparison_Exp>;
  loggedActions?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Bool_Exp>;
  loggedActions_aggregate?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Aggregate_Bool_Exp>;
  pdfDocument?: InputMaybe<S3_Objects_Bool_Exp>;
  pdfDocumentId?: InputMaybe<Bigint_Comparison_Exp>;
  pdfPrintDocument?: InputMaybe<S3_Objects_Bool_Exp>;
  pdfPrintDocumentId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
  xmlDocument?: InputMaybe<S3_Objects_Bool_Exp>;
  xmlDocumentId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_invoice_versions" */
export enum Customer_Invoice_Versions_Constraint {
  /** unique or primary key constraint on columns "customer_invoice_id", "invoice_version" */
  CustomerInvoiceVersionsCustomerInvoiceIdInvoiceVersiKey = 'customer_invoice_versions_customer_invoice_id_invoice_versi_key',
  /** unique or primary key constraint on columns "id" */
  CustomerInvoiceVersionsPkey = 'customer_invoice_versions_pkey'
}

/** input type for incrementing numeric columns in table "customer_invoice_versions" */
export type Customer_Invoice_Versions_Inc_Input = {
  contentHash?: InputMaybe<Scalars['bigint']>;
  customerInvoiceId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  invoiceVersion?: InputMaybe<Scalars['bigint']>;
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  pdfPrintDocumentId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  xmlDocumentId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_invoice_versions" */
export type Customer_Invoice_Versions_Insert_Input = {
  contentHash?: InputMaybe<Scalars['bigint']>;
  customerInvoiceId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  invoiceVersion?: InputMaybe<Scalars['bigint']>;
  pdfDocument?: InputMaybe<S3_Objects_Obj_Rel_Insert_Input>;
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  pdfPrintDocument?: InputMaybe<S3_Objects_Obj_Rel_Insert_Input>;
  pdfPrintDocumentId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  xmlDocument?: InputMaybe<S3_Objects_Obj_Rel_Insert_Input>;
  xmlDocumentId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Customer_Invoice_Versions_Max_Fields = {
  __typename: 'customer_invoice_versions_max_fields';
  contentHash?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerInvoiceId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  invoiceVersion?: Maybe<Scalars['bigint']>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  pdfPrintDocumentId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  xmlDocumentId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_invoice_versions" */
export type Customer_Invoice_Versions_Max_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceVersion?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  pdfPrintDocumentId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Invoice_Versions_Min_Fields = {
  __typename: 'customer_invoice_versions_min_fields';
  contentHash?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerInvoiceId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  invoiceVersion?: Maybe<Scalars['bigint']>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  pdfPrintDocumentId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  xmlDocumentId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_invoice_versions" */
export type Customer_Invoice_Versions_Min_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceVersion?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  pdfPrintDocumentId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_invoice_versions" */
export type Customer_Invoice_Versions_Mutation_Response = {
  __typename: 'customer_invoice_versions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Invoice_Versions>;
};

/** on_conflict condition type for table "customer_invoice_versions" */
export type Customer_Invoice_Versions_On_Conflict = {
  constraint: Customer_Invoice_Versions_Constraint;
  update_columns?: Array<Customer_Invoice_Versions_Update_Column>;
  where?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_invoice_versions". */
export type Customer_Invoice_Versions_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  contentHash?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerInvoice?: InputMaybe<Customer_Invoices_Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceVersion?: InputMaybe<Order_By>;
  loggedActions_aggregate?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Aggregate_Order_By>;
  pdfDocument?: InputMaybe<S3_Objects_Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  pdfPrintDocument?: InputMaybe<S3_Objects_Order_By>;
  pdfPrintDocumentId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocument?: InputMaybe<S3_Objects_Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_invoice_versions */
export type Customer_Invoice_Versions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customer_invoice_versions" */
export enum Customer_Invoice_Versions_Select_Column {
  /** column name */
  ContentHash = 'contentHash',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerInvoiceId = 'customerInvoiceId',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceVersion = 'invoiceVersion',
  /** column name */
  PdfDocumentId = 'pdfDocumentId',
  /** column name */
  PdfPrintDocumentId = 'pdfPrintDocumentId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version',
  /** column name */
  XmlDocumentId = 'xmlDocumentId'
}

/** input type for updating data in table "customer_invoice_versions" */
export type Customer_Invoice_Versions_Set_Input = {
  contentHash?: InputMaybe<Scalars['bigint']>;
  customerInvoiceId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  invoiceVersion?: InputMaybe<Scalars['bigint']>;
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  pdfPrintDocumentId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  xmlDocumentId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Customer_Invoice_Versions_Stddev_Fields = {
  __typename: 'customer_invoice_versions_stddev_fields';
  contentHash?: Maybe<Scalars['Float']>;
  customerInvoiceId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoiceVersion?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  pdfPrintDocumentId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xmlDocumentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_invoice_versions" */
export type Customer_Invoice_Versions_Stddev_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceVersion?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  pdfPrintDocumentId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Invoice_Versions_Stddev_Pop_Fields = {
  __typename: 'customer_invoice_versions_stddev_pop_fields';
  contentHash?: Maybe<Scalars['Float']>;
  customerInvoiceId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoiceVersion?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  pdfPrintDocumentId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xmlDocumentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_invoice_versions" */
export type Customer_Invoice_Versions_Stddev_Pop_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceVersion?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  pdfPrintDocumentId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Invoice_Versions_Stddev_Samp_Fields = {
  __typename: 'customer_invoice_versions_stddev_samp_fields';
  contentHash?: Maybe<Scalars['Float']>;
  customerInvoiceId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoiceVersion?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  pdfPrintDocumentId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xmlDocumentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_invoice_versions" */
export type Customer_Invoice_Versions_Stddev_Samp_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceVersion?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  pdfPrintDocumentId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_invoice_versions" */
export type Customer_Invoice_Versions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Invoice_Versions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Invoice_Versions_Stream_Cursor_Value_Input = {
  contentHash?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerInvoiceId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  invoiceVersion?: InputMaybe<Scalars['bigint']>;
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  pdfPrintDocumentId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
  xmlDocumentId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Invoice_Versions_Sum_Fields = {
  __typename: 'customer_invoice_versions_sum_fields';
  contentHash?: Maybe<Scalars['bigint']>;
  customerInvoiceId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  invoiceVersion?: Maybe<Scalars['bigint']>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  pdfPrintDocumentId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
  xmlDocumentId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_invoice_versions" */
export type Customer_Invoice_Versions_Sum_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceVersion?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  pdfPrintDocumentId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** update columns of table "customer_invoice_versions" */
export enum Customer_Invoice_Versions_Update_Column {
  /** column name */
  ContentHash = 'contentHash',
  /** column name */
  CustomerInvoiceId = 'customerInvoiceId',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceVersion = 'invoiceVersion',
  /** column name */
  PdfDocumentId = 'pdfDocumentId',
  /** column name */
  PdfPrintDocumentId = 'pdfPrintDocumentId',
  /** column name */
  Version = 'version',
  /** column name */
  XmlDocumentId = 'xmlDocumentId'
}

export type Customer_Invoice_Versions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Invoice_Versions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Invoice_Versions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Invoice_Versions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Invoice_Versions_Var_Pop_Fields = {
  __typename: 'customer_invoice_versions_var_pop_fields';
  contentHash?: Maybe<Scalars['Float']>;
  customerInvoiceId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoiceVersion?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  pdfPrintDocumentId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xmlDocumentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_invoice_versions" */
export type Customer_Invoice_Versions_Var_Pop_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceVersion?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  pdfPrintDocumentId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Invoice_Versions_Var_Samp_Fields = {
  __typename: 'customer_invoice_versions_var_samp_fields';
  contentHash?: Maybe<Scalars['Float']>;
  customerInvoiceId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoiceVersion?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  pdfPrintDocumentId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xmlDocumentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_invoice_versions" */
export type Customer_Invoice_Versions_Var_Samp_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceVersion?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  pdfPrintDocumentId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Invoice_Versions_Variance_Fields = {
  __typename: 'customer_invoice_versions_variance_fields';
  contentHash?: Maybe<Scalars['Float']>;
  customerInvoiceId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoiceVersion?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  pdfPrintDocumentId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xmlDocumentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_invoice_versions" */
export type Customer_Invoice_Versions_Variance_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceVersion?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  pdfPrintDocumentId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xmlDocumentId?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_invoices" */
export type Customer_Invoices = {
  __typename: 'customer_invoices';
  /** An object relationship */
  Tenant: Tenants;
  billingFrequency: Customer_Billing_Frequency_Enum;
  /** Returns the calculated bigint hash of the contents of this customer invoice for PDF generation especially. */
  computedContentHash?: Maybe<Scalars['bigint']>;
  createdAt: Scalars['timestamptz'];
  currency: Currencies_Enum;
  /** An object relationship */
  customer: Customers;
  customerId: Scalars['bigint'];
  /** An array relationship */
  customerInvoiceOrders: Array<Customer_Invoice_Orders>;
  /** An aggregate relationship */
  customerInvoiceOrders_aggregate: Customer_Invoice_Orders_Aggregate;
  /** An array relationship */
  customerInvoiceVersions: Array<Customer_Invoice_Versions>;
  /** An aggregate relationship */
  customerInvoiceVersions_aggregate: Customer_Invoice_Versions_Aggregate;
  domainId: Scalars['String'];
  /** An array relationship */
  globalSearchResults: Array<Global_Search_Result>;
  /** An aggregate relationship */
  globalSearchResults_aggregate: Global_Search_Result_Aggregate;
  /** Returns whether all of the customer invoice versions references are outdated, to be re-generated. */
  hasOutdatedCustomerInvoiceVersion?: Maybe<Scalars['Boolean']>;
  id: Scalars['bigint'];
  invoiceDate?: Maybe<Scalars['date']>;
  /** An array relationship */
  loggedActions: Array<Customer_Invoice_Logged_Actions>;
  /** An aggregate relationship */
  loggedActions_aggregate: Customer_Invoice_Logged_Actions_Aggregate;
  paymentMethod?: Maybe<Customer_Payment_Method_Enum>;
  /** An object relationship */
  paymentTerm?: Maybe<Customer_Payment_Terms>;
  paymentTermId?: Maybe<Scalars['bigint']>;
  state: Customer_Invoice_States_Enum;
  syncedToAccounting: Scalars['Boolean'];
  tenant: Scalars['String'];
  totalInvoicePrice: Scalars['bigint'];
  totalInvoicePriceWithVat?: Maybe<Scalars['bigint']>;
  totalInvoiceWeight?: Maybe<Scalars['bigint']>;
  totalSalesWeight?: Maybe<Scalars['bigint']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['bigint']>;
  updatedAt: Scalars['timestamptz'];
  /** Returns the VAT summary table for a customer invoice */
  vatSummary?: Maybe<Array<Customer_Invoice_Vat_Summary_Results>>;
  version: Scalars['bigint'];
};


/** columns and relationships of "customer_invoices" */
export type Customer_InvoicesCustomerInvoiceOrdersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Orders_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Orders_Bool_Exp>;
};


/** columns and relationships of "customer_invoices" */
export type Customer_InvoicesCustomerInvoiceOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Orders_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Orders_Bool_Exp>;
};


/** columns and relationships of "customer_invoices" */
export type Customer_InvoicesCustomerInvoiceVersionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Versions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
};


/** columns and relationships of "customer_invoices" */
export type Customer_InvoicesCustomerInvoiceVersions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Versions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
};


/** columns and relationships of "customer_invoices" */
export type Customer_InvoicesGlobalSearchResultsArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


/** columns and relationships of "customer_invoices" */
export type Customer_InvoicesGlobalSearchResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


/** columns and relationships of "customer_invoices" */
export type Customer_InvoicesLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "customer_invoices" */
export type Customer_InvoicesLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "customer_invoices" */
export type Customer_InvoicesVatSummaryArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Vat_Summary_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Vat_Summary_Results_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Vat_Summary_Results_Bool_Exp>;
};

/** aggregated selection of "customer_invoices" */
export type Customer_Invoices_Aggregate = {
  __typename: 'customer_invoices_aggregate';
  aggregate?: Maybe<Customer_Invoices_Aggregate_Fields>;
  nodes: Array<Customer_Invoices>;
};

export type Customer_Invoices_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Customer_Invoices_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Customer_Invoices_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Customer_Invoices_Aggregate_Bool_Exp_Count>;
};

export type Customer_Invoices_Aggregate_Bool_Exp_Bool_And = {
  arguments: Customer_Invoices_Select_Column_Customer_Invoices_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Invoices_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Invoices_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Customer_Invoices_Select_Column_Customer_Invoices_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Invoices_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Invoices_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Invoices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Invoices_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_invoices" */
export type Customer_Invoices_Aggregate_Fields = {
  __typename: 'customer_invoices_aggregate_fields';
  avg?: Maybe<Customer_Invoices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Invoices_Max_Fields>;
  min?: Maybe<Customer_Invoices_Min_Fields>;
  stddev?: Maybe<Customer_Invoices_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Invoices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Invoices_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Invoices_Sum_Fields>;
  var_pop?: Maybe<Customer_Invoices_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Invoices_Var_Samp_Fields>;
  variance?: Maybe<Customer_Invoices_Variance_Fields>;
};


/** aggregate fields of "customer_invoices" */
export type Customer_Invoices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Invoices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_invoices" */
export type Customer_Invoices_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Invoices_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Invoices_Max_Order_By>;
  min?: InputMaybe<Customer_Invoices_Min_Order_By>;
  stddev?: InputMaybe<Customer_Invoices_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Invoices_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Invoices_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Invoices_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Invoices_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Invoices_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Invoices_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Customer_Invoices_Avg_Fields = {
  __typename: 'customer_invoices_avg_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  paymentTermId?: Maybe<Scalars['Float']>;
  totalInvoicePrice?: Maybe<Scalars['Float']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['Float']>;
  totalInvoiceWeight?: Maybe<Scalars['Float']>;
  totalSalesWeight?: Maybe<Scalars['Float']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_invoices" */
export type Customer_Invoices_Avg_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_invoices". All fields are combined with a logical 'AND'. */
export type Customer_Invoices_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Invoices_Bool_Exp>>;
  _not?: InputMaybe<Customer_Invoices_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Invoices_Bool_Exp>>;
  billingFrequency?: InputMaybe<Customer_Billing_Frequency_Enum_Comparison_Exp>;
  computedContentHash?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  customer?: InputMaybe<Customers_Bool_Exp>;
  customerId?: InputMaybe<Bigint_Comparison_Exp>;
  customerInvoiceOrders?: InputMaybe<Customer_Invoice_Orders_Bool_Exp>;
  customerInvoiceOrders_aggregate?: InputMaybe<Customer_Invoice_Orders_Aggregate_Bool_Exp>;
  customerInvoiceVersions?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
  customerInvoiceVersions_aggregate?: InputMaybe<Customer_Invoice_Versions_Aggregate_Bool_Exp>;
  domainId?: InputMaybe<String_Comparison_Exp>;
  globalSearchResults?: InputMaybe<Global_Search_Result_Bool_Exp>;
  globalSearchResults_aggregate?: InputMaybe<Global_Search_Result_Aggregate_Bool_Exp>;
  hasOutdatedCustomerInvoiceVersion?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  invoiceDate?: InputMaybe<Date_Comparison_Exp>;
  loggedActions?: InputMaybe<Customer_Invoice_Logged_Actions_Bool_Exp>;
  loggedActions_aggregate?: InputMaybe<Customer_Invoice_Logged_Actions_Aggregate_Bool_Exp>;
  paymentMethod?: InputMaybe<Customer_Payment_Method_Enum_Comparison_Exp>;
  paymentTerm?: InputMaybe<Customer_Payment_Terms_Bool_Exp>;
  paymentTermId?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<Customer_Invoice_States_Enum_Comparison_Exp>;
  syncedToAccounting?: InputMaybe<Boolean_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  totalInvoicePrice?: InputMaybe<Bigint_Comparison_Exp>;
  totalInvoicePriceWithVat?: InputMaybe<Bigint_Comparison_Exp>;
  totalInvoiceWeight?: InputMaybe<Bigint_Comparison_Exp>;
  totalSalesWeight?: InputMaybe<Bigint_Comparison_Exp>;
  totalVatOnInvoicePrice?: InputMaybe<Bigint_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  vatSummary?: InputMaybe<Customer_Invoice_Vat_Summary_Results_Bool_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "customer_invoices" */
export type Customer_Invoices_Inc_Input = {
  totalInvoicePrice?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Customer_Invoices_Max_Fields = {
  __typename: 'customer_invoices_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerId?: Maybe<Scalars['bigint']>;
  domainId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  invoiceDate?: Maybe<Scalars['date']>;
  paymentTermId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  totalInvoicePrice?: Maybe<Scalars['bigint']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['bigint']>;
  totalInvoiceWeight?: Maybe<Scalars['bigint']>;
  totalSalesWeight?: Maybe<Scalars['bigint']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_invoices" */
export type Customer_Invoices_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceDate?: InputMaybe<Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Invoices_Min_Fields = {
  __typename: 'customer_invoices_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerId?: Maybe<Scalars['bigint']>;
  domainId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  invoiceDate?: Maybe<Scalars['date']>;
  paymentTermId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  totalInvoicePrice?: Maybe<Scalars['bigint']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['bigint']>;
  totalInvoiceWeight?: Maybe<Scalars['bigint']>;
  totalSalesWeight?: Maybe<Scalars['bigint']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_invoices" */
export type Customer_Invoices_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceDate?: InputMaybe<Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_invoices" */
export type Customer_Invoices_Mutation_Response = {
  __typename: 'customer_invoices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Invoices>;
};

/** Ordering options when selecting data from "customer_invoices". */
export type Customer_Invoices_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  billingFrequency?: InputMaybe<Order_By>;
  computedContentHash?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  customer?: InputMaybe<Customers_Order_By>;
  customerId?: InputMaybe<Order_By>;
  customerInvoiceOrders_aggregate?: InputMaybe<Customer_Invoice_Orders_Aggregate_Order_By>;
  customerInvoiceVersions_aggregate?: InputMaybe<Customer_Invoice_Versions_Aggregate_Order_By>;
  domainId?: InputMaybe<Order_By>;
  globalSearchResults_aggregate?: InputMaybe<Global_Search_Result_Aggregate_Order_By>;
  hasOutdatedCustomerInvoiceVersion?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceDate?: InputMaybe<Order_By>;
  loggedActions_aggregate?: InputMaybe<Customer_Invoice_Logged_Actions_Aggregate_Order_By>;
  paymentMethod?: InputMaybe<Order_By>;
  paymentTerm?: InputMaybe<Customer_Payment_Terms_Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  syncedToAccounting?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vatSummary_aggregate?: InputMaybe<Customer_Invoice_Vat_Summary_Results_Aggregate_Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_invoices */
export type Customer_Invoices_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customer_invoices" */
export enum Customer_Invoices_Select_Column {
  /** column name */
  BillingFrequency = 'billingFrequency',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  DomainId = 'domainId',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceDate = 'invoiceDate',
  /** column name */
  PaymentMethod = 'paymentMethod',
  /** column name */
  PaymentTermId = 'paymentTermId',
  /** column name */
  State = 'state',
  /** column name */
  SyncedToAccounting = 'syncedToAccounting',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  TotalInvoicePrice = 'totalInvoicePrice',
  /** column name */
  TotalInvoicePriceWithVat = 'totalInvoicePriceWithVat',
  /** column name */
  TotalInvoiceWeight = 'totalInvoiceWeight',
  /** column name */
  TotalSalesWeight = 'totalSalesWeight',
  /** column name */
  TotalVatOnInvoicePrice = 'totalVatOnInvoicePrice',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** select "customer_invoices_aggregate_bool_exp_bool_and_arguments_columns" columns of table "customer_invoices" */
export enum Customer_Invoices_Select_Column_Customer_Invoices_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  SyncedToAccounting = 'syncedToAccounting'
}

/** select "customer_invoices_aggregate_bool_exp_bool_or_arguments_columns" columns of table "customer_invoices" */
export enum Customer_Invoices_Select_Column_Customer_Invoices_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  SyncedToAccounting = 'syncedToAccounting'
}

/** input type for updating data in table "customer_invoices" */
export type Customer_Invoices_Set_Input = {
  state?: InputMaybe<Customer_Invoice_States_Enum>;
  syncedToAccounting?: InputMaybe<Scalars['Boolean']>;
  totalInvoicePrice?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Customer_Invoices_Stddev_Fields = {
  __typename: 'customer_invoices_stddev_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  paymentTermId?: Maybe<Scalars['Float']>;
  totalInvoicePrice?: Maybe<Scalars['Float']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['Float']>;
  totalInvoiceWeight?: Maybe<Scalars['Float']>;
  totalSalesWeight?: Maybe<Scalars['Float']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_invoices" */
export type Customer_Invoices_Stddev_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Invoices_Stddev_Pop_Fields = {
  __typename: 'customer_invoices_stddev_pop_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  paymentTermId?: Maybe<Scalars['Float']>;
  totalInvoicePrice?: Maybe<Scalars['Float']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['Float']>;
  totalInvoiceWeight?: Maybe<Scalars['Float']>;
  totalSalesWeight?: Maybe<Scalars['Float']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_invoices" */
export type Customer_Invoices_Stddev_Pop_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Invoices_Stddev_Samp_Fields = {
  __typename: 'customer_invoices_stddev_samp_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  paymentTermId?: Maybe<Scalars['Float']>;
  totalInvoicePrice?: Maybe<Scalars['Float']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['Float']>;
  totalInvoiceWeight?: Maybe<Scalars['Float']>;
  totalSalesWeight?: Maybe<Scalars['Float']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_invoices" */
export type Customer_Invoices_Stddev_Samp_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_invoices" */
export type Customer_Invoices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Invoices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Invoices_Stream_Cursor_Value_Input = {
  billingFrequency?: InputMaybe<Customer_Billing_Frequency_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Currencies_Enum>;
  customerId?: InputMaybe<Scalars['bigint']>;
  domainId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  invoiceDate?: InputMaybe<Scalars['date']>;
  paymentMethod?: InputMaybe<Customer_Payment_Method_Enum>;
  paymentTermId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Customer_Invoice_States_Enum>;
  syncedToAccounting?: InputMaybe<Scalars['Boolean']>;
  tenant?: InputMaybe<Scalars['String']>;
  totalInvoicePrice?: InputMaybe<Scalars['bigint']>;
  totalInvoicePriceWithVat?: InputMaybe<Scalars['bigint']>;
  totalInvoiceWeight?: InputMaybe<Scalars['bigint']>;
  totalSalesWeight?: InputMaybe<Scalars['bigint']>;
  totalVatOnInvoicePrice?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Invoices_Sum_Fields = {
  __typename: 'customer_invoices_sum_fields';
  customerId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  paymentTermId?: Maybe<Scalars['bigint']>;
  totalInvoicePrice?: Maybe<Scalars['bigint']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['bigint']>;
  totalInvoiceWeight?: Maybe<Scalars['bigint']>;
  totalSalesWeight?: Maybe<Scalars['bigint']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_invoices" */
export type Customer_Invoices_Sum_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

export type Customer_Invoices_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Invoices_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Invoices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Invoices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Invoices_Var_Pop_Fields = {
  __typename: 'customer_invoices_var_pop_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  paymentTermId?: Maybe<Scalars['Float']>;
  totalInvoicePrice?: Maybe<Scalars['Float']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['Float']>;
  totalInvoiceWeight?: Maybe<Scalars['Float']>;
  totalSalesWeight?: Maybe<Scalars['Float']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_invoices" */
export type Customer_Invoices_Var_Pop_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Invoices_Var_Samp_Fields = {
  __typename: 'customer_invoices_var_samp_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  paymentTermId?: Maybe<Scalars['Float']>;
  totalInvoicePrice?: Maybe<Scalars['Float']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['Float']>;
  totalInvoiceWeight?: Maybe<Scalars['Float']>;
  totalSalesWeight?: Maybe<Scalars['Float']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_invoices" */
export type Customer_Invoices_Var_Samp_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Invoices_Variance_Fields = {
  __typename: 'customer_invoices_variance_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  paymentTermId?: Maybe<Scalars['Float']>;
  totalInvoicePrice?: Maybe<Scalars['Float']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['Float']>;
  totalInvoiceWeight?: Maybe<Scalars['Float']>;
  totalSalesWeight?: Maybe<Scalars['Float']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_invoices" */
export type Customer_Invoices_Variance_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paymentTermId?: InputMaybe<Order_By>;
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_logged_actions" */
export type Customer_Logged_Actions = {
  __typename: 'customer_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  entity?: Maybe<Customers>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "customer_logged_actions" */
export type Customer_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "customer_logged_actions" */
export type Customer_Logged_Actions_Aggregate = {
  __typename: 'customer_logged_actions_aggregate';
  aggregate?: Maybe<Customer_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Customer_Logged_Actions>;
};

export type Customer_Logged_Actions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Logged_Actions_Aggregate_Bool_Exp_Count>;
};

export type Customer_Logged_Actions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Logged_Actions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_logged_actions" */
export type Customer_Logged_Actions_Aggregate_Fields = {
  __typename: 'customer_logged_actions_aggregate_fields';
  avg?: Maybe<Customer_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Logged_Actions_Max_Fields>;
  min?: Maybe<Customer_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Customer_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Customer_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Customer_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "customer_logged_actions" */
export type Customer_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_logged_actions" */
export type Customer_Logged_Actions_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Logged_Actions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Logged_Actions_Max_Order_By>;
  min?: InputMaybe<Customer_Logged_Actions_Min_Order_By>;
  stddev?: InputMaybe<Customer_Logged_Actions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Logged_Actions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Logged_Actions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Logged_Actions_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Logged_Actions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Logged_Actions_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Logged_Actions_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Customer_Logged_Actions_Avg_Fields = {
  __typename: 'customer_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_logged_actions" */
export type Customer_Logged_Actions_Avg_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_logged_actions". All fields are combined with a logical 'AND'. */
export type Customer_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Customer_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entity?: InputMaybe<Customers_Bool_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Customer_Logged_Actions_Max_Fields = {
  __typename: 'customer_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_logged_actions" */
export type Customer_Logged_Actions_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Logged_Actions_Min_Fields = {
  __typename: 'customer_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_logged_actions" */
export type Customer_Logged_Actions_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "customer_logged_actions". */
export type Customer_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entity?: InputMaybe<Customers_Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "customer_logged_actions" */
export enum Customer_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Role = 'role',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Customer_Logged_Actions_Stddev_Fields = {
  __typename: 'customer_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_logged_actions" */
export type Customer_Logged_Actions_Stddev_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'customer_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_logged_actions" */
export type Customer_Logged_Actions_Stddev_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'customer_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_logged_actions" */
export type Customer_Logged_Actions_Stddev_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_logged_actions" */
export type Customer_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  role?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Logged_Actions_Sum_Fields = {
  __typename: 'customer_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_logged_actions" */
export type Customer_Logged_Actions_Sum_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Customer_Logged_Actions_Var_Pop_Fields = {
  __typename: 'customer_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_logged_actions" */
export type Customer_Logged_Actions_Var_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Logged_Actions_Var_Samp_Fields = {
  __typename: 'customer_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_logged_actions" */
export type Customer_Logged_Actions_Var_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Logged_Actions_Variance_Fields = {
  __typename: 'customer_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_logged_actions" */
export type Customer_Logged_Actions_Variance_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items = {
  __typename: 'customer_order_container_return_items';
  /** An object relationship */
  Tenant: Tenants;
  /** An object relationship */
  containerProduct: Products;
  containerProductId: Scalars['bigint'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customerOrderContainerReturn: Customer_Order_Container_Returns;
  customerOrderContainerReturnId: Scalars['bigint'];
  id: Scalars['bigint'];
  quantity: Scalars['bigint'];
  tenant: Scalars['String'];
  /** An object relationship */
  unit: Product_Units;
  unitId: Scalars['bigint'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_Aggregate = {
  __typename: 'customer_order_container_return_items_aggregate';
  aggregate?: Maybe<Customer_Order_Container_Return_Items_Aggregate_Fields>;
  nodes: Array<Customer_Order_Container_Return_Items>;
};

export type Customer_Order_Container_Return_Items_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Order_Container_Return_Items_Aggregate_Bool_Exp_Count>;
};

export type Customer_Order_Container_Return_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Order_Container_Return_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Order_Container_Return_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_Aggregate_Fields = {
  __typename: 'customer_order_container_return_items_aggregate_fields';
  avg?: Maybe<Customer_Order_Container_Return_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Container_Return_Items_Max_Fields>;
  min?: Maybe<Customer_Order_Container_Return_Items_Min_Fields>;
  stddev?: Maybe<Customer_Order_Container_Return_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Order_Container_Return_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Order_Container_Return_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Order_Container_Return_Items_Sum_Fields>;
  var_pop?: Maybe<Customer_Order_Container_Return_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Order_Container_Return_Items_Var_Samp_Fields>;
  variance?: Maybe<Customer_Order_Container_Return_Items_Variance_Fields>;
};


/** aggregate fields of "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Container_Return_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Order_Container_Return_Items_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Order_Container_Return_Items_Max_Order_By>;
  min?: InputMaybe<Customer_Order_Container_Return_Items_Min_Order_By>;
  stddev?: InputMaybe<Customer_Order_Container_Return_Items_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Order_Container_Return_Items_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Order_Container_Return_Items_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Order_Container_Return_Items_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Order_Container_Return_Items_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Order_Container_Return_Items_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Order_Container_Return_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_Arr_Rel_Insert_Input = {
  data: Array<Customer_Order_Container_Return_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Order_Container_Return_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Order_Container_Return_Items_Avg_Fields = {
  __typename: 'customer_order_container_return_items_avg_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderContainerReturnId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unitId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_Avg_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderContainerReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  unitId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_order_container_return_items". All fields are combined with a logical 'AND'. */
export type Customer_Order_Container_Return_Items_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Order_Container_Return_Items_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Container_Return_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Container_Return_Items_Bool_Exp>>;
  containerProduct?: InputMaybe<Products_Bool_Exp>;
  containerProductId?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerOrderContainerReturn?: InputMaybe<Customer_Order_Container_Returns_Bool_Exp>;
  customerOrderContainerReturnId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  quantity?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  unit?: InputMaybe<Product_Units_Bool_Exp>;
  unitId?: InputMaybe<Bigint_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_order_container_return_items" */
export enum Customer_Order_Container_Return_Items_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerOrderContainerReturnItemsPkey = 'customer_order_container_return_items_pkey'
}

/** input type for incrementing numeric columns in table "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_Inc_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  customerOrderContainerReturnId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  unitId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_Insert_Input = {
  containerProduct?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  containerProductId?: InputMaybe<Scalars['bigint']>;
  customerOrderContainerReturn?: InputMaybe<Customer_Order_Container_Returns_Obj_Rel_Insert_Input>;
  customerOrderContainerReturnId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  unit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  unitId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Customer_Order_Container_Return_Items_Max_Fields = {
  __typename: 'customer_order_container_return_items_max_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderContainerReturnId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_Max_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrderContainerReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  unitId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Order_Container_Return_Items_Min_Fields = {
  __typename: 'customer_order_container_return_items_min_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderContainerReturnId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_Min_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrderContainerReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  unitId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_Mutation_Response = {
  __typename: 'customer_order_container_return_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Order_Container_Return_Items>;
};

/** on_conflict condition type for table "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_On_Conflict = {
  constraint: Customer_Order_Container_Return_Items_Constraint;
  update_columns?: Array<Customer_Order_Container_Return_Items_Update_Column>;
  where?: InputMaybe<Customer_Order_Container_Return_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_order_container_return_items". */
export type Customer_Order_Container_Return_Items_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  containerProduct?: InputMaybe<Products_Order_By>;
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrderContainerReturn?: InputMaybe<Customer_Order_Container_Returns_Order_By>;
  customerOrderContainerReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  unit?: InputMaybe<Product_Units_Order_By>;
  unitId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_order_container_return_items */
export type Customer_Order_Container_Return_Items_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customer_order_container_return_items" */
export enum Customer_Order_Container_Return_Items_Select_Column {
  /** column name */
  ContainerProductId = 'containerProductId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerOrderContainerReturnId = 'customerOrderContainerReturnId',
  /** column name */
  Id = 'id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UnitId = 'unitId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_Set_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  customerOrderContainerReturnId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  unitId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Customer_Order_Container_Return_Items_Stddev_Fields = {
  __typename: 'customer_order_container_return_items_stddev_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderContainerReturnId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unitId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_Stddev_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderContainerReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  unitId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Order_Container_Return_Items_Stddev_Pop_Fields = {
  __typename: 'customer_order_container_return_items_stddev_pop_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderContainerReturnId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unitId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_Stddev_Pop_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderContainerReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  unitId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Order_Container_Return_Items_Stddev_Samp_Fields = {
  __typename: 'customer_order_container_return_items_stddev_samp_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderContainerReturnId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unitId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_Stddev_Samp_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderContainerReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  unitId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Container_Return_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Container_Return_Items_Stream_Cursor_Value_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerOrderContainerReturnId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  unitId?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Customer_Order_Container_Return_Items_Sum_Fields = {
  __typename: 'customer_order_container_return_items_sum_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  customerOrderContainerReturnId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  unitId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_Sum_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderContainerReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  unitId?: InputMaybe<Order_By>;
};

/** update columns of table "customer_order_container_return_items" */
export enum Customer_Order_Container_Return_Items_Update_Column {
  /** column name */
  ContainerProductId = 'containerProductId',
  /** column name */
  CustomerOrderContainerReturnId = 'customerOrderContainerReturnId',
  /** column name */
  Id = 'id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  UnitId = 'unitId'
}

export type Customer_Order_Container_Return_Items_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Order_Container_Return_Items_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Order_Container_Return_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Order_Container_Return_Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Order_Container_Return_Items_Var_Pop_Fields = {
  __typename: 'customer_order_container_return_items_var_pop_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderContainerReturnId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unitId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_Var_Pop_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderContainerReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  unitId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Order_Container_Return_Items_Var_Samp_Fields = {
  __typename: 'customer_order_container_return_items_var_samp_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderContainerReturnId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unitId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_Var_Samp_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderContainerReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  unitId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Order_Container_Return_Items_Variance_Fields = {
  __typename: 'customer_order_container_return_items_variance_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderContainerReturnId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unitId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_order_container_return_items" */
export type Customer_Order_Container_Return_Items_Variance_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderContainerReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  unitId?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_order_container_returns" */
export type Customer_Order_Container_Returns = {
  __typename: 'customer_order_container_returns';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customerOrder: Customer_Orders;
  /** An array relationship */
  customerOrderContainerReturnItems: Array<Customer_Order_Container_Return_Items>;
  /** An aggregate relationship */
  customerOrderContainerReturnItems_aggregate: Customer_Order_Container_Return_Items_Aggregate;
  customerOrderId: Scalars['bigint'];
  id: Scalars['bigint'];
  /** An object relationship */
  journalEntry?: Maybe<Journal_Entries>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "customer_order_container_returns" */
export type Customer_Order_Container_ReturnsCustomerOrderContainerReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Return_Items_Bool_Exp>;
};


/** columns and relationships of "customer_order_container_returns" */
export type Customer_Order_Container_ReturnsCustomerOrderContainerReturnItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Return_Items_Bool_Exp>;
};

/** aggregated selection of "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Aggregate = {
  __typename: 'customer_order_container_returns_aggregate';
  aggregate?: Maybe<Customer_Order_Container_Returns_Aggregate_Fields>;
  nodes: Array<Customer_Order_Container_Returns>;
};

export type Customer_Order_Container_Returns_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Order_Container_Returns_Aggregate_Bool_Exp_Count>;
};

export type Customer_Order_Container_Returns_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Order_Container_Returns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Order_Container_Returns_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Aggregate_Fields = {
  __typename: 'customer_order_container_returns_aggregate_fields';
  avg?: Maybe<Customer_Order_Container_Returns_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Container_Returns_Max_Fields>;
  min?: Maybe<Customer_Order_Container_Returns_Min_Fields>;
  stddev?: Maybe<Customer_Order_Container_Returns_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Order_Container_Returns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Order_Container_Returns_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Order_Container_Returns_Sum_Fields>;
  var_pop?: Maybe<Customer_Order_Container_Returns_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Order_Container_Returns_Var_Samp_Fields>;
  variance?: Maybe<Customer_Order_Container_Returns_Variance_Fields>;
};


/** aggregate fields of "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Container_Returns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Order_Container_Returns_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Order_Container_Returns_Max_Order_By>;
  min?: InputMaybe<Customer_Order_Container_Returns_Min_Order_By>;
  stddev?: InputMaybe<Customer_Order_Container_Returns_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Order_Container_Returns_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Order_Container_Returns_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Order_Container_Returns_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Order_Container_Returns_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Order_Container_Returns_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Order_Container_Returns_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Arr_Rel_Insert_Input = {
  data: Array<Customer_Order_Container_Returns_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Order_Container_Returns_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Order_Container_Returns_Avg_Fields = {
  __typename: 'customer_order_container_returns_avg_fields';
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Avg_Order_By = {
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_order_container_returns". All fields are combined with a logical 'AND'. */
export type Customer_Order_Container_Returns_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Order_Container_Returns_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Container_Returns_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Container_Returns_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerOrder?: InputMaybe<Customer_Orders_Bool_Exp>;
  customerOrderContainerReturnItems?: InputMaybe<Customer_Order_Container_Return_Items_Bool_Exp>;
  customerOrderContainerReturnItems_aggregate?: InputMaybe<Customer_Order_Container_Return_Items_Aggregate_Bool_Exp>;
  customerOrderId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  journalEntry?: InputMaybe<Journal_Entries_Bool_Exp>;
  journalEntryId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_order_container_returns" */
export enum Customer_Order_Container_Returns_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerOrderContainerReturnsPkey = 'customer_order_container_returns_pkey'
}

/** input type for incrementing numeric columns in table "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Inc_Input = {
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Insert_Input = {
  customerOrder?: InputMaybe<Customer_Orders_Obj_Rel_Insert_Input>;
  customerOrderContainerReturnItems?: InputMaybe<Customer_Order_Container_Return_Items_Arr_Rel_Insert_Input>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntry?: InputMaybe<Journal_Entries_Obj_Rel_Insert_Input>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Customer_Order_Container_Returns_Max_Fields = {
  __typename: 'customer_order_container_returns_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Order_Container_Returns_Min_Fields = {
  __typename: 'customer_order_container_returns_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Mutation_Response = {
  __typename: 'customer_order_container_returns_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Order_Container_Returns>;
};

/** input type for inserting object relation for remote table "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Obj_Rel_Insert_Input = {
  data: Customer_Order_Container_Returns_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Order_Container_Returns_On_Conflict>;
};

/** on_conflict condition type for table "customer_order_container_returns" */
export type Customer_Order_Container_Returns_On_Conflict = {
  constraint: Customer_Order_Container_Returns_Constraint;
  update_columns?: Array<Customer_Order_Container_Returns_Update_Column>;
  where?: InputMaybe<Customer_Order_Container_Returns_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_order_container_returns". */
export type Customer_Order_Container_Returns_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrder?: InputMaybe<Customer_Orders_Order_By>;
  customerOrderContainerReturnItems_aggregate?: InputMaybe<Customer_Order_Container_Return_Items_Aggregate_Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntry?: InputMaybe<Journal_Entries_Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_order_container_returns */
export type Customer_Order_Container_Returns_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customer_order_container_returns" */
export enum Customer_Order_Container_Returns_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerOrderId = 'customerOrderId',
  /** column name */
  Id = 'id',
  /** column name */
  JournalEntryId = 'journalEntryId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Set_Input = {
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Customer_Order_Container_Returns_Stddev_Fields = {
  __typename: 'customer_order_container_returns_stddev_fields';
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Stddev_Order_By = {
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Order_Container_Returns_Stddev_Pop_Fields = {
  __typename: 'customer_order_container_returns_stddev_pop_fields';
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Stddev_Pop_Order_By = {
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Order_Container_Returns_Stddev_Samp_Fields = {
  __typename: 'customer_order_container_returns_stddev_samp_fields';
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Stddev_Samp_Order_By = {
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Container_Returns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Container_Returns_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Customer_Order_Container_Returns_Sum_Fields = {
  __typename: 'customer_order_container_returns_sum_fields';
  customerOrderId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Sum_Order_By = {
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
};

/** update columns of table "customer_order_container_returns" */
export enum Customer_Order_Container_Returns_Update_Column {
  /** column name */
  CustomerOrderId = 'customerOrderId',
  /** column name */
  Id = 'id',
  /** column name */
  JournalEntryId = 'journalEntryId'
}

export type Customer_Order_Container_Returns_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Order_Container_Returns_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Order_Container_Returns_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Order_Container_Returns_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Order_Container_Returns_Var_Pop_Fields = {
  __typename: 'customer_order_container_returns_var_pop_fields';
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Var_Pop_Order_By = {
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Order_Container_Returns_Var_Samp_Fields = {
  __typename: 'customer_order_container_returns_var_samp_fields';
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Var_Samp_Order_By = {
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Order_Container_Returns_Variance_Fields = {
  __typename: 'customer_order_container_returns_variance_fields';
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_order_container_returns" */
export type Customer_Order_Container_Returns_Variance_Order_By = {
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_order_item_adjustment_logged_actions" */
export type Customer_Order_Item_Adjustment_Logged_Actions = {
  __typename: 'customer_order_item_adjustment_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "customer_order_item_adjustment_logged_actions" */
export type Customer_Order_Item_Adjustment_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "customer_order_item_adjustment_logged_actions" */
export type Customer_Order_Item_Adjustment_Logged_Actions_Aggregate = {
  __typename: 'customer_order_item_adjustment_logged_actions_aggregate';
  aggregate?: Maybe<Customer_Order_Item_Adjustment_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Customer_Order_Item_Adjustment_Logged_Actions>;
};

/** aggregate fields of "customer_order_item_adjustment_logged_actions" */
export type Customer_Order_Item_Adjustment_Logged_Actions_Aggregate_Fields = {
  __typename: 'customer_order_item_adjustment_logged_actions_aggregate_fields';
  avg?: Maybe<Customer_Order_Item_Adjustment_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Item_Adjustment_Logged_Actions_Max_Fields>;
  min?: Maybe<Customer_Order_Item_Adjustment_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Customer_Order_Item_Adjustment_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Order_Item_Adjustment_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Order_Item_Adjustment_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Order_Item_Adjustment_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Customer_Order_Item_Adjustment_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Order_Item_Adjustment_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Customer_Order_Item_Adjustment_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "customer_order_item_adjustment_logged_actions" */
export type Customer_Order_Item_Adjustment_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Item_Adjustment_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Customer_Order_Item_Adjustment_Logged_Actions_Avg_Fields = {
  __typename: 'customer_order_item_adjustment_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "customer_order_item_adjustment_logged_actions". All fields are combined with a logical 'AND'. */
export type Customer_Order_Item_Adjustment_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Order_Item_Adjustment_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Item_Adjustment_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Item_Adjustment_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Customer_Order_Item_Adjustment_Logged_Actions_Max_Fields = {
  __typename: 'customer_order_item_adjustment_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Customer_Order_Item_Adjustment_Logged_Actions_Min_Fields = {
  __typename: 'customer_order_item_adjustment_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "customer_order_item_adjustment_logged_actions". */
export type Customer_Order_Item_Adjustment_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "customer_order_item_adjustment_logged_actions" */
export enum Customer_Order_Item_Adjustment_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Role = 'role',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Customer_Order_Item_Adjustment_Logged_Actions_Stddev_Fields = {
  __typename: 'customer_order_item_adjustment_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Customer_Order_Item_Adjustment_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'customer_order_item_adjustment_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Customer_Order_Item_Adjustment_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'customer_order_item_adjustment_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "customer_order_item_adjustment_logged_actions" */
export type Customer_Order_Item_Adjustment_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Item_Adjustment_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Item_Adjustment_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  role?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Order_Item_Adjustment_Logged_Actions_Sum_Fields = {
  __typename: 'customer_order_item_adjustment_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Customer_Order_Item_Adjustment_Logged_Actions_Var_Pop_Fields = {
  __typename: 'customer_order_item_adjustment_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Customer_Order_Item_Adjustment_Logged_Actions_Var_Samp_Fields = {
  __typename: 'customer_order_item_adjustment_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Customer_Order_Item_Adjustment_Logged_Actions_Variance_Fields = {
  __typename: 'customer_order_item_adjustment_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "customer_order_item_adjustment_types" */
export type Customer_Order_Item_Adjustment_Types = {
  __typename: 'customer_order_item_adjustment_types';
  /** An array relationship */
  customerOrderItemAdjustments: Array<Customer_Order_Item_Adjustments>;
  /** An aggregate relationship */
  customerOrderItemAdjustments_aggregate: Customer_Order_Item_Adjustments_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "customer_order_item_adjustment_types" */
export type Customer_Order_Item_Adjustment_TypesCustomerOrderItemAdjustmentsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustments_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
};


/** columns and relationships of "customer_order_item_adjustment_types" */
export type Customer_Order_Item_Adjustment_TypesCustomerOrderItemAdjustments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustments_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
};

/** aggregated selection of "customer_order_item_adjustment_types" */
export type Customer_Order_Item_Adjustment_Types_Aggregate = {
  __typename: 'customer_order_item_adjustment_types_aggregate';
  aggregate?: Maybe<Customer_Order_Item_Adjustment_Types_Aggregate_Fields>;
  nodes: Array<Customer_Order_Item_Adjustment_Types>;
};

/** aggregate fields of "customer_order_item_adjustment_types" */
export type Customer_Order_Item_Adjustment_Types_Aggregate_Fields = {
  __typename: 'customer_order_item_adjustment_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Item_Adjustment_Types_Max_Fields>;
  min?: Maybe<Customer_Order_Item_Adjustment_Types_Min_Fields>;
};


/** aggregate fields of "customer_order_item_adjustment_types" */
export type Customer_Order_Item_Adjustment_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Item_Adjustment_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_order_item_adjustment_types". All fields are combined with a logical 'AND'. */
export type Customer_Order_Item_Adjustment_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Order_Item_Adjustment_Types_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Item_Adjustment_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Item_Adjustment_Types_Bool_Exp>>;
  customerOrderItemAdjustments?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
  customerOrderItemAdjustments_aggregate?: InputMaybe<Customer_Order_Item_Adjustments_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Customer_Order_Item_Adjustment_Types_Enum {
  PriceChange = 'PRICE_CHANGE',
  WeightChange = 'WEIGHT_CHANGE'
}

/** Boolean expression to compare columns of type "customer_order_item_adjustment_types_enum". All fields are combined with logical 'AND'. */
export type Customer_Order_Item_Adjustment_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Customer_Order_Item_Adjustment_Types_Enum>;
  _in?: InputMaybe<Array<Customer_Order_Item_Adjustment_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Customer_Order_Item_Adjustment_Types_Enum>;
  _nin?: InputMaybe<Array<Customer_Order_Item_Adjustment_Types_Enum>>;
};

/** aggregate max on columns */
export type Customer_Order_Item_Adjustment_Types_Max_Fields = {
  __typename: 'customer_order_item_adjustment_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Order_Item_Adjustment_Types_Min_Fields = {
  __typename: 'customer_order_item_adjustment_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "customer_order_item_adjustment_types". */
export type Customer_Order_Item_Adjustment_Types_Order_By = {
  customerOrderItemAdjustments_aggregate?: InputMaybe<Customer_Order_Item_Adjustments_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "customer_order_item_adjustment_types" */
export enum Customer_Order_Item_Adjustment_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "customer_order_item_adjustment_types" */
export type Customer_Order_Item_Adjustment_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Item_Adjustment_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Item_Adjustment_Types_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments = {
  __typename: 'customer_order_item_adjustments';
  /** An object relationship */
  Tenant: Tenants;
  adjustmentQuantity?: Maybe<Scalars['bigint']>;
  adjustmentType: Customer_Order_Item_Adjustment_Types_Enum;
  /** An object relationship */
  adjustmentUnit?: Maybe<Product_Units>;
  adjustmentUnitId?: Maybe<Scalars['bigint']>;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  creditNote?: Maybe<Credit_Notes>;
  creditNoteId?: Maybe<Scalars['bigint']>;
  customerNote?: Maybe<Scalars['String']>;
  /** An object relationship */
  customerOrderItem: Customer_Order_Items;
  customerOrderItemId: Scalars['bigint'];
  id: Scalars['bigint'];
  newPrice?: Maybe<Scalars['bigint']>;
  salesQuantityChange?: Maybe<Scalars['bigint']>;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
  weightDifference?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_Aggregate = {
  __typename: 'customer_order_item_adjustments_aggregate';
  aggregate?: Maybe<Customer_Order_Item_Adjustments_Aggregate_Fields>;
  nodes: Array<Customer_Order_Item_Adjustments>;
};

export type Customer_Order_Item_Adjustments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Order_Item_Adjustments_Aggregate_Bool_Exp_Count>;
};

export type Customer_Order_Item_Adjustments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Order_Item_Adjustments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_Aggregate_Fields = {
  __typename: 'customer_order_item_adjustments_aggregate_fields';
  avg?: Maybe<Customer_Order_Item_Adjustments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Item_Adjustments_Max_Fields>;
  min?: Maybe<Customer_Order_Item_Adjustments_Min_Fields>;
  stddev?: Maybe<Customer_Order_Item_Adjustments_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Order_Item_Adjustments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Order_Item_Adjustments_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Order_Item_Adjustments_Sum_Fields>;
  var_pop?: Maybe<Customer_Order_Item_Adjustments_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Order_Item_Adjustments_Var_Samp_Fields>;
  variance?: Maybe<Customer_Order_Item_Adjustments_Variance_Fields>;
};


/** aggregate fields of "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Item_Adjustments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Order_Item_Adjustments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Order_Item_Adjustments_Max_Order_By>;
  min?: InputMaybe<Customer_Order_Item_Adjustments_Min_Order_By>;
  stddev?: InputMaybe<Customer_Order_Item_Adjustments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Order_Item_Adjustments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Order_Item_Adjustments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Order_Item_Adjustments_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Order_Item_Adjustments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Order_Item_Adjustments_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Order_Item_Adjustments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_Arr_Rel_Insert_Input = {
  data: Array<Customer_Order_Item_Adjustments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Order_Item_Adjustments_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Order_Item_Adjustments_Avg_Fields = {
  __typename: 'customer_order_item_adjustments_avg_fields';
  adjustmentQuantity?: Maybe<Scalars['Float']>;
  adjustmentUnitId?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  newPrice?: Maybe<Scalars['Float']>;
  salesQuantityChange?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightDifference?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_Avg_Order_By = {
  adjustmentQuantity?: InputMaybe<Order_By>;
  adjustmentUnitId?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  newPrice?: InputMaybe<Order_By>;
  salesQuantityChange?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightDifference?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_order_item_adjustments". All fields are combined with a logical 'AND'. */
export type Customer_Order_Item_Adjustments_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Order_Item_Adjustments_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Item_Adjustments_Bool_Exp>>;
  adjustmentQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  adjustmentType?: InputMaybe<Customer_Order_Item_Adjustment_Types_Enum_Comparison_Exp>;
  adjustmentUnit?: InputMaybe<Product_Units_Bool_Exp>;
  adjustmentUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  creditNote?: InputMaybe<Credit_Notes_Bool_Exp>;
  creditNoteId?: InputMaybe<Bigint_Comparison_Exp>;
  customerNote?: InputMaybe<String_Comparison_Exp>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  customerOrderItemId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  newPrice?: InputMaybe<Bigint_Comparison_Exp>;
  salesQuantityChange?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
  weightDifference?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_order_item_adjustments" */
export enum Customer_Order_Item_Adjustments_Constraint {
  /** unique or primary key constraint on columns "adjustment_type", "customer_order_item_id" */
  CustomerOrderItemAdjustmenCustomerOrderItemIdAdjusIdx1 = 'customer_order_item_adjustmen_customer_order_item_id_adjus_idx1',
  /** unique or primary key constraint on columns "adjustment_type", "customer_order_item_id" */
  CustomerOrderItemAdjustmenCustomerOrderItemIdAdjustIdx = 'customer_order_item_adjustmen_customer_order_item_id_adjust_idx',
  /** unique or primary key constraint on columns "id" */
  CustomerOrderItemAdjustmentsPkey = 'customer_order_item_adjustments_pkey'
}

/** input type for incrementing numeric columns in table "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_Inc_Input = {
  adjustmentQuantity?: InputMaybe<Scalars['bigint']>;
  adjustmentUnitId?: InputMaybe<Scalars['bigint']>;
  creditNoteId?: InputMaybe<Scalars['bigint']>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  newPrice?: InputMaybe<Scalars['bigint']>;
  salesQuantityChange?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  weightDifference?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_Insert_Input = {
  adjustmentQuantity?: InputMaybe<Scalars['bigint']>;
  adjustmentType?: InputMaybe<Customer_Order_Item_Adjustment_Types_Enum>;
  adjustmentUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  adjustmentUnitId?: InputMaybe<Scalars['bigint']>;
  creditNote?: InputMaybe<Credit_Notes_Obj_Rel_Insert_Input>;
  creditNoteId?: InputMaybe<Scalars['bigint']>;
  customerNote?: InputMaybe<Scalars['String']>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Obj_Rel_Insert_Input>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  newPrice?: InputMaybe<Scalars['bigint']>;
  salesQuantityChange?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  weightDifference?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Customer_Order_Item_Adjustments_Max_Fields = {
  __typename: 'customer_order_item_adjustments_max_fields';
  adjustmentQuantity?: Maybe<Scalars['bigint']>;
  adjustmentUnitId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creditNoteId?: Maybe<Scalars['bigint']>;
  customerNote?: Maybe<Scalars['String']>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  newPrice?: Maybe<Scalars['bigint']>;
  salesQuantityChange?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  weightDifference?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_Max_Order_By = {
  adjustmentQuantity?: InputMaybe<Order_By>;
  adjustmentUnitId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerNote?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  newPrice?: InputMaybe<Order_By>;
  salesQuantityChange?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightDifference?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Order_Item_Adjustments_Min_Fields = {
  __typename: 'customer_order_item_adjustments_min_fields';
  adjustmentQuantity?: Maybe<Scalars['bigint']>;
  adjustmentUnitId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creditNoteId?: Maybe<Scalars['bigint']>;
  customerNote?: Maybe<Scalars['String']>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  newPrice?: Maybe<Scalars['bigint']>;
  salesQuantityChange?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  weightDifference?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_Min_Order_By = {
  adjustmentQuantity?: InputMaybe<Order_By>;
  adjustmentUnitId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerNote?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  newPrice?: InputMaybe<Order_By>;
  salesQuantityChange?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightDifference?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_Mutation_Response = {
  __typename: 'customer_order_item_adjustments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Order_Item_Adjustments>;
};

/** on_conflict condition type for table "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_On_Conflict = {
  constraint: Customer_Order_Item_Adjustments_Constraint;
  update_columns?: Array<Customer_Order_Item_Adjustments_Update_Column>;
  where?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_order_item_adjustments". */
export type Customer_Order_Item_Adjustments_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  adjustmentQuantity?: InputMaybe<Order_By>;
  adjustmentType?: InputMaybe<Order_By>;
  adjustmentUnit?: InputMaybe<Product_Units_Order_By>;
  adjustmentUnitId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creditNote?: InputMaybe<Credit_Notes_Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerNote?: InputMaybe<Order_By>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  newPrice?: InputMaybe<Order_By>;
  salesQuantityChange?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightDifference?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_order_item_adjustments */
export type Customer_Order_Item_Adjustments_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customer_order_item_adjustments" */
export enum Customer_Order_Item_Adjustments_Select_Column {
  /** column name */
  AdjustmentQuantity = 'adjustmentQuantity',
  /** column name */
  AdjustmentType = 'adjustmentType',
  /** column name */
  AdjustmentUnitId = 'adjustmentUnitId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreditNoteId = 'creditNoteId',
  /** column name */
  CustomerNote = 'customerNote',
  /** column name */
  CustomerOrderItemId = 'customerOrderItemId',
  /** column name */
  Id = 'id',
  /** column name */
  NewPrice = 'newPrice',
  /** column name */
  SalesQuantityChange = 'salesQuantityChange',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version',
  /** column name */
  WeightDifference = 'weightDifference'
}

/** input type for updating data in table "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_Set_Input = {
  adjustmentQuantity?: InputMaybe<Scalars['bigint']>;
  adjustmentType?: InputMaybe<Customer_Order_Item_Adjustment_Types_Enum>;
  adjustmentUnitId?: InputMaybe<Scalars['bigint']>;
  creditNoteId?: InputMaybe<Scalars['bigint']>;
  customerNote?: InputMaybe<Scalars['String']>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  newPrice?: InputMaybe<Scalars['bigint']>;
  salesQuantityChange?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  weightDifference?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Customer_Order_Item_Adjustments_Stddev_Fields = {
  __typename: 'customer_order_item_adjustments_stddev_fields';
  adjustmentQuantity?: Maybe<Scalars['Float']>;
  adjustmentUnitId?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  newPrice?: Maybe<Scalars['Float']>;
  salesQuantityChange?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightDifference?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_Stddev_Order_By = {
  adjustmentQuantity?: InputMaybe<Order_By>;
  adjustmentUnitId?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  newPrice?: InputMaybe<Order_By>;
  salesQuantityChange?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightDifference?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Order_Item_Adjustments_Stddev_Pop_Fields = {
  __typename: 'customer_order_item_adjustments_stddev_pop_fields';
  adjustmentQuantity?: Maybe<Scalars['Float']>;
  adjustmentUnitId?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  newPrice?: Maybe<Scalars['Float']>;
  salesQuantityChange?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightDifference?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_Stddev_Pop_Order_By = {
  adjustmentQuantity?: InputMaybe<Order_By>;
  adjustmentUnitId?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  newPrice?: InputMaybe<Order_By>;
  salesQuantityChange?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightDifference?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Order_Item_Adjustments_Stddev_Samp_Fields = {
  __typename: 'customer_order_item_adjustments_stddev_samp_fields';
  adjustmentQuantity?: Maybe<Scalars['Float']>;
  adjustmentUnitId?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  newPrice?: Maybe<Scalars['Float']>;
  salesQuantityChange?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightDifference?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_Stddev_Samp_Order_By = {
  adjustmentQuantity?: InputMaybe<Order_By>;
  adjustmentUnitId?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  newPrice?: InputMaybe<Order_By>;
  salesQuantityChange?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightDifference?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Item_Adjustments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Item_Adjustments_Stream_Cursor_Value_Input = {
  adjustmentQuantity?: InputMaybe<Scalars['bigint']>;
  adjustmentType?: InputMaybe<Customer_Order_Item_Adjustment_Types_Enum>;
  adjustmentUnitId?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  creditNoteId?: InputMaybe<Scalars['bigint']>;
  customerNote?: InputMaybe<Scalars['String']>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  newPrice?: InputMaybe<Scalars['bigint']>;
  salesQuantityChange?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
  weightDifference?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Order_Item_Adjustments_Sum_Fields = {
  __typename: 'customer_order_item_adjustments_sum_fields';
  adjustmentQuantity?: Maybe<Scalars['bigint']>;
  adjustmentUnitId?: Maybe<Scalars['bigint']>;
  creditNoteId?: Maybe<Scalars['bigint']>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  newPrice?: Maybe<Scalars['bigint']>;
  salesQuantityChange?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
  weightDifference?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_Sum_Order_By = {
  adjustmentQuantity?: InputMaybe<Order_By>;
  adjustmentUnitId?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  newPrice?: InputMaybe<Order_By>;
  salesQuantityChange?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightDifference?: InputMaybe<Order_By>;
};

/** update columns of table "customer_order_item_adjustments" */
export enum Customer_Order_Item_Adjustments_Update_Column {
  /** column name */
  AdjustmentQuantity = 'adjustmentQuantity',
  /** column name */
  AdjustmentType = 'adjustmentType',
  /** column name */
  AdjustmentUnitId = 'adjustmentUnitId',
  /** column name */
  CreditNoteId = 'creditNoteId',
  /** column name */
  CustomerNote = 'customerNote',
  /** column name */
  CustomerOrderItemId = 'customerOrderItemId',
  /** column name */
  Id = 'id',
  /** column name */
  NewPrice = 'newPrice',
  /** column name */
  SalesQuantityChange = 'salesQuantityChange',
  /** column name */
  Version = 'version',
  /** column name */
  WeightDifference = 'weightDifference'
}

export type Customer_Order_Item_Adjustments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Order_Item_Adjustments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Order_Item_Adjustments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Order_Item_Adjustments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Order_Item_Adjustments_Var_Pop_Fields = {
  __typename: 'customer_order_item_adjustments_var_pop_fields';
  adjustmentQuantity?: Maybe<Scalars['Float']>;
  adjustmentUnitId?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  newPrice?: Maybe<Scalars['Float']>;
  salesQuantityChange?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightDifference?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_Var_Pop_Order_By = {
  adjustmentQuantity?: InputMaybe<Order_By>;
  adjustmentUnitId?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  newPrice?: InputMaybe<Order_By>;
  salesQuantityChange?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightDifference?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Order_Item_Adjustments_Var_Samp_Fields = {
  __typename: 'customer_order_item_adjustments_var_samp_fields';
  adjustmentQuantity?: Maybe<Scalars['Float']>;
  adjustmentUnitId?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  newPrice?: Maybe<Scalars['Float']>;
  salesQuantityChange?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightDifference?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_Var_Samp_Order_By = {
  adjustmentQuantity?: InputMaybe<Order_By>;
  adjustmentUnitId?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  newPrice?: InputMaybe<Order_By>;
  salesQuantityChange?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightDifference?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Order_Item_Adjustments_Variance_Fields = {
  __typename: 'customer_order_item_adjustments_variance_fields';
  adjustmentQuantity?: Maybe<Scalars['Float']>;
  adjustmentUnitId?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  newPrice?: Maybe<Scalars['Float']>;
  salesQuantityChange?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightDifference?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_order_item_adjustments" */
export type Customer_Order_Item_Adjustments_Variance_Order_By = {
  adjustmentQuantity?: InputMaybe<Order_By>;
  adjustmentUnitId?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  newPrice?: InputMaybe<Order_By>;
  salesQuantityChange?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightDifference?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations = {
  __typename: 'customer_order_item_container_expectations';
  /** An object relationship */
  Tenant: Tenants;
  containerPieceQuantity?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  containerProduct: Products;
  containerProductId: Scalars['bigint'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customerOrderItem: Customer_Order_Items;
  customerOrderItemId: Scalars['bigint'];
  ediPackageTypeDescriptionCode?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  salesUnitPerContainerPiece?: Maybe<Scalars['bigint']>;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};

/** aggregated selection of "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_Aggregate = {
  __typename: 'customer_order_item_container_expectations_aggregate';
  aggregate?: Maybe<Customer_Order_Item_Container_Expectations_Aggregate_Fields>;
  nodes: Array<Customer_Order_Item_Container_Expectations>;
};

export type Customer_Order_Item_Container_Expectations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Order_Item_Container_Expectations_Aggregate_Bool_Exp_Count>;
};

export type Customer_Order_Item_Container_Expectations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Order_Item_Container_Expectations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_Aggregate_Fields = {
  __typename: 'customer_order_item_container_expectations_aggregate_fields';
  avg?: Maybe<Customer_Order_Item_Container_Expectations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Item_Container_Expectations_Max_Fields>;
  min?: Maybe<Customer_Order_Item_Container_Expectations_Min_Fields>;
  stddev?: Maybe<Customer_Order_Item_Container_Expectations_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Order_Item_Container_Expectations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Order_Item_Container_Expectations_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Order_Item_Container_Expectations_Sum_Fields>;
  var_pop?: Maybe<Customer_Order_Item_Container_Expectations_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Order_Item_Container_Expectations_Var_Samp_Fields>;
  variance?: Maybe<Customer_Order_Item_Container_Expectations_Variance_Fields>;
};


/** aggregate fields of "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Order_Item_Container_Expectations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Order_Item_Container_Expectations_Max_Order_By>;
  min?: InputMaybe<Customer_Order_Item_Container_Expectations_Min_Order_By>;
  stddev?: InputMaybe<Customer_Order_Item_Container_Expectations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Order_Item_Container_Expectations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Order_Item_Container_Expectations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Order_Item_Container_Expectations_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Order_Item_Container_Expectations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Order_Item_Container_Expectations_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Order_Item_Container_Expectations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_Arr_Rel_Insert_Input = {
  data: Array<Customer_Order_Item_Container_Expectations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Order_Item_Container_Expectations_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Order_Item_Container_Expectations_Avg_Fields = {
  __typename: 'customer_order_item_container_expectations_avg_fields';
  containerPieceQuantity?: Maybe<Scalars['Float']>;
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salesUnitPerContainerPiece?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_Avg_Order_By = {
  containerPieceQuantity?: InputMaybe<Order_By>;
  containerProductId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesUnitPerContainerPiece?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_order_item_container_expectations". All fields are combined with a logical 'AND'. */
export type Customer_Order_Item_Container_Expectations_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Item_Container_Expectations_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Bool_Exp>>;
  containerPieceQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  containerProduct?: InputMaybe<Products_Bool_Exp>;
  containerProductId?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  customerOrderItemId?: InputMaybe<Bigint_Comparison_Exp>;
  ediPackageTypeDescriptionCode?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  salesUnitPerContainerPiece?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_order_item_container_expectations" */
export enum Customer_Order_Item_Container_Expectations_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerOrderItemContainerExpectationsPkey = 'customer_order_item_container_expectations_pkey'
}

/** input type for incrementing numeric columns in table "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_Inc_Input = {
  containerPieceQuantity?: InputMaybe<Scalars['bigint']>;
  containerProductId?: InputMaybe<Scalars['bigint']>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  salesUnitPerContainerPiece?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_Insert_Input = {
  containerPieceQuantity?: InputMaybe<Scalars['bigint']>;
  containerProduct?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  containerProductId?: InputMaybe<Scalars['bigint']>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Obj_Rel_Insert_Input>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  ediPackageTypeDescriptionCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  salesUnitPerContainerPiece?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Customer_Order_Item_Container_Expectations_Max_Fields = {
  __typename: 'customer_order_item_container_expectations_max_fields';
  containerPieceQuantity?: Maybe<Scalars['bigint']>;
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  ediPackageTypeDescriptionCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  salesUnitPerContainerPiece?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_Max_Order_By = {
  containerPieceQuantity?: InputMaybe<Order_By>;
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  ediPackageTypeDescriptionCode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesUnitPerContainerPiece?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Order_Item_Container_Expectations_Min_Fields = {
  __typename: 'customer_order_item_container_expectations_min_fields';
  containerPieceQuantity?: Maybe<Scalars['bigint']>;
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  ediPackageTypeDescriptionCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  salesUnitPerContainerPiece?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_Min_Order_By = {
  containerPieceQuantity?: InputMaybe<Order_By>;
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  ediPackageTypeDescriptionCode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesUnitPerContainerPiece?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_Mutation_Response = {
  __typename: 'customer_order_item_container_expectations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Order_Item_Container_Expectations>;
};

/** on_conflict condition type for table "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_On_Conflict = {
  constraint: Customer_Order_Item_Container_Expectations_Constraint;
  update_columns?: Array<Customer_Order_Item_Container_Expectations_Update_Column>;
  where?: InputMaybe<Customer_Order_Item_Container_Expectations_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_order_item_container_expectations". */
export type Customer_Order_Item_Container_Expectations_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  containerPieceQuantity?: InputMaybe<Order_By>;
  containerProduct?: InputMaybe<Products_Order_By>;
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  ediPackageTypeDescriptionCode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesUnitPerContainerPiece?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_order_item_container_expectations */
export type Customer_Order_Item_Container_Expectations_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customer_order_item_container_expectations" */
export enum Customer_Order_Item_Container_Expectations_Select_Column {
  /** column name */
  ContainerPieceQuantity = 'containerPieceQuantity',
  /** column name */
  ContainerProductId = 'containerProductId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerOrderItemId = 'customerOrderItemId',
  /** column name */
  EdiPackageTypeDescriptionCode = 'ediPackageTypeDescriptionCode',
  /** column name */
  Id = 'id',
  /** column name */
  SalesUnitPerContainerPiece = 'salesUnitPerContainerPiece',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_Set_Input = {
  containerPieceQuantity?: InputMaybe<Scalars['bigint']>;
  containerProductId?: InputMaybe<Scalars['bigint']>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  ediPackageTypeDescriptionCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  salesUnitPerContainerPiece?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Customer_Order_Item_Container_Expectations_Stddev_Fields = {
  __typename: 'customer_order_item_container_expectations_stddev_fields';
  containerPieceQuantity?: Maybe<Scalars['Float']>;
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salesUnitPerContainerPiece?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_Stddev_Order_By = {
  containerPieceQuantity?: InputMaybe<Order_By>;
  containerProductId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesUnitPerContainerPiece?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Order_Item_Container_Expectations_Stddev_Pop_Fields = {
  __typename: 'customer_order_item_container_expectations_stddev_pop_fields';
  containerPieceQuantity?: Maybe<Scalars['Float']>;
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salesUnitPerContainerPiece?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_Stddev_Pop_Order_By = {
  containerPieceQuantity?: InputMaybe<Order_By>;
  containerProductId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesUnitPerContainerPiece?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Order_Item_Container_Expectations_Stddev_Samp_Fields = {
  __typename: 'customer_order_item_container_expectations_stddev_samp_fields';
  containerPieceQuantity?: Maybe<Scalars['Float']>;
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salesUnitPerContainerPiece?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_Stddev_Samp_Order_By = {
  containerPieceQuantity?: InputMaybe<Order_By>;
  containerProductId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesUnitPerContainerPiece?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Item_Container_Expectations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Item_Container_Expectations_Stream_Cursor_Value_Input = {
  containerPieceQuantity?: InputMaybe<Scalars['bigint']>;
  containerProductId?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  ediPackageTypeDescriptionCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  salesUnitPerContainerPiece?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Order_Item_Container_Expectations_Sum_Fields = {
  __typename: 'customer_order_item_container_expectations_sum_fields';
  containerPieceQuantity?: Maybe<Scalars['bigint']>;
  containerProductId?: Maybe<Scalars['bigint']>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  salesUnitPerContainerPiece?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_Sum_Order_By = {
  containerPieceQuantity?: InputMaybe<Order_By>;
  containerProductId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesUnitPerContainerPiece?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "customer_order_item_container_expectations" */
export enum Customer_Order_Item_Container_Expectations_Update_Column {
  /** column name */
  ContainerPieceQuantity = 'containerPieceQuantity',
  /** column name */
  ContainerProductId = 'containerProductId',
  /** column name */
  CustomerOrderItemId = 'customerOrderItemId',
  /** column name */
  EdiPackageTypeDescriptionCode = 'ediPackageTypeDescriptionCode',
  /** column name */
  Id = 'id',
  /** column name */
  SalesUnitPerContainerPiece = 'salesUnitPerContainerPiece',
  /** column name */
  Version = 'version'
}

export type Customer_Order_Item_Container_Expectations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Order_Item_Container_Expectations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Order_Item_Container_Expectations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Order_Item_Container_Expectations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Order_Item_Container_Expectations_Var_Pop_Fields = {
  __typename: 'customer_order_item_container_expectations_var_pop_fields';
  containerPieceQuantity?: Maybe<Scalars['Float']>;
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salesUnitPerContainerPiece?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_Var_Pop_Order_By = {
  containerPieceQuantity?: InputMaybe<Order_By>;
  containerProductId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesUnitPerContainerPiece?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Order_Item_Container_Expectations_Var_Samp_Fields = {
  __typename: 'customer_order_item_container_expectations_var_samp_fields';
  containerPieceQuantity?: Maybe<Scalars['Float']>;
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salesUnitPerContainerPiece?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_Var_Samp_Order_By = {
  containerPieceQuantity?: InputMaybe<Order_By>;
  containerProductId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesUnitPerContainerPiece?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Order_Item_Container_Expectations_Variance_Fields = {
  __typename: 'customer_order_item_container_expectations_variance_fields';
  containerPieceQuantity?: Maybe<Scalars['Float']>;
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salesUnitPerContainerPiece?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_order_item_container_expectations" */
export type Customer_Order_Item_Container_Expectations_Variance_Order_By = {
  containerPieceQuantity?: InputMaybe<Order_By>;
  containerProductId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesUnitPerContainerPiece?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_order_item_extras" */
export type Customer_Order_Item_Extras = {
  __typename: 'customer_order_item_extras';
  /** An object relationship */
  customerOrderItem?: Maybe<Customer_Order_Items>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  finalCostPrice?: Maybe<Scalars['numeric']>;
  finalInvoicePrice?: Maybe<Scalars['numeric']>;
  finalInvoiceWeight?: Maybe<Scalars['numeric']>;
  marginAbsolute?: Maybe<Scalars['numeric']>;
  marginPercentage?: Maybe<Scalars['numeric']>;
  tenant?: Maybe<Scalars['String']>;
};

/** aggregated selection of "customer_order_item_extras" */
export type Customer_Order_Item_Extras_Aggregate = {
  __typename: 'customer_order_item_extras_aggregate';
  aggregate?: Maybe<Customer_Order_Item_Extras_Aggregate_Fields>;
  nodes: Array<Customer_Order_Item_Extras>;
};

/** aggregate fields of "customer_order_item_extras" */
export type Customer_Order_Item_Extras_Aggregate_Fields = {
  __typename: 'customer_order_item_extras_aggregate_fields';
  avg?: Maybe<Customer_Order_Item_Extras_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Item_Extras_Max_Fields>;
  min?: Maybe<Customer_Order_Item_Extras_Min_Fields>;
  stddev?: Maybe<Customer_Order_Item_Extras_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Order_Item_Extras_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Order_Item_Extras_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Order_Item_Extras_Sum_Fields>;
  var_pop?: Maybe<Customer_Order_Item_Extras_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Order_Item_Extras_Var_Samp_Fields>;
  variance?: Maybe<Customer_Order_Item_Extras_Variance_Fields>;
};


/** aggregate fields of "customer_order_item_extras" */
export type Customer_Order_Item_Extras_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Item_Extras_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Customer_Order_Item_Extras_Avg_Fields = {
  __typename: 'customer_order_item_extras_avg_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  finalCostPrice?: Maybe<Scalars['Float']>;
  finalInvoicePrice?: Maybe<Scalars['Float']>;
  finalInvoiceWeight?: Maybe<Scalars['Float']>;
  marginAbsolute?: Maybe<Scalars['Float']>;
  marginPercentage?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "customer_order_item_extras". All fields are combined with a logical 'AND'. */
export type Customer_Order_Item_Extras_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Order_Item_Extras_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Item_Extras_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Item_Extras_Bool_Exp>>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  customerOrderItemId?: InputMaybe<Bigint_Comparison_Exp>;
  finalCostPrice?: InputMaybe<Numeric_Comparison_Exp>;
  finalInvoicePrice?: InputMaybe<Numeric_Comparison_Exp>;
  finalInvoiceWeight?: InputMaybe<Numeric_Comparison_Exp>;
  marginAbsolute?: InputMaybe<Numeric_Comparison_Exp>;
  marginPercentage?: InputMaybe<Numeric_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Customer_Order_Item_Extras_Max_Fields = {
  __typename: 'customer_order_item_extras_max_fields';
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  finalCostPrice?: Maybe<Scalars['numeric']>;
  finalInvoicePrice?: Maybe<Scalars['numeric']>;
  finalInvoiceWeight?: Maybe<Scalars['numeric']>;
  marginAbsolute?: Maybe<Scalars['numeric']>;
  marginPercentage?: Maybe<Scalars['numeric']>;
  tenant?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Order_Item_Extras_Min_Fields = {
  __typename: 'customer_order_item_extras_min_fields';
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  finalCostPrice?: Maybe<Scalars['numeric']>;
  finalInvoicePrice?: Maybe<Scalars['numeric']>;
  finalInvoiceWeight?: Maybe<Scalars['numeric']>;
  marginAbsolute?: Maybe<Scalars['numeric']>;
  marginPercentage?: Maybe<Scalars['numeric']>;
  tenant?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "customer_order_item_extras". */
export type Customer_Order_Item_Extras_Order_By = {
  customerOrderItem?: InputMaybe<Customer_Order_Items_Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  finalCostPrice?: InputMaybe<Order_By>;
  finalInvoicePrice?: InputMaybe<Order_By>;
  finalInvoiceWeight?: InputMaybe<Order_By>;
  marginAbsolute?: InputMaybe<Order_By>;
  marginPercentage?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** select columns of table "customer_order_item_extras" */
export enum Customer_Order_Item_Extras_Select_Column {
  /** column name */
  CustomerOrderItemId = 'customerOrderItemId',
  /** column name */
  FinalCostPrice = 'finalCostPrice',
  /** column name */
  FinalInvoicePrice = 'finalInvoicePrice',
  /** column name */
  FinalInvoiceWeight = 'finalInvoiceWeight',
  /** column name */
  MarginAbsolute = 'marginAbsolute',
  /** column name */
  MarginPercentage = 'marginPercentage',
  /** column name */
  Tenant = 'tenant'
}

/** aggregate stddev on columns */
export type Customer_Order_Item_Extras_Stddev_Fields = {
  __typename: 'customer_order_item_extras_stddev_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  finalCostPrice?: Maybe<Scalars['Float']>;
  finalInvoicePrice?: Maybe<Scalars['Float']>;
  finalInvoiceWeight?: Maybe<Scalars['Float']>;
  marginAbsolute?: Maybe<Scalars['Float']>;
  marginPercentage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Customer_Order_Item_Extras_Stddev_Pop_Fields = {
  __typename: 'customer_order_item_extras_stddev_pop_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  finalCostPrice?: Maybe<Scalars['Float']>;
  finalInvoicePrice?: Maybe<Scalars['Float']>;
  finalInvoiceWeight?: Maybe<Scalars['Float']>;
  marginAbsolute?: Maybe<Scalars['Float']>;
  marginPercentage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Customer_Order_Item_Extras_Stddev_Samp_Fields = {
  __typename: 'customer_order_item_extras_stddev_samp_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  finalCostPrice?: Maybe<Scalars['Float']>;
  finalInvoicePrice?: Maybe<Scalars['Float']>;
  finalInvoiceWeight?: Maybe<Scalars['Float']>;
  marginAbsolute?: Maybe<Scalars['Float']>;
  marginPercentage?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "customer_order_item_extras" */
export type Customer_Order_Item_Extras_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Item_Extras_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Item_Extras_Stream_Cursor_Value_Input = {
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  finalCostPrice?: InputMaybe<Scalars['numeric']>;
  finalInvoicePrice?: InputMaybe<Scalars['numeric']>;
  finalInvoiceWeight?: InputMaybe<Scalars['numeric']>;
  marginAbsolute?: InputMaybe<Scalars['numeric']>;
  marginPercentage?: InputMaybe<Scalars['numeric']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Customer_Order_Item_Extras_Sum_Fields = {
  __typename: 'customer_order_item_extras_sum_fields';
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  finalCostPrice?: Maybe<Scalars['numeric']>;
  finalInvoicePrice?: Maybe<Scalars['numeric']>;
  finalInvoiceWeight?: Maybe<Scalars['numeric']>;
  marginAbsolute?: Maybe<Scalars['numeric']>;
  marginPercentage?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Customer_Order_Item_Extras_Var_Pop_Fields = {
  __typename: 'customer_order_item_extras_var_pop_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  finalCostPrice?: Maybe<Scalars['Float']>;
  finalInvoicePrice?: Maybe<Scalars['Float']>;
  finalInvoiceWeight?: Maybe<Scalars['Float']>;
  marginAbsolute?: Maybe<Scalars['Float']>;
  marginPercentage?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Customer_Order_Item_Extras_Var_Samp_Fields = {
  __typename: 'customer_order_item_extras_var_samp_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  finalCostPrice?: Maybe<Scalars['Float']>;
  finalInvoicePrice?: Maybe<Scalars['Float']>;
  finalInvoiceWeight?: Maybe<Scalars['Float']>;
  marginAbsolute?: Maybe<Scalars['Float']>;
  marginPercentage?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Customer_Order_Item_Extras_Variance_Fields = {
  __typename: 'customer_order_item_extras_variance_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  finalCostPrice?: Maybe<Scalars['Float']>;
  finalInvoicePrice?: Maybe<Scalars['Float']>;
  finalInvoiceWeight?: Maybe<Scalars['Float']>;
  marginAbsolute?: Maybe<Scalars['Float']>;
  marginPercentage?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans = {
  __typename: 'customer_order_item_fulfillment_plans';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customerOrderItem: Customer_Order_Items;
  customerOrderItemId: Scalars['bigint'];
  id: Scalars['bigint'];
  /** When true the plan is to use existing stock, when false the plan is to produce the quantity to this stock location */
  isReserved: Scalars['Boolean'];
  /** An object relationship */
  productUnit: Product_Units;
  productUnitId: Scalars['bigint'];
  quantity: Scalars['bigint'];
  /** An object relationship */
  stockLocation: Stock_Locations;
  stockLocationId: Scalars['bigint'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};

/** aggregated selection of "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_Aggregate = {
  __typename: 'customer_order_item_fulfillment_plans_aggregate';
  aggregate?: Maybe<Customer_Order_Item_Fulfillment_Plans_Aggregate_Fields>;
  nodes: Array<Customer_Order_Item_Fulfillment_Plans>;
};

export type Customer_Order_Item_Fulfillment_Plans_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Aggregate_Bool_Exp_Count>;
};

export type Customer_Order_Item_Fulfillment_Plans_Aggregate_Bool_Exp_Bool_And = {
  arguments: Customer_Order_Item_Fulfillment_Plans_Select_Column_Customer_Order_Item_Fulfillment_Plans_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Order_Item_Fulfillment_Plans_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Customer_Order_Item_Fulfillment_Plans_Select_Column_Customer_Order_Item_Fulfillment_Plans_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Order_Item_Fulfillment_Plans_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_Aggregate_Fields = {
  __typename: 'customer_order_item_fulfillment_plans_aggregate_fields';
  avg?: Maybe<Customer_Order_Item_Fulfillment_Plans_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Item_Fulfillment_Plans_Max_Fields>;
  min?: Maybe<Customer_Order_Item_Fulfillment_Plans_Min_Fields>;
  stddev?: Maybe<Customer_Order_Item_Fulfillment_Plans_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Order_Item_Fulfillment_Plans_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Order_Item_Fulfillment_Plans_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Order_Item_Fulfillment_Plans_Sum_Fields>;
  var_pop?: Maybe<Customer_Order_Item_Fulfillment_Plans_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Order_Item_Fulfillment_Plans_Var_Samp_Fields>;
  variance?: Maybe<Customer_Order_Item_Fulfillment_Plans_Variance_Fields>;
};


/** aggregate fields of "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Max_Order_By>;
  min?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Min_Order_By>;
  stddev?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_Arr_Rel_Insert_Input = {
  data: Array<Customer_Order_Item_Fulfillment_Plans_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Order_Item_Fulfillment_Plans_Avg_Fields = {
  __typename: 'customer_order_item_fulfillment_plans_avg_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_Avg_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_order_item_fulfillment_plans". All fields are combined with a logical 'AND'. */
export type Customer_Order_Item_Fulfillment_Plans_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  customerOrderItemId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  isReserved?: InputMaybe<Boolean_Comparison_Exp>;
  productUnit?: InputMaybe<Product_Units_Bool_Exp>;
  productUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  quantity?: InputMaybe<Bigint_Comparison_Exp>;
  stockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  stockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_order_item_fulfillment_plans" */
export enum Customer_Order_Item_Fulfillment_Plans_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerOrderItemFulfillmentPlansPkey = 'customer_order_item_fulfillment_plans_pkey'
}

/** input type for incrementing numeric columns in table "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_Inc_Input = {
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_Insert_Input = {
  customerOrderItem?: InputMaybe<Customer_Order_Items_Obj_Rel_Insert_Input>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  /** When true the plan is to use existing stock, when false the plan is to produce the quantity to this stock location */
  isReserved?: InputMaybe<Scalars['Boolean']>;
  productUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  stockLocation?: InputMaybe<Stock_Locations_Obj_Rel_Insert_Input>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Customer_Order_Item_Fulfillment_Plans_Max_Fields = {
  __typename: 'customer_order_item_fulfillment_plans_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Order_Item_Fulfillment_Plans_Min_Fields = {
  __typename: 'customer_order_item_fulfillment_plans_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_Mutation_Response = {
  __typename: 'customer_order_item_fulfillment_plans_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Order_Item_Fulfillment_Plans>;
};

/** on_conflict condition type for table "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_On_Conflict = {
  constraint: Customer_Order_Item_Fulfillment_Plans_Constraint;
  update_columns?: Array<Customer_Order_Item_Fulfillment_Plans_Update_Column>;
  where?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_order_item_fulfillment_plans". */
export type Customer_Order_Item_Fulfillment_Plans_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isReserved?: InputMaybe<Order_By>;
  productUnit?: InputMaybe<Product_Units_Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocation?: InputMaybe<Stock_Locations_Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_order_item_fulfillment_plans */
export type Customer_Order_Item_Fulfillment_Plans_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customer_order_item_fulfillment_plans" */
export enum Customer_Order_Item_Fulfillment_Plans_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerOrderItemId = 'customerOrderItemId',
  /** column name */
  Id = 'id',
  /** column name */
  IsReserved = 'isReserved',
  /** column name */
  ProductUnitId = 'productUnitId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  StockLocationId = 'stockLocationId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** select "customer_order_item_fulfillment_plans_aggregate_bool_exp_bool_and_arguments_columns" columns of table "customer_order_item_fulfillment_plans" */
export enum Customer_Order_Item_Fulfillment_Plans_Select_Column_Customer_Order_Item_Fulfillment_Plans_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsReserved = 'isReserved'
}

/** select "customer_order_item_fulfillment_plans_aggregate_bool_exp_bool_or_arguments_columns" columns of table "customer_order_item_fulfillment_plans" */
export enum Customer_Order_Item_Fulfillment_Plans_Select_Column_Customer_Order_Item_Fulfillment_Plans_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsReserved = 'isReserved'
}

/** input type for updating data in table "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_Set_Input = {
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  /** When true the plan is to use existing stock, when false the plan is to produce the quantity to this stock location */
  isReserved?: InputMaybe<Scalars['Boolean']>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Customer_Order_Item_Fulfillment_Plans_Stddev_Fields = {
  __typename: 'customer_order_item_fulfillment_plans_stddev_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_Stddev_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Order_Item_Fulfillment_Plans_Stddev_Pop_Fields = {
  __typename: 'customer_order_item_fulfillment_plans_stddev_pop_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_Stddev_Pop_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Order_Item_Fulfillment_Plans_Stddev_Samp_Fields = {
  __typename: 'customer_order_item_fulfillment_plans_stddev_samp_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_Stddev_Samp_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Item_Fulfillment_Plans_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Item_Fulfillment_Plans_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  /** When true the plan is to use existing stock, when false the plan is to produce the quantity to this stock location */
  isReserved?: InputMaybe<Scalars['Boolean']>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Order_Item_Fulfillment_Plans_Sum_Fields = {
  __typename: 'customer_order_item_fulfillment_plans_sum_fields';
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_Sum_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "customer_order_item_fulfillment_plans" */
export enum Customer_Order_Item_Fulfillment_Plans_Update_Column {
  /** column name */
  CustomerOrderItemId = 'customerOrderItemId',
  /** column name */
  Id = 'id',
  /** column name */
  IsReserved = 'isReserved',
  /** column name */
  ProductUnitId = 'productUnitId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  StockLocationId = 'stockLocationId',
  /** column name */
  Version = 'version'
}

export type Customer_Order_Item_Fulfillment_Plans_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Order_Item_Fulfillment_Plans_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Order_Item_Fulfillment_Plans_Var_Pop_Fields = {
  __typename: 'customer_order_item_fulfillment_plans_var_pop_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_Var_Pop_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Order_Item_Fulfillment_Plans_Var_Samp_Fields = {
  __typename: 'customer_order_item_fulfillment_plans_var_samp_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_Var_Samp_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Order_Item_Fulfillment_Plans_Variance_Fields = {
  __typename: 'customer_order_item_fulfillment_plans_variance_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_order_item_fulfillment_plans" */
export type Customer_Order_Item_Fulfillment_Plans_Variance_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_order_item_logged_actions" */
export type Customer_Order_Item_Logged_Actions = {
  __typename: 'customer_order_item_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  entity?: Maybe<Customer_Order_Items>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "customer_order_item_logged_actions" */
export type Customer_Order_Item_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "customer_order_item_logged_actions" */
export type Customer_Order_Item_Logged_Actions_Aggregate = {
  __typename: 'customer_order_item_logged_actions_aggregate';
  aggregate?: Maybe<Customer_Order_Item_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Customer_Order_Item_Logged_Actions>;
};

export type Customer_Order_Item_Logged_Actions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Order_Item_Logged_Actions_Aggregate_Bool_Exp_Count>;
};

export type Customer_Order_Item_Logged_Actions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Order_Item_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Order_Item_Logged_Actions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_order_item_logged_actions" */
export type Customer_Order_Item_Logged_Actions_Aggregate_Fields = {
  __typename: 'customer_order_item_logged_actions_aggregate_fields';
  avg?: Maybe<Customer_Order_Item_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Item_Logged_Actions_Max_Fields>;
  min?: Maybe<Customer_Order_Item_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Customer_Order_Item_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Order_Item_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Order_Item_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Order_Item_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Customer_Order_Item_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Order_Item_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Customer_Order_Item_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "customer_order_item_logged_actions" */
export type Customer_Order_Item_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Item_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_order_item_logged_actions" */
export type Customer_Order_Item_Logged_Actions_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Order_Item_Logged_Actions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Order_Item_Logged_Actions_Max_Order_By>;
  min?: InputMaybe<Customer_Order_Item_Logged_Actions_Min_Order_By>;
  stddev?: InputMaybe<Customer_Order_Item_Logged_Actions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Order_Item_Logged_Actions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Order_Item_Logged_Actions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Order_Item_Logged_Actions_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Order_Item_Logged_Actions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Order_Item_Logged_Actions_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Order_Item_Logged_Actions_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Customer_Order_Item_Logged_Actions_Avg_Fields = {
  __typename: 'customer_order_item_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_order_item_logged_actions" */
export type Customer_Order_Item_Logged_Actions_Avg_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_order_item_logged_actions". All fields are combined with a logical 'AND'. */
export type Customer_Order_Item_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Order_Item_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Item_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Item_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entity?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Customer_Order_Item_Logged_Actions_Max_Fields = {
  __typename: 'customer_order_item_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_order_item_logged_actions" */
export type Customer_Order_Item_Logged_Actions_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Order_Item_Logged_Actions_Min_Fields = {
  __typename: 'customer_order_item_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_order_item_logged_actions" */
export type Customer_Order_Item_Logged_Actions_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "customer_order_item_logged_actions". */
export type Customer_Order_Item_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entity?: InputMaybe<Customer_Order_Items_Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "customer_order_item_logged_actions" */
export enum Customer_Order_Item_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Role = 'role',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Customer_Order_Item_Logged_Actions_Stddev_Fields = {
  __typename: 'customer_order_item_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_order_item_logged_actions" */
export type Customer_Order_Item_Logged_Actions_Stddev_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Order_Item_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'customer_order_item_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_order_item_logged_actions" */
export type Customer_Order_Item_Logged_Actions_Stddev_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Order_Item_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'customer_order_item_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_order_item_logged_actions" */
export type Customer_Order_Item_Logged_Actions_Stddev_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_order_item_logged_actions" */
export type Customer_Order_Item_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Item_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Item_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  role?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Order_Item_Logged_Actions_Sum_Fields = {
  __typename: 'customer_order_item_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_order_item_logged_actions" */
export type Customer_Order_Item_Logged_Actions_Sum_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Customer_Order_Item_Logged_Actions_Var_Pop_Fields = {
  __typename: 'customer_order_item_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_order_item_logged_actions" */
export type Customer_Order_Item_Logged_Actions_Var_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Order_Item_Logged_Actions_Var_Samp_Fields = {
  __typename: 'customer_order_item_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_order_item_logged_actions" */
export type Customer_Order_Item_Logged_Actions_Var_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Order_Item_Logged_Actions_Variance_Fields = {
  __typename: 'customer_order_item_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_order_item_logged_actions" */
export type Customer_Order_Item_Logged_Actions_Variance_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes = {
  __typename: 'customer_order_item_return_causes';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  customerOrderReturnItems: Array<Customer_Order_Return_Items>;
  /** An aggregate relationship */
  customerOrderReturnItems_aggregate: Customer_Order_Return_Items_Aggregate;
  i18n: Scalars['jsonb'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  ordinalPosition: Scalars['Int'];
  tenant: Scalars['String'];
};


/** columns and relationships of "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_CausesCustomerOrderReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_CausesCustomerOrderReturnItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_CausesI18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_Aggregate = {
  __typename: 'customer_order_item_return_causes_aggregate';
  aggregate?: Maybe<Customer_Order_Item_Return_Causes_Aggregate_Fields>;
  nodes: Array<Customer_Order_Item_Return_Causes>;
};

export type Customer_Order_Item_Return_Causes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Order_Item_Return_Causes_Aggregate_Bool_Exp_Count>;
};

export type Customer_Order_Item_Return_Causes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Order_Item_Return_Causes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_Aggregate_Fields = {
  __typename: 'customer_order_item_return_causes_aggregate_fields';
  avg?: Maybe<Customer_Order_Item_Return_Causes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Item_Return_Causes_Max_Fields>;
  min?: Maybe<Customer_Order_Item_Return_Causes_Min_Fields>;
  stddev?: Maybe<Customer_Order_Item_Return_Causes_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Order_Item_Return_Causes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Order_Item_Return_Causes_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Order_Item_Return_Causes_Sum_Fields>;
  var_pop?: Maybe<Customer_Order_Item_Return_Causes_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Order_Item_Return_Causes_Var_Samp_Fields>;
  variance?: Maybe<Customer_Order_Item_Return_Causes_Variance_Fields>;
};


/** aggregate fields of "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Order_Item_Return_Causes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Order_Item_Return_Causes_Max_Order_By>;
  min?: InputMaybe<Customer_Order_Item_Return_Causes_Min_Order_By>;
  stddev?: InputMaybe<Customer_Order_Item_Return_Causes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Order_Item_Return_Causes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Order_Item_Return_Causes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Order_Item_Return_Causes_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Order_Item_Return_Causes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Order_Item_Return_Causes_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Order_Item_Return_Causes_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Customer_Order_Item_Return_Causes_Append_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Customer_Order_Item_Return_Causes_Avg_Fields = {
  __typename: 'customer_order_item_return_causes_avg_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_order_item_return_causes". All fields are combined with a logical 'AND'. */
export type Customer_Order_Item_Return_Causes_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Item_Return_Causes_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Bool_Exp>>;
  customerOrderReturnItems?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
  customerOrderReturnItems_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Bool_Exp>;
  i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_order_item_return_causes" */
export enum Customer_Order_Item_Return_Causes_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerOrderItemReturnCausesPkey = 'customer_order_item_return_causes_pkey',
  /** unique or primary key constraint on columns "name", "tenant" */
  CustomerOrderItemReturnCausesTenantNameKey = 'customer_order_item_return_causes_tenant_name_key',
  /** unique or primary key constraint on columns "tenant", "ordinal_position" */
  CustomerOrderItemReturnCausesTenantOrdinalPositionUniq = 'customer_order_item_return_causes_tenant_ordinal_position_uniq'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Customer_Order_Item_Return_Causes_Delete_At_Path_Input = {
  i18n?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Customer_Order_Item_Return_Causes_Delete_Elem_Input = {
  i18n?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Customer_Order_Item_Return_Causes_Delete_Key_Input = {
  i18n?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_Insert_Input = {
  customerOrderReturnItems?: InputMaybe<Customer_Order_Return_Items_Arr_Rel_Insert_Input>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Customer_Order_Item_Return_Causes_Max_Fields = {
  __typename: 'customer_order_item_return_causes_max_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Order_Item_Return_Causes_Min_Fields = {
  __typename: 'customer_order_item_return_causes_min_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_Mutation_Response = {
  __typename: 'customer_order_item_return_causes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Order_Item_Return_Causes>;
};

/** input type for inserting object relation for remote table "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_Obj_Rel_Insert_Input = {
  data: Customer_Order_Item_Return_Causes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Order_Item_Return_Causes_On_Conflict>;
};

/** on_conflict condition type for table "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_On_Conflict = {
  constraint: Customer_Order_Item_Return_Causes_Constraint;
  update_columns?: Array<Customer_Order_Item_Return_Causes_Update_Column>;
  where?: InputMaybe<Customer_Order_Item_Return_Causes_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_order_item_return_causes". */
export type Customer_Order_Item_Return_Causes_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  customerOrderReturnItems_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Order_By>;
  i18n?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_order_item_return_causes */
export type Customer_Order_Item_Return_Causes_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Customer_Order_Item_Return_Causes_Prepend_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "customer_order_item_return_causes" */
export enum Customer_Order_Item_Return_Causes_Select_Column {
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  Tenant = 'tenant'
}

/** input type for updating data in table "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_Set_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Customer_Order_Item_Return_Causes_Stddev_Fields = {
  __typename: 'customer_order_item_return_causes_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Order_Item_Return_Causes_Stddev_Pop_Fields = {
  __typename: 'customer_order_item_return_causes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Order_Item_Return_Causes_Stddev_Samp_Fields = {
  __typename: 'customer_order_item_return_causes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Item_Return_Causes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Item_Return_Causes_Stream_Cursor_Value_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Customer_Order_Item_Return_Causes_Sum_Fields = {
  __typename: 'customer_order_item_return_causes_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** update columns of table "customer_order_item_return_causes" */
export enum Customer_Order_Item_Return_Causes_Update_Column {
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition'
}

export type Customer_Order_Item_Return_Causes_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Customer_Order_Item_Return_Causes_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Customer_Order_Item_Return_Causes_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Customer_Order_Item_Return_Causes_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Customer_Order_Item_Return_Causes_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Order_Item_Return_Causes_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Customer_Order_Item_Return_Causes_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Order_Item_Return_Causes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Order_Item_Return_Causes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Order_Item_Return_Causes_Var_Pop_Fields = {
  __typename: 'customer_order_item_return_causes_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Order_Item_Return_Causes_Var_Samp_Fields = {
  __typename: 'customer_order_item_return_causes_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Order_Item_Return_Causes_Variance_Fields = {
  __typename: 'customer_order_item_return_causes_variance_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_order_item_return_causes" */
export type Customer_Order_Item_Return_Causes_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_order_item_state_transitions" */
export type Customer_Order_Item_State_Transitions = {
  __typename: 'customer_order_item_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Customer_Order_Item_States_Enum;
  toState: Customer_Order_Item_States_Enum;
};

/** aggregated selection of "customer_order_item_state_transitions" */
export type Customer_Order_Item_State_Transitions_Aggregate = {
  __typename: 'customer_order_item_state_transitions_aggregate';
  aggregate?: Maybe<Customer_Order_Item_State_Transitions_Aggregate_Fields>;
  nodes: Array<Customer_Order_Item_State_Transitions>;
};

/** aggregate fields of "customer_order_item_state_transitions" */
export type Customer_Order_Item_State_Transitions_Aggregate_Fields = {
  __typename: 'customer_order_item_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Item_State_Transitions_Max_Fields>;
  min?: Maybe<Customer_Order_Item_State_Transitions_Min_Fields>;
};


/** aggregate fields of "customer_order_item_state_transitions" */
export type Customer_Order_Item_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Item_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_order_item_state_transitions". All fields are combined with a logical 'AND'. */
export type Customer_Order_Item_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Order_Item_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Item_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Item_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Customer_Order_Item_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Customer_Order_Item_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Customer_Order_Item_State_Transitions_Max_Fields = {
  __typename: 'customer_order_item_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Order_Item_State_Transitions_Min_Fields = {
  __typename: 'customer_order_item_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "customer_order_item_state_transitions". */
export type Customer_Order_Item_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "customer_order_item_state_transitions" */
export enum Customer_Order_Item_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "customer_order_item_state_transitions" */
export type Customer_Order_Item_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Item_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Item_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Customer_Order_Item_States_Enum>;
  toState?: InputMaybe<Customer_Order_Item_States_Enum>;
};

/** columns and relationships of "customer_order_item_states" */
export type Customer_Order_Item_States = {
  __typename: 'customer_order_item_states';
  /** An array relationship */
  customerOrderItems: Array<Customer_Order_Items>;
  /** An aggregate relationship */
  customerOrderItems_aggregate: Customer_Order_Items_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "customer_order_item_states" */
export type Customer_Order_Item_StatesCustomerOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** columns and relationships of "customer_order_item_states" */
export type Customer_Order_Item_StatesCustomerOrderItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};

/** aggregated selection of "customer_order_item_states" */
export type Customer_Order_Item_States_Aggregate = {
  __typename: 'customer_order_item_states_aggregate';
  aggregate?: Maybe<Customer_Order_Item_States_Aggregate_Fields>;
  nodes: Array<Customer_Order_Item_States>;
};

/** aggregate fields of "customer_order_item_states" */
export type Customer_Order_Item_States_Aggregate_Fields = {
  __typename: 'customer_order_item_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Item_States_Max_Fields>;
  min?: Maybe<Customer_Order_Item_States_Min_Fields>;
};


/** aggregate fields of "customer_order_item_states" */
export type Customer_Order_Item_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Item_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_order_item_states". All fields are combined with a logical 'AND'. */
export type Customer_Order_Item_States_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Order_Item_States_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Item_States_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Item_States_Bool_Exp>>;
  customerOrderItems?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  customerOrderItems_aggregate?: InputMaybe<Customer_Order_Items_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Customer_Order_Item_States_Enum {
  Canceled = 'CANCELED',
  DismantlingInprogress = 'DISMANTLING_INPROGRESS',
  Open = 'OPEN',
  Picked = 'PICKED',
  PickingInProgress = 'PICKING_IN_PROGRESS',
  ReadyForPicking = 'READY_FOR_PICKING'
}

/** Boolean expression to compare columns of type "customer_order_item_states_enum". All fields are combined with logical 'AND'. */
export type Customer_Order_Item_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Customer_Order_Item_States_Enum>;
  _in?: InputMaybe<Array<Customer_Order_Item_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Customer_Order_Item_States_Enum>;
  _nin?: InputMaybe<Array<Customer_Order_Item_States_Enum>>;
};

/** aggregate max on columns */
export type Customer_Order_Item_States_Max_Fields = {
  __typename: 'customer_order_item_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Order_Item_States_Min_Fields = {
  __typename: 'customer_order_item_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "customer_order_item_states". */
export type Customer_Order_Item_States_Order_By = {
  customerOrderItems_aggregate?: InputMaybe<Customer_Order_Items_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "customer_order_item_states" */
export enum Customer_Order_Item_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "customer_order_item_states" */
export type Customer_Order_Item_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Item_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Item_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "customer_order_items" */
export type Customer_Order_Items = {
  __typename: 'customer_order_items';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  aggregatedDemandPlanOutgoingGoodsResults: Array<Aggregated_Demand_Plan_Outgoing_Goods_Results>;
  /** An aggregate relationship */
  aggregatedDemandPlanOutgoingGoodsResults_aggregate: Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate;
  /** An object relationship */
  containerProduct?: Maybe<Products>;
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt: Scalars['timestamptz'];
  currency: Currencies_Enum;
  /** Returns a matching active temporal product which matches the order delivery date. */
  currentTemporalProducts?: Maybe<Array<Temporal_Products>>;
  customerNote?: Maybe<Scalars['String']>;
  /** An object relationship */
  customerOrder: Customer_Orders;
  customerOrderId: Scalars['bigint'];
  /** An array relationship */
  customerOrderItemAdjustments: Array<Customer_Order_Item_Adjustments>;
  /** An aggregate relationship */
  customerOrderItemAdjustments_aggregate: Customer_Order_Item_Adjustments_Aggregate;
  /** An array relationship */
  customerOrderItemContainerExpectations: Array<Customer_Order_Item_Container_Expectations>;
  /** An aggregate relationship */
  customerOrderItemContainerExpectations_aggregate: Customer_Order_Item_Container_Expectations_Aggregate;
  /** An object relationship */
  customerOrderItemExtra?: Maybe<Customer_Order_Item_Extras>;
  /** An array relationship */
  customerOrderItemFulfillmentPlans: Array<Customer_Order_Item_Fulfillment_Plans>;
  /** An aggregate relationship */
  customerOrderItemFulfillmentPlans_aggregate: Customer_Order_Item_Fulfillment_Plans_Aggregate;
  /** An array relationship */
  customerOrderReturnItems: Array<Customer_Order_Return_Items>;
  /** An aggregate relationship */
  customerOrderReturnItems_aggregate: Customer_Order_Return_Items_Aggregate;
  /** Returns the demands necessary to produce this order item, using the order unit + quantity. */
  demands?: Maybe<Array<Demands_For_Coi_Results>>;
  /** Returns the calculated dismantling product demands necessary to produce this order item in pieces, using the order unit + quantity. */
  dismantlingDemands?: Maybe<Array<Demands_For_Coi_Results>>;
  /** An array relationship */
  dismantlingPlanDemands: Array<Dismantling_Plan_Demands>;
  /** An aggregate relationship */
  dismantlingPlanDemands_aggregate: Dismantling_Plan_Demands_Aggregate;
  fixedPrice: Scalars['Boolean'];
  hasDemandChanges: Scalars['Boolean'];
  id: Scalars['bigint'];
  invoicePrice?: Maybe<Scalars['bigint']>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: Maybe<Scalars['bigint']>;
  invoiceQuantity?: Maybe<Scalars['bigint']>;
  /** Check whether the order item is promoted by the tenant, leading to atypical quantities. */
  isPromoted?: Maybe<Scalars['Boolean']>;
  labelingPricePerUnit?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  loggedActions: Array<Customer_Order_Item_Logged_Actions>;
  /** An aggregate relationship */
  loggedActions_aggregate: Customer_Order_Item_Logged_Actions_Aggregate;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  offeredQuantity?: Maybe<Scalars['bigint']>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: Maybe<Scalars['bigint']>;
  /** The quantity that the customer ordered. */
  orderQuantity: Scalars['bigint'];
  /** Calculates order quantity in sales unit */
  orderQuantityInSalesUnit?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  orderUnit: Product_Units;
  /** The unit of order_quantity. */
  orderUnitId: Scalars['bigint'];
  /** Computes the name of the order unit */
  orderUnitName?: Maybe<Scalars['String']>;
  orderUnitToSalesUnitFactor?: Maybe<Scalars['numeric']>;
  ordinalPosition: Scalars['Int'];
  /** An array relationship */
  pickedItems: Array<Picked_Items>;
  /** An aggregate relationship */
  pickedItems_aggregate: Picked_Items_Aggregate;
  /** An object relationship */
  pickingDeviationReason?: Maybe<Picking_Deviation_Reasons>;
  pickingDeviationReasonId?: Maybe<Scalars['bigint']>;
  pickingDeviationType?: Maybe<Picking_Deviation_Types_Enum>;
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  productionNote?: Maybe<Scalars['String']>;
  requirePicking: Scalars['Boolean'];
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: Maybe<Scalars['bigint']>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: Maybe<Scalars['bigint']>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: Maybe<Scalars['bigint']>;
  /** Calculates sales quantity in order unit */
  salesQuantityInOrderUnit?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  salesUnit: Product_Units;
  /** The unit in which the order_price is calculated. */
  salesUnitId: Scalars['bigint'];
  /** Computes the name of the sales unit */
  salesUnitName?: Maybe<Scalars['String']>;
  state: Customer_Order_Item_States_Enum;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  vatOnInvoicePrice?: Maybe<Scalars['bigint']>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct: Scalars['numeric'];
  /** The VAT type code copied over from the product base data at the time of order item creation. */
  vatType: Scalars['String'];
  version: Scalars['bigint'];
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsAggregatedDemandPlanOutgoingGoodsResultsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsAggregatedDemandPlanOutgoingGoodsResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsCurrentTemporalProductsArgs = {
  args: CurrentTemporalProducts_Customer_Order_Items_Args;
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsCustomerOrderItemAdjustmentsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustments_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsCustomerOrderItemAdjustments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustments_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsCustomerOrderItemContainerExpectationsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Container_Expectations_Bool_Exp>;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsCustomerOrderItemContainerExpectations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Container_Expectations_Bool_Exp>;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsCustomerOrderItemFulfillmentPlansArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsCustomerOrderItemFulfillmentPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsCustomerOrderReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsCustomerOrderReturnItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsDemandsArgs = {
  distinct_on?: InputMaybe<Array<Demands_For_Coi_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Demands_For_Coi_Results_Order_By>>;
  where?: InputMaybe<Demands_For_Coi_Results_Bool_Exp>;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsDismantlingDemandsArgs = {
  distinct_on?: InputMaybe<Array<Demands_For_Coi_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Demands_For_Coi_Results_Order_By>>;
  where?: InputMaybe<Demands_For_Coi_Results_Bool_Exp>;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsDismantlingPlanDemandsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Demands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Demands_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsDismantlingPlanDemands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Demands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Demands_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsIsPromotedArgs = {
  args: IsPromoted_Customer_Order_Items_Args;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsPickedItemsArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "customer_order_items" */
export type Customer_Order_ItemsPickedItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};

/** aggregated selection of "customer_order_items" */
export type Customer_Order_Items_Aggregate = {
  __typename: 'customer_order_items_aggregate';
  aggregate?: Maybe<Customer_Order_Items_Aggregate_Fields>;
  nodes: Array<Customer_Order_Items>;
};

export type Customer_Order_Items_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Customer_Order_Items_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Customer_Order_Items_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Customer_Order_Items_Aggregate_Bool_Exp_Count>;
};

export type Customer_Order_Items_Aggregate_Bool_Exp_Bool_And = {
  arguments: Customer_Order_Items_Select_Column_Customer_Order_Items_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Order_Items_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Customer_Order_Items_Select_Column_Customer_Order_Items_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Order_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_order_items" */
export type Customer_Order_Items_Aggregate_Fields = {
  __typename: 'customer_order_items_aggregate_fields';
  avg?: Maybe<Customer_Order_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Items_Max_Fields>;
  min?: Maybe<Customer_Order_Items_Min_Fields>;
  stddev?: Maybe<Customer_Order_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Order_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Order_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Order_Items_Sum_Fields>;
  var_pop?: Maybe<Customer_Order_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Order_Items_Var_Samp_Fields>;
  variance?: Maybe<Customer_Order_Items_Variance_Fields>;
};


/** aggregate fields of "customer_order_items" */
export type Customer_Order_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_order_items" */
export type Customer_Order_Items_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Order_Items_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Order_Items_Max_Order_By>;
  min?: InputMaybe<Customer_Order_Items_Min_Order_By>;
  stddev?: InputMaybe<Customer_Order_Items_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Order_Items_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Order_Items_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Order_Items_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Order_Items_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Order_Items_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Order_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_order_items" */
export type Customer_Order_Items_Arr_Rel_Insert_Input = {
  data: Array<Customer_Order_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Order_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Order_Items_Avg_Fields = {
  __typename: 'customer_order_items_avg_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoicePrice?: Maybe<Scalars['Float']>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: Maybe<Scalars['Float']>;
  invoiceQuantity?: Maybe<Scalars['Float']>;
  labelingPricePerUnit?: Maybe<Scalars['Float']>;
  offeredQuantity?: Maybe<Scalars['Float']>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: Maybe<Scalars['Float']>;
  /** The quantity that the customer ordered. */
  orderQuantity?: Maybe<Scalars['Float']>;
  /** The unit of order_quantity. */
  orderUnitId?: Maybe<Scalars['Float']>;
  orderUnitToSalesUnitFactor?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pickingDeviationReasonId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: Maybe<Scalars['Float']>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: Maybe<Scalars['Float']>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: Maybe<Scalars['Float']>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: Maybe<Scalars['Float']>;
  vatOnInvoicePrice?: Maybe<Scalars['Float']>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_order_items" */
export type Customer_Order_Items_Avg_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicePrice?: InputMaybe<Order_By>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: InputMaybe<Order_By>;
  invoiceQuantity?: InputMaybe<Order_By>;
  labelingPricePerUnit?: InputMaybe<Order_By>;
  offeredQuantity?: InputMaybe<Order_By>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: InputMaybe<Order_By>;
  /** The quantity that the customer ordered. */
  orderQuantity?: InputMaybe<Order_By>;
  /** The unit of order_quantity. */
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToSalesUnitFactor?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: InputMaybe<Order_By>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: InputMaybe<Order_By>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: InputMaybe<Order_By>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: InputMaybe<Order_By>;
  vatOnInvoicePrice?: InputMaybe<Order_By>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_order_items". All fields are combined with a logical 'AND'. */
export type Customer_Order_Items_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Order_Items_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Items_Bool_Exp>>;
  aggregatedDemandPlanOutgoingGoodsResults?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
  aggregatedDemandPlanOutgoingGoodsResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Bool_Exp>;
  containerProduct?: InputMaybe<Products_Bool_Exp>;
  containerProductId?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  customerNote?: InputMaybe<String_Comparison_Exp>;
  customerOrder?: InputMaybe<Customer_Orders_Bool_Exp>;
  customerOrderId?: InputMaybe<Bigint_Comparison_Exp>;
  customerOrderItemAdjustments?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
  customerOrderItemAdjustments_aggregate?: InputMaybe<Customer_Order_Item_Adjustments_Aggregate_Bool_Exp>;
  customerOrderItemContainerExpectations?: InputMaybe<Customer_Order_Item_Container_Expectations_Bool_Exp>;
  customerOrderItemContainerExpectations_aggregate?: InputMaybe<Customer_Order_Item_Container_Expectations_Aggregate_Bool_Exp>;
  customerOrderItemExtra?: InputMaybe<Customer_Order_Item_Extras_Bool_Exp>;
  customerOrderItemFulfillmentPlans?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
  customerOrderItemFulfillmentPlans_aggregate?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Aggregate_Bool_Exp>;
  customerOrderReturnItems?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
  customerOrderReturnItems_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Bool_Exp>;
  demands?: InputMaybe<Demands_For_Coi_Results_Bool_Exp>;
  dismantlingDemands?: InputMaybe<Demands_For_Coi_Results_Bool_Exp>;
  dismantlingPlanDemands?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
  dismantlingPlanDemands_aggregate?: InputMaybe<Dismantling_Plan_Demands_Aggregate_Bool_Exp>;
  fixedPrice?: InputMaybe<Boolean_Comparison_Exp>;
  hasDemandChanges?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  invoicePrice?: InputMaybe<Bigint_Comparison_Exp>;
  invoicePricePerUnit?: InputMaybe<Bigint_Comparison_Exp>;
  invoiceQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  labelingPricePerUnit?: InputMaybe<Bigint_Comparison_Exp>;
  loggedActions?: InputMaybe<Customer_Order_Item_Logged_Actions_Bool_Exp>;
  loggedActions_aggregate?: InputMaybe<Customer_Order_Item_Logged_Actions_Aggregate_Bool_Exp>;
  notifications?: InputMaybe<Notifications_Bool_Exp>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  offeredQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  orderPrice?: InputMaybe<Bigint_Comparison_Exp>;
  orderQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  orderQuantityInSalesUnit?: InputMaybe<Bigint_Comparison_Exp>;
  orderUnit?: InputMaybe<Product_Units_Bool_Exp>;
  orderUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  orderUnitName?: InputMaybe<String_Comparison_Exp>;
  orderUnitToSalesUnitFactor?: InputMaybe<Numeric_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  pickedItems?: InputMaybe<Picked_Items_Bool_Exp>;
  pickedItems_aggregate?: InputMaybe<Picked_Items_Aggregate_Bool_Exp>;
  pickingDeviationReason?: InputMaybe<Picking_Deviation_Reasons_Bool_Exp>;
  pickingDeviationReasonId?: InputMaybe<Bigint_Comparison_Exp>;
  pickingDeviationType?: InputMaybe<Picking_Deviation_Types_Enum_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  productionNote?: InputMaybe<String_Comparison_Exp>;
  requirePicking?: InputMaybe<Boolean_Comparison_Exp>;
  salesPrice?: InputMaybe<Bigint_Comparison_Exp>;
  salesPricePerUnit?: InputMaybe<Bigint_Comparison_Exp>;
  salesQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  salesQuantityInOrderUnit?: InputMaybe<Bigint_Comparison_Exp>;
  salesUnit?: InputMaybe<Product_Units_Bool_Exp>;
  salesUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  salesUnitName?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<Customer_Order_Item_States_Enum_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  vatOnInvoicePrice?: InputMaybe<Bigint_Comparison_Exp>;
  vatPct?: InputMaybe<Numeric_Comparison_Exp>;
  vatType?: InputMaybe<String_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_order_items" */
export enum Customer_Order_Items_Constraint {
  /** unique or primary key constraint on columns "customer_order_id", "ordinal_position" */
  CustomerOrderItemsCoIdOrdinalPositionUniq = 'customer_order_items_co_id_ordinal_position_uniq',
  /** unique or primary key constraint on columns "id" */
  CustomerOrderItemsPkey = 'customer_order_items_pkey'
}

/** input type for incrementing numeric columns in table "customer_order_items" */
export type Customer_Order_Items_Inc_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  labelingPricePerUnit?: InputMaybe<Scalars['bigint']>;
  offeredQuantity?: InputMaybe<Scalars['bigint']>;
  /** The quantity that the customer ordered. */
  orderQuantity?: InputMaybe<Scalars['bigint']>;
  /** The unit of order_quantity. */
  orderUnitId?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  pickingDeviationReasonId?: InputMaybe<Scalars['bigint']>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: InputMaybe<Scalars['bigint']>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_order_items" */
export type Customer_Order_Items_Insert_Input = {
  containerProduct?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  containerProductId?: InputMaybe<Scalars['bigint']>;
  currency?: InputMaybe<Currencies_Enum>;
  customerNote?: InputMaybe<Scalars['String']>;
  customerOrder?: InputMaybe<Customer_Orders_Obj_Rel_Insert_Input>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  customerOrderItemAdjustments?: InputMaybe<Customer_Order_Item_Adjustments_Arr_Rel_Insert_Input>;
  customerOrderItemContainerExpectations?: InputMaybe<Customer_Order_Item_Container_Expectations_Arr_Rel_Insert_Input>;
  customerOrderItemFulfillmentPlans?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Arr_Rel_Insert_Input>;
  customerOrderReturnItems?: InputMaybe<Customer_Order_Return_Items_Arr_Rel_Insert_Input>;
  dismantlingPlanDemands?: InputMaybe<Dismantling_Plan_Demands_Arr_Rel_Insert_Input>;
  fixedPrice?: InputMaybe<Scalars['Boolean']>;
  hasDemandChanges?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  invoicePrice?: InputMaybe<Scalars['bigint']>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: InputMaybe<Scalars['bigint']>;
  invoiceQuantity?: InputMaybe<Scalars['bigint']>;
  labelingPricePerUnit?: InputMaybe<Scalars['bigint']>;
  notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  offeredQuantity?: InputMaybe<Scalars['bigint']>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: InputMaybe<Scalars['bigint']>;
  /** The quantity that the customer ordered. */
  orderQuantity?: InputMaybe<Scalars['bigint']>;
  orderUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  /** The unit of order_quantity. */
  orderUnitId?: InputMaybe<Scalars['bigint']>;
  orderUnitToSalesUnitFactor?: InputMaybe<Scalars['numeric']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  pickedItems?: InputMaybe<Picked_Items_Arr_Rel_Insert_Input>;
  pickingDeviationReasonId?: InputMaybe<Scalars['bigint']>;
  pickingDeviationType?: InputMaybe<Picking_Deviation_Types_Enum>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  productionNote?: InputMaybe<Scalars['String']>;
  requirePicking?: InputMaybe<Scalars['Boolean']>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: InputMaybe<Scalars['bigint']>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: InputMaybe<Scalars['bigint']>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: InputMaybe<Scalars['bigint']>;
  salesUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Customer_Order_Item_States_Enum>;
  vatOnInvoicePrice?: InputMaybe<Scalars['bigint']>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: InputMaybe<Scalars['numeric']>;
  /** The VAT type code copied over from the product base data at the time of order item creation. */
  vatType?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Customer_Order_Items_Max_Fields = {
  __typename: 'customer_order_items_max_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerNote?: Maybe<Scalars['String']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  invoicePrice?: Maybe<Scalars['bigint']>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: Maybe<Scalars['bigint']>;
  invoiceQuantity?: Maybe<Scalars['bigint']>;
  labelingPricePerUnit?: Maybe<Scalars['bigint']>;
  offeredQuantity?: Maybe<Scalars['bigint']>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: Maybe<Scalars['bigint']>;
  /** The quantity that the customer ordered. */
  orderQuantity?: Maybe<Scalars['bigint']>;
  /** The unit of order_quantity. */
  orderUnitId?: Maybe<Scalars['bigint']>;
  orderUnitToSalesUnitFactor?: Maybe<Scalars['numeric']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  pickingDeviationReasonId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  productionNote?: Maybe<Scalars['String']>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: Maybe<Scalars['bigint']>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: Maybe<Scalars['bigint']>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: Maybe<Scalars['bigint']>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vatOnInvoicePrice?: Maybe<Scalars['bigint']>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: Maybe<Scalars['numeric']>;
  /** The VAT type code copied over from the product base data at the time of order item creation. */
  vatType?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_order_items" */
export type Customer_Order_Items_Max_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerNote?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicePrice?: InputMaybe<Order_By>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: InputMaybe<Order_By>;
  invoiceQuantity?: InputMaybe<Order_By>;
  labelingPricePerUnit?: InputMaybe<Order_By>;
  offeredQuantity?: InputMaybe<Order_By>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: InputMaybe<Order_By>;
  /** The quantity that the customer ordered. */
  orderQuantity?: InputMaybe<Order_By>;
  /** The unit of order_quantity. */
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToSalesUnitFactor?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productionNote?: InputMaybe<Order_By>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: InputMaybe<Order_By>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: InputMaybe<Order_By>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: InputMaybe<Order_By>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vatOnInvoicePrice?: InputMaybe<Order_By>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: InputMaybe<Order_By>;
  /** The VAT type code copied over from the product base data at the time of order item creation. */
  vatType?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Order_Items_Min_Fields = {
  __typename: 'customer_order_items_min_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerNote?: Maybe<Scalars['String']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  invoicePrice?: Maybe<Scalars['bigint']>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: Maybe<Scalars['bigint']>;
  invoiceQuantity?: Maybe<Scalars['bigint']>;
  labelingPricePerUnit?: Maybe<Scalars['bigint']>;
  offeredQuantity?: Maybe<Scalars['bigint']>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: Maybe<Scalars['bigint']>;
  /** The quantity that the customer ordered. */
  orderQuantity?: Maybe<Scalars['bigint']>;
  /** The unit of order_quantity. */
  orderUnitId?: Maybe<Scalars['bigint']>;
  orderUnitToSalesUnitFactor?: Maybe<Scalars['numeric']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  pickingDeviationReasonId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  productionNote?: Maybe<Scalars['String']>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: Maybe<Scalars['bigint']>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: Maybe<Scalars['bigint']>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: Maybe<Scalars['bigint']>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vatOnInvoicePrice?: Maybe<Scalars['bigint']>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: Maybe<Scalars['numeric']>;
  /** The VAT type code copied over from the product base data at the time of order item creation. */
  vatType?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_order_items" */
export type Customer_Order_Items_Min_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerNote?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicePrice?: InputMaybe<Order_By>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: InputMaybe<Order_By>;
  invoiceQuantity?: InputMaybe<Order_By>;
  labelingPricePerUnit?: InputMaybe<Order_By>;
  offeredQuantity?: InputMaybe<Order_By>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: InputMaybe<Order_By>;
  /** The quantity that the customer ordered. */
  orderQuantity?: InputMaybe<Order_By>;
  /** The unit of order_quantity. */
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToSalesUnitFactor?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productionNote?: InputMaybe<Order_By>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: InputMaybe<Order_By>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: InputMaybe<Order_By>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: InputMaybe<Order_By>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vatOnInvoicePrice?: InputMaybe<Order_By>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: InputMaybe<Order_By>;
  /** The VAT type code copied over from the product base data at the time of order item creation. */
  vatType?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_order_items" */
export type Customer_Order_Items_Mutation_Response = {
  __typename: 'customer_order_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Order_Items>;
};

/** input type for inserting object relation for remote table "customer_order_items" */
export type Customer_Order_Items_Obj_Rel_Insert_Input = {
  data: Customer_Order_Items_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Order_Items_On_Conflict>;
};

/** on_conflict condition type for table "customer_order_items" */
export type Customer_Order_Items_On_Conflict = {
  constraint: Customer_Order_Items_Constraint;
  update_columns?: Array<Customer_Order_Items_Update_Column>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_order_items". */
export type Customer_Order_Items_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  aggregatedDemandPlanOutgoingGoodsResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Order_By>;
  containerProduct?: InputMaybe<Products_Order_By>;
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  customerNote?: InputMaybe<Order_By>;
  customerOrder?: InputMaybe<Customer_Orders_Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemAdjustments_aggregate?: InputMaybe<Customer_Order_Item_Adjustments_Aggregate_Order_By>;
  customerOrderItemContainerExpectations_aggregate?: InputMaybe<Customer_Order_Item_Container_Expectations_Aggregate_Order_By>;
  customerOrderItemExtra?: InputMaybe<Customer_Order_Item_Extras_Order_By>;
  customerOrderItemFulfillmentPlans_aggregate?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Aggregate_Order_By>;
  customerOrderReturnItems_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Order_By>;
  demands_aggregate?: InputMaybe<Demands_For_Coi_Results_Aggregate_Order_By>;
  dismantlingDemands_aggregate?: InputMaybe<Demands_For_Coi_Results_Aggregate_Order_By>;
  dismantlingPlanDemands_aggregate?: InputMaybe<Dismantling_Plan_Demands_Aggregate_Order_By>;
  fixedPrice?: InputMaybe<Order_By>;
  hasDemandChanges?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicePrice?: InputMaybe<Order_By>;
  invoicePricePerUnit?: InputMaybe<Order_By>;
  invoiceQuantity?: InputMaybe<Order_By>;
  labelingPricePerUnit?: InputMaybe<Order_By>;
  loggedActions_aggregate?: InputMaybe<Customer_Order_Item_Logged_Actions_Aggregate_Order_By>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  offeredQuantity?: InputMaybe<Order_By>;
  orderPrice?: InputMaybe<Order_By>;
  orderQuantity?: InputMaybe<Order_By>;
  orderQuantityInSalesUnit?: InputMaybe<Order_By>;
  orderUnit?: InputMaybe<Product_Units_Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitName?: InputMaybe<Order_By>;
  orderUnitToSalesUnitFactor?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pickedItems_aggregate?: InputMaybe<Picked_Items_Aggregate_Order_By>;
  pickingDeviationReason?: InputMaybe<Picking_Deviation_Reasons_Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  pickingDeviationType?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  productionNote?: InputMaybe<Order_By>;
  requirePicking?: InputMaybe<Order_By>;
  salesPrice?: InputMaybe<Order_By>;
  salesPricePerUnit?: InputMaybe<Order_By>;
  salesQuantity?: InputMaybe<Order_By>;
  salesQuantityInOrderUnit?: InputMaybe<Order_By>;
  salesUnit?: InputMaybe<Product_Units_Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  salesUnitName?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vatOnInvoicePrice?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatType?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_order_items */
export type Customer_Order_Items_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customer_order_items" */
export enum Customer_Order_Items_Select_Column {
  /** column name */
  ContainerProductId = 'containerProductId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerNote = 'customerNote',
  /** column name */
  CustomerOrderId = 'customerOrderId',
  /** column name */
  FixedPrice = 'fixedPrice',
  /** column name */
  HasDemandChanges = 'hasDemandChanges',
  /** column name */
  Id = 'id',
  /** column name */
  InvoicePrice = 'invoicePrice',
  /** column name */
  InvoicePricePerUnit = 'invoicePricePerUnit',
  /** column name */
  InvoiceQuantity = 'invoiceQuantity',
  /** column name */
  LabelingPricePerUnit = 'labelingPricePerUnit',
  /** column name */
  OfferedQuantity = 'offeredQuantity',
  /** column name */
  OrderPrice = 'orderPrice',
  /** column name */
  OrderQuantity = 'orderQuantity',
  /** column name */
  OrderUnitId = 'orderUnitId',
  /** column name */
  OrderUnitToSalesUnitFactor = 'orderUnitToSalesUnitFactor',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  PickingDeviationReasonId = 'pickingDeviationReasonId',
  /** column name */
  PickingDeviationType = 'pickingDeviationType',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ProductionNote = 'productionNote',
  /** column name */
  RequirePicking = 'requirePicking',
  /** column name */
  SalesPrice = 'salesPrice',
  /** column name */
  SalesPricePerUnit = 'salesPricePerUnit',
  /** column name */
  SalesQuantity = 'salesQuantity',
  /** column name */
  SalesUnitId = 'salesUnitId',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VatOnInvoicePrice = 'vatOnInvoicePrice',
  /** column name */
  VatPct = 'vatPct',
  /** column name */
  VatType = 'vatType',
  /** column name */
  Version = 'version'
}

/** select "customer_order_items_aggregate_bool_exp_bool_and_arguments_columns" columns of table "customer_order_items" */
export enum Customer_Order_Items_Select_Column_Customer_Order_Items_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  FixedPrice = 'fixedPrice',
  /** column name */
  HasDemandChanges = 'hasDemandChanges',
  /** column name */
  RequirePicking = 'requirePicking'
}

/** select "customer_order_items_aggregate_bool_exp_bool_or_arguments_columns" columns of table "customer_order_items" */
export enum Customer_Order_Items_Select_Column_Customer_Order_Items_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  FixedPrice = 'fixedPrice',
  /** column name */
  HasDemandChanges = 'hasDemandChanges',
  /** column name */
  RequirePicking = 'requirePicking'
}

/** input type for updating data in table "customer_order_items" */
export type Customer_Order_Items_Set_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  customerNote?: InputMaybe<Scalars['String']>;
  fixedPrice?: InputMaybe<Scalars['Boolean']>;
  hasDemandChanges?: InputMaybe<Scalars['Boolean']>;
  labelingPricePerUnit?: InputMaybe<Scalars['bigint']>;
  offeredQuantity?: InputMaybe<Scalars['bigint']>;
  /** The quantity that the customer ordered. */
  orderQuantity?: InputMaybe<Scalars['bigint']>;
  /** The unit of order_quantity. */
  orderUnitId?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  pickingDeviationReasonId?: InputMaybe<Scalars['bigint']>;
  pickingDeviationType?: InputMaybe<Picking_Deviation_Types_Enum>;
  productionNote?: InputMaybe<Scalars['String']>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: InputMaybe<Scalars['bigint']>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Customer_Order_Item_States_Enum>;
};

/** aggregate stddev on columns */
export type Customer_Order_Items_Stddev_Fields = {
  __typename: 'customer_order_items_stddev_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoicePrice?: Maybe<Scalars['Float']>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: Maybe<Scalars['Float']>;
  invoiceQuantity?: Maybe<Scalars['Float']>;
  labelingPricePerUnit?: Maybe<Scalars['Float']>;
  offeredQuantity?: Maybe<Scalars['Float']>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: Maybe<Scalars['Float']>;
  /** The quantity that the customer ordered. */
  orderQuantity?: Maybe<Scalars['Float']>;
  /** The unit of order_quantity. */
  orderUnitId?: Maybe<Scalars['Float']>;
  orderUnitToSalesUnitFactor?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pickingDeviationReasonId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: Maybe<Scalars['Float']>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: Maybe<Scalars['Float']>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: Maybe<Scalars['Float']>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: Maybe<Scalars['Float']>;
  vatOnInvoicePrice?: Maybe<Scalars['Float']>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_order_items" */
export type Customer_Order_Items_Stddev_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicePrice?: InputMaybe<Order_By>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: InputMaybe<Order_By>;
  invoiceQuantity?: InputMaybe<Order_By>;
  labelingPricePerUnit?: InputMaybe<Order_By>;
  offeredQuantity?: InputMaybe<Order_By>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: InputMaybe<Order_By>;
  /** The quantity that the customer ordered. */
  orderQuantity?: InputMaybe<Order_By>;
  /** The unit of order_quantity. */
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToSalesUnitFactor?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: InputMaybe<Order_By>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: InputMaybe<Order_By>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: InputMaybe<Order_By>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: InputMaybe<Order_By>;
  vatOnInvoicePrice?: InputMaybe<Order_By>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Order_Items_Stddev_Pop_Fields = {
  __typename: 'customer_order_items_stddev_pop_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoicePrice?: Maybe<Scalars['Float']>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: Maybe<Scalars['Float']>;
  invoiceQuantity?: Maybe<Scalars['Float']>;
  labelingPricePerUnit?: Maybe<Scalars['Float']>;
  offeredQuantity?: Maybe<Scalars['Float']>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: Maybe<Scalars['Float']>;
  /** The quantity that the customer ordered. */
  orderQuantity?: Maybe<Scalars['Float']>;
  /** The unit of order_quantity. */
  orderUnitId?: Maybe<Scalars['Float']>;
  orderUnitToSalesUnitFactor?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pickingDeviationReasonId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: Maybe<Scalars['Float']>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: Maybe<Scalars['Float']>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: Maybe<Scalars['Float']>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: Maybe<Scalars['Float']>;
  vatOnInvoicePrice?: Maybe<Scalars['Float']>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_order_items" */
export type Customer_Order_Items_Stddev_Pop_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicePrice?: InputMaybe<Order_By>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: InputMaybe<Order_By>;
  invoiceQuantity?: InputMaybe<Order_By>;
  labelingPricePerUnit?: InputMaybe<Order_By>;
  offeredQuantity?: InputMaybe<Order_By>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: InputMaybe<Order_By>;
  /** The quantity that the customer ordered. */
  orderQuantity?: InputMaybe<Order_By>;
  /** The unit of order_quantity. */
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToSalesUnitFactor?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: InputMaybe<Order_By>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: InputMaybe<Order_By>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: InputMaybe<Order_By>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: InputMaybe<Order_By>;
  vatOnInvoicePrice?: InputMaybe<Order_By>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Order_Items_Stddev_Samp_Fields = {
  __typename: 'customer_order_items_stddev_samp_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoicePrice?: Maybe<Scalars['Float']>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: Maybe<Scalars['Float']>;
  invoiceQuantity?: Maybe<Scalars['Float']>;
  labelingPricePerUnit?: Maybe<Scalars['Float']>;
  offeredQuantity?: Maybe<Scalars['Float']>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: Maybe<Scalars['Float']>;
  /** The quantity that the customer ordered. */
  orderQuantity?: Maybe<Scalars['Float']>;
  /** The unit of order_quantity. */
  orderUnitId?: Maybe<Scalars['Float']>;
  orderUnitToSalesUnitFactor?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pickingDeviationReasonId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: Maybe<Scalars['Float']>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: Maybe<Scalars['Float']>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: Maybe<Scalars['Float']>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: Maybe<Scalars['Float']>;
  vatOnInvoicePrice?: Maybe<Scalars['Float']>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_order_items" */
export type Customer_Order_Items_Stddev_Samp_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicePrice?: InputMaybe<Order_By>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: InputMaybe<Order_By>;
  invoiceQuantity?: InputMaybe<Order_By>;
  labelingPricePerUnit?: InputMaybe<Order_By>;
  offeredQuantity?: InputMaybe<Order_By>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: InputMaybe<Order_By>;
  /** The quantity that the customer ordered. */
  orderQuantity?: InputMaybe<Order_By>;
  /** The unit of order_quantity. */
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToSalesUnitFactor?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: InputMaybe<Order_By>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: InputMaybe<Order_By>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: InputMaybe<Order_By>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: InputMaybe<Order_By>;
  vatOnInvoicePrice?: InputMaybe<Order_By>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_order_items" */
export type Customer_Order_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Items_Stream_Cursor_Value_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Currencies_Enum>;
  customerNote?: InputMaybe<Scalars['String']>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  fixedPrice?: InputMaybe<Scalars['Boolean']>;
  hasDemandChanges?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  invoicePrice?: InputMaybe<Scalars['bigint']>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: InputMaybe<Scalars['bigint']>;
  invoiceQuantity?: InputMaybe<Scalars['bigint']>;
  labelingPricePerUnit?: InputMaybe<Scalars['bigint']>;
  offeredQuantity?: InputMaybe<Scalars['bigint']>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: InputMaybe<Scalars['bigint']>;
  /** The quantity that the customer ordered. */
  orderQuantity?: InputMaybe<Scalars['bigint']>;
  /** The unit of order_quantity. */
  orderUnitId?: InputMaybe<Scalars['bigint']>;
  orderUnitToSalesUnitFactor?: InputMaybe<Scalars['numeric']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  pickingDeviationReasonId?: InputMaybe<Scalars['bigint']>;
  pickingDeviationType?: InputMaybe<Picking_Deviation_Types_Enum>;
  productId?: InputMaybe<Scalars['bigint']>;
  productionNote?: InputMaybe<Scalars['String']>;
  requirePicking?: InputMaybe<Scalars['Boolean']>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: InputMaybe<Scalars['bigint']>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: InputMaybe<Scalars['bigint']>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: InputMaybe<Scalars['bigint']>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Customer_Order_Item_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vatOnInvoicePrice?: InputMaybe<Scalars['bigint']>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: InputMaybe<Scalars['numeric']>;
  /** The VAT type code copied over from the product base data at the time of order item creation. */
  vatType?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Order_Items_Sum_Fields = {
  __typename: 'customer_order_items_sum_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  invoicePrice?: Maybe<Scalars['bigint']>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: Maybe<Scalars['bigint']>;
  invoiceQuantity?: Maybe<Scalars['bigint']>;
  labelingPricePerUnit?: Maybe<Scalars['bigint']>;
  offeredQuantity?: Maybe<Scalars['bigint']>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: Maybe<Scalars['bigint']>;
  /** The quantity that the customer ordered. */
  orderQuantity?: Maybe<Scalars['bigint']>;
  /** The unit of order_quantity. */
  orderUnitId?: Maybe<Scalars['bigint']>;
  orderUnitToSalesUnitFactor?: Maybe<Scalars['numeric']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  pickingDeviationReasonId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: Maybe<Scalars['bigint']>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: Maybe<Scalars['bigint']>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: Maybe<Scalars['bigint']>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: Maybe<Scalars['bigint']>;
  vatOnInvoicePrice?: Maybe<Scalars['bigint']>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: Maybe<Scalars['numeric']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_order_items" */
export type Customer_Order_Items_Sum_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicePrice?: InputMaybe<Order_By>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: InputMaybe<Order_By>;
  invoiceQuantity?: InputMaybe<Order_By>;
  labelingPricePerUnit?: InputMaybe<Order_By>;
  offeredQuantity?: InputMaybe<Order_By>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: InputMaybe<Order_By>;
  /** The quantity that the customer ordered. */
  orderQuantity?: InputMaybe<Order_By>;
  /** The unit of order_quantity. */
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToSalesUnitFactor?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: InputMaybe<Order_By>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: InputMaybe<Order_By>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: InputMaybe<Order_By>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: InputMaybe<Order_By>;
  vatOnInvoicePrice?: InputMaybe<Order_By>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "customer_order_items" */
export enum Customer_Order_Items_Update_Column {
  /** column name */
  ContainerProductId = 'containerProductId',
  /** column name */
  CustomerNote = 'customerNote',
  /** column name */
  FixedPrice = 'fixedPrice',
  /** column name */
  HasDemandChanges = 'hasDemandChanges',
  /** column name */
  LabelingPricePerUnit = 'labelingPricePerUnit',
  /** column name */
  OfferedQuantity = 'offeredQuantity',
  /** column name */
  OrderQuantity = 'orderQuantity',
  /** column name */
  OrderUnitId = 'orderUnitId',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  PickingDeviationReasonId = 'pickingDeviationReasonId',
  /** column name */
  PickingDeviationType = 'pickingDeviationType',
  /** column name */
  ProductionNote = 'productionNote',
  /** column name */
  SalesPricePerUnit = 'salesPricePerUnit',
  /** column name */
  SalesUnitId = 'salesUnitId',
  /** column name */
  State = 'state'
}

export type Customer_Order_Items_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Order_Items_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Order_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Order_Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Order_Items_Var_Pop_Fields = {
  __typename: 'customer_order_items_var_pop_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoicePrice?: Maybe<Scalars['Float']>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: Maybe<Scalars['Float']>;
  invoiceQuantity?: Maybe<Scalars['Float']>;
  labelingPricePerUnit?: Maybe<Scalars['Float']>;
  offeredQuantity?: Maybe<Scalars['Float']>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: Maybe<Scalars['Float']>;
  /** The quantity that the customer ordered. */
  orderQuantity?: Maybe<Scalars['Float']>;
  /** The unit of order_quantity. */
  orderUnitId?: Maybe<Scalars['Float']>;
  orderUnitToSalesUnitFactor?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pickingDeviationReasonId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: Maybe<Scalars['Float']>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: Maybe<Scalars['Float']>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: Maybe<Scalars['Float']>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: Maybe<Scalars['Float']>;
  vatOnInvoicePrice?: Maybe<Scalars['Float']>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_order_items" */
export type Customer_Order_Items_Var_Pop_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicePrice?: InputMaybe<Order_By>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: InputMaybe<Order_By>;
  invoiceQuantity?: InputMaybe<Order_By>;
  labelingPricePerUnit?: InputMaybe<Order_By>;
  offeredQuantity?: InputMaybe<Order_By>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: InputMaybe<Order_By>;
  /** The quantity that the customer ordered. */
  orderQuantity?: InputMaybe<Order_By>;
  /** The unit of order_quantity. */
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToSalesUnitFactor?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: InputMaybe<Order_By>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: InputMaybe<Order_By>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: InputMaybe<Order_By>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: InputMaybe<Order_By>;
  vatOnInvoicePrice?: InputMaybe<Order_By>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Order_Items_Var_Samp_Fields = {
  __typename: 'customer_order_items_var_samp_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoicePrice?: Maybe<Scalars['Float']>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: Maybe<Scalars['Float']>;
  invoiceQuantity?: Maybe<Scalars['Float']>;
  labelingPricePerUnit?: Maybe<Scalars['Float']>;
  offeredQuantity?: Maybe<Scalars['Float']>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: Maybe<Scalars['Float']>;
  /** The quantity that the customer ordered. */
  orderQuantity?: Maybe<Scalars['Float']>;
  /** The unit of order_quantity. */
  orderUnitId?: Maybe<Scalars['Float']>;
  orderUnitToSalesUnitFactor?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pickingDeviationReasonId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: Maybe<Scalars['Float']>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: Maybe<Scalars['Float']>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: Maybe<Scalars['Float']>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: Maybe<Scalars['Float']>;
  vatOnInvoicePrice?: Maybe<Scalars['Float']>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_order_items" */
export type Customer_Order_Items_Var_Samp_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicePrice?: InputMaybe<Order_By>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: InputMaybe<Order_By>;
  invoiceQuantity?: InputMaybe<Order_By>;
  labelingPricePerUnit?: InputMaybe<Order_By>;
  offeredQuantity?: InputMaybe<Order_By>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: InputMaybe<Order_By>;
  /** The quantity that the customer ordered. */
  orderQuantity?: InputMaybe<Order_By>;
  /** The unit of order_quantity. */
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToSalesUnitFactor?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: InputMaybe<Order_By>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: InputMaybe<Order_By>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: InputMaybe<Order_By>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: InputMaybe<Order_By>;
  vatOnInvoicePrice?: InputMaybe<Order_By>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Order_Items_Variance_Fields = {
  __typename: 'customer_order_items_variance_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoicePrice?: Maybe<Scalars['Float']>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: Maybe<Scalars['Float']>;
  invoiceQuantity?: Maybe<Scalars['Float']>;
  labelingPricePerUnit?: Maybe<Scalars['Float']>;
  offeredQuantity?: Maybe<Scalars['Float']>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: Maybe<Scalars['Float']>;
  /** The quantity that the customer ordered. */
  orderQuantity?: Maybe<Scalars['Float']>;
  /** The unit of order_quantity. */
  orderUnitId?: Maybe<Scalars['Float']>;
  orderUnitToSalesUnitFactor?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pickingDeviationReasonId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: Maybe<Scalars['Float']>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: Maybe<Scalars['Float']>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: Maybe<Scalars['Float']>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: Maybe<Scalars['Float']>;
  vatOnInvoicePrice?: Maybe<Scalars['Float']>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_order_items" */
export type Customer_Order_Items_Variance_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicePrice?: InputMaybe<Order_By>;
  /** The price that we will print one an invoice for one unit in sales_unit_id. */
  invoicePricePerUnit?: InputMaybe<Order_By>;
  invoiceQuantity?: InputMaybe<Order_By>;
  labelingPricePerUnit?: InputMaybe<Order_By>;
  offeredQuantity?: InputMaybe<Order_By>;
  /** The price of this order item at the time of ordering. */
  orderPrice?: InputMaybe<Order_By>;
  /** The quantity that the customer ordered. */
  orderQuantity?: InputMaybe<Order_By>;
  /** The unit of order_quantity. */
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToSalesUnitFactor?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  /** The price of this order item recalculated based on the real sales_quantity. */
  salesPrice?: InputMaybe<Order_By>;
  /** The price of one unit in sales_unit_id. */
  salesPricePerUnit?: InputMaybe<Order_By>;
  /** The real quantity that was measured during picking. */
  salesQuantity?: InputMaybe<Order_By>;
  /** The unit in which the order_price is calculated. */
  salesUnitId?: InputMaybe<Order_By>;
  vatOnInvoicePrice?: InputMaybe<Order_By>;
  /** The VAT percentage copied over from the product base data at the time of order item creation. */
  vatPct?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_order_logged_actions" */
export type Customer_Order_Logged_Actions = {
  __typename: 'customer_order_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  entity?: Maybe<Customer_Orders>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "customer_order_logged_actions" */
export type Customer_Order_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "customer_order_logged_actions" */
export type Customer_Order_Logged_Actions_Aggregate = {
  __typename: 'customer_order_logged_actions_aggregate';
  aggregate?: Maybe<Customer_Order_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Customer_Order_Logged_Actions>;
};

export type Customer_Order_Logged_Actions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Order_Logged_Actions_Aggregate_Bool_Exp_Count>;
};

export type Customer_Order_Logged_Actions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Order_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Order_Logged_Actions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_order_logged_actions" */
export type Customer_Order_Logged_Actions_Aggregate_Fields = {
  __typename: 'customer_order_logged_actions_aggregate_fields';
  avg?: Maybe<Customer_Order_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Logged_Actions_Max_Fields>;
  min?: Maybe<Customer_Order_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Customer_Order_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Order_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Order_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Order_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Customer_Order_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Order_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Customer_Order_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "customer_order_logged_actions" */
export type Customer_Order_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_order_logged_actions" */
export type Customer_Order_Logged_Actions_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Order_Logged_Actions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Order_Logged_Actions_Max_Order_By>;
  min?: InputMaybe<Customer_Order_Logged_Actions_Min_Order_By>;
  stddev?: InputMaybe<Customer_Order_Logged_Actions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Order_Logged_Actions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Order_Logged_Actions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Order_Logged_Actions_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Order_Logged_Actions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Order_Logged_Actions_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Order_Logged_Actions_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Customer_Order_Logged_Actions_Avg_Fields = {
  __typename: 'customer_order_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_order_logged_actions" */
export type Customer_Order_Logged_Actions_Avg_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_order_logged_actions". All fields are combined with a logical 'AND'. */
export type Customer_Order_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Order_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entity?: InputMaybe<Customer_Orders_Bool_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Customer_Order_Logged_Actions_Max_Fields = {
  __typename: 'customer_order_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_order_logged_actions" */
export type Customer_Order_Logged_Actions_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Order_Logged_Actions_Min_Fields = {
  __typename: 'customer_order_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_order_logged_actions" */
export type Customer_Order_Logged_Actions_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "customer_order_logged_actions". */
export type Customer_Order_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entity?: InputMaybe<Customer_Orders_Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "customer_order_logged_actions" */
export enum Customer_Order_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Role = 'role',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Customer_Order_Logged_Actions_Stddev_Fields = {
  __typename: 'customer_order_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_order_logged_actions" */
export type Customer_Order_Logged_Actions_Stddev_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Order_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'customer_order_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_order_logged_actions" */
export type Customer_Order_Logged_Actions_Stddev_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Order_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'customer_order_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_order_logged_actions" */
export type Customer_Order_Logged_Actions_Stddev_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_order_logged_actions" */
export type Customer_Order_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  role?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Order_Logged_Actions_Sum_Fields = {
  __typename: 'customer_order_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_order_logged_actions" */
export type Customer_Order_Logged_Actions_Sum_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Customer_Order_Logged_Actions_Var_Pop_Fields = {
  __typename: 'customer_order_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_order_logged_actions" */
export type Customer_Order_Logged_Actions_Var_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Order_Logged_Actions_Var_Samp_Fields = {
  __typename: 'customer_order_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_order_logged_actions" */
export type Customer_Order_Logged_Actions_Var_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Order_Logged_Actions_Variance_Fields = {
  __typename: 'customer_order_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_order_logged_actions" */
export type Customer_Order_Logged_Actions_Variance_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_order_picked_container" */
export type Customer_Order_Picked_Container = {
  __typename: 'customer_order_picked_container';
  /** An object relationship */
  Tenant: Tenants;
  /** An object relationship */
  containerProduct: Products;
  containerProductId: Scalars['bigint'];
  createdAt: Scalars['timestamptz'];
  /** Returns the unscaled piece number of containers that the referenced customer has on their account. */
  customerInventoryTotal?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  customerOrder: Customer_Orders;
  customerOrderId: Scalars['bigint'];
  /** An array relationship */
  customerOrderPickedContainers: Array<Customer_Order_Picked_Container>;
  /** An aggregate relationship */
  customerOrderPickedContainers_aggregate: Customer_Order_Picked_Container_Aggregate;
  heightMm?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  /** An object relationship */
  pickedContainerParent?: Maybe<Customer_Order_Picked_Container>;
  pickedContainerParentId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  pickedItemContainers: Array<Picked_Item_Containers>;
  /** An aggregate relationship */
  pickedItemContainers_aggregate: Picked_Item_Containers_Aggregate;
  quantity: Scalars['bigint'];
  sscc?: Maybe<Scalars['bigint']>;
  state: Customer_Order_Picked_Container_States_Enum;
  tenant: Scalars['String'];
  totalTareWeight?: Maybe<Scalars['bigint']>;
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "customer_order_picked_container" */
export type Customer_Order_Picked_ContainerCustomerOrderPickedContainersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
};


/** columns and relationships of "customer_order_picked_container" */
export type Customer_Order_Picked_ContainerCustomerOrderPickedContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
};


/** columns and relationships of "customer_order_picked_container" */
export type Customer_Order_Picked_ContainerPickedItemContainersArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_Containers_Order_By>>;
  where?: InputMaybe<Picked_Item_Containers_Bool_Exp>;
};


/** columns and relationships of "customer_order_picked_container" */
export type Customer_Order_Picked_ContainerPickedItemContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_Containers_Order_By>>;
  where?: InputMaybe<Picked_Item_Containers_Bool_Exp>;
};

/** aggregated selection of "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Aggregate = {
  __typename: 'customer_order_picked_container_aggregate';
  aggregate?: Maybe<Customer_Order_Picked_Container_Aggregate_Fields>;
  nodes: Array<Customer_Order_Picked_Container>;
};

export type Customer_Order_Picked_Container_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Order_Picked_Container_Aggregate_Bool_Exp_Count>;
};

export type Customer_Order_Picked_Container_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Order_Picked_Container_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Aggregate_Fields = {
  __typename: 'customer_order_picked_container_aggregate_fields';
  avg?: Maybe<Customer_Order_Picked_Container_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Picked_Container_Max_Fields>;
  min?: Maybe<Customer_Order_Picked_Container_Min_Fields>;
  stddev?: Maybe<Customer_Order_Picked_Container_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Order_Picked_Container_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Order_Picked_Container_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Order_Picked_Container_Sum_Fields>;
  var_pop?: Maybe<Customer_Order_Picked_Container_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Order_Picked_Container_Var_Samp_Fields>;
  variance?: Maybe<Customer_Order_Picked_Container_Variance_Fields>;
};


/** aggregate fields of "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Picked_Container_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Order_Picked_Container_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Order_Picked_Container_Max_Order_By>;
  min?: InputMaybe<Customer_Order_Picked_Container_Min_Order_By>;
  stddev?: InputMaybe<Customer_Order_Picked_Container_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Order_Picked_Container_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Order_Picked_Container_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Order_Picked_Container_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Order_Picked_Container_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Order_Picked_Container_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Order_Picked_Container_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Arr_Rel_Insert_Input = {
  data: Array<Customer_Order_Picked_Container_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Order_Picked_Container_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Order_Picked_Container_Avg_Fields = {
  __typename: 'customer_order_picked_container_avg_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  heightMm?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pickedContainerParentId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sscc?: Maybe<Scalars['Float']>;
  totalTareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Avg_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  heightMm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pickedContainerParentId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_order_picked_container". All fields are combined with a logical 'AND'. */
export type Customer_Order_Picked_Container_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Order_Picked_Container_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Picked_Container_Bool_Exp>>;
  containerProduct?: InputMaybe<Products_Bool_Exp>;
  containerProductId?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerInventoryTotal?: InputMaybe<Bigint_Comparison_Exp>;
  customerOrder?: InputMaybe<Customer_Orders_Bool_Exp>;
  customerOrderId?: InputMaybe<Bigint_Comparison_Exp>;
  customerOrderPickedContainers?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
  customerOrderPickedContainers_aggregate?: InputMaybe<Customer_Order_Picked_Container_Aggregate_Bool_Exp>;
  heightMm?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  pickedContainerParent?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
  pickedContainerParentId?: InputMaybe<Bigint_Comparison_Exp>;
  pickedItemContainers?: InputMaybe<Picked_Item_Containers_Bool_Exp>;
  pickedItemContainers_aggregate?: InputMaybe<Picked_Item_Containers_Aggregate_Bool_Exp>;
  quantity?: InputMaybe<Bigint_Comparison_Exp>;
  sscc?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<Customer_Order_Picked_Container_States_Enum_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  totalTareWeight?: InputMaybe<Bigint_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_order_picked_container" */
export enum Customer_Order_Picked_Container_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerOrderPickedContainerPkey = 'customer_order_picked_container_pkey',
  /** unique or primary key constraint on columns "sscc" */
  CustomerOrderPickedContainerSsccKey = 'customer_order_picked_container_sscc_key'
}

/** input type for incrementing numeric columns in table "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Inc_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  heightMm?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  pickedContainerParentId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  sscc?: InputMaybe<Scalars['bigint']>;
  totalTareWeight?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Insert_Input = {
  containerProduct?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  containerProductId?: InputMaybe<Scalars['bigint']>;
  customerOrder?: InputMaybe<Customer_Orders_Obj_Rel_Insert_Input>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  customerOrderPickedContainers?: InputMaybe<Customer_Order_Picked_Container_Arr_Rel_Insert_Input>;
  heightMm?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  pickedContainerParent?: InputMaybe<Customer_Order_Picked_Container_Obj_Rel_Insert_Input>;
  pickedContainerParentId?: InputMaybe<Scalars['bigint']>;
  pickedItemContainers?: InputMaybe<Picked_Item_Containers_Arr_Rel_Insert_Input>;
  quantity?: InputMaybe<Scalars['bigint']>;
  sscc?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Customer_Order_Picked_Container_States_Enum>;
  totalTareWeight?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Customer_Order_Picked_Container_Max_Fields = {
  __typename: 'customer_order_picked_container_max_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  heightMm?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  pickedContainerParentId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  sscc?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  totalTareWeight?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Max_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  heightMm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pickedContainerParentId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Order_Picked_Container_Min_Fields = {
  __typename: 'customer_order_picked_container_min_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  heightMm?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  pickedContainerParentId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  sscc?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  totalTareWeight?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Min_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  heightMm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pickedContainerParentId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Mutation_Response = {
  __typename: 'customer_order_picked_container_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Order_Picked_Container>;
};

/** input type for inserting object relation for remote table "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Obj_Rel_Insert_Input = {
  data: Customer_Order_Picked_Container_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Order_Picked_Container_On_Conflict>;
};

/** on_conflict condition type for table "customer_order_picked_container" */
export type Customer_Order_Picked_Container_On_Conflict = {
  constraint: Customer_Order_Picked_Container_Constraint;
  update_columns?: Array<Customer_Order_Picked_Container_Update_Column>;
  where?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_order_picked_container". */
export type Customer_Order_Picked_Container_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  containerProduct?: InputMaybe<Products_Order_By>;
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerInventoryTotal?: InputMaybe<Order_By>;
  customerOrder?: InputMaybe<Customer_Orders_Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderPickedContainers_aggregate?: InputMaybe<Customer_Order_Picked_Container_Aggregate_Order_By>;
  heightMm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pickedContainerParent?: InputMaybe<Customer_Order_Picked_Container_Order_By>;
  pickedContainerParentId?: InputMaybe<Order_By>;
  pickedItemContainers_aggregate?: InputMaybe<Picked_Item_Containers_Aggregate_Order_By>;
  quantity?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_order_picked_container */
export type Customer_Order_Picked_Container_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customer_order_picked_container" */
export enum Customer_Order_Picked_Container_Select_Column {
  /** column name */
  ContainerProductId = 'containerProductId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerOrderId = 'customerOrderId',
  /** column name */
  HeightMm = 'heightMm',
  /** column name */
  Id = 'id',
  /** column name */
  PickedContainerParentId = 'pickedContainerParentId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Sscc = 'sscc',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  TotalTareWeight = 'totalTareWeight',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Set_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  heightMm?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  pickedContainerParentId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  sscc?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Customer_Order_Picked_Container_States_Enum>;
  totalTareWeight?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "customer_order_picked_container_state_transitions" */
export type Customer_Order_Picked_Container_State_Transitions = {
  __typename: 'customer_order_picked_container_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Customer_Order_Picked_Container_States_Enum;
  toState: Customer_Order_Picked_Container_States_Enum;
};

/** aggregated selection of "customer_order_picked_container_state_transitions" */
export type Customer_Order_Picked_Container_State_Transitions_Aggregate = {
  __typename: 'customer_order_picked_container_state_transitions_aggregate';
  aggregate?: Maybe<Customer_Order_Picked_Container_State_Transitions_Aggregate_Fields>;
  nodes: Array<Customer_Order_Picked_Container_State_Transitions>;
};

/** aggregate fields of "customer_order_picked_container_state_transitions" */
export type Customer_Order_Picked_Container_State_Transitions_Aggregate_Fields = {
  __typename: 'customer_order_picked_container_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Picked_Container_State_Transitions_Max_Fields>;
  min?: Maybe<Customer_Order_Picked_Container_State_Transitions_Min_Fields>;
};


/** aggregate fields of "customer_order_picked_container_state_transitions" */
export type Customer_Order_Picked_Container_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Picked_Container_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_order_picked_container_state_transitions". All fields are combined with a logical 'AND'. */
export type Customer_Order_Picked_Container_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Order_Picked_Container_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Picked_Container_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Picked_Container_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Customer_Order_Picked_Container_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Customer_Order_Picked_Container_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Customer_Order_Picked_Container_State_Transitions_Max_Fields = {
  __typename: 'customer_order_picked_container_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Order_Picked_Container_State_Transitions_Min_Fields = {
  __typename: 'customer_order_picked_container_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "customer_order_picked_container_state_transitions". */
export type Customer_Order_Picked_Container_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "customer_order_picked_container_state_transitions" */
export enum Customer_Order_Picked_Container_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "customer_order_picked_container_state_transitions" */
export type Customer_Order_Picked_Container_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Picked_Container_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Picked_Container_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Customer_Order_Picked_Container_States_Enum>;
  toState?: InputMaybe<Customer_Order_Picked_Container_States_Enum>;
};

/** columns and relationships of "customer_order_picked_container_states" */
export type Customer_Order_Picked_Container_States = {
  __typename: 'customer_order_picked_container_states';
  /** An array relationship */
  customerOrderPickedContainers: Array<Customer_Order_Picked_Container>;
  /** An aggregate relationship */
  customerOrderPickedContainers_aggregate: Customer_Order_Picked_Container_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "customer_order_picked_container_states" */
export type Customer_Order_Picked_Container_StatesCustomerOrderPickedContainersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
};


/** columns and relationships of "customer_order_picked_container_states" */
export type Customer_Order_Picked_Container_StatesCustomerOrderPickedContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
};

/** aggregated selection of "customer_order_picked_container_states" */
export type Customer_Order_Picked_Container_States_Aggregate = {
  __typename: 'customer_order_picked_container_states_aggregate';
  aggregate?: Maybe<Customer_Order_Picked_Container_States_Aggregate_Fields>;
  nodes: Array<Customer_Order_Picked_Container_States>;
};

/** aggregate fields of "customer_order_picked_container_states" */
export type Customer_Order_Picked_Container_States_Aggregate_Fields = {
  __typename: 'customer_order_picked_container_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Picked_Container_States_Max_Fields>;
  min?: Maybe<Customer_Order_Picked_Container_States_Min_Fields>;
};


/** aggregate fields of "customer_order_picked_container_states" */
export type Customer_Order_Picked_Container_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Picked_Container_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_order_picked_container_states". All fields are combined with a logical 'AND'. */
export type Customer_Order_Picked_Container_States_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Order_Picked_Container_States_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Picked_Container_States_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Picked_Container_States_Bool_Exp>>;
  customerOrderPickedContainers?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
  customerOrderPickedContainers_aggregate?: InputMaybe<Customer_Order_Picked_Container_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Customer_Order_Picked_Container_States_Enum {
  Picked = 'PICKED',
  PickingInProgress = 'PICKING_IN_PROGRESS'
}

/** Boolean expression to compare columns of type "customer_order_picked_container_states_enum". All fields are combined with logical 'AND'. */
export type Customer_Order_Picked_Container_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Customer_Order_Picked_Container_States_Enum>;
  _in?: InputMaybe<Array<Customer_Order_Picked_Container_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Customer_Order_Picked_Container_States_Enum>;
  _nin?: InputMaybe<Array<Customer_Order_Picked_Container_States_Enum>>;
};

/** aggregate max on columns */
export type Customer_Order_Picked_Container_States_Max_Fields = {
  __typename: 'customer_order_picked_container_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Order_Picked_Container_States_Min_Fields = {
  __typename: 'customer_order_picked_container_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "customer_order_picked_container_states". */
export type Customer_Order_Picked_Container_States_Order_By = {
  customerOrderPickedContainers_aggregate?: InputMaybe<Customer_Order_Picked_Container_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "customer_order_picked_container_states" */
export enum Customer_Order_Picked_Container_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "customer_order_picked_container_states" */
export type Customer_Order_Picked_Container_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Picked_Container_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Picked_Container_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Customer_Order_Picked_Container_Stddev_Fields = {
  __typename: 'customer_order_picked_container_stddev_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  heightMm?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pickedContainerParentId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sscc?: Maybe<Scalars['Float']>;
  totalTareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Stddev_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  heightMm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pickedContainerParentId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Order_Picked_Container_Stddev_Pop_Fields = {
  __typename: 'customer_order_picked_container_stddev_pop_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  heightMm?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pickedContainerParentId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sscc?: Maybe<Scalars['Float']>;
  totalTareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Stddev_Pop_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  heightMm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pickedContainerParentId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Order_Picked_Container_Stddev_Samp_Fields = {
  __typename: 'customer_order_picked_container_stddev_samp_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  heightMm?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pickedContainerParentId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sscc?: Maybe<Scalars['Float']>;
  totalTareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Stddev_Samp_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  heightMm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pickedContainerParentId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Picked_Container_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Picked_Container_Stream_Cursor_Value_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  heightMm?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  pickedContainerParentId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  sscc?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Customer_Order_Picked_Container_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
  totalTareWeight?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Order_Picked_Container_Sum_Fields = {
  __typename: 'customer_order_picked_container_sum_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  heightMm?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  pickedContainerParentId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  sscc?: Maybe<Scalars['bigint']>;
  totalTareWeight?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Sum_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  heightMm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pickedContainerParentId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "customer_order_picked_container" */
export enum Customer_Order_Picked_Container_Update_Column {
  /** column name */
  ContainerProductId = 'containerProductId',
  /** column name */
  CustomerOrderId = 'customerOrderId',
  /** column name */
  HeightMm = 'heightMm',
  /** column name */
  Id = 'id',
  /** column name */
  PickedContainerParentId = 'pickedContainerParentId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Sscc = 'sscc',
  /** column name */
  State = 'state',
  /** column name */
  TotalTareWeight = 'totalTareWeight',
  /** column name */
  Version = 'version'
}

export type Customer_Order_Picked_Container_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Order_Picked_Container_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Order_Picked_Container_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Order_Picked_Container_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Order_Picked_Container_Var_Pop_Fields = {
  __typename: 'customer_order_picked_container_var_pop_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  heightMm?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pickedContainerParentId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sscc?: Maybe<Scalars['Float']>;
  totalTareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Var_Pop_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  heightMm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pickedContainerParentId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Order_Picked_Container_Var_Samp_Fields = {
  __typename: 'customer_order_picked_container_var_samp_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  heightMm?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pickedContainerParentId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sscc?: Maybe<Scalars['Float']>;
  totalTareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Var_Samp_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  heightMm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pickedContainerParentId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Order_Picked_Container_Variance_Fields = {
  __typename: 'customer_order_picked_container_variance_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  heightMm?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pickedContainerParentId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sscc?: Maybe<Scalars['Float']>;
  totalTareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_order_picked_container" */
export type Customer_Order_Picked_Container_Variance_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  heightMm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pickedContainerParentId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers = {
  __typename: 'customer_order_return_item_containers';
  /** An object relationship */
  Tenant: Tenants;
  /** An object relationship */
  containerProduct: Products;
  containerProductId: Scalars['bigint'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customerOrderReturnItem: Customer_Order_Return_Items;
  customerOrderReturnItemId: Scalars['bigint'];
  id: Scalars['bigint'];
  quantity: Scalars['bigint'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};

/** aggregated selection of "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_Aggregate = {
  __typename: 'customer_order_return_item_containers_aggregate';
  aggregate?: Maybe<Customer_Order_Return_Item_Containers_Aggregate_Fields>;
  nodes: Array<Customer_Order_Return_Item_Containers>;
};

export type Customer_Order_Return_Item_Containers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Order_Return_Item_Containers_Aggregate_Bool_Exp_Count>;
};

export type Customer_Order_Return_Item_Containers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Order_Return_Item_Containers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_Aggregate_Fields = {
  __typename: 'customer_order_return_item_containers_aggregate_fields';
  avg?: Maybe<Customer_Order_Return_Item_Containers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Return_Item_Containers_Max_Fields>;
  min?: Maybe<Customer_Order_Return_Item_Containers_Min_Fields>;
  stddev?: Maybe<Customer_Order_Return_Item_Containers_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Order_Return_Item_Containers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Order_Return_Item_Containers_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Order_Return_Item_Containers_Sum_Fields>;
  var_pop?: Maybe<Customer_Order_Return_Item_Containers_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Order_Return_Item_Containers_Var_Samp_Fields>;
  variance?: Maybe<Customer_Order_Return_Item_Containers_Variance_Fields>;
};


/** aggregate fields of "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Order_Return_Item_Containers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Order_Return_Item_Containers_Max_Order_By>;
  min?: InputMaybe<Customer_Order_Return_Item_Containers_Min_Order_By>;
  stddev?: InputMaybe<Customer_Order_Return_Item_Containers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Order_Return_Item_Containers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Order_Return_Item_Containers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Order_Return_Item_Containers_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Order_Return_Item_Containers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Order_Return_Item_Containers_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Order_Return_Item_Containers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_Arr_Rel_Insert_Input = {
  data: Array<Customer_Order_Return_Item_Containers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Order_Return_Item_Containers_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Order_Return_Item_Containers_Avg_Fields = {
  __typename: 'customer_order_return_item_containers_avg_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderReturnItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_Avg_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderReturnItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_order_return_item_containers". All fields are combined with a logical 'AND'. */
export type Customer_Order_Return_Item_Containers_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Return_Item_Containers_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Bool_Exp>>;
  containerProduct?: InputMaybe<Products_Bool_Exp>;
  containerProductId?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerOrderReturnItem?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
  customerOrderReturnItemId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  quantity?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_order_return_item_containers" */
export enum Customer_Order_Return_Item_Containers_Constraint {
  /** unique or primary key constraint on columns "container_product_id", "customer_order_return_item_id" */
  CustomerOrderReturnItemCoCustomerOrderReturnItemIdKey = 'customer_order_return_item_co_customer_order_return_item_id_key',
  /** unique or primary key constraint on columns "id" */
  CustomerOrderReturnItemContainersPkey = 'customer_order_return_item_containers_pkey'
}

/** input type for incrementing numeric columns in table "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_Inc_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  customerOrderReturnItemId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_Insert_Input = {
  containerProduct?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  containerProductId?: InputMaybe<Scalars['bigint']>;
  customerOrderReturnItem?: InputMaybe<Customer_Order_Return_Items_Obj_Rel_Insert_Input>;
  customerOrderReturnItemId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Customer_Order_Return_Item_Containers_Max_Fields = {
  __typename: 'customer_order_return_item_containers_max_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderReturnItemId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_Max_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrderReturnItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Order_Return_Item_Containers_Min_Fields = {
  __typename: 'customer_order_return_item_containers_min_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderReturnItemId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_Min_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrderReturnItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_Mutation_Response = {
  __typename: 'customer_order_return_item_containers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Order_Return_Item_Containers>;
};

/** on_conflict condition type for table "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_On_Conflict = {
  constraint: Customer_Order_Return_Item_Containers_Constraint;
  update_columns?: Array<Customer_Order_Return_Item_Containers_Update_Column>;
  where?: InputMaybe<Customer_Order_Return_Item_Containers_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_order_return_item_containers". */
export type Customer_Order_Return_Item_Containers_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  containerProduct?: InputMaybe<Products_Order_By>;
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrderReturnItem?: InputMaybe<Customer_Order_Return_Items_Order_By>;
  customerOrderReturnItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_order_return_item_containers */
export type Customer_Order_Return_Item_Containers_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customer_order_return_item_containers" */
export enum Customer_Order_Return_Item_Containers_Select_Column {
  /** column name */
  ContainerProductId = 'containerProductId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerOrderReturnItemId = 'customerOrderReturnItemId',
  /** column name */
  Id = 'id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_Set_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  customerOrderReturnItemId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Customer_Order_Return_Item_Containers_Stddev_Fields = {
  __typename: 'customer_order_return_item_containers_stddev_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderReturnItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_Stddev_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderReturnItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Order_Return_Item_Containers_Stddev_Pop_Fields = {
  __typename: 'customer_order_return_item_containers_stddev_pop_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderReturnItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_Stddev_Pop_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderReturnItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Order_Return_Item_Containers_Stddev_Samp_Fields = {
  __typename: 'customer_order_return_item_containers_stddev_samp_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderReturnItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_Stddev_Samp_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderReturnItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Return_Item_Containers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Return_Item_Containers_Stream_Cursor_Value_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerOrderReturnItemId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Order_Return_Item_Containers_Sum_Fields = {
  __typename: 'customer_order_return_item_containers_sum_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  customerOrderReturnItemId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_Sum_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderReturnItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "customer_order_return_item_containers" */
export enum Customer_Order_Return_Item_Containers_Update_Column {
  /** column name */
  ContainerProductId = 'containerProductId',
  /** column name */
  CustomerOrderReturnItemId = 'customerOrderReturnItemId',
  /** column name */
  Id = 'id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Version = 'version'
}

export type Customer_Order_Return_Item_Containers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Order_Return_Item_Containers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Order_Return_Item_Containers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Order_Return_Item_Containers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Order_Return_Item_Containers_Var_Pop_Fields = {
  __typename: 'customer_order_return_item_containers_var_pop_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderReturnItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_Var_Pop_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderReturnItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Order_Return_Item_Containers_Var_Samp_Fields = {
  __typename: 'customer_order_return_item_containers_var_samp_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderReturnItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_Var_Samp_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderReturnItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Order_Return_Item_Containers_Variance_Fields = {
  __typename: 'customer_order_return_item_containers_variance_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  customerOrderReturnItemId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_order_return_item_containers" */
export type Customer_Order_Return_Item_Containers_Variance_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  customerOrderReturnItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_order_return_items" */
export type Customer_Order_Return_Items = {
  __typename: 'customer_order_return_items';
  /** An object relationship */
  LotNumber?: Maybe<Lot_Numbers>;
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  currency?: Maybe<Currencies_Enum>;
  customerNote?: Maybe<Scalars['String']>;
  /** An object relationship */
  customerOrderItem: Customer_Order_Items;
  customerOrderItemId: Scalars['bigint'];
  /** An object relationship */
  customerOrderReturn: Customer_Order_Returns;
  customerOrderReturnId: Scalars['bigint'];
  /** An array relationship */
  customerOrderReturnItemContainers: Array<Customer_Order_Return_Item_Containers>;
  /** An aggregate relationship */
  customerOrderReturnItemContainers_aggregate: Customer_Order_Return_Item_Containers_Aggregate;
  id: Scalars['bigint'];
  /** An object relationship */
  imageAttachment?: Maybe<S3_Objects>;
  imageAttachmentId?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  returnCause?: Maybe<Customer_Order_Item_Return_Causes>;
  returnCauseId?: Maybe<Scalars['bigint']>;
  returnedAbsolutePrice?: Maybe<Scalars['bigint']>;
  returnedCustomQuantity: Scalars['bigint'];
  returnedMonetaryQuantity: Scalars['bigint'];
  /** An object relationship */
  returnedMonetaryUnit: Product_Units;
  returnedMonetaryUnitId: Scalars['bigint'];
  returnedStorageQuantity: Scalars['bigint'];
  /** An object relationship */
  returnedStorageUnit: Product_Units;
  returnedStorageUnitId: Scalars['bigint'];
  tareWeight: Scalars['bigint'];
  targetMonetaryQuantity?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  targetMonetaryUnit?: Maybe<Product_Units>;
  targetMonetaryUnitId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  targetStockLocation?: Maybe<Stock_Locations>;
  targetStockLocationId?: Maybe<Scalars['bigint']>;
  targetStorageQuantity?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  targetStorageUnit?: Maybe<Product_Units>;
  targetStorageUnitId?: Maybe<Scalars['bigint']>;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "customer_order_return_items" */
export type Customer_Order_Return_ItemsCustomerOrderReturnItemContainersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Item_Containers_Bool_Exp>;
};


/** columns and relationships of "customer_order_return_items" */
export type Customer_Order_Return_ItemsCustomerOrderReturnItemContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Item_Containers_Bool_Exp>;
};

/** aggregated selection of "customer_order_return_items" */
export type Customer_Order_Return_Items_Aggregate = {
  __typename: 'customer_order_return_items_aggregate';
  aggregate?: Maybe<Customer_Order_Return_Items_Aggregate_Fields>;
  nodes: Array<Customer_Order_Return_Items>;
};

export type Customer_Order_Return_Items_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Order_Return_Items_Aggregate_Bool_Exp_Count>;
};

export type Customer_Order_Return_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_order_return_items" */
export type Customer_Order_Return_Items_Aggregate_Fields = {
  __typename: 'customer_order_return_items_aggregate_fields';
  avg?: Maybe<Customer_Order_Return_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Return_Items_Max_Fields>;
  min?: Maybe<Customer_Order_Return_Items_Min_Fields>;
  stddev?: Maybe<Customer_Order_Return_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Order_Return_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Order_Return_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Order_Return_Items_Sum_Fields>;
  var_pop?: Maybe<Customer_Order_Return_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Order_Return_Items_Var_Samp_Fields>;
  variance?: Maybe<Customer_Order_Return_Items_Variance_Fields>;
};


/** aggregate fields of "customer_order_return_items" */
export type Customer_Order_Return_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_order_return_items" */
export type Customer_Order_Return_Items_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Order_Return_Items_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Order_Return_Items_Max_Order_By>;
  min?: InputMaybe<Customer_Order_Return_Items_Min_Order_By>;
  stddev?: InputMaybe<Customer_Order_Return_Items_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Order_Return_Items_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Order_Return_Items_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Order_Return_Items_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Order_Return_Items_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Order_Return_Items_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Order_Return_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_order_return_items" */
export type Customer_Order_Return_Items_Arr_Rel_Insert_Input = {
  data: Array<Customer_Order_Return_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Order_Return_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Order_Return_Items_Avg_Fields = {
  __typename: 'customer_order_return_items_avg_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  customerOrderReturnId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  imageAttachmentId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  returnCauseId?: Maybe<Scalars['Float']>;
  returnedAbsolutePrice?: Maybe<Scalars['Float']>;
  returnedCustomQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryUnitId?: Maybe<Scalars['Float']>;
  returnedStorageQuantity?: Maybe<Scalars['Float']>;
  returnedStorageUnitId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_order_return_items" */
export type Customer_Order_Return_Items_Avg_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  customerOrderReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  returnedAbsolutePrice?: InputMaybe<Order_By>;
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_order_return_items". All fields are combined with a logical 'AND'. */
export type Customer_Order_Return_Items_Bool_Exp = {
  LotNumber?: InputMaybe<Lot_Numbers_Bool_Exp>;
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Order_Return_Items_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Return_Items_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  customerNote?: InputMaybe<String_Comparison_Exp>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  customerOrderItemId?: InputMaybe<Bigint_Comparison_Exp>;
  customerOrderReturn?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
  customerOrderReturnId?: InputMaybe<Bigint_Comparison_Exp>;
  customerOrderReturnItemContainers?: InputMaybe<Customer_Order_Return_Item_Containers_Bool_Exp>;
  customerOrderReturnItemContainers_aggregate?: InputMaybe<Customer_Order_Return_Item_Containers_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  imageAttachment?: InputMaybe<S3_Objects_Bool_Exp>;
  imageAttachmentId?: InputMaybe<Bigint_Comparison_Exp>;
  lotNumber?: InputMaybe<String_Comparison_Exp>;
  lotNumberId?: InputMaybe<Bigint_Comparison_Exp>;
  returnCause?: InputMaybe<Customer_Order_Item_Return_Causes_Bool_Exp>;
  returnCauseId?: InputMaybe<Bigint_Comparison_Exp>;
  returnedAbsolutePrice?: InputMaybe<Bigint_Comparison_Exp>;
  returnedCustomQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  returnedMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  returnedMonetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  returnedMonetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  returnedStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  returnedStorageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  returnedStorageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  tareWeight?: InputMaybe<Bigint_Comparison_Exp>;
  targetMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  targetMonetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  targetMonetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  targetStockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  targetStockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  targetStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  targetStorageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  targetStorageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_order_return_items" */
export enum Customer_Order_Return_Items_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerOrderReturnItemsPkey = 'customer_order_return_items_pkey'
}

/** input type for incrementing numeric columns in table "customer_order_return_items" */
export type Customer_Order_Return_Items_Inc_Input = {
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  customerOrderReturnId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  imageAttachmentId?: InputMaybe<Scalars['bigint']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  returnCauseId?: InputMaybe<Scalars['bigint']>;
  returnedAbsolutePrice?: InputMaybe<Scalars['bigint']>;
  returnedCustomQuantity?: InputMaybe<Scalars['bigint']>;
  returnedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  returnedMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  returnedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  returnedStorageUnitId?: InputMaybe<Scalars['bigint']>;
  tareWeight?: InputMaybe<Scalars['bigint']>;
  targetMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  targetMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  targetStockLocationId?: InputMaybe<Scalars['bigint']>;
  targetStorageQuantity?: InputMaybe<Scalars['bigint']>;
  targetStorageUnitId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_order_return_items" */
export type Customer_Order_Return_Items_Insert_Input = {
  LotNumber?: InputMaybe<Lot_Numbers_Obj_Rel_Insert_Input>;
  currency?: InputMaybe<Currencies_Enum>;
  customerNote?: InputMaybe<Scalars['String']>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Obj_Rel_Insert_Input>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  customerOrderReturn?: InputMaybe<Customer_Order_Returns_Obj_Rel_Insert_Input>;
  customerOrderReturnId?: InputMaybe<Scalars['bigint']>;
  customerOrderReturnItemContainers?: InputMaybe<Customer_Order_Return_Item_Containers_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  imageAttachment?: InputMaybe<S3_Objects_Obj_Rel_Insert_Input>;
  imageAttachmentId?: InputMaybe<Scalars['bigint']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  returnCause?: InputMaybe<Customer_Order_Item_Return_Causes_Obj_Rel_Insert_Input>;
  returnCauseId?: InputMaybe<Scalars['bigint']>;
  returnedAbsolutePrice?: InputMaybe<Scalars['bigint']>;
  returnedCustomQuantity?: InputMaybe<Scalars['bigint']>;
  returnedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  returnedMonetaryUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  returnedMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  returnedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  returnedStorageUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  returnedStorageUnitId?: InputMaybe<Scalars['bigint']>;
  tareWeight?: InputMaybe<Scalars['bigint']>;
  targetMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  targetMonetaryUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  targetMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  targetStockLocation?: InputMaybe<Stock_Locations_Obj_Rel_Insert_Input>;
  targetStockLocationId?: InputMaybe<Scalars['bigint']>;
  targetStorageQuantity?: InputMaybe<Scalars['bigint']>;
  targetStorageUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  targetStorageUnitId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Customer_Order_Return_Items_Max_Fields = {
  __typename: 'customer_order_return_items_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerNote?: Maybe<Scalars['String']>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  customerOrderReturnId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  imageAttachmentId?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  returnCauseId?: Maybe<Scalars['bigint']>;
  returnedAbsolutePrice?: Maybe<Scalars['bigint']>;
  returnedCustomQuantity?: Maybe<Scalars['bigint']>;
  returnedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  returnedMonetaryUnitId?: Maybe<Scalars['bigint']>;
  returnedStorageQuantity?: Maybe<Scalars['bigint']>;
  returnedStorageUnitId?: Maybe<Scalars['bigint']>;
  tareWeight?: Maybe<Scalars['bigint']>;
  targetMonetaryQuantity?: Maybe<Scalars['bigint']>;
  targetMonetaryUnitId?: Maybe<Scalars['bigint']>;
  targetStockLocationId?: Maybe<Scalars['bigint']>;
  targetStorageQuantity?: Maybe<Scalars['bigint']>;
  targetStorageUnitId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_order_return_items" */
export type Customer_Order_Return_Items_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerNote?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  customerOrderReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  returnedAbsolutePrice?: InputMaybe<Order_By>;
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Order_Return_Items_Min_Fields = {
  __typename: 'customer_order_return_items_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerNote?: Maybe<Scalars['String']>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  customerOrderReturnId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  imageAttachmentId?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  returnCauseId?: Maybe<Scalars['bigint']>;
  returnedAbsolutePrice?: Maybe<Scalars['bigint']>;
  returnedCustomQuantity?: Maybe<Scalars['bigint']>;
  returnedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  returnedMonetaryUnitId?: Maybe<Scalars['bigint']>;
  returnedStorageQuantity?: Maybe<Scalars['bigint']>;
  returnedStorageUnitId?: Maybe<Scalars['bigint']>;
  tareWeight?: Maybe<Scalars['bigint']>;
  targetMonetaryQuantity?: Maybe<Scalars['bigint']>;
  targetMonetaryUnitId?: Maybe<Scalars['bigint']>;
  targetStockLocationId?: Maybe<Scalars['bigint']>;
  targetStorageQuantity?: Maybe<Scalars['bigint']>;
  targetStorageUnitId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_order_return_items" */
export type Customer_Order_Return_Items_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerNote?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  customerOrderReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  returnedAbsolutePrice?: InputMaybe<Order_By>;
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_order_return_items" */
export type Customer_Order_Return_Items_Mutation_Response = {
  __typename: 'customer_order_return_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Order_Return_Items>;
};

/** input type for inserting object relation for remote table "customer_order_return_items" */
export type Customer_Order_Return_Items_Obj_Rel_Insert_Input = {
  data: Customer_Order_Return_Items_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Order_Return_Items_On_Conflict>;
};

/** on_conflict condition type for table "customer_order_return_items" */
export type Customer_Order_Return_Items_On_Conflict = {
  constraint: Customer_Order_Return_Items_Constraint;
  update_columns?: Array<Customer_Order_Return_Items_Update_Column>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_order_return_items". */
export type Customer_Order_Return_Items_Order_By = {
  LotNumber?: InputMaybe<Lot_Numbers_Order_By>;
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  customerNote?: InputMaybe<Order_By>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  customerOrderReturn?: InputMaybe<Customer_Order_Returns_Order_By>;
  customerOrderReturnId?: InputMaybe<Order_By>;
  customerOrderReturnItemContainers_aggregate?: InputMaybe<Customer_Order_Return_Item_Containers_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  imageAttachment?: InputMaybe<S3_Objects_Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCause?: InputMaybe<Customer_Order_Item_Return_Causes_Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  returnedAbsolutePrice?: InputMaybe<Order_By>;
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnit?: InputMaybe<Product_Units_Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnit?: InputMaybe<Product_Units_Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnit?: InputMaybe<Product_Units_Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocation?: InputMaybe<Stock_Locations_Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnit?: InputMaybe<Product_Units_Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_order_return_items */
export type Customer_Order_Return_Items_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customer_order_return_items" */
export enum Customer_Order_Return_Items_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerNote = 'customerNote',
  /** column name */
  CustomerOrderItemId = 'customerOrderItemId',
  /** column name */
  CustomerOrderReturnId = 'customerOrderReturnId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageAttachmentId = 'imageAttachmentId',
  /** column name */
  LotNumber = 'lotNumber',
  /** column name */
  LotNumberId = 'lotNumberId',
  /** column name */
  ReturnCauseId = 'returnCauseId',
  /** column name */
  ReturnedAbsolutePrice = 'returnedAbsolutePrice',
  /** column name */
  ReturnedCustomQuantity = 'returnedCustomQuantity',
  /** column name */
  ReturnedMonetaryQuantity = 'returnedMonetaryQuantity',
  /** column name */
  ReturnedMonetaryUnitId = 'returnedMonetaryUnitId',
  /** column name */
  ReturnedStorageQuantity = 'returnedStorageQuantity',
  /** column name */
  ReturnedStorageUnitId = 'returnedStorageUnitId',
  /** column name */
  TareWeight = 'tareWeight',
  /** column name */
  TargetMonetaryQuantity = 'targetMonetaryQuantity',
  /** column name */
  TargetMonetaryUnitId = 'targetMonetaryUnitId',
  /** column name */
  TargetStockLocationId = 'targetStockLocationId',
  /** column name */
  TargetStorageQuantity = 'targetStorageQuantity',
  /** column name */
  TargetStorageUnitId = 'targetStorageUnitId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "customer_order_return_items" */
export type Customer_Order_Return_Items_Set_Input = {
  currency?: InputMaybe<Currencies_Enum>;
  customerNote?: InputMaybe<Scalars['String']>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  customerOrderReturnId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  imageAttachmentId?: InputMaybe<Scalars['bigint']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  returnCauseId?: InputMaybe<Scalars['bigint']>;
  returnedAbsolutePrice?: InputMaybe<Scalars['bigint']>;
  returnedCustomQuantity?: InputMaybe<Scalars['bigint']>;
  returnedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  returnedMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  returnedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  returnedStorageUnitId?: InputMaybe<Scalars['bigint']>;
  tareWeight?: InputMaybe<Scalars['bigint']>;
  targetMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  targetMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  targetStockLocationId?: InputMaybe<Scalars['bigint']>;
  targetStorageQuantity?: InputMaybe<Scalars['bigint']>;
  targetStorageUnitId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Customer_Order_Return_Items_Stddev_Fields = {
  __typename: 'customer_order_return_items_stddev_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  customerOrderReturnId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  imageAttachmentId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  returnCauseId?: Maybe<Scalars['Float']>;
  returnedAbsolutePrice?: Maybe<Scalars['Float']>;
  returnedCustomQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryUnitId?: Maybe<Scalars['Float']>;
  returnedStorageQuantity?: Maybe<Scalars['Float']>;
  returnedStorageUnitId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_order_return_items" */
export type Customer_Order_Return_Items_Stddev_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  customerOrderReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  returnedAbsolutePrice?: InputMaybe<Order_By>;
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Order_Return_Items_Stddev_Pop_Fields = {
  __typename: 'customer_order_return_items_stddev_pop_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  customerOrderReturnId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  imageAttachmentId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  returnCauseId?: Maybe<Scalars['Float']>;
  returnedAbsolutePrice?: Maybe<Scalars['Float']>;
  returnedCustomQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryUnitId?: Maybe<Scalars['Float']>;
  returnedStorageQuantity?: Maybe<Scalars['Float']>;
  returnedStorageUnitId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_order_return_items" */
export type Customer_Order_Return_Items_Stddev_Pop_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  customerOrderReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  returnedAbsolutePrice?: InputMaybe<Order_By>;
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Order_Return_Items_Stddev_Samp_Fields = {
  __typename: 'customer_order_return_items_stddev_samp_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  customerOrderReturnId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  imageAttachmentId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  returnCauseId?: Maybe<Scalars['Float']>;
  returnedAbsolutePrice?: Maybe<Scalars['Float']>;
  returnedCustomQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryUnitId?: Maybe<Scalars['Float']>;
  returnedStorageQuantity?: Maybe<Scalars['Float']>;
  returnedStorageUnitId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_order_return_items" */
export type Customer_Order_Return_Items_Stddev_Samp_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  customerOrderReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  returnedAbsolutePrice?: InputMaybe<Order_By>;
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_order_return_items" */
export type Customer_Order_Return_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Return_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Return_Items_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Currencies_Enum>;
  customerNote?: InputMaybe<Scalars['String']>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  customerOrderReturnId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  imageAttachmentId?: InputMaybe<Scalars['bigint']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  returnCauseId?: InputMaybe<Scalars['bigint']>;
  returnedAbsolutePrice?: InputMaybe<Scalars['bigint']>;
  returnedCustomQuantity?: InputMaybe<Scalars['bigint']>;
  returnedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  returnedMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  returnedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  returnedStorageUnitId?: InputMaybe<Scalars['bigint']>;
  tareWeight?: InputMaybe<Scalars['bigint']>;
  targetMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  targetMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  targetStockLocationId?: InputMaybe<Scalars['bigint']>;
  targetStorageQuantity?: InputMaybe<Scalars['bigint']>;
  targetStorageUnitId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Order_Return_Items_Sum_Fields = {
  __typename: 'customer_order_return_items_sum_fields';
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  customerOrderReturnId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  imageAttachmentId?: Maybe<Scalars['bigint']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  returnCauseId?: Maybe<Scalars['bigint']>;
  returnedAbsolutePrice?: Maybe<Scalars['bigint']>;
  returnedCustomQuantity?: Maybe<Scalars['bigint']>;
  returnedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  returnedMonetaryUnitId?: Maybe<Scalars['bigint']>;
  returnedStorageQuantity?: Maybe<Scalars['bigint']>;
  returnedStorageUnitId?: Maybe<Scalars['bigint']>;
  tareWeight?: Maybe<Scalars['bigint']>;
  targetMonetaryQuantity?: Maybe<Scalars['bigint']>;
  targetMonetaryUnitId?: Maybe<Scalars['bigint']>;
  targetStockLocationId?: Maybe<Scalars['bigint']>;
  targetStorageQuantity?: Maybe<Scalars['bigint']>;
  targetStorageUnitId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_order_return_items" */
export type Customer_Order_Return_Items_Sum_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  customerOrderReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  returnedAbsolutePrice?: InputMaybe<Order_By>;
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "customer_order_return_items" */
export enum Customer_Order_Return_Items_Update_Column {
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerNote = 'customerNote',
  /** column name */
  CustomerOrderItemId = 'customerOrderItemId',
  /** column name */
  CustomerOrderReturnId = 'customerOrderReturnId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageAttachmentId = 'imageAttachmentId',
  /** column name */
  LotNumber = 'lotNumber',
  /** column name */
  LotNumberId = 'lotNumberId',
  /** column name */
  ReturnCauseId = 'returnCauseId',
  /** column name */
  ReturnedAbsolutePrice = 'returnedAbsolutePrice',
  /** column name */
  ReturnedCustomQuantity = 'returnedCustomQuantity',
  /** column name */
  ReturnedMonetaryQuantity = 'returnedMonetaryQuantity',
  /** column name */
  ReturnedMonetaryUnitId = 'returnedMonetaryUnitId',
  /** column name */
  ReturnedStorageQuantity = 'returnedStorageQuantity',
  /** column name */
  ReturnedStorageUnitId = 'returnedStorageUnitId',
  /** column name */
  TareWeight = 'tareWeight',
  /** column name */
  TargetMonetaryQuantity = 'targetMonetaryQuantity',
  /** column name */
  TargetMonetaryUnitId = 'targetMonetaryUnitId',
  /** column name */
  TargetStockLocationId = 'targetStockLocationId',
  /** column name */
  TargetStorageQuantity = 'targetStorageQuantity',
  /** column name */
  TargetStorageUnitId = 'targetStorageUnitId',
  /** column name */
  Version = 'version'
}

export type Customer_Order_Return_Items_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Order_Return_Items_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Order_Return_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Order_Return_Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Order_Return_Items_Var_Pop_Fields = {
  __typename: 'customer_order_return_items_var_pop_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  customerOrderReturnId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  imageAttachmentId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  returnCauseId?: Maybe<Scalars['Float']>;
  returnedAbsolutePrice?: Maybe<Scalars['Float']>;
  returnedCustomQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryUnitId?: Maybe<Scalars['Float']>;
  returnedStorageQuantity?: Maybe<Scalars['Float']>;
  returnedStorageUnitId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_order_return_items" */
export type Customer_Order_Return_Items_Var_Pop_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  customerOrderReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  returnedAbsolutePrice?: InputMaybe<Order_By>;
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Order_Return_Items_Var_Samp_Fields = {
  __typename: 'customer_order_return_items_var_samp_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  customerOrderReturnId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  imageAttachmentId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  returnCauseId?: Maybe<Scalars['Float']>;
  returnedAbsolutePrice?: Maybe<Scalars['Float']>;
  returnedCustomQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryUnitId?: Maybe<Scalars['Float']>;
  returnedStorageQuantity?: Maybe<Scalars['Float']>;
  returnedStorageUnitId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_order_return_items" */
export type Customer_Order_Return_Items_Var_Samp_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  customerOrderReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  returnedAbsolutePrice?: InputMaybe<Order_By>;
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Order_Return_Items_Variance_Fields = {
  __typename: 'customer_order_return_items_variance_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  customerOrderReturnId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  imageAttachmentId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  returnCauseId?: Maybe<Scalars['Float']>;
  returnedAbsolutePrice?: Maybe<Scalars['Float']>;
  returnedCustomQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryUnitId?: Maybe<Scalars['Float']>;
  returnedStorageQuantity?: Maybe<Scalars['Float']>;
  returnedStorageUnitId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_order_return_items" */
export type Customer_Order_Return_Items_Variance_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  customerOrderReturnId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  returnedAbsolutePrice?: InputMaybe<Order_By>;
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_order_return_states" */
export type Customer_Order_Return_States = {
  __typename: 'customer_order_return_states';
  /** An array relationship */
  customerOrderReturns: Array<Customer_Order_Returns>;
  /** An aggregate relationship */
  customerOrderReturns_aggregate: Customer_Order_Returns_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "customer_order_return_states" */
export type Customer_Order_Return_StatesCustomerOrderReturnsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
};


/** columns and relationships of "customer_order_return_states" */
export type Customer_Order_Return_StatesCustomerOrderReturns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
};

/** aggregated selection of "customer_order_return_states" */
export type Customer_Order_Return_States_Aggregate = {
  __typename: 'customer_order_return_states_aggregate';
  aggregate?: Maybe<Customer_Order_Return_States_Aggregate_Fields>;
  nodes: Array<Customer_Order_Return_States>;
};

/** aggregate fields of "customer_order_return_states" */
export type Customer_Order_Return_States_Aggregate_Fields = {
  __typename: 'customer_order_return_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Return_States_Max_Fields>;
  min?: Maybe<Customer_Order_Return_States_Min_Fields>;
};


/** aggregate fields of "customer_order_return_states" */
export type Customer_Order_Return_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Return_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_order_return_states". All fields are combined with a logical 'AND'. */
export type Customer_Order_Return_States_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Order_Return_States_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Return_States_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Return_States_Bool_Exp>>;
  customerOrderReturns?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
  customerOrderReturns_aggregate?: InputMaybe<Customer_Order_Returns_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Customer_Order_Return_States_Enum {
  Active = 'ACTIVE',
  Open = 'OPEN'
}

/** Boolean expression to compare columns of type "customer_order_return_states_enum". All fields are combined with logical 'AND'. */
export type Customer_Order_Return_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Customer_Order_Return_States_Enum>;
  _in?: InputMaybe<Array<Customer_Order_Return_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Customer_Order_Return_States_Enum>;
  _nin?: InputMaybe<Array<Customer_Order_Return_States_Enum>>;
};

/** aggregate max on columns */
export type Customer_Order_Return_States_Max_Fields = {
  __typename: 'customer_order_return_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Order_Return_States_Min_Fields = {
  __typename: 'customer_order_return_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "customer_order_return_states". */
export type Customer_Order_Return_States_Order_By = {
  customerOrderReturns_aggregate?: InputMaybe<Customer_Order_Returns_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "customer_order_return_states" */
export enum Customer_Order_Return_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "customer_order_return_states" */
export type Customer_Order_Return_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Return_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Return_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "customer_order_returns" */
export type Customer_Order_Returns = {
  __typename: 'customer_order_returns';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  creditNote?: Maybe<Credit_Notes>;
  creditNoteId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  customerOrder: Customer_Orders;
  customerOrderId: Scalars['bigint'];
  /** An array relationship */
  customerOrderReturnItems: Array<Customer_Order_Return_Items>;
  /** An aggregate relationship */
  customerOrderReturnItems_aggregate: Customer_Order_Return_Items_Aggregate;
  id: Scalars['bigint'];
  state: Customer_Order_Return_States_Enum;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "customer_order_returns" */
export type Customer_Order_ReturnsCustomerOrderReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "customer_order_returns" */
export type Customer_Order_ReturnsCustomerOrderReturnItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};

/** aggregated selection of "customer_order_returns" */
export type Customer_Order_Returns_Aggregate = {
  __typename: 'customer_order_returns_aggregate';
  aggregate?: Maybe<Customer_Order_Returns_Aggregate_Fields>;
  nodes: Array<Customer_Order_Returns>;
};

export type Customer_Order_Returns_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Order_Returns_Aggregate_Bool_Exp_Count>;
};

export type Customer_Order_Returns_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Order_Returns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_order_returns" */
export type Customer_Order_Returns_Aggregate_Fields = {
  __typename: 'customer_order_returns_aggregate_fields';
  avg?: Maybe<Customer_Order_Returns_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Returns_Max_Fields>;
  min?: Maybe<Customer_Order_Returns_Min_Fields>;
  stddev?: Maybe<Customer_Order_Returns_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Order_Returns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Order_Returns_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Order_Returns_Sum_Fields>;
  var_pop?: Maybe<Customer_Order_Returns_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Order_Returns_Var_Samp_Fields>;
  variance?: Maybe<Customer_Order_Returns_Variance_Fields>;
};


/** aggregate fields of "customer_order_returns" */
export type Customer_Order_Returns_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Returns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_order_returns" */
export type Customer_Order_Returns_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Order_Returns_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Order_Returns_Max_Order_By>;
  min?: InputMaybe<Customer_Order_Returns_Min_Order_By>;
  stddev?: InputMaybe<Customer_Order_Returns_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Order_Returns_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Order_Returns_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Order_Returns_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Order_Returns_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Order_Returns_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Order_Returns_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_order_returns" */
export type Customer_Order_Returns_Arr_Rel_Insert_Input = {
  data: Array<Customer_Order_Returns_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Order_Returns_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Order_Returns_Avg_Fields = {
  __typename: 'customer_order_returns_avg_fields';
  creditNoteId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_order_returns" */
export type Customer_Order_Returns_Avg_Order_By = {
  creditNoteId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_order_returns". All fields are combined with a logical 'AND'. */
export type Customer_Order_Returns_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Order_Returns_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Returns_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  creditNote?: InputMaybe<Credit_Notes_Bool_Exp>;
  creditNoteId?: InputMaybe<Bigint_Comparison_Exp>;
  customerOrder?: InputMaybe<Customer_Orders_Bool_Exp>;
  customerOrderId?: InputMaybe<Bigint_Comparison_Exp>;
  customerOrderReturnItems?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
  customerOrderReturnItems_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<Customer_Order_Return_States_Enum_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_order_returns" */
export enum Customer_Order_Returns_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerOrderReturnsPkey = 'customer_order_returns_pkey'
}

/** input type for incrementing numeric columns in table "customer_order_returns" */
export type Customer_Order_Returns_Inc_Input = {
  creditNoteId?: InputMaybe<Scalars['bigint']>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_order_returns" */
export type Customer_Order_Returns_Insert_Input = {
  creditNote?: InputMaybe<Credit_Notes_Obj_Rel_Insert_Input>;
  creditNoteId?: InputMaybe<Scalars['bigint']>;
  customerOrder?: InputMaybe<Customer_Orders_Obj_Rel_Insert_Input>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  customerOrderReturnItems?: InputMaybe<Customer_Order_Return_Items_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Customer_Order_Return_States_Enum>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Customer_Order_Returns_Max_Fields = {
  __typename: 'customer_order_returns_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  creditNoteId?: Maybe<Scalars['bigint']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_order_returns" */
export type Customer_Order_Returns_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Order_Returns_Min_Fields = {
  __typename: 'customer_order_returns_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  creditNoteId?: Maybe<Scalars['bigint']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_order_returns" */
export type Customer_Order_Returns_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_order_returns" */
export type Customer_Order_Returns_Mutation_Response = {
  __typename: 'customer_order_returns_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Order_Returns>;
};

/** input type for inserting object relation for remote table "customer_order_returns" */
export type Customer_Order_Returns_Obj_Rel_Insert_Input = {
  data: Customer_Order_Returns_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Order_Returns_On_Conflict>;
};

/** on_conflict condition type for table "customer_order_returns" */
export type Customer_Order_Returns_On_Conflict = {
  constraint: Customer_Order_Returns_Constraint;
  update_columns?: Array<Customer_Order_Returns_Update_Column>;
  where?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_order_returns". */
export type Customer_Order_Returns_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creditNote?: InputMaybe<Credit_Notes_Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerOrder?: InputMaybe<Customer_Orders_Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderReturnItems_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_order_returns */
export type Customer_Order_Returns_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customer_order_returns" */
export enum Customer_Order_Returns_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreditNoteId = 'creditNoteId',
  /** column name */
  CustomerOrderId = 'customerOrderId',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "customer_order_returns" */
export type Customer_Order_Returns_Set_Input = {
  creditNoteId?: InputMaybe<Scalars['bigint']>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Customer_Order_Return_States_Enum>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Customer_Order_Returns_Stddev_Fields = {
  __typename: 'customer_order_returns_stddev_fields';
  creditNoteId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_order_returns" */
export type Customer_Order_Returns_Stddev_Order_By = {
  creditNoteId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Order_Returns_Stddev_Pop_Fields = {
  __typename: 'customer_order_returns_stddev_pop_fields';
  creditNoteId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_order_returns" */
export type Customer_Order_Returns_Stddev_Pop_Order_By = {
  creditNoteId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Order_Returns_Stddev_Samp_Fields = {
  __typename: 'customer_order_returns_stddev_samp_fields';
  creditNoteId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_order_returns" */
export type Customer_Order_Returns_Stddev_Samp_Order_By = {
  creditNoteId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_order_returns" */
export type Customer_Order_Returns_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Returns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Returns_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  creditNoteId?: InputMaybe<Scalars['bigint']>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Customer_Order_Return_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Order_Returns_Sum_Fields = {
  __typename: 'customer_order_returns_sum_fields';
  creditNoteId?: Maybe<Scalars['bigint']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_order_returns" */
export type Customer_Order_Returns_Sum_Order_By = {
  creditNoteId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "customer_order_returns" */
export enum Customer_Order_Returns_Update_Column {
  /** column name */
  CreditNoteId = 'creditNoteId',
  /** column name */
  CustomerOrderId = 'customerOrderId',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  Version = 'version'
}

export type Customer_Order_Returns_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Order_Returns_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Order_Returns_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Order_Returns_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Order_Returns_Var_Pop_Fields = {
  __typename: 'customer_order_returns_var_pop_fields';
  creditNoteId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_order_returns" */
export type Customer_Order_Returns_Var_Pop_Order_By = {
  creditNoteId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Order_Returns_Var_Samp_Fields = {
  __typename: 'customer_order_returns_var_samp_fields';
  creditNoteId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_order_returns" */
export type Customer_Order_Returns_Var_Samp_Order_By = {
  creditNoteId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Order_Returns_Variance_Fields = {
  __typename: 'customer_order_returns_variance_fields';
  creditNoteId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_order_returns" */
export type Customer_Order_Returns_Variance_Order_By = {
  creditNoteId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_order_state_transitions" */
export type Customer_Order_State_Transitions = {
  __typename: 'customer_order_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Customer_Order_States_Enum;
  toState: Customer_Order_States_Enum;
};

/** aggregated selection of "customer_order_state_transitions" */
export type Customer_Order_State_Transitions_Aggregate = {
  __typename: 'customer_order_state_transitions_aggregate';
  aggregate?: Maybe<Customer_Order_State_Transitions_Aggregate_Fields>;
  nodes: Array<Customer_Order_State_Transitions>;
};

/** aggregate fields of "customer_order_state_transitions" */
export type Customer_Order_State_Transitions_Aggregate_Fields = {
  __typename: 'customer_order_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_State_Transitions_Max_Fields>;
  min?: Maybe<Customer_Order_State_Transitions_Min_Fields>;
};


/** aggregate fields of "customer_order_state_transitions" */
export type Customer_Order_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_order_state_transitions". All fields are combined with a logical 'AND'. */
export type Customer_Order_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Order_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Customer_Order_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Customer_Order_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Customer_Order_State_Transitions_Max_Fields = {
  __typename: 'customer_order_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Order_State_Transitions_Min_Fields = {
  __typename: 'customer_order_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "customer_order_state_transitions". */
export type Customer_Order_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "customer_order_state_transitions" */
export enum Customer_Order_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "customer_order_state_transitions" */
export type Customer_Order_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Customer_Order_States_Enum>;
  toState?: InputMaybe<Customer_Order_States_Enum>;
};

/** columns and relationships of "customer_order_states" */
export type Customer_Order_States = {
  __typename: 'customer_order_states';
  /** An array relationship */
  customerOrders: Array<Customer_Orders>;
  /** An aggregate relationship */
  customerOrders_aggregate: Customer_Orders_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "customer_order_states" */
export type Customer_Order_StatesCustomerOrdersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** columns and relationships of "customer_order_states" */
export type Customer_Order_StatesCustomerOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};

/** aggregated selection of "customer_order_states" */
export type Customer_Order_States_Aggregate = {
  __typename: 'customer_order_states_aggregate';
  aggregate?: Maybe<Customer_Order_States_Aggregate_Fields>;
  nodes: Array<Customer_Order_States>;
};

/** aggregate fields of "customer_order_states" */
export type Customer_Order_States_Aggregate_Fields = {
  __typename: 'customer_order_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_States_Max_Fields>;
  min?: Maybe<Customer_Order_States_Min_Fields>;
};


/** aggregate fields of "customer_order_states" */
export type Customer_Order_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_order_states". All fields are combined with a logical 'AND'. */
export type Customer_Order_States_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Order_States_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_States_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_States_Bool_Exp>>;
  customerOrders?: InputMaybe<Customer_Orders_Bool_Exp>;
  customerOrders_aggregate?: InputMaybe<Customer_Orders_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Customer_Order_States_Enum {
  AttachedToInvoice = 'ATTACHED_TO_INVOICE',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Delivered = 'DELIVERED',
  OfferAccepted = 'OFFER_ACCEPTED',
  OfferRejected = 'OFFER_REJECTED',
  OfferSendRequested = 'OFFER_SEND_REQUESTED',
  OfferSent = 'OFFER_SENT',
  Open = 'OPEN',
  Ordered = 'ORDERED',
  Picked = 'PICKED',
  PickingInProgress = 'PICKING_IN_PROGRESS',
  Production = 'PRODUCTION',
  ReadyForPicking = 'READY_FOR_PICKING'
}

/** Boolean expression to compare columns of type "customer_order_states_enum". All fields are combined with logical 'AND'. */
export type Customer_Order_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Customer_Order_States_Enum>;
  _in?: InputMaybe<Array<Customer_Order_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Customer_Order_States_Enum>;
  _nin?: InputMaybe<Array<Customer_Order_States_Enum>>;
};

/** aggregate max on columns */
export type Customer_Order_States_Max_Fields = {
  __typename: 'customer_order_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Order_States_Min_Fields = {
  __typename: 'customer_order_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "customer_order_states". */
export type Customer_Order_States_Order_By = {
  customerOrders_aggregate?: InputMaybe<Customer_Orders_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "customer_order_states" */
export enum Customer_Order_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "customer_order_states" */
export type Customer_Order_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "customer_order_stock_reservations" */
export type Customer_Order_Stock_Reservations = {
  __typename: 'customer_order_stock_reservations';
  accountId?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  orderUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  reservationStockLocationId?: Maybe<Scalars['bigint']>;
  totalOrderQuantity?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "customer_order_stock_reservations" */
export type Customer_Order_Stock_Reservations_Aggregate = {
  __typename: 'customer_order_stock_reservations_aggregate';
  aggregate?: Maybe<Customer_Order_Stock_Reservations_Aggregate_Fields>;
  nodes: Array<Customer_Order_Stock_Reservations>;
};

/** aggregate fields of "customer_order_stock_reservations" */
export type Customer_Order_Stock_Reservations_Aggregate_Fields = {
  __typename: 'customer_order_stock_reservations_aggregate_fields';
  avg?: Maybe<Customer_Order_Stock_Reservations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Order_Stock_Reservations_Max_Fields>;
  min?: Maybe<Customer_Order_Stock_Reservations_Min_Fields>;
  stddev?: Maybe<Customer_Order_Stock_Reservations_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Order_Stock_Reservations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Order_Stock_Reservations_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Order_Stock_Reservations_Sum_Fields>;
  var_pop?: Maybe<Customer_Order_Stock_Reservations_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Order_Stock_Reservations_Var_Samp_Fields>;
  variance?: Maybe<Customer_Order_Stock_Reservations_Variance_Fields>;
};


/** aggregate fields of "customer_order_stock_reservations" */
export type Customer_Order_Stock_Reservations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Order_Stock_Reservations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Customer_Order_Stock_Reservations_Avg_Fields = {
  __typename: 'customer_order_stock_reservations_avg_fields';
  accountId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservationStockLocationId?: Maybe<Scalars['Float']>;
  totalOrderQuantity?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "customer_order_stock_reservations". All fields are combined with a logical 'AND'. */
export type Customer_Order_Stock_Reservations_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Order_Stock_Reservations_Bool_Exp>>;
  _not?: InputMaybe<Customer_Order_Stock_Reservations_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Order_Stock_Reservations_Bool_Exp>>;
  accountId?: InputMaybe<Bigint_Comparison_Exp>;
  lotNumber?: InputMaybe<String_Comparison_Exp>;
  lotNumberId?: InputMaybe<Bigint_Comparison_Exp>;
  orderUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  reservationStockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  totalOrderQuantity?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Customer_Order_Stock_Reservations_Max_Fields = {
  __typename: 'customer_order_stock_reservations_max_fields';
  accountId?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  orderUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  reservationStockLocationId?: Maybe<Scalars['bigint']>;
  totalOrderQuantity?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Customer_Order_Stock_Reservations_Min_Fields = {
  __typename: 'customer_order_stock_reservations_min_fields';
  accountId?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  orderUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  reservationStockLocationId?: Maybe<Scalars['bigint']>;
  totalOrderQuantity?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "customer_order_stock_reservations". */
export type Customer_Order_Stock_Reservations_Order_By = {
  accountId?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservationStockLocationId?: InputMaybe<Order_By>;
  totalOrderQuantity?: InputMaybe<Order_By>;
};

/** select columns of table "customer_order_stock_reservations" */
export enum Customer_Order_Stock_Reservations_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  LotNumber = 'lotNumber',
  /** column name */
  LotNumberId = 'lotNumberId',
  /** column name */
  OrderUnitId = 'orderUnitId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ReservationStockLocationId = 'reservationStockLocationId',
  /** column name */
  TotalOrderQuantity = 'totalOrderQuantity'
}

/** aggregate stddev on columns */
export type Customer_Order_Stock_Reservations_Stddev_Fields = {
  __typename: 'customer_order_stock_reservations_stddev_fields';
  accountId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservationStockLocationId?: Maybe<Scalars['Float']>;
  totalOrderQuantity?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Customer_Order_Stock_Reservations_Stddev_Pop_Fields = {
  __typename: 'customer_order_stock_reservations_stddev_pop_fields';
  accountId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservationStockLocationId?: Maybe<Scalars['Float']>;
  totalOrderQuantity?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Customer_Order_Stock_Reservations_Stddev_Samp_Fields = {
  __typename: 'customer_order_stock_reservations_stddev_samp_fields';
  accountId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservationStockLocationId?: Maybe<Scalars['Float']>;
  totalOrderQuantity?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "customer_order_stock_reservations" */
export type Customer_Order_Stock_Reservations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Order_Stock_Reservations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Order_Stock_Reservations_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['bigint']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  orderUnitId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  reservationStockLocationId?: InputMaybe<Scalars['bigint']>;
  totalOrderQuantity?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Order_Stock_Reservations_Sum_Fields = {
  __typename: 'customer_order_stock_reservations_sum_fields';
  accountId?: Maybe<Scalars['bigint']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  orderUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  reservationStockLocationId?: Maybe<Scalars['bigint']>;
  totalOrderQuantity?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Customer_Order_Stock_Reservations_Var_Pop_Fields = {
  __typename: 'customer_order_stock_reservations_var_pop_fields';
  accountId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservationStockLocationId?: Maybe<Scalars['Float']>;
  totalOrderQuantity?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Customer_Order_Stock_Reservations_Var_Samp_Fields = {
  __typename: 'customer_order_stock_reservations_var_samp_fields';
  accountId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservationStockLocationId?: Maybe<Scalars['Float']>;
  totalOrderQuantity?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Customer_Order_Stock_Reservations_Variance_Fields = {
  __typename: 'customer_order_stock_reservations_variance_fields';
  accountId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservationStockLocationId?: Maybe<Scalars['Float']>;
  totalOrderQuantity?: Maybe<Scalars['Float']>;
};

/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_Orders = {
  __typename: 'customer_orders';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  aggregatedDemandPlanOutgoingGoodsResults: Array<Aggregated_Demand_Plan_Outgoing_Goods_Results>;
  /** An aggregate relationship */
  aggregatedDemandPlanOutgoingGoodsResults_aggregate: Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate;
  confirmationRecipients?: Maybe<Scalars['jsonb']>;
  contactPerson?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  currency: Currencies_Enum;
  /** An object relationship */
  customer?: Maybe<Customers>;
  customerId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  customerInvoiceOrders: Array<Customer_Invoice_Orders>;
  /** An aggregate relationship */
  customerInvoiceOrders_aggregate: Customer_Invoice_Orders_Aggregate;
  /** An array relationship */
  customerOrderContainerReturns: Array<Customer_Order_Container_Returns>;
  /** An aggregate relationship */
  customerOrderContainerReturns_aggregate: Customer_Order_Container_Returns_Aggregate;
  /** An array relationship */
  customerOrderItems: Array<Customer_Order_Items>;
  /** An aggregate relationship */
  customerOrderItems_aggregate: Customer_Order_Items_Aggregate;
  /** An array relationship */
  customerOrderPickedContainers: Array<Customer_Order_Picked_Container>;
  /** An aggregate relationship */
  customerOrderPickedContainers_aggregate: Customer_Order_Picked_Container_Aggregate;
  /** An array relationship */
  customerOrderReturns: Array<Customer_Order_Returns>;
  /** An aggregate relationship */
  customerOrderReturns_aggregate: Customer_Order_Returns_Aggregate;
  /** The day when the order needs to get delivered. */
  deliveryDate?: Maybe<Scalars['date']>;
  /** An object relationship */
  deliveryNotePdf?: Maybe<S3_Objects>;
  deliveryNotePdfId?: Maybe<Scalars['bigint']>;
  deliveryTimeEarliest?: Maybe<Scalars['time']>;
  deliveryTimeLatest?: Maybe<Scalars['time']>;
  domainId: Scalars['String'];
  externalOrderId?: Maybe<Scalars['String']>;
  /** An array relationship */
  globalSearchResults: Array<Global_Search_Result>;
  /** An aggregate relationship */
  globalSearchResults_aggregate: Global_Search_Result_Aggregate;
  /** Returns whether there exists an out of date picking slip pdf s3 object for this order, to be re-generated. */
  hasChangedPickingSlipPdf?: Maybe<Scalars['Boolean']>;
  id: Scalars['bigint'];
  isContainerSurchargeFree?: Maybe<Scalars['Boolean']>;
  isInternal: Scalars['Boolean'];
  /** Is this customer order's offer expired? */
  isOfferExpired?: Maybe<Scalars['Boolean']>;
  isPartialSurchargeFree?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  journalEntry?: Maybe<Journal_Entries>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  loggedActions: Array<Customer_Order_Logged_Actions>;
  /** An aggregate relationship */
  loggedActions_aggregate: Customer_Order_Logged_Actions_Aggregate;
  messageToCustomer?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  notesCustomer?: Maybe<Scalars['String']>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  offerAcceptedAt?: Maybe<Scalars['timestamptz']>;
  offerDate?: Maybe<Scalars['timestamptz']>;
  offerExpiresAt?: Maybe<Scalars['timestamptz']>;
  offerPublicAccessToken?: Maybe<Scalars['uuid']>;
  offerRecipients?: Maybe<Scalars['jsonb']>;
  orderDate?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  partialDeliveries: Array<Partial_Deliveries>;
  /** An aggregate relationship */
  partialDeliveries_aggregate: Partial_Deliveries_Aggregate;
  /** An object relationship */
  pickingSlipPdf?: Maybe<S3_Objects>;
  /** Returns the calculated bigint hash of the contents of this order and it's items within the context of picking slip pdf. */
  pickingSlipPdfContentHash?: Maybe<Scalars['bigint']>;
  pickingSlipPdfId?: Maybe<Scalars['bigint']>;
  /** Returns the customer order items in picking UI specific order */
  pickingSortedCustomerOrderItems?: Maybe<Array<Customer_Order_Items>>;
  /** An object relationship */
  salesChannel?: Maybe<Sales_Channels>;
  salesChannelId?: Maybe<Scalars['bigint']>;
  state: Customer_Order_States_Enum;
  /** An object relationship */
  targetStockLocation?: Maybe<Stock_Locations>;
  targetStockLocationId?: Maybe<Scalars['bigint']>;
  tenant: Scalars['String'];
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: Maybe<Scalars['bigint']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['bigint']>;
  totalInvoiceWeight?: Maybe<Scalars['bigint']>;
  totalOrderPrice?: Maybe<Scalars['bigint']>;
  totalOrderWeight?: Maybe<Scalars['bigint']>;
  totalSalesPrice?: Maybe<Scalars['bigint']>;
  totalSalesWeight?: Maybe<Scalars['bigint']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  tour?: Maybe<Tours>;
  tourId?: Maybe<Scalars['bigint']>;
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersAggregatedDemandPlanOutgoingGoodsResultsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersAggregatedDemandPlanOutgoingGoodsResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersConfirmationRecipientsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersCustomerInvoiceOrdersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Orders_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Orders_Bool_Exp>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersCustomerInvoiceOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Orders_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Orders_Bool_Exp>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersCustomerOrderContainerReturnsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Returns_Bool_Exp>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersCustomerOrderContainerReturns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Returns_Bool_Exp>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersCustomerOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersCustomerOrderItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersCustomerOrderPickedContainersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersCustomerOrderPickedContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersCustomerOrderReturnsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersCustomerOrderReturns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersGlobalSearchResultsArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersGlobalSearchResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Order_Logged_Actions_Bool_Exp>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Order_Logged_Actions_Bool_Exp>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersOfferRecipientsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersPartialDeliveriesArgs = {
  distinct_on?: InputMaybe<Array<Partial_Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Deliveries_Order_By>>;
  where?: InputMaybe<Partial_Deliveries_Bool_Exp>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersPartialDeliveries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partial_Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Deliveries_Order_By>>;
  where?: InputMaybe<Partial_Deliveries_Bool_Exp>;
};


/** Customer orders to be delivered on a certain delivery date. Used for internal and external customers, for different purposes. */
export type Customer_OrdersPickingSortedCustomerOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};

/** aggregated selection of "customer_orders" */
export type Customer_Orders_Aggregate = {
  __typename: 'customer_orders_aggregate';
  aggregate?: Maybe<Customer_Orders_Aggregate_Fields>;
  nodes: Array<Customer_Orders>;
};

export type Customer_Orders_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Customer_Orders_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Customer_Orders_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Customer_Orders_Aggregate_Bool_Exp_Count>;
};

export type Customer_Orders_Aggregate_Bool_Exp_Bool_And = {
  arguments: Customer_Orders_Select_Column_Customer_Orders_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Orders_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Orders_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Customer_Orders_Select_Column_Customer_Orders_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Orders_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Orders_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Orders_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_orders" */
export type Customer_Orders_Aggregate_Fields = {
  __typename: 'customer_orders_aggregate_fields';
  avg?: Maybe<Customer_Orders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Orders_Max_Fields>;
  min?: Maybe<Customer_Orders_Min_Fields>;
  stddev?: Maybe<Customer_Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Orders_Sum_Fields>;
  var_pop?: Maybe<Customer_Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Orders_Var_Samp_Fields>;
  variance?: Maybe<Customer_Orders_Variance_Fields>;
};


/** aggregate fields of "customer_orders" */
export type Customer_Orders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_orders" */
export type Customer_Orders_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Orders_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Orders_Max_Order_By>;
  min?: InputMaybe<Customer_Orders_Min_Order_By>;
  stddev?: InputMaybe<Customer_Orders_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Orders_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Orders_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Orders_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Orders_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Orders_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Orders_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Customer_Orders_Append_Input = {
  confirmationRecipients?: InputMaybe<Scalars['jsonb']>;
  offerRecipients?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "customer_orders" */
export type Customer_Orders_Arr_Rel_Insert_Input = {
  data: Array<Customer_Orders_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Orders_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Orders_Avg_Fields = {
  __typename: 'customer_orders_avg_fields';
  customerId?: Maybe<Scalars['Float']>;
  deliveryNotePdfId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pickingSlipPdfId?: Maybe<Scalars['Float']>;
  salesChannelId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: Maybe<Scalars['Float']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['Float']>;
  totalInvoiceWeight?: Maybe<Scalars['Float']>;
  totalOrderPrice?: Maybe<Scalars['Float']>;
  totalOrderWeight?: Maybe<Scalars['Float']>;
  totalSalesPrice?: Maybe<Scalars['Float']>;
  totalSalesWeight?: Maybe<Scalars['Float']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['Float']>;
  tourId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_orders" */
export type Customer_Orders_Avg_Order_By = {
  customerId?: InputMaybe<Order_By>;
  deliveryNotePdfId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  pickingSlipPdfId?: InputMaybe<Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalOrderPrice?: InputMaybe<Order_By>;
  totalOrderWeight?: InputMaybe<Order_By>;
  totalSalesPrice?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  tourId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_orders". All fields are combined with a logical 'AND'. */
export type Customer_Orders_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Orders_Bool_Exp>>;
  _not?: InputMaybe<Customer_Orders_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Orders_Bool_Exp>>;
  aggregatedDemandPlanOutgoingGoodsResults?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
  aggregatedDemandPlanOutgoingGoodsResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Bool_Exp>;
  confirmationRecipients?: InputMaybe<Jsonb_Comparison_Exp>;
  contactPerson?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  customer?: InputMaybe<Customers_Bool_Exp>;
  customerId?: InputMaybe<Bigint_Comparison_Exp>;
  customerInvoiceOrders?: InputMaybe<Customer_Invoice_Orders_Bool_Exp>;
  customerInvoiceOrders_aggregate?: InputMaybe<Customer_Invoice_Orders_Aggregate_Bool_Exp>;
  customerOrderContainerReturns?: InputMaybe<Customer_Order_Container_Returns_Bool_Exp>;
  customerOrderContainerReturns_aggregate?: InputMaybe<Customer_Order_Container_Returns_Aggregate_Bool_Exp>;
  customerOrderItems?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  customerOrderItems_aggregate?: InputMaybe<Customer_Order_Items_Aggregate_Bool_Exp>;
  customerOrderPickedContainers?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
  customerOrderPickedContainers_aggregate?: InputMaybe<Customer_Order_Picked_Container_Aggregate_Bool_Exp>;
  customerOrderReturns?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
  customerOrderReturns_aggregate?: InputMaybe<Customer_Order_Returns_Aggregate_Bool_Exp>;
  deliveryDate?: InputMaybe<Date_Comparison_Exp>;
  deliveryNotePdf?: InputMaybe<S3_Objects_Bool_Exp>;
  deliveryNotePdfId?: InputMaybe<Bigint_Comparison_Exp>;
  deliveryTimeEarliest?: InputMaybe<Time_Comparison_Exp>;
  deliveryTimeLatest?: InputMaybe<Time_Comparison_Exp>;
  domainId?: InputMaybe<String_Comparison_Exp>;
  externalOrderId?: InputMaybe<String_Comparison_Exp>;
  globalSearchResults?: InputMaybe<Global_Search_Result_Bool_Exp>;
  globalSearchResults_aggregate?: InputMaybe<Global_Search_Result_Aggregate_Bool_Exp>;
  hasChangedPickingSlipPdf?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  isContainerSurchargeFree?: InputMaybe<Boolean_Comparison_Exp>;
  isInternal?: InputMaybe<Boolean_Comparison_Exp>;
  isOfferExpired?: InputMaybe<Boolean_Comparison_Exp>;
  isPartialSurchargeFree?: InputMaybe<Boolean_Comparison_Exp>;
  journalEntry?: InputMaybe<Journal_Entries_Bool_Exp>;
  journalEntryId?: InputMaybe<Bigint_Comparison_Exp>;
  loggedActions?: InputMaybe<Customer_Order_Logged_Actions_Bool_Exp>;
  loggedActions_aggregate?: InputMaybe<Customer_Order_Logged_Actions_Aggregate_Bool_Exp>;
  messageToCustomer?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  notesCustomer?: InputMaybe<String_Comparison_Exp>;
  notifications?: InputMaybe<Notifications_Bool_Exp>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  offerAcceptedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  offerDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  offerExpiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  offerPublicAccessToken?: InputMaybe<Uuid_Comparison_Exp>;
  offerRecipients?: InputMaybe<Jsonb_Comparison_Exp>;
  orderDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  partialDeliveries?: InputMaybe<Partial_Deliveries_Bool_Exp>;
  partialDeliveries_aggregate?: InputMaybe<Partial_Deliveries_Aggregate_Bool_Exp>;
  pickingSlipPdf?: InputMaybe<S3_Objects_Bool_Exp>;
  pickingSlipPdfContentHash?: InputMaybe<Bigint_Comparison_Exp>;
  pickingSlipPdfId?: InputMaybe<Bigint_Comparison_Exp>;
  pickingSortedCustomerOrderItems?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  salesChannel?: InputMaybe<Sales_Channels_Bool_Exp>;
  salesChannelId?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<Customer_Order_States_Enum_Comparison_Exp>;
  targetStockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  targetStockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  totalInvoicePrice?: InputMaybe<Bigint_Comparison_Exp>;
  totalInvoicePriceWithVat?: InputMaybe<Bigint_Comparison_Exp>;
  totalInvoiceWeight?: InputMaybe<Bigint_Comparison_Exp>;
  totalOrderPrice?: InputMaybe<Bigint_Comparison_Exp>;
  totalOrderWeight?: InputMaybe<Bigint_Comparison_Exp>;
  totalSalesPrice?: InputMaybe<Bigint_Comparison_Exp>;
  totalSalesWeight?: InputMaybe<Bigint_Comparison_Exp>;
  totalVatOnInvoicePrice?: InputMaybe<Bigint_Comparison_Exp>;
  tour?: InputMaybe<Tours_Bool_Exp>;
  tourId?: InputMaybe<Bigint_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_orders" */
export enum Customer_Orders_Constraint {
  /** unique or primary key constraint on columns "external_order_id", "tenant", "customer_id" */
  CustomerOrdersExternalOrderId = 'customer_orders_external_order_id',
  /** unique or primary key constraint on columns "offer_public_access_token" */
  CustomerOrdersOfferPublicAccessTokenIdx = 'customer_orders_offer_public_access_token_idx',
  /** unique or primary key constraint on columns "id" */
  CustomerOrdersPkey = 'customer_orders_pkey',
  /** unique or primary key constraint on columns "tenant", "domain_id" */
  CustomerOrdersTenantDomainId = 'customer_orders_tenant_domain_id'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Customer_Orders_Delete_At_Path_Input = {
  confirmationRecipients?: InputMaybe<Array<Scalars['String']>>;
  offerRecipients?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Customer_Orders_Delete_Elem_Input = {
  confirmationRecipients?: InputMaybe<Scalars['Int']>;
  offerRecipients?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Customer_Orders_Delete_Key_Input = {
  confirmationRecipients?: InputMaybe<Scalars['String']>;
  offerRecipients?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "customer_orders" */
export type Customer_Orders_Inc_Input = {
  customerId?: InputMaybe<Scalars['bigint']>;
  deliveryNotePdfId?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  pickingSlipPdfId?: InputMaybe<Scalars['bigint']>;
  salesChannelId?: InputMaybe<Scalars['bigint']>;
  targetStockLocationId?: InputMaybe<Scalars['bigint']>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: InputMaybe<Scalars['bigint']>;
  totalInvoicePriceWithVat?: InputMaybe<Scalars['bigint']>;
  totalInvoiceWeight?: InputMaybe<Scalars['bigint']>;
  totalOrderPrice?: InputMaybe<Scalars['bigint']>;
  totalOrderWeight?: InputMaybe<Scalars['bigint']>;
  totalSalesPrice?: InputMaybe<Scalars['bigint']>;
  totalSalesWeight?: InputMaybe<Scalars['bigint']>;
  totalVatOnInvoicePrice?: InputMaybe<Scalars['bigint']>;
  tourId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_orders" */
export type Customer_Orders_Insert_Input = {
  confirmationRecipients?: InputMaybe<Scalars['jsonb']>;
  contactPerson?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Currencies_Enum>;
  customer?: InputMaybe<Customers_Obj_Rel_Insert_Input>;
  customerId?: InputMaybe<Scalars['bigint']>;
  customerInvoiceOrders?: InputMaybe<Customer_Invoice_Orders_Arr_Rel_Insert_Input>;
  customerOrderContainerReturns?: InputMaybe<Customer_Order_Container_Returns_Arr_Rel_Insert_Input>;
  customerOrderItems?: InputMaybe<Customer_Order_Items_Arr_Rel_Insert_Input>;
  customerOrderPickedContainers?: InputMaybe<Customer_Order_Picked_Container_Arr_Rel_Insert_Input>;
  customerOrderReturns?: InputMaybe<Customer_Order_Returns_Arr_Rel_Insert_Input>;
  /** The day when the order needs to get delivered. */
  deliveryDate?: InputMaybe<Scalars['date']>;
  deliveryNotePdf?: InputMaybe<S3_Objects_Obj_Rel_Insert_Input>;
  deliveryNotePdfId?: InputMaybe<Scalars['bigint']>;
  deliveryTimeEarliest?: InputMaybe<Scalars['time']>;
  deliveryTimeLatest?: InputMaybe<Scalars['time']>;
  domainId?: InputMaybe<Scalars['String']>;
  externalOrderId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  isContainerSurchargeFree?: InputMaybe<Scalars['Boolean']>;
  isInternal?: InputMaybe<Scalars['Boolean']>;
  isPartialSurchargeFree?: InputMaybe<Scalars['Boolean']>;
  journalEntry?: InputMaybe<Journal_Entries_Obj_Rel_Insert_Input>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  messageToCustomer?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  notesCustomer?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  offerAcceptedAt?: InputMaybe<Scalars['timestamptz']>;
  offerDate?: InputMaybe<Scalars['timestamptz']>;
  offerExpiresAt?: InputMaybe<Scalars['timestamptz']>;
  offerPublicAccessToken?: InputMaybe<Scalars['uuid']>;
  offerRecipients?: InputMaybe<Scalars['jsonb']>;
  orderDate?: InputMaybe<Scalars['timestamptz']>;
  partialDeliveries?: InputMaybe<Partial_Deliveries_Arr_Rel_Insert_Input>;
  pickingSlipPdf?: InputMaybe<S3_Objects_Obj_Rel_Insert_Input>;
  pickingSlipPdfId?: InputMaybe<Scalars['bigint']>;
  salesChannelId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Customer_Order_States_Enum>;
  targetStockLocation?: InputMaybe<Stock_Locations_Obj_Rel_Insert_Input>;
  targetStockLocationId?: InputMaybe<Scalars['bigint']>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: InputMaybe<Scalars['bigint']>;
  totalInvoicePriceWithVat?: InputMaybe<Scalars['bigint']>;
  totalInvoiceWeight?: InputMaybe<Scalars['bigint']>;
  totalOrderPrice?: InputMaybe<Scalars['bigint']>;
  totalOrderWeight?: InputMaybe<Scalars['bigint']>;
  totalSalesPrice?: InputMaybe<Scalars['bigint']>;
  totalSalesWeight?: InputMaybe<Scalars['bigint']>;
  totalVatOnInvoicePrice?: InputMaybe<Scalars['bigint']>;
  tour?: InputMaybe<Tours_Obj_Rel_Insert_Input>;
  tourId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Customer_Orders_Max_Fields = {
  __typename: 'customer_orders_max_fields';
  contactPerson?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerId?: Maybe<Scalars['bigint']>;
  /** The day when the order needs to get delivered. */
  deliveryDate?: Maybe<Scalars['date']>;
  deliveryNotePdfId?: Maybe<Scalars['bigint']>;
  domainId?: Maybe<Scalars['String']>;
  externalOrderId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  messageToCustomer?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  notesCustomer?: Maybe<Scalars['String']>;
  offerAcceptedAt?: Maybe<Scalars['timestamptz']>;
  offerDate?: Maybe<Scalars['timestamptz']>;
  offerExpiresAt?: Maybe<Scalars['timestamptz']>;
  offerPublicAccessToken?: Maybe<Scalars['uuid']>;
  orderDate?: Maybe<Scalars['timestamptz']>;
  pickingSlipPdfId?: Maybe<Scalars['bigint']>;
  salesChannelId?: Maybe<Scalars['bigint']>;
  targetStockLocationId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: Maybe<Scalars['bigint']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['bigint']>;
  totalInvoiceWeight?: Maybe<Scalars['bigint']>;
  totalOrderPrice?: Maybe<Scalars['bigint']>;
  totalOrderWeight?: Maybe<Scalars['bigint']>;
  totalSalesPrice?: Maybe<Scalars['bigint']>;
  totalSalesWeight?: Maybe<Scalars['bigint']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['bigint']>;
  tourId?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_orders" */
export type Customer_Orders_Max_Order_By = {
  contactPerson?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  /** The day when the order needs to get delivered. */
  deliveryDate?: InputMaybe<Order_By>;
  deliveryNotePdfId?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  externalOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  messageToCustomer?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  notesCustomer?: InputMaybe<Order_By>;
  offerAcceptedAt?: InputMaybe<Order_By>;
  offerDate?: InputMaybe<Order_By>;
  offerExpiresAt?: InputMaybe<Order_By>;
  offerPublicAccessToken?: InputMaybe<Order_By>;
  orderDate?: InputMaybe<Order_By>;
  pickingSlipPdfId?: InputMaybe<Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalOrderPrice?: InputMaybe<Order_By>;
  totalOrderWeight?: InputMaybe<Order_By>;
  totalSalesPrice?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  tourId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Orders_Min_Fields = {
  __typename: 'customer_orders_min_fields';
  contactPerson?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerId?: Maybe<Scalars['bigint']>;
  /** The day when the order needs to get delivered. */
  deliveryDate?: Maybe<Scalars['date']>;
  deliveryNotePdfId?: Maybe<Scalars['bigint']>;
  domainId?: Maybe<Scalars['String']>;
  externalOrderId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  messageToCustomer?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  notesCustomer?: Maybe<Scalars['String']>;
  offerAcceptedAt?: Maybe<Scalars['timestamptz']>;
  offerDate?: Maybe<Scalars['timestamptz']>;
  offerExpiresAt?: Maybe<Scalars['timestamptz']>;
  offerPublicAccessToken?: Maybe<Scalars['uuid']>;
  orderDate?: Maybe<Scalars['timestamptz']>;
  pickingSlipPdfId?: Maybe<Scalars['bigint']>;
  salesChannelId?: Maybe<Scalars['bigint']>;
  targetStockLocationId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: Maybe<Scalars['bigint']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['bigint']>;
  totalInvoiceWeight?: Maybe<Scalars['bigint']>;
  totalOrderPrice?: Maybe<Scalars['bigint']>;
  totalOrderWeight?: Maybe<Scalars['bigint']>;
  totalSalesPrice?: Maybe<Scalars['bigint']>;
  totalSalesWeight?: Maybe<Scalars['bigint']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['bigint']>;
  tourId?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_orders" */
export type Customer_Orders_Min_Order_By = {
  contactPerson?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  /** The day when the order needs to get delivered. */
  deliveryDate?: InputMaybe<Order_By>;
  deliveryNotePdfId?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  externalOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  messageToCustomer?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  notesCustomer?: InputMaybe<Order_By>;
  offerAcceptedAt?: InputMaybe<Order_By>;
  offerDate?: InputMaybe<Order_By>;
  offerExpiresAt?: InputMaybe<Order_By>;
  offerPublicAccessToken?: InputMaybe<Order_By>;
  orderDate?: InputMaybe<Order_By>;
  pickingSlipPdfId?: InputMaybe<Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalOrderPrice?: InputMaybe<Order_By>;
  totalOrderWeight?: InputMaybe<Order_By>;
  totalSalesPrice?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  tourId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_orders" */
export type Customer_Orders_Mutation_Response = {
  __typename: 'customer_orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Orders>;
};

/** input type for inserting object relation for remote table "customer_orders" */
export type Customer_Orders_Obj_Rel_Insert_Input = {
  data: Customer_Orders_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Orders_On_Conflict>;
};

/** on_conflict condition type for table "customer_orders" */
export type Customer_Orders_On_Conflict = {
  constraint: Customer_Orders_Constraint;
  update_columns?: Array<Customer_Orders_Update_Column>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_orders". */
export type Customer_Orders_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  aggregatedDemandPlanOutgoingGoodsResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Order_By>;
  confirmationRecipients?: InputMaybe<Order_By>;
  contactPerson?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  customer?: InputMaybe<Customers_Order_By>;
  customerId?: InputMaybe<Order_By>;
  customerInvoiceOrders_aggregate?: InputMaybe<Customer_Invoice_Orders_Aggregate_Order_By>;
  customerOrderContainerReturns_aggregate?: InputMaybe<Customer_Order_Container_Returns_Aggregate_Order_By>;
  customerOrderItems_aggregate?: InputMaybe<Customer_Order_Items_Aggregate_Order_By>;
  customerOrderPickedContainers_aggregate?: InputMaybe<Customer_Order_Picked_Container_Aggregate_Order_By>;
  customerOrderReturns_aggregate?: InputMaybe<Customer_Order_Returns_Aggregate_Order_By>;
  deliveryDate?: InputMaybe<Order_By>;
  deliveryNotePdf?: InputMaybe<S3_Objects_Order_By>;
  deliveryNotePdfId?: InputMaybe<Order_By>;
  deliveryTimeEarliest?: InputMaybe<Order_By>;
  deliveryTimeLatest?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  externalOrderId?: InputMaybe<Order_By>;
  globalSearchResults_aggregate?: InputMaybe<Global_Search_Result_Aggregate_Order_By>;
  hasChangedPickingSlipPdf?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isContainerSurchargeFree?: InputMaybe<Order_By>;
  isInternal?: InputMaybe<Order_By>;
  isOfferExpired?: InputMaybe<Order_By>;
  isPartialSurchargeFree?: InputMaybe<Order_By>;
  journalEntry?: InputMaybe<Journal_Entries_Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  loggedActions_aggregate?: InputMaybe<Customer_Order_Logged_Actions_Aggregate_Order_By>;
  messageToCustomer?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  notesCustomer?: InputMaybe<Order_By>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  offerAcceptedAt?: InputMaybe<Order_By>;
  offerDate?: InputMaybe<Order_By>;
  offerExpiresAt?: InputMaybe<Order_By>;
  offerPublicAccessToken?: InputMaybe<Order_By>;
  offerRecipients?: InputMaybe<Order_By>;
  orderDate?: InputMaybe<Order_By>;
  partialDeliveries_aggregate?: InputMaybe<Partial_Deliveries_Aggregate_Order_By>;
  pickingSlipPdf?: InputMaybe<S3_Objects_Order_By>;
  pickingSlipPdfContentHash?: InputMaybe<Order_By>;
  pickingSlipPdfId?: InputMaybe<Order_By>;
  pickingSortedCustomerOrderItems_aggregate?: InputMaybe<Customer_Order_Items_Aggregate_Order_By>;
  salesChannel?: InputMaybe<Sales_Channels_Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  targetStockLocation?: InputMaybe<Stock_Locations_Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalOrderPrice?: InputMaybe<Order_By>;
  totalOrderWeight?: InputMaybe<Order_By>;
  totalSalesPrice?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  tour?: InputMaybe<Tours_Order_By>;
  tourId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_orders */
export type Customer_Orders_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Customer_Orders_Prepend_Input = {
  confirmationRecipients?: InputMaybe<Scalars['jsonb']>;
  offerRecipients?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "customer_orders" */
export enum Customer_Orders_Select_Column {
  /** column name */
  ConfirmationRecipients = 'confirmationRecipients',
  /** column name */
  ContactPerson = 'contactPerson',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  DeliveryDate = 'deliveryDate',
  /** column name */
  DeliveryNotePdfId = 'deliveryNotePdfId',
  /** column name */
  DeliveryTimeEarliest = 'deliveryTimeEarliest',
  /** column name */
  DeliveryTimeLatest = 'deliveryTimeLatest',
  /** column name */
  DomainId = 'domainId',
  /** column name */
  ExternalOrderId = 'externalOrderId',
  /** column name */
  Id = 'id',
  /** column name */
  IsContainerSurchargeFree = 'isContainerSurchargeFree',
  /** column name */
  IsInternal = 'isInternal',
  /** column name */
  IsPartialSurchargeFree = 'isPartialSurchargeFree',
  /** column name */
  JournalEntryId = 'journalEntryId',
  /** column name */
  MessageToCustomer = 'messageToCustomer',
  /** column name */
  Notes = 'notes',
  /** column name */
  NotesCustomer = 'notesCustomer',
  /** column name */
  OfferAcceptedAt = 'offerAcceptedAt',
  /** column name */
  OfferDate = 'offerDate',
  /** column name */
  OfferExpiresAt = 'offerExpiresAt',
  /** column name */
  OfferPublicAccessToken = 'offerPublicAccessToken',
  /** column name */
  OfferRecipients = 'offerRecipients',
  /** column name */
  OrderDate = 'orderDate',
  /** column name */
  PickingSlipPdfId = 'pickingSlipPdfId',
  /** column name */
  SalesChannelId = 'salesChannelId',
  /** column name */
  State = 'state',
  /** column name */
  TargetStockLocationId = 'targetStockLocationId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  TotalInvoicePrice = 'totalInvoicePrice',
  /** column name */
  TotalInvoicePriceWithVat = 'totalInvoicePriceWithVat',
  /** column name */
  TotalInvoiceWeight = 'totalInvoiceWeight',
  /** column name */
  TotalOrderPrice = 'totalOrderPrice',
  /** column name */
  TotalOrderWeight = 'totalOrderWeight',
  /** column name */
  TotalSalesPrice = 'totalSalesPrice',
  /** column name */
  TotalSalesWeight = 'totalSalesWeight',
  /** column name */
  TotalVatOnInvoicePrice = 'totalVatOnInvoicePrice',
  /** column name */
  TourId = 'tourId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** select "customer_orders_aggregate_bool_exp_bool_and_arguments_columns" columns of table "customer_orders" */
export enum Customer_Orders_Select_Column_Customer_Orders_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsContainerSurchargeFree = 'isContainerSurchargeFree',
  /** column name */
  IsInternal = 'isInternal',
  /** column name */
  IsPartialSurchargeFree = 'isPartialSurchargeFree'
}

/** select "customer_orders_aggregate_bool_exp_bool_or_arguments_columns" columns of table "customer_orders" */
export enum Customer_Orders_Select_Column_Customer_Orders_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsContainerSurchargeFree = 'isContainerSurchargeFree',
  /** column name */
  IsInternal = 'isInternal',
  /** column name */
  IsPartialSurchargeFree = 'isPartialSurchargeFree'
}

/** input type for updating data in table "customer_orders" */
export type Customer_Orders_Set_Input = {
  confirmationRecipients?: InputMaybe<Scalars['jsonb']>;
  contactPerson?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Currencies_Enum>;
  customerId?: InputMaybe<Scalars['bigint']>;
  /** The day when the order needs to get delivered. */
  deliveryDate?: InputMaybe<Scalars['date']>;
  deliveryNotePdfId?: InputMaybe<Scalars['bigint']>;
  deliveryTimeEarliest?: InputMaybe<Scalars['time']>;
  deliveryTimeLatest?: InputMaybe<Scalars['time']>;
  domainId?: InputMaybe<Scalars['String']>;
  externalOrderId?: InputMaybe<Scalars['String']>;
  isContainerSurchargeFree?: InputMaybe<Scalars['Boolean']>;
  isPartialSurchargeFree?: InputMaybe<Scalars['Boolean']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  messageToCustomer?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  notesCustomer?: InputMaybe<Scalars['String']>;
  offerAcceptedAt?: InputMaybe<Scalars['timestamptz']>;
  offerDate?: InputMaybe<Scalars['timestamptz']>;
  offerExpiresAt?: InputMaybe<Scalars['timestamptz']>;
  offerPublicAccessToken?: InputMaybe<Scalars['uuid']>;
  offerRecipients?: InputMaybe<Scalars['jsonb']>;
  orderDate?: InputMaybe<Scalars['timestamptz']>;
  pickingSlipPdfId?: InputMaybe<Scalars['bigint']>;
  salesChannelId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Customer_Order_States_Enum>;
  targetStockLocationId?: InputMaybe<Scalars['bigint']>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: InputMaybe<Scalars['bigint']>;
  totalInvoicePriceWithVat?: InputMaybe<Scalars['bigint']>;
  totalInvoiceWeight?: InputMaybe<Scalars['bigint']>;
  totalOrderPrice?: InputMaybe<Scalars['bigint']>;
  totalOrderWeight?: InputMaybe<Scalars['bigint']>;
  totalSalesPrice?: InputMaybe<Scalars['bigint']>;
  totalSalesWeight?: InputMaybe<Scalars['bigint']>;
  totalVatOnInvoicePrice?: InputMaybe<Scalars['bigint']>;
  tourId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Customer_Orders_Stddev_Fields = {
  __typename: 'customer_orders_stddev_fields';
  customerId?: Maybe<Scalars['Float']>;
  deliveryNotePdfId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pickingSlipPdfId?: Maybe<Scalars['Float']>;
  salesChannelId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: Maybe<Scalars['Float']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['Float']>;
  totalInvoiceWeight?: Maybe<Scalars['Float']>;
  totalOrderPrice?: Maybe<Scalars['Float']>;
  totalOrderWeight?: Maybe<Scalars['Float']>;
  totalSalesPrice?: Maybe<Scalars['Float']>;
  totalSalesWeight?: Maybe<Scalars['Float']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['Float']>;
  tourId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_orders" */
export type Customer_Orders_Stddev_Order_By = {
  customerId?: InputMaybe<Order_By>;
  deliveryNotePdfId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  pickingSlipPdfId?: InputMaybe<Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalOrderPrice?: InputMaybe<Order_By>;
  totalOrderWeight?: InputMaybe<Order_By>;
  totalSalesPrice?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  tourId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Orders_Stddev_Pop_Fields = {
  __typename: 'customer_orders_stddev_pop_fields';
  customerId?: Maybe<Scalars['Float']>;
  deliveryNotePdfId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pickingSlipPdfId?: Maybe<Scalars['Float']>;
  salesChannelId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: Maybe<Scalars['Float']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['Float']>;
  totalInvoiceWeight?: Maybe<Scalars['Float']>;
  totalOrderPrice?: Maybe<Scalars['Float']>;
  totalOrderWeight?: Maybe<Scalars['Float']>;
  totalSalesPrice?: Maybe<Scalars['Float']>;
  totalSalesWeight?: Maybe<Scalars['Float']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['Float']>;
  tourId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_orders" */
export type Customer_Orders_Stddev_Pop_Order_By = {
  customerId?: InputMaybe<Order_By>;
  deliveryNotePdfId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  pickingSlipPdfId?: InputMaybe<Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalOrderPrice?: InputMaybe<Order_By>;
  totalOrderWeight?: InputMaybe<Order_By>;
  totalSalesPrice?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  tourId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Orders_Stddev_Samp_Fields = {
  __typename: 'customer_orders_stddev_samp_fields';
  customerId?: Maybe<Scalars['Float']>;
  deliveryNotePdfId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pickingSlipPdfId?: Maybe<Scalars['Float']>;
  salesChannelId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: Maybe<Scalars['Float']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['Float']>;
  totalInvoiceWeight?: Maybe<Scalars['Float']>;
  totalOrderPrice?: Maybe<Scalars['Float']>;
  totalOrderWeight?: Maybe<Scalars['Float']>;
  totalSalesPrice?: Maybe<Scalars['Float']>;
  totalSalesWeight?: Maybe<Scalars['Float']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['Float']>;
  tourId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_orders" */
export type Customer_Orders_Stddev_Samp_Order_By = {
  customerId?: InputMaybe<Order_By>;
  deliveryNotePdfId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  pickingSlipPdfId?: InputMaybe<Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalOrderPrice?: InputMaybe<Order_By>;
  totalOrderWeight?: InputMaybe<Order_By>;
  totalSalesPrice?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  tourId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_orders" */
export type Customer_Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Orders_Stream_Cursor_Value_Input = {
  confirmationRecipients?: InputMaybe<Scalars['jsonb']>;
  contactPerson?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Currencies_Enum>;
  customerId?: InputMaybe<Scalars['bigint']>;
  /** The day when the order needs to get delivered. */
  deliveryDate?: InputMaybe<Scalars['date']>;
  deliveryNotePdfId?: InputMaybe<Scalars['bigint']>;
  deliveryTimeEarliest?: InputMaybe<Scalars['time']>;
  deliveryTimeLatest?: InputMaybe<Scalars['time']>;
  domainId?: InputMaybe<Scalars['String']>;
  externalOrderId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  isContainerSurchargeFree?: InputMaybe<Scalars['Boolean']>;
  isInternal?: InputMaybe<Scalars['Boolean']>;
  isPartialSurchargeFree?: InputMaybe<Scalars['Boolean']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  messageToCustomer?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  notesCustomer?: InputMaybe<Scalars['String']>;
  offerAcceptedAt?: InputMaybe<Scalars['timestamptz']>;
  offerDate?: InputMaybe<Scalars['timestamptz']>;
  offerExpiresAt?: InputMaybe<Scalars['timestamptz']>;
  offerPublicAccessToken?: InputMaybe<Scalars['uuid']>;
  offerRecipients?: InputMaybe<Scalars['jsonb']>;
  orderDate?: InputMaybe<Scalars['timestamptz']>;
  pickingSlipPdfId?: InputMaybe<Scalars['bigint']>;
  salesChannelId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Customer_Order_States_Enum>;
  targetStockLocationId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: InputMaybe<Scalars['bigint']>;
  totalInvoicePriceWithVat?: InputMaybe<Scalars['bigint']>;
  totalInvoiceWeight?: InputMaybe<Scalars['bigint']>;
  totalOrderPrice?: InputMaybe<Scalars['bigint']>;
  totalOrderWeight?: InputMaybe<Scalars['bigint']>;
  totalSalesPrice?: InputMaybe<Scalars['bigint']>;
  totalSalesWeight?: InputMaybe<Scalars['bigint']>;
  totalVatOnInvoicePrice?: InputMaybe<Scalars['bigint']>;
  tourId?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Orders_Sum_Fields = {
  __typename: 'customer_orders_sum_fields';
  customerId?: Maybe<Scalars['bigint']>;
  deliveryNotePdfId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  pickingSlipPdfId?: Maybe<Scalars['bigint']>;
  salesChannelId?: Maybe<Scalars['bigint']>;
  targetStockLocationId?: Maybe<Scalars['bigint']>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: Maybe<Scalars['bigint']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['bigint']>;
  totalInvoiceWeight?: Maybe<Scalars['bigint']>;
  totalOrderPrice?: Maybe<Scalars['bigint']>;
  totalOrderWeight?: Maybe<Scalars['bigint']>;
  totalSalesPrice?: Maybe<Scalars['bigint']>;
  totalSalesWeight?: Maybe<Scalars['bigint']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['bigint']>;
  tourId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_orders" */
export type Customer_Orders_Sum_Order_By = {
  customerId?: InputMaybe<Order_By>;
  deliveryNotePdfId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  pickingSlipPdfId?: InputMaybe<Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalOrderPrice?: InputMaybe<Order_By>;
  totalOrderWeight?: InputMaybe<Order_By>;
  totalSalesPrice?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  tourId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "customer_orders" */
export enum Customer_Orders_Update_Column {
  /** column name */
  ConfirmationRecipients = 'confirmationRecipients',
  /** column name */
  ContactPerson = 'contactPerson',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  DeliveryDate = 'deliveryDate',
  /** column name */
  DeliveryNotePdfId = 'deliveryNotePdfId',
  /** column name */
  DeliveryTimeEarliest = 'deliveryTimeEarliest',
  /** column name */
  DeliveryTimeLatest = 'deliveryTimeLatest',
  /** column name */
  DomainId = 'domainId',
  /** column name */
  ExternalOrderId = 'externalOrderId',
  /** column name */
  IsContainerSurchargeFree = 'isContainerSurchargeFree',
  /** column name */
  IsPartialSurchargeFree = 'isPartialSurchargeFree',
  /** column name */
  JournalEntryId = 'journalEntryId',
  /** column name */
  MessageToCustomer = 'messageToCustomer',
  /** column name */
  Notes = 'notes',
  /** column name */
  NotesCustomer = 'notesCustomer',
  /** column name */
  OfferAcceptedAt = 'offerAcceptedAt',
  /** column name */
  OfferDate = 'offerDate',
  /** column name */
  OfferExpiresAt = 'offerExpiresAt',
  /** column name */
  OfferPublicAccessToken = 'offerPublicAccessToken',
  /** column name */
  OfferRecipients = 'offerRecipients',
  /** column name */
  OrderDate = 'orderDate',
  /** column name */
  PickingSlipPdfId = 'pickingSlipPdfId',
  /** column name */
  SalesChannelId = 'salesChannelId',
  /** column name */
  State = 'state',
  /** column name */
  TargetStockLocationId = 'targetStockLocationId',
  /** column name */
  TotalInvoicePrice = 'totalInvoicePrice',
  /** column name */
  TotalInvoicePriceWithVat = 'totalInvoicePriceWithVat',
  /** column name */
  TotalInvoiceWeight = 'totalInvoiceWeight',
  /** column name */
  TotalOrderPrice = 'totalOrderPrice',
  /** column name */
  TotalOrderWeight = 'totalOrderWeight',
  /** column name */
  TotalSalesPrice = 'totalSalesPrice',
  /** column name */
  TotalSalesWeight = 'totalSalesWeight',
  /** column name */
  TotalVatOnInvoicePrice = 'totalVatOnInvoicePrice',
  /** column name */
  TourId = 'tourId',
  /** column name */
  Version = 'version'
}

export type Customer_Orders_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Customer_Orders_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Customer_Orders_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Customer_Orders_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Customer_Orders_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Orders_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Customer_Orders_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Orders_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Orders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Orders_Var_Pop_Fields = {
  __typename: 'customer_orders_var_pop_fields';
  customerId?: Maybe<Scalars['Float']>;
  deliveryNotePdfId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pickingSlipPdfId?: Maybe<Scalars['Float']>;
  salesChannelId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: Maybe<Scalars['Float']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['Float']>;
  totalInvoiceWeight?: Maybe<Scalars['Float']>;
  totalOrderPrice?: Maybe<Scalars['Float']>;
  totalOrderWeight?: Maybe<Scalars['Float']>;
  totalSalesPrice?: Maybe<Scalars['Float']>;
  totalSalesWeight?: Maybe<Scalars['Float']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['Float']>;
  tourId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_orders" */
export type Customer_Orders_Var_Pop_Order_By = {
  customerId?: InputMaybe<Order_By>;
  deliveryNotePdfId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  pickingSlipPdfId?: InputMaybe<Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalOrderPrice?: InputMaybe<Order_By>;
  totalOrderWeight?: InputMaybe<Order_By>;
  totalSalesPrice?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  tourId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Orders_Var_Samp_Fields = {
  __typename: 'customer_orders_var_samp_fields';
  customerId?: Maybe<Scalars['Float']>;
  deliveryNotePdfId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pickingSlipPdfId?: Maybe<Scalars['Float']>;
  salesChannelId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: Maybe<Scalars['Float']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['Float']>;
  totalInvoiceWeight?: Maybe<Scalars['Float']>;
  totalOrderPrice?: Maybe<Scalars['Float']>;
  totalOrderWeight?: Maybe<Scalars['Float']>;
  totalSalesPrice?: Maybe<Scalars['Float']>;
  totalSalesWeight?: Maybe<Scalars['Float']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['Float']>;
  tourId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_orders" */
export type Customer_Orders_Var_Samp_Order_By = {
  customerId?: InputMaybe<Order_By>;
  deliveryNotePdfId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  pickingSlipPdfId?: InputMaybe<Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalOrderPrice?: InputMaybe<Order_By>;
  totalOrderWeight?: InputMaybe<Order_By>;
  totalSalesPrice?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  tourId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Orders_Variance_Fields = {
  __typename: 'customer_orders_variance_fields';
  customerId?: Maybe<Scalars['Float']>;
  deliveryNotePdfId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pickingSlipPdfId?: Maybe<Scalars['Float']>;
  salesChannelId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: Maybe<Scalars['Float']>;
  totalInvoicePriceWithVat?: Maybe<Scalars['Float']>;
  totalInvoiceWeight?: Maybe<Scalars['Float']>;
  totalOrderPrice?: Maybe<Scalars['Float']>;
  totalOrderWeight?: Maybe<Scalars['Float']>;
  totalSalesPrice?: Maybe<Scalars['Float']>;
  totalSalesWeight?: Maybe<Scalars['Float']>;
  totalVatOnInvoicePrice?: Maybe<Scalars['Float']>;
  tourId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_orders" */
export type Customer_Orders_Variance_Order_By = {
  customerId?: InputMaybe<Order_By>;
  deliveryNotePdfId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  pickingSlipPdfId?: InputMaybe<Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  /** total_sales_price + deductions/post delivery changes in price. Trigger computed field, scaled number. */
  totalInvoicePrice?: InputMaybe<Order_By>;
  totalInvoicePriceWithVat?: InputMaybe<Order_By>;
  totalInvoiceWeight?: InputMaybe<Order_By>;
  totalOrderPrice?: InputMaybe<Order_By>;
  totalOrderWeight?: InputMaybe<Order_By>;
  totalSalesPrice?: InputMaybe<Order_By>;
  totalSalesWeight?: InputMaybe<Order_By>;
  totalVatOnInvoicePrice?: InputMaybe<Order_By>;
  tourId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_payment_method" */
export type Customer_Payment_Method = {
  __typename: 'customer_payment_method';
  /** An array relationship */
  customerBillingSettings: Array<Customer_Billing_Settings>;
  /** An aggregate relationship */
  customerBillingSettings_aggregate: Customer_Billing_Settings_Aggregate;
  /** An array relationship */
  customerInvoices: Array<Customer_Invoices>;
  /** An aggregate relationship */
  customerInvoices_aggregate: Customer_Invoices_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "customer_payment_method" */
export type Customer_Payment_MethodCustomerBillingSettingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Settings_Order_By>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};


/** columns and relationships of "customer_payment_method" */
export type Customer_Payment_MethodCustomerBillingSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Settings_Order_By>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};


/** columns and relationships of "customer_payment_method" */
export type Customer_Payment_MethodCustomerInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoices_Order_By>>;
  where?: InputMaybe<Customer_Invoices_Bool_Exp>;
};


/** columns and relationships of "customer_payment_method" */
export type Customer_Payment_MethodCustomerInvoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoices_Order_By>>;
  where?: InputMaybe<Customer_Invoices_Bool_Exp>;
};

/** aggregated selection of "customer_payment_method" */
export type Customer_Payment_Method_Aggregate = {
  __typename: 'customer_payment_method_aggregate';
  aggregate?: Maybe<Customer_Payment_Method_Aggregate_Fields>;
  nodes: Array<Customer_Payment_Method>;
};

/** aggregate fields of "customer_payment_method" */
export type Customer_Payment_Method_Aggregate_Fields = {
  __typename: 'customer_payment_method_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Payment_Method_Max_Fields>;
  min?: Maybe<Customer_Payment_Method_Min_Fields>;
};


/** aggregate fields of "customer_payment_method" */
export type Customer_Payment_Method_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Payment_Method_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_payment_method". All fields are combined with a logical 'AND'. */
export type Customer_Payment_Method_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Payment_Method_Bool_Exp>>;
  _not?: InputMaybe<Customer_Payment_Method_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Payment_Method_Bool_Exp>>;
  customerBillingSettings?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
  customerBillingSettings_aggregate?: InputMaybe<Customer_Billing_Settings_Aggregate_Bool_Exp>;
  customerInvoices?: InputMaybe<Customer_Invoices_Bool_Exp>;
  customerInvoices_aggregate?: InputMaybe<Customer_Invoices_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Customer_Payment_Method_Enum {
  BankTransfer = 'BANK_TRANSFER',
  Cash = 'CASH'
}

/** Boolean expression to compare columns of type "customer_payment_method_enum". All fields are combined with logical 'AND'. */
export type Customer_Payment_Method_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Customer_Payment_Method_Enum>;
  _in?: InputMaybe<Array<Customer_Payment_Method_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Customer_Payment_Method_Enum>;
  _nin?: InputMaybe<Array<Customer_Payment_Method_Enum>>;
};

/** aggregate max on columns */
export type Customer_Payment_Method_Max_Fields = {
  __typename: 'customer_payment_method_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Payment_Method_Min_Fields = {
  __typename: 'customer_payment_method_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "customer_payment_method". */
export type Customer_Payment_Method_Order_By = {
  customerBillingSettings_aggregate?: InputMaybe<Customer_Billing_Settings_Aggregate_Order_By>;
  customerInvoices_aggregate?: InputMaybe<Customer_Invoices_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "customer_payment_method" */
export enum Customer_Payment_Method_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "customer_payment_method" */
export type Customer_Payment_Method_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Payment_Method_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Payment_Method_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

export type Customer_Payment_Term_Suggestions_Args = {
  _customer_payment_method?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "customer_payment_terms" */
export type Customer_Payment_Terms = {
  __typename: 'customer_payment_terms';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  customerBillingSettings: Array<Customer_Billing_Settings>;
  /** An aggregate relationship */
  customerBillingSettings_aggregate: Customer_Billing_Settings_Aggregate;
  /** An array relationship */
  customerInvoices: Array<Customer_Invoices>;
  /** An aggregate relationship */
  customerInvoices_aggregate: Customer_Invoices_Aggregate;
  discountPercentage: Scalars['numeric'];
  i18n: Scalars['jsonb'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  ordinalPosition: Scalars['Int'];
  paymentDueInDays: Scalars['Int'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "customer_payment_terms" */
export type Customer_Payment_TermsCustomerBillingSettingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Settings_Order_By>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};


/** columns and relationships of "customer_payment_terms" */
export type Customer_Payment_TermsCustomerBillingSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Settings_Order_By>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};


/** columns and relationships of "customer_payment_terms" */
export type Customer_Payment_TermsCustomerInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoices_Order_By>>;
  where?: InputMaybe<Customer_Invoices_Bool_Exp>;
};


/** columns and relationships of "customer_payment_terms" */
export type Customer_Payment_TermsCustomerInvoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoices_Order_By>>;
  where?: InputMaybe<Customer_Invoices_Bool_Exp>;
};


/** columns and relationships of "customer_payment_terms" */
export type Customer_Payment_TermsI18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "customer_payment_terms" */
export type Customer_Payment_Terms_Aggregate = {
  __typename: 'customer_payment_terms_aggregate';
  aggregate?: Maybe<Customer_Payment_Terms_Aggregate_Fields>;
  nodes: Array<Customer_Payment_Terms>;
};

export type Customer_Payment_Terms_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Payment_Terms_Aggregate_Bool_Exp_Count>;
};

export type Customer_Payment_Terms_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Payment_Terms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Payment_Terms_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_payment_terms" */
export type Customer_Payment_Terms_Aggregate_Fields = {
  __typename: 'customer_payment_terms_aggregate_fields';
  avg?: Maybe<Customer_Payment_Terms_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Payment_Terms_Max_Fields>;
  min?: Maybe<Customer_Payment_Terms_Min_Fields>;
  stddev?: Maybe<Customer_Payment_Terms_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Payment_Terms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Payment_Terms_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Payment_Terms_Sum_Fields>;
  var_pop?: Maybe<Customer_Payment_Terms_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Payment_Terms_Var_Samp_Fields>;
  variance?: Maybe<Customer_Payment_Terms_Variance_Fields>;
};


/** aggregate fields of "customer_payment_terms" */
export type Customer_Payment_Terms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Payment_Terms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_payment_terms" */
export type Customer_Payment_Terms_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Payment_Terms_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Payment_Terms_Max_Order_By>;
  min?: InputMaybe<Customer_Payment_Terms_Min_Order_By>;
  stddev?: InputMaybe<Customer_Payment_Terms_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Payment_Terms_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Payment_Terms_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Payment_Terms_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Payment_Terms_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Payment_Terms_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Payment_Terms_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Customer_Payment_Terms_Append_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Customer_Payment_Terms_Avg_Fields = {
  __typename: 'customer_payment_terms_avg_fields';
  discountPercentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  paymentDueInDays?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_payment_terms" */
export type Customer_Payment_Terms_Avg_Order_By = {
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  paymentDueInDays?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_payment_terms". All fields are combined with a logical 'AND'. */
export type Customer_Payment_Terms_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Payment_Terms_Bool_Exp>>;
  _not?: InputMaybe<Customer_Payment_Terms_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Payment_Terms_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerBillingSettings?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
  customerBillingSettings_aggregate?: InputMaybe<Customer_Billing_Settings_Aggregate_Bool_Exp>;
  customerInvoices?: InputMaybe<Customer_Invoices_Bool_Exp>;
  customerInvoices_aggregate?: InputMaybe<Customer_Invoices_Aggregate_Bool_Exp>;
  discountPercentage?: InputMaybe<Numeric_Comparison_Exp>;
  i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  paymentDueInDays?: InputMaybe<Int_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_payment_terms" */
export enum Customer_Payment_Terms_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerPaymentTermsPkey = 'customer_payment_terms_pkey',
  /** unique or primary key constraint on columns "name", "tenant" */
  CustomerPaymentTermsTenantNameKey = 'customer_payment_terms_tenant_name_key',
  /** unique or primary key constraint on columns "tenant", "ordinal_position" */
  CustomerPaymentTermsTenantOrdinalPositionKey = 'customer_payment_terms_tenant_ordinal_position_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Customer_Payment_Terms_Delete_At_Path_Input = {
  i18n?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Customer_Payment_Terms_Delete_Elem_Input = {
  i18n?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Customer_Payment_Terms_Delete_Key_Input = {
  i18n?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "customer_payment_terms" */
export type Customer_Payment_Terms_Inc_Input = {
  discountPercentage?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  paymentDueInDays?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_payment_terms" */
export type Customer_Payment_Terms_Insert_Input = {
  customerBillingSettings?: InputMaybe<Customer_Billing_Settings_Arr_Rel_Insert_Input>;
  discountPercentage?: InputMaybe<Scalars['numeric']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  paymentDueInDays?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Customer_Payment_Terms_Max_Fields = {
  __typename: 'customer_payment_terms_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  discountPercentage?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  paymentDueInDays?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_payment_terms" */
export type Customer_Payment_Terms_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  paymentDueInDays?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Payment_Terms_Min_Fields = {
  __typename: 'customer_payment_terms_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  discountPercentage?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  paymentDueInDays?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_payment_terms" */
export type Customer_Payment_Terms_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  paymentDueInDays?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_payment_terms" */
export type Customer_Payment_Terms_Mutation_Response = {
  __typename: 'customer_payment_terms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Payment_Terms>;
};

/** input type for inserting object relation for remote table "customer_payment_terms" */
export type Customer_Payment_Terms_Obj_Rel_Insert_Input = {
  data: Customer_Payment_Terms_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Payment_Terms_On_Conflict>;
};

/** on_conflict condition type for table "customer_payment_terms" */
export type Customer_Payment_Terms_On_Conflict = {
  constraint: Customer_Payment_Terms_Constraint;
  update_columns?: Array<Customer_Payment_Terms_Update_Column>;
  where?: InputMaybe<Customer_Payment_Terms_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_payment_terms". */
export type Customer_Payment_Terms_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerBillingSettings_aggregate?: InputMaybe<Customer_Billing_Settings_Aggregate_Order_By>;
  customerInvoices_aggregate?: InputMaybe<Customer_Invoices_Aggregate_Order_By>;
  discountPercentage?: InputMaybe<Order_By>;
  i18n?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  paymentDueInDays?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_payment_terms */
export type Customer_Payment_Terms_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Customer_Payment_Terms_Prepend_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "customer_payment_terms" */
export enum Customer_Payment_Terms_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DiscountPercentage = 'discountPercentage',
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  PaymentDueInDays = 'paymentDueInDays',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "customer_payment_terms" */
export type Customer_Payment_Terms_Set_Input = {
  discountPercentage?: InputMaybe<Scalars['numeric']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  paymentDueInDays?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Customer_Payment_Terms_Stddev_Fields = {
  __typename: 'customer_payment_terms_stddev_fields';
  discountPercentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  paymentDueInDays?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_payment_terms" */
export type Customer_Payment_Terms_Stddev_Order_By = {
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  paymentDueInDays?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Payment_Terms_Stddev_Pop_Fields = {
  __typename: 'customer_payment_terms_stddev_pop_fields';
  discountPercentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  paymentDueInDays?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_payment_terms" */
export type Customer_Payment_Terms_Stddev_Pop_Order_By = {
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  paymentDueInDays?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Payment_Terms_Stddev_Samp_Fields = {
  __typename: 'customer_payment_terms_stddev_samp_fields';
  discountPercentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  paymentDueInDays?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_payment_terms" */
export type Customer_Payment_Terms_Stddev_Samp_Order_By = {
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  paymentDueInDays?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_payment_terms" */
export type Customer_Payment_Terms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Payment_Terms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Payment_Terms_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  discountPercentage?: InputMaybe<Scalars['numeric']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  paymentDueInDays?: InputMaybe<Scalars['Int']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Payment_Terms_Sum_Fields = {
  __typename: 'customer_payment_terms_sum_fields';
  discountPercentage?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  paymentDueInDays?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_payment_terms" */
export type Customer_Payment_Terms_Sum_Order_By = {
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  paymentDueInDays?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "customer_payment_terms" */
export enum Customer_Payment_Terms_Update_Column {
  /** column name */
  DiscountPercentage = 'discountPercentage',
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  PaymentDueInDays = 'paymentDueInDays',
  /** column name */
  Version = 'version'
}

export type Customer_Payment_Terms_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Customer_Payment_Terms_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Customer_Payment_Terms_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Customer_Payment_Terms_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Customer_Payment_Terms_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Payment_Terms_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Customer_Payment_Terms_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Payment_Terms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Payment_Terms_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Payment_Terms_Var_Pop_Fields = {
  __typename: 'customer_payment_terms_var_pop_fields';
  discountPercentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  paymentDueInDays?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_payment_terms" */
export type Customer_Payment_Terms_Var_Pop_Order_By = {
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  paymentDueInDays?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Payment_Terms_Var_Samp_Fields = {
  __typename: 'customer_payment_terms_var_samp_fields';
  discountPercentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  paymentDueInDays?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_payment_terms" */
export type Customer_Payment_Terms_Var_Samp_Order_By = {
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  paymentDueInDays?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Payment_Terms_Variance_Fields = {
  __typename: 'customer_payment_terms_variance_fields';
  discountPercentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  paymentDueInDays?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_payment_terms" */
export type Customer_Payment_Terms_Variance_Order_By = {
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  paymentDueInDays?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_product_availabilities" */
export type Customer_Product_Availabilities = {
  __typename: 'customer_product_availabilities';
  /** An object relationship */
  Tenant: Tenants;
  bizerbaTemplateId?: Maybe<Scalars['bigint']>;
  bizerbaTemplateValues: Scalars['jsonb'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customer?: Maybe<Customers>;
  /** An object relationship */
  customerGroup?: Maybe<Customer_Groups>;
  customerGroupId?: Maybe<Scalars['bigint']>;
  customerId?: Maybe<Scalars['bigint']>;
  defaultCustomerNote?: Maybe<Scalars['String']>;
  defaultProductionNote?: Maybe<Scalars['String']>;
  externalProductId?: Maybe<Scalars['String']>;
  gtin?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  /** An array relationship */
  productPackagingConfigurations: Array<Product_Packaging_Configurations>;
  /** An aggregate relationship */
  productPackagingConfigurations_aggregate: Product_Packaging_Configurations_Aggregate;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "customer_product_availabilities" */
export type Customer_Product_AvailabilitiesBizerbaTemplateValuesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "customer_product_availabilities" */
export type Customer_Product_AvailabilitiesProductPackagingConfigurationsArgs = {
  distinct_on?: InputMaybe<Array<Product_Packaging_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Packaging_Configurations_Order_By>>;
  where?: InputMaybe<Product_Packaging_Configurations_Bool_Exp>;
};


/** columns and relationships of "customer_product_availabilities" */
export type Customer_Product_AvailabilitiesProductPackagingConfigurations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Packaging_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Packaging_Configurations_Order_By>>;
  where?: InputMaybe<Product_Packaging_Configurations_Bool_Exp>;
};

/** aggregated selection of "customer_product_availabilities" */
export type Customer_Product_Availabilities_Aggregate = {
  __typename: 'customer_product_availabilities_aggregate';
  aggregate?: Maybe<Customer_Product_Availabilities_Aggregate_Fields>;
  nodes: Array<Customer_Product_Availabilities>;
};

export type Customer_Product_Availabilities_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Product_Availabilities_Aggregate_Bool_Exp_Count>;
};

export type Customer_Product_Availabilities_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Product_Availabilities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_product_availabilities" */
export type Customer_Product_Availabilities_Aggregate_Fields = {
  __typename: 'customer_product_availabilities_aggregate_fields';
  avg?: Maybe<Customer_Product_Availabilities_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Product_Availabilities_Max_Fields>;
  min?: Maybe<Customer_Product_Availabilities_Min_Fields>;
  stddev?: Maybe<Customer_Product_Availabilities_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Product_Availabilities_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Product_Availabilities_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Product_Availabilities_Sum_Fields>;
  var_pop?: Maybe<Customer_Product_Availabilities_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Product_Availabilities_Var_Samp_Fields>;
  variance?: Maybe<Customer_Product_Availabilities_Variance_Fields>;
};


/** aggregate fields of "customer_product_availabilities" */
export type Customer_Product_Availabilities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Product_Availabilities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_product_availabilities" */
export type Customer_Product_Availabilities_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Product_Availabilities_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Product_Availabilities_Max_Order_By>;
  min?: InputMaybe<Customer_Product_Availabilities_Min_Order_By>;
  stddev?: InputMaybe<Customer_Product_Availabilities_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Product_Availabilities_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Product_Availabilities_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Product_Availabilities_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Product_Availabilities_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Product_Availabilities_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Product_Availabilities_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Customer_Product_Availabilities_Append_Input = {
  bizerbaTemplateValues?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "customer_product_availabilities" */
export type Customer_Product_Availabilities_Arr_Rel_Insert_Input = {
  data: Array<Customer_Product_Availabilities_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Product_Availabilities_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Product_Availabilities_Avg_Fields = {
  __typename: 'customer_product_availabilities_avg_fields';
  bizerbaTemplateId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  gtin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_product_availabilities" */
export type Customer_Product_Availabilities_Avg_Order_By = {
  bizerbaTemplateId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_product_availabilities". All fields are combined with a logical 'AND'. */
export type Customer_Product_Availabilities_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Product_Availabilities_Bool_Exp>>;
  _not?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Product_Availabilities_Bool_Exp>>;
  bizerbaTemplateId?: InputMaybe<Bigint_Comparison_Exp>;
  bizerbaTemplateValues?: InputMaybe<Jsonb_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer?: InputMaybe<Customers_Bool_Exp>;
  customerGroup?: InputMaybe<Customer_Groups_Bool_Exp>;
  customerGroupId?: InputMaybe<Bigint_Comparison_Exp>;
  customerId?: InputMaybe<Bigint_Comparison_Exp>;
  defaultCustomerNote?: InputMaybe<String_Comparison_Exp>;
  defaultProductionNote?: InputMaybe<String_Comparison_Exp>;
  externalProductId?: InputMaybe<String_Comparison_Exp>;
  gtin?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  productPackagingConfigurations?: InputMaybe<Product_Packaging_Configurations_Bool_Exp>;
  productPackagingConfigurations_aggregate?: InputMaybe<Product_Packaging_Configurations_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_product_availabilities" */
export enum Customer_Product_Availabilities_Constraint {
  /** unique or primary key constraint on columns "customer_group_id", "external_product_id" */
  CpaCgExternalProductIdUniq = 'cpa_cg_external_product_id_uniq',
  /** unique or primary key constraint on columns "customer_id", "external_product_id" */
  CustomerProductAvailabilitiCustomerIdExternalProductKey = 'customer_product_availabiliti_customer_id_external_product__key',
  /** unique or primary key constraint on columns "customer_group_id", "product_id" */
  CustomerProductAvailabilitieCustomerGroupIdProductIdIdx = 'customer_product_availabilitie_customer_group_id_product_id_idx',
  /** unique or primary key constraint on columns "customer_group_id", "product_id" */
  CustomerProductAvailabilitieCustomerGroupIdProductIdKey = 'customer_product_availabilitie_customer_group_id_product_id_key',
  /** unique or primary key constraint on columns "product_id", "customer_id" */
  CustomerProductAvailabilitiesCustomerIdProductIdIdx = 'customer_product_availabilities_customer_id_product_id_idx',
  /** unique or primary key constraint on columns "product_id", "customer_id" */
  CustomerProductAvailabilitiesCustomerIdProductIdKey = 'customer_product_availabilities_customer_id_product_id_key',
  /** unique or primary key constraint on columns "id" */
  CustomerProductAvailabilitiesPkey = 'customer_product_availabilities_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Customer_Product_Availabilities_Delete_At_Path_Input = {
  bizerbaTemplateValues?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Customer_Product_Availabilities_Delete_Elem_Input = {
  bizerbaTemplateValues?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Customer_Product_Availabilities_Delete_Key_Input = {
  bizerbaTemplateValues?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "customer_product_availabilities" */
export type Customer_Product_Availabilities_Inc_Input = {
  bizerbaTemplateId?: InputMaybe<Scalars['bigint']>;
  customerGroupId?: InputMaybe<Scalars['bigint']>;
  customerId?: InputMaybe<Scalars['bigint']>;
  gtin?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_product_availabilities" */
export type Customer_Product_Availabilities_Insert_Input = {
  bizerbaTemplateId?: InputMaybe<Scalars['bigint']>;
  bizerbaTemplateValues?: InputMaybe<Scalars['jsonb']>;
  customer?: InputMaybe<Customers_Obj_Rel_Insert_Input>;
  customerGroup?: InputMaybe<Customer_Groups_Obj_Rel_Insert_Input>;
  customerGroupId?: InputMaybe<Scalars['bigint']>;
  customerId?: InputMaybe<Scalars['bigint']>;
  defaultCustomerNote?: InputMaybe<Scalars['String']>;
  defaultProductionNote?: InputMaybe<Scalars['String']>;
  externalProductId?: InputMaybe<Scalars['String']>;
  gtin?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  productPackagingConfigurations?: InputMaybe<Product_Packaging_Configurations_Arr_Rel_Insert_Input>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Customer_Product_Availabilities_Max_Fields = {
  __typename: 'customer_product_availabilities_max_fields';
  bizerbaTemplateId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerGroupId?: Maybe<Scalars['bigint']>;
  customerId?: Maybe<Scalars['bigint']>;
  defaultCustomerNote?: Maybe<Scalars['String']>;
  defaultProductionNote?: Maybe<Scalars['String']>;
  externalProductId?: Maybe<Scalars['String']>;
  gtin?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_product_availabilities" */
export type Customer_Product_Availabilities_Max_Order_By = {
  bizerbaTemplateId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  defaultCustomerNote?: InputMaybe<Order_By>;
  defaultProductionNote?: InputMaybe<Order_By>;
  externalProductId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Product_Availabilities_Min_Fields = {
  __typename: 'customer_product_availabilities_min_fields';
  bizerbaTemplateId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerGroupId?: Maybe<Scalars['bigint']>;
  customerId?: Maybe<Scalars['bigint']>;
  defaultCustomerNote?: Maybe<Scalars['String']>;
  defaultProductionNote?: Maybe<Scalars['String']>;
  externalProductId?: Maybe<Scalars['String']>;
  gtin?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_product_availabilities" */
export type Customer_Product_Availabilities_Min_Order_By = {
  bizerbaTemplateId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  defaultCustomerNote?: InputMaybe<Order_By>;
  defaultProductionNote?: InputMaybe<Order_By>;
  externalProductId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_product_availabilities" */
export type Customer_Product_Availabilities_Mutation_Response = {
  __typename: 'customer_product_availabilities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Product_Availabilities>;
};

/** input type for inserting object relation for remote table "customer_product_availabilities" */
export type Customer_Product_Availabilities_Obj_Rel_Insert_Input = {
  data: Customer_Product_Availabilities_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Product_Availabilities_On_Conflict>;
};

/** on_conflict condition type for table "customer_product_availabilities" */
export type Customer_Product_Availabilities_On_Conflict = {
  constraint: Customer_Product_Availabilities_Constraint;
  update_columns?: Array<Customer_Product_Availabilities_Update_Column>;
  where?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_product_availabilities". */
export type Customer_Product_Availabilities_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  bizerbaTemplateId?: InputMaybe<Order_By>;
  bizerbaTemplateValues?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customer?: InputMaybe<Customers_Order_By>;
  customerGroup?: InputMaybe<Customer_Groups_Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  defaultCustomerNote?: InputMaybe<Order_By>;
  defaultProductionNote?: InputMaybe<Order_By>;
  externalProductId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  productPackagingConfigurations_aggregate?: InputMaybe<Product_Packaging_Configurations_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_product_availabilities */
export type Customer_Product_Availabilities_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Customer_Product_Availabilities_Prepend_Input = {
  bizerbaTemplateValues?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "customer_product_availabilities" */
export enum Customer_Product_Availabilities_Select_Column {
  /** column name */
  BizerbaTemplateId = 'bizerbaTemplateId',
  /** column name */
  BizerbaTemplateValues = 'bizerbaTemplateValues',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerGroupId = 'customerGroupId',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  DefaultCustomerNote = 'defaultCustomerNote',
  /** column name */
  DefaultProductionNote = 'defaultProductionNote',
  /** column name */
  ExternalProductId = 'externalProductId',
  /** column name */
  Gtin = 'gtin',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "customer_product_availabilities" */
export type Customer_Product_Availabilities_Set_Input = {
  bizerbaTemplateId?: InputMaybe<Scalars['bigint']>;
  bizerbaTemplateValues?: InputMaybe<Scalars['jsonb']>;
  customerGroupId?: InputMaybe<Scalars['bigint']>;
  customerId?: InputMaybe<Scalars['bigint']>;
  defaultCustomerNote?: InputMaybe<Scalars['String']>;
  defaultProductionNote?: InputMaybe<Scalars['String']>;
  externalProductId?: InputMaybe<Scalars['String']>;
  gtin?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Customer_Product_Availabilities_Stddev_Fields = {
  __typename: 'customer_product_availabilities_stddev_fields';
  bizerbaTemplateId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  gtin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_product_availabilities" */
export type Customer_Product_Availabilities_Stddev_Order_By = {
  bizerbaTemplateId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Product_Availabilities_Stddev_Pop_Fields = {
  __typename: 'customer_product_availabilities_stddev_pop_fields';
  bizerbaTemplateId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  gtin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_product_availabilities" */
export type Customer_Product_Availabilities_Stddev_Pop_Order_By = {
  bizerbaTemplateId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Product_Availabilities_Stddev_Samp_Fields = {
  __typename: 'customer_product_availabilities_stddev_samp_fields';
  bizerbaTemplateId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  gtin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_product_availabilities" */
export type Customer_Product_Availabilities_Stddev_Samp_Order_By = {
  bizerbaTemplateId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_product_availabilities" */
export type Customer_Product_Availabilities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Product_Availabilities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Product_Availabilities_Stream_Cursor_Value_Input = {
  bizerbaTemplateId?: InputMaybe<Scalars['bigint']>;
  bizerbaTemplateValues?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerGroupId?: InputMaybe<Scalars['bigint']>;
  customerId?: InputMaybe<Scalars['bigint']>;
  defaultCustomerNote?: InputMaybe<Scalars['String']>;
  defaultProductionNote?: InputMaybe<Scalars['String']>;
  externalProductId?: InputMaybe<Scalars['String']>;
  gtin?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Product_Availabilities_Sum_Fields = {
  __typename: 'customer_product_availabilities_sum_fields';
  bizerbaTemplateId?: Maybe<Scalars['bigint']>;
  customerGroupId?: Maybe<Scalars['bigint']>;
  customerId?: Maybe<Scalars['bigint']>;
  gtin?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_product_availabilities" */
export type Customer_Product_Availabilities_Sum_Order_By = {
  bizerbaTemplateId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "customer_product_availabilities" */
export enum Customer_Product_Availabilities_Update_Column {
  /** column name */
  BizerbaTemplateId = 'bizerbaTemplateId',
  /** column name */
  BizerbaTemplateValues = 'bizerbaTemplateValues',
  /** column name */
  CustomerGroupId = 'customerGroupId',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  DefaultCustomerNote = 'defaultCustomerNote',
  /** column name */
  DefaultProductionNote = 'defaultProductionNote',
  /** column name */
  ExternalProductId = 'externalProductId',
  /** column name */
  Gtin = 'gtin',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Version = 'version'
}

export type Customer_Product_Availabilities_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Customer_Product_Availabilities_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Customer_Product_Availabilities_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Customer_Product_Availabilities_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Customer_Product_Availabilities_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Product_Availabilities_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Customer_Product_Availabilities_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Product_Availabilities_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Product_Availabilities_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Product_Availabilities_Var_Pop_Fields = {
  __typename: 'customer_product_availabilities_var_pop_fields';
  bizerbaTemplateId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  gtin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_product_availabilities" */
export type Customer_Product_Availabilities_Var_Pop_Order_By = {
  bizerbaTemplateId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Product_Availabilities_Var_Samp_Fields = {
  __typename: 'customer_product_availabilities_var_samp_fields';
  bizerbaTemplateId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  gtin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_product_availabilities" */
export type Customer_Product_Availabilities_Var_Samp_Order_By = {
  bizerbaTemplateId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Product_Availabilities_Variance_Fields = {
  __typename: 'customer_product_availabilities_variance_fields';
  bizerbaTemplateId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  gtin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_product_availabilities" */
export type Customer_Product_Availabilities_Variance_Order_By = {
  bizerbaTemplateId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer_state_transitions" */
export type Customer_State_Transitions = {
  __typename: 'customer_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Customer_States_Enum;
  toState: Customer_States_Enum;
};

/** aggregated selection of "customer_state_transitions" */
export type Customer_State_Transitions_Aggregate = {
  __typename: 'customer_state_transitions_aggregate';
  aggregate?: Maybe<Customer_State_Transitions_Aggregate_Fields>;
  nodes: Array<Customer_State_Transitions>;
};

/** aggregate fields of "customer_state_transitions" */
export type Customer_State_Transitions_Aggregate_Fields = {
  __typename: 'customer_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_State_Transitions_Max_Fields>;
  min?: Maybe<Customer_State_Transitions_Min_Fields>;
};


/** aggregate fields of "customer_state_transitions" */
export type Customer_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_state_transitions". All fields are combined with a logical 'AND'. */
export type Customer_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Customer_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Customer_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Customer_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Customer_State_Transitions_Max_Fields = {
  __typename: 'customer_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_State_Transitions_Min_Fields = {
  __typename: 'customer_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "customer_state_transitions". */
export type Customer_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "customer_state_transitions" */
export enum Customer_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "customer_state_transitions" */
export type Customer_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Customer_States_Enum>;
  toState?: InputMaybe<Customer_States_Enum>;
};

/** columns and relationships of "customer_states" */
export type Customer_States = {
  __typename: 'customer_states';
  /** An array relationship */
  customers: Array<Customers>;
  /** An aggregate relationship */
  customers_aggregate: Customers_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "customer_states" */
export type Customer_StatesCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "customer_states" */
export type Customer_StatesCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};

/** aggregated selection of "customer_states" */
export type Customer_States_Aggregate = {
  __typename: 'customer_states_aggregate';
  aggregate?: Maybe<Customer_States_Aggregate_Fields>;
  nodes: Array<Customer_States>;
};

/** aggregate fields of "customer_states" */
export type Customer_States_Aggregate_Fields = {
  __typename: 'customer_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_States_Max_Fields>;
  min?: Maybe<Customer_States_Min_Fields>;
};


/** aggregate fields of "customer_states" */
export type Customer_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_states". All fields are combined with a logical 'AND'. */
export type Customer_States_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_States_Bool_Exp>>;
  _not?: InputMaybe<Customer_States_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_States_Bool_Exp>>;
  customers?: InputMaybe<Customers_Bool_Exp>;
  customers_aggregate?: InputMaybe<Customers_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Customer_States_Enum {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE'
}

/** Boolean expression to compare columns of type "customer_states_enum". All fields are combined with logical 'AND'. */
export type Customer_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Customer_States_Enum>;
  _in?: InputMaybe<Array<Customer_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Customer_States_Enum>;
  _nin?: InputMaybe<Array<Customer_States_Enum>>;
};

/** aggregate max on columns */
export type Customer_States_Max_Fields = {
  __typename: 'customer_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_States_Min_Fields = {
  __typename: 'customer_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "customer_states". */
export type Customer_States_Order_By = {
  customers_aggregate?: InputMaybe<Customers_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "customer_states" */
export enum Customer_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "customer_states" */
export type Customer_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "customer_template_settings" */
export type Customer_Template_Settings = {
  __typename: 'customer_template_settings';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  customers: Array<Customers>;
  /** An aggregate relationship */
  customers_aggregate: Customers_Aggregate;
  /** An object relationship */
  ediDeliveryNoteType?: Maybe<Edi_Delivery_Note_Types>;
  ediDeliveryNoteTypeId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  ediInvoiceType?: Maybe<Edi_Invoice_Types>;
  ediInvoiceTypeId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  gs1LogisticType?: Maybe<Gs1_Logistic_Types>;
  gs1LogisticTypeId?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  notes?: Maybe<Scalars['String']>;
  preferOwnIdToParent: Scalars['Boolean'];
  showDnaTestPricePerKg?: Maybe<Scalars['Boolean']>;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "customer_template_settings" */
export type Customer_Template_SettingsCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "customer_template_settings" */
export type Customer_Template_SettingsCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};

/** aggregated selection of "customer_template_settings" */
export type Customer_Template_Settings_Aggregate = {
  __typename: 'customer_template_settings_aggregate';
  aggregate?: Maybe<Customer_Template_Settings_Aggregate_Fields>;
  nodes: Array<Customer_Template_Settings>;
};

export type Customer_Template_Settings_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Customer_Template_Settings_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Customer_Template_Settings_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Customer_Template_Settings_Aggregate_Bool_Exp_Count>;
};

export type Customer_Template_Settings_Aggregate_Bool_Exp_Bool_And = {
  arguments: Customer_Template_Settings_Select_Column_Customer_Template_Settings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Template_Settings_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Customer_Template_Settings_Select_Column_Customer_Template_Settings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Template_Settings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Template_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer_template_settings" */
export type Customer_Template_Settings_Aggregate_Fields = {
  __typename: 'customer_template_settings_aggregate_fields';
  avg?: Maybe<Customer_Template_Settings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Template_Settings_Max_Fields>;
  min?: Maybe<Customer_Template_Settings_Min_Fields>;
  stddev?: Maybe<Customer_Template_Settings_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Template_Settings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Template_Settings_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Template_Settings_Sum_Fields>;
  var_pop?: Maybe<Customer_Template_Settings_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Template_Settings_Var_Samp_Fields>;
  variance?: Maybe<Customer_Template_Settings_Variance_Fields>;
};


/** aggregate fields of "customer_template_settings" */
export type Customer_Template_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Template_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_template_settings" */
export type Customer_Template_Settings_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Template_Settings_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Template_Settings_Max_Order_By>;
  min?: InputMaybe<Customer_Template_Settings_Min_Order_By>;
  stddev?: InputMaybe<Customer_Template_Settings_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Template_Settings_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Template_Settings_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Template_Settings_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Template_Settings_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Template_Settings_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Template_Settings_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_template_settings" */
export type Customer_Template_Settings_Arr_Rel_Insert_Input = {
  data: Array<Customer_Template_Settings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Template_Settings_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Template_Settings_Avg_Fields = {
  __typename: 'customer_template_settings_avg_fields';
  ediDeliveryNoteTypeId?: Maybe<Scalars['Float']>;
  ediInvoiceTypeId?: Maybe<Scalars['Float']>;
  gs1LogisticTypeId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_template_settings" */
export type Customer_Template_Settings_Avg_Order_By = {
  ediDeliveryNoteTypeId?: InputMaybe<Order_By>;
  ediInvoiceTypeId?: InputMaybe<Order_By>;
  gs1LogisticTypeId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_template_settings". All fields are combined with a logical 'AND'. */
export type Customer_Template_Settings_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Template_Settings_Bool_Exp>>;
  _not?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Template_Settings_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customers?: InputMaybe<Customers_Bool_Exp>;
  customers_aggregate?: InputMaybe<Customers_Aggregate_Bool_Exp>;
  ediDeliveryNoteType?: InputMaybe<Edi_Delivery_Note_Types_Bool_Exp>;
  ediDeliveryNoteTypeId?: InputMaybe<Bigint_Comparison_Exp>;
  ediInvoiceType?: InputMaybe<Edi_Invoice_Types_Bool_Exp>;
  ediInvoiceTypeId?: InputMaybe<Bigint_Comparison_Exp>;
  gs1LogisticType?: InputMaybe<Gs1_Logistic_Types_Bool_Exp>;
  gs1LogisticTypeId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  preferOwnIdToParent?: InputMaybe<Boolean_Comparison_Exp>;
  showDnaTestPricePerKg?: InputMaybe<Boolean_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_template_settings" */
export enum Customer_Template_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerTemplateSettingsPkey = 'customer_template_settings_pkey'
}

/** input type for incrementing numeric columns in table "customer_template_settings" */
export type Customer_Template_Settings_Inc_Input = {
  ediDeliveryNoteTypeId?: InputMaybe<Scalars['bigint']>;
  ediInvoiceTypeId?: InputMaybe<Scalars['bigint']>;
  gs1LogisticTypeId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customer_template_settings" */
export type Customer_Template_Settings_Insert_Input = {
  customers?: InputMaybe<Customers_Arr_Rel_Insert_Input>;
  ediDeliveryNoteTypeId?: InputMaybe<Scalars['bigint']>;
  ediInvoiceTypeId?: InputMaybe<Scalars['bigint']>;
  gs1LogisticType?: InputMaybe<Gs1_Logistic_Types_Obj_Rel_Insert_Input>;
  gs1LogisticTypeId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  preferOwnIdToParent?: InputMaybe<Scalars['Boolean']>;
  showDnaTestPricePerKg?: InputMaybe<Scalars['Boolean']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Customer_Template_Settings_Max_Fields = {
  __typename: 'customer_template_settings_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  ediDeliveryNoteTypeId?: Maybe<Scalars['bigint']>;
  ediInvoiceTypeId?: Maybe<Scalars['bigint']>;
  gs1LogisticTypeId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customer_template_settings" */
export type Customer_Template_Settings_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  ediDeliveryNoteTypeId?: InputMaybe<Order_By>;
  ediInvoiceTypeId?: InputMaybe<Order_By>;
  gs1LogisticTypeId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Template_Settings_Min_Fields = {
  __typename: 'customer_template_settings_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  ediDeliveryNoteTypeId?: Maybe<Scalars['bigint']>;
  ediInvoiceTypeId?: Maybe<Scalars['bigint']>;
  gs1LogisticTypeId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customer_template_settings" */
export type Customer_Template_Settings_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  ediDeliveryNoteTypeId?: InputMaybe<Order_By>;
  ediInvoiceTypeId?: InputMaybe<Order_By>;
  gs1LogisticTypeId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_template_settings" */
export type Customer_Template_Settings_Mutation_Response = {
  __typename: 'customer_template_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Template_Settings>;
};

/** input type for inserting object relation for remote table "customer_template_settings" */
export type Customer_Template_Settings_Obj_Rel_Insert_Input = {
  data: Customer_Template_Settings_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Template_Settings_On_Conflict>;
};

/** on_conflict condition type for table "customer_template_settings" */
export type Customer_Template_Settings_On_Conflict = {
  constraint: Customer_Template_Settings_Constraint;
  update_columns?: Array<Customer_Template_Settings_Update_Column>;
  where?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_template_settings". */
export type Customer_Template_Settings_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customers_aggregate?: InputMaybe<Customers_Aggregate_Order_By>;
  ediDeliveryNoteType?: InputMaybe<Edi_Delivery_Note_Types_Order_By>;
  ediDeliveryNoteTypeId?: InputMaybe<Order_By>;
  ediInvoiceType?: InputMaybe<Edi_Invoice_Types_Order_By>;
  ediInvoiceTypeId?: InputMaybe<Order_By>;
  gs1LogisticType?: InputMaybe<Gs1_Logistic_Types_Order_By>;
  gs1LogisticTypeId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  preferOwnIdToParent?: InputMaybe<Order_By>;
  showDnaTestPricePerKg?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_template_settings */
export type Customer_Template_Settings_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "customer_template_settings" */
export enum Customer_Template_Settings_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EdiDeliveryNoteTypeId = 'ediDeliveryNoteTypeId',
  /** column name */
  EdiInvoiceTypeId = 'ediInvoiceTypeId',
  /** column name */
  Gs1LogisticTypeId = 'gs1LogisticTypeId',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PreferOwnIdToParent = 'preferOwnIdToParent',
  /** column name */
  ShowDnaTestPricePerKg = 'showDnaTestPricePerKg',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** select "customer_template_settings_aggregate_bool_exp_bool_and_arguments_columns" columns of table "customer_template_settings" */
export enum Customer_Template_Settings_Select_Column_Customer_Template_Settings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  PreferOwnIdToParent = 'preferOwnIdToParent',
  /** column name */
  ShowDnaTestPricePerKg = 'showDnaTestPricePerKg'
}

/** select "customer_template_settings_aggregate_bool_exp_bool_or_arguments_columns" columns of table "customer_template_settings" */
export enum Customer_Template_Settings_Select_Column_Customer_Template_Settings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  PreferOwnIdToParent = 'preferOwnIdToParent',
  /** column name */
  ShowDnaTestPricePerKg = 'showDnaTestPricePerKg'
}

/** input type for updating data in table "customer_template_settings" */
export type Customer_Template_Settings_Set_Input = {
  ediDeliveryNoteTypeId?: InputMaybe<Scalars['bigint']>;
  ediInvoiceTypeId?: InputMaybe<Scalars['bigint']>;
  gs1LogisticTypeId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  preferOwnIdToParent?: InputMaybe<Scalars['Boolean']>;
  showDnaTestPricePerKg?: InputMaybe<Scalars['Boolean']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Customer_Template_Settings_Stddev_Fields = {
  __typename: 'customer_template_settings_stddev_fields';
  ediDeliveryNoteTypeId?: Maybe<Scalars['Float']>;
  ediInvoiceTypeId?: Maybe<Scalars['Float']>;
  gs1LogisticTypeId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_template_settings" */
export type Customer_Template_Settings_Stddev_Order_By = {
  ediDeliveryNoteTypeId?: InputMaybe<Order_By>;
  ediInvoiceTypeId?: InputMaybe<Order_By>;
  gs1LogisticTypeId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Template_Settings_Stddev_Pop_Fields = {
  __typename: 'customer_template_settings_stddev_pop_fields';
  ediDeliveryNoteTypeId?: Maybe<Scalars['Float']>;
  ediInvoiceTypeId?: Maybe<Scalars['Float']>;
  gs1LogisticTypeId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_template_settings" */
export type Customer_Template_Settings_Stddev_Pop_Order_By = {
  ediDeliveryNoteTypeId?: InputMaybe<Order_By>;
  ediInvoiceTypeId?: InputMaybe<Order_By>;
  gs1LogisticTypeId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Template_Settings_Stddev_Samp_Fields = {
  __typename: 'customer_template_settings_stddev_samp_fields';
  ediDeliveryNoteTypeId?: Maybe<Scalars['Float']>;
  ediInvoiceTypeId?: Maybe<Scalars['Float']>;
  gs1LogisticTypeId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_template_settings" */
export type Customer_Template_Settings_Stddev_Samp_Order_By = {
  ediDeliveryNoteTypeId?: InputMaybe<Order_By>;
  ediInvoiceTypeId?: InputMaybe<Order_By>;
  gs1LogisticTypeId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_template_settings" */
export type Customer_Template_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Template_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Template_Settings_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  ediDeliveryNoteTypeId?: InputMaybe<Scalars['bigint']>;
  ediInvoiceTypeId?: InputMaybe<Scalars['bigint']>;
  gs1LogisticTypeId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  preferOwnIdToParent?: InputMaybe<Scalars['Boolean']>;
  showDnaTestPricePerKg?: InputMaybe<Scalars['Boolean']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customer_Template_Settings_Sum_Fields = {
  __typename: 'customer_template_settings_sum_fields';
  ediDeliveryNoteTypeId?: Maybe<Scalars['bigint']>;
  ediInvoiceTypeId?: Maybe<Scalars['bigint']>;
  gs1LogisticTypeId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customer_template_settings" */
export type Customer_Template_Settings_Sum_Order_By = {
  ediDeliveryNoteTypeId?: InputMaybe<Order_By>;
  ediInvoiceTypeId?: InputMaybe<Order_By>;
  gs1LogisticTypeId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "customer_template_settings" */
export enum Customer_Template_Settings_Update_Column {
  /** column name */
  EdiDeliveryNoteTypeId = 'ediDeliveryNoteTypeId',
  /** column name */
  EdiInvoiceTypeId = 'ediInvoiceTypeId',
  /** column name */
  Gs1LogisticTypeId = 'gs1LogisticTypeId',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PreferOwnIdToParent = 'preferOwnIdToParent',
  /** column name */
  ShowDnaTestPricePerKg = 'showDnaTestPricePerKg',
  /** column name */
  Version = 'version'
}

export type Customer_Template_Settings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Template_Settings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Template_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Template_Settings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Template_Settings_Var_Pop_Fields = {
  __typename: 'customer_template_settings_var_pop_fields';
  ediDeliveryNoteTypeId?: Maybe<Scalars['Float']>;
  ediInvoiceTypeId?: Maybe<Scalars['Float']>;
  gs1LogisticTypeId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_template_settings" */
export type Customer_Template_Settings_Var_Pop_Order_By = {
  ediDeliveryNoteTypeId?: InputMaybe<Order_By>;
  ediInvoiceTypeId?: InputMaybe<Order_By>;
  gs1LogisticTypeId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Template_Settings_Var_Samp_Fields = {
  __typename: 'customer_template_settings_var_samp_fields';
  ediDeliveryNoteTypeId?: Maybe<Scalars['Float']>;
  ediInvoiceTypeId?: Maybe<Scalars['Float']>;
  gs1LogisticTypeId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_template_settings" */
export type Customer_Template_Settings_Var_Samp_Order_By = {
  ediDeliveryNoteTypeId?: InputMaybe<Order_By>;
  ediInvoiceTypeId?: InputMaybe<Order_By>;
  gs1LogisticTypeId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Template_Settings_Variance_Fields = {
  __typename: 'customer_template_settings_variance_fields';
  ediDeliveryNoteTypeId?: Maybe<Scalars['Float']>;
  ediInvoiceTypeId?: Maybe<Scalars['Float']>;
  gs1LogisticTypeId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_template_settings" */
export type Customer_Template_Settings_Variance_Order_By = {
  ediDeliveryNoteTypeId?: InputMaybe<Order_By>;
  ediInvoiceTypeId?: InputMaybe<Order_By>;
  gs1LogisticTypeId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "customers" */
export type Customers = {
  __typename: 'customers';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  accounts: Array<Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Accounts_Aggregate;
  /** An array relationship */
  aggregatedDemandPlanOutgoingGoodsResults: Array<Aggregated_Demand_Plan_Outgoing_Goods_Results>;
  /** An aggregate relationship */
  aggregatedDemandPlanOutgoingGoodsResults_aggregate: Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate;
  allowedTenants: Scalars['jsonb'];
  /** An object relationship */
  billingAddress?: Maybe<Customer_Addresses>;
  billingAddressId?: Maybe<Scalars['bigint']>;
  communicationLanguage: Languages_Enum;
  /** An array relationship */
  contacts: Array<Contacts>;
  /** An aggregate relationship */
  contacts_aggregate: Contacts_Aggregate;
  /** An object relationship */
  containerPoolProductGroup?: Maybe<Product_Groups>;
  containerPoolProductGroupId?: Maybe<Scalars['bigint']>;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  creditNotes: Array<Credit_Notes>;
  /** An aggregate relationship */
  creditNotes_aggregate: Credit_Notes_Aggregate;
  /** Returns a matching active temporal sales teams customers which matches the current date or the one specified. 0 or 1 records only. */
  currentTemporalSalesTeams?: Maybe<Array<Temporal_Sales_Teams_Customers>>;
  /** An object relationship */
  customerBillingSetting?: Maybe<Customer_Billing_Settings>;
  customerBillingSettingId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  customerDeliveryAvailabilities: Array<Customer_Delivery_Availability>;
  /** An aggregate relationship */
  customerDeliveryAvailabilities_aggregate: Customer_Delivery_Availability_Aggregate;
  /** An object relationship */
  customerGroup?: Maybe<Customer_Groups>;
  customerGroupId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  customerInvoices: Array<Customer_Invoices>;
  /** An aggregate relationship */
  customerInvoices_aggregate: Customer_Invoices_Aggregate;
  customerOrderNotes?: Maybe<Scalars['String']>;
  /** An array relationship */
  customerOrders: Array<Customer_Orders>;
  /** An aggregate relationship */
  customerOrders_aggregate: Customer_Orders_Aggregate;
  /** An object relationship */
  customerParent?: Maybe<Customers>;
  customerParentId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  customerProductAvailabilities: Array<Customer_Product_Availabilities>;
  /** An aggregate relationship */
  customerProductAvailabilities_aggregate: Customer_Product_Availabilities_Aggregate;
  /** An object relationship */
  customerTemplateSetting?: Maybe<Customer_Template_Settings>;
  customerTemplateSettingId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  customers: Array<Customers>;
  /** An aggregate relationship */
  customers_aggregate: Customers_Aggregate;
  /** An object relationship */
  defaultOrderStockLocation?: Maybe<Stock_Locations>;
  defaultOrderStockLocationId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  defaultStockLocation?: Maybe<Stock_Locations>;
  defaultStockLocationId?: Maybe<Scalars['bigint']>;
  deliveryNoteFormat: Customer_Delivery_Note_Formats_Enum;
  deliveryNotes?: Maybe<Scalars['String']>;
  deliveryTimeEarliest?: Maybe<Scalars['time']>;
  deliveryTimeLatest?: Maybe<Scalars['time']>;
  domainId: Scalars['String'];
  extRef?: Maybe<Scalars['String']>;
  gln?: Maybe<Scalars['bigint']>;
  /** Returns whether this customer is a parent of any other customers */
  hasChildrenCustomers?: Maybe<Scalars['Boolean']>;
  id: Scalars['bigint'];
  /** An array relationship */
  loggedActions: Array<Customer_Logged_Actions>;
  /** An aggregate relationship */
  loggedActions_aggregate: Customer_Logged_Actions_Aggregate;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  priceCustomerGroup?: Maybe<Customer_Groups>;
  priceCustomerGroupId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  shippingAddress?: Maybe<Customer_Addresses>;
  shippingAddressId?: Maybe<Scalars['bigint']>;
  state: Customer_States_Enum;
  /** An array relationship */
  stockTransactions: Array<Stock_Transactions>;
  /** An aggregate relationship */
  stockTransactions_aggregate: Stock_Transactions_Aggregate;
  /** An array relationship */
  temporalSalesTeamsCustomers: Array<Temporal_Sales_Teams_Customers>;
  /** An aggregate relationship */
  temporalSalesTeamsCustomers_aggregate: Temporal_Sales_Teams_Customers_Aggregate;
  tenant: Scalars['String'];
  /** Returns a matching active temporal sales teams customers which matches the current date or the one specified. 0 or 1 records only. */
  todaysTemporalSalesTeams?: Maybe<Array<Temporal_Sales_Teams_Customers>>;
  /** An array relationship */
  todos: Array<Todos>;
  /** An aggregate relationship */
  todos_aggregate: Todos_Aggregate;
  /** An array relationship */
  tourRoutes: Array<Tour_Routes>;
  /** An aggregate relationship */
  tourRoutes_aggregate: Tour_Routes_Aggregate;
  updatedAt: Scalars['timestamptz'];
  useBillingOfParent: Scalars['Boolean'];
  useDeliveryAddressForBilling?: Maybe<Scalars['Boolean']>;
  version: Scalars['bigint'];
};


/** columns and relationships of "customers" */
export type CustomersAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersAggregatedDemandPlanOutgoingGoodsResultsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersAggregatedDemandPlanOutgoingGoodsResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersAllowedTenantsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "customers" */
export type CustomersContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCreditNotesArgs = {
  distinct_on?: InputMaybe<Array<Credit_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Notes_Order_By>>;
  where?: InputMaybe<Credit_Notes_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCreditNotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Notes_Order_By>>;
  where?: InputMaybe<Credit_Notes_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCurrentTemporalSalesTeamsArgs = {
  args: CurrentTemporalSalesTeams_Customers_Args;
  distinct_on?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Order_By>>;
  where?: InputMaybe<Temporal_Sales_Teams_Customers_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCustomerDeliveryAvailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Delivery_Availability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Delivery_Availability_Order_By>>;
  where?: InputMaybe<Customer_Delivery_Availability_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCustomerDeliveryAvailabilities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Delivery_Availability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Delivery_Availability_Order_By>>;
  where?: InputMaybe<Customer_Delivery_Availability_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCustomerInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoices_Order_By>>;
  where?: InputMaybe<Customer_Invoices_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCustomerInvoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoices_Order_By>>;
  where?: InputMaybe<Customer_Invoices_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCustomerOrdersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCustomerOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCustomerProductAvailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Product_Availabilities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Product_Availabilities_Order_By>>;
  where?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCustomerProductAvailabilities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Product_Availabilities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Product_Availabilities_Order_By>>;
  where?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersStockTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersStockTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersTemporalSalesTeamsCustomersArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Order_By>>;
  where?: InputMaybe<Temporal_Sales_Teams_Customers_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersTemporalSalesTeamsCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Order_By>>;
  where?: InputMaybe<Temporal_Sales_Teams_Customers_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersTodaysTemporalSalesTeamsArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Order_By>>;
  where?: InputMaybe<Temporal_Sales_Teams_Customers_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersTodosArgs = {
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersTodos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersTourRoutesArgs = {
  distinct_on?: InputMaybe<Array<Tour_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tour_Routes_Order_By>>;
  where?: InputMaybe<Tour_Routes_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersTourRoutes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tour_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tour_Routes_Order_By>>;
  where?: InputMaybe<Tour_Routes_Bool_Exp>;
};

/** aggregated selection of "customers" */
export type Customers_Aggregate = {
  __typename: 'customers_aggregate';
  aggregate?: Maybe<Customers_Aggregate_Fields>;
  nodes: Array<Customers>;
};

export type Customers_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Customers_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Customers_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Customers_Aggregate_Bool_Exp_Count>;
};

export type Customers_Aggregate_Bool_Exp_Bool_And = {
  arguments: Customers_Select_Column_Customers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customers_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Customers_Select_Column_Customers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customers" */
export type Customers_Aggregate_Fields = {
  __typename: 'customers_aggregate_fields';
  avg?: Maybe<Customers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customers_Max_Fields>;
  min?: Maybe<Customers_Min_Fields>;
  stddev?: Maybe<Customers_Stddev_Fields>;
  stddev_pop?: Maybe<Customers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customers_Stddev_Samp_Fields>;
  sum?: Maybe<Customers_Sum_Fields>;
  var_pop?: Maybe<Customers_Var_Pop_Fields>;
  var_samp?: Maybe<Customers_Var_Samp_Fields>;
  variance?: Maybe<Customers_Variance_Fields>;
};


/** aggregate fields of "customers" */
export type Customers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customers" */
export type Customers_Aggregate_Order_By = {
  avg?: InputMaybe<Customers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customers_Max_Order_By>;
  min?: InputMaybe<Customers_Min_Order_By>;
  stddev?: InputMaybe<Customers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customers_Sum_Order_By>;
  var_pop?: InputMaybe<Customers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customers_Var_Samp_Order_By>;
  variance?: InputMaybe<Customers_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Customers_Append_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "customers" */
export type Customers_Arr_Rel_Insert_Input = {
  data: Array<Customers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customers_On_Conflict>;
};

/** aggregate avg on columns */
export type Customers_Avg_Fields = {
  __typename: 'customers_avg_fields';
  billingAddressId?: Maybe<Scalars['Float']>;
  containerPoolProductGroupId?: Maybe<Scalars['Float']>;
  customerBillingSettingId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerParentId?: Maybe<Scalars['Float']>;
  customerTemplateSettingId?: Maybe<Scalars['Float']>;
  defaultOrderStockLocationId?: Maybe<Scalars['Float']>;
  defaultStockLocationId?: Maybe<Scalars['Float']>;
  gln?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priceCustomerGroupId?: Maybe<Scalars['Float']>;
  shippingAddressId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customers" */
export type Customers_Avg_Order_By = {
  billingAddressId?: InputMaybe<Order_By>;
  containerPoolProductGroupId?: InputMaybe<Order_By>;
  customerBillingSettingId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerParentId?: InputMaybe<Order_By>;
  customerTemplateSettingId?: InputMaybe<Order_By>;
  defaultOrderStockLocationId?: InputMaybe<Order_By>;
  defaultStockLocationId?: InputMaybe<Order_By>;
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priceCustomerGroupId?: InputMaybe<Order_By>;
  shippingAddressId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customers". All fields are combined with a logical 'AND'. */
export type Customers_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Customers_Bool_Exp>>;
  _not?: InputMaybe<Customers_Bool_Exp>;
  _or?: InputMaybe<Array<Customers_Bool_Exp>>;
  accounts?: InputMaybe<Accounts_Bool_Exp>;
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Bool_Exp>;
  aggregatedDemandPlanOutgoingGoodsResults?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
  aggregatedDemandPlanOutgoingGoodsResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Bool_Exp>;
  allowedTenants?: InputMaybe<Jsonb_Comparison_Exp>;
  billingAddress?: InputMaybe<Customer_Addresses_Bool_Exp>;
  billingAddressId?: InputMaybe<Bigint_Comparison_Exp>;
  communicationLanguage?: InputMaybe<Languages_Enum_Comparison_Exp>;
  contacts?: InputMaybe<Contacts_Bool_Exp>;
  contacts_aggregate?: InputMaybe<Contacts_Aggregate_Bool_Exp>;
  containerPoolProductGroup?: InputMaybe<Product_Groups_Bool_Exp>;
  containerPoolProductGroupId?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  creditNotes?: InputMaybe<Credit_Notes_Bool_Exp>;
  creditNotes_aggregate?: InputMaybe<Credit_Notes_Aggregate_Bool_Exp>;
  customerBillingSetting?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
  customerBillingSettingId?: InputMaybe<Bigint_Comparison_Exp>;
  customerDeliveryAvailabilities?: InputMaybe<Customer_Delivery_Availability_Bool_Exp>;
  customerDeliveryAvailabilities_aggregate?: InputMaybe<Customer_Delivery_Availability_Aggregate_Bool_Exp>;
  customerGroup?: InputMaybe<Customer_Groups_Bool_Exp>;
  customerGroupId?: InputMaybe<Bigint_Comparison_Exp>;
  customerInvoices?: InputMaybe<Customer_Invoices_Bool_Exp>;
  customerInvoices_aggregate?: InputMaybe<Customer_Invoices_Aggregate_Bool_Exp>;
  customerOrderNotes?: InputMaybe<String_Comparison_Exp>;
  customerOrders?: InputMaybe<Customer_Orders_Bool_Exp>;
  customerOrders_aggregate?: InputMaybe<Customer_Orders_Aggregate_Bool_Exp>;
  customerParent?: InputMaybe<Customers_Bool_Exp>;
  customerParentId?: InputMaybe<Bigint_Comparison_Exp>;
  customerProductAvailabilities?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
  customerProductAvailabilities_aggregate?: InputMaybe<Customer_Product_Availabilities_Aggregate_Bool_Exp>;
  customerTemplateSetting?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
  customerTemplateSettingId?: InputMaybe<Bigint_Comparison_Exp>;
  customers?: InputMaybe<Customers_Bool_Exp>;
  customers_aggregate?: InputMaybe<Customers_Aggregate_Bool_Exp>;
  defaultOrderStockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  defaultOrderStockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  defaultStockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  defaultStockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  deliveryNoteFormat?: InputMaybe<Customer_Delivery_Note_Formats_Enum_Comparison_Exp>;
  deliveryNotes?: InputMaybe<String_Comparison_Exp>;
  deliveryTimeEarliest?: InputMaybe<Time_Comparison_Exp>;
  deliveryTimeLatest?: InputMaybe<Time_Comparison_Exp>;
  domainId?: InputMaybe<String_Comparison_Exp>;
  extRef?: InputMaybe<String_Comparison_Exp>;
  gln?: InputMaybe<Bigint_Comparison_Exp>;
  hasChildrenCustomers?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  loggedActions?: InputMaybe<Customer_Logged_Actions_Bool_Exp>;
  loggedActions_aggregate?: InputMaybe<Customer_Logged_Actions_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  priceCustomerGroup?: InputMaybe<Customer_Groups_Bool_Exp>;
  priceCustomerGroupId?: InputMaybe<Bigint_Comparison_Exp>;
  shippingAddress?: InputMaybe<Customer_Addresses_Bool_Exp>;
  shippingAddressId?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<Customer_States_Enum_Comparison_Exp>;
  stockTransactions?: InputMaybe<Stock_Transactions_Bool_Exp>;
  stockTransactions_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Bool_Exp>;
  temporalSalesTeamsCustomers?: InputMaybe<Temporal_Sales_Teams_Customers_Bool_Exp>;
  temporalSalesTeamsCustomers_aggregate?: InputMaybe<Temporal_Sales_Teams_Customers_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  todaysTemporalSalesTeams?: InputMaybe<Temporal_Sales_Teams_Customers_Bool_Exp>;
  todos?: InputMaybe<Todos_Bool_Exp>;
  todos_aggregate?: InputMaybe<Todos_Aggregate_Bool_Exp>;
  tourRoutes?: InputMaybe<Tour_Routes_Bool_Exp>;
  tourRoutes_aggregate?: InputMaybe<Tour_Routes_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  useBillingOfParent?: InputMaybe<Boolean_Comparison_Exp>;
  useDeliveryAddressForBilling?: InputMaybe<Boolean_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "customers" */
export enum Customers_Constraint {
  /** unique or primary key constraint on columns "name", "tenant" */
  CustomersName = 'customers_name',
  /** unique or primary key constraint on columns "id" */
  CustomersPkey = 'customers_pkey',
  /** unique or primary key constraint on columns "tenant", "domain_id" */
  CustomersTenantDomainId = 'customers_tenant_domain_id',
  /** unique or primary key constraint on columns "gln", "tenant" */
  CustomersTenantGlnUniq = 'customers_tenant_gln_uniq'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Customers_Delete_At_Path_Input = {
  allowedTenants?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Customers_Delete_Elem_Input = {
  allowedTenants?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Customers_Delete_Key_Input = {
  allowedTenants?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "customers" */
export type Customers_Inc_Input = {
  billingAddressId?: InputMaybe<Scalars['bigint']>;
  containerPoolProductGroupId?: InputMaybe<Scalars['bigint']>;
  customerBillingSettingId?: InputMaybe<Scalars['bigint']>;
  customerGroupId?: InputMaybe<Scalars['bigint']>;
  customerParentId?: InputMaybe<Scalars['bigint']>;
  customerTemplateSettingId?: InputMaybe<Scalars['bigint']>;
  defaultOrderStockLocationId?: InputMaybe<Scalars['bigint']>;
  defaultStockLocationId?: InputMaybe<Scalars['bigint']>;
  gln?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  priceCustomerGroupId?: InputMaybe<Scalars['bigint']>;
  shippingAddressId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "customers" */
export type Customers_Insert_Input = {
  accounts?: InputMaybe<Accounts_Arr_Rel_Insert_Input>;
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
  billingAddress?: InputMaybe<Customer_Addresses_Obj_Rel_Insert_Input>;
  billingAddressId?: InputMaybe<Scalars['bigint']>;
  communicationLanguage?: InputMaybe<Languages_Enum>;
  contacts?: InputMaybe<Contacts_Arr_Rel_Insert_Input>;
  containerPoolProductGroup?: InputMaybe<Product_Groups_Obj_Rel_Insert_Input>;
  containerPoolProductGroupId?: InputMaybe<Scalars['bigint']>;
  creditNotes?: InputMaybe<Credit_Notes_Arr_Rel_Insert_Input>;
  customerBillingSetting?: InputMaybe<Customer_Billing_Settings_Obj_Rel_Insert_Input>;
  customerBillingSettingId?: InputMaybe<Scalars['bigint']>;
  customerDeliveryAvailabilities?: InputMaybe<Customer_Delivery_Availability_Arr_Rel_Insert_Input>;
  customerGroup?: InputMaybe<Customer_Groups_Obj_Rel_Insert_Input>;
  customerGroupId?: InputMaybe<Scalars['bigint']>;
  customerOrderNotes?: InputMaybe<Scalars['String']>;
  customerOrders?: InputMaybe<Customer_Orders_Arr_Rel_Insert_Input>;
  customerParent?: InputMaybe<Customers_Obj_Rel_Insert_Input>;
  customerParentId?: InputMaybe<Scalars['bigint']>;
  customerProductAvailabilities?: InputMaybe<Customer_Product_Availabilities_Arr_Rel_Insert_Input>;
  customerTemplateSetting?: InputMaybe<Customer_Template_Settings_Obj_Rel_Insert_Input>;
  customerTemplateSettingId?: InputMaybe<Scalars['bigint']>;
  customers?: InputMaybe<Customers_Arr_Rel_Insert_Input>;
  defaultOrderStockLocation?: InputMaybe<Stock_Locations_Obj_Rel_Insert_Input>;
  defaultOrderStockLocationId?: InputMaybe<Scalars['bigint']>;
  defaultStockLocation?: InputMaybe<Stock_Locations_Obj_Rel_Insert_Input>;
  defaultStockLocationId?: InputMaybe<Scalars['bigint']>;
  deliveryNoteFormat?: InputMaybe<Customer_Delivery_Note_Formats_Enum>;
  deliveryNotes?: InputMaybe<Scalars['String']>;
  deliveryTimeEarliest?: InputMaybe<Scalars['time']>;
  deliveryTimeLatest?: InputMaybe<Scalars['time']>;
  domainId?: InputMaybe<Scalars['String']>;
  extRef?: InputMaybe<Scalars['String']>;
  gln?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  priceCustomerGroup?: InputMaybe<Customer_Groups_Obj_Rel_Insert_Input>;
  priceCustomerGroupId?: InputMaybe<Scalars['bigint']>;
  shippingAddress?: InputMaybe<Customer_Addresses_Obj_Rel_Insert_Input>;
  shippingAddressId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Customer_States_Enum>;
  stockTransactions?: InputMaybe<Stock_Transactions_Arr_Rel_Insert_Input>;
  temporalSalesTeamsCustomers?: InputMaybe<Temporal_Sales_Teams_Customers_Arr_Rel_Insert_Input>;
  todos?: InputMaybe<Todos_Arr_Rel_Insert_Input>;
  tourRoutes?: InputMaybe<Tour_Routes_Arr_Rel_Insert_Input>;
  useBillingOfParent?: InputMaybe<Scalars['Boolean']>;
  useDeliveryAddressForBilling?: InputMaybe<Scalars['Boolean']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Customers_Max_Fields = {
  __typename: 'customers_max_fields';
  billingAddressId?: Maybe<Scalars['bigint']>;
  containerPoolProductGroupId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerBillingSettingId?: Maybe<Scalars['bigint']>;
  customerGroupId?: Maybe<Scalars['bigint']>;
  customerOrderNotes?: Maybe<Scalars['String']>;
  customerParentId?: Maybe<Scalars['bigint']>;
  customerTemplateSettingId?: Maybe<Scalars['bigint']>;
  defaultOrderStockLocationId?: Maybe<Scalars['bigint']>;
  defaultStockLocationId?: Maybe<Scalars['bigint']>;
  deliveryNotes?: Maybe<Scalars['String']>;
  domainId?: Maybe<Scalars['String']>;
  extRef?: Maybe<Scalars['String']>;
  gln?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  priceCustomerGroupId?: Maybe<Scalars['bigint']>;
  shippingAddressId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "customers" */
export type Customers_Max_Order_By = {
  billingAddressId?: InputMaybe<Order_By>;
  containerPoolProductGroupId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerBillingSettingId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerOrderNotes?: InputMaybe<Order_By>;
  customerParentId?: InputMaybe<Order_By>;
  customerTemplateSettingId?: InputMaybe<Order_By>;
  defaultOrderStockLocationId?: InputMaybe<Order_By>;
  defaultStockLocationId?: InputMaybe<Order_By>;
  deliveryNotes?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  extRef?: InputMaybe<Order_By>;
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  priceCustomerGroupId?: InputMaybe<Order_By>;
  shippingAddressId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customers_Min_Fields = {
  __typename: 'customers_min_fields';
  billingAddressId?: Maybe<Scalars['bigint']>;
  containerPoolProductGroupId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerBillingSettingId?: Maybe<Scalars['bigint']>;
  customerGroupId?: Maybe<Scalars['bigint']>;
  customerOrderNotes?: Maybe<Scalars['String']>;
  customerParentId?: Maybe<Scalars['bigint']>;
  customerTemplateSettingId?: Maybe<Scalars['bigint']>;
  defaultOrderStockLocationId?: Maybe<Scalars['bigint']>;
  defaultStockLocationId?: Maybe<Scalars['bigint']>;
  deliveryNotes?: Maybe<Scalars['String']>;
  domainId?: Maybe<Scalars['String']>;
  extRef?: Maybe<Scalars['String']>;
  gln?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  priceCustomerGroupId?: Maybe<Scalars['bigint']>;
  shippingAddressId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "customers" */
export type Customers_Min_Order_By = {
  billingAddressId?: InputMaybe<Order_By>;
  containerPoolProductGroupId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerBillingSettingId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerOrderNotes?: InputMaybe<Order_By>;
  customerParentId?: InputMaybe<Order_By>;
  customerTemplateSettingId?: InputMaybe<Order_By>;
  defaultOrderStockLocationId?: InputMaybe<Order_By>;
  defaultStockLocationId?: InputMaybe<Order_By>;
  deliveryNotes?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  extRef?: InputMaybe<Order_By>;
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  priceCustomerGroupId?: InputMaybe<Order_By>;
  shippingAddressId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customers" */
export type Customers_Mutation_Response = {
  __typename: 'customers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customers>;
};

/** input type for inserting object relation for remote table "customers" */
export type Customers_Obj_Rel_Insert_Input = {
  data: Customers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Customers_On_Conflict>;
};

/** on_conflict condition type for table "customers" */
export type Customers_On_Conflict = {
  constraint: Customers_Constraint;
  update_columns?: Array<Customers_Update_Column>;
  where?: InputMaybe<Customers_Bool_Exp>;
};

/** Ordering options when selecting data from "customers". */
export type Customers_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Order_By>;
  aggregatedDemandPlanOutgoingGoodsResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Order_By>;
  allowedTenants?: InputMaybe<Order_By>;
  billingAddress?: InputMaybe<Customer_Addresses_Order_By>;
  billingAddressId?: InputMaybe<Order_By>;
  communicationLanguage?: InputMaybe<Order_By>;
  contacts_aggregate?: InputMaybe<Contacts_Aggregate_Order_By>;
  containerPoolProductGroup?: InputMaybe<Product_Groups_Order_By>;
  containerPoolProductGroupId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creditNotes_aggregate?: InputMaybe<Credit_Notes_Aggregate_Order_By>;
  customerBillingSetting?: InputMaybe<Customer_Billing_Settings_Order_By>;
  customerBillingSettingId?: InputMaybe<Order_By>;
  customerDeliveryAvailabilities_aggregate?: InputMaybe<Customer_Delivery_Availability_Aggregate_Order_By>;
  customerGroup?: InputMaybe<Customer_Groups_Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerInvoices_aggregate?: InputMaybe<Customer_Invoices_Aggregate_Order_By>;
  customerOrderNotes?: InputMaybe<Order_By>;
  customerOrders_aggregate?: InputMaybe<Customer_Orders_Aggregate_Order_By>;
  customerParent?: InputMaybe<Customers_Order_By>;
  customerParentId?: InputMaybe<Order_By>;
  customerProductAvailabilities_aggregate?: InputMaybe<Customer_Product_Availabilities_Aggregate_Order_By>;
  customerTemplateSetting?: InputMaybe<Customer_Template_Settings_Order_By>;
  customerTemplateSettingId?: InputMaybe<Order_By>;
  customers_aggregate?: InputMaybe<Customers_Aggregate_Order_By>;
  defaultOrderStockLocation?: InputMaybe<Stock_Locations_Order_By>;
  defaultOrderStockLocationId?: InputMaybe<Order_By>;
  defaultStockLocation?: InputMaybe<Stock_Locations_Order_By>;
  defaultStockLocationId?: InputMaybe<Order_By>;
  deliveryNoteFormat?: InputMaybe<Order_By>;
  deliveryNotes?: InputMaybe<Order_By>;
  deliveryTimeEarliest?: InputMaybe<Order_By>;
  deliveryTimeLatest?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  extRef?: InputMaybe<Order_By>;
  gln?: InputMaybe<Order_By>;
  hasChildrenCustomers?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loggedActions_aggregate?: InputMaybe<Customer_Logged_Actions_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  priceCustomerGroup?: InputMaybe<Customer_Groups_Order_By>;
  priceCustomerGroupId?: InputMaybe<Order_By>;
  shippingAddress?: InputMaybe<Customer_Addresses_Order_By>;
  shippingAddressId?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  stockTransactions_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Order_By>;
  temporalSalesTeamsCustomers_aggregate?: InputMaybe<Temporal_Sales_Teams_Customers_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
  todaysTemporalSalesTeams_aggregate?: InputMaybe<Temporal_Sales_Teams_Customers_Aggregate_Order_By>;
  todos_aggregate?: InputMaybe<Todos_Aggregate_Order_By>;
  tourRoutes_aggregate?: InputMaybe<Tour_Routes_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  useBillingOfParent?: InputMaybe<Order_By>;
  useDeliveryAddressForBilling?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customers */
export type Customers_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Customers_Prepend_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "customers" */
export enum Customers_Select_Column {
  /** column name */
  AllowedTenants = 'allowedTenants',
  /** column name */
  BillingAddressId = 'billingAddressId',
  /** column name */
  CommunicationLanguage = 'communicationLanguage',
  /** column name */
  ContainerPoolProductGroupId = 'containerPoolProductGroupId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerBillingSettingId = 'customerBillingSettingId',
  /** column name */
  CustomerGroupId = 'customerGroupId',
  /** column name */
  CustomerOrderNotes = 'customerOrderNotes',
  /** column name */
  CustomerParentId = 'customerParentId',
  /** column name */
  CustomerTemplateSettingId = 'customerTemplateSettingId',
  /** column name */
  DefaultOrderStockLocationId = 'defaultOrderStockLocationId',
  /** column name */
  DefaultStockLocationId = 'defaultStockLocationId',
  /** column name */
  DeliveryNoteFormat = 'deliveryNoteFormat',
  /** column name */
  DeliveryNotes = 'deliveryNotes',
  /** column name */
  DeliveryTimeEarliest = 'deliveryTimeEarliest',
  /** column name */
  DeliveryTimeLatest = 'deliveryTimeLatest',
  /** column name */
  DomainId = 'domainId',
  /** column name */
  ExtRef = 'extRef',
  /** column name */
  Gln = 'gln',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  PriceCustomerGroupId = 'priceCustomerGroupId',
  /** column name */
  ShippingAddressId = 'shippingAddressId',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UseBillingOfParent = 'useBillingOfParent',
  /** column name */
  UseDeliveryAddressForBilling = 'useDeliveryAddressForBilling',
  /** column name */
  Version = 'version'
}

/** select "customers_aggregate_bool_exp_bool_and_arguments_columns" columns of table "customers" */
export enum Customers_Select_Column_Customers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  UseBillingOfParent = 'useBillingOfParent',
  /** column name */
  UseDeliveryAddressForBilling = 'useDeliveryAddressForBilling'
}

/** select "customers_aggregate_bool_exp_bool_or_arguments_columns" columns of table "customers" */
export enum Customers_Select_Column_Customers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  UseBillingOfParent = 'useBillingOfParent',
  /** column name */
  UseDeliveryAddressForBilling = 'useDeliveryAddressForBilling'
}

/** input type for updating data in table "customers" */
export type Customers_Set_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
  billingAddressId?: InputMaybe<Scalars['bigint']>;
  communicationLanguage?: InputMaybe<Languages_Enum>;
  containerPoolProductGroupId?: InputMaybe<Scalars['bigint']>;
  customerBillingSettingId?: InputMaybe<Scalars['bigint']>;
  customerGroupId?: InputMaybe<Scalars['bigint']>;
  customerOrderNotes?: InputMaybe<Scalars['String']>;
  customerParentId?: InputMaybe<Scalars['bigint']>;
  customerTemplateSettingId?: InputMaybe<Scalars['bigint']>;
  defaultOrderStockLocationId?: InputMaybe<Scalars['bigint']>;
  defaultStockLocationId?: InputMaybe<Scalars['bigint']>;
  deliveryNoteFormat?: InputMaybe<Customer_Delivery_Note_Formats_Enum>;
  deliveryNotes?: InputMaybe<Scalars['String']>;
  deliveryTimeEarliest?: InputMaybe<Scalars['time']>;
  deliveryTimeLatest?: InputMaybe<Scalars['time']>;
  domainId?: InputMaybe<Scalars['String']>;
  extRef?: InputMaybe<Scalars['String']>;
  gln?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  priceCustomerGroupId?: InputMaybe<Scalars['bigint']>;
  shippingAddressId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Customer_States_Enum>;
  useBillingOfParent?: InputMaybe<Scalars['Boolean']>;
  useDeliveryAddressForBilling?: InputMaybe<Scalars['Boolean']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Customers_Stddev_Fields = {
  __typename: 'customers_stddev_fields';
  billingAddressId?: Maybe<Scalars['Float']>;
  containerPoolProductGroupId?: Maybe<Scalars['Float']>;
  customerBillingSettingId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerParentId?: Maybe<Scalars['Float']>;
  customerTemplateSettingId?: Maybe<Scalars['Float']>;
  defaultOrderStockLocationId?: Maybe<Scalars['Float']>;
  defaultStockLocationId?: Maybe<Scalars['Float']>;
  gln?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priceCustomerGroupId?: Maybe<Scalars['Float']>;
  shippingAddressId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customers" */
export type Customers_Stddev_Order_By = {
  billingAddressId?: InputMaybe<Order_By>;
  containerPoolProductGroupId?: InputMaybe<Order_By>;
  customerBillingSettingId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerParentId?: InputMaybe<Order_By>;
  customerTemplateSettingId?: InputMaybe<Order_By>;
  defaultOrderStockLocationId?: InputMaybe<Order_By>;
  defaultStockLocationId?: InputMaybe<Order_By>;
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priceCustomerGroupId?: InputMaybe<Order_By>;
  shippingAddressId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customers_Stddev_Pop_Fields = {
  __typename: 'customers_stddev_pop_fields';
  billingAddressId?: Maybe<Scalars['Float']>;
  containerPoolProductGroupId?: Maybe<Scalars['Float']>;
  customerBillingSettingId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerParentId?: Maybe<Scalars['Float']>;
  customerTemplateSettingId?: Maybe<Scalars['Float']>;
  defaultOrderStockLocationId?: Maybe<Scalars['Float']>;
  defaultStockLocationId?: Maybe<Scalars['Float']>;
  gln?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priceCustomerGroupId?: Maybe<Scalars['Float']>;
  shippingAddressId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customers" */
export type Customers_Stddev_Pop_Order_By = {
  billingAddressId?: InputMaybe<Order_By>;
  containerPoolProductGroupId?: InputMaybe<Order_By>;
  customerBillingSettingId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerParentId?: InputMaybe<Order_By>;
  customerTemplateSettingId?: InputMaybe<Order_By>;
  defaultOrderStockLocationId?: InputMaybe<Order_By>;
  defaultStockLocationId?: InputMaybe<Order_By>;
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priceCustomerGroupId?: InputMaybe<Order_By>;
  shippingAddressId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customers_Stddev_Samp_Fields = {
  __typename: 'customers_stddev_samp_fields';
  billingAddressId?: Maybe<Scalars['Float']>;
  containerPoolProductGroupId?: Maybe<Scalars['Float']>;
  customerBillingSettingId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerParentId?: Maybe<Scalars['Float']>;
  customerTemplateSettingId?: Maybe<Scalars['Float']>;
  defaultOrderStockLocationId?: Maybe<Scalars['Float']>;
  defaultStockLocationId?: Maybe<Scalars['Float']>;
  gln?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priceCustomerGroupId?: Maybe<Scalars['Float']>;
  shippingAddressId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customers" */
export type Customers_Stddev_Samp_Order_By = {
  billingAddressId?: InputMaybe<Order_By>;
  containerPoolProductGroupId?: InputMaybe<Order_By>;
  customerBillingSettingId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerParentId?: InputMaybe<Order_By>;
  customerTemplateSettingId?: InputMaybe<Order_By>;
  defaultOrderStockLocationId?: InputMaybe<Order_By>;
  defaultStockLocationId?: InputMaybe<Order_By>;
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priceCustomerGroupId?: InputMaybe<Order_By>;
  shippingAddressId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customers" */
export type Customers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customers_Stream_Cursor_Value_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
  billingAddressId?: InputMaybe<Scalars['bigint']>;
  communicationLanguage?: InputMaybe<Languages_Enum>;
  containerPoolProductGroupId?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerBillingSettingId?: InputMaybe<Scalars['bigint']>;
  customerGroupId?: InputMaybe<Scalars['bigint']>;
  customerOrderNotes?: InputMaybe<Scalars['String']>;
  customerParentId?: InputMaybe<Scalars['bigint']>;
  customerTemplateSettingId?: InputMaybe<Scalars['bigint']>;
  defaultOrderStockLocationId?: InputMaybe<Scalars['bigint']>;
  defaultStockLocationId?: InputMaybe<Scalars['bigint']>;
  deliveryNoteFormat?: InputMaybe<Customer_Delivery_Note_Formats_Enum>;
  deliveryNotes?: InputMaybe<Scalars['String']>;
  deliveryTimeEarliest?: InputMaybe<Scalars['time']>;
  deliveryTimeLatest?: InputMaybe<Scalars['time']>;
  domainId?: InputMaybe<Scalars['String']>;
  extRef?: InputMaybe<Scalars['String']>;
  gln?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  priceCustomerGroupId?: InputMaybe<Scalars['bigint']>;
  shippingAddressId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Customer_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  useBillingOfParent?: InputMaybe<Scalars['Boolean']>;
  useDeliveryAddressForBilling?: InputMaybe<Scalars['Boolean']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Customers_Sum_Fields = {
  __typename: 'customers_sum_fields';
  billingAddressId?: Maybe<Scalars['bigint']>;
  containerPoolProductGroupId?: Maybe<Scalars['bigint']>;
  customerBillingSettingId?: Maybe<Scalars['bigint']>;
  customerGroupId?: Maybe<Scalars['bigint']>;
  customerParentId?: Maybe<Scalars['bigint']>;
  customerTemplateSettingId?: Maybe<Scalars['bigint']>;
  defaultOrderStockLocationId?: Maybe<Scalars['bigint']>;
  defaultStockLocationId?: Maybe<Scalars['bigint']>;
  gln?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  priceCustomerGroupId?: Maybe<Scalars['bigint']>;
  shippingAddressId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "customers" */
export type Customers_Sum_Order_By = {
  billingAddressId?: InputMaybe<Order_By>;
  containerPoolProductGroupId?: InputMaybe<Order_By>;
  customerBillingSettingId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerParentId?: InputMaybe<Order_By>;
  customerTemplateSettingId?: InputMaybe<Order_By>;
  defaultOrderStockLocationId?: InputMaybe<Order_By>;
  defaultStockLocationId?: InputMaybe<Order_By>;
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priceCustomerGroupId?: InputMaybe<Order_By>;
  shippingAddressId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "customers" */
export enum Customers_Update_Column {
  /** column name */
  AllowedTenants = 'allowedTenants',
  /** column name */
  BillingAddressId = 'billingAddressId',
  /** column name */
  CommunicationLanguage = 'communicationLanguage',
  /** column name */
  ContainerPoolProductGroupId = 'containerPoolProductGroupId',
  /** column name */
  CustomerBillingSettingId = 'customerBillingSettingId',
  /** column name */
  CustomerGroupId = 'customerGroupId',
  /** column name */
  CustomerOrderNotes = 'customerOrderNotes',
  /** column name */
  CustomerParentId = 'customerParentId',
  /** column name */
  CustomerTemplateSettingId = 'customerTemplateSettingId',
  /** column name */
  DefaultOrderStockLocationId = 'defaultOrderStockLocationId',
  /** column name */
  DefaultStockLocationId = 'defaultStockLocationId',
  /** column name */
  DeliveryNoteFormat = 'deliveryNoteFormat',
  /** column name */
  DeliveryNotes = 'deliveryNotes',
  /** column name */
  DeliveryTimeEarliest = 'deliveryTimeEarliest',
  /** column name */
  DeliveryTimeLatest = 'deliveryTimeLatest',
  /** column name */
  DomainId = 'domainId',
  /** column name */
  ExtRef = 'extRef',
  /** column name */
  Gln = 'gln',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  PriceCustomerGroupId = 'priceCustomerGroupId',
  /** column name */
  ShippingAddressId = 'shippingAddressId',
  /** column name */
  State = 'state',
  /** column name */
  UseBillingOfParent = 'useBillingOfParent',
  /** column name */
  UseDeliveryAddressForBilling = 'useDeliveryAddressForBilling',
  /** column name */
  Version = 'version'
}

export type Customers_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Customers_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Customers_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Customers_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Customers_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customers_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Customers_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customers_Var_Pop_Fields = {
  __typename: 'customers_var_pop_fields';
  billingAddressId?: Maybe<Scalars['Float']>;
  containerPoolProductGroupId?: Maybe<Scalars['Float']>;
  customerBillingSettingId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerParentId?: Maybe<Scalars['Float']>;
  customerTemplateSettingId?: Maybe<Scalars['Float']>;
  defaultOrderStockLocationId?: Maybe<Scalars['Float']>;
  defaultStockLocationId?: Maybe<Scalars['Float']>;
  gln?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priceCustomerGroupId?: Maybe<Scalars['Float']>;
  shippingAddressId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customers" */
export type Customers_Var_Pop_Order_By = {
  billingAddressId?: InputMaybe<Order_By>;
  containerPoolProductGroupId?: InputMaybe<Order_By>;
  customerBillingSettingId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerParentId?: InputMaybe<Order_By>;
  customerTemplateSettingId?: InputMaybe<Order_By>;
  defaultOrderStockLocationId?: InputMaybe<Order_By>;
  defaultStockLocationId?: InputMaybe<Order_By>;
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priceCustomerGroupId?: InputMaybe<Order_By>;
  shippingAddressId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customers_Var_Samp_Fields = {
  __typename: 'customers_var_samp_fields';
  billingAddressId?: Maybe<Scalars['Float']>;
  containerPoolProductGroupId?: Maybe<Scalars['Float']>;
  customerBillingSettingId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerParentId?: Maybe<Scalars['Float']>;
  customerTemplateSettingId?: Maybe<Scalars['Float']>;
  defaultOrderStockLocationId?: Maybe<Scalars['Float']>;
  defaultStockLocationId?: Maybe<Scalars['Float']>;
  gln?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priceCustomerGroupId?: Maybe<Scalars['Float']>;
  shippingAddressId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customers" */
export type Customers_Var_Samp_Order_By = {
  billingAddressId?: InputMaybe<Order_By>;
  containerPoolProductGroupId?: InputMaybe<Order_By>;
  customerBillingSettingId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerParentId?: InputMaybe<Order_By>;
  customerTemplateSettingId?: InputMaybe<Order_By>;
  defaultOrderStockLocationId?: InputMaybe<Order_By>;
  defaultStockLocationId?: InputMaybe<Order_By>;
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priceCustomerGroupId?: InputMaybe<Order_By>;
  shippingAddressId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customers_Variance_Fields = {
  __typename: 'customers_variance_fields';
  billingAddressId?: Maybe<Scalars['Float']>;
  containerPoolProductGroupId?: Maybe<Scalars['Float']>;
  customerBillingSettingId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerParentId?: Maybe<Scalars['Float']>;
  customerTemplateSettingId?: Maybe<Scalars['Float']>;
  defaultOrderStockLocationId?: Maybe<Scalars['Float']>;
  defaultStockLocationId?: Maybe<Scalars['Float']>;
  gln?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priceCustomerGroupId?: Maybe<Scalars['Float']>;
  shippingAddressId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customers" */
export type Customers_Variance_Order_By = {
  billingAddressId?: InputMaybe<Order_By>;
  containerPoolProductGroupId?: InputMaybe<Order_By>;
  customerBillingSettingId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerParentId?: InputMaybe<Order_By>;
  customerTemplateSettingId?: InputMaybe<Order_By>;
  defaultOrderStockLocationId?: InputMaybe<Order_By>;
  defaultStockLocationId?: InputMaybe<Order_By>;
  gln?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priceCustomerGroupId?: InputMaybe<Order_By>;
  shippingAddressId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "cutting_pattern_draft_actions" */
export type Cutting_Pattern_Draft_Actions = {
  __typename: 'cutting_pattern_draft_actions';
  /** An array relationship */
  cuttingPatternsDrafts: Array<Cutting_Patterns_Draft>;
  /** An aggregate relationship */
  cuttingPatternsDrafts_aggregate: Cutting_Patterns_Draft_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "cutting_pattern_draft_actions" */
export type Cutting_Pattern_Draft_ActionsCuttingPatternsDraftsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


/** columns and relationships of "cutting_pattern_draft_actions" */
export type Cutting_Pattern_Draft_ActionsCuttingPatternsDrafts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};

/** aggregated selection of "cutting_pattern_draft_actions" */
export type Cutting_Pattern_Draft_Actions_Aggregate = {
  __typename: 'cutting_pattern_draft_actions_aggregate';
  aggregate?: Maybe<Cutting_Pattern_Draft_Actions_Aggregate_Fields>;
  nodes: Array<Cutting_Pattern_Draft_Actions>;
};

/** aggregate fields of "cutting_pattern_draft_actions" */
export type Cutting_Pattern_Draft_Actions_Aggregate_Fields = {
  __typename: 'cutting_pattern_draft_actions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Cutting_Pattern_Draft_Actions_Max_Fields>;
  min?: Maybe<Cutting_Pattern_Draft_Actions_Min_Fields>;
};


/** aggregate fields of "cutting_pattern_draft_actions" */
export type Cutting_Pattern_Draft_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cutting_Pattern_Draft_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "cutting_pattern_draft_actions". All fields are combined with a logical 'AND'. */
export type Cutting_Pattern_Draft_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Cutting_Pattern_Draft_Actions_Bool_Exp>>;
  _not?: InputMaybe<Cutting_Pattern_Draft_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Cutting_Pattern_Draft_Actions_Bool_Exp>>;
  cuttingPatternsDrafts?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
  cuttingPatternsDrafts_aggregate?: InputMaybe<Cutting_Patterns_Draft_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Cutting_Pattern_Draft_Actions_Enum {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE'
}

/** Boolean expression to compare columns of type "cutting_pattern_draft_actions_enum". All fields are combined with logical 'AND'. */
export type Cutting_Pattern_Draft_Actions_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Cutting_Pattern_Draft_Actions_Enum>;
  _in?: InputMaybe<Array<Cutting_Pattern_Draft_Actions_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Cutting_Pattern_Draft_Actions_Enum>;
  _nin?: InputMaybe<Array<Cutting_Pattern_Draft_Actions_Enum>>;
};

/** aggregate max on columns */
export type Cutting_Pattern_Draft_Actions_Max_Fields = {
  __typename: 'cutting_pattern_draft_actions_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Cutting_Pattern_Draft_Actions_Min_Fields = {
  __typename: 'cutting_pattern_draft_actions_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "cutting_pattern_draft_actions". */
export type Cutting_Pattern_Draft_Actions_Order_By = {
  cuttingPatternsDrafts_aggregate?: InputMaybe<Cutting_Patterns_Draft_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "cutting_pattern_draft_actions" */
export enum Cutting_Pattern_Draft_Actions_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "cutting_pattern_draft_actions" */
export type Cutting_Pattern_Draft_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cutting_Pattern_Draft_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cutting_Pattern_Draft_Actions_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "cutting_pattern_draft_state_transitions" */
export type Cutting_Pattern_Draft_State_Transitions = {
  __typename: 'cutting_pattern_draft_state_transitions';
  action: Scalars['String'];
  eventName: Scalars['String'];
  fromState: Cutting_Pattern_Draft_States_Enum;
  toState: Cutting_Pattern_Draft_States_Enum;
};

/** aggregated selection of "cutting_pattern_draft_state_transitions" */
export type Cutting_Pattern_Draft_State_Transitions_Aggregate = {
  __typename: 'cutting_pattern_draft_state_transitions_aggregate';
  aggregate?: Maybe<Cutting_Pattern_Draft_State_Transitions_Aggregate_Fields>;
  nodes: Array<Cutting_Pattern_Draft_State_Transitions>;
};

/** aggregate fields of "cutting_pattern_draft_state_transitions" */
export type Cutting_Pattern_Draft_State_Transitions_Aggregate_Fields = {
  __typename: 'cutting_pattern_draft_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Cutting_Pattern_Draft_State_Transitions_Max_Fields>;
  min?: Maybe<Cutting_Pattern_Draft_State_Transitions_Min_Fields>;
};


/** aggregate fields of "cutting_pattern_draft_state_transitions" */
export type Cutting_Pattern_Draft_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cutting_Pattern_Draft_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "cutting_pattern_draft_state_transitions". All fields are combined with a logical 'AND'. */
export type Cutting_Pattern_Draft_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Cutting_Pattern_Draft_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Cutting_Pattern_Draft_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Cutting_Pattern_Draft_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Cutting_Pattern_Draft_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Cutting_Pattern_Draft_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Cutting_Pattern_Draft_State_Transitions_Max_Fields = {
  __typename: 'cutting_pattern_draft_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Cutting_Pattern_Draft_State_Transitions_Min_Fields = {
  __typename: 'cutting_pattern_draft_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "cutting_pattern_draft_state_transitions". */
export type Cutting_Pattern_Draft_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "cutting_pattern_draft_state_transitions" */
export enum Cutting_Pattern_Draft_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "cutting_pattern_draft_state_transitions" */
export type Cutting_Pattern_Draft_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cutting_Pattern_Draft_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cutting_Pattern_Draft_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Cutting_Pattern_Draft_States_Enum>;
  toState?: InputMaybe<Cutting_Pattern_Draft_States_Enum>;
};

/** columns and relationships of "cutting_pattern_draft_states" */
export type Cutting_Pattern_Draft_States = {
  __typename: 'cutting_pattern_draft_states';
  /** An array relationship */
  cuttingPatternsDrafts: Array<Cutting_Patterns_Draft>;
  /** An aggregate relationship */
  cuttingPatternsDrafts_aggregate: Cutting_Patterns_Draft_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "cutting_pattern_draft_states" */
export type Cutting_Pattern_Draft_StatesCuttingPatternsDraftsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


/** columns and relationships of "cutting_pattern_draft_states" */
export type Cutting_Pattern_Draft_StatesCuttingPatternsDrafts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};

/** aggregated selection of "cutting_pattern_draft_states" */
export type Cutting_Pattern_Draft_States_Aggregate = {
  __typename: 'cutting_pattern_draft_states_aggregate';
  aggregate?: Maybe<Cutting_Pattern_Draft_States_Aggregate_Fields>;
  nodes: Array<Cutting_Pattern_Draft_States>;
};

/** aggregate fields of "cutting_pattern_draft_states" */
export type Cutting_Pattern_Draft_States_Aggregate_Fields = {
  __typename: 'cutting_pattern_draft_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Cutting_Pattern_Draft_States_Max_Fields>;
  min?: Maybe<Cutting_Pattern_Draft_States_Min_Fields>;
};


/** aggregate fields of "cutting_pattern_draft_states" */
export type Cutting_Pattern_Draft_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cutting_Pattern_Draft_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "cutting_pattern_draft_states". All fields are combined with a logical 'AND'. */
export type Cutting_Pattern_Draft_States_Bool_Exp = {
  _and?: InputMaybe<Array<Cutting_Pattern_Draft_States_Bool_Exp>>;
  _not?: InputMaybe<Cutting_Pattern_Draft_States_Bool_Exp>;
  _or?: InputMaybe<Array<Cutting_Pattern_Draft_States_Bool_Exp>>;
  cuttingPatternsDrafts?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
  cuttingPatternsDrafts_aggregate?: InputMaybe<Cutting_Patterns_Draft_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Cutting_Pattern_Draft_States_Enum {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  WaitingForActivation = 'WAITING_FOR_ACTIVATION',
  WaitingForApproval = 'WAITING_FOR_APPROVAL'
}

/** Boolean expression to compare columns of type "cutting_pattern_draft_states_enum". All fields are combined with logical 'AND'. */
export type Cutting_Pattern_Draft_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Cutting_Pattern_Draft_States_Enum>;
  _in?: InputMaybe<Array<Cutting_Pattern_Draft_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Cutting_Pattern_Draft_States_Enum>;
  _nin?: InputMaybe<Array<Cutting_Pattern_Draft_States_Enum>>;
};

/** aggregate max on columns */
export type Cutting_Pattern_Draft_States_Max_Fields = {
  __typename: 'cutting_pattern_draft_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Cutting_Pattern_Draft_States_Min_Fields = {
  __typename: 'cutting_pattern_draft_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "cutting_pattern_draft_states". */
export type Cutting_Pattern_Draft_States_Order_By = {
  cuttingPatternsDrafts_aggregate?: InputMaybe<Cutting_Patterns_Draft_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "cutting_pattern_draft_states" */
export enum Cutting_Pattern_Draft_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "cutting_pattern_draft_states" */
export type Cutting_Pattern_Draft_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cutting_Pattern_Draft_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cutting_Pattern_Draft_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "cutting_pattern_logged_actions" */
export type Cutting_Pattern_Logged_Actions = {
  __typename: 'cutting_pattern_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  relid?: Maybe<Scalars['oid']>;
  role?: Maybe<Scalars['String']>;
  rowData?: Maybe<Scalars['jsonb']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "cutting_pattern_logged_actions" */
export type Cutting_Pattern_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "cutting_pattern_logged_actions" */
export type Cutting_Pattern_Logged_ActionsRowDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "cutting_pattern_logged_actions" */
export type Cutting_Pattern_Logged_Actions_Aggregate = {
  __typename: 'cutting_pattern_logged_actions_aggregate';
  aggregate?: Maybe<Cutting_Pattern_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Cutting_Pattern_Logged_Actions>;
};

/** aggregate fields of "cutting_pattern_logged_actions" */
export type Cutting_Pattern_Logged_Actions_Aggregate_Fields = {
  __typename: 'cutting_pattern_logged_actions_aggregate_fields';
  avg?: Maybe<Cutting_Pattern_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Cutting_Pattern_Logged_Actions_Max_Fields>;
  min?: Maybe<Cutting_Pattern_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Cutting_Pattern_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Cutting_Pattern_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cutting_Pattern_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Cutting_Pattern_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Cutting_Pattern_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Cutting_Pattern_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Cutting_Pattern_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "cutting_pattern_logged_actions" */
export type Cutting_Pattern_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cutting_Pattern_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Cutting_Pattern_Logged_Actions_Avg_Fields = {
  __typename: 'cutting_pattern_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "cutting_pattern_logged_actions". All fields are combined with a logical 'AND'. */
export type Cutting_Pattern_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Cutting_Pattern_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Cutting_Pattern_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Cutting_Pattern_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  relid?: InputMaybe<Oid_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  rowData?: InputMaybe<Jsonb_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Cutting_Pattern_Logged_Actions_Max_Fields = {
  __typename: 'cutting_pattern_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Cutting_Pattern_Logged_Actions_Min_Fields = {
  __typename: 'cutting_pattern_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "cutting_pattern_logged_actions". */
export type Cutting_Pattern_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  relid?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  rowData?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "cutting_pattern_logged_actions" */
export enum Cutting_Pattern_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Relid = 'relid',
  /** column name */
  Role = 'role',
  /** column name */
  RowData = 'rowData',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Cutting_Pattern_Logged_Actions_Stddev_Fields = {
  __typename: 'cutting_pattern_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Cutting_Pattern_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'cutting_pattern_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Cutting_Pattern_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'cutting_pattern_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "cutting_pattern_logged_actions" */
export type Cutting_Pattern_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cutting_Pattern_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cutting_Pattern_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  relid?: InputMaybe<Scalars['oid']>;
  role?: InputMaybe<Scalars['String']>;
  rowData?: InputMaybe<Scalars['jsonb']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Cutting_Pattern_Logged_Actions_Sum_Fields = {
  __typename: 'cutting_pattern_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Cutting_Pattern_Logged_Actions_Var_Pop_Fields = {
  __typename: 'cutting_pattern_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Cutting_Pattern_Logged_Actions_Var_Samp_Fields = {
  __typename: 'cutting_pattern_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Cutting_Pattern_Logged_Actions_Variance_Fields = {
  __typename: 'cutting_pattern_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "cutting_pattern_output_logged_actions" */
export type Cutting_Pattern_Output_Logged_Actions = {
  __typename: 'cutting_pattern_output_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  relid?: Maybe<Scalars['oid']>;
  role?: Maybe<Scalars['String']>;
  rowData?: Maybe<Scalars['jsonb']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "cutting_pattern_output_logged_actions" */
export type Cutting_Pattern_Output_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "cutting_pattern_output_logged_actions" */
export type Cutting_Pattern_Output_Logged_ActionsRowDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "cutting_pattern_output_logged_actions" */
export type Cutting_Pattern_Output_Logged_Actions_Aggregate = {
  __typename: 'cutting_pattern_output_logged_actions_aggregate';
  aggregate?: Maybe<Cutting_Pattern_Output_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Cutting_Pattern_Output_Logged_Actions>;
};

/** aggregate fields of "cutting_pattern_output_logged_actions" */
export type Cutting_Pattern_Output_Logged_Actions_Aggregate_Fields = {
  __typename: 'cutting_pattern_output_logged_actions_aggregate_fields';
  avg?: Maybe<Cutting_Pattern_Output_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Cutting_Pattern_Output_Logged_Actions_Max_Fields>;
  min?: Maybe<Cutting_Pattern_Output_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Cutting_Pattern_Output_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Cutting_Pattern_Output_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cutting_Pattern_Output_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Cutting_Pattern_Output_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Cutting_Pattern_Output_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Cutting_Pattern_Output_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Cutting_Pattern_Output_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "cutting_pattern_output_logged_actions" */
export type Cutting_Pattern_Output_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cutting_Pattern_Output_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Cutting_Pattern_Output_Logged_Actions_Avg_Fields = {
  __typename: 'cutting_pattern_output_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "cutting_pattern_output_logged_actions". All fields are combined with a logical 'AND'. */
export type Cutting_Pattern_Output_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Cutting_Pattern_Output_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Cutting_Pattern_Output_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Cutting_Pattern_Output_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  relid?: InputMaybe<Oid_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  rowData?: InputMaybe<Jsonb_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Cutting_Pattern_Output_Logged_Actions_Max_Fields = {
  __typename: 'cutting_pattern_output_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Cutting_Pattern_Output_Logged_Actions_Min_Fields = {
  __typename: 'cutting_pattern_output_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "cutting_pattern_output_logged_actions". */
export type Cutting_Pattern_Output_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  relid?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  rowData?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "cutting_pattern_output_logged_actions" */
export enum Cutting_Pattern_Output_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Relid = 'relid',
  /** column name */
  Role = 'role',
  /** column name */
  RowData = 'rowData',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Cutting_Pattern_Output_Logged_Actions_Stddev_Fields = {
  __typename: 'cutting_pattern_output_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Cutting_Pattern_Output_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'cutting_pattern_output_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Cutting_Pattern_Output_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'cutting_pattern_output_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "cutting_pattern_output_logged_actions" */
export type Cutting_Pattern_Output_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cutting_Pattern_Output_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cutting_Pattern_Output_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  relid?: InputMaybe<Scalars['oid']>;
  role?: InputMaybe<Scalars['String']>;
  rowData?: InputMaybe<Scalars['jsonb']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Cutting_Pattern_Output_Logged_Actions_Sum_Fields = {
  __typename: 'cutting_pattern_output_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Cutting_Pattern_Output_Logged_Actions_Var_Pop_Fields = {
  __typename: 'cutting_pattern_output_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Cutting_Pattern_Output_Logged_Actions_Var_Samp_Fields = {
  __typename: 'cutting_pattern_output_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Cutting_Pattern_Output_Logged_Actions_Variance_Fields = {
  __typename: 'cutting_pattern_output_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs = {
  __typename: 'cutting_pattern_outputs';
  /** An object relationship */
  Tenant: Tenants;
  comment?: Maybe<Scalars['String']>;
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['bigint']>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: Maybe<Scalars['numeric']>;
  createdAt: Scalars['timestamptz'];
  /** Currency of cost_price_per_absolute_margin */
  currency: Currencies_Enum;
  /** An object relationship */
  cuttingPattern: Cutting_Patterns;
  cuttingPatternId: Scalars['bigint'];
  dismantlingPlanPriority: Dismantling_Plan_Priorities_Enum;
  id: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  /** An object relationship */
  productUnit: Product_Units;
  productUnitId: Scalars['bigint'];
  quantity: Scalars['bigint'];
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['bigint']>;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};

/** aggregated selection of "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_Aggregate = {
  __typename: 'cutting_pattern_outputs_aggregate';
  aggregate?: Maybe<Cutting_Pattern_Outputs_Aggregate_Fields>;
  nodes: Array<Cutting_Pattern_Outputs>;
};

export type Cutting_Pattern_Outputs_Aggregate_Bool_Exp = {
  count?: InputMaybe<Cutting_Pattern_Outputs_Aggregate_Bool_Exp_Count>;
};

export type Cutting_Pattern_Outputs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Cutting_Pattern_Outputs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_Aggregate_Fields = {
  __typename: 'cutting_pattern_outputs_aggregate_fields';
  avg?: Maybe<Cutting_Pattern_Outputs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Cutting_Pattern_Outputs_Max_Fields>;
  min?: Maybe<Cutting_Pattern_Outputs_Min_Fields>;
  stddev?: Maybe<Cutting_Pattern_Outputs_Stddev_Fields>;
  stddev_pop?: Maybe<Cutting_Pattern_Outputs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cutting_Pattern_Outputs_Stddev_Samp_Fields>;
  sum?: Maybe<Cutting_Pattern_Outputs_Sum_Fields>;
  var_pop?: Maybe<Cutting_Pattern_Outputs_Var_Pop_Fields>;
  var_samp?: Maybe<Cutting_Pattern_Outputs_Var_Samp_Fields>;
  variance?: Maybe<Cutting_Pattern_Outputs_Variance_Fields>;
};


/** aggregate fields of "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cutting_Pattern_Outputs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_Aggregate_Order_By = {
  avg?: InputMaybe<Cutting_Pattern_Outputs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Cutting_Pattern_Outputs_Max_Order_By>;
  min?: InputMaybe<Cutting_Pattern_Outputs_Min_Order_By>;
  stddev?: InputMaybe<Cutting_Pattern_Outputs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Cutting_Pattern_Outputs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Cutting_Pattern_Outputs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Cutting_Pattern_Outputs_Sum_Order_By>;
  var_pop?: InputMaybe<Cutting_Pattern_Outputs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Cutting_Pattern_Outputs_Var_Samp_Order_By>;
  variance?: InputMaybe<Cutting_Pattern_Outputs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_Arr_Rel_Insert_Input = {
  data: Array<Cutting_Pattern_Outputs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Cutting_Pattern_Outputs_On_Conflict>;
};

/** aggregate avg on columns */
export type Cutting_Pattern_Outputs_Avg_Fields = {
  __typename: 'cutting_pattern_outputs_avg_fields';
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: Maybe<Scalars['Float']>;
  cuttingPatternId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_Avg_Order_By = {
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  cuttingPatternId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cutting_pattern_outputs". All fields are combined with a logical 'AND'. */
export type Cutting_Pattern_Outputs_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Cutting_Pattern_Outputs_Bool_Exp>>;
  _not?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
  _or?: InputMaybe<Array<Cutting_Pattern_Outputs_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Bigint_Comparison_Exp>;
  costPricePerUnitFactor?: InputMaybe<Numeric_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  cuttingPattern?: InputMaybe<Cutting_Patterns_Bool_Exp>;
  cuttingPatternId?: InputMaybe<Bigint_Comparison_Exp>;
  dismantlingPlanPriority?: InputMaybe<Dismantling_Plan_Priorities_Enum_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  productUnit?: InputMaybe<Product_Units_Bool_Exp>;
  productUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  quantity?: InputMaybe<Bigint_Comparison_Exp>;
  sampledKgWeight?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "cutting_pattern_outputs" */
export enum Cutting_Pattern_Outputs_Constraint {
  /** unique or primary key constraint on columns "cutting_pattern_id", "product_id" */
  CuttingPatternOutputsCuttingPatternIdProductIdKey = 'cutting_pattern_outputs_cutting_pattern_id_product_id_key',
  /** unique or primary key constraint on columns "id" */
  CuttingPatternOutputsPkey = 'cutting_pattern_outputs_pkey'
}

/** columns and relationships of "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft = {
  __typename: 'cutting_pattern_outputs_draft';
  /** An object relationship */
  Tenant: Tenants;
  comment?: Maybe<Scalars['String']>;
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['bigint']>;
  costPricePerUnitFactor?: Maybe<Scalars['numeric']>;
  createdAt: Scalars['timestamptz'];
  currency: Currencies_Enum;
  /** An object relationship */
  cuttingPatternDraft: Cutting_Patterns_Draft;
  cuttingPatternDraftId: Scalars['bigint'];
  dismantlingPlanPriority?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  /** An object relationship */
  productUnit: Product_Units;
  productUnitId: Scalars['bigint'];
  quantity: Scalars['numeric'];
  sampledKgWeight?: Maybe<Scalars['bigint']>;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};

/** aggregated selection of "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_Aggregate = {
  __typename: 'cutting_pattern_outputs_draft_aggregate';
  aggregate?: Maybe<Cutting_Pattern_Outputs_Draft_Aggregate_Fields>;
  nodes: Array<Cutting_Pattern_Outputs_Draft>;
};

export type Cutting_Pattern_Outputs_Draft_Aggregate_Bool_Exp = {
  count?: InputMaybe<Cutting_Pattern_Outputs_Draft_Aggregate_Bool_Exp_Count>;
};

export type Cutting_Pattern_Outputs_Draft_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_Aggregate_Fields = {
  __typename: 'cutting_pattern_outputs_draft_aggregate_fields';
  avg?: Maybe<Cutting_Pattern_Outputs_Draft_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Cutting_Pattern_Outputs_Draft_Max_Fields>;
  min?: Maybe<Cutting_Pattern_Outputs_Draft_Min_Fields>;
  stddev?: Maybe<Cutting_Pattern_Outputs_Draft_Stddev_Fields>;
  stddev_pop?: Maybe<Cutting_Pattern_Outputs_Draft_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cutting_Pattern_Outputs_Draft_Stddev_Samp_Fields>;
  sum?: Maybe<Cutting_Pattern_Outputs_Draft_Sum_Fields>;
  var_pop?: Maybe<Cutting_Pattern_Outputs_Draft_Var_Pop_Fields>;
  var_samp?: Maybe<Cutting_Pattern_Outputs_Draft_Var_Samp_Fields>;
  variance?: Maybe<Cutting_Pattern_Outputs_Draft_Variance_Fields>;
};


/** aggregate fields of "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_Aggregate_Order_By = {
  avg?: InputMaybe<Cutting_Pattern_Outputs_Draft_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Cutting_Pattern_Outputs_Draft_Max_Order_By>;
  min?: InputMaybe<Cutting_Pattern_Outputs_Draft_Min_Order_By>;
  stddev?: InputMaybe<Cutting_Pattern_Outputs_Draft_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Cutting_Pattern_Outputs_Draft_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Cutting_Pattern_Outputs_Draft_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Cutting_Pattern_Outputs_Draft_Sum_Order_By>;
  var_pop?: InputMaybe<Cutting_Pattern_Outputs_Draft_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Cutting_Pattern_Outputs_Draft_Var_Samp_Order_By>;
  variance?: InputMaybe<Cutting_Pattern_Outputs_Draft_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_Arr_Rel_Insert_Input = {
  data: Array<Cutting_Pattern_Outputs_Draft_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Cutting_Pattern_Outputs_Draft_On_Conflict>;
};

/** aggregate avg on columns */
export type Cutting_Pattern_Outputs_Draft_Avg_Fields = {
  __typename: 'cutting_pattern_outputs_draft_avg_fields';
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  costPricePerUnitFactor?: Maybe<Scalars['Float']>;
  cuttingPatternDraftId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sampledKgWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_Avg_Order_By = {
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  cuttingPatternDraftId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cutting_pattern_outputs_draft". All fields are combined with a logical 'AND'. */
export type Cutting_Pattern_Outputs_Draft_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Bool_Exp>>;
  _not?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
  _or?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Bigint_Comparison_Exp>;
  costPricePerUnitFactor?: InputMaybe<Numeric_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  cuttingPatternDraft?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
  cuttingPatternDraftId?: InputMaybe<Bigint_Comparison_Exp>;
  dismantlingPlanPriority?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  productUnit?: InputMaybe<Product_Units_Bool_Exp>;
  productUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  quantity?: InputMaybe<Numeric_Comparison_Exp>;
  sampledKgWeight?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "cutting_pattern_outputs_draft" */
export enum Cutting_Pattern_Outputs_Draft_Constraint {
  /** unique or primary key constraint on columns "id" */
  CuttingPatternOutputsDraftPkey = 'cutting_pattern_outputs_draft_pkey',
  /** unique or primary key constraint on columns "cutting_pattern_draft_id", "product_id" */
  CuttingPatternOutputsDraftUnique = 'cutting_pattern_outputs_draft_unique'
}

/** input type for incrementing numeric columns in table "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_Inc_Input = {
  costPricePerUnitAbsoluteMargin?: InputMaybe<Scalars['bigint']>;
  costPricePerUnitFactor?: InputMaybe<Scalars['numeric']>;
  cuttingPatternDraftId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  sampledKgWeight?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Scalars['bigint']>;
  costPricePerUnitFactor?: InputMaybe<Scalars['numeric']>;
  currency?: InputMaybe<Currencies_Enum>;
  cuttingPatternDraft?: InputMaybe<Cutting_Patterns_Draft_Obj_Rel_Insert_Input>;
  cuttingPatternDraftId?: InputMaybe<Scalars['bigint']>;
  dismantlingPlanPriority?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  productUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  sampledKgWeight?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Cutting_Pattern_Outputs_Draft_Max_Fields = {
  __typename: 'cutting_pattern_outputs_draft_max_fields';
  comment?: Maybe<Scalars['String']>;
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['bigint']>;
  costPricePerUnitFactor?: Maybe<Scalars['numeric']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  cuttingPatternDraftId?: Maybe<Scalars['bigint']>;
  dismantlingPlanPriority?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['numeric']>;
  sampledKgWeight?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  cuttingPatternDraftId?: InputMaybe<Order_By>;
  dismantlingPlanPriority?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Cutting_Pattern_Outputs_Draft_Min_Fields = {
  __typename: 'cutting_pattern_outputs_draft_min_fields';
  comment?: Maybe<Scalars['String']>;
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['bigint']>;
  costPricePerUnitFactor?: Maybe<Scalars['numeric']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  cuttingPatternDraftId?: Maybe<Scalars['bigint']>;
  dismantlingPlanPriority?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['numeric']>;
  sampledKgWeight?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  cuttingPatternDraftId?: InputMaybe<Order_By>;
  dismantlingPlanPriority?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_Mutation_Response = {
  __typename: 'cutting_pattern_outputs_draft_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cutting_Pattern_Outputs_Draft>;
};

/** on_conflict condition type for table "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_On_Conflict = {
  constraint: Cutting_Pattern_Outputs_Draft_Constraint;
  update_columns?: Array<Cutting_Pattern_Outputs_Draft_Update_Column>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
};

/** Ordering options when selecting data from "cutting_pattern_outputs_draft". */
export type Cutting_Pattern_Outputs_Draft_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  comment?: InputMaybe<Order_By>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  cuttingPatternDraft?: InputMaybe<Cutting_Patterns_Draft_Order_By>;
  cuttingPatternDraftId?: InputMaybe<Order_By>;
  dismantlingPlanPriority?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnit?: InputMaybe<Product_Units_Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cutting_pattern_outputs_draft */
export type Cutting_Pattern_Outputs_Draft_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "cutting_pattern_outputs_draft" */
export enum Cutting_Pattern_Outputs_Draft_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CostPricePerUnitAbsoluteMargin = 'costPricePerUnitAbsoluteMargin',
  /** column name */
  CostPricePerUnitFactor = 'costPricePerUnitFactor',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  CuttingPatternDraftId = 'cuttingPatternDraftId',
  /** column name */
  DismantlingPlanPriority = 'dismantlingPlanPriority',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ProductUnitId = 'productUnitId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SampledKgWeight = 'sampledKgWeight',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Scalars['bigint']>;
  costPricePerUnitFactor?: InputMaybe<Scalars['numeric']>;
  currency?: InputMaybe<Currencies_Enum>;
  cuttingPatternDraftId?: InputMaybe<Scalars['bigint']>;
  dismantlingPlanPriority?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  sampledKgWeight?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Cutting_Pattern_Outputs_Draft_Stddev_Fields = {
  __typename: 'cutting_pattern_outputs_draft_stddev_fields';
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  costPricePerUnitFactor?: Maybe<Scalars['Float']>;
  cuttingPatternDraftId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sampledKgWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_Stddev_Order_By = {
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  cuttingPatternDraftId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cutting_Pattern_Outputs_Draft_Stddev_Pop_Fields = {
  __typename: 'cutting_pattern_outputs_draft_stddev_pop_fields';
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  costPricePerUnitFactor?: Maybe<Scalars['Float']>;
  cuttingPatternDraftId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sampledKgWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_Stddev_Pop_Order_By = {
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  cuttingPatternDraftId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cutting_Pattern_Outputs_Draft_Stddev_Samp_Fields = {
  __typename: 'cutting_pattern_outputs_draft_stddev_samp_fields';
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  costPricePerUnitFactor?: Maybe<Scalars['Float']>;
  cuttingPatternDraftId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sampledKgWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_Stddev_Samp_Order_By = {
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  cuttingPatternDraftId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cutting_Pattern_Outputs_Draft_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cutting_Pattern_Outputs_Draft_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Scalars['bigint']>;
  costPricePerUnitFactor?: InputMaybe<Scalars['numeric']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Currencies_Enum>;
  cuttingPatternDraftId?: InputMaybe<Scalars['bigint']>;
  dismantlingPlanPriority?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  sampledKgWeight?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Cutting_Pattern_Outputs_Draft_Sum_Fields = {
  __typename: 'cutting_pattern_outputs_draft_sum_fields';
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['bigint']>;
  costPricePerUnitFactor?: Maybe<Scalars['numeric']>;
  cuttingPatternDraftId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['numeric']>;
  sampledKgWeight?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_Sum_Order_By = {
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  cuttingPatternDraftId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "cutting_pattern_outputs_draft" */
export enum Cutting_Pattern_Outputs_Draft_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CostPricePerUnitAbsoluteMargin = 'costPricePerUnitAbsoluteMargin',
  /** column name */
  CostPricePerUnitFactor = 'costPricePerUnitFactor',
  /** column name */
  Currency = 'currency',
  /** column name */
  CuttingPatternDraftId = 'cuttingPatternDraftId',
  /** column name */
  DismantlingPlanPriority = 'dismantlingPlanPriority',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ProductUnitId = 'productUnitId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SampledKgWeight = 'sampledKgWeight',
  /** column name */
  Version = 'version'
}

export type Cutting_Pattern_Outputs_Draft_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Cutting_Pattern_Outputs_Draft_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cutting_Pattern_Outputs_Draft_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cutting_Pattern_Outputs_Draft_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Cutting_Pattern_Outputs_Draft_Var_Pop_Fields = {
  __typename: 'cutting_pattern_outputs_draft_var_pop_fields';
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  costPricePerUnitFactor?: Maybe<Scalars['Float']>;
  cuttingPatternDraftId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sampledKgWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_Var_Pop_Order_By = {
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  cuttingPatternDraftId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cutting_Pattern_Outputs_Draft_Var_Samp_Fields = {
  __typename: 'cutting_pattern_outputs_draft_var_samp_fields';
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  costPricePerUnitFactor?: Maybe<Scalars['Float']>;
  cuttingPatternDraftId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sampledKgWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_Var_Samp_Order_By = {
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  cuttingPatternDraftId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Cutting_Pattern_Outputs_Draft_Variance_Fields = {
  __typename: 'cutting_pattern_outputs_draft_variance_fields';
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  costPricePerUnitFactor?: Maybe<Scalars['Float']>;
  cuttingPatternDraftId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sampledKgWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cutting_pattern_outputs_draft" */
export type Cutting_Pattern_Outputs_Draft_Variance_Order_By = {
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  cuttingPatternDraftId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_Inc_Input = {
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: InputMaybe<Scalars['bigint']>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: InputMaybe<Scalars['numeric']>;
  cuttingPatternId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: InputMaybe<Scalars['bigint']>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: InputMaybe<Scalars['numeric']>;
  /** Currency of cost_price_per_absolute_margin */
  currency?: InputMaybe<Currencies_Enum>;
  cuttingPattern?: InputMaybe<Cutting_Patterns_Obj_Rel_Insert_Input>;
  cuttingPatternId?: InputMaybe<Scalars['bigint']>;
  dismantlingPlanPriority?: InputMaybe<Dismantling_Plan_Priorities_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  productUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Cutting_Pattern_Outputs_Max_Fields = {
  __typename: 'cutting_pattern_outputs_max_fields';
  comment?: Maybe<Scalars['String']>;
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['bigint']>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: Maybe<Scalars['numeric']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  cuttingPatternId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  cuttingPatternId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Cutting_Pattern_Outputs_Min_Fields = {
  __typename: 'cutting_pattern_outputs_min_fields';
  comment?: Maybe<Scalars['String']>;
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['bigint']>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: Maybe<Scalars['numeric']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  cuttingPatternId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  cuttingPatternId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_Mutation_Response = {
  __typename: 'cutting_pattern_outputs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cutting_Pattern_Outputs>;
};

/** on_conflict condition type for table "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_On_Conflict = {
  constraint: Cutting_Pattern_Outputs_Constraint;
  update_columns?: Array<Cutting_Pattern_Outputs_Update_Column>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
};

/** Ordering options when selecting data from "cutting_pattern_outputs". */
export type Cutting_Pattern_Outputs_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  comment?: InputMaybe<Order_By>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  cuttingPattern?: InputMaybe<Cutting_Patterns_Order_By>;
  cuttingPatternId?: InputMaybe<Order_By>;
  dismantlingPlanPriority?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnit?: InputMaybe<Product_Units_Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cutting_pattern_outputs */
export type Cutting_Pattern_Outputs_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "cutting_pattern_outputs" */
export enum Cutting_Pattern_Outputs_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CostPricePerUnitAbsoluteMargin = 'costPricePerUnitAbsoluteMargin',
  /** column name */
  CostPricePerUnitFactor = 'costPricePerUnitFactor',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  CuttingPatternId = 'cuttingPatternId',
  /** column name */
  DismantlingPlanPriority = 'dismantlingPlanPriority',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ProductUnitId = 'productUnitId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SampledKgWeight = 'sampledKgWeight',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: InputMaybe<Scalars['bigint']>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: InputMaybe<Scalars['numeric']>;
  /** Currency of cost_price_per_absolute_margin */
  currency?: InputMaybe<Currencies_Enum>;
  cuttingPatternId?: InputMaybe<Scalars['bigint']>;
  dismantlingPlanPriority?: InputMaybe<Dismantling_Plan_Priorities_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Cutting_Pattern_Outputs_Stddev_Fields = {
  __typename: 'cutting_pattern_outputs_stddev_fields';
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: Maybe<Scalars['Float']>;
  cuttingPatternId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_Stddev_Order_By = {
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  cuttingPatternId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cutting_Pattern_Outputs_Stddev_Pop_Fields = {
  __typename: 'cutting_pattern_outputs_stddev_pop_fields';
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: Maybe<Scalars['Float']>;
  cuttingPatternId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_Stddev_Pop_Order_By = {
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  cuttingPatternId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cutting_Pattern_Outputs_Stddev_Samp_Fields = {
  __typename: 'cutting_pattern_outputs_stddev_samp_fields';
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: Maybe<Scalars['Float']>;
  cuttingPatternId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_Stddev_Samp_Order_By = {
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  cuttingPatternId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cutting_Pattern_Outputs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cutting_Pattern_Outputs_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: InputMaybe<Scalars['bigint']>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: InputMaybe<Scalars['numeric']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  /** Currency of cost_price_per_absolute_margin */
  currency?: InputMaybe<Currencies_Enum>;
  cuttingPatternId?: InputMaybe<Scalars['bigint']>;
  dismantlingPlanPriority?: InputMaybe<Dismantling_Plan_Priorities_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Cutting_Pattern_Outputs_Sum_Fields = {
  __typename: 'cutting_pattern_outputs_sum_fields';
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['bigint']>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: Maybe<Scalars['numeric']>;
  cuttingPatternId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_Sum_Order_By = {
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  cuttingPatternId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "cutting_pattern_outputs" */
export enum Cutting_Pattern_Outputs_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CostPricePerUnitAbsoluteMargin = 'costPricePerUnitAbsoluteMargin',
  /** column name */
  CostPricePerUnitFactor = 'costPricePerUnitFactor',
  /** column name */
  Currency = 'currency',
  /** column name */
  CuttingPatternId = 'cuttingPatternId',
  /** column name */
  DismantlingPlanPriority = 'dismantlingPlanPriority',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ProductUnitId = 'productUnitId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SampledKgWeight = 'sampledKgWeight',
  /** column name */
  Version = 'version'
}

export type Cutting_Pattern_Outputs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Cutting_Pattern_Outputs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cutting_Pattern_Outputs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cutting_Pattern_Outputs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Cutting_Pattern_Outputs_Var_Pop_Fields = {
  __typename: 'cutting_pattern_outputs_var_pop_fields';
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: Maybe<Scalars['Float']>;
  cuttingPatternId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_Var_Pop_Order_By = {
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  cuttingPatternId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cutting_Pattern_Outputs_Var_Samp_Fields = {
  __typename: 'cutting_pattern_outputs_var_samp_fields';
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: Maybe<Scalars['Float']>;
  cuttingPatternId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_Var_Samp_Order_By = {
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  cuttingPatternId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Cutting_Pattern_Outputs_Variance_Fields = {
  __typename: 'cutting_pattern_outputs_variance_fields';
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: Maybe<Scalars['Float']>;
  cuttingPatternId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cutting_pattern_outputs" */
export type Cutting_Pattern_Outputs_Variance_Order_By = {
  /** Scaled price to add on top of the calculate cost price. Example: 1000 => add 1 of currency to cost price */
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  /** Percentage to apply on top of the calculated cost price. Example: 1.10 => 10% margin */
  costPricePerUnitFactor?: InputMaybe<Order_By>;
  cuttingPatternId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "cutting_pattern_state_transitions" */
export type Cutting_Pattern_State_Transitions = {
  __typename: 'cutting_pattern_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Cutting_Pattern_States_Enum;
  toState: Cutting_Pattern_States_Enum;
};

/** aggregated selection of "cutting_pattern_state_transitions" */
export type Cutting_Pattern_State_Transitions_Aggregate = {
  __typename: 'cutting_pattern_state_transitions_aggregate';
  aggregate?: Maybe<Cutting_Pattern_State_Transitions_Aggregate_Fields>;
  nodes: Array<Cutting_Pattern_State_Transitions>;
};

/** aggregate fields of "cutting_pattern_state_transitions" */
export type Cutting_Pattern_State_Transitions_Aggregate_Fields = {
  __typename: 'cutting_pattern_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Cutting_Pattern_State_Transitions_Max_Fields>;
  min?: Maybe<Cutting_Pattern_State_Transitions_Min_Fields>;
};


/** aggregate fields of "cutting_pattern_state_transitions" */
export type Cutting_Pattern_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cutting_Pattern_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "cutting_pattern_state_transitions". All fields are combined with a logical 'AND'. */
export type Cutting_Pattern_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Cutting_Pattern_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Cutting_Pattern_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Cutting_Pattern_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Cutting_Pattern_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Cutting_Pattern_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Cutting_Pattern_State_Transitions_Max_Fields = {
  __typename: 'cutting_pattern_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Cutting_Pattern_State_Transitions_Min_Fields = {
  __typename: 'cutting_pattern_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "cutting_pattern_state_transitions". */
export type Cutting_Pattern_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "cutting_pattern_state_transitions" */
export enum Cutting_Pattern_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "cutting_pattern_state_transitions" */
export type Cutting_Pattern_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cutting_Pattern_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cutting_Pattern_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Cutting_Pattern_States_Enum>;
  toState?: InputMaybe<Cutting_Pattern_States_Enum>;
};

/** columns and relationships of "cutting_pattern_states" */
export type Cutting_Pattern_States = {
  __typename: 'cutting_pattern_states';
  /** An array relationship */
  cuttingPatterns: Array<Cutting_Patterns>;
  /** An array relationship */
  cuttingPatternsDrafts: Array<Cutting_Patterns_Draft>;
  /** An aggregate relationship */
  cuttingPatternsDrafts_aggregate: Cutting_Patterns_Draft_Aggregate;
  /** An aggregate relationship */
  cuttingPatterns_aggregate: Cutting_Patterns_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "cutting_pattern_states" */
export type Cutting_Pattern_StatesCuttingPatternsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Bool_Exp>;
};


/** columns and relationships of "cutting_pattern_states" */
export type Cutting_Pattern_StatesCuttingPatternsDraftsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


/** columns and relationships of "cutting_pattern_states" */
export type Cutting_Pattern_StatesCuttingPatternsDrafts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


/** columns and relationships of "cutting_pattern_states" */
export type Cutting_Pattern_StatesCuttingPatterns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Bool_Exp>;
};

/** aggregated selection of "cutting_pattern_states" */
export type Cutting_Pattern_States_Aggregate = {
  __typename: 'cutting_pattern_states_aggregate';
  aggregate?: Maybe<Cutting_Pattern_States_Aggregate_Fields>;
  nodes: Array<Cutting_Pattern_States>;
};

/** aggregate fields of "cutting_pattern_states" */
export type Cutting_Pattern_States_Aggregate_Fields = {
  __typename: 'cutting_pattern_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Cutting_Pattern_States_Max_Fields>;
  min?: Maybe<Cutting_Pattern_States_Min_Fields>;
};


/** aggregate fields of "cutting_pattern_states" */
export type Cutting_Pattern_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cutting_Pattern_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "cutting_pattern_states". All fields are combined with a logical 'AND'. */
export type Cutting_Pattern_States_Bool_Exp = {
  _and?: InputMaybe<Array<Cutting_Pattern_States_Bool_Exp>>;
  _not?: InputMaybe<Cutting_Pattern_States_Bool_Exp>;
  _or?: InputMaybe<Array<Cutting_Pattern_States_Bool_Exp>>;
  cuttingPatterns?: InputMaybe<Cutting_Patterns_Bool_Exp>;
  cuttingPatternsDrafts?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
  cuttingPatternsDrafts_aggregate?: InputMaybe<Cutting_Patterns_Draft_Aggregate_Bool_Exp>;
  cuttingPatterns_aggregate?: InputMaybe<Cutting_Patterns_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Cutting_Pattern_States_Enum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Inactive = 'INACTIVE'
}

/** Boolean expression to compare columns of type "cutting_pattern_states_enum". All fields are combined with logical 'AND'. */
export type Cutting_Pattern_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Cutting_Pattern_States_Enum>;
  _in?: InputMaybe<Array<Cutting_Pattern_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Cutting_Pattern_States_Enum>;
  _nin?: InputMaybe<Array<Cutting_Pattern_States_Enum>>;
};

/** aggregate max on columns */
export type Cutting_Pattern_States_Max_Fields = {
  __typename: 'cutting_pattern_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Cutting_Pattern_States_Min_Fields = {
  __typename: 'cutting_pattern_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "cutting_pattern_states". */
export type Cutting_Pattern_States_Order_By = {
  cuttingPatternsDrafts_aggregate?: InputMaybe<Cutting_Patterns_Draft_Aggregate_Order_By>;
  cuttingPatterns_aggregate?: InputMaybe<Cutting_Patterns_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "cutting_pattern_states" */
export enum Cutting_Pattern_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "cutting_pattern_states" */
export type Cutting_Pattern_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cutting_Pattern_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cutting_Pattern_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "cutting_patterns" */
export type Cutting_Patterns = {
  __typename: 'cutting_patterns';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** The currency of the dismantling_cost_per_kg */
  currency: Currencies_Enum;
  /** An array relationship */
  cuttingPatternOutputs: Array<Cutting_Pattern_Outputs>;
  /** An aggregate relationship */
  cuttingPatternOutputs_aggregate: Cutting_Pattern_Outputs_Aggregate;
  /** An array relationship */
  cuttingPatternsDrafts: Array<Cutting_Patterns_Draft>;
  /** An aggregate relationship */
  cuttingPatternsDrafts_aggregate: Cutting_Patterns_Draft_Aggregate;
  defaultCut: Scalars['Boolean'];
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  dismantlingPlanCuttingPatternChoices: Array<Dismantling_Plan_Cutting_Pattern_Choices>;
  /** An aggregate relationship */
  dismantlingPlanCuttingPatternChoices_aggregate: Dismantling_Plan_Cutting_Pattern_Choices_Aggregate;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency: Scalars['bigint'];
  id: Scalars['bigint'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  priority: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['bigint']>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: Maybe<Scalars['bigint']>;
  state: Cutting_Pattern_States_Enum;
  tenant: Scalars['String'];
  /** An array relationship */
  topologicallySortedCuttingPatternsResults: Array<Topologically_Sorted_Cutting_Patterns_Results>;
  /** An aggregate relationship */
  topologicallySortedCuttingPatternsResults_aggregate: Topologically_Sorted_Cutting_Patterns_Results_Aggregate;
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "cutting_patterns" */
export type Cutting_PatternsCuttingPatternOutputsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
};


/** columns and relationships of "cutting_patterns" */
export type Cutting_PatternsCuttingPatternOutputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
};


/** columns and relationships of "cutting_patterns" */
export type Cutting_PatternsCuttingPatternsDraftsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


/** columns and relationships of "cutting_patterns" */
export type Cutting_PatternsCuttingPatternsDrafts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


/** columns and relationships of "cutting_patterns" */
export type Cutting_PatternsDismantlingPlanCuttingPatternChoicesArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>;
};


/** columns and relationships of "cutting_patterns" */
export type Cutting_PatternsDismantlingPlanCuttingPatternChoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>;
};


/** columns and relationships of "cutting_patterns" */
export type Cutting_PatternsTopologicallySortedCuttingPatternsResultsArgs = {
  distinct_on?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Order_By>>;
  where?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Bool_Exp>;
};


/** columns and relationships of "cutting_patterns" */
export type Cutting_PatternsTopologicallySortedCuttingPatternsResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Order_By>>;
  where?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Bool_Exp>;
};

/** aggregated selection of "cutting_patterns" */
export type Cutting_Patterns_Aggregate = {
  __typename: 'cutting_patterns_aggregate';
  aggregate?: Maybe<Cutting_Patterns_Aggregate_Fields>;
  nodes: Array<Cutting_Patterns>;
};

export type Cutting_Patterns_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Cutting_Patterns_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Cutting_Patterns_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Cutting_Patterns_Aggregate_Bool_Exp_Count>;
};

export type Cutting_Patterns_Aggregate_Bool_Exp_Bool_And = {
  arguments: Cutting_Patterns_Select_Column_Cutting_Patterns_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cutting_Patterns_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Cutting_Patterns_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Cutting_Patterns_Select_Column_Cutting_Patterns_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cutting_Patterns_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Cutting_Patterns_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Cutting_Patterns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cutting_Patterns_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "cutting_patterns" */
export type Cutting_Patterns_Aggregate_Fields = {
  __typename: 'cutting_patterns_aggregate_fields';
  avg?: Maybe<Cutting_Patterns_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Cutting_Patterns_Max_Fields>;
  min?: Maybe<Cutting_Patterns_Min_Fields>;
  stddev?: Maybe<Cutting_Patterns_Stddev_Fields>;
  stddev_pop?: Maybe<Cutting_Patterns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cutting_Patterns_Stddev_Samp_Fields>;
  sum?: Maybe<Cutting_Patterns_Sum_Fields>;
  var_pop?: Maybe<Cutting_Patterns_Var_Pop_Fields>;
  var_samp?: Maybe<Cutting_Patterns_Var_Samp_Fields>;
  variance?: Maybe<Cutting_Patterns_Variance_Fields>;
};


/** aggregate fields of "cutting_patterns" */
export type Cutting_Patterns_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cutting_Patterns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cutting_patterns" */
export type Cutting_Patterns_Aggregate_Order_By = {
  avg?: InputMaybe<Cutting_Patterns_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Cutting_Patterns_Max_Order_By>;
  min?: InputMaybe<Cutting_Patterns_Min_Order_By>;
  stddev?: InputMaybe<Cutting_Patterns_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Cutting_Patterns_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Cutting_Patterns_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Cutting_Patterns_Sum_Order_By>;
  var_pop?: InputMaybe<Cutting_Patterns_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Cutting_Patterns_Var_Samp_Order_By>;
  variance?: InputMaybe<Cutting_Patterns_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "cutting_patterns" */
export type Cutting_Patterns_Arr_Rel_Insert_Input = {
  data: Array<Cutting_Patterns_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Cutting_Patterns_On_Conflict>;
};

/** aggregate avg on columns */
export type Cutting_Patterns_Avg_Fields = {
  __typename: 'cutting_patterns_avg_fields';
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: Maybe<Scalars['Float']>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['Float']>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cutting_patterns" */
export type Cutting_Patterns_Avg_Order_By = {
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Order_By>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cutting_patterns". All fields are combined with a logical 'AND'. */
export type Cutting_Patterns_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Cutting_Patterns_Bool_Exp>>;
  _not?: InputMaybe<Cutting_Patterns_Bool_Exp>;
  _or?: InputMaybe<Array<Cutting_Patterns_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  cuttingPatternOutputs?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
  cuttingPatternOutputs_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Aggregate_Bool_Exp>;
  cuttingPatternsDrafts?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
  cuttingPatternsDrafts_aggregate?: InputMaybe<Cutting_Patterns_Draft_Aggregate_Bool_Exp>;
  defaultCut?: InputMaybe<Boolean_Comparison_Exp>;
  dismantlingCostPerKg?: InputMaybe<Bigint_Comparison_Exp>;
  dismantlingPlanCuttingPatternChoices?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>;
  dismantlingPlanCuttingPatternChoices_aggregate?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_Bool_Exp>;
  executionFrequency?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  priority?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  sampledKgWeight?: InputMaybe<Bigint_Comparison_Exp>;
  sampledPieces?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<Cutting_Pattern_States_Enum_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  topologicallySortedCuttingPatternsResults?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Bool_Exp>;
  topologicallySortedCuttingPatternsResults_aggregate?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "cutting_patterns" */
export enum Cutting_Patterns_Constraint {
  /** unique or primary key constraint on columns "name", "tenant" */
  CuttingPatternsNameKey = 'cutting_patterns_name_key',
  /** unique or primary key constraint on columns "id" */
  CuttingPatternsPkey = 'cutting_patterns_pkey',
  /** unique or primary key constraint on columns "product_id", "tenant", "priority" */
  CuttingPatternsTenantProductIdPriorityKey = 'cutting_patterns_tenant_product_id_priority_key',
  /** unique or primary key constraint on columns "product_id" */
  OneDefaultCutPerProduct = 'one_default_cut_per_product'
}

/** columns and relationships of "cutting_patterns_draft" */
export type Cutting_Patterns_Draft = {
  __typename: 'cutting_patterns_draft';
  /** An object relationship */
  Tenant: Tenants;
  action: Cutting_Pattern_Draft_Actions_Enum;
  approveRequestedAt?: Maybe<Scalars['timestamptz']>;
  approveRequestedBy?: Maybe<Scalars['bigint']>;
  approvedAt?: Maybe<Scalars['timestamptz']>;
  approvedBy?: Maybe<Scalars['bigint']>;
  createdAt: Scalars['timestamptz'];
  currency: Currencies_Enum;
  /** An array relationship */
  cuttingPatternOutputsDrafts: Array<Cutting_Pattern_Outputs_Draft>;
  /** An aggregate relationship */
  cuttingPatternOutputsDrafts_aggregate: Cutting_Pattern_Outputs_Draft_Aggregate;
  defaultCut: Scalars['Boolean'];
  dismantlingCostPerKg?: Maybe<Scalars['bigint']>;
  draftState: Cutting_Pattern_Draft_States_Enum;
  executionFrequency: Scalars['bigint'];
  id: Scalars['bigint'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  originalCuttingPattern?: Maybe<Cutting_Patterns>;
  originalCuttingPatternId?: Maybe<Scalars['bigint']>;
  priority: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  revision: Scalars['bigint'];
  sampledKgWeight?: Maybe<Scalars['bigint']>;
  sampledPieces?: Maybe<Scalars['bigint']>;
  state: Cutting_Pattern_States_Enum;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  validFrom?: Maybe<Scalars['timestamptz']>;
  version: Scalars['bigint'];
};


/** columns and relationships of "cutting_patterns_draft" */
export type Cutting_Patterns_DraftCuttingPatternOutputsDraftsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
};


/** columns and relationships of "cutting_patterns_draft" */
export type Cutting_Patterns_DraftCuttingPatternOutputsDrafts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
};

/** aggregated selection of "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Aggregate = {
  __typename: 'cutting_patterns_draft_aggregate';
  aggregate?: Maybe<Cutting_Patterns_Draft_Aggregate_Fields>;
  nodes: Array<Cutting_Patterns_Draft>;
};

export type Cutting_Patterns_Draft_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Cutting_Patterns_Draft_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Cutting_Patterns_Draft_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Cutting_Patterns_Draft_Aggregate_Bool_Exp_Count>;
};

export type Cutting_Patterns_Draft_Aggregate_Bool_Exp_Bool_And = {
  arguments: Cutting_Patterns_Draft_Select_Column_Cutting_Patterns_Draft_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Cutting_Patterns_Draft_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Cutting_Patterns_Draft_Select_Column_Cutting_Patterns_Draft_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Cutting_Patterns_Draft_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Aggregate_Fields = {
  __typename: 'cutting_patterns_draft_aggregate_fields';
  avg?: Maybe<Cutting_Patterns_Draft_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Cutting_Patterns_Draft_Max_Fields>;
  min?: Maybe<Cutting_Patterns_Draft_Min_Fields>;
  stddev?: Maybe<Cutting_Patterns_Draft_Stddev_Fields>;
  stddev_pop?: Maybe<Cutting_Patterns_Draft_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cutting_Patterns_Draft_Stddev_Samp_Fields>;
  sum?: Maybe<Cutting_Patterns_Draft_Sum_Fields>;
  var_pop?: Maybe<Cutting_Patterns_Draft_Var_Pop_Fields>;
  var_samp?: Maybe<Cutting_Patterns_Draft_Var_Samp_Fields>;
  variance?: Maybe<Cutting_Patterns_Draft_Variance_Fields>;
};


/** aggregate fields of "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Aggregate_Order_By = {
  avg?: InputMaybe<Cutting_Patterns_Draft_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Cutting_Patterns_Draft_Max_Order_By>;
  min?: InputMaybe<Cutting_Patterns_Draft_Min_Order_By>;
  stddev?: InputMaybe<Cutting_Patterns_Draft_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Cutting_Patterns_Draft_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Cutting_Patterns_Draft_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Cutting_Patterns_Draft_Sum_Order_By>;
  var_pop?: InputMaybe<Cutting_Patterns_Draft_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Cutting_Patterns_Draft_Var_Samp_Order_By>;
  variance?: InputMaybe<Cutting_Patterns_Draft_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Arr_Rel_Insert_Input = {
  data: Array<Cutting_Patterns_Draft_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Cutting_Patterns_Draft_On_Conflict>;
};

/** aggregate avg on columns */
export type Cutting_Patterns_Draft_Avg_Fields = {
  __typename: 'cutting_patterns_draft_avg_fields';
  approveRequestedBy?: Maybe<Scalars['Float']>;
  approvedBy?: Maybe<Scalars['Float']>;
  dismantlingCostPerKg?: Maybe<Scalars['Float']>;
  executionFrequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  originalCuttingPatternId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  sampledKgWeight?: Maybe<Scalars['Float']>;
  sampledPieces?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Avg_Order_By = {
  approveRequestedBy?: InputMaybe<Order_By>;
  approvedBy?: InputMaybe<Order_By>;
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  originalCuttingPatternId?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  sampledPieces?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cutting_patterns_draft". All fields are combined with a logical 'AND'. */
export type Cutting_Patterns_Draft_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Cutting_Patterns_Draft_Bool_Exp>>;
  _not?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
  _or?: InputMaybe<Array<Cutting_Patterns_Draft_Bool_Exp>>;
  action?: InputMaybe<Cutting_Pattern_Draft_Actions_Enum_Comparison_Exp>;
  approveRequestedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  approveRequestedBy?: InputMaybe<Bigint_Comparison_Exp>;
  approvedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  approvedBy?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  cuttingPatternOutputsDrafts?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
  cuttingPatternOutputsDrafts_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Draft_Aggregate_Bool_Exp>;
  defaultCut?: InputMaybe<Boolean_Comparison_Exp>;
  dismantlingCostPerKg?: InputMaybe<Bigint_Comparison_Exp>;
  draftState?: InputMaybe<Cutting_Pattern_Draft_States_Enum_Comparison_Exp>;
  executionFrequency?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  originalCuttingPattern?: InputMaybe<Cutting_Patterns_Bool_Exp>;
  originalCuttingPatternId?: InputMaybe<Bigint_Comparison_Exp>;
  priority?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  revision?: InputMaybe<Bigint_Comparison_Exp>;
  sampledKgWeight?: InputMaybe<Bigint_Comparison_Exp>;
  sampledPieces?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<Cutting_Pattern_States_Enum_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  validFrom?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "cutting_patterns_draft" */
export enum Cutting_Patterns_Draft_Constraint {
  /** unique or primary key constraint on columns "name", "revision", "tenant" */
  CuttingPatternsDraftNameKey = 'cutting_patterns_draft_name_key',
  /** unique or primary key constraint on columns "id" */
  CuttingPatternsDraftPkey = 'cutting_patterns_draft_pkey',
  /** unique or primary key constraint on columns "revision", "product_id" */
  OneDefaultCutPerProductDraft = 'one_default_cut_per_product_draft',
  /** unique or primary key constraint on columns "revision", "original_cutting_pattern_id" */
  OneDraftPerCuttingPattern = 'one_draft_per_cutting_pattern'
}

/** input type for incrementing numeric columns in table "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Inc_Input = {
  approveRequestedBy?: InputMaybe<Scalars['bigint']>;
  approvedBy?: InputMaybe<Scalars['bigint']>;
  dismantlingCostPerKg?: InputMaybe<Scalars['bigint']>;
  executionFrequency?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  originalCuttingPatternId?: InputMaybe<Scalars['bigint']>;
  priority?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  revision?: InputMaybe<Scalars['bigint']>;
  sampledKgWeight?: InputMaybe<Scalars['bigint']>;
  sampledPieces?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Insert_Input = {
  action?: InputMaybe<Cutting_Pattern_Draft_Actions_Enum>;
  approveRequestedAt?: InputMaybe<Scalars['timestamptz']>;
  approveRequestedBy?: InputMaybe<Scalars['bigint']>;
  approvedAt?: InputMaybe<Scalars['timestamptz']>;
  approvedBy?: InputMaybe<Scalars['bigint']>;
  currency?: InputMaybe<Currencies_Enum>;
  cuttingPatternOutputsDrafts?: InputMaybe<Cutting_Pattern_Outputs_Draft_Arr_Rel_Insert_Input>;
  defaultCut?: InputMaybe<Scalars['Boolean']>;
  dismantlingCostPerKg?: InputMaybe<Scalars['bigint']>;
  draftState?: InputMaybe<Cutting_Pattern_Draft_States_Enum>;
  executionFrequency?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  originalCuttingPattern?: InputMaybe<Cutting_Patterns_Obj_Rel_Insert_Input>;
  originalCuttingPatternId?: InputMaybe<Scalars['bigint']>;
  priority?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  revision?: InputMaybe<Scalars['bigint']>;
  sampledKgWeight?: InputMaybe<Scalars['bigint']>;
  sampledPieces?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Cutting_Pattern_States_Enum>;
  validFrom?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Cutting_Patterns_Draft_Max_Fields = {
  __typename: 'cutting_patterns_draft_max_fields';
  approveRequestedAt?: Maybe<Scalars['timestamptz']>;
  approveRequestedBy?: Maybe<Scalars['bigint']>;
  approvedAt?: Maybe<Scalars['timestamptz']>;
  approvedBy?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dismantlingCostPerKg?: Maybe<Scalars['bigint']>;
  executionFrequency?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  originalCuttingPatternId?: Maybe<Scalars['bigint']>;
  priority?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  revision?: Maybe<Scalars['bigint']>;
  sampledKgWeight?: Maybe<Scalars['bigint']>;
  sampledPieces?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Max_Order_By = {
  approveRequestedAt?: InputMaybe<Order_By>;
  approveRequestedBy?: InputMaybe<Order_By>;
  approvedAt?: InputMaybe<Order_By>;
  approvedBy?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  originalCuttingPatternId?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  sampledPieces?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  validFrom?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Cutting_Patterns_Draft_Min_Fields = {
  __typename: 'cutting_patterns_draft_min_fields';
  approveRequestedAt?: Maybe<Scalars['timestamptz']>;
  approveRequestedBy?: Maybe<Scalars['bigint']>;
  approvedAt?: Maybe<Scalars['timestamptz']>;
  approvedBy?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dismantlingCostPerKg?: Maybe<Scalars['bigint']>;
  executionFrequency?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  originalCuttingPatternId?: Maybe<Scalars['bigint']>;
  priority?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  revision?: Maybe<Scalars['bigint']>;
  sampledKgWeight?: Maybe<Scalars['bigint']>;
  sampledPieces?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Min_Order_By = {
  approveRequestedAt?: InputMaybe<Order_By>;
  approveRequestedBy?: InputMaybe<Order_By>;
  approvedAt?: InputMaybe<Order_By>;
  approvedBy?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  originalCuttingPatternId?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  sampledPieces?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  validFrom?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Mutation_Response = {
  __typename: 'cutting_patterns_draft_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cutting_Patterns_Draft>;
};

/** input type for inserting object relation for remote table "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Obj_Rel_Insert_Input = {
  data: Cutting_Patterns_Draft_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Cutting_Patterns_Draft_On_Conflict>;
};

/** on_conflict condition type for table "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_On_Conflict = {
  constraint: Cutting_Patterns_Draft_Constraint;
  update_columns?: Array<Cutting_Patterns_Draft_Update_Column>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};

/** Ordering options when selecting data from "cutting_patterns_draft". */
export type Cutting_Patterns_Draft_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  action?: InputMaybe<Order_By>;
  approveRequestedAt?: InputMaybe<Order_By>;
  approveRequestedBy?: InputMaybe<Order_By>;
  approvedAt?: InputMaybe<Order_By>;
  approvedBy?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  cuttingPatternOutputsDrafts_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Draft_Aggregate_Order_By>;
  defaultCut?: InputMaybe<Order_By>;
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  draftState?: InputMaybe<Order_By>;
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  originalCuttingPattern?: InputMaybe<Cutting_Patterns_Order_By>;
  originalCuttingPatternId?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  sampledPieces?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  validFrom?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cutting_patterns_draft */
export type Cutting_Patterns_Draft_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "cutting_patterns_draft" */
export enum Cutting_Patterns_Draft_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ApproveRequestedAt = 'approveRequestedAt',
  /** column name */
  ApproveRequestedBy = 'approveRequestedBy',
  /** column name */
  ApprovedAt = 'approvedAt',
  /** column name */
  ApprovedBy = 'approvedBy',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  DefaultCut = 'defaultCut',
  /** column name */
  DismantlingCostPerKg = 'dismantlingCostPerKg',
  /** column name */
  DraftState = 'draftState',
  /** column name */
  ExecutionFrequency = 'executionFrequency',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  OriginalCuttingPatternId = 'originalCuttingPatternId',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Revision = 'revision',
  /** column name */
  SampledKgWeight = 'sampledKgWeight',
  /** column name */
  SampledPieces = 'sampledPieces',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ValidFrom = 'validFrom',
  /** column name */
  Version = 'version'
}

/** select "cutting_patterns_draft_aggregate_bool_exp_bool_and_arguments_columns" columns of table "cutting_patterns_draft" */
export enum Cutting_Patterns_Draft_Select_Column_Cutting_Patterns_Draft_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  DefaultCut = 'defaultCut'
}

/** select "cutting_patterns_draft_aggregate_bool_exp_bool_or_arguments_columns" columns of table "cutting_patterns_draft" */
export enum Cutting_Patterns_Draft_Select_Column_Cutting_Patterns_Draft_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  DefaultCut = 'defaultCut'
}

/** input type for updating data in table "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Set_Input = {
  action?: InputMaybe<Cutting_Pattern_Draft_Actions_Enum>;
  approveRequestedAt?: InputMaybe<Scalars['timestamptz']>;
  approveRequestedBy?: InputMaybe<Scalars['bigint']>;
  approvedAt?: InputMaybe<Scalars['timestamptz']>;
  approvedBy?: InputMaybe<Scalars['bigint']>;
  currency?: InputMaybe<Currencies_Enum>;
  defaultCut?: InputMaybe<Scalars['Boolean']>;
  dismantlingCostPerKg?: InputMaybe<Scalars['bigint']>;
  draftState?: InputMaybe<Cutting_Pattern_Draft_States_Enum>;
  executionFrequency?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  originalCuttingPatternId?: InputMaybe<Scalars['bigint']>;
  priority?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  revision?: InputMaybe<Scalars['bigint']>;
  sampledKgWeight?: InputMaybe<Scalars['bigint']>;
  sampledPieces?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Cutting_Pattern_States_Enum>;
  validFrom?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Cutting_Patterns_Draft_Stddev_Fields = {
  __typename: 'cutting_patterns_draft_stddev_fields';
  approveRequestedBy?: Maybe<Scalars['Float']>;
  approvedBy?: Maybe<Scalars['Float']>;
  dismantlingCostPerKg?: Maybe<Scalars['Float']>;
  executionFrequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  originalCuttingPatternId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  sampledKgWeight?: Maybe<Scalars['Float']>;
  sampledPieces?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Stddev_Order_By = {
  approveRequestedBy?: InputMaybe<Order_By>;
  approvedBy?: InputMaybe<Order_By>;
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  originalCuttingPatternId?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  sampledPieces?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cutting_Patterns_Draft_Stddev_Pop_Fields = {
  __typename: 'cutting_patterns_draft_stddev_pop_fields';
  approveRequestedBy?: Maybe<Scalars['Float']>;
  approvedBy?: Maybe<Scalars['Float']>;
  dismantlingCostPerKg?: Maybe<Scalars['Float']>;
  executionFrequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  originalCuttingPatternId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  sampledKgWeight?: Maybe<Scalars['Float']>;
  sampledPieces?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Stddev_Pop_Order_By = {
  approveRequestedBy?: InputMaybe<Order_By>;
  approvedBy?: InputMaybe<Order_By>;
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  originalCuttingPatternId?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  sampledPieces?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cutting_Patterns_Draft_Stddev_Samp_Fields = {
  __typename: 'cutting_patterns_draft_stddev_samp_fields';
  approveRequestedBy?: Maybe<Scalars['Float']>;
  approvedBy?: Maybe<Scalars['Float']>;
  dismantlingCostPerKg?: Maybe<Scalars['Float']>;
  executionFrequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  originalCuttingPatternId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  sampledKgWeight?: Maybe<Scalars['Float']>;
  sampledPieces?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Stddev_Samp_Order_By = {
  approveRequestedBy?: InputMaybe<Order_By>;
  approvedBy?: InputMaybe<Order_By>;
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  originalCuttingPatternId?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  sampledPieces?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cutting_Patterns_Draft_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cutting_Patterns_Draft_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Cutting_Pattern_Draft_Actions_Enum>;
  approveRequestedAt?: InputMaybe<Scalars['timestamptz']>;
  approveRequestedBy?: InputMaybe<Scalars['bigint']>;
  approvedAt?: InputMaybe<Scalars['timestamptz']>;
  approvedBy?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Currencies_Enum>;
  defaultCut?: InputMaybe<Scalars['Boolean']>;
  dismantlingCostPerKg?: InputMaybe<Scalars['bigint']>;
  draftState?: InputMaybe<Cutting_Pattern_Draft_States_Enum>;
  executionFrequency?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  originalCuttingPatternId?: InputMaybe<Scalars['bigint']>;
  priority?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  revision?: InputMaybe<Scalars['bigint']>;
  sampledKgWeight?: InputMaybe<Scalars['bigint']>;
  sampledPieces?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Cutting_Pattern_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  validFrom?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Cutting_Patterns_Draft_Sum_Fields = {
  __typename: 'cutting_patterns_draft_sum_fields';
  approveRequestedBy?: Maybe<Scalars['bigint']>;
  approvedBy?: Maybe<Scalars['bigint']>;
  dismantlingCostPerKg?: Maybe<Scalars['bigint']>;
  executionFrequency?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  originalCuttingPatternId?: Maybe<Scalars['bigint']>;
  priority?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  revision?: Maybe<Scalars['bigint']>;
  sampledKgWeight?: Maybe<Scalars['bigint']>;
  sampledPieces?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Sum_Order_By = {
  approveRequestedBy?: InputMaybe<Order_By>;
  approvedBy?: InputMaybe<Order_By>;
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  originalCuttingPatternId?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  sampledPieces?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "cutting_patterns_draft" */
export enum Cutting_Patterns_Draft_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ApproveRequestedAt = 'approveRequestedAt',
  /** column name */
  ApproveRequestedBy = 'approveRequestedBy',
  /** column name */
  ApprovedAt = 'approvedAt',
  /** column name */
  ApprovedBy = 'approvedBy',
  /** column name */
  Currency = 'currency',
  /** column name */
  DefaultCut = 'defaultCut',
  /** column name */
  DismantlingCostPerKg = 'dismantlingCostPerKg',
  /** column name */
  DraftState = 'draftState',
  /** column name */
  ExecutionFrequency = 'executionFrequency',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  OriginalCuttingPatternId = 'originalCuttingPatternId',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Revision = 'revision',
  /** column name */
  SampledKgWeight = 'sampledKgWeight',
  /** column name */
  SampledPieces = 'sampledPieces',
  /** column name */
  State = 'state',
  /** column name */
  ValidFrom = 'validFrom',
  /** column name */
  Version = 'version'
}

export type Cutting_Patterns_Draft_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Cutting_Patterns_Draft_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cutting_Patterns_Draft_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cutting_Patterns_Draft_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Cutting_Patterns_Draft_Var_Pop_Fields = {
  __typename: 'cutting_patterns_draft_var_pop_fields';
  approveRequestedBy?: Maybe<Scalars['Float']>;
  approvedBy?: Maybe<Scalars['Float']>;
  dismantlingCostPerKg?: Maybe<Scalars['Float']>;
  executionFrequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  originalCuttingPatternId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  sampledKgWeight?: Maybe<Scalars['Float']>;
  sampledPieces?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Var_Pop_Order_By = {
  approveRequestedBy?: InputMaybe<Order_By>;
  approvedBy?: InputMaybe<Order_By>;
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  originalCuttingPatternId?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  sampledPieces?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cutting_Patterns_Draft_Var_Samp_Fields = {
  __typename: 'cutting_patterns_draft_var_samp_fields';
  approveRequestedBy?: Maybe<Scalars['Float']>;
  approvedBy?: Maybe<Scalars['Float']>;
  dismantlingCostPerKg?: Maybe<Scalars['Float']>;
  executionFrequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  originalCuttingPatternId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  sampledKgWeight?: Maybe<Scalars['Float']>;
  sampledPieces?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Var_Samp_Order_By = {
  approveRequestedBy?: InputMaybe<Order_By>;
  approvedBy?: InputMaybe<Order_By>;
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  originalCuttingPatternId?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  sampledPieces?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Cutting_Patterns_Draft_Variance_Fields = {
  __typename: 'cutting_patterns_draft_variance_fields';
  approveRequestedBy?: Maybe<Scalars['Float']>;
  approvedBy?: Maybe<Scalars['Float']>;
  dismantlingCostPerKg?: Maybe<Scalars['Float']>;
  executionFrequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  originalCuttingPatternId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  sampledKgWeight?: Maybe<Scalars['Float']>;
  sampledPieces?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cutting_patterns_draft" */
export type Cutting_Patterns_Draft_Variance_Order_By = {
  approveRequestedBy?: InputMaybe<Order_By>;
  approvedBy?: InputMaybe<Order_By>;
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  originalCuttingPatternId?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  sampledPieces?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "cutting_patterns" */
export type Cutting_Patterns_Inc_Input = {
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: InputMaybe<Scalars['bigint']>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  priority?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Scalars['bigint']>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "cutting_patterns" */
export type Cutting_Patterns_Insert_Input = {
  /** The currency of the dismantling_cost_per_kg */
  currency?: InputMaybe<Currencies_Enum>;
  cuttingPatternOutputs?: InputMaybe<Cutting_Pattern_Outputs_Arr_Rel_Insert_Input>;
  cuttingPatternsDrafts?: InputMaybe<Cutting_Patterns_Draft_Arr_Rel_Insert_Input>;
  defaultCut?: InputMaybe<Scalars['Boolean']>;
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: InputMaybe<Scalars['bigint']>;
  dismantlingPlanCuttingPatternChoices?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Arr_Rel_Insert_Input>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Scalars['bigint']>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Cutting_Pattern_States_Enum>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Cutting_Patterns_Max_Fields = {
  __typename: 'cutting_patterns_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: Maybe<Scalars['bigint']>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['bigint']>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "cutting_patterns" */
export type Cutting_Patterns_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Order_By>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Cutting_Patterns_Min_Fields = {
  __typename: 'cutting_patterns_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: Maybe<Scalars['bigint']>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['bigint']>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "cutting_patterns" */
export type Cutting_Patterns_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Order_By>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "cutting_patterns" */
export type Cutting_Patterns_Mutation_Response = {
  __typename: 'cutting_patterns_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cutting_Patterns>;
};

/** input type for inserting object relation for remote table "cutting_patterns" */
export type Cutting_Patterns_Obj_Rel_Insert_Input = {
  data: Cutting_Patterns_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Cutting_Patterns_On_Conflict>;
};

/** on_conflict condition type for table "cutting_patterns" */
export type Cutting_Patterns_On_Conflict = {
  constraint: Cutting_Patterns_Constraint;
  update_columns?: Array<Cutting_Patterns_Update_Column>;
  where?: InputMaybe<Cutting_Patterns_Bool_Exp>;
};

/** Ordering options when selecting data from "cutting_patterns". */
export type Cutting_Patterns_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  cuttingPatternOutputs_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Aggregate_Order_By>;
  cuttingPatternsDrafts_aggregate?: InputMaybe<Cutting_Patterns_Draft_Aggregate_Order_By>;
  defaultCut?: InputMaybe<Order_By>;
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  dismantlingPlanCuttingPatternChoices_aggregate?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_Order_By>;
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  sampledKgWeight?: InputMaybe<Order_By>;
  sampledPieces?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  topologicallySortedCuttingPatternsResults_aggregate?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cutting_patterns */
export type Cutting_Patterns_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "cutting_patterns" */
export enum Cutting_Patterns_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  DefaultCut = 'defaultCut',
  /** column name */
  DismantlingCostPerKg = 'dismantlingCostPerKg',
  /** column name */
  ExecutionFrequency = 'executionFrequency',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProductId = 'productId',
  /** column name */
  SampledKgWeight = 'sampledKgWeight',
  /** column name */
  SampledPieces = 'sampledPieces',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** select "cutting_patterns_aggregate_bool_exp_bool_and_arguments_columns" columns of table "cutting_patterns" */
export enum Cutting_Patterns_Select_Column_Cutting_Patterns_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  DefaultCut = 'defaultCut'
}

/** select "cutting_patterns_aggregate_bool_exp_bool_or_arguments_columns" columns of table "cutting_patterns" */
export enum Cutting_Patterns_Select_Column_Cutting_Patterns_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  DefaultCut = 'defaultCut'
}

/** input type for updating data in table "cutting_patterns" */
export type Cutting_Patterns_Set_Input = {
  /** The currency of the dismantling_cost_per_kg */
  currency?: InputMaybe<Currencies_Enum>;
  defaultCut?: InputMaybe<Scalars['Boolean']>;
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: InputMaybe<Scalars['bigint']>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Scalars['bigint']>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Cutting_Pattern_States_Enum>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Cutting_Patterns_Stddev_Fields = {
  __typename: 'cutting_patterns_stddev_fields';
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: Maybe<Scalars['Float']>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['Float']>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cutting_patterns" */
export type Cutting_Patterns_Stddev_Order_By = {
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Order_By>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cutting_Patterns_Stddev_Pop_Fields = {
  __typename: 'cutting_patterns_stddev_pop_fields';
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: Maybe<Scalars['Float']>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['Float']>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cutting_patterns" */
export type Cutting_Patterns_Stddev_Pop_Order_By = {
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Order_By>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cutting_Patterns_Stddev_Samp_Fields = {
  __typename: 'cutting_patterns_stddev_samp_fields';
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: Maybe<Scalars['Float']>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['Float']>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cutting_patterns" */
export type Cutting_Patterns_Stddev_Samp_Order_By = {
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Order_By>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "cutting_patterns" */
export type Cutting_Patterns_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cutting_Patterns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cutting_Patterns_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  /** The currency of the dismantling_cost_per_kg */
  currency?: InputMaybe<Currencies_Enum>;
  defaultCut?: InputMaybe<Scalars['Boolean']>;
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: InputMaybe<Scalars['bigint']>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Scalars['bigint']>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Cutting_Pattern_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Cutting_Patterns_Sum_Fields = {
  __typename: 'cutting_patterns_sum_fields';
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: Maybe<Scalars['bigint']>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  priority?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['bigint']>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "cutting_patterns" */
export type Cutting_Patterns_Sum_Order_By = {
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Order_By>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "cutting_patterns" */
export enum Cutting_Patterns_Update_Column {
  /** column name */
  Currency = 'currency',
  /** column name */
  DefaultCut = 'defaultCut',
  /** column name */
  DismantlingCostPerKg = 'dismantlingCostPerKg',
  /** column name */
  ExecutionFrequency = 'executionFrequency',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProductId = 'productId',
  /** column name */
  SampledKgWeight = 'sampledKgWeight',
  /** column name */
  SampledPieces = 'sampledPieces',
  /** column name */
  State = 'state',
  /** column name */
  Version = 'version'
}

export type Cutting_Patterns_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Cutting_Patterns_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cutting_Patterns_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cutting_Patterns_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Cutting_Patterns_Var_Pop_Fields = {
  __typename: 'cutting_patterns_var_pop_fields';
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: Maybe<Scalars['Float']>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['Float']>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cutting_patterns" */
export type Cutting_Patterns_Var_Pop_Order_By = {
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Order_By>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cutting_Patterns_Var_Samp_Fields = {
  __typename: 'cutting_patterns_var_samp_fields';
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: Maybe<Scalars['Float']>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['Float']>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cutting_patterns" */
export type Cutting_Patterns_Var_Samp_Order_By = {
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Order_By>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Cutting_Patterns_Variance_Fields = {
  __typename: 'cutting_patterns_variance_fields';
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: Maybe<Scalars['Float']>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: Maybe<Scalars['Float']>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cutting_patterns" */
export type Cutting_Patterns_Variance_Order_By = {
  /** How much does it cost to execute this cut per kg of the records product_id */
  dismantlingCostPerKg?: InputMaybe<Order_By>;
  /** Unscaled number of times this cut is executed. Only makes sense in relation for all cutting patterns for a given CP (relative weight). Used for pricing calculations. */
  executionFrequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  /** How much did the sampled pieces weigh in scaled numbered kg. Used for pricing calculations. */
  sampledKgWeight?: InputMaybe<Order_By>;
  /** How many (scaled number) pieces of this cut was measured during sampling. Used for pricing calculations. */
  sampledPieces?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "daily_price_lists" */
export type Daily_Price_Lists = {
  __typename: 'daily_price_lists';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['numeric']>;
  costPriceChange?: Maybe<Scalars['bigint']>;
  costPricePercentageChange?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  customerGroupPricing?: Maybe<Customer_Group_Pricing>;
  customerGroupPricingId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  lastCustomerGroupPricing?: Maybe<Customer_Group_Pricing>;
  lastCustomerGroupPricingId?: Maybe<Scalars['bigint']>;
  marginAbsolutePercentageChange?: Maybe<Scalars['numeric']>;
  marginChange?: Maybe<Scalars['bigint']>;
  marginPercentageChange?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  salesPriceAbsolutePercentageChange?: Maybe<Scalars['numeric']>;
  salesPriceChange?: Maybe<Scalars['bigint']>;
  salesPricePercentageChange?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "daily_price_lists" */
export type Daily_Price_Lists_Aggregate = {
  __typename: 'daily_price_lists_aggregate';
  aggregate?: Maybe<Daily_Price_Lists_Aggregate_Fields>;
  nodes: Array<Daily_Price_Lists>;
};

export type Daily_Price_Lists_Aggregate_Bool_Exp = {
  count?: InputMaybe<Daily_Price_Lists_Aggregate_Bool_Exp_Count>;
};

export type Daily_Price_Lists_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Daily_Price_Lists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Daily_Price_Lists_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "daily_price_lists" */
export type Daily_Price_Lists_Aggregate_Fields = {
  __typename: 'daily_price_lists_aggregate_fields';
  avg?: Maybe<Daily_Price_Lists_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Daily_Price_Lists_Max_Fields>;
  min?: Maybe<Daily_Price_Lists_Min_Fields>;
  stddev?: Maybe<Daily_Price_Lists_Stddev_Fields>;
  stddev_pop?: Maybe<Daily_Price_Lists_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Daily_Price_Lists_Stddev_Samp_Fields>;
  sum?: Maybe<Daily_Price_Lists_Sum_Fields>;
  var_pop?: Maybe<Daily_Price_Lists_Var_Pop_Fields>;
  var_samp?: Maybe<Daily_Price_Lists_Var_Samp_Fields>;
  variance?: Maybe<Daily_Price_Lists_Variance_Fields>;
};


/** aggregate fields of "daily_price_lists" */
export type Daily_Price_Lists_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Daily_Price_Lists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "daily_price_lists" */
export type Daily_Price_Lists_Aggregate_Order_By = {
  avg?: InputMaybe<Daily_Price_Lists_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Daily_Price_Lists_Max_Order_By>;
  min?: InputMaybe<Daily_Price_Lists_Min_Order_By>;
  stddev?: InputMaybe<Daily_Price_Lists_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Daily_Price_Lists_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Daily_Price_Lists_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Daily_Price_Lists_Sum_Order_By>;
  var_pop?: InputMaybe<Daily_Price_Lists_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Daily_Price_Lists_Var_Samp_Order_By>;
  variance?: InputMaybe<Daily_Price_Lists_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Daily_Price_Lists_Avg_Fields = {
  __typename: 'daily_price_lists_avg_fields';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  costPriceChange?: Maybe<Scalars['Float']>;
  costPricePercentageChange?: Maybe<Scalars['Float']>;
  customerGroupPricingId?: Maybe<Scalars['Float']>;
  lastCustomerGroupPricingId?: Maybe<Scalars['Float']>;
  marginAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  marginChange?: Maybe<Scalars['Float']>;
  marginPercentageChange?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  salesPriceChange?: Maybe<Scalars['Float']>;
  salesPricePercentageChange?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "daily_price_lists" */
export type Daily_Price_Lists_Avg_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  customerGroupPricingId?: InputMaybe<Order_By>;
  lastCustomerGroupPricingId?: InputMaybe<Order_By>;
  marginAbsolutePercentageChange?: InputMaybe<Order_By>;
  marginChange?: InputMaybe<Order_By>;
  marginPercentageChange?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  salesPriceChange?: InputMaybe<Order_By>;
  salesPricePercentageChange?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "daily_price_lists". All fields are combined with a logical 'AND'. */
export type Daily_Price_Lists_Bool_Exp = {
  _and?: InputMaybe<Array<Daily_Price_Lists_Bool_Exp>>;
  _not?: InputMaybe<Daily_Price_Lists_Bool_Exp>;
  _or?: InputMaybe<Array<Daily_Price_Lists_Bool_Exp>>;
  costPriceAbsolutePercentageChange?: InputMaybe<Numeric_Comparison_Exp>;
  costPriceChange?: InputMaybe<Bigint_Comparison_Exp>;
  costPricePercentageChange?: InputMaybe<Numeric_Comparison_Exp>;
  customerGroupPricing?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
  customerGroupPricingId?: InputMaybe<Bigint_Comparison_Exp>;
  lastCustomerGroupPricing?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
  lastCustomerGroupPricingId?: InputMaybe<Bigint_Comparison_Exp>;
  marginAbsolutePercentageChange?: InputMaybe<Numeric_Comparison_Exp>;
  marginChange?: InputMaybe<Bigint_Comparison_Exp>;
  marginPercentageChange?: InputMaybe<Numeric_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  salesPriceAbsolutePercentageChange?: InputMaybe<Numeric_Comparison_Exp>;
  salesPriceChange?: InputMaybe<Bigint_Comparison_Exp>;
  salesPricePercentageChange?: InputMaybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Daily_Price_Lists_Max_Fields = {
  __typename: 'daily_price_lists_max_fields';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['numeric']>;
  costPriceChange?: Maybe<Scalars['bigint']>;
  costPricePercentageChange?: Maybe<Scalars['numeric']>;
  customerGroupPricingId?: Maybe<Scalars['bigint']>;
  lastCustomerGroupPricingId?: Maybe<Scalars['bigint']>;
  marginAbsolutePercentageChange?: Maybe<Scalars['numeric']>;
  marginChange?: Maybe<Scalars['bigint']>;
  marginPercentageChange?: Maybe<Scalars['numeric']>;
  productId?: Maybe<Scalars['bigint']>;
  salesPriceAbsolutePercentageChange?: Maybe<Scalars['numeric']>;
  salesPriceChange?: Maybe<Scalars['bigint']>;
  salesPricePercentageChange?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "daily_price_lists" */
export type Daily_Price_Lists_Max_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  customerGroupPricingId?: InputMaybe<Order_By>;
  lastCustomerGroupPricingId?: InputMaybe<Order_By>;
  marginAbsolutePercentageChange?: InputMaybe<Order_By>;
  marginChange?: InputMaybe<Order_By>;
  marginPercentageChange?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  salesPriceChange?: InputMaybe<Order_By>;
  salesPricePercentageChange?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Daily_Price_Lists_Min_Fields = {
  __typename: 'daily_price_lists_min_fields';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['numeric']>;
  costPriceChange?: Maybe<Scalars['bigint']>;
  costPricePercentageChange?: Maybe<Scalars['numeric']>;
  customerGroupPricingId?: Maybe<Scalars['bigint']>;
  lastCustomerGroupPricingId?: Maybe<Scalars['bigint']>;
  marginAbsolutePercentageChange?: Maybe<Scalars['numeric']>;
  marginChange?: Maybe<Scalars['bigint']>;
  marginPercentageChange?: Maybe<Scalars['numeric']>;
  productId?: Maybe<Scalars['bigint']>;
  salesPriceAbsolutePercentageChange?: Maybe<Scalars['numeric']>;
  salesPriceChange?: Maybe<Scalars['bigint']>;
  salesPricePercentageChange?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "daily_price_lists" */
export type Daily_Price_Lists_Min_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  customerGroupPricingId?: InputMaybe<Order_By>;
  lastCustomerGroupPricingId?: InputMaybe<Order_By>;
  marginAbsolutePercentageChange?: InputMaybe<Order_By>;
  marginChange?: InputMaybe<Order_By>;
  marginPercentageChange?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  salesPriceChange?: InputMaybe<Order_By>;
  salesPricePercentageChange?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "daily_price_lists". */
export type Daily_Price_Lists_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  customerGroupPricing?: InputMaybe<Customer_Group_Pricing_Order_By>;
  customerGroupPricingId?: InputMaybe<Order_By>;
  lastCustomerGroupPricing?: InputMaybe<Customer_Group_Pricing_Order_By>;
  lastCustomerGroupPricingId?: InputMaybe<Order_By>;
  marginAbsolutePercentageChange?: InputMaybe<Order_By>;
  marginChange?: InputMaybe<Order_By>;
  marginPercentageChange?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  salesPriceChange?: InputMaybe<Order_By>;
  salesPricePercentageChange?: InputMaybe<Order_By>;
};

/** select columns of table "daily_price_lists" */
export enum Daily_Price_Lists_Select_Column {
  /** column name */
  CostPriceAbsolutePercentageChange = 'costPriceAbsolutePercentageChange',
  /** column name */
  CostPriceChange = 'costPriceChange',
  /** column name */
  CostPricePercentageChange = 'costPricePercentageChange',
  /** column name */
  CustomerGroupPricingId = 'customerGroupPricingId',
  /** column name */
  LastCustomerGroupPricingId = 'lastCustomerGroupPricingId',
  /** column name */
  MarginAbsolutePercentageChange = 'marginAbsolutePercentageChange',
  /** column name */
  MarginChange = 'marginChange',
  /** column name */
  MarginPercentageChange = 'marginPercentageChange',
  /** column name */
  ProductId = 'productId',
  /** column name */
  SalesPriceAbsolutePercentageChange = 'salesPriceAbsolutePercentageChange',
  /** column name */
  SalesPriceChange = 'salesPriceChange',
  /** column name */
  SalesPricePercentageChange = 'salesPricePercentageChange'
}

/** aggregate stddev on columns */
export type Daily_Price_Lists_Stddev_Fields = {
  __typename: 'daily_price_lists_stddev_fields';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  costPriceChange?: Maybe<Scalars['Float']>;
  costPricePercentageChange?: Maybe<Scalars['Float']>;
  customerGroupPricingId?: Maybe<Scalars['Float']>;
  lastCustomerGroupPricingId?: Maybe<Scalars['Float']>;
  marginAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  marginChange?: Maybe<Scalars['Float']>;
  marginPercentageChange?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  salesPriceChange?: Maybe<Scalars['Float']>;
  salesPricePercentageChange?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "daily_price_lists" */
export type Daily_Price_Lists_Stddev_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  customerGroupPricingId?: InputMaybe<Order_By>;
  lastCustomerGroupPricingId?: InputMaybe<Order_By>;
  marginAbsolutePercentageChange?: InputMaybe<Order_By>;
  marginChange?: InputMaybe<Order_By>;
  marginPercentageChange?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  salesPriceChange?: InputMaybe<Order_By>;
  salesPricePercentageChange?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Daily_Price_Lists_Stddev_Pop_Fields = {
  __typename: 'daily_price_lists_stddev_pop_fields';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  costPriceChange?: Maybe<Scalars['Float']>;
  costPricePercentageChange?: Maybe<Scalars['Float']>;
  customerGroupPricingId?: Maybe<Scalars['Float']>;
  lastCustomerGroupPricingId?: Maybe<Scalars['Float']>;
  marginAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  marginChange?: Maybe<Scalars['Float']>;
  marginPercentageChange?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  salesPriceChange?: Maybe<Scalars['Float']>;
  salesPricePercentageChange?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "daily_price_lists" */
export type Daily_Price_Lists_Stddev_Pop_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  customerGroupPricingId?: InputMaybe<Order_By>;
  lastCustomerGroupPricingId?: InputMaybe<Order_By>;
  marginAbsolutePercentageChange?: InputMaybe<Order_By>;
  marginChange?: InputMaybe<Order_By>;
  marginPercentageChange?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  salesPriceChange?: InputMaybe<Order_By>;
  salesPricePercentageChange?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Daily_Price_Lists_Stddev_Samp_Fields = {
  __typename: 'daily_price_lists_stddev_samp_fields';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  costPriceChange?: Maybe<Scalars['Float']>;
  costPricePercentageChange?: Maybe<Scalars['Float']>;
  customerGroupPricingId?: Maybe<Scalars['Float']>;
  lastCustomerGroupPricingId?: Maybe<Scalars['Float']>;
  marginAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  marginChange?: Maybe<Scalars['Float']>;
  marginPercentageChange?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  salesPriceChange?: Maybe<Scalars['Float']>;
  salesPricePercentageChange?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "daily_price_lists" */
export type Daily_Price_Lists_Stddev_Samp_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  customerGroupPricingId?: InputMaybe<Order_By>;
  lastCustomerGroupPricingId?: InputMaybe<Order_By>;
  marginAbsolutePercentageChange?: InputMaybe<Order_By>;
  marginChange?: InputMaybe<Order_By>;
  marginPercentageChange?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  salesPriceChange?: InputMaybe<Order_By>;
  salesPricePercentageChange?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "daily_price_lists" */
export type Daily_Price_Lists_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Daily_Price_Lists_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Daily_Price_Lists_Stream_Cursor_Value_Input = {
  costPriceAbsolutePercentageChange?: InputMaybe<Scalars['numeric']>;
  costPriceChange?: InputMaybe<Scalars['bigint']>;
  costPricePercentageChange?: InputMaybe<Scalars['numeric']>;
  customerGroupPricingId?: InputMaybe<Scalars['bigint']>;
  lastCustomerGroupPricingId?: InputMaybe<Scalars['bigint']>;
  marginAbsolutePercentageChange?: InputMaybe<Scalars['numeric']>;
  marginChange?: InputMaybe<Scalars['bigint']>;
  marginPercentageChange?: InputMaybe<Scalars['numeric']>;
  productId?: InputMaybe<Scalars['bigint']>;
  salesPriceAbsolutePercentageChange?: InputMaybe<Scalars['numeric']>;
  salesPriceChange?: InputMaybe<Scalars['bigint']>;
  salesPricePercentageChange?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Daily_Price_Lists_Sum_Fields = {
  __typename: 'daily_price_lists_sum_fields';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['numeric']>;
  costPriceChange?: Maybe<Scalars['bigint']>;
  costPricePercentageChange?: Maybe<Scalars['numeric']>;
  customerGroupPricingId?: Maybe<Scalars['bigint']>;
  lastCustomerGroupPricingId?: Maybe<Scalars['bigint']>;
  marginAbsolutePercentageChange?: Maybe<Scalars['numeric']>;
  marginChange?: Maybe<Scalars['bigint']>;
  marginPercentageChange?: Maybe<Scalars['numeric']>;
  productId?: Maybe<Scalars['bigint']>;
  salesPriceAbsolutePercentageChange?: Maybe<Scalars['numeric']>;
  salesPriceChange?: Maybe<Scalars['bigint']>;
  salesPricePercentageChange?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "daily_price_lists" */
export type Daily_Price_Lists_Sum_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  customerGroupPricingId?: InputMaybe<Order_By>;
  lastCustomerGroupPricingId?: InputMaybe<Order_By>;
  marginAbsolutePercentageChange?: InputMaybe<Order_By>;
  marginChange?: InputMaybe<Order_By>;
  marginPercentageChange?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  salesPriceChange?: InputMaybe<Order_By>;
  salesPricePercentageChange?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Daily_Price_Lists_Var_Pop_Fields = {
  __typename: 'daily_price_lists_var_pop_fields';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  costPriceChange?: Maybe<Scalars['Float']>;
  costPricePercentageChange?: Maybe<Scalars['Float']>;
  customerGroupPricingId?: Maybe<Scalars['Float']>;
  lastCustomerGroupPricingId?: Maybe<Scalars['Float']>;
  marginAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  marginChange?: Maybe<Scalars['Float']>;
  marginPercentageChange?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  salesPriceChange?: Maybe<Scalars['Float']>;
  salesPricePercentageChange?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "daily_price_lists" */
export type Daily_Price_Lists_Var_Pop_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  customerGroupPricingId?: InputMaybe<Order_By>;
  lastCustomerGroupPricingId?: InputMaybe<Order_By>;
  marginAbsolutePercentageChange?: InputMaybe<Order_By>;
  marginChange?: InputMaybe<Order_By>;
  marginPercentageChange?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  salesPriceChange?: InputMaybe<Order_By>;
  salesPricePercentageChange?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Daily_Price_Lists_Var_Samp_Fields = {
  __typename: 'daily_price_lists_var_samp_fields';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  costPriceChange?: Maybe<Scalars['Float']>;
  costPricePercentageChange?: Maybe<Scalars['Float']>;
  customerGroupPricingId?: Maybe<Scalars['Float']>;
  lastCustomerGroupPricingId?: Maybe<Scalars['Float']>;
  marginAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  marginChange?: Maybe<Scalars['Float']>;
  marginPercentageChange?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  salesPriceChange?: Maybe<Scalars['Float']>;
  salesPricePercentageChange?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "daily_price_lists" */
export type Daily_Price_Lists_Var_Samp_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  customerGroupPricingId?: InputMaybe<Order_By>;
  lastCustomerGroupPricingId?: InputMaybe<Order_By>;
  marginAbsolutePercentageChange?: InputMaybe<Order_By>;
  marginChange?: InputMaybe<Order_By>;
  marginPercentageChange?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  salesPriceChange?: InputMaybe<Order_By>;
  salesPricePercentageChange?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Daily_Price_Lists_Variance_Fields = {
  __typename: 'daily_price_lists_variance_fields';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  costPriceChange?: Maybe<Scalars['Float']>;
  costPricePercentageChange?: Maybe<Scalars['Float']>;
  customerGroupPricingId?: Maybe<Scalars['Float']>;
  lastCustomerGroupPricingId?: Maybe<Scalars['Float']>;
  marginAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  marginChange?: Maybe<Scalars['Float']>;
  marginPercentageChange?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  salesPriceChange?: Maybe<Scalars['Float']>;
  salesPricePercentageChange?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "daily_price_lists" */
export type Daily_Price_Lists_Variance_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  customerGroupPricingId?: InputMaybe<Order_By>;
  lastCustomerGroupPricingId?: InputMaybe<Order_By>;
  marginAbsolutePercentageChange?: InputMaybe<Order_By>;
  marginChange?: InputMaybe<Order_By>;
  marginPercentageChange?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  salesPriceChange?: InputMaybe<Order_By>;
  salesPricePercentageChange?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** Boolean expression to compare columns of type "daterange". All fields are combined with logical 'AND'. */
export type Daterange_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['daterange']>;
  _gt?: InputMaybe<Scalars['daterange']>;
  _gte?: InputMaybe<Scalars['daterange']>;
  _in?: InputMaybe<Array<Scalars['daterange']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['daterange']>;
  _lte?: InputMaybe<Scalars['daterange']>;
  _neq?: InputMaybe<Scalars['daterange']>;
  _nin?: InputMaybe<Array<Scalars['daterange']>>;
};

export type Delete_Customer_Order_Item_Args = {
  _id?: InputMaybe<Scalars['bigint']>;
};

export type Delete_Partial_Delivery_Args = {
  _partial_delivery_id?: InputMaybe<Scalars['bigint']>;
};

export type Delete_Picked_Items_Args = {
  _pi_ids?: InputMaybe<Scalars['json']>;
  _source_account_id?: InputMaybe<Scalars['bigint']>;
};

export type Delete_Product_S3_Object_Args = {
  _product_id?: InputMaybe<Scalars['bigint']>;
  _s3_object_id?: InputMaybe<Scalars['bigint']>;
};

export type Delete_Rolling_Inventory_Args = {
  _id?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "demands_for_coi_results" */
export type Demands_For_Coi_Results = {
  __typename: 'demands_for_coi_results';
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  /** An object relationship */
  productUnit: Product_Units;
  productUnitId: Scalars['bigint'];
  quantity: Scalars['bigint'];
};

/** aggregated selection of "demands_for_coi_results" */
export type Demands_For_Coi_Results_Aggregate = {
  __typename: 'demands_for_coi_results_aggregate';
  aggregate?: Maybe<Demands_For_Coi_Results_Aggregate_Fields>;
  nodes: Array<Demands_For_Coi_Results>;
};

export type Demands_For_Coi_Results_Aggregate_Bool_Exp = {
  count?: InputMaybe<Demands_For_Coi_Results_Aggregate_Bool_Exp_Count>;
};

export type Demands_For_Coi_Results_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Demands_For_Coi_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Demands_For_Coi_Results_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "demands_for_coi_results" */
export type Demands_For_Coi_Results_Aggregate_Fields = {
  __typename: 'demands_for_coi_results_aggregate_fields';
  avg?: Maybe<Demands_For_Coi_Results_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Demands_For_Coi_Results_Max_Fields>;
  min?: Maybe<Demands_For_Coi_Results_Min_Fields>;
  stddev?: Maybe<Demands_For_Coi_Results_Stddev_Fields>;
  stddev_pop?: Maybe<Demands_For_Coi_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Demands_For_Coi_Results_Stddev_Samp_Fields>;
  sum?: Maybe<Demands_For_Coi_Results_Sum_Fields>;
  var_pop?: Maybe<Demands_For_Coi_Results_Var_Pop_Fields>;
  var_samp?: Maybe<Demands_For_Coi_Results_Var_Samp_Fields>;
  variance?: Maybe<Demands_For_Coi_Results_Variance_Fields>;
};


/** aggregate fields of "demands_for_coi_results" */
export type Demands_For_Coi_Results_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Demands_For_Coi_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demands_for_coi_results" */
export type Demands_For_Coi_Results_Aggregate_Order_By = {
  avg?: InputMaybe<Demands_For_Coi_Results_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Demands_For_Coi_Results_Max_Order_By>;
  min?: InputMaybe<Demands_For_Coi_Results_Min_Order_By>;
  stddev?: InputMaybe<Demands_For_Coi_Results_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Demands_For_Coi_Results_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Demands_For_Coi_Results_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Demands_For_Coi_Results_Sum_Order_By>;
  var_pop?: InputMaybe<Demands_For_Coi_Results_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Demands_For_Coi_Results_Var_Samp_Order_By>;
  variance?: InputMaybe<Demands_For_Coi_Results_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Demands_For_Coi_Results_Avg_Fields = {
  __typename: 'demands_for_coi_results_avg_fields';
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demands_for_coi_results" */
export type Demands_For_Coi_Results_Avg_Order_By = {
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "demands_for_coi_results". All fields are combined with a logical 'AND'. */
export type Demands_For_Coi_Results_Bool_Exp = {
  _and?: InputMaybe<Array<Demands_For_Coi_Results_Bool_Exp>>;
  _not?: InputMaybe<Demands_For_Coi_Results_Bool_Exp>;
  _or?: InputMaybe<Array<Demands_For_Coi_Results_Bool_Exp>>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  productUnit?: InputMaybe<Product_Units_Bool_Exp>;
  productUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  quantity?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Demands_For_Coi_Results_Max_Fields = {
  __typename: 'demands_for_coi_results_max_fields';
  productId?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demands_for_coi_results" */
export type Demands_For_Coi_Results_Max_Order_By = {
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Demands_For_Coi_Results_Min_Fields = {
  __typename: 'demands_for_coi_results_min_fields';
  productId?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demands_for_coi_results" */
export type Demands_For_Coi_Results_Min_Order_By = {
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "demands_for_coi_results". */
export type Demands_For_Coi_Results_Order_By = {
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnit?: InputMaybe<Product_Units_Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** select columns of table "demands_for_coi_results" */
export enum Demands_For_Coi_Results_Select_Column {
  /** column name */
  ProductId = 'productId',
  /** column name */
  ProductUnitId = 'productUnitId',
  /** column name */
  Quantity = 'quantity'
}

/** aggregate stddev on columns */
export type Demands_For_Coi_Results_Stddev_Fields = {
  __typename: 'demands_for_coi_results_stddev_fields';
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demands_for_coi_results" */
export type Demands_For_Coi_Results_Stddev_Order_By = {
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Demands_For_Coi_Results_Stddev_Pop_Fields = {
  __typename: 'demands_for_coi_results_stddev_pop_fields';
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demands_for_coi_results" */
export type Demands_For_Coi_Results_Stddev_Pop_Order_By = {
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Demands_For_Coi_Results_Stddev_Samp_Fields = {
  __typename: 'demands_for_coi_results_stddev_samp_fields';
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demands_for_coi_results" */
export type Demands_For_Coi_Results_Stddev_Samp_Order_By = {
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "demands_for_coi_results" */
export type Demands_For_Coi_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Demands_For_Coi_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Demands_For_Coi_Results_Stream_Cursor_Value_Input = {
  productId?: InputMaybe<Scalars['bigint']>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Demands_For_Coi_Results_Sum_Fields = {
  __typename: 'demands_for_coi_results_sum_fields';
  productId?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demands_for_coi_results" */
export type Demands_For_Coi_Results_Sum_Order_By = {
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Demands_For_Coi_Results_Var_Pop_Fields = {
  __typename: 'demands_for_coi_results_var_pop_fields';
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demands_for_coi_results" */
export type Demands_For_Coi_Results_Var_Pop_Order_By = {
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Demands_For_Coi_Results_Var_Samp_Fields = {
  __typename: 'demands_for_coi_results_var_samp_fields';
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demands_for_coi_results" */
export type Demands_For_Coi_Results_Var_Samp_Order_By = {
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Demands_For_Coi_Results_Variance_Fields = {
  __typename: 'demands_for_coi_results_variance_fields';
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demands_for_coi_results" */
export type Demands_For_Coi_Results_Variance_Order_By = {
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

export type Dismantleable_Products_Args = {
  product_id?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "dismantleable_products_results" */
export type Dismantleable_Products_Results = {
  __typename: 'dismantleable_products_results';
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
};

/** aggregated selection of "dismantleable_products_results" */
export type Dismantleable_Products_Results_Aggregate = {
  __typename: 'dismantleable_products_results_aggregate';
  aggregate?: Maybe<Dismantleable_Products_Results_Aggregate_Fields>;
  nodes: Array<Dismantleable_Products_Results>;
};

export type Dismantleable_Products_Results_Aggregate_Bool_Exp = {
  count?: InputMaybe<Dismantleable_Products_Results_Aggregate_Bool_Exp_Count>;
};

export type Dismantleable_Products_Results_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Dismantleable_Products_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dismantleable_Products_Results_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "dismantleable_products_results" */
export type Dismantleable_Products_Results_Aggregate_Fields = {
  __typename: 'dismantleable_products_results_aggregate_fields';
  avg?: Maybe<Dismantleable_Products_Results_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dismantleable_Products_Results_Max_Fields>;
  min?: Maybe<Dismantleable_Products_Results_Min_Fields>;
  stddev?: Maybe<Dismantleable_Products_Results_Stddev_Fields>;
  stddev_pop?: Maybe<Dismantleable_Products_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dismantleable_Products_Results_Stddev_Samp_Fields>;
  sum?: Maybe<Dismantleable_Products_Results_Sum_Fields>;
  var_pop?: Maybe<Dismantleable_Products_Results_Var_Pop_Fields>;
  var_samp?: Maybe<Dismantleable_Products_Results_Var_Samp_Fields>;
  variance?: Maybe<Dismantleable_Products_Results_Variance_Fields>;
};


/** aggregate fields of "dismantleable_products_results" */
export type Dismantleable_Products_Results_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dismantleable_Products_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dismantleable_products_results" */
export type Dismantleable_Products_Results_Aggregate_Order_By = {
  avg?: InputMaybe<Dismantleable_Products_Results_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dismantleable_Products_Results_Max_Order_By>;
  min?: InputMaybe<Dismantleable_Products_Results_Min_Order_By>;
  stddev?: InputMaybe<Dismantleable_Products_Results_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Dismantleable_Products_Results_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Dismantleable_Products_Results_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Dismantleable_Products_Results_Sum_Order_By>;
  var_pop?: InputMaybe<Dismantleable_Products_Results_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Dismantleable_Products_Results_Var_Samp_Order_By>;
  variance?: InputMaybe<Dismantleable_Products_Results_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Dismantleable_Products_Results_Avg_Fields = {
  __typename: 'dismantleable_products_results_avg_fields';
  productId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dismantleable_products_results" */
export type Dismantleable_Products_Results_Avg_Order_By = {
  productId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dismantleable_products_results". All fields are combined with a logical 'AND'. */
export type Dismantleable_Products_Results_Bool_Exp = {
  _and?: InputMaybe<Array<Dismantleable_Products_Results_Bool_Exp>>;
  _not?: InputMaybe<Dismantleable_Products_Results_Bool_Exp>;
  _or?: InputMaybe<Array<Dismantleable_Products_Results_Bool_Exp>>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dismantleable_Products_Results_Max_Fields = {
  __typename: 'dismantleable_products_results_max_fields';
  productId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "dismantleable_products_results" */
export type Dismantleable_Products_Results_Max_Order_By = {
  productId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Dismantleable_Products_Results_Min_Fields = {
  __typename: 'dismantleable_products_results_min_fields';
  productId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "dismantleable_products_results" */
export type Dismantleable_Products_Results_Min_Order_By = {
  productId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "dismantleable_products_results". */
export type Dismantleable_Products_Results_Order_By = {
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** select columns of table "dismantleable_products_results" */
export enum Dismantleable_Products_Results_Select_Column {
  /** column name */
  ProductId = 'productId'
}

/** aggregate stddev on columns */
export type Dismantleable_Products_Results_Stddev_Fields = {
  __typename: 'dismantleable_products_results_stddev_fields';
  productId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dismantleable_products_results" */
export type Dismantleable_Products_Results_Stddev_Order_By = {
  productId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dismantleable_Products_Results_Stddev_Pop_Fields = {
  __typename: 'dismantleable_products_results_stddev_pop_fields';
  productId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dismantleable_products_results" */
export type Dismantleable_Products_Results_Stddev_Pop_Order_By = {
  productId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dismantleable_Products_Results_Stddev_Samp_Fields = {
  __typename: 'dismantleable_products_results_stddev_samp_fields';
  productId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dismantleable_products_results" */
export type Dismantleable_Products_Results_Stddev_Samp_Order_By = {
  productId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "dismantleable_products_results" */
export type Dismantleable_Products_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dismantleable_Products_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dismantleable_Products_Results_Stream_Cursor_Value_Input = {
  productId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Dismantleable_Products_Results_Sum_Fields = {
  __typename: 'dismantleable_products_results_sum_fields';
  productId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "dismantleable_products_results" */
export type Dismantleable_Products_Results_Sum_Order_By = {
  productId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Dismantleable_Products_Results_Var_Pop_Fields = {
  __typename: 'dismantleable_products_results_var_pop_fields';
  productId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dismantleable_products_results" */
export type Dismantleable_Products_Results_Var_Pop_Order_By = {
  productId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dismantleable_Products_Results_Var_Samp_Fields = {
  __typename: 'dismantleable_products_results_var_samp_fields';
  productId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dismantleable_products_results" */
export type Dismantleable_Products_Results_Var_Samp_Order_By = {
  productId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Dismantleable_Products_Results_Variance_Fields = {
  __typename: 'dismantleable_products_results_variance_fields';
  productId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dismantleable_products_results" */
export type Dismantleable_Products_Results_Variance_Order_By = {
  productId?: InputMaybe<Order_By>;
};

/** columns and relationships of "dismantling_output_weight_results" */
export type Dismantling_Output_Weight_Results = {
  __typename: 'dismantling_output_weight_results';
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  weightInKg: Scalars['bigint'];
};

/** aggregated selection of "dismantling_output_weight_results" */
export type Dismantling_Output_Weight_Results_Aggregate = {
  __typename: 'dismantling_output_weight_results_aggregate';
  aggregate?: Maybe<Dismantling_Output_Weight_Results_Aggregate_Fields>;
  nodes: Array<Dismantling_Output_Weight_Results>;
};

export type Dismantling_Output_Weight_Results_Aggregate_Bool_Exp = {
  count?: InputMaybe<Dismantling_Output_Weight_Results_Aggregate_Bool_Exp_Count>;
};

export type Dismantling_Output_Weight_Results_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Dismantling_Output_Weight_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dismantling_Output_Weight_Results_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "dismantling_output_weight_results" */
export type Dismantling_Output_Weight_Results_Aggregate_Fields = {
  __typename: 'dismantling_output_weight_results_aggregate_fields';
  avg?: Maybe<Dismantling_Output_Weight_Results_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dismantling_Output_Weight_Results_Max_Fields>;
  min?: Maybe<Dismantling_Output_Weight_Results_Min_Fields>;
  stddev?: Maybe<Dismantling_Output_Weight_Results_Stddev_Fields>;
  stddev_pop?: Maybe<Dismantling_Output_Weight_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dismantling_Output_Weight_Results_Stddev_Samp_Fields>;
  sum?: Maybe<Dismantling_Output_Weight_Results_Sum_Fields>;
  var_pop?: Maybe<Dismantling_Output_Weight_Results_Var_Pop_Fields>;
  var_samp?: Maybe<Dismantling_Output_Weight_Results_Var_Samp_Fields>;
  variance?: Maybe<Dismantling_Output_Weight_Results_Variance_Fields>;
};


/** aggregate fields of "dismantling_output_weight_results" */
export type Dismantling_Output_Weight_Results_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dismantling_Output_Weight_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dismantling_output_weight_results" */
export type Dismantling_Output_Weight_Results_Aggregate_Order_By = {
  avg?: InputMaybe<Dismantling_Output_Weight_Results_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dismantling_Output_Weight_Results_Max_Order_By>;
  min?: InputMaybe<Dismantling_Output_Weight_Results_Min_Order_By>;
  stddev?: InputMaybe<Dismantling_Output_Weight_Results_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Dismantling_Output_Weight_Results_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Dismantling_Output_Weight_Results_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Dismantling_Output_Weight_Results_Sum_Order_By>;
  var_pop?: InputMaybe<Dismantling_Output_Weight_Results_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Dismantling_Output_Weight_Results_Var_Samp_Order_By>;
  variance?: InputMaybe<Dismantling_Output_Weight_Results_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Dismantling_Output_Weight_Results_Avg_Fields = {
  __typename: 'dismantling_output_weight_results_avg_fields';
  productId?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dismantling_output_weight_results" */
export type Dismantling_Output_Weight_Results_Avg_Order_By = {
  productId?: InputMaybe<Order_By>;
  weightInKg?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dismantling_output_weight_results". All fields are combined with a logical 'AND'. */
export type Dismantling_Output_Weight_Results_Bool_Exp = {
  _and?: InputMaybe<Array<Dismantling_Output_Weight_Results_Bool_Exp>>;
  _not?: InputMaybe<Dismantling_Output_Weight_Results_Bool_Exp>;
  _or?: InputMaybe<Array<Dismantling_Output_Weight_Results_Bool_Exp>>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  weightInKg?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dismantling_Output_Weight_Results_Max_Fields = {
  __typename: 'dismantling_output_weight_results_max_fields';
  productId?: Maybe<Scalars['bigint']>;
  weightInKg?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "dismantling_output_weight_results" */
export type Dismantling_Output_Weight_Results_Max_Order_By = {
  productId?: InputMaybe<Order_By>;
  weightInKg?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Dismantling_Output_Weight_Results_Min_Fields = {
  __typename: 'dismantling_output_weight_results_min_fields';
  productId?: Maybe<Scalars['bigint']>;
  weightInKg?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "dismantling_output_weight_results" */
export type Dismantling_Output_Weight_Results_Min_Order_By = {
  productId?: InputMaybe<Order_By>;
  weightInKg?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "dismantling_output_weight_results". */
export type Dismantling_Output_Weight_Results_Order_By = {
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  weightInKg?: InputMaybe<Order_By>;
};

/** select columns of table "dismantling_output_weight_results" */
export enum Dismantling_Output_Weight_Results_Select_Column {
  /** column name */
  ProductId = 'productId',
  /** column name */
  WeightInKg = 'weightInKg'
}

/** aggregate stddev on columns */
export type Dismantling_Output_Weight_Results_Stddev_Fields = {
  __typename: 'dismantling_output_weight_results_stddev_fields';
  productId?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dismantling_output_weight_results" */
export type Dismantling_Output_Weight_Results_Stddev_Order_By = {
  productId?: InputMaybe<Order_By>;
  weightInKg?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dismantling_Output_Weight_Results_Stddev_Pop_Fields = {
  __typename: 'dismantling_output_weight_results_stddev_pop_fields';
  productId?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dismantling_output_weight_results" */
export type Dismantling_Output_Weight_Results_Stddev_Pop_Order_By = {
  productId?: InputMaybe<Order_By>;
  weightInKg?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dismantling_Output_Weight_Results_Stddev_Samp_Fields = {
  __typename: 'dismantling_output_weight_results_stddev_samp_fields';
  productId?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dismantling_output_weight_results" */
export type Dismantling_Output_Weight_Results_Stddev_Samp_Order_By = {
  productId?: InputMaybe<Order_By>;
  weightInKg?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "dismantling_output_weight_results" */
export type Dismantling_Output_Weight_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dismantling_Output_Weight_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dismantling_Output_Weight_Results_Stream_Cursor_Value_Input = {
  productId?: InputMaybe<Scalars['bigint']>;
  weightInKg?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Dismantling_Output_Weight_Results_Sum_Fields = {
  __typename: 'dismantling_output_weight_results_sum_fields';
  productId?: Maybe<Scalars['bigint']>;
  weightInKg?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "dismantling_output_weight_results" */
export type Dismantling_Output_Weight_Results_Sum_Order_By = {
  productId?: InputMaybe<Order_By>;
  weightInKg?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Dismantling_Output_Weight_Results_Var_Pop_Fields = {
  __typename: 'dismantling_output_weight_results_var_pop_fields';
  productId?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dismantling_output_weight_results" */
export type Dismantling_Output_Weight_Results_Var_Pop_Order_By = {
  productId?: InputMaybe<Order_By>;
  weightInKg?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dismantling_Output_Weight_Results_Var_Samp_Fields = {
  __typename: 'dismantling_output_weight_results_var_samp_fields';
  productId?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dismantling_output_weight_results" */
export type Dismantling_Output_Weight_Results_Var_Samp_Order_By = {
  productId?: InputMaybe<Order_By>;
  weightInKg?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Dismantling_Output_Weight_Results_Variance_Fields = {
  __typename: 'dismantling_output_weight_results_variance_fields';
  productId?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dismantling_output_weight_results" */
export type Dismantling_Output_Weight_Results_Variance_Order_By = {
  productId?: InputMaybe<Order_By>;
  weightInKg?: InputMaybe<Order_By>;
};

/** columns and relationships of "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices = {
  __typename: 'dismantling_plan_cutting_pattern_choices';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  cuttingPattern: Cutting_Patterns;
  cuttingPatternId: Scalars['bigint'];
  /** An object relationship */
  dismantlingPlan: Dismantling_Plans;
  dismantlingPlanId: Scalars['bigint'];
  id: Scalars['bigint'];
  manual?: Maybe<Scalars['Boolean']>;
  quantity: Scalars['bigint'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};

/** aggregated selection of "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_Aggregate = {
  __typename: 'dismantling_plan_cutting_pattern_choices_aggregate';
  aggregate?: Maybe<Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_Fields>;
  nodes: Array<Dismantling_Plan_Cutting_Pattern_Choices>;
};

export type Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_Bool_Exp_Count>;
};

export type Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_Bool_Exp_Bool_And = {
  arguments: Dismantling_Plan_Cutting_Pattern_Choices_Select_Column_Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Dismantling_Plan_Cutting_Pattern_Choices_Select_Column_Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_Fields = {
  __typename: 'dismantling_plan_cutting_pattern_choices_aggregate_fields';
  avg?: Maybe<Dismantling_Plan_Cutting_Pattern_Choices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dismantling_Plan_Cutting_Pattern_Choices_Max_Fields>;
  min?: Maybe<Dismantling_Plan_Cutting_Pattern_Choices_Min_Fields>;
  stddev?: Maybe<Dismantling_Plan_Cutting_Pattern_Choices_Stddev_Fields>;
  stddev_pop?: Maybe<Dismantling_Plan_Cutting_Pattern_Choices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dismantling_Plan_Cutting_Pattern_Choices_Stddev_Samp_Fields>;
  sum?: Maybe<Dismantling_Plan_Cutting_Pattern_Choices_Sum_Fields>;
  var_pop?: Maybe<Dismantling_Plan_Cutting_Pattern_Choices_Var_Pop_Fields>;
  var_samp?: Maybe<Dismantling_Plan_Cutting_Pattern_Choices_Var_Samp_Fields>;
  variance?: Maybe<Dismantling_Plan_Cutting_Pattern_Choices_Variance_Fields>;
};


/** aggregate fields of "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_Order_By = {
  avg?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Max_Order_By>;
  min?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Min_Order_By>;
  stddev?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Sum_Order_By>;
  var_pop?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Var_Samp_Order_By>;
  variance?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_Arr_Rel_Insert_Input = {
  data: Array<Dismantling_Plan_Cutting_Pattern_Choices_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_On_Conflict>;
};

/** aggregate avg on columns */
export type Dismantling_Plan_Cutting_Pattern_Choices_Avg_Fields = {
  __typename: 'dismantling_plan_cutting_pattern_choices_avg_fields';
  cuttingPatternId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_Avg_Order_By = {
  cuttingPatternId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dismantling_plan_cutting_pattern_choices". All fields are combined with a logical 'AND'. */
export type Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>>;
  _not?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  cuttingPattern?: InputMaybe<Cutting_Patterns_Bool_Exp>;
  cuttingPatternId?: InputMaybe<Bigint_Comparison_Exp>;
  dismantlingPlan?: InputMaybe<Dismantling_Plans_Bool_Exp>;
  dismantlingPlanId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  manual?: InputMaybe<Boolean_Comparison_Exp>;
  quantity?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "dismantling_plan_cutting_pattern_choices" */
export enum Dismantling_Plan_Cutting_Pattern_Choices_Constraint {
  /** unique or primary key constraint on columns "cutting_pattern_id", "dismantling_plan_id", "manual" */
  CpChoicesDpIdMnaulCpIdUniq = 'cp_choices_dp_id_mnaul_cp_id_uniq',
  /** unique or primary key constraint on columns "id" */
  DismantlingPlanCuttingPatternChoicesPkey = 'dismantling_plan_cutting_pattern_choices_pkey'
}

/** input type for incrementing numeric columns in table "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_Inc_Input = {
  cuttingPatternId?: InputMaybe<Scalars['bigint']>;
  dismantlingPlanId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_Insert_Input = {
  cuttingPattern?: InputMaybe<Cutting_Patterns_Obj_Rel_Insert_Input>;
  cuttingPatternId?: InputMaybe<Scalars['bigint']>;
  dismantlingPlan?: InputMaybe<Dismantling_Plans_Obj_Rel_Insert_Input>;
  dismantlingPlanId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  manual?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Dismantling_Plan_Cutting_Pattern_Choices_Max_Fields = {
  __typename: 'dismantling_plan_cutting_pattern_choices_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  cuttingPatternId?: Maybe<Scalars['bigint']>;
  dismantlingPlanId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  cuttingPatternId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Dismantling_Plan_Cutting_Pattern_Choices_Min_Fields = {
  __typename: 'dismantling_plan_cutting_pattern_choices_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  cuttingPatternId?: Maybe<Scalars['bigint']>;
  dismantlingPlanId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  cuttingPatternId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_Mutation_Response = {
  __typename: 'dismantling_plan_cutting_pattern_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dismantling_Plan_Cutting_Pattern_Choices>;
};

/** on_conflict condition type for table "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_On_Conflict = {
  constraint: Dismantling_Plan_Cutting_Pattern_Choices_Constraint;
  update_columns?: Array<Dismantling_Plan_Cutting_Pattern_Choices_Update_Column>;
  where?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "dismantling_plan_cutting_pattern_choices". */
export type Dismantling_Plan_Cutting_Pattern_Choices_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  cuttingPattern?: InputMaybe<Cutting_Patterns_Order_By>;
  cuttingPatternId?: InputMaybe<Order_By>;
  dismantlingPlan?: InputMaybe<Dismantling_Plans_Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manual?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dismantling_plan_cutting_pattern_choices */
export type Dismantling_Plan_Cutting_Pattern_Choices_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "dismantling_plan_cutting_pattern_choices" */
export enum Dismantling_Plan_Cutting_Pattern_Choices_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CuttingPatternId = 'cuttingPatternId',
  /** column name */
  DismantlingPlanId = 'dismantlingPlanId',
  /** column name */
  Id = 'id',
  /** column name */
  Manual = 'manual',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** select "dismantling_plan_cutting_pattern_choices_aggregate_bool_exp_bool_and_arguments_columns" columns of table "dismantling_plan_cutting_pattern_choices" */
export enum Dismantling_Plan_Cutting_Pattern_Choices_Select_Column_Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Manual = 'manual'
}

/** select "dismantling_plan_cutting_pattern_choices_aggregate_bool_exp_bool_or_arguments_columns" columns of table "dismantling_plan_cutting_pattern_choices" */
export enum Dismantling_Plan_Cutting_Pattern_Choices_Select_Column_Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Manual = 'manual'
}

/** input type for updating data in table "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_Set_Input = {
  cuttingPatternId?: InputMaybe<Scalars['bigint']>;
  dismantlingPlanId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  manual?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Dismantling_Plan_Cutting_Pattern_Choices_Stddev_Fields = {
  __typename: 'dismantling_plan_cutting_pattern_choices_stddev_fields';
  cuttingPatternId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_Stddev_Order_By = {
  cuttingPatternId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dismantling_Plan_Cutting_Pattern_Choices_Stddev_Pop_Fields = {
  __typename: 'dismantling_plan_cutting_pattern_choices_stddev_pop_fields';
  cuttingPatternId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_Stddev_Pop_Order_By = {
  cuttingPatternId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dismantling_Plan_Cutting_Pattern_Choices_Stddev_Samp_Fields = {
  __typename: 'dismantling_plan_cutting_pattern_choices_stddev_samp_fields';
  cuttingPatternId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_Stddev_Samp_Order_By = {
  cuttingPatternId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dismantling_Plan_Cutting_Pattern_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dismantling_Plan_Cutting_Pattern_Choices_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  cuttingPatternId?: InputMaybe<Scalars['bigint']>;
  dismantlingPlanId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  manual?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Dismantling_Plan_Cutting_Pattern_Choices_Sum_Fields = {
  __typename: 'dismantling_plan_cutting_pattern_choices_sum_fields';
  cuttingPatternId?: Maybe<Scalars['bigint']>;
  dismantlingPlanId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_Sum_Order_By = {
  cuttingPatternId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "dismantling_plan_cutting_pattern_choices" */
export enum Dismantling_Plan_Cutting_Pattern_Choices_Update_Column {
  /** column name */
  CuttingPatternId = 'cuttingPatternId',
  /** column name */
  DismantlingPlanId = 'dismantlingPlanId',
  /** column name */
  Id = 'id',
  /** column name */
  Manual = 'manual',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Version = 'version'
}

export type Dismantling_Plan_Cutting_Pattern_Choices_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dismantling_Plan_Cutting_Pattern_Choices_Var_Pop_Fields = {
  __typename: 'dismantling_plan_cutting_pattern_choices_var_pop_fields';
  cuttingPatternId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_Var_Pop_Order_By = {
  cuttingPatternId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dismantling_Plan_Cutting_Pattern_Choices_Var_Samp_Fields = {
  __typename: 'dismantling_plan_cutting_pattern_choices_var_samp_fields';
  cuttingPatternId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_Var_Samp_Order_By = {
  cuttingPatternId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Dismantling_Plan_Cutting_Pattern_Choices_Variance_Fields = {
  __typename: 'dismantling_plan_cutting_pattern_choices_variance_fields';
  cuttingPatternId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dismantling_plan_cutting_pattern_choices" */
export type Dismantling_Plan_Cutting_Pattern_Choices_Variance_Order_By = {
  cuttingPatternId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "dismantling_plan_demands" */
export type Dismantling_Plan_Demands = {
  __typename: 'dismantling_plan_demands';
  /** An object relationship */
  Tenant: Tenants;
  /** Returns the order quantity of the coi, scaled to the number of times can get actually produced within the context of the given demands dismantling plan. Unit is the coi.order_unit_id */
  computedOrderQuantity?: Maybe<Scalars['bigint']>;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customerOrderItem: Customer_Order_Items;
  customerOrderItemId: Scalars['bigint'];
  /** An object relationship */
  dismantlingPlan: Dismantling_Plans;
  dismantlingPlanId: Scalars['bigint'];
  hasManualQuantityChanges: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** Returns whether this demand comes from a late customer order (order delivery date = dismantling plan due date) */
  isFromLateOrder?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  /** An object relationship */
  productUnit: Product_Units;
  productUnitId: Scalars['bigint'];
  quantity: Scalars['bigint'];
  showOrderQuantity: Scalars['Boolean'];
  splitPercentage: Scalars['numeric'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};

/** aggregated selection of "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_Aggregate = {
  __typename: 'dismantling_plan_demands_aggregate';
  aggregate?: Maybe<Dismantling_Plan_Demands_Aggregate_Fields>;
  nodes: Array<Dismantling_Plan_Demands>;
};

export type Dismantling_Plan_Demands_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Dismantling_Plan_Demands_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Dismantling_Plan_Demands_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Dismantling_Plan_Demands_Aggregate_Bool_Exp_Count>;
};

export type Dismantling_Plan_Demands_Aggregate_Bool_Exp_Bool_And = {
  arguments: Dismantling_Plan_Demands_Select_Column_Dismantling_Plan_Demands_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Dismantling_Plan_Demands_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Dismantling_Plan_Demands_Select_Column_Dismantling_Plan_Demands_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Dismantling_Plan_Demands_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Dismantling_Plan_Demands_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_Aggregate_Fields = {
  __typename: 'dismantling_plan_demands_aggregate_fields';
  avg?: Maybe<Dismantling_Plan_Demands_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dismantling_Plan_Demands_Max_Fields>;
  min?: Maybe<Dismantling_Plan_Demands_Min_Fields>;
  stddev?: Maybe<Dismantling_Plan_Demands_Stddev_Fields>;
  stddev_pop?: Maybe<Dismantling_Plan_Demands_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dismantling_Plan_Demands_Stddev_Samp_Fields>;
  sum?: Maybe<Dismantling_Plan_Demands_Sum_Fields>;
  var_pop?: Maybe<Dismantling_Plan_Demands_Var_Pop_Fields>;
  var_samp?: Maybe<Dismantling_Plan_Demands_Var_Samp_Fields>;
  variance?: Maybe<Dismantling_Plan_Demands_Variance_Fields>;
};


/** aggregate fields of "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dismantling_Plan_Demands_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_Aggregate_Order_By = {
  avg?: InputMaybe<Dismantling_Plan_Demands_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dismantling_Plan_Demands_Max_Order_By>;
  min?: InputMaybe<Dismantling_Plan_Demands_Min_Order_By>;
  stddev?: InputMaybe<Dismantling_Plan_Demands_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Dismantling_Plan_Demands_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Dismantling_Plan_Demands_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Dismantling_Plan_Demands_Sum_Order_By>;
  var_pop?: InputMaybe<Dismantling_Plan_Demands_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Dismantling_Plan_Demands_Var_Samp_Order_By>;
  variance?: InputMaybe<Dismantling_Plan_Demands_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_Arr_Rel_Insert_Input = {
  data: Array<Dismantling_Plan_Demands_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Dismantling_Plan_Demands_On_Conflict>;
};

/** aggregate avg on columns */
export type Dismantling_Plan_Demands_Avg_Fields = {
  __typename: 'dismantling_plan_demands_avg_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  splitPercentage?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_Avg_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  splitPercentage?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dismantling_plan_demands". All fields are combined with a logical 'AND'. */
export type Dismantling_Plan_Demands_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Dismantling_Plan_Demands_Bool_Exp>>;
  _not?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
  _or?: InputMaybe<Array<Dismantling_Plan_Demands_Bool_Exp>>;
  computedOrderQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  customerOrderItemId?: InputMaybe<Bigint_Comparison_Exp>;
  dismantlingPlan?: InputMaybe<Dismantling_Plans_Bool_Exp>;
  dismantlingPlanId?: InputMaybe<Bigint_Comparison_Exp>;
  hasManualQuantityChanges?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  isFromLateOrder?: InputMaybe<Boolean_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  productUnit?: InputMaybe<Product_Units_Bool_Exp>;
  productUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  quantity?: InputMaybe<Bigint_Comparison_Exp>;
  showOrderQuantity?: InputMaybe<Boolean_Comparison_Exp>;
  splitPercentage?: InputMaybe<Numeric_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "dismantling_plan_demands" */
export enum Dismantling_Plan_Demands_Constraint {
  /** unique or primary key constraint on columns "customer_order_item_id", "product_id", "dismantling_plan_id" */
  DismantlingPlanDemandsDismantlingPlanIdProductIdCusIdx = 'dismantling_plan_demands_dismantling_plan_id_product_id_cus_idx',
  /** unique or primary key constraint on columns "id" */
  DismantlingPlanDemandsPkey = 'dismantling_plan_demands_pkey'
}

/** input type for incrementing numeric columns in table "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_Inc_Input = {
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  dismantlingPlanId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  splitPercentage?: InputMaybe<Scalars['numeric']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_Insert_Input = {
  customerOrderItem?: InputMaybe<Customer_Order_Items_Obj_Rel_Insert_Input>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  dismantlingPlan?: InputMaybe<Dismantling_Plans_Obj_Rel_Insert_Input>;
  dismantlingPlanId?: InputMaybe<Scalars['bigint']>;
  hasManualQuantityChanges?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  productUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  showOrderQuantity?: InputMaybe<Scalars['Boolean']>;
  splitPercentage?: InputMaybe<Scalars['numeric']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Dismantling_Plan_Demands_Max_Fields = {
  __typename: 'dismantling_plan_demands_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  dismantlingPlanId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  splitPercentage?: Maybe<Scalars['numeric']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  splitPercentage?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Dismantling_Plan_Demands_Min_Fields = {
  __typename: 'dismantling_plan_demands_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  dismantlingPlanId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  splitPercentage?: Maybe<Scalars['numeric']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  splitPercentage?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_Mutation_Response = {
  __typename: 'dismantling_plan_demands_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dismantling_Plan_Demands>;
};

/** on_conflict condition type for table "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_On_Conflict = {
  constraint: Dismantling_Plan_Demands_Constraint;
  update_columns?: Array<Dismantling_Plan_Demands_Update_Column>;
  where?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
};

/** Ordering options when selecting data from "dismantling_plan_demands". */
export type Dismantling_Plan_Demands_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  computedOrderQuantity?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  dismantlingPlan?: InputMaybe<Dismantling_Plans_Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  hasManualQuantityChanges?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isFromLateOrder?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnit?: InputMaybe<Product_Units_Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  showOrderQuantity?: InputMaybe<Order_By>;
  splitPercentage?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dismantling_plan_demands */
export type Dismantling_Plan_Demands_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "dismantling_plan_demands" */
export enum Dismantling_Plan_Demands_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerOrderItemId = 'customerOrderItemId',
  /** column name */
  DismantlingPlanId = 'dismantlingPlanId',
  /** column name */
  HasManualQuantityChanges = 'hasManualQuantityChanges',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ProductUnitId = 'productUnitId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ShowOrderQuantity = 'showOrderQuantity',
  /** column name */
  SplitPercentage = 'splitPercentage',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** select "dismantling_plan_demands_aggregate_bool_exp_bool_and_arguments_columns" columns of table "dismantling_plan_demands" */
export enum Dismantling_Plan_Demands_Select_Column_Dismantling_Plan_Demands_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasManualQuantityChanges = 'hasManualQuantityChanges',
  /** column name */
  ShowOrderQuantity = 'showOrderQuantity'
}

/** select "dismantling_plan_demands_aggregate_bool_exp_bool_or_arguments_columns" columns of table "dismantling_plan_demands" */
export enum Dismantling_Plan_Demands_Select_Column_Dismantling_Plan_Demands_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasManualQuantityChanges = 'hasManualQuantityChanges',
  /** column name */
  ShowOrderQuantity = 'showOrderQuantity'
}

/** input type for updating data in table "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_Set_Input = {
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  dismantlingPlanId?: InputMaybe<Scalars['bigint']>;
  hasManualQuantityChanges?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['bigint']>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  showOrderQuantity?: InputMaybe<Scalars['Boolean']>;
  splitPercentage?: InputMaybe<Scalars['numeric']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Dismantling_Plan_Demands_Stddev_Fields = {
  __typename: 'dismantling_plan_demands_stddev_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  splitPercentage?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_Stddev_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  splitPercentage?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dismantling_Plan_Demands_Stddev_Pop_Fields = {
  __typename: 'dismantling_plan_demands_stddev_pop_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  splitPercentage?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_Stddev_Pop_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  splitPercentage?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dismantling_Plan_Demands_Stddev_Samp_Fields = {
  __typename: 'dismantling_plan_demands_stddev_samp_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  splitPercentage?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_Stddev_Samp_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  splitPercentage?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dismantling_Plan_Demands_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dismantling_Plan_Demands_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  dismantlingPlanId?: InputMaybe<Scalars['bigint']>;
  hasManualQuantityChanges?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['bigint']>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  showOrderQuantity?: InputMaybe<Scalars['Boolean']>;
  splitPercentage?: InputMaybe<Scalars['numeric']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Dismantling_Plan_Demands_Sum_Fields = {
  __typename: 'dismantling_plan_demands_sum_fields';
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  dismantlingPlanId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  splitPercentage?: Maybe<Scalars['numeric']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_Sum_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  splitPercentage?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "dismantling_plan_demands" */
export enum Dismantling_Plan_Demands_Update_Column {
  /** column name */
  CustomerOrderItemId = 'customerOrderItemId',
  /** column name */
  DismantlingPlanId = 'dismantlingPlanId',
  /** column name */
  HasManualQuantityChanges = 'hasManualQuantityChanges',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ProductUnitId = 'productUnitId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ShowOrderQuantity = 'showOrderQuantity',
  /** column name */
  SplitPercentage = 'splitPercentage',
  /** column name */
  Version = 'version'
}

export type Dismantling_Plan_Demands_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dismantling_Plan_Demands_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dismantling_Plan_Demands_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dismantling_Plan_Demands_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dismantling_Plan_Demands_Var_Pop_Fields = {
  __typename: 'dismantling_plan_demands_var_pop_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  splitPercentage?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_Var_Pop_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  splitPercentage?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dismantling_Plan_Demands_Var_Samp_Fields = {
  __typename: 'dismantling_plan_demands_var_samp_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  splitPercentage?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_Var_Samp_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  splitPercentage?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Dismantling_Plan_Demands_Variance_Fields = {
  __typename: 'dismantling_plan_demands_variance_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  splitPercentage?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dismantling_plan_demands" */
export type Dismantling_Plan_Demands_Variance_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  splitPercentage?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions = {
  __typename: 'dismantling_plan_instructions';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  dismantlingPlan: Dismantling_Plans;
  dismantlingPlanId: Scalars['bigint'];
  /** An object relationship */
  dismantlingProductGroup: Product_Groups;
  dismantlingProductGroupId: Scalars['bigint'];
  id: Scalars['bigint'];
  instructions: Scalars['String'];
  ordinalPosition: Scalars['Int'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};

/** aggregated selection of "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_Aggregate = {
  __typename: 'dismantling_plan_instructions_aggregate';
  aggregate?: Maybe<Dismantling_Plan_Instructions_Aggregate_Fields>;
  nodes: Array<Dismantling_Plan_Instructions>;
};

export type Dismantling_Plan_Instructions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Dismantling_Plan_Instructions_Aggregate_Bool_Exp_Count>;
};

export type Dismantling_Plan_Instructions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Dismantling_Plan_Instructions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dismantling_Plan_Instructions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_Aggregate_Fields = {
  __typename: 'dismantling_plan_instructions_aggregate_fields';
  avg?: Maybe<Dismantling_Plan_Instructions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dismantling_Plan_Instructions_Max_Fields>;
  min?: Maybe<Dismantling_Plan_Instructions_Min_Fields>;
  stddev?: Maybe<Dismantling_Plan_Instructions_Stddev_Fields>;
  stddev_pop?: Maybe<Dismantling_Plan_Instructions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dismantling_Plan_Instructions_Stddev_Samp_Fields>;
  sum?: Maybe<Dismantling_Plan_Instructions_Sum_Fields>;
  var_pop?: Maybe<Dismantling_Plan_Instructions_Var_Pop_Fields>;
  var_samp?: Maybe<Dismantling_Plan_Instructions_Var_Samp_Fields>;
  variance?: Maybe<Dismantling_Plan_Instructions_Variance_Fields>;
};


/** aggregate fields of "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dismantling_Plan_Instructions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_Aggregate_Order_By = {
  avg?: InputMaybe<Dismantling_Plan_Instructions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dismantling_Plan_Instructions_Max_Order_By>;
  min?: InputMaybe<Dismantling_Plan_Instructions_Min_Order_By>;
  stddev?: InputMaybe<Dismantling_Plan_Instructions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Dismantling_Plan_Instructions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Dismantling_Plan_Instructions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Dismantling_Plan_Instructions_Sum_Order_By>;
  var_pop?: InputMaybe<Dismantling_Plan_Instructions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Dismantling_Plan_Instructions_Var_Samp_Order_By>;
  variance?: InputMaybe<Dismantling_Plan_Instructions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_Arr_Rel_Insert_Input = {
  data: Array<Dismantling_Plan_Instructions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Dismantling_Plan_Instructions_On_Conflict>;
};

/** aggregate avg on columns */
export type Dismantling_Plan_Instructions_Avg_Fields = {
  __typename: 'dismantling_plan_instructions_avg_fields';
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  dismantlingProductGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_Avg_Order_By = {
  dismantlingPlanId?: InputMaybe<Order_By>;
  dismantlingProductGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dismantling_plan_instructions". All fields are combined with a logical 'AND'. */
export type Dismantling_Plan_Instructions_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Dismantling_Plan_Instructions_Bool_Exp>>;
  _not?: InputMaybe<Dismantling_Plan_Instructions_Bool_Exp>;
  _or?: InputMaybe<Array<Dismantling_Plan_Instructions_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dismantlingPlan?: InputMaybe<Dismantling_Plans_Bool_Exp>;
  dismantlingPlanId?: InputMaybe<Bigint_Comparison_Exp>;
  dismantlingProductGroup?: InputMaybe<Product_Groups_Bool_Exp>;
  dismantlingProductGroupId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  instructions?: InputMaybe<String_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "dismantling_plan_instructions" */
export enum Dismantling_Plan_Instructions_Constraint {
  /** unique or primary key constraint on columns "id" */
  DismantlingPlanInstructionsPkey = 'dismantling_plan_instructions_pkey'
}

/** input type for incrementing numeric columns in table "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_Inc_Input = {
  dismantlingPlanId?: InputMaybe<Scalars['bigint']>;
  dismantlingProductGroupId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_Insert_Input = {
  dismantlingPlan?: InputMaybe<Dismantling_Plans_Obj_Rel_Insert_Input>;
  dismantlingPlanId?: InputMaybe<Scalars['bigint']>;
  dismantlingProductGroup?: InputMaybe<Product_Groups_Obj_Rel_Insert_Input>;
  dismantlingProductGroupId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  instructions?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Dismantling_Plan_Instructions_Max_Fields = {
  __typename: 'dismantling_plan_instructions_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dismantlingPlanId?: Maybe<Scalars['bigint']>;
  dismantlingProductGroupId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  instructions?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  dismantlingProductGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instructions?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Dismantling_Plan_Instructions_Min_Fields = {
  __typename: 'dismantling_plan_instructions_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dismantlingPlanId?: Maybe<Scalars['bigint']>;
  dismantlingProductGroupId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  instructions?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  dismantlingProductGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instructions?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_Mutation_Response = {
  __typename: 'dismantling_plan_instructions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dismantling_Plan_Instructions>;
};

/** on_conflict condition type for table "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_On_Conflict = {
  constraint: Dismantling_Plan_Instructions_Constraint;
  update_columns?: Array<Dismantling_Plan_Instructions_Update_Column>;
  where?: InputMaybe<Dismantling_Plan_Instructions_Bool_Exp>;
};

/** Ordering options when selecting data from "dismantling_plan_instructions". */
export type Dismantling_Plan_Instructions_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dismantlingPlan?: InputMaybe<Dismantling_Plans_Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  dismantlingProductGroup?: InputMaybe<Product_Groups_Order_By>;
  dismantlingProductGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instructions?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dismantling_plan_instructions */
export type Dismantling_Plan_Instructions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "dismantling_plan_instructions" */
export enum Dismantling_Plan_Instructions_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DismantlingPlanId = 'dismantlingPlanId',
  /** column name */
  DismantlingProductGroupId = 'dismantlingProductGroupId',
  /** column name */
  Id = 'id',
  /** column name */
  Instructions = 'instructions',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_Set_Input = {
  dismantlingPlanId?: InputMaybe<Scalars['bigint']>;
  dismantlingProductGroupId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  instructions?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Dismantling_Plan_Instructions_Stddev_Fields = {
  __typename: 'dismantling_plan_instructions_stddev_fields';
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  dismantlingProductGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_Stddev_Order_By = {
  dismantlingPlanId?: InputMaybe<Order_By>;
  dismantlingProductGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dismantling_Plan_Instructions_Stddev_Pop_Fields = {
  __typename: 'dismantling_plan_instructions_stddev_pop_fields';
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  dismantlingProductGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_Stddev_Pop_Order_By = {
  dismantlingPlanId?: InputMaybe<Order_By>;
  dismantlingProductGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dismantling_Plan_Instructions_Stddev_Samp_Fields = {
  __typename: 'dismantling_plan_instructions_stddev_samp_fields';
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  dismantlingProductGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_Stddev_Samp_Order_By = {
  dismantlingPlanId?: InputMaybe<Order_By>;
  dismantlingProductGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dismantling_Plan_Instructions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dismantling_Plan_Instructions_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dismantlingPlanId?: InputMaybe<Scalars['bigint']>;
  dismantlingProductGroupId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  instructions?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Dismantling_Plan_Instructions_Sum_Fields = {
  __typename: 'dismantling_plan_instructions_sum_fields';
  dismantlingPlanId?: Maybe<Scalars['bigint']>;
  dismantlingProductGroupId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_Sum_Order_By = {
  dismantlingPlanId?: InputMaybe<Order_By>;
  dismantlingProductGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "dismantling_plan_instructions" */
export enum Dismantling_Plan_Instructions_Update_Column {
  /** column name */
  DismantlingPlanId = 'dismantlingPlanId',
  /** column name */
  DismantlingProductGroupId = 'dismantlingProductGroupId',
  /** column name */
  Id = 'id',
  /** column name */
  Instructions = 'instructions',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  Version = 'version'
}

export type Dismantling_Plan_Instructions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dismantling_Plan_Instructions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dismantling_Plan_Instructions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dismantling_Plan_Instructions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dismantling_Plan_Instructions_Var_Pop_Fields = {
  __typename: 'dismantling_plan_instructions_var_pop_fields';
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  dismantlingProductGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_Var_Pop_Order_By = {
  dismantlingPlanId?: InputMaybe<Order_By>;
  dismantlingProductGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dismantling_Plan_Instructions_Var_Samp_Fields = {
  __typename: 'dismantling_plan_instructions_var_samp_fields';
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  dismantlingProductGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_Var_Samp_Order_By = {
  dismantlingPlanId?: InputMaybe<Order_By>;
  dismantlingProductGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Dismantling_Plan_Instructions_Variance_Fields = {
  __typename: 'dismantling_plan_instructions_variance_fields';
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  dismantlingProductGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dismantling_plan_instructions" */
export type Dismantling_Plan_Instructions_Variance_Order_By = {
  dismantlingPlanId?: InputMaybe<Order_By>;
  dismantlingProductGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

export type Dismantling_Plan_Inventory_Args = {
  id?: InputMaybe<Scalars['bigint']>;
  with_stock_available_quantity?: InputMaybe<Scalars['Boolean']>;
};

export type Dismantling_Plan_Inventory_Candidate_Search_Args = {
  _id?: InputMaybe<Scalars['bigint']>;
  _product_name_search?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "dismantling_plan_logged_actions" */
export type Dismantling_Plan_Logged_Actions = {
  __typename: 'dismantling_plan_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  entity?: Maybe<Dismantling_Plans>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "dismantling_plan_logged_actions" */
export type Dismantling_Plan_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "dismantling_plan_logged_actions" */
export type Dismantling_Plan_Logged_Actions_Aggregate = {
  __typename: 'dismantling_plan_logged_actions_aggregate';
  aggregate?: Maybe<Dismantling_Plan_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Dismantling_Plan_Logged_Actions>;
};

export type Dismantling_Plan_Logged_Actions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Dismantling_Plan_Logged_Actions_Aggregate_Bool_Exp_Count>;
};

export type Dismantling_Plan_Logged_Actions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Dismantling_Plan_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dismantling_Plan_Logged_Actions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "dismantling_plan_logged_actions" */
export type Dismantling_Plan_Logged_Actions_Aggregate_Fields = {
  __typename: 'dismantling_plan_logged_actions_aggregate_fields';
  avg?: Maybe<Dismantling_Plan_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dismantling_Plan_Logged_Actions_Max_Fields>;
  min?: Maybe<Dismantling_Plan_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Dismantling_Plan_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Dismantling_Plan_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dismantling_Plan_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Dismantling_Plan_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Dismantling_Plan_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Dismantling_Plan_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Dismantling_Plan_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "dismantling_plan_logged_actions" */
export type Dismantling_Plan_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dismantling_Plan_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dismantling_plan_logged_actions" */
export type Dismantling_Plan_Logged_Actions_Aggregate_Order_By = {
  avg?: InputMaybe<Dismantling_Plan_Logged_Actions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dismantling_Plan_Logged_Actions_Max_Order_By>;
  min?: InputMaybe<Dismantling_Plan_Logged_Actions_Min_Order_By>;
  stddev?: InputMaybe<Dismantling_Plan_Logged_Actions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Dismantling_Plan_Logged_Actions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Dismantling_Plan_Logged_Actions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Dismantling_Plan_Logged_Actions_Sum_Order_By>;
  var_pop?: InputMaybe<Dismantling_Plan_Logged_Actions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Dismantling_Plan_Logged_Actions_Var_Samp_Order_By>;
  variance?: InputMaybe<Dismantling_Plan_Logged_Actions_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Dismantling_Plan_Logged_Actions_Avg_Fields = {
  __typename: 'dismantling_plan_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dismantling_plan_logged_actions" */
export type Dismantling_Plan_Logged_Actions_Avg_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dismantling_plan_logged_actions". All fields are combined with a logical 'AND'. */
export type Dismantling_Plan_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Dismantling_Plan_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Dismantling_Plan_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Dismantling_Plan_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entity?: InputMaybe<Dismantling_Plans_Bool_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dismantling_Plan_Logged_Actions_Max_Fields = {
  __typename: 'dismantling_plan_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "dismantling_plan_logged_actions" */
export type Dismantling_Plan_Logged_Actions_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Dismantling_Plan_Logged_Actions_Min_Fields = {
  __typename: 'dismantling_plan_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "dismantling_plan_logged_actions" */
export type Dismantling_Plan_Logged_Actions_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "dismantling_plan_logged_actions". */
export type Dismantling_Plan_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entity?: InputMaybe<Dismantling_Plans_Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "dismantling_plan_logged_actions" */
export enum Dismantling_Plan_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Role = 'role',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Dismantling_Plan_Logged_Actions_Stddev_Fields = {
  __typename: 'dismantling_plan_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dismantling_plan_logged_actions" */
export type Dismantling_Plan_Logged_Actions_Stddev_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dismantling_Plan_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'dismantling_plan_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dismantling_plan_logged_actions" */
export type Dismantling_Plan_Logged_Actions_Stddev_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dismantling_Plan_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'dismantling_plan_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dismantling_plan_logged_actions" */
export type Dismantling_Plan_Logged_Actions_Stddev_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "dismantling_plan_logged_actions" */
export type Dismantling_Plan_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dismantling_Plan_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dismantling_Plan_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  role?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Dismantling_Plan_Logged_Actions_Sum_Fields = {
  __typename: 'dismantling_plan_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "dismantling_plan_logged_actions" */
export type Dismantling_Plan_Logged_Actions_Sum_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Dismantling_Plan_Logged_Actions_Var_Pop_Fields = {
  __typename: 'dismantling_plan_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dismantling_plan_logged_actions" */
export type Dismantling_Plan_Logged_Actions_Var_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dismantling_Plan_Logged_Actions_Var_Samp_Fields = {
  __typename: 'dismantling_plan_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dismantling_plan_logged_actions" */
export type Dismantling_Plan_Logged_Actions_Var_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Dismantling_Plan_Logged_Actions_Variance_Fields = {
  __typename: 'dismantling_plan_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dismantling_plan_logged_actions" */
export type Dismantling_Plan_Logged_Actions_Variance_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "dismantling_plan_priorities" */
export type Dismantling_Plan_Priorities = {
  __typename: 'dismantling_plan_priorities';
  /** An array relationship */
  cuttingPatternOutputs: Array<Cutting_Pattern_Outputs>;
  /** An aggregate relationship */
  cuttingPatternOutputs_aggregate: Cutting_Pattern_Outputs_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "dismantling_plan_priorities" */
export type Dismantling_Plan_PrioritiesCuttingPatternOutputsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
};


/** columns and relationships of "dismantling_plan_priorities" */
export type Dismantling_Plan_PrioritiesCuttingPatternOutputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
};

/** aggregated selection of "dismantling_plan_priorities" */
export type Dismantling_Plan_Priorities_Aggregate = {
  __typename: 'dismantling_plan_priorities_aggregate';
  aggregate?: Maybe<Dismantling_Plan_Priorities_Aggregate_Fields>;
  nodes: Array<Dismantling_Plan_Priorities>;
};

/** aggregate fields of "dismantling_plan_priorities" */
export type Dismantling_Plan_Priorities_Aggregate_Fields = {
  __typename: 'dismantling_plan_priorities_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Dismantling_Plan_Priorities_Max_Fields>;
  min?: Maybe<Dismantling_Plan_Priorities_Min_Fields>;
};


/** aggregate fields of "dismantling_plan_priorities" */
export type Dismantling_Plan_Priorities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dismantling_Plan_Priorities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "dismantling_plan_priorities". All fields are combined with a logical 'AND'. */
export type Dismantling_Plan_Priorities_Bool_Exp = {
  _and?: InputMaybe<Array<Dismantling_Plan_Priorities_Bool_Exp>>;
  _not?: InputMaybe<Dismantling_Plan_Priorities_Bool_Exp>;
  _or?: InputMaybe<Array<Dismantling_Plan_Priorities_Bool_Exp>>;
  cuttingPatternOutputs?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
  cuttingPatternOutputs_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Dismantling_Plan_Priorities_Enum {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY'
}

/** Boolean expression to compare columns of type "dismantling_plan_priorities_enum". All fields are combined with logical 'AND'. */
export type Dismantling_Plan_Priorities_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Dismantling_Plan_Priorities_Enum>;
  _in?: InputMaybe<Array<Dismantling_Plan_Priorities_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Dismantling_Plan_Priorities_Enum>;
  _nin?: InputMaybe<Array<Dismantling_Plan_Priorities_Enum>>;
};

/** aggregate max on columns */
export type Dismantling_Plan_Priorities_Max_Fields = {
  __typename: 'dismantling_plan_priorities_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Dismantling_Plan_Priorities_Min_Fields = {
  __typename: 'dismantling_plan_priorities_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "dismantling_plan_priorities". */
export type Dismantling_Plan_Priorities_Order_By = {
  cuttingPatternOutputs_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "dismantling_plan_priorities" */
export enum Dismantling_Plan_Priorities_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "dismantling_plan_priorities" */
export type Dismantling_Plan_Priorities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dismantling_Plan_Priorities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dismantling_Plan_Priorities_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "dismantling_plan_remaining_pieces" */
export type Dismantling_Plan_Remaining_Pieces = {
  __typename: 'dismantling_plan_remaining_pieces';
  /** An object relationship */
  Tenant: Tenants;
  /** An object relationship */
  containerProduct?: Maybe<Products>;
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  dismantlingPlan: Dismantling_Plans;
  dismantlingPlanId: Scalars['bigint'];
  id: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  productionNote?: Maybe<Scalars['String']>;
  quantity: Scalars['bigint'];
  /** An object relationship */
  stockLocation?: Maybe<Stock_Locations>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  tenant: Scalars['String'];
};

/** aggregated selection of "dismantling_plan_remaining_pieces" */
export type Dismantling_Plan_Remaining_Pieces_Aggregate = {
  __typename: 'dismantling_plan_remaining_pieces_aggregate';
  aggregate?: Maybe<Dismantling_Plan_Remaining_Pieces_Aggregate_Fields>;
  nodes: Array<Dismantling_Plan_Remaining_Pieces>;
};

export type Dismantling_Plan_Remaining_Pieces_Aggregate_Bool_Exp = {
  count?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Aggregate_Bool_Exp_Count>;
};

export type Dismantling_Plan_Remaining_Pieces_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "dismantling_plan_remaining_pieces" */
export type Dismantling_Plan_Remaining_Pieces_Aggregate_Fields = {
  __typename: 'dismantling_plan_remaining_pieces_aggregate_fields';
  avg?: Maybe<Dismantling_Plan_Remaining_Pieces_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dismantling_Plan_Remaining_Pieces_Max_Fields>;
  min?: Maybe<Dismantling_Plan_Remaining_Pieces_Min_Fields>;
  stddev?: Maybe<Dismantling_Plan_Remaining_Pieces_Stddev_Fields>;
  stddev_pop?: Maybe<Dismantling_Plan_Remaining_Pieces_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dismantling_Plan_Remaining_Pieces_Stddev_Samp_Fields>;
  sum?: Maybe<Dismantling_Plan_Remaining_Pieces_Sum_Fields>;
  var_pop?: Maybe<Dismantling_Plan_Remaining_Pieces_Var_Pop_Fields>;
  var_samp?: Maybe<Dismantling_Plan_Remaining_Pieces_Var_Samp_Fields>;
  variance?: Maybe<Dismantling_Plan_Remaining_Pieces_Variance_Fields>;
};


/** aggregate fields of "dismantling_plan_remaining_pieces" */
export type Dismantling_Plan_Remaining_Pieces_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dismantling_plan_remaining_pieces" */
export type Dismantling_Plan_Remaining_Pieces_Aggregate_Order_By = {
  avg?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Max_Order_By>;
  min?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Min_Order_By>;
  stddev?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Sum_Order_By>;
  var_pop?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Var_Samp_Order_By>;
  variance?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Dismantling_Plan_Remaining_Pieces_Avg_Fields = {
  __typename: 'dismantling_plan_remaining_pieces_avg_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dismantling_plan_remaining_pieces" */
export type Dismantling_Plan_Remaining_Pieces_Avg_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dismantling_plan_remaining_pieces". All fields are combined with a logical 'AND'. */
export type Dismantling_Plan_Remaining_Pieces_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Bool_Exp>>;
  _not?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
  _or?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Bool_Exp>>;
  containerProduct?: InputMaybe<Products_Bool_Exp>;
  containerProductId?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dismantlingPlan?: InputMaybe<Dismantling_Plans_Bool_Exp>;
  dismantlingPlanId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  productionNote?: InputMaybe<String_Comparison_Exp>;
  quantity?: InputMaybe<Bigint_Comparison_Exp>;
  stockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  stockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "dismantling_plan_remaining_pieces" */
export type Dismantling_Plan_Remaining_Pieces_Inc_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  dismantlingPlanId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Dismantling_Plan_Remaining_Pieces_Max_Fields = {
  __typename: 'dismantling_plan_remaining_pieces_max_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dismantlingPlanId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  productionNote?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "dismantling_plan_remaining_pieces" */
export type Dismantling_Plan_Remaining_Pieces_Max_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productionNote?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Dismantling_Plan_Remaining_Pieces_Min_Fields = {
  __typename: 'dismantling_plan_remaining_pieces_min_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dismantlingPlanId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  productionNote?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "dismantling_plan_remaining_pieces" */
export type Dismantling_Plan_Remaining_Pieces_Min_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productionNote?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "dismantling_plan_remaining_pieces" */
export type Dismantling_Plan_Remaining_Pieces_Mutation_Response = {
  __typename: 'dismantling_plan_remaining_pieces_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dismantling_Plan_Remaining_Pieces>;
};

/** Ordering options when selecting data from "dismantling_plan_remaining_pieces". */
export type Dismantling_Plan_Remaining_Pieces_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  containerProduct?: InputMaybe<Products_Order_By>;
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dismantlingPlan?: InputMaybe<Dismantling_Plans_Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  productionNote?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocation?: InputMaybe<Stock_Locations_Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dismantling_plan_remaining_pieces */
export type Dismantling_Plan_Remaining_Pieces_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "dismantling_plan_remaining_pieces" */
export enum Dismantling_Plan_Remaining_Pieces_Select_Column {
  /** column name */
  ContainerProductId = 'containerProductId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DismantlingPlanId = 'dismantlingPlanId',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ProductionNote = 'productionNote',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  StockLocationId = 'stockLocationId',
  /** column name */
  Tenant = 'tenant'
}

/** input type for updating data in table "dismantling_plan_remaining_pieces" */
export type Dismantling_Plan_Remaining_Pieces_Set_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  dismantlingPlanId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  productionNote?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Dismantling_Plan_Remaining_Pieces_Stddev_Fields = {
  __typename: 'dismantling_plan_remaining_pieces_stddev_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dismantling_plan_remaining_pieces" */
export type Dismantling_Plan_Remaining_Pieces_Stddev_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dismantling_Plan_Remaining_Pieces_Stddev_Pop_Fields = {
  __typename: 'dismantling_plan_remaining_pieces_stddev_pop_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dismantling_plan_remaining_pieces" */
export type Dismantling_Plan_Remaining_Pieces_Stddev_Pop_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dismantling_Plan_Remaining_Pieces_Stddev_Samp_Fields = {
  __typename: 'dismantling_plan_remaining_pieces_stddev_samp_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dismantling_plan_remaining_pieces" */
export type Dismantling_Plan_Remaining_Pieces_Stddev_Samp_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "dismantling_plan_remaining_pieces" */
export type Dismantling_Plan_Remaining_Pieces_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dismantling_Plan_Remaining_Pieces_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dismantling_Plan_Remaining_Pieces_Stream_Cursor_Value_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dismantlingPlanId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  productionNote?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Dismantling_Plan_Remaining_Pieces_Sum_Fields = {
  __typename: 'dismantling_plan_remaining_pieces_sum_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  dismantlingPlanId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "dismantling_plan_remaining_pieces" */
export type Dismantling_Plan_Remaining_Pieces_Sum_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
};

export type Dismantling_Plan_Remaining_Pieces_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dismantling_Plan_Remaining_Pieces_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dismantling_Plan_Remaining_Pieces_Var_Pop_Fields = {
  __typename: 'dismantling_plan_remaining_pieces_var_pop_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dismantling_plan_remaining_pieces" */
export type Dismantling_Plan_Remaining_Pieces_Var_Pop_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dismantling_Plan_Remaining_Pieces_Var_Samp_Fields = {
  __typename: 'dismantling_plan_remaining_pieces_var_samp_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dismantling_plan_remaining_pieces" */
export type Dismantling_Plan_Remaining_Pieces_Var_Samp_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Dismantling_Plan_Remaining_Pieces_Variance_Fields = {
  __typename: 'dismantling_plan_remaining_pieces_variance_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  dismantlingPlanId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dismantling_plan_remaining_pieces" */
export type Dismantling_Plan_Remaining_Pieces_Variance_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  dismantlingPlanId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
};

/** columns and relationships of "dismantling_plan_state_transitions" */
export type Dismantling_Plan_State_Transitions = {
  __typename: 'dismantling_plan_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Dismantling_Plan_States_Enum;
  toState: Dismantling_Plan_States_Enum;
};

/** aggregated selection of "dismantling_plan_state_transitions" */
export type Dismantling_Plan_State_Transitions_Aggregate = {
  __typename: 'dismantling_plan_state_transitions_aggregate';
  aggregate?: Maybe<Dismantling_Plan_State_Transitions_Aggregate_Fields>;
  nodes: Array<Dismantling_Plan_State_Transitions>;
};

/** aggregate fields of "dismantling_plan_state_transitions" */
export type Dismantling_Plan_State_Transitions_Aggregate_Fields = {
  __typename: 'dismantling_plan_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Dismantling_Plan_State_Transitions_Max_Fields>;
  min?: Maybe<Dismantling_Plan_State_Transitions_Min_Fields>;
};


/** aggregate fields of "dismantling_plan_state_transitions" */
export type Dismantling_Plan_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dismantling_Plan_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "dismantling_plan_state_transitions". All fields are combined with a logical 'AND'. */
export type Dismantling_Plan_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Dismantling_Plan_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Dismantling_Plan_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Dismantling_Plan_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Dismantling_Plan_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Dismantling_Plan_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dismantling_Plan_State_Transitions_Max_Fields = {
  __typename: 'dismantling_plan_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Dismantling_Plan_State_Transitions_Min_Fields = {
  __typename: 'dismantling_plan_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "dismantling_plan_state_transitions". */
export type Dismantling_Plan_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "dismantling_plan_state_transitions" */
export enum Dismantling_Plan_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "dismantling_plan_state_transitions" */
export type Dismantling_Plan_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dismantling_Plan_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dismantling_Plan_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Dismantling_Plan_States_Enum>;
  toState?: InputMaybe<Dismantling_Plan_States_Enum>;
};

/** columns and relationships of "dismantling_plan_states" */
export type Dismantling_Plan_States = {
  __typename: 'dismantling_plan_states';
  /** An array relationship */
  dismantlingPlans: Array<Dismantling_Plans>;
  /** An aggregate relationship */
  dismantlingPlans_aggregate: Dismantling_Plans_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "dismantling_plan_states" */
export type Dismantling_Plan_StatesDismantlingPlansArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plans_Order_By>>;
  where?: InputMaybe<Dismantling_Plans_Bool_Exp>;
};


/** columns and relationships of "dismantling_plan_states" */
export type Dismantling_Plan_StatesDismantlingPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plans_Order_By>>;
  where?: InputMaybe<Dismantling_Plans_Bool_Exp>;
};

/** aggregated selection of "dismantling_plan_states" */
export type Dismantling_Plan_States_Aggregate = {
  __typename: 'dismantling_plan_states_aggregate';
  aggregate?: Maybe<Dismantling_Plan_States_Aggregate_Fields>;
  nodes: Array<Dismantling_Plan_States>;
};

/** aggregate fields of "dismantling_plan_states" */
export type Dismantling_Plan_States_Aggregate_Fields = {
  __typename: 'dismantling_plan_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Dismantling_Plan_States_Max_Fields>;
  min?: Maybe<Dismantling_Plan_States_Min_Fields>;
};


/** aggregate fields of "dismantling_plan_states" */
export type Dismantling_Plan_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dismantling_Plan_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "dismantling_plan_states". All fields are combined with a logical 'AND'. */
export type Dismantling_Plan_States_Bool_Exp = {
  _and?: InputMaybe<Array<Dismantling_Plan_States_Bool_Exp>>;
  _not?: InputMaybe<Dismantling_Plan_States_Bool_Exp>;
  _or?: InputMaybe<Array<Dismantling_Plan_States_Bool_Exp>>;
  dismantlingPlans?: InputMaybe<Dismantling_Plans_Bool_Exp>;
  dismantlingPlans_aggregate?: InputMaybe<Dismantling_Plans_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Dismantling_Plan_States_Enum {
  Active = 'ACTIVE',
  Committed = 'COMMITTED',
  NotSolving = 'NOT_SOLVING',
  SolvingActive = 'SOLVING_ACTIVE'
}

/** Boolean expression to compare columns of type "dismantling_plan_states_enum". All fields are combined with logical 'AND'. */
export type Dismantling_Plan_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Dismantling_Plan_States_Enum>;
  _in?: InputMaybe<Array<Dismantling_Plan_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Dismantling_Plan_States_Enum>;
  _nin?: InputMaybe<Array<Dismantling_Plan_States_Enum>>;
};

/** aggregate max on columns */
export type Dismantling_Plan_States_Max_Fields = {
  __typename: 'dismantling_plan_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Dismantling_Plan_States_Min_Fields = {
  __typename: 'dismantling_plan_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "dismantling_plan_states". */
export type Dismantling_Plan_States_Order_By = {
  dismantlingPlans_aggregate?: InputMaybe<Dismantling_Plans_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "dismantling_plan_states" */
export enum Dismantling_Plan_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "dismantling_plan_states" */
export type Dismantling_Plan_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dismantling_Plan_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dismantling_Plan_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "dismantling_plans" */
export type Dismantling_Plans = {
  __typename: 'dismantling_plans';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  accounts: Array<Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Accounts_Aggregate;
  createdAt: Scalars['timestamptz'];
  /** Computes the sum of the all the missing pieces that we still need to produce */
  deficit?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  dismantlingPlanCuttingPatternChoices: Array<Dismantling_Plan_Cutting_Pattern_Choices>;
  /** An aggregate relationship */
  dismantlingPlanCuttingPatternChoices_aggregate: Dismantling_Plan_Cutting_Pattern_Choices_Aggregate;
  /** An array relationship */
  dismantlingPlanDemands: Array<Dismantling_Plan_Demands>;
  /** An aggregate relationship */
  dismantlingPlanDemands_aggregate: Dismantling_Plan_Demands_Aggregate;
  /** An array relationship */
  dismantlingPlanInstructions: Array<Dismantling_Plan_Instructions>;
  /** An aggregate relationship */
  dismantlingPlanInstructions_aggregate: Dismantling_Plan_Instructions_Aggregate;
  /** An array relationship */
  dismantlingPlanRemainingPieces: Array<Dismantling_Plan_Remaining_Pieces>;
  /** An aggregate relationship */
  dismantlingPlanRemainingPieces_aggregate: Dismantling_Plan_Remaining_Pieces_Aggregate;
  dueDate: Scalars['date'];
  generateSvgOnNextSolve?: Maybe<Scalars['Boolean']>;
  id: Scalars['bigint'];
  /** An object relationship */
  journalEntry?: Maybe<Journal_Entries>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  lastUnprocessedFactChange?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  loggedActions: Array<Dismantling_Plan_Logged_Actions>;
  /** An aggregate relationship */
  loggedActions_aggregate: Dismantling_Plan_Logged_Actions_Aggregate;
  /** Check whether the given dismantling plans animal type on the given date matches the work calendar base data. */
  matchesWorkCalendar?: Maybe<Scalars['Boolean']>;
  /** Returns the calculated produced product weights based off the currently active tcpos. */
  outputWeights?: Maybe<Array<Dismantling_Output_Weight_Results>>;
  /** Computes the sum of the all the overage pieces that will need to be assigned/processed */
  overage?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  pdfDocument?: Maybe<S3_Objects>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  productGroup: Product_Groups;
  productGroupId: Scalars['bigint'];
  state: Dismantling_Plan_States_Enum;
  /** Computes the sum of the all the pieces that are demanded in this dismantling plan */
  sumOfDemandPieces?: Maybe<Scalars['bigint']>;
  /** Computes the sum of the pieces of root all products that are assigned to this dismantling plan */
  sumOfInventoryPieces?: Maybe<Scalars['bigint']>;
  /** Computes the sum of the pieces of root cutting products that are assigned to this dismantling plan */
  sumOfRootProductInventoryPieces?: Maybe<Scalars['bigint']>;
  svg?: Maybe<Scalars['xml']>;
  svgGeneratedAt?: Maybe<Scalars['timestamptz']>;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
  /** Returns a data structure compatible with https://github.com/vasturiano/react-force-graph */
  vizGraph?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  xlsxDocument?: Maybe<S3_Objects>;
  xlsxDocumentId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "dismantling_plans" */
export type Dismantling_PlansAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


/** columns and relationships of "dismantling_plans" */
export type Dismantling_PlansAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


/** columns and relationships of "dismantling_plans" */
export type Dismantling_PlansDismantlingPlanCuttingPatternChoicesArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>;
};


/** columns and relationships of "dismantling_plans" */
export type Dismantling_PlansDismantlingPlanCuttingPatternChoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>;
};


/** columns and relationships of "dismantling_plans" */
export type Dismantling_PlansDismantlingPlanDemandsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Demands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Demands_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
};


/** columns and relationships of "dismantling_plans" */
export type Dismantling_PlansDismantlingPlanDemands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Demands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Demands_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
};


/** columns and relationships of "dismantling_plans" */
export type Dismantling_PlansDismantlingPlanInstructionsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Instructions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Instructions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Instructions_Bool_Exp>;
};


/** columns and relationships of "dismantling_plans" */
export type Dismantling_PlansDismantlingPlanInstructions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Instructions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Instructions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Instructions_Bool_Exp>;
};


/** columns and relationships of "dismantling_plans" */
export type Dismantling_PlansDismantlingPlanRemainingPiecesArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
};


/** columns and relationships of "dismantling_plans" */
export type Dismantling_PlansDismantlingPlanRemainingPieces_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
};


/** columns and relationships of "dismantling_plans" */
export type Dismantling_PlansLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Logged_Actions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "dismantling_plans" */
export type Dismantling_PlansLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Logged_Actions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "dismantling_plans" */
export type Dismantling_PlansOutputWeightsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Output_Weight_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Output_Weight_Results_Order_By>>;
  where?: InputMaybe<Dismantling_Output_Weight_Results_Bool_Exp>;
};


/** columns and relationships of "dismantling_plans" */
export type Dismantling_PlansVizGraphArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "dismantling_plans" */
export type Dismantling_Plans_Aggregate = {
  __typename: 'dismantling_plans_aggregate';
  aggregate?: Maybe<Dismantling_Plans_Aggregate_Fields>;
  nodes: Array<Dismantling_Plans>;
};

export type Dismantling_Plans_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Dismantling_Plans_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Dismantling_Plans_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Dismantling_Plans_Aggregate_Bool_Exp_Count>;
};

export type Dismantling_Plans_Aggregate_Bool_Exp_Bool_And = {
  arguments: Dismantling_Plans_Select_Column_Dismantling_Plans_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dismantling_Plans_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Dismantling_Plans_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Dismantling_Plans_Select_Column_Dismantling_Plans_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dismantling_Plans_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Dismantling_Plans_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Dismantling_Plans_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dismantling_Plans_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "dismantling_plans" */
export type Dismantling_Plans_Aggregate_Fields = {
  __typename: 'dismantling_plans_aggregate_fields';
  avg?: Maybe<Dismantling_Plans_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dismantling_Plans_Max_Fields>;
  min?: Maybe<Dismantling_Plans_Min_Fields>;
  stddev?: Maybe<Dismantling_Plans_Stddev_Fields>;
  stddev_pop?: Maybe<Dismantling_Plans_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dismantling_Plans_Stddev_Samp_Fields>;
  sum?: Maybe<Dismantling_Plans_Sum_Fields>;
  var_pop?: Maybe<Dismantling_Plans_Var_Pop_Fields>;
  var_samp?: Maybe<Dismantling_Plans_Var_Samp_Fields>;
  variance?: Maybe<Dismantling_Plans_Variance_Fields>;
};


/** aggregate fields of "dismantling_plans" */
export type Dismantling_Plans_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dismantling_Plans_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dismantling_plans" */
export type Dismantling_Plans_Aggregate_Order_By = {
  avg?: InputMaybe<Dismantling_Plans_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dismantling_Plans_Max_Order_By>;
  min?: InputMaybe<Dismantling_Plans_Min_Order_By>;
  stddev?: InputMaybe<Dismantling_Plans_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Dismantling_Plans_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Dismantling_Plans_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Dismantling_Plans_Sum_Order_By>;
  var_pop?: InputMaybe<Dismantling_Plans_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Dismantling_Plans_Var_Samp_Order_By>;
  variance?: InputMaybe<Dismantling_Plans_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dismantling_plans" */
export type Dismantling_Plans_Arr_Rel_Insert_Input = {
  data: Array<Dismantling_Plans_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Dismantling_Plans_On_Conflict>;
};

/** aggregate avg on columns */
export type Dismantling_Plans_Avg_Fields = {
  __typename: 'dismantling_plans_avg_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xlsxDocumentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dismantling_plans" */
export type Dismantling_Plans_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xlsxDocumentId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dismantling_plans". All fields are combined with a logical 'AND'. */
export type Dismantling_Plans_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Dismantling_Plans_Bool_Exp>>;
  _not?: InputMaybe<Dismantling_Plans_Bool_Exp>;
  _or?: InputMaybe<Array<Dismantling_Plans_Bool_Exp>>;
  accounts?: InputMaybe<Accounts_Bool_Exp>;
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deficit?: InputMaybe<Bigint_Comparison_Exp>;
  dismantlingPlanCuttingPatternChoices?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>;
  dismantlingPlanCuttingPatternChoices_aggregate?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_Bool_Exp>;
  dismantlingPlanDemands?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
  dismantlingPlanDemands_aggregate?: InputMaybe<Dismantling_Plan_Demands_Aggregate_Bool_Exp>;
  dismantlingPlanInstructions?: InputMaybe<Dismantling_Plan_Instructions_Bool_Exp>;
  dismantlingPlanInstructions_aggregate?: InputMaybe<Dismantling_Plan_Instructions_Aggregate_Bool_Exp>;
  dismantlingPlanRemainingPieces?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
  dismantlingPlanRemainingPieces_aggregate?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Aggregate_Bool_Exp>;
  dueDate?: InputMaybe<Date_Comparison_Exp>;
  generateSvgOnNextSolve?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  journalEntry?: InputMaybe<Journal_Entries_Bool_Exp>;
  journalEntryId?: InputMaybe<Bigint_Comparison_Exp>;
  lastUnprocessedFactChange?: InputMaybe<Timestamptz_Comparison_Exp>;
  loggedActions?: InputMaybe<Dismantling_Plan_Logged_Actions_Bool_Exp>;
  loggedActions_aggregate?: InputMaybe<Dismantling_Plan_Logged_Actions_Aggregate_Bool_Exp>;
  matchesWorkCalendar?: InputMaybe<Boolean_Comparison_Exp>;
  outputWeights?: InputMaybe<Dismantling_Output_Weight_Results_Bool_Exp>;
  overage?: InputMaybe<Bigint_Comparison_Exp>;
  pdfDocument?: InputMaybe<S3_Objects_Bool_Exp>;
  pdfDocumentId?: InputMaybe<Bigint_Comparison_Exp>;
  productGroup?: InputMaybe<Product_Groups_Bool_Exp>;
  productGroupId?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<Dismantling_Plan_States_Enum_Comparison_Exp>;
  sumOfDemandPieces?: InputMaybe<Bigint_Comparison_Exp>;
  sumOfInventoryPieces?: InputMaybe<Bigint_Comparison_Exp>;
  sumOfRootProductInventoryPieces?: InputMaybe<Bigint_Comparison_Exp>;
  svg?: InputMaybe<Xml_Comparison_Exp>;
  svgGeneratedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
  vizGraph?: InputMaybe<Jsonb_Comparison_Exp>;
  xlsxDocument?: InputMaybe<S3_Objects_Bool_Exp>;
  xlsxDocumentId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "dismantling_plans" */
export enum Dismantling_Plans_Constraint {
  /** unique or primary key constraint on columns "id" */
  DismantlingPlansPkey = 'dismantling_plans_pkey',
  /** unique or primary key constraint on columns "product_group_id", "tenant", "due_date" */
  DismantlingPlansTenantDueDateProductGroupIdKey = 'dismantling_plans_tenant_due_date_product_group_id_key'
}

/** input type for incrementing numeric columns in table "dismantling_plans" */
export type Dismantling_Plans_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  productGroupId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  xlsxDocumentId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "dismantling_plans" */
export type Dismantling_Plans_Insert_Input = {
  accounts?: InputMaybe<Accounts_Arr_Rel_Insert_Input>;
  dismantlingPlanCuttingPatternChoices?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Arr_Rel_Insert_Input>;
  dismantlingPlanDemands?: InputMaybe<Dismantling_Plan_Demands_Arr_Rel_Insert_Input>;
  dismantlingPlanInstructions?: InputMaybe<Dismantling_Plan_Instructions_Arr_Rel_Insert_Input>;
  dueDate?: InputMaybe<Scalars['date']>;
  generateSvgOnNextSolve?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntry?: InputMaybe<Journal_Entries_Obj_Rel_Insert_Input>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  lastUnprocessedFactChange?: InputMaybe<Scalars['timestamptz']>;
  pdfDocument?: InputMaybe<S3_Objects_Obj_Rel_Insert_Input>;
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  productGroup?: InputMaybe<Product_Groups_Obj_Rel_Insert_Input>;
  productGroupId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Dismantling_Plan_States_Enum>;
  svg?: InputMaybe<Scalars['xml']>;
  svgGeneratedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
  xlsxDocument?: InputMaybe<S3_Objects_Obj_Rel_Insert_Input>;
  xlsxDocumentId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Dismantling_Plans_Max_Fields = {
  __typename: 'dismantling_plans_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dueDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  lastUnprocessedFactChange?: Maybe<Scalars['timestamptz']>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  productGroupId?: Maybe<Scalars['bigint']>;
  svgGeneratedAt?: Maybe<Scalars['timestamptz']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  xlsxDocumentId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "dismantling_plans" */
export type Dismantling_Plans_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  dueDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lastUnprocessedFactChange?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  svgGeneratedAt?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xlsxDocumentId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Dismantling_Plans_Min_Fields = {
  __typename: 'dismantling_plans_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dueDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  lastUnprocessedFactChange?: Maybe<Scalars['timestamptz']>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  productGroupId?: Maybe<Scalars['bigint']>;
  svgGeneratedAt?: Maybe<Scalars['timestamptz']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  xlsxDocumentId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "dismantling_plans" */
export type Dismantling_Plans_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  dueDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lastUnprocessedFactChange?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  svgGeneratedAt?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xlsxDocumentId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "dismantling_plans" */
export type Dismantling_Plans_Mutation_Response = {
  __typename: 'dismantling_plans_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dismantling_Plans>;
};

/** input type for inserting object relation for remote table "dismantling_plans" */
export type Dismantling_Plans_Obj_Rel_Insert_Input = {
  data: Dismantling_Plans_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Dismantling_Plans_On_Conflict>;
};

/** on_conflict condition type for table "dismantling_plans" */
export type Dismantling_Plans_On_Conflict = {
  constraint: Dismantling_Plans_Constraint;
  update_columns?: Array<Dismantling_Plans_Update_Column>;
  where?: InputMaybe<Dismantling_Plans_Bool_Exp>;
};

/** Ordering options when selecting data from "dismantling_plans". */
export type Dismantling_Plans_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deficit?: InputMaybe<Order_By>;
  dismantlingPlanCuttingPatternChoices_aggregate?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_Order_By>;
  dismantlingPlanDemands_aggregate?: InputMaybe<Dismantling_Plan_Demands_Aggregate_Order_By>;
  dismantlingPlanInstructions_aggregate?: InputMaybe<Dismantling_Plan_Instructions_Aggregate_Order_By>;
  dismantlingPlanRemainingPieces_aggregate?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Aggregate_Order_By>;
  dueDate?: InputMaybe<Order_By>;
  generateSvgOnNextSolve?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntry?: InputMaybe<Journal_Entries_Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lastUnprocessedFactChange?: InputMaybe<Order_By>;
  loggedActions_aggregate?: InputMaybe<Dismantling_Plan_Logged_Actions_Aggregate_Order_By>;
  matchesWorkCalendar?: InputMaybe<Order_By>;
  outputWeights_aggregate?: InputMaybe<Dismantling_Output_Weight_Results_Aggregate_Order_By>;
  overage?: InputMaybe<Order_By>;
  pdfDocument?: InputMaybe<S3_Objects_Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  productGroup?: InputMaybe<Product_Groups_Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  sumOfDemandPieces?: InputMaybe<Order_By>;
  sumOfInventoryPieces?: InputMaybe<Order_By>;
  sumOfRootProductInventoryPieces?: InputMaybe<Order_By>;
  svg?: InputMaybe<Order_By>;
  svgGeneratedAt?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  vizGraph?: InputMaybe<Order_By>;
  xlsxDocument?: InputMaybe<S3_Objects_Order_By>;
  xlsxDocumentId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dismantling_plans */
export type Dismantling_Plans_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "dismantling_plans" */
export enum Dismantling_Plans_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DueDate = 'dueDate',
  /** column name */
  GenerateSvgOnNextSolve = 'generateSvgOnNextSolve',
  /** column name */
  Id = 'id',
  /** column name */
  JournalEntryId = 'journalEntryId',
  /** column name */
  LastUnprocessedFactChange = 'lastUnprocessedFactChange',
  /** column name */
  PdfDocumentId = 'pdfDocumentId',
  /** column name */
  ProductGroupId = 'productGroupId',
  /** column name */
  State = 'state',
  /** column name */
  Svg = 'svg',
  /** column name */
  SvgGeneratedAt = 'svgGeneratedAt',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version',
  /** column name */
  XlsxDocumentId = 'xlsxDocumentId'
}

/** select "dismantling_plans_aggregate_bool_exp_bool_and_arguments_columns" columns of table "dismantling_plans" */
export enum Dismantling_Plans_Select_Column_Dismantling_Plans_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  GenerateSvgOnNextSolve = 'generateSvgOnNextSolve'
}

/** select "dismantling_plans_aggregate_bool_exp_bool_or_arguments_columns" columns of table "dismantling_plans" */
export enum Dismantling_Plans_Select_Column_Dismantling_Plans_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  GenerateSvgOnNextSolve = 'generateSvgOnNextSolve'
}

/** input type for updating data in table "dismantling_plans" */
export type Dismantling_Plans_Set_Input = {
  dueDate?: InputMaybe<Scalars['date']>;
  generateSvgOnNextSolve?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  lastUnprocessedFactChange?: InputMaybe<Scalars['timestamptz']>;
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  productGroupId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Dismantling_Plan_States_Enum>;
  svg?: InputMaybe<Scalars['xml']>;
  svgGeneratedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
  xlsxDocumentId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Dismantling_Plans_Stddev_Fields = {
  __typename: 'dismantling_plans_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xlsxDocumentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dismantling_plans" */
export type Dismantling_Plans_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xlsxDocumentId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dismantling_Plans_Stddev_Pop_Fields = {
  __typename: 'dismantling_plans_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xlsxDocumentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dismantling_plans" */
export type Dismantling_Plans_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xlsxDocumentId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dismantling_Plans_Stddev_Samp_Fields = {
  __typename: 'dismantling_plans_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xlsxDocumentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dismantling_plans" */
export type Dismantling_Plans_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xlsxDocumentId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "dismantling_plans" */
export type Dismantling_Plans_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dismantling_Plans_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dismantling_Plans_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dueDate?: InputMaybe<Scalars['date']>;
  generateSvgOnNextSolve?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  lastUnprocessedFactChange?: InputMaybe<Scalars['timestamptz']>;
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  productGroupId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Dismantling_Plan_States_Enum>;
  svg?: InputMaybe<Scalars['xml']>;
  svgGeneratedAt?: InputMaybe<Scalars['timestamptz']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
  xlsxDocumentId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Dismantling_Plans_Sum_Fields = {
  __typename: 'dismantling_plans_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  productGroupId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
  xlsxDocumentId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "dismantling_plans" */
export type Dismantling_Plans_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xlsxDocumentId?: InputMaybe<Order_By>;
};

/** update columns of table "dismantling_plans" */
export enum Dismantling_Plans_Update_Column {
  /** column name */
  DueDate = 'dueDate',
  /** column name */
  GenerateSvgOnNextSolve = 'generateSvgOnNextSolve',
  /** column name */
  Id = 'id',
  /** column name */
  JournalEntryId = 'journalEntryId',
  /** column name */
  LastUnprocessedFactChange = 'lastUnprocessedFactChange',
  /** column name */
  PdfDocumentId = 'pdfDocumentId',
  /** column name */
  ProductGroupId = 'productGroupId',
  /** column name */
  State = 'state',
  /** column name */
  Svg = 'svg',
  /** column name */
  SvgGeneratedAt = 'svgGeneratedAt',
  /** column name */
  Version = 'version',
  /** column name */
  XlsxDocumentId = 'xlsxDocumentId'
}

export type Dismantling_Plans_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dismantling_Plans_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dismantling_Plans_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dismantling_Plans_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dismantling_Plans_Var_Pop_Fields = {
  __typename: 'dismantling_plans_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xlsxDocumentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dismantling_plans" */
export type Dismantling_Plans_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xlsxDocumentId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dismantling_Plans_Var_Samp_Fields = {
  __typename: 'dismantling_plans_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xlsxDocumentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dismantling_plans" */
export type Dismantling_Plans_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xlsxDocumentId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Dismantling_Plans_Variance_Fields = {
  __typename: 'dismantling_plans_variance_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xlsxDocumentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dismantling_plans" */
export type Dismantling_Plans_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xlsxDocumentId?: InputMaybe<Order_By>;
};

/** columns and relationships of "edi_delivery_note_types" */
export type Edi_Delivery_Note_Types = {
  __typename: 'edi_delivery_note_types';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  customerTemplateSettings: Array<Customer_Template_Settings>;
  /** An aggregate relationship */
  customerTemplateSettings_aggregate: Customer_Template_Settings_Aggregate;
  data?: Maybe<Scalars['jsonb']>;
  i18n: Scalars['jsonb'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  ordinalPosition: Scalars['Int'];
  tenant: Scalars['String'];
};


/** columns and relationships of "edi_delivery_note_types" */
export type Edi_Delivery_Note_TypesCustomerTemplateSettingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Template_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Template_Settings_Order_By>>;
  where?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
};


/** columns and relationships of "edi_delivery_note_types" */
export type Edi_Delivery_Note_TypesCustomerTemplateSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Template_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Template_Settings_Order_By>>;
  where?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
};


/** columns and relationships of "edi_delivery_note_types" */
export type Edi_Delivery_Note_TypesDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "edi_delivery_note_types" */
export type Edi_Delivery_Note_TypesI18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "edi_delivery_note_types" */
export type Edi_Delivery_Note_Types_Aggregate = {
  __typename: 'edi_delivery_note_types_aggregate';
  aggregate?: Maybe<Edi_Delivery_Note_Types_Aggregate_Fields>;
  nodes: Array<Edi_Delivery_Note_Types>;
};

export type Edi_Delivery_Note_Types_Aggregate_Bool_Exp = {
  count?: InputMaybe<Edi_Delivery_Note_Types_Aggregate_Bool_Exp_Count>;
};

export type Edi_Delivery_Note_Types_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Edi_Delivery_Note_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Edi_Delivery_Note_Types_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "edi_delivery_note_types" */
export type Edi_Delivery_Note_Types_Aggregate_Fields = {
  __typename: 'edi_delivery_note_types_aggregate_fields';
  avg?: Maybe<Edi_Delivery_Note_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Edi_Delivery_Note_Types_Max_Fields>;
  min?: Maybe<Edi_Delivery_Note_Types_Min_Fields>;
  stddev?: Maybe<Edi_Delivery_Note_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Edi_Delivery_Note_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Edi_Delivery_Note_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Edi_Delivery_Note_Types_Sum_Fields>;
  var_pop?: Maybe<Edi_Delivery_Note_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Edi_Delivery_Note_Types_Var_Samp_Fields>;
  variance?: Maybe<Edi_Delivery_Note_Types_Variance_Fields>;
};


/** aggregate fields of "edi_delivery_note_types" */
export type Edi_Delivery_Note_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Edi_Delivery_Note_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "edi_delivery_note_types" */
export type Edi_Delivery_Note_Types_Aggregate_Order_By = {
  avg?: InputMaybe<Edi_Delivery_Note_Types_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Edi_Delivery_Note_Types_Max_Order_By>;
  min?: InputMaybe<Edi_Delivery_Note_Types_Min_Order_By>;
  stddev?: InputMaybe<Edi_Delivery_Note_Types_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Edi_Delivery_Note_Types_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Edi_Delivery_Note_Types_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Edi_Delivery_Note_Types_Sum_Order_By>;
  var_pop?: InputMaybe<Edi_Delivery_Note_Types_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Edi_Delivery_Note_Types_Var_Samp_Order_By>;
  variance?: InputMaybe<Edi_Delivery_Note_Types_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Edi_Delivery_Note_Types_Avg_Fields = {
  __typename: 'edi_delivery_note_types_avg_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "edi_delivery_note_types" */
export type Edi_Delivery_Note_Types_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "edi_delivery_note_types". All fields are combined with a logical 'AND'. */
export type Edi_Delivery_Note_Types_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Edi_Delivery_Note_Types_Bool_Exp>>;
  _not?: InputMaybe<Edi_Delivery_Note_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Edi_Delivery_Note_Types_Bool_Exp>>;
  customerTemplateSettings?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
  customerTemplateSettings_aggregate?: InputMaybe<Customer_Template_Settings_Aggregate_Bool_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Edi_Delivery_Note_Types_Max_Fields = {
  __typename: 'edi_delivery_note_types_max_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "edi_delivery_note_types" */
export type Edi_Delivery_Note_Types_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Edi_Delivery_Note_Types_Min_Fields = {
  __typename: 'edi_delivery_note_types_min_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "edi_delivery_note_types" */
export type Edi_Delivery_Note_Types_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "edi_delivery_note_types". */
export type Edi_Delivery_Note_Types_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  customerTemplateSettings_aggregate?: InputMaybe<Customer_Template_Settings_Aggregate_Order_By>;
  data?: InputMaybe<Order_By>;
  i18n?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** select columns of table "edi_delivery_note_types" */
export enum Edi_Delivery_Note_Types_Select_Column {
  /** column name */
  Data = 'data',
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  Tenant = 'tenant'
}

/** aggregate stddev on columns */
export type Edi_Delivery_Note_Types_Stddev_Fields = {
  __typename: 'edi_delivery_note_types_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "edi_delivery_note_types" */
export type Edi_Delivery_Note_Types_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Edi_Delivery_Note_Types_Stddev_Pop_Fields = {
  __typename: 'edi_delivery_note_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "edi_delivery_note_types" */
export type Edi_Delivery_Note_Types_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Edi_Delivery_Note_Types_Stddev_Samp_Fields = {
  __typename: 'edi_delivery_note_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "edi_delivery_note_types" */
export type Edi_Delivery_Note_Types_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "edi_delivery_note_types" */
export type Edi_Delivery_Note_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Edi_Delivery_Note_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Edi_Delivery_Note_Types_Stream_Cursor_Value_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Edi_Delivery_Note_Types_Sum_Fields = {
  __typename: 'edi_delivery_note_types_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "edi_delivery_note_types" */
export type Edi_Delivery_Note_Types_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Edi_Delivery_Note_Types_Var_Pop_Fields = {
  __typename: 'edi_delivery_note_types_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "edi_delivery_note_types" */
export type Edi_Delivery_Note_Types_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Edi_Delivery_Note_Types_Var_Samp_Fields = {
  __typename: 'edi_delivery_note_types_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "edi_delivery_note_types" */
export type Edi_Delivery_Note_Types_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Edi_Delivery_Note_Types_Variance_Fields = {
  __typename: 'edi_delivery_note_types_variance_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "edi_delivery_note_types" */
export type Edi_Delivery_Note_Types_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** columns and relationships of "edi_invoice_types" */
export type Edi_Invoice_Types = {
  __typename: 'edi_invoice_types';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  customerTemplateSettings: Array<Customer_Template_Settings>;
  /** An aggregate relationship */
  customerTemplateSettings_aggregate: Customer_Template_Settings_Aggregate;
  data?: Maybe<Scalars['jsonb']>;
  i18n: Scalars['jsonb'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  ordinalPosition: Scalars['Int'];
  tenant: Scalars['String'];
};


/** columns and relationships of "edi_invoice_types" */
export type Edi_Invoice_TypesCustomerTemplateSettingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Template_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Template_Settings_Order_By>>;
  where?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
};


/** columns and relationships of "edi_invoice_types" */
export type Edi_Invoice_TypesCustomerTemplateSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Template_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Template_Settings_Order_By>>;
  where?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
};


/** columns and relationships of "edi_invoice_types" */
export type Edi_Invoice_TypesDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "edi_invoice_types" */
export type Edi_Invoice_TypesI18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "edi_invoice_types" */
export type Edi_Invoice_Types_Aggregate = {
  __typename: 'edi_invoice_types_aggregate';
  aggregate?: Maybe<Edi_Invoice_Types_Aggregate_Fields>;
  nodes: Array<Edi_Invoice_Types>;
};

export type Edi_Invoice_Types_Aggregate_Bool_Exp = {
  count?: InputMaybe<Edi_Invoice_Types_Aggregate_Bool_Exp_Count>;
};

export type Edi_Invoice_Types_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Edi_Invoice_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Edi_Invoice_Types_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "edi_invoice_types" */
export type Edi_Invoice_Types_Aggregate_Fields = {
  __typename: 'edi_invoice_types_aggregate_fields';
  avg?: Maybe<Edi_Invoice_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Edi_Invoice_Types_Max_Fields>;
  min?: Maybe<Edi_Invoice_Types_Min_Fields>;
  stddev?: Maybe<Edi_Invoice_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Edi_Invoice_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Edi_Invoice_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Edi_Invoice_Types_Sum_Fields>;
  var_pop?: Maybe<Edi_Invoice_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Edi_Invoice_Types_Var_Samp_Fields>;
  variance?: Maybe<Edi_Invoice_Types_Variance_Fields>;
};


/** aggregate fields of "edi_invoice_types" */
export type Edi_Invoice_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Edi_Invoice_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "edi_invoice_types" */
export type Edi_Invoice_Types_Aggregate_Order_By = {
  avg?: InputMaybe<Edi_Invoice_Types_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Edi_Invoice_Types_Max_Order_By>;
  min?: InputMaybe<Edi_Invoice_Types_Min_Order_By>;
  stddev?: InputMaybe<Edi_Invoice_Types_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Edi_Invoice_Types_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Edi_Invoice_Types_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Edi_Invoice_Types_Sum_Order_By>;
  var_pop?: InputMaybe<Edi_Invoice_Types_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Edi_Invoice_Types_Var_Samp_Order_By>;
  variance?: InputMaybe<Edi_Invoice_Types_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Edi_Invoice_Types_Avg_Fields = {
  __typename: 'edi_invoice_types_avg_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "edi_invoice_types" */
export type Edi_Invoice_Types_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "edi_invoice_types". All fields are combined with a logical 'AND'. */
export type Edi_Invoice_Types_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Edi_Invoice_Types_Bool_Exp>>;
  _not?: InputMaybe<Edi_Invoice_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Edi_Invoice_Types_Bool_Exp>>;
  customerTemplateSettings?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
  customerTemplateSettings_aggregate?: InputMaybe<Customer_Template_Settings_Aggregate_Bool_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Edi_Invoice_Types_Max_Fields = {
  __typename: 'edi_invoice_types_max_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "edi_invoice_types" */
export type Edi_Invoice_Types_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Edi_Invoice_Types_Min_Fields = {
  __typename: 'edi_invoice_types_min_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "edi_invoice_types" */
export type Edi_Invoice_Types_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "edi_invoice_types". */
export type Edi_Invoice_Types_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  customerTemplateSettings_aggregate?: InputMaybe<Customer_Template_Settings_Aggregate_Order_By>;
  data?: InputMaybe<Order_By>;
  i18n?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** select columns of table "edi_invoice_types" */
export enum Edi_Invoice_Types_Select_Column {
  /** column name */
  Data = 'data',
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  Tenant = 'tenant'
}

/** aggregate stddev on columns */
export type Edi_Invoice_Types_Stddev_Fields = {
  __typename: 'edi_invoice_types_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "edi_invoice_types" */
export type Edi_Invoice_Types_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Edi_Invoice_Types_Stddev_Pop_Fields = {
  __typename: 'edi_invoice_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "edi_invoice_types" */
export type Edi_Invoice_Types_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Edi_Invoice_Types_Stddev_Samp_Fields = {
  __typename: 'edi_invoice_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "edi_invoice_types" */
export type Edi_Invoice_Types_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "edi_invoice_types" */
export type Edi_Invoice_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Edi_Invoice_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Edi_Invoice_Types_Stream_Cursor_Value_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Edi_Invoice_Types_Sum_Fields = {
  __typename: 'edi_invoice_types_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "edi_invoice_types" */
export type Edi_Invoice_Types_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Edi_Invoice_Types_Var_Pop_Fields = {
  __typename: 'edi_invoice_types_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "edi_invoice_types" */
export type Edi_Invoice_Types_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Edi_Invoice_Types_Var_Samp_Fields = {
  __typename: 'edi_invoice_types_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "edi_invoice_types" */
export type Edi_Invoice_Types_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Edi_Invoice_Types_Variance_Fields = {
  __typename: 'edi_invoice_types_variance_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "edi_invoice_types" */
export type Edi_Invoice_Types_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** columns and relationships of "emails" */
export type Emails = {
  __typename: 'emails';
  /** An object relationship */
  Tenant: Tenants;
  contactId: Scalars['bigint'];
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['bigint'];
  ordinalPosition: Scalars['Int'];
  sendOffersAndConfirmations?: Maybe<Scalars['Boolean']>;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};

/** aggregated selection of "emails" */
export type Emails_Aggregate = {
  __typename: 'emails_aggregate';
  aggregate?: Maybe<Emails_Aggregate_Fields>;
  nodes: Array<Emails>;
};

export type Emails_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Emails_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Emails_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Emails_Aggregate_Bool_Exp_Count>;
};

export type Emails_Aggregate_Bool_Exp_Bool_And = {
  arguments: Emails_Select_Column_Emails_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Emails_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Emails_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Emails_Select_Column_Emails_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Emails_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Emails_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Emails_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Emails_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "emails" */
export type Emails_Aggregate_Fields = {
  __typename: 'emails_aggregate_fields';
  avg?: Maybe<Emails_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Emails_Max_Fields>;
  min?: Maybe<Emails_Min_Fields>;
  stddev?: Maybe<Emails_Stddev_Fields>;
  stddev_pop?: Maybe<Emails_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Emails_Stddev_Samp_Fields>;
  sum?: Maybe<Emails_Sum_Fields>;
  var_pop?: Maybe<Emails_Var_Pop_Fields>;
  var_samp?: Maybe<Emails_Var_Samp_Fields>;
  variance?: Maybe<Emails_Variance_Fields>;
};


/** aggregate fields of "emails" */
export type Emails_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Emails_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "emails" */
export type Emails_Aggregate_Order_By = {
  avg?: InputMaybe<Emails_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Emails_Max_Order_By>;
  min?: InputMaybe<Emails_Min_Order_By>;
  stddev?: InputMaybe<Emails_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Emails_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Emails_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Emails_Sum_Order_By>;
  var_pop?: InputMaybe<Emails_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Emails_Var_Samp_Order_By>;
  variance?: InputMaybe<Emails_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "emails" */
export type Emails_Arr_Rel_Insert_Input = {
  data: Array<Emails_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Emails_On_Conflict>;
};

/** aggregate avg on columns */
export type Emails_Avg_Fields = {
  __typename: 'emails_avg_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "emails" */
export type Emails_Avg_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "emails". All fields are combined with a logical 'AND'. */
export type Emails_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Emails_Bool_Exp>>;
  _not?: InputMaybe<Emails_Bool_Exp>;
  _or?: InputMaybe<Array<Emails_Bool_Exp>>;
  contactId?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  sendOffersAndConfirmations?: InputMaybe<Boolean_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "emails" */
export enum Emails_Constraint {
  /** unique or primary key constraint on columns "contact_id", "ordinal_position" */
  EmailsContactIdOrdinalPositionKey = 'emails_contact_id_ordinal_position_key',
  /** unique or primary key constraint on columns "id" */
  EmailsPkey = 'emails_pkey'
}

/** input type for incrementing numeric columns in table "emails" */
export type Emails_Inc_Input = {
  contactId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "emails" */
export type Emails_Insert_Input = {
  contactId?: InputMaybe<Scalars['bigint']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  sendOffersAndConfirmations?: InputMaybe<Scalars['Boolean']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Emails_Max_Fields = {
  __typename: 'emails_max_fields';
  contactId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "emails" */
export type Emails_Max_Order_By = {
  contactId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Emails_Min_Fields = {
  __typename: 'emails_min_fields';
  contactId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "emails" */
export type Emails_Min_Order_By = {
  contactId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "emails" */
export type Emails_Mutation_Response = {
  __typename: 'emails_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Emails>;
};

/** on_conflict condition type for table "emails" */
export type Emails_On_Conflict = {
  constraint: Emails_Constraint;
  update_columns?: Array<Emails_Update_Column>;
  where?: InputMaybe<Emails_Bool_Exp>;
};

/** Ordering options when selecting data from "emails". */
export type Emails_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  contactId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  sendOffersAndConfirmations?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: emails */
export type Emails_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "emails" */
export enum Emails_Select_Column {
  /** column name */
  ContactId = 'contactId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  SendOffersAndConfirmations = 'sendOffersAndConfirmations',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** select "emails_aggregate_bool_exp_bool_and_arguments_columns" columns of table "emails" */
export enum Emails_Select_Column_Emails_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  SendOffersAndConfirmations = 'sendOffersAndConfirmations'
}

/** select "emails_aggregate_bool_exp_bool_or_arguments_columns" columns of table "emails" */
export enum Emails_Select_Column_Emails_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  SendOffersAndConfirmations = 'sendOffersAndConfirmations'
}

/** input type for updating data in table "emails" */
export type Emails_Set_Input = {
  contactId?: InputMaybe<Scalars['bigint']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  sendOffersAndConfirmations?: InputMaybe<Scalars['Boolean']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Emails_Stddev_Fields = {
  __typename: 'emails_stddev_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "emails" */
export type Emails_Stddev_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Emails_Stddev_Pop_Fields = {
  __typename: 'emails_stddev_pop_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "emails" */
export type Emails_Stddev_Pop_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Emails_Stddev_Samp_Fields = {
  __typename: 'emails_stddev_samp_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "emails" */
export type Emails_Stddev_Samp_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "emails" */
export type Emails_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Emails_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Emails_Stream_Cursor_Value_Input = {
  contactId?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  sendOffersAndConfirmations?: InputMaybe<Scalars['Boolean']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Emails_Sum_Fields = {
  __typename: 'emails_sum_fields';
  contactId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "emails" */
export type Emails_Sum_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "emails" */
export enum Emails_Update_Column {
  /** column name */
  ContactId = 'contactId',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  SendOffersAndConfirmations = 'sendOffersAndConfirmations',
  /** column name */
  Version = 'version'
}

export type Emails_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Emails_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Emails_Set_Input>;
  /** filter the rows which have to be updated */
  where: Emails_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Emails_Var_Pop_Fields = {
  __typename: 'emails_var_pop_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "emails" */
export type Emails_Var_Pop_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Emails_Var_Samp_Fields = {
  __typename: 'emails_var_samp_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "emails" */
export type Emails_Var_Samp_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Emails_Variance_Fields = {
  __typename: 'emails_variance_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "emails" */
export type Emails_Variance_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

export type Enqueue_Bizerba_Finish_Picking_Flow_Args = {
  _customer_order_item_id?: InputMaybe<Scalars['bigint']>;
  _device_id?: InputMaybe<Scalars['bigint']>;
  _lot_no?: InputMaybe<Scalars['String']>;
  _picking_location_id?: InputMaybe<Scalars['bigint']>;
  _template_id?: InputMaybe<Scalars['bigint']>;
};

export type Enqueue_Bizerba_Reset_Device_Args = {
  _device_id?: InputMaybe<Scalars['bigint']>;
};

export type Enqueue_Bizerba_Resume_Picking_Flow_Args = {
  _customer_order_item_id?: InputMaybe<Scalars['bigint']>;
  _device_id?: InputMaybe<Scalars['bigint']>;
  _picking_location_id?: InputMaybe<Scalars['bigint']>;
  _template_id?: InputMaybe<Scalars['bigint']>;
};

export type Enqueue_Bizerba_Setup_Picking_Flow_Args = {
  _customer_order_item_id?: InputMaybe<Scalars['bigint']>;
  _device_id?: InputMaybe<Scalars['bigint']>;
  _picking_location_id?: InputMaybe<Scalars['bigint']>;
  _template_id?: InputMaybe<Scalars['bigint']>;
};

export type Enqueue_Bulk_Customer_Order_Delivery_Note_Pdf_Generation_Args = {
  customer_order_ids?: InputMaybe<Scalars['_int8']>;
};

export type Enqueue_Credit_Note_Pdf_Generation_Args = {
  _credit_note_id?: InputMaybe<Scalars['bigint']>;
  _expiration_in_sec?: InputMaybe<Scalars['Int']>;
};

export type Enqueue_Credit_Notes_Pdf_Generation_Args = {
  _credit_note_ids?: InputMaybe<Scalars['_int8']>;
  _expiration_in_sec?: InputMaybe<Scalars['Int']>;
};

export type Enqueue_Customer_Invoice_Preparation_Args = {
  _customer_invoice_id?: InputMaybe<Scalars['bigint']>;
};

export type Enqueue_Customer_Invoices_Preparation_Args = {
  _customer_invoice_ids?: InputMaybe<Scalars['_int8']>;
};

export type Enqueue_Customer_Order_Delivery_Note_Pdf_Generation_Args = {
  customer_order_id?: InputMaybe<Scalars['bigint']>;
};

export type Enqueue_Edi_Invoice_Sending_If_Necessary_Args = {
  _customer_invoice_ids?: InputMaybe<Scalars['_int8']>;
};

export type Enqueue_Email_Credit_Note_Sending_Args = {
  _credit_note_ids?: InputMaybe<Scalars['_int8']>;
};

export type Enqueue_Email_Invoice_Sending_Args = {
  _customer_invoice_ids?: InputMaybe<Scalars['_int8']>;
};

export type Enqueue_Gs1_Logistic_Pdf_Generation_Args = {
  _expiration_in_sec?: InputMaybe<Scalars['Int']>;
  _picked_item_id?: InputMaybe<Scalars['bigint']>;
};

export type Enqueue_Picking_Slip_Pdf_Generation_Args = {
  _customer_order_ids?: InputMaybe<Scalars['_int8']>;
  _delivery_date?: InputMaybe<Scalars['date']>;
  _delivery_date_lower?: InputMaybe<Scalars['date']>;
  _delivery_date_upper?: InputMaybe<Scalars['date']>;
  _expiration_in_sec?: InputMaybe<Scalars['Int']>;
  _inclusion_filter?: InputMaybe<Scalars['String']>;
  _picking_category_ids?: InputMaybe<Scalars['_int8']>;
};

export type Enqueue_Tour_Plan_Csv_Generation_Args = {
  _delivery_date?: InputMaybe<Scalars['date']>;
};

/** columns and relationships of "environment_state" */
export type Environment_State = {
  __typename: 'environment_state';
  id: Scalars['bigint'];
  state?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "environment_state" */
export type Environment_StateStateArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "environment_state" */
export type Environment_State_Aggregate = {
  __typename: 'environment_state_aggregate';
  aggregate?: Maybe<Environment_State_Aggregate_Fields>;
  nodes: Array<Environment_State>;
};

/** aggregate fields of "environment_state" */
export type Environment_State_Aggregate_Fields = {
  __typename: 'environment_state_aggregate_fields';
  avg?: Maybe<Environment_State_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Environment_State_Max_Fields>;
  min?: Maybe<Environment_State_Min_Fields>;
  stddev?: Maybe<Environment_State_Stddev_Fields>;
  stddev_pop?: Maybe<Environment_State_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Environment_State_Stddev_Samp_Fields>;
  sum?: Maybe<Environment_State_Sum_Fields>;
  var_pop?: Maybe<Environment_State_Var_Pop_Fields>;
  var_samp?: Maybe<Environment_State_Var_Samp_Fields>;
  variance?: Maybe<Environment_State_Variance_Fields>;
};


/** aggregate fields of "environment_state" */
export type Environment_State_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Environment_State_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Environment_State_Avg_Fields = {
  __typename: 'environment_state_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "environment_state". All fields are combined with a logical 'AND'. */
export type Environment_State_Bool_Exp = {
  _and?: InputMaybe<Array<Environment_State_Bool_Exp>>;
  _not?: InputMaybe<Environment_State_Bool_Exp>;
  _or?: InputMaybe<Array<Environment_State_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** aggregate max on columns */
export type Environment_State_Max_Fields = {
  __typename: 'environment_state_max_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Environment_State_Min_Fields = {
  __typename: 'environment_state_min_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "environment_state". */
export type Environment_State_Order_By = {
  id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
};

/** select columns of table "environment_state" */
export enum Environment_State_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state'
}

/** aggregate stddev on columns */
export type Environment_State_Stddev_Fields = {
  __typename: 'environment_state_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Environment_State_Stddev_Pop_Fields = {
  __typename: 'environment_state_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Environment_State_Stddev_Samp_Fields = {
  __typename: 'environment_state_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "environment_state" */
export type Environment_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Environment_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Environment_State_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type Environment_State_Sum_Fields = {
  __typename: 'environment_state_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Environment_State_Var_Pop_Fields = {
  __typename: 'environment_state_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Environment_State_Var_Samp_Fields = {
  __typename: 'environment_state_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Environment_State_Variance_Fields = {
  __typename: 'environment_state_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Evict_Rolling_Inventory_Items_Args = {
  _payload?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "exchange_rates" */
export type Exchange_Rates = {
  __typename: 'exchange_rates';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  currencyFrom: Currencies_Enum;
  currencyTo: Currencies_Enum;
  id: Scalars['bigint'];
  rate: Scalars['numeric'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  valid: Scalars['daterange'];
  version: Scalars['bigint'];
};

/** aggregated selection of "exchange_rates" */
export type Exchange_Rates_Aggregate = {
  __typename: 'exchange_rates_aggregate';
  aggregate?: Maybe<Exchange_Rates_Aggregate_Fields>;
  nodes: Array<Exchange_Rates>;
};

export type Exchange_Rates_Aggregate_Bool_Exp = {
  count?: InputMaybe<Exchange_Rates_Aggregate_Bool_Exp_Count>;
};

export type Exchange_Rates_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Exchange_Rates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Exchange_Rates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "exchange_rates" */
export type Exchange_Rates_Aggregate_Fields = {
  __typename: 'exchange_rates_aggregate_fields';
  avg?: Maybe<Exchange_Rates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Exchange_Rates_Max_Fields>;
  min?: Maybe<Exchange_Rates_Min_Fields>;
  stddev?: Maybe<Exchange_Rates_Stddev_Fields>;
  stddev_pop?: Maybe<Exchange_Rates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Exchange_Rates_Stddev_Samp_Fields>;
  sum?: Maybe<Exchange_Rates_Sum_Fields>;
  var_pop?: Maybe<Exchange_Rates_Var_Pop_Fields>;
  var_samp?: Maybe<Exchange_Rates_Var_Samp_Fields>;
  variance?: Maybe<Exchange_Rates_Variance_Fields>;
};


/** aggregate fields of "exchange_rates" */
export type Exchange_Rates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Exchange_Rates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "exchange_rates" */
export type Exchange_Rates_Aggregate_Order_By = {
  avg?: InputMaybe<Exchange_Rates_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Exchange_Rates_Max_Order_By>;
  min?: InputMaybe<Exchange_Rates_Min_Order_By>;
  stddev?: InputMaybe<Exchange_Rates_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Exchange_Rates_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Exchange_Rates_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Exchange_Rates_Sum_Order_By>;
  var_pop?: InputMaybe<Exchange_Rates_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Exchange_Rates_Var_Samp_Order_By>;
  variance?: InputMaybe<Exchange_Rates_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Exchange_Rates_Avg_Fields = {
  __typename: 'exchange_rates_avg_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "exchange_rates" */
export type Exchange_Rates_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "exchange_rates". All fields are combined with a logical 'AND'. */
export type Exchange_Rates_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Exchange_Rates_Bool_Exp>>;
  _not?: InputMaybe<Exchange_Rates_Bool_Exp>;
  _or?: InputMaybe<Array<Exchange_Rates_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currencyFrom?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  currencyTo?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  rate?: InputMaybe<Numeric_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid?: InputMaybe<Daterange_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "exchange_rates" */
export enum Exchange_Rates_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExchangeRatesPkey = 'exchange_rates_pkey',
  /** unique or primary key constraint on columns "currency_from", "valid", "tenant", "currency_to" */
  ExchangeRatesTenantCurrencyFromCurrencyToValidKey = 'exchange_rates_tenant_currency_from_currency_to_valid_key'
}

export type Exchange_Rates_For_Day_Args = {
  _as_of?: InputMaybe<Scalars['date']>;
};

/** input type for incrementing numeric columns in table "exchange_rates" */
export type Exchange_Rates_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  rate?: InputMaybe<Scalars['numeric']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "exchange_rates" */
export type Exchange_Rates_Insert_Input = {
  currencyFrom?: InputMaybe<Currencies_Enum>;
  currencyTo?: InputMaybe<Currencies_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  rate?: InputMaybe<Scalars['numeric']>;
  valid?: InputMaybe<Scalars['daterange']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Exchange_Rates_Max_Fields = {
  __typename: 'exchange_rates_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  rate?: Maybe<Scalars['numeric']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "exchange_rates" */
export type Exchange_Rates_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Exchange_Rates_Min_Fields = {
  __typename: 'exchange_rates_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  rate?: Maybe<Scalars['numeric']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "exchange_rates" */
export type Exchange_Rates_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "exchange_rates" */
export type Exchange_Rates_Mutation_Response = {
  __typename: 'exchange_rates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Exchange_Rates>;
};

/** on_conflict condition type for table "exchange_rates" */
export type Exchange_Rates_On_Conflict = {
  constraint: Exchange_Rates_Constraint;
  update_columns?: Array<Exchange_Rates_Update_Column>;
  where?: InputMaybe<Exchange_Rates_Bool_Exp>;
};

/** Ordering options when selecting data from "exchange_rates". */
export type Exchange_Rates_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currencyFrom?: InputMaybe<Order_By>;
  currencyTo?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  valid?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: exchange_rates */
export type Exchange_Rates_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "exchange_rates" */
export enum Exchange_Rates_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyFrom = 'currencyFrom',
  /** column name */
  CurrencyTo = 'currencyTo',
  /** column name */
  Id = 'id',
  /** column name */
  Rate = 'rate',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Valid = 'valid',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "exchange_rates" */
export type Exchange_Rates_Set_Input = {
  currencyFrom?: InputMaybe<Currencies_Enum>;
  currencyTo?: InputMaybe<Currencies_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  rate?: InputMaybe<Scalars['numeric']>;
  valid?: InputMaybe<Scalars['daterange']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Exchange_Rates_Stddev_Fields = {
  __typename: 'exchange_rates_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "exchange_rates" */
export type Exchange_Rates_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Exchange_Rates_Stddev_Pop_Fields = {
  __typename: 'exchange_rates_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "exchange_rates" */
export type Exchange_Rates_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Exchange_Rates_Stddev_Samp_Fields = {
  __typename: 'exchange_rates_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "exchange_rates" */
export type Exchange_Rates_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "exchange_rates" */
export type Exchange_Rates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Exchange_Rates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Exchange_Rates_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyFrom?: InputMaybe<Currencies_Enum>;
  currencyTo?: InputMaybe<Currencies_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  rate?: InputMaybe<Scalars['numeric']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  valid?: InputMaybe<Scalars['daterange']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Exchange_Rates_Sum_Fields = {
  __typename: 'exchange_rates_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  rate?: Maybe<Scalars['numeric']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "exchange_rates" */
export type Exchange_Rates_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "exchange_rates" */
export enum Exchange_Rates_Update_Column {
  /** column name */
  CurrencyFrom = 'currencyFrom',
  /** column name */
  CurrencyTo = 'currencyTo',
  /** column name */
  Id = 'id',
  /** column name */
  Rate = 'rate',
  /** column name */
  Valid = 'valid',
  /** column name */
  Version = 'version'
}

export type Exchange_Rates_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Exchange_Rates_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Exchange_Rates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Exchange_Rates_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Exchange_Rates_Var_Pop_Fields = {
  __typename: 'exchange_rates_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "exchange_rates" */
export type Exchange_Rates_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Exchange_Rates_Var_Samp_Fields = {
  __typename: 'exchange_rates_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "exchange_rates" */
export type Exchange_Rates_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Exchange_Rates_Variance_Fields = {
  __typename: 'exchange_rates_variance_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "exchange_rates" */
export type Exchange_Rates_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

export type Execute_Product_Recipe_Args = {
  _payload?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "external_errors" */
export type External_Errors = {
  __typename: 'external_errors';
  description?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
};

/** aggregated selection of "external_errors" */
export type External_Errors_Aggregate = {
  __typename: 'external_errors_aggregate';
  aggregate?: Maybe<External_Errors_Aggregate_Fields>;
  nodes: Array<External_Errors>;
};

/** aggregate fields of "external_errors" */
export type External_Errors_Aggregate_Fields = {
  __typename: 'external_errors_aggregate_fields';
  avg?: Maybe<External_Errors_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<External_Errors_Max_Fields>;
  min?: Maybe<External_Errors_Min_Fields>;
  stddev?: Maybe<External_Errors_Stddev_Fields>;
  stddev_pop?: Maybe<External_Errors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<External_Errors_Stddev_Samp_Fields>;
  sum?: Maybe<External_Errors_Sum_Fields>;
  var_pop?: Maybe<External_Errors_Var_Pop_Fields>;
  var_samp?: Maybe<External_Errors_Var_Samp_Fields>;
  variance?: Maybe<External_Errors_Variance_Fields>;
};


/** aggregate fields of "external_errors" */
export type External_Errors_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<External_Errors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type External_Errors_Avg_Fields = {
  __typename: 'external_errors_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "external_errors". All fields are combined with a logical 'AND'. */
export type External_Errors_Bool_Exp = {
  _and?: InputMaybe<Array<External_Errors_Bool_Exp>>;
  _not?: InputMaybe<External_Errors_Bool_Exp>;
  _or?: InputMaybe<Array<External_Errors_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type External_Errors_Max_Fields = {
  __typename: 'external_errors_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type External_Errors_Min_Fields = {
  __typename: 'external_errors_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "external_errors". */
export type External_Errors_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "external_errors" */
export enum External_Errors_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** aggregate stddev on columns */
export type External_Errors_Stddev_Fields = {
  __typename: 'external_errors_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type External_Errors_Stddev_Pop_Fields = {
  __typename: 'external_errors_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type External_Errors_Stddev_Samp_Fields = {
  __typename: 'external_errors_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "external_errors" */
export type External_Errors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: External_Errors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type External_Errors_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type External_Errors_Sum_Fields = {
  __typename: 'external_errors_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type External_Errors_Var_Pop_Fields = {
  __typename: 'external_errors_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type External_Errors_Var_Samp_Fields = {
  __typename: 'external_errors_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type External_Errors_Variance_Fields = {
  __typename: 'external_errors_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Framework_Agreements_Within_Range_Args = {
  range?: InputMaybe<Scalars['daterange']>;
};

/** columns and relationships of "full_work_calendar" */
export type Full_Work_Calendar = {
  __typename: 'full_work_calendar';
  day?: Maybe<Scalars['date']>;
  /** An object relationship */
  productionSiteProductGroupCalendar?: Maybe<Production_Site_Product_Group_Calendar>;
  productionSiteProductGroupCalendarId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  /** An object relationship */
  workCalendar?: Maybe<Work_Calendar>;
  workCalendarId?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "full_work_calendar" */
export type Full_Work_Calendar_Aggregate = {
  __typename: 'full_work_calendar_aggregate';
  aggregate?: Maybe<Full_Work_Calendar_Aggregate_Fields>;
  nodes: Array<Full_Work_Calendar>;
};

export type Full_Work_Calendar_Aggregate_Bool_Exp = {
  count?: InputMaybe<Full_Work_Calendar_Aggregate_Bool_Exp_Count>;
};

export type Full_Work_Calendar_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Full_Work_Calendar_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Full_Work_Calendar_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "full_work_calendar" */
export type Full_Work_Calendar_Aggregate_Fields = {
  __typename: 'full_work_calendar_aggregate_fields';
  avg?: Maybe<Full_Work_Calendar_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Full_Work_Calendar_Max_Fields>;
  min?: Maybe<Full_Work_Calendar_Min_Fields>;
  stddev?: Maybe<Full_Work_Calendar_Stddev_Fields>;
  stddev_pop?: Maybe<Full_Work_Calendar_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Full_Work_Calendar_Stddev_Samp_Fields>;
  sum?: Maybe<Full_Work_Calendar_Sum_Fields>;
  var_pop?: Maybe<Full_Work_Calendar_Var_Pop_Fields>;
  var_samp?: Maybe<Full_Work_Calendar_Var_Samp_Fields>;
  variance?: Maybe<Full_Work_Calendar_Variance_Fields>;
};


/** aggregate fields of "full_work_calendar" */
export type Full_Work_Calendar_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Full_Work_Calendar_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "full_work_calendar" */
export type Full_Work_Calendar_Aggregate_Order_By = {
  avg?: InputMaybe<Full_Work_Calendar_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Full_Work_Calendar_Max_Order_By>;
  min?: InputMaybe<Full_Work_Calendar_Min_Order_By>;
  stddev?: InputMaybe<Full_Work_Calendar_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Full_Work_Calendar_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Full_Work_Calendar_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Full_Work_Calendar_Sum_Order_By>;
  var_pop?: InputMaybe<Full_Work_Calendar_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Full_Work_Calendar_Var_Samp_Order_By>;
  variance?: InputMaybe<Full_Work_Calendar_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Full_Work_Calendar_Avg_Fields = {
  __typename: 'full_work_calendar_avg_fields';
  productionSiteProductGroupCalendarId?: Maybe<Scalars['Float']>;
  workCalendarId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "full_work_calendar" */
export type Full_Work_Calendar_Avg_Order_By = {
  productionSiteProductGroupCalendarId?: InputMaybe<Order_By>;
  workCalendarId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "full_work_calendar". All fields are combined with a logical 'AND'. */
export type Full_Work_Calendar_Bool_Exp = {
  _and?: InputMaybe<Array<Full_Work_Calendar_Bool_Exp>>;
  _not?: InputMaybe<Full_Work_Calendar_Bool_Exp>;
  _or?: InputMaybe<Array<Full_Work_Calendar_Bool_Exp>>;
  day?: InputMaybe<Date_Comparison_Exp>;
  productionSiteProductGroupCalendar?: InputMaybe<Production_Site_Product_Group_Calendar_Bool_Exp>;
  productionSiteProductGroupCalendarId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  workCalendar?: InputMaybe<Work_Calendar_Bool_Exp>;
  workCalendarId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Full_Work_Calendar_Max_Fields = {
  __typename: 'full_work_calendar_max_fields';
  day?: Maybe<Scalars['date']>;
  productionSiteProductGroupCalendarId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  workCalendarId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "full_work_calendar" */
export type Full_Work_Calendar_Max_Order_By = {
  day?: InputMaybe<Order_By>;
  productionSiteProductGroupCalendarId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  workCalendarId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Full_Work_Calendar_Min_Fields = {
  __typename: 'full_work_calendar_min_fields';
  day?: Maybe<Scalars['date']>;
  productionSiteProductGroupCalendarId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  workCalendarId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "full_work_calendar" */
export type Full_Work_Calendar_Min_Order_By = {
  day?: InputMaybe<Order_By>;
  productionSiteProductGroupCalendarId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  workCalendarId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "full_work_calendar". */
export type Full_Work_Calendar_Order_By = {
  day?: InputMaybe<Order_By>;
  productionSiteProductGroupCalendar?: InputMaybe<Production_Site_Product_Group_Calendar_Order_By>;
  productionSiteProductGroupCalendarId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  workCalendar?: InputMaybe<Work_Calendar_Order_By>;
  workCalendarId?: InputMaybe<Order_By>;
};

/** select columns of table "full_work_calendar" */
export enum Full_Work_Calendar_Select_Column {
  /** column name */
  Day = 'day',
  /** column name */
  ProductionSiteProductGroupCalendarId = 'productionSiteProductGroupCalendarId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  WorkCalendarId = 'workCalendarId'
}

/** aggregate stddev on columns */
export type Full_Work_Calendar_Stddev_Fields = {
  __typename: 'full_work_calendar_stddev_fields';
  productionSiteProductGroupCalendarId?: Maybe<Scalars['Float']>;
  workCalendarId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "full_work_calendar" */
export type Full_Work_Calendar_Stddev_Order_By = {
  productionSiteProductGroupCalendarId?: InputMaybe<Order_By>;
  workCalendarId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Full_Work_Calendar_Stddev_Pop_Fields = {
  __typename: 'full_work_calendar_stddev_pop_fields';
  productionSiteProductGroupCalendarId?: Maybe<Scalars['Float']>;
  workCalendarId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "full_work_calendar" */
export type Full_Work_Calendar_Stddev_Pop_Order_By = {
  productionSiteProductGroupCalendarId?: InputMaybe<Order_By>;
  workCalendarId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Full_Work_Calendar_Stddev_Samp_Fields = {
  __typename: 'full_work_calendar_stddev_samp_fields';
  productionSiteProductGroupCalendarId?: Maybe<Scalars['Float']>;
  workCalendarId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "full_work_calendar" */
export type Full_Work_Calendar_Stddev_Samp_Order_By = {
  productionSiteProductGroupCalendarId?: InputMaybe<Order_By>;
  workCalendarId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "full_work_calendar" */
export type Full_Work_Calendar_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Full_Work_Calendar_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Full_Work_Calendar_Stream_Cursor_Value_Input = {
  day?: InputMaybe<Scalars['date']>;
  productionSiteProductGroupCalendarId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  workCalendarId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Full_Work_Calendar_Sum_Fields = {
  __typename: 'full_work_calendar_sum_fields';
  productionSiteProductGroupCalendarId?: Maybe<Scalars['bigint']>;
  workCalendarId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "full_work_calendar" */
export type Full_Work_Calendar_Sum_Order_By = {
  productionSiteProductGroupCalendarId?: InputMaybe<Order_By>;
  workCalendarId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Full_Work_Calendar_Var_Pop_Fields = {
  __typename: 'full_work_calendar_var_pop_fields';
  productionSiteProductGroupCalendarId?: Maybe<Scalars['Float']>;
  workCalendarId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "full_work_calendar" */
export type Full_Work_Calendar_Var_Pop_Order_By = {
  productionSiteProductGroupCalendarId?: InputMaybe<Order_By>;
  workCalendarId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Full_Work_Calendar_Var_Samp_Fields = {
  __typename: 'full_work_calendar_var_samp_fields';
  productionSiteProductGroupCalendarId?: Maybe<Scalars['Float']>;
  workCalendarId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "full_work_calendar" */
export type Full_Work_Calendar_Var_Samp_Order_By = {
  productionSiteProductGroupCalendarId?: InputMaybe<Order_By>;
  workCalendarId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Full_Work_Calendar_Variance_Fields = {
  __typename: 'full_work_calendar_variance_fields';
  productionSiteProductGroupCalendarId?: Maybe<Scalars['Float']>;
  workCalendarId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "full_work_calendar" */
export type Full_Work_Calendar_Variance_Order_By = {
  productionSiteProductGroupCalendarId?: InputMaybe<Order_By>;
  workCalendarId?: InputMaybe<Order_By>;
};

/** columns and relationships of "gender" */
export type Gender = {
  __typename: 'gender';
  /** An array relationship */
  contacts: Array<Contacts>;
  /** An aggregate relationship */
  contacts_aggregate: Contacts_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "gender" */
export type GenderContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


/** columns and relationships of "gender" */
export type GenderContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

/** aggregated selection of "gender" */
export type Gender_Aggregate = {
  __typename: 'gender_aggregate';
  aggregate?: Maybe<Gender_Aggregate_Fields>;
  nodes: Array<Gender>;
};

/** aggregate fields of "gender" */
export type Gender_Aggregate_Fields = {
  __typename: 'gender_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Gender_Max_Fields>;
  min?: Maybe<Gender_Min_Fields>;
};


/** aggregate fields of "gender" */
export type Gender_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gender_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "gender". All fields are combined with a logical 'AND'. */
export type Gender_Bool_Exp = {
  _and?: InputMaybe<Array<Gender_Bool_Exp>>;
  _not?: InputMaybe<Gender_Bool_Exp>;
  _or?: InputMaybe<Array<Gender_Bool_Exp>>;
  contacts?: InputMaybe<Contacts_Bool_Exp>;
  contacts_aggregate?: InputMaybe<Contacts_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Gender_Enum {
  Female = 'FEMALE',
  Male = 'MALE',
  Unknown = 'UNKNOWN'
}

/** Boolean expression to compare columns of type "gender_enum". All fields are combined with logical 'AND'. */
export type Gender_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Gender_Enum>;
  _in?: InputMaybe<Array<Gender_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Gender_Enum>;
  _nin?: InputMaybe<Array<Gender_Enum>>;
};

/** aggregate max on columns */
export type Gender_Max_Fields = {
  __typename: 'gender_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Gender_Min_Fields = {
  __typename: 'gender_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "gender". */
export type Gender_Order_By = {
  contacts_aggregate?: InputMaybe<Contacts_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "gender" */
export enum Gender_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "gender" */
export type Gender_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gender_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gender_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

export type Generate_Presigned_Url_Args = {
  _expiration_in_sec?: InputMaybe<Scalars['Int']>;
  _s3_object_id?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "generate_presigned_url_result" */
export type Generate_Presigned_Url_Result = {
  __typename: 'generate_presigned_url_result';
  ok: Scalars['Boolean'];
};

/** aggregated selection of "generate_presigned_url_result" */
export type Generate_Presigned_Url_Result_Aggregate = {
  __typename: 'generate_presigned_url_result_aggregate';
  aggregate?: Maybe<Generate_Presigned_Url_Result_Aggregate_Fields>;
  nodes: Array<Generate_Presigned_Url_Result>;
};

/** aggregate fields of "generate_presigned_url_result" */
export type Generate_Presigned_Url_Result_Aggregate_Fields = {
  __typename: 'generate_presigned_url_result_aggregate_fields';
  count: Scalars['Int'];
};


/** aggregate fields of "generate_presigned_url_result" */
export type Generate_Presigned_Url_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Generate_Presigned_Url_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "generate_presigned_url_result". All fields are combined with a logical 'AND'. */
export type Generate_Presigned_Url_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Generate_Presigned_Url_Result_Bool_Exp>>;
  _not?: InputMaybe<Generate_Presigned_Url_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Generate_Presigned_Url_Result_Bool_Exp>>;
  ok?: InputMaybe<Boolean_Comparison_Exp>;
};

/** Ordering options when selecting data from "generate_presigned_url_result". */
export type Generate_Presigned_Url_Result_Order_By = {
  ok?: InputMaybe<Order_By>;
};

/** select columns of table "generate_presigned_url_result" */
export enum Generate_Presigned_Url_Result_Select_Column {
  /** column name */
  Ok = 'ok'
}

/** Streaming cursor of the table "generate_presigned_url_result" */
export type Generate_Presigned_Url_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Generate_Presigned_Url_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Generate_Presigned_Url_Result_Stream_Cursor_Value_Input = {
  ok?: InputMaybe<Scalars['Boolean']>;
};

export type Generate_Supplier_Order_Pdf_Args = {
  _expiration_in_sec?: InputMaybe<Scalars['Int']>;
  _is_digital?: InputMaybe<Scalars['Boolean']>;
  _supplier_order_id?: InputMaybe<Scalars['bigint']>;
  _with_signature?: InputMaybe<Scalars['Boolean']>;
};

export type Get_Public_Tenant_Info_Args = {
  hostname?: InputMaybe<Scalars['String']>;
};

export type Global_Search_Args = {
  lang?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "global_search_result" */
export type Global_Search_Result = {
  __typename: 'global_search_result';
  /** An object relationship */
  LotNumber?: Maybe<Lot_Numbers>;
  /** An object relationship */
  contact?: Maybe<Contacts>;
  contactId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  creditNote?: Maybe<Credit_Notes>;
  creditNoteId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  customer?: Maybe<Customers>;
  /** An object relationship */
  customerGroup?: Maybe<Customer_Groups>;
  customerGroupId?: Maybe<Scalars['bigint']>;
  customerId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  customerInvoice?: Maybe<Customer_Invoices>;
  customerInvoiceId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  customerOrder?: Maybe<Customer_Orders>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  /** An object relationship */
  product?: Maybe<Products>;
  /** An object relationship */
  productGroup?: Maybe<Product_Groups>;
  productGroupId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  salesTeam?: Maybe<Sales_Teams>;
  salesTeamId?: Maybe<Scalars['bigint']>;
  similarity: Scalars['Float'];
  /** An object relationship */
  supplier?: Maybe<Suppliers>;
  supplierId?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "global_search_result" */
export type Global_Search_Result_Aggregate = {
  __typename: 'global_search_result_aggregate';
  aggregate?: Maybe<Global_Search_Result_Aggregate_Fields>;
  nodes: Array<Global_Search_Result>;
};

export type Global_Search_Result_Aggregate_Bool_Exp = {
  count?: InputMaybe<Global_Search_Result_Aggregate_Bool_Exp_Count>;
};

export type Global_Search_Result_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Global_Search_Result_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "global_search_result" */
export type Global_Search_Result_Aggregate_Fields = {
  __typename: 'global_search_result_aggregate_fields';
  avg?: Maybe<Global_Search_Result_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Global_Search_Result_Max_Fields>;
  min?: Maybe<Global_Search_Result_Min_Fields>;
  stddev?: Maybe<Global_Search_Result_Stddev_Fields>;
  stddev_pop?: Maybe<Global_Search_Result_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Global_Search_Result_Stddev_Samp_Fields>;
  sum?: Maybe<Global_Search_Result_Sum_Fields>;
  var_pop?: Maybe<Global_Search_Result_Var_Pop_Fields>;
  var_samp?: Maybe<Global_Search_Result_Var_Samp_Fields>;
  variance?: Maybe<Global_Search_Result_Variance_Fields>;
};


/** aggregate fields of "global_search_result" */
export type Global_Search_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "global_search_result" */
export type Global_Search_Result_Aggregate_Order_By = {
  avg?: InputMaybe<Global_Search_Result_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Global_Search_Result_Max_Order_By>;
  min?: InputMaybe<Global_Search_Result_Min_Order_By>;
  stddev?: InputMaybe<Global_Search_Result_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Global_Search_Result_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Global_Search_Result_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Global_Search_Result_Sum_Order_By>;
  var_pop?: InputMaybe<Global_Search_Result_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Global_Search_Result_Var_Samp_Order_By>;
  variance?: InputMaybe<Global_Search_Result_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Global_Search_Result_Avg_Fields = {
  __typename: 'global_search_result_avg_fields';
  contactId?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  customerInvoiceId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesTeamId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "global_search_result" */
export type Global_Search_Result_Avg_Order_By = {
  contactId?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "global_search_result". All fields are combined with a logical 'AND'. */
export type Global_Search_Result_Bool_Exp = {
  LotNumber?: InputMaybe<Lot_Numbers_Bool_Exp>;
  _and?: InputMaybe<Array<Global_Search_Result_Bool_Exp>>;
  _not?: InputMaybe<Global_Search_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Global_Search_Result_Bool_Exp>>;
  contact?: InputMaybe<Contacts_Bool_Exp>;
  contactId?: InputMaybe<Bigint_Comparison_Exp>;
  creditNote?: InputMaybe<Credit_Notes_Bool_Exp>;
  creditNoteId?: InputMaybe<Bigint_Comparison_Exp>;
  customer?: InputMaybe<Customers_Bool_Exp>;
  customerGroup?: InputMaybe<Customer_Groups_Bool_Exp>;
  customerGroupId?: InputMaybe<Bigint_Comparison_Exp>;
  customerId?: InputMaybe<Bigint_Comparison_Exp>;
  customerInvoice?: InputMaybe<Customer_Invoices_Bool_Exp>;
  customerInvoiceId?: InputMaybe<Bigint_Comparison_Exp>;
  customerOrder?: InputMaybe<Customer_Orders_Bool_Exp>;
  customerOrderId?: InputMaybe<Bigint_Comparison_Exp>;
  lotNumberId?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productGroup?: InputMaybe<Product_Groups_Bool_Exp>;
  productGroupId?: InputMaybe<Bigint_Comparison_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  salesTeam?: InputMaybe<Sales_Teams_Bool_Exp>;
  salesTeamId?: InputMaybe<Bigint_Comparison_Exp>;
  similarity?: InputMaybe<Float_Comparison_Exp>;
  supplier?: InputMaybe<Suppliers_Bool_Exp>;
  supplierId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Global_Search_Result_Max_Fields = {
  __typename: 'global_search_result_max_fields';
  contactId?: Maybe<Scalars['bigint']>;
  creditNoteId?: Maybe<Scalars['bigint']>;
  customerGroupId?: Maybe<Scalars['bigint']>;
  customerId?: Maybe<Scalars['bigint']>;
  customerInvoiceId?: Maybe<Scalars['bigint']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  productGroupId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  salesTeamId?: Maybe<Scalars['bigint']>;
  similarity?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "global_search_result" */
export type Global_Search_Result_Max_Order_By = {
  contactId?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Global_Search_Result_Min_Fields = {
  __typename: 'global_search_result_min_fields';
  contactId?: Maybe<Scalars['bigint']>;
  creditNoteId?: Maybe<Scalars['bigint']>;
  customerGroupId?: Maybe<Scalars['bigint']>;
  customerId?: Maybe<Scalars['bigint']>;
  customerInvoiceId?: Maybe<Scalars['bigint']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  productGroupId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  salesTeamId?: Maybe<Scalars['bigint']>;
  similarity?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "global_search_result" */
export type Global_Search_Result_Min_Order_By = {
  contactId?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "global_search_result". */
export type Global_Search_Result_Order_By = {
  LotNumber?: InputMaybe<Lot_Numbers_Order_By>;
  contact?: InputMaybe<Contacts_Order_By>;
  contactId?: InputMaybe<Order_By>;
  creditNote?: InputMaybe<Credit_Notes_Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customer?: InputMaybe<Customers_Order_By>;
  customerGroup?: InputMaybe<Customer_Groups_Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  customerInvoice?: InputMaybe<Customer_Invoices_Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrder?: InputMaybe<Customer_Orders_Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productGroup?: InputMaybe<Product_Groups_Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesTeam?: InputMaybe<Sales_Teams_Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  supplier?: InputMaybe<Suppliers_Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** select columns of table "global_search_result" */
export enum Global_Search_Result_Select_Column {
  /** column name */
  ContactId = 'contactId',
  /** column name */
  CreditNoteId = 'creditNoteId',
  /** column name */
  CustomerGroupId = 'customerGroupId',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  CustomerInvoiceId = 'customerInvoiceId',
  /** column name */
  CustomerOrderId = 'customerOrderId',
  /** column name */
  LotNumberId = 'lotNumberId',
  /** column name */
  Name = 'name',
  /** column name */
  ProductGroupId = 'productGroupId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  SalesTeamId = 'salesTeamId',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  SupplierId = 'supplierId'
}

/** aggregate stddev on columns */
export type Global_Search_Result_Stddev_Fields = {
  __typename: 'global_search_result_stddev_fields';
  contactId?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  customerInvoiceId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesTeamId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "global_search_result" */
export type Global_Search_Result_Stddev_Order_By = {
  contactId?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Global_Search_Result_Stddev_Pop_Fields = {
  __typename: 'global_search_result_stddev_pop_fields';
  contactId?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  customerInvoiceId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesTeamId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "global_search_result" */
export type Global_Search_Result_Stddev_Pop_Order_By = {
  contactId?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Global_Search_Result_Stddev_Samp_Fields = {
  __typename: 'global_search_result_stddev_samp_fields';
  contactId?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  customerInvoiceId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesTeamId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "global_search_result" */
export type Global_Search_Result_Stddev_Samp_Order_By = {
  contactId?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "global_search_result" */
export type Global_Search_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Global_Search_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Global_Search_Result_Stream_Cursor_Value_Input = {
  contactId?: InputMaybe<Scalars['bigint']>;
  creditNoteId?: InputMaybe<Scalars['bigint']>;
  customerGroupId?: InputMaybe<Scalars['bigint']>;
  customerId?: InputMaybe<Scalars['bigint']>;
  customerInvoiceId?: InputMaybe<Scalars['bigint']>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  productGroupId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  salesTeamId?: InputMaybe<Scalars['bigint']>;
  similarity?: InputMaybe<Scalars['Float']>;
  supplierId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Global_Search_Result_Sum_Fields = {
  __typename: 'global_search_result_sum_fields';
  contactId?: Maybe<Scalars['bigint']>;
  creditNoteId?: Maybe<Scalars['bigint']>;
  customerGroupId?: Maybe<Scalars['bigint']>;
  customerId?: Maybe<Scalars['bigint']>;
  customerInvoiceId?: Maybe<Scalars['bigint']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  productGroupId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  salesTeamId?: Maybe<Scalars['bigint']>;
  similarity?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "global_search_result" */
export type Global_Search_Result_Sum_Order_By = {
  contactId?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Global_Search_Result_Var_Pop_Fields = {
  __typename: 'global_search_result_var_pop_fields';
  contactId?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  customerInvoiceId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesTeamId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "global_search_result" */
export type Global_Search_Result_Var_Pop_Order_By = {
  contactId?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Global_Search_Result_Var_Samp_Fields = {
  __typename: 'global_search_result_var_samp_fields';
  contactId?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  customerInvoiceId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesTeamId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "global_search_result" */
export type Global_Search_Result_Var_Samp_Order_By = {
  contactId?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Global_Search_Result_Variance_Fields = {
  __typename: 'global_search_result_variance_fields';
  contactId?: Maybe<Scalars['Float']>;
  creditNoteId?: Maybe<Scalars['Float']>;
  customerGroupId?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['Float']>;
  customerInvoiceId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesTeamId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "global_search_result" */
export type Global_Search_Result_Variance_Order_By = {
  contactId?: InputMaybe<Order_By>;
  creditNoteId?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  customerInvoiceId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
};

/** columns and relationships of "gs1_logistic_types" */
export type Gs1_Logistic_Types = {
  __typename: 'gs1_logistic_types';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  customerTemplateSettings: Array<Customer_Template_Settings>;
  /** An aggregate relationship */
  customerTemplateSettings_aggregate: Customer_Template_Settings_Aggregate;
  data?: Maybe<Scalars['jsonb']>;
  i18n: Scalars['jsonb'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  ordinalPosition: Scalars['Int'];
  tenant: Scalars['String'];
};


/** columns and relationships of "gs1_logistic_types" */
export type Gs1_Logistic_TypesCustomerTemplateSettingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Template_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Template_Settings_Order_By>>;
  where?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
};


/** columns and relationships of "gs1_logistic_types" */
export type Gs1_Logistic_TypesCustomerTemplateSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Template_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Template_Settings_Order_By>>;
  where?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
};


/** columns and relationships of "gs1_logistic_types" */
export type Gs1_Logistic_TypesDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "gs1_logistic_types" */
export type Gs1_Logistic_TypesI18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "gs1_logistic_types" */
export type Gs1_Logistic_Types_Aggregate = {
  __typename: 'gs1_logistic_types_aggregate';
  aggregate?: Maybe<Gs1_Logistic_Types_Aggregate_Fields>;
  nodes: Array<Gs1_Logistic_Types>;
};

export type Gs1_Logistic_Types_Aggregate_Bool_Exp = {
  count?: InputMaybe<Gs1_Logistic_Types_Aggregate_Bool_Exp_Count>;
};

export type Gs1_Logistic_Types_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Gs1_Logistic_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Gs1_Logistic_Types_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "gs1_logistic_types" */
export type Gs1_Logistic_Types_Aggregate_Fields = {
  __typename: 'gs1_logistic_types_aggregate_fields';
  avg?: Maybe<Gs1_Logistic_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Gs1_Logistic_Types_Max_Fields>;
  min?: Maybe<Gs1_Logistic_Types_Min_Fields>;
  stddev?: Maybe<Gs1_Logistic_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Gs1_Logistic_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Gs1_Logistic_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Gs1_Logistic_Types_Sum_Fields>;
  var_pop?: Maybe<Gs1_Logistic_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Gs1_Logistic_Types_Var_Samp_Fields>;
  variance?: Maybe<Gs1_Logistic_Types_Variance_Fields>;
};


/** aggregate fields of "gs1_logistic_types" */
export type Gs1_Logistic_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gs1_Logistic_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "gs1_logistic_types" */
export type Gs1_Logistic_Types_Aggregate_Order_By = {
  avg?: InputMaybe<Gs1_Logistic_Types_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Gs1_Logistic_Types_Max_Order_By>;
  min?: InputMaybe<Gs1_Logistic_Types_Min_Order_By>;
  stddev?: InputMaybe<Gs1_Logistic_Types_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Gs1_Logistic_Types_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Gs1_Logistic_Types_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Gs1_Logistic_Types_Sum_Order_By>;
  var_pop?: InputMaybe<Gs1_Logistic_Types_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Gs1_Logistic_Types_Var_Samp_Order_By>;
  variance?: InputMaybe<Gs1_Logistic_Types_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Gs1_Logistic_Types_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Gs1_Logistic_Types_Avg_Fields = {
  __typename: 'gs1_logistic_types_avg_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "gs1_logistic_types" */
export type Gs1_Logistic_Types_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "gs1_logistic_types". All fields are combined with a logical 'AND'. */
export type Gs1_Logistic_Types_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Gs1_Logistic_Types_Bool_Exp>>;
  _not?: InputMaybe<Gs1_Logistic_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Gs1_Logistic_Types_Bool_Exp>>;
  customerTemplateSettings?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
  customerTemplateSettings_aggregate?: InputMaybe<Customer_Template_Settings_Aggregate_Bool_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "gs1_logistic_types" */
export enum Gs1_Logistic_Types_Constraint {
  /** unique or primary key constraint on columns "tenant", "ordinal_position" */
  Gs1LogisticTypeTenantOrdinalPositionUniq = 'gs1_logistic_type_tenant_ordinal_position_uniq',
  /** unique or primary key constraint on columns "id" */
  Gs1LogisticTypesPkey = 'gs1_logistic_types_pkey',
  /** unique or primary key constraint on columns "name", "tenant" */
  Gs1LogisticTypesTenantNameKey = 'gs1_logistic_types_tenant_name_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Gs1_Logistic_Types_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
  i18n?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Gs1_Logistic_Types_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
  i18n?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Gs1_Logistic_Types_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
  i18n?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "gs1_logistic_types" */
export type Gs1_Logistic_Types_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "gs1_logistic_types" */
export type Gs1_Logistic_Types_Insert_Input = {
  customerTemplateSettings?: InputMaybe<Customer_Template_Settings_Arr_Rel_Insert_Input>;
  data?: InputMaybe<Scalars['jsonb']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Gs1_Logistic_Types_Max_Fields = {
  __typename: 'gs1_logistic_types_max_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "gs1_logistic_types" */
export type Gs1_Logistic_Types_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Gs1_Logistic_Types_Min_Fields = {
  __typename: 'gs1_logistic_types_min_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "gs1_logistic_types" */
export type Gs1_Logistic_Types_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "gs1_logistic_types" */
export type Gs1_Logistic_Types_Mutation_Response = {
  __typename: 'gs1_logistic_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gs1_Logistic_Types>;
};

/** input type for inserting object relation for remote table "gs1_logistic_types" */
export type Gs1_Logistic_Types_Obj_Rel_Insert_Input = {
  data: Gs1_Logistic_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Gs1_Logistic_Types_On_Conflict>;
};

/** on_conflict condition type for table "gs1_logistic_types" */
export type Gs1_Logistic_Types_On_Conflict = {
  constraint: Gs1_Logistic_Types_Constraint;
  update_columns?: Array<Gs1_Logistic_Types_Update_Column>;
  where?: InputMaybe<Gs1_Logistic_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "gs1_logistic_types". */
export type Gs1_Logistic_Types_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  customerTemplateSettings_aggregate?: InputMaybe<Customer_Template_Settings_Aggregate_Order_By>;
  data?: InputMaybe<Order_By>;
  i18n?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** primary key columns input for table: gs1_logistic_types */
export type Gs1_Logistic_Types_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Gs1_Logistic_Types_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "gs1_logistic_types" */
export enum Gs1_Logistic_Types_Select_Column {
  /** column name */
  Data = 'data',
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  Tenant = 'tenant'
}

/** input type for updating data in table "gs1_logistic_types" */
export type Gs1_Logistic_Types_Set_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Gs1_Logistic_Types_Stddev_Fields = {
  __typename: 'gs1_logistic_types_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "gs1_logistic_types" */
export type Gs1_Logistic_Types_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Gs1_Logistic_Types_Stddev_Pop_Fields = {
  __typename: 'gs1_logistic_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "gs1_logistic_types" */
export type Gs1_Logistic_Types_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Gs1_Logistic_Types_Stddev_Samp_Fields = {
  __typename: 'gs1_logistic_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "gs1_logistic_types" */
export type Gs1_Logistic_Types_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "gs1_logistic_types" */
export type Gs1_Logistic_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gs1_Logistic_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gs1_Logistic_Types_Stream_Cursor_Value_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Gs1_Logistic_Types_Sum_Fields = {
  __typename: 'gs1_logistic_types_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "gs1_logistic_types" */
export type Gs1_Logistic_Types_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** update columns of table "gs1_logistic_types" */
export enum Gs1_Logistic_Types_Update_Column {
  /** column name */
  Data = 'data',
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition'
}

export type Gs1_Logistic_Types_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Gs1_Logistic_Types_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Gs1_Logistic_Types_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Gs1_Logistic_Types_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Gs1_Logistic_Types_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Gs1_Logistic_Types_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Gs1_Logistic_Types_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Gs1_Logistic_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Gs1_Logistic_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Gs1_Logistic_Types_Var_Pop_Fields = {
  __typename: 'gs1_logistic_types_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "gs1_logistic_types" */
export type Gs1_Logistic_Types_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Gs1_Logistic_Types_Var_Samp_Fields = {
  __typename: 'gs1_logistic_types_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "gs1_logistic_types" */
export type Gs1_Logistic_Types_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Gs1_Logistic_Types_Variance_Fields = {
  __typename: 'gs1_logistic_types_variance_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "gs1_logistic_types" */
export type Gs1_Logistic_Types_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

export type Hard_Delete_Customer_Order_Return_Args = {
  _id?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "hardware_device_connection_types" */
export type Hardware_Device_Connection_Types = {
  __typename: 'hardware_device_connection_types';
  value: Scalars['String'];
  /** An array relationship */
  workstationHardwareDeviceConnections: Array<Workstation_Hardware_Device_Connections>;
  /** An aggregate relationship */
  workstationHardwareDeviceConnections_aggregate: Workstation_Hardware_Device_Connections_Aggregate;
};


/** columns and relationships of "hardware_device_connection_types" */
export type Hardware_Device_Connection_TypesWorkstationHardwareDeviceConnectionsArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Order_By>>;
  where?: InputMaybe<Workstation_Hardware_Device_Connections_Bool_Exp>;
};


/** columns and relationships of "hardware_device_connection_types" */
export type Hardware_Device_Connection_TypesWorkstationHardwareDeviceConnections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Order_By>>;
  where?: InputMaybe<Workstation_Hardware_Device_Connections_Bool_Exp>;
};

/** aggregated selection of "hardware_device_connection_types" */
export type Hardware_Device_Connection_Types_Aggregate = {
  __typename: 'hardware_device_connection_types_aggregate';
  aggregate?: Maybe<Hardware_Device_Connection_Types_Aggregate_Fields>;
  nodes: Array<Hardware_Device_Connection_Types>;
};

/** aggregate fields of "hardware_device_connection_types" */
export type Hardware_Device_Connection_Types_Aggregate_Fields = {
  __typename: 'hardware_device_connection_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Hardware_Device_Connection_Types_Max_Fields>;
  min?: Maybe<Hardware_Device_Connection_Types_Min_Fields>;
};


/** aggregate fields of "hardware_device_connection_types" */
export type Hardware_Device_Connection_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hardware_Device_Connection_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "hardware_device_connection_types". All fields are combined with a logical 'AND'. */
export type Hardware_Device_Connection_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Hardware_Device_Connection_Types_Bool_Exp>>;
  _not?: InputMaybe<Hardware_Device_Connection_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Hardware_Device_Connection_Types_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
  workstationHardwareDeviceConnections?: InputMaybe<Workstation_Hardware_Device_Connections_Bool_Exp>;
  workstationHardwareDeviceConnections_aggregate?: InputMaybe<Workstation_Hardware_Device_Connections_Aggregate_Bool_Exp>;
};

export enum Hardware_Device_Connection_Types_Enum {
  Ip = 'IP',
  Usb = 'USB'
}

/** Boolean expression to compare columns of type "hardware_device_connection_types_enum". All fields are combined with logical 'AND'. */
export type Hardware_Device_Connection_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Hardware_Device_Connection_Types_Enum>;
  _in?: InputMaybe<Array<Hardware_Device_Connection_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Hardware_Device_Connection_Types_Enum>;
  _nin?: InputMaybe<Array<Hardware_Device_Connection_Types_Enum>>;
};

/** aggregate max on columns */
export type Hardware_Device_Connection_Types_Max_Fields = {
  __typename: 'hardware_device_connection_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Hardware_Device_Connection_Types_Min_Fields = {
  __typename: 'hardware_device_connection_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "hardware_device_connection_types". */
export type Hardware_Device_Connection_Types_Order_By = {
  value?: InputMaybe<Order_By>;
  workstationHardwareDeviceConnections_aggregate?: InputMaybe<Workstation_Hardware_Device_Connections_Aggregate_Order_By>;
};

/** select columns of table "hardware_device_connection_types" */
export enum Hardware_Device_Connection_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "hardware_device_connection_types" */
export type Hardware_Device_Connection_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hardware_Device_Connection_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hardware_Device_Connection_Types_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "hardware_device_types" */
export type Hardware_Device_Types = {
  __typename: 'hardware_device_types';
  /** An array relationship */
  hardwareDevices: Array<Hardware_Devices>;
  /** An aggregate relationship */
  hardwareDevices_aggregate: Hardware_Devices_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "hardware_device_types" */
export type Hardware_Device_TypesHardwareDevicesArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Devices_Order_By>>;
  where?: InputMaybe<Hardware_Devices_Bool_Exp>;
};


/** columns and relationships of "hardware_device_types" */
export type Hardware_Device_TypesHardwareDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Devices_Order_By>>;
  where?: InputMaybe<Hardware_Devices_Bool_Exp>;
};

/** aggregated selection of "hardware_device_types" */
export type Hardware_Device_Types_Aggregate = {
  __typename: 'hardware_device_types_aggregate';
  aggregate?: Maybe<Hardware_Device_Types_Aggregate_Fields>;
  nodes: Array<Hardware_Device_Types>;
};

/** aggregate fields of "hardware_device_types" */
export type Hardware_Device_Types_Aggregate_Fields = {
  __typename: 'hardware_device_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Hardware_Device_Types_Max_Fields>;
  min?: Maybe<Hardware_Device_Types_Min_Fields>;
};


/** aggregate fields of "hardware_device_types" */
export type Hardware_Device_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hardware_Device_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "hardware_device_types". All fields are combined with a logical 'AND'. */
export type Hardware_Device_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Hardware_Device_Types_Bool_Exp>>;
  _not?: InputMaybe<Hardware_Device_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Hardware_Device_Types_Bool_Exp>>;
  hardwareDevices?: InputMaybe<Hardware_Devices_Bool_Exp>;
  hardwareDevices_aggregate?: InputMaybe<Hardware_Devices_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Hardware_Device_Types_Enum {
  A4Printer = 'A4_PRINTER',
  Bizerba = 'BIZERBA',
  LabelPrinter = 'LABEL_PRINTER',
  Leichmehl = 'LEICHMEHL',
  Scale = 'SCALE'
}

/** Boolean expression to compare columns of type "hardware_device_types_enum". All fields are combined with logical 'AND'. */
export type Hardware_Device_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Hardware_Device_Types_Enum>;
  _in?: InputMaybe<Array<Hardware_Device_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Hardware_Device_Types_Enum>;
  _nin?: InputMaybe<Array<Hardware_Device_Types_Enum>>;
};

/** aggregate max on columns */
export type Hardware_Device_Types_Max_Fields = {
  __typename: 'hardware_device_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Hardware_Device_Types_Min_Fields = {
  __typename: 'hardware_device_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "hardware_device_types". */
export type Hardware_Device_Types_Order_By = {
  hardwareDevices_aggregate?: InputMaybe<Hardware_Devices_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "hardware_device_types" */
export enum Hardware_Device_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "hardware_device_types" */
export type Hardware_Device_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hardware_Device_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hardware_Device_Types_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "hardware_devices" */
export type Hardware_Devices = {
  __typename: 'hardware_devices';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  deviceType: Hardware_Device_Types_Enum;
  id: Scalars['bigint'];
  ip?: Maybe<Scalars['inet']>;
  /** An array relationship */
  leichmehlTemplates: Array<Leichmehl_Templates>;
  /** An aggregate relationship */
  leichmehlTemplates_aggregate: Leichmehl_Templates_Aggregate;
  make?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  model?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
  /** An array relationship */
  workstationHardwareDeviceConnections: Array<Workstation_Hardware_Device_Connections>;
  /** An aggregate relationship */
  workstationHardwareDeviceConnections_aggregate: Workstation_Hardware_Device_Connections_Aggregate;
};


/** columns and relationships of "hardware_devices" */
export type Hardware_DevicesLeichmehlTemplatesArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Templates_Order_By>>;
  where?: InputMaybe<Leichmehl_Templates_Bool_Exp>;
};


/** columns and relationships of "hardware_devices" */
export type Hardware_DevicesLeichmehlTemplates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Templates_Order_By>>;
  where?: InputMaybe<Leichmehl_Templates_Bool_Exp>;
};


/** columns and relationships of "hardware_devices" */
export type Hardware_DevicesMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "hardware_devices" */
export type Hardware_DevicesWorkstationHardwareDeviceConnectionsArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Order_By>>;
  where?: InputMaybe<Workstation_Hardware_Device_Connections_Bool_Exp>;
};


/** columns and relationships of "hardware_devices" */
export type Hardware_DevicesWorkstationHardwareDeviceConnections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Order_By>>;
  where?: InputMaybe<Workstation_Hardware_Device_Connections_Bool_Exp>;
};

/** aggregated selection of "hardware_devices" */
export type Hardware_Devices_Aggregate = {
  __typename: 'hardware_devices_aggregate';
  aggregate?: Maybe<Hardware_Devices_Aggregate_Fields>;
  nodes: Array<Hardware_Devices>;
};

export type Hardware_Devices_Aggregate_Bool_Exp = {
  count?: InputMaybe<Hardware_Devices_Aggregate_Bool_Exp_Count>;
};

export type Hardware_Devices_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Hardware_Devices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hardware_Devices_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "hardware_devices" */
export type Hardware_Devices_Aggregate_Fields = {
  __typename: 'hardware_devices_aggregate_fields';
  avg?: Maybe<Hardware_Devices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Hardware_Devices_Max_Fields>;
  min?: Maybe<Hardware_Devices_Min_Fields>;
  stddev?: Maybe<Hardware_Devices_Stddev_Fields>;
  stddev_pop?: Maybe<Hardware_Devices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hardware_Devices_Stddev_Samp_Fields>;
  sum?: Maybe<Hardware_Devices_Sum_Fields>;
  var_pop?: Maybe<Hardware_Devices_Var_Pop_Fields>;
  var_samp?: Maybe<Hardware_Devices_Var_Samp_Fields>;
  variance?: Maybe<Hardware_Devices_Variance_Fields>;
};


/** aggregate fields of "hardware_devices" */
export type Hardware_Devices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hardware_Devices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hardware_devices" */
export type Hardware_Devices_Aggregate_Order_By = {
  avg?: InputMaybe<Hardware_Devices_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hardware_Devices_Max_Order_By>;
  min?: InputMaybe<Hardware_Devices_Min_Order_By>;
  stddev?: InputMaybe<Hardware_Devices_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Hardware_Devices_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Hardware_Devices_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Hardware_Devices_Sum_Order_By>;
  var_pop?: InputMaybe<Hardware_Devices_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Hardware_Devices_Var_Samp_Order_By>;
  variance?: InputMaybe<Hardware_Devices_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Hardware_Devices_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Hardware_Devices_Avg_Fields = {
  __typename: 'hardware_devices_avg_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hardware_devices" */
export type Hardware_Devices_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hardware_devices". All fields are combined with a logical 'AND'. */
export type Hardware_Devices_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Hardware_Devices_Bool_Exp>>;
  _not?: InputMaybe<Hardware_Devices_Bool_Exp>;
  _or?: InputMaybe<Array<Hardware_Devices_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  deviceType?: InputMaybe<Hardware_Device_Types_Enum_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  ip?: InputMaybe<Inet_Comparison_Exp>;
  leichmehlTemplates?: InputMaybe<Leichmehl_Templates_Bool_Exp>;
  leichmehlTemplates_aggregate?: InputMaybe<Leichmehl_Templates_Aggregate_Bool_Exp>;
  make?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  model?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
  workstationHardwareDeviceConnections?: InputMaybe<Workstation_Hardware_Device_Connections_Bool_Exp>;
  workstationHardwareDeviceConnections_aggregate?: InputMaybe<Workstation_Hardware_Device_Connections_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "hardware_devices" */
export enum Hardware_Devices_Constraint {
  /** unique or primary key constraint on columns "id" */
  HardwareDevicesPkey = 'hardware_devices_pkey',
  /** unique or primary key constraint on columns "name", "tenant" */
  HardwareDevicesTenantNameKey = 'hardware_devices_tenant_name_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Hardware_Devices_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Hardware_Devices_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Hardware_Devices_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "hardware_devices" */
export type Hardware_Devices_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "hardware_devices" */
export type Hardware_Devices_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  deviceType?: InputMaybe<Hardware_Device_Types_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  ip?: InputMaybe<Scalars['inet']>;
  make?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  model?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
  workstationHardwareDeviceConnections?: InputMaybe<Workstation_Hardware_Device_Connections_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Hardware_Devices_Max_Fields = {
  __typename: 'hardware_devices_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "hardware_devices" */
export type Hardware_Devices_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  make?: InputMaybe<Order_By>;
  model?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Hardware_Devices_Min_Fields = {
  __typename: 'hardware_devices_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "hardware_devices" */
export type Hardware_Devices_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  make?: InputMaybe<Order_By>;
  model?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "hardware_devices" */
export type Hardware_Devices_Mutation_Response = {
  __typename: 'hardware_devices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hardware_Devices>;
};

/** input type for inserting object relation for remote table "hardware_devices" */
export type Hardware_Devices_Obj_Rel_Insert_Input = {
  data: Hardware_Devices_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Hardware_Devices_On_Conflict>;
};

/** on_conflict condition type for table "hardware_devices" */
export type Hardware_Devices_On_Conflict = {
  constraint: Hardware_Devices_Constraint;
  update_columns?: Array<Hardware_Devices_Update_Column>;
  where?: InputMaybe<Hardware_Devices_Bool_Exp>;
};

/** Ordering options when selecting data from "hardware_devices". */
export type Hardware_Devices_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  deviceType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ip?: InputMaybe<Order_By>;
  leichmehlTemplates_aggregate?: InputMaybe<Leichmehl_Templates_Aggregate_Order_By>;
  make?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  model?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  workstationHardwareDeviceConnections_aggregate?: InputMaybe<Workstation_Hardware_Device_Connections_Aggregate_Order_By>;
};

/** primary key columns input for table: hardware_devices */
export type Hardware_Devices_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Hardware_Devices_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "hardware_devices" */
export enum Hardware_Devices_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DeviceType = 'deviceType',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Make = 'make',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Model = 'model',
  /** column name */
  Name = 'name',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "hardware_devices" */
export type Hardware_Devices_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  deviceType?: InputMaybe<Hardware_Device_Types_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  ip?: InputMaybe<Scalars['inet']>;
  make?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  model?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Hardware_Devices_Stddev_Fields = {
  __typename: 'hardware_devices_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hardware_devices" */
export type Hardware_Devices_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hardware_Devices_Stddev_Pop_Fields = {
  __typename: 'hardware_devices_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hardware_devices" */
export type Hardware_Devices_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hardware_Devices_Stddev_Samp_Fields = {
  __typename: 'hardware_devices_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hardware_devices" */
export type Hardware_Devices_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "hardware_devices" */
export type Hardware_Devices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hardware_Devices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hardware_Devices_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  deviceType?: InputMaybe<Hardware_Device_Types_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  ip?: InputMaybe<Scalars['inet']>;
  make?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  model?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Hardware_Devices_Sum_Fields = {
  __typename: 'hardware_devices_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "hardware_devices" */
export type Hardware_Devices_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "hardware_devices" */
export enum Hardware_Devices_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  DeviceType = 'deviceType',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Make = 'make',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Model = 'model',
  /** column name */
  Name = 'name',
  /** column name */
  Version = 'version'
}

export type Hardware_Devices_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Hardware_Devices_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Hardware_Devices_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Hardware_Devices_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Hardware_Devices_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Hardware_Devices_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Hardware_Devices_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hardware_Devices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hardware_Devices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Hardware_Devices_Var_Pop_Fields = {
  __typename: 'hardware_devices_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hardware_devices" */
export type Hardware_Devices_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hardware_Devices_Var_Samp_Fields = {
  __typename: 'hardware_devices_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hardware_devices" */
export type Hardware_Devices_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Hardware_Devices_Variance_Fields = {
  __typename: 'hardware_devices_variance_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hardware_devices" */
export type Hardware_Devices_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "hierarchical_goods_incomes" */
export type Hierarchical_Goods_Incomes = {
  __typename: 'hierarchical_goods_incomes';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  structure: Scalars['jsonb'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "hierarchical_goods_incomes" */
export type Hierarchical_Goods_IncomesStructureArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hierarchical_goods_incomes" */
export type Hierarchical_Goods_Incomes_Aggregate = {
  __typename: 'hierarchical_goods_incomes_aggregate';
  aggregate?: Maybe<Hierarchical_Goods_Incomes_Aggregate_Fields>;
  nodes: Array<Hierarchical_Goods_Incomes>;
};

export type Hierarchical_Goods_Incomes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Hierarchical_Goods_Incomes_Aggregate_Bool_Exp_Count>;
};

export type Hierarchical_Goods_Incomes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Hierarchical_Goods_Incomes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hierarchical_Goods_Incomes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "hierarchical_goods_incomes" */
export type Hierarchical_Goods_Incomes_Aggregate_Fields = {
  __typename: 'hierarchical_goods_incomes_aggregate_fields';
  avg?: Maybe<Hierarchical_Goods_Incomes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Hierarchical_Goods_Incomes_Max_Fields>;
  min?: Maybe<Hierarchical_Goods_Incomes_Min_Fields>;
  stddev?: Maybe<Hierarchical_Goods_Incomes_Stddev_Fields>;
  stddev_pop?: Maybe<Hierarchical_Goods_Incomes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hierarchical_Goods_Incomes_Stddev_Samp_Fields>;
  sum?: Maybe<Hierarchical_Goods_Incomes_Sum_Fields>;
  var_pop?: Maybe<Hierarchical_Goods_Incomes_Var_Pop_Fields>;
  var_samp?: Maybe<Hierarchical_Goods_Incomes_Var_Samp_Fields>;
  variance?: Maybe<Hierarchical_Goods_Incomes_Variance_Fields>;
};


/** aggregate fields of "hierarchical_goods_incomes" */
export type Hierarchical_Goods_Incomes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hierarchical_Goods_Incomes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hierarchical_goods_incomes" */
export type Hierarchical_Goods_Incomes_Aggregate_Order_By = {
  avg?: InputMaybe<Hierarchical_Goods_Incomes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hierarchical_Goods_Incomes_Max_Order_By>;
  min?: InputMaybe<Hierarchical_Goods_Incomes_Min_Order_By>;
  stddev?: InputMaybe<Hierarchical_Goods_Incomes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Hierarchical_Goods_Incomes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Hierarchical_Goods_Incomes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Hierarchical_Goods_Incomes_Sum_Order_By>;
  var_pop?: InputMaybe<Hierarchical_Goods_Incomes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Hierarchical_Goods_Incomes_Var_Samp_Order_By>;
  variance?: InputMaybe<Hierarchical_Goods_Incomes_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Hierarchical_Goods_Incomes_Append_Input = {
  structure?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Hierarchical_Goods_Incomes_Avg_Fields = {
  __typename: 'hierarchical_goods_incomes_avg_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hierarchical_goods_incomes" */
export type Hierarchical_Goods_Incomes_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hierarchical_goods_incomes". All fields are combined with a logical 'AND'. */
export type Hierarchical_Goods_Incomes_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Hierarchical_Goods_Incomes_Bool_Exp>>;
  _not?: InputMaybe<Hierarchical_Goods_Incomes_Bool_Exp>;
  _or?: InputMaybe<Array<Hierarchical_Goods_Incomes_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  structure?: InputMaybe<Jsonb_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Hierarchical_Goods_Incomes_Delete_At_Path_Input = {
  structure?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Hierarchical_Goods_Incomes_Delete_Elem_Input = {
  structure?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Hierarchical_Goods_Incomes_Delete_Key_Input = {
  structure?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "hierarchical_goods_incomes" */
export type Hierarchical_Goods_Incomes_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Hierarchical_Goods_Incomes_Max_Fields = {
  __typename: 'hierarchical_goods_incomes_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "hierarchical_goods_incomes" */
export type Hierarchical_Goods_Incomes_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Hierarchical_Goods_Incomes_Min_Fields = {
  __typename: 'hierarchical_goods_incomes_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "hierarchical_goods_incomes" */
export type Hierarchical_Goods_Incomes_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "hierarchical_goods_incomes" */
export type Hierarchical_Goods_Incomes_Mutation_Response = {
  __typename: 'hierarchical_goods_incomes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hierarchical_Goods_Incomes>;
};

/** Ordering options when selecting data from "hierarchical_goods_incomes". */
export type Hierarchical_Goods_Incomes_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  structure?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hierarchical_goods_incomes */
export type Hierarchical_Goods_Incomes_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Hierarchical_Goods_Incomes_Prepend_Input = {
  structure?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "hierarchical_goods_incomes" */
export enum Hierarchical_Goods_Incomes_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Structure = 'structure',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "hierarchical_goods_incomes" */
export type Hierarchical_Goods_Incomes_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  structure?: InputMaybe<Scalars['jsonb']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Hierarchical_Goods_Incomes_Stddev_Fields = {
  __typename: 'hierarchical_goods_incomes_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hierarchical_goods_incomes" */
export type Hierarchical_Goods_Incomes_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hierarchical_Goods_Incomes_Stddev_Pop_Fields = {
  __typename: 'hierarchical_goods_incomes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hierarchical_goods_incomes" */
export type Hierarchical_Goods_Incomes_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hierarchical_Goods_Incomes_Stddev_Samp_Fields = {
  __typename: 'hierarchical_goods_incomes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hierarchical_goods_incomes" */
export type Hierarchical_Goods_Incomes_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "hierarchical_goods_incomes" */
export type Hierarchical_Goods_Incomes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hierarchical_Goods_Incomes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hierarchical_Goods_Incomes_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  structure?: InputMaybe<Scalars['jsonb']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Hierarchical_Goods_Incomes_Sum_Fields = {
  __typename: 'hierarchical_goods_incomes_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "hierarchical_goods_incomes" */
export type Hierarchical_Goods_Incomes_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

export type Hierarchical_Goods_Incomes_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Hierarchical_Goods_Incomes_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Hierarchical_Goods_Incomes_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Hierarchical_Goods_Incomes_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Hierarchical_Goods_Incomes_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Hierarchical_Goods_Incomes_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Hierarchical_Goods_Incomes_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hierarchical_Goods_Incomes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hierarchical_Goods_Incomes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Hierarchical_Goods_Incomes_Var_Pop_Fields = {
  __typename: 'hierarchical_goods_incomes_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hierarchical_goods_incomes" */
export type Hierarchical_Goods_Incomes_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hierarchical_Goods_Incomes_Var_Samp_Fields = {
  __typename: 'hierarchical_goods_incomes_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hierarchical_goods_incomes" */
export type Hierarchical_Goods_Incomes_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Hierarchical_Goods_Incomes_Variance_Fields = {
  __typename: 'hierarchical_goods_incomes_variance_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hierarchical_goods_incomes" */
export type Hierarchical_Goods_Incomes_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "images" */
export type Images = {
  __typename: 'images';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  /** An object relationship */
  s3Object?: Maybe<S3_Objects>;
  s3ObjectId?: Maybe<Scalars['bigint']>;
  tenant: Scalars['String'];
  /** An array relationship */
  tenants: Array<Tenants>;
  /** An aggregate relationship */
  tenants_aggregate: Tenants_Aggregate;
  updatedAt: Scalars['timestamptz'];
  url: Scalars['String'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  version: Scalars['bigint'];
};


/** columns and relationships of "images" */
export type ImagesProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesTenantsArgs = {
  distinct_on?: InputMaybe<Array<Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenants_Order_By>>;
  where?: InputMaybe<Tenants_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesTenants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenants_Order_By>>;
  where?: InputMaybe<Tenants_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "images" */
export type ImagesUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "images" */
export type Images_Aggregate = {
  __typename: 'images_aggregate';
  aggregate?: Maybe<Images_Aggregate_Fields>;
  nodes: Array<Images>;
};

export type Images_Aggregate_Bool_Exp = {
  count?: InputMaybe<Images_Aggregate_Bool_Exp_Count>;
};

export type Images_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Images_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Images_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "images" */
export type Images_Aggregate_Fields = {
  __typename: 'images_aggregate_fields';
  avg?: Maybe<Images_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Images_Max_Fields>;
  min?: Maybe<Images_Min_Fields>;
  stddev?: Maybe<Images_Stddev_Fields>;
  stddev_pop?: Maybe<Images_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Images_Stddev_Samp_Fields>;
  sum?: Maybe<Images_Sum_Fields>;
  var_pop?: Maybe<Images_Var_Pop_Fields>;
  var_samp?: Maybe<Images_Var_Samp_Fields>;
  variance?: Maybe<Images_Variance_Fields>;
};


/** aggregate fields of "images" */
export type Images_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Images_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "images" */
export type Images_Aggregate_Order_By = {
  avg?: InputMaybe<Images_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Images_Max_Order_By>;
  min?: InputMaybe<Images_Min_Order_By>;
  stddev?: InputMaybe<Images_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Images_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Images_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Images_Sum_Order_By>;
  var_pop?: InputMaybe<Images_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Images_Var_Samp_Order_By>;
  variance?: InputMaybe<Images_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "images" */
export type Images_Arr_Rel_Insert_Input = {
  data: Array<Images_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Images_On_Conflict>;
};

/** aggregate avg on columns */
export type Images_Avg_Fields = {
  __typename: 'images_avg_fields';
  id?: Maybe<Scalars['Float']>;
  s3ObjectId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "images" */
export type Images_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "images". All fields are combined with a logical 'AND'. */
export type Images_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Images_Bool_Exp>>;
  _not?: InputMaybe<Images_Bool_Exp>;
  _or?: InputMaybe<Array<Images_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  products?: InputMaybe<Products_Bool_Exp>;
  products_aggregate?: InputMaybe<Products_Aggregate_Bool_Exp>;
  s3Object?: InputMaybe<S3_Objects_Bool_Exp>;
  s3ObjectId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  tenants?: InputMaybe<Tenants_Bool_Exp>;
  tenants_aggregate?: InputMaybe<Tenants_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
  users_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "images" */
export enum Images_Constraint {
  /** unique or primary key constraint on columns "id" */
  ImagesPkey = 'images_pkey'
}

/** input type for incrementing numeric columns in table "images" */
export type Images_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  s3ObjectId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "images" */
export type Images_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  products?: InputMaybe<Products_Arr_Rel_Insert_Input>;
  s3Object?: InputMaybe<S3_Objects_Obj_Rel_Insert_Input>;
  s3ObjectId?: InputMaybe<Scalars['bigint']>;
  url?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Images_Max_Fields = {
  __typename: 'images_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  s3ObjectId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "images" */
export type Images_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Images_Min_Fields = {
  __typename: 'images_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  s3ObjectId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "images" */
export type Images_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "images" */
export type Images_Mutation_Response = {
  __typename: 'images_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Images>;
};

/** input type for inserting object relation for remote table "images" */
export type Images_Obj_Rel_Insert_Input = {
  data: Images_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Images_On_Conflict>;
};

/** on_conflict condition type for table "images" */
export type Images_On_Conflict = {
  constraint: Images_Constraint;
  update_columns?: Array<Images_Update_Column>;
  where?: InputMaybe<Images_Bool_Exp>;
};

/** Ordering options when selecting data from "images". */
export type Images_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  products_aggregate?: InputMaybe<Products_Aggregate_Order_By>;
  s3Object?: InputMaybe<S3_Objects_Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  tenants_aggregate?: InputMaybe<Tenants_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: images */
export type Images_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "images" */
export enum Images_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  S3ObjectId = 's3ObjectId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "images" */
export type Images_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  s3ObjectId?: InputMaybe<Scalars['bigint']>;
  url?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Images_Stddev_Fields = {
  __typename: 'images_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  s3ObjectId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "images" */
export type Images_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Images_Stddev_Pop_Fields = {
  __typename: 'images_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  s3ObjectId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "images" */
export type Images_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Images_Stddev_Samp_Fields = {
  __typename: 'images_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  s3ObjectId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "images" */
export type Images_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "images" */
export type Images_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Images_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Images_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  s3ObjectId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Images_Sum_Fields = {
  __typename: 'images_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  s3ObjectId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "images" */
export type Images_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "images" */
export enum Images_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  S3ObjectId = 's3ObjectId',
  /** column name */
  Url = 'url',
  /** column name */
  Version = 'version'
}

export type Images_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Images_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Images_Set_Input>;
  /** filter the rows which have to be updated */
  where: Images_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Images_Var_Pop_Fields = {
  __typename: 'images_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  s3ObjectId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "images" */
export type Images_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Images_Var_Samp_Fields = {
  __typename: 'images_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  s3ObjectId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "images" */
export type Images_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Images_Variance_Fields = {
  __typename: 'images_variance_fields';
  id?: Maybe<Scalars['Float']>;
  s3ObjectId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "images" */
export type Images_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "inet". All fields are combined with logical 'AND'. */
export type Inet_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['inet']>;
  _gt?: InputMaybe<Scalars['inet']>;
  _gte?: InputMaybe<Scalars['inet']>;
  _in?: InputMaybe<Array<Scalars['inet']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['inet']>;
  _lte?: InputMaybe<Scalars['inet']>;
  _neq?: InputMaybe<Scalars['inet']>;
  _nin?: InputMaybe<Array<Scalars['inet']>>;
};

export type Initialize_Dismantling_Pricing_Args = {
  _animal_product_group_id?: InputMaybe<Scalars['bigint']>;
  _valid?: InputMaybe<Scalars['daterange']>;
};

export type Initiate_S3_Upload_Args = {
  _expiration_in_sec?: InputMaybe<Scalars['Int']>;
  _s3_object_key?: InputMaybe<Scalars['String']>;
};

export type Inventory_Daily_Prognosis_Args = {
  _config?: InputMaybe<Scalars['jsonb']>;
  _end_date?: InputMaybe<Scalars['date']>;
  _inventory_average_daily_usage?: InputMaybe<Scalars['bigint']>;
  _monetary_unit_id?: InputMaybe<Scalars['bigint']>;
  _product_id?: InputMaybe<Scalars['bigint']>;
  _stock_location_id?: InputMaybe<Scalars['bigint']>;
  _storage_unit_id?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "inventory_daily_prognosis_results" */
export type Inventory_Daily_Prognosis_Results = {
  __typename: 'inventory_daily_prognosis_results';
  contents?: Maybe<Scalars['jsonb']>;
  day: Scalars['date'];
  expectedIncomingGoods?: Maybe<Scalars['bigint']>;
  inventoryAverageDailyUsage?: Maybe<Scalars['bigint']>;
  monetaryQuantity: Scalars['bigint'];
  /** An object relationship */
  monetaryUnit: Product_Units;
  monetaryUnitId: Scalars['bigint'];
  plannedUsage?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  /** An object relationship */
  stockLocation: Stock_Locations;
  stockLocationId: Scalars['bigint'];
  /** An object relationship */
  storageUnit: Product_Units;
  storageUnitId: Scalars['bigint'];
};


/** columns and relationships of "inventory_daily_prognosis_results" */
export type Inventory_Daily_Prognosis_ResultsContentsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "inventory_daily_prognosis_results" */
export type Inventory_Daily_Prognosis_Results_Aggregate = {
  __typename: 'inventory_daily_prognosis_results_aggregate';
  aggregate?: Maybe<Inventory_Daily_Prognosis_Results_Aggregate_Fields>;
  nodes: Array<Inventory_Daily_Prognosis_Results>;
};

export type Inventory_Daily_Prognosis_Results_Aggregate_Bool_Exp = {
  count?: InputMaybe<Inventory_Daily_Prognosis_Results_Aggregate_Bool_Exp_Count>;
};

export type Inventory_Daily_Prognosis_Results_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "inventory_daily_prognosis_results" */
export type Inventory_Daily_Prognosis_Results_Aggregate_Fields = {
  __typename: 'inventory_daily_prognosis_results_aggregate_fields';
  avg?: Maybe<Inventory_Daily_Prognosis_Results_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Inventory_Daily_Prognosis_Results_Max_Fields>;
  min?: Maybe<Inventory_Daily_Prognosis_Results_Min_Fields>;
  stddev?: Maybe<Inventory_Daily_Prognosis_Results_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Daily_Prognosis_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Daily_Prognosis_Results_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Daily_Prognosis_Results_Sum_Fields>;
  var_pop?: Maybe<Inventory_Daily_Prognosis_Results_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Daily_Prognosis_Results_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Daily_Prognosis_Results_Variance_Fields>;
};


/** aggregate fields of "inventory_daily_prognosis_results" */
export type Inventory_Daily_Prognosis_Results_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "inventory_daily_prognosis_results" */
export type Inventory_Daily_Prognosis_Results_Aggregate_Order_By = {
  avg?: InputMaybe<Inventory_Daily_Prognosis_Results_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Inventory_Daily_Prognosis_Results_Max_Order_By>;
  min?: InputMaybe<Inventory_Daily_Prognosis_Results_Min_Order_By>;
  stddev?: InputMaybe<Inventory_Daily_Prognosis_Results_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Inventory_Daily_Prognosis_Results_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Inventory_Daily_Prognosis_Results_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Inventory_Daily_Prognosis_Results_Sum_Order_By>;
  var_pop?: InputMaybe<Inventory_Daily_Prognosis_Results_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Inventory_Daily_Prognosis_Results_Var_Samp_Order_By>;
  variance?: InputMaybe<Inventory_Daily_Prognosis_Results_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Inventory_Daily_Prognosis_Results_Avg_Fields = {
  __typename: 'inventory_daily_prognosis_results_avg_fields';
  expectedIncomingGoods?: Maybe<Scalars['Float']>;
  inventoryAverageDailyUsage?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  plannedUsage?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "inventory_daily_prognosis_results" */
export type Inventory_Daily_Prognosis_Results_Avg_Order_By = {
  expectedIncomingGoods?: InputMaybe<Order_By>;
  inventoryAverageDailyUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  plannedUsage?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory_daily_prognosis_results". All fields are combined with a logical 'AND'. */
export type Inventory_Daily_Prognosis_Results_Bool_Exp = {
  _and?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Bool_Exp>>;
  _not?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
  _or?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Bool_Exp>>;
  contents?: InputMaybe<Jsonb_Comparison_Exp>;
  day?: InputMaybe<Date_Comparison_Exp>;
  expectedIncomingGoods?: InputMaybe<Bigint_Comparison_Exp>;
  inventoryAverageDailyUsage?: InputMaybe<Bigint_Comparison_Exp>;
  monetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  monetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  monetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  plannedUsage?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  stockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  stockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  storageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  storageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Inventory_Daily_Prognosis_Results_Max_Fields = {
  __typename: 'inventory_daily_prognosis_results_max_fields';
  day?: Maybe<Scalars['date']>;
  expectedIncomingGoods?: Maybe<Scalars['bigint']>;
  inventoryAverageDailyUsage?: Maybe<Scalars['bigint']>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  plannedUsage?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "inventory_daily_prognosis_results" */
export type Inventory_Daily_Prognosis_Results_Max_Order_By = {
  day?: InputMaybe<Order_By>;
  expectedIncomingGoods?: InputMaybe<Order_By>;
  inventoryAverageDailyUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  plannedUsage?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Daily_Prognosis_Results_Min_Fields = {
  __typename: 'inventory_daily_prognosis_results_min_fields';
  day?: Maybe<Scalars['date']>;
  expectedIncomingGoods?: Maybe<Scalars['bigint']>;
  inventoryAverageDailyUsage?: Maybe<Scalars['bigint']>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  plannedUsage?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "inventory_daily_prognosis_results" */
export type Inventory_Daily_Prognosis_Results_Min_Order_By = {
  day?: InputMaybe<Order_By>;
  expectedIncomingGoods?: InputMaybe<Order_By>;
  inventoryAverageDailyUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  plannedUsage?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "inventory_daily_prognosis_results". */
export type Inventory_Daily_Prognosis_Results_Order_By = {
  contents?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  expectedIncomingGoods?: InputMaybe<Order_By>;
  inventoryAverageDailyUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnit?: InputMaybe<Product_Units_Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  plannedUsage?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocation?: InputMaybe<Stock_Locations_Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnit?: InputMaybe<Product_Units_Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** select columns of table "inventory_daily_prognosis_results" */
export enum Inventory_Daily_Prognosis_Results_Select_Column {
  /** column name */
  Contents = 'contents',
  /** column name */
  Day = 'day',
  /** column name */
  ExpectedIncomingGoods = 'expectedIncomingGoods',
  /** column name */
  InventoryAverageDailyUsage = 'inventoryAverageDailyUsage',
  /** column name */
  MonetaryQuantity = 'monetaryQuantity',
  /** column name */
  MonetaryUnitId = 'monetaryUnitId',
  /** column name */
  PlannedUsage = 'plannedUsage',
  /** column name */
  ProductId = 'productId',
  /** column name */
  StockLocationId = 'stockLocationId',
  /** column name */
  StorageUnitId = 'storageUnitId'
}

/** aggregate stddev on columns */
export type Inventory_Daily_Prognosis_Results_Stddev_Fields = {
  __typename: 'inventory_daily_prognosis_results_stddev_fields';
  expectedIncomingGoods?: Maybe<Scalars['Float']>;
  inventoryAverageDailyUsage?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  plannedUsage?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "inventory_daily_prognosis_results" */
export type Inventory_Daily_Prognosis_Results_Stddev_Order_By = {
  expectedIncomingGoods?: InputMaybe<Order_By>;
  inventoryAverageDailyUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  plannedUsage?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Daily_Prognosis_Results_Stddev_Pop_Fields = {
  __typename: 'inventory_daily_prognosis_results_stddev_pop_fields';
  expectedIncomingGoods?: Maybe<Scalars['Float']>;
  inventoryAverageDailyUsage?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  plannedUsage?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "inventory_daily_prognosis_results" */
export type Inventory_Daily_Prognosis_Results_Stddev_Pop_Order_By = {
  expectedIncomingGoods?: InputMaybe<Order_By>;
  inventoryAverageDailyUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  plannedUsage?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Daily_Prognosis_Results_Stddev_Samp_Fields = {
  __typename: 'inventory_daily_prognosis_results_stddev_samp_fields';
  expectedIncomingGoods?: Maybe<Scalars['Float']>;
  inventoryAverageDailyUsage?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  plannedUsage?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "inventory_daily_prognosis_results" */
export type Inventory_Daily_Prognosis_Results_Stddev_Samp_Order_By = {
  expectedIncomingGoods?: InputMaybe<Order_By>;
  inventoryAverageDailyUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  plannedUsage?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "inventory_daily_prognosis_results" */
export type Inventory_Daily_Prognosis_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inventory_Daily_Prognosis_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inventory_Daily_Prognosis_Results_Stream_Cursor_Value_Input = {
  contents?: InputMaybe<Scalars['jsonb']>;
  day?: InputMaybe<Scalars['date']>;
  expectedIncomingGoods?: InputMaybe<Scalars['bigint']>;
  inventoryAverageDailyUsage?: InputMaybe<Scalars['bigint']>;
  monetaryQuantity?: InputMaybe<Scalars['bigint']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  plannedUsage?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Inventory_Daily_Prognosis_Results_Sum_Fields = {
  __typename: 'inventory_daily_prognosis_results_sum_fields';
  expectedIncomingGoods?: Maybe<Scalars['bigint']>;
  inventoryAverageDailyUsage?: Maybe<Scalars['bigint']>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  plannedUsage?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "inventory_daily_prognosis_results" */
export type Inventory_Daily_Prognosis_Results_Sum_Order_By = {
  expectedIncomingGoods?: InputMaybe<Order_By>;
  inventoryAverageDailyUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  plannedUsage?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Inventory_Daily_Prognosis_Results_Var_Pop_Fields = {
  __typename: 'inventory_daily_prognosis_results_var_pop_fields';
  expectedIncomingGoods?: Maybe<Scalars['Float']>;
  inventoryAverageDailyUsage?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  plannedUsage?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "inventory_daily_prognosis_results" */
export type Inventory_Daily_Prognosis_Results_Var_Pop_Order_By = {
  expectedIncomingGoods?: InputMaybe<Order_By>;
  inventoryAverageDailyUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  plannedUsage?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Daily_Prognosis_Results_Var_Samp_Fields = {
  __typename: 'inventory_daily_prognosis_results_var_samp_fields';
  expectedIncomingGoods?: Maybe<Scalars['Float']>;
  inventoryAverageDailyUsage?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  plannedUsage?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "inventory_daily_prognosis_results" */
export type Inventory_Daily_Prognosis_Results_Var_Samp_Order_By = {
  expectedIncomingGoods?: InputMaybe<Order_By>;
  inventoryAverageDailyUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  plannedUsage?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Daily_Prognosis_Results_Variance_Fields = {
  __typename: 'inventory_daily_prognosis_results_variance_fields';
  expectedIncomingGoods?: Maybe<Scalars['Float']>;
  inventoryAverageDailyUsage?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  plannedUsage?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "inventory_daily_prognosis_results" */
export type Inventory_Daily_Prognosis_Results_Variance_Order_By = {
  expectedIncomingGoods?: InputMaybe<Order_By>;
  inventoryAverageDailyUsage?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  plannedUsage?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** columns and relationships of "inventory_daily_usage_results" */
export type Inventory_Daily_Usage_Results = {
  __typename: 'inventory_daily_usage_results';
  contents?: Maybe<Scalars['jsonb']>;
  day: Scalars['date'];
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  monetaryUnit: Product_Units;
  monetaryUnitId: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  /** An object relationship */
  stockLocation: Stock_Locations;
  stockLocationId: Scalars['bigint'];
  /** An object relationship */
  storageUnit: Product_Units;
  storageUnitId: Scalars['bigint'];
};


/** columns and relationships of "inventory_daily_usage_results" */
export type Inventory_Daily_Usage_ResultsContentsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "inventory_daily_usage_results" */
export type Inventory_Daily_Usage_Results_Aggregate = {
  __typename: 'inventory_daily_usage_results_aggregate';
  aggregate?: Maybe<Inventory_Daily_Usage_Results_Aggregate_Fields>;
  nodes: Array<Inventory_Daily_Usage_Results>;
};

export type Inventory_Daily_Usage_Results_Aggregate_Bool_Exp = {
  count?: InputMaybe<Inventory_Daily_Usage_Results_Aggregate_Bool_Exp_Count>;
};

export type Inventory_Daily_Usage_Results_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Inventory_Daily_Usage_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Inventory_Daily_Usage_Results_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "inventory_daily_usage_results" */
export type Inventory_Daily_Usage_Results_Aggregate_Fields = {
  __typename: 'inventory_daily_usage_results_aggregate_fields';
  avg?: Maybe<Inventory_Daily_Usage_Results_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Inventory_Daily_Usage_Results_Max_Fields>;
  min?: Maybe<Inventory_Daily_Usage_Results_Min_Fields>;
  stddev?: Maybe<Inventory_Daily_Usage_Results_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Daily_Usage_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Daily_Usage_Results_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Daily_Usage_Results_Sum_Fields>;
  var_pop?: Maybe<Inventory_Daily_Usage_Results_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Daily_Usage_Results_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Daily_Usage_Results_Variance_Fields>;
};


/** aggregate fields of "inventory_daily_usage_results" */
export type Inventory_Daily_Usage_Results_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Inventory_Daily_Usage_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "inventory_daily_usage_results" */
export type Inventory_Daily_Usage_Results_Aggregate_Order_By = {
  avg?: InputMaybe<Inventory_Daily_Usage_Results_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Inventory_Daily_Usage_Results_Max_Order_By>;
  min?: InputMaybe<Inventory_Daily_Usage_Results_Min_Order_By>;
  stddev?: InputMaybe<Inventory_Daily_Usage_Results_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Inventory_Daily_Usage_Results_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Inventory_Daily_Usage_Results_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Inventory_Daily_Usage_Results_Sum_Order_By>;
  var_pop?: InputMaybe<Inventory_Daily_Usage_Results_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Inventory_Daily_Usage_Results_Var_Samp_Order_By>;
  variance?: InputMaybe<Inventory_Daily_Usage_Results_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Inventory_Daily_Usage_Results_Avg_Fields = {
  __typename: 'inventory_daily_usage_results_avg_fields';
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "inventory_daily_usage_results" */
export type Inventory_Daily_Usage_Results_Avg_Order_By = {
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory_daily_usage_results". All fields are combined with a logical 'AND'. */
export type Inventory_Daily_Usage_Results_Bool_Exp = {
  _and?: InputMaybe<Array<Inventory_Daily_Usage_Results_Bool_Exp>>;
  _not?: InputMaybe<Inventory_Daily_Usage_Results_Bool_Exp>;
  _or?: InputMaybe<Array<Inventory_Daily_Usage_Results_Bool_Exp>>;
  contents?: InputMaybe<Jsonb_Comparison_Exp>;
  day?: InputMaybe<Date_Comparison_Exp>;
  monetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  monetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  monetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  stockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  stockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  storageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  storageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Inventory_Daily_Usage_Results_Max_Fields = {
  __typename: 'inventory_daily_usage_results_max_fields';
  day?: Maybe<Scalars['date']>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "inventory_daily_usage_results" */
export type Inventory_Daily_Usage_Results_Max_Order_By = {
  day?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Daily_Usage_Results_Min_Fields = {
  __typename: 'inventory_daily_usage_results_min_fields';
  day?: Maybe<Scalars['date']>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "inventory_daily_usage_results" */
export type Inventory_Daily_Usage_Results_Min_Order_By = {
  day?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "inventory_daily_usage_results". */
export type Inventory_Daily_Usage_Results_Order_By = {
  contents?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnit?: InputMaybe<Product_Units_Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocation?: InputMaybe<Stock_Locations_Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnit?: InputMaybe<Product_Units_Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** select columns of table "inventory_daily_usage_results" */
export enum Inventory_Daily_Usage_Results_Select_Column {
  /** column name */
  Contents = 'contents',
  /** column name */
  Day = 'day',
  /** column name */
  MonetaryQuantity = 'monetaryQuantity',
  /** column name */
  MonetaryUnitId = 'monetaryUnitId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  StockLocationId = 'stockLocationId',
  /** column name */
  StorageUnitId = 'storageUnitId'
}

/** aggregate stddev on columns */
export type Inventory_Daily_Usage_Results_Stddev_Fields = {
  __typename: 'inventory_daily_usage_results_stddev_fields';
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "inventory_daily_usage_results" */
export type Inventory_Daily_Usage_Results_Stddev_Order_By = {
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Daily_Usage_Results_Stddev_Pop_Fields = {
  __typename: 'inventory_daily_usage_results_stddev_pop_fields';
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "inventory_daily_usage_results" */
export type Inventory_Daily_Usage_Results_Stddev_Pop_Order_By = {
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Daily_Usage_Results_Stddev_Samp_Fields = {
  __typename: 'inventory_daily_usage_results_stddev_samp_fields';
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "inventory_daily_usage_results" */
export type Inventory_Daily_Usage_Results_Stddev_Samp_Order_By = {
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "inventory_daily_usage_results" */
export type Inventory_Daily_Usage_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inventory_Daily_Usage_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inventory_Daily_Usage_Results_Stream_Cursor_Value_Input = {
  contents?: InputMaybe<Scalars['jsonb']>;
  day?: InputMaybe<Scalars['date']>;
  monetaryQuantity?: InputMaybe<Scalars['bigint']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Inventory_Daily_Usage_Results_Sum_Fields = {
  __typename: 'inventory_daily_usage_results_sum_fields';
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "inventory_daily_usage_results" */
export type Inventory_Daily_Usage_Results_Sum_Order_By = {
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Inventory_Daily_Usage_Results_Var_Pop_Fields = {
  __typename: 'inventory_daily_usage_results_var_pop_fields';
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "inventory_daily_usage_results" */
export type Inventory_Daily_Usage_Results_Var_Pop_Order_By = {
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Daily_Usage_Results_Var_Samp_Fields = {
  __typename: 'inventory_daily_usage_results_var_samp_fields';
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "inventory_daily_usage_results" */
export type Inventory_Daily_Usage_Results_Var_Samp_Order_By = {
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Daily_Usage_Results_Variance_Fields = {
  __typename: 'inventory_daily_usage_results_variance_fields';
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "inventory_daily_usage_results" */
export type Inventory_Daily_Usage_Results_Variance_Order_By = {
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** columns and relationships of "inventory_items" */
export type Inventory_Items = {
  __typename: 'inventory_items';
  /** An object relationship */
  LotNumber?: Maybe<Lot_Numbers>;
  /** An object relationship */
  account: Accounts;
  accountId: Scalars['bigint'];
  availableMonetaryQuantity: Scalars['bigint'];
  availableStorageQuantity: Scalars['bigint'];
  /** Unique identity for each aggregate record */
  hash?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  monetaryQuantity: Scalars['bigint'];
  /** An object relationship */
  monetaryUnit: Product_Units;
  monetaryUnitId: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  reservedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  reservedStorageQuantity?: Maybe<Scalars['bigint']>;
  storageQuantity: Scalars['bigint'];
  /** An object relationship */
  storageUnit: Product_Units;
  storageUnitId: Scalars['bigint'];
};

/** aggregated selection of "inventory_items" */
export type Inventory_Items_Aggregate = {
  __typename: 'inventory_items_aggregate';
  aggregate?: Maybe<Inventory_Items_Aggregate_Fields>;
  nodes: Array<Inventory_Items>;
};

export type Inventory_Items_Aggregate_Bool_Exp = {
  count?: InputMaybe<Inventory_Items_Aggregate_Bool_Exp_Count>;
};

export type Inventory_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Inventory_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "inventory_items" */
export type Inventory_Items_Aggregate_Fields = {
  __typename: 'inventory_items_aggregate_fields';
  avg?: Maybe<Inventory_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Inventory_Items_Max_Fields>;
  min?: Maybe<Inventory_Items_Min_Fields>;
  stddev?: Maybe<Inventory_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Items_Sum_Fields>;
  var_pop?: Maybe<Inventory_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Items_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Items_Variance_Fields>;
};


/** aggregate fields of "inventory_items" */
export type Inventory_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "inventory_items" */
export type Inventory_Items_Aggregate_Order_By = {
  avg?: InputMaybe<Inventory_Items_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Inventory_Items_Max_Order_By>;
  min?: InputMaybe<Inventory_Items_Min_Order_By>;
  stddev?: InputMaybe<Inventory_Items_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Inventory_Items_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Inventory_Items_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Inventory_Items_Sum_Order_By>;
  var_pop?: InputMaybe<Inventory_Items_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Inventory_Items_Var_Samp_Order_By>;
  variance?: InputMaybe<Inventory_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory_items" */
export type Inventory_Items_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Items_Insert_Input>;
};

/** aggregate avg on columns */
export type Inventory_Items_Avg_Fields = {
  __typename: 'inventory_items_avg_fields';
  accountId?: Maybe<Scalars['Float']>;
  availableMonetaryQuantity?: Maybe<Scalars['Float']>;
  availableStorageQuantity?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  reservedStorageQuantity?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "inventory_items" */
export type Inventory_Items_Avg_Order_By = {
  accountId?: InputMaybe<Order_By>;
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory_items". All fields are combined with a logical 'AND'. */
export type Inventory_Items_Bool_Exp = {
  LotNumber?: InputMaybe<Lot_Numbers_Bool_Exp>;
  _and?: InputMaybe<Array<Inventory_Items_Bool_Exp>>;
  _not?: InputMaybe<Inventory_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Inventory_Items_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accountId?: InputMaybe<Bigint_Comparison_Exp>;
  availableMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  availableStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  hash?: InputMaybe<Bigint_Comparison_Exp>;
  lotNumber?: InputMaybe<String_Comparison_Exp>;
  lotNumberId?: InputMaybe<Bigint_Comparison_Exp>;
  monetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  monetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  monetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  reservedMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  reservedStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  storageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  storageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  storageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** columns and relationships of "inventory_items_by_location" */
export type Inventory_Items_By_Location = {
  __typename: 'inventory_items_by_location';
  /** An object relationship */
  LotNumber?: Maybe<Lot_Numbers>;
  availableMonetaryQuantity: Scalars['bigint'];
  availableStorageQuantity: Scalars['bigint'];
  costPricePerUnit?: Maybe<Scalars['bigint']>;
  costPriceUnitId?: Maybe<Scalars['bigint']>;
  currency?: Maybe<Currencies_Enum>;
  /** Unique identity for each aggregate record */
  hash?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  location: Stock_Locations;
  locationId: Scalars['bigint'];
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  monetaryQuantity: Scalars['bigint'];
  /** An object relationship */
  monetaryUnit: Product_Units;
  monetaryUnitId: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  /** An object relationship */
  productStockLocation?: Maybe<Product_Stock_Locations>;
  reservedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  reservedStorageQuantity?: Maybe<Scalars['bigint']>;
  storageQuantity: Scalars['bigint'];
  /** An object relationship */
  storageUnit: Product_Units;
  storageUnitId: Scalars['bigint'];
  totalMonetaryCostPrice?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "inventory_items_by_location" */
export type Inventory_Items_By_Location_Aggregate = {
  __typename: 'inventory_items_by_location_aggregate';
  aggregate?: Maybe<Inventory_Items_By_Location_Aggregate_Fields>;
  nodes: Array<Inventory_Items_By_Location>;
};

export type Inventory_Items_By_Location_Aggregate_Bool_Exp = {
  count?: InputMaybe<Inventory_Items_By_Location_Aggregate_Bool_Exp_Count>;
};

export type Inventory_Items_By_Location_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "inventory_items_by_location" */
export type Inventory_Items_By_Location_Aggregate_Fields = {
  __typename: 'inventory_items_by_location_aggregate_fields';
  avg?: Maybe<Inventory_Items_By_Location_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Inventory_Items_By_Location_Max_Fields>;
  min?: Maybe<Inventory_Items_By_Location_Min_Fields>;
  stddev?: Maybe<Inventory_Items_By_Location_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Items_By_Location_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Items_By_Location_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Items_By_Location_Sum_Fields>;
  var_pop?: Maybe<Inventory_Items_By_Location_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Items_By_Location_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Items_By_Location_Variance_Fields>;
};


/** aggregate fields of "inventory_items_by_location" */
export type Inventory_Items_By_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "inventory_items_by_location" */
export type Inventory_Items_By_Location_Aggregate_Order_By = {
  avg?: InputMaybe<Inventory_Items_By_Location_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Inventory_Items_By_Location_Max_Order_By>;
  min?: InputMaybe<Inventory_Items_By_Location_Min_Order_By>;
  stddev?: InputMaybe<Inventory_Items_By_Location_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Inventory_Items_By_Location_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Inventory_Items_By_Location_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Inventory_Items_By_Location_Sum_Order_By>;
  var_pop?: InputMaybe<Inventory_Items_By_Location_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Inventory_Items_By_Location_Var_Samp_Order_By>;
  variance?: InputMaybe<Inventory_Items_By_Location_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory_items_by_location" */
export type Inventory_Items_By_Location_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Items_By_Location_Insert_Input>;
};

/** aggregate avg on columns */
export type Inventory_Items_By_Location_Avg_Fields = {
  __typename: 'inventory_items_by_location_avg_fields';
  availableMonetaryQuantity?: Maybe<Scalars['Float']>;
  availableStorageQuantity?: Maybe<Scalars['Float']>;
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  reservedStorageQuantity?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  totalMonetaryCostPrice?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "inventory_items_by_location" */
export type Inventory_Items_By_Location_Avg_Order_By = {
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory_items_by_location". All fields are combined with a logical 'AND'. */
export type Inventory_Items_By_Location_Bool_Exp = {
  LotNumber?: InputMaybe<Lot_Numbers_Bool_Exp>;
  _and?: InputMaybe<Array<Inventory_Items_By_Location_Bool_Exp>>;
  _not?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Inventory_Items_By_Location_Bool_Exp>>;
  availableMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  availableStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  costPricePerUnit?: InputMaybe<Bigint_Comparison_Exp>;
  costPriceUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  hash?: InputMaybe<Bigint_Comparison_Exp>;
  location?: InputMaybe<Stock_Locations_Bool_Exp>;
  locationId?: InputMaybe<Bigint_Comparison_Exp>;
  lotNumber?: InputMaybe<String_Comparison_Exp>;
  lotNumberId?: InputMaybe<Bigint_Comparison_Exp>;
  monetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  monetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  monetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  productStockLocation?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
  reservedMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  reservedStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  storageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  storageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  storageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  totalMonetaryCostPrice?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "inventory_items_by_location" */
export type Inventory_Items_By_Location_Inc_Input = {
  availableMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  availableStorageQuantity?: InputMaybe<Scalars['bigint']>;
  costPricePerUnit?: InputMaybe<Scalars['bigint']>;
  costPriceUnitId?: InputMaybe<Scalars['bigint']>;
  locationId?: InputMaybe<Scalars['bigint']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  monetaryQuantity?: InputMaybe<Scalars['bigint']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  reservedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  reservedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  storageQuantity?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
  totalMonetaryCostPrice?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "inventory_items_by_location" */
export type Inventory_Items_By_Location_Insert_Input = {
  LotNumber?: InputMaybe<Lot_Numbers_Obj_Rel_Insert_Input>;
  availableMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  availableStorageQuantity?: InputMaybe<Scalars['bigint']>;
  costPricePerUnit?: InputMaybe<Scalars['bigint']>;
  costPriceUnitId?: InputMaybe<Scalars['bigint']>;
  currency?: InputMaybe<Currencies_Enum>;
  location?: InputMaybe<Stock_Locations_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['bigint']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  monetaryQuantity?: InputMaybe<Scalars['bigint']>;
  monetaryUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  productStockLocation?: InputMaybe<Product_Stock_Locations_Obj_Rel_Insert_Input>;
  reservedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  reservedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  storageQuantity?: InputMaybe<Scalars['bigint']>;
  storageUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
  totalMonetaryCostPrice?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Inventory_Items_By_Location_Max_Fields = {
  __typename: 'inventory_items_by_location_max_fields';
  availableMonetaryQuantity?: Maybe<Scalars['bigint']>;
  availableStorageQuantity?: Maybe<Scalars['bigint']>;
  costPricePerUnit?: Maybe<Scalars['bigint']>;
  costPriceUnitId?: Maybe<Scalars['bigint']>;
  locationId?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  reservedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  reservedStorageQuantity?: Maybe<Scalars['bigint']>;
  storageQuantity?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  totalMonetaryCostPrice?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "inventory_items_by_location" */
export type Inventory_Items_By_Location_Max_Order_By = {
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Items_By_Location_Min_Fields = {
  __typename: 'inventory_items_by_location_min_fields';
  availableMonetaryQuantity?: Maybe<Scalars['bigint']>;
  availableStorageQuantity?: Maybe<Scalars['bigint']>;
  costPricePerUnit?: Maybe<Scalars['bigint']>;
  costPriceUnitId?: Maybe<Scalars['bigint']>;
  locationId?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  reservedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  reservedStorageQuantity?: Maybe<Scalars['bigint']>;
  storageQuantity?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  totalMonetaryCostPrice?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "inventory_items_by_location" */
export type Inventory_Items_By_Location_Min_Order_By = {
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "inventory_items_by_location" */
export type Inventory_Items_By_Location_Mutation_Response = {
  __typename: 'inventory_items_by_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Inventory_Items_By_Location>;
};

/** Ordering options when selecting data from "inventory_items_by_location". */
export type Inventory_Items_By_Location_Order_By = {
  LotNumber?: InputMaybe<Lot_Numbers_Order_By>;
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  location?: InputMaybe<Stock_Locations_Order_By>;
  locationId?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnit?: InputMaybe<Product_Units_Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  productStockLocation?: InputMaybe<Product_Stock_Locations_Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnit?: InputMaybe<Product_Units_Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

/** select columns of table "inventory_items_by_location" */
export enum Inventory_Items_By_Location_Select_Column {
  /** column name */
  AvailableMonetaryQuantity = 'availableMonetaryQuantity',
  /** column name */
  AvailableStorageQuantity = 'availableStorageQuantity',
  /** column name */
  CostPricePerUnit = 'costPricePerUnit',
  /** column name */
  CostPriceUnitId = 'costPriceUnitId',
  /** column name */
  Currency = 'currency',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  LotNumber = 'lotNumber',
  /** column name */
  LotNumberId = 'lotNumberId',
  /** column name */
  MonetaryQuantity = 'monetaryQuantity',
  /** column name */
  MonetaryUnitId = 'monetaryUnitId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ReservedMonetaryQuantity = 'reservedMonetaryQuantity',
  /** column name */
  ReservedStorageQuantity = 'reservedStorageQuantity',
  /** column name */
  StorageQuantity = 'storageQuantity',
  /** column name */
  StorageUnitId = 'storageUnitId',
  /** column name */
  TotalMonetaryCostPrice = 'totalMonetaryCostPrice'
}

/** input type for updating data in table "inventory_items_by_location" */
export type Inventory_Items_By_Location_Set_Input = {
  availableMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  availableStorageQuantity?: InputMaybe<Scalars['bigint']>;
  costPricePerUnit?: InputMaybe<Scalars['bigint']>;
  costPriceUnitId?: InputMaybe<Scalars['bigint']>;
  currency?: InputMaybe<Currencies_Enum>;
  locationId?: InputMaybe<Scalars['bigint']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  monetaryQuantity?: InputMaybe<Scalars['bigint']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  reservedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  reservedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  storageQuantity?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
  totalMonetaryCostPrice?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Inventory_Items_By_Location_Stddev_Fields = {
  __typename: 'inventory_items_by_location_stddev_fields';
  availableMonetaryQuantity?: Maybe<Scalars['Float']>;
  availableStorageQuantity?: Maybe<Scalars['Float']>;
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  reservedStorageQuantity?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  totalMonetaryCostPrice?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "inventory_items_by_location" */
export type Inventory_Items_By_Location_Stddev_Order_By = {
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Items_By_Location_Stddev_Pop_Fields = {
  __typename: 'inventory_items_by_location_stddev_pop_fields';
  availableMonetaryQuantity?: Maybe<Scalars['Float']>;
  availableStorageQuantity?: Maybe<Scalars['Float']>;
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  reservedStorageQuantity?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  totalMonetaryCostPrice?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "inventory_items_by_location" */
export type Inventory_Items_By_Location_Stddev_Pop_Order_By = {
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Items_By_Location_Stddev_Samp_Fields = {
  __typename: 'inventory_items_by_location_stddev_samp_fields';
  availableMonetaryQuantity?: Maybe<Scalars['Float']>;
  availableStorageQuantity?: Maybe<Scalars['Float']>;
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  reservedStorageQuantity?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  totalMonetaryCostPrice?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "inventory_items_by_location" */
export type Inventory_Items_By_Location_Stddev_Samp_Order_By = {
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "inventory_items_by_location" */
export type Inventory_Items_By_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inventory_Items_By_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inventory_Items_By_Location_Stream_Cursor_Value_Input = {
  availableMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  availableStorageQuantity?: InputMaybe<Scalars['bigint']>;
  costPricePerUnit?: InputMaybe<Scalars['bigint']>;
  costPriceUnitId?: InputMaybe<Scalars['bigint']>;
  currency?: InputMaybe<Currencies_Enum>;
  locationId?: InputMaybe<Scalars['bigint']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  monetaryQuantity?: InputMaybe<Scalars['bigint']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  reservedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  reservedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  storageQuantity?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
  totalMonetaryCostPrice?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Inventory_Items_By_Location_Sum_Fields = {
  __typename: 'inventory_items_by_location_sum_fields';
  availableMonetaryQuantity?: Maybe<Scalars['bigint']>;
  availableStorageQuantity?: Maybe<Scalars['bigint']>;
  costPricePerUnit?: Maybe<Scalars['bigint']>;
  costPriceUnitId?: Maybe<Scalars['bigint']>;
  locationId?: Maybe<Scalars['bigint']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  reservedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  reservedStorageQuantity?: Maybe<Scalars['bigint']>;
  storageQuantity?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  totalMonetaryCostPrice?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "inventory_items_by_location" */
export type Inventory_Items_By_Location_Sum_Order_By = {
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

export type Inventory_Items_By_Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Inventory_Items_By_Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Inventory_Items_By_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inventory_Items_By_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Inventory_Items_By_Location_Var_Pop_Fields = {
  __typename: 'inventory_items_by_location_var_pop_fields';
  availableMonetaryQuantity?: Maybe<Scalars['Float']>;
  availableStorageQuantity?: Maybe<Scalars['Float']>;
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  reservedStorageQuantity?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  totalMonetaryCostPrice?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "inventory_items_by_location" */
export type Inventory_Items_By_Location_Var_Pop_Order_By = {
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Items_By_Location_Var_Samp_Fields = {
  __typename: 'inventory_items_by_location_var_samp_fields';
  availableMonetaryQuantity?: Maybe<Scalars['Float']>;
  availableStorageQuantity?: Maybe<Scalars['Float']>;
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  reservedStorageQuantity?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  totalMonetaryCostPrice?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "inventory_items_by_location" */
export type Inventory_Items_By_Location_Var_Samp_Order_By = {
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Items_By_Location_Variance_Fields = {
  __typename: 'inventory_items_by_location_variance_fields';
  availableMonetaryQuantity?: Maybe<Scalars['Float']>;
  availableStorageQuantity?: Maybe<Scalars['Float']>;
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  reservedStorageQuantity?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  totalMonetaryCostPrice?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "inventory_items_by_location" */
export type Inventory_Items_By_Location_Variance_Order_By = {
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryCostPrice?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "inventory_items" */
export type Inventory_Items_Inc_Input = {
  accountId?: InputMaybe<Scalars['bigint']>;
  availableMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  availableStorageQuantity?: InputMaybe<Scalars['bigint']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  monetaryQuantity?: InputMaybe<Scalars['bigint']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  reservedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  reservedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  storageQuantity?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "inventory_items" */
export type Inventory_Items_Insert_Input = {
  LotNumber?: InputMaybe<Lot_Numbers_Obj_Rel_Insert_Input>;
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['bigint']>;
  availableMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  availableStorageQuantity?: InputMaybe<Scalars['bigint']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  monetaryQuantity?: InputMaybe<Scalars['bigint']>;
  monetaryUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  reservedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  reservedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  storageQuantity?: InputMaybe<Scalars['bigint']>;
  storageUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Inventory_Items_Max_Fields = {
  __typename: 'inventory_items_max_fields';
  accountId?: Maybe<Scalars['bigint']>;
  availableMonetaryQuantity?: Maybe<Scalars['bigint']>;
  availableStorageQuantity?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  reservedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  reservedStorageQuantity?: Maybe<Scalars['bigint']>;
  storageQuantity?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "inventory_items" */
export type Inventory_Items_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Items_Min_Fields = {
  __typename: 'inventory_items_min_fields';
  accountId?: Maybe<Scalars['bigint']>;
  availableMonetaryQuantity?: Maybe<Scalars['bigint']>;
  availableStorageQuantity?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  reservedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  reservedStorageQuantity?: Maybe<Scalars['bigint']>;
  storageQuantity?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "inventory_items" */
export type Inventory_Items_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "inventory_items" */
export type Inventory_Items_Mutation_Response = {
  __typename: 'inventory_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Inventory_Items>;
};

/** Ordering options when selecting data from "inventory_items". */
export type Inventory_Items_Order_By = {
  LotNumber?: InputMaybe<Lot_Numbers_Order_By>;
  account?: InputMaybe<Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnit?: InputMaybe<Product_Units_Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnit?: InputMaybe<Product_Units_Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** select columns of table "inventory_items" */
export enum Inventory_Items_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AvailableMonetaryQuantity = 'availableMonetaryQuantity',
  /** column name */
  AvailableStorageQuantity = 'availableStorageQuantity',
  /** column name */
  LotNumber = 'lotNumber',
  /** column name */
  LotNumberId = 'lotNumberId',
  /** column name */
  MonetaryQuantity = 'monetaryQuantity',
  /** column name */
  MonetaryUnitId = 'monetaryUnitId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ReservedMonetaryQuantity = 'reservedMonetaryQuantity',
  /** column name */
  ReservedStorageQuantity = 'reservedStorageQuantity',
  /** column name */
  StorageQuantity = 'storageQuantity',
  /** column name */
  StorageUnitId = 'storageUnitId'
}

/** input type for updating data in table "inventory_items" */
export type Inventory_Items_Set_Input = {
  accountId?: InputMaybe<Scalars['bigint']>;
  availableMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  availableStorageQuantity?: InputMaybe<Scalars['bigint']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  monetaryQuantity?: InputMaybe<Scalars['bigint']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  reservedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  reservedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  storageQuantity?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Inventory_Items_Stddev_Fields = {
  __typename: 'inventory_items_stddev_fields';
  accountId?: Maybe<Scalars['Float']>;
  availableMonetaryQuantity?: Maybe<Scalars['Float']>;
  availableStorageQuantity?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  reservedStorageQuantity?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "inventory_items" */
export type Inventory_Items_Stddev_Order_By = {
  accountId?: InputMaybe<Order_By>;
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Items_Stddev_Pop_Fields = {
  __typename: 'inventory_items_stddev_pop_fields';
  accountId?: Maybe<Scalars['Float']>;
  availableMonetaryQuantity?: Maybe<Scalars['Float']>;
  availableStorageQuantity?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  reservedStorageQuantity?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "inventory_items" */
export type Inventory_Items_Stddev_Pop_Order_By = {
  accountId?: InputMaybe<Order_By>;
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Items_Stddev_Samp_Fields = {
  __typename: 'inventory_items_stddev_samp_fields';
  accountId?: Maybe<Scalars['Float']>;
  availableMonetaryQuantity?: Maybe<Scalars['Float']>;
  availableStorageQuantity?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  reservedStorageQuantity?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "inventory_items" */
export type Inventory_Items_Stddev_Samp_Order_By = {
  accountId?: InputMaybe<Order_By>;
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "inventory_items" */
export type Inventory_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inventory_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inventory_Items_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['bigint']>;
  availableMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  availableStorageQuantity?: InputMaybe<Scalars['bigint']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  monetaryQuantity?: InputMaybe<Scalars['bigint']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  reservedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  reservedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  storageQuantity?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Inventory_Items_Sum_Fields = {
  __typename: 'inventory_items_sum_fields';
  accountId?: Maybe<Scalars['bigint']>;
  availableMonetaryQuantity?: Maybe<Scalars['bigint']>;
  availableStorageQuantity?: Maybe<Scalars['bigint']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  reservedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  reservedStorageQuantity?: Maybe<Scalars['bigint']>;
  storageQuantity?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "inventory_items" */
export type Inventory_Items_Sum_Order_By = {
  accountId?: InputMaybe<Order_By>;
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

export type Inventory_Items_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Inventory_Items_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Inventory_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inventory_Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Inventory_Items_Var_Pop_Fields = {
  __typename: 'inventory_items_var_pop_fields';
  accountId?: Maybe<Scalars['Float']>;
  availableMonetaryQuantity?: Maybe<Scalars['Float']>;
  availableStorageQuantity?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  reservedStorageQuantity?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "inventory_items" */
export type Inventory_Items_Var_Pop_Order_By = {
  accountId?: InputMaybe<Order_By>;
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Items_Var_Samp_Fields = {
  __typename: 'inventory_items_var_samp_fields';
  accountId?: Maybe<Scalars['Float']>;
  availableMonetaryQuantity?: Maybe<Scalars['Float']>;
  availableStorageQuantity?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  reservedStorageQuantity?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "inventory_items" */
export type Inventory_Items_Var_Samp_Order_By = {
  accountId?: InputMaybe<Order_By>;
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Items_Variance_Fields = {
  __typename: 'inventory_items_variance_fields';
  accountId?: Maybe<Scalars['Float']>;
  availableMonetaryQuantity?: Maybe<Scalars['Float']>;
  availableStorageQuantity?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  reservedMonetaryQuantity?: Maybe<Scalars['Float']>;
  reservedStorageQuantity?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "inventory_items" */
export type Inventory_Items_Variance_Order_By = {
  accountId?: InputMaybe<Order_By>;
  availableMonetaryQuantity?: InputMaybe<Order_By>;
  availableStorageQuantity?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  reservedMonetaryQuantity?: InputMaybe<Order_By>;
  reservedStorageQuantity?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

export type IsPromoted_Customer_Order_Items_Args = {
  _as_of?: InputMaybe<Scalars['date']>;
  _customer_id?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "journal_entries" */
export type Journal_Entries = {
  __typename: 'journal_entries';
  /** An object relationship */
  Tenant: Tenants;
  createdAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  customerOrderContainerReturns: Array<Customer_Order_Container_Returns>;
  /** An aggregate relationship */
  customerOrderContainerReturns_aggregate: Customer_Order_Container_Returns_Aggregate;
  /** An array relationship */
  customerOrders: Array<Customer_Orders>;
  /** An aggregate relationship */
  customerOrders_aggregate: Customer_Orders_Aggregate;
  data?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  dismantlingPlans: Array<Dismantling_Plans>;
  /** An aggregate relationship */
  dismantlingPlans_aggregate: Dismantling_Plans_Aggregate;
  id: Scalars['bigint'];
  /** An array relationship */
  pickedItems: Array<Picked_Items>;
  /** An aggregate relationship */
  pickedItems_aggregate: Picked_Items_Aggregate;
  /** An array relationship */
  postings: Array<Postings>;
  /** An aggregate relationship */
  postings_aggregate: Postings_Aggregate;
  /** An array relationship */
  rollingInventories: Array<Rolling_Inventories>;
  /** An aggregate relationship */
  rollingInventories_aggregate: Rolling_Inventories_Aggregate;
  /** An array relationship */
  stockTransactions: Array<Stock_Transactions>;
  /** An aggregate relationship */
  stockTransactions_aggregate: Stock_Transactions_Aggregate;
  stockTx: Stock_Transaction_Names_Enum;
  /** An array relationship */
  supplierOrderGoodsIncomeItems: Array<Supplier_Order_Goods_Income_Items>;
  /** An aggregate relationship */
  supplierOrderGoodsIncomeItems_aggregate: Supplier_Order_Goods_Income_Items_Aggregate;
  /** An array relationship */
  supplierOrderReturnedContainers: Array<Supplier_Order_Returned_Containers>;
  /** An aggregate relationship */
  supplierOrderReturnedContainers_aggregate: Supplier_Order_Returned_Containers_Aggregate;
  /** An array relationship */
  supplierOrderReturns: Array<Supplier_Order_Returns>;
  /** An aggregate relationship */
  supplierOrderReturns_aggregate: Supplier_Order_Returns_Aggregate;
  tenant: Scalars['String'];
  validTime: Scalars['timestamp'];
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesCustomerOrderContainerReturnsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Returns_Bool_Exp>;
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesCustomerOrderContainerReturns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Returns_Bool_Exp>;
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesCustomerOrdersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesCustomerOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesDismantlingPlansArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plans_Order_By>>;
  where?: InputMaybe<Dismantling_Plans_Bool_Exp>;
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesDismantlingPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plans_Order_By>>;
  where?: InputMaybe<Dismantling_Plans_Bool_Exp>;
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesPickedItemsArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesPickedItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesPostingsArgs = {
  distinct_on?: InputMaybe<Array<Postings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postings_Order_By>>;
  where?: InputMaybe<Postings_Bool_Exp>;
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesPostings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Postings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postings_Order_By>>;
  where?: InputMaybe<Postings_Bool_Exp>;
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesRollingInventoriesArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventories_Order_By>>;
  where?: InputMaybe<Rolling_Inventories_Bool_Exp>;
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesRollingInventories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventories_Order_By>>;
  where?: InputMaybe<Rolling_Inventories_Bool_Exp>;
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesStockTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesStockTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesSupplierOrderGoodsIncomeItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesSupplierOrderGoodsIncomeItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesSupplierOrderReturnedContainersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returned_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returned_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returned_Containers_Bool_Exp>;
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesSupplierOrderReturnedContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returned_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returned_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returned_Containers_Bool_Exp>;
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesSupplierOrderReturnsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returns_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
};


/** columns and relationships of "journal_entries" */
export type Journal_EntriesSupplierOrderReturns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returns_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
};

/** aggregated selection of "journal_entries" */
export type Journal_Entries_Aggregate = {
  __typename: 'journal_entries_aggregate';
  aggregate?: Maybe<Journal_Entries_Aggregate_Fields>;
  nodes: Array<Journal_Entries>;
};

export type Journal_Entries_Aggregate_Bool_Exp = {
  count?: InputMaybe<Journal_Entries_Aggregate_Bool_Exp_Count>;
};

export type Journal_Entries_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Journal_Entries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Journal_Entries_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "journal_entries" */
export type Journal_Entries_Aggregate_Fields = {
  __typename: 'journal_entries_aggregate_fields';
  avg?: Maybe<Journal_Entries_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Journal_Entries_Max_Fields>;
  min?: Maybe<Journal_Entries_Min_Fields>;
  stddev?: Maybe<Journal_Entries_Stddev_Fields>;
  stddev_pop?: Maybe<Journal_Entries_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Journal_Entries_Stddev_Samp_Fields>;
  sum?: Maybe<Journal_Entries_Sum_Fields>;
  var_pop?: Maybe<Journal_Entries_Var_Pop_Fields>;
  var_samp?: Maybe<Journal_Entries_Var_Samp_Fields>;
  variance?: Maybe<Journal_Entries_Variance_Fields>;
};


/** aggregate fields of "journal_entries" */
export type Journal_Entries_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Journal_Entries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "journal_entries" */
export type Journal_Entries_Aggregate_Order_By = {
  avg?: InputMaybe<Journal_Entries_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Journal_Entries_Max_Order_By>;
  min?: InputMaybe<Journal_Entries_Min_Order_By>;
  stddev?: InputMaybe<Journal_Entries_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Journal_Entries_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Journal_Entries_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Journal_Entries_Sum_Order_By>;
  var_pop?: InputMaybe<Journal_Entries_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Journal_Entries_Var_Samp_Order_By>;
  variance?: InputMaybe<Journal_Entries_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Journal_Entries_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Journal_Entries_Avg_Fields = {
  __typename: 'journal_entries_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "journal_entries" */
export type Journal_Entries_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "journal_entries". All fields are combined with a logical 'AND'. */
export type Journal_Entries_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Journal_Entries_Bool_Exp>>;
  _not?: InputMaybe<Journal_Entries_Bool_Exp>;
  _or?: InputMaybe<Array<Journal_Entries_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerOrderContainerReturns?: InputMaybe<Customer_Order_Container_Returns_Bool_Exp>;
  customerOrderContainerReturns_aggregate?: InputMaybe<Customer_Order_Container_Returns_Aggregate_Bool_Exp>;
  customerOrders?: InputMaybe<Customer_Orders_Bool_Exp>;
  customerOrders_aggregate?: InputMaybe<Customer_Orders_Aggregate_Bool_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  dismantlingPlans?: InputMaybe<Dismantling_Plans_Bool_Exp>;
  dismantlingPlans_aggregate?: InputMaybe<Dismantling_Plans_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  pickedItems?: InputMaybe<Picked_Items_Bool_Exp>;
  pickedItems_aggregate?: InputMaybe<Picked_Items_Aggregate_Bool_Exp>;
  postings?: InputMaybe<Postings_Bool_Exp>;
  postings_aggregate?: InputMaybe<Postings_Aggregate_Bool_Exp>;
  rollingInventories?: InputMaybe<Rolling_Inventories_Bool_Exp>;
  rollingInventories_aggregate?: InputMaybe<Rolling_Inventories_Aggregate_Bool_Exp>;
  stockTransactions?: InputMaybe<Stock_Transactions_Bool_Exp>;
  stockTransactions_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Bool_Exp>;
  stockTx?: InputMaybe<Stock_Transaction_Names_Enum_Comparison_Exp>;
  supplierOrderGoodsIncomeItems?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
  supplierOrderGoodsIncomeItems_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Bool_Exp>;
  supplierOrderReturnedContainers?: InputMaybe<Supplier_Order_Returned_Containers_Bool_Exp>;
  supplierOrderReturnedContainers_aggregate?: InputMaybe<Supplier_Order_Returned_Containers_Aggregate_Bool_Exp>;
  supplierOrderReturns?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
  supplierOrderReturns_aggregate?: InputMaybe<Supplier_Order_Returns_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  validTime?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "journal_entries" */
export enum Journal_Entries_Constraint {
  /** unique or primary key constraint on columns "id" */
  JournalEntriesPkey = 'journal_entries_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Journal_Entries_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Journal_Entries_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Journal_Entries_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "journal_entries" */
export type Journal_Entries_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "journal_entries" */
export type Journal_Entries_Insert_Input = {
  customerOrderContainerReturns?: InputMaybe<Customer_Order_Container_Returns_Arr_Rel_Insert_Input>;
  customerOrders?: InputMaybe<Customer_Orders_Arr_Rel_Insert_Input>;
  data?: InputMaybe<Scalars['jsonb']>;
  dismantlingPlans?: InputMaybe<Dismantling_Plans_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  pickedItems?: InputMaybe<Picked_Items_Arr_Rel_Insert_Input>;
  rollingInventories?: InputMaybe<Rolling_Inventories_Arr_Rel_Insert_Input>;
  stockTransactions?: InputMaybe<Stock_Transactions_Arr_Rel_Insert_Input>;
  stockTx?: InputMaybe<Stock_Transaction_Names_Enum>;
  supplierOrderGoodsIncomeItems?: InputMaybe<Supplier_Order_Goods_Income_Items_Arr_Rel_Insert_Input>;
  supplierOrderReturnedContainers?: InputMaybe<Supplier_Order_Returned_Containers_Arr_Rel_Insert_Input>;
  supplierOrderReturns?: InputMaybe<Supplier_Order_Returns_Arr_Rel_Insert_Input>;
  validTime?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Journal_Entries_Max_Fields = {
  __typename: 'journal_entries_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  validTime?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "journal_entries" */
export type Journal_Entries_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  validTime?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Journal_Entries_Min_Fields = {
  __typename: 'journal_entries_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  validTime?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "journal_entries" */
export type Journal_Entries_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  validTime?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "journal_entries" */
export type Journal_Entries_Mutation_Response = {
  __typename: 'journal_entries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Journal_Entries>;
};

/** input type for inserting object relation for remote table "journal_entries" */
export type Journal_Entries_Obj_Rel_Insert_Input = {
  data: Journal_Entries_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Journal_Entries_On_Conflict>;
};

/** on_conflict condition type for table "journal_entries" */
export type Journal_Entries_On_Conflict = {
  constraint: Journal_Entries_Constraint;
  update_columns?: Array<Journal_Entries_Update_Column>;
  where?: InputMaybe<Journal_Entries_Bool_Exp>;
};

/** Ordering options when selecting data from "journal_entries". */
export type Journal_Entries_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrderContainerReturns_aggregate?: InputMaybe<Customer_Order_Container_Returns_Aggregate_Order_By>;
  customerOrders_aggregate?: InputMaybe<Customer_Orders_Aggregate_Order_By>;
  data?: InputMaybe<Order_By>;
  dismantlingPlans_aggregate?: InputMaybe<Dismantling_Plans_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  pickedItems_aggregate?: InputMaybe<Picked_Items_Aggregate_Order_By>;
  postings_aggregate?: InputMaybe<Postings_Aggregate_Order_By>;
  rollingInventories_aggregate?: InputMaybe<Rolling_Inventories_Aggregate_Order_By>;
  stockTransactions_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Order_By>;
  stockTx?: InputMaybe<Order_By>;
  supplierOrderGoodsIncomeItems_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Order_By>;
  supplierOrderReturnedContainers_aggregate?: InputMaybe<Supplier_Order_Returned_Containers_Aggregate_Order_By>;
  supplierOrderReturns_aggregate?: InputMaybe<Supplier_Order_Returns_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
  validTime?: InputMaybe<Order_By>;
};

/** primary key columns input for table: journal_entries */
export type Journal_Entries_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Journal_Entries_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "journal_entries" */
export enum Journal_Entries_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  StockTx = 'stockTx',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  ValidTime = 'validTime'
}

/** input type for updating data in table "journal_entries" */
export type Journal_Entries_Set_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  stockTx?: InputMaybe<Stock_Transaction_Names_Enum>;
  validTime?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Journal_Entries_Stddev_Fields = {
  __typename: 'journal_entries_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "journal_entries" */
export type Journal_Entries_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Journal_Entries_Stddev_Pop_Fields = {
  __typename: 'journal_entries_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "journal_entries" */
export type Journal_Entries_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Journal_Entries_Stddev_Samp_Fields = {
  __typename: 'journal_entries_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "journal_entries" */
export type Journal_Entries_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "journal_entries" */
export type Journal_Entries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Journal_Entries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Journal_Entries_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  stockTx?: InputMaybe<Stock_Transaction_Names_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
  validTime?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Journal_Entries_Sum_Fields = {
  __typename: 'journal_entries_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "journal_entries" */
export type Journal_Entries_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "journal_entries" */
export enum Journal_Entries_Update_Column {
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  StockTx = 'stockTx',
  /** column name */
  ValidTime = 'validTime'
}

export type Journal_Entries_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Journal_Entries_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Journal_Entries_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Journal_Entries_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Journal_Entries_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Journal_Entries_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Journal_Entries_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Journal_Entries_Set_Input>;
  /** filter the rows which have to be updated */
  where: Journal_Entries_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Journal_Entries_Var_Pop_Fields = {
  __typename: 'journal_entries_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "journal_entries" */
export type Journal_Entries_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Journal_Entries_Var_Samp_Fields = {
  __typename: 'journal_entries_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "journal_entries" */
export type Journal_Entries_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Journal_Entries_Variance_Fields = {
  __typename: 'journal_entries_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "journal_entries" */
export type Journal_Entries_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum Jscript_Print_Methods_Enum {
  DirectThermal = 'DIRECT_THERMAL',
  ThermalTransfer = 'THERMAL_TRANSFER'
}

/** Boolean expression to compare columns of type "jscript_print_methods_enum". All fields are combined with logical 'AND'. */
export type Jscript_Print_Methods_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Jscript_Print_Methods_Enum>;
  _in?: InputMaybe<Array<Jscript_Print_Methods_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Jscript_Print_Methods_Enum>;
  _nin?: InputMaybe<Array<Jscript_Print_Methods_Enum>>;
};

/** columns and relationships of "jscript_template_names" */
export type Jscript_Template_Names = {
  __typename: 'jscript_template_names';
  /** An array relationship */
  jscriptTemplates: Array<Jscript_Templates>;
  /** An aggregate relationship */
  jscriptTemplates_aggregate: Jscript_Templates_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "jscript_template_names" */
export type Jscript_Template_NamesJscriptTemplatesArgs = {
  distinct_on?: InputMaybe<Array<Jscript_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jscript_Templates_Order_By>>;
  where?: InputMaybe<Jscript_Templates_Bool_Exp>;
};


/** columns and relationships of "jscript_template_names" */
export type Jscript_Template_NamesJscriptTemplates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jscript_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jscript_Templates_Order_By>>;
  where?: InputMaybe<Jscript_Templates_Bool_Exp>;
};

/** aggregated selection of "jscript_template_names" */
export type Jscript_Template_Names_Aggregate = {
  __typename: 'jscript_template_names_aggregate';
  aggregate?: Maybe<Jscript_Template_Names_Aggregate_Fields>;
  nodes: Array<Jscript_Template_Names>;
};

/** aggregate fields of "jscript_template_names" */
export type Jscript_Template_Names_Aggregate_Fields = {
  __typename: 'jscript_template_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Jscript_Template_Names_Max_Fields>;
  min?: Maybe<Jscript_Template_Names_Min_Fields>;
};


/** aggregate fields of "jscript_template_names" */
export type Jscript_Template_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Jscript_Template_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "jscript_template_names". All fields are combined with a logical 'AND'. */
export type Jscript_Template_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Jscript_Template_Names_Bool_Exp>>;
  _not?: InputMaybe<Jscript_Template_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Jscript_Template_Names_Bool_Exp>>;
  jscriptTemplates?: InputMaybe<Jscript_Templates_Bool_Exp>;
  jscriptTemplates_aggregate?: InputMaybe<Jscript_Templates_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Jscript_Template_Names_Enum {
  AddressLabel = 'ADDRESS_LABEL',
  CattleGoodsIncome = 'CATTLE_GOODS_INCOME',
  DismantlingHookInhouse = 'DISMANTLING_HOOK_INHOUSE',
  DismantlingStickerInhouse = 'DISMANTLING_STICKER_INHOUSE',
  HangingGoodsLabel = 'HANGING_GOODS_LABEL',
  ProductLabel = 'PRODUCT_LABEL',
  RollingInventoryItem = 'ROLLING_INVENTORY_ITEM',
  StockContainerLabel = 'STOCK_CONTAINER_LABEL',
  Test = 'TEST'
}

/** Boolean expression to compare columns of type "jscript_template_names_enum". All fields are combined with logical 'AND'. */
export type Jscript_Template_Names_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Jscript_Template_Names_Enum>;
  _in?: InputMaybe<Array<Jscript_Template_Names_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Jscript_Template_Names_Enum>;
  _nin?: InputMaybe<Array<Jscript_Template_Names_Enum>>;
};

/** aggregate max on columns */
export type Jscript_Template_Names_Max_Fields = {
  __typename: 'jscript_template_names_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Jscript_Template_Names_Min_Fields = {
  __typename: 'jscript_template_names_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "jscript_template_names". */
export type Jscript_Template_Names_Order_By = {
  jscriptTemplates_aggregate?: InputMaybe<Jscript_Templates_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "jscript_template_names" */
export enum Jscript_Template_Names_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "jscript_template_names" */
export type Jscript_Template_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Jscript_Template_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Jscript_Template_Names_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "jscript_templates" */
export type Jscript_Templates = {
  __typename: 'jscript_templates';
  /** An object relationship */
  Tenant: Tenants;
  appModule: App_Modules_Enum;
  description?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  name: Jscript_Template_Names_Enum;
  printMethod: Jscript_Print_Methods_Enum;
  /** An array relationship */
  productLabelSettings: Array<Product_Label_Settings>;
  /** An aggregate relationship */
  productLabelSettings_aggregate: Product_Label_Settings_Aggregate;
  schema: Scalars['jsonb'];
  template: Scalars['String'];
  tenant: Scalars['String'];
};


/** columns and relationships of "jscript_templates" */
export type Jscript_TemplatesProductLabelSettingsArgs = {
  distinct_on?: InputMaybe<Array<Product_Label_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Label_Settings_Order_By>>;
  where?: InputMaybe<Product_Label_Settings_Bool_Exp>;
};


/** columns and relationships of "jscript_templates" */
export type Jscript_TemplatesProductLabelSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Label_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Label_Settings_Order_By>>;
  where?: InputMaybe<Product_Label_Settings_Bool_Exp>;
};


/** columns and relationships of "jscript_templates" */
export type Jscript_TemplatesSchemaArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "jscript_templates" */
export type Jscript_Templates_Aggregate = {
  __typename: 'jscript_templates_aggregate';
  aggregate?: Maybe<Jscript_Templates_Aggregate_Fields>;
  nodes: Array<Jscript_Templates>;
};

export type Jscript_Templates_Aggregate_Bool_Exp = {
  count?: InputMaybe<Jscript_Templates_Aggregate_Bool_Exp_Count>;
};

export type Jscript_Templates_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Jscript_Templates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Jscript_Templates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "jscript_templates" */
export type Jscript_Templates_Aggregate_Fields = {
  __typename: 'jscript_templates_aggregate_fields';
  avg?: Maybe<Jscript_Templates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Jscript_Templates_Max_Fields>;
  min?: Maybe<Jscript_Templates_Min_Fields>;
  stddev?: Maybe<Jscript_Templates_Stddev_Fields>;
  stddev_pop?: Maybe<Jscript_Templates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Jscript_Templates_Stddev_Samp_Fields>;
  sum?: Maybe<Jscript_Templates_Sum_Fields>;
  var_pop?: Maybe<Jscript_Templates_Var_Pop_Fields>;
  var_samp?: Maybe<Jscript_Templates_Var_Samp_Fields>;
  variance?: Maybe<Jscript_Templates_Variance_Fields>;
};


/** aggregate fields of "jscript_templates" */
export type Jscript_Templates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Jscript_Templates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "jscript_templates" */
export type Jscript_Templates_Aggregate_Order_By = {
  avg?: InputMaybe<Jscript_Templates_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Jscript_Templates_Max_Order_By>;
  min?: InputMaybe<Jscript_Templates_Min_Order_By>;
  stddev?: InputMaybe<Jscript_Templates_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Jscript_Templates_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Jscript_Templates_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Jscript_Templates_Sum_Order_By>;
  var_pop?: InputMaybe<Jscript_Templates_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Jscript_Templates_Var_Samp_Order_By>;
  variance?: InputMaybe<Jscript_Templates_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Jscript_Templates_Append_Input = {
  schema?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Jscript_Templates_Avg_Fields = {
  __typename: 'jscript_templates_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "jscript_templates" */
export type Jscript_Templates_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "jscript_templates". All fields are combined with a logical 'AND'. */
export type Jscript_Templates_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Jscript_Templates_Bool_Exp>>;
  _not?: InputMaybe<Jscript_Templates_Bool_Exp>;
  _or?: InputMaybe<Array<Jscript_Templates_Bool_Exp>>;
  appModule?: InputMaybe<App_Modules_Enum_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<Jscript_Template_Names_Enum_Comparison_Exp>;
  printMethod?: InputMaybe<Jscript_Print_Methods_Enum_Comparison_Exp>;
  productLabelSettings?: InputMaybe<Product_Label_Settings_Bool_Exp>;
  productLabelSettings_aggregate?: InputMaybe<Product_Label_Settings_Aggregate_Bool_Exp>;
  schema?: InputMaybe<Jsonb_Comparison_Exp>;
  template?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "jscript_templates" */
export enum Jscript_Templates_Constraint {
  /** unique or primary key constraint on columns "id" */
  JscriptTemplatesPkey = 'jscript_templates_pkey',
  /** unique or primary key constraint on columns "name", "tenant" */
  JscriptTemplatesTenantNameUniq = 'jscript_templates_tenant_name_uniq'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Jscript_Templates_Delete_At_Path_Input = {
  schema?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Jscript_Templates_Delete_Elem_Input = {
  schema?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Jscript_Templates_Delete_Key_Input = {
  schema?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "jscript_templates" */
export type Jscript_Templates_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "jscript_templates" */
export type Jscript_Templates_Insert_Input = {
  appModule?: InputMaybe<App_Modules_Enum>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Jscript_Template_Names_Enum>;
  printMethod?: InputMaybe<Jscript_Print_Methods_Enum>;
  productLabelSettings?: InputMaybe<Product_Label_Settings_Arr_Rel_Insert_Input>;
  schema?: InputMaybe<Scalars['jsonb']>;
  template?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "jscript_templates_logged_actions" */
export type Jscript_Templates_Logged_Actions = {
  __typename: 'jscript_templates_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  eventId?: Maybe<Scalars['bigint']>;
  role?: Maybe<Scalars['String']>;
  rowData?: Maybe<Scalars['jsonb']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "jscript_templates_logged_actions" */
export type Jscript_Templates_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "jscript_templates_logged_actions" */
export type Jscript_Templates_Logged_ActionsRowDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "jscript_templates_logged_actions" */
export type Jscript_Templates_Logged_Actions_Aggregate = {
  __typename: 'jscript_templates_logged_actions_aggregate';
  aggregate?: Maybe<Jscript_Templates_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Jscript_Templates_Logged_Actions>;
};

/** aggregate fields of "jscript_templates_logged_actions" */
export type Jscript_Templates_Logged_Actions_Aggregate_Fields = {
  __typename: 'jscript_templates_logged_actions_aggregate_fields';
  avg?: Maybe<Jscript_Templates_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Jscript_Templates_Logged_Actions_Max_Fields>;
  min?: Maybe<Jscript_Templates_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Jscript_Templates_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Jscript_Templates_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Jscript_Templates_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Jscript_Templates_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Jscript_Templates_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Jscript_Templates_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Jscript_Templates_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "jscript_templates_logged_actions" */
export type Jscript_Templates_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Jscript_Templates_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Jscript_Templates_Logged_Actions_Avg_Fields = {
  __typename: 'jscript_templates_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  eventId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "jscript_templates_logged_actions". All fields are combined with a logical 'AND'. */
export type Jscript_Templates_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Jscript_Templates_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Jscript_Templates_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Jscript_Templates_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  eventId?: InputMaybe<Bigint_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  rowData?: InputMaybe<Jsonb_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Jscript_Templates_Logged_Actions_Max_Fields = {
  __typename: 'jscript_templates_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  eventId?: Maybe<Scalars['bigint']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Jscript_Templates_Logged_Actions_Min_Fields = {
  __typename: 'jscript_templates_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  eventId?: Maybe<Scalars['bigint']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "jscript_templates_logged_actions". */
export type Jscript_Templates_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  eventId?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  rowData?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "jscript_templates_logged_actions" */
export enum Jscript_Templates_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  EventId = 'eventId',
  /** column name */
  Role = 'role',
  /** column name */
  RowData = 'rowData',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Jscript_Templates_Logged_Actions_Stddev_Fields = {
  __typename: 'jscript_templates_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  eventId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Jscript_Templates_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'jscript_templates_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  eventId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Jscript_Templates_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'jscript_templates_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  eventId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "jscript_templates_logged_actions" */
export type Jscript_Templates_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Jscript_Templates_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Jscript_Templates_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  eventId?: InputMaybe<Scalars['bigint']>;
  role?: InputMaybe<Scalars['String']>;
  rowData?: InputMaybe<Scalars['jsonb']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Jscript_Templates_Logged_Actions_Sum_Fields = {
  __typename: 'jscript_templates_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  eventId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Jscript_Templates_Logged_Actions_Var_Pop_Fields = {
  __typename: 'jscript_templates_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  eventId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Jscript_Templates_Logged_Actions_Var_Samp_Fields = {
  __typename: 'jscript_templates_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  eventId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Jscript_Templates_Logged_Actions_Variance_Fields = {
  __typename: 'jscript_templates_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  eventId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Jscript_Templates_Max_Fields = {
  __typename: 'jscript_templates_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  template?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "jscript_templates" */
export type Jscript_Templates_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Jscript_Templates_Min_Fields = {
  __typename: 'jscript_templates_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  template?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "jscript_templates" */
export type Jscript_Templates_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "jscript_templates" */
export type Jscript_Templates_Mutation_Response = {
  __typename: 'jscript_templates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Jscript_Templates>;
};

/** input type for inserting object relation for remote table "jscript_templates" */
export type Jscript_Templates_Obj_Rel_Insert_Input = {
  data: Jscript_Templates_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Jscript_Templates_On_Conflict>;
};

/** on_conflict condition type for table "jscript_templates" */
export type Jscript_Templates_On_Conflict = {
  constraint: Jscript_Templates_Constraint;
  update_columns?: Array<Jscript_Templates_Update_Column>;
  where?: InputMaybe<Jscript_Templates_Bool_Exp>;
};

/** Ordering options when selecting data from "jscript_templates". */
export type Jscript_Templates_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  appModule?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  printMethod?: InputMaybe<Order_By>;
  productLabelSettings_aggregate?: InputMaybe<Product_Label_Settings_Aggregate_Order_By>;
  schema?: InputMaybe<Order_By>;
  template?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** primary key columns input for table: jscript_templates */
export type Jscript_Templates_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Jscript_Templates_Prepend_Input = {
  schema?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "jscript_templates" */
export enum Jscript_Templates_Select_Column {
  /** column name */
  AppModule = 'appModule',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrintMethod = 'printMethod',
  /** column name */
  Schema = 'schema',
  /** column name */
  Template = 'template',
  /** column name */
  Tenant = 'tenant'
}

/** input type for updating data in table "jscript_templates" */
export type Jscript_Templates_Set_Input = {
  appModule?: InputMaybe<App_Modules_Enum>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Jscript_Template_Names_Enum>;
  printMethod?: InputMaybe<Jscript_Print_Methods_Enum>;
  schema?: InputMaybe<Scalars['jsonb']>;
  template?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Jscript_Templates_Stddev_Fields = {
  __typename: 'jscript_templates_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "jscript_templates" */
export type Jscript_Templates_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Jscript_Templates_Stddev_Pop_Fields = {
  __typename: 'jscript_templates_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "jscript_templates" */
export type Jscript_Templates_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Jscript_Templates_Stddev_Samp_Fields = {
  __typename: 'jscript_templates_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "jscript_templates" */
export type Jscript_Templates_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "jscript_templates" */
export type Jscript_Templates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Jscript_Templates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Jscript_Templates_Stream_Cursor_Value_Input = {
  appModule?: InputMaybe<App_Modules_Enum>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Jscript_Template_Names_Enum>;
  printMethod?: InputMaybe<Jscript_Print_Methods_Enum>;
  schema?: InputMaybe<Scalars['jsonb']>;
  template?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Jscript_Templates_Sum_Fields = {
  __typename: 'jscript_templates_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "jscript_templates" */
export type Jscript_Templates_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "jscript_templates" */
export enum Jscript_Templates_Update_Column {
  /** column name */
  AppModule = 'appModule',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrintMethod = 'printMethod',
  /** column name */
  Schema = 'schema',
  /** column name */
  Template = 'template'
}

export type Jscript_Templates_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Jscript_Templates_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Jscript_Templates_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Jscript_Templates_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Jscript_Templates_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Jscript_Templates_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Jscript_Templates_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Jscript_Templates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Jscript_Templates_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Jscript_Templates_Var_Pop_Fields = {
  __typename: 'jscript_templates_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "jscript_templates" */
export type Jscript_Templates_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Jscript_Templates_Var_Samp_Fields = {
  __typename: 'jscript_templates_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "jscript_templates" */
export type Jscript_Templates_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Jscript_Templates_Variance_Fields = {
  __typename: 'jscript_templates_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "jscript_templates" */
export type Jscript_Templates_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "languages" */
export type Languages = {
  __typename: 'languages';
  /** An array relationship */
  customers: Array<Customers>;
  /** An aggregate relationship */
  customers_aggregate: Customers_Aggregate;
  /** An array relationship */
  suppliers: Array<Suppliers>;
  /** An aggregate relationship */
  suppliers_aggregate: Suppliers_Aggregate;
  /** An array relationship */
  tenantLanguages: Array<Tenant_Languages>;
  /** An aggregate relationship */
  tenantLanguages_aggregate: Tenant_Languages_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "languages" */
export type LanguagesCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "languages" */
export type LanguagesCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "languages" */
export type LanguagesSuppliersArgs = {
  distinct_on?: InputMaybe<Array<Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Suppliers_Order_By>>;
  where?: InputMaybe<Suppliers_Bool_Exp>;
};


/** columns and relationships of "languages" */
export type LanguagesSuppliers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Suppliers_Order_By>>;
  where?: InputMaybe<Suppliers_Bool_Exp>;
};


/** columns and relationships of "languages" */
export type LanguagesTenantLanguagesArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Languages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Languages_Order_By>>;
  where?: InputMaybe<Tenant_Languages_Bool_Exp>;
};


/** columns and relationships of "languages" */
export type LanguagesTenantLanguages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Languages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Languages_Order_By>>;
  where?: InputMaybe<Tenant_Languages_Bool_Exp>;
};

/** aggregated selection of "languages" */
export type Languages_Aggregate = {
  __typename: 'languages_aggregate';
  aggregate?: Maybe<Languages_Aggregate_Fields>;
  nodes: Array<Languages>;
};

/** aggregate fields of "languages" */
export type Languages_Aggregate_Fields = {
  __typename: 'languages_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Languages_Max_Fields>;
  min?: Maybe<Languages_Min_Fields>;
};


/** aggregate fields of "languages" */
export type Languages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Languages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "languages". All fields are combined with a logical 'AND'. */
export type Languages_Bool_Exp = {
  _and?: InputMaybe<Array<Languages_Bool_Exp>>;
  _not?: InputMaybe<Languages_Bool_Exp>;
  _or?: InputMaybe<Array<Languages_Bool_Exp>>;
  customers?: InputMaybe<Customers_Bool_Exp>;
  customers_aggregate?: InputMaybe<Customers_Aggregate_Bool_Exp>;
  suppliers?: InputMaybe<Suppliers_Bool_Exp>;
  suppliers_aggregate?: InputMaybe<Suppliers_Aggregate_Bool_Exp>;
  tenantLanguages?: InputMaybe<Tenant_Languages_Bool_Exp>;
  tenantLanguages_aggregate?: InputMaybe<Tenant_Languages_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Languages_Enum {
  DeCh = 'de_CH',
  En = 'en',
  FrCh = 'fr_CH',
  ItCh = 'it_CH'
}

/** Boolean expression to compare columns of type "languages_enum". All fields are combined with logical 'AND'. */
export type Languages_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Languages_Enum>;
  _in?: InputMaybe<Array<Languages_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Languages_Enum>;
  _nin?: InputMaybe<Array<Languages_Enum>>;
};

/** aggregate max on columns */
export type Languages_Max_Fields = {
  __typename: 'languages_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Languages_Min_Fields = {
  __typename: 'languages_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "languages". */
export type Languages_Order_By = {
  customers_aggregate?: InputMaybe<Customers_Aggregate_Order_By>;
  suppliers_aggregate?: InputMaybe<Suppliers_Aggregate_Order_By>;
  tenantLanguages_aggregate?: InputMaybe<Tenant_Languages_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "languages" */
export enum Languages_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "languages" */
export type Languages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Languages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Languages_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "leichmehl_template_types" */
export type Leichmehl_Template_Types = {
  __typename: 'leichmehl_template_types';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An array relationship */
  leichmehlTemplates: Array<Leichmehl_Templates>;
  /** An aggregate relationship */
  leichmehlTemplates_aggregate: Leichmehl_Templates_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  productPackagingConfigurations: Array<Product_Packaging_Configurations>;
  /** An aggregate relationship */
  productPackagingConfigurations_aggregate: Product_Packaging_Configurations_Aggregate;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "leichmehl_template_types" */
export type Leichmehl_Template_TypesLeichmehlTemplatesArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Templates_Order_By>>;
  where?: InputMaybe<Leichmehl_Templates_Bool_Exp>;
};


/** columns and relationships of "leichmehl_template_types" */
export type Leichmehl_Template_TypesLeichmehlTemplates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Templates_Order_By>>;
  where?: InputMaybe<Leichmehl_Templates_Bool_Exp>;
};


/** columns and relationships of "leichmehl_template_types" */
export type Leichmehl_Template_TypesProductPackagingConfigurationsArgs = {
  distinct_on?: InputMaybe<Array<Product_Packaging_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Packaging_Configurations_Order_By>>;
  where?: InputMaybe<Product_Packaging_Configurations_Bool_Exp>;
};


/** columns and relationships of "leichmehl_template_types" */
export type Leichmehl_Template_TypesProductPackagingConfigurations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Packaging_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Packaging_Configurations_Order_By>>;
  where?: InputMaybe<Product_Packaging_Configurations_Bool_Exp>;
};

/** aggregated selection of "leichmehl_template_types" */
export type Leichmehl_Template_Types_Aggregate = {
  __typename: 'leichmehl_template_types_aggregate';
  aggregate?: Maybe<Leichmehl_Template_Types_Aggregate_Fields>;
  nodes: Array<Leichmehl_Template_Types>;
};

export type Leichmehl_Template_Types_Aggregate_Bool_Exp = {
  count?: InputMaybe<Leichmehl_Template_Types_Aggregate_Bool_Exp_Count>;
};

export type Leichmehl_Template_Types_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Leichmehl_Template_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Leichmehl_Template_Types_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "leichmehl_template_types" */
export type Leichmehl_Template_Types_Aggregate_Fields = {
  __typename: 'leichmehl_template_types_aggregate_fields';
  avg?: Maybe<Leichmehl_Template_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Leichmehl_Template_Types_Max_Fields>;
  min?: Maybe<Leichmehl_Template_Types_Min_Fields>;
  stddev?: Maybe<Leichmehl_Template_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Leichmehl_Template_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Leichmehl_Template_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Leichmehl_Template_Types_Sum_Fields>;
  var_pop?: Maybe<Leichmehl_Template_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Leichmehl_Template_Types_Var_Samp_Fields>;
  variance?: Maybe<Leichmehl_Template_Types_Variance_Fields>;
};


/** aggregate fields of "leichmehl_template_types" */
export type Leichmehl_Template_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Leichmehl_Template_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "leichmehl_template_types" */
export type Leichmehl_Template_Types_Aggregate_Order_By = {
  avg?: InputMaybe<Leichmehl_Template_Types_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Leichmehl_Template_Types_Max_Order_By>;
  min?: InputMaybe<Leichmehl_Template_Types_Min_Order_By>;
  stddev?: InputMaybe<Leichmehl_Template_Types_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Leichmehl_Template_Types_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Leichmehl_Template_Types_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Leichmehl_Template_Types_Sum_Order_By>;
  var_pop?: InputMaybe<Leichmehl_Template_Types_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Leichmehl_Template_Types_Var_Samp_Order_By>;
  variance?: InputMaybe<Leichmehl_Template_Types_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Leichmehl_Template_Types_Avg_Fields = {
  __typename: 'leichmehl_template_types_avg_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "leichmehl_template_types" */
export type Leichmehl_Template_Types_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "leichmehl_template_types". All fields are combined with a logical 'AND'. */
export type Leichmehl_Template_Types_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Leichmehl_Template_Types_Bool_Exp>>;
  _not?: InputMaybe<Leichmehl_Template_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Leichmehl_Template_Types_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  leichmehlTemplates?: InputMaybe<Leichmehl_Templates_Bool_Exp>;
  leichmehlTemplates_aggregate?: InputMaybe<Leichmehl_Templates_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  productPackagingConfigurations?: InputMaybe<Product_Packaging_Configurations_Bool_Exp>;
  productPackagingConfigurations_aggregate?: InputMaybe<Product_Packaging_Configurations_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** columns and relationships of "leichmehl_template_types_logged_actions" */
export type Leichmehl_Template_Types_Logged_Actions = {
  __typename: 'leichmehl_template_types_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  relid?: Maybe<Scalars['oid']>;
  role?: Maybe<Scalars['String']>;
  rowData?: Maybe<Scalars['jsonb']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "leichmehl_template_types_logged_actions" */
export type Leichmehl_Template_Types_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "leichmehl_template_types_logged_actions" */
export type Leichmehl_Template_Types_Logged_ActionsRowDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "leichmehl_template_types_logged_actions" */
export type Leichmehl_Template_Types_Logged_Actions_Aggregate = {
  __typename: 'leichmehl_template_types_logged_actions_aggregate';
  aggregate?: Maybe<Leichmehl_Template_Types_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Leichmehl_Template_Types_Logged_Actions>;
};

/** aggregate fields of "leichmehl_template_types_logged_actions" */
export type Leichmehl_Template_Types_Logged_Actions_Aggregate_Fields = {
  __typename: 'leichmehl_template_types_logged_actions_aggregate_fields';
  avg?: Maybe<Leichmehl_Template_Types_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Leichmehl_Template_Types_Logged_Actions_Max_Fields>;
  min?: Maybe<Leichmehl_Template_Types_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Leichmehl_Template_Types_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Leichmehl_Template_Types_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Leichmehl_Template_Types_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Leichmehl_Template_Types_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Leichmehl_Template_Types_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Leichmehl_Template_Types_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Leichmehl_Template_Types_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "leichmehl_template_types_logged_actions" */
export type Leichmehl_Template_Types_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Leichmehl_Template_Types_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Leichmehl_Template_Types_Logged_Actions_Avg_Fields = {
  __typename: 'leichmehl_template_types_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "leichmehl_template_types_logged_actions". All fields are combined with a logical 'AND'. */
export type Leichmehl_Template_Types_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Leichmehl_Template_Types_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Leichmehl_Template_Types_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Leichmehl_Template_Types_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  relid?: InputMaybe<Oid_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  rowData?: InputMaybe<Jsonb_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Leichmehl_Template_Types_Logged_Actions_Max_Fields = {
  __typename: 'leichmehl_template_types_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Leichmehl_Template_Types_Logged_Actions_Min_Fields = {
  __typename: 'leichmehl_template_types_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "leichmehl_template_types_logged_actions". */
export type Leichmehl_Template_Types_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  relid?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  rowData?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "leichmehl_template_types_logged_actions" */
export enum Leichmehl_Template_Types_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Relid = 'relid',
  /** column name */
  Role = 'role',
  /** column name */
  RowData = 'rowData',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Leichmehl_Template_Types_Logged_Actions_Stddev_Fields = {
  __typename: 'leichmehl_template_types_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Leichmehl_Template_Types_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'leichmehl_template_types_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Leichmehl_Template_Types_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'leichmehl_template_types_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "leichmehl_template_types_logged_actions" */
export type Leichmehl_Template_Types_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Leichmehl_Template_Types_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Leichmehl_Template_Types_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  relid?: InputMaybe<Scalars['oid']>;
  role?: InputMaybe<Scalars['String']>;
  rowData?: InputMaybe<Scalars['jsonb']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Leichmehl_Template_Types_Logged_Actions_Sum_Fields = {
  __typename: 'leichmehl_template_types_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Leichmehl_Template_Types_Logged_Actions_Var_Pop_Fields = {
  __typename: 'leichmehl_template_types_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Leichmehl_Template_Types_Logged_Actions_Var_Samp_Fields = {
  __typename: 'leichmehl_template_types_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Leichmehl_Template_Types_Logged_Actions_Variance_Fields = {
  __typename: 'leichmehl_template_types_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Leichmehl_Template_Types_Max_Fields = {
  __typename: 'leichmehl_template_types_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "leichmehl_template_types" */
export type Leichmehl_Template_Types_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Leichmehl_Template_Types_Min_Fields = {
  __typename: 'leichmehl_template_types_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "leichmehl_template_types" */
export type Leichmehl_Template_Types_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "leichmehl_template_types". */
export type Leichmehl_Template_Types_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leichmehlTemplates_aggregate?: InputMaybe<Leichmehl_Templates_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  productPackagingConfigurations_aggregate?: InputMaybe<Product_Packaging_Configurations_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** select columns of table "leichmehl_template_types" */
export enum Leichmehl_Template_Types_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** aggregate stddev on columns */
export type Leichmehl_Template_Types_Stddev_Fields = {
  __typename: 'leichmehl_template_types_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "leichmehl_template_types" */
export type Leichmehl_Template_Types_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Leichmehl_Template_Types_Stddev_Pop_Fields = {
  __typename: 'leichmehl_template_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "leichmehl_template_types" */
export type Leichmehl_Template_Types_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Leichmehl_Template_Types_Stddev_Samp_Fields = {
  __typename: 'leichmehl_template_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "leichmehl_template_types" */
export type Leichmehl_Template_Types_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "leichmehl_template_types" */
export type Leichmehl_Template_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Leichmehl_Template_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Leichmehl_Template_Types_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Leichmehl_Template_Types_Sum_Fields = {
  __typename: 'leichmehl_template_types_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "leichmehl_template_types" */
export type Leichmehl_Template_Types_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Leichmehl_Template_Types_Var_Pop_Fields = {
  __typename: 'leichmehl_template_types_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "leichmehl_template_types" */
export type Leichmehl_Template_Types_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Leichmehl_Template_Types_Var_Samp_Fields = {
  __typename: 'leichmehl_template_types_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "leichmehl_template_types" */
export type Leichmehl_Template_Types_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Leichmehl_Template_Types_Variance_Fields = {
  __typename: 'leichmehl_template_types_variance_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "leichmehl_template_types" */
export type Leichmehl_Template_Types_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "leichmehl_templates" */
export type Leichmehl_Templates = {
  __typename: 'leichmehl_templates';
  /** An object relationship */
  Tenant: Tenants;
  counters: Scalars['jsonb'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  device: Hardware_Devices;
  deviceId: Scalars['bigint'];
  id: Scalars['bigint'];
  metadata: Scalars['jsonb'];
  pluGroup: Scalars['String'];
  pluName: Scalars['String'];
  printObjects: Scalars['jsonb'];
  templateData: Scalars['jsonb'];
  tenant: Scalars['String'];
  /** An object relationship */
  type?: Maybe<Leichmehl_Template_Types>;
  typeId?: Maybe<Scalars['bigint']>;
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "leichmehl_templates" */
export type Leichmehl_TemplatesCountersArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "leichmehl_templates" */
export type Leichmehl_TemplatesMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "leichmehl_templates" */
export type Leichmehl_TemplatesPrintObjectsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "leichmehl_templates" */
export type Leichmehl_TemplatesTemplateDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "leichmehl_templates" */
export type Leichmehl_Templates_Aggregate = {
  __typename: 'leichmehl_templates_aggregate';
  aggregate?: Maybe<Leichmehl_Templates_Aggregate_Fields>;
  nodes: Array<Leichmehl_Templates>;
};

export type Leichmehl_Templates_Aggregate_Bool_Exp = {
  count?: InputMaybe<Leichmehl_Templates_Aggregate_Bool_Exp_Count>;
};

export type Leichmehl_Templates_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Leichmehl_Templates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Leichmehl_Templates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "leichmehl_templates" */
export type Leichmehl_Templates_Aggregate_Fields = {
  __typename: 'leichmehl_templates_aggregate_fields';
  avg?: Maybe<Leichmehl_Templates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Leichmehl_Templates_Max_Fields>;
  min?: Maybe<Leichmehl_Templates_Min_Fields>;
  stddev?: Maybe<Leichmehl_Templates_Stddev_Fields>;
  stddev_pop?: Maybe<Leichmehl_Templates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Leichmehl_Templates_Stddev_Samp_Fields>;
  sum?: Maybe<Leichmehl_Templates_Sum_Fields>;
  var_pop?: Maybe<Leichmehl_Templates_Var_Pop_Fields>;
  var_samp?: Maybe<Leichmehl_Templates_Var_Samp_Fields>;
  variance?: Maybe<Leichmehl_Templates_Variance_Fields>;
};


/** aggregate fields of "leichmehl_templates" */
export type Leichmehl_Templates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Leichmehl_Templates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "leichmehl_templates" */
export type Leichmehl_Templates_Aggregate_Order_By = {
  avg?: InputMaybe<Leichmehl_Templates_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Leichmehl_Templates_Max_Order_By>;
  min?: InputMaybe<Leichmehl_Templates_Min_Order_By>;
  stddev?: InputMaybe<Leichmehl_Templates_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Leichmehl_Templates_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Leichmehl_Templates_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Leichmehl_Templates_Sum_Order_By>;
  var_pop?: InputMaybe<Leichmehl_Templates_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Leichmehl_Templates_Var_Samp_Order_By>;
  variance?: InputMaybe<Leichmehl_Templates_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Leichmehl_Templates_Avg_Fields = {
  __typename: 'leichmehl_templates_avg_fields';
  deviceId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  typeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "leichmehl_templates" */
export type Leichmehl_Templates_Avg_Order_By = {
  deviceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  typeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "leichmehl_templates". All fields are combined with a logical 'AND'. */
export type Leichmehl_Templates_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Leichmehl_Templates_Bool_Exp>>;
  _not?: InputMaybe<Leichmehl_Templates_Bool_Exp>;
  _or?: InputMaybe<Array<Leichmehl_Templates_Bool_Exp>>;
  counters?: InputMaybe<Jsonb_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  device?: InputMaybe<Hardware_Devices_Bool_Exp>;
  deviceId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  pluGroup?: InputMaybe<String_Comparison_Exp>;
  pluName?: InputMaybe<String_Comparison_Exp>;
  printObjects?: InputMaybe<Jsonb_Comparison_Exp>;
  templateData?: InputMaybe<Jsonb_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Leichmehl_Template_Types_Bool_Exp>;
  typeId?: InputMaybe<Bigint_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** columns and relationships of "leichmehl_templates_logged_actions" */
export type Leichmehl_Templates_Logged_Actions = {
  __typename: 'leichmehl_templates_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  relid?: Maybe<Scalars['oid']>;
  role?: Maybe<Scalars['String']>;
  rowData?: Maybe<Scalars['jsonb']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "leichmehl_templates_logged_actions" */
export type Leichmehl_Templates_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "leichmehl_templates_logged_actions" */
export type Leichmehl_Templates_Logged_ActionsRowDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "leichmehl_templates_logged_actions" */
export type Leichmehl_Templates_Logged_Actions_Aggregate = {
  __typename: 'leichmehl_templates_logged_actions_aggregate';
  aggregate?: Maybe<Leichmehl_Templates_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Leichmehl_Templates_Logged_Actions>;
};

/** aggregate fields of "leichmehl_templates_logged_actions" */
export type Leichmehl_Templates_Logged_Actions_Aggregate_Fields = {
  __typename: 'leichmehl_templates_logged_actions_aggregate_fields';
  avg?: Maybe<Leichmehl_Templates_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Leichmehl_Templates_Logged_Actions_Max_Fields>;
  min?: Maybe<Leichmehl_Templates_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Leichmehl_Templates_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Leichmehl_Templates_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Leichmehl_Templates_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Leichmehl_Templates_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Leichmehl_Templates_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Leichmehl_Templates_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Leichmehl_Templates_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "leichmehl_templates_logged_actions" */
export type Leichmehl_Templates_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Leichmehl_Templates_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Leichmehl_Templates_Logged_Actions_Avg_Fields = {
  __typename: 'leichmehl_templates_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "leichmehl_templates_logged_actions". All fields are combined with a logical 'AND'. */
export type Leichmehl_Templates_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Leichmehl_Templates_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Leichmehl_Templates_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Leichmehl_Templates_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  relid?: InputMaybe<Oid_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  rowData?: InputMaybe<Jsonb_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Leichmehl_Templates_Logged_Actions_Max_Fields = {
  __typename: 'leichmehl_templates_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Leichmehl_Templates_Logged_Actions_Min_Fields = {
  __typename: 'leichmehl_templates_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "leichmehl_templates_logged_actions". */
export type Leichmehl_Templates_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  relid?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  rowData?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "leichmehl_templates_logged_actions" */
export enum Leichmehl_Templates_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Relid = 'relid',
  /** column name */
  Role = 'role',
  /** column name */
  RowData = 'rowData',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Leichmehl_Templates_Logged_Actions_Stddev_Fields = {
  __typename: 'leichmehl_templates_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Leichmehl_Templates_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'leichmehl_templates_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Leichmehl_Templates_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'leichmehl_templates_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "leichmehl_templates_logged_actions" */
export type Leichmehl_Templates_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Leichmehl_Templates_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Leichmehl_Templates_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  relid?: InputMaybe<Scalars['oid']>;
  role?: InputMaybe<Scalars['String']>;
  rowData?: InputMaybe<Scalars['jsonb']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Leichmehl_Templates_Logged_Actions_Sum_Fields = {
  __typename: 'leichmehl_templates_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Leichmehl_Templates_Logged_Actions_Var_Pop_Fields = {
  __typename: 'leichmehl_templates_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Leichmehl_Templates_Logged_Actions_Var_Samp_Fields = {
  __typename: 'leichmehl_templates_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Leichmehl_Templates_Logged_Actions_Variance_Fields = {
  __typename: 'leichmehl_templates_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Leichmehl_Templates_Max_Fields = {
  __typename: 'leichmehl_templates_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deviceId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  pluGroup?: Maybe<Scalars['String']>;
  pluName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "leichmehl_templates" */
export type Leichmehl_Templates_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deviceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pluGroup?: InputMaybe<Order_By>;
  pluName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  typeId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Leichmehl_Templates_Min_Fields = {
  __typename: 'leichmehl_templates_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deviceId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  pluGroup?: Maybe<Scalars['String']>;
  pluName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "leichmehl_templates" */
export type Leichmehl_Templates_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deviceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pluGroup?: InputMaybe<Order_By>;
  pluName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  typeId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "leichmehl_templates". */
export type Leichmehl_Templates_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  counters?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  device?: InputMaybe<Hardware_Devices_Order_By>;
  deviceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  pluGroup?: InputMaybe<Order_By>;
  pluName?: InputMaybe<Order_By>;
  printObjects?: InputMaybe<Order_By>;
  templateData?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  type?: InputMaybe<Leichmehl_Template_Types_Order_By>;
  typeId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** select columns of table "leichmehl_templates" */
export enum Leichmehl_Templates_Select_Column {
  /** column name */
  Counters = 'counters',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeviceId = 'deviceId',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PluGroup = 'pluGroup',
  /** column name */
  PluName = 'pluName',
  /** column name */
  PrintObjects = 'printObjects',
  /** column name */
  TemplateData = 'templateData',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  TypeId = 'typeId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** aggregate stddev on columns */
export type Leichmehl_Templates_Stddev_Fields = {
  __typename: 'leichmehl_templates_stddev_fields';
  deviceId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  typeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "leichmehl_templates" */
export type Leichmehl_Templates_Stddev_Order_By = {
  deviceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  typeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Leichmehl_Templates_Stddev_Pop_Fields = {
  __typename: 'leichmehl_templates_stddev_pop_fields';
  deviceId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  typeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "leichmehl_templates" */
export type Leichmehl_Templates_Stddev_Pop_Order_By = {
  deviceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  typeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Leichmehl_Templates_Stddev_Samp_Fields = {
  __typename: 'leichmehl_templates_stddev_samp_fields';
  deviceId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  typeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "leichmehl_templates" */
export type Leichmehl_Templates_Stddev_Samp_Order_By = {
  deviceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  typeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "leichmehl_templates" */
export type Leichmehl_Templates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Leichmehl_Templates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Leichmehl_Templates_Stream_Cursor_Value_Input = {
  counters?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deviceId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  pluGroup?: InputMaybe<Scalars['String']>;
  pluName?: InputMaybe<Scalars['String']>;
  printObjects?: InputMaybe<Scalars['jsonb']>;
  templateData?: InputMaybe<Scalars['jsonb']>;
  tenant?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Leichmehl_Templates_Sum_Fields = {
  __typename: 'leichmehl_templates_sum_fields';
  deviceId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  typeId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "leichmehl_templates" */
export type Leichmehl_Templates_Sum_Order_By = {
  deviceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  typeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Leichmehl_Templates_Var_Pop_Fields = {
  __typename: 'leichmehl_templates_var_pop_fields';
  deviceId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  typeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "leichmehl_templates" */
export type Leichmehl_Templates_Var_Pop_Order_By = {
  deviceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  typeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Leichmehl_Templates_Var_Samp_Fields = {
  __typename: 'leichmehl_templates_var_samp_fields';
  deviceId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  typeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "leichmehl_templates" */
export type Leichmehl_Templates_Var_Samp_Order_By = {
  deviceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  typeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Leichmehl_Templates_Variance_Fields = {
  __typename: 'leichmehl_templates_variance_fields';
  deviceId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  typeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "leichmehl_templates" */
export type Leichmehl_Templates_Variance_Order_By = {
  deviceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  typeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

export type List_Container_Inventory_By_Customer_Args = {
  _customer_id?: InputMaybe<Scalars['bigint']>;
};

export type List_Inventory_Args = {
  on_date?: InputMaybe<Scalars['timestamptz']>;
  with_lots?: InputMaybe<Scalars['Boolean']>;
  with_psl?: InputMaybe<Scalars['Boolean']>;
  with_reservations?: InputMaybe<Scalars['Boolean']>;
};

export type List_Inventory_By_Location_Args = {
  on_date?: InputMaybe<Scalars['timestamptz']>;
  with_cost_price?: InputMaybe<Scalars['Boolean']>;
  with_lots?: InputMaybe<Scalars['Boolean']>;
  with_psl?: InputMaybe<Scalars['Boolean']>;
  with_reservations?: InputMaybe<Scalars['Boolean']>;
};

export type List_Inventory_By_Location_With_Product_Search_Args = {
  lang?: InputMaybe<Scalars['String']>;
  on_date?: InputMaybe<Scalars['timestamptz']>;
  search?: InputMaybe<Scalars['String']>;
  with_cost_price?: InputMaybe<Scalars['Boolean']>;
  with_lots?: InputMaybe<Scalars['Boolean']>;
  with_psl?: InputMaybe<Scalars['Boolean']>;
  with_reservations?: InputMaybe<Scalars['Boolean']>;
};

export type List_Ordered_Customer_Order_Containers_Args = {
  _customer_order_id?: InputMaybe<Scalars['bigint']>;
  _filter_nulls?: InputMaybe<Scalars['Boolean']>;
  on_date?: InputMaybe<Scalars['timestamptz']>;
  with_lots?: InputMaybe<Scalars['Boolean']>;
  with_psl?: InputMaybe<Scalars['Boolean']>;
  with_reservations?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "list_purchased_products_result" */
export type List_Purchased_Products_Result = {
  __typename: 'list_purchased_products_result';
  /** An object relationship */
  firstProductGroup?: Maybe<Product_Groups>;
  firstProductGroupId?: Maybe<Scalars['bigint']>;
  firstProductGroupName?: Maybe<Scalars['String']>;
  /** An object relationship */
  monetaryUnit: Product_Units;
  monetaryUnitId: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  /** An object relationship */
  storageUnit: Product_Units;
  storageUnitId: Scalars['bigint'];
  totalMonetaryQuantity: Scalars['bigint'];
  totalStorageQuantity: Scalars['bigint'];
};

/** aggregated selection of "list_purchased_products_result" */
export type List_Purchased_Products_Result_Aggregate = {
  __typename: 'list_purchased_products_result_aggregate';
  aggregate?: Maybe<List_Purchased_Products_Result_Aggregate_Fields>;
  nodes: Array<List_Purchased_Products_Result>;
};

export type List_Purchased_Products_Result_Aggregate_Bool_Exp = {
  count?: InputMaybe<List_Purchased_Products_Result_Aggregate_Bool_Exp_Count>;
};

export type List_Purchased_Products_Result_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<List_Purchased_Products_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "list_purchased_products_result" */
export type List_Purchased_Products_Result_Aggregate_Fields = {
  __typename: 'list_purchased_products_result_aggregate_fields';
  avg?: Maybe<List_Purchased_Products_Result_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<List_Purchased_Products_Result_Max_Fields>;
  min?: Maybe<List_Purchased_Products_Result_Min_Fields>;
  stddev?: Maybe<List_Purchased_Products_Result_Stddev_Fields>;
  stddev_pop?: Maybe<List_Purchased_Products_Result_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<List_Purchased_Products_Result_Stddev_Samp_Fields>;
  sum?: Maybe<List_Purchased_Products_Result_Sum_Fields>;
  var_pop?: Maybe<List_Purchased_Products_Result_Var_Pop_Fields>;
  var_samp?: Maybe<List_Purchased_Products_Result_Var_Samp_Fields>;
  variance?: Maybe<List_Purchased_Products_Result_Variance_Fields>;
};


/** aggregate fields of "list_purchased_products_result" */
export type List_Purchased_Products_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<List_Purchased_Products_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "list_purchased_products_result" */
export type List_Purchased_Products_Result_Aggregate_Order_By = {
  avg?: InputMaybe<List_Purchased_Products_Result_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<List_Purchased_Products_Result_Max_Order_By>;
  min?: InputMaybe<List_Purchased_Products_Result_Min_Order_By>;
  stddev?: InputMaybe<List_Purchased_Products_Result_Stddev_Order_By>;
  stddev_pop?: InputMaybe<List_Purchased_Products_Result_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<List_Purchased_Products_Result_Stddev_Samp_Order_By>;
  sum?: InputMaybe<List_Purchased_Products_Result_Sum_Order_By>;
  var_pop?: InputMaybe<List_Purchased_Products_Result_Var_Pop_Order_By>;
  var_samp?: InputMaybe<List_Purchased_Products_Result_Var_Samp_Order_By>;
  variance?: InputMaybe<List_Purchased_Products_Result_Variance_Order_By>;
};

/** aggregate avg on columns */
export type List_Purchased_Products_Result_Avg_Fields = {
  __typename: 'list_purchased_products_result_avg_fields';
  firstProductGroupId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  totalMonetaryQuantity?: Maybe<Scalars['Float']>;
  totalStorageQuantity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "list_purchased_products_result" */
export type List_Purchased_Products_Result_Avg_Order_By = {
  firstProductGroupId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryQuantity?: InputMaybe<Order_By>;
  totalStorageQuantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "list_purchased_products_result". All fields are combined with a logical 'AND'. */
export type List_Purchased_Products_Result_Bool_Exp = {
  _and?: InputMaybe<Array<List_Purchased_Products_Result_Bool_Exp>>;
  _not?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
  _or?: InputMaybe<Array<List_Purchased_Products_Result_Bool_Exp>>;
  firstProductGroup?: InputMaybe<Product_Groups_Bool_Exp>;
  firstProductGroupId?: InputMaybe<Bigint_Comparison_Exp>;
  firstProductGroupName?: InputMaybe<String_Comparison_Exp>;
  monetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  monetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  storageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  storageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  totalMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  totalStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type List_Purchased_Products_Result_Max_Fields = {
  __typename: 'list_purchased_products_result_max_fields';
  firstProductGroupId?: Maybe<Scalars['bigint']>;
  firstProductGroupName?: Maybe<Scalars['String']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  totalMonetaryQuantity?: Maybe<Scalars['bigint']>;
  totalStorageQuantity?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "list_purchased_products_result" */
export type List_Purchased_Products_Result_Max_Order_By = {
  firstProductGroupId?: InputMaybe<Order_By>;
  firstProductGroupName?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryQuantity?: InputMaybe<Order_By>;
  totalStorageQuantity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type List_Purchased_Products_Result_Min_Fields = {
  __typename: 'list_purchased_products_result_min_fields';
  firstProductGroupId?: Maybe<Scalars['bigint']>;
  firstProductGroupName?: Maybe<Scalars['String']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  totalMonetaryQuantity?: Maybe<Scalars['bigint']>;
  totalStorageQuantity?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "list_purchased_products_result" */
export type List_Purchased_Products_Result_Min_Order_By = {
  firstProductGroupId?: InputMaybe<Order_By>;
  firstProductGroupName?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryQuantity?: InputMaybe<Order_By>;
  totalStorageQuantity?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "list_purchased_products_result". */
export type List_Purchased_Products_Result_Order_By = {
  firstProductGroup?: InputMaybe<Product_Groups_Order_By>;
  firstProductGroupId?: InputMaybe<Order_By>;
  firstProductGroupName?: InputMaybe<Order_By>;
  monetaryUnit?: InputMaybe<Product_Units_Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnit?: InputMaybe<Product_Units_Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryQuantity?: InputMaybe<Order_By>;
  totalStorageQuantity?: InputMaybe<Order_By>;
};

/** select columns of table "list_purchased_products_result" */
export enum List_Purchased_Products_Result_Select_Column {
  /** column name */
  FirstProductGroupId = 'firstProductGroupId',
  /** column name */
  FirstProductGroupName = 'firstProductGroupName',
  /** column name */
  MonetaryUnitId = 'monetaryUnitId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  StorageUnitId = 'storageUnitId',
  /** column name */
  TotalMonetaryQuantity = 'totalMonetaryQuantity',
  /** column name */
  TotalStorageQuantity = 'totalStorageQuantity'
}

/** aggregate stddev on columns */
export type List_Purchased_Products_Result_Stddev_Fields = {
  __typename: 'list_purchased_products_result_stddev_fields';
  firstProductGroupId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  totalMonetaryQuantity?: Maybe<Scalars['Float']>;
  totalStorageQuantity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "list_purchased_products_result" */
export type List_Purchased_Products_Result_Stddev_Order_By = {
  firstProductGroupId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryQuantity?: InputMaybe<Order_By>;
  totalStorageQuantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type List_Purchased_Products_Result_Stddev_Pop_Fields = {
  __typename: 'list_purchased_products_result_stddev_pop_fields';
  firstProductGroupId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  totalMonetaryQuantity?: Maybe<Scalars['Float']>;
  totalStorageQuantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "list_purchased_products_result" */
export type List_Purchased_Products_Result_Stddev_Pop_Order_By = {
  firstProductGroupId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryQuantity?: InputMaybe<Order_By>;
  totalStorageQuantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type List_Purchased_Products_Result_Stddev_Samp_Fields = {
  __typename: 'list_purchased_products_result_stddev_samp_fields';
  firstProductGroupId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  totalMonetaryQuantity?: Maybe<Scalars['Float']>;
  totalStorageQuantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "list_purchased_products_result" */
export type List_Purchased_Products_Result_Stddev_Samp_Order_By = {
  firstProductGroupId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryQuantity?: InputMaybe<Order_By>;
  totalStorageQuantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "list_purchased_products_result" */
export type List_Purchased_Products_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: List_Purchased_Products_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type List_Purchased_Products_Result_Stream_Cursor_Value_Input = {
  firstProductGroupId?: InputMaybe<Scalars['bigint']>;
  firstProductGroupName?: InputMaybe<Scalars['String']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
  totalMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  totalStorageQuantity?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type List_Purchased_Products_Result_Sum_Fields = {
  __typename: 'list_purchased_products_result_sum_fields';
  firstProductGroupId?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  totalMonetaryQuantity?: Maybe<Scalars['bigint']>;
  totalStorageQuantity?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "list_purchased_products_result" */
export type List_Purchased_Products_Result_Sum_Order_By = {
  firstProductGroupId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryQuantity?: InputMaybe<Order_By>;
  totalStorageQuantity?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type List_Purchased_Products_Result_Var_Pop_Fields = {
  __typename: 'list_purchased_products_result_var_pop_fields';
  firstProductGroupId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  totalMonetaryQuantity?: Maybe<Scalars['Float']>;
  totalStorageQuantity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "list_purchased_products_result" */
export type List_Purchased_Products_Result_Var_Pop_Order_By = {
  firstProductGroupId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryQuantity?: InputMaybe<Order_By>;
  totalStorageQuantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type List_Purchased_Products_Result_Var_Samp_Fields = {
  __typename: 'list_purchased_products_result_var_samp_fields';
  firstProductGroupId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  totalMonetaryQuantity?: Maybe<Scalars['Float']>;
  totalStorageQuantity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "list_purchased_products_result" */
export type List_Purchased_Products_Result_Var_Samp_Order_By = {
  firstProductGroupId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryQuantity?: InputMaybe<Order_By>;
  totalStorageQuantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type List_Purchased_Products_Result_Variance_Fields = {
  __typename: 'list_purchased_products_result_variance_fields';
  firstProductGroupId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  totalMonetaryQuantity?: Maybe<Scalars['Float']>;
  totalStorageQuantity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "list_purchased_products_result" */
export type List_Purchased_Products_Result_Variance_Order_By = {
  firstProductGroupId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  totalMonetaryQuantity?: InputMaybe<Order_By>;
  totalStorageQuantity?: InputMaybe<Order_By>;
};

export type List_Purchased_Products_With_Inventory_Args = {
  lang?: InputMaybe<Scalars['String']>;
  on_date?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "lot_number_products" */
export type Lot_Number_Products = {
  __typename: 'lot_number_products';
  /** An object relationship */
  LotNumber: Lot_Numbers;
  /** An object relationship */
  Tenant: Tenants;
  costPricePerUnit: Scalars['bigint'];
  /** An object relationship */
  costPriceUnit: Product_Units;
  costPriceUnitId: Scalars['bigint'];
  createdAt: Scalars['timestamptz'];
  currency: Currencies_Enum;
  id: Scalars['bigint'];
  lotNumberId: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  tenant: Scalars['String'];
  version: Scalars['bigint'];
};

/** aggregated selection of "lot_number_products" */
export type Lot_Number_Products_Aggregate = {
  __typename: 'lot_number_products_aggregate';
  aggregate?: Maybe<Lot_Number_Products_Aggregate_Fields>;
  nodes: Array<Lot_Number_Products>;
};

export type Lot_Number_Products_Aggregate_Bool_Exp = {
  count?: InputMaybe<Lot_Number_Products_Aggregate_Bool_Exp_Count>;
};

export type Lot_Number_Products_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lot_Number_Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lot_Number_Products_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lot_number_products" */
export type Lot_Number_Products_Aggregate_Fields = {
  __typename: 'lot_number_products_aggregate_fields';
  avg?: Maybe<Lot_Number_Products_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lot_Number_Products_Max_Fields>;
  min?: Maybe<Lot_Number_Products_Min_Fields>;
  stddev?: Maybe<Lot_Number_Products_Stddev_Fields>;
  stddev_pop?: Maybe<Lot_Number_Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lot_Number_Products_Stddev_Samp_Fields>;
  sum?: Maybe<Lot_Number_Products_Sum_Fields>;
  var_pop?: Maybe<Lot_Number_Products_Var_Pop_Fields>;
  var_samp?: Maybe<Lot_Number_Products_Var_Samp_Fields>;
  variance?: Maybe<Lot_Number_Products_Variance_Fields>;
};


/** aggregate fields of "lot_number_products" */
export type Lot_Number_Products_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lot_Number_Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lot_number_products" */
export type Lot_Number_Products_Aggregate_Order_By = {
  avg?: InputMaybe<Lot_Number_Products_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lot_Number_Products_Max_Order_By>;
  min?: InputMaybe<Lot_Number_Products_Min_Order_By>;
  stddev?: InputMaybe<Lot_Number_Products_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lot_Number_Products_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lot_Number_Products_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lot_Number_Products_Sum_Order_By>;
  var_pop?: InputMaybe<Lot_Number_Products_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lot_Number_Products_Var_Samp_Order_By>;
  variance?: InputMaybe<Lot_Number_Products_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lot_number_products" */
export type Lot_Number_Products_Arr_Rel_Insert_Input = {
  data: Array<Lot_Number_Products_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lot_Number_Products_On_Conflict>;
};

/** aggregate avg on columns */
export type Lot_Number_Products_Avg_Fields = {
  __typename: 'lot_number_products_avg_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lot_number_products" */
export type Lot_Number_Products_Avg_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lot_number_products". All fields are combined with a logical 'AND'. */
export type Lot_Number_Products_Bool_Exp = {
  LotNumber?: InputMaybe<Lot_Numbers_Bool_Exp>;
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Lot_Number_Products_Bool_Exp>>;
  _not?: InputMaybe<Lot_Number_Products_Bool_Exp>;
  _or?: InputMaybe<Array<Lot_Number_Products_Bool_Exp>>;
  costPricePerUnit?: InputMaybe<Bigint_Comparison_Exp>;
  costPriceUnit?: InputMaybe<Product_Units_Bool_Exp>;
  costPriceUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  lotNumberId?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "lot_number_products" */
export enum Lot_Number_Products_Constraint {
  /** unique or primary key constraint on columns "product_id", "lot_number_id" */
  LotNumberProductsLotNumberIdProductIdKey = 'lot_number_products_lot_number_id_product_id_key',
  /** unique or primary key constraint on columns "id" */
  LotNumberProductsPkey = 'lot_number_products_pkey'
}

/** input type for incrementing numeric columns in table "lot_number_products" */
export type Lot_Number_Products_Inc_Input = {
  costPricePerUnit?: InputMaybe<Scalars['bigint']>;
  costPriceUnitId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "lot_number_products" */
export type Lot_Number_Products_Insert_Input = {
  LotNumber?: InputMaybe<Lot_Numbers_Obj_Rel_Insert_Input>;
  costPricePerUnit?: InputMaybe<Scalars['bigint']>;
  costPriceUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  costPriceUnitId?: InputMaybe<Scalars['bigint']>;
  currency?: InputMaybe<Currencies_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Lot_Number_Products_Max_Fields = {
  __typename: 'lot_number_products_max_fields';
  costPricePerUnit?: Maybe<Scalars['bigint']>;
  costPriceUnitId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "lot_number_products" */
export type Lot_Number_Products_Max_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lot_Number_Products_Min_Fields = {
  __typename: 'lot_number_products_min_fields';
  costPricePerUnit?: Maybe<Scalars['bigint']>;
  costPriceUnitId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "lot_number_products" */
export type Lot_Number_Products_Min_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lot_number_products" */
export type Lot_Number_Products_Mutation_Response = {
  __typename: 'lot_number_products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lot_Number_Products>;
};

/** on_conflict condition type for table "lot_number_products" */
export type Lot_Number_Products_On_Conflict = {
  constraint: Lot_Number_Products_Constraint;
  update_columns?: Array<Lot_Number_Products_Update_Column>;
  where?: InputMaybe<Lot_Number_Products_Bool_Exp>;
};

/** Ordering options when selecting data from "lot_number_products". */
export type Lot_Number_Products_Order_By = {
  LotNumber?: InputMaybe<Lot_Numbers_Order_By>;
  Tenant?: InputMaybe<Tenants_Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnit?: InputMaybe<Product_Units_Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lot_number_products */
export type Lot_Number_Products_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "lot_number_products" */
export enum Lot_Number_Products_Select_Column {
  /** column name */
  CostPricePerUnit = 'costPricePerUnit',
  /** column name */
  CostPriceUnitId = 'costPriceUnitId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  LotNumberId = 'lotNumberId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "lot_number_products" */
export type Lot_Number_Products_Set_Input = {
  costPricePerUnit?: InputMaybe<Scalars['bigint']>;
  costPriceUnitId?: InputMaybe<Scalars['bigint']>;
  currency?: InputMaybe<Currencies_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Lot_Number_Products_Stddev_Fields = {
  __typename: 'lot_number_products_stddev_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lot_number_products" */
export type Lot_Number_Products_Stddev_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lot_Number_Products_Stddev_Pop_Fields = {
  __typename: 'lot_number_products_stddev_pop_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lot_number_products" */
export type Lot_Number_Products_Stddev_Pop_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lot_Number_Products_Stddev_Samp_Fields = {
  __typename: 'lot_number_products_stddev_samp_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lot_number_products" */
export type Lot_Number_Products_Stddev_Samp_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lot_number_products" */
export type Lot_Number_Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lot_Number_Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lot_Number_Products_Stream_Cursor_Value_Input = {
  costPricePerUnit?: InputMaybe<Scalars['bigint']>;
  costPriceUnitId?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Currencies_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Lot_Number_Products_Sum_Fields = {
  __typename: 'lot_number_products_sum_fields';
  costPricePerUnit?: Maybe<Scalars['bigint']>;
  costPriceUnitId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "lot_number_products" */
export type Lot_Number_Products_Sum_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "lot_number_products" */
export enum Lot_Number_Products_Update_Column {
  /** column name */
  CostPricePerUnit = 'costPricePerUnit',
  /** column name */
  CostPriceUnitId = 'costPriceUnitId',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  LotNumberId = 'lotNumberId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Version = 'version'
}

export type Lot_Number_Products_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lot_Number_Products_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lot_Number_Products_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lot_Number_Products_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lot_Number_Products_Var_Pop_Fields = {
  __typename: 'lot_number_products_var_pop_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lot_number_products" */
export type Lot_Number_Products_Var_Pop_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lot_Number_Products_Var_Samp_Fields = {
  __typename: 'lot_number_products_var_samp_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lot_number_products" */
export type Lot_Number_Products_Var_Samp_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lot_Number_Products_Variance_Fields = {
  __typename: 'lot_number_products_variance_fields';
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lot_number_products" */
export type Lot_Number_Products_Variance_Order_By = {
  costPricePerUnit?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "lot_numbers" */
export type Lot_Numbers = {
  __typename: 'lot_numbers';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  customerOrderReturnItems: Array<Customer_Order_Return_Items>;
  /** An aggregate relationship */
  customerOrderReturnItems_aggregate: Customer_Order_Return_Items_Aggregate;
  day?: Maybe<Scalars['date']>;
  /** An array relationship */
  globalSearchResults: Array<Global_Search_Result>;
  /** An aggregate relationship */
  globalSearchResults_aggregate: Global_Search_Result_Aggregate;
  id: Scalars['bigint'];
  /** An array relationship */
  inventoryItems: Array<Inventory_Items>;
  /** An array relationship */
  inventoryItemsByLocations: Array<Inventory_Items_By_Location>;
  /** An aggregate relationship */
  inventoryItemsByLocations_aggregate: Inventory_Items_By_Location_Aggregate;
  /** An aggregate relationship */
  inventoryItems_aggregate: Inventory_Items_Aggregate;
  /** An array relationship */
  lotNumberProducts: Array<Lot_Number_Products>;
  /** An aggregate relationship */
  lotNumberProducts_aggregate: Lot_Number_Products_Aggregate;
  /** An array relationship */
  pickedItems: Array<Picked_Items>;
  /** An aggregate relationship */
  pickedItems_aggregate: Picked_Items_Aggregate;
  /** An array relationship */
  postings: Array<Postings>;
  /** An aggregate relationship */
  postings_aggregate: Postings_Aggregate;
  /** An array relationship */
  rollingInventoryItems: Array<Rolling_Inventory_Items>;
  /** An aggregate relationship */
  rollingInventoryItems_aggregate: Rolling_Inventory_Items_Aggregate;
  /** An array relationship */
  rollingInventoryLotSummaryItems: Array<Rolling_Inventory_Lot_Summary_Items>;
  /** An aggregate relationship */
  rollingInventoryLotSummaryItems_aggregate: Rolling_Inventory_Lot_Summary_Items_Aggregate;
  /** An array relationship */
  stockTransactions: Array<Stock_Transactions>;
  /** An aggregate relationship */
  stockTransactions_aggregate: Stock_Transactions_Aggregate;
  /** An array relationship */
  supplierOrderGoodsIncomeItems: Array<Supplier_Order_Goods_Income_Items>;
  /** An aggregate relationship */
  supplierOrderGoodsIncomeItems_aggregate: Supplier_Order_Goods_Income_Items_Aggregate;
  /** An array relationship */
  supplierOrderReturnItems: Array<Supplier_Order_Return_Items>;
  /** An aggregate relationship */
  supplierOrderReturnItems_aggregate: Supplier_Order_Return_Items_Aggregate;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  value: Scalars['String'];
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersCustomerOrderReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersCustomerOrderReturnItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersGlobalSearchResultsArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersGlobalSearchResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersInventoryItemsArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersInventoryItemsByLocationsArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersInventoryItemsByLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersInventoryItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersLotNumberProductsArgs = {
  distinct_on?: InputMaybe<Array<Lot_Number_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Number_Products_Order_By>>;
  where?: InputMaybe<Lot_Number_Products_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersLotNumberProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lot_Number_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Number_Products_Order_By>>;
  where?: InputMaybe<Lot_Number_Products_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersPickedItemsArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersPickedItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersPostingsArgs = {
  distinct_on?: InputMaybe<Array<Postings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postings_Order_By>>;
  where?: InputMaybe<Postings_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersPostings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Postings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postings_Order_By>>;
  where?: InputMaybe<Postings_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersRollingInventoryItemsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersRollingInventoryItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersRollingInventoryLotSummaryItemsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersRollingInventoryLotSummaryItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersStockTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersStockTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersSupplierOrderGoodsIncomeItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersSupplierOrderGoodsIncomeItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersSupplierOrderReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "lot_numbers" */
export type Lot_NumbersSupplierOrderReturnItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};

/** aggregated selection of "lot_numbers" */
export type Lot_Numbers_Aggregate = {
  __typename: 'lot_numbers_aggregate';
  aggregate?: Maybe<Lot_Numbers_Aggregate_Fields>;
  nodes: Array<Lot_Numbers>;
};

export type Lot_Numbers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Lot_Numbers_Aggregate_Bool_Exp_Count>;
};

export type Lot_Numbers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lot_Numbers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lot_Numbers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lot_numbers" */
export type Lot_Numbers_Aggregate_Fields = {
  __typename: 'lot_numbers_aggregate_fields';
  avg?: Maybe<Lot_Numbers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lot_Numbers_Max_Fields>;
  min?: Maybe<Lot_Numbers_Min_Fields>;
  stddev?: Maybe<Lot_Numbers_Stddev_Fields>;
  stddev_pop?: Maybe<Lot_Numbers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lot_Numbers_Stddev_Samp_Fields>;
  sum?: Maybe<Lot_Numbers_Sum_Fields>;
  var_pop?: Maybe<Lot_Numbers_Var_Pop_Fields>;
  var_samp?: Maybe<Lot_Numbers_Var_Samp_Fields>;
  variance?: Maybe<Lot_Numbers_Variance_Fields>;
};


/** aggregate fields of "lot_numbers" */
export type Lot_Numbers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lot_Numbers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lot_numbers" */
export type Lot_Numbers_Aggregate_Order_By = {
  avg?: InputMaybe<Lot_Numbers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lot_Numbers_Max_Order_By>;
  min?: InputMaybe<Lot_Numbers_Min_Order_By>;
  stddev?: InputMaybe<Lot_Numbers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lot_Numbers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lot_Numbers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lot_Numbers_Sum_Order_By>;
  var_pop?: InputMaybe<Lot_Numbers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lot_Numbers_Var_Samp_Order_By>;
  variance?: InputMaybe<Lot_Numbers_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Lot_Numbers_Avg_Fields = {
  __typename: 'lot_numbers_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lot_numbers" */
export type Lot_Numbers_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lot_numbers". All fields are combined with a logical 'AND'. */
export type Lot_Numbers_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Lot_Numbers_Bool_Exp>>;
  _not?: InputMaybe<Lot_Numbers_Bool_Exp>;
  _or?: InputMaybe<Array<Lot_Numbers_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerOrderReturnItems?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
  customerOrderReturnItems_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Bool_Exp>;
  day?: InputMaybe<Date_Comparison_Exp>;
  globalSearchResults?: InputMaybe<Global_Search_Result_Bool_Exp>;
  globalSearchResults_aggregate?: InputMaybe<Global_Search_Result_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  inventoryItems?: InputMaybe<Inventory_Items_Bool_Exp>;
  inventoryItemsByLocations?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
  inventoryItemsByLocations_aggregate?: InputMaybe<Inventory_Items_By_Location_Aggregate_Bool_Exp>;
  inventoryItems_aggregate?: InputMaybe<Inventory_Items_Aggregate_Bool_Exp>;
  lotNumberProducts?: InputMaybe<Lot_Number_Products_Bool_Exp>;
  lotNumberProducts_aggregate?: InputMaybe<Lot_Number_Products_Aggregate_Bool_Exp>;
  pickedItems?: InputMaybe<Picked_Items_Bool_Exp>;
  pickedItems_aggregate?: InputMaybe<Picked_Items_Aggregate_Bool_Exp>;
  postings?: InputMaybe<Postings_Bool_Exp>;
  postings_aggregate?: InputMaybe<Postings_Aggregate_Bool_Exp>;
  rollingInventoryItems?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
  rollingInventoryItems_aggregate?: InputMaybe<Rolling_Inventory_Items_Aggregate_Bool_Exp>;
  rollingInventoryLotSummaryItems?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
  rollingInventoryLotSummaryItems_aggregate?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Aggregate_Bool_Exp>;
  stockTransactions?: InputMaybe<Stock_Transactions_Bool_Exp>;
  stockTransactions_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Bool_Exp>;
  supplierOrderGoodsIncomeItems?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
  supplierOrderGoodsIncomeItems_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Bool_Exp>;
  supplierOrderReturnItems?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
  supplierOrderReturnItems_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lot_numbers" */
export enum Lot_Numbers_Constraint {
  /** unique or primary key constraint on columns "id" */
  LotNumbersPkey = 'lot_numbers_pkey',
  /** unique or primary key constraint on columns "tenant", "value" */
  LotNumbersTenantValueKey = 'lot_numbers_tenant_value_key'
}

/** input type for incrementing numeric columns in table "lot_numbers" */
export type Lot_Numbers_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "lot_numbers" */
export type Lot_Numbers_Insert_Input = {
  customerOrderReturnItems?: InputMaybe<Customer_Order_Return_Items_Arr_Rel_Insert_Input>;
  day?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  inventoryItems?: InputMaybe<Inventory_Items_Arr_Rel_Insert_Input>;
  inventoryItemsByLocations?: InputMaybe<Inventory_Items_By_Location_Arr_Rel_Insert_Input>;
  lotNumberProducts?: InputMaybe<Lot_Number_Products_Arr_Rel_Insert_Input>;
  pickedItems?: InputMaybe<Picked_Items_Arr_Rel_Insert_Input>;
  rollingInventoryItems?: InputMaybe<Rolling_Inventory_Items_Arr_Rel_Insert_Input>;
  stockTransactions?: InputMaybe<Stock_Transactions_Arr_Rel_Insert_Input>;
  supplierOrderGoodsIncomeItems?: InputMaybe<Supplier_Order_Goods_Income_Items_Arr_Rel_Insert_Input>;
  supplierOrderReturnItems?: InputMaybe<Supplier_Order_Return_Items_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lot_Numbers_Max_Fields = {
  __typename: 'lot_numbers_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  day?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "lot_numbers" */
export type Lot_Numbers_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lot_Numbers_Min_Fields = {
  __typename: 'lot_numbers_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  day?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "lot_numbers" */
export type Lot_Numbers_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lot_numbers" */
export type Lot_Numbers_Mutation_Response = {
  __typename: 'lot_numbers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lot_Numbers>;
};

/** input type for inserting object relation for remote table "lot_numbers" */
export type Lot_Numbers_Obj_Rel_Insert_Input = {
  data: Lot_Numbers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lot_Numbers_On_Conflict>;
};

/** on_conflict condition type for table "lot_numbers" */
export type Lot_Numbers_On_Conflict = {
  constraint: Lot_Numbers_Constraint;
  update_columns?: Array<Lot_Numbers_Update_Column>;
  where?: InputMaybe<Lot_Numbers_Bool_Exp>;
};

/** Ordering options when selecting data from "lot_numbers". */
export type Lot_Numbers_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrderReturnItems_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Order_By>;
  day?: InputMaybe<Order_By>;
  globalSearchResults_aggregate?: InputMaybe<Global_Search_Result_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  inventoryItemsByLocations_aggregate?: InputMaybe<Inventory_Items_By_Location_Aggregate_Order_By>;
  inventoryItems_aggregate?: InputMaybe<Inventory_Items_Aggregate_Order_By>;
  lotNumberProducts_aggregate?: InputMaybe<Lot_Number_Products_Aggregate_Order_By>;
  pickedItems_aggregate?: InputMaybe<Picked_Items_Aggregate_Order_By>;
  postings_aggregate?: InputMaybe<Postings_Aggregate_Order_By>;
  rollingInventoryItems_aggregate?: InputMaybe<Rolling_Inventory_Items_Aggregate_Order_By>;
  rollingInventoryLotSummaryItems_aggregate?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Aggregate_Order_By>;
  stockTransactions_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Order_By>;
  supplierOrderGoodsIncomeItems_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Order_By>;
  supplierOrderReturnItems_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lot_numbers */
export type Lot_Numbers_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "lot_numbers" */
export enum Lot_Numbers_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Day = 'day',
  /** column name */
  Id = 'id',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "lot_numbers" */
export type Lot_Numbers_Set_Input = {
  day?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Lot_Numbers_Stddev_Fields = {
  __typename: 'lot_numbers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lot_numbers" */
export type Lot_Numbers_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lot_Numbers_Stddev_Pop_Fields = {
  __typename: 'lot_numbers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lot_numbers" */
export type Lot_Numbers_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lot_Numbers_Stddev_Samp_Fields = {
  __typename: 'lot_numbers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lot_numbers" */
export type Lot_Numbers_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lot_numbers" */
export type Lot_Numbers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lot_Numbers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lot_Numbers_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  day?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Lot_Numbers_Sum_Fields = {
  __typename: 'lot_numbers_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "lot_numbers" */
export type Lot_Numbers_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "lot_numbers" */
export enum Lot_Numbers_Update_Column {
  /** column name */
  Day = 'day',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

export type Lot_Numbers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lot_Numbers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lot_Numbers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lot_Numbers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lot_Numbers_Var_Pop_Fields = {
  __typename: 'lot_numbers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lot_numbers" */
export type Lot_Numbers_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lot_Numbers_Var_Samp_Fields = {
  __typename: 'lot_numbers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lot_numbers" */
export type Lot_Numbers_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lot_Numbers_Variance_Fields = {
  __typename: 'lot_numbers_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lot_numbers" */
export type Lot_Numbers_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Maintain_Dismantling_Plan_Demands_Args = {
  _payload?: InputMaybe<Scalars['jsonb']>;
};

export type Maintain_Product_Group_Associations_Args = {
  product_group_ids?: InputMaybe<Scalars['json']>;
  product_id?: InputMaybe<Scalars['bigint']>;
};

export type Merge_Pdfs_Args = {
  _expiration_in_sec?: InputMaybe<Scalars['Int']>;
  _s3_object_ids?: InputMaybe<Scalars['json']>;
};

export type Move_Supplier_Order_Item_Delivery_Date_Args = {
  new_delivery_date?: InputMaybe<Scalars['date']>;
  supplier_order_item_id?: InputMaybe<Scalars['bigint']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename: 'mutation_root';
  /** execute VOLATILE function "abacus_xml_export" which returns "s3_objects" */
  abacus_xml_export: Array<S3_Objects>;
  activateUser?: Maybe<ActivateUserOutput>;
  /** execute VOLATILE function "activate_cutting_pattern_drafts" which returns "cutting_patterns_draft" */
  activate_cutting_pattern_drafts: Array<Cutting_Patterns_Draft>;
  /** execute VOLATILE function "activate_price_list" which returns "customer_group_pricing" */
  activate_price_list: Array<Customer_Group_Pricing>;
  /** execute VOLATILE function "add_products_to_supplier_order" which returns "supplier_order_items" */
  add_products_to_supplier_order: Array<Supplier_Order_Items>;
  /** execute VOLATILE function "approve_cutting_pattern_drafts" which returns "cutting_patterns_draft" */
  approve_cutting_pattern_drafts: Array<Cutting_Patterns_Draft>;
  calculateDismantlingPricing?: Maybe<CalculateDismantlingPricingOutput>;
  /** execute VOLATILE function "calculate_coi_sales_quantity_from_picking" which returns "customer_order_items" */
  calculate_coi_sales_quantity_from_picking: Array<Customer_Order_Items>;
  /** execute VOLATILE function "cancel_cutting_pattern_draft_approval_request" which returns "cutting_patterns_draft" */
  cancel_cutting_pattern_draft_approval_request: Array<Cutting_Patterns_Draft>;
  /** execute VOLATILE function "cancel_supplier_order_item" which returns "supplier_order_items" */
  cancel_supplier_order_item: Array<Supplier_Order_Items>;
  /** execute VOLATILE function "change_customer_order_with_token" which returns "change_customer_order_with_token_result" */
  change_customer_order_with_token: Array<Change_Customer_Order_With_Token_Result>;
  /** execute VOLATILE function "copy_customer_order" which returns "customer_orders" */
  copy_customer_order: Array<Customer_Orders>;
  /** execute VOLATILE function "copy_product" which returns "products" */
  copy_product: Array<Products>;
  createUser?: Maybe<CreateUserOutput>;
  /** execute VOLATILE function "create_internal_order_for_dismantling_plan" which returns "customer_order_items" */
  create_internal_order_for_dismantling_plan: Array<Customer_Order_Items>;
  /** execute VOLATILE function "create_partial_delivery" which returns "partial_deliveries" */
  create_partial_delivery: Array<Partial_Deliveries>;
  deactivateUser?: Maybe<DeactivateUserOutput>;
  /** delete data from the table: "contact_availability" */
  deleteContactAvailabilities?: Maybe<Contact_Availability_Mutation_Response>;
  /** delete single row from the table: "contact_availability" */
  deleteContactAvailabilityByPk?: Maybe<Contact_Availability>;
  /** delete single row from the table: "contact_availability_ranges" */
  deleteContactAvailabilityRangeByPk?: Maybe<Contact_Availability_Ranges>;
  /** delete data from the table: "contact_availability_ranges" */
  deleteContactAvailabilityRanges?: Maybe<Contact_Availability_Ranges_Mutation_Response>;
  /** delete single row from the table: "contacts" */
  deleteContactByPk?: Maybe<Contacts>;
  /** delete data from the table: "contacts" */
  deleteContacts?: Maybe<Contacts_Mutation_Response>;
  /** delete single row from the table: "credit_note_items" */
  deleteCreditNoteItemByPk?: Maybe<Credit_Note_Items>;
  /** delete data from the table: "credit_note_items" */
  deleteCreditNoteItems?: Maybe<Credit_Note_Items_Mutation_Response>;
  /** delete single row from the table: "customer_billing_settings" */
  deleteCustomerBillingSettingByPk?: Maybe<Customer_Billing_Settings>;
  /** delete data from the table: "customer_billing_settings" */
  deleteCustomerBillingSettings?: Maybe<Customer_Billing_Settings_Mutation_Response>;
  /** delete data from the table: "customer_delivery_availability" */
  deleteCustomerDeliveryAvailabilities?: Maybe<Customer_Delivery_Availability_Mutation_Response>;
  /** delete single row from the table: "customer_delivery_availability" */
  deleteCustomerDeliveryAvailabilityByPk?: Maybe<Customer_Delivery_Availability>;
  /** delete single row from the table: "customer_group_pricing" */
  deleteCustomerGroupPricingByPk?: Maybe<Customer_Group_Pricing>;
  /** delete data from the table: "customer_group_pricing" */
  deleteCustomerGroupPricings?: Maybe<Customer_Group_Pricing_Mutation_Response>;
  /** delete single row from the table: "customer_invoice_orders" */
  deleteCustomerInvoiceOrderByPk?: Maybe<Customer_Invoice_Orders>;
  /** delete data from the table: "customer_invoice_orders" */
  deleteCustomerInvoiceOrders?: Maybe<Customer_Invoice_Orders_Mutation_Response>;
  /** delete single row from the table: "customer_orders" */
  deleteCustomerOrderByPk?: Maybe<Customer_Orders>;
  /** delete single row from the table: "customer_order_container_returns" */
  deleteCustomerOrderContainerReturnByPk?: Maybe<Customer_Order_Container_Returns>;
  /** delete single row from the table: "customer_order_container_return_items" */
  deleteCustomerOrderContainerReturnItemByPk?: Maybe<Customer_Order_Container_Return_Items>;
  /** delete data from the table: "customer_order_container_return_items" */
  deleteCustomerOrderContainerReturnItems?: Maybe<Customer_Order_Container_Return_Items_Mutation_Response>;
  /** delete data from the table: "customer_order_container_returns" */
  deleteCustomerOrderContainerReturns?: Maybe<Customer_Order_Container_Returns_Mutation_Response>;
  /** delete single row from the table: "customer_order_item_adjustments" */
  deleteCustomerOrderItemAdjustmentByPk?: Maybe<Customer_Order_Item_Adjustments>;
  /** delete data from the table: "customer_order_item_adjustments" */
  deleteCustomerOrderItemAdjustments?: Maybe<Customer_Order_Item_Adjustments_Mutation_Response>;
  /** delete single row from the table: "customer_order_items" */
  deleteCustomerOrderItemByPk?: Maybe<Customer_Order_Items>;
  /** delete single row from the table: "customer_order_item_container_expectations" */
  deleteCustomerOrderItemContainerExpectationByPk?: Maybe<Customer_Order_Item_Container_Expectations>;
  /** delete data from the table: "customer_order_item_container_expectations" */
  deleteCustomerOrderItemContainerExpectations?: Maybe<Customer_Order_Item_Container_Expectations_Mutation_Response>;
  /** delete single row from the table: "customer_order_item_fulfillment_plans" */
  deleteCustomerOrderItemFulfillmentPlanByPk?: Maybe<Customer_Order_Item_Fulfillment_Plans>;
  /** delete data from the table: "customer_order_item_fulfillment_plans" */
  deleteCustomerOrderItemFulfillmentPlans?: Maybe<Customer_Order_Item_Fulfillment_Plans_Mutation_Response>;
  /** delete data from the table: "customer_order_items" */
  deleteCustomerOrderItems?: Maybe<Customer_Order_Items_Mutation_Response>;
  /** delete single row from the table: "customer_order_picked_container" */
  deleteCustomerOrderPickedContainerByPk?: Maybe<Customer_Order_Picked_Container>;
  /** delete data from the table: "customer_order_picked_container" */
  deleteCustomerOrderPickedContainers?: Maybe<Customer_Order_Picked_Container_Mutation_Response>;
  /** delete single row from the table: "customer_order_return_items" */
  deleteCustomerOrderReturnItemByPk?: Maybe<Customer_Order_Return_Items>;
  /** delete single row from the table: "customer_order_return_item_containers" */
  deleteCustomerOrderReturnItemContainerByPk?: Maybe<Customer_Order_Return_Item_Containers>;
  /** delete data from the table: "customer_order_return_item_containers" */
  deleteCustomerOrderReturnItemContainers?: Maybe<Customer_Order_Return_Item_Containers_Mutation_Response>;
  /** delete data from the table: "customer_order_return_items" */
  deleteCustomerOrderReturnItems?: Maybe<Customer_Order_Return_Items_Mutation_Response>;
  /** delete data from the table: "customer_orders" */
  deleteCustomerOrders?: Maybe<Customer_Orders_Mutation_Response>;
  /** delete data from the table: "customer_product_availabilities" */
  deleteCustomerProductAvailabilities?: Maybe<Customer_Product_Availabilities_Mutation_Response>;
  /** delete single row from the table: "customer_product_availabilities" */
  deleteCustomerProductAvailabilityByPk?: Maybe<Customer_Product_Availabilities>;
  /** delete single row from the table: "cutting_pattern_outputs" */
  deleteCuttingPatternOutputByPk?: Maybe<Cutting_Pattern_Outputs>;
  /** delete data from the table: "cutting_pattern_outputs" */
  deleteCuttingPatternOutputs?: Maybe<Cutting_Pattern_Outputs_Mutation_Response>;
  /** delete single row from the table: "cutting_pattern_outputs_draft" */
  deleteCuttingPatternOutputsDraftByPk?: Maybe<Cutting_Pattern_Outputs_Draft>;
  /** delete data from the table: "cutting_pattern_outputs_draft" */
  deleteCuttingPatternOutputsDrafts?: Maybe<Cutting_Pattern_Outputs_Draft_Mutation_Response>;
  /** delete single row from the table: "dismantling_plan_cutting_pattern_choices" */
  deleteDismantlingPlanCuttingPatternChoiceByPk?: Maybe<Dismantling_Plan_Cutting_Pattern_Choices>;
  /** delete data from the table: "dismantling_plan_cutting_pattern_choices" */
  deleteDismantlingPlanCuttingPatternChoices?: Maybe<Dismantling_Plan_Cutting_Pattern_Choices_Mutation_Response>;
  /** delete single row from the table: "dismantling_plan_demands" */
  deleteDismantlingPlanDemandByPk?: Maybe<Dismantling_Plan_Demands>;
  /** delete data from the table: "dismantling_plan_demands" */
  deleteDismantlingPlanDemands?: Maybe<Dismantling_Plan_Demands_Mutation_Response>;
  /** delete single row from the table: "dismantling_plan_instructions" */
  deleteDismantlingPlanInstructionByPk?: Maybe<Dismantling_Plan_Instructions>;
  /** delete data from the table: "dismantling_plan_instructions" */
  deleteDismantlingPlanInstructions?: Maybe<Dismantling_Plan_Instructions_Mutation_Response>;
  /** delete single row from the table: "emails" */
  deleteEmailByPk?: Maybe<Emails>;
  /** delete data from the table: "emails" */
  deleteEmails?: Maybe<Emails_Mutation_Response>;
  /** delete single row from the table: "hardware_devices" */
  deleteHardwareDeviceByPk?: Maybe<Hardware_Devices>;
  /** delete data from the table: "hardware_devices" */
  deleteHardwareDevices?: Maybe<Hardware_Devices_Mutation_Response>;
  /** delete single row from the table: "phones" */
  deletePhoneByPk?: Maybe<Phones>;
  /** delete data from the table: "phones" */
  deletePhones?: Maybe<Phones_Mutation_Response>;
  /** delete single row from the table: "picked_items" */
  deletePickedItemByPk?: Maybe<Picked_Items>;
  /** delete single row from the table: "picked_item_containers" */
  deletePickedItemContainerByPk?: Maybe<Picked_Item_Containers>;
  /** delete data from the table: "picked_item_containers" */
  deletePickedItemContainers?: Maybe<Picked_Item_Containers_Mutation_Response>;
  /** delete data from the table: "picked_items" */
  deletePickedItems?: Maybe<Picked_Items_Mutation_Response>;
  /** delete single row from the table: "product_allowed_containers" */
  deleteProductAllowedContainerByPk?: Maybe<Product_Allowed_Containers>;
  /** delete data from the table: "product_allowed_containers" */
  deleteProductAllowedContainers?: Maybe<Product_Allowed_Containers_Mutation_Response>;
  /** delete single row from the table: "product_label_settings" */
  deleteProductLabelSettingByPk?: Maybe<Product_Label_Settings>;
  /** delete data from the table: "product_label_settings" */
  deleteProductLabelSettings?: Maybe<Product_Label_Settings_Mutation_Response>;
  /** delete single row from the table: "product_packaging_configurations" */
  deleteProductPackagingConfigurationByPk?: Maybe<Product_Packaging_Configurations>;
  /** delete data from the table: "product_packaging_configurations" */
  deleteProductPackagingConfigurations?: Maybe<Product_Packaging_Configurations_Mutation_Response>;
  /** delete single row from the table: "product_recipe_ingredients" */
  deleteProductRecipeIngredientByPk?: Maybe<Product_Recipe_Ingredients>;
  /** delete data from the table: "product_recipe_ingredients" */
  deleteProductRecipeIngredients?: Maybe<Product_Recipe_Ingredients_Mutation_Response>;
  /** delete single row from the table: "product_recipe_steps" */
  deleteProductRecipeStepByPk?: Maybe<Product_Recipe_Steps>;
  /** delete data from the table: "product_recipe_steps" */
  deleteProductRecipeSteps?: Maybe<Product_Recipe_Steps_Mutation_Response>;
  /** delete single row from the table: "product_s3_objects" */
  deleteProductS3ObjectByPk?: Maybe<Product_S3_Objects>;
  /** delete data from the table: "product_s3_objects" */
  deleteProductS3Objects?: Maybe<Product_S3_Objects_Mutation_Response>;
  /** delete single row from the table: "product_sales_channel_members" */
  deleteProductSalesChannelMemberByPk?: Maybe<Product_Sales_Channel_Members>;
  /** delete data from the table: "product_sales_channel_members" */
  deleteProductSalesChannelMembers?: Maybe<Product_Sales_Channel_Members_Mutation_Response>;
  /** delete single row from the table: "product_stock_locations" */
  deleteProductStockLocationByPk?: Maybe<Product_Stock_Locations>;
  /** delete data from the table: "product_stock_locations" */
  deleteProductStockLocations?: Maybe<Product_Stock_Locations_Mutation_Response>;
  /** delete single row from the table: "product_suppliers" */
  deleteProductSupplierByPk?: Maybe<Product_Suppliers>;
  /** delete data from the table: "product_suppliers" */
  deleteProductSuppliers?: Maybe<Product_Suppliers_Mutation_Response>;
  /** delete single row from the table: "product_unit_sales_price_config" */
  deleteProductUnitSalesPriceConfigByPk?: Maybe<Product_Unit_Sales_Price_Config>;
  /** delete data from the table: "product_unit_sales_price_config" */
  deleteProductUnitSalesPriceConfigs?: Maybe<Product_Unit_Sales_Price_Config_Mutation_Response>;
  /** delete single row from the table: "production_site_input_stock_locations" */
  deleteProductionSiteInputStockLocationByPk?: Maybe<Production_Site_Input_Stock_Locations>;
  /** delete data from the table: "production_site_input_stock_locations" */
  deleteProductionSiteInputStockLocations?: Maybe<Production_Site_Input_Stock_Locations_Mutation_Response>;
  /** delete single row from the table: "production_site_output_stock_locations" */
  deleteProductionSiteOutputStockLocationByPk?: Maybe<Production_Site_Output_Stock_Locations>;
  /** delete data from the table: "production_site_output_stock_locations" */
  deleteProductionSiteOutputStockLocations?: Maybe<Production_Site_Output_Stock_Locations_Mutation_Response>;
  /** delete single row from the table: "production_site_product_group_calendar" */
  deleteProductionSiteProductGroupCalendarByPk?: Maybe<Production_Site_Product_Group_Calendar>;
  /** delete data from the table: "production_site_product_group_calendar" */
  deleteProductionSiteProductGroupCalendars?: Maybe<Production_Site_Product_Group_Calendar_Mutation_Response>;
  /** delete data from the table: "rolling_inventories" */
  deleteRollingInventories?: Maybe<Rolling_Inventories_Mutation_Response>;
  /** delete single row from the table: "rolling_inventory_actors" */
  deleteRollingInventoryActorByPk?: Maybe<Rolling_Inventory_Actors>;
  /** delete data from the table: "rolling_inventory_actors" */
  deleteRollingInventoryActors?: Maybe<Rolling_Inventory_Actors_Mutation_Response>;
  /** delete single row from the table: "rolling_inventories" */
  deleteRollingInventoryByPk?: Maybe<Rolling_Inventories>;
  /** delete single row from the table: "rolling_inventory_items" */
  deleteRollingInventoryItemByPk?: Maybe<Rolling_Inventory_Items>;
  /** delete data from the table: "rolling_inventory_items" */
  deleteRollingInventoryItems?: Maybe<Rolling_Inventory_Items_Mutation_Response>;
  /** delete single row from the table: "sales_team_users" */
  deleteSalesTeamUserByPk?: Maybe<Sales_Team_Users>;
  /** delete data from the table: "sales_team_users" */
  deleteSalesTeamUsers?: Maybe<Sales_Team_Users_Mutation_Response>;
  /** delete single row from the table: "supplier_orders" */
  deleteSupplierOrderByPk?: Maybe<Supplier_Orders>;
  /** delete single row from the table: "supplier_order_goods_income_items" */
  deleteSupplierOrderGoodsIncomeItemByPk?: Maybe<Supplier_Order_Goods_Income_Items>;
  /** delete single row from the table: "supplier_order_goods_income_item_containers" */
  deleteSupplierOrderGoodsIncomeItemContainerByPk?: Maybe<Supplier_Order_Goods_Income_Item_Containers>;
  /** delete data from the table: "supplier_order_goods_income_item_containers" */
  deleteSupplierOrderGoodsIncomeItemContainers?: Maybe<Supplier_Order_Goods_Income_Item_Containers_Mutation_Response>;
  /** delete data from the table: "supplier_order_goods_income_items" */
  deleteSupplierOrderGoodsIncomeItems?: Maybe<Supplier_Order_Goods_Income_Items_Mutation_Response>;
  /** delete single row from the table: "supplier_order_items" */
  deleteSupplierOrderItemByPk?: Maybe<Supplier_Order_Items>;
  /** delete data from the table: "supplier_order_items" */
  deleteSupplierOrderItems?: Maybe<Supplier_Order_Items_Mutation_Response>;
  /** delete single row from the table: "supplier_order_return_items" */
  deleteSupplierOrderReturnItemByPk?: Maybe<Supplier_Order_Return_Items>;
  /** delete single row from the table: "supplier_order_return_item_containers" */
  deleteSupplierOrderReturnItemContainerByPk?: Maybe<Supplier_Order_Return_Item_Containers>;
  /** delete data from the table: "supplier_order_return_item_containers" */
  deleteSupplierOrderReturnItemContainers?: Maybe<Supplier_Order_Return_Item_Containers_Mutation_Response>;
  /** delete data from the table: "supplier_order_return_items" */
  deleteSupplierOrderReturnItems?: Maybe<Supplier_Order_Return_Items_Mutation_Response>;
  /** delete single row from the table: "supplier_order_returned_containers" */
  deleteSupplierOrderReturnedContainerByPk?: Maybe<Supplier_Order_Returned_Containers>;
  /** delete data from the table: "supplier_order_returned_containers" */
  deleteSupplierOrderReturnedContainers?: Maybe<Supplier_Order_Returned_Containers_Mutation_Response>;
  /** delete data from the table: "supplier_orders" */
  deleteSupplierOrders?: Maybe<Supplier_Orders_Mutation_Response>;
  /** delete single row from the table: "temporal_products" */
  deleteTemporalProductByPk?: Maybe<Temporal_Products>;
  /** delete data from the table: "temporal_products" */
  deleteTemporalProducts?: Maybe<Temporal_Products_Mutation_Response>;
  /** delete single row from the table: "temporal_sales_teams_customers" */
  deleteTemporalSalesTeamsCustomerByPk?: Maybe<Temporal_Sales_Teams_Customers>;
  /** delete data from the table: "temporal_sales_teams_customers" */
  deleteTemporalSalesTeamsCustomers?: Maybe<Temporal_Sales_Teams_Customers_Mutation_Response>;
  /** delete single row from the table: "tour_routes" */
  deleteTourRouteByPk?: Maybe<Tour_Routes>;
  /** delete data from the table: "tour_routes" */
  deleteTourRoutes?: Maybe<Tour_Routes_Mutation_Response>;
  deleteUser?: Maybe<DeleteUserOutput>;
  /** delete single row from the table: "workstations" */
  deleteWorkstationByPk?: Maybe<Workstations>;
  /** delete single row from the table: "workstation_hardware_device_connections" */
  deleteWorkstationHardwareDeviceConnectionByPk?: Maybe<Workstation_Hardware_Device_Connections>;
  /** delete data from the table: "workstation_hardware_device_connections" */
  deleteWorkstationHardwareDeviceConnections?: Maybe<Workstation_Hardware_Device_Connections_Mutation_Response>;
  /** delete data from the table: "workstation_picking_categories" */
  deleteWorkstationPickingCategories?: Maybe<Workstation_Picking_Categories_Mutation_Response>;
  /** delete data from the table: "workstations" */
  deleteWorkstations?: Maybe<Workstations_Mutation_Response>;
  /** execute VOLATILE function "delete_customer_order_item" which returns "customer_order_items" */
  delete_customer_order_item: Array<Customer_Order_Items>;
  /** execute VOLATILE function "delete_partial_delivery" which returns "partial_deliveries" */
  delete_partial_delivery: Array<Partial_Deliveries>;
  /** execute VOLATILE function "delete_picked_items" which returns "picked_items" */
  delete_picked_items: Array<Picked_Items>;
  /** execute VOLATILE function "delete_product_s3_object" which returns "product_s3_objects" */
  delete_product_s3_object?: Maybe<Product_S3_Objects>;
  /** execute VOLATILE function "delete_rolling_inventory" which returns "rolling_inventories" */
  delete_rolling_inventory: Array<Rolling_Inventories>;
  dismantlingPlanPrepare?: Maybe<DismantlingPlanPrepareOutput>;
  /** execute VOLATILE function "enqueue_bizerba_finish_picking_flow" which returns "proletarian_jobs" */
  enqueue_bizerba_finish_picking_flow: Array<Proletarian_Jobs>;
  /** execute VOLATILE function "enqueue_bizerba_reset_device" which returns "proletarian_jobs" */
  enqueue_bizerba_reset_device: Array<Proletarian_Jobs>;
  /** execute VOLATILE function "enqueue_bizerba_resume_picking_flow" which returns "proletarian_jobs" */
  enqueue_bizerba_resume_picking_flow: Array<Proletarian_Jobs>;
  /** execute VOLATILE function "enqueue_bizerba_setup_picking_flow" which returns "proletarian_jobs" */
  enqueue_bizerba_setup_picking_flow: Array<Proletarian_Jobs>;
  /** execute VOLATILE function "enqueue_bulk_customer_order_delivery_note_pdf_generation" which returns "s3_objects" */
  enqueue_bulk_customer_order_delivery_note_pdf_generation: Array<S3_Objects>;
  /** execute VOLATILE function "enqueue_credit_note_pdf_generation" which returns "s3_objects" */
  enqueue_credit_note_pdf_generation: Array<S3_Objects>;
  /** execute VOLATILE function "enqueue_credit_notes_pdf_generation" which returns "s3_objects" */
  enqueue_credit_notes_pdf_generation: Array<S3_Objects>;
  /** execute VOLATILE function "enqueue_customer_invoice_preparation" which returns "customer_invoice_versions" */
  enqueue_customer_invoice_preparation: Array<Customer_Invoice_Versions>;
  /** execute VOLATILE function "enqueue_customer_invoices_preparation" which returns "customer_invoice_versions" */
  enqueue_customer_invoices_preparation: Array<Customer_Invoice_Versions>;
  /** execute VOLATILE function "enqueue_customer_order_delivery_note_pdf_generation" which returns "customer_orders" */
  enqueue_customer_order_delivery_note_pdf_generation: Array<Customer_Orders>;
  /** execute VOLATILE function "enqueue_edi_invoice_sending_if_necessary" which returns "proletarian_jobs" */
  enqueue_edi_invoice_sending_if_necessary: Array<Proletarian_Jobs>;
  /** execute VOLATILE function "enqueue_email_credit_note_sending" which returns "proletarian_jobs" */
  enqueue_email_credit_note_sending: Array<Proletarian_Jobs>;
  /** execute VOLATILE function "enqueue_email_invoice_sending" which returns "proletarian_jobs" */
  enqueue_email_invoice_sending: Array<Proletarian_Jobs>;
  /** execute VOLATILE function "enqueue_gs1_logistic_pdf_generation" which returns "s3_objects" */
  enqueue_gs1_logistic_pdf_generation: Array<S3_Objects>;
  /** execute VOLATILE function "enqueue_picking_slip_pdf_generation" which returns "s3_objects" */
  enqueue_picking_slip_pdf_generation: Array<S3_Objects>;
  /** execute VOLATILE function "enqueue_tour_plan_csv_generation" which returns "s3_objects" */
  enqueue_tour_plan_csv_generation: Array<S3_Objects>;
  /** execute VOLATILE function "evict_rolling_inventory_items" which returns "rolling_inventory_items" */
  evict_rolling_inventory_items: Array<Rolling_Inventory_Items>;
  /** execute VOLATILE function "execute_product_recipe" which returns "journal_entries" */
  execute_product_recipe: Array<Journal_Entries>;
  finishBizerbaPickingFlow?: Maybe<FinishBizerbaPickingFlowOutput>;
  finishLeichMehlPickingFlow?: Maybe<FinishLeichMehlPickingFlowOutput>;
  /** execute VOLATILE function "generate_presigned_url" which returns "generate_presigned_url_result" */
  generate_presigned_url: Array<Generate_Presigned_Url_Result>;
  /** execute VOLATILE function "generate_supplier_order_pdf" which returns "s3_objects" */
  generate_supplier_order_pdf: Array<S3_Objects>;
  /** execute VOLATILE function "hard_delete_customer_order_return" which returns "customer_order_returns" */
  hard_delete_customer_order_return: Array<Customer_Order_Returns>;
  /** execute VOLATILE function "initialize_dismantling_pricing" which returns "temporal_products" */
  initialize_dismantling_pricing: Array<Temporal_Products>;
  /** execute VOLATILE function "initiate_s3_upload" which returns "s3_objects" */
  initiate_s3_upload: Array<S3_Objects>;
  /** insert a single row into the table: "accounts" */
  insertAccount?: Maybe<Accounts>;
  /** insert data into the table: "accounts" */
  insertAccounts?: Maybe<Accounts_Mutation_Response>;
  /** insert a single row into the table: "aggregated_inventory_items_by_location" */
  insertAggregatedInventoryItemsByLocation?: Maybe<Aggregated_Inventory_Items_By_Location>;
  /** insert data into the table: "aggregated_inventory_items_by_location" */
  insertAggregatedInventoryItemsByLocations?: Maybe<Aggregated_Inventory_Items_By_Location_Mutation_Response>;
  /** insert a single row into the table: "contacts" */
  insertContact?: Maybe<Contacts>;
  /** insert a single row into the table: "contact_assigned_roles" */
  insertContactAssignedRole?: Maybe<Contact_Assigned_Roles>;
  /** insert data into the table: "contact_assigned_roles" */
  insertContactAssignedRoles?: Maybe<Contact_Assigned_Roles_Mutation_Response>;
  /** insert data into the table: "contact_availability" */
  insertContactAvailabilities?: Maybe<Contact_Availability_Mutation_Response>;
  /** insert a single row into the table: "contact_availability" */
  insertContactAvailability?: Maybe<Contact_Availability>;
  /** insert a single row into the table: "contact_availability_ranges" */
  insertContactAvailabilityRange?: Maybe<Contact_Availability_Ranges>;
  /** insert data into the table: "contact_availability_ranges" */
  insertContactAvailabilityRanges?: Maybe<Contact_Availability_Ranges_Mutation_Response>;
  /** insert data into the table: "contacts" */
  insertContacts?: Maybe<Contacts_Mutation_Response>;
  /** insert a single row into the table: "credit_notes" */
  insertCreditNote?: Maybe<Credit_Notes>;
  /** insert a single row into the table: "credit_note_items" */
  insertCreditNoteItem?: Maybe<Credit_Note_Items>;
  /** insert data into the table: "credit_note_items" */
  insertCreditNoteItems?: Maybe<Credit_Note_Items_Mutation_Response>;
  /** insert data into the table: "credit_notes" */
  insertCreditNotes?: Maybe<Credit_Notes_Mutation_Response>;
  /** insert a single row into the table: "customers" */
  insertCustomer?: Maybe<Customers>;
  /** insert a single row into the table: "customer_addresses" */
  insertCustomerAddress?: Maybe<Customer_Addresses>;
  /** insert data into the table: "customer_addresses" */
  insertCustomerAddresses?: Maybe<Customer_Addresses_Mutation_Response>;
  /** insert a single row into the table: "customer_billing_settings" */
  insertCustomerBillingSetting?: Maybe<Customer_Billing_Settings>;
  /** insert data into the table: "customer_billing_settings" */
  insertCustomerBillingSettings?: Maybe<Customer_Billing_Settings_Mutation_Response>;
  /** insert data into the table: "customer_delivery_availability" */
  insertCustomerDeliveryAvailabilities?: Maybe<Customer_Delivery_Availability_Mutation_Response>;
  /** insert a single row into the table: "customer_delivery_availability" */
  insertCustomerDeliveryAvailability?: Maybe<Customer_Delivery_Availability>;
  /** insert a single row into the table: "customer_groups" */
  insertCustomerGroup?: Maybe<Customer_Groups>;
  /** insert a single row into the table: "customer_group_pricing" */
  insertCustomerGroupPricing?: Maybe<Customer_Group_Pricing>;
  /** insert data into the table: "customer_group_pricing" */
  insertCustomerGroupPricings?: Maybe<Customer_Group_Pricing_Mutation_Response>;
  /** insert data into the table: "customer_groups" */
  insertCustomerGroups?: Maybe<Customer_Groups_Mutation_Response>;
  /** insert a single row into the table: "customer_invoice_orders" */
  insertCustomerInvoiceOrder?: Maybe<Customer_Invoice_Orders>;
  /** insert data into the table: "customer_invoice_orders" */
  insertCustomerInvoiceOrders?: Maybe<Customer_Invoice_Orders_Mutation_Response>;
  /** insert a single row into the table: "customer_invoice_versions" */
  insertCustomerInvoiceVersion?: Maybe<Customer_Invoice_Versions>;
  /** insert data into the table: "customer_invoice_versions" */
  insertCustomerInvoiceVersions?: Maybe<Customer_Invoice_Versions_Mutation_Response>;
  /** insert a single row into the table: "customer_orders" */
  insertCustomerOrder?: Maybe<Customer_Orders>;
  /** insert a single row into the table: "customer_order_container_returns" */
  insertCustomerOrderContainerReturn?: Maybe<Customer_Order_Container_Returns>;
  /** insert a single row into the table: "customer_order_container_return_items" */
  insertCustomerOrderContainerReturnItem?: Maybe<Customer_Order_Container_Return_Items>;
  /** insert data into the table: "customer_order_container_return_items" */
  insertCustomerOrderContainerReturnItems?: Maybe<Customer_Order_Container_Return_Items_Mutation_Response>;
  /** insert data into the table: "customer_order_container_returns" */
  insertCustomerOrderContainerReturns?: Maybe<Customer_Order_Container_Returns_Mutation_Response>;
  /** insert a single row into the table: "customer_order_items" */
  insertCustomerOrderItem?: Maybe<Customer_Order_Items>;
  /** insert a single row into the table: "customer_order_item_adjustments" */
  insertCustomerOrderItemAdjustment?: Maybe<Customer_Order_Item_Adjustments>;
  /** insert data into the table: "customer_order_item_adjustments" */
  insertCustomerOrderItemAdjustments?: Maybe<Customer_Order_Item_Adjustments_Mutation_Response>;
  /** insert a single row into the table: "customer_order_item_container_expectations" */
  insertCustomerOrderItemContainerExpectation?: Maybe<Customer_Order_Item_Container_Expectations>;
  /** insert data into the table: "customer_order_item_container_expectations" */
  insertCustomerOrderItemContainerExpectations?: Maybe<Customer_Order_Item_Container_Expectations_Mutation_Response>;
  /** insert a single row into the table: "customer_order_item_fulfillment_plans" */
  insertCustomerOrderItemFulfillmentPlan?: Maybe<Customer_Order_Item_Fulfillment_Plans>;
  /** insert data into the table: "customer_order_item_fulfillment_plans" */
  insertCustomerOrderItemFulfillmentPlans?: Maybe<Customer_Order_Item_Fulfillment_Plans_Mutation_Response>;
  /** insert a single row into the table: "customer_order_item_return_causes" */
  insertCustomerOrderItemReturnCause?: Maybe<Customer_Order_Item_Return_Causes>;
  /** insert data into the table: "customer_order_item_return_causes" */
  insertCustomerOrderItemReturnCauses?: Maybe<Customer_Order_Item_Return_Causes_Mutation_Response>;
  /** insert data into the table: "customer_order_items" */
  insertCustomerOrderItems?: Maybe<Customer_Order_Items_Mutation_Response>;
  /** insert a single row into the table: "customer_order_picked_container" */
  insertCustomerOrderPickedContainer?: Maybe<Customer_Order_Picked_Container>;
  /** insert data into the table: "customer_order_picked_container" */
  insertCustomerOrderPickedContainers?: Maybe<Customer_Order_Picked_Container_Mutation_Response>;
  /** insert a single row into the table: "customer_order_returns" */
  insertCustomerOrderReturn?: Maybe<Customer_Order_Returns>;
  /** insert a single row into the table: "customer_order_return_items" */
  insertCustomerOrderReturnItem?: Maybe<Customer_Order_Return_Items>;
  /** insert a single row into the table: "customer_order_return_item_containers" */
  insertCustomerOrderReturnItemContainer?: Maybe<Customer_Order_Return_Item_Containers>;
  /** insert data into the table: "customer_order_return_item_containers" */
  insertCustomerOrderReturnItemContainers?: Maybe<Customer_Order_Return_Item_Containers_Mutation_Response>;
  /** insert data into the table: "customer_order_return_items" */
  insertCustomerOrderReturnItems?: Maybe<Customer_Order_Return_Items_Mutation_Response>;
  /** insert data into the table: "customer_order_returns" */
  insertCustomerOrderReturns?: Maybe<Customer_Order_Returns_Mutation_Response>;
  /** insert data into the table: "customer_orders" */
  insertCustomerOrders?: Maybe<Customer_Orders_Mutation_Response>;
  /** insert a single row into the table: "customer_payment_terms" */
  insertCustomerPaymentTerm?: Maybe<Customer_Payment_Terms>;
  /** insert data into the table: "customer_payment_terms" */
  insertCustomerPaymentTerms?: Maybe<Customer_Payment_Terms_Mutation_Response>;
  /** insert data into the table: "customer_product_availabilities" */
  insertCustomerProductAvailabilities?: Maybe<Customer_Product_Availabilities_Mutation_Response>;
  /** insert a single row into the table: "customer_product_availabilities" */
  insertCustomerProductAvailability?: Maybe<Customer_Product_Availabilities>;
  /** insert a single row into the table: "customer_template_settings" */
  insertCustomerTemplateSetting?: Maybe<Customer_Template_Settings>;
  /** insert data into the table: "customer_template_settings" */
  insertCustomerTemplateSettings?: Maybe<Customer_Template_Settings_Mutation_Response>;
  /** insert data into the table: "customers" */
  insertCustomers?: Maybe<Customers_Mutation_Response>;
  /** insert a single row into the table: "cutting_patterns" */
  insertCuttingPattern?: Maybe<Cutting_Patterns>;
  /** insert a single row into the table: "cutting_pattern_outputs" */
  insertCuttingPatternOutput?: Maybe<Cutting_Pattern_Outputs>;
  /** insert data into the table: "cutting_pattern_outputs" */
  insertCuttingPatternOutputs?: Maybe<Cutting_Pattern_Outputs_Mutation_Response>;
  /** insert a single row into the table: "cutting_pattern_outputs_draft" */
  insertCuttingPatternOutputsDraft?: Maybe<Cutting_Pattern_Outputs_Draft>;
  /** insert data into the table: "cutting_pattern_outputs_draft" */
  insertCuttingPatternOutputsDrafts?: Maybe<Cutting_Pattern_Outputs_Draft_Mutation_Response>;
  /** insert data into the table: "cutting_patterns" */
  insertCuttingPatterns?: Maybe<Cutting_Patterns_Mutation_Response>;
  /** insert a single row into the table: "cutting_patterns_draft" */
  insertCuttingPatternsDraft?: Maybe<Cutting_Patterns_Draft>;
  /** insert data into the table: "cutting_patterns_draft" */
  insertCuttingPatternsDrafts?: Maybe<Cutting_Patterns_Draft_Mutation_Response>;
  /** insert a single row into the table: "dismantling_plans" */
  insertDismantlingPlan?: Maybe<Dismantling_Plans>;
  /** insert a single row into the table: "dismantling_plan_cutting_pattern_choices" */
  insertDismantlingPlanCuttingPatternChoice?: Maybe<Dismantling_Plan_Cutting_Pattern_Choices>;
  /** insert data into the table: "dismantling_plan_cutting_pattern_choices" */
  insertDismantlingPlanCuttingPatternChoices?: Maybe<Dismantling_Plan_Cutting_Pattern_Choices_Mutation_Response>;
  /** insert a single row into the table: "dismantling_plan_demands" */
  insertDismantlingPlanDemand?: Maybe<Dismantling_Plan_Demands>;
  /** insert data into the table: "dismantling_plan_demands" */
  insertDismantlingPlanDemands?: Maybe<Dismantling_Plan_Demands_Mutation_Response>;
  /** insert a single row into the table: "dismantling_plan_instructions" */
  insertDismantlingPlanInstruction?: Maybe<Dismantling_Plan_Instructions>;
  /** insert data into the table: "dismantling_plan_instructions" */
  insertDismantlingPlanInstructions?: Maybe<Dismantling_Plan_Instructions_Mutation_Response>;
  /** insert data into the table: "dismantling_plans" */
  insertDismantlingPlans?: Maybe<Dismantling_Plans_Mutation_Response>;
  /** insert a single row into the table: "emails" */
  insertEmail?: Maybe<Emails>;
  /** insert data into the table: "emails" */
  insertEmails?: Maybe<Emails_Mutation_Response>;
  /** insert a single row into the table: "exchange_rates" */
  insertExchangeRate?: Maybe<Exchange_Rates>;
  /** insert data into the table: "exchange_rates" */
  insertExchangeRates?: Maybe<Exchange_Rates_Mutation_Response>;
  /** insert a single row into the table: "gs1_logistic_types" */
  insertGs1LogisticType?: Maybe<Gs1_Logistic_Types>;
  /** insert data into the table: "gs1_logistic_types" */
  insertGs1LogisticTypes?: Maybe<Gs1_Logistic_Types_Mutation_Response>;
  /** insert a single row into the table: "hardware_devices" */
  insertHardwareDevice?: Maybe<Hardware_Devices>;
  /** insert data into the table: "hardware_devices" */
  insertHardwareDevices?: Maybe<Hardware_Devices_Mutation_Response>;
  /** insert a single row into the table: "images" */
  insertImage?: Maybe<Images>;
  /** insert data into the table: "images" */
  insertImages?: Maybe<Images_Mutation_Response>;
  /** insert a single row into the table: "inventory_items" */
  insertInventoryItem?: Maybe<Inventory_Items>;
  /** insert data into the table: "inventory_items" */
  insertInventoryItems?: Maybe<Inventory_Items_Mutation_Response>;
  /** insert a single row into the table: "inventory_items_by_location" */
  insertInventoryItemsByLocation?: Maybe<Inventory_Items_By_Location>;
  /** insert data into the table: "inventory_items_by_location" */
  insertInventoryItemsByLocations?: Maybe<Inventory_Items_By_Location_Mutation_Response>;
  /** insert data into the table: "journal_entries" */
  insertJournalEntries?: Maybe<Journal_Entries_Mutation_Response>;
  /** insert a single row into the table: "journal_entries" */
  insertJournalEntry?: Maybe<Journal_Entries>;
  /** insert a single row into the table: "jscript_templates" */
  insertJscriptTemplate?: Maybe<Jscript_Templates>;
  /** insert data into the table: "jscript_templates" */
  insertJscriptTemplates?: Maybe<Jscript_Templates_Mutation_Response>;
  /** insert a single row into the table: "lot_numbers" */
  insertLotNumber?: Maybe<Lot_Numbers>;
  /** insert a single row into the table: "lot_number_products" */
  insertLotNumberProduct?: Maybe<Lot_Number_Products>;
  /** insert data into the table: "lot_number_products" */
  insertLotNumberProducts?: Maybe<Lot_Number_Products_Mutation_Response>;
  /** insert data into the table: "lot_numbers" */
  insertLotNumbers?: Maybe<Lot_Numbers_Mutation_Response>;
  /** insert a single row into the table: "notifications" */
  insertNotification?: Maybe<Notifications>;
  /** insert data into the table: "notifications" */
  insertNotifications?: Maybe<Notifications_Mutation_Response>;
  /** insert data into the table: "partial_deliveries" */
  insertPartialDeliveries?: Maybe<Partial_Deliveries_Mutation_Response>;
  /** insert a single row into the table: "partial_deliveries" */
  insertPartialDelivery?: Maybe<Partial_Deliveries>;
  /** insert a single row into the table: "phones" */
  insertPhone?: Maybe<Phones>;
  /** insert data into the table: "phones" */
  insertPhones?: Maybe<Phones_Mutation_Response>;
  /** insert a single row into the table: "picked_items" */
  insertPickedItem?: Maybe<Picked_Items>;
  /** insert a single row into the table: "picked_item_containers" */
  insertPickedItemContainer?: Maybe<Picked_Item_Containers>;
  /** insert data into the table: "picked_item_containers" */
  insertPickedItemContainers?: Maybe<Picked_Item_Containers_Mutation_Response>;
  /** insert data into the table: "picked_items" */
  insertPickedItems?: Maybe<Picked_Items_Mutation_Response>;
  /** insert data into the table: "picking_categories" */
  insertPickingCategories?: Maybe<Picking_Categories_Mutation_Response>;
  /** insert a single row into the table: "picking_categories" */
  insertPickingCategory?: Maybe<Picking_Categories>;
  /** insert a single row into the table: "price_lists" */
  insertPriceList?: Maybe<Price_Lists>;
  /** insert data into the table: "price_lists" */
  insertPriceLists?: Maybe<Price_Lists_Mutation_Response>;
  /** insert a single row into the table: "products" */
  insertProduct?: Maybe<Products>;
  /** insert a single row into the table: "product_allowed_containers" */
  insertProductAllowedContainer?: Maybe<Product_Allowed_Containers>;
  /** insert data into the table: "product_allowed_containers" */
  insertProductAllowedContainers?: Maybe<Product_Allowed_Containers_Mutation_Response>;
  /** insert a single row into the table: "product_groups" */
  insertProductGroup?: Maybe<Product_Groups>;
  /** insert a single row into the table: "product_group_members" */
  insertProductGroupMember?: Maybe<Product_Group_Members>;
  /** insert data into the table: "product_group_members" */
  insertProductGroupMembers?: Maybe<Product_Group_Members_Mutation_Response>;
  /** insert data into the table: "product_groups" */
  insertProductGroups?: Maybe<Product_Groups_Mutation_Response>;
  /** insert a single row into the table: "product_label_settings" */
  insertProductLabelSetting?: Maybe<Product_Label_Settings>;
  /** insert data into the table: "product_label_settings" */
  insertProductLabelSettings?: Maybe<Product_Label_Settings_Mutation_Response>;
  /** insert a single row into the table: "product_packaging_configurations" */
  insertProductPackagingConfiguration?: Maybe<Product_Packaging_Configurations>;
  /** insert data into the table: "product_packaging_configurations" */
  insertProductPackagingConfigurations?: Maybe<Product_Packaging_Configurations_Mutation_Response>;
  /** insert a single row into the table: "product_recipes" */
  insertProductRecipe?: Maybe<Product_Recipes>;
  /** insert a single row into the table: "product_recipe_ingredients" */
  insertProductRecipeIngredient?: Maybe<Product_Recipe_Ingredients>;
  /** insert data into the table: "product_recipe_ingredients" */
  insertProductRecipeIngredients?: Maybe<Product_Recipe_Ingredients_Mutation_Response>;
  /** insert a single row into the table: "product_recipe_steps" */
  insertProductRecipeStep?: Maybe<Product_Recipe_Steps>;
  /** insert data into the table: "product_recipe_steps" */
  insertProductRecipeSteps?: Maybe<Product_Recipe_Steps_Mutation_Response>;
  /** insert data into the table: "product_recipes" */
  insertProductRecipes?: Maybe<Product_Recipes_Mutation_Response>;
  /** insert a single row into the table: "product_s3_objects" */
  insertProductS3Object?: Maybe<Product_S3_Objects>;
  /** insert data into the table: "product_s3_objects" */
  insertProductS3Objects?: Maybe<Product_S3_Objects_Mutation_Response>;
  /** insert a single row into the table: "product_sales_channel_members" */
  insertProductSalesChannelMember?: Maybe<Product_Sales_Channel_Members>;
  /** insert data into the table: "product_sales_channel_members" */
  insertProductSalesChannelMembers?: Maybe<Product_Sales_Channel_Members_Mutation_Response>;
  /** insert a single row into the table: "product_stock_locations" */
  insertProductStockLocation?: Maybe<Product_Stock_Locations>;
  /** insert data into the table: "product_stock_locations" */
  insertProductStockLocations?: Maybe<Product_Stock_Locations_Mutation_Response>;
  /** insert a single row into the table: "product_suppliers" */
  insertProductSupplier?: Maybe<Product_Suppliers>;
  /** insert data into the table: "product_suppliers" */
  insertProductSuppliers?: Maybe<Product_Suppliers_Mutation_Response>;
  /** insert a single row into the table: "product_units" */
  insertProductUnit?: Maybe<Product_Units>;
  /** insert a single row into the table: "product_unit_sales_price_config" */
  insertProductUnitSalesPriceConfig?: Maybe<Product_Unit_Sales_Price_Config>;
  /** insert data into the table: "product_unit_sales_price_config" */
  insertProductUnitSalesPriceConfigs?: Maybe<Product_Unit_Sales_Price_Config_Mutation_Response>;
  /** insert data into the table: "product_units" */
  insertProductUnits?: Maybe<Product_Units_Mutation_Response>;
  /** insert a single row into the table: "production_sites" */
  insertProductionSite?: Maybe<Production_Sites>;
  /** insert a single row into the table: "production_site_input_stock_locations" */
  insertProductionSiteInputStockLocation?: Maybe<Production_Site_Input_Stock_Locations>;
  /** insert data into the table: "production_site_input_stock_locations" */
  insertProductionSiteInputStockLocations?: Maybe<Production_Site_Input_Stock_Locations_Mutation_Response>;
  /** insert a single row into the table: "production_site_output_stock_locations" */
  insertProductionSiteOutputStockLocation?: Maybe<Production_Site_Output_Stock_Locations>;
  /** insert data into the table: "production_site_output_stock_locations" */
  insertProductionSiteOutputStockLocations?: Maybe<Production_Site_Output_Stock_Locations_Mutation_Response>;
  /** insert a single row into the table: "production_site_product_group_calendar" */
  insertProductionSiteProductGroupCalendar?: Maybe<Production_Site_Product_Group_Calendar>;
  /** insert data into the table: "production_site_product_group_calendar" */
  insertProductionSiteProductGroupCalendars?: Maybe<Production_Site_Product_Group_Calendar_Mutation_Response>;
  /** insert data into the table: "production_sites" */
  insertProductionSites?: Maybe<Production_Sites_Mutation_Response>;
  /** insert data into the table: "products" */
  insertProducts?: Maybe<Products_Mutation_Response>;
  /** insert data into the table: "rolling_inventories" */
  insertRollingInventories?: Maybe<Rolling_Inventories_Mutation_Response>;
  /** insert a single row into the table: "rolling_inventories" */
  insertRollingInventory?: Maybe<Rolling_Inventories>;
  /** insert a single row into the table: "rolling_inventory_actors" */
  insertRollingInventoryActor?: Maybe<Rolling_Inventory_Actors>;
  /** insert data into the table: "rolling_inventory_actors" */
  insertRollingInventoryActors?: Maybe<Rolling_Inventory_Actors_Mutation_Response>;
  /** insert a single row into the table: "rolling_inventory_items" */
  insertRollingInventoryItem?: Maybe<Rolling_Inventory_Items>;
  /** insert data into the table: "rolling_inventory_items" */
  insertRollingInventoryItems?: Maybe<Rolling_Inventory_Items_Mutation_Response>;
  /** insert a single row into the table: "s3_objects" */
  insertS3Object?: Maybe<S3_Objects>;
  /** insert data into the table: "s3_objects" */
  insertS3Objects?: Maybe<S3_Objects_Mutation_Response>;
  /** insert a single row into the table: "sales_teams" */
  insertSalesTeam?: Maybe<Sales_Teams>;
  /** insert a single row into the table: "sales_team_users" */
  insertSalesTeamUser?: Maybe<Sales_Team_Users>;
  /** insert data into the table: "sales_team_users" */
  insertSalesTeamUsers?: Maybe<Sales_Team_Users_Mutation_Response>;
  /** insert data into the table: "sales_teams" */
  insertSalesTeams?: Maybe<Sales_Teams_Mutation_Response>;
  /** insert a single row into the table: "stock_locations" */
  insertStockLocation?: Maybe<Stock_Locations>;
  /** insert data into the table: "stock_locations" */
  insertStockLocations?: Maybe<Stock_Locations_Mutation_Response>;
  /** insert a single row into the table: "stock_transactions" */
  insertStockTransaction?: Maybe<Stock_Transactions>;
  /** insert data into the table: "stock_transactions" */
  insertStockTransactions?: Maybe<Stock_Transactions_Mutation_Response>;
  /** insert a single row into the table: "suppliers" */
  insertSupplier?: Maybe<Suppliers>;
  /** insert a single row into the table: "supplier_addresses" */
  insertSupplierAddress?: Maybe<Supplier_Addresses>;
  /** insert data into the table: "supplier_addresses" */
  insertSupplierAddresses?: Maybe<Supplier_Addresses_Mutation_Response>;
  /** insert a single row into the table: "supplier_orders" */
  insertSupplierOrder?: Maybe<Supplier_Orders>;
  /** insert a single row into the table: "supplier_order_goods_income_items" */
  insertSupplierOrderGoodsIncomeItem?: Maybe<Supplier_Order_Goods_Income_Items>;
  /** insert a single row into the table: "supplier_order_goods_income_item_containers" */
  insertSupplierOrderGoodsIncomeItemContainer?: Maybe<Supplier_Order_Goods_Income_Item_Containers>;
  /** insert data into the table: "supplier_order_goods_income_item_containers" */
  insertSupplierOrderGoodsIncomeItemContainers?: Maybe<Supplier_Order_Goods_Income_Item_Containers_Mutation_Response>;
  /** insert data into the table: "supplier_order_goods_income_items" */
  insertSupplierOrderGoodsIncomeItems?: Maybe<Supplier_Order_Goods_Income_Items_Mutation_Response>;
  /** insert a single row into the table: "supplier_order_items" */
  insertSupplierOrderItem?: Maybe<Supplier_Order_Items>;
  /** insert a single row into the table: "supplier_order_item_return_causes" */
  insertSupplierOrderItemReturnCause?: Maybe<Supplier_Order_Item_Return_Causes>;
  /** insert data into the table: "supplier_order_item_return_causes" */
  insertSupplierOrderItemReturnCauses?: Maybe<Supplier_Order_Item_Return_Causes_Mutation_Response>;
  /** insert data into the table: "supplier_order_items" */
  insertSupplierOrderItems?: Maybe<Supplier_Order_Items_Mutation_Response>;
  /** insert a single row into the table: "supplier_order_returns" */
  insertSupplierOrderReturn?: Maybe<Supplier_Order_Returns>;
  /** insert a single row into the table: "supplier_order_return_items" */
  insertSupplierOrderReturnItem?: Maybe<Supplier_Order_Return_Items>;
  /** insert a single row into the table: "supplier_order_return_item_containers" */
  insertSupplierOrderReturnItemContainer?: Maybe<Supplier_Order_Return_Item_Containers>;
  /** insert data into the table: "supplier_order_return_item_containers" */
  insertSupplierOrderReturnItemContainers?: Maybe<Supplier_Order_Return_Item_Containers_Mutation_Response>;
  /** insert data into the table: "supplier_order_return_items" */
  insertSupplierOrderReturnItems?: Maybe<Supplier_Order_Return_Items_Mutation_Response>;
  /** insert a single row into the table: "supplier_order_returned_containers" */
  insertSupplierOrderReturnedContainer?: Maybe<Supplier_Order_Returned_Containers>;
  /** insert data into the table: "supplier_order_returned_containers" */
  insertSupplierOrderReturnedContainers?: Maybe<Supplier_Order_Returned_Containers_Mutation_Response>;
  /** insert data into the table: "supplier_order_returns" */
  insertSupplierOrderReturns?: Maybe<Supplier_Order_Returns_Mutation_Response>;
  /** insert data into the table: "supplier_orders" */
  insertSupplierOrders?: Maybe<Supplier_Orders_Mutation_Response>;
  /** insert data into the table: "suppliers" */
  insertSuppliers?: Maybe<Suppliers_Mutation_Response>;
  /** insert a single row into the table: "temporal_products" */
  insertTemporalProduct?: Maybe<Temporal_Products>;
  /** insert data into the table: "temporal_products" */
  insertTemporalProducts?: Maybe<Temporal_Products_Mutation_Response>;
  /** insert a single row into the table: "temporal_sales_teams_customers" */
  insertTemporalSalesTeamsCustomer?: Maybe<Temporal_Sales_Teams_Customers>;
  /** insert data into the table: "temporal_sales_teams_customers" */
  insertTemporalSalesTeamsCustomers?: Maybe<Temporal_Sales_Teams_Customers_Mutation_Response>;
  /** insert a single row into the table: "tenant_bank_accounts" */
  insertTenantBankAccount?: Maybe<Tenant_Bank_Accounts>;
  /** insert data into the table: "tenant_bank_accounts" */
  insertTenantBankAccounts?: Maybe<Tenant_Bank_Accounts_Mutation_Response>;
  /** insert a single row into the table: "todos" */
  insertTodo?: Maybe<Todos>;
  /** insert data into the table: "todos" */
  insertTodos?: Maybe<Todos_Mutation_Response>;
  /** insert a single row into the table: "tours" */
  insertTour?: Maybe<Tours>;
  /** insert a single row into the table: "tour_routes" */
  insertTourRoute?: Maybe<Tour_Routes>;
  /** insert data into the table: "tour_routes" */
  insertTourRoutes?: Maybe<Tour_Routes_Mutation_Response>;
  /** insert data into the table: "tours" */
  insertTours?: Maybe<Tours_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insertUser?: Maybe<Users>;
  /** insert a single row into the table: "user_sales_goals" */
  insertUserSalesGoal?: Maybe<User_Sales_Goals>;
  /** insert data into the table: "user_sales_goals" */
  insertUserSalesGoals?: Maybe<User_Sales_Goals_Mutation_Response>;
  /** insert data into the table: "users" */
  insertUsers?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "workstations" */
  insertWorkstation?: Maybe<Workstations>;
  /** insert a single row into the table: "workstation_hardware_device_connections" */
  insertWorkstationHardwareDeviceConnection?: Maybe<Workstation_Hardware_Device_Connections>;
  /** insert data into the table: "workstation_hardware_device_connections" */
  insertWorkstationHardwareDeviceConnections?: Maybe<Workstation_Hardware_Device_Connections_Mutation_Response>;
  /** insert data into the table: "workstation_picking_categories" */
  insertWorkstationPickingCategories?: Maybe<Workstation_Picking_Categories_Mutation_Response>;
  /** insert a single row into the table: "workstation_picking_categories" */
  insertWorkstationPickingCategory?: Maybe<Workstation_Picking_Categories>;
  /** insert data into the table: "workstations" */
  insertWorkstations?: Maybe<Workstations_Mutation_Response>;
  /** execute VOLATILE function "maintain_dismantling_plan_demands" which returns "dismantling_plan_demands" */
  maintain_dismantling_plan_demands: Array<Dismantling_Plan_Demands>;
  /** execute VOLATILE function "maintain_product_group_associations" which returns "product_group_members" */
  maintain_product_group_associations: Array<Product_Group_Members>;
  /** execute VOLATILE function "merge_pdfs" which returns "s3_objects" */
  merge_pdfs: Array<S3_Objects>;
  /** execute VOLATILE function "move_supplier_order_item_delivery_date" which returns "supplier_order_items" */
  move_supplier_order_item_delivery_date: Array<Supplier_Order_Items>;
  /** execute VOLATILE function "order_supplied_products" which returns "supplier_orders" */
  order_supplied_products: Array<Supplier_Orders>;
  printJscript?: Maybe<PrintJscriptOutput>;
  printJscriptMultiple?: Maybe<PrintJscriptOutput>;
  printJscriptWithTemplate?: Maybe<PrintJscriptOutput>;
  printPickingAddressLabelForCo?: Maybe<PrintJscriptOutput>;
  printPickingLabelForPickedItem?: Maybe<PrintJscriptOutput>;
  printPickingLabelsForCoi?: Maybe<PrintJscriptOutput>;
  printPickingSummaryLabelForCoi?: Maybe<PrintJscriptOutput>;
  printStockContainerLabels?: Maybe<PrintJscriptOutput>;
  /** execute VOLATILE function "print_address_label_for_picking" which returns "proletarian_jobs" */
  print_address_label_for_picking: Array<Proletarian_Jobs>;
  /** execute VOLATILE function "print_picking_item_label_for_picked_item" which returns "proletarian_jobs" */
  print_picking_item_label_for_picked_item: Array<Proletarian_Jobs>;
  /** execute VOLATILE function "print_picking_item_labels_for_coi" which returns "proletarian_jobs" */
  print_picking_item_labels_for_coi: Array<Proletarian_Jobs>;
  /** execute VOLATILE function "print_picking_summary_label_for_coi" which returns "proletarian_jobs" */
  print_picking_summary_label_for_coi: Array<Proletarian_Jobs>;
  /** execute VOLATILE function "print_stock_container_labels" which returns "proletarian_jobs" */
  print_stock_container_labels: Array<Proletarian_Jobs>;
  /** execute VOLATILE function "product_set_s3_object_as_product_image" which returns "images" */
  product_set_s3_object_as_product_image?: Maybe<Images>;
  /** execute VOLATILE function "recalculate_unlocked_coi_prices" which returns "customer_order_items" */
  recalculate_unlocked_coi_prices: Array<Customer_Order_Items>;
  /** execute VOLATILE function "request_cutting_pattern_draft_approval" which returns "cutting_patterns_draft" */
  request_cutting_pattern_draft_approval: Array<Cutting_Patterns_Draft>;
  resumeBizerbaPickingFlow?: Maybe<ResumeBizerbaPickingFlowOutput>;
  resumeLeichMehlPickingFlow?: Maybe<ResumeLeichMehlPickingFlowOutput>;
  /** execute VOLATILE function "revert_cutting_pattern_draft_changes" which returns "cutting_patterns_draft" */
  revert_cutting_pattern_draft_changes: Array<Cutting_Patterns_Draft>;
  /** execute VOLATILE function "revert_journal_entries" which returns "journal_entries" */
  revert_journal_entries: Array<Journal_Entries>;
  /** execute VOLATILE function "revert_jscript_template_from_logged_action" which returns "jscript_templates" */
  revert_jscript_template_from_logged_action: Array<Jscript_Templates>;
  /** execute VOLATILE function "set_exchange_rate" which returns "exchange_rates" */
  set_exchange_rate: Array<Exchange_Rates>;
  setupBizerbaPickingFlow?: Maybe<SetupBizerbaPickingFlowOutput>;
  setupLeichMehlPickingFlow?: Maybe<SetupLeichMehlPickingFlowOutput>;
  /** update single row of the table: "accounts" */
  updateAccountByPk?: Maybe<Accounts>;
  /** update data of the table: "accounts" */
  updateAccounts?: Maybe<Accounts_Mutation_Response>;
  /** update data of the table: "aggregated_inventory_items_by_location" */
  updateAggregatedInventoryItemsByLocations?: Maybe<Aggregated_Inventory_Items_By_Location_Mutation_Response>;
  /** update single row of the table: "contact_assigned_roles" */
  updateContactAssignedRoleByPk?: Maybe<Contact_Assigned_Roles>;
  /** update data of the table: "contact_assigned_roles" */
  updateContactAssignedRoles?: Maybe<Contact_Assigned_Roles_Mutation_Response>;
  /** update data of the table: "contact_availability" */
  updateContactAvailabilities?: Maybe<Contact_Availability_Mutation_Response>;
  /** update single row of the table: "contact_availability" */
  updateContactAvailabilityByPk?: Maybe<Contact_Availability>;
  /** update single row of the table: "contact_availability_ranges" */
  updateContactAvailabilityRangeByPk?: Maybe<Contact_Availability_Ranges>;
  /** update data of the table: "contact_availability_ranges" */
  updateContactAvailabilityRanges?: Maybe<Contact_Availability_Ranges_Mutation_Response>;
  /** update single row of the table: "contacts" */
  updateContactByPk?: Maybe<Contacts>;
  /** update data of the table: "contacts" */
  updateContacts?: Maybe<Contacts_Mutation_Response>;
  /** update single row of the table: "credit_notes" */
  updateCreditNoteByPk?: Maybe<Credit_Notes>;
  /** update single row of the table: "credit_note_items" */
  updateCreditNoteItemByPk?: Maybe<Credit_Note_Items>;
  /** update data of the table: "credit_note_items" */
  updateCreditNoteItems?: Maybe<Credit_Note_Items_Mutation_Response>;
  /** update data of the table: "credit_notes" */
  updateCreditNotes?: Maybe<Credit_Notes_Mutation_Response>;
  /** update single row of the table: "customer_addresses" */
  updateCustomerAddressByPk?: Maybe<Customer_Addresses>;
  /** update data of the table: "customer_addresses" */
  updateCustomerAddresses?: Maybe<Customer_Addresses_Mutation_Response>;
  /** update single row of the table: "customer_billing_settings" */
  updateCustomerBillingSettingByPk?: Maybe<Customer_Billing_Settings>;
  /** update data of the table: "customer_billing_settings" */
  updateCustomerBillingSettings?: Maybe<Customer_Billing_Settings_Mutation_Response>;
  /** update single row of the table: "customers" */
  updateCustomerByPk?: Maybe<Customers>;
  /** update data of the table: "customer_delivery_availability" */
  updateCustomerDeliveryAvailabilities?: Maybe<Customer_Delivery_Availability_Mutation_Response>;
  /** update single row of the table: "customer_delivery_availability" */
  updateCustomerDeliveryAvailabilityByPk?: Maybe<Customer_Delivery_Availability>;
  /** update single row of the table: "customer_groups" */
  updateCustomerGroupByPk?: Maybe<Customer_Groups>;
  /** update single row of the table: "customer_group_pricing" */
  updateCustomerGroupPricingByPk?: Maybe<Customer_Group_Pricing>;
  /** update data of the table: "customer_group_pricing" */
  updateCustomerGroupPricings?: Maybe<Customer_Group_Pricing_Mutation_Response>;
  /** update data of the table: "customer_groups" */
  updateCustomerGroups?: Maybe<Customer_Groups_Mutation_Response>;
  /** update single row of the table: "customer_invoices" */
  updateCustomerInvoiceByPk?: Maybe<Customer_Invoices>;
  /** update single row of the table: "customer_invoice_orders" */
  updateCustomerInvoiceOrderByPk?: Maybe<Customer_Invoice_Orders>;
  /** update data of the table: "customer_invoice_orders" */
  updateCustomerInvoiceOrders?: Maybe<Customer_Invoice_Orders_Mutation_Response>;
  /** update single row of the table: "customer_invoice_versions" */
  updateCustomerInvoiceVersionByPk?: Maybe<Customer_Invoice_Versions>;
  /** update data of the table: "customer_invoice_versions" */
  updateCustomerInvoiceVersions?: Maybe<Customer_Invoice_Versions_Mutation_Response>;
  /** update data of the table: "customer_invoices" */
  updateCustomerInvoices?: Maybe<Customer_Invoices_Mutation_Response>;
  /** update single row of the table: "customer_orders" */
  updateCustomerOrderByPk?: Maybe<Customer_Orders>;
  /** update single row of the table: "customer_order_container_returns" */
  updateCustomerOrderContainerReturnByPk?: Maybe<Customer_Order_Container_Returns>;
  /** update single row of the table: "customer_order_container_return_items" */
  updateCustomerOrderContainerReturnItemByPk?: Maybe<Customer_Order_Container_Return_Items>;
  /** update data of the table: "customer_order_container_return_items" */
  updateCustomerOrderContainerReturnItems?: Maybe<Customer_Order_Container_Return_Items_Mutation_Response>;
  /** update data of the table: "customer_order_container_returns" */
  updateCustomerOrderContainerReturns?: Maybe<Customer_Order_Container_Returns_Mutation_Response>;
  /** update single row of the table: "customer_order_item_adjustments" */
  updateCustomerOrderItemAdjustmentByPk?: Maybe<Customer_Order_Item_Adjustments>;
  /** update data of the table: "customer_order_item_adjustments" */
  updateCustomerOrderItemAdjustments?: Maybe<Customer_Order_Item_Adjustments_Mutation_Response>;
  /** update single row of the table: "customer_order_items" */
  updateCustomerOrderItemByPk?: Maybe<Customer_Order_Items>;
  /** update single row of the table: "customer_order_item_container_expectations" */
  updateCustomerOrderItemContainerExpectationByPk?: Maybe<Customer_Order_Item_Container_Expectations>;
  /** update data of the table: "customer_order_item_container_expectations" */
  updateCustomerOrderItemContainerExpectations?: Maybe<Customer_Order_Item_Container_Expectations_Mutation_Response>;
  /** update single row of the table: "customer_order_item_fulfillment_plans" */
  updateCustomerOrderItemFulfillmentPlanByPk?: Maybe<Customer_Order_Item_Fulfillment_Plans>;
  /** update data of the table: "customer_order_item_fulfillment_plans" */
  updateCustomerOrderItemFulfillmentPlans?: Maybe<Customer_Order_Item_Fulfillment_Plans_Mutation_Response>;
  /** update single row of the table: "customer_order_item_return_causes" */
  updateCustomerOrderItemReturnCauseByPk?: Maybe<Customer_Order_Item_Return_Causes>;
  /** update data of the table: "customer_order_item_return_causes" */
  updateCustomerOrderItemReturnCauses?: Maybe<Customer_Order_Item_Return_Causes_Mutation_Response>;
  /** update data of the table: "customer_order_items" */
  updateCustomerOrderItems?: Maybe<Customer_Order_Items_Mutation_Response>;
  /** update single row of the table: "customer_order_picked_container" */
  updateCustomerOrderPickedContainerByPk?: Maybe<Customer_Order_Picked_Container>;
  /** update data of the table: "customer_order_picked_container" */
  updateCustomerOrderPickedContainers?: Maybe<Customer_Order_Picked_Container_Mutation_Response>;
  /** update single row of the table: "customer_order_returns" */
  updateCustomerOrderReturnByPk?: Maybe<Customer_Order_Returns>;
  /** update single row of the table: "customer_order_return_items" */
  updateCustomerOrderReturnItemByPk?: Maybe<Customer_Order_Return_Items>;
  /** update single row of the table: "customer_order_return_item_containers" */
  updateCustomerOrderReturnItemContainerByPk?: Maybe<Customer_Order_Return_Item_Containers>;
  /** update data of the table: "customer_order_return_item_containers" */
  updateCustomerOrderReturnItemContainers?: Maybe<Customer_Order_Return_Item_Containers_Mutation_Response>;
  /** update data of the table: "customer_order_return_items" */
  updateCustomerOrderReturnItems?: Maybe<Customer_Order_Return_Items_Mutation_Response>;
  /** update data of the table: "customer_order_returns" */
  updateCustomerOrderReturns?: Maybe<Customer_Order_Returns_Mutation_Response>;
  /** update data of the table: "customer_orders" */
  updateCustomerOrders?: Maybe<Customer_Orders_Mutation_Response>;
  /** update single row of the table: "customer_payment_terms" */
  updateCustomerPaymentTermByPk?: Maybe<Customer_Payment_Terms>;
  /** update data of the table: "customer_payment_terms" */
  updateCustomerPaymentTerms?: Maybe<Customer_Payment_Terms_Mutation_Response>;
  /** update data of the table: "customer_product_availabilities" */
  updateCustomerProductAvailabilities?: Maybe<Customer_Product_Availabilities_Mutation_Response>;
  /** update single row of the table: "customer_product_availabilities" */
  updateCustomerProductAvailabilityByPk?: Maybe<Customer_Product_Availabilities>;
  /** update single row of the table: "customer_template_settings" */
  updateCustomerTemplateSettingByPk?: Maybe<Customer_Template_Settings>;
  /** update data of the table: "customer_template_settings" */
  updateCustomerTemplateSettings?: Maybe<Customer_Template_Settings_Mutation_Response>;
  /** update data of the table: "customers" */
  updateCustomers?: Maybe<Customers_Mutation_Response>;
  /** update single row of the table: "cutting_patterns" */
  updateCuttingPatternByPk?: Maybe<Cutting_Patterns>;
  /** update single row of the table: "cutting_pattern_outputs" */
  updateCuttingPatternOutputByPk?: Maybe<Cutting_Pattern_Outputs>;
  /** update data of the table: "cutting_pattern_outputs" */
  updateCuttingPatternOutputs?: Maybe<Cutting_Pattern_Outputs_Mutation_Response>;
  /** update single row of the table: "cutting_pattern_outputs_draft" */
  updateCuttingPatternOutputsDraftByPk?: Maybe<Cutting_Pattern_Outputs_Draft>;
  /** update data of the table: "cutting_pattern_outputs_draft" */
  updateCuttingPatternOutputsDrafts?: Maybe<Cutting_Pattern_Outputs_Draft_Mutation_Response>;
  /** update data of the table: "cutting_patterns" */
  updateCuttingPatterns?: Maybe<Cutting_Patterns_Mutation_Response>;
  /** update single row of the table: "cutting_patterns_draft" */
  updateCuttingPatternsDraftByPk?: Maybe<Cutting_Patterns_Draft>;
  /** update data of the table: "cutting_patterns_draft" */
  updateCuttingPatternsDrafts?: Maybe<Cutting_Patterns_Draft_Mutation_Response>;
  /** update single row of the table: "dismantling_plans" */
  updateDismantlingPlanByPk?: Maybe<Dismantling_Plans>;
  /** update single row of the table: "dismantling_plan_cutting_pattern_choices" */
  updateDismantlingPlanCuttingPatternChoiceByPk?: Maybe<Dismantling_Plan_Cutting_Pattern_Choices>;
  /** update data of the table: "dismantling_plan_cutting_pattern_choices" */
  updateDismantlingPlanCuttingPatternChoices?: Maybe<Dismantling_Plan_Cutting_Pattern_Choices_Mutation_Response>;
  /** update single row of the table: "dismantling_plan_demands" */
  updateDismantlingPlanDemandByPk?: Maybe<Dismantling_Plan_Demands>;
  /** update data of the table: "dismantling_plan_demands" */
  updateDismantlingPlanDemands?: Maybe<Dismantling_Plan_Demands_Mutation_Response>;
  /** update single row of the table: "dismantling_plan_instructions" */
  updateDismantlingPlanInstructionByPk?: Maybe<Dismantling_Plan_Instructions>;
  /** update data of the table: "dismantling_plan_instructions" */
  updateDismantlingPlanInstructions?: Maybe<Dismantling_Plan_Instructions_Mutation_Response>;
  /** update single row of the table: "dismantling_plan_remaining_pieces" */
  updateDismantlingPlanRemainingPieceByPk?: Maybe<Dismantling_Plan_Remaining_Pieces>;
  /** update data of the table: "dismantling_plan_remaining_pieces" */
  updateDismantlingPlanRemainingPieces?: Maybe<Dismantling_Plan_Remaining_Pieces_Mutation_Response>;
  /** update data of the table: "dismantling_plans" */
  updateDismantlingPlans?: Maybe<Dismantling_Plans_Mutation_Response>;
  /** update single row of the table: "emails" */
  updateEmailByPk?: Maybe<Emails>;
  /** update data of the table: "emails" */
  updateEmails?: Maybe<Emails_Mutation_Response>;
  /** update single row of the table: "exchange_rates" */
  updateExchangeRateByPk?: Maybe<Exchange_Rates>;
  /** update data of the table: "exchange_rates" */
  updateExchangeRates?: Maybe<Exchange_Rates_Mutation_Response>;
  /** update single row of the table: "gs1_logistic_types" */
  updateGs1LogisticTypeByPk?: Maybe<Gs1_Logistic_Types>;
  /** update data of the table: "gs1_logistic_types" */
  updateGs1LogisticTypes?: Maybe<Gs1_Logistic_Types_Mutation_Response>;
  /** update single row of the table: "hardware_devices" */
  updateHardwareDeviceByPk?: Maybe<Hardware_Devices>;
  /** update data of the table: "hardware_devices" */
  updateHardwareDevices?: Maybe<Hardware_Devices_Mutation_Response>;
  /** update single row of the table: "hierarchical_goods_incomes" */
  updateHierarchicalGoodsIncomeByPk?: Maybe<Hierarchical_Goods_Incomes>;
  /** update data of the table: "hierarchical_goods_incomes" */
  updateHierarchicalGoodsIncomes?: Maybe<Hierarchical_Goods_Incomes_Mutation_Response>;
  /** update single row of the table: "images" */
  updateImageByPk?: Maybe<Images>;
  /** update data of the table: "images" */
  updateImages?: Maybe<Images_Mutation_Response>;
  /** update data of the table: "inventory_items" */
  updateInventoryItems?: Maybe<Inventory_Items_Mutation_Response>;
  /** update data of the table: "inventory_items_by_location" */
  updateInventoryItemsByLocations?: Maybe<Inventory_Items_By_Location_Mutation_Response>;
  /** update data of the table: "journal_entries" */
  updateJournalEntries?: Maybe<Journal_Entries_Mutation_Response>;
  /** update single row of the table: "journal_entries" */
  updateJournalEntryByPk?: Maybe<Journal_Entries>;
  /** update single row of the table: "jscript_templates" */
  updateJscriptTemplateByPk?: Maybe<Jscript_Templates>;
  /** update data of the table: "jscript_templates" */
  updateJscriptTemplates?: Maybe<Jscript_Templates_Mutation_Response>;
  /** update single row of the table: "lot_numbers" */
  updateLotNumberByPk?: Maybe<Lot_Numbers>;
  /** update single row of the table: "lot_number_products" */
  updateLotNumberProductByPk?: Maybe<Lot_Number_Products>;
  /** update data of the table: "lot_number_products" */
  updateLotNumberProducts?: Maybe<Lot_Number_Products_Mutation_Response>;
  /** update data of the table: "lot_numbers" */
  updateLotNumbers?: Maybe<Lot_Numbers_Mutation_Response>;
  /** update single row of the table: "notifications" */
  updateNotificationByPk?: Maybe<Notifications>;
  /** update data of the table: "notifications" */
  updateNotifications?: Maybe<Notifications_Mutation_Response>;
  /** update data of the table: "partial_deliveries" */
  updatePartialDeliveries?: Maybe<Partial_Deliveries_Mutation_Response>;
  /** update single row of the table: "partial_deliveries" */
  updatePartialDeliveryByPk?: Maybe<Partial_Deliveries>;
  /** update single row of the table: "phones" */
  updatePhoneByPk?: Maybe<Phones>;
  /** update data of the table: "phones" */
  updatePhones?: Maybe<Phones_Mutation_Response>;
  /** update single row of the table: "picked_items" */
  updatePickedItemByPk?: Maybe<Picked_Items>;
  /** update single row of the table: "picked_item_containers" */
  updatePickedItemContainerByPk?: Maybe<Picked_Item_Containers>;
  /** update data of the table: "picked_item_containers" */
  updatePickedItemContainers?: Maybe<Picked_Item_Containers_Mutation_Response>;
  /** update data of the table: "picked_items" */
  updatePickedItems?: Maybe<Picked_Items_Mutation_Response>;
  /** update data of the table: "picking_categories" */
  updatePickingCategories?: Maybe<Picking_Categories_Mutation_Response>;
  /** update single row of the table: "picking_categories" */
  updatePickingCategoryByPk?: Maybe<Picking_Categories>;
  /** update single row of the table: "price_lists" */
  updatePriceListByPk?: Maybe<Price_Lists>;
  /** update data of the table: "price_lists" */
  updatePriceLists?: Maybe<Price_Lists_Mutation_Response>;
  /** update single row of the table: "product_allowed_containers" */
  updateProductAllowedContainerByPk?: Maybe<Product_Allowed_Containers>;
  /** update data of the table: "product_allowed_containers" */
  updateProductAllowedContainers?: Maybe<Product_Allowed_Containers_Mutation_Response>;
  /** update single row of the table: "products" */
  updateProductByPk?: Maybe<Products>;
  /** update single row of the table: "product_groups" */
  updateProductGroupByPk?: Maybe<Product_Groups>;
  /** update single row of the table: "product_group_members" */
  updateProductGroupMemberByPk?: Maybe<Product_Group_Members>;
  /** update data of the table: "product_group_members" */
  updateProductGroupMembers?: Maybe<Product_Group_Members_Mutation_Response>;
  /** update data of the table: "product_groups" */
  updateProductGroups?: Maybe<Product_Groups_Mutation_Response>;
  /** update single row of the table: "product_label_settings" */
  updateProductLabelSettingByPk?: Maybe<Product_Label_Settings>;
  /** update data of the table: "product_label_settings" */
  updateProductLabelSettings?: Maybe<Product_Label_Settings_Mutation_Response>;
  /** update single row of the table: "product_packaging_configurations" */
  updateProductPackagingConfigurationByPk?: Maybe<Product_Packaging_Configurations>;
  /** update data of the table: "product_packaging_configurations" */
  updateProductPackagingConfigurations?: Maybe<Product_Packaging_Configurations_Mutation_Response>;
  /** update single row of the table: "product_recipes" */
  updateProductRecipeByPk?: Maybe<Product_Recipes>;
  /** update single row of the table: "product_recipe_ingredients" */
  updateProductRecipeIngredientByPk?: Maybe<Product_Recipe_Ingredients>;
  /** update data of the table: "product_recipe_ingredients" */
  updateProductRecipeIngredients?: Maybe<Product_Recipe_Ingredients_Mutation_Response>;
  /** update single row of the table: "product_recipe_steps" */
  updateProductRecipeStepByPk?: Maybe<Product_Recipe_Steps>;
  /** update data of the table: "product_recipe_steps" */
  updateProductRecipeSteps?: Maybe<Product_Recipe_Steps_Mutation_Response>;
  /** update data of the table: "product_recipes" */
  updateProductRecipes?: Maybe<Product_Recipes_Mutation_Response>;
  /** update single row of the table: "product_s3_objects" */
  updateProductS3ObjectByPk?: Maybe<Product_S3_Objects>;
  /** update data of the table: "product_s3_objects" */
  updateProductS3Objects?: Maybe<Product_S3_Objects_Mutation_Response>;
  /** update single row of the table: "product_sales_channel_members" */
  updateProductSalesChannelMemberByPk?: Maybe<Product_Sales_Channel_Members>;
  /** update data of the table: "product_sales_channel_members" */
  updateProductSalesChannelMembers?: Maybe<Product_Sales_Channel_Members_Mutation_Response>;
  /** update single row of the table: "product_stock_locations" */
  updateProductStockLocationByPk?: Maybe<Product_Stock_Locations>;
  /** update data of the table: "product_stock_locations" */
  updateProductStockLocations?: Maybe<Product_Stock_Locations_Mutation_Response>;
  /** update single row of the table: "product_suppliers" */
  updateProductSupplierByPk?: Maybe<Product_Suppliers>;
  /** update data of the table: "product_suppliers" */
  updateProductSuppliers?: Maybe<Product_Suppliers_Mutation_Response>;
  /** update single row of the table: "product_units" */
  updateProductUnitByPk?: Maybe<Product_Units>;
  /** update single row of the table: "product_unit_sales_price_config" */
  updateProductUnitSalesPriceConfigByPk?: Maybe<Product_Unit_Sales_Price_Config>;
  /** update data of the table: "product_unit_sales_price_config" */
  updateProductUnitSalesPriceConfigs?: Maybe<Product_Unit_Sales_Price_Config_Mutation_Response>;
  /** update data of the table: "product_units" */
  updateProductUnits?: Maybe<Product_Units_Mutation_Response>;
  /** update single row of the table: "production_sites" */
  updateProductionSiteByPk?: Maybe<Production_Sites>;
  /** update single row of the table: "production_site_input_stock_locations" */
  updateProductionSiteInputStockLocationByPk?: Maybe<Production_Site_Input_Stock_Locations>;
  /** update data of the table: "production_site_input_stock_locations" */
  updateProductionSiteInputStockLocations?: Maybe<Production_Site_Input_Stock_Locations_Mutation_Response>;
  /** update single row of the table: "production_site_output_stock_locations" */
  updateProductionSiteOutputStockLocationByPk?: Maybe<Production_Site_Output_Stock_Locations>;
  /** update data of the table: "production_site_output_stock_locations" */
  updateProductionSiteOutputStockLocations?: Maybe<Production_Site_Output_Stock_Locations_Mutation_Response>;
  /** update single row of the table: "production_site_product_group_calendar" */
  updateProductionSiteProductGroupCalendarByPk?: Maybe<Production_Site_Product_Group_Calendar>;
  /** update data of the table: "production_site_product_group_calendar" */
  updateProductionSiteProductGroupCalendars?: Maybe<Production_Site_Product_Group_Calendar_Mutation_Response>;
  /** update data of the table: "production_sites" */
  updateProductionSites?: Maybe<Production_Sites_Mutation_Response>;
  /** update data of the table: "products" */
  updateProducts?: Maybe<Products_Mutation_Response>;
  /** update data of the table: "rolling_inventories" */
  updateRollingInventories?: Maybe<Rolling_Inventories_Mutation_Response>;
  /** update single row of the table: "rolling_inventory_actors" */
  updateRollingInventoryActorByPk?: Maybe<Rolling_Inventory_Actors>;
  /** update data of the table: "rolling_inventory_actors" */
  updateRollingInventoryActors?: Maybe<Rolling_Inventory_Actors_Mutation_Response>;
  /** update single row of the table: "rolling_inventories" */
  updateRollingInventoryByPk?: Maybe<Rolling_Inventories>;
  /** update single row of the table: "rolling_inventory_items" */
  updateRollingInventoryItemByPk?: Maybe<Rolling_Inventory_Items>;
  /** update data of the table: "rolling_inventory_items" */
  updateRollingInventoryItems?: Maybe<Rolling_Inventory_Items_Mutation_Response>;
  /** update single row of the table: "s3_objects" */
  updateS3ObjectByPk?: Maybe<S3_Objects>;
  /** update data of the table: "s3_objects" */
  updateS3Objects?: Maybe<S3_Objects_Mutation_Response>;
  /** update single row of the table: "sales_teams" */
  updateSalesTeamByPk?: Maybe<Sales_Teams>;
  /** update single row of the table: "sales_team_users" */
  updateSalesTeamUserByPk?: Maybe<Sales_Team_Users>;
  /** update data of the table: "sales_team_users" */
  updateSalesTeamUsers?: Maybe<Sales_Team_Users_Mutation_Response>;
  /** update data of the table: "sales_teams" */
  updateSalesTeams?: Maybe<Sales_Teams_Mutation_Response>;
  /** update single row of the table: "stock_locations" */
  updateStockLocationByPk?: Maybe<Stock_Locations>;
  /** update data of the table: "stock_locations" */
  updateStockLocations?: Maybe<Stock_Locations_Mutation_Response>;
  /** update single row of the table: "stock_transactions" */
  updateStockTransactionByPk?: Maybe<Stock_Transactions>;
  /** update data of the table: "stock_transactions" */
  updateStockTransactions?: Maybe<Stock_Transactions_Mutation_Response>;
  /** update single row of the table: "supplier_addresses" */
  updateSupplierAddressByPk?: Maybe<Supplier_Addresses>;
  /** update data of the table: "supplier_addresses" */
  updateSupplierAddresses?: Maybe<Supplier_Addresses_Mutation_Response>;
  /** update single row of the table: "suppliers" */
  updateSupplierByPk?: Maybe<Suppliers>;
  /** update single row of the table: "supplier_orders" */
  updateSupplierOrderByPk?: Maybe<Supplier_Orders>;
  /** update single row of the table: "supplier_order_goods_income_items" */
  updateSupplierOrderGoodsIncomeItemByPk?: Maybe<Supplier_Order_Goods_Income_Items>;
  /** update single row of the table: "supplier_order_goods_income_item_containers" */
  updateSupplierOrderGoodsIncomeItemContainerByPk?: Maybe<Supplier_Order_Goods_Income_Item_Containers>;
  /** update data of the table: "supplier_order_goods_income_item_containers" */
  updateSupplierOrderGoodsIncomeItemContainers?: Maybe<Supplier_Order_Goods_Income_Item_Containers_Mutation_Response>;
  /** update data of the table: "supplier_order_goods_income_items" */
  updateSupplierOrderGoodsIncomeItems?: Maybe<Supplier_Order_Goods_Income_Items_Mutation_Response>;
  /** update single row of the table: "supplier_order_items" */
  updateSupplierOrderItemByPk?: Maybe<Supplier_Order_Items>;
  /** update single row of the table: "supplier_order_item_return_causes" */
  updateSupplierOrderItemReturnCauseByPk?: Maybe<Supplier_Order_Item_Return_Causes>;
  /** update data of the table: "supplier_order_item_return_causes" */
  updateSupplierOrderItemReturnCauses?: Maybe<Supplier_Order_Item_Return_Causes_Mutation_Response>;
  /** update data of the table: "supplier_order_items" */
  updateSupplierOrderItems?: Maybe<Supplier_Order_Items_Mutation_Response>;
  /** update single row of the table: "supplier_order_returns" */
  updateSupplierOrderReturnByPk?: Maybe<Supplier_Order_Returns>;
  /** update single row of the table: "supplier_order_return_items" */
  updateSupplierOrderReturnItemByPk?: Maybe<Supplier_Order_Return_Items>;
  /** update single row of the table: "supplier_order_return_item_containers" */
  updateSupplierOrderReturnItemContainerByPk?: Maybe<Supplier_Order_Return_Item_Containers>;
  /** update data of the table: "supplier_order_return_item_containers" */
  updateSupplierOrderReturnItemContainers?: Maybe<Supplier_Order_Return_Item_Containers_Mutation_Response>;
  /** update data of the table: "supplier_order_return_items" */
  updateSupplierOrderReturnItems?: Maybe<Supplier_Order_Return_Items_Mutation_Response>;
  /** update single row of the table: "supplier_order_returned_containers" */
  updateSupplierOrderReturnedContainerByPk?: Maybe<Supplier_Order_Returned_Containers>;
  /** update data of the table: "supplier_order_returned_containers" */
  updateSupplierOrderReturnedContainers?: Maybe<Supplier_Order_Returned_Containers_Mutation_Response>;
  /** update data of the table: "supplier_order_returns" */
  updateSupplierOrderReturns?: Maybe<Supplier_Order_Returns_Mutation_Response>;
  /** update data of the table: "supplier_orders" */
  updateSupplierOrders?: Maybe<Supplier_Orders_Mutation_Response>;
  /** update data of the table: "suppliers" */
  updateSuppliers?: Maybe<Suppliers_Mutation_Response>;
  /** update single row of the table: "temporal_products" */
  updateTemporalProductByPk?: Maybe<Temporal_Products>;
  /** update data of the table: "temporal_products" */
  updateTemporalProducts?: Maybe<Temporal_Products_Mutation_Response>;
  /** update single row of the table: "temporal_sales_teams_customers" */
  updateTemporalSalesTeamsCustomerByPk?: Maybe<Temporal_Sales_Teams_Customers>;
  /** update data of the table: "temporal_sales_teams_customers" */
  updateTemporalSalesTeamsCustomers?: Maybe<Temporal_Sales_Teams_Customers_Mutation_Response>;
  /** update single row of the table: "tenant_bank_accounts" */
  updateTenantBankAccountByPk?: Maybe<Tenant_Bank_Accounts>;
  /** update data of the table: "tenant_bank_accounts" */
  updateTenantBankAccounts?: Maybe<Tenant_Bank_Accounts_Mutation_Response>;
  /** update single row of the table: "todos" */
  updateTodoByPk?: Maybe<Todos>;
  /** update data of the table: "todos" */
  updateTodos?: Maybe<Todos_Mutation_Response>;
  /** update single row of the table: "tours" */
  updateTourByPk?: Maybe<Tours>;
  /** update single row of the table: "tour_routes" */
  updateTourRouteByPk?: Maybe<Tour_Routes>;
  /** update data of the table: "tour_routes" */
  updateTourRoutes?: Maybe<Tour_Routes_Mutation_Response>;
  /** update data of the table: "tours" */
  updateTours?: Maybe<Tours_Mutation_Response>;
  /** update single row of the table: "users" */
  updateUserByPk?: Maybe<Users>;
  updateUserPassword?: Maybe<UpdateUserPasswordOutput>;
  updateUserRoles?: Maybe<UpdateUserRolesOutput>;
  /** update single row of the table: "user_sales_goals" */
  updateUserSalesGoalByPk?: Maybe<User_Sales_Goals>;
  /** update data of the table: "user_sales_goals" */
  updateUserSalesGoals?: Maybe<User_Sales_Goals_Mutation_Response>;
  /** update data of the table: "users" */
  updateUsers?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "work_calendar" */
  updateWorkCalendarByPk?: Maybe<Work_Calendar>;
  /** update data of the table: "work_calendar" */
  updateWorkCalendars?: Maybe<Work_Calendar_Mutation_Response>;
  /** update single row of the table: "workstations" */
  updateWorkstationByPk?: Maybe<Workstations>;
  /** update single row of the table: "workstation_hardware_device_connections" */
  updateWorkstationHardwareDeviceConnectionByPk?: Maybe<Workstation_Hardware_Device_Connections>;
  /** update data of the table: "workstation_hardware_device_connections" */
  updateWorkstationHardwareDeviceConnections?: Maybe<Workstation_Hardware_Device_Connections_Mutation_Response>;
  /** update data of the table: "workstation_picking_categories" */
  updateWorkstationPickingCategories?: Maybe<Workstation_Picking_Categories_Mutation_Response>;
  /** update data of the table: "workstations" */
  updateWorkstations?: Maybe<Workstations_Mutation_Response>;
  /** update multiples rows of table: "accounts" */
  update_accounts_many?: Maybe<Array<Maybe<Accounts_Mutation_Response>>>;
  /** update multiples rows of table: "aggregated_inventory_items_by_location" */
  update_aggregated_inventory_items_by_location_many?: Maybe<Array<Maybe<Aggregated_Inventory_Items_By_Location_Mutation_Response>>>;
  /** update multiples rows of table: "contact_assigned_roles" */
  update_contact_assigned_roles_many?: Maybe<Array<Maybe<Contact_Assigned_Roles_Mutation_Response>>>;
  /** update multiples rows of table: "contact_availability" */
  update_contact_availability_many?: Maybe<Array<Maybe<Contact_Availability_Mutation_Response>>>;
  /** update multiples rows of table: "contact_availability_ranges" */
  update_contact_availability_ranges_many?: Maybe<Array<Maybe<Contact_Availability_Ranges_Mutation_Response>>>;
  /** update multiples rows of table: "contacts" */
  update_contacts_many?: Maybe<Array<Maybe<Contacts_Mutation_Response>>>;
  /** update multiples rows of table: "credit_note_items" */
  update_credit_note_items_many?: Maybe<Array<Maybe<Credit_Note_Items_Mutation_Response>>>;
  /** update multiples rows of table: "credit_notes" */
  update_credit_notes_many?: Maybe<Array<Maybe<Credit_Notes_Mutation_Response>>>;
  /** update multiples rows of table: "customer_addresses" */
  update_customer_addresses_many?: Maybe<Array<Maybe<Customer_Addresses_Mutation_Response>>>;
  /** update multiples rows of table: "customer_billing_settings" */
  update_customer_billing_settings_many?: Maybe<Array<Maybe<Customer_Billing_Settings_Mutation_Response>>>;
  /** update multiples rows of table: "customer_delivery_availability" */
  update_customer_delivery_availability_many?: Maybe<Array<Maybe<Customer_Delivery_Availability_Mutation_Response>>>;
  /** update multiples rows of table: "customer_group_pricing" */
  update_customer_group_pricing_many?: Maybe<Array<Maybe<Customer_Group_Pricing_Mutation_Response>>>;
  /** update multiples rows of table: "customer_groups" */
  update_customer_groups_many?: Maybe<Array<Maybe<Customer_Groups_Mutation_Response>>>;
  /** update multiples rows of table: "customer_invoice_orders" */
  update_customer_invoice_orders_many?: Maybe<Array<Maybe<Customer_Invoice_Orders_Mutation_Response>>>;
  /** update multiples rows of table: "customer_invoice_versions" */
  update_customer_invoice_versions_many?: Maybe<Array<Maybe<Customer_Invoice_Versions_Mutation_Response>>>;
  /** update multiples rows of table: "customer_invoices" */
  update_customer_invoices_many?: Maybe<Array<Maybe<Customer_Invoices_Mutation_Response>>>;
  /** update multiples rows of table: "customer_order_container_return_items" */
  update_customer_order_container_return_items_many?: Maybe<Array<Maybe<Customer_Order_Container_Return_Items_Mutation_Response>>>;
  /** update multiples rows of table: "customer_order_container_returns" */
  update_customer_order_container_returns_many?: Maybe<Array<Maybe<Customer_Order_Container_Returns_Mutation_Response>>>;
  /** update multiples rows of table: "customer_order_item_adjustments" */
  update_customer_order_item_adjustments_many?: Maybe<Array<Maybe<Customer_Order_Item_Adjustments_Mutation_Response>>>;
  /** update multiples rows of table: "customer_order_item_container_expectations" */
  update_customer_order_item_container_expectations_many?: Maybe<Array<Maybe<Customer_Order_Item_Container_Expectations_Mutation_Response>>>;
  /** update multiples rows of table: "customer_order_item_fulfillment_plans" */
  update_customer_order_item_fulfillment_plans_many?: Maybe<Array<Maybe<Customer_Order_Item_Fulfillment_Plans_Mutation_Response>>>;
  /** update multiples rows of table: "customer_order_item_return_causes" */
  update_customer_order_item_return_causes_many?: Maybe<Array<Maybe<Customer_Order_Item_Return_Causes_Mutation_Response>>>;
  /** update multiples rows of table: "customer_order_items" */
  update_customer_order_items_many?: Maybe<Array<Maybe<Customer_Order_Items_Mutation_Response>>>;
  /** update multiples rows of table: "customer_order_picked_container" */
  update_customer_order_picked_container_many?: Maybe<Array<Maybe<Customer_Order_Picked_Container_Mutation_Response>>>;
  /** update multiples rows of table: "customer_order_return_item_containers" */
  update_customer_order_return_item_containers_many?: Maybe<Array<Maybe<Customer_Order_Return_Item_Containers_Mutation_Response>>>;
  /** update multiples rows of table: "customer_order_return_items" */
  update_customer_order_return_items_many?: Maybe<Array<Maybe<Customer_Order_Return_Items_Mutation_Response>>>;
  /** update multiples rows of table: "customer_order_returns" */
  update_customer_order_returns_many?: Maybe<Array<Maybe<Customer_Order_Returns_Mutation_Response>>>;
  /** update multiples rows of table: "customer_orders" */
  update_customer_orders_many?: Maybe<Array<Maybe<Customer_Orders_Mutation_Response>>>;
  /** update multiples rows of table: "customer_payment_terms" */
  update_customer_payment_terms_many?: Maybe<Array<Maybe<Customer_Payment_Terms_Mutation_Response>>>;
  /** update multiples rows of table: "customer_product_availabilities" */
  update_customer_product_availabilities_many?: Maybe<Array<Maybe<Customer_Product_Availabilities_Mutation_Response>>>;
  /** update multiples rows of table: "customer_template_settings" */
  update_customer_template_settings_many?: Maybe<Array<Maybe<Customer_Template_Settings_Mutation_Response>>>;
  /** update multiples rows of table: "customers" */
  update_customers_many?: Maybe<Array<Maybe<Customers_Mutation_Response>>>;
  /** update multiples rows of table: "cutting_pattern_outputs_draft" */
  update_cutting_pattern_outputs_draft_many?: Maybe<Array<Maybe<Cutting_Pattern_Outputs_Draft_Mutation_Response>>>;
  /** update multiples rows of table: "cutting_pattern_outputs" */
  update_cutting_pattern_outputs_many?: Maybe<Array<Maybe<Cutting_Pattern_Outputs_Mutation_Response>>>;
  /** update multiples rows of table: "cutting_patterns_draft" */
  update_cutting_patterns_draft_many?: Maybe<Array<Maybe<Cutting_Patterns_Draft_Mutation_Response>>>;
  /** update multiples rows of table: "cutting_patterns" */
  update_cutting_patterns_many?: Maybe<Array<Maybe<Cutting_Patterns_Mutation_Response>>>;
  /** update multiples rows of table: "dismantling_plan_cutting_pattern_choices" */
  update_dismantling_plan_cutting_pattern_choices_many?: Maybe<Array<Maybe<Dismantling_Plan_Cutting_Pattern_Choices_Mutation_Response>>>;
  /** update multiples rows of table: "dismantling_plan_demands" */
  update_dismantling_plan_demands_many?: Maybe<Array<Maybe<Dismantling_Plan_Demands_Mutation_Response>>>;
  /** update multiples rows of table: "dismantling_plan_instructions" */
  update_dismantling_plan_instructions_many?: Maybe<Array<Maybe<Dismantling_Plan_Instructions_Mutation_Response>>>;
  /** update multiples rows of table: "dismantling_plan_remaining_pieces" */
  update_dismantling_plan_remaining_pieces_many?: Maybe<Array<Maybe<Dismantling_Plan_Remaining_Pieces_Mutation_Response>>>;
  /** update multiples rows of table: "dismantling_plans" */
  update_dismantling_plans_many?: Maybe<Array<Maybe<Dismantling_Plans_Mutation_Response>>>;
  /** update multiples rows of table: "emails" */
  update_emails_many?: Maybe<Array<Maybe<Emails_Mutation_Response>>>;
  /** update multiples rows of table: "exchange_rates" */
  update_exchange_rates_many?: Maybe<Array<Maybe<Exchange_Rates_Mutation_Response>>>;
  /** update multiples rows of table: "gs1_logistic_types" */
  update_gs1_logistic_types_many?: Maybe<Array<Maybe<Gs1_Logistic_Types_Mutation_Response>>>;
  /** update multiples rows of table: "hardware_devices" */
  update_hardware_devices_many?: Maybe<Array<Maybe<Hardware_Devices_Mutation_Response>>>;
  /** update multiples rows of table: "hierarchical_goods_incomes" */
  update_hierarchical_goods_incomes_many?: Maybe<Array<Maybe<Hierarchical_Goods_Incomes_Mutation_Response>>>;
  /** update multiples rows of table: "images" */
  update_images_many?: Maybe<Array<Maybe<Images_Mutation_Response>>>;
  /** update multiples rows of table: "inventory_items_by_location" */
  update_inventory_items_by_location_many?: Maybe<Array<Maybe<Inventory_Items_By_Location_Mutation_Response>>>;
  /** update multiples rows of table: "inventory_items" */
  update_inventory_items_many?: Maybe<Array<Maybe<Inventory_Items_Mutation_Response>>>;
  /** update multiples rows of table: "journal_entries" */
  update_journal_entries_many?: Maybe<Array<Maybe<Journal_Entries_Mutation_Response>>>;
  /** update multiples rows of table: "jscript_templates" */
  update_jscript_templates_many?: Maybe<Array<Maybe<Jscript_Templates_Mutation_Response>>>;
  /** update multiples rows of table: "lot_number_products" */
  update_lot_number_products_many?: Maybe<Array<Maybe<Lot_Number_Products_Mutation_Response>>>;
  /** update multiples rows of table: "lot_numbers" */
  update_lot_numbers_many?: Maybe<Array<Maybe<Lot_Numbers_Mutation_Response>>>;
  /** update multiples rows of table: "notifications" */
  update_notifications_many?: Maybe<Array<Maybe<Notifications_Mutation_Response>>>;
  /** update multiples rows of table: "partial_deliveries" */
  update_partial_deliveries_many?: Maybe<Array<Maybe<Partial_Deliveries_Mutation_Response>>>;
  /** update multiples rows of table: "phones" */
  update_phones_many?: Maybe<Array<Maybe<Phones_Mutation_Response>>>;
  /** update multiples rows of table: "picked_item_containers" */
  update_picked_item_containers_many?: Maybe<Array<Maybe<Picked_Item_Containers_Mutation_Response>>>;
  /** update multiples rows of table: "picked_items" */
  update_picked_items_many?: Maybe<Array<Maybe<Picked_Items_Mutation_Response>>>;
  /** update multiples rows of table: "picking_categories" */
  update_picking_categories_many?: Maybe<Array<Maybe<Picking_Categories_Mutation_Response>>>;
  /** update multiples rows of table: "price_lists" */
  update_price_lists_many?: Maybe<Array<Maybe<Price_Lists_Mutation_Response>>>;
  /** update multiples rows of table: "product_allowed_containers" */
  update_product_allowed_containers_many?: Maybe<Array<Maybe<Product_Allowed_Containers_Mutation_Response>>>;
  /** update multiples rows of table: "product_group_members" */
  update_product_group_members_many?: Maybe<Array<Maybe<Product_Group_Members_Mutation_Response>>>;
  /** update multiples rows of table: "product_groups" */
  update_product_groups_many?: Maybe<Array<Maybe<Product_Groups_Mutation_Response>>>;
  /** update multiples rows of table: "product_label_settings" */
  update_product_label_settings_many?: Maybe<Array<Maybe<Product_Label_Settings_Mutation_Response>>>;
  /** update multiples rows of table: "product_packaging_configurations" */
  update_product_packaging_configurations_many?: Maybe<Array<Maybe<Product_Packaging_Configurations_Mutation_Response>>>;
  /** update multiples rows of table: "product_recipe_ingredients" */
  update_product_recipe_ingredients_many?: Maybe<Array<Maybe<Product_Recipe_Ingredients_Mutation_Response>>>;
  /** update multiples rows of table: "product_recipe_steps" */
  update_product_recipe_steps_many?: Maybe<Array<Maybe<Product_Recipe_Steps_Mutation_Response>>>;
  /** update multiples rows of table: "product_recipes" */
  update_product_recipes_many?: Maybe<Array<Maybe<Product_Recipes_Mutation_Response>>>;
  /** update multiples rows of table: "product_s3_objects" */
  update_product_s3_objects_many?: Maybe<Array<Maybe<Product_S3_Objects_Mutation_Response>>>;
  /** update multiples rows of table: "product_sales_channel_members" */
  update_product_sales_channel_members_many?: Maybe<Array<Maybe<Product_Sales_Channel_Members_Mutation_Response>>>;
  /** update multiples rows of table: "product_stock_locations" */
  update_product_stock_locations_many?: Maybe<Array<Maybe<Product_Stock_Locations_Mutation_Response>>>;
  /** update multiples rows of table: "product_suppliers" */
  update_product_suppliers_many?: Maybe<Array<Maybe<Product_Suppliers_Mutation_Response>>>;
  /** update multiples rows of table: "product_unit_sales_price_config" */
  update_product_unit_sales_price_config_many?: Maybe<Array<Maybe<Product_Unit_Sales_Price_Config_Mutation_Response>>>;
  /** update multiples rows of table: "product_units" */
  update_product_units_many?: Maybe<Array<Maybe<Product_Units_Mutation_Response>>>;
  /** update multiples rows of table: "production_site_input_stock_locations" */
  update_production_site_input_stock_locations_many?: Maybe<Array<Maybe<Production_Site_Input_Stock_Locations_Mutation_Response>>>;
  /** update multiples rows of table: "production_site_output_stock_locations" */
  update_production_site_output_stock_locations_many?: Maybe<Array<Maybe<Production_Site_Output_Stock_Locations_Mutation_Response>>>;
  /** update multiples rows of table: "production_site_product_group_calendar" */
  update_production_site_product_group_calendar_many?: Maybe<Array<Maybe<Production_Site_Product_Group_Calendar_Mutation_Response>>>;
  /** update multiples rows of table: "production_sites" */
  update_production_sites_many?: Maybe<Array<Maybe<Production_Sites_Mutation_Response>>>;
  /** update multiples rows of table: "products" */
  update_products_many?: Maybe<Array<Maybe<Products_Mutation_Response>>>;
  /** update multiples rows of table: "rolling_inventories" */
  update_rolling_inventories_many?: Maybe<Array<Maybe<Rolling_Inventories_Mutation_Response>>>;
  /** update multiples rows of table: "rolling_inventory_actors" */
  update_rolling_inventory_actors_many?: Maybe<Array<Maybe<Rolling_Inventory_Actors_Mutation_Response>>>;
  /** update multiples rows of table: "rolling_inventory_items" */
  update_rolling_inventory_items_many?: Maybe<Array<Maybe<Rolling_Inventory_Items_Mutation_Response>>>;
  /** update multiples rows of table: "s3_objects" */
  update_s3_objects_many?: Maybe<Array<Maybe<S3_Objects_Mutation_Response>>>;
  /** update multiples rows of table: "sales_team_users" */
  update_sales_team_users_many?: Maybe<Array<Maybe<Sales_Team_Users_Mutation_Response>>>;
  /** update multiples rows of table: "sales_teams" */
  update_sales_teams_many?: Maybe<Array<Maybe<Sales_Teams_Mutation_Response>>>;
  /** update multiples rows of table: "stock_locations" */
  update_stock_locations_many?: Maybe<Array<Maybe<Stock_Locations_Mutation_Response>>>;
  /** update multiples rows of table: "stock_transactions" */
  update_stock_transactions_many?: Maybe<Array<Maybe<Stock_Transactions_Mutation_Response>>>;
  /** update multiples rows of table: "supplier_addresses" */
  update_supplier_addresses_many?: Maybe<Array<Maybe<Supplier_Addresses_Mutation_Response>>>;
  /** update multiples rows of table: "supplier_order_goods_income_item_containers" */
  update_supplier_order_goods_income_item_containers_many?: Maybe<Array<Maybe<Supplier_Order_Goods_Income_Item_Containers_Mutation_Response>>>;
  /** update multiples rows of table: "supplier_order_goods_income_items" */
  update_supplier_order_goods_income_items_many?: Maybe<Array<Maybe<Supplier_Order_Goods_Income_Items_Mutation_Response>>>;
  /** update multiples rows of table: "supplier_order_item_return_causes" */
  update_supplier_order_item_return_causes_many?: Maybe<Array<Maybe<Supplier_Order_Item_Return_Causes_Mutation_Response>>>;
  /** update multiples rows of table: "supplier_order_items" */
  update_supplier_order_items_many?: Maybe<Array<Maybe<Supplier_Order_Items_Mutation_Response>>>;
  /** update multiples rows of table: "supplier_order_return_item_containers" */
  update_supplier_order_return_item_containers_many?: Maybe<Array<Maybe<Supplier_Order_Return_Item_Containers_Mutation_Response>>>;
  /** update multiples rows of table: "supplier_order_return_items" */
  update_supplier_order_return_items_many?: Maybe<Array<Maybe<Supplier_Order_Return_Items_Mutation_Response>>>;
  /** update multiples rows of table: "supplier_order_returned_containers" */
  update_supplier_order_returned_containers_many?: Maybe<Array<Maybe<Supplier_Order_Returned_Containers_Mutation_Response>>>;
  /** update multiples rows of table: "supplier_order_returns" */
  update_supplier_order_returns_many?: Maybe<Array<Maybe<Supplier_Order_Returns_Mutation_Response>>>;
  /** update multiples rows of table: "supplier_orders" */
  update_supplier_orders_many?: Maybe<Array<Maybe<Supplier_Orders_Mutation_Response>>>;
  /** update multiples rows of table: "suppliers" */
  update_suppliers_many?: Maybe<Array<Maybe<Suppliers_Mutation_Response>>>;
  /** update multiples rows of table: "temporal_products" */
  update_temporal_products_many?: Maybe<Array<Maybe<Temporal_Products_Mutation_Response>>>;
  /** update multiples rows of table: "temporal_sales_teams_customers" */
  update_temporal_sales_teams_customers_many?: Maybe<Array<Maybe<Temporal_Sales_Teams_Customers_Mutation_Response>>>;
  /** update multiples rows of table: "tenant_bank_accounts" */
  update_tenant_bank_accounts_many?: Maybe<Array<Maybe<Tenant_Bank_Accounts_Mutation_Response>>>;
  /** update multiples rows of table: "todos" */
  update_todos_many?: Maybe<Array<Maybe<Todos_Mutation_Response>>>;
  /** update multiples rows of table: "tour_routes" */
  update_tour_routes_many?: Maybe<Array<Maybe<Tour_Routes_Mutation_Response>>>;
  /** update multiples rows of table: "tours" */
  update_tours_many?: Maybe<Array<Maybe<Tours_Mutation_Response>>>;
  /** update multiples rows of table: "user_sales_goals" */
  update_user_sales_goals_many?: Maybe<Array<Maybe<User_Sales_Goals_Mutation_Response>>>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update multiples rows of table: "work_calendar" */
  update_work_calendar_many?: Maybe<Array<Maybe<Work_Calendar_Mutation_Response>>>;
  /** update multiples rows of table: "workstation_hardware_device_connections" */
  update_workstation_hardware_device_connections_many?: Maybe<Array<Maybe<Workstation_Hardware_Device_Connections_Mutation_Response>>>;
  /** update multiples rows of table: "workstation_picking_categories" */
  update_workstation_picking_categories_many?: Maybe<Array<Maybe<Workstation_Picking_Categories_Mutation_Response>>>;
  /** update multiples rows of table: "workstations" */
  update_workstations_many?: Maybe<Array<Maybe<Workstations_Mutation_Response>>>;
  /** execute VOLATILE function "upsert_price_list_cgps" which returns "customer_group_pricing" */
  upsert_price_list_cgps: Array<Customer_Group_Pricing>;
  /** execute VOLATILE function "upsert_sscc_for_picked_item" which returns "picked_items" */
  upsert_sscc_for_picked_item: Array<Picked_Items>;
};


/** mutation root */
export type Mutation_RootAbacus_Xml_ExportArgs = {
  args: Abacus_Xml_Export_Args;
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootActivateUserArgs = {
  userId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootActivate_Cutting_Pattern_DraftsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootActivate_Price_ListArgs = {
  args: Activate_Price_List_Args;
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootAdd_Products_To_Supplier_OrderArgs = {
  args: Add_Products_To_Supplier_Order_Args;
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootApprove_Cutting_Pattern_DraftsArgs = {
  args: Approve_Cutting_Pattern_Drafts_Args;
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootCalculateDismantlingPricingArgs = {
  args?: InputMaybe<CalculateDismantlingPricingInput>;
};


/** mutation root */
export type Mutation_RootCalculate_Coi_Sales_Quantity_From_PickingArgs = {
  args: Calculate_Coi_Sales_Quantity_From_Picking_Args;
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootCancel_Cutting_Pattern_Draft_Approval_RequestArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootCancel_Supplier_Order_ItemArgs = {
  args: Cancel_Supplier_Order_Item_Args;
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootChange_Customer_Order_With_TokenArgs = {
  args: Change_Customer_Order_With_Token_Args;
  distinct_on?: InputMaybe<Array<Change_Customer_Order_With_Token_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Change_Customer_Order_With_Token_Result_Order_By>>;
  where?: InputMaybe<Change_Customer_Order_With_Token_Result_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootCopy_Customer_OrderArgs = {
  args: Copy_Customer_Order_Args;
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootCopy_ProductArgs = {
  args: Copy_Product_Args;
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootCreateUserArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreate_Internal_Order_For_Dismantling_PlanArgs = {
  args: Create_Internal_Order_For_Dismantling_Plan_Args;
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootCreate_Partial_DeliveryArgs = {
  args: Create_Partial_Delivery_Args;
  distinct_on?: InputMaybe<Array<Partial_Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Deliveries_Order_By>>;
  where?: InputMaybe<Partial_Deliveries_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootDeactivateUserArgs = {
  userId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteContactAvailabilitiesArgs = {
  where: Contact_Availability_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteContactAvailabilityByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteContactAvailabilityRangeByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteContactAvailabilityRangesArgs = {
  where: Contact_Availability_Ranges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteContactByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteContactsArgs = {
  where: Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCreditNoteItemByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteCreditNoteItemsArgs = {
  where: Credit_Note_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCustomerBillingSettingByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteCustomerBillingSettingsArgs = {
  where: Customer_Billing_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCustomerDeliveryAvailabilitiesArgs = {
  where: Customer_Delivery_Availability_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCustomerDeliveryAvailabilityByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteCustomerGroupPricingByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteCustomerGroupPricingsArgs = {
  where: Customer_Group_Pricing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCustomerInvoiceOrderByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteCustomerInvoiceOrdersArgs = {
  where: Customer_Invoice_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCustomerOrderByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteCustomerOrderContainerReturnByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteCustomerOrderContainerReturnItemByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteCustomerOrderContainerReturnItemsArgs = {
  where: Customer_Order_Container_Return_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCustomerOrderContainerReturnsArgs = {
  where: Customer_Order_Container_Returns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCustomerOrderItemAdjustmentByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteCustomerOrderItemAdjustmentsArgs = {
  where: Customer_Order_Item_Adjustments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCustomerOrderItemByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteCustomerOrderItemContainerExpectationByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteCustomerOrderItemContainerExpectationsArgs = {
  where: Customer_Order_Item_Container_Expectations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCustomerOrderItemFulfillmentPlanByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteCustomerOrderItemFulfillmentPlansArgs = {
  where: Customer_Order_Item_Fulfillment_Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCustomerOrderItemsArgs = {
  where: Customer_Order_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCustomerOrderPickedContainerByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteCustomerOrderPickedContainersArgs = {
  where: Customer_Order_Picked_Container_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCustomerOrderReturnItemByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteCustomerOrderReturnItemContainerByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteCustomerOrderReturnItemContainersArgs = {
  where: Customer_Order_Return_Item_Containers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCustomerOrderReturnItemsArgs = {
  where: Customer_Order_Return_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCustomerOrdersArgs = {
  where: Customer_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCustomerProductAvailabilitiesArgs = {
  where: Customer_Product_Availabilities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCustomerProductAvailabilityByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteCuttingPatternOutputByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteCuttingPatternOutputsArgs = {
  where: Cutting_Pattern_Outputs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteCuttingPatternOutputsDraftByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteCuttingPatternOutputsDraftsArgs = {
  where: Cutting_Pattern_Outputs_Draft_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteDismantlingPlanCuttingPatternChoiceByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteDismantlingPlanCuttingPatternChoicesArgs = {
  where: Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteDismantlingPlanDemandByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteDismantlingPlanDemandsArgs = {
  where: Dismantling_Plan_Demands_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteDismantlingPlanInstructionByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteDismantlingPlanInstructionsArgs = {
  where: Dismantling_Plan_Instructions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteEmailByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteEmailsArgs = {
  where: Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteHardwareDeviceByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteHardwareDevicesArgs = {
  where: Hardware_Devices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeletePhoneByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeletePhonesArgs = {
  where: Phones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeletePickedItemByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeletePickedItemContainerByPkArgs = {
  customerOrderPickedContainerId: Scalars['bigint'];
  pickedItemId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeletePickedItemContainersArgs = {
  where: Picked_Item_Containers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeletePickedItemsArgs = {
  where: Picked_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteProductAllowedContainerByPkArgs = {
  containerProductId: Scalars['bigint'];
  productId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteProductAllowedContainersArgs = {
  where: Product_Allowed_Containers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteProductLabelSettingByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteProductLabelSettingsArgs = {
  where: Product_Label_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteProductPackagingConfigurationByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteProductPackagingConfigurationsArgs = {
  where: Product_Packaging_Configurations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteProductRecipeIngredientByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteProductRecipeIngredientsArgs = {
  where: Product_Recipe_Ingredients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteProductRecipeStepByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteProductRecipeStepsArgs = {
  where: Product_Recipe_Steps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteProductS3ObjectByPkArgs = {
  productId: Scalars['bigint'];
  s3ObjectId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteProductS3ObjectsArgs = {
  where: Product_S3_Objects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteProductSalesChannelMemberByPkArgs = {
  productId: Scalars['bigint'];
  salesChannelId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteProductSalesChannelMembersArgs = {
  where: Product_Sales_Channel_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteProductStockLocationByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteProductStockLocationsArgs = {
  where: Product_Stock_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteProductSupplierByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteProductSuppliersArgs = {
  where: Product_Suppliers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteProductUnitSalesPriceConfigByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteProductUnitSalesPriceConfigsArgs = {
  where: Product_Unit_Sales_Price_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteProductionSiteInputStockLocationByPkArgs = {
  productionSiteId: Scalars['bigint'];
  stockLocationId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteProductionSiteInputStockLocationsArgs = {
  where: Production_Site_Input_Stock_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteProductionSiteOutputStockLocationByPkArgs = {
  productionSiteId: Scalars['bigint'];
  stockLocationId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteProductionSiteOutputStockLocationsArgs = {
  where: Production_Site_Output_Stock_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteProductionSiteProductGroupCalendarByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteProductionSiteProductGroupCalendarsArgs = {
  where: Production_Site_Product_Group_Calendar_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteRollingInventoriesArgs = {
  where: Rolling_Inventories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteRollingInventoryActorByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteRollingInventoryActorsArgs = {
  where: Rolling_Inventory_Actors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteRollingInventoryByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteRollingInventoryItemByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteRollingInventoryItemsArgs = {
  where: Rolling_Inventory_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteSalesTeamUserByPkArgs = {
  salesTeamId: Scalars['bigint'];
  userId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteSalesTeamUsersArgs = {
  where: Sales_Team_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteSupplierOrderByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteSupplierOrderGoodsIncomeItemByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteSupplierOrderGoodsIncomeItemContainerByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteSupplierOrderGoodsIncomeItemContainersArgs = {
  where: Supplier_Order_Goods_Income_Item_Containers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteSupplierOrderGoodsIncomeItemsArgs = {
  where: Supplier_Order_Goods_Income_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteSupplierOrderItemByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteSupplierOrderItemsArgs = {
  where: Supplier_Order_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteSupplierOrderReturnItemByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteSupplierOrderReturnItemContainerByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteSupplierOrderReturnItemContainersArgs = {
  where: Supplier_Order_Return_Item_Containers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteSupplierOrderReturnItemsArgs = {
  where: Supplier_Order_Return_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteSupplierOrderReturnedContainerByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteSupplierOrderReturnedContainersArgs = {
  where: Supplier_Order_Returned_Containers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteSupplierOrdersArgs = {
  where: Supplier_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteTemporalProductByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteTemporalProductsArgs = {
  where: Temporal_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteTemporalSalesTeamsCustomerByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteTemporalSalesTeamsCustomersArgs = {
  where: Temporal_Sales_Teams_Customers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteTourRouteByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteTourRoutesArgs = {
  where: Tour_Routes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteUserArgs = {
  userId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteWorkstationByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteWorkstationHardwareDeviceConnectionByPkArgs = {
  connectionType: Hardware_Device_Connection_Types_Enum;
  hardwareDeviceId: Scalars['bigint'];
  workstationId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeleteWorkstationHardwareDeviceConnectionsArgs = {
  where: Workstation_Hardware_Device_Connections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteWorkstationPickingCategoriesArgs = {
  where: Workstation_Picking_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteWorkstationsArgs = {
  where: Workstations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Order_ItemArgs = {
  args: Delete_Customer_Order_Item_Args;
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootDelete_Partial_DeliveryArgs = {
  args: Delete_Partial_Delivery_Args;
  distinct_on?: InputMaybe<Array<Partial_Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Deliveries_Order_By>>;
  where?: InputMaybe<Partial_Deliveries_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootDelete_Picked_ItemsArgs = {
  args: Delete_Picked_Items_Args;
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootDelete_Product_S3_ObjectArgs = {
  args: Delete_Product_S3_Object_Args;
  distinct_on?: InputMaybe<Array<Product_S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_S3_Objects_Order_By>>;
  where?: InputMaybe<Product_S3_Objects_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootDelete_Rolling_InventoryArgs = {
  args: Delete_Rolling_Inventory_Args;
  distinct_on?: InputMaybe<Array<Rolling_Inventories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventories_Order_By>>;
  where?: InputMaybe<Rolling_Inventories_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootDismantlingPlanPrepareArgs = {
  dismantlingPlanId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootEnqueue_Bizerba_Finish_Picking_FlowArgs = {
  args: Enqueue_Bizerba_Finish_Picking_Flow_Args;
  distinct_on?: InputMaybe<Array<Proletarian_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Proletarian_Jobs_Order_By>>;
  where?: InputMaybe<Proletarian_Jobs_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootEnqueue_Bizerba_Reset_DeviceArgs = {
  args: Enqueue_Bizerba_Reset_Device_Args;
  distinct_on?: InputMaybe<Array<Proletarian_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Proletarian_Jobs_Order_By>>;
  where?: InputMaybe<Proletarian_Jobs_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootEnqueue_Bizerba_Resume_Picking_FlowArgs = {
  args: Enqueue_Bizerba_Resume_Picking_Flow_Args;
  distinct_on?: InputMaybe<Array<Proletarian_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Proletarian_Jobs_Order_By>>;
  where?: InputMaybe<Proletarian_Jobs_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootEnqueue_Bizerba_Setup_Picking_FlowArgs = {
  args: Enqueue_Bizerba_Setup_Picking_Flow_Args;
  distinct_on?: InputMaybe<Array<Proletarian_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Proletarian_Jobs_Order_By>>;
  where?: InputMaybe<Proletarian_Jobs_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootEnqueue_Bulk_Customer_Order_Delivery_Note_Pdf_GenerationArgs = {
  args: Enqueue_Bulk_Customer_Order_Delivery_Note_Pdf_Generation_Args;
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootEnqueue_Credit_Note_Pdf_GenerationArgs = {
  args: Enqueue_Credit_Note_Pdf_Generation_Args;
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootEnqueue_Credit_Notes_Pdf_GenerationArgs = {
  args: Enqueue_Credit_Notes_Pdf_Generation_Args;
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootEnqueue_Customer_Invoice_PreparationArgs = {
  args: Enqueue_Customer_Invoice_Preparation_Args;
  distinct_on?: InputMaybe<Array<Customer_Invoice_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Versions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootEnqueue_Customer_Invoices_PreparationArgs = {
  args: Enqueue_Customer_Invoices_Preparation_Args;
  distinct_on?: InputMaybe<Array<Customer_Invoice_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Versions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootEnqueue_Customer_Order_Delivery_Note_Pdf_GenerationArgs = {
  args: Enqueue_Customer_Order_Delivery_Note_Pdf_Generation_Args;
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootEnqueue_Edi_Invoice_Sending_If_NecessaryArgs = {
  args: Enqueue_Edi_Invoice_Sending_If_Necessary_Args;
  distinct_on?: InputMaybe<Array<Proletarian_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Proletarian_Jobs_Order_By>>;
  where?: InputMaybe<Proletarian_Jobs_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootEnqueue_Email_Credit_Note_SendingArgs = {
  args: Enqueue_Email_Credit_Note_Sending_Args;
  distinct_on?: InputMaybe<Array<Proletarian_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Proletarian_Jobs_Order_By>>;
  where?: InputMaybe<Proletarian_Jobs_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootEnqueue_Email_Invoice_SendingArgs = {
  args: Enqueue_Email_Invoice_Sending_Args;
  distinct_on?: InputMaybe<Array<Proletarian_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Proletarian_Jobs_Order_By>>;
  where?: InputMaybe<Proletarian_Jobs_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootEnqueue_Gs1_Logistic_Pdf_GenerationArgs = {
  args: Enqueue_Gs1_Logistic_Pdf_Generation_Args;
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootEnqueue_Picking_Slip_Pdf_GenerationArgs = {
  args: Enqueue_Picking_Slip_Pdf_Generation_Args;
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootEnqueue_Tour_Plan_Csv_GenerationArgs = {
  args: Enqueue_Tour_Plan_Csv_Generation_Args;
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootEvict_Rolling_Inventory_ItemsArgs = {
  args: Evict_Rolling_Inventory_Items_Args;
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootExecute_Product_RecipeArgs = {
  args: Execute_Product_Recipe_Args;
  distinct_on?: InputMaybe<Array<Journal_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journal_Entries_Order_By>>;
  where?: InputMaybe<Journal_Entries_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootFinishBizerbaPickingFlowArgs = {
  args: FinishBizerbaPickingFlowInput;
};


/** mutation root */
export type Mutation_RootFinishLeichMehlPickingFlowArgs = {
  args: FinishLeichMehlPickingFlowInput;
};


/** mutation root */
export type Mutation_RootGenerate_Presigned_UrlArgs = {
  args: Generate_Presigned_Url_Args;
  distinct_on?: InputMaybe<Array<Generate_Presigned_Url_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Generate_Presigned_Url_Result_Order_By>>;
  where?: InputMaybe<Generate_Presigned_Url_Result_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootGenerate_Supplier_Order_PdfArgs = {
  args: Generate_Supplier_Order_Pdf_Args;
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootHard_Delete_Customer_Order_ReturnArgs = {
  args: Hard_Delete_Customer_Order_Return_Args;
  distinct_on?: InputMaybe<Array<Customer_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootInitialize_Dismantling_PricingArgs = {
  args: Initialize_Dismantling_Pricing_Args;
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootInitiate_S3_UploadArgs = {
  args: Initiate_S3_Upload_Args;
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootInsertAccountArgs = {
  object: Accounts_Insert_Input;
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAccountsArgs = {
  objects: Array<Accounts_Insert_Input>;
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAggregatedInventoryItemsByLocationArgs = {
  object: Aggregated_Inventory_Items_By_Location_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsertAggregatedInventoryItemsByLocationsArgs = {
  objects: Array<Aggregated_Inventory_Items_By_Location_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsertContactArgs = {
  object: Contacts_Insert_Input;
  on_conflict?: InputMaybe<Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertContactAssignedRoleArgs = {
  object: Contact_Assigned_Roles_Insert_Input;
  on_conflict?: InputMaybe<Contact_Assigned_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertContactAssignedRolesArgs = {
  objects: Array<Contact_Assigned_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Contact_Assigned_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertContactAvailabilitiesArgs = {
  objects: Array<Contact_Availability_Insert_Input>;
  on_conflict?: InputMaybe<Contact_Availability_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertContactAvailabilityArgs = {
  object: Contact_Availability_Insert_Input;
  on_conflict?: InputMaybe<Contact_Availability_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertContactAvailabilityRangeArgs = {
  object: Contact_Availability_Ranges_Insert_Input;
  on_conflict?: InputMaybe<Contact_Availability_Ranges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertContactAvailabilityRangesArgs = {
  objects: Array<Contact_Availability_Ranges_Insert_Input>;
  on_conflict?: InputMaybe<Contact_Availability_Ranges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertContactsArgs = {
  objects: Array<Contacts_Insert_Input>;
  on_conflict?: InputMaybe<Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCreditNoteArgs = {
  object: Credit_Notes_Insert_Input;
  on_conflict?: InputMaybe<Credit_Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCreditNoteItemArgs = {
  object: Credit_Note_Items_Insert_Input;
  on_conflict?: InputMaybe<Credit_Note_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCreditNoteItemsArgs = {
  objects: Array<Credit_Note_Items_Insert_Input>;
  on_conflict?: InputMaybe<Credit_Note_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCreditNotesArgs = {
  objects: Array<Credit_Notes_Insert_Input>;
  on_conflict?: InputMaybe<Credit_Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerArgs = {
  object: Customers_Insert_Input;
  on_conflict?: InputMaybe<Customers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerAddressArgs = {
  object: Customer_Addresses_Insert_Input;
  on_conflict?: InputMaybe<Customer_Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerAddressesArgs = {
  objects: Array<Customer_Addresses_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerBillingSettingArgs = {
  object: Customer_Billing_Settings_Insert_Input;
  on_conflict?: InputMaybe<Customer_Billing_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerBillingSettingsArgs = {
  objects: Array<Customer_Billing_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Billing_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerDeliveryAvailabilitiesArgs = {
  objects: Array<Customer_Delivery_Availability_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Delivery_Availability_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerDeliveryAvailabilityArgs = {
  object: Customer_Delivery_Availability_Insert_Input;
  on_conflict?: InputMaybe<Customer_Delivery_Availability_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerGroupArgs = {
  object: Customer_Groups_Insert_Input;
  on_conflict?: InputMaybe<Customer_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerGroupPricingArgs = {
  object: Customer_Group_Pricing_Insert_Input;
  on_conflict?: InputMaybe<Customer_Group_Pricing_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerGroupPricingsArgs = {
  objects: Array<Customer_Group_Pricing_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Group_Pricing_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerGroupsArgs = {
  objects: Array<Customer_Groups_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerInvoiceOrderArgs = {
  object: Customer_Invoice_Orders_Insert_Input;
  on_conflict?: InputMaybe<Customer_Invoice_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerInvoiceOrdersArgs = {
  objects: Array<Customer_Invoice_Orders_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Invoice_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerInvoiceVersionArgs = {
  object: Customer_Invoice_Versions_Insert_Input;
  on_conflict?: InputMaybe<Customer_Invoice_Versions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerInvoiceVersionsArgs = {
  objects: Array<Customer_Invoice_Versions_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Invoice_Versions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderArgs = {
  object: Customer_Orders_Insert_Input;
  on_conflict?: InputMaybe<Customer_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderContainerReturnArgs = {
  object: Customer_Order_Container_Returns_Insert_Input;
  on_conflict?: InputMaybe<Customer_Order_Container_Returns_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderContainerReturnItemArgs = {
  object: Customer_Order_Container_Return_Items_Insert_Input;
  on_conflict?: InputMaybe<Customer_Order_Container_Return_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderContainerReturnItemsArgs = {
  objects: Array<Customer_Order_Container_Return_Items_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Order_Container_Return_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderContainerReturnsArgs = {
  objects: Array<Customer_Order_Container_Returns_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Order_Container_Returns_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderItemArgs = {
  object: Customer_Order_Items_Insert_Input;
  on_conflict?: InputMaybe<Customer_Order_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderItemAdjustmentArgs = {
  object: Customer_Order_Item_Adjustments_Insert_Input;
  on_conflict?: InputMaybe<Customer_Order_Item_Adjustments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderItemAdjustmentsArgs = {
  objects: Array<Customer_Order_Item_Adjustments_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Order_Item_Adjustments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderItemContainerExpectationArgs = {
  object: Customer_Order_Item_Container_Expectations_Insert_Input;
  on_conflict?: InputMaybe<Customer_Order_Item_Container_Expectations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderItemContainerExpectationsArgs = {
  objects: Array<Customer_Order_Item_Container_Expectations_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Order_Item_Container_Expectations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderItemFulfillmentPlanArgs = {
  object: Customer_Order_Item_Fulfillment_Plans_Insert_Input;
  on_conflict?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderItemFulfillmentPlansArgs = {
  objects: Array<Customer_Order_Item_Fulfillment_Plans_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderItemReturnCauseArgs = {
  object: Customer_Order_Item_Return_Causes_Insert_Input;
  on_conflict?: InputMaybe<Customer_Order_Item_Return_Causes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderItemReturnCausesArgs = {
  objects: Array<Customer_Order_Item_Return_Causes_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Order_Item_Return_Causes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderItemsArgs = {
  objects: Array<Customer_Order_Items_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Order_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderPickedContainerArgs = {
  object: Customer_Order_Picked_Container_Insert_Input;
  on_conflict?: InputMaybe<Customer_Order_Picked_Container_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderPickedContainersArgs = {
  objects: Array<Customer_Order_Picked_Container_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Order_Picked_Container_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderReturnArgs = {
  object: Customer_Order_Returns_Insert_Input;
  on_conflict?: InputMaybe<Customer_Order_Returns_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderReturnItemArgs = {
  object: Customer_Order_Return_Items_Insert_Input;
  on_conflict?: InputMaybe<Customer_Order_Return_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderReturnItemContainerArgs = {
  object: Customer_Order_Return_Item_Containers_Insert_Input;
  on_conflict?: InputMaybe<Customer_Order_Return_Item_Containers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderReturnItemContainersArgs = {
  objects: Array<Customer_Order_Return_Item_Containers_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Order_Return_Item_Containers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderReturnItemsArgs = {
  objects: Array<Customer_Order_Return_Items_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Order_Return_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrderReturnsArgs = {
  objects: Array<Customer_Order_Returns_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Order_Returns_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerOrdersArgs = {
  objects: Array<Customer_Orders_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerPaymentTermArgs = {
  object: Customer_Payment_Terms_Insert_Input;
  on_conflict?: InputMaybe<Customer_Payment_Terms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerPaymentTermsArgs = {
  objects: Array<Customer_Payment_Terms_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Payment_Terms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerProductAvailabilitiesArgs = {
  objects: Array<Customer_Product_Availabilities_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Product_Availabilities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerProductAvailabilityArgs = {
  object: Customer_Product_Availabilities_Insert_Input;
  on_conflict?: InputMaybe<Customer_Product_Availabilities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerTemplateSettingArgs = {
  object: Customer_Template_Settings_Insert_Input;
  on_conflict?: InputMaybe<Customer_Template_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomerTemplateSettingsArgs = {
  objects: Array<Customer_Template_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Template_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCustomersArgs = {
  objects: Array<Customers_Insert_Input>;
  on_conflict?: InputMaybe<Customers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCuttingPatternArgs = {
  object: Cutting_Patterns_Insert_Input;
  on_conflict?: InputMaybe<Cutting_Patterns_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCuttingPatternOutputArgs = {
  object: Cutting_Pattern_Outputs_Insert_Input;
  on_conflict?: InputMaybe<Cutting_Pattern_Outputs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCuttingPatternOutputsArgs = {
  objects: Array<Cutting_Pattern_Outputs_Insert_Input>;
  on_conflict?: InputMaybe<Cutting_Pattern_Outputs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCuttingPatternOutputsDraftArgs = {
  object: Cutting_Pattern_Outputs_Draft_Insert_Input;
  on_conflict?: InputMaybe<Cutting_Pattern_Outputs_Draft_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCuttingPatternOutputsDraftsArgs = {
  objects: Array<Cutting_Pattern_Outputs_Draft_Insert_Input>;
  on_conflict?: InputMaybe<Cutting_Pattern_Outputs_Draft_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCuttingPatternsArgs = {
  objects: Array<Cutting_Patterns_Insert_Input>;
  on_conflict?: InputMaybe<Cutting_Patterns_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCuttingPatternsDraftArgs = {
  object: Cutting_Patterns_Draft_Insert_Input;
  on_conflict?: InputMaybe<Cutting_Patterns_Draft_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertCuttingPatternsDraftsArgs = {
  objects: Array<Cutting_Patterns_Draft_Insert_Input>;
  on_conflict?: InputMaybe<Cutting_Patterns_Draft_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertDismantlingPlanArgs = {
  object: Dismantling_Plans_Insert_Input;
  on_conflict?: InputMaybe<Dismantling_Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertDismantlingPlanCuttingPatternChoiceArgs = {
  object: Dismantling_Plan_Cutting_Pattern_Choices_Insert_Input;
  on_conflict?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertDismantlingPlanCuttingPatternChoicesArgs = {
  objects: Array<Dismantling_Plan_Cutting_Pattern_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertDismantlingPlanDemandArgs = {
  object: Dismantling_Plan_Demands_Insert_Input;
  on_conflict?: InputMaybe<Dismantling_Plan_Demands_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertDismantlingPlanDemandsArgs = {
  objects: Array<Dismantling_Plan_Demands_Insert_Input>;
  on_conflict?: InputMaybe<Dismantling_Plan_Demands_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertDismantlingPlanInstructionArgs = {
  object: Dismantling_Plan_Instructions_Insert_Input;
  on_conflict?: InputMaybe<Dismantling_Plan_Instructions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertDismantlingPlanInstructionsArgs = {
  objects: Array<Dismantling_Plan_Instructions_Insert_Input>;
  on_conflict?: InputMaybe<Dismantling_Plan_Instructions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertDismantlingPlansArgs = {
  objects: Array<Dismantling_Plans_Insert_Input>;
  on_conflict?: InputMaybe<Dismantling_Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertEmailArgs = {
  object: Emails_Insert_Input;
  on_conflict?: InputMaybe<Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertEmailsArgs = {
  objects: Array<Emails_Insert_Input>;
  on_conflict?: InputMaybe<Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertExchangeRateArgs = {
  object: Exchange_Rates_Insert_Input;
  on_conflict?: InputMaybe<Exchange_Rates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertExchangeRatesArgs = {
  objects: Array<Exchange_Rates_Insert_Input>;
  on_conflict?: InputMaybe<Exchange_Rates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertGs1LogisticTypeArgs = {
  object: Gs1_Logistic_Types_Insert_Input;
  on_conflict?: InputMaybe<Gs1_Logistic_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertGs1LogisticTypesArgs = {
  objects: Array<Gs1_Logistic_Types_Insert_Input>;
  on_conflict?: InputMaybe<Gs1_Logistic_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertHardwareDeviceArgs = {
  object: Hardware_Devices_Insert_Input;
  on_conflict?: InputMaybe<Hardware_Devices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertHardwareDevicesArgs = {
  objects: Array<Hardware_Devices_Insert_Input>;
  on_conflict?: InputMaybe<Hardware_Devices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertImageArgs = {
  object: Images_Insert_Input;
  on_conflict?: InputMaybe<Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertImagesArgs = {
  objects: Array<Images_Insert_Input>;
  on_conflict?: InputMaybe<Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertInventoryItemArgs = {
  object: Inventory_Items_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsertInventoryItemsArgs = {
  objects: Array<Inventory_Items_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsertInventoryItemsByLocationArgs = {
  object: Inventory_Items_By_Location_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsertInventoryItemsByLocationsArgs = {
  objects: Array<Inventory_Items_By_Location_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsertJournalEntriesArgs = {
  objects: Array<Journal_Entries_Insert_Input>;
  on_conflict?: InputMaybe<Journal_Entries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertJournalEntryArgs = {
  object: Journal_Entries_Insert_Input;
  on_conflict?: InputMaybe<Journal_Entries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertJscriptTemplateArgs = {
  object: Jscript_Templates_Insert_Input;
  on_conflict?: InputMaybe<Jscript_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertJscriptTemplatesArgs = {
  objects: Array<Jscript_Templates_Insert_Input>;
  on_conflict?: InputMaybe<Jscript_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertLotNumberArgs = {
  object: Lot_Numbers_Insert_Input;
  on_conflict?: InputMaybe<Lot_Numbers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertLotNumberProductArgs = {
  object: Lot_Number_Products_Insert_Input;
  on_conflict?: InputMaybe<Lot_Number_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertLotNumberProductsArgs = {
  objects: Array<Lot_Number_Products_Insert_Input>;
  on_conflict?: InputMaybe<Lot_Number_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertLotNumbersArgs = {
  objects: Array<Lot_Numbers_Insert_Input>;
  on_conflict?: InputMaybe<Lot_Numbers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertNotificationArgs = {
  object: Notifications_Insert_Input;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertNotificationsArgs = {
  objects: Array<Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertPartialDeliveriesArgs = {
  objects: Array<Partial_Deliveries_Insert_Input>;
  on_conflict?: InputMaybe<Partial_Deliveries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertPartialDeliveryArgs = {
  object: Partial_Deliveries_Insert_Input;
  on_conflict?: InputMaybe<Partial_Deliveries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertPhoneArgs = {
  object: Phones_Insert_Input;
  on_conflict?: InputMaybe<Phones_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertPhonesArgs = {
  objects: Array<Phones_Insert_Input>;
  on_conflict?: InputMaybe<Phones_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertPickedItemArgs = {
  object: Picked_Items_Insert_Input;
  on_conflict?: InputMaybe<Picked_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertPickedItemContainerArgs = {
  object: Picked_Item_Containers_Insert_Input;
  on_conflict?: InputMaybe<Picked_Item_Containers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertPickedItemContainersArgs = {
  objects: Array<Picked_Item_Containers_Insert_Input>;
  on_conflict?: InputMaybe<Picked_Item_Containers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertPickedItemsArgs = {
  objects: Array<Picked_Items_Insert_Input>;
  on_conflict?: InputMaybe<Picked_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertPickingCategoriesArgs = {
  objects: Array<Picking_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Picking_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertPickingCategoryArgs = {
  object: Picking_Categories_Insert_Input;
  on_conflict?: InputMaybe<Picking_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertPriceListArgs = {
  object: Price_Lists_Insert_Input;
  on_conflict?: InputMaybe<Price_Lists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertPriceListsArgs = {
  objects: Array<Price_Lists_Insert_Input>;
  on_conflict?: InputMaybe<Price_Lists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductArgs = {
  object: Products_Insert_Input;
  on_conflict?: InputMaybe<Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductAllowedContainerArgs = {
  object: Product_Allowed_Containers_Insert_Input;
  on_conflict?: InputMaybe<Product_Allowed_Containers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductAllowedContainersArgs = {
  objects: Array<Product_Allowed_Containers_Insert_Input>;
  on_conflict?: InputMaybe<Product_Allowed_Containers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductGroupArgs = {
  object: Product_Groups_Insert_Input;
  on_conflict?: InputMaybe<Product_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductGroupMemberArgs = {
  object: Product_Group_Members_Insert_Input;
  on_conflict?: InputMaybe<Product_Group_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductGroupMembersArgs = {
  objects: Array<Product_Group_Members_Insert_Input>;
  on_conflict?: InputMaybe<Product_Group_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductGroupsArgs = {
  objects: Array<Product_Groups_Insert_Input>;
  on_conflict?: InputMaybe<Product_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductLabelSettingArgs = {
  object: Product_Label_Settings_Insert_Input;
  on_conflict?: InputMaybe<Product_Label_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductLabelSettingsArgs = {
  objects: Array<Product_Label_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Product_Label_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductPackagingConfigurationArgs = {
  object: Product_Packaging_Configurations_Insert_Input;
  on_conflict?: InputMaybe<Product_Packaging_Configurations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductPackagingConfigurationsArgs = {
  objects: Array<Product_Packaging_Configurations_Insert_Input>;
  on_conflict?: InputMaybe<Product_Packaging_Configurations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductRecipeArgs = {
  object: Product_Recipes_Insert_Input;
  on_conflict?: InputMaybe<Product_Recipes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductRecipeIngredientArgs = {
  object: Product_Recipe_Ingredients_Insert_Input;
  on_conflict?: InputMaybe<Product_Recipe_Ingredients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductRecipeIngredientsArgs = {
  objects: Array<Product_Recipe_Ingredients_Insert_Input>;
  on_conflict?: InputMaybe<Product_Recipe_Ingredients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductRecipeStepArgs = {
  object: Product_Recipe_Steps_Insert_Input;
  on_conflict?: InputMaybe<Product_Recipe_Steps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductRecipeStepsArgs = {
  objects: Array<Product_Recipe_Steps_Insert_Input>;
  on_conflict?: InputMaybe<Product_Recipe_Steps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductRecipesArgs = {
  objects: Array<Product_Recipes_Insert_Input>;
  on_conflict?: InputMaybe<Product_Recipes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductS3ObjectArgs = {
  object: Product_S3_Objects_Insert_Input;
  on_conflict?: InputMaybe<Product_S3_Objects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductS3ObjectsArgs = {
  objects: Array<Product_S3_Objects_Insert_Input>;
  on_conflict?: InputMaybe<Product_S3_Objects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductSalesChannelMemberArgs = {
  object: Product_Sales_Channel_Members_Insert_Input;
  on_conflict?: InputMaybe<Product_Sales_Channel_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductSalesChannelMembersArgs = {
  objects: Array<Product_Sales_Channel_Members_Insert_Input>;
  on_conflict?: InputMaybe<Product_Sales_Channel_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductStockLocationArgs = {
  object: Product_Stock_Locations_Insert_Input;
  on_conflict?: InputMaybe<Product_Stock_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductStockLocationsArgs = {
  objects: Array<Product_Stock_Locations_Insert_Input>;
  on_conflict?: InputMaybe<Product_Stock_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductSupplierArgs = {
  object: Product_Suppliers_Insert_Input;
  on_conflict?: InputMaybe<Product_Suppliers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductSuppliersArgs = {
  objects: Array<Product_Suppliers_Insert_Input>;
  on_conflict?: InputMaybe<Product_Suppliers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductUnitArgs = {
  object: Product_Units_Insert_Input;
  on_conflict?: InputMaybe<Product_Units_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductUnitSalesPriceConfigArgs = {
  object: Product_Unit_Sales_Price_Config_Insert_Input;
  on_conflict?: InputMaybe<Product_Unit_Sales_Price_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductUnitSalesPriceConfigsArgs = {
  objects: Array<Product_Unit_Sales_Price_Config_Insert_Input>;
  on_conflict?: InputMaybe<Product_Unit_Sales_Price_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductUnitsArgs = {
  objects: Array<Product_Units_Insert_Input>;
  on_conflict?: InputMaybe<Product_Units_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductionSiteArgs = {
  object: Production_Sites_Insert_Input;
  on_conflict?: InputMaybe<Production_Sites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductionSiteInputStockLocationArgs = {
  object: Production_Site_Input_Stock_Locations_Insert_Input;
  on_conflict?: InputMaybe<Production_Site_Input_Stock_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductionSiteInputStockLocationsArgs = {
  objects: Array<Production_Site_Input_Stock_Locations_Insert_Input>;
  on_conflict?: InputMaybe<Production_Site_Input_Stock_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductionSiteOutputStockLocationArgs = {
  object: Production_Site_Output_Stock_Locations_Insert_Input;
  on_conflict?: InputMaybe<Production_Site_Output_Stock_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductionSiteOutputStockLocationsArgs = {
  objects: Array<Production_Site_Output_Stock_Locations_Insert_Input>;
  on_conflict?: InputMaybe<Production_Site_Output_Stock_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductionSiteProductGroupCalendarArgs = {
  object: Production_Site_Product_Group_Calendar_Insert_Input;
  on_conflict?: InputMaybe<Production_Site_Product_Group_Calendar_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductionSiteProductGroupCalendarsArgs = {
  objects: Array<Production_Site_Product_Group_Calendar_Insert_Input>;
  on_conflict?: InputMaybe<Production_Site_Product_Group_Calendar_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductionSitesArgs = {
  objects: Array<Production_Sites_Insert_Input>;
  on_conflict?: InputMaybe<Production_Sites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertProductsArgs = {
  objects: Array<Products_Insert_Input>;
  on_conflict?: InputMaybe<Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertRollingInventoriesArgs = {
  objects: Array<Rolling_Inventories_Insert_Input>;
  on_conflict?: InputMaybe<Rolling_Inventories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertRollingInventoryArgs = {
  object: Rolling_Inventories_Insert_Input;
  on_conflict?: InputMaybe<Rolling_Inventories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertRollingInventoryActorArgs = {
  object: Rolling_Inventory_Actors_Insert_Input;
  on_conflict?: InputMaybe<Rolling_Inventory_Actors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertRollingInventoryActorsArgs = {
  objects: Array<Rolling_Inventory_Actors_Insert_Input>;
  on_conflict?: InputMaybe<Rolling_Inventory_Actors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertRollingInventoryItemArgs = {
  object: Rolling_Inventory_Items_Insert_Input;
  on_conflict?: InputMaybe<Rolling_Inventory_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertRollingInventoryItemsArgs = {
  objects: Array<Rolling_Inventory_Items_Insert_Input>;
  on_conflict?: InputMaybe<Rolling_Inventory_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertS3ObjectArgs = {
  object: S3_Objects_Insert_Input;
  on_conflict?: InputMaybe<S3_Objects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertS3ObjectsArgs = {
  objects: Array<S3_Objects_Insert_Input>;
  on_conflict?: InputMaybe<S3_Objects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSalesTeamArgs = {
  object: Sales_Teams_Insert_Input;
  on_conflict?: InputMaybe<Sales_Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSalesTeamUserArgs = {
  object: Sales_Team_Users_Insert_Input;
  on_conflict?: InputMaybe<Sales_Team_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSalesTeamUsersArgs = {
  objects: Array<Sales_Team_Users_Insert_Input>;
  on_conflict?: InputMaybe<Sales_Team_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSalesTeamsArgs = {
  objects: Array<Sales_Teams_Insert_Input>;
  on_conflict?: InputMaybe<Sales_Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertStockLocationArgs = {
  object: Stock_Locations_Insert_Input;
  on_conflict?: InputMaybe<Stock_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertStockLocationsArgs = {
  objects: Array<Stock_Locations_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertStockTransactionArgs = {
  object: Stock_Transactions_Insert_Input;
  on_conflict?: InputMaybe<Stock_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertStockTransactionsArgs = {
  objects: Array<Stock_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierArgs = {
  object: Suppliers_Insert_Input;
  on_conflict?: InputMaybe<Suppliers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierAddressArgs = {
  object: Supplier_Addresses_Insert_Input;
  on_conflict?: InputMaybe<Supplier_Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierAddressesArgs = {
  objects: Array<Supplier_Addresses_Insert_Input>;
  on_conflict?: InputMaybe<Supplier_Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierOrderArgs = {
  object: Supplier_Orders_Insert_Input;
  on_conflict?: InputMaybe<Supplier_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierOrderGoodsIncomeItemArgs = {
  object: Supplier_Order_Goods_Income_Items_Insert_Input;
  on_conflict?: InputMaybe<Supplier_Order_Goods_Income_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierOrderGoodsIncomeItemContainerArgs = {
  object: Supplier_Order_Goods_Income_Item_Containers_Insert_Input;
  on_conflict?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierOrderGoodsIncomeItemContainersArgs = {
  objects: Array<Supplier_Order_Goods_Income_Item_Containers_Insert_Input>;
  on_conflict?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierOrderGoodsIncomeItemsArgs = {
  objects: Array<Supplier_Order_Goods_Income_Items_Insert_Input>;
  on_conflict?: InputMaybe<Supplier_Order_Goods_Income_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierOrderItemArgs = {
  object: Supplier_Order_Items_Insert_Input;
  on_conflict?: InputMaybe<Supplier_Order_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierOrderItemReturnCauseArgs = {
  object: Supplier_Order_Item_Return_Causes_Insert_Input;
  on_conflict?: InputMaybe<Supplier_Order_Item_Return_Causes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierOrderItemReturnCausesArgs = {
  objects: Array<Supplier_Order_Item_Return_Causes_Insert_Input>;
  on_conflict?: InputMaybe<Supplier_Order_Item_Return_Causes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierOrderItemsArgs = {
  objects: Array<Supplier_Order_Items_Insert_Input>;
  on_conflict?: InputMaybe<Supplier_Order_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierOrderReturnArgs = {
  object: Supplier_Order_Returns_Insert_Input;
  on_conflict?: InputMaybe<Supplier_Order_Returns_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierOrderReturnItemArgs = {
  object: Supplier_Order_Return_Items_Insert_Input;
  on_conflict?: InputMaybe<Supplier_Order_Return_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierOrderReturnItemContainerArgs = {
  object: Supplier_Order_Return_Item_Containers_Insert_Input;
  on_conflict?: InputMaybe<Supplier_Order_Return_Item_Containers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierOrderReturnItemContainersArgs = {
  objects: Array<Supplier_Order_Return_Item_Containers_Insert_Input>;
  on_conflict?: InputMaybe<Supplier_Order_Return_Item_Containers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierOrderReturnItemsArgs = {
  objects: Array<Supplier_Order_Return_Items_Insert_Input>;
  on_conflict?: InputMaybe<Supplier_Order_Return_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierOrderReturnedContainerArgs = {
  object: Supplier_Order_Returned_Containers_Insert_Input;
  on_conflict?: InputMaybe<Supplier_Order_Returned_Containers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierOrderReturnedContainersArgs = {
  objects: Array<Supplier_Order_Returned_Containers_Insert_Input>;
  on_conflict?: InputMaybe<Supplier_Order_Returned_Containers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierOrderReturnsArgs = {
  objects: Array<Supplier_Order_Returns_Insert_Input>;
  on_conflict?: InputMaybe<Supplier_Order_Returns_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSupplierOrdersArgs = {
  objects: Array<Supplier_Orders_Insert_Input>;
  on_conflict?: InputMaybe<Supplier_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertSuppliersArgs = {
  objects: Array<Suppliers_Insert_Input>;
  on_conflict?: InputMaybe<Suppliers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertTemporalProductArgs = {
  object: Temporal_Products_Insert_Input;
  on_conflict?: InputMaybe<Temporal_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertTemporalProductsArgs = {
  objects: Array<Temporal_Products_Insert_Input>;
  on_conflict?: InputMaybe<Temporal_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertTemporalSalesTeamsCustomerArgs = {
  object: Temporal_Sales_Teams_Customers_Insert_Input;
  on_conflict?: InputMaybe<Temporal_Sales_Teams_Customers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertTemporalSalesTeamsCustomersArgs = {
  objects: Array<Temporal_Sales_Teams_Customers_Insert_Input>;
  on_conflict?: InputMaybe<Temporal_Sales_Teams_Customers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertTenantBankAccountArgs = {
  object: Tenant_Bank_Accounts_Insert_Input;
  on_conflict?: InputMaybe<Tenant_Bank_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertTenantBankAccountsArgs = {
  objects: Array<Tenant_Bank_Accounts_Insert_Input>;
  on_conflict?: InputMaybe<Tenant_Bank_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertTodoArgs = {
  object: Todos_Insert_Input;
  on_conflict?: InputMaybe<Todos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertTodosArgs = {
  objects: Array<Todos_Insert_Input>;
  on_conflict?: InputMaybe<Todos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertTourArgs = {
  object: Tours_Insert_Input;
  on_conflict?: InputMaybe<Tours_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertTourRouteArgs = {
  object: Tour_Routes_Insert_Input;
  on_conflict?: InputMaybe<Tour_Routes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertTourRoutesArgs = {
  objects: Array<Tour_Routes_Insert_Input>;
  on_conflict?: InputMaybe<Tour_Routes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertToursArgs = {
  objects: Array<Tours_Insert_Input>;
  on_conflict?: InputMaybe<Tours_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertUserArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertUserSalesGoalArgs = {
  object: User_Sales_Goals_Insert_Input;
  on_conflict?: InputMaybe<User_Sales_Goals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertUserSalesGoalsArgs = {
  objects: Array<User_Sales_Goals_Insert_Input>;
  on_conflict?: InputMaybe<User_Sales_Goals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertUsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertWorkstationArgs = {
  object: Workstations_Insert_Input;
  on_conflict?: InputMaybe<Workstations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertWorkstationHardwareDeviceConnectionArgs = {
  object: Workstation_Hardware_Device_Connections_Insert_Input;
  on_conflict?: InputMaybe<Workstation_Hardware_Device_Connections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertWorkstationHardwareDeviceConnectionsArgs = {
  objects: Array<Workstation_Hardware_Device_Connections_Insert_Input>;
  on_conflict?: InputMaybe<Workstation_Hardware_Device_Connections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertWorkstationPickingCategoriesArgs = {
  objects: Array<Workstation_Picking_Categories_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsertWorkstationPickingCategoryArgs = {
  object: Workstation_Picking_Categories_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsertWorkstationsArgs = {
  objects: Array<Workstations_Insert_Input>;
  on_conflict?: InputMaybe<Workstations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootMaintain_Dismantling_Plan_DemandsArgs = {
  args: Maintain_Dismantling_Plan_Demands_Args;
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Demands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Demands_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootMaintain_Product_Group_AssociationsArgs = {
  args: Maintain_Product_Group_Associations_Args;
  distinct_on?: InputMaybe<Array<Product_Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_Members_Order_By>>;
  where?: InputMaybe<Product_Group_Members_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootMerge_PdfsArgs = {
  args: Merge_Pdfs_Args;
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootMove_Supplier_Order_Item_Delivery_DateArgs = {
  args: Move_Supplier_Order_Item_Delivery_Date_Args;
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootOrder_Supplied_ProductsArgs = {
  args: Order_Supplied_Products_Args;
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootPrintJscriptArgs = {
  args: PrintJscriptInput;
};


/** mutation root */
export type Mutation_RootPrintJscriptMultipleArgs = {
  args: PrintJscriptMultipleInput;
};


/** mutation root */
export type Mutation_RootPrintJscriptWithTemplateArgs = {
  args: PrintJscriptWithTemplateInput;
};


/** mutation root */
export type Mutation_RootPrintPickingAddressLabelForCoArgs = {
  args: PrintPayloadInput;
};


/** mutation root */
export type Mutation_RootPrintPickingLabelForPickedItemArgs = {
  args: PrintPayloadInput;
};


/** mutation root */
export type Mutation_RootPrintPickingLabelsForCoiArgs = {
  args: PrintPayloadInput;
};


/** mutation root */
export type Mutation_RootPrintPickingSummaryLabelForCoiArgs = {
  args: PrintPayloadInput;
};


/** mutation root */
export type Mutation_RootPrintStockContainerLabelsArgs = {
  args: PrintPayloadInput;
};


/** mutation root */
export type Mutation_RootPrint_Address_Label_For_PickingArgs = {
  args: Print_Address_Label_For_Picking_Args;
  distinct_on?: InputMaybe<Array<Proletarian_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Proletarian_Jobs_Order_By>>;
  where?: InputMaybe<Proletarian_Jobs_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootPrint_Picking_Item_Label_For_Picked_ItemArgs = {
  args: Print_Picking_Item_Label_For_Picked_Item_Args;
  distinct_on?: InputMaybe<Array<Proletarian_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Proletarian_Jobs_Order_By>>;
  where?: InputMaybe<Proletarian_Jobs_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootPrint_Picking_Item_Labels_For_CoiArgs = {
  args: Print_Picking_Item_Labels_For_Coi_Args;
  distinct_on?: InputMaybe<Array<Proletarian_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Proletarian_Jobs_Order_By>>;
  where?: InputMaybe<Proletarian_Jobs_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootPrint_Picking_Summary_Label_For_CoiArgs = {
  args: Print_Picking_Summary_Label_For_Coi_Args;
  distinct_on?: InputMaybe<Array<Proletarian_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Proletarian_Jobs_Order_By>>;
  where?: InputMaybe<Proletarian_Jobs_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootPrint_Stock_Container_LabelsArgs = {
  args: Print_Stock_Container_Labels_Args;
  distinct_on?: InputMaybe<Array<Proletarian_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Proletarian_Jobs_Order_By>>;
  where?: InputMaybe<Proletarian_Jobs_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootProduct_Set_S3_Object_As_Product_ImageArgs = {
  args: Product_Set_S3_Object_As_Product_Image_Args;
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootRecalculate_Unlocked_Coi_PricesArgs = {
  args: Recalculate_Unlocked_Coi_Prices_Args;
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootRequest_Cutting_Pattern_Draft_ApprovalArgs = {
  args: Request_Cutting_Pattern_Draft_Approval_Args;
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootResumeBizerbaPickingFlowArgs = {
  args: ResumeBizerbaPickingFlowInput;
};


/** mutation root */
export type Mutation_RootResumeLeichMehlPickingFlowArgs = {
  args: ResumeLeichMehlPickingFlowInput;
};


/** mutation root */
export type Mutation_RootRevert_Cutting_Pattern_Draft_ChangesArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootRevert_Journal_EntriesArgs = {
  args: Revert_Journal_Entries_Args;
  distinct_on?: InputMaybe<Array<Journal_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journal_Entries_Order_By>>;
  where?: InputMaybe<Journal_Entries_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootRevert_Jscript_Template_From_Logged_ActionArgs = {
  args: Revert_Jscript_Template_From_Logged_Action_Args;
  distinct_on?: InputMaybe<Array<Jscript_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jscript_Templates_Order_By>>;
  where?: InputMaybe<Jscript_Templates_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootSet_Exchange_RateArgs = {
  args: Set_Exchange_Rate_Args;
  distinct_on?: InputMaybe<Array<Exchange_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Rates_Order_By>>;
  where?: InputMaybe<Exchange_Rates_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootSetupBizerbaPickingFlowArgs = {
  args: SetupBizerbaPickingFlowInput;
};


/** mutation root */
export type Mutation_RootSetupLeichMehlPickingFlowArgs = {
  args: SetupLeichMehlPickingFlowInput;
};


/** mutation root */
export type Mutation_RootUpdateAccountByPkArgs = {
  _inc?: InputMaybe<Accounts_Inc_Input>;
  _set?: InputMaybe<Accounts_Set_Input>;
  pk_columns: Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateAccountsArgs = {
  _inc?: InputMaybe<Accounts_Inc_Input>;
  _set?: InputMaybe<Accounts_Set_Input>;
  where: Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateAggregatedInventoryItemsByLocationsArgs = {
  _append?: InputMaybe<Aggregated_Inventory_Items_By_Location_Append_Input>;
  _delete_at_path?: InputMaybe<Aggregated_Inventory_Items_By_Location_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Aggregated_Inventory_Items_By_Location_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Aggregated_Inventory_Items_By_Location_Delete_Key_Input>;
  _inc?: InputMaybe<Aggregated_Inventory_Items_By_Location_Inc_Input>;
  _prepend?: InputMaybe<Aggregated_Inventory_Items_By_Location_Prepend_Input>;
  _set?: InputMaybe<Aggregated_Inventory_Items_By_Location_Set_Input>;
  where: Aggregated_Inventory_Items_By_Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateContactAssignedRoleByPkArgs = {
  _inc?: InputMaybe<Contact_Assigned_Roles_Inc_Input>;
  _set?: InputMaybe<Contact_Assigned_Roles_Set_Input>;
  pk_columns: Contact_Assigned_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateContactAssignedRolesArgs = {
  _inc?: InputMaybe<Contact_Assigned_Roles_Inc_Input>;
  _set?: InputMaybe<Contact_Assigned_Roles_Set_Input>;
  where: Contact_Assigned_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateContactAvailabilitiesArgs = {
  _inc?: InputMaybe<Contact_Availability_Inc_Input>;
  _set?: InputMaybe<Contact_Availability_Set_Input>;
  where: Contact_Availability_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateContactAvailabilityByPkArgs = {
  _inc?: InputMaybe<Contact_Availability_Inc_Input>;
  _set?: InputMaybe<Contact_Availability_Set_Input>;
  pk_columns: Contact_Availability_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateContactAvailabilityRangeByPkArgs = {
  _inc?: InputMaybe<Contact_Availability_Ranges_Inc_Input>;
  _set?: InputMaybe<Contact_Availability_Ranges_Set_Input>;
  pk_columns: Contact_Availability_Ranges_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateContactAvailabilityRangesArgs = {
  _inc?: InputMaybe<Contact_Availability_Ranges_Inc_Input>;
  _set?: InputMaybe<Contact_Availability_Ranges_Set_Input>;
  where: Contact_Availability_Ranges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateContactByPkArgs = {
  _inc?: InputMaybe<Contacts_Inc_Input>;
  _set?: InputMaybe<Contacts_Set_Input>;
  pk_columns: Contacts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateContactsArgs = {
  _inc?: InputMaybe<Contacts_Inc_Input>;
  _set?: InputMaybe<Contacts_Set_Input>;
  where: Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCreditNoteByPkArgs = {
  _inc?: InputMaybe<Credit_Notes_Inc_Input>;
  _set?: InputMaybe<Credit_Notes_Set_Input>;
  pk_columns: Credit_Notes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCreditNoteItemByPkArgs = {
  _inc?: InputMaybe<Credit_Note_Items_Inc_Input>;
  _set?: InputMaybe<Credit_Note_Items_Set_Input>;
  pk_columns: Credit_Note_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCreditNoteItemsArgs = {
  _inc?: InputMaybe<Credit_Note_Items_Inc_Input>;
  _set?: InputMaybe<Credit_Note_Items_Set_Input>;
  where: Credit_Note_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCreditNotesArgs = {
  _inc?: InputMaybe<Credit_Notes_Inc_Input>;
  _set?: InputMaybe<Credit_Notes_Set_Input>;
  where: Credit_Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerAddressByPkArgs = {
  _inc?: InputMaybe<Customer_Addresses_Inc_Input>;
  _set?: InputMaybe<Customer_Addresses_Set_Input>;
  pk_columns: Customer_Addresses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerAddressesArgs = {
  _inc?: InputMaybe<Customer_Addresses_Inc_Input>;
  _set?: InputMaybe<Customer_Addresses_Set_Input>;
  where: Customer_Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerBillingSettingByPkArgs = {
  _inc?: InputMaybe<Customer_Billing_Settings_Inc_Input>;
  _set?: InputMaybe<Customer_Billing_Settings_Set_Input>;
  pk_columns: Customer_Billing_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerBillingSettingsArgs = {
  _inc?: InputMaybe<Customer_Billing_Settings_Inc_Input>;
  _set?: InputMaybe<Customer_Billing_Settings_Set_Input>;
  where: Customer_Billing_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerByPkArgs = {
  _append?: InputMaybe<Customers_Append_Input>;
  _delete_at_path?: InputMaybe<Customers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Customers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Customers_Delete_Key_Input>;
  _inc?: InputMaybe<Customers_Inc_Input>;
  _prepend?: InputMaybe<Customers_Prepend_Input>;
  _set?: InputMaybe<Customers_Set_Input>;
  pk_columns: Customers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerDeliveryAvailabilitiesArgs = {
  _inc?: InputMaybe<Customer_Delivery_Availability_Inc_Input>;
  _set?: InputMaybe<Customer_Delivery_Availability_Set_Input>;
  where: Customer_Delivery_Availability_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerDeliveryAvailabilityByPkArgs = {
  _inc?: InputMaybe<Customer_Delivery_Availability_Inc_Input>;
  _set?: InputMaybe<Customer_Delivery_Availability_Set_Input>;
  pk_columns: Customer_Delivery_Availability_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerGroupByPkArgs = {
  _append?: InputMaybe<Customer_Groups_Append_Input>;
  _delete_at_path?: InputMaybe<Customer_Groups_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Customer_Groups_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Customer_Groups_Delete_Key_Input>;
  _inc?: InputMaybe<Customer_Groups_Inc_Input>;
  _prepend?: InputMaybe<Customer_Groups_Prepend_Input>;
  _set?: InputMaybe<Customer_Groups_Set_Input>;
  pk_columns: Customer_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerGroupPricingByPkArgs = {
  _inc?: InputMaybe<Customer_Group_Pricing_Inc_Input>;
  _set?: InputMaybe<Customer_Group_Pricing_Set_Input>;
  pk_columns: Customer_Group_Pricing_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerGroupPricingsArgs = {
  _inc?: InputMaybe<Customer_Group_Pricing_Inc_Input>;
  _set?: InputMaybe<Customer_Group_Pricing_Set_Input>;
  where: Customer_Group_Pricing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerGroupsArgs = {
  _append?: InputMaybe<Customer_Groups_Append_Input>;
  _delete_at_path?: InputMaybe<Customer_Groups_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Customer_Groups_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Customer_Groups_Delete_Key_Input>;
  _inc?: InputMaybe<Customer_Groups_Inc_Input>;
  _prepend?: InputMaybe<Customer_Groups_Prepend_Input>;
  _set?: InputMaybe<Customer_Groups_Set_Input>;
  where: Customer_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerInvoiceByPkArgs = {
  _inc?: InputMaybe<Customer_Invoices_Inc_Input>;
  _set?: InputMaybe<Customer_Invoices_Set_Input>;
  pk_columns: Customer_Invoices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerInvoiceOrderByPkArgs = {
  _inc?: InputMaybe<Customer_Invoice_Orders_Inc_Input>;
  _set?: InputMaybe<Customer_Invoice_Orders_Set_Input>;
  pk_columns: Customer_Invoice_Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerInvoiceOrdersArgs = {
  _inc?: InputMaybe<Customer_Invoice_Orders_Inc_Input>;
  _set?: InputMaybe<Customer_Invoice_Orders_Set_Input>;
  where: Customer_Invoice_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerInvoiceVersionByPkArgs = {
  _inc?: InputMaybe<Customer_Invoice_Versions_Inc_Input>;
  _set?: InputMaybe<Customer_Invoice_Versions_Set_Input>;
  pk_columns: Customer_Invoice_Versions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerInvoiceVersionsArgs = {
  _inc?: InputMaybe<Customer_Invoice_Versions_Inc_Input>;
  _set?: InputMaybe<Customer_Invoice_Versions_Set_Input>;
  where: Customer_Invoice_Versions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerInvoicesArgs = {
  _inc?: InputMaybe<Customer_Invoices_Inc_Input>;
  _set?: InputMaybe<Customer_Invoices_Set_Input>;
  where: Customer_Invoices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderByPkArgs = {
  _append?: InputMaybe<Customer_Orders_Append_Input>;
  _delete_at_path?: InputMaybe<Customer_Orders_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Customer_Orders_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Customer_Orders_Delete_Key_Input>;
  _inc?: InputMaybe<Customer_Orders_Inc_Input>;
  _prepend?: InputMaybe<Customer_Orders_Prepend_Input>;
  _set?: InputMaybe<Customer_Orders_Set_Input>;
  pk_columns: Customer_Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderContainerReturnByPkArgs = {
  _inc?: InputMaybe<Customer_Order_Container_Returns_Inc_Input>;
  _set?: InputMaybe<Customer_Order_Container_Returns_Set_Input>;
  pk_columns: Customer_Order_Container_Returns_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderContainerReturnItemByPkArgs = {
  _inc?: InputMaybe<Customer_Order_Container_Return_Items_Inc_Input>;
  _set?: InputMaybe<Customer_Order_Container_Return_Items_Set_Input>;
  pk_columns: Customer_Order_Container_Return_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderContainerReturnItemsArgs = {
  _inc?: InputMaybe<Customer_Order_Container_Return_Items_Inc_Input>;
  _set?: InputMaybe<Customer_Order_Container_Return_Items_Set_Input>;
  where: Customer_Order_Container_Return_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderContainerReturnsArgs = {
  _inc?: InputMaybe<Customer_Order_Container_Returns_Inc_Input>;
  _set?: InputMaybe<Customer_Order_Container_Returns_Set_Input>;
  where: Customer_Order_Container_Returns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderItemAdjustmentByPkArgs = {
  _inc?: InputMaybe<Customer_Order_Item_Adjustments_Inc_Input>;
  _set?: InputMaybe<Customer_Order_Item_Adjustments_Set_Input>;
  pk_columns: Customer_Order_Item_Adjustments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderItemAdjustmentsArgs = {
  _inc?: InputMaybe<Customer_Order_Item_Adjustments_Inc_Input>;
  _set?: InputMaybe<Customer_Order_Item_Adjustments_Set_Input>;
  where: Customer_Order_Item_Adjustments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderItemByPkArgs = {
  _inc?: InputMaybe<Customer_Order_Items_Inc_Input>;
  _set?: InputMaybe<Customer_Order_Items_Set_Input>;
  pk_columns: Customer_Order_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderItemContainerExpectationByPkArgs = {
  _inc?: InputMaybe<Customer_Order_Item_Container_Expectations_Inc_Input>;
  _set?: InputMaybe<Customer_Order_Item_Container_Expectations_Set_Input>;
  pk_columns: Customer_Order_Item_Container_Expectations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderItemContainerExpectationsArgs = {
  _inc?: InputMaybe<Customer_Order_Item_Container_Expectations_Inc_Input>;
  _set?: InputMaybe<Customer_Order_Item_Container_Expectations_Set_Input>;
  where: Customer_Order_Item_Container_Expectations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderItemFulfillmentPlanByPkArgs = {
  _inc?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Inc_Input>;
  _set?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Set_Input>;
  pk_columns: Customer_Order_Item_Fulfillment_Plans_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderItemFulfillmentPlansArgs = {
  _inc?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Inc_Input>;
  _set?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Set_Input>;
  where: Customer_Order_Item_Fulfillment_Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderItemReturnCauseByPkArgs = {
  _append?: InputMaybe<Customer_Order_Item_Return_Causes_Append_Input>;
  _delete_at_path?: InputMaybe<Customer_Order_Item_Return_Causes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Customer_Order_Item_Return_Causes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Customer_Order_Item_Return_Causes_Delete_Key_Input>;
  _inc?: InputMaybe<Customer_Order_Item_Return_Causes_Inc_Input>;
  _prepend?: InputMaybe<Customer_Order_Item_Return_Causes_Prepend_Input>;
  _set?: InputMaybe<Customer_Order_Item_Return_Causes_Set_Input>;
  pk_columns: Customer_Order_Item_Return_Causes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderItemReturnCausesArgs = {
  _append?: InputMaybe<Customer_Order_Item_Return_Causes_Append_Input>;
  _delete_at_path?: InputMaybe<Customer_Order_Item_Return_Causes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Customer_Order_Item_Return_Causes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Customer_Order_Item_Return_Causes_Delete_Key_Input>;
  _inc?: InputMaybe<Customer_Order_Item_Return_Causes_Inc_Input>;
  _prepend?: InputMaybe<Customer_Order_Item_Return_Causes_Prepend_Input>;
  _set?: InputMaybe<Customer_Order_Item_Return_Causes_Set_Input>;
  where: Customer_Order_Item_Return_Causes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderItemsArgs = {
  _inc?: InputMaybe<Customer_Order_Items_Inc_Input>;
  _set?: InputMaybe<Customer_Order_Items_Set_Input>;
  where: Customer_Order_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderPickedContainerByPkArgs = {
  _inc?: InputMaybe<Customer_Order_Picked_Container_Inc_Input>;
  _set?: InputMaybe<Customer_Order_Picked_Container_Set_Input>;
  pk_columns: Customer_Order_Picked_Container_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderPickedContainersArgs = {
  _inc?: InputMaybe<Customer_Order_Picked_Container_Inc_Input>;
  _set?: InputMaybe<Customer_Order_Picked_Container_Set_Input>;
  where: Customer_Order_Picked_Container_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderReturnByPkArgs = {
  _inc?: InputMaybe<Customer_Order_Returns_Inc_Input>;
  _set?: InputMaybe<Customer_Order_Returns_Set_Input>;
  pk_columns: Customer_Order_Returns_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderReturnItemByPkArgs = {
  _inc?: InputMaybe<Customer_Order_Return_Items_Inc_Input>;
  _set?: InputMaybe<Customer_Order_Return_Items_Set_Input>;
  pk_columns: Customer_Order_Return_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderReturnItemContainerByPkArgs = {
  _inc?: InputMaybe<Customer_Order_Return_Item_Containers_Inc_Input>;
  _set?: InputMaybe<Customer_Order_Return_Item_Containers_Set_Input>;
  pk_columns: Customer_Order_Return_Item_Containers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderReturnItemContainersArgs = {
  _inc?: InputMaybe<Customer_Order_Return_Item_Containers_Inc_Input>;
  _set?: InputMaybe<Customer_Order_Return_Item_Containers_Set_Input>;
  where: Customer_Order_Return_Item_Containers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderReturnItemsArgs = {
  _inc?: InputMaybe<Customer_Order_Return_Items_Inc_Input>;
  _set?: InputMaybe<Customer_Order_Return_Items_Set_Input>;
  where: Customer_Order_Return_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrderReturnsArgs = {
  _inc?: InputMaybe<Customer_Order_Returns_Inc_Input>;
  _set?: InputMaybe<Customer_Order_Returns_Set_Input>;
  where: Customer_Order_Returns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerOrdersArgs = {
  _append?: InputMaybe<Customer_Orders_Append_Input>;
  _delete_at_path?: InputMaybe<Customer_Orders_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Customer_Orders_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Customer_Orders_Delete_Key_Input>;
  _inc?: InputMaybe<Customer_Orders_Inc_Input>;
  _prepend?: InputMaybe<Customer_Orders_Prepend_Input>;
  _set?: InputMaybe<Customer_Orders_Set_Input>;
  where: Customer_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerPaymentTermByPkArgs = {
  _append?: InputMaybe<Customer_Payment_Terms_Append_Input>;
  _delete_at_path?: InputMaybe<Customer_Payment_Terms_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Customer_Payment_Terms_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Customer_Payment_Terms_Delete_Key_Input>;
  _inc?: InputMaybe<Customer_Payment_Terms_Inc_Input>;
  _prepend?: InputMaybe<Customer_Payment_Terms_Prepend_Input>;
  _set?: InputMaybe<Customer_Payment_Terms_Set_Input>;
  pk_columns: Customer_Payment_Terms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerPaymentTermsArgs = {
  _append?: InputMaybe<Customer_Payment_Terms_Append_Input>;
  _delete_at_path?: InputMaybe<Customer_Payment_Terms_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Customer_Payment_Terms_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Customer_Payment_Terms_Delete_Key_Input>;
  _inc?: InputMaybe<Customer_Payment_Terms_Inc_Input>;
  _prepend?: InputMaybe<Customer_Payment_Terms_Prepend_Input>;
  _set?: InputMaybe<Customer_Payment_Terms_Set_Input>;
  where: Customer_Payment_Terms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerProductAvailabilitiesArgs = {
  _append?: InputMaybe<Customer_Product_Availabilities_Append_Input>;
  _delete_at_path?: InputMaybe<Customer_Product_Availabilities_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Customer_Product_Availabilities_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Customer_Product_Availabilities_Delete_Key_Input>;
  _inc?: InputMaybe<Customer_Product_Availabilities_Inc_Input>;
  _prepend?: InputMaybe<Customer_Product_Availabilities_Prepend_Input>;
  _set?: InputMaybe<Customer_Product_Availabilities_Set_Input>;
  where: Customer_Product_Availabilities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomerProductAvailabilityByPkArgs = {
  _append?: InputMaybe<Customer_Product_Availabilities_Append_Input>;
  _delete_at_path?: InputMaybe<Customer_Product_Availabilities_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Customer_Product_Availabilities_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Customer_Product_Availabilities_Delete_Key_Input>;
  _inc?: InputMaybe<Customer_Product_Availabilities_Inc_Input>;
  _prepend?: InputMaybe<Customer_Product_Availabilities_Prepend_Input>;
  _set?: InputMaybe<Customer_Product_Availabilities_Set_Input>;
  pk_columns: Customer_Product_Availabilities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerTemplateSettingByPkArgs = {
  _inc?: InputMaybe<Customer_Template_Settings_Inc_Input>;
  _set?: InputMaybe<Customer_Template_Settings_Set_Input>;
  pk_columns: Customer_Template_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCustomerTemplateSettingsArgs = {
  _inc?: InputMaybe<Customer_Template_Settings_Inc_Input>;
  _set?: InputMaybe<Customer_Template_Settings_Set_Input>;
  where: Customer_Template_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCustomersArgs = {
  _append?: InputMaybe<Customers_Append_Input>;
  _delete_at_path?: InputMaybe<Customers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Customers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Customers_Delete_Key_Input>;
  _inc?: InputMaybe<Customers_Inc_Input>;
  _prepend?: InputMaybe<Customers_Prepend_Input>;
  _set?: InputMaybe<Customers_Set_Input>;
  where: Customers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCuttingPatternByPkArgs = {
  _inc?: InputMaybe<Cutting_Patterns_Inc_Input>;
  _set?: InputMaybe<Cutting_Patterns_Set_Input>;
  pk_columns: Cutting_Patterns_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCuttingPatternOutputByPkArgs = {
  _inc?: InputMaybe<Cutting_Pattern_Outputs_Inc_Input>;
  _set?: InputMaybe<Cutting_Pattern_Outputs_Set_Input>;
  pk_columns: Cutting_Pattern_Outputs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCuttingPatternOutputsArgs = {
  _inc?: InputMaybe<Cutting_Pattern_Outputs_Inc_Input>;
  _set?: InputMaybe<Cutting_Pattern_Outputs_Set_Input>;
  where: Cutting_Pattern_Outputs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCuttingPatternOutputsDraftByPkArgs = {
  _inc?: InputMaybe<Cutting_Pattern_Outputs_Draft_Inc_Input>;
  _set?: InputMaybe<Cutting_Pattern_Outputs_Draft_Set_Input>;
  pk_columns: Cutting_Pattern_Outputs_Draft_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCuttingPatternOutputsDraftsArgs = {
  _inc?: InputMaybe<Cutting_Pattern_Outputs_Draft_Inc_Input>;
  _set?: InputMaybe<Cutting_Pattern_Outputs_Draft_Set_Input>;
  where: Cutting_Pattern_Outputs_Draft_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCuttingPatternsArgs = {
  _inc?: InputMaybe<Cutting_Patterns_Inc_Input>;
  _set?: InputMaybe<Cutting_Patterns_Set_Input>;
  where: Cutting_Patterns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateCuttingPatternsDraftByPkArgs = {
  _inc?: InputMaybe<Cutting_Patterns_Draft_Inc_Input>;
  _set?: InputMaybe<Cutting_Patterns_Draft_Set_Input>;
  pk_columns: Cutting_Patterns_Draft_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateCuttingPatternsDraftsArgs = {
  _inc?: InputMaybe<Cutting_Patterns_Draft_Inc_Input>;
  _set?: InputMaybe<Cutting_Patterns_Draft_Set_Input>;
  where: Cutting_Patterns_Draft_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateDismantlingPlanByPkArgs = {
  _inc?: InputMaybe<Dismantling_Plans_Inc_Input>;
  _set?: InputMaybe<Dismantling_Plans_Set_Input>;
  pk_columns: Dismantling_Plans_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateDismantlingPlanCuttingPatternChoiceByPkArgs = {
  _inc?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Inc_Input>;
  _set?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Set_Input>;
  pk_columns: Dismantling_Plan_Cutting_Pattern_Choices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateDismantlingPlanCuttingPatternChoicesArgs = {
  _inc?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Inc_Input>;
  _set?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Set_Input>;
  where: Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateDismantlingPlanDemandByPkArgs = {
  _inc?: InputMaybe<Dismantling_Plan_Demands_Inc_Input>;
  _set?: InputMaybe<Dismantling_Plan_Demands_Set_Input>;
  pk_columns: Dismantling_Plan_Demands_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateDismantlingPlanDemandsArgs = {
  _inc?: InputMaybe<Dismantling_Plan_Demands_Inc_Input>;
  _set?: InputMaybe<Dismantling_Plan_Demands_Set_Input>;
  where: Dismantling_Plan_Demands_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateDismantlingPlanInstructionByPkArgs = {
  _inc?: InputMaybe<Dismantling_Plan_Instructions_Inc_Input>;
  _set?: InputMaybe<Dismantling_Plan_Instructions_Set_Input>;
  pk_columns: Dismantling_Plan_Instructions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateDismantlingPlanInstructionsArgs = {
  _inc?: InputMaybe<Dismantling_Plan_Instructions_Inc_Input>;
  _set?: InputMaybe<Dismantling_Plan_Instructions_Set_Input>;
  where: Dismantling_Plan_Instructions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateDismantlingPlanRemainingPieceByPkArgs = {
  _inc?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Inc_Input>;
  _set?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Set_Input>;
  pk_columns: Dismantling_Plan_Remaining_Pieces_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateDismantlingPlanRemainingPiecesArgs = {
  _inc?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Inc_Input>;
  _set?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Set_Input>;
  where: Dismantling_Plan_Remaining_Pieces_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateDismantlingPlansArgs = {
  _inc?: InputMaybe<Dismantling_Plans_Inc_Input>;
  _set?: InputMaybe<Dismantling_Plans_Set_Input>;
  where: Dismantling_Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateEmailByPkArgs = {
  _inc?: InputMaybe<Emails_Inc_Input>;
  _set?: InputMaybe<Emails_Set_Input>;
  pk_columns: Emails_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateEmailsArgs = {
  _inc?: InputMaybe<Emails_Inc_Input>;
  _set?: InputMaybe<Emails_Set_Input>;
  where: Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateExchangeRateByPkArgs = {
  _inc?: InputMaybe<Exchange_Rates_Inc_Input>;
  _set?: InputMaybe<Exchange_Rates_Set_Input>;
  pk_columns: Exchange_Rates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateExchangeRatesArgs = {
  _inc?: InputMaybe<Exchange_Rates_Inc_Input>;
  _set?: InputMaybe<Exchange_Rates_Set_Input>;
  where: Exchange_Rates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateGs1LogisticTypeByPkArgs = {
  _append?: InputMaybe<Gs1_Logistic_Types_Append_Input>;
  _delete_at_path?: InputMaybe<Gs1_Logistic_Types_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Gs1_Logistic_Types_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Gs1_Logistic_Types_Delete_Key_Input>;
  _inc?: InputMaybe<Gs1_Logistic_Types_Inc_Input>;
  _prepend?: InputMaybe<Gs1_Logistic_Types_Prepend_Input>;
  _set?: InputMaybe<Gs1_Logistic_Types_Set_Input>;
  pk_columns: Gs1_Logistic_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateGs1LogisticTypesArgs = {
  _append?: InputMaybe<Gs1_Logistic_Types_Append_Input>;
  _delete_at_path?: InputMaybe<Gs1_Logistic_Types_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Gs1_Logistic_Types_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Gs1_Logistic_Types_Delete_Key_Input>;
  _inc?: InputMaybe<Gs1_Logistic_Types_Inc_Input>;
  _prepend?: InputMaybe<Gs1_Logistic_Types_Prepend_Input>;
  _set?: InputMaybe<Gs1_Logistic_Types_Set_Input>;
  where: Gs1_Logistic_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateHardwareDeviceByPkArgs = {
  _append?: InputMaybe<Hardware_Devices_Append_Input>;
  _delete_at_path?: InputMaybe<Hardware_Devices_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hardware_Devices_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hardware_Devices_Delete_Key_Input>;
  _inc?: InputMaybe<Hardware_Devices_Inc_Input>;
  _prepend?: InputMaybe<Hardware_Devices_Prepend_Input>;
  _set?: InputMaybe<Hardware_Devices_Set_Input>;
  pk_columns: Hardware_Devices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateHardwareDevicesArgs = {
  _append?: InputMaybe<Hardware_Devices_Append_Input>;
  _delete_at_path?: InputMaybe<Hardware_Devices_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hardware_Devices_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hardware_Devices_Delete_Key_Input>;
  _inc?: InputMaybe<Hardware_Devices_Inc_Input>;
  _prepend?: InputMaybe<Hardware_Devices_Prepend_Input>;
  _set?: InputMaybe<Hardware_Devices_Set_Input>;
  where: Hardware_Devices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateHierarchicalGoodsIncomeByPkArgs = {
  _append?: InputMaybe<Hierarchical_Goods_Incomes_Append_Input>;
  _delete_at_path?: InputMaybe<Hierarchical_Goods_Incomes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hierarchical_Goods_Incomes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hierarchical_Goods_Incomes_Delete_Key_Input>;
  _inc?: InputMaybe<Hierarchical_Goods_Incomes_Inc_Input>;
  _prepend?: InputMaybe<Hierarchical_Goods_Incomes_Prepend_Input>;
  _set?: InputMaybe<Hierarchical_Goods_Incomes_Set_Input>;
  pk_columns: Hierarchical_Goods_Incomes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateHierarchicalGoodsIncomesArgs = {
  _append?: InputMaybe<Hierarchical_Goods_Incomes_Append_Input>;
  _delete_at_path?: InputMaybe<Hierarchical_Goods_Incomes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hierarchical_Goods_Incomes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hierarchical_Goods_Incomes_Delete_Key_Input>;
  _inc?: InputMaybe<Hierarchical_Goods_Incomes_Inc_Input>;
  _prepend?: InputMaybe<Hierarchical_Goods_Incomes_Prepend_Input>;
  _set?: InputMaybe<Hierarchical_Goods_Incomes_Set_Input>;
  where: Hierarchical_Goods_Incomes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateImageByPkArgs = {
  _inc?: InputMaybe<Images_Inc_Input>;
  _set?: InputMaybe<Images_Set_Input>;
  pk_columns: Images_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateImagesArgs = {
  _inc?: InputMaybe<Images_Inc_Input>;
  _set?: InputMaybe<Images_Set_Input>;
  where: Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateInventoryItemsArgs = {
  _inc?: InputMaybe<Inventory_Items_Inc_Input>;
  _set?: InputMaybe<Inventory_Items_Set_Input>;
  where: Inventory_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateInventoryItemsByLocationsArgs = {
  _inc?: InputMaybe<Inventory_Items_By_Location_Inc_Input>;
  _set?: InputMaybe<Inventory_Items_By_Location_Set_Input>;
  where: Inventory_Items_By_Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateJournalEntriesArgs = {
  _append?: InputMaybe<Journal_Entries_Append_Input>;
  _delete_at_path?: InputMaybe<Journal_Entries_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Journal_Entries_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Journal_Entries_Delete_Key_Input>;
  _inc?: InputMaybe<Journal_Entries_Inc_Input>;
  _prepend?: InputMaybe<Journal_Entries_Prepend_Input>;
  _set?: InputMaybe<Journal_Entries_Set_Input>;
  where: Journal_Entries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateJournalEntryByPkArgs = {
  _append?: InputMaybe<Journal_Entries_Append_Input>;
  _delete_at_path?: InputMaybe<Journal_Entries_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Journal_Entries_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Journal_Entries_Delete_Key_Input>;
  _inc?: InputMaybe<Journal_Entries_Inc_Input>;
  _prepend?: InputMaybe<Journal_Entries_Prepend_Input>;
  _set?: InputMaybe<Journal_Entries_Set_Input>;
  pk_columns: Journal_Entries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateJscriptTemplateByPkArgs = {
  _append?: InputMaybe<Jscript_Templates_Append_Input>;
  _delete_at_path?: InputMaybe<Jscript_Templates_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Jscript_Templates_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Jscript_Templates_Delete_Key_Input>;
  _inc?: InputMaybe<Jscript_Templates_Inc_Input>;
  _prepend?: InputMaybe<Jscript_Templates_Prepend_Input>;
  _set?: InputMaybe<Jscript_Templates_Set_Input>;
  pk_columns: Jscript_Templates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateJscriptTemplatesArgs = {
  _append?: InputMaybe<Jscript_Templates_Append_Input>;
  _delete_at_path?: InputMaybe<Jscript_Templates_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Jscript_Templates_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Jscript_Templates_Delete_Key_Input>;
  _inc?: InputMaybe<Jscript_Templates_Inc_Input>;
  _prepend?: InputMaybe<Jscript_Templates_Prepend_Input>;
  _set?: InputMaybe<Jscript_Templates_Set_Input>;
  where: Jscript_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateLotNumberByPkArgs = {
  _inc?: InputMaybe<Lot_Numbers_Inc_Input>;
  _set?: InputMaybe<Lot_Numbers_Set_Input>;
  pk_columns: Lot_Numbers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateLotNumberProductByPkArgs = {
  _inc?: InputMaybe<Lot_Number_Products_Inc_Input>;
  _set?: InputMaybe<Lot_Number_Products_Set_Input>;
  pk_columns: Lot_Number_Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateLotNumberProductsArgs = {
  _inc?: InputMaybe<Lot_Number_Products_Inc_Input>;
  _set?: InputMaybe<Lot_Number_Products_Set_Input>;
  where: Lot_Number_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateLotNumbersArgs = {
  _inc?: InputMaybe<Lot_Numbers_Inc_Input>;
  _set?: InputMaybe<Lot_Numbers_Set_Input>;
  where: Lot_Numbers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateNotificationByPkArgs = {
  _append?: InputMaybe<Notifications_Append_Input>;
  _delete_at_path?: InputMaybe<Notifications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Notifications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Notifications_Delete_Key_Input>;
  _inc?: InputMaybe<Notifications_Inc_Input>;
  _prepend?: InputMaybe<Notifications_Prepend_Input>;
  _set?: InputMaybe<Notifications_Set_Input>;
  pk_columns: Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateNotificationsArgs = {
  _append?: InputMaybe<Notifications_Append_Input>;
  _delete_at_path?: InputMaybe<Notifications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Notifications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Notifications_Delete_Key_Input>;
  _inc?: InputMaybe<Notifications_Inc_Input>;
  _prepend?: InputMaybe<Notifications_Prepend_Input>;
  _set?: InputMaybe<Notifications_Set_Input>;
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdatePartialDeliveriesArgs = {
  _inc?: InputMaybe<Partial_Deliveries_Inc_Input>;
  _set?: InputMaybe<Partial_Deliveries_Set_Input>;
  where: Partial_Deliveries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdatePartialDeliveryByPkArgs = {
  _inc?: InputMaybe<Partial_Deliveries_Inc_Input>;
  _set?: InputMaybe<Partial_Deliveries_Set_Input>;
  pk_columns: Partial_Deliveries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdatePhoneByPkArgs = {
  _inc?: InputMaybe<Phones_Inc_Input>;
  _set?: InputMaybe<Phones_Set_Input>;
  pk_columns: Phones_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdatePhonesArgs = {
  _inc?: InputMaybe<Phones_Inc_Input>;
  _set?: InputMaybe<Phones_Set_Input>;
  where: Phones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdatePickedItemByPkArgs = {
  _inc?: InputMaybe<Picked_Items_Inc_Input>;
  _set?: InputMaybe<Picked_Items_Set_Input>;
  pk_columns: Picked_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdatePickedItemContainerByPkArgs = {
  _inc?: InputMaybe<Picked_Item_Containers_Inc_Input>;
  _set?: InputMaybe<Picked_Item_Containers_Set_Input>;
  pk_columns: Picked_Item_Containers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdatePickedItemContainersArgs = {
  _inc?: InputMaybe<Picked_Item_Containers_Inc_Input>;
  _set?: InputMaybe<Picked_Item_Containers_Set_Input>;
  where: Picked_Item_Containers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdatePickedItemsArgs = {
  _inc?: InputMaybe<Picked_Items_Inc_Input>;
  _set?: InputMaybe<Picked_Items_Set_Input>;
  where: Picked_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdatePickingCategoriesArgs = {
  _inc?: InputMaybe<Picking_Categories_Inc_Input>;
  _set?: InputMaybe<Picking_Categories_Set_Input>;
  where: Picking_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdatePickingCategoryByPkArgs = {
  _inc?: InputMaybe<Picking_Categories_Inc_Input>;
  _set?: InputMaybe<Picking_Categories_Set_Input>;
  pk_columns: Picking_Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdatePriceListByPkArgs = {
  _inc?: InputMaybe<Price_Lists_Inc_Input>;
  _set?: InputMaybe<Price_Lists_Set_Input>;
  pk_columns: Price_Lists_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdatePriceListsArgs = {
  _inc?: InputMaybe<Price_Lists_Inc_Input>;
  _set?: InputMaybe<Price_Lists_Set_Input>;
  where: Price_Lists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateProductAllowedContainerByPkArgs = {
  _inc?: InputMaybe<Product_Allowed_Containers_Inc_Input>;
  _set?: InputMaybe<Product_Allowed_Containers_Set_Input>;
  pk_columns: Product_Allowed_Containers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateProductAllowedContainersArgs = {
  _inc?: InputMaybe<Product_Allowed_Containers_Inc_Input>;
  _set?: InputMaybe<Product_Allowed_Containers_Set_Input>;
  where: Product_Allowed_Containers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateProductByPkArgs = {
  _append?: InputMaybe<Products_Append_Input>;
  _delete_at_path?: InputMaybe<Products_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Products_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Products_Delete_Key_Input>;
  _inc?: InputMaybe<Products_Inc_Input>;
  _prepend?: InputMaybe<Products_Prepend_Input>;
  _set?: InputMaybe<Products_Set_Input>;
  pk_columns: Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateProductGroupByPkArgs = {
  _append?: InputMaybe<Product_Groups_Append_Input>;
  _delete_at_path?: InputMaybe<Product_Groups_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Product_Groups_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Product_Groups_Delete_Key_Input>;
  _inc?: InputMaybe<Product_Groups_Inc_Input>;
  _prepend?: InputMaybe<Product_Groups_Prepend_Input>;
  _set?: InputMaybe<Product_Groups_Set_Input>;
  pk_columns: Product_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateProductGroupMemberByPkArgs = {
  _inc?: InputMaybe<Product_Group_Members_Inc_Input>;
  _set?: InputMaybe<Product_Group_Members_Set_Input>;
  pk_columns: Product_Group_Members_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateProductGroupMembersArgs = {
  _inc?: InputMaybe<Product_Group_Members_Inc_Input>;
  _set?: InputMaybe<Product_Group_Members_Set_Input>;
  where: Product_Group_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateProductGroupsArgs = {
  _append?: InputMaybe<Product_Groups_Append_Input>;
  _delete_at_path?: InputMaybe<Product_Groups_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Product_Groups_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Product_Groups_Delete_Key_Input>;
  _inc?: InputMaybe<Product_Groups_Inc_Input>;
  _prepend?: InputMaybe<Product_Groups_Prepend_Input>;
  _set?: InputMaybe<Product_Groups_Set_Input>;
  where: Product_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateProductLabelSettingByPkArgs = {
  _append?: InputMaybe<Product_Label_Settings_Append_Input>;
  _delete_at_path?: InputMaybe<Product_Label_Settings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Product_Label_Settings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Product_Label_Settings_Delete_Key_Input>;
  _inc?: InputMaybe<Product_Label_Settings_Inc_Input>;
  _prepend?: InputMaybe<Product_Label_Settings_Prepend_Input>;
  _set?: InputMaybe<Product_Label_Settings_Set_Input>;
  pk_columns: Product_Label_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateProductLabelSettingsArgs = {
  _append?: InputMaybe<Product_Label_Settings_Append_Input>;
  _delete_at_path?: InputMaybe<Product_Label_Settings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Product_Label_Settings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Product_Label_Settings_Delete_Key_Input>;
  _inc?: InputMaybe<Product_Label_Settings_Inc_Input>;
  _prepend?: InputMaybe<Product_Label_Settings_Prepend_Input>;
  _set?: InputMaybe<Product_Label_Settings_Set_Input>;
  where: Product_Label_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateProductPackagingConfigurationByPkArgs = {
  _append?: InputMaybe<Product_Packaging_Configurations_Append_Input>;
  _delete_at_path?: InputMaybe<Product_Packaging_Configurations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Product_Packaging_Configurations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Product_Packaging_Configurations_Delete_Key_Input>;
  _inc?: InputMaybe<Product_Packaging_Configurations_Inc_Input>;
  _prepend?: InputMaybe<Product_Packaging_Configurations_Prepend_Input>;
  _set?: InputMaybe<Product_Packaging_Configurations_Set_Input>;
  pk_columns: Product_Packaging_Configurations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateProductPackagingConfigurationsArgs = {
  _append?: InputMaybe<Product_Packaging_Configurations_Append_Input>;
  _delete_at_path?: InputMaybe<Product_Packaging_Configurations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Product_Packaging_Configurations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Product_Packaging_Configurations_Delete_Key_Input>;
  _inc?: InputMaybe<Product_Packaging_Configurations_Inc_Input>;
  _prepend?: InputMaybe<Product_Packaging_Configurations_Prepend_Input>;
  _set?: InputMaybe<Product_Packaging_Configurations_Set_Input>;
  where: Product_Packaging_Configurations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateProductRecipeByPkArgs = {
  _inc?: InputMaybe<Product_Recipes_Inc_Input>;
  _set?: InputMaybe<Product_Recipes_Set_Input>;
  pk_columns: Product_Recipes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateProductRecipeIngredientByPkArgs = {
  _inc?: InputMaybe<Product_Recipe_Ingredients_Inc_Input>;
  _set?: InputMaybe<Product_Recipe_Ingredients_Set_Input>;
  pk_columns: Product_Recipe_Ingredients_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateProductRecipeIngredientsArgs = {
  _inc?: InputMaybe<Product_Recipe_Ingredients_Inc_Input>;
  _set?: InputMaybe<Product_Recipe_Ingredients_Set_Input>;
  where: Product_Recipe_Ingredients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateProductRecipeStepByPkArgs = {
  _inc?: InputMaybe<Product_Recipe_Steps_Inc_Input>;
  _set?: InputMaybe<Product_Recipe_Steps_Set_Input>;
  pk_columns: Product_Recipe_Steps_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateProductRecipeStepsArgs = {
  _inc?: InputMaybe<Product_Recipe_Steps_Inc_Input>;
  _set?: InputMaybe<Product_Recipe_Steps_Set_Input>;
  where: Product_Recipe_Steps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateProductRecipesArgs = {
  _inc?: InputMaybe<Product_Recipes_Inc_Input>;
  _set?: InputMaybe<Product_Recipes_Set_Input>;
  where: Product_Recipes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateProductS3ObjectByPkArgs = {
  _inc?: InputMaybe<Product_S3_Objects_Inc_Input>;
  _set?: InputMaybe<Product_S3_Objects_Set_Input>;
  pk_columns: Product_S3_Objects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateProductS3ObjectsArgs = {
  _inc?: InputMaybe<Product_S3_Objects_Inc_Input>;
  _set?: InputMaybe<Product_S3_Objects_Set_Input>;
  where: Product_S3_Objects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateProductSalesChannelMemberByPkArgs = {
  _inc?: InputMaybe<Product_Sales_Channel_Members_Inc_Input>;
  _set?: InputMaybe<Product_Sales_Channel_Members_Set_Input>;
  pk_columns: Product_Sales_Channel_Members_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateProductSalesChannelMembersArgs = {
  _inc?: InputMaybe<Product_Sales_Channel_Members_Inc_Input>;
  _set?: InputMaybe<Product_Sales_Channel_Members_Set_Input>;
  where: Product_Sales_Channel_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateProductStockLocationByPkArgs = {
  _append?: InputMaybe<Product_Stock_Locations_Append_Input>;
  _delete_at_path?: InputMaybe<Product_Stock_Locations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Product_Stock_Locations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Product_Stock_Locations_Delete_Key_Input>;
  _inc?: InputMaybe<Product_Stock_Locations_Inc_Input>;
  _prepend?: InputMaybe<Product_Stock_Locations_Prepend_Input>;
  _set?: InputMaybe<Product_Stock_Locations_Set_Input>;
  pk_columns: Product_Stock_Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateProductStockLocationsArgs = {
  _append?: InputMaybe<Product_Stock_Locations_Append_Input>;
  _delete_at_path?: InputMaybe<Product_Stock_Locations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Product_Stock_Locations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Product_Stock_Locations_Delete_Key_Input>;
  _inc?: InputMaybe<Product_Stock_Locations_Inc_Input>;
  _prepend?: InputMaybe<Product_Stock_Locations_Prepend_Input>;
  _set?: InputMaybe<Product_Stock_Locations_Set_Input>;
  where: Product_Stock_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateProductSupplierByPkArgs = {
  _inc?: InputMaybe<Product_Suppliers_Inc_Input>;
  _set?: InputMaybe<Product_Suppliers_Set_Input>;
  pk_columns: Product_Suppliers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateProductSuppliersArgs = {
  _inc?: InputMaybe<Product_Suppliers_Inc_Input>;
  _set?: InputMaybe<Product_Suppliers_Set_Input>;
  where: Product_Suppliers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateProductUnitByPkArgs = {
  _inc?: InputMaybe<Product_Units_Inc_Input>;
  _set?: InputMaybe<Product_Units_Set_Input>;
  pk_columns: Product_Units_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateProductUnitSalesPriceConfigByPkArgs = {
  _inc?: InputMaybe<Product_Unit_Sales_Price_Config_Inc_Input>;
  _set?: InputMaybe<Product_Unit_Sales_Price_Config_Set_Input>;
  pk_columns: Product_Unit_Sales_Price_Config_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateProductUnitSalesPriceConfigsArgs = {
  _inc?: InputMaybe<Product_Unit_Sales_Price_Config_Inc_Input>;
  _set?: InputMaybe<Product_Unit_Sales_Price_Config_Set_Input>;
  where: Product_Unit_Sales_Price_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateProductUnitsArgs = {
  _inc?: InputMaybe<Product_Units_Inc_Input>;
  _set?: InputMaybe<Product_Units_Set_Input>;
  where: Product_Units_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateProductionSiteByPkArgs = {
  _append?: InputMaybe<Production_Sites_Append_Input>;
  _delete_at_path?: InputMaybe<Production_Sites_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Production_Sites_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Production_Sites_Delete_Key_Input>;
  _inc?: InputMaybe<Production_Sites_Inc_Input>;
  _prepend?: InputMaybe<Production_Sites_Prepend_Input>;
  _set?: InputMaybe<Production_Sites_Set_Input>;
  pk_columns: Production_Sites_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateProductionSiteInputStockLocationByPkArgs = {
  _inc?: InputMaybe<Production_Site_Input_Stock_Locations_Inc_Input>;
  _set?: InputMaybe<Production_Site_Input_Stock_Locations_Set_Input>;
  pk_columns: Production_Site_Input_Stock_Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateProductionSiteInputStockLocationsArgs = {
  _inc?: InputMaybe<Production_Site_Input_Stock_Locations_Inc_Input>;
  _set?: InputMaybe<Production_Site_Input_Stock_Locations_Set_Input>;
  where: Production_Site_Input_Stock_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateProductionSiteOutputStockLocationByPkArgs = {
  _inc?: InputMaybe<Production_Site_Output_Stock_Locations_Inc_Input>;
  _set?: InputMaybe<Production_Site_Output_Stock_Locations_Set_Input>;
  pk_columns: Production_Site_Output_Stock_Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateProductionSiteOutputStockLocationsArgs = {
  _inc?: InputMaybe<Production_Site_Output_Stock_Locations_Inc_Input>;
  _set?: InputMaybe<Production_Site_Output_Stock_Locations_Set_Input>;
  where: Production_Site_Output_Stock_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateProductionSiteProductGroupCalendarByPkArgs = {
  _inc?: InputMaybe<Production_Site_Product_Group_Calendar_Inc_Input>;
  _set?: InputMaybe<Production_Site_Product_Group_Calendar_Set_Input>;
  pk_columns: Production_Site_Product_Group_Calendar_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateProductionSiteProductGroupCalendarsArgs = {
  _inc?: InputMaybe<Production_Site_Product_Group_Calendar_Inc_Input>;
  _set?: InputMaybe<Production_Site_Product_Group_Calendar_Set_Input>;
  where: Production_Site_Product_Group_Calendar_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateProductionSitesArgs = {
  _append?: InputMaybe<Production_Sites_Append_Input>;
  _delete_at_path?: InputMaybe<Production_Sites_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Production_Sites_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Production_Sites_Delete_Key_Input>;
  _inc?: InputMaybe<Production_Sites_Inc_Input>;
  _prepend?: InputMaybe<Production_Sites_Prepend_Input>;
  _set?: InputMaybe<Production_Sites_Set_Input>;
  where: Production_Sites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateProductsArgs = {
  _append?: InputMaybe<Products_Append_Input>;
  _delete_at_path?: InputMaybe<Products_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Products_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Products_Delete_Key_Input>;
  _inc?: InputMaybe<Products_Inc_Input>;
  _prepend?: InputMaybe<Products_Prepend_Input>;
  _set?: InputMaybe<Products_Set_Input>;
  where: Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateRollingInventoriesArgs = {
  _inc?: InputMaybe<Rolling_Inventories_Inc_Input>;
  _set?: InputMaybe<Rolling_Inventories_Set_Input>;
  where: Rolling_Inventories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateRollingInventoryActorByPkArgs = {
  _inc?: InputMaybe<Rolling_Inventory_Actors_Inc_Input>;
  _set?: InputMaybe<Rolling_Inventory_Actors_Set_Input>;
  pk_columns: Rolling_Inventory_Actors_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateRollingInventoryActorsArgs = {
  _inc?: InputMaybe<Rolling_Inventory_Actors_Inc_Input>;
  _set?: InputMaybe<Rolling_Inventory_Actors_Set_Input>;
  where: Rolling_Inventory_Actors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateRollingInventoryByPkArgs = {
  _inc?: InputMaybe<Rolling_Inventories_Inc_Input>;
  _set?: InputMaybe<Rolling_Inventories_Set_Input>;
  pk_columns: Rolling_Inventories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateRollingInventoryItemByPkArgs = {
  _append?: InputMaybe<Rolling_Inventory_Items_Append_Input>;
  _delete_at_path?: InputMaybe<Rolling_Inventory_Items_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Rolling_Inventory_Items_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Rolling_Inventory_Items_Delete_Key_Input>;
  _inc?: InputMaybe<Rolling_Inventory_Items_Inc_Input>;
  _prepend?: InputMaybe<Rolling_Inventory_Items_Prepend_Input>;
  _set?: InputMaybe<Rolling_Inventory_Items_Set_Input>;
  pk_columns: Rolling_Inventory_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateRollingInventoryItemsArgs = {
  _append?: InputMaybe<Rolling_Inventory_Items_Append_Input>;
  _delete_at_path?: InputMaybe<Rolling_Inventory_Items_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Rolling_Inventory_Items_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Rolling_Inventory_Items_Delete_Key_Input>;
  _inc?: InputMaybe<Rolling_Inventory_Items_Inc_Input>;
  _prepend?: InputMaybe<Rolling_Inventory_Items_Prepend_Input>;
  _set?: InputMaybe<Rolling_Inventory_Items_Set_Input>;
  where: Rolling_Inventory_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateS3ObjectByPkArgs = {
  _inc?: InputMaybe<S3_Objects_Inc_Input>;
  _set?: InputMaybe<S3_Objects_Set_Input>;
  pk_columns: S3_Objects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateS3ObjectsArgs = {
  _inc?: InputMaybe<S3_Objects_Inc_Input>;
  _set?: InputMaybe<S3_Objects_Set_Input>;
  where: S3_Objects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateSalesTeamByPkArgs = {
  _inc?: InputMaybe<Sales_Teams_Inc_Input>;
  _set?: InputMaybe<Sales_Teams_Set_Input>;
  pk_columns: Sales_Teams_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateSalesTeamUserByPkArgs = {
  _inc?: InputMaybe<Sales_Team_Users_Inc_Input>;
  _set?: InputMaybe<Sales_Team_Users_Set_Input>;
  pk_columns: Sales_Team_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateSalesTeamUsersArgs = {
  _inc?: InputMaybe<Sales_Team_Users_Inc_Input>;
  _set?: InputMaybe<Sales_Team_Users_Set_Input>;
  where: Sales_Team_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateSalesTeamsArgs = {
  _inc?: InputMaybe<Sales_Teams_Inc_Input>;
  _set?: InputMaybe<Sales_Teams_Set_Input>;
  where: Sales_Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateStockLocationByPkArgs = {
  _append?: InputMaybe<Stock_Locations_Append_Input>;
  _delete_at_path?: InputMaybe<Stock_Locations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stock_Locations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stock_Locations_Delete_Key_Input>;
  _inc?: InputMaybe<Stock_Locations_Inc_Input>;
  _prepend?: InputMaybe<Stock_Locations_Prepend_Input>;
  _set?: InputMaybe<Stock_Locations_Set_Input>;
  pk_columns: Stock_Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateStockLocationsArgs = {
  _append?: InputMaybe<Stock_Locations_Append_Input>;
  _delete_at_path?: InputMaybe<Stock_Locations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stock_Locations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stock_Locations_Delete_Key_Input>;
  _inc?: InputMaybe<Stock_Locations_Inc_Input>;
  _prepend?: InputMaybe<Stock_Locations_Prepend_Input>;
  _set?: InputMaybe<Stock_Locations_Set_Input>;
  where: Stock_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateStockTransactionByPkArgs = {
  _append?: InputMaybe<Stock_Transactions_Append_Input>;
  _delete_at_path?: InputMaybe<Stock_Transactions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stock_Transactions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stock_Transactions_Delete_Key_Input>;
  _inc?: InputMaybe<Stock_Transactions_Inc_Input>;
  _prepend?: InputMaybe<Stock_Transactions_Prepend_Input>;
  _set?: InputMaybe<Stock_Transactions_Set_Input>;
  pk_columns: Stock_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateStockTransactionsArgs = {
  _append?: InputMaybe<Stock_Transactions_Append_Input>;
  _delete_at_path?: InputMaybe<Stock_Transactions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stock_Transactions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stock_Transactions_Delete_Key_Input>;
  _inc?: InputMaybe<Stock_Transactions_Inc_Input>;
  _prepend?: InputMaybe<Stock_Transactions_Prepend_Input>;
  _set?: InputMaybe<Stock_Transactions_Set_Input>;
  where: Stock_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateSupplierAddressByPkArgs = {
  _inc?: InputMaybe<Supplier_Addresses_Inc_Input>;
  _set?: InputMaybe<Supplier_Addresses_Set_Input>;
  pk_columns: Supplier_Addresses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateSupplierAddressesArgs = {
  _inc?: InputMaybe<Supplier_Addresses_Inc_Input>;
  _set?: InputMaybe<Supplier_Addresses_Set_Input>;
  where: Supplier_Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateSupplierByPkArgs = {
  _append?: InputMaybe<Suppliers_Append_Input>;
  _delete_at_path?: InputMaybe<Suppliers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Suppliers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Suppliers_Delete_Key_Input>;
  _inc?: InputMaybe<Suppliers_Inc_Input>;
  _prepend?: InputMaybe<Suppliers_Prepend_Input>;
  _set?: InputMaybe<Suppliers_Set_Input>;
  pk_columns: Suppliers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateSupplierOrderByPkArgs = {
  _append?: InputMaybe<Supplier_Orders_Append_Input>;
  _delete_at_path?: InputMaybe<Supplier_Orders_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Supplier_Orders_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Supplier_Orders_Delete_Key_Input>;
  _inc?: InputMaybe<Supplier_Orders_Inc_Input>;
  _prepend?: InputMaybe<Supplier_Orders_Prepend_Input>;
  _set?: InputMaybe<Supplier_Orders_Set_Input>;
  pk_columns: Supplier_Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateSupplierOrderGoodsIncomeItemByPkArgs = {
  _inc?: InputMaybe<Supplier_Order_Goods_Income_Items_Inc_Input>;
  _set?: InputMaybe<Supplier_Order_Goods_Income_Items_Set_Input>;
  pk_columns: Supplier_Order_Goods_Income_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateSupplierOrderGoodsIncomeItemContainerByPkArgs = {
  _inc?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Inc_Input>;
  _set?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Set_Input>;
  pk_columns: Supplier_Order_Goods_Income_Item_Containers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateSupplierOrderGoodsIncomeItemContainersArgs = {
  _inc?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Inc_Input>;
  _set?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Set_Input>;
  where: Supplier_Order_Goods_Income_Item_Containers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateSupplierOrderGoodsIncomeItemsArgs = {
  _inc?: InputMaybe<Supplier_Order_Goods_Income_Items_Inc_Input>;
  _set?: InputMaybe<Supplier_Order_Goods_Income_Items_Set_Input>;
  where: Supplier_Order_Goods_Income_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateSupplierOrderItemByPkArgs = {
  _inc?: InputMaybe<Supplier_Order_Items_Inc_Input>;
  _set?: InputMaybe<Supplier_Order_Items_Set_Input>;
  pk_columns: Supplier_Order_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateSupplierOrderItemReturnCauseByPkArgs = {
  _append?: InputMaybe<Supplier_Order_Item_Return_Causes_Append_Input>;
  _delete_at_path?: InputMaybe<Supplier_Order_Item_Return_Causes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Supplier_Order_Item_Return_Causes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Supplier_Order_Item_Return_Causes_Delete_Key_Input>;
  _inc?: InputMaybe<Supplier_Order_Item_Return_Causes_Inc_Input>;
  _prepend?: InputMaybe<Supplier_Order_Item_Return_Causes_Prepend_Input>;
  _set?: InputMaybe<Supplier_Order_Item_Return_Causes_Set_Input>;
  pk_columns: Supplier_Order_Item_Return_Causes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateSupplierOrderItemReturnCausesArgs = {
  _append?: InputMaybe<Supplier_Order_Item_Return_Causes_Append_Input>;
  _delete_at_path?: InputMaybe<Supplier_Order_Item_Return_Causes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Supplier_Order_Item_Return_Causes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Supplier_Order_Item_Return_Causes_Delete_Key_Input>;
  _inc?: InputMaybe<Supplier_Order_Item_Return_Causes_Inc_Input>;
  _prepend?: InputMaybe<Supplier_Order_Item_Return_Causes_Prepend_Input>;
  _set?: InputMaybe<Supplier_Order_Item_Return_Causes_Set_Input>;
  where: Supplier_Order_Item_Return_Causes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateSupplierOrderItemsArgs = {
  _inc?: InputMaybe<Supplier_Order_Items_Inc_Input>;
  _set?: InputMaybe<Supplier_Order_Items_Set_Input>;
  where: Supplier_Order_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateSupplierOrderReturnByPkArgs = {
  _inc?: InputMaybe<Supplier_Order_Returns_Inc_Input>;
  _set?: InputMaybe<Supplier_Order_Returns_Set_Input>;
  pk_columns: Supplier_Order_Returns_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateSupplierOrderReturnItemByPkArgs = {
  _inc?: InputMaybe<Supplier_Order_Return_Items_Inc_Input>;
  _set?: InputMaybe<Supplier_Order_Return_Items_Set_Input>;
  pk_columns: Supplier_Order_Return_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateSupplierOrderReturnItemContainerByPkArgs = {
  _inc?: InputMaybe<Supplier_Order_Return_Item_Containers_Inc_Input>;
  _set?: InputMaybe<Supplier_Order_Return_Item_Containers_Set_Input>;
  pk_columns: Supplier_Order_Return_Item_Containers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateSupplierOrderReturnItemContainersArgs = {
  _inc?: InputMaybe<Supplier_Order_Return_Item_Containers_Inc_Input>;
  _set?: InputMaybe<Supplier_Order_Return_Item_Containers_Set_Input>;
  where: Supplier_Order_Return_Item_Containers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateSupplierOrderReturnItemsArgs = {
  _inc?: InputMaybe<Supplier_Order_Return_Items_Inc_Input>;
  _set?: InputMaybe<Supplier_Order_Return_Items_Set_Input>;
  where: Supplier_Order_Return_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateSupplierOrderReturnedContainerByPkArgs = {
  _inc?: InputMaybe<Supplier_Order_Returned_Containers_Inc_Input>;
  _set?: InputMaybe<Supplier_Order_Returned_Containers_Set_Input>;
  pk_columns: Supplier_Order_Returned_Containers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateSupplierOrderReturnedContainersArgs = {
  _inc?: InputMaybe<Supplier_Order_Returned_Containers_Inc_Input>;
  _set?: InputMaybe<Supplier_Order_Returned_Containers_Set_Input>;
  where: Supplier_Order_Returned_Containers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateSupplierOrderReturnsArgs = {
  _inc?: InputMaybe<Supplier_Order_Returns_Inc_Input>;
  _set?: InputMaybe<Supplier_Order_Returns_Set_Input>;
  where: Supplier_Order_Returns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateSupplierOrdersArgs = {
  _append?: InputMaybe<Supplier_Orders_Append_Input>;
  _delete_at_path?: InputMaybe<Supplier_Orders_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Supplier_Orders_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Supplier_Orders_Delete_Key_Input>;
  _inc?: InputMaybe<Supplier_Orders_Inc_Input>;
  _prepend?: InputMaybe<Supplier_Orders_Prepend_Input>;
  _set?: InputMaybe<Supplier_Orders_Set_Input>;
  where: Supplier_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateSuppliersArgs = {
  _append?: InputMaybe<Suppliers_Append_Input>;
  _delete_at_path?: InputMaybe<Suppliers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Suppliers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Suppliers_Delete_Key_Input>;
  _inc?: InputMaybe<Suppliers_Inc_Input>;
  _prepend?: InputMaybe<Suppliers_Prepend_Input>;
  _set?: InputMaybe<Suppliers_Set_Input>;
  where: Suppliers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateTemporalProductByPkArgs = {
  _inc?: InputMaybe<Temporal_Products_Inc_Input>;
  _set?: InputMaybe<Temporal_Products_Set_Input>;
  pk_columns: Temporal_Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateTemporalProductsArgs = {
  _inc?: InputMaybe<Temporal_Products_Inc_Input>;
  _set?: InputMaybe<Temporal_Products_Set_Input>;
  where: Temporal_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateTemporalSalesTeamsCustomerByPkArgs = {
  _inc?: InputMaybe<Temporal_Sales_Teams_Customers_Inc_Input>;
  _set?: InputMaybe<Temporal_Sales_Teams_Customers_Set_Input>;
  pk_columns: Temporal_Sales_Teams_Customers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateTemporalSalesTeamsCustomersArgs = {
  _inc?: InputMaybe<Temporal_Sales_Teams_Customers_Inc_Input>;
  _set?: InputMaybe<Temporal_Sales_Teams_Customers_Set_Input>;
  where: Temporal_Sales_Teams_Customers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateTenantBankAccountByPkArgs = {
  _append?: InputMaybe<Tenant_Bank_Accounts_Append_Input>;
  _delete_at_path?: InputMaybe<Tenant_Bank_Accounts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenant_Bank_Accounts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenant_Bank_Accounts_Delete_Key_Input>;
  _inc?: InputMaybe<Tenant_Bank_Accounts_Inc_Input>;
  _prepend?: InputMaybe<Tenant_Bank_Accounts_Prepend_Input>;
  _set?: InputMaybe<Tenant_Bank_Accounts_Set_Input>;
  pk_columns: Tenant_Bank_Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateTenantBankAccountsArgs = {
  _append?: InputMaybe<Tenant_Bank_Accounts_Append_Input>;
  _delete_at_path?: InputMaybe<Tenant_Bank_Accounts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenant_Bank_Accounts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenant_Bank_Accounts_Delete_Key_Input>;
  _inc?: InputMaybe<Tenant_Bank_Accounts_Inc_Input>;
  _prepend?: InputMaybe<Tenant_Bank_Accounts_Prepend_Input>;
  _set?: InputMaybe<Tenant_Bank_Accounts_Set_Input>;
  where: Tenant_Bank_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateTodoByPkArgs = {
  _inc?: InputMaybe<Todos_Inc_Input>;
  _set?: InputMaybe<Todos_Set_Input>;
  pk_columns: Todos_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateTodosArgs = {
  _inc?: InputMaybe<Todos_Inc_Input>;
  _set?: InputMaybe<Todos_Set_Input>;
  where: Todos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateTourByPkArgs = {
  _append?: InputMaybe<Tours_Append_Input>;
  _delete_at_path?: InputMaybe<Tours_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tours_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tours_Delete_Key_Input>;
  _inc?: InputMaybe<Tours_Inc_Input>;
  _prepend?: InputMaybe<Tours_Prepend_Input>;
  _set?: InputMaybe<Tours_Set_Input>;
  pk_columns: Tours_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateTourRouteByPkArgs = {
  _inc?: InputMaybe<Tour_Routes_Inc_Input>;
  _set?: InputMaybe<Tour_Routes_Set_Input>;
  pk_columns: Tour_Routes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateTourRoutesArgs = {
  _inc?: InputMaybe<Tour_Routes_Inc_Input>;
  _set?: InputMaybe<Tour_Routes_Set_Input>;
  where: Tour_Routes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateToursArgs = {
  _append?: InputMaybe<Tours_Append_Input>;
  _delete_at_path?: InputMaybe<Tours_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tours_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tours_Delete_Key_Input>;
  _inc?: InputMaybe<Tours_Inc_Input>;
  _prepend?: InputMaybe<Tours_Prepend_Input>;
  _set?: InputMaybe<Tours_Set_Input>;
  where: Tours_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateUserByPkArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateUserPasswordArgs = {
  password: Scalars['String'];
  userId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootUpdateUserRolesArgs = {
  roles: Array<Scalars['String']>;
  userId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootUpdateUserSalesGoalByPkArgs = {
  _inc?: InputMaybe<User_Sales_Goals_Inc_Input>;
  _set?: InputMaybe<User_Sales_Goals_Set_Input>;
  pk_columns: User_Sales_Goals_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateUserSalesGoalsArgs = {
  _inc?: InputMaybe<User_Sales_Goals_Inc_Input>;
  _set?: InputMaybe<User_Sales_Goals_Set_Input>;
  where: User_Sales_Goals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateUsersArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateWorkCalendarByPkArgs = {
  _inc?: InputMaybe<Work_Calendar_Inc_Input>;
  _set?: InputMaybe<Work_Calendar_Set_Input>;
  pk_columns: Work_Calendar_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateWorkCalendarsArgs = {
  _inc?: InputMaybe<Work_Calendar_Inc_Input>;
  _set?: InputMaybe<Work_Calendar_Set_Input>;
  where: Work_Calendar_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateWorkstationByPkArgs = {
  _inc?: InputMaybe<Workstations_Inc_Input>;
  _set?: InputMaybe<Workstations_Set_Input>;
  pk_columns: Workstations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateWorkstationHardwareDeviceConnectionByPkArgs = {
  _inc?: InputMaybe<Workstation_Hardware_Device_Connections_Inc_Input>;
  _set?: InputMaybe<Workstation_Hardware_Device_Connections_Set_Input>;
  pk_columns: Workstation_Hardware_Device_Connections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateWorkstationHardwareDeviceConnectionsArgs = {
  _inc?: InputMaybe<Workstation_Hardware_Device_Connections_Inc_Input>;
  _set?: InputMaybe<Workstation_Hardware_Device_Connections_Set_Input>;
  where: Workstation_Hardware_Device_Connections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateWorkstationPickingCategoriesArgs = {
  _inc?: InputMaybe<Workstation_Picking_Categories_Inc_Input>;
  _set?: InputMaybe<Workstation_Picking_Categories_Set_Input>;
  where: Workstation_Picking_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateWorkstationsArgs = {
  _inc?: InputMaybe<Workstations_Inc_Input>;
  _set?: InputMaybe<Workstations_Set_Input>;
  where: Workstations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_ManyArgs = {
  updates: Array<Accounts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Aggregated_Inventory_Items_By_Location_ManyArgs = {
  updates: Array<Aggregated_Inventory_Items_By_Location_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Assigned_Roles_ManyArgs = {
  updates: Array<Contact_Assigned_Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Availability_ManyArgs = {
  updates: Array<Contact_Availability_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Availability_Ranges_ManyArgs = {
  updates: Array<Contact_Availability_Ranges_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Contacts_ManyArgs = {
  updates: Array<Contacts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Credit_Note_Items_ManyArgs = {
  updates: Array<Credit_Note_Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Credit_Notes_ManyArgs = {
  updates: Array<Credit_Notes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Addresses_ManyArgs = {
  updates: Array<Customer_Addresses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Billing_Settings_ManyArgs = {
  updates: Array<Customer_Billing_Settings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Delivery_Availability_ManyArgs = {
  updates: Array<Customer_Delivery_Availability_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Group_Pricing_ManyArgs = {
  updates: Array<Customer_Group_Pricing_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Groups_ManyArgs = {
  updates: Array<Customer_Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Invoice_Orders_ManyArgs = {
  updates: Array<Customer_Invoice_Orders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Invoice_Versions_ManyArgs = {
  updates: Array<Customer_Invoice_Versions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Invoices_ManyArgs = {
  updates: Array<Customer_Invoices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Order_Container_Return_Items_ManyArgs = {
  updates: Array<Customer_Order_Container_Return_Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Order_Container_Returns_ManyArgs = {
  updates: Array<Customer_Order_Container_Returns_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Order_Item_Adjustments_ManyArgs = {
  updates: Array<Customer_Order_Item_Adjustments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Order_Item_Container_Expectations_ManyArgs = {
  updates: Array<Customer_Order_Item_Container_Expectations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Order_Item_Fulfillment_Plans_ManyArgs = {
  updates: Array<Customer_Order_Item_Fulfillment_Plans_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Order_Item_Return_Causes_ManyArgs = {
  updates: Array<Customer_Order_Item_Return_Causes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Order_Items_ManyArgs = {
  updates: Array<Customer_Order_Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Order_Picked_Container_ManyArgs = {
  updates: Array<Customer_Order_Picked_Container_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Order_Return_Item_Containers_ManyArgs = {
  updates: Array<Customer_Order_Return_Item_Containers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Order_Return_Items_ManyArgs = {
  updates: Array<Customer_Order_Return_Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Order_Returns_ManyArgs = {
  updates: Array<Customer_Order_Returns_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Orders_ManyArgs = {
  updates: Array<Customer_Orders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Payment_Terms_ManyArgs = {
  updates: Array<Customer_Payment_Terms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Product_Availabilities_ManyArgs = {
  updates: Array<Customer_Product_Availabilities_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Template_Settings_ManyArgs = {
  updates: Array<Customer_Template_Settings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customers_ManyArgs = {
  updates: Array<Customers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Cutting_Pattern_Outputs_Draft_ManyArgs = {
  updates: Array<Cutting_Pattern_Outputs_Draft_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Cutting_Pattern_Outputs_ManyArgs = {
  updates: Array<Cutting_Pattern_Outputs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Cutting_Patterns_Draft_ManyArgs = {
  updates: Array<Cutting_Patterns_Draft_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Cutting_Patterns_ManyArgs = {
  updates: Array<Cutting_Patterns_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dismantling_Plan_Cutting_Pattern_Choices_ManyArgs = {
  updates: Array<Dismantling_Plan_Cutting_Pattern_Choices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dismantling_Plan_Demands_ManyArgs = {
  updates: Array<Dismantling_Plan_Demands_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dismantling_Plan_Instructions_ManyArgs = {
  updates: Array<Dismantling_Plan_Instructions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dismantling_Plan_Remaining_Pieces_ManyArgs = {
  updates: Array<Dismantling_Plan_Remaining_Pieces_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dismantling_Plans_ManyArgs = {
  updates: Array<Dismantling_Plans_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Emails_ManyArgs = {
  updates: Array<Emails_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Exchange_Rates_ManyArgs = {
  updates: Array<Exchange_Rates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gs1_Logistic_Types_ManyArgs = {
  updates: Array<Gs1_Logistic_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Hardware_Devices_ManyArgs = {
  updates: Array<Hardware_Devices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Hierarchical_Goods_Incomes_ManyArgs = {
  updates: Array<Hierarchical_Goods_Incomes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Images_ManyArgs = {
  updates: Array<Images_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Inventory_Items_By_Location_ManyArgs = {
  updates: Array<Inventory_Items_By_Location_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Inventory_Items_ManyArgs = {
  updates: Array<Inventory_Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Journal_Entries_ManyArgs = {
  updates: Array<Journal_Entries_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Jscript_Templates_ManyArgs = {
  updates: Array<Jscript_Templates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lot_Number_Products_ManyArgs = {
  updates: Array<Lot_Number_Products_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lot_Numbers_ManyArgs = {
  updates: Array<Lot_Numbers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_ManyArgs = {
  updates: Array<Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partial_Deliveries_ManyArgs = {
  updates: Array<Partial_Deliveries_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Phones_ManyArgs = {
  updates: Array<Phones_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Picked_Item_Containers_ManyArgs = {
  updates: Array<Picked_Item_Containers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Picked_Items_ManyArgs = {
  updates: Array<Picked_Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Picking_Categories_ManyArgs = {
  updates: Array<Picking_Categories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Price_Lists_ManyArgs = {
  updates: Array<Price_Lists_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Allowed_Containers_ManyArgs = {
  updates: Array<Product_Allowed_Containers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Group_Members_ManyArgs = {
  updates: Array<Product_Group_Members_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Groups_ManyArgs = {
  updates: Array<Product_Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Label_Settings_ManyArgs = {
  updates: Array<Product_Label_Settings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Packaging_Configurations_ManyArgs = {
  updates: Array<Product_Packaging_Configurations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Recipe_Ingredients_ManyArgs = {
  updates: Array<Product_Recipe_Ingredients_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Recipe_Steps_ManyArgs = {
  updates: Array<Product_Recipe_Steps_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Recipes_ManyArgs = {
  updates: Array<Product_Recipes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_S3_Objects_ManyArgs = {
  updates: Array<Product_S3_Objects_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Sales_Channel_Members_ManyArgs = {
  updates: Array<Product_Sales_Channel_Members_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Stock_Locations_ManyArgs = {
  updates: Array<Product_Stock_Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Suppliers_ManyArgs = {
  updates: Array<Product_Suppliers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Unit_Sales_Price_Config_ManyArgs = {
  updates: Array<Product_Unit_Sales_Price_Config_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Units_ManyArgs = {
  updates: Array<Product_Units_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Production_Site_Input_Stock_Locations_ManyArgs = {
  updates: Array<Production_Site_Input_Stock_Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Production_Site_Output_Stock_Locations_ManyArgs = {
  updates: Array<Production_Site_Output_Stock_Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Production_Site_Product_Group_Calendar_ManyArgs = {
  updates: Array<Production_Site_Product_Group_Calendar_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Production_Sites_ManyArgs = {
  updates: Array<Production_Sites_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Products_ManyArgs = {
  updates: Array<Products_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rolling_Inventories_ManyArgs = {
  updates: Array<Rolling_Inventories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rolling_Inventory_Actors_ManyArgs = {
  updates: Array<Rolling_Inventory_Actors_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rolling_Inventory_Items_ManyArgs = {
  updates: Array<Rolling_Inventory_Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_S3_Objects_ManyArgs = {
  updates: Array<S3_Objects_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Sales_Team_Users_ManyArgs = {
  updates: Array<Sales_Team_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Sales_Teams_ManyArgs = {
  updates: Array<Sales_Teams_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Locations_ManyArgs = {
  updates: Array<Stock_Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Transactions_ManyArgs = {
  updates: Array<Stock_Transactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Supplier_Addresses_ManyArgs = {
  updates: Array<Supplier_Addresses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Supplier_Order_Goods_Income_Item_Containers_ManyArgs = {
  updates: Array<Supplier_Order_Goods_Income_Item_Containers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Supplier_Order_Goods_Income_Items_ManyArgs = {
  updates: Array<Supplier_Order_Goods_Income_Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Supplier_Order_Item_Return_Causes_ManyArgs = {
  updates: Array<Supplier_Order_Item_Return_Causes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Supplier_Order_Items_ManyArgs = {
  updates: Array<Supplier_Order_Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Supplier_Order_Return_Item_Containers_ManyArgs = {
  updates: Array<Supplier_Order_Return_Item_Containers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Supplier_Order_Return_Items_ManyArgs = {
  updates: Array<Supplier_Order_Return_Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Supplier_Order_Returned_Containers_ManyArgs = {
  updates: Array<Supplier_Order_Returned_Containers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Supplier_Order_Returns_ManyArgs = {
  updates: Array<Supplier_Order_Returns_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Supplier_Orders_ManyArgs = {
  updates: Array<Supplier_Orders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Suppliers_ManyArgs = {
  updates: Array<Suppliers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Temporal_Products_ManyArgs = {
  updates: Array<Temporal_Products_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Temporal_Sales_Teams_Customers_ManyArgs = {
  updates: Array<Temporal_Sales_Teams_Customers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenant_Bank_Accounts_ManyArgs = {
  updates: Array<Tenant_Bank_Accounts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Todos_ManyArgs = {
  updates: Array<Todos_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tour_Routes_ManyArgs = {
  updates: Array<Tour_Routes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tours_ManyArgs = {
  updates: Array<Tours_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Sales_Goals_ManyArgs = {
  updates: Array<User_Sales_Goals_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Work_Calendar_ManyArgs = {
  updates: Array<Work_Calendar_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Workstation_Hardware_Device_Connections_ManyArgs = {
  updates: Array<Workstation_Hardware_Device_Connections_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Workstation_Picking_Categories_ManyArgs = {
  updates: Array<Workstation_Picking_Categories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Workstations_ManyArgs = {
  updates: Array<Workstations_Updates>;
};


/** mutation root */
export type Mutation_RootUpsert_Price_List_CgpsArgs = {
  args: Upsert_Price_List_Cgps_Args;
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootUpsert_Sscc_For_Picked_ItemArgs = {
  args: Upsert_Sscc_For_Picked_Item_Args;
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};

/** columns and relationships of "my_user" */
export type My_User = {
  __typename: 'my_user';
  auth0Identity?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lastLoginAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "my_user" */
export type My_User_Aggregate = {
  __typename: 'my_user_aggregate';
  aggregate?: Maybe<My_User_Aggregate_Fields>;
  nodes: Array<My_User>;
};

/** aggregate fields of "my_user" */
export type My_User_Aggregate_Fields = {
  __typename: 'my_user_aggregate_fields';
  avg?: Maybe<My_User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<My_User_Max_Fields>;
  min?: Maybe<My_User_Min_Fields>;
  stddev?: Maybe<My_User_Stddev_Fields>;
  stddev_pop?: Maybe<My_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<My_User_Stddev_Samp_Fields>;
  sum?: Maybe<My_User_Sum_Fields>;
  var_pop?: Maybe<My_User_Var_Pop_Fields>;
  var_samp?: Maybe<My_User_Var_Samp_Fields>;
  variance?: Maybe<My_User_Variance_Fields>;
};


/** aggregate fields of "my_user" */
export type My_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<My_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type My_User_Avg_Fields = {
  __typename: 'my_user_avg_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "my_user". All fields are combined with a logical 'AND'. */
export type My_User_Bool_Exp = {
  _and?: InputMaybe<Array<My_User_Bool_Exp>>;
  _not?: InputMaybe<My_User_Bool_Exp>;
  _or?: InputMaybe<Array<My_User_Bool_Exp>>;
  auth0Identity?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  lastLoginAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type My_User_Max_Fields = {
  __typename: 'my_user_max_fields';
  auth0Identity?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lastLoginAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type My_User_Min_Fields = {
  __typename: 'my_user_min_fields';
  auth0Identity?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lastLoginAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "my_user". */
export type My_User_Order_By = {
  auth0Identity?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastLoginAt?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** select columns of table "my_user" */
export enum My_User_Select_Column {
  /** column name */
  Auth0Identity = 'auth0Identity',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  LastLoginAt = 'lastLoginAt',
  /** column name */
  Name = 'name',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  Version = 'version'
}

/** aggregate stddev on columns */
export type My_User_Stddev_Fields = {
  __typename: 'my_user_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type My_User_Stddev_Pop_Fields = {
  __typename: 'my_user_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type My_User_Stddev_Samp_Fields = {
  __typename: 'my_user_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "my_user" */
export type My_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: My_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type My_User_Stream_Cursor_Value_Input = {
  auth0Identity?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  lastLoginAt?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type My_User_Sum_Fields = {
  __typename: 'my_user_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type My_User_Var_Pop_Fields = {
  __typename: 'my_user_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type My_User_Var_Samp_Fields = {
  __typename: 'my_user_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type My_User_Variance_Fields = {
  __typename: 'my_user_variance_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "name". All fields are combined with logical 'AND'. */
export type Name_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['name']>;
  _gt?: InputMaybe<Scalars['name']>;
  _gte?: InputMaybe<Scalars['name']>;
  _in?: InputMaybe<Array<Scalars['name']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['name']>;
  _lte?: InputMaybe<Scalars['name']>;
  _neq?: InputMaybe<Scalars['name']>;
  _nin?: InputMaybe<Array<Scalars['name']>>;
};

/** columns and relationships of "next_dismantling_plans" */
export type Next_Dismantling_Plans = {
  __typename: 'next_dismantling_plans';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dueDate?: Maybe<Scalars['date']>;
  generateSvgOnNextSolve?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  lastUnprocessedFactChange?: Maybe<Scalars['timestamptz']>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  productGroupId?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  svg?: Maybe<Scalars['xml']>;
  svgGeneratedAt?: Maybe<Scalars['timestamptz']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  xlsxDocumentId?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "next_dismantling_plans" */
export type Next_Dismantling_Plans_Aggregate = {
  __typename: 'next_dismantling_plans_aggregate';
  aggregate?: Maybe<Next_Dismantling_Plans_Aggregate_Fields>;
  nodes: Array<Next_Dismantling_Plans>;
};

/** aggregate fields of "next_dismantling_plans" */
export type Next_Dismantling_Plans_Aggregate_Fields = {
  __typename: 'next_dismantling_plans_aggregate_fields';
  avg?: Maybe<Next_Dismantling_Plans_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Next_Dismantling_Plans_Max_Fields>;
  min?: Maybe<Next_Dismantling_Plans_Min_Fields>;
  stddev?: Maybe<Next_Dismantling_Plans_Stddev_Fields>;
  stddev_pop?: Maybe<Next_Dismantling_Plans_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Next_Dismantling_Plans_Stddev_Samp_Fields>;
  sum?: Maybe<Next_Dismantling_Plans_Sum_Fields>;
  var_pop?: Maybe<Next_Dismantling_Plans_Var_Pop_Fields>;
  var_samp?: Maybe<Next_Dismantling_Plans_Var_Samp_Fields>;
  variance?: Maybe<Next_Dismantling_Plans_Variance_Fields>;
};


/** aggregate fields of "next_dismantling_plans" */
export type Next_Dismantling_Plans_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Next_Dismantling_Plans_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Next_Dismantling_Plans_Avg_Fields = {
  __typename: 'next_dismantling_plans_avg_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xlsxDocumentId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "next_dismantling_plans". All fields are combined with a logical 'AND'. */
export type Next_Dismantling_Plans_Bool_Exp = {
  _and?: InputMaybe<Array<Next_Dismantling_Plans_Bool_Exp>>;
  _not?: InputMaybe<Next_Dismantling_Plans_Bool_Exp>;
  _or?: InputMaybe<Array<Next_Dismantling_Plans_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dueDate?: InputMaybe<Date_Comparison_Exp>;
  generateSvgOnNextSolve?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  journalEntryId?: InputMaybe<Bigint_Comparison_Exp>;
  lastUnprocessedFactChange?: InputMaybe<Timestamptz_Comparison_Exp>;
  pdfDocumentId?: InputMaybe<Bigint_Comparison_Exp>;
  productGroupId?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  svg?: InputMaybe<Xml_Comparison_Exp>;
  svgGeneratedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
  xlsxDocumentId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Next_Dismantling_Plans_Max_Fields = {
  __typename: 'next_dismantling_plans_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dueDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  lastUnprocessedFactChange?: Maybe<Scalars['timestamptz']>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  productGroupId?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  svgGeneratedAt?: Maybe<Scalars['timestamptz']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  xlsxDocumentId?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Next_Dismantling_Plans_Min_Fields = {
  __typename: 'next_dismantling_plans_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dueDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  lastUnprocessedFactChange?: Maybe<Scalars['timestamptz']>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  productGroupId?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  svgGeneratedAt?: Maybe<Scalars['timestamptz']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  xlsxDocumentId?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "next_dismantling_plans". */
export type Next_Dismantling_Plans_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  dueDate?: InputMaybe<Order_By>;
  generateSvgOnNextSolve?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lastUnprocessedFactChange?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  svg?: InputMaybe<Order_By>;
  svgGeneratedAt?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xlsxDocumentId?: InputMaybe<Order_By>;
};

/** select columns of table "next_dismantling_plans" */
export enum Next_Dismantling_Plans_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DueDate = 'dueDate',
  /** column name */
  GenerateSvgOnNextSolve = 'generateSvgOnNextSolve',
  /** column name */
  Id = 'id',
  /** column name */
  JournalEntryId = 'journalEntryId',
  /** column name */
  LastUnprocessedFactChange = 'lastUnprocessedFactChange',
  /** column name */
  PdfDocumentId = 'pdfDocumentId',
  /** column name */
  ProductGroupId = 'productGroupId',
  /** column name */
  State = 'state',
  /** column name */
  Svg = 'svg',
  /** column name */
  SvgGeneratedAt = 'svgGeneratedAt',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version',
  /** column name */
  XlsxDocumentId = 'xlsxDocumentId'
}

/** aggregate stddev on columns */
export type Next_Dismantling_Plans_Stddev_Fields = {
  __typename: 'next_dismantling_plans_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xlsxDocumentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Next_Dismantling_Plans_Stddev_Pop_Fields = {
  __typename: 'next_dismantling_plans_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xlsxDocumentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Next_Dismantling_Plans_Stddev_Samp_Fields = {
  __typename: 'next_dismantling_plans_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xlsxDocumentId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "next_dismantling_plans" */
export type Next_Dismantling_Plans_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Next_Dismantling_Plans_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Next_Dismantling_Plans_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dueDate?: InputMaybe<Scalars['date']>;
  generateSvgOnNextSolve?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  lastUnprocessedFactChange?: InputMaybe<Scalars['timestamptz']>;
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  productGroupId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Scalars['String']>;
  svg?: InputMaybe<Scalars['xml']>;
  svgGeneratedAt?: InputMaybe<Scalars['timestamptz']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
  xlsxDocumentId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Next_Dismantling_Plans_Sum_Fields = {
  __typename: 'next_dismantling_plans_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  productGroupId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
  xlsxDocumentId?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Next_Dismantling_Plans_Var_Pop_Fields = {
  __typename: 'next_dismantling_plans_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xlsxDocumentId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Next_Dismantling_Plans_Var_Samp_Fields = {
  __typename: 'next_dismantling_plans_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xlsxDocumentId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Next_Dismantling_Plans_Variance_Fields = {
  __typename: 'next_dismantling_plans_variance_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  xlsxDocumentId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "notification_types" */
export type Notification_Types = {
  __typename: 'notification_types';
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "notification_types" */
export type Notification_TypesNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "notification_types" */
export type Notification_TypesNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** aggregated selection of "notification_types" */
export type Notification_Types_Aggregate = {
  __typename: 'notification_types_aggregate';
  aggregate?: Maybe<Notification_Types_Aggregate_Fields>;
  nodes: Array<Notification_Types>;
};

/** aggregate fields of "notification_types" */
export type Notification_Types_Aggregate_Fields = {
  __typename: 'notification_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notification_Types_Max_Fields>;
  min?: Maybe<Notification_Types_Min_Fields>;
};


/** aggregate fields of "notification_types" */
export type Notification_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "notification_types". All fields are combined with a logical 'AND'. */
export type Notification_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Types_Bool_Exp>>;
  _not?: InputMaybe<Notification_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Types_Bool_Exp>>;
  notifications?: InputMaybe<Notifications_Bool_Exp>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Notification_Types_Enum {
  CoiUnderpicked = 'COI_UNDERPICKED',
  ExpiredOfferAccepted = 'EXPIRED_OFFER_ACCEPTED',
  OfferAccepted = 'OFFER_ACCEPTED',
  OfferDeliveryDateChange = 'OFFER_DELIVERY_DATE_CHANGE',
  OfferRejected = 'OFFER_REJECTED'
}

/** Boolean expression to compare columns of type "notification_types_enum". All fields are combined with logical 'AND'. */
export type Notification_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Notification_Types_Enum>;
  _in?: InputMaybe<Array<Notification_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Notification_Types_Enum>;
  _nin?: InputMaybe<Array<Notification_Types_Enum>>;
};

/** aggregate max on columns */
export type Notification_Types_Max_Fields = {
  __typename: 'notification_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Notification_Types_Min_Fields = {
  __typename: 'notification_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "notification_types". */
export type Notification_Types_Order_By = {
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "notification_types" */
export enum Notification_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "notification_types" */
export type Notification_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Types_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "notifications" */
export type Notifications = {
  __typename: 'notifications';
  /** An object relationship */
  Tenant: Tenants;
  ackedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  ackedByUser?: Maybe<Users>;
  ackedByUserId?: Maybe<Scalars['bigint']>;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customerOrder?: Maybe<Customer_Orders>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  customerOrderItem?: Maybe<Customer_Order_Items>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  errorId?: Maybe<Scalars['bigint']>;
  firstShownAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  payload: Scalars['jsonb'];
  tenant: Scalars['String'];
  type: Notification_Types_Enum;
  updatedAt: Scalars['timestamptz'];
  userIds: Scalars['jsonb'];
  version: Scalars['bigint'];
};


/** columns and relationships of "notifications" */
export type NotificationsPayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "notifications" */
export type NotificationsUserIdsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "notifications" */
export type Notifications_Aggregate = {
  __typename: 'notifications_aggregate';
  aggregate?: Maybe<Notifications_Aggregate_Fields>;
  nodes: Array<Notifications>;
};

export type Notifications_Aggregate_Bool_Exp = {
  count?: InputMaybe<Notifications_Aggregate_Bool_Exp_Count>;
};

export type Notifications_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Notifications_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "notifications" */
export type Notifications_Aggregate_Fields = {
  __typename: 'notifications_aggregate_fields';
  avg?: Maybe<Notifications_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Notifications_Max_Fields>;
  min?: Maybe<Notifications_Min_Fields>;
  stddev?: Maybe<Notifications_Stddev_Fields>;
  stddev_pop?: Maybe<Notifications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Notifications_Stddev_Samp_Fields>;
  sum?: Maybe<Notifications_Sum_Fields>;
  var_pop?: Maybe<Notifications_Var_Pop_Fields>;
  var_samp?: Maybe<Notifications_Var_Samp_Fields>;
  variance?: Maybe<Notifications_Variance_Fields>;
};


/** aggregate fields of "notifications" */
export type Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "notifications" */
export type Notifications_Aggregate_Order_By = {
  avg?: InputMaybe<Notifications_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Notifications_Max_Order_By>;
  min?: InputMaybe<Notifications_Min_Order_By>;
  stddev?: InputMaybe<Notifications_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Notifications_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Notifications_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Notifications_Sum_Order_By>;
  var_pop?: InputMaybe<Notifications_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Notifications_Var_Samp_Order_By>;
  variance?: InputMaybe<Notifications_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Notifications_Append_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
  userIds?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "notifications" */
export type Notifications_Arr_Rel_Insert_Input = {
  data: Array<Notifications_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};

/** aggregate avg on columns */
export type Notifications_Avg_Fields = {
  __typename: 'notifications_avg_fields';
  ackedByUserId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  errorId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "notifications" */
export type Notifications_Avg_Order_By = {
  ackedByUserId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  errorId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export type Notifications_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Notifications_Bool_Exp>>;
  _not?: InputMaybe<Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Notifications_Bool_Exp>>;
  ackedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  ackedByUser?: InputMaybe<Users_Bool_Exp>;
  ackedByUserId?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerOrder?: InputMaybe<Customer_Orders_Bool_Exp>;
  customerOrderId?: InputMaybe<Bigint_Comparison_Exp>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  customerOrderItemId?: InputMaybe<Bigint_Comparison_Exp>;
  errorId?: InputMaybe<Bigint_Comparison_Exp>;
  firstShownAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Notification_Types_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userIds?: InputMaybe<Jsonb_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "notifications" */
export enum Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationsPkey = 'notifications_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Notifications_Delete_At_Path_Input = {
  payload?: InputMaybe<Array<Scalars['String']>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Notifications_Delete_Elem_Input = {
  payload?: InputMaybe<Scalars['Int']>;
  userIds?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Notifications_Delete_Key_Input = {
  payload?: InputMaybe<Scalars['String']>;
  userIds?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "notifications" */
export type Notifications_Inc_Input = {
  ackedByUserId?: InputMaybe<Scalars['bigint']>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  errorId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "notifications" */
export type Notifications_Insert_Input = {
  ackedAt?: InputMaybe<Scalars['timestamptz']>;
  ackedByUser?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  ackedByUserId?: InputMaybe<Scalars['bigint']>;
  customerOrder?: InputMaybe<Customer_Orders_Obj_Rel_Insert_Input>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Obj_Rel_Insert_Input>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  errorId?: InputMaybe<Scalars['bigint']>;
  firstShownAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Notification_Types_Enum>;
  userIds?: InputMaybe<Scalars['jsonb']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Notifications_Max_Fields = {
  __typename: 'notifications_max_fields';
  ackedAt?: Maybe<Scalars['timestamptz']>;
  ackedByUserId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  errorId?: Maybe<Scalars['bigint']>;
  firstShownAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "notifications" */
export type Notifications_Max_Order_By = {
  ackedAt?: InputMaybe<Order_By>;
  ackedByUserId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  errorId?: InputMaybe<Order_By>;
  firstShownAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Notifications_Min_Fields = {
  __typename: 'notifications_min_fields';
  ackedAt?: Maybe<Scalars['timestamptz']>;
  ackedByUserId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  errorId?: Maybe<Scalars['bigint']>;
  firstShownAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "notifications" */
export type Notifications_Min_Order_By = {
  ackedAt?: InputMaybe<Order_By>;
  ackedByUserId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  errorId?: InputMaybe<Order_By>;
  firstShownAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "notifications" */
export type Notifications_Mutation_Response = {
  __typename: 'notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notifications>;
};

/** on_conflict condition type for table "notifications" */
export type Notifications_On_Conflict = {
  constraint: Notifications_Constraint;
  update_columns?: Array<Notifications_Update_Column>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "notifications". */
export type Notifications_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  ackedAt?: InputMaybe<Order_By>;
  ackedByUser?: InputMaybe<Users_Order_By>;
  ackedByUserId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrder?: InputMaybe<Customer_Orders_Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  errorId?: InputMaybe<Order_By>;
  firstShownAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userIds?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notifications */
export type Notifications_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Notifications_Prepend_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
  userIds?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "notifications" */
export enum Notifications_Select_Column {
  /** column name */
  AckedAt = 'ackedAt',
  /** column name */
  AckedByUserId = 'ackedByUserId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerOrderId = 'customerOrderId',
  /** column name */
  CustomerOrderItemId = 'customerOrderItemId',
  /** column name */
  ErrorId = 'errorId',
  /** column name */
  FirstShownAt = 'firstShownAt',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserIds = 'userIds',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "notifications" */
export type Notifications_Set_Input = {
  ackedAt?: InputMaybe<Scalars['timestamptz']>;
  ackedByUserId?: InputMaybe<Scalars['bigint']>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  errorId?: InputMaybe<Scalars['bigint']>;
  firstShownAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Notification_Types_Enum>;
  userIds?: InputMaybe<Scalars['jsonb']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Notifications_Stddev_Fields = {
  __typename: 'notifications_stddev_fields';
  ackedByUserId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  errorId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "notifications" */
export type Notifications_Stddev_Order_By = {
  ackedByUserId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  errorId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Notifications_Stddev_Pop_Fields = {
  __typename: 'notifications_stddev_pop_fields';
  ackedByUserId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  errorId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "notifications" */
export type Notifications_Stddev_Pop_Order_By = {
  ackedByUserId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  errorId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Notifications_Stddev_Samp_Fields = {
  __typename: 'notifications_stddev_samp_fields';
  ackedByUserId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  errorId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "notifications" */
export type Notifications_Stddev_Samp_Order_By = {
  ackedByUserId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  errorId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "notifications" */
export type Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notifications_Stream_Cursor_Value_Input = {
  ackedAt?: InputMaybe<Scalars['timestamptz']>;
  ackedByUserId?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  errorId?: InputMaybe<Scalars['bigint']>;
  firstShownAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  tenant?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Notification_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userIds?: InputMaybe<Scalars['jsonb']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Notifications_Sum_Fields = {
  __typename: 'notifications_sum_fields';
  ackedByUserId?: Maybe<Scalars['bigint']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  errorId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "notifications" */
export type Notifications_Sum_Order_By = {
  ackedByUserId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  errorId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "notifications" */
export enum Notifications_Update_Column {
  /** column name */
  AckedAt = 'ackedAt',
  /** column name */
  AckedByUserId = 'ackedByUserId',
  /** column name */
  CustomerOrderId = 'customerOrderId',
  /** column name */
  CustomerOrderItemId = 'customerOrderItemId',
  /** column name */
  ErrorId = 'errorId',
  /** column name */
  FirstShownAt = 'firstShownAt',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Type = 'type',
  /** column name */
  UserIds = 'userIds',
  /** column name */
  Version = 'version'
}

export type Notifications_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Notifications_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Notifications_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Notifications_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Notifications_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Notifications_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Notifications_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notifications_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Notifications_Var_Pop_Fields = {
  __typename: 'notifications_var_pop_fields';
  ackedByUserId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  errorId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "notifications" */
export type Notifications_Var_Pop_Order_By = {
  ackedByUserId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  errorId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Notifications_Var_Samp_Fields = {
  __typename: 'notifications_var_samp_fields';
  ackedByUserId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  errorId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "notifications" */
export type Notifications_Var_Samp_Order_By = {
  ackedByUserId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  errorId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Notifications_Variance_Fields = {
  __typename: 'notifications_variance_fields';
  ackedByUserId?: Maybe<Scalars['Float']>;
  customerOrderId?: Maybe<Scalars['Float']>;
  customerOrderItemId?: Maybe<Scalars['Float']>;
  errorId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "notifications" */
export type Notifications_Variance_Order_By = {
  ackedByUserId?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  errorId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** Boolean expression to compare columns of type "oid". All fields are combined with logical 'AND'. */
export type Oid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['oid']>;
  _gt?: InputMaybe<Scalars['oid']>;
  _gte?: InputMaybe<Scalars['oid']>;
  _in?: InputMaybe<Array<Scalars['oid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['oid']>;
  _lte?: InputMaybe<Scalars['oid']>;
  _neq?: InputMaybe<Scalars['oid']>;
  _nin?: InputMaybe<Array<Scalars['oid']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Order_Supplied_Products_Args = {
  _delivery_date?: InputMaybe<Scalars['date']>;
  _supplied_products?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "partial_deliveries" */
export type Partial_Deliveries = {
  __typename: 'partial_deliveries';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customerOrder: Customer_Orders;
  customerOrderId: Scalars['bigint'];
  id: Scalars['bigint'];
  /** An array relationship */
  loggedActions: Array<Partial_Delivery_Logged_Actions>;
  /** An aggregate relationship */
  loggedActions_aggregate: Partial_Delivery_Logged_Actions_Aggregate;
  ordinalPosition: Scalars['bigint'];
  /** An object relationship */
  pdfDocument: S3_Objects;
  pdfDocumentId: Scalars['bigint'];
  /** An array relationship */
  pickedItems: Array<Picked_Items>;
  /** An aggregate relationship */
  pickedItems_aggregate: Picked_Items_Aggregate;
  /** An object relationship */
  targetAccount: Accounts;
  targetAccountId: Scalars['bigint'];
  tenant: Scalars['String'];
};


/** columns and relationships of "partial_deliveries" */
export type Partial_DeliveriesLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Partial_Delivery_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Delivery_Logged_Actions_Order_By>>;
  where?: InputMaybe<Partial_Delivery_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "partial_deliveries" */
export type Partial_DeliveriesLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partial_Delivery_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Delivery_Logged_Actions_Order_By>>;
  where?: InputMaybe<Partial_Delivery_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "partial_deliveries" */
export type Partial_DeliveriesPickedItemsArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "partial_deliveries" */
export type Partial_DeliveriesPickedItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};

/** aggregated selection of "partial_deliveries" */
export type Partial_Deliveries_Aggregate = {
  __typename: 'partial_deliveries_aggregate';
  aggregate?: Maybe<Partial_Deliveries_Aggregate_Fields>;
  nodes: Array<Partial_Deliveries>;
};

export type Partial_Deliveries_Aggregate_Bool_Exp = {
  count?: InputMaybe<Partial_Deliveries_Aggregate_Bool_Exp_Count>;
};

export type Partial_Deliveries_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Partial_Deliveries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Partial_Deliveries_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "partial_deliveries" */
export type Partial_Deliveries_Aggregate_Fields = {
  __typename: 'partial_deliveries_aggregate_fields';
  avg?: Maybe<Partial_Deliveries_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partial_Deliveries_Max_Fields>;
  min?: Maybe<Partial_Deliveries_Min_Fields>;
  stddev?: Maybe<Partial_Deliveries_Stddev_Fields>;
  stddev_pop?: Maybe<Partial_Deliveries_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partial_Deliveries_Stddev_Samp_Fields>;
  sum?: Maybe<Partial_Deliveries_Sum_Fields>;
  var_pop?: Maybe<Partial_Deliveries_Var_Pop_Fields>;
  var_samp?: Maybe<Partial_Deliveries_Var_Samp_Fields>;
  variance?: Maybe<Partial_Deliveries_Variance_Fields>;
};


/** aggregate fields of "partial_deliveries" */
export type Partial_Deliveries_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partial_Deliveries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "partial_deliveries" */
export type Partial_Deliveries_Aggregate_Order_By = {
  avg?: InputMaybe<Partial_Deliveries_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Partial_Deliveries_Max_Order_By>;
  min?: InputMaybe<Partial_Deliveries_Min_Order_By>;
  stddev?: InputMaybe<Partial_Deliveries_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Partial_Deliveries_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Partial_Deliveries_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Partial_Deliveries_Sum_Order_By>;
  var_pop?: InputMaybe<Partial_Deliveries_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Partial_Deliveries_Var_Samp_Order_By>;
  variance?: InputMaybe<Partial_Deliveries_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "partial_deliveries" */
export type Partial_Deliveries_Arr_Rel_Insert_Input = {
  data: Array<Partial_Deliveries_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Partial_Deliveries_On_Conflict>;
};

/** aggregate avg on columns */
export type Partial_Deliveries_Avg_Fields = {
  __typename: 'partial_deliveries_avg_fields';
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  targetAccountId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "partial_deliveries" */
export type Partial_Deliveries_Avg_Order_By = {
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "partial_deliveries". All fields are combined with a logical 'AND'. */
export type Partial_Deliveries_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Partial_Deliveries_Bool_Exp>>;
  _not?: InputMaybe<Partial_Deliveries_Bool_Exp>;
  _or?: InputMaybe<Array<Partial_Deliveries_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerOrder?: InputMaybe<Customer_Orders_Bool_Exp>;
  customerOrderId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  loggedActions?: InputMaybe<Partial_Delivery_Logged_Actions_Bool_Exp>;
  loggedActions_aggregate?: InputMaybe<Partial_Delivery_Logged_Actions_Aggregate_Bool_Exp>;
  ordinalPosition?: InputMaybe<Bigint_Comparison_Exp>;
  pdfDocument?: InputMaybe<S3_Objects_Bool_Exp>;
  pdfDocumentId?: InputMaybe<Bigint_Comparison_Exp>;
  pickedItems?: InputMaybe<Picked_Items_Bool_Exp>;
  pickedItems_aggregate?: InputMaybe<Picked_Items_Aggregate_Bool_Exp>;
  targetAccount?: InputMaybe<Accounts_Bool_Exp>;
  targetAccountId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "partial_deliveries" */
export enum Partial_Deliveries_Constraint {
  /** unique or primary key constraint on columns "customer_order_id", "ordinal_position" */
  PartialDeliveriesCustomerOrderIdOrdinalPositionKey = 'partial_deliveries_customer_order_id_ordinal_position_key',
  /** unique or primary key constraint on columns "id" */
  PartialDeliveriesPkey = 'partial_deliveries_pkey'
}

/** input type for incrementing numeric columns in table "partial_deliveries" */
export type Partial_Deliveries_Inc_Input = {
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['bigint']>;
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  targetAccountId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "partial_deliveries" */
export type Partial_Deliveries_Insert_Input = {
  customerOrder?: InputMaybe<Customer_Orders_Obj_Rel_Insert_Input>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['bigint']>;
  pdfDocument?: InputMaybe<S3_Objects_Obj_Rel_Insert_Input>;
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  pickedItems?: InputMaybe<Picked_Items_Arr_Rel_Insert_Input>;
  targetAccount?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  targetAccountId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Partial_Deliveries_Max_Fields = {
  __typename: 'partial_deliveries_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['bigint']>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  targetAccountId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "partial_deliveries" */
export type Partial_Deliveries_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Partial_Deliveries_Min_Fields = {
  __typename: 'partial_deliveries_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['bigint']>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  targetAccountId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "partial_deliveries" */
export type Partial_Deliveries_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "partial_deliveries" */
export type Partial_Deliveries_Mutation_Response = {
  __typename: 'partial_deliveries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partial_Deliveries>;
};

/** input type for inserting object relation for remote table "partial_deliveries" */
export type Partial_Deliveries_Obj_Rel_Insert_Input = {
  data: Partial_Deliveries_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Partial_Deliveries_On_Conflict>;
};

/** on_conflict condition type for table "partial_deliveries" */
export type Partial_Deliveries_On_Conflict = {
  constraint: Partial_Deliveries_Constraint;
  update_columns?: Array<Partial_Deliveries_Update_Column>;
  where?: InputMaybe<Partial_Deliveries_Bool_Exp>;
};

/** Ordering options when selecting data from "partial_deliveries". */
export type Partial_Deliveries_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrder?: InputMaybe<Customer_Orders_Order_By>;
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loggedActions_aggregate?: InputMaybe<Partial_Delivery_Logged_Actions_Aggregate_Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pdfDocument?: InputMaybe<S3_Objects_Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  pickedItems_aggregate?: InputMaybe<Picked_Items_Aggregate_Order_By>;
  targetAccount?: InputMaybe<Accounts_Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partial_deliveries */
export type Partial_Deliveries_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "partial_deliveries" */
export enum Partial_Deliveries_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerOrderId = 'customerOrderId',
  /** column name */
  Id = 'id',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  PdfDocumentId = 'pdfDocumentId',
  /** column name */
  TargetAccountId = 'targetAccountId',
  /** column name */
  Tenant = 'tenant'
}

/** input type for updating data in table "partial_deliveries" */
export type Partial_Deliveries_Set_Input = {
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['bigint']>;
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  targetAccountId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Partial_Deliveries_Stddev_Fields = {
  __typename: 'partial_deliveries_stddev_fields';
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  targetAccountId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "partial_deliveries" */
export type Partial_Deliveries_Stddev_Order_By = {
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Partial_Deliveries_Stddev_Pop_Fields = {
  __typename: 'partial_deliveries_stddev_pop_fields';
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  targetAccountId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "partial_deliveries" */
export type Partial_Deliveries_Stddev_Pop_Order_By = {
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Partial_Deliveries_Stddev_Samp_Fields = {
  __typename: 'partial_deliveries_stddev_samp_fields';
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  targetAccountId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "partial_deliveries" */
export type Partial_Deliveries_Stddev_Samp_Order_By = {
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "partial_deliveries" */
export type Partial_Deliveries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partial_Deliveries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partial_Deliveries_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerOrderId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['bigint']>;
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  targetAccountId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Partial_Deliveries_Sum_Fields = {
  __typename: 'partial_deliveries_sum_fields';
  customerOrderId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['bigint']>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  targetAccountId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "partial_deliveries" */
export type Partial_Deliveries_Sum_Order_By = {
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
};

/** update columns of table "partial_deliveries" */
export enum Partial_Deliveries_Update_Column {
  /** column name */
  CustomerOrderId = 'customerOrderId',
  /** column name */
  Id = 'id',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  PdfDocumentId = 'pdfDocumentId',
  /** column name */
  TargetAccountId = 'targetAccountId'
}

export type Partial_Deliveries_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partial_Deliveries_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partial_Deliveries_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partial_Deliveries_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partial_Deliveries_Var_Pop_Fields = {
  __typename: 'partial_deliveries_var_pop_fields';
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  targetAccountId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "partial_deliveries" */
export type Partial_Deliveries_Var_Pop_Order_By = {
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Partial_Deliveries_Var_Samp_Fields = {
  __typename: 'partial_deliveries_var_samp_fields';
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  targetAccountId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "partial_deliveries" */
export type Partial_Deliveries_Var_Samp_Order_By = {
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Partial_Deliveries_Variance_Fields = {
  __typename: 'partial_deliveries_variance_fields';
  customerOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  targetAccountId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "partial_deliveries" */
export type Partial_Deliveries_Variance_Order_By = {
  customerOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
};

/** columns and relationships of "partial_delivery_logged_actions" */
export type Partial_Delivery_Logged_Actions = {
  __typename: 'partial_delivery_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  entity?: Maybe<Partial_Deliveries>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "partial_delivery_logged_actions" */
export type Partial_Delivery_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "partial_delivery_logged_actions" */
export type Partial_Delivery_Logged_Actions_Aggregate = {
  __typename: 'partial_delivery_logged_actions_aggregate';
  aggregate?: Maybe<Partial_Delivery_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Partial_Delivery_Logged_Actions>;
};

export type Partial_Delivery_Logged_Actions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Partial_Delivery_Logged_Actions_Aggregate_Bool_Exp_Count>;
};

export type Partial_Delivery_Logged_Actions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Partial_Delivery_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Partial_Delivery_Logged_Actions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "partial_delivery_logged_actions" */
export type Partial_Delivery_Logged_Actions_Aggregate_Fields = {
  __typename: 'partial_delivery_logged_actions_aggregate_fields';
  avg?: Maybe<Partial_Delivery_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partial_Delivery_Logged_Actions_Max_Fields>;
  min?: Maybe<Partial_Delivery_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Partial_Delivery_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Partial_Delivery_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partial_Delivery_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Partial_Delivery_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Partial_Delivery_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Partial_Delivery_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Partial_Delivery_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "partial_delivery_logged_actions" */
export type Partial_Delivery_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partial_Delivery_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "partial_delivery_logged_actions" */
export type Partial_Delivery_Logged_Actions_Aggregate_Order_By = {
  avg?: InputMaybe<Partial_Delivery_Logged_Actions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Partial_Delivery_Logged_Actions_Max_Order_By>;
  min?: InputMaybe<Partial_Delivery_Logged_Actions_Min_Order_By>;
  stddev?: InputMaybe<Partial_Delivery_Logged_Actions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Partial_Delivery_Logged_Actions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Partial_Delivery_Logged_Actions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Partial_Delivery_Logged_Actions_Sum_Order_By>;
  var_pop?: InputMaybe<Partial_Delivery_Logged_Actions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Partial_Delivery_Logged_Actions_Var_Samp_Order_By>;
  variance?: InputMaybe<Partial_Delivery_Logged_Actions_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Partial_Delivery_Logged_Actions_Avg_Fields = {
  __typename: 'partial_delivery_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "partial_delivery_logged_actions" */
export type Partial_Delivery_Logged_Actions_Avg_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "partial_delivery_logged_actions". All fields are combined with a logical 'AND'. */
export type Partial_Delivery_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Partial_Delivery_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Partial_Delivery_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Partial_Delivery_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entity?: InputMaybe<Partial_Deliveries_Bool_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Partial_Delivery_Logged_Actions_Max_Fields = {
  __typename: 'partial_delivery_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "partial_delivery_logged_actions" */
export type Partial_Delivery_Logged_Actions_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Partial_Delivery_Logged_Actions_Min_Fields = {
  __typename: 'partial_delivery_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "partial_delivery_logged_actions" */
export type Partial_Delivery_Logged_Actions_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "partial_delivery_logged_actions". */
export type Partial_Delivery_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entity?: InputMaybe<Partial_Deliveries_Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "partial_delivery_logged_actions" */
export enum Partial_Delivery_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Role = 'role',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Partial_Delivery_Logged_Actions_Stddev_Fields = {
  __typename: 'partial_delivery_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "partial_delivery_logged_actions" */
export type Partial_Delivery_Logged_Actions_Stddev_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Partial_Delivery_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'partial_delivery_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "partial_delivery_logged_actions" */
export type Partial_Delivery_Logged_Actions_Stddev_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Partial_Delivery_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'partial_delivery_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "partial_delivery_logged_actions" */
export type Partial_Delivery_Logged_Actions_Stddev_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "partial_delivery_logged_actions" */
export type Partial_Delivery_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partial_Delivery_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partial_Delivery_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  role?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Partial_Delivery_Logged_Actions_Sum_Fields = {
  __typename: 'partial_delivery_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "partial_delivery_logged_actions" */
export type Partial_Delivery_Logged_Actions_Sum_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Partial_Delivery_Logged_Actions_Var_Pop_Fields = {
  __typename: 'partial_delivery_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "partial_delivery_logged_actions" */
export type Partial_Delivery_Logged_Actions_Var_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Partial_Delivery_Logged_Actions_Var_Samp_Fields = {
  __typename: 'partial_delivery_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "partial_delivery_logged_actions" */
export type Partial_Delivery_Logged_Actions_Var_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Partial_Delivery_Logged_Actions_Variance_Fields = {
  __typename: 'partial_delivery_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "partial_delivery_logged_actions" */
export type Partial_Delivery_Logged_Actions_Variance_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "phones" */
export type Phones = {
  __typename: 'phones';
  /** An object relationship */
  Tenant: Tenants;
  contactId: Scalars['bigint'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  ordinalPosition: Scalars['Int'];
  phoneNumber: Scalars['String'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};

/** aggregated selection of "phones" */
export type Phones_Aggregate = {
  __typename: 'phones_aggregate';
  aggregate?: Maybe<Phones_Aggregate_Fields>;
  nodes: Array<Phones>;
};

export type Phones_Aggregate_Bool_Exp = {
  count?: InputMaybe<Phones_Aggregate_Bool_Exp_Count>;
};

export type Phones_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Phones_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Phones_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "phones" */
export type Phones_Aggregate_Fields = {
  __typename: 'phones_aggregate_fields';
  avg?: Maybe<Phones_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Phones_Max_Fields>;
  min?: Maybe<Phones_Min_Fields>;
  stddev?: Maybe<Phones_Stddev_Fields>;
  stddev_pop?: Maybe<Phones_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Phones_Stddev_Samp_Fields>;
  sum?: Maybe<Phones_Sum_Fields>;
  var_pop?: Maybe<Phones_Var_Pop_Fields>;
  var_samp?: Maybe<Phones_Var_Samp_Fields>;
  variance?: Maybe<Phones_Variance_Fields>;
};


/** aggregate fields of "phones" */
export type Phones_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Phones_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "phones" */
export type Phones_Aggregate_Order_By = {
  avg?: InputMaybe<Phones_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Phones_Max_Order_By>;
  min?: InputMaybe<Phones_Min_Order_By>;
  stddev?: InputMaybe<Phones_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Phones_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Phones_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Phones_Sum_Order_By>;
  var_pop?: InputMaybe<Phones_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Phones_Var_Samp_Order_By>;
  variance?: InputMaybe<Phones_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "phones" */
export type Phones_Arr_Rel_Insert_Input = {
  data: Array<Phones_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Phones_On_Conflict>;
};

/** aggregate avg on columns */
export type Phones_Avg_Fields = {
  __typename: 'phones_avg_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "phones" */
export type Phones_Avg_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "phones". All fields are combined with a logical 'AND'. */
export type Phones_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Phones_Bool_Exp>>;
  _not?: InputMaybe<Phones_Bool_Exp>;
  _or?: InputMaybe<Array<Phones_Bool_Exp>>;
  contactId?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "phones" */
export enum Phones_Constraint {
  /** unique or primary key constraint on columns "contact_id", "ordinal_position" */
  PhonesContactIdOrdinalPositionKey = 'phones_contact_id_ordinal_position_key',
  /** unique or primary key constraint on columns "id" */
  PhonesPkey = 'phones_pkey'
}

/** input type for incrementing numeric columns in table "phones" */
export type Phones_Inc_Input = {
  contactId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "phones" */
export type Phones_Insert_Input = {
  contactId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Phones_Max_Fields = {
  __typename: 'phones_max_fields';
  contactId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "phones" */
export type Phones_Max_Order_By = {
  contactId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Phones_Min_Fields = {
  __typename: 'phones_min_fields';
  contactId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "phones" */
export type Phones_Min_Order_By = {
  contactId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "phones" */
export type Phones_Mutation_Response = {
  __typename: 'phones_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Phones>;
};

/** on_conflict condition type for table "phones" */
export type Phones_On_Conflict = {
  constraint: Phones_Constraint;
  update_columns?: Array<Phones_Update_Column>;
  where?: InputMaybe<Phones_Bool_Exp>;
};

/** Ordering options when selecting data from "phones". */
export type Phones_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  contactId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: phones */
export type Phones_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "phones" */
export enum Phones_Select_Column {
  /** column name */
  ContactId = 'contactId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "phones" */
export type Phones_Set_Input = {
  contactId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Phones_Stddev_Fields = {
  __typename: 'phones_stddev_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "phones" */
export type Phones_Stddev_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Phones_Stddev_Pop_Fields = {
  __typename: 'phones_stddev_pop_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "phones" */
export type Phones_Stddev_Pop_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Phones_Stddev_Samp_Fields = {
  __typename: 'phones_stddev_samp_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "phones" */
export type Phones_Stddev_Samp_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "phones" */
export type Phones_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Phones_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Phones_Stream_Cursor_Value_Input = {
  contactId?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Phones_Sum_Fields = {
  __typename: 'phones_sum_fields';
  contactId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "phones" */
export type Phones_Sum_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "phones" */
export enum Phones_Update_Column {
  /** column name */
  ContactId = 'contactId',
  /** column name */
  Id = 'id',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Version = 'version'
}

export type Phones_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Phones_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Phones_Set_Input>;
  /** filter the rows which have to be updated */
  where: Phones_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Phones_Var_Pop_Fields = {
  __typename: 'phones_var_pop_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "phones" */
export type Phones_Var_Pop_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Phones_Var_Samp_Fields = {
  __typename: 'phones_var_samp_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "phones" */
export type Phones_Var_Samp_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Phones_Variance_Fields = {
  __typename: 'phones_variance_fields';
  contactId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "phones" */
export type Phones_Variance_Order_By = {
  contactId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "picked_item_containers" */
export type Picked_Item_Containers = {
  __typename: 'picked_item_containers';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customerOrderPickedContainer: Customer_Order_Picked_Container;
  customerOrderPickedContainerId: Scalars['bigint'];
  /** An object relationship */
  pickedItem: Picked_Items;
  pickedItemId: Scalars['bigint'];
  tenant: Scalars['String'];
};

/** aggregated selection of "picked_item_containers" */
export type Picked_Item_Containers_Aggregate = {
  __typename: 'picked_item_containers_aggregate';
  aggregate?: Maybe<Picked_Item_Containers_Aggregate_Fields>;
  nodes: Array<Picked_Item_Containers>;
};

export type Picked_Item_Containers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Picked_Item_Containers_Aggregate_Bool_Exp_Count>;
};

export type Picked_Item_Containers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Picked_Item_Containers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Picked_Item_Containers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "picked_item_containers" */
export type Picked_Item_Containers_Aggregate_Fields = {
  __typename: 'picked_item_containers_aggregate_fields';
  avg?: Maybe<Picked_Item_Containers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Picked_Item_Containers_Max_Fields>;
  min?: Maybe<Picked_Item_Containers_Min_Fields>;
  stddev?: Maybe<Picked_Item_Containers_Stddev_Fields>;
  stddev_pop?: Maybe<Picked_Item_Containers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Picked_Item_Containers_Stddev_Samp_Fields>;
  sum?: Maybe<Picked_Item_Containers_Sum_Fields>;
  var_pop?: Maybe<Picked_Item_Containers_Var_Pop_Fields>;
  var_samp?: Maybe<Picked_Item_Containers_Var_Samp_Fields>;
  variance?: Maybe<Picked_Item_Containers_Variance_Fields>;
};


/** aggregate fields of "picked_item_containers" */
export type Picked_Item_Containers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Picked_Item_Containers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "picked_item_containers" */
export type Picked_Item_Containers_Aggregate_Order_By = {
  avg?: InputMaybe<Picked_Item_Containers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Picked_Item_Containers_Max_Order_By>;
  min?: InputMaybe<Picked_Item_Containers_Min_Order_By>;
  stddev?: InputMaybe<Picked_Item_Containers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Picked_Item_Containers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Picked_Item_Containers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Picked_Item_Containers_Sum_Order_By>;
  var_pop?: InputMaybe<Picked_Item_Containers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Picked_Item_Containers_Var_Samp_Order_By>;
  variance?: InputMaybe<Picked_Item_Containers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "picked_item_containers" */
export type Picked_Item_Containers_Arr_Rel_Insert_Input = {
  data: Array<Picked_Item_Containers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Picked_Item_Containers_On_Conflict>;
};

/** aggregate avg on columns */
export type Picked_Item_Containers_Avg_Fields = {
  __typename: 'picked_item_containers_avg_fields';
  customerOrderPickedContainerId?: Maybe<Scalars['Float']>;
  pickedItemId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "picked_item_containers" */
export type Picked_Item_Containers_Avg_Order_By = {
  customerOrderPickedContainerId?: InputMaybe<Order_By>;
  pickedItemId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "picked_item_containers". All fields are combined with a logical 'AND'. */
export type Picked_Item_Containers_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Picked_Item_Containers_Bool_Exp>>;
  _not?: InputMaybe<Picked_Item_Containers_Bool_Exp>;
  _or?: InputMaybe<Array<Picked_Item_Containers_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerOrderPickedContainer?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
  customerOrderPickedContainerId?: InputMaybe<Bigint_Comparison_Exp>;
  pickedItem?: InputMaybe<Picked_Items_Bool_Exp>;
  pickedItemId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "picked_item_containers" */
export enum Picked_Item_Containers_Constraint {
  /** unique or primary key constraint on columns "picked_item_id", "customer_order_picked_container_id" */
  PickedItemContainersPkey = 'picked_item_containers_pkey'
}

/** input type for incrementing numeric columns in table "picked_item_containers" */
export type Picked_Item_Containers_Inc_Input = {
  customerOrderPickedContainerId?: InputMaybe<Scalars['bigint']>;
  pickedItemId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "picked_item_containers" */
export type Picked_Item_Containers_Insert_Input = {
  customerOrderPickedContainer?: InputMaybe<Customer_Order_Picked_Container_Obj_Rel_Insert_Input>;
  customerOrderPickedContainerId?: InputMaybe<Scalars['bigint']>;
  pickedItem?: InputMaybe<Picked_Items_Obj_Rel_Insert_Input>;
  pickedItemId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Picked_Item_Containers_Max_Fields = {
  __typename: 'picked_item_containers_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderPickedContainerId?: Maybe<Scalars['bigint']>;
  pickedItemId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "picked_item_containers" */
export type Picked_Item_Containers_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerOrderPickedContainerId?: InputMaybe<Order_By>;
  pickedItemId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Picked_Item_Containers_Min_Fields = {
  __typename: 'picked_item_containers_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderPickedContainerId?: Maybe<Scalars['bigint']>;
  pickedItemId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "picked_item_containers" */
export type Picked_Item_Containers_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerOrderPickedContainerId?: InputMaybe<Order_By>;
  pickedItemId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "picked_item_containers" */
export type Picked_Item_Containers_Mutation_Response = {
  __typename: 'picked_item_containers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Picked_Item_Containers>;
};

/** on_conflict condition type for table "picked_item_containers" */
export type Picked_Item_Containers_On_Conflict = {
  constraint: Picked_Item_Containers_Constraint;
  update_columns?: Array<Picked_Item_Containers_Update_Column>;
  where?: InputMaybe<Picked_Item_Containers_Bool_Exp>;
};

/** Ordering options when selecting data from "picked_item_containers". */
export type Picked_Item_Containers_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrderPickedContainer?: InputMaybe<Customer_Order_Picked_Container_Order_By>;
  customerOrderPickedContainerId?: InputMaybe<Order_By>;
  pickedItem?: InputMaybe<Picked_Items_Order_By>;
  pickedItemId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** primary key columns input for table: picked_item_containers */
export type Picked_Item_Containers_Pk_Columns_Input = {
  customerOrderPickedContainerId: Scalars['bigint'];
  pickedItemId: Scalars['bigint'];
};

/** select columns of table "picked_item_containers" */
export enum Picked_Item_Containers_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerOrderPickedContainerId = 'customerOrderPickedContainerId',
  /** column name */
  PickedItemId = 'pickedItemId',
  /** column name */
  Tenant = 'tenant'
}

/** input type for updating data in table "picked_item_containers" */
export type Picked_Item_Containers_Set_Input = {
  customerOrderPickedContainerId?: InputMaybe<Scalars['bigint']>;
  pickedItemId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Picked_Item_Containers_Stddev_Fields = {
  __typename: 'picked_item_containers_stddev_fields';
  customerOrderPickedContainerId?: Maybe<Scalars['Float']>;
  pickedItemId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "picked_item_containers" */
export type Picked_Item_Containers_Stddev_Order_By = {
  customerOrderPickedContainerId?: InputMaybe<Order_By>;
  pickedItemId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Picked_Item_Containers_Stddev_Pop_Fields = {
  __typename: 'picked_item_containers_stddev_pop_fields';
  customerOrderPickedContainerId?: Maybe<Scalars['Float']>;
  pickedItemId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "picked_item_containers" */
export type Picked_Item_Containers_Stddev_Pop_Order_By = {
  customerOrderPickedContainerId?: InputMaybe<Order_By>;
  pickedItemId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Picked_Item_Containers_Stddev_Samp_Fields = {
  __typename: 'picked_item_containers_stddev_samp_fields';
  customerOrderPickedContainerId?: Maybe<Scalars['Float']>;
  pickedItemId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "picked_item_containers" */
export type Picked_Item_Containers_Stddev_Samp_Order_By = {
  customerOrderPickedContainerId?: InputMaybe<Order_By>;
  pickedItemId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "picked_item_containers" */
export type Picked_Item_Containers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Picked_Item_Containers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Picked_Item_Containers_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerOrderPickedContainerId?: InputMaybe<Scalars['bigint']>;
  pickedItemId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Picked_Item_Containers_Sum_Fields = {
  __typename: 'picked_item_containers_sum_fields';
  customerOrderPickedContainerId?: Maybe<Scalars['bigint']>;
  pickedItemId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "picked_item_containers" */
export type Picked_Item_Containers_Sum_Order_By = {
  customerOrderPickedContainerId?: InputMaybe<Order_By>;
  pickedItemId?: InputMaybe<Order_By>;
};

/** update columns of table "picked_item_containers" */
export enum Picked_Item_Containers_Update_Column {
  /** column name */
  CustomerOrderPickedContainerId = 'customerOrderPickedContainerId',
  /** column name */
  PickedItemId = 'pickedItemId'
}

export type Picked_Item_Containers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Picked_Item_Containers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Picked_Item_Containers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Picked_Item_Containers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Picked_Item_Containers_Var_Pop_Fields = {
  __typename: 'picked_item_containers_var_pop_fields';
  customerOrderPickedContainerId?: Maybe<Scalars['Float']>;
  pickedItemId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "picked_item_containers" */
export type Picked_Item_Containers_Var_Pop_Order_By = {
  customerOrderPickedContainerId?: InputMaybe<Order_By>;
  pickedItemId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Picked_Item_Containers_Var_Samp_Fields = {
  __typename: 'picked_item_containers_var_samp_fields';
  customerOrderPickedContainerId?: Maybe<Scalars['Float']>;
  pickedItemId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "picked_item_containers" */
export type Picked_Item_Containers_Var_Samp_Order_By = {
  customerOrderPickedContainerId?: InputMaybe<Order_By>;
  pickedItemId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Picked_Item_Containers_Variance_Fields = {
  __typename: 'picked_item_containers_variance_fields';
  customerOrderPickedContainerId?: Maybe<Scalars['Float']>;
  pickedItemId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "picked_item_containers" */
export type Picked_Item_Containers_Variance_Order_By = {
  customerOrderPickedContainerId?: InputMaybe<Order_By>;
  pickedItemId?: InputMaybe<Order_By>;
};

/** columns and relationships of "picked_item_logged_actions" */
export type Picked_Item_Logged_Actions = {
  __typename: 'picked_item_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  entity?: Maybe<Picked_Items>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "picked_item_logged_actions" */
export type Picked_Item_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "picked_item_logged_actions" */
export type Picked_Item_Logged_Actions_Aggregate = {
  __typename: 'picked_item_logged_actions_aggregate';
  aggregate?: Maybe<Picked_Item_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Picked_Item_Logged_Actions>;
};

export type Picked_Item_Logged_Actions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Picked_Item_Logged_Actions_Aggregate_Bool_Exp_Count>;
};

export type Picked_Item_Logged_Actions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Picked_Item_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Picked_Item_Logged_Actions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "picked_item_logged_actions" */
export type Picked_Item_Logged_Actions_Aggregate_Fields = {
  __typename: 'picked_item_logged_actions_aggregate_fields';
  avg?: Maybe<Picked_Item_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Picked_Item_Logged_Actions_Max_Fields>;
  min?: Maybe<Picked_Item_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Picked_Item_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Picked_Item_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Picked_Item_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Picked_Item_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Picked_Item_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Picked_Item_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Picked_Item_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "picked_item_logged_actions" */
export type Picked_Item_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Picked_Item_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "picked_item_logged_actions" */
export type Picked_Item_Logged_Actions_Aggregate_Order_By = {
  avg?: InputMaybe<Picked_Item_Logged_Actions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Picked_Item_Logged_Actions_Max_Order_By>;
  min?: InputMaybe<Picked_Item_Logged_Actions_Min_Order_By>;
  stddev?: InputMaybe<Picked_Item_Logged_Actions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Picked_Item_Logged_Actions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Picked_Item_Logged_Actions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Picked_Item_Logged_Actions_Sum_Order_By>;
  var_pop?: InputMaybe<Picked_Item_Logged_Actions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Picked_Item_Logged_Actions_Var_Samp_Order_By>;
  variance?: InputMaybe<Picked_Item_Logged_Actions_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Picked_Item_Logged_Actions_Avg_Fields = {
  __typename: 'picked_item_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "picked_item_logged_actions" */
export type Picked_Item_Logged_Actions_Avg_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "picked_item_logged_actions". All fields are combined with a logical 'AND'. */
export type Picked_Item_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Picked_Item_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Picked_Item_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Picked_Item_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entity?: InputMaybe<Picked_Items_Bool_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Picked_Item_Logged_Actions_Max_Fields = {
  __typename: 'picked_item_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "picked_item_logged_actions" */
export type Picked_Item_Logged_Actions_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Picked_Item_Logged_Actions_Min_Fields = {
  __typename: 'picked_item_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "picked_item_logged_actions" */
export type Picked_Item_Logged_Actions_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "picked_item_logged_actions". */
export type Picked_Item_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entity?: InputMaybe<Picked_Items_Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "picked_item_logged_actions" */
export enum Picked_Item_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Role = 'role',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Picked_Item_Logged_Actions_Stddev_Fields = {
  __typename: 'picked_item_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "picked_item_logged_actions" */
export type Picked_Item_Logged_Actions_Stddev_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Picked_Item_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'picked_item_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "picked_item_logged_actions" */
export type Picked_Item_Logged_Actions_Stddev_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Picked_Item_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'picked_item_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "picked_item_logged_actions" */
export type Picked_Item_Logged_Actions_Stddev_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "picked_item_logged_actions" */
export type Picked_Item_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Picked_Item_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Picked_Item_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  role?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Picked_Item_Logged_Actions_Sum_Fields = {
  __typename: 'picked_item_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "picked_item_logged_actions" */
export type Picked_Item_Logged_Actions_Sum_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Picked_Item_Logged_Actions_Var_Pop_Fields = {
  __typename: 'picked_item_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "picked_item_logged_actions" */
export type Picked_Item_Logged_Actions_Var_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Picked_Item_Logged_Actions_Var_Samp_Fields = {
  __typename: 'picked_item_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "picked_item_logged_actions" */
export type Picked_Item_Logged_Actions_Var_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Picked_Item_Logged_Actions_Variance_Fields = {
  __typename: 'picked_item_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "picked_item_logged_actions" */
export type Picked_Item_Logged_Actions_Variance_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "picked_item_state_transitions" */
export type Picked_Item_State_Transitions = {
  __typename: 'picked_item_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Picked_Item_States_Enum;
  toState: Picked_Item_States_Enum;
};

/** aggregated selection of "picked_item_state_transitions" */
export type Picked_Item_State_Transitions_Aggregate = {
  __typename: 'picked_item_state_transitions_aggregate';
  aggregate?: Maybe<Picked_Item_State_Transitions_Aggregate_Fields>;
  nodes: Array<Picked_Item_State_Transitions>;
};

/** aggregate fields of "picked_item_state_transitions" */
export type Picked_Item_State_Transitions_Aggregate_Fields = {
  __typename: 'picked_item_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Picked_Item_State_Transitions_Max_Fields>;
  min?: Maybe<Picked_Item_State_Transitions_Min_Fields>;
};


/** aggregate fields of "picked_item_state_transitions" */
export type Picked_Item_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Picked_Item_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "picked_item_state_transitions". All fields are combined with a logical 'AND'. */
export type Picked_Item_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Picked_Item_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Picked_Item_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Picked_Item_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Picked_Item_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Picked_Item_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Picked_Item_State_Transitions_Max_Fields = {
  __typename: 'picked_item_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Picked_Item_State_Transitions_Min_Fields = {
  __typename: 'picked_item_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "picked_item_state_transitions". */
export type Picked_Item_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "picked_item_state_transitions" */
export enum Picked_Item_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "picked_item_state_transitions" */
export type Picked_Item_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Picked_Item_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Picked_Item_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Picked_Item_States_Enum>;
  toState?: InputMaybe<Picked_Item_States_Enum>;
};

/** columns and relationships of "picked_item_states" */
export type Picked_Item_States = {
  __typename: 'picked_item_states';
  /** An array relationship */
  pickedItems: Array<Picked_Items>;
  /** An aggregate relationship */
  pickedItems_aggregate: Picked_Items_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "picked_item_states" */
export type Picked_Item_StatesPickedItemsArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "picked_item_states" */
export type Picked_Item_StatesPickedItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};

/** aggregated selection of "picked_item_states" */
export type Picked_Item_States_Aggregate = {
  __typename: 'picked_item_states_aggregate';
  aggregate?: Maybe<Picked_Item_States_Aggregate_Fields>;
  nodes: Array<Picked_Item_States>;
};

/** aggregate fields of "picked_item_states" */
export type Picked_Item_States_Aggregate_Fields = {
  __typename: 'picked_item_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Picked_Item_States_Max_Fields>;
  min?: Maybe<Picked_Item_States_Min_Fields>;
};


/** aggregate fields of "picked_item_states" */
export type Picked_Item_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Picked_Item_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "picked_item_states". All fields are combined with a logical 'AND'. */
export type Picked_Item_States_Bool_Exp = {
  _and?: InputMaybe<Array<Picked_Item_States_Bool_Exp>>;
  _not?: InputMaybe<Picked_Item_States_Bool_Exp>;
  _or?: InputMaybe<Array<Picked_Item_States_Bool_Exp>>;
  pickedItems?: InputMaybe<Picked_Items_Bool_Exp>;
  pickedItems_aggregate?: InputMaybe<Picked_Items_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Picked_Item_States_Enum {
  Committed = 'COMMITTED',
  Open = 'OPEN'
}

/** Boolean expression to compare columns of type "picked_item_states_enum". All fields are combined with logical 'AND'. */
export type Picked_Item_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Picked_Item_States_Enum>;
  _in?: InputMaybe<Array<Picked_Item_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Picked_Item_States_Enum>;
  _nin?: InputMaybe<Array<Picked_Item_States_Enum>>;
};

/** aggregate max on columns */
export type Picked_Item_States_Max_Fields = {
  __typename: 'picked_item_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Picked_Item_States_Min_Fields = {
  __typename: 'picked_item_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "picked_item_states". */
export type Picked_Item_States_Order_By = {
  pickedItems_aggregate?: InputMaybe<Picked_Items_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "picked_item_states" */
export enum Picked_Item_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "picked_item_states" */
export type Picked_Item_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Picked_Item_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Picked_Item_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "picked_items" */
export type Picked_Items = {
  __typename: 'picked_items';
  /** An object relationship */
  LotNumber?: Maybe<Lot_Numbers>;
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customerOrderItem: Customer_Order_Items;
  customerOrderItemId: Scalars['bigint'];
  gs1Batch?: Maybe<Scalars['String']>;
  gs1BestBefore?: Maybe<Scalars['date']>;
  /** An object relationship */
  gs1LogisticDocument?: Maybe<S3_Objects>;
  gs1LogisticDocumentId?: Maybe<Scalars['bigint']>;
  gs1ProductionDate?: Maybe<Scalars['date']>;
  id: Scalars['bigint'];
  /** An object relationship */
  journalEntry?: Maybe<Journal_Entries>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  loggedActions: Array<Picked_Item_Logged_Actions>;
  /** An aggregate relationship */
  loggedActions_aggregate: Picked_Item_Logged_Actions_Aggregate;
  lotNo?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  netWeight?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  partialDelivery?: Maybe<Partial_Deliveries>;
  partialDeliveryId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  pickedItemContainers: Array<Picked_Item_Containers>;
  /** An aggregate relationship */
  pickedItemContainers_aggregate: Picked_Item_Containers_Aggregate;
  sourceMonetaryQuantity: Scalars['bigint'];
  /** An object relationship */
  sourceMonetaryUnit: Product_Units;
  sourceMonetaryUnitId: Scalars['bigint'];
  sourceStorageQuantity: Scalars['bigint'];
  /** An object relationship */
  sourceStorageUnit: Product_Units;
  sourceStorageUnitId: Scalars['bigint'];
  sscc?: Maybe<Scalars['bigint']>;
  state: Picked_Item_States_Enum;
  /** An object relationship */
  stockLocation: Stock_Locations;
  stockLocationId: Scalars['bigint'];
  tareWeight: Scalars['bigint'];
  targetMonetaryQuantity: Scalars['bigint'];
  /** An object relationship */
  targetMonetaryUnit?: Maybe<Product_Units>;
  targetMonetaryUnitId?: Maybe<Scalars['bigint']>;
  targetStorageQuantity: Scalars['bigint'];
  /** An object relationship */
  targetStorageUnit: Product_Units;
  targetStorageUnitId: Scalars['bigint'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
  /** An object relationship */
  weightUnit?: Maybe<Product_Units>;
  weightUnitId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "picked_items" */
export type Picked_ItemsLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Picked_Item_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "picked_items" */
export type Picked_ItemsLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Picked_Item_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "picked_items" */
export type Picked_ItemsPickedItemContainersArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_Containers_Order_By>>;
  where?: InputMaybe<Picked_Item_Containers_Bool_Exp>;
};


/** columns and relationships of "picked_items" */
export type Picked_ItemsPickedItemContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_Containers_Order_By>>;
  where?: InputMaybe<Picked_Item_Containers_Bool_Exp>;
};

/** aggregated selection of "picked_items" */
export type Picked_Items_Aggregate = {
  __typename: 'picked_items_aggregate';
  aggregate?: Maybe<Picked_Items_Aggregate_Fields>;
  nodes: Array<Picked_Items>;
};

export type Picked_Items_Aggregate_Bool_Exp = {
  count?: InputMaybe<Picked_Items_Aggregate_Bool_Exp_Count>;
};

export type Picked_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Picked_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Picked_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "picked_items" */
export type Picked_Items_Aggregate_Fields = {
  __typename: 'picked_items_aggregate_fields';
  avg?: Maybe<Picked_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Picked_Items_Max_Fields>;
  min?: Maybe<Picked_Items_Min_Fields>;
  stddev?: Maybe<Picked_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Picked_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Picked_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Picked_Items_Sum_Fields>;
  var_pop?: Maybe<Picked_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Picked_Items_Var_Samp_Fields>;
  variance?: Maybe<Picked_Items_Variance_Fields>;
};


/** aggregate fields of "picked_items" */
export type Picked_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Picked_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "picked_items" */
export type Picked_Items_Aggregate_Order_By = {
  avg?: InputMaybe<Picked_Items_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Picked_Items_Max_Order_By>;
  min?: InputMaybe<Picked_Items_Min_Order_By>;
  stddev?: InputMaybe<Picked_Items_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Picked_Items_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Picked_Items_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Picked_Items_Sum_Order_By>;
  var_pop?: InputMaybe<Picked_Items_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Picked_Items_Var_Samp_Order_By>;
  variance?: InputMaybe<Picked_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "picked_items" */
export type Picked_Items_Arr_Rel_Insert_Input = {
  data: Array<Picked_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Picked_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Picked_Items_Avg_Fields = {
  __typename: 'picked_items_avg_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  gs1LogisticDocumentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  partialDeliveryId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  sscc?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightUnitId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "picked_items" */
export type Picked_Items_Avg_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  gs1LogisticDocumentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  partialDeliveryId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "picked_items". All fields are combined with a logical 'AND'. */
export type Picked_Items_Bool_Exp = {
  LotNumber?: InputMaybe<Lot_Numbers_Bool_Exp>;
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Picked_Items_Bool_Exp>>;
  _not?: InputMaybe<Picked_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Picked_Items_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  customerOrderItemId?: InputMaybe<Bigint_Comparison_Exp>;
  gs1Batch?: InputMaybe<String_Comparison_Exp>;
  gs1BestBefore?: InputMaybe<Date_Comparison_Exp>;
  gs1LogisticDocument?: InputMaybe<S3_Objects_Bool_Exp>;
  gs1LogisticDocumentId?: InputMaybe<Bigint_Comparison_Exp>;
  gs1ProductionDate?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  journalEntry?: InputMaybe<Journal_Entries_Bool_Exp>;
  journalEntryId?: InputMaybe<Bigint_Comparison_Exp>;
  loggedActions?: InputMaybe<Picked_Item_Logged_Actions_Bool_Exp>;
  loggedActions_aggregate?: InputMaybe<Picked_Item_Logged_Actions_Aggregate_Bool_Exp>;
  lotNo?: InputMaybe<String_Comparison_Exp>;
  lotNumberId?: InputMaybe<Bigint_Comparison_Exp>;
  netWeight?: InputMaybe<Bigint_Comparison_Exp>;
  partialDelivery?: InputMaybe<Partial_Deliveries_Bool_Exp>;
  partialDeliveryId?: InputMaybe<Bigint_Comparison_Exp>;
  pickedItemContainers?: InputMaybe<Picked_Item_Containers_Bool_Exp>;
  pickedItemContainers_aggregate?: InputMaybe<Picked_Item_Containers_Aggregate_Bool_Exp>;
  sourceMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  sourceMonetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  sourceMonetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  sourceStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  sourceStorageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  sourceStorageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  sscc?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<Picked_Item_States_Enum_Comparison_Exp>;
  stockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  stockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  tareWeight?: InputMaybe<Bigint_Comparison_Exp>;
  targetMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  targetMonetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  targetMonetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  targetStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  targetStorageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  targetStorageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
  weightUnit?: InputMaybe<Product_Units_Bool_Exp>;
  weightUnitId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "picked_items" */
export enum Picked_Items_Constraint {
  /** unique or primary key constraint on columns "id" */
  PickedItemsPkey = 'picked_items_pkey',
  /** unique or primary key constraint on columns "sscc" */
  PickedItemsSsccKey = 'picked_items_sscc_key'
}

/** input type for incrementing numeric columns in table "picked_items" */
export type Picked_Items_Inc_Input = {
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  gs1LogisticDocumentId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  netWeight?: InputMaybe<Scalars['bigint']>;
  partialDeliveryId?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  sourceStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sourceStorageUnitId?: InputMaybe<Scalars['bigint']>;
  sscc?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  tareWeight?: InputMaybe<Scalars['bigint']>;
  targetMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  targetMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  targetStorageQuantity?: InputMaybe<Scalars['bigint']>;
  targetStorageUnitId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  weightUnitId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "picked_items" */
export type Picked_Items_Insert_Input = {
  LotNumber?: InputMaybe<Lot_Numbers_Obj_Rel_Insert_Input>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Obj_Rel_Insert_Input>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  gs1Batch?: InputMaybe<Scalars['String']>;
  gs1BestBefore?: InputMaybe<Scalars['date']>;
  gs1LogisticDocument?: InputMaybe<S3_Objects_Obj_Rel_Insert_Input>;
  gs1LogisticDocumentId?: InputMaybe<Scalars['bigint']>;
  gs1ProductionDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntry?: InputMaybe<Journal_Entries_Obj_Rel_Insert_Input>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  lotNo?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  netWeight?: InputMaybe<Scalars['bigint']>;
  partialDelivery?: InputMaybe<Partial_Deliveries_Obj_Rel_Insert_Input>;
  partialDeliveryId?: InputMaybe<Scalars['bigint']>;
  pickedItemContainers?: InputMaybe<Picked_Item_Containers_Arr_Rel_Insert_Input>;
  sourceMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  sourceMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  sourceStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sourceStorageUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  sourceStorageUnitId?: InputMaybe<Scalars['bigint']>;
  sscc?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Picked_Item_States_Enum>;
  stockLocation?: InputMaybe<Stock_Locations_Obj_Rel_Insert_Input>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  tareWeight?: InputMaybe<Scalars['bigint']>;
  targetMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  targetMonetaryUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  targetMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  targetStorageQuantity?: InputMaybe<Scalars['bigint']>;
  targetStorageUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  targetStorageUnitId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  weightUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  weightUnitId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Picked_Items_Max_Fields = {
  __typename: 'picked_items_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  gs1Batch?: Maybe<Scalars['String']>;
  gs1BestBefore?: Maybe<Scalars['date']>;
  gs1LogisticDocumentId?: Maybe<Scalars['bigint']>;
  gs1ProductionDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  lotNo?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  netWeight?: Maybe<Scalars['bigint']>;
  partialDeliveryId?: Maybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sourceMonetaryUnitId?: Maybe<Scalars['bigint']>;
  sourceStorageQuantity?: Maybe<Scalars['bigint']>;
  sourceStorageUnitId?: Maybe<Scalars['bigint']>;
  sscc?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  tareWeight?: Maybe<Scalars['bigint']>;
  targetMonetaryQuantity?: Maybe<Scalars['bigint']>;
  targetMonetaryUnitId?: Maybe<Scalars['bigint']>;
  targetStorageQuantity?: Maybe<Scalars['bigint']>;
  targetStorageUnitId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  weightUnitId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "picked_items" */
export type Picked_Items_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  gs1Batch?: InputMaybe<Order_By>;
  gs1BestBefore?: InputMaybe<Order_By>;
  gs1LogisticDocumentId?: InputMaybe<Order_By>;
  gs1ProductionDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNo?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  partialDeliveryId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Picked_Items_Min_Fields = {
  __typename: 'picked_items_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  gs1Batch?: Maybe<Scalars['String']>;
  gs1BestBefore?: Maybe<Scalars['date']>;
  gs1LogisticDocumentId?: Maybe<Scalars['bigint']>;
  gs1ProductionDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  lotNo?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  netWeight?: Maybe<Scalars['bigint']>;
  partialDeliveryId?: Maybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sourceMonetaryUnitId?: Maybe<Scalars['bigint']>;
  sourceStorageQuantity?: Maybe<Scalars['bigint']>;
  sourceStorageUnitId?: Maybe<Scalars['bigint']>;
  sscc?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  tareWeight?: Maybe<Scalars['bigint']>;
  targetMonetaryQuantity?: Maybe<Scalars['bigint']>;
  targetMonetaryUnitId?: Maybe<Scalars['bigint']>;
  targetStorageQuantity?: Maybe<Scalars['bigint']>;
  targetStorageUnitId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  weightUnitId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "picked_items" */
export type Picked_Items_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  gs1Batch?: InputMaybe<Order_By>;
  gs1BestBefore?: InputMaybe<Order_By>;
  gs1LogisticDocumentId?: InputMaybe<Order_By>;
  gs1ProductionDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNo?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  partialDeliveryId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "picked_items" */
export type Picked_Items_Mutation_Response = {
  __typename: 'picked_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Picked_Items>;
};

/** input type for inserting object relation for remote table "picked_items" */
export type Picked_Items_Obj_Rel_Insert_Input = {
  data: Picked_Items_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Picked_Items_On_Conflict>;
};

/** on_conflict condition type for table "picked_items" */
export type Picked_Items_On_Conflict = {
  constraint: Picked_Items_Constraint;
  update_columns?: Array<Picked_Items_Update_Column>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "picked_items". */
export type Picked_Items_Order_By = {
  LotNumber?: InputMaybe<Lot_Numbers_Order_By>;
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrderItem?: InputMaybe<Customer_Order_Items_Order_By>;
  customerOrderItemId?: InputMaybe<Order_By>;
  gs1Batch?: InputMaybe<Order_By>;
  gs1BestBefore?: InputMaybe<Order_By>;
  gs1LogisticDocument?: InputMaybe<S3_Objects_Order_By>;
  gs1LogisticDocumentId?: InputMaybe<Order_By>;
  gs1ProductionDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntry?: InputMaybe<Journal_Entries_Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  loggedActions_aggregate?: InputMaybe<Picked_Item_Logged_Actions_Aggregate_Order_By>;
  lotNo?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  partialDelivery?: InputMaybe<Partial_Deliveries_Order_By>;
  partialDeliveryId?: InputMaybe<Order_By>;
  pickedItemContainers_aggregate?: InputMaybe<Picked_Item_Containers_Aggregate_Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnit?: InputMaybe<Product_Units_Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnit?: InputMaybe<Product_Units_Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  stockLocation?: InputMaybe<Stock_Locations_Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnit?: InputMaybe<Product_Units_Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnit?: InputMaybe<Product_Units_Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnit?: InputMaybe<Product_Units_Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: picked_items */
export type Picked_Items_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "picked_items" */
export enum Picked_Items_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerOrderItemId = 'customerOrderItemId',
  /** column name */
  Gs1Batch = 'gs1Batch',
  /** column name */
  Gs1BestBefore = 'gs1BestBefore',
  /** column name */
  Gs1LogisticDocumentId = 'gs1LogisticDocumentId',
  /** column name */
  Gs1ProductionDate = 'gs1ProductionDate',
  /** column name */
  Id = 'id',
  /** column name */
  JournalEntryId = 'journalEntryId',
  /** column name */
  LotNo = 'lotNo',
  /** column name */
  LotNumberId = 'lotNumberId',
  /** column name */
  NetWeight = 'netWeight',
  /** column name */
  PartialDeliveryId = 'partialDeliveryId',
  /** column name */
  SourceMonetaryQuantity = 'sourceMonetaryQuantity',
  /** column name */
  SourceMonetaryUnitId = 'sourceMonetaryUnitId',
  /** column name */
  SourceStorageQuantity = 'sourceStorageQuantity',
  /** column name */
  SourceStorageUnitId = 'sourceStorageUnitId',
  /** column name */
  Sscc = 'sscc',
  /** column name */
  State = 'state',
  /** column name */
  StockLocationId = 'stockLocationId',
  /** column name */
  TareWeight = 'tareWeight',
  /** column name */
  TargetMonetaryQuantity = 'targetMonetaryQuantity',
  /** column name */
  TargetMonetaryUnitId = 'targetMonetaryUnitId',
  /** column name */
  TargetStorageQuantity = 'targetStorageQuantity',
  /** column name */
  TargetStorageUnitId = 'targetStorageUnitId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version',
  /** column name */
  WeightUnitId = 'weightUnitId'
}

/** input type for updating data in table "picked_items" */
export type Picked_Items_Set_Input = {
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  gs1Batch?: InputMaybe<Scalars['String']>;
  gs1BestBefore?: InputMaybe<Scalars['date']>;
  gs1LogisticDocumentId?: InputMaybe<Scalars['bigint']>;
  gs1ProductionDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  lotNo?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  netWeight?: InputMaybe<Scalars['bigint']>;
  partialDeliveryId?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  sourceStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sourceStorageUnitId?: InputMaybe<Scalars['bigint']>;
  sscc?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Picked_Item_States_Enum>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  tareWeight?: InputMaybe<Scalars['bigint']>;
  targetMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  targetMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  targetStorageQuantity?: InputMaybe<Scalars['bigint']>;
  targetStorageUnitId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  weightUnitId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Picked_Items_Stddev_Fields = {
  __typename: 'picked_items_stddev_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  gs1LogisticDocumentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  partialDeliveryId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  sscc?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "picked_items" */
export type Picked_Items_Stddev_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  gs1LogisticDocumentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  partialDeliveryId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Picked_Items_Stddev_Pop_Fields = {
  __typename: 'picked_items_stddev_pop_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  gs1LogisticDocumentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  partialDeliveryId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  sscc?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "picked_items" */
export type Picked_Items_Stddev_Pop_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  gs1LogisticDocumentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  partialDeliveryId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Picked_Items_Stddev_Samp_Fields = {
  __typename: 'picked_items_stddev_samp_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  gs1LogisticDocumentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  partialDeliveryId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  sscc?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "picked_items" */
export type Picked_Items_Stddev_Samp_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  gs1LogisticDocumentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  partialDeliveryId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "picked_items" */
export type Picked_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Picked_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Picked_Items_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerOrderItemId?: InputMaybe<Scalars['bigint']>;
  gs1Batch?: InputMaybe<Scalars['String']>;
  gs1BestBefore?: InputMaybe<Scalars['date']>;
  gs1LogisticDocumentId?: InputMaybe<Scalars['bigint']>;
  gs1ProductionDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  lotNo?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  netWeight?: InputMaybe<Scalars['bigint']>;
  partialDeliveryId?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  sourceStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sourceStorageUnitId?: InputMaybe<Scalars['bigint']>;
  sscc?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Picked_Item_States_Enum>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  tareWeight?: InputMaybe<Scalars['bigint']>;
  targetMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  targetMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  targetStorageQuantity?: InputMaybe<Scalars['bigint']>;
  targetStorageUnitId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
  weightUnitId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Picked_Items_Sum_Fields = {
  __typename: 'picked_items_sum_fields';
  customerOrderItemId?: Maybe<Scalars['bigint']>;
  gs1LogisticDocumentId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  netWeight?: Maybe<Scalars['bigint']>;
  partialDeliveryId?: Maybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sourceMonetaryUnitId?: Maybe<Scalars['bigint']>;
  sourceStorageQuantity?: Maybe<Scalars['bigint']>;
  sourceStorageUnitId?: Maybe<Scalars['bigint']>;
  sscc?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  tareWeight?: Maybe<Scalars['bigint']>;
  targetMonetaryQuantity?: Maybe<Scalars['bigint']>;
  targetMonetaryUnitId?: Maybe<Scalars['bigint']>;
  targetStorageQuantity?: Maybe<Scalars['bigint']>;
  targetStorageUnitId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
  weightUnitId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "picked_items" */
export type Picked_Items_Sum_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  gs1LogisticDocumentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  partialDeliveryId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** update columns of table "picked_items" */
export enum Picked_Items_Update_Column {
  /** column name */
  CustomerOrderItemId = 'customerOrderItemId',
  /** column name */
  Gs1Batch = 'gs1Batch',
  /** column name */
  Gs1BestBefore = 'gs1BestBefore',
  /** column name */
  Gs1LogisticDocumentId = 'gs1LogisticDocumentId',
  /** column name */
  Gs1ProductionDate = 'gs1ProductionDate',
  /** column name */
  Id = 'id',
  /** column name */
  JournalEntryId = 'journalEntryId',
  /** column name */
  LotNo = 'lotNo',
  /** column name */
  LotNumberId = 'lotNumberId',
  /** column name */
  NetWeight = 'netWeight',
  /** column name */
  PartialDeliveryId = 'partialDeliveryId',
  /** column name */
  SourceMonetaryQuantity = 'sourceMonetaryQuantity',
  /** column name */
  SourceMonetaryUnitId = 'sourceMonetaryUnitId',
  /** column name */
  SourceStorageQuantity = 'sourceStorageQuantity',
  /** column name */
  SourceStorageUnitId = 'sourceStorageUnitId',
  /** column name */
  Sscc = 'sscc',
  /** column name */
  State = 'state',
  /** column name */
  StockLocationId = 'stockLocationId',
  /** column name */
  TareWeight = 'tareWeight',
  /** column name */
  TargetMonetaryQuantity = 'targetMonetaryQuantity',
  /** column name */
  TargetMonetaryUnitId = 'targetMonetaryUnitId',
  /** column name */
  TargetStorageQuantity = 'targetStorageQuantity',
  /** column name */
  TargetStorageUnitId = 'targetStorageUnitId',
  /** column name */
  Version = 'version',
  /** column name */
  WeightUnitId = 'weightUnitId'
}

export type Picked_Items_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Picked_Items_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Picked_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Picked_Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Picked_Items_Var_Pop_Fields = {
  __typename: 'picked_items_var_pop_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  gs1LogisticDocumentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  partialDeliveryId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  sscc?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightUnitId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "picked_items" */
export type Picked_Items_Var_Pop_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  gs1LogisticDocumentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  partialDeliveryId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Picked_Items_Var_Samp_Fields = {
  __typename: 'picked_items_var_samp_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  gs1LogisticDocumentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  partialDeliveryId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  sscc?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightUnitId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "picked_items" */
export type Picked_Items_Var_Samp_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  gs1LogisticDocumentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  partialDeliveryId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Picked_Items_Variance_Fields = {
  __typename: 'picked_items_variance_fields';
  customerOrderItemId?: Maybe<Scalars['Float']>;
  gs1LogisticDocumentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  partialDeliveryId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  sscc?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightUnitId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "picked_items" */
export type Picked_Items_Variance_Order_By = {
  customerOrderItemId?: InputMaybe<Order_By>;
  gs1LogisticDocumentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  partialDeliveryId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  sscc?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** columns and relationships of "picking_categories" */
export type Picking_Categories = {
  __typename: 'picking_categories';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  domainId: Scalars['String'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
  /** An array relationship */
  workstationPickingCategories: Array<Workstation_Picking_Categories>;
  /** An aggregate relationship */
  workstationPickingCategories_aggregate: Workstation_Picking_Categories_Aggregate;
};


/** columns and relationships of "picking_categories" */
export type Picking_CategoriesProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


/** columns and relationships of "picking_categories" */
export type Picking_CategoriesProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


/** columns and relationships of "picking_categories" */
export type Picking_CategoriesWorkstationPickingCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Picking_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Picking_Categories_Order_By>>;
  where?: InputMaybe<Workstation_Picking_Categories_Bool_Exp>;
};


/** columns and relationships of "picking_categories" */
export type Picking_CategoriesWorkstationPickingCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Picking_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Picking_Categories_Order_By>>;
  where?: InputMaybe<Workstation_Picking_Categories_Bool_Exp>;
};

/** aggregated selection of "picking_categories" */
export type Picking_Categories_Aggregate = {
  __typename: 'picking_categories_aggregate';
  aggregate?: Maybe<Picking_Categories_Aggregate_Fields>;
  nodes: Array<Picking_Categories>;
};

export type Picking_Categories_Aggregate_Bool_Exp = {
  count?: InputMaybe<Picking_Categories_Aggregate_Bool_Exp_Count>;
};

export type Picking_Categories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Picking_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Picking_Categories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "picking_categories" */
export type Picking_Categories_Aggregate_Fields = {
  __typename: 'picking_categories_aggregate_fields';
  avg?: Maybe<Picking_Categories_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Picking_Categories_Max_Fields>;
  min?: Maybe<Picking_Categories_Min_Fields>;
  stddev?: Maybe<Picking_Categories_Stddev_Fields>;
  stddev_pop?: Maybe<Picking_Categories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Picking_Categories_Stddev_Samp_Fields>;
  sum?: Maybe<Picking_Categories_Sum_Fields>;
  var_pop?: Maybe<Picking_Categories_Var_Pop_Fields>;
  var_samp?: Maybe<Picking_Categories_Var_Samp_Fields>;
  variance?: Maybe<Picking_Categories_Variance_Fields>;
};


/** aggregate fields of "picking_categories" */
export type Picking_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Picking_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "picking_categories" */
export type Picking_Categories_Aggregate_Order_By = {
  avg?: InputMaybe<Picking_Categories_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Picking_Categories_Max_Order_By>;
  min?: InputMaybe<Picking_Categories_Min_Order_By>;
  stddev?: InputMaybe<Picking_Categories_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Picking_Categories_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Picking_Categories_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Picking_Categories_Sum_Order_By>;
  var_pop?: InputMaybe<Picking_Categories_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Picking_Categories_Var_Samp_Order_By>;
  variance?: InputMaybe<Picking_Categories_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Picking_Categories_Avg_Fields = {
  __typename: 'picking_categories_avg_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "picking_categories" */
export type Picking_Categories_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "picking_categories". All fields are combined with a logical 'AND'. */
export type Picking_Categories_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Picking_Categories_Bool_Exp>>;
  _not?: InputMaybe<Picking_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Picking_Categories_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  domainId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  products?: InputMaybe<Products_Bool_Exp>;
  products_aggregate?: InputMaybe<Products_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
  workstationPickingCategories?: InputMaybe<Workstation_Picking_Categories_Bool_Exp>;
  workstationPickingCategories_aggregate?: InputMaybe<Workstation_Picking_Categories_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "picking_categories" */
export enum Picking_Categories_Constraint {
  /** unique or primary key constraint on columns "id" */
  PickingCategoriesPkey = 'picking_categories_pkey',
  /** unique or primary key constraint on columns "tenant", "domain_id" */
  PickingCategoriesTenantDomainId = 'picking_categories_tenant_domain_id',
  /** unique or primary key constraint on columns "name", "tenant" */
  PickingCategoriesTenantName = 'picking_categories_tenant_name'
}

/** input type for incrementing numeric columns in table "picking_categories" */
export type Picking_Categories_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "picking_categories" */
export type Picking_Categories_Insert_Input = {
  domainId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Products_Arr_Rel_Insert_Input>;
  version?: InputMaybe<Scalars['bigint']>;
  workstationPickingCategories?: InputMaybe<Workstation_Picking_Categories_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Picking_Categories_Max_Fields = {
  __typename: 'picking_categories_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  domainId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "picking_categories" */
export type Picking_Categories_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Picking_Categories_Min_Fields = {
  __typename: 'picking_categories_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  domainId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "picking_categories" */
export type Picking_Categories_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "picking_categories" */
export type Picking_Categories_Mutation_Response = {
  __typename: 'picking_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Picking_Categories>;
};

/** input type for inserting object relation for remote table "picking_categories" */
export type Picking_Categories_Obj_Rel_Insert_Input = {
  data: Picking_Categories_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Picking_Categories_On_Conflict>;
};

/** on_conflict condition type for table "picking_categories" */
export type Picking_Categories_On_Conflict = {
  constraint: Picking_Categories_Constraint;
  update_columns?: Array<Picking_Categories_Update_Column>;
  where?: InputMaybe<Picking_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "picking_categories". */
export type Picking_Categories_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  products_aggregate?: InputMaybe<Products_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  workstationPickingCategories_aggregate?: InputMaybe<Workstation_Picking_Categories_Aggregate_Order_By>;
};

/** primary key columns input for table: picking_categories */
export type Picking_Categories_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "picking_categories" */
export enum Picking_Categories_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DomainId = 'domainId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "picking_categories" */
export type Picking_Categories_Set_Input = {
  domainId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Picking_Categories_Stddev_Fields = {
  __typename: 'picking_categories_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "picking_categories" */
export type Picking_Categories_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Picking_Categories_Stddev_Pop_Fields = {
  __typename: 'picking_categories_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "picking_categories" */
export type Picking_Categories_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Picking_Categories_Stddev_Samp_Fields = {
  __typename: 'picking_categories_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "picking_categories" */
export type Picking_Categories_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "picking_categories" */
export type Picking_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Picking_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Picking_Categories_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  domainId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Picking_Categories_Sum_Fields = {
  __typename: 'picking_categories_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "picking_categories" */
export type Picking_Categories_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "picking_categories" */
export enum Picking_Categories_Update_Column {
  /** column name */
  DomainId = 'domainId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Version = 'version'
}

export type Picking_Categories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Picking_Categories_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Picking_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Picking_Categories_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Picking_Categories_Var_Pop_Fields = {
  __typename: 'picking_categories_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "picking_categories" */
export type Picking_Categories_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Picking_Categories_Var_Samp_Fields = {
  __typename: 'picking_categories_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "picking_categories" */
export type Picking_Categories_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Picking_Categories_Variance_Fields = {
  __typename: 'picking_categories_variance_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "picking_categories" */
export type Picking_Categories_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "picking_deviation_reasons" */
export type Picking_Deviation_Reasons = {
  __typename: 'picking_deviation_reasons';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  customerOrderItems: Array<Customer_Order_Items>;
  /** An aggregate relationship */
  customerOrderItems_aggregate: Customer_Order_Items_Aggregate;
  i18n: Scalars['jsonb'];
  id: Scalars['bigint'];
  ordinalPosition?: Maybe<Scalars['Int']>;
  reasonCode: Scalars['String'];
  /** An array relationship */
  supplierOrderItems: Array<Supplier_Order_Items>;
  /** An aggregate relationship */
  supplierOrderItems_aggregate: Supplier_Order_Items_Aggregate;
  tenant: Scalars['String'];
};


/** columns and relationships of "picking_deviation_reasons" */
export type Picking_Deviation_ReasonsCustomerOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** columns and relationships of "picking_deviation_reasons" */
export type Picking_Deviation_ReasonsCustomerOrderItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** columns and relationships of "picking_deviation_reasons" */
export type Picking_Deviation_ReasonsI18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "picking_deviation_reasons" */
export type Picking_Deviation_ReasonsSupplierOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** columns and relationships of "picking_deviation_reasons" */
export type Picking_Deviation_ReasonsSupplierOrderItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};

/** aggregated selection of "picking_deviation_reasons" */
export type Picking_Deviation_Reasons_Aggregate = {
  __typename: 'picking_deviation_reasons_aggregate';
  aggregate?: Maybe<Picking_Deviation_Reasons_Aggregate_Fields>;
  nodes: Array<Picking_Deviation_Reasons>;
};

export type Picking_Deviation_Reasons_Aggregate_Bool_Exp = {
  count?: InputMaybe<Picking_Deviation_Reasons_Aggregate_Bool_Exp_Count>;
};

export type Picking_Deviation_Reasons_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Picking_Deviation_Reasons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Picking_Deviation_Reasons_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "picking_deviation_reasons" */
export type Picking_Deviation_Reasons_Aggregate_Fields = {
  __typename: 'picking_deviation_reasons_aggregate_fields';
  avg?: Maybe<Picking_Deviation_Reasons_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Picking_Deviation_Reasons_Max_Fields>;
  min?: Maybe<Picking_Deviation_Reasons_Min_Fields>;
  stddev?: Maybe<Picking_Deviation_Reasons_Stddev_Fields>;
  stddev_pop?: Maybe<Picking_Deviation_Reasons_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Picking_Deviation_Reasons_Stddev_Samp_Fields>;
  sum?: Maybe<Picking_Deviation_Reasons_Sum_Fields>;
  var_pop?: Maybe<Picking_Deviation_Reasons_Var_Pop_Fields>;
  var_samp?: Maybe<Picking_Deviation_Reasons_Var_Samp_Fields>;
  variance?: Maybe<Picking_Deviation_Reasons_Variance_Fields>;
};


/** aggregate fields of "picking_deviation_reasons" */
export type Picking_Deviation_Reasons_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Picking_Deviation_Reasons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "picking_deviation_reasons" */
export type Picking_Deviation_Reasons_Aggregate_Order_By = {
  avg?: InputMaybe<Picking_Deviation_Reasons_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Picking_Deviation_Reasons_Max_Order_By>;
  min?: InputMaybe<Picking_Deviation_Reasons_Min_Order_By>;
  stddev?: InputMaybe<Picking_Deviation_Reasons_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Picking_Deviation_Reasons_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Picking_Deviation_Reasons_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Picking_Deviation_Reasons_Sum_Order_By>;
  var_pop?: InputMaybe<Picking_Deviation_Reasons_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Picking_Deviation_Reasons_Var_Samp_Order_By>;
  variance?: InputMaybe<Picking_Deviation_Reasons_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Picking_Deviation_Reasons_Avg_Fields = {
  __typename: 'picking_deviation_reasons_avg_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "picking_deviation_reasons" */
export type Picking_Deviation_Reasons_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "picking_deviation_reasons". All fields are combined with a logical 'AND'. */
export type Picking_Deviation_Reasons_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Picking_Deviation_Reasons_Bool_Exp>>;
  _not?: InputMaybe<Picking_Deviation_Reasons_Bool_Exp>;
  _or?: InputMaybe<Array<Picking_Deviation_Reasons_Bool_Exp>>;
  customerOrderItems?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  customerOrderItems_aggregate?: InputMaybe<Customer_Order_Items_Aggregate_Bool_Exp>;
  i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  reasonCode?: InputMaybe<String_Comparison_Exp>;
  supplierOrderItems?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
  supplierOrderItems_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Picking_Deviation_Reasons_Max_Fields = {
  __typename: 'picking_deviation_reasons_max_fields';
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  reasonCode?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "picking_deviation_reasons" */
export type Picking_Deviation_Reasons_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  reasonCode?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Picking_Deviation_Reasons_Min_Fields = {
  __typename: 'picking_deviation_reasons_min_fields';
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  reasonCode?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "picking_deviation_reasons" */
export type Picking_Deviation_Reasons_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  reasonCode?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "picking_deviation_reasons". */
export type Picking_Deviation_Reasons_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  customerOrderItems_aggregate?: InputMaybe<Customer_Order_Items_Aggregate_Order_By>;
  i18n?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  reasonCode?: InputMaybe<Order_By>;
  supplierOrderItems_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** select columns of table "picking_deviation_reasons" */
export enum Picking_Deviation_Reasons_Select_Column {
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  ReasonCode = 'reasonCode',
  /** column name */
  Tenant = 'tenant'
}

/** aggregate stddev on columns */
export type Picking_Deviation_Reasons_Stddev_Fields = {
  __typename: 'picking_deviation_reasons_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "picking_deviation_reasons" */
export type Picking_Deviation_Reasons_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Picking_Deviation_Reasons_Stddev_Pop_Fields = {
  __typename: 'picking_deviation_reasons_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "picking_deviation_reasons" */
export type Picking_Deviation_Reasons_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Picking_Deviation_Reasons_Stddev_Samp_Fields = {
  __typename: 'picking_deviation_reasons_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "picking_deviation_reasons" */
export type Picking_Deviation_Reasons_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "picking_deviation_reasons" */
export type Picking_Deviation_Reasons_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Picking_Deviation_Reasons_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Picking_Deviation_Reasons_Stream_Cursor_Value_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  reasonCode?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Picking_Deviation_Reasons_Sum_Fields = {
  __typename: 'picking_deviation_reasons_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "picking_deviation_reasons" */
export type Picking_Deviation_Reasons_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Picking_Deviation_Reasons_Var_Pop_Fields = {
  __typename: 'picking_deviation_reasons_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "picking_deviation_reasons" */
export type Picking_Deviation_Reasons_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Picking_Deviation_Reasons_Var_Samp_Fields = {
  __typename: 'picking_deviation_reasons_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "picking_deviation_reasons" */
export type Picking_Deviation_Reasons_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Picking_Deviation_Reasons_Variance_Fields = {
  __typename: 'picking_deviation_reasons_variance_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "picking_deviation_reasons" */
export type Picking_Deviation_Reasons_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

export enum Picking_Deviation_Types_Enum {
  LessThanOrdered = 'LESS_THAN_ORDERED',
  MoreThanOrdered = 'MORE_THAN_ORDERED',
  NoDeviation = 'NO_DEVIATION'
}

/** Boolean expression to compare columns of type "picking_deviation_types_enum". All fields are combined with logical 'AND'. */
export type Picking_Deviation_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Picking_Deviation_Types_Enum>;
  _in?: InputMaybe<Array<Picking_Deviation_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Picking_Deviation_Types_Enum>;
  _nin?: InputMaybe<Array<Picking_Deviation_Types_Enum>>;
};

/** columns and relationships of "postings" */
export type Postings = {
  __typename: 'postings';
  /** An object relationship */
  LotNumber?: Maybe<Lot_Numbers>;
  /** An object relationship */
  Tenant: Tenants;
  /** The absolute value of the storage_quantity column. Used for sorting. */
  absoluteStorageQuantity?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  account: Accounts;
  accountId: Scalars['bigint'];
  data?: Maybe<Scalars['jsonb']>;
  dueDate?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  /** An object relationship */
  journalEntry: Journal_Entries;
  journalEntryId: Scalars['bigint'];
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  monetaryQuantity: Scalars['bigint'];
  /** An object relationship */
  monetaryUnit: Product_Units;
  monetaryUnitId: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  storageQuantity: Scalars['bigint'];
  /** An object relationship */
  storageUnit: Product_Units;
  storageUnitId: Scalars['bigint'];
  tenant: Scalars['String'];
  validTime: Scalars['timestamptz'];
};


/** columns and relationships of "postings" */
export type PostingsDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "postings" */
export type Postings_Aggregate = {
  __typename: 'postings_aggregate';
  aggregate?: Maybe<Postings_Aggregate_Fields>;
  nodes: Array<Postings>;
};

export type Postings_Aggregate_Bool_Exp = {
  count?: InputMaybe<Postings_Aggregate_Bool_Exp_Count>;
};

export type Postings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Postings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Postings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "postings" */
export type Postings_Aggregate_Fields = {
  __typename: 'postings_aggregate_fields';
  avg?: Maybe<Postings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Postings_Max_Fields>;
  min?: Maybe<Postings_Min_Fields>;
  stddev?: Maybe<Postings_Stddev_Fields>;
  stddev_pop?: Maybe<Postings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Postings_Stddev_Samp_Fields>;
  sum?: Maybe<Postings_Sum_Fields>;
  var_pop?: Maybe<Postings_Var_Pop_Fields>;
  var_samp?: Maybe<Postings_Var_Samp_Fields>;
  variance?: Maybe<Postings_Variance_Fields>;
};


/** aggregate fields of "postings" */
export type Postings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Postings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "postings" */
export type Postings_Aggregate_Order_By = {
  avg?: InputMaybe<Postings_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Postings_Max_Order_By>;
  min?: InputMaybe<Postings_Min_Order_By>;
  stddev?: InputMaybe<Postings_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Postings_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Postings_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Postings_Sum_Order_By>;
  var_pop?: InputMaybe<Postings_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Postings_Var_Samp_Order_By>;
  variance?: InputMaybe<Postings_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Postings_Avg_Fields = {
  __typename: 'postings_avg_fields';
  accountId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "postings" */
export type Postings_Avg_Order_By = {
  accountId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "postings". All fields are combined with a logical 'AND'. */
export type Postings_Bool_Exp = {
  LotNumber?: InputMaybe<Lot_Numbers_Bool_Exp>;
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Postings_Bool_Exp>>;
  _not?: InputMaybe<Postings_Bool_Exp>;
  _or?: InputMaybe<Array<Postings_Bool_Exp>>;
  absoluteStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accountId?: InputMaybe<Bigint_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  dueDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  journalEntry?: InputMaybe<Journal_Entries_Bool_Exp>;
  journalEntryId?: InputMaybe<Bigint_Comparison_Exp>;
  lotNumber?: InputMaybe<String_Comparison_Exp>;
  lotNumberId?: InputMaybe<Bigint_Comparison_Exp>;
  monetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  monetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  monetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  storageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  storageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  storageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  validTime?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Postings_Max_Fields = {
  __typename: 'postings_max_fields';
  accountId?: Maybe<Scalars['bigint']>;
  dueDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  storageQuantity?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  validTime?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "postings" */
export type Postings_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  dueDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  validTime?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Postings_Min_Fields = {
  __typename: 'postings_min_fields';
  accountId?: Maybe<Scalars['bigint']>;
  dueDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  storageQuantity?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  validTime?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "postings" */
export type Postings_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  dueDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  validTime?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "postings". */
export type Postings_Order_By = {
  LotNumber?: InputMaybe<Lot_Numbers_Order_By>;
  Tenant?: InputMaybe<Tenants_Order_By>;
  absoluteStorageQuantity?: InputMaybe<Order_By>;
  account?: InputMaybe<Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  dueDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntry?: InputMaybe<Journal_Entries_Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnit?: InputMaybe<Product_Units_Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnit?: InputMaybe<Product_Units_Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  validTime?: InputMaybe<Order_By>;
};

/** select columns of table "postings" */
export enum Postings_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Data = 'data',
  /** column name */
  DueDate = 'dueDate',
  /** column name */
  Id = 'id',
  /** column name */
  JournalEntryId = 'journalEntryId',
  /** column name */
  LotNumber = 'lotNumber',
  /** column name */
  LotNumberId = 'lotNumberId',
  /** column name */
  MonetaryQuantity = 'monetaryQuantity',
  /** column name */
  MonetaryUnitId = 'monetaryUnitId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  StorageQuantity = 'storageQuantity',
  /** column name */
  StorageUnitId = 'storageUnitId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  ValidTime = 'validTime'
}

/** aggregate stddev on columns */
export type Postings_Stddev_Fields = {
  __typename: 'postings_stddev_fields';
  accountId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "postings" */
export type Postings_Stddev_Order_By = {
  accountId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Postings_Stddev_Pop_Fields = {
  __typename: 'postings_stddev_pop_fields';
  accountId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "postings" */
export type Postings_Stddev_Pop_Order_By = {
  accountId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Postings_Stddev_Samp_Fields = {
  __typename: 'postings_stddev_samp_fields';
  accountId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "postings" */
export type Postings_Stddev_Samp_Order_By = {
  accountId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "postings" */
export type Postings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Postings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Postings_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['bigint']>;
  data?: InputMaybe<Scalars['jsonb']>;
  dueDate?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  monetaryQuantity?: InputMaybe<Scalars['bigint']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  storageQuantity?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  validTime?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Postings_Sum_Fields = {
  __typename: 'postings_sum_fields';
  accountId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  storageQuantity?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "postings" */
export type Postings_Sum_Order_By = {
  accountId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Postings_Var_Pop_Fields = {
  __typename: 'postings_var_pop_fields';
  accountId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "postings" */
export type Postings_Var_Pop_Order_By = {
  accountId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Postings_Var_Samp_Fields = {
  __typename: 'postings_var_samp_fields';
  accountId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "postings" */
export type Postings_Var_Samp_Order_By = {
  accountId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Postings_Variance_Fields = {
  __typename: 'postings_variance_fields';
  accountId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "postings" */
export type Postings_Variance_Order_By = {
  accountId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** columns and relationships of "price_lists" */
export type Price_Lists = {
  __typename: 'price_lists';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customerGroup: Customer_Groups;
  customerGroupId: Scalars['bigint'];
  id: Scalars['bigint'];
  /** Returns the next price lists compared to this record regardless of state in ascending order. */
  nextPriceLists?: Maybe<Array<Price_Lists>>;
  notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  pdfDocument?: Maybe<S3_Objects>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  pdfRequested: Scalars['Boolean'];
  /** Returns the previous price lists compared to this record regardless of state in descending order. */
  previousPriceLists?: Maybe<Array<Price_Lists>>;
  /** Returns the daily price list for the given customer group based price list. */
  sortedDailyPriceLists?: Maybe<Array<Daily_Price_Lists>>;
  state: Temporal_Pricing_States_Enum;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  valid: Scalars['daterange'];
  version: Scalars['bigint'];
};


/** columns and relationships of "price_lists" */
export type Price_ListsNextPriceListsArgs = {
  distinct_on?: InputMaybe<Array<Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Lists_Order_By>>;
  where?: InputMaybe<Price_Lists_Bool_Exp>;
};


/** columns and relationships of "price_lists" */
export type Price_ListsPreviousPriceListsArgs = {
  distinct_on?: InputMaybe<Array<Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Lists_Order_By>>;
  where?: InputMaybe<Price_Lists_Bool_Exp>;
};


/** columns and relationships of "price_lists" */
export type Price_ListsSortedDailyPriceListsArgs = {
  args: SortedDailyPriceLists_Price_Lists_Args;
  distinct_on?: InputMaybe<Array<Daily_Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Price_Lists_Order_By>>;
  where?: InputMaybe<Daily_Price_Lists_Bool_Exp>;
};

/** aggregated selection of "price_lists" */
export type Price_Lists_Aggregate = {
  __typename: 'price_lists_aggregate';
  aggregate?: Maybe<Price_Lists_Aggregate_Fields>;
  nodes: Array<Price_Lists>;
};

export type Price_Lists_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Price_Lists_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Price_Lists_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Price_Lists_Aggregate_Bool_Exp_Count>;
};

export type Price_Lists_Aggregate_Bool_Exp_Bool_And = {
  arguments: Price_Lists_Select_Column_Price_Lists_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Price_Lists_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Price_Lists_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Price_Lists_Select_Column_Price_Lists_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Price_Lists_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Price_Lists_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Price_Lists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Price_Lists_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "price_lists" */
export type Price_Lists_Aggregate_Fields = {
  __typename: 'price_lists_aggregate_fields';
  avg?: Maybe<Price_Lists_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Price_Lists_Max_Fields>;
  min?: Maybe<Price_Lists_Min_Fields>;
  stddev?: Maybe<Price_Lists_Stddev_Fields>;
  stddev_pop?: Maybe<Price_Lists_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Price_Lists_Stddev_Samp_Fields>;
  sum?: Maybe<Price_Lists_Sum_Fields>;
  var_pop?: Maybe<Price_Lists_Var_Pop_Fields>;
  var_samp?: Maybe<Price_Lists_Var_Samp_Fields>;
  variance?: Maybe<Price_Lists_Variance_Fields>;
};


/** aggregate fields of "price_lists" */
export type Price_Lists_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Price_Lists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "price_lists" */
export type Price_Lists_Aggregate_Order_By = {
  avg?: InputMaybe<Price_Lists_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Price_Lists_Max_Order_By>;
  min?: InputMaybe<Price_Lists_Min_Order_By>;
  stddev?: InputMaybe<Price_Lists_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Price_Lists_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Price_Lists_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Price_Lists_Sum_Order_By>;
  var_pop?: InputMaybe<Price_Lists_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Price_Lists_Var_Samp_Order_By>;
  variance?: InputMaybe<Price_Lists_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "price_lists" */
export type Price_Lists_Arr_Rel_Insert_Input = {
  data: Array<Price_Lists_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Price_Lists_On_Conflict>;
};

/** aggregate avg on columns */
export type Price_Lists_Avg_Fields = {
  __typename: 'price_lists_avg_fields';
  customerGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "price_lists" */
export type Price_Lists_Avg_Order_By = {
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "price_lists". All fields are combined with a logical 'AND'. */
export type Price_Lists_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Price_Lists_Bool_Exp>>;
  _not?: InputMaybe<Price_Lists_Bool_Exp>;
  _or?: InputMaybe<Array<Price_Lists_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerGroup?: InputMaybe<Customer_Groups_Bool_Exp>;
  customerGroupId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  nextPriceLists?: InputMaybe<Price_Lists_Bool_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  pdfDocument?: InputMaybe<S3_Objects_Bool_Exp>;
  pdfDocumentId?: InputMaybe<Bigint_Comparison_Exp>;
  pdfRequested?: InputMaybe<Boolean_Comparison_Exp>;
  previousPriceLists?: InputMaybe<Price_Lists_Bool_Exp>;
  state?: InputMaybe<Temporal_Pricing_States_Enum_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid?: InputMaybe<Daterange_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "price_lists" */
export enum Price_Lists_Constraint {
  /** unique or primary key constraint on columns "id" */
  PriceListsPkey = 'price_lists_pkey',
  /** unique or primary key constraint on columns "valid", "state", "customer_group_id", "tenant" */
  PriceListsTenantCustomerGroupIdValidStateIdx = 'price_lists_tenant_customer_group_id_valid_state_idx'
}

/** input type for incrementing numeric columns in table "price_lists" */
export type Price_Lists_Inc_Input = {
  customerGroupId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "price_lists" */
export type Price_Lists_Insert_Input = {
  customerGroup?: InputMaybe<Customer_Groups_Obj_Rel_Insert_Input>;
  customerGroupId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  pdfDocument?: InputMaybe<S3_Objects_Obj_Rel_Insert_Input>;
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  pdfRequested?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Temporal_Pricing_States_Enum>;
  valid?: InputMaybe<Scalars['daterange']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Price_Lists_Max_Fields = {
  __typename: 'price_lists_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerGroupId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "price_lists" */
export type Price_Lists_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Price_Lists_Min_Fields = {
  __typename: 'price_lists_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerGroupId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "price_lists" */
export type Price_Lists_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "price_lists" */
export type Price_Lists_Mutation_Response = {
  __typename: 'price_lists_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Price_Lists>;
};

/** on_conflict condition type for table "price_lists" */
export type Price_Lists_On_Conflict = {
  constraint: Price_Lists_Constraint;
  update_columns?: Array<Price_Lists_Update_Column>;
  where?: InputMaybe<Price_Lists_Bool_Exp>;
};

/** Ordering options when selecting data from "price_lists". */
export type Price_Lists_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerGroup?: InputMaybe<Customer_Groups_Order_By>;
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nextPriceLists_aggregate?: InputMaybe<Price_Lists_Aggregate_Order_By>;
  notes?: InputMaybe<Order_By>;
  pdfDocument?: InputMaybe<S3_Objects_Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  pdfRequested?: InputMaybe<Order_By>;
  previousPriceLists_aggregate?: InputMaybe<Price_Lists_Aggregate_Order_By>;
  state?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  valid?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: price_lists */
export type Price_Lists_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "price_lists" */
export enum Price_Lists_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerGroupId = 'customerGroupId',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PdfDocumentId = 'pdfDocumentId',
  /** column name */
  PdfRequested = 'pdfRequested',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Valid = 'valid',
  /** column name */
  Version = 'version'
}

/** select "price_lists_aggregate_bool_exp_bool_and_arguments_columns" columns of table "price_lists" */
export enum Price_Lists_Select_Column_Price_Lists_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  PdfRequested = 'pdfRequested'
}

/** select "price_lists_aggregate_bool_exp_bool_or_arguments_columns" columns of table "price_lists" */
export enum Price_Lists_Select_Column_Price_Lists_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  PdfRequested = 'pdfRequested'
}

/** input type for updating data in table "price_lists" */
export type Price_Lists_Set_Input = {
  customerGroupId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  pdfRequested?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Temporal_Pricing_States_Enum>;
  valid?: InputMaybe<Scalars['daterange']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Price_Lists_Stddev_Fields = {
  __typename: 'price_lists_stddev_fields';
  customerGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "price_lists" */
export type Price_Lists_Stddev_Order_By = {
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Price_Lists_Stddev_Pop_Fields = {
  __typename: 'price_lists_stddev_pop_fields';
  customerGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "price_lists" */
export type Price_Lists_Stddev_Pop_Order_By = {
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Price_Lists_Stddev_Samp_Fields = {
  __typename: 'price_lists_stddev_samp_fields';
  customerGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "price_lists" */
export type Price_Lists_Stddev_Samp_Order_By = {
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "price_lists" */
export type Price_Lists_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Price_Lists_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Price_Lists_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerGroupId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  pdfDocumentId?: InputMaybe<Scalars['bigint']>;
  pdfRequested?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Temporal_Pricing_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  valid?: InputMaybe<Scalars['daterange']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Price_Lists_Sum_Fields = {
  __typename: 'price_lists_sum_fields';
  customerGroupId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  pdfDocumentId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "price_lists" */
export type Price_Lists_Sum_Order_By = {
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "price_lists" */
export enum Price_Lists_Update_Column {
  /** column name */
  CustomerGroupId = 'customerGroupId',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PdfDocumentId = 'pdfDocumentId',
  /** column name */
  PdfRequested = 'pdfRequested',
  /** column name */
  State = 'state',
  /** column name */
  Valid = 'valid',
  /** column name */
  Version = 'version'
}

export type Price_Lists_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Price_Lists_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Price_Lists_Set_Input>;
  /** filter the rows which have to be updated */
  where: Price_Lists_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Price_Lists_Var_Pop_Fields = {
  __typename: 'price_lists_var_pop_fields';
  customerGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "price_lists" */
export type Price_Lists_Var_Pop_Order_By = {
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Price_Lists_Var_Samp_Fields = {
  __typename: 'price_lists_var_samp_fields';
  customerGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "price_lists" */
export type Price_Lists_Var_Samp_Order_By = {
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Price_Lists_Variance_Fields = {
  __typename: 'price_lists_variance_fields';
  customerGroupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pdfDocumentId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "price_lists" */
export type Price_Lists_Variance_Order_By = {
  customerGroupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pdfDocumentId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

export type Print_Address_Label_For_Picking_Args = {
  _co_id?: InputMaybe<Scalars['bigint']>;
  _count?: InputMaybe<Scalars['bigint']>;
  _label_printer_id?: InputMaybe<Scalars['bigint']>;
};

export type Print_Picking_Item_Label_For_Picked_Item_Args = {
  _label_printer_id?: InputMaybe<Scalars['bigint']>;
  _picked_item_id?: InputMaybe<Scalars['bigint']>;
};

export type Print_Picking_Item_Labels_For_Coi_Args = {
  _coi_id?: InputMaybe<Scalars['bigint']>;
  _label_printer_id?: InputMaybe<Scalars['bigint']>;
};

export type Print_Picking_Summary_Label_For_Coi_Args = {
  _coi_id?: InputMaybe<Scalars['bigint']>;
  _label_printer_id?: InputMaybe<Scalars['bigint']>;
};

export type Print_Stock_Container_Labels_Args = {
  _dismantling_date?: InputMaybe<Scalars['date']>;
  _label_printer_id?: InputMaybe<Scalars['bigint']>;
  _lot_number?: InputMaybe<Scalars['String']>;
  _netto_quantity?: InputMaybe<Scalars['String']>;
  _print_count?: InputMaybe<Scalars['bigint']>;
  _product_id?: InputMaybe<Scalars['bigint']>;
  _qr_code?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "product_allowed_containers" */
export type Product_Allowed_Containers = {
  __typename: 'product_allowed_containers';
  /** An object relationship */
  Tenant: Tenants;
  /** An object relationship */
  containerProduct: Products;
  containerProductId: Scalars['bigint'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  tenant: Scalars['String'];
};

/** aggregated selection of "product_allowed_containers" */
export type Product_Allowed_Containers_Aggregate = {
  __typename: 'product_allowed_containers_aggregate';
  aggregate?: Maybe<Product_Allowed_Containers_Aggregate_Fields>;
  nodes: Array<Product_Allowed_Containers>;
};

export type Product_Allowed_Containers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Allowed_Containers_Aggregate_Bool_Exp_Count>;
};

export type Product_Allowed_Containers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Allowed_Containers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Product_Allowed_Containers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_allowed_containers" */
export type Product_Allowed_Containers_Aggregate_Fields = {
  __typename: 'product_allowed_containers_aggregate_fields';
  avg?: Maybe<Product_Allowed_Containers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Allowed_Containers_Max_Fields>;
  min?: Maybe<Product_Allowed_Containers_Min_Fields>;
  stddev?: Maybe<Product_Allowed_Containers_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Allowed_Containers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Allowed_Containers_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Allowed_Containers_Sum_Fields>;
  var_pop?: Maybe<Product_Allowed_Containers_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Allowed_Containers_Var_Samp_Fields>;
  variance?: Maybe<Product_Allowed_Containers_Variance_Fields>;
};


/** aggregate fields of "product_allowed_containers" */
export type Product_Allowed_Containers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Allowed_Containers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_allowed_containers" */
export type Product_Allowed_Containers_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Allowed_Containers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Allowed_Containers_Max_Order_By>;
  min?: InputMaybe<Product_Allowed_Containers_Min_Order_By>;
  stddev?: InputMaybe<Product_Allowed_Containers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Allowed_Containers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Allowed_Containers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Allowed_Containers_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Allowed_Containers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Allowed_Containers_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Allowed_Containers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_allowed_containers" */
export type Product_Allowed_Containers_Arr_Rel_Insert_Input = {
  data: Array<Product_Allowed_Containers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Allowed_Containers_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Allowed_Containers_Avg_Fields = {
  __typename: 'product_allowed_containers_avg_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_allowed_containers" */
export type Product_Allowed_Containers_Avg_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_allowed_containers". All fields are combined with a logical 'AND'. */
export type Product_Allowed_Containers_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Product_Allowed_Containers_Bool_Exp>>;
  _not?: InputMaybe<Product_Allowed_Containers_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Allowed_Containers_Bool_Exp>>;
  containerProduct?: InputMaybe<Products_Bool_Exp>;
  containerProductId?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_allowed_containers" */
export enum Product_Allowed_Containers_Constraint {
  /** unique or primary key constraint on columns "container_product_id", "product_id" */
  ProductAllowedContainersPkey = 'product_allowed_containers_pkey'
}

/** input type for incrementing numeric columns in table "product_allowed_containers" */
export type Product_Allowed_Containers_Inc_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_allowed_containers" */
export type Product_Allowed_Containers_Insert_Input = {
  containerProduct?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  containerProductId?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Product_Allowed_Containers_Max_Fields = {
  __typename: 'product_allowed_containers_max_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  productId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "product_allowed_containers" */
export type Product_Allowed_Containers_Max_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Allowed_Containers_Min_Fields = {
  __typename: 'product_allowed_containers_min_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  productId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "product_allowed_containers" */
export type Product_Allowed_Containers_Min_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_allowed_containers" */
export type Product_Allowed_Containers_Mutation_Response = {
  __typename: 'product_allowed_containers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Allowed_Containers>;
};

/** on_conflict condition type for table "product_allowed_containers" */
export type Product_Allowed_Containers_On_Conflict = {
  constraint: Product_Allowed_Containers_Constraint;
  update_columns?: Array<Product_Allowed_Containers_Update_Column>;
  where?: InputMaybe<Product_Allowed_Containers_Bool_Exp>;
};

/** Ordering options when selecting data from "product_allowed_containers". */
export type Product_Allowed_Containers_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  containerProduct?: InputMaybe<Products_Order_By>;
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_allowed_containers */
export type Product_Allowed_Containers_Pk_Columns_Input = {
  containerProductId: Scalars['bigint'];
  productId: Scalars['bigint'];
};

/** select columns of table "product_allowed_containers" */
export enum Product_Allowed_Containers_Select_Column {
  /** column name */
  ContainerProductId = 'containerProductId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Tenant = 'tenant'
}

/** input type for updating data in table "product_allowed_containers" */
export type Product_Allowed_Containers_Set_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Product_Allowed_Containers_Stddev_Fields = {
  __typename: 'product_allowed_containers_stddev_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_allowed_containers" */
export type Product_Allowed_Containers_Stddev_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Allowed_Containers_Stddev_Pop_Fields = {
  __typename: 'product_allowed_containers_stddev_pop_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_allowed_containers" */
export type Product_Allowed_Containers_Stddev_Pop_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Allowed_Containers_Stddev_Samp_Fields = {
  __typename: 'product_allowed_containers_stddev_samp_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_allowed_containers" */
export type Product_Allowed_Containers_Stddev_Samp_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "product_allowed_containers" */
export type Product_Allowed_Containers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Allowed_Containers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Allowed_Containers_Stream_Cursor_Value_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  productId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Product_Allowed_Containers_Sum_Fields = {
  __typename: 'product_allowed_containers_sum_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product_allowed_containers" */
export type Product_Allowed_Containers_Sum_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** update columns of table "product_allowed_containers" */
export enum Product_Allowed_Containers_Update_Column {
  /** column name */
  ContainerProductId = 'containerProductId',
  /** column name */
  ProductId = 'productId'
}

export type Product_Allowed_Containers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Allowed_Containers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Allowed_Containers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Allowed_Containers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Allowed_Containers_Var_Pop_Fields = {
  __typename: 'product_allowed_containers_var_pop_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_allowed_containers" */
export type Product_Allowed_Containers_Var_Pop_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Allowed_Containers_Var_Samp_Fields = {
  __typename: 'product_allowed_containers_var_samp_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_allowed_containers" */
export type Product_Allowed_Containers_Var_Samp_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Allowed_Containers_Variance_Fields = {
  __typename: 'product_allowed_containers_variance_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_allowed_containers" */
export type Product_Allowed_Containers_Variance_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** columns and relationships of "product_group_members" */
export type Product_Group_Members = {
  __typename: 'product_group_members';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  ordinalPosition?: Maybe<Scalars['Int']>;
  /** An object relationship */
  product: Products;
  /** An object relationship */
  productGroup: Product_Groups;
  productGroupId: Scalars['bigint'];
  productId: Scalars['bigint'];
  tenant: Scalars['String'];
};

/** aggregated selection of "product_group_members" */
export type Product_Group_Members_Aggregate = {
  __typename: 'product_group_members_aggregate';
  aggregate?: Maybe<Product_Group_Members_Aggregate_Fields>;
  nodes: Array<Product_Group_Members>;
};

export type Product_Group_Members_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Group_Members_Aggregate_Bool_Exp_Count>;
};

export type Product_Group_Members_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Group_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Product_Group_Members_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_group_members" */
export type Product_Group_Members_Aggregate_Fields = {
  __typename: 'product_group_members_aggregate_fields';
  avg?: Maybe<Product_Group_Members_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Group_Members_Max_Fields>;
  min?: Maybe<Product_Group_Members_Min_Fields>;
  stddev?: Maybe<Product_Group_Members_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Group_Members_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Group_Members_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Group_Members_Sum_Fields>;
  var_pop?: Maybe<Product_Group_Members_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Group_Members_Var_Samp_Fields>;
  variance?: Maybe<Product_Group_Members_Variance_Fields>;
};


/** aggregate fields of "product_group_members" */
export type Product_Group_Members_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Group_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_group_members" */
export type Product_Group_Members_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Group_Members_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Group_Members_Max_Order_By>;
  min?: InputMaybe<Product_Group_Members_Min_Order_By>;
  stddev?: InputMaybe<Product_Group_Members_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Group_Members_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Group_Members_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Group_Members_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Group_Members_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Group_Members_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Group_Members_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_group_members" */
export type Product_Group_Members_Arr_Rel_Insert_Input = {
  data: Array<Product_Group_Members_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Group_Members_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Group_Members_Avg_Fields = {
  __typename: 'product_group_members_avg_fields';
  ordinalPosition?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_group_members" */
export type Product_Group_Members_Avg_Order_By = {
  ordinalPosition?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_group_members". All fields are combined with a logical 'AND'. */
export type Product_Group_Members_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Product_Group_Members_Bool_Exp>>;
  _not?: InputMaybe<Product_Group_Members_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Group_Members_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productGroup?: InputMaybe<Product_Groups_Bool_Exp>;
  productGroupId?: InputMaybe<Bigint_Comparison_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_group_members" */
export enum Product_Group_Members_Constraint {
  /** unique or primary key constraint on columns "product_group_id", "product_id" */
  ProductGroupMembersPkey = 'product_group_members_pkey'
}

/** input type for incrementing numeric columns in table "product_group_members" */
export type Product_Group_Members_Inc_Input = {
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  productGroupId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_group_members" */
export type Product_Group_Members_Insert_Input = {
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productGroup?: InputMaybe<Product_Groups_Obj_Rel_Insert_Input>;
  productGroupId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Product_Group_Members_Max_Fields = {
  __typename: 'product_group_members_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  productGroupId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "product_group_members" */
export type Product_Group_Members_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Group_Members_Min_Fields = {
  __typename: 'product_group_members_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  productGroupId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "product_group_members" */
export type Product_Group_Members_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_group_members" */
export type Product_Group_Members_Mutation_Response = {
  __typename: 'product_group_members_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Group_Members>;
};

/** on_conflict condition type for table "product_group_members" */
export type Product_Group_Members_On_Conflict = {
  constraint: Product_Group_Members_Constraint;
  update_columns?: Array<Product_Group_Members_Update_Column>;
  where?: InputMaybe<Product_Group_Members_Bool_Exp>;
};

/** Ordering options when selecting data from "product_group_members". */
export type Product_Group_Members_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productGroup?: InputMaybe<Product_Groups_Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_group_members */
export type Product_Group_Members_Pk_Columns_Input = {
  productGroupId: Scalars['bigint'];
  productId: Scalars['bigint'];
};

/** select columns of table "product_group_members" */
export enum Product_Group_Members_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  ProductGroupId = 'productGroupId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Tenant = 'tenant'
}

/** input type for updating data in table "product_group_members" */
export type Product_Group_Members_Set_Input = {
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  productGroupId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Product_Group_Members_Stddev_Fields = {
  __typename: 'product_group_members_stddev_fields';
  ordinalPosition?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_group_members" */
export type Product_Group_Members_Stddev_Order_By = {
  ordinalPosition?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Group_Members_Stddev_Pop_Fields = {
  __typename: 'product_group_members_stddev_pop_fields';
  ordinalPosition?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_group_members" */
export type Product_Group_Members_Stddev_Pop_Order_By = {
  ordinalPosition?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Group_Members_Stddev_Samp_Fields = {
  __typename: 'product_group_members_stddev_samp_fields';
  ordinalPosition?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_group_members" */
export type Product_Group_Members_Stddev_Samp_Order_By = {
  ordinalPosition?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "product_group_members" */
export type Product_Group_Members_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Group_Members_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Group_Members_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  productGroupId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Product_Group_Members_Sum_Fields = {
  __typename: 'product_group_members_sum_fields';
  ordinalPosition?: Maybe<Scalars['Int']>;
  productGroupId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product_group_members" */
export type Product_Group_Members_Sum_Order_By = {
  ordinalPosition?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** update columns of table "product_group_members" */
export enum Product_Group_Members_Update_Column {
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  ProductGroupId = 'productGroupId',
  /** column name */
  ProductId = 'productId'
}

export type Product_Group_Members_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Group_Members_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Group_Members_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Group_Members_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Group_Members_Var_Pop_Fields = {
  __typename: 'product_group_members_var_pop_fields';
  ordinalPosition?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_group_members" */
export type Product_Group_Members_Var_Pop_Order_By = {
  ordinalPosition?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Group_Members_Var_Samp_Fields = {
  __typename: 'product_group_members_var_samp_fields';
  ordinalPosition?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_group_members" */
export type Product_Group_Members_Var_Samp_Order_By = {
  ordinalPosition?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Group_Members_Variance_Fields = {
  __typename: 'product_group_members_variance_fields';
  ordinalPosition?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_group_members" */
export type Product_Group_Members_Variance_Order_By = {
  ordinalPosition?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** columns and relationships of "product_group_state_transitions" */
export type Product_Group_State_Transitions = {
  __typename: 'product_group_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Product_Group_States_Enum;
  toState: Product_Group_States_Enum;
};

/** aggregated selection of "product_group_state_transitions" */
export type Product_Group_State_Transitions_Aggregate = {
  __typename: 'product_group_state_transitions_aggregate';
  aggregate?: Maybe<Product_Group_State_Transitions_Aggregate_Fields>;
  nodes: Array<Product_Group_State_Transitions>;
};

/** aggregate fields of "product_group_state_transitions" */
export type Product_Group_State_Transitions_Aggregate_Fields = {
  __typename: 'product_group_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_Group_State_Transitions_Max_Fields>;
  min?: Maybe<Product_Group_State_Transitions_Min_Fields>;
};


/** aggregate fields of "product_group_state_transitions" */
export type Product_Group_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Group_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "product_group_state_transitions". All fields are combined with a logical 'AND'. */
export type Product_Group_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Group_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Product_Group_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Group_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Product_Group_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Product_Group_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Product_Group_State_Transitions_Max_Fields = {
  __typename: 'product_group_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Product_Group_State_Transitions_Min_Fields = {
  __typename: 'product_group_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "product_group_state_transitions". */
export type Product_Group_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "product_group_state_transitions" */
export enum Product_Group_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "product_group_state_transitions" */
export type Product_Group_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Group_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Group_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Product_Group_States_Enum>;
  toState?: InputMaybe<Product_Group_States_Enum>;
};

/** columns and relationships of "product_group_states" */
export type Product_Group_States = {
  __typename: 'product_group_states';
  /** An array relationship */
  productGroups: Array<Product_Groups>;
  /** An aggregate relationship */
  productGroups_aggregate: Product_Groups_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "product_group_states" */
export type Product_Group_StatesProductGroupsArgs = {
  distinct_on?: InputMaybe<Array<Product_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Groups_Order_By>>;
  where?: InputMaybe<Product_Groups_Bool_Exp>;
};


/** columns and relationships of "product_group_states" */
export type Product_Group_StatesProductGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Groups_Order_By>>;
  where?: InputMaybe<Product_Groups_Bool_Exp>;
};

/** aggregated selection of "product_group_states" */
export type Product_Group_States_Aggregate = {
  __typename: 'product_group_states_aggregate';
  aggregate?: Maybe<Product_Group_States_Aggregate_Fields>;
  nodes: Array<Product_Group_States>;
};

/** aggregate fields of "product_group_states" */
export type Product_Group_States_Aggregate_Fields = {
  __typename: 'product_group_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_Group_States_Max_Fields>;
  min?: Maybe<Product_Group_States_Min_Fields>;
};


/** aggregate fields of "product_group_states" */
export type Product_Group_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Group_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "product_group_states". All fields are combined with a logical 'AND'. */
export type Product_Group_States_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Group_States_Bool_Exp>>;
  _not?: InputMaybe<Product_Group_States_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Group_States_Bool_Exp>>;
  productGroups?: InputMaybe<Product_Groups_Bool_Exp>;
  productGroups_aggregate?: InputMaybe<Product_Groups_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Product_Group_States_Enum {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE'
}

/** Boolean expression to compare columns of type "product_group_states_enum". All fields are combined with logical 'AND'. */
export type Product_Group_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Product_Group_States_Enum>;
  _in?: InputMaybe<Array<Product_Group_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Product_Group_States_Enum>;
  _nin?: InputMaybe<Array<Product_Group_States_Enum>>;
};

/** aggregate max on columns */
export type Product_Group_States_Max_Fields = {
  __typename: 'product_group_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Product_Group_States_Min_Fields = {
  __typename: 'product_group_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "product_group_states". */
export type Product_Group_States_Order_By = {
  productGroups_aggregate?: InputMaybe<Product_Groups_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "product_group_states" */
export enum Product_Group_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "product_group_states" */
export type Product_Group_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Group_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Group_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "product_group_types" */
export type Product_Group_Types = {
  __typename: 'product_group_types';
  /** An array relationship */
  productGroups: Array<Product_Groups>;
  /** An aggregate relationship */
  productGroups_aggregate: Product_Groups_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "product_group_types" */
export type Product_Group_TypesProductGroupsArgs = {
  distinct_on?: InputMaybe<Array<Product_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Groups_Order_By>>;
  where?: InputMaybe<Product_Groups_Bool_Exp>;
};


/** columns and relationships of "product_group_types" */
export type Product_Group_TypesProductGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Groups_Order_By>>;
  where?: InputMaybe<Product_Groups_Bool_Exp>;
};

/** aggregated selection of "product_group_types" */
export type Product_Group_Types_Aggregate = {
  __typename: 'product_group_types_aggregate';
  aggregate?: Maybe<Product_Group_Types_Aggregate_Fields>;
  nodes: Array<Product_Group_Types>;
};

/** aggregate fields of "product_group_types" */
export type Product_Group_Types_Aggregate_Fields = {
  __typename: 'product_group_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_Group_Types_Max_Fields>;
  min?: Maybe<Product_Group_Types_Min_Fields>;
};


/** aggregate fields of "product_group_types" */
export type Product_Group_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Group_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "product_group_types". All fields are combined with a logical 'AND'. */
export type Product_Group_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Group_Types_Bool_Exp>>;
  _not?: InputMaybe<Product_Group_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Group_Types_Bool_Exp>>;
  productGroups?: InputMaybe<Product_Groups_Bool_Exp>;
  productGroups_aggregate?: InputMaybe<Product_Groups_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Product_Group_Types_Enum {
  Animal = 'ANIMAL',
  ContainerPool = 'CONTAINER_POOL',
  ContainerType = 'CONTAINER_TYPE',
  Dismantling = 'DISMANTLING',
  DismantlingSub = 'DISMANTLING_SUB',
  Piece = 'PIECE',
  Webshop = 'WEBSHOP'
}

/** Boolean expression to compare columns of type "product_group_types_enum". All fields are combined with logical 'AND'. */
export type Product_Group_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Product_Group_Types_Enum>;
  _in?: InputMaybe<Array<Product_Group_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Product_Group_Types_Enum>;
  _nin?: InputMaybe<Array<Product_Group_Types_Enum>>;
};

/** aggregate max on columns */
export type Product_Group_Types_Max_Fields = {
  __typename: 'product_group_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Product_Group_Types_Min_Fields = {
  __typename: 'product_group_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "product_group_types". */
export type Product_Group_Types_Order_By = {
  productGroups_aggregate?: InputMaybe<Product_Groups_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "product_group_types" */
export enum Product_Group_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "product_group_types" */
export type Product_Group_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Group_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Group_Types_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "product_groups" */
export type Product_Groups = {
  __typename: 'product_groups';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  customers: Array<Customers>;
  /** An aggregate relationship */
  customers_aggregate: Customers_Aggregate;
  /** An array relationship */
  dismantlingPlanInstructions: Array<Dismantling_Plan_Instructions>;
  /** An aggregate relationship */
  dismantlingPlanInstructions_aggregate: Dismantling_Plan_Instructions_Aggregate;
  /** An array relationship */
  dismantlingPlans: Array<Dismantling_Plans>;
  /** An aggregate relationship */
  dismantlingPlans_aggregate: Dismantling_Plans_Aggregate;
  domainId: Scalars['String'];
  /** An array relationship */
  globalSearchResults: Array<Global_Search_Result>;
  /** An aggregate relationship */
  globalSearchResults_aggregate: Global_Search_Result_Aggregate;
  i18n: Scalars['jsonb'];
  id: Scalars['bigint'];
  /** The name used internally for searching product groups, never communicated externally */
  internalName?: Maybe<Scalars['String']>;
  /** An array relationship */
  listPurchasedProductsResults: Array<List_Purchased_Products_Result>;
  /** An aggregate relationship */
  listPurchasedProductsResults_aggregate: List_Purchased_Products_Result_Aggregate;
  name: Scalars['String'];
  ordinalPosition: Scalars['Int'];
  pricingWeight: Scalars['bigint'];
  /** An array relationship */
  productGroupMembers: Array<Product_Group_Members>;
  /** An aggregate relationship */
  productGroupMembers_aggregate: Product_Group_Members_Aggregate;
  /** An object relationship */
  productGroupParent?: Maybe<Product_Groups>;
  productGroupParentId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  productGroups: Array<Product_Groups>;
  /** An aggregate relationship */
  productGroups_aggregate: Product_Groups_Aggregate;
  /** An array relationship */
  productionSiteProductGroupCalendars: Array<Production_Site_Product_Group_Calendar>;
  /** An aggregate relationship */
  productionSiteProductGroupCalendars_aggregate: Production_Site_Product_Group_Calendar_Aggregate;
  protected: Scalars['Boolean'];
  state: Product_Group_States_Enum;
  tenant: Scalars['String'];
  type?: Maybe<Product_Group_Types_Enum>;
};


/** columns and relationships of "product_groups" */
export type Product_GroupsCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "product_groups" */
export type Product_GroupsCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "product_groups" */
export type Product_GroupsDismantlingPlanInstructionsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Instructions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Instructions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Instructions_Bool_Exp>;
};


/** columns and relationships of "product_groups" */
export type Product_GroupsDismantlingPlanInstructions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Instructions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Instructions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Instructions_Bool_Exp>;
};


/** columns and relationships of "product_groups" */
export type Product_GroupsDismantlingPlansArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plans_Order_By>>;
  where?: InputMaybe<Dismantling_Plans_Bool_Exp>;
};


/** columns and relationships of "product_groups" */
export type Product_GroupsDismantlingPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plans_Order_By>>;
  where?: InputMaybe<Dismantling_Plans_Bool_Exp>;
};


/** columns and relationships of "product_groups" */
export type Product_GroupsGlobalSearchResultsArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


/** columns and relationships of "product_groups" */
export type Product_GroupsGlobalSearchResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


/** columns and relationships of "product_groups" */
export type Product_GroupsI18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "product_groups" */
export type Product_GroupsListPurchasedProductsResultsArgs = {
  distinct_on?: InputMaybe<Array<List_Purchased_Products_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Purchased_Products_Result_Order_By>>;
  where?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
};


/** columns and relationships of "product_groups" */
export type Product_GroupsListPurchasedProductsResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Purchased_Products_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Purchased_Products_Result_Order_By>>;
  where?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
};


/** columns and relationships of "product_groups" */
export type Product_GroupsProductGroupMembersArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_Members_Order_By>>;
  where?: InputMaybe<Product_Group_Members_Bool_Exp>;
};


/** columns and relationships of "product_groups" */
export type Product_GroupsProductGroupMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_Members_Order_By>>;
  where?: InputMaybe<Product_Group_Members_Bool_Exp>;
};


/** columns and relationships of "product_groups" */
export type Product_GroupsProductGroupsArgs = {
  distinct_on?: InputMaybe<Array<Product_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Groups_Order_By>>;
  where?: InputMaybe<Product_Groups_Bool_Exp>;
};


/** columns and relationships of "product_groups" */
export type Product_GroupsProductGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Groups_Order_By>>;
  where?: InputMaybe<Product_Groups_Bool_Exp>;
};


/** columns and relationships of "product_groups" */
export type Product_GroupsProductionSiteProductGroupCalendarsArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Order_By>>;
  where?: InputMaybe<Production_Site_Product_Group_Calendar_Bool_Exp>;
};


/** columns and relationships of "product_groups" */
export type Product_GroupsProductionSiteProductGroupCalendars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Order_By>>;
  where?: InputMaybe<Production_Site_Product_Group_Calendar_Bool_Exp>;
};

/** aggregated selection of "product_groups" */
export type Product_Groups_Aggregate = {
  __typename: 'product_groups_aggregate';
  aggregate?: Maybe<Product_Groups_Aggregate_Fields>;
  nodes: Array<Product_Groups>;
};

export type Product_Groups_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Product_Groups_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Product_Groups_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Product_Groups_Aggregate_Bool_Exp_Count>;
};

export type Product_Groups_Aggregate_Bool_Exp_Bool_And = {
  arguments: Product_Groups_Select_Column_Product_Groups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Product_Groups_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Product_Groups_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Product_Groups_Select_Column_Product_Groups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Product_Groups_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Product_Groups_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Product_Groups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_groups" */
export type Product_Groups_Aggregate_Fields = {
  __typename: 'product_groups_aggregate_fields';
  avg?: Maybe<Product_Groups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Groups_Max_Fields>;
  min?: Maybe<Product_Groups_Min_Fields>;
  stddev?: Maybe<Product_Groups_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Groups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Groups_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Groups_Sum_Fields>;
  var_pop?: Maybe<Product_Groups_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Groups_Var_Samp_Fields>;
  variance?: Maybe<Product_Groups_Variance_Fields>;
};


/** aggregate fields of "product_groups" */
export type Product_Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_groups" */
export type Product_Groups_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Groups_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Groups_Max_Order_By>;
  min?: InputMaybe<Product_Groups_Min_Order_By>;
  stddev?: InputMaybe<Product_Groups_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Groups_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Groups_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Groups_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Groups_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Groups_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Groups_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Product_Groups_Append_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "product_groups" */
export type Product_Groups_Arr_Rel_Insert_Input = {
  data: Array<Product_Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Groups_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Groups_Avg_Fields = {
  __typename: 'product_groups_avg_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pricingWeight?: Maybe<Scalars['Float']>;
  productGroupParentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_groups" */
export type Product_Groups_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pricingWeight?: InputMaybe<Order_By>;
  productGroupParentId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_groups". All fields are combined with a logical 'AND'. */
export type Product_Groups_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Product_Groups_Bool_Exp>>;
  _not?: InputMaybe<Product_Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Groups_Bool_Exp>>;
  customers?: InputMaybe<Customers_Bool_Exp>;
  customers_aggregate?: InputMaybe<Customers_Aggregate_Bool_Exp>;
  dismantlingPlanInstructions?: InputMaybe<Dismantling_Plan_Instructions_Bool_Exp>;
  dismantlingPlanInstructions_aggregate?: InputMaybe<Dismantling_Plan_Instructions_Aggregate_Bool_Exp>;
  dismantlingPlans?: InputMaybe<Dismantling_Plans_Bool_Exp>;
  dismantlingPlans_aggregate?: InputMaybe<Dismantling_Plans_Aggregate_Bool_Exp>;
  domainId?: InputMaybe<String_Comparison_Exp>;
  globalSearchResults?: InputMaybe<Global_Search_Result_Bool_Exp>;
  globalSearchResults_aggregate?: InputMaybe<Global_Search_Result_Aggregate_Bool_Exp>;
  i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  internalName?: InputMaybe<String_Comparison_Exp>;
  listPurchasedProductsResults?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
  listPurchasedProductsResults_aggregate?: InputMaybe<List_Purchased_Products_Result_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  pricingWeight?: InputMaybe<Bigint_Comparison_Exp>;
  productGroupMembers?: InputMaybe<Product_Group_Members_Bool_Exp>;
  productGroupMembers_aggregate?: InputMaybe<Product_Group_Members_Aggregate_Bool_Exp>;
  productGroupParent?: InputMaybe<Product_Groups_Bool_Exp>;
  productGroupParentId?: InputMaybe<Bigint_Comparison_Exp>;
  productGroups?: InputMaybe<Product_Groups_Bool_Exp>;
  productGroups_aggregate?: InputMaybe<Product_Groups_Aggregate_Bool_Exp>;
  productionSiteProductGroupCalendars?: InputMaybe<Production_Site_Product_Group_Calendar_Bool_Exp>;
  productionSiteProductGroupCalendars_aggregate?: InputMaybe<Production_Site_Product_Group_Calendar_Aggregate_Bool_Exp>;
  protected?: InputMaybe<Boolean_Comparison_Exp>;
  state?: InputMaybe<Product_Group_States_Enum_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Product_Group_Types_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_groups" */
export enum Product_Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductGroupsPkey = 'product_groups_pkey',
  /** unique or primary key constraint on columns "tenant", "domain_id" */
  ProductGroupsTenantDomainId = 'product_groups_tenant_domain_id',
  /** unique or primary key constraint on columns "name", "tenant" */
  ProductGroupsTenantNameKey = 'product_groups_tenant_name_key',
  /** unique or primary key constraint on columns "tenant", "ordinal_position" */
  ProductGroupsTenantOrdinalPositionUniq = 'product_groups_tenant_ordinal_position_uniq'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Product_Groups_Delete_At_Path_Input = {
  i18n?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Product_Groups_Delete_Elem_Input = {
  i18n?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Product_Groups_Delete_Key_Input = {
  i18n?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "product_groups" */
export type Product_Groups_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  pricingWeight?: InputMaybe<Scalars['bigint']>;
  productGroupParentId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_groups" */
export type Product_Groups_Insert_Input = {
  customers?: InputMaybe<Customers_Arr_Rel_Insert_Input>;
  dismantlingPlanInstructions?: InputMaybe<Dismantling_Plan_Instructions_Arr_Rel_Insert_Input>;
  dismantlingPlans?: InputMaybe<Dismantling_Plans_Arr_Rel_Insert_Input>;
  domainId?: InputMaybe<Scalars['String']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  pricingWeight?: InputMaybe<Scalars['bigint']>;
  productGroupMembers?: InputMaybe<Product_Group_Members_Arr_Rel_Insert_Input>;
  productGroupParent?: InputMaybe<Product_Groups_Obj_Rel_Insert_Input>;
  productGroupParentId?: InputMaybe<Scalars['bigint']>;
  productGroups?: InputMaybe<Product_Groups_Arr_Rel_Insert_Input>;
  productionSiteProductGroupCalendars?: InputMaybe<Production_Site_Product_Group_Calendar_Arr_Rel_Insert_Input>;
  protected?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Product_Group_States_Enum>;
  type?: InputMaybe<Product_Group_Types_Enum>;
};

/** aggregate max on columns */
export type Product_Groups_Max_Fields = {
  __typename: 'product_groups_max_fields';
  domainId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  pricingWeight?: Maybe<Scalars['bigint']>;
  productGroupParentId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "product_groups" */
export type Product_Groups_Max_Order_By = {
  domainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pricingWeight?: InputMaybe<Order_By>;
  productGroupParentId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Groups_Min_Fields = {
  __typename: 'product_groups_min_fields';
  domainId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  pricingWeight?: Maybe<Scalars['bigint']>;
  productGroupParentId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "product_groups" */
export type Product_Groups_Min_Order_By = {
  domainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pricingWeight?: InputMaybe<Order_By>;
  productGroupParentId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_groups" */
export type Product_Groups_Mutation_Response = {
  __typename: 'product_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Groups>;
};

/** input type for inserting object relation for remote table "product_groups" */
export type Product_Groups_Obj_Rel_Insert_Input = {
  data: Product_Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Groups_On_Conflict>;
};

/** on_conflict condition type for table "product_groups" */
export type Product_Groups_On_Conflict = {
  constraint: Product_Groups_Constraint;
  update_columns?: Array<Product_Groups_Update_Column>;
  where?: InputMaybe<Product_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "product_groups". */
export type Product_Groups_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  customers_aggregate?: InputMaybe<Customers_Aggregate_Order_By>;
  dismantlingPlanInstructions_aggregate?: InputMaybe<Dismantling_Plan_Instructions_Aggregate_Order_By>;
  dismantlingPlans_aggregate?: InputMaybe<Dismantling_Plans_Aggregate_Order_By>;
  domainId?: InputMaybe<Order_By>;
  globalSearchResults_aggregate?: InputMaybe<Global_Search_Result_Aggregate_Order_By>;
  i18n?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internalName?: InputMaybe<Order_By>;
  listPurchasedProductsResults_aggregate?: InputMaybe<List_Purchased_Products_Result_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pricingWeight?: InputMaybe<Order_By>;
  productGroupMembers_aggregate?: InputMaybe<Product_Group_Members_Aggregate_Order_By>;
  productGroupParent?: InputMaybe<Product_Groups_Order_By>;
  productGroupParentId?: InputMaybe<Order_By>;
  productGroups_aggregate?: InputMaybe<Product_Groups_Aggregate_Order_By>;
  productionSiteProductGroupCalendars_aggregate?: InputMaybe<Production_Site_Product_Group_Calendar_Aggregate_Order_By>;
  protected?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_groups */
export type Product_Groups_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Product_Groups_Prepend_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "product_groups" */
export enum Product_Groups_Select_Column {
  /** column name */
  DomainId = 'domainId',
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  PricingWeight = 'pricingWeight',
  /** column name */
  ProductGroupParentId = 'productGroupParentId',
  /** column name */
  Protected = 'protected',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  Type = 'type'
}

/** select "product_groups_aggregate_bool_exp_bool_and_arguments_columns" columns of table "product_groups" */
export enum Product_Groups_Select_Column_Product_Groups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Protected = 'protected'
}

/** select "product_groups_aggregate_bool_exp_bool_or_arguments_columns" columns of table "product_groups" */
export enum Product_Groups_Select_Column_Product_Groups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Protected = 'protected'
}

/** input type for updating data in table "product_groups" */
export type Product_Groups_Set_Input = {
  domainId?: InputMaybe<Scalars['String']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  pricingWeight?: InputMaybe<Scalars['bigint']>;
  productGroupParentId?: InputMaybe<Scalars['bigint']>;
  protected?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Product_Group_States_Enum>;
  type?: InputMaybe<Product_Group_Types_Enum>;
};

/** aggregate stddev on columns */
export type Product_Groups_Stddev_Fields = {
  __typename: 'product_groups_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pricingWeight?: Maybe<Scalars['Float']>;
  productGroupParentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_groups" */
export type Product_Groups_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pricingWeight?: InputMaybe<Order_By>;
  productGroupParentId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Groups_Stddev_Pop_Fields = {
  __typename: 'product_groups_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pricingWeight?: Maybe<Scalars['Float']>;
  productGroupParentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_groups" */
export type Product_Groups_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pricingWeight?: InputMaybe<Order_By>;
  productGroupParentId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Groups_Stddev_Samp_Fields = {
  __typename: 'product_groups_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pricingWeight?: Maybe<Scalars['Float']>;
  productGroupParentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_groups" */
export type Product_Groups_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pricingWeight?: InputMaybe<Order_By>;
  productGroupParentId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "product_groups" */
export type Product_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Groups_Stream_Cursor_Value_Input = {
  domainId?: InputMaybe<Scalars['String']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  pricingWeight?: InputMaybe<Scalars['bigint']>;
  productGroupParentId?: InputMaybe<Scalars['bigint']>;
  protected?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Product_Group_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Product_Group_Types_Enum>;
};

/** aggregate sum on columns */
export type Product_Groups_Sum_Fields = {
  __typename: 'product_groups_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  pricingWeight?: Maybe<Scalars['bigint']>;
  productGroupParentId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product_groups" */
export type Product_Groups_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pricingWeight?: InputMaybe<Order_By>;
  productGroupParentId?: InputMaybe<Order_By>;
};

/** update columns of table "product_groups" */
export enum Product_Groups_Update_Column {
  /** column name */
  DomainId = 'domainId',
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  PricingWeight = 'pricingWeight',
  /** column name */
  ProductGroupParentId = 'productGroupParentId',
  /** column name */
  Protected = 'protected',
  /** column name */
  State = 'state',
  /** column name */
  Type = 'type'
}

export type Product_Groups_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Product_Groups_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Product_Groups_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Product_Groups_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Product_Groups_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Groups_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Product_Groups_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Groups_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Groups_Var_Pop_Fields = {
  __typename: 'product_groups_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pricingWeight?: Maybe<Scalars['Float']>;
  productGroupParentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_groups" */
export type Product_Groups_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pricingWeight?: InputMaybe<Order_By>;
  productGroupParentId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Groups_Var_Samp_Fields = {
  __typename: 'product_groups_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pricingWeight?: Maybe<Scalars['Float']>;
  productGroupParentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_groups" */
export type Product_Groups_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pricingWeight?: InputMaybe<Order_By>;
  productGroupParentId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Groups_Variance_Fields = {
  __typename: 'product_groups_variance_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  pricingWeight?: Maybe<Scalars['Float']>;
  productGroupParentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_groups" */
export type Product_Groups_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pricingWeight?: InputMaybe<Order_By>;
  productGroupParentId?: InputMaybe<Order_By>;
};

/** columns and relationships of "product_label_settings" */
export type Product_Label_Settings = {
  __typename: 'product_label_settings';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  label: Jscript_Templates;
  labelId: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  templateValues: Scalars['jsonb'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "product_label_settings" */
export type Product_Label_SettingsTemplateValuesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "product_label_settings" */
export type Product_Label_Settings_Aggregate = {
  __typename: 'product_label_settings_aggregate';
  aggregate?: Maybe<Product_Label_Settings_Aggregate_Fields>;
  nodes: Array<Product_Label_Settings>;
};

export type Product_Label_Settings_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Label_Settings_Aggregate_Bool_Exp_Count>;
};

export type Product_Label_Settings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Label_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Product_Label_Settings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_label_settings" */
export type Product_Label_Settings_Aggregate_Fields = {
  __typename: 'product_label_settings_aggregate_fields';
  avg?: Maybe<Product_Label_Settings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Label_Settings_Max_Fields>;
  min?: Maybe<Product_Label_Settings_Min_Fields>;
  stddev?: Maybe<Product_Label_Settings_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Label_Settings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Label_Settings_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Label_Settings_Sum_Fields>;
  var_pop?: Maybe<Product_Label_Settings_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Label_Settings_Var_Samp_Fields>;
  variance?: Maybe<Product_Label_Settings_Variance_Fields>;
};


/** aggregate fields of "product_label_settings" */
export type Product_Label_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Label_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_label_settings" */
export type Product_Label_Settings_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Label_Settings_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Label_Settings_Max_Order_By>;
  min?: InputMaybe<Product_Label_Settings_Min_Order_By>;
  stddev?: InputMaybe<Product_Label_Settings_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Label_Settings_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Label_Settings_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Label_Settings_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Label_Settings_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Label_Settings_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Label_Settings_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Product_Label_Settings_Append_Input = {
  templateValues?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "product_label_settings" */
export type Product_Label_Settings_Arr_Rel_Insert_Input = {
  data: Array<Product_Label_Settings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Label_Settings_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Label_Settings_Avg_Fields = {
  __typename: 'product_label_settings_avg_fields';
  id?: Maybe<Scalars['Float']>;
  labelId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_label_settings" */
export type Product_Label_Settings_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  labelId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_label_settings". All fields are combined with a logical 'AND'. */
export type Product_Label_Settings_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Product_Label_Settings_Bool_Exp>>;
  _not?: InputMaybe<Product_Label_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Label_Settings_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  label?: InputMaybe<Jscript_Templates_Bool_Exp>;
  labelId?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  templateValues?: InputMaybe<Jsonb_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_label_settings" */
export enum Product_Label_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductLabelSettingsPkey = 'product_label_settings_pkey',
  /** unique or primary key constraint on columns "product_id" */
  ProductLabelSettingsProductIdKey = 'product_label_settings_product_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Product_Label_Settings_Delete_At_Path_Input = {
  templateValues?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Product_Label_Settings_Delete_Elem_Input = {
  templateValues?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Product_Label_Settings_Delete_Key_Input = {
  templateValues?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "product_label_settings" */
export type Product_Label_Settings_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  labelId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_label_settings" */
export type Product_Label_Settings_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  label?: InputMaybe<Jscript_Templates_Obj_Rel_Insert_Input>;
  labelId?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  templateValues?: InputMaybe<Scalars['jsonb']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Product_Label_Settings_Max_Fields = {
  __typename: 'product_label_settings_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  labelId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "product_label_settings" */
export type Product_Label_Settings_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  labelId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Label_Settings_Min_Fields = {
  __typename: 'product_label_settings_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  labelId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "product_label_settings" */
export type Product_Label_Settings_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  labelId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_label_settings" */
export type Product_Label_Settings_Mutation_Response = {
  __typename: 'product_label_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Label_Settings>;
};

/** on_conflict condition type for table "product_label_settings" */
export type Product_Label_Settings_On_Conflict = {
  constraint: Product_Label_Settings_Constraint;
  update_columns?: Array<Product_Label_Settings_Update_Column>;
  where?: InputMaybe<Product_Label_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "product_label_settings". */
export type Product_Label_Settings_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Jscript_Templates_Order_By>;
  labelId?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  templateValues?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_label_settings */
export type Product_Label_Settings_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Product_Label_Settings_Prepend_Input = {
  templateValues?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "product_label_settings" */
export enum Product_Label_Settings_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LabelId = 'labelId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  TemplateValues = 'templateValues',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "product_label_settings" */
export type Product_Label_Settings_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  labelId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  templateValues?: InputMaybe<Scalars['jsonb']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Product_Label_Settings_Stddev_Fields = {
  __typename: 'product_label_settings_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  labelId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_label_settings" */
export type Product_Label_Settings_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  labelId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Label_Settings_Stddev_Pop_Fields = {
  __typename: 'product_label_settings_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  labelId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_label_settings" */
export type Product_Label_Settings_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  labelId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Label_Settings_Stddev_Samp_Fields = {
  __typename: 'product_label_settings_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  labelId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_label_settings" */
export type Product_Label_Settings_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  labelId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "product_label_settings" */
export type Product_Label_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Label_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Label_Settings_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  labelId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  templateValues?: InputMaybe<Scalars['jsonb']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Product_Label_Settings_Sum_Fields = {
  __typename: 'product_label_settings_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  labelId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product_label_settings" */
export type Product_Label_Settings_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  labelId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "product_label_settings" */
export enum Product_Label_Settings_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LabelId = 'labelId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  TemplateValues = 'templateValues',
  /** column name */
  Version = 'version'
}

export type Product_Label_Settings_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Product_Label_Settings_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Product_Label_Settings_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Product_Label_Settings_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Product_Label_Settings_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Label_Settings_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Product_Label_Settings_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Label_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Label_Settings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Label_Settings_Var_Pop_Fields = {
  __typename: 'product_label_settings_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  labelId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_label_settings" */
export type Product_Label_Settings_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  labelId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Label_Settings_Var_Samp_Fields = {
  __typename: 'product_label_settings_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  labelId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_label_settings" */
export type Product_Label_Settings_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  labelId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Label_Settings_Variance_Fields = {
  __typename: 'product_label_settings_variance_fields';
  id?: Maybe<Scalars['Float']>;
  labelId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_label_settings" */
export type Product_Label_Settings_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  labelId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "product_logged_actions" */
export type Product_Logged_Actions = {
  __typename: 'product_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  entity?: Maybe<Products>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "product_logged_actions" */
export type Product_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "product_logged_actions" */
export type Product_Logged_Actions_Aggregate = {
  __typename: 'product_logged_actions_aggregate';
  aggregate?: Maybe<Product_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Product_Logged_Actions>;
};

export type Product_Logged_Actions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Logged_Actions_Aggregate_Bool_Exp_Count>;
};

export type Product_Logged_Actions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Product_Logged_Actions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_logged_actions" */
export type Product_Logged_Actions_Aggregate_Fields = {
  __typename: 'product_logged_actions_aggregate_fields';
  avg?: Maybe<Product_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Logged_Actions_Max_Fields>;
  min?: Maybe<Product_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Product_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Product_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Product_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "product_logged_actions" */
export type Product_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_logged_actions" */
export type Product_Logged_Actions_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Logged_Actions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Logged_Actions_Max_Order_By>;
  min?: InputMaybe<Product_Logged_Actions_Min_Order_By>;
  stddev?: InputMaybe<Product_Logged_Actions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Logged_Actions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Logged_Actions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Logged_Actions_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Logged_Actions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Logged_Actions_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Logged_Actions_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Product_Logged_Actions_Avg_Fields = {
  __typename: 'product_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_logged_actions" */
export type Product_Logged_Actions_Avg_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_logged_actions". All fields are combined with a logical 'AND'. */
export type Product_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Product_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entity?: InputMaybe<Products_Bool_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Product_Logged_Actions_Max_Fields = {
  __typename: 'product_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "product_logged_actions" */
export type Product_Logged_Actions_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Logged_Actions_Min_Fields = {
  __typename: 'product_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "product_logged_actions" */
export type Product_Logged_Actions_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "product_logged_actions". */
export type Product_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entity?: InputMaybe<Products_Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "product_logged_actions" */
export enum Product_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Role = 'role',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Product_Logged_Actions_Stddev_Fields = {
  __typename: 'product_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_logged_actions" */
export type Product_Logged_Actions_Stddev_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'product_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_logged_actions" */
export type Product_Logged_Actions_Stddev_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'product_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_logged_actions" */
export type Product_Logged_Actions_Stddev_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "product_logged_actions" */
export type Product_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  role?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Product_Logged_Actions_Sum_Fields = {
  __typename: 'product_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product_logged_actions" */
export type Product_Logged_Actions_Sum_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Product_Logged_Actions_Var_Pop_Fields = {
  __typename: 'product_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_logged_actions" */
export type Product_Logged_Actions_Var_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Logged_Actions_Var_Samp_Fields = {
  __typename: 'product_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_logged_actions" */
export type Product_Logged_Actions_Var_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Logged_Actions_Variance_Fields = {
  __typename: 'product_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_logged_actions" */
export type Product_Logged_Actions_Variance_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "product_packaging_configurations" */
export type Product_Packaging_Configurations = {
  __typename: 'product_packaging_configurations';
  /** An object relationship */
  Tenant: Tenants;
  barcodeType?: Maybe<Scalars['bigint']>;
  bestBeforeDays?: Maybe<Scalars['bigint']>;
  createdAt: Scalars['timestamptz'];
  customerLocation?: Maybe<Scalars['String']>;
  /** An object relationship */
  customerProductAvailability: Customer_Product_Availabilities;
  customerProductAvailabilityId: Scalars['bigint'];
  description: Scalars['String'];
  ean?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  itemsPerBox?: Maybe<Scalars['bigint']>;
  itemsPerPallet?: Maybe<Scalars['bigint']>;
  labelName: Scalars['jsonb'];
  /** An object relationship */
  leichmehlTemplateType: Leichmehl_Template_Types;
  leichmehlTemplateTypeId: Scalars['bigint'];
  name: Scalars['String'];
  tareWeightGrams?: Maybe<Scalars['bigint']>;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "product_packaging_configurations" */
export type Product_Packaging_ConfigurationsLabelNameArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "product_packaging_configurations" */
export type Product_Packaging_Configurations_Aggregate = {
  __typename: 'product_packaging_configurations_aggregate';
  aggregate?: Maybe<Product_Packaging_Configurations_Aggregate_Fields>;
  nodes: Array<Product_Packaging_Configurations>;
};

export type Product_Packaging_Configurations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Packaging_Configurations_Aggregate_Bool_Exp_Count>;
};

export type Product_Packaging_Configurations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Packaging_Configurations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Product_Packaging_Configurations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_packaging_configurations" */
export type Product_Packaging_Configurations_Aggregate_Fields = {
  __typename: 'product_packaging_configurations_aggregate_fields';
  avg?: Maybe<Product_Packaging_Configurations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Packaging_Configurations_Max_Fields>;
  min?: Maybe<Product_Packaging_Configurations_Min_Fields>;
  stddev?: Maybe<Product_Packaging_Configurations_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Packaging_Configurations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Packaging_Configurations_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Packaging_Configurations_Sum_Fields>;
  var_pop?: Maybe<Product_Packaging_Configurations_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Packaging_Configurations_Var_Samp_Fields>;
  variance?: Maybe<Product_Packaging_Configurations_Variance_Fields>;
};


/** aggregate fields of "product_packaging_configurations" */
export type Product_Packaging_Configurations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Packaging_Configurations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_packaging_configurations" */
export type Product_Packaging_Configurations_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Packaging_Configurations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Packaging_Configurations_Max_Order_By>;
  min?: InputMaybe<Product_Packaging_Configurations_Min_Order_By>;
  stddev?: InputMaybe<Product_Packaging_Configurations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Packaging_Configurations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Packaging_Configurations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Packaging_Configurations_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Packaging_Configurations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Packaging_Configurations_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Packaging_Configurations_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Product_Packaging_Configurations_Append_Input = {
  labelName?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "product_packaging_configurations" */
export type Product_Packaging_Configurations_Arr_Rel_Insert_Input = {
  data: Array<Product_Packaging_Configurations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Packaging_Configurations_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Packaging_Configurations_Avg_Fields = {
  __typename: 'product_packaging_configurations_avg_fields';
  barcodeType?: Maybe<Scalars['Float']>;
  bestBeforeDays?: Maybe<Scalars['Float']>;
  customerProductAvailabilityId?: Maybe<Scalars['Float']>;
  ean?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  itemsPerBox?: Maybe<Scalars['Float']>;
  itemsPerPallet?: Maybe<Scalars['Float']>;
  leichmehlTemplateTypeId?: Maybe<Scalars['Float']>;
  tareWeightGrams?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_packaging_configurations" */
export type Product_Packaging_Configurations_Avg_Order_By = {
  barcodeType?: InputMaybe<Order_By>;
  bestBeforeDays?: InputMaybe<Order_By>;
  customerProductAvailabilityId?: InputMaybe<Order_By>;
  ean?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  itemsPerBox?: InputMaybe<Order_By>;
  itemsPerPallet?: InputMaybe<Order_By>;
  leichmehlTemplateTypeId?: InputMaybe<Order_By>;
  tareWeightGrams?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_packaging_configurations". All fields are combined with a logical 'AND'. */
export type Product_Packaging_Configurations_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Product_Packaging_Configurations_Bool_Exp>>;
  _not?: InputMaybe<Product_Packaging_Configurations_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Packaging_Configurations_Bool_Exp>>;
  barcodeType?: InputMaybe<Bigint_Comparison_Exp>;
  bestBeforeDays?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerLocation?: InputMaybe<String_Comparison_Exp>;
  customerProductAvailability?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
  customerProductAvailabilityId?: InputMaybe<Bigint_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  ean?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  itemsPerBox?: InputMaybe<Bigint_Comparison_Exp>;
  itemsPerPallet?: InputMaybe<Bigint_Comparison_Exp>;
  labelName?: InputMaybe<Jsonb_Comparison_Exp>;
  leichmehlTemplateType?: InputMaybe<Leichmehl_Template_Types_Bool_Exp>;
  leichmehlTemplateTypeId?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tareWeightGrams?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_packaging_configurations" */
export enum Product_Packaging_Configurations_Constraint {
  /** unique or primary key constraint on columns "customer_product_availability_id" */
  ProductPackagingConfiguratiCustomerProductAvailabilityKey = 'product_packaging_configurati_customer_product_availability_key',
  /** unique or primary key constraint on columns "id" */
  ProductPackagingConfigurationsPkey = 'product_packaging_configurations_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Product_Packaging_Configurations_Delete_At_Path_Input = {
  labelName?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Product_Packaging_Configurations_Delete_Elem_Input = {
  labelName?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Product_Packaging_Configurations_Delete_Key_Input = {
  labelName?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "product_packaging_configurations" */
export type Product_Packaging_Configurations_Inc_Input = {
  barcodeType?: InputMaybe<Scalars['bigint']>;
  bestBeforeDays?: InputMaybe<Scalars['bigint']>;
  customerProductAvailabilityId?: InputMaybe<Scalars['bigint']>;
  ean?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  itemsPerBox?: InputMaybe<Scalars['bigint']>;
  itemsPerPallet?: InputMaybe<Scalars['bigint']>;
  leichmehlTemplateTypeId?: InputMaybe<Scalars['bigint']>;
  tareWeightGrams?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_packaging_configurations" */
export type Product_Packaging_Configurations_Insert_Input = {
  barcodeType?: InputMaybe<Scalars['bigint']>;
  bestBeforeDays?: InputMaybe<Scalars['bigint']>;
  customerLocation?: InputMaybe<Scalars['String']>;
  customerProductAvailability?: InputMaybe<Customer_Product_Availabilities_Obj_Rel_Insert_Input>;
  customerProductAvailabilityId?: InputMaybe<Scalars['bigint']>;
  description?: InputMaybe<Scalars['String']>;
  ean?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  itemsPerBox?: InputMaybe<Scalars['bigint']>;
  itemsPerPallet?: InputMaybe<Scalars['bigint']>;
  labelName?: InputMaybe<Scalars['jsonb']>;
  leichmehlTemplateTypeId?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  tareWeightGrams?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "product_packaging_configurations_logged_actions" */
export type Product_Packaging_Configurations_Logged_Actions = {
  __typename: 'product_packaging_configurations_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  relid?: Maybe<Scalars['oid']>;
  role?: Maybe<Scalars['String']>;
  rowData?: Maybe<Scalars['jsonb']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "product_packaging_configurations_logged_actions" */
export type Product_Packaging_Configurations_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "product_packaging_configurations_logged_actions" */
export type Product_Packaging_Configurations_Logged_ActionsRowDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "product_packaging_configurations_logged_actions" */
export type Product_Packaging_Configurations_Logged_Actions_Aggregate = {
  __typename: 'product_packaging_configurations_logged_actions_aggregate';
  aggregate?: Maybe<Product_Packaging_Configurations_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Product_Packaging_Configurations_Logged_Actions>;
};

/** aggregate fields of "product_packaging_configurations_logged_actions" */
export type Product_Packaging_Configurations_Logged_Actions_Aggregate_Fields = {
  __typename: 'product_packaging_configurations_logged_actions_aggregate_fields';
  avg?: Maybe<Product_Packaging_Configurations_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Packaging_Configurations_Logged_Actions_Max_Fields>;
  min?: Maybe<Product_Packaging_Configurations_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Product_Packaging_Configurations_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Packaging_Configurations_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Packaging_Configurations_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Packaging_Configurations_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Product_Packaging_Configurations_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Packaging_Configurations_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Product_Packaging_Configurations_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "product_packaging_configurations_logged_actions" */
export type Product_Packaging_Configurations_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Packaging_Configurations_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Product_Packaging_Configurations_Logged_Actions_Avg_Fields = {
  __typename: 'product_packaging_configurations_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "product_packaging_configurations_logged_actions". All fields are combined with a logical 'AND'. */
export type Product_Packaging_Configurations_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Packaging_Configurations_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Product_Packaging_Configurations_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Packaging_Configurations_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  relid?: InputMaybe<Oid_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  rowData?: InputMaybe<Jsonb_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Product_Packaging_Configurations_Logged_Actions_Max_Fields = {
  __typename: 'product_packaging_configurations_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Product_Packaging_Configurations_Logged_Actions_Min_Fields = {
  __typename: 'product_packaging_configurations_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "product_packaging_configurations_logged_actions". */
export type Product_Packaging_Configurations_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  relid?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  rowData?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "product_packaging_configurations_logged_actions" */
export enum Product_Packaging_Configurations_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Relid = 'relid',
  /** column name */
  Role = 'role',
  /** column name */
  RowData = 'rowData',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Product_Packaging_Configurations_Logged_Actions_Stddev_Fields = {
  __typename: 'product_packaging_configurations_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Product_Packaging_Configurations_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'product_packaging_configurations_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Product_Packaging_Configurations_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'product_packaging_configurations_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "product_packaging_configurations_logged_actions" */
export type Product_Packaging_Configurations_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Packaging_Configurations_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Packaging_Configurations_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  relid?: InputMaybe<Scalars['oid']>;
  role?: InputMaybe<Scalars['String']>;
  rowData?: InputMaybe<Scalars['jsonb']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Product_Packaging_Configurations_Logged_Actions_Sum_Fields = {
  __typename: 'product_packaging_configurations_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Product_Packaging_Configurations_Logged_Actions_Var_Pop_Fields = {
  __typename: 'product_packaging_configurations_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Product_Packaging_Configurations_Logged_Actions_Var_Samp_Fields = {
  __typename: 'product_packaging_configurations_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Product_Packaging_Configurations_Logged_Actions_Variance_Fields = {
  __typename: 'product_packaging_configurations_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Product_Packaging_Configurations_Max_Fields = {
  __typename: 'product_packaging_configurations_max_fields';
  barcodeType?: Maybe<Scalars['bigint']>;
  bestBeforeDays?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerLocation?: Maybe<Scalars['String']>;
  customerProductAvailabilityId?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  itemsPerBox?: Maybe<Scalars['bigint']>;
  itemsPerPallet?: Maybe<Scalars['bigint']>;
  leichmehlTemplateTypeId?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  tareWeightGrams?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "product_packaging_configurations" */
export type Product_Packaging_Configurations_Max_Order_By = {
  barcodeType?: InputMaybe<Order_By>;
  bestBeforeDays?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerLocation?: InputMaybe<Order_By>;
  customerProductAvailabilityId?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  ean?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  itemsPerBox?: InputMaybe<Order_By>;
  itemsPerPallet?: InputMaybe<Order_By>;
  leichmehlTemplateTypeId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tareWeightGrams?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Packaging_Configurations_Min_Fields = {
  __typename: 'product_packaging_configurations_min_fields';
  barcodeType?: Maybe<Scalars['bigint']>;
  bestBeforeDays?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerLocation?: Maybe<Scalars['String']>;
  customerProductAvailabilityId?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  itemsPerBox?: Maybe<Scalars['bigint']>;
  itemsPerPallet?: Maybe<Scalars['bigint']>;
  leichmehlTemplateTypeId?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  tareWeightGrams?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "product_packaging_configurations" */
export type Product_Packaging_Configurations_Min_Order_By = {
  barcodeType?: InputMaybe<Order_By>;
  bestBeforeDays?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerLocation?: InputMaybe<Order_By>;
  customerProductAvailabilityId?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  ean?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  itemsPerBox?: InputMaybe<Order_By>;
  itemsPerPallet?: InputMaybe<Order_By>;
  leichmehlTemplateTypeId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tareWeightGrams?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_packaging_configurations" */
export type Product_Packaging_Configurations_Mutation_Response = {
  __typename: 'product_packaging_configurations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Packaging_Configurations>;
};

/** on_conflict condition type for table "product_packaging_configurations" */
export type Product_Packaging_Configurations_On_Conflict = {
  constraint: Product_Packaging_Configurations_Constraint;
  update_columns?: Array<Product_Packaging_Configurations_Update_Column>;
  where?: InputMaybe<Product_Packaging_Configurations_Bool_Exp>;
};

/** Ordering options when selecting data from "product_packaging_configurations". */
export type Product_Packaging_Configurations_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  barcodeType?: InputMaybe<Order_By>;
  bestBeforeDays?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerLocation?: InputMaybe<Order_By>;
  customerProductAvailability?: InputMaybe<Customer_Product_Availabilities_Order_By>;
  customerProductAvailabilityId?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  ean?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  itemsPerBox?: InputMaybe<Order_By>;
  itemsPerPallet?: InputMaybe<Order_By>;
  labelName?: InputMaybe<Order_By>;
  leichmehlTemplateType?: InputMaybe<Leichmehl_Template_Types_Order_By>;
  leichmehlTemplateTypeId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tareWeightGrams?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_packaging_configurations */
export type Product_Packaging_Configurations_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Product_Packaging_Configurations_Prepend_Input = {
  labelName?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "product_packaging_configurations" */
export enum Product_Packaging_Configurations_Select_Column {
  /** column name */
  BarcodeType = 'barcodeType',
  /** column name */
  BestBeforeDays = 'bestBeforeDays',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerLocation = 'customerLocation',
  /** column name */
  CustomerProductAvailabilityId = 'customerProductAvailabilityId',
  /** column name */
  Description = 'description',
  /** column name */
  Ean = 'ean',
  /** column name */
  Id = 'id',
  /** column name */
  ItemsPerBox = 'itemsPerBox',
  /** column name */
  ItemsPerPallet = 'itemsPerPallet',
  /** column name */
  LabelName = 'labelName',
  /** column name */
  LeichmehlTemplateTypeId = 'leichmehlTemplateTypeId',
  /** column name */
  Name = 'name',
  /** column name */
  TareWeightGrams = 'tareWeightGrams',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "product_packaging_configurations" */
export type Product_Packaging_Configurations_Set_Input = {
  barcodeType?: InputMaybe<Scalars['bigint']>;
  bestBeforeDays?: InputMaybe<Scalars['bigint']>;
  customerLocation?: InputMaybe<Scalars['String']>;
  customerProductAvailabilityId?: InputMaybe<Scalars['bigint']>;
  description?: InputMaybe<Scalars['String']>;
  ean?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  itemsPerBox?: InputMaybe<Scalars['bigint']>;
  itemsPerPallet?: InputMaybe<Scalars['bigint']>;
  labelName?: InputMaybe<Scalars['jsonb']>;
  leichmehlTemplateTypeId?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  tareWeightGrams?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Product_Packaging_Configurations_Stddev_Fields = {
  __typename: 'product_packaging_configurations_stddev_fields';
  barcodeType?: Maybe<Scalars['Float']>;
  bestBeforeDays?: Maybe<Scalars['Float']>;
  customerProductAvailabilityId?: Maybe<Scalars['Float']>;
  ean?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  itemsPerBox?: Maybe<Scalars['Float']>;
  itemsPerPallet?: Maybe<Scalars['Float']>;
  leichmehlTemplateTypeId?: Maybe<Scalars['Float']>;
  tareWeightGrams?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_packaging_configurations" */
export type Product_Packaging_Configurations_Stddev_Order_By = {
  barcodeType?: InputMaybe<Order_By>;
  bestBeforeDays?: InputMaybe<Order_By>;
  customerProductAvailabilityId?: InputMaybe<Order_By>;
  ean?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  itemsPerBox?: InputMaybe<Order_By>;
  itemsPerPallet?: InputMaybe<Order_By>;
  leichmehlTemplateTypeId?: InputMaybe<Order_By>;
  tareWeightGrams?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Packaging_Configurations_Stddev_Pop_Fields = {
  __typename: 'product_packaging_configurations_stddev_pop_fields';
  barcodeType?: Maybe<Scalars['Float']>;
  bestBeforeDays?: Maybe<Scalars['Float']>;
  customerProductAvailabilityId?: Maybe<Scalars['Float']>;
  ean?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  itemsPerBox?: Maybe<Scalars['Float']>;
  itemsPerPallet?: Maybe<Scalars['Float']>;
  leichmehlTemplateTypeId?: Maybe<Scalars['Float']>;
  tareWeightGrams?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_packaging_configurations" */
export type Product_Packaging_Configurations_Stddev_Pop_Order_By = {
  barcodeType?: InputMaybe<Order_By>;
  bestBeforeDays?: InputMaybe<Order_By>;
  customerProductAvailabilityId?: InputMaybe<Order_By>;
  ean?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  itemsPerBox?: InputMaybe<Order_By>;
  itemsPerPallet?: InputMaybe<Order_By>;
  leichmehlTemplateTypeId?: InputMaybe<Order_By>;
  tareWeightGrams?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Packaging_Configurations_Stddev_Samp_Fields = {
  __typename: 'product_packaging_configurations_stddev_samp_fields';
  barcodeType?: Maybe<Scalars['Float']>;
  bestBeforeDays?: Maybe<Scalars['Float']>;
  customerProductAvailabilityId?: Maybe<Scalars['Float']>;
  ean?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  itemsPerBox?: Maybe<Scalars['Float']>;
  itemsPerPallet?: Maybe<Scalars['Float']>;
  leichmehlTemplateTypeId?: Maybe<Scalars['Float']>;
  tareWeightGrams?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_packaging_configurations" */
export type Product_Packaging_Configurations_Stddev_Samp_Order_By = {
  barcodeType?: InputMaybe<Order_By>;
  bestBeforeDays?: InputMaybe<Order_By>;
  customerProductAvailabilityId?: InputMaybe<Order_By>;
  ean?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  itemsPerBox?: InputMaybe<Order_By>;
  itemsPerPallet?: InputMaybe<Order_By>;
  leichmehlTemplateTypeId?: InputMaybe<Order_By>;
  tareWeightGrams?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "product_packaging_configurations" */
export type Product_Packaging_Configurations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Packaging_Configurations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Packaging_Configurations_Stream_Cursor_Value_Input = {
  barcodeType?: InputMaybe<Scalars['bigint']>;
  bestBeforeDays?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerLocation?: InputMaybe<Scalars['String']>;
  customerProductAvailabilityId?: InputMaybe<Scalars['bigint']>;
  description?: InputMaybe<Scalars['String']>;
  ean?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  itemsPerBox?: InputMaybe<Scalars['bigint']>;
  itemsPerPallet?: InputMaybe<Scalars['bigint']>;
  labelName?: InputMaybe<Scalars['jsonb']>;
  leichmehlTemplateTypeId?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  tareWeightGrams?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Product_Packaging_Configurations_Sum_Fields = {
  __typename: 'product_packaging_configurations_sum_fields';
  barcodeType?: Maybe<Scalars['bigint']>;
  bestBeforeDays?: Maybe<Scalars['bigint']>;
  customerProductAvailabilityId?: Maybe<Scalars['bigint']>;
  ean?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  itemsPerBox?: Maybe<Scalars['bigint']>;
  itemsPerPallet?: Maybe<Scalars['bigint']>;
  leichmehlTemplateTypeId?: Maybe<Scalars['bigint']>;
  tareWeightGrams?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product_packaging_configurations" */
export type Product_Packaging_Configurations_Sum_Order_By = {
  barcodeType?: InputMaybe<Order_By>;
  bestBeforeDays?: InputMaybe<Order_By>;
  customerProductAvailabilityId?: InputMaybe<Order_By>;
  ean?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  itemsPerBox?: InputMaybe<Order_By>;
  itemsPerPallet?: InputMaybe<Order_By>;
  leichmehlTemplateTypeId?: InputMaybe<Order_By>;
  tareWeightGrams?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "product_packaging_configurations" */
export enum Product_Packaging_Configurations_Update_Column {
  /** column name */
  BarcodeType = 'barcodeType',
  /** column name */
  BestBeforeDays = 'bestBeforeDays',
  /** column name */
  CustomerLocation = 'customerLocation',
  /** column name */
  CustomerProductAvailabilityId = 'customerProductAvailabilityId',
  /** column name */
  Description = 'description',
  /** column name */
  Ean = 'ean',
  /** column name */
  Id = 'id',
  /** column name */
  ItemsPerBox = 'itemsPerBox',
  /** column name */
  ItemsPerPallet = 'itemsPerPallet',
  /** column name */
  LabelName = 'labelName',
  /** column name */
  LeichmehlTemplateTypeId = 'leichmehlTemplateTypeId',
  /** column name */
  Name = 'name',
  /** column name */
  TareWeightGrams = 'tareWeightGrams',
  /** column name */
  Version = 'version'
}

export type Product_Packaging_Configurations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Product_Packaging_Configurations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Product_Packaging_Configurations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Product_Packaging_Configurations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Product_Packaging_Configurations_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Packaging_Configurations_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Product_Packaging_Configurations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Packaging_Configurations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Packaging_Configurations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Packaging_Configurations_Var_Pop_Fields = {
  __typename: 'product_packaging_configurations_var_pop_fields';
  barcodeType?: Maybe<Scalars['Float']>;
  bestBeforeDays?: Maybe<Scalars['Float']>;
  customerProductAvailabilityId?: Maybe<Scalars['Float']>;
  ean?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  itemsPerBox?: Maybe<Scalars['Float']>;
  itemsPerPallet?: Maybe<Scalars['Float']>;
  leichmehlTemplateTypeId?: Maybe<Scalars['Float']>;
  tareWeightGrams?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_packaging_configurations" */
export type Product_Packaging_Configurations_Var_Pop_Order_By = {
  barcodeType?: InputMaybe<Order_By>;
  bestBeforeDays?: InputMaybe<Order_By>;
  customerProductAvailabilityId?: InputMaybe<Order_By>;
  ean?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  itemsPerBox?: InputMaybe<Order_By>;
  itemsPerPallet?: InputMaybe<Order_By>;
  leichmehlTemplateTypeId?: InputMaybe<Order_By>;
  tareWeightGrams?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Packaging_Configurations_Var_Samp_Fields = {
  __typename: 'product_packaging_configurations_var_samp_fields';
  barcodeType?: Maybe<Scalars['Float']>;
  bestBeforeDays?: Maybe<Scalars['Float']>;
  customerProductAvailabilityId?: Maybe<Scalars['Float']>;
  ean?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  itemsPerBox?: Maybe<Scalars['Float']>;
  itemsPerPallet?: Maybe<Scalars['Float']>;
  leichmehlTemplateTypeId?: Maybe<Scalars['Float']>;
  tareWeightGrams?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_packaging_configurations" */
export type Product_Packaging_Configurations_Var_Samp_Order_By = {
  barcodeType?: InputMaybe<Order_By>;
  bestBeforeDays?: InputMaybe<Order_By>;
  customerProductAvailabilityId?: InputMaybe<Order_By>;
  ean?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  itemsPerBox?: InputMaybe<Order_By>;
  itemsPerPallet?: InputMaybe<Order_By>;
  leichmehlTemplateTypeId?: InputMaybe<Order_By>;
  tareWeightGrams?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Packaging_Configurations_Variance_Fields = {
  __typename: 'product_packaging_configurations_variance_fields';
  barcodeType?: Maybe<Scalars['Float']>;
  bestBeforeDays?: Maybe<Scalars['Float']>;
  customerProductAvailabilityId?: Maybe<Scalars['Float']>;
  ean?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  itemsPerBox?: Maybe<Scalars['Float']>;
  itemsPerPallet?: Maybe<Scalars['Float']>;
  leichmehlTemplateTypeId?: Maybe<Scalars['Float']>;
  tareWeightGrams?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_packaging_configurations" */
export type Product_Packaging_Configurations_Variance_Order_By = {
  barcodeType?: InputMaybe<Order_By>;
  bestBeforeDays?: InputMaybe<Order_By>;
  customerProductAvailabilityId?: InputMaybe<Order_By>;
  ean?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  itemsPerBox?: InputMaybe<Order_By>;
  itemsPerPallet?: InputMaybe<Order_By>;
  leichmehlTemplateTypeId?: InputMaybe<Order_By>;
  tareWeightGrams?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "product_recipe_ingredients" */
export type Product_Recipe_Ingredients = {
  __typename: 'product_recipe_ingredients';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  /** An object relationship */
  productRecipe: Product_Recipes;
  productRecipeId: Scalars['bigint'];
  /** An object relationship */
  productUnit: Product_Units;
  productUnitId: Scalars['bigint'];
  quantity: Scalars['bigint'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};

/** aggregated selection of "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_Aggregate = {
  __typename: 'product_recipe_ingredients_aggregate';
  aggregate?: Maybe<Product_Recipe_Ingredients_Aggregate_Fields>;
  nodes: Array<Product_Recipe_Ingredients>;
};

export type Product_Recipe_Ingredients_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Recipe_Ingredients_Aggregate_Bool_Exp_Count>;
};

export type Product_Recipe_Ingredients_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Recipe_Ingredients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Product_Recipe_Ingredients_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_Aggregate_Fields = {
  __typename: 'product_recipe_ingredients_aggregate_fields';
  avg?: Maybe<Product_Recipe_Ingredients_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Recipe_Ingredients_Max_Fields>;
  min?: Maybe<Product_Recipe_Ingredients_Min_Fields>;
  stddev?: Maybe<Product_Recipe_Ingredients_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Recipe_Ingredients_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Recipe_Ingredients_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Recipe_Ingredients_Sum_Fields>;
  var_pop?: Maybe<Product_Recipe_Ingredients_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Recipe_Ingredients_Var_Samp_Fields>;
  variance?: Maybe<Product_Recipe_Ingredients_Variance_Fields>;
};


/** aggregate fields of "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Recipe_Ingredients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Recipe_Ingredients_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Recipe_Ingredients_Max_Order_By>;
  min?: InputMaybe<Product_Recipe_Ingredients_Min_Order_By>;
  stddev?: InputMaybe<Product_Recipe_Ingredients_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Recipe_Ingredients_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Recipe_Ingredients_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Recipe_Ingredients_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Recipe_Ingredients_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Recipe_Ingredients_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Recipe_Ingredients_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_Arr_Rel_Insert_Input = {
  data: Array<Product_Recipe_Ingredients_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Recipe_Ingredients_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Recipe_Ingredients_Avg_Fields = {
  __typename: 'product_recipe_ingredients_avg_fields';
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productRecipeId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_recipe_ingredients". All fields are combined with a logical 'AND'. */
export type Product_Recipe_Ingredients_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Product_Recipe_Ingredients_Bool_Exp>>;
  _not?: InputMaybe<Product_Recipe_Ingredients_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Recipe_Ingredients_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  productRecipe?: InputMaybe<Product_Recipes_Bool_Exp>;
  productRecipeId?: InputMaybe<Bigint_Comparison_Exp>;
  productUnit?: InputMaybe<Product_Units_Bool_Exp>;
  productUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  quantity?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_recipe_ingredients" */
export enum Product_Recipe_Ingredients_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductRecipeIngredientsPkey = 'product_recipe_ingredients_pkey',
  /** unique or primary key constraint on columns "product_id", "product_recipe_id" */
  ProductRecipeIngredientsProductRecipeIdProductIdKey = 'product_recipe_ingredients_product_recipe_id_product_id_key'
}

/** input type for incrementing numeric columns in table "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  productRecipeId?: InputMaybe<Scalars['bigint']>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  productRecipe?: InputMaybe<Product_Recipes_Obj_Rel_Insert_Input>;
  productRecipeId?: InputMaybe<Scalars['bigint']>;
  productUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Product_Recipe_Ingredients_Max_Fields = {
  __typename: 'product_recipe_ingredients_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  productRecipeId?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Recipe_Ingredients_Min_Fields = {
  __typename: 'product_recipe_ingredients_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  productRecipeId?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_Mutation_Response = {
  __typename: 'product_recipe_ingredients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Recipe_Ingredients>;
};

/** on_conflict condition type for table "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_On_Conflict = {
  constraint: Product_Recipe_Ingredients_Constraint;
  update_columns?: Array<Product_Recipe_Ingredients_Update_Column>;
  where?: InputMaybe<Product_Recipe_Ingredients_Bool_Exp>;
};

/** Ordering options when selecting data from "product_recipe_ingredients". */
export type Product_Recipe_Ingredients_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  productRecipe?: InputMaybe<Product_Recipes_Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  productUnit?: InputMaybe<Product_Units_Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_recipe_ingredients */
export type Product_Recipe_Ingredients_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "product_recipe_ingredients" */
export enum Product_Recipe_Ingredients_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ProductRecipeId = 'productRecipeId',
  /** column name */
  ProductUnitId = 'productUnitId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  productRecipeId?: InputMaybe<Scalars['bigint']>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Product_Recipe_Ingredients_Stddev_Fields = {
  __typename: 'product_recipe_ingredients_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productRecipeId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Recipe_Ingredients_Stddev_Pop_Fields = {
  __typename: 'product_recipe_ingredients_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productRecipeId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Recipe_Ingredients_Stddev_Samp_Fields = {
  __typename: 'product_recipe_ingredients_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productRecipeId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Recipe_Ingredients_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Recipe_Ingredients_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  productRecipeId?: InputMaybe<Scalars['bigint']>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Product_Recipe_Ingredients_Sum_Fields = {
  __typename: 'product_recipe_ingredients_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  productRecipeId?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "product_recipe_ingredients" */
export enum Product_Recipe_Ingredients_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ProductRecipeId = 'productRecipeId',
  /** column name */
  ProductUnitId = 'productUnitId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Version = 'version'
}

export type Product_Recipe_Ingredients_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Recipe_Ingredients_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Recipe_Ingredients_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Recipe_Ingredients_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Recipe_Ingredients_Var_Pop_Fields = {
  __typename: 'product_recipe_ingredients_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productRecipeId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Recipe_Ingredients_Var_Samp_Fields = {
  __typename: 'product_recipe_ingredients_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productRecipeId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Recipe_Ingredients_Variance_Fields = {
  __typename: 'product_recipe_ingredients_variance_fields';
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productRecipeId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_recipe_ingredients" */
export type Product_Recipe_Ingredients_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "product_recipe_state_transitions" */
export type Product_Recipe_State_Transitions = {
  __typename: 'product_recipe_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Product_States_Enum;
  toState: Product_States_Enum;
};

/** aggregated selection of "product_recipe_state_transitions" */
export type Product_Recipe_State_Transitions_Aggregate = {
  __typename: 'product_recipe_state_transitions_aggregate';
  aggregate?: Maybe<Product_Recipe_State_Transitions_Aggregate_Fields>;
  nodes: Array<Product_Recipe_State_Transitions>;
};

/** aggregate fields of "product_recipe_state_transitions" */
export type Product_Recipe_State_Transitions_Aggregate_Fields = {
  __typename: 'product_recipe_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_Recipe_State_Transitions_Max_Fields>;
  min?: Maybe<Product_Recipe_State_Transitions_Min_Fields>;
};


/** aggregate fields of "product_recipe_state_transitions" */
export type Product_Recipe_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Recipe_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "product_recipe_state_transitions". All fields are combined with a logical 'AND'. */
export type Product_Recipe_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Recipe_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Product_Recipe_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Recipe_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Product_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Product_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Product_Recipe_State_Transitions_Max_Fields = {
  __typename: 'product_recipe_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Product_Recipe_State_Transitions_Min_Fields = {
  __typename: 'product_recipe_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "product_recipe_state_transitions". */
export type Product_Recipe_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "product_recipe_state_transitions" */
export enum Product_Recipe_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "product_recipe_state_transitions" */
export type Product_Recipe_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Recipe_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Recipe_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Product_States_Enum>;
  toState?: InputMaybe<Product_States_Enum>;
};

/** columns and relationships of "product_recipe_states" */
export type Product_Recipe_States = {
  __typename: 'product_recipe_states';
  /** An array relationship */
  productRecipes: Array<Product_Recipes>;
  /** An aggregate relationship */
  productRecipes_aggregate: Product_Recipes_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "product_recipe_states" */
export type Product_Recipe_StatesProductRecipesArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipes_Order_By>>;
  where?: InputMaybe<Product_Recipes_Bool_Exp>;
};


/** columns and relationships of "product_recipe_states" */
export type Product_Recipe_StatesProductRecipes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipes_Order_By>>;
  where?: InputMaybe<Product_Recipes_Bool_Exp>;
};

/** aggregated selection of "product_recipe_states" */
export type Product_Recipe_States_Aggregate = {
  __typename: 'product_recipe_states_aggregate';
  aggregate?: Maybe<Product_Recipe_States_Aggregate_Fields>;
  nodes: Array<Product_Recipe_States>;
};

/** aggregate fields of "product_recipe_states" */
export type Product_Recipe_States_Aggregate_Fields = {
  __typename: 'product_recipe_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_Recipe_States_Max_Fields>;
  min?: Maybe<Product_Recipe_States_Min_Fields>;
};


/** aggregate fields of "product_recipe_states" */
export type Product_Recipe_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Recipe_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "product_recipe_states". All fields are combined with a logical 'AND'. */
export type Product_Recipe_States_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Recipe_States_Bool_Exp>>;
  _not?: InputMaybe<Product_Recipe_States_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Recipe_States_Bool_Exp>>;
  productRecipes?: InputMaybe<Product_Recipes_Bool_Exp>;
  productRecipes_aggregate?: InputMaybe<Product_Recipes_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Product_Recipe_States_Enum {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE'
}

/** Boolean expression to compare columns of type "product_recipe_states_enum". All fields are combined with logical 'AND'. */
export type Product_Recipe_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Product_Recipe_States_Enum>;
  _in?: InputMaybe<Array<Product_Recipe_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Product_Recipe_States_Enum>;
  _nin?: InputMaybe<Array<Product_Recipe_States_Enum>>;
};

/** aggregate max on columns */
export type Product_Recipe_States_Max_Fields = {
  __typename: 'product_recipe_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Product_Recipe_States_Min_Fields = {
  __typename: 'product_recipe_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "product_recipe_states". */
export type Product_Recipe_States_Order_By = {
  productRecipes_aggregate?: InputMaybe<Product_Recipes_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "product_recipe_states" */
export enum Product_Recipe_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "product_recipe_states" */
export type Product_Recipe_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Recipe_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Recipe_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "product_recipe_steps" */
export type Product_Recipe_Steps = {
  __typename: 'product_recipe_steps';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  machineName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  ordinalPosition: Scalars['Int'];
  /** An object relationship */
  productRecipe: Product_Recipes;
  productRecipeId: Scalars['bigint'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};

/** aggregated selection of "product_recipe_steps" */
export type Product_Recipe_Steps_Aggregate = {
  __typename: 'product_recipe_steps_aggregate';
  aggregate?: Maybe<Product_Recipe_Steps_Aggregate_Fields>;
  nodes: Array<Product_Recipe_Steps>;
};

export type Product_Recipe_Steps_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Recipe_Steps_Aggregate_Bool_Exp_Count>;
};

export type Product_Recipe_Steps_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Recipe_Steps_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Product_Recipe_Steps_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_recipe_steps" */
export type Product_Recipe_Steps_Aggregate_Fields = {
  __typename: 'product_recipe_steps_aggregate_fields';
  avg?: Maybe<Product_Recipe_Steps_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Recipe_Steps_Max_Fields>;
  min?: Maybe<Product_Recipe_Steps_Min_Fields>;
  stddev?: Maybe<Product_Recipe_Steps_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Recipe_Steps_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Recipe_Steps_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Recipe_Steps_Sum_Fields>;
  var_pop?: Maybe<Product_Recipe_Steps_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Recipe_Steps_Var_Samp_Fields>;
  variance?: Maybe<Product_Recipe_Steps_Variance_Fields>;
};


/** aggregate fields of "product_recipe_steps" */
export type Product_Recipe_Steps_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Recipe_Steps_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_recipe_steps" */
export type Product_Recipe_Steps_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Recipe_Steps_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Recipe_Steps_Max_Order_By>;
  min?: InputMaybe<Product_Recipe_Steps_Min_Order_By>;
  stddev?: InputMaybe<Product_Recipe_Steps_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Recipe_Steps_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Recipe_Steps_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Recipe_Steps_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Recipe_Steps_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Recipe_Steps_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Recipe_Steps_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_recipe_steps" */
export type Product_Recipe_Steps_Arr_Rel_Insert_Input = {
  data: Array<Product_Recipe_Steps_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Recipe_Steps_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Recipe_Steps_Avg_Fields = {
  __typename: 'product_recipe_steps_avg_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productRecipeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_recipe_steps" */
export type Product_Recipe_Steps_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_recipe_steps". All fields are combined with a logical 'AND'. */
export type Product_Recipe_Steps_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Product_Recipe_Steps_Bool_Exp>>;
  _not?: InputMaybe<Product_Recipe_Steps_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Recipe_Steps_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  machineName?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  productRecipe?: InputMaybe<Product_Recipes_Bool_Exp>;
  productRecipeId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_recipe_steps" */
export enum Product_Recipe_Steps_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductRecipeStepsPkey = 'product_recipe_steps_pkey',
  /** unique or primary key constraint on columns "ordinal_position", "product_recipe_id" */
  ProductRecipeStepsProductRecipeIdOrdinalPositionKey = 'product_recipe_steps_product_recipe_id_ordinal_position_key'
}

/** input type for incrementing numeric columns in table "product_recipe_steps" */
export type Product_Recipe_Steps_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  productRecipeId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_recipe_steps" */
export type Product_Recipe_Steps_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  machineName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  productRecipe?: InputMaybe<Product_Recipes_Obj_Rel_Insert_Input>;
  productRecipeId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Product_Recipe_Steps_Max_Fields = {
  __typename: 'product_recipe_steps_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  machineName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  productRecipeId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "product_recipe_steps" */
export type Product_Recipe_Steps_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  machineName?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Recipe_Steps_Min_Fields = {
  __typename: 'product_recipe_steps_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  machineName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  productRecipeId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "product_recipe_steps" */
export type Product_Recipe_Steps_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  machineName?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_recipe_steps" */
export type Product_Recipe_Steps_Mutation_Response = {
  __typename: 'product_recipe_steps_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Recipe_Steps>;
};

/** on_conflict condition type for table "product_recipe_steps" */
export type Product_Recipe_Steps_On_Conflict = {
  constraint: Product_Recipe_Steps_Constraint;
  update_columns?: Array<Product_Recipe_Steps_Update_Column>;
  where?: InputMaybe<Product_Recipe_Steps_Bool_Exp>;
};

/** Ordering options when selecting data from "product_recipe_steps". */
export type Product_Recipe_Steps_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  machineName?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productRecipe?: InputMaybe<Product_Recipes_Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_recipe_steps */
export type Product_Recipe_Steps_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "product_recipe_steps" */
export enum Product_Recipe_Steps_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MachineName = 'machineName',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  ProductRecipeId = 'productRecipeId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "product_recipe_steps" */
export type Product_Recipe_Steps_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  machineName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  productRecipeId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Product_Recipe_Steps_Stddev_Fields = {
  __typename: 'product_recipe_steps_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productRecipeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_recipe_steps" */
export type Product_Recipe_Steps_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Recipe_Steps_Stddev_Pop_Fields = {
  __typename: 'product_recipe_steps_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productRecipeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_recipe_steps" */
export type Product_Recipe_Steps_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Recipe_Steps_Stddev_Samp_Fields = {
  __typename: 'product_recipe_steps_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productRecipeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_recipe_steps" */
export type Product_Recipe_Steps_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "product_recipe_steps" */
export type Product_Recipe_Steps_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Recipe_Steps_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Recipe_Steps_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  machineName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  productRecipeId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Product_Recipe_Steps_Sum_Fields = {
  __typename: 'product_recipe_steps_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  productRecipeId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product_recipe_steps" */
export type Product_Recipe_Steps_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "product_recipe_steps" */
export enum Product_Recipe_Steps_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  MachineName = 'machineName',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  ProductRecipeId = 'productRecipeId',
  /** column name */
  Version = 'version'
}

export type Product_Recipe_Steps_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Recipe_Steps_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Recipe_Steps_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Recipe_Steps_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Recipe_Steps_Var_Pop_Fields = {
  __typename: 'product_recipe_steps_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productRecipeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_recipe_steps" */
export type Product_Recipe_Steps_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Recipe_Steps_Var_Samp_Fields = {
  __typename: 'product_recipe_steps_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productRecipeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_recipe_steps" */
export type Product_Recipe_Steps_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Recipe_Steps_Variance_Fields = {
  __typename: 'product_recipe_steps_variance_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productRecipeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_recipe_steps" */
export type Product_Recipe_Steps_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productRecipeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "product_recipes" */
export type Product_Recipes = {
  __typename: 'product_recipes';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  /** An array relationship */
  productRecipeIngredients: Array<Product_Recipe_Ingredients>;
  /** An aggregate relationship */
  productRecipeIngredients_aggregate: Product_Recipe_Ingredients_Aggregate;
  /** An array relationship */
  productRecipeSteps: Array<Product_Recipe_Steps>;
  /** An aggregate relationship */
  productRecipeSteps_aggregate: Product_Recipe_Steps_Aggregate;
  /** An object relationship */
  productUnit: Product_Units;
  productUnitId: Scalars['bigint'];
  productionDurationMs?: Maybe<Scalars['bigint']>;
  productionEmployeeCostCurrency?: Maybe<Currencies_Enum>;
  productionEmployeeCostPerHour?: Maybe<Scalars['numeric']>;
  state: Product_Recipe_States_Enum;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "product_recipes" */
export type Product_RecipesProductRecipeIngredientsArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_Ingredients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_Ingredients_Order_By>>;
  where?: InputMaybe<Product_Recipe_Ingredients_Bool_Exp>;
};


/** columns and relationships of "product_recipes" */
export type Product_RecipesProductRecipeIngredients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_Ingredients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_Ingredients_Order_By>>;
  where?: InputMaybe<Product_Recipe_Ingredients_Bool_Exp>;
};


/** columns and relationships of "product_recipes" */
export type Product_RecipesProductRecipeStepsArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_Steps_Order_By>>;
  where?: InputMaybe<Product_Recipe_Steps_Bool_Exp>;
};


/** columns and relationships of "product_recipes" */
export type Product_RecipesProductRecipeSteps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_Steps_Order_By>>;
  where?: InputMaybe<Product_Recipe_Steps_Bool_Exp>;
};

/** aggregated selection of "product_recipes" */
export type Product_Recipes_Aggregate = {
  __typename: 'product_recipes_aggregate';
  aggregate?: Maybe<Product_Recipes_Aggregate_Fields>;
  nodes: Array<Product_Recipes>;
};

export type Product_Recipes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Recipes_Aggregate_Bool_Exp_Count>;
};

export type Product_Recipes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Recipes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Product_Recipes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_recipes" */
export type Product_Recipes_Aggregate_Fields = {
  __typename: 'product_recipes_aggregate_fields';
  avg?: Maybe<Product_Recipes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Recipes_Max_Fields>;
  min?: Maybe<Product_Recipes_Min_Fields>;
  stddev?: Maybe<Product_Recipes_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Recipes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Recipes_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Recipes_Sum_Fields>;
  var_pop?: Maybe<Product_Recipes_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Recipes_Var_Samp_Fields>;
  variance?: Maybe<Product_Recipes_Variance_Fields>;
};


/** aggregate fields of "product_recipes" */
export type Product_Recipes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Recipes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_recipes" */
export type Product_Recipes_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Recipes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Recipes_Max_Order_By>;
  min?: InputMaybe<Product_Recipes_Min_Order_By>;
  stddev?: InputMaybe<Product_Recipes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Recipes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Recipes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Recipes_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Recipes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Recipes_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Recipes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_recipes" */
export type Product_Recipes_Arr_Rel_Insert_Input = {
  data: Array<Product_Recipes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Recipes_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Recipes_Avg_Fields = {
  __typename: 'product_recipes_avg_fields';
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  productionDurationMs?: Maybe<Scalars['Float']>;
  productionEmployeeCostPerHour?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_recipes" */
export type Product_Recipes_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  productionDurationMs?: InputMaybe<Order_By>;
  productionEmployeeCostPerHour?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_recipes". All fields are combined with a logical 'AND'. */
export type Product_Recipes_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Product_Recipes_Bool_Exp>>;
  _not?: InputMaybe<Product_Recipes_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Recipes_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  productRecipeIngredients?: InputMaybe<Product_Recipe_Ingredients_Bool_Exp>;
  productRecipeIngredients_aggregate?: InputMaybe<Product_Recipe_Ingredients_Aggregate_Bool_Exp>;
  productRecipeSteps?: InputMaybe<Product_Recipe_Steps_Bool_Exp>;
  productRecipeSteps_aggregate?: InputMaybe<Product_Recipe_Steps_Aggregate_Bool_Exp>;
  productUnit?: InputMaybe<Product_Units_Bool_Exp>;
  productUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  productionDurationMs?: InputMaybe<Bigint_Comparison_Exp>;
  productionEmployeeCostCurrency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  productionEmployeeCostPerHour?: InputMaybe<Numeric_Comparison_Exp>;
  state?: InputMaybe<Product_Recipe_States_Enum_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_recipes" */
export enum Product_Recipes_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductRecipesPkey = 'product_recipes_pkey'
}

/** input type for incrementing numeric columns in table "product_recipes" */
export type Product_Recipes_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  productionDurationMs?: InputMaybe<Scalars['bigint']>;
  productionEmployeeCostPerHour?: InputMaybe<Scalars['numeric']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_recipes" */
export type Product_Recipes_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  productRecipeIngredients?: InputMaybe<Product_Recipe_Ingredients_Arr_Rel_Insert_Input>;
  productRecipeSteps?: InputMaybe<Product_Recipe_Steps_Arr_Rel_Insert_Input>;
  productUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  productionDurationMs?: InputMaybe<Scalars['bigint']>;
  productionEmployeeCostCurrency?: InputMaybe<Currencies_Enum>;
  productionEmployeeCostPerHour?: InputMaybe<Scalars['numeric']>;
  state?: InputMaybe<Product_Recipe_States_Enum>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Product_Recipes_Max_Fields = {
  __typename: 'product_recipes_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  productionDurationMs?: Maybe<Scalars['bigint']>;
  productionEmployeeCostPerHour?: Maybe<Scalars['numeric']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "product_recipes" */
export type Product_Recipes_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  productionDurationMs?: InputMaybe<Order_By>;
  productionEmployeeCostPerHour?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Recipes_Min_Fields = {
  __typename: 'product_recipes_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  productionDurationMs?: Maybe<Scalars['bigint']>;
  productionEmployeeCostPerHour?: Maybe<Scalars['numeric']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "product_recipes" */
export type Product_Recipes_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  productionDurationMs?: InputMaybe<Order_By>;
  productionEmployeeCostPerHour?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_recipes" */
export type Product_Recipes_Mutation_Response = {
  __typename: 'product_recipes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Recipes>;
};

/** input type for inserting object relation for remote table "product_recipes" */
export type Product_Recipes_Obj_Rel_Insert_Input = {
  data: Product_Recipes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Recipes_On_Conflict>;
};

/** on_conflict condition type for table "product_recipes" */
export type Product_Recipes_On_Conflict = {
  constraint: Product_Recipes_Constraint;
  update_columns?: Array<Product_Recipes_Update_Column>;
  where?: InputMaybe<Product_Recipes_Bool_Exp>;
};

/** Ordering options when selecting data from "product_recipes". */
export type Product_Recipes_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  productRecipeIngredients_aggregate?: InputMaybe<Product_Recipe_Ingredients_Aggregate_Order_By>;
  productRecipeSteps_aggregate?: InputMaybe<Product_Recipe_Steps_Aggregate_Order_By>;
  productUnit?: InputMaybe<Product_Units_Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  productionDurationMs?: InputMaybe<Order_By>;
  productionEmployeeCostCurrency?: InputMaybe<Order_By>;
  productionEmployeeCostPerHour?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_recipes */
export type Product_Recipes_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "product_recipes" */
export enum Product_Recipes_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ProductUnitId = 'productUnitId',
  /** column name */
  ProductionDurationMs = 'productionDurationMs',
  /** column name */
  ProductionEmployeeCostCurrency = 'productionEmployeeCostCurrency',
  /** column name */
  ProductionEmployeeCostPerHour = 'productionEmployeeCostPerHour',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "product_recipes" */
export type Product_Recipes_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  productionDurationMs?: InputMaybe<Scalars['bigint']>;
  productionEmployeeCostCurrency?: InputMaybe<Currencies_Enum>;
  productionEmployeeCostPerHour?: InputMaybe<Scalars['numeric']>;
  state?: InputMaybe<Product_Recipe_States_Enum>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Product_Recipes_Stddev_Fields = {
  __typename: 'product_recipes_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  productionDurationMs?: Maybe<Scalars['Float']>;
  productionEmployeeCostPerHour?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_recipes" */
export type Product_Recipes_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  productionDurationMs?: InputMaybe<Order_By>;
  productionEmployeeCostPerHour?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Recipes_Stddev_Pop_Fields = {
  __typename: 'product_recipes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  productionDurationMs?: Maybe<Scalars['Float']>;
  productionEmployeeCostPerHour?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_recipes" */
export type Product_Recipes_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  productionDurationMs?: InputMaybe<Order_By>;
  productionEmployeeCostPerHour?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Recipes_Stddev_Samp_Fields = {
  __typename: 'product_recipes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  productionDurationMs?: Maybe<Scalars['Float']>;
  productionEmployeeCostPerHour?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_recipes" */
export type Product_Recipes_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  productionDurationMs?: InputMaybe<Order_By>;
  productionEmployeeCostPerHour?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "product_recipes" */
export type Product_Recipes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Recipes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Recipes_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  productUnitId?: InputMaybe<Scalars['bigint']>;
  productionDurationMs?: InputMaybe<Scalars['bigint']>;
  productionEmployeeCostCurrency?: InputMaybe<Currencies_Enum>;
  productionEmployeeCostPerHour?: InputMaybe<Scalars['numeric']>;
  state?: InputMaybe<Product_Recipe_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Product_Recipes_Sum_Fields = {
  __typename: 'product_recipes_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  productUnitId?: Maybe<Scalars['bigint']>;
  productionDurationMs?: Maybe<Scalars['bigint']>;
  productionEmployeeCostPerHour?: Maybe<Scalars['numeric']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product_recipes" */
export type Product_Recipes_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  productionDurationMs?: InputMaybe<Order_By>;
  productionEmployeeCostPerHour?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "product_recipes" */
export enum Product_Recipes_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ProductUnitId = 'productUnitId',
  /** column name */
  ProductionDurationMs = 'productionDurationMs',
  /** column name */
  ProductionEmployeeCostCurrency = 'productionEmployeeCostCurrency',
  /** column name */
  ProductionEmployeeCostPerHour = 'productionEmployeeCostPerHour',
  /** column name */
  State = 'state',
  /** column name */
  Version = 'version'
}

export type Product_Recipes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Recipes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Recipes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Recipes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Recipes_Var_Pop_Fields = {
  __typename: 'product_recipes_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  productionDurationMs?: Maybe<Scalars['Float']>;
  productionEmployeeCostPerHour?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_recipes" */
export type Product_Recipes_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  productionDurationMs?: InputMaybe<Order_By>;
  productionEmployeeCostPerHour?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Recipes_Var_Samp_Fields = {
  __typename: 'product_recipes_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  productionDurationMs?: Maybe<Scalars['Float']>;
  productionEmployeeCostPerHour?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_recipes" */
export type Product_Recipes_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  productionDurationMs?: InputMaybe<Order_By>;
  productionEmployeeCostPerHour?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Recipes_Variance_Fields = {
  __typename: 'product_recipes_variance_fields';
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  productUnitId?: Maybe<Scalars['Float']>;
  productionDurationMs?: Maybe<Scalars['Float']>;
  productionEmployeeCostPerHour?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_recipes" */
export type Product_Recipes_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitId?: InputMaybe<Order_By>;
  productionDurationMs?: InputMaybe<Order_By>;
  productionEmployeeCostPerHour?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "product_s3_objects" */
export type Product_S3_Objects = {
  __typename: 'product_s3_objects';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  /** An object relationship */
  s3Object: S3_Objects;
  s3ObjectId: Scalars['bigint'];
  tenant: Scalars['String'];
};

/** aggregated selection of "product_s3_objects" */
export type Product_S3_Objects_Aggregate = {
  __typename: 'product_s3_objects_aggregate';
  aggregate?: Maybe<Product_S3_Objects_Aggregate_Fields>;
  nodes: Array<Product_S3_Objects>;
};

export type Product_S3_Objects_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_S3_Objects_Aggregate_Bool_Exp_Count>;
};

export type Product_S3_Objects_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_S3_Objects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Product_S3_Objects_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_s3_objects" */
export type Product_S3_Objects_Aggregate_Fields = {
  __typename: 'product_s3_objects_aggregate_fields';
  avg?: Maybe<Product_S3_Objects_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_S3_Objects_Max_Fields>;
  min?: Maybe<Product_S3_Objects_Min_Fields>;
  stddev?: Maybe<Product_S3_Objects_Stddev_Fields>;
  stddev_pop?: Maybe<Product_S3_Objects_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_S3_Objects_Stddev_Samp_Fields>;
  sum?: Maybe<Product_S3_Objects_Sum_Fields>;
  var_pop?: Maybe<Product_S3_Objects_Var_Pop_Fields>;
  var_samp?: Maybe<Product_S3_Objects_Var_Samp_Fields>;
  variance?: Maybe<Product_S3_Objects_Variance_Fields>;
};


/** aggregate fields of "product_s3_objects" */
export type Product_S3_Objects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_S3_Objects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_s3_objects" */
export type Product_S3_Objects_Aggregate_Order_By = {
  avg?: InputMaybe<Product_S3_Objects_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_S3_Objects_Max_Order_By>;
  min?: InputMaybe<Product_S3_Objects_Min_Order_By>;
  stddev?: InputMaybe<Product_S3_Objects_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_S3_Objects_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_S3_Objects_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_S3_Objects_Sum_Order_By>;
  var_pop?: InputMaybe<Product_S3_Objects_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_S3_Objects_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_S3_Objects_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_s3_objects" */
export type Product_S3_Objects_Arr_Rel_Insert_Input = {
  data: Array<Product_S3_Objects_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_S3_Objects_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_S3_Objects_Avg_Fields = {
  __typename: 'product_s3_objects_avg_fields';
  productId?: Maybe<Scalars['Float']>;
  s3ObjectId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_s3_objects" */
export type Product_S3_Objects_Avg_Order_By = {
  productId?: InputMaybe<Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_s3_objects". All fields are combined with a logical 'AND'. */
export type Product_S3_Objects_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Product_S3_Objects_Bool_Exp>>;
  _not?: InputMaybe<Product_S3_Objects_Bool_Exp>;
  _or?: InputMaybe<Array<Product_S3_Objects_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  s3Object?: InputMaybe<S3_Objects_Bool_Exp>;
  s3ObjectId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_s3_objects" */
export enum Product_S3_Objects_Constraint {
  /** unique or primary key constraint on columns "s3_object_id", "product_id" */
  ProductS3ObjectsPkey = 'product_s3_objects_pkey'
}

/** input type for incrementing numeric columns in table "product_s3_objects" */
export type Product_S3_Objects_Inc_Input = {
  productId?: InputMaybe<Scalars['bigint']>;
  s3ObjectId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_s3_objects" */
export type Product_S3_Objects_Insert_Input = {
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  s3Object?: InputMaybe<S3_Objects_Obj_Rel_Insert_Input>;
  s3ObjectId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Product_S3_Objects_Max_Fields = {
  __typename: 'product_s3_objects_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  productId?: Maybe<Scalars['bigint']>;
  s3ObjectId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "product_s3_objects" */
export type Product_S3_Objects_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_S3_Objects_Min_Fields = {
  __typename: 'product_s3_objects_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  productId?: Maybe<Scalars['bigint']>;
  s3ObjectId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "product_s3_objects" */
export type Product_S3_Objects_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_s3_objects" */
export type Product_S3_Objects_Mutation_Response = {
  __typename: 'product_s3_objects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_S3_Objects>;
};

/** on_conflict condition type for table "product_s3_objects" */
export type Product_S3_Objects_On_Conflict = {
  constraint: Product_S3_Objects_Constraint;
  update_columns?: Array<Product_S3_Objects_Update_Column>;
  where?: InputMaybe<Product_S3_Objects_Bool_Exp>;
};

/** Ordering options when selecting data from "product_s3_objects". */
export type Product_S3_Objects_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  s3Object?: InputMaybe<S3_Objects_Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_s3_objects */
export type Product_S3_Objects_Pk_Columns_Input = {
  productId: Scalars['bigint'];
  s3ObjectId: Scalars['bigint'];
};

/** select columns of table "product_s3_objects" */
export enum Product_S3_Objects_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ProductId = 'productId',
  /** column name */
  S3ObjectId = 's3ObjectId',
  /** column name */
  Tenant = 'tenant'
}

/** input type for updating data in table "product_s3_objects" */
export type Product_S3_Objects_Set_Input = {
  productId?: InputMaybe<Scalars['bigint']>;
  s3ObjectId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Product_S3_Objects_Stddev_Fields = {
  __typename: 'product_s3_objects_stddev_fields';
  productId?: Maybe<Scalars['Float']>;
  s3ObjectId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_s3_objects" */
export type Product_S3_Objects_Stddev_Order_By = {
  productId?: InputMaybe<Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_S3_Objects_Stddev_Pop_Fields = {
  __typename: 'product_s3_objects_stddev_pop_fields';
  productId?: Maybe<Scalars['Float']>;
  s3ObjectId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_s3_objects" */
export type Product_S3_Objects_Stddev_Pop_Order_By = {
  productId?: InputMaybe<Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_S3_Objects_Stddev_Samp_Fields = {
  __typename: 'product_s3_objects_stddev_samp_fields';
  productId?: Maybe<Scalars['Float']>;
  s3ObjectId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_s3_objects" */
export type Product_S3_Objects_Stddev_Samp_Order_By = {
  productId?: InputMaybe<Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "product_s3_objects" */
export type Product_S3_Objects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_S3_Objects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_S3_Objects_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  productId?: InputMaybe<Scalars['bigint']>;
  s3ObjectId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Product_S3_Objects_Sum_Fields = {
  __typename: 'product_s3_objects_sum_fields';
  productId?: Maybe<Scalars['bigint']>;
  s3ObjectId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product_s3_objects" */
export type Product_S3_Objects_Sum_Order_By = {
  productId?: InputMaybe<Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
};

/** update columns of table "product_s3_objects" */
export enum Product_S3_Objects_Update_Column {
  /** column name */
  ProductId = 'productId',
  /** column name */
  S3ObjectId = 's3ObjectId'
}

export type Product_S3_Objects_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_S3_Objects_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_S3_Objects_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_S3_Objects_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_S3_Objects_Var_Pop_Fields = {
  __typename: 'product_s3_objects_var_pop_fields';
  productId?: Maybe<Scalars['Float']>;
  s3ObjectId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_s3_objects" */
export type Product_S3_Objects_Var_Pop_Order_By = {
  productId?: InputMaybe<Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_S3_Objects_Var_Samp_Fields = {
  __typename: 'product_s3_objects_var_samp_fields';
  productId?: Maybe<Scalars['Float']>;
  s3ObjectId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_s3_objects" */
export type Product_S3_Objects_Var_Samp_Order_By = {
  productId?: InputMaybe<Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_S3_Objects_Variance_Fields = {
  __typename: 'product_s3_objects_variance_fields';
  productId?: Maybe<Scalars['Float']>;
  s3ObjectId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_s3_objects" */
export type Product_S3_Objects_Variance_Order_By = {
  productId?: InputMaybe<Order_By>;
  s3ObjectId?: InputMaybe<Order_By>;
};

/** columns and relationships of "product_sales_channel_members" */
export type Product_Sales_Channel_Members = {
  __typename: 'product_sales_channel_members';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  /** An object relationship */
  salesChannel: Sales_Channels;
  salesChannelId: Scalars['bigint'];
  tenant: Scalars['String'];
};

/** aggregated selection of "product_sales_channel_members" */
export type Product_Sales_Channel_Members_Aggregate = {
  __typename: 'product_sales_channel_members_aggregate';
  aggregate?: Maybe<Product_Sales_Channel_Members_Aggregate_Fields>;
  nodes: Array<Product_Sales_Channel_Members>;
};

export type Product_Sales_Channel_Members_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Sales_Channel_Members_Aggregate_Bool_Exp_Count>;
};

export type Product_Sales_Channel_Members_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Sales_Channel_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Product_Sales_Channel_Members_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_sales_channel_members" */
export type Product_Sales_Channel_Members_Aggregate_Fields = {
  __typename: 'product_sales_channel_members_aggregate_fields';
  avg?: Maybe<Product_Sales_Channel_Members_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Sales_Channel_Members_Max_Fields>;
  min?: Maybe<Product_Sales_Channel_Members_Min_Fields>;
  stddev?: Maybe<Product_Sales_Channel_Members_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Sales_Channel_Members_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Sales_Channel_Members_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Sales_Channel_Members_Sum_Fields>;
  var_pop?: Maybe<Product_Sales_Channel_Members_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Sales_Channel_Members_Var_Samp_Fields>;
  variance?: Maybe<Product_Sales_Channel_Members_Variance_Fields>;
};


/** aggregate fields of "product_sales_channel_members" */
export type Product_Sales_Channel_Members_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Sales_Channel_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_sales_channel_members" */
export type Product_Sales_Channel_Members_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Sales_Channel_Members_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Sales_Channel_Members_Max_Order_By>;
  min?: InputMaybe<Product_Sales_Channel_Members_Min_Order_By>;
  stddev?: InputMaybe<Product_Sales_Channel_Members_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Sales_Channel_Members_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Sales_Channel_Members_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Sales_Channel_Members_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Sales_Channel_Members_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Sales_Channel_Members_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Sales_Channel_Members_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_sales_channel_members" */
export type Product_Sales_Channel_Members_Arr_Rel_Insert_Input = {
  data: Array<Product_Sales_Channel_Members_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Sales_Channel_Members_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Sales_Channel_Members_Avg_Fields = {
  __typename: 'product_sales_channel_members_avg_fields';
  productId?: Maybe<Scalars['Float']>;
  salesChannelId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_sales_channel_members" */
export type Product_Sales_Channel_Members_Avg_Order_By = {
  productId?: InputMaybe<Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_sales_channel_members". All fields are combined with a logical 'AND'. */
export type Product_Sales_Channel_Members_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Product_Sales_Channel_Members_Bool_Exp>>;
  _not?: InputMaybe<Product_Sales_Channel_Members_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Sales_Channel_Members_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  salesChannel?: InputMaybe<Sales_Channels_Bool_Exp>;
  salesChannelId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_sales_channel_members" */
export enum Product_Sales_Channel_Members_Constraint {
  /** unique or primary key constraint on columns "sales_channel_id", "product_id" */
  ProductSalesChannelMembersPkey = 'product_sales_channel_members_pkey'
}

/** input type for incrementing numeric columns in table "product_sales_channel_members" */
export type Product_Sales_Channel_Members_Inc_Input = {
  productId?: InputMaybe<Scalars['bigint']>;
  salesChannelId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_sales_channel_members" */
export type Product_Sales_Channel_Members_Insert_Input = {
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  salesChannelId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Product_Sales_Channel_Members_Max_Fields = {
  __typename: 'product_sales_channel_members_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  productId?: Maybe<Scalars['bigint']>;
  salesChannelId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "product_sales_channel_members" */
export type Product_Sales_Channel_Members_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Sales_Channel_Members_Min_Fields = {
  __typename: 'product_sales_channel_members_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  productId?: Maybe<Scalars['bigint']>;
  salesChannelId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "product_sales_channel_members" */
export type Product_Sales_Channel_Members_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_sales_channel_members" */
export type Product_Sales_Channel_Members_Mutation_Response = {
  __typename: 'product_sales_channel_members_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Sales_Channel_Members>;
};

/** on_conflict condition type for table "product_sales_channel_members" */
export type Product_Sales_Channel_Members_On_Conflict = {
  constraint: Product_Sales_Channel_Members_Constraint;
  update_columns?: Array<Product_Sales_Channel_Members_Update_Column>;
  where?: InputMaybe<Product_Sales_Channel_Members_Bool_Exp>;
};

/** Ordering options when selecting data from "product_sales_channel_members". */
export type Product_Sales_Channel_Members_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  salesChannel?: InputMaybe<Sales_Channels_Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_sales_channel_members */
export type Product_Sales_Channel_Members_Pk_Columns_Input = {
  productId: Scalars['bigint'];
  salesChannelId: Scalars['bigint'];
};

/** select columns of table "product_sales_channel_members" */
export enum Product_Sales_Channel_Members_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ProductId = 'productId',
  /** column name */
  SalesChannelId = 'salesChannelId',
  /** column name */
  Tenant = 'tenant'
}

/** input type for updating data in table "product_sales_channel_members" */
export type Product_Sales_Channel_Members_Set_Input = {
  productId?: InputMaybe<Scalars['bigint']>;
  salesChannelId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Product_Sales_Channel_Members_Stddev_Fields = {
  __typename: 'product_sales_channel_members_stddev_fields';
  productId?: Maybe<Scalars['Float']>;
  salesChannelId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_sales_channel_members" */
export type Product_Sales_Channel_Members_Stddev_Order_By = {
  productId?: InputMaybe<Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Sales_Channel_Members_Stddev_Pop_Fields = {
  __typename: 'product_sales_channel_members_stddev_pop_fields';
  productId?: Maybe<Scalars['Float']>;
  salesChannelId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_sales_channel_members" */
export type Product_Sales_Channel_Members_Stddev_Pop_Order_By = {
  productId?: InputMaybe<Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Sales_Channel_Members_Stddev_Samp_Fields = {
  __typename: 'product_sales_channel_members_stddev_samp_fields';
  productId?: Maybe<Scalars['Float']>;
  salesChannelId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_sales_channel_members" */
export type Product_Sales_Channel_Members_Stddev_Samp_Order_By = {
  productId?: InputMaybe<Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "product_sales_channel_members" */
export type Product_Sales_Channel_Members_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Sales_Channel_Members_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Sales_Channel_Members_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  productId?: InputMaybe<Scalars['bigint']>;
  salesChannelId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Product_Sales_Channel_Members_Sum_Fields = {
  __typename: 'product_sales_channel_members_sum_fields';
  productId?: Maybe<Scalars['bigint']>;
  salesChannelId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product_sales_channel_members" */
export type Product_Sales_Channel_Members_Sum_Order_By = {
  productId?: InputMaybe<Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
};

/** update columns of table "product_sales_channel_members" */
export enum Product_Sales_Channel_Members_Update_Column {
  /** column name */
  ProductId = 'productId',
  /** column name */
  SalesChannelId = 'salesChannelId'
}

export type Product_Sales_Channel_Members_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Sales_Channel_Members_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Sales_Channel_Members_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Sales_Channel_Members_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Sales_Channel_Members_Var_Pop_Fields = {
  __typename: 'product_sales_channel_members_var_pop_fields';
  productId?: Maybe<Scalars['Float']>;
  salesChannelId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_sales_channel_members" */
export type Product_Sales_Channel_Members_Var_Pop_Order_By = {
  productId?: InputMaybe<Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Sales_Channel_Members_Var_Samp_Fields = {
  __typename: 'product_sales_channel_members_var_samp_fields';
  productId?: Maybe<Scalars['Float']>;
  salesChannelId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_sales_channel_members" */
export type Product_Sales_Channel_Members_Var_Samp_Order_By = {
  productId?: InputMaybe<Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Sales_Channel_Members_Variance_Fields = {
  __typename: 'product_sales_channel_members_variance_fields';
  productId?: Maybe<Scalars['Float']>;
  salesChannelId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_sales_channel_members" */
export type Product_Sales_Channel_Members_Variance_Order_By = {
  productId?: InputMaybe<Order_By>;
  salesChannelId?: InputMaybe<Order_By>;
};

export type Product_Set_S3_Object_As_Product_Image_Args = {
  _product_id?: InputMaybe<Scalars['bigint']>;
  _s3_object_id?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "product_state_transitions" */
export type Product_State_Transitions = {
  __typename: 'product_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Product_States_Enum;
  toState: Product_States_Enum;
};

/** aggregated selection of "product_state_transitions" */
export type Product_State_Transitions_Aggregate = {
  __typename: 'product_state_transitions_aggregate';
  aggregate?: Maybe<Product_State_Transitions_Aggregate_Fields>;
  nodes: Array<Product_State_Transitions>;
};

/** aggregate fields of "product_state_transitions" */
export type Product_State_Transitions_Aggregate_Fields = {
  __typename: 'product_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_State_Transitions_Max_Fields>;
  min?: Maybe<Product_State_Transitions_Min_Fields>;
};


/** aggregate fields of "product_state_transitions" */
export type Product_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "product_state_transitions". All fields are combined with a logical 'AND'. */
export type Product_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Product_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Product_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Product_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Product_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Product_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Product_State_Transitions_Max_Fields = {
  __typename: 'product_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Product_State_Transitions_Min_Fields = {
  __typename: 'product_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "product_state_transitions". */
export type Product_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "product_state_transitions" */
export enum Product_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "product_state_transitions" */
export type Product_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Product_States_Enum>;
  toState?: InputMaybe<Product_States_Enum>;
};

/** columns and relationships of "product_states" */
export type Product_States = {
  __typename: 'product_states';
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "product_states" */
export type Product_StatesProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


/** columns and relationships of "product_states" */
export type Product_StatesProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};

/** aggregated selection of "product_states" */
export type Product_States_Aggregate = {
  __typename: 'product_states_aggregate';
  aggregate?: Maybe<Product_States_Aggregate_Fields>;
  nodes: Array<Product_States>;
};

/** aggregate fields of "product_states" */
export type Product_States_Aggregate_Fields = {
  __typename: 'product_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_States_Max_Fields>;
  min?: Maybe<Product_States_Min_Fields>;
};


/** aggregate fields of "product_states" */
export type Product_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "product_states". All fields are combined with a logical 'AND'. */
export type Product_States_Bool_Exp = {
  _and?: InputMaybe<Array<Product_States_Bool_Exp>>;
  _not?: InputMaybe<Product_States_Bool_Exp>;
  _or?: InputMaybe<Array<Product_States_Bool_Exp>>;
  products?: InputMaybe<Products_Bool_Exp>;
  products_aggregate?: InputMaybe<Products_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Product_States_Enum {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE'
}

/** Boolean expression to compare columns of type "product_states_enum". All fields are combined with logical 'AND'. */
export type Product_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Product_States_Enum>;
  _in?: InputMaybe<Array<Product_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Product_States_Enum>;
  _nin?: InputMaybe<Array<Product_States_Enum>>;
};

/** aggregate max on columns */
export type Product_States_Max_Fields = {
  __typename: 'product_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Product_States_Min_Fields = {
  __typename: 'product_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "product_states". */
export type Product_States_Order_By = {
  products_aggregate?: InputMaybe<Products_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "product_states" */
export enum Product_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "product_states" */
export type Product_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "product_stock_locations" */
export type Product_Stock_Locations = {
  __typename: 'product_stock_locations';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  aggregatedInventoryItemsByLocations: Array<Aggregated_Inventory_Items_By_Location>;
  /** An aggregate relationship */
  aggregatedInventoryItemsByLocations_aggregate: Aggregated_Inventory_Items_By_Location_Aggregate;
  allowedTenants: Scalars['jsonb'];
  id: Scalars['bigint'];
  /** An array relationship */
  inventoryItemsByLocations: Array<Inventory_Items_By_Location>;
  /** An aggregate relationship */
  inventoryItemsByLocations_aggregate: Inventory_Items_By_Location_Aggregate;
  /** An object relationship */
  monetaryUnit: Product_Units;
  monetaryUnitId: Scalars['bigint'];
  ordinalPosition: Scalars['Int'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  securityMonetaryQuantity?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  stockLocation: Stock_Locations;
  stockLocationId: Scalars['bigint'];
  /** An object relationship */
  storageUnit: Product_Units;
  storageUnitId: Scalars['bigint'];
  tenant: Scalars['String'];
};


/** columns and relationships of "product_stock_locations" */
export type Product_Stock_LocationsAggregatedInventoryItemsByLocationsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "product_stock_locations" */
export type Product_Stock_LocationsAggregatedInventoryItemsByLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "product_stock_locations" */
export type Product_Stock_LocationsAllowedTenantsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "product_stock_locations" */
export type Product_Stock_LocationsInventoryItemsByLocationsArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "product_stock_locations" */
export type Product_Stock_LocationsInventoryItemsByLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};

/** aggregated selection of "product_stock_locations" */
export type Product_Stock_Locations_Aggregate = {
  __typename: 'product_stock_locations_aggregate';
  aggregate?: Maybe<Product_Stock_Locations_Aggregate_Fields>;
  nodes: Array<Product_Stock_Locations>;
};

export type Product_Stock_Locations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Stock_Locations_Aggregate_Bool_Exp_Count>;
};

export type Product_Stock_Locations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Stock_Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_stock_locations" */
export type Product_Stock_Locations_Aggregate_Fields = {
  __typename: 'product_stock_locations_aggregate_fields';
  avg?: Maybe<Product_Stock_Locations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Stock_Locations_Max_Fields>;
  min?: Maybe<Product_Stock_Locations_Min_Fields>;
  stddev?: Maybe<Product_Stock_Locations_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Stock_Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Stock_Locations_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Stock_Locations_Sum_Fields>;
  var_pop?: Maybe<Product_Stock_Locations_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Stock_Locations_Var_Samp_Fields>;
  variance?: Maybe<Product_Stock_Locations_Variance_Fields>;
};


/** aggregate fields of "product_stock_locations" */
export type Product_Stock_Locations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Stock_Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_stock_locations" */
export type Product_Stock_Locations_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Stock_Locations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Stock_Locations_Max_Order_By>;
  min?: InputMaybe<Product_Stock_Locations_Min_Order_By>;
  stddev?: InputMaybe<Product_Stock_Locations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Stock_Locations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Stock_Locations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Stock_Locations_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Stock_Locations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Stock_Locations_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Stock_Locations_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Product_Stock_Locations_Append_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "product_stock_locations" */
export type Product_Stock_Locations_Arr_Rel_Insert_Input = {
  data: Array<Product_Stock_Locations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Stock_Locations_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Stock_Locations_Avg_Fields = {
  __typename: 'product_stock_locations_avg_fields';
  id?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  securityMonetaryQuantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_stock_locations" */
export type Product_Stock_Locations_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_stock_locations". All fields are combined with a logical 'AND'. */
export type Product_Stock_Locations_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Product_Stock_Locations_Bool_Exp>>;
  _not?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Stock_Locations_Bool_Exp>>;
  aggregatedInventoryItemsByLocations?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
  aggregatedInventoryItemsByLocations_aggregate?: InputMaybe<Aggregated_Inventory_Items_By_Location_Aggregate_Bool_Exp>;
  allowedTenants?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  inventoryItemsByLocations?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
  inventoryItemsByLocations_aggregate?: InputMaybe<Inventory_Items_By_Location_Aggregate_Bool_Exp>;
  monetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  monetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  securityMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  stockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  stockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  storageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  storageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_stock_locations" */
export enum Product_Stock_Locations_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductStockLocationsPkey = 'product_stock_locations_pkey',
  /** unique or primary key constraint on columns "product_id", "tenant", "ordinal_position" */
  ProductStockLocationsProductIdOrdinalPositionKey = 'product_stock_locations_product_id_ordinal_position_key',
  /** unique or primary key constraint on columns "stock_location_id", "product_id" */
  ProductStockLocationsStockLocationIdProductIdKey = 'product_stock_locations_stock_location_id_product_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Product_Stock_Locations_Delete_At_Path_Input = {
  allowedTenants?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Product_Stock_Locations_Delete_Elem_Input = {
  allowedTenants?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Product_Stock_Locations_Delete_Key_Input = {
  allowedTenants?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "product_stock_locations" */
export type Product_Stock_Locations_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['bigint']>;
  securityMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_stock_locations" */
export type Product_Stock_Locations_Insert_Input = {
  aggregatedInventoryItemsByLocations?: InputMaybe<Aggregated_Inventory_Items_By_Location_Arr_Rel_Insert_Input>;
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  inventoryItemsByLocations?: InputMaybe<Inventory_Items_By_Location_Arr_Rel_Insert_Input>;
  monetaryUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  securityMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  stockLocation?: InputMaybe<Stock_Locations_Obj_Rel_Insert_Input>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  storageUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Product_Stock_Locations_Max_Fields = {
  __typename: 'product_stock_locations_max_fields';
  id?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['bigint']>;
  securityMonetaryQuantity?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "product_stock_locations" */
export type Product_Stock_Locations_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Stock_Locations_Min_Fields = {
  __typename: 'product_stock_locations_min_fields';
  id?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['bigint']>;
  securityMonetaryQuantity?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "product_stock_locations" */
export type Product_Stock_Locations_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_stock_locations" */
export type Product_Stock_Locations_Mutation_Response = {
  __typename: 'product_stock_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Stock_Locations>;
};

/** input type for inserting object relation for remote table "product_stock_locations" */
export type Product_Stock_Locations_Obj_Rel_Insert_Input = {
  data: Product_Stock_Locations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Stock_Locations_On_Conflict>;
};

/** on_conflict condition type for table "product_stock_locations" */
export type Product_Stock_Locations_On_Conflict = {
  constraint: Product_Stock_Locations_Constraint;
  update_columns?: Array<Product_Stock_Locations_Update_Column>;
  where?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "product_stock_locations". */
export type Product_Stock_Locations_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  aggregatedInventoryItemsByLocations_aggregate?: InputMaybe<Aggregated_Inventory_Items_By_Location_Aggregate_Order_By>;
  allowedTenants?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inventoryItemsByLocations_aggregate?: InputMaybe<Inventory_Items_By_Location_Aggregate_Order_By>;
  monetaryUnit?: InputMaybe<Product_Units_Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocation?: InputMaybe<Stock_Locations_Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnit?: InputMaybe<Product_Units_Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_stock_locations */
export type Product_Stock_Locations_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Product_Stock_Locations_Prepend_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "product_stock_locations" */
export enum Product_Stock_Locations_Select_Column {
  /** column name */
  AllowedTenants = 'allowedTenants',
  /** column name */
  Id = 'id',
  /** column name */
  MonetaryUnitId = 'monetaryUnitId',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  ProductId = 'productId',
  /** column name */
  SecurityMonetaryQuantity = 'securityMonetaryQuantity',
  /** column name */
  StockLocationId = 'stockLocationId',
  /** column name */
  StorageUnitId = 'storageUnitId',
  /** column name */
  Tenant = 'tenant'
}

/** input type for updating data in table "product_stock_locations" */
export type Product_Stock_Locations_Set_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['bigint']>;
  securityMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Product_Stock_Locations_Stddev_Fields = {
  __typename: 'product_stock_locations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  securityMonetaryQuantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_stock_locations" */
export type Product_Stock_Locations_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Stock_Locations_Stddev_Pop_Fields = {
  __typename: 'product_stock_locations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  securityMonetaryQuantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_stock_locations" */
export type Product_Stock_Locations_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Stock_Locations_Stddev_Samp_Fields = {
  __typename: 'product_stock_locations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  securityMonetaryQuantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_stock_locations" */
export type Product_Stock_Locations_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "product_stock_locations" */
export type Product_Stock_Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Stock_Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Stock_Locations_Stream_Cursor_Value_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['bigint']>;
  securityMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Product_Stock_Locations_Sum_Fields = {
  __typename: 'product_stock_locations_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['bigint']>;
  securityMonetaryQuantity?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product_stock_locations" */
export type Product_Stock_Locations_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** update columns of table "product_stock_locations" */
export enum Product_Stock_Locations_Update_Column {
  /** column name */
  AllowedTenants = 'allowedTenants',
  /** column name */
  Id = 'id',
  /** column name */
  MonetaryUnitId = 'monetaryUnitId',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  ProductId = 'productId',
  /** column name */
  SecurityMonetaryQuantity = 'securityMonetaryQuantity',
  /** column name */
  StockLocationId = 'stockLocationId',
  /** column name */
  StorageUnitId = 'storageUnitId'
}

export type Product_Stock_Locations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Product_Stock_Locations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Product_Stock_Locations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Product_Stock_Locations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Product_Stock_Locations_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Stock_Locations_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Product_Stock_Locations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Stock_Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Stock_Locations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Stock_Locations_Var_Pop_Fields = {
  __typename: 'product_stock_locations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  securityMonetaryQuantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_stock_locations" */
export type Product_Stock_Locations_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Stock_Locations_Var_Samp_Fields = {
  __typename: 'product_stock_locations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  securityMonetaryQuantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_stock_locations" */
export type Product_Stock_Locations_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Stock_Locations_Variance_Fields = {
  __typename: 'product_stock_locations_variance_fields';
  id?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  securityMonetaryQuantity?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_stock_locations" */
export type Product_Stock_Locations_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  securityMonetaryQuantity?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
};

/** columns and relationships of "product_supplier_state_transitions" */
export type Product_Supplier_State_Transitions = {
  __typename: 'product_supplier_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Product_Supplier_States_Enum;
  toState: Product_Supplier_States_Enum;
};

/** aggregated selection of "product_supplier_state_transitions" */
export type Product_Supplier_State_Transitions_Aggregate = {
  __typename: 'product_supplier_state_transitions_aggregate';
  aggregate?: Maybe<Product_Supplier_State_Transitions_Aggregate_Fields>;
  nodes: Array<Product_Supplier_State_Transitions>;
};

/** aggregate fields of "product_supplier_state_transitions" */
export type Product_Supplier_State_Transitions_Aggregate_Fields = {
  __typename: 'product_supplier_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_Supplier_State_Transitions_Max_Fields>;
  min?: Maybe<Product_Supplier_State_Transitions_Min_Fields>;
};


/** aggregate fields of "product_supplier_state_transitions" */
export type Product_Supplier_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Supplier_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "product_supplier_state_transitions". All fields are combined with a logical 'AND'. */
export type Product_Supplier_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Supplier_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Product_Supplier_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Supplier_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Product_Supplier_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Product_Supplier_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Product_Supplier_State_Transitions_Max_Fields = {
  __typename: 'product_supplier_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Product_Supplier_State_Transitions_Min_Fields = {
  __typename: 'product_supplier_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "product_supplier_state_transitions". */
export type Product_Supplier_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "product_supplier_state_transitions" */
export enum Product_Supplier_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "product_supplier_state_transitions" */
export type Product_Supplier_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Supplier_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Supplier_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Product_Supplier_States_Enum>;
  toState?: InputMaybe<Product_Supplier_States_Enum>;
};

/** columns and relationships of "product_supplier_states" */
export type Product_Supplier_States = {
  __typename: 'product_supplier_states';
  /** An array relationship */
  productSuppliers: Array<Product_Suppliers>;
  /** An aggregate relationship */
  productSuppliers_aggregate: Product_Suppliers_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "product_supplier_states" */
export type Product_Supplier_StatesProductSuppliersArgs = {
  distinct_on?: InputMaybe<Array<Product_Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Suppliers_Order_By>>;
  where?: InputMaybe<Product_Suppliers_Bool_Exp>;
};


/** columns and relationships of "product_supplier_states" */
export type Product_Supplier_StatesProductSuppliers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Suppliers_Order_By>>;
  where?: InputMaybe<Product_Suppliers_Bool_Exp>;
};

/** aggregated selection of "product_supplier_states" */
export type Product_Supplier_States_Aggregate = {
  __typename: 'product_supplier_states_aggregate';
  aggregate?: Maybe<Product_Supplier_States_Aggregate_Fields>;
  nodes: Array<Product_Supplier_States>;
};

/** aggregate fields of "product_supplier_states" */
export type Product_Supplier_States_Aggregate_Fields = {
  __typename: 'product_supplier_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_Supplier_States_Max_Fields>;
  min?: Maybe<Product_Supplier_States_Min_Fields>;
};


/** aggregate fields of "product_supplier_states" */
export type Product_Supplier_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Supplier_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "product_supplier_states". All fields are combined with a logical 'AND'. */
export type Product_Supplier_States_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Supplier_States_Bool_Exp>>;
  _not?: InputMaybe<Product_Supplier_States_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Supplier_States_Bool_Exp>>;
  productSuppliers?: InputMaybe<Product_Suppliers_Bool_Exp>;
  productSuppliers_aggregate?: InputMaybe<Product_Suppliers_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Product_Supplier_States_Enum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

/** Boolean expression to compare columns of type "product_supplier_states_enum". All fields are combined with logical 'AND'. */
export type Product_Supplier_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Product_Supplier_States_Enum>;
  _in?: InputMaybe<Array<Product_Supplier_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Product_Supplier_States_Enum>;
  _nin?: InputMaybe<Array<Product_Supplier_States_Enum>>;
};

/** aggregate max on columns */
export type Product_Supplier_States_Max_Fields = {
  __typename: 'product_supplier_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Product_Supplier_States_Min_Fields = {
  __typename: 'product_supplier_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "product_supplier_states". */
export type Product_Supplier_States_Order_By = {
  productSuppliers_aggregate?: InputMaybe<Product_Suppliers_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "product_supplier_states" */
export enum Product_Supplier_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "product_supplier_states" */
export type Product_Supplier_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Supplier_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Supplier_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "product_suppliers" */
export type Product_Suppliers = {
  __typename: 'product_suppliers';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  currency?: Maybe<Currencies_Enum>;
  defaultSupplierNote?: Maybe<Scalars['String']>;
  externalProductId?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  internalNote?: Maybe<Scalars['String']>;
  /** An object relationship */
  orderUnit: Product_Units;
  orderUnitId: Scalars['bigint'];
  ordinalPosition: Scalars['Int'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  purchasingPricePerUnit: Scalars['numeric'];
  /** An object relationship */
  purchasingUnit: Product_Units;
  purchasingUnitId: Scalars['bigint'];
  restockDurationInDays?: Maybe<Scalars['smallint']>;
  state: Product_Supplier_States_Enum;
  /** An object relationship */
  supplier: Suppliers;
  supplierId: Scalars['bigint'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};

/** aggregated selection of "product_suppliers" */
export type Product_Suppliers_Aggregate = {
  __typename: 'product_suppliers_aggregate';
  aggregate?: Maybe<Product_Suppliers_Aggregate_Fields>;
  nodes: Array<Product_Suppliers>;
};

export type Product_Suppliers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Suppliers_Aggregate_Bool_Exp_Count>;
};

export type Product_Suppliers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Suppliers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Product_Suppliers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_suppliers" */
export type Product_Suppliers_Aggregate_Fields = {
  __typename: 'product_suppliers_aggregate_fields';
  avg?: Maybe<Product_Suppliers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Suppliers_Max_Fields>;
  min?: Maybe<Product_Suppliers_Min_Fields>;
  stddev?: Maybe<Product_Suppliers_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Suppliers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Suppliers_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Suppliers_Sum_Fields>;
  var_pop?: Maybe<Product_Suppliers_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Suppliers_Var_Samp_Fields>;
  variance?: Maybe<Product_Suppliers_Variance_Fields>;
};


/** aggregate fields of "product_suppliers" */
export type Product_Suppliers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Suppliers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_suppliers" */
export type Product_Suppliers_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Suppliers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Suppliers_Max_Order_By>;
  min?: InputMaybe<Product_Suppliers_Min_Order_By>;
  stddev?: InputMaybe<Product_Suppliers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Suppliers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Suppliers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Suppliers_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Suppliers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Suppliers_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Suppliers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_suppliers" */
export type Product_Suppliers_Arr_Rel_Insert_Input = {
  data: Array<Product_Suppliers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Suppliers_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Suppliers_Avg_Fields = {
  __typename: 'product_suppliers_avg_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  purchasingPricePerUnit?: Maybe<Scalars['Float']>;
  purchasingUnitId?: Maybe<Scalars['Float']>;
  restockDurationInDays?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_suppliers" */
export type Product_Suppliers_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  restockDurationInDays?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_suppliers". All fields are combined with a logical 'AND'. */
export type Product_Suppliers_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Product_Suppliers_Bool_Exp>>;
  _not?: InputMaybe<Product_Suppliers_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Suppliers_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  defaultSupplierNote?: InputMaybe<String_Comparison_Exp>;
  externalProductId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  internalNote?: InputMaybe<String_Comparison_Exp>;
  orderUnit?: InputMaybe<Product_Units_Bool_Exp>;
  orderUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  purchasingPricePerUnit?: InputMaybe<Numeric_Comparison_Exp>;
  purchasingUnit?: InputMaybe<Product_Units_Bool_Exp>;
  purchasingUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  restockDurationInDays?: InputMaybe<Smallint_Comparison_Exp>;
  state?: InputMaybe<Product_Supplier_States_Enum_Comparison_Exp>;
  supplier?: InputMaybe<Suppliers_Bool_Exp>;
  supplierId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_suppliers" */
export enum Product_Suppliers_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductSuppliersPkey = 'product_suppliers_pkey',
  /** unique or primary key constraint on columns "product_id", "tenant", "ordinal_position" */
  ProductSuppliersProductIdOrdinalPositionKey = 'product_suppliers_product_id_ordinal_position_key',
  /** unique or primary key constraint on columns "product_id", "tenant", "supplier_id" */
  ProductSuppliersSupplierIdProductIdTenantIdx = 'product_suppliers_supplier_id_product_id_tenant_idx'
}

/** input type for incrementing numeric columns in table "product_suppliers" */
export type Product_Suppliers_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  orderUnitId?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['bigint']>;
  purchasingPricePerUnit?: InputMaybe<Scalars['numeric']>;
  purchasingUnitId?: InputMaybe<Scalars['bigint']>;
  restockDurationInDays?: InputMaybe<Scalars['smallint']>;
  supplierId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_suppliers" */
export type Product_Suppliers_Insert_Input = {
  currency?: InputMaybe<Currencies_Enum>;
  defaultSupplierNote?: InputMaybe<Scalars['String']>;
  externalProductId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  internalNote?: InputMaybe<Scalars['String']>;
  orderUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  orderUnitId?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  purchasingPricePerUnit?: InputMaybe<Scalars['numeric']>;
  purchasingUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  purchasingUnitId?: InputMaybe<Scalars['bigint']>;
  restockDurationInDays?: InputMaybe<Scalars['smallint']>;
  state?: InputMaybe<Product_Supplier_States_Enum>;
  supplier?: InputMaybe<Suppliers_Obj_Rel_Insert_Input>;
  supplierId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Product_Suppliers_Max_Fields = {
  __typename: 'product_suppliers_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  defaultSupplierNote?: Maybe<Scalars['String']>;
  externalProductId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  internalNote?: Maybe<Scalars['String']>;
  orderUnitId?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['bigint']>;
  purchasingPricePerUnit?: Maybe<Scalars['numeric']>;
  purchasingUnitId?: Maybe<Scalars['bigint']>;
  restockDurationInDays?: Maybe<Scalars['smallint']>;
  supplierId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "product_suppliers" */
export type Product_Suppliers_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  defaultSupplierNote?: InputMaybe<Order_By>;
  externalProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internalNote?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  restockDurationInDays?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Suppliers_Min_Fields = {
  __typename: 'product_suppliers_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  defaultSupplierNote?: Maybe<Scalars['String']>;
  externalProductId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  internalNote?: Maybe<Scalars['String']>;
  orderUnitId?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['bigint']>;
  purchasingPricePerUnit?: Maybe<Scalars['numeric']>;
  purchasingUnitId?: Maybe<Scalars['bigint']>;
  restockDurationInDays?: Maybe<Scalars['smallint']>;
  supplierId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "product_suppliers" */
export type Product_Suppliers_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  defaultSupplierNote?: InputMaybe<Order_By>;
  externalProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internalNote?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  restockDurationInDays?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_suppliers" */
export type Product_Suppliers_Mutation_Response = {
  __typename: 'product_suppliers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Suppliers>;
};

/** on_conflict condition type for table "product_suppliers" */
export type Product_Suppliers_On_Conflict = {
  constraint: Product_Suppliers_Constraint;
  update_columns?: Array<Product_Suppliers_Update_Column>;
  where?: InputMaybe<Product_Suppliers_Bool_Exp>;
};

/** Ordering options when selecting data from "product_suppliers". */
export type Product_Suppliers_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  defaultSupplierNote?: InputMaybe<Order_By>;
  externalProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internalNote?: InputMaybe<Order_By>;
  orderUnit?: InputMaybe<Product_Units_Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnit?: InputMaybe<Product_Units_Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  restockDurationInDays?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  supplier?: InputMaybe<Suppliers_Order_By>;
  supplierId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_suppliers */
export type Product_Suppliers_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "product_suppliers" */
export enum Product_Suppliers_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  DefaultSupplierNote = 'defaultSupplierNote',
  /** column name */
  ExternalProductId = 'externalProductId',
  /** column name */
  Id = 'id',
  /** column name */
  InternalNote = 'internalNote',
  /** column name */
  OrderUnitId = 'orderUnitId',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  ProductId = 'productId',
  /** column name */
  PurchasingPricePerUnit = 'purchasingPricePerUnit',
  /** column name */
  PurchasingUnitId = 'purchasingUnitId',
  /** column name */
  RestockDurationInDays = 'restockDurationInDays',
  /** column name */
  State = 'state',
  /** column name */
  SupplierId = 'supplierId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "product_suppliers" */
export type Product_Suppliers_Set_Input = {
  currency?: InputMaybe<Currencies_Enum>;
  defaultSupplierNote?: InputMaybe<Scalars['String']>;
  externalProductId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  internalNote?: InputMaybe<Scalars['String']>;
  orderUnitId?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['bigint']>;
  purchasingPricePerUnit?: InputMaybe<Scalars['numeric']>;
  purchasingUnitId?: InputMaybe<Scalars['bigint']>;
  restockDurationInDays?: InputMaybe<Scalars['smallint']>;
  state?: InputMaybe<Product_Supplier_States_Enum>;
  supplierId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Product_Suppliers_Stddev_Fields = {
  __typename: 'product_suppliers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  purchasingPricePerUnit?: Maybe<Scalars['Float']>;
  purchasingUnitId?: Maybe<Scalars['Float']>;
  restockDurationInDays?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_suppliers" */
export type Product_Suppliers_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  restockDurationInDays?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Suppliers_Stddev_Pop_Fields = {
  __typename: 'product_suppliers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  purchasingPricePerUnit?: Maybe<Scalars['Float']>;
  purchasingUnitId?: Maybe<Scalars['Float']>;
  restockDurationInDays?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_suppliers" */
export type Product_Suppliers_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  restockDurationInDays?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Suppliers_Stddev_Samp_Fields = {
  __typename: 'product_suppliers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  purchasingPricePerUnit?: Maybe<Scalars['Float']>;
  purchasingUnitId?: Maybe<Scalars['Float']>;
  restockDurationInDays?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_suppliers" */
export type Product_Suppliers_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  restockDurationInDays?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "product_suppliers" */
export type Product_Suppliers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Suppliers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Suppliers_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Currencies_Enum>;
  defaultSupplierNote?: InputMaybe<Scalars['String']>;
  externalProductId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  internalNote?: InputMaybe<Scalars['String']>;
  orderUnitId?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['bigint']>;
  purchasingPricePerUnit?: InputMaybe<Scalars['numeric']>;
  purchasingUnitId?: InputMaybe<Scalars['bigint']>;
  restockDurationInDays?: InputMaybe<Scalars['smallint']>;
  state?: InputMaybe<Product_Supplier_States_Enum>;
  supplierId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Product_Suppliers_Sum_Fields = {
  __typename: 'product_suppliers_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  orderUnitId?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['bigint']>;
  purchasingPricePerUnit?: Maybe<Scalars['numeric']>;
  purchasingUnitId?: Maybe<Scalars['bigint']>;
  restockDurationInDays?: Maybe<Scalars['smallint']>;
  supplierId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product_suppliers" */
export type Product_Suppliers_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  restockDurationInDays?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "product_suppliers" */
export enum Product_Suppliers_Update_Column {
  /** column name */
  Currency = 'currency',
  /** column name */
  DefaultSupplierNote = 'defaultSupplierNote',
  /** column name */
  ExternalProductId = 'externalProductId',
  /** column name */
  Id = 'id',
  /** column name */
  InternalNote = 'internalNote',
  /** column name */
  OrderUnitId = 'orderUnitId',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  ProductId = 'productId',
  /** column name */
  PurchasingPricePerUnit = 'purchasingPricePerUnit',
  /** column name */
  PurchasingUnitId = 'purchasingUnitId',
  /** column name */
  RestockDurationInDays = 'restockDurationInDays',
  /** column name */
  State = 'state',
  /** column name */
  SupplierId = 'supplierId',
  /** column name */
  Version = 'version'
}

export type Product_Suppliers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Suppliers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Suppliers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Suppliers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Suppliers_Var_Pop_Fields = {
  __typename: 'product_suppliers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  purchasingPricePerUnit?: Maybe<Scalars['Float']>;
  purchasingUnitId?: Maybe<Scalars['Float']>;
  restockDurationInDays?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_suppliers" */
export type Product_Suppliers_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  restockDurationInDays?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Suppliers_Var_Samp_Fields = {
  __typename: 'product_suppliers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  purchasingPricePerUnit?: Maybe<Scalars['Float']>;
  purchasingUnitId?: Maybe<Scalars['Float']>;
  restockDurationInDays?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_suppliers" */
export type Product_Suppliers_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  restockDurationInDays?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Suppliers_Variance_Fields = {
  __typename: 'product_suppliers_variance_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  purchasingPricePerUnit?: Maybe<Scalars['Float']>;
  purchasingUnitId?: Maybe<Scalars['Float']>;
  restockDurationInDays?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_suppliers" */
export type Product_Suppliers_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  restockDurationInDays?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

export type Product_Unit_Conversion_Args = {
  quantity?: InputMaybe<Scalars['bigint']>;
  source_product_unit_id?: InputMaybe<Scalars['bigint']>;
  target_product_unit_id?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "product_unit_conversion_result" */
export type Product_Unit_Conversion_Result = {
  __typename: 'product_unit_conversion_result';
  result?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "product_unit_conversion_result" */
export type Product_Unit_Conversion_Result_Aggregate = {
  __typename: 'product_unit_conversion_result_aggregate';
  aggregate?: Maybe<Product_Unit_Conversion_Result_Aggregate_Fields>;
  nodes: Array<Product_Unit_Conversion_Result>;
};

/** aggregate fields of "product_unit_conversion_result" */
export type Product_Unit_Conversion_Result_Aggregate_Fields = {
  __typename: 'product_unit_conversion_result_aggregate_fields';
  avg?: Maybe<Product_Unit_Conversion_Result_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Unit_Conversion_Result_Max_Fields>;
  min?: Maybe<Product_Unit_Conversion_Result_Min_Fields>;
  stddev?: Maybe<Product_Unit_Conversion_Result_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Unit_Conversion_Result_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Unit_Conversion_Result_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Unit_Conversion_Result_Sum_Fields>;
  var_pop?: Maybe<Product_Unit_Conversion_Result_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Unit_Conversion_Result_Var_Samp_Fields>;
  variance?: Maybe<Product_Unit_Conversion_Result_Variance_Fields>;
};


/** aggregate fields of "product_unit_conversion_result" */
export type Product_Unit_Conversion_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Unit_Conversion_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Product_Unit_Conversion_Result_Avg_Fields = {
  __typename: 'product_unit_conversion_result_avg_fields';
  result?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "product_unit_conversion_result". All fields are combined with a logical 'AND'. */
export type Product_Unit_Conversion_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Unit_Conversion_Result_Bool_Exp>>;
  _not?: InputMaybe<Product_Unit_Conversion_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Unit_Conversion_Result_Bool_Exp>>;
  result?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Product_Unit_Conversion_Result_Max_Fields = {
  __typename: 'product_unit_conversion_result_max_fields';
  result?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Product_Unit_Conversion_Result_Min_Fields = {
  __typename: 'product_unit_conversion_result_min_fields';
  result?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "product_unit_conversion_result". */
export type Product_Unit_Conversion_Result_Order_By = {
  result?: InputMaybe<Order_By>;
};

/** select columns of table "product_unit_conversion_result" */
export enum Product_Unit_Conversion_Result_Select_Column {
  /** column name */
  Result = 'result'
}

/** aggregate stddev on columns */
export type Product_Unit_Conversion_Result_Stddev_Fields = {
  __typename: 'product_unit_conversion_result_stddev_fields';
  result?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Product_Unit_Conversion_Result_Stddev_Pop_Fields = {
  __typename: 'product_unit_conversion_result_stddev_pop_fields';
  result?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Product_Unit_Conversion_Result_Stddev_Samp_Fields = {
  __typename: 'product_unit_conversion_result_stddev_samp_fields';
  result?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "product_unit_conversion_result" */
export type Product_Unit_Conversion_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Unit_Conversion_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Unit_Conversion_Result_Stream_Cursor_Value_Input = {
  result?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Product_Unit_Conversion_Result_Sum_Fields = {
  __typename: 'product_unit_conversion_result_sum_fields';
  result?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Product_Unit_Conversion_Result_Var_Pop_Fields = {
  __typename: 'product_unit_conversion_result_var_pop_fields';
  result?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Product_Unit_Conversion_Result_Var_Samp_Fields = {
  __typename: 'product_unit_conversion_result_var_samp_fields';
  result?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Product_Unit_Conversion_Result_Variance_Fields = {
  __typename: 'product_unit_conversion_result_variance_fields';
  result?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config = {
  __typename: 'product_unit_sales_price_config';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  currency: Currencies_Enum;
  id: Scalars['bigint'];
  /** An object relationship */
  orderUnit: Product_Units;
  orderUnitId: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  /** An object relationship */
  salesUnit: Product_Units;
  salesUnitId: Scalars['bigint'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};

/** aggregated selection of "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_Aggregate = {
  __typename: 'product_unit_sales_price_config_aggregate';
  aggregate?: Maybe<Product_Unit_Sales_Price_Config_Aggregate_Fields>;
  nodes: Array<Product_Unit_Sales_Price_Config>;
};

export type Product_Unit_Sales_Price_Config_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Unit_Sales_Price_Config_Aggregate_Bool_Exp_Count>;
};

export type Product_Unit_Sales_Price_Config_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_Aggregate_Fields = {
  __typename: 'product_unit_sales_price_config_aggregate_fields';
  avg?: Maybe<Product_Unit_Sales_Price_Config_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Unit_Sales_Price_Config_Max_Fields>;
  min?: Maybe<Product_Unit_Sales_Price_Config_Min_Fields>;
  stddev?: Maybe<Product_Unit_Sales_Price_Config_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Unit_Sales_Price_Config_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Unit_Sales_Price_Config_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Unit_Sales_Price_Config_Sum_Fields>;
  var_pop?: Maybe<Product_Unit_Sales_Price_Config_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Unit_Sales_Price_Config_Var_Samp_Fields>;
  variance?: Maybe<Product_Unit_Sales_Price_Config_Variance_Fields>;
};


/** aggregate fields of "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Unit_Sales_Price_Config_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Unit_Sales_Price_Config_Max_Order_By>;
  min?: InputMaybe<Product_Unit_Sales_Price_Config_Min_Order_By>;
  stddev?: InputMaybe<Product_Unit_Sales_Price_Config_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Unit_Sales_Price_Config_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Unit_Sales_Price_Config_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Unit_Sales_Price_Config_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Unit_Sales_Price_Config_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Unit_Sales_Price_Config_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Unit_Sales_Price_Config_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_Arr_Rel_Insert_Input = {
  data: Array<Product_Unit_Sales_Price_Config_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Unit_Sales_Price_Config_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Unit_Sales_Price_Config_Avg_Fields = {
  __typename: 'product_unit_sales_price_config_avg_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_unit_sales_price_config". All fields are combined with a logical 'AND'. */
export type Product_Unit_Sales_Price_Config_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Bool_Exp>>;
  _not?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  orderUnit?: InputMaybe<Product_Units_Bool_Exp>;
  orderUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  salesUnit?: InputMaybe<Product_Units_Bool_Exp>;
  salesUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_unit_sales_price_config" */
export enum Product_Unit_Sales_Price_Config_Constraint {
  /** unique or primary key constraint on columns "sales_unit_id", "order_unit_id" */
  ProductUnitSalesPriceConfigOrderUnitIdSalesUnitIdKey = 'product_unit_sales_price_config_order_unit_id_sales_unit_id_key',
  /** unique or primary key constraint on columns "id" */
  ProductUnitSalesPriceConfigPkey = 'product_unit_sales_price_config_pkey'
}

/** input type for incrementing numeric columns in table "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  orderUnitId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  salesUnitId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_Insert_Input = {
  currency?: InputMaybe<Currencies_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  orderUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  orderUnitId?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  salesUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  salesUnitId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Product_Unit_Sales_Price_Config_Max_Fields = {
  __typename: 'product_unit_sales_price_config_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  orderUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  salesUnitId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Unit_Sales_Price_Config_Min_Fields = {
  __typename: 'product_unit_sales_price_config_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  orderUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  salesUnitId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_Mutation_Response = {
  __typename: 'product_unit_sales_price_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Unit_Sales_Price_Config>;
};

/** on_conflict condition type for table "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_On_Conflict = {
  constraint: Product_Unit_Sales_Price_Config_Constraint;
  update_columns?: Array<Product_Unit_Sales_Price_Config_Update_Column>;
  where?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "product_unit_sales_price_config". */
export type Product_Unit_Sales_Price_Config_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderUnit?: InputMaybe<Product_Units_Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  salesUnit?: InputMaybe<Product_Units_Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_unit_sales_price_config */
export type Product_Unit_Sales_Price_Config_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "product_unit_sales_price_config" */
export enum Product_Unit_Sales_Price_Config_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  OrderUnitId = 'orderUnitId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  SalesUnitId = 'salesUnitId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_Set_Input = {
  currency?: InputMaybe<Currencies_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  orderUnitId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  salesUnitId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Product_Unit_Sales_Price_Config_Stddev_Fields = {
  __typename: 'product_unit_sales_price_config_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Unit_Sales_Price_Config_Stddev_Pop_Fields = {
  __typename: 'product_unit_sales_price_config_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Unit_Sales_Price_Config_Stddev_Samp_Fields = {
  __typename: 'product_unit_sales_price_config_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Unit_Sales_Price_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Unit_Sales_Price_Config_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Currencies_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  orderUnitId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  salesUnitId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Product_Unit_Sales_Price_Config_Sum_Fields = {
  __typename: 'product_unit_sales_price_config_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  orderUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  salesUnitId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "product_unit_sales_price_config" */
export enum Product_Unit_Sales_Price_Config_Update_Column {
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  OrderUnitId = 'orderUnitId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  SalesUnitId = 'salesUnitId',
  /** column name */
  Version = 'version'
}

export type Product_Unit_Sales_Price_Config_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Unit_Sales_Price_Config_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Unit_Sales_Price_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Unit_Sales_Price_Config_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Unit_Sales_Price_Config_Var_Pop_Fields = {
  __typename: 'product_unit_sales_price_config_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Unit_Sales_Price_Config_Var_Samp_Fields = {
  __typename: 'product_unit_sales_price_config_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Unit_Sales_Price_Config_Variance_Fields = {
  __typename: 'product_unit_sales_price_config_variance_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  salesUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_unit_sales_price_config" */
export type Product_Unit_Sales_Price_Config_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  salesUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "product_unit_state_transitions" */
export type Product_Unit_State_Transitions = {
  __typename: 'product_unit_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Product_Unit_States_Enum;
  toState: Product_Unit_States_Enum;
};

/** aggregated selection of "product_unit_state_transitions" */
export type Product_Unit_State_Transitions_Aggregate = {
  __typename: 'product_unit_state_transitions_aggregate';
  aggregate?: Maybe<Product_Unit_State_Transitions_Aggregate_Fields>;
  nodes: Array<Product_Unit_State_Transitions>;
};

/** aggregate fields of "product_unit_state_transitions" */
export type Product_Unit_State_Transitions_Aggregate_Fields = {
  __typename: 'product_unit_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_Unit_State_Transitions_Max_Fields>;
  min?: Maybe<Product_Unit_State_Transitions_Min_Fields>;
};


/** aggregate fields of "product_unit_state_transitions" */
export type Product_Unit_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Unit_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "product_unit_state_transitions". All fields are combined with a logical 'AND'. */
export type Product_Unit_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Unit_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Product_Unit_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Unit_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Product_Unit_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Product_Unit_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Product_Unit_State_Transitions_Max_Fields = {
  __typename: 'product_unit_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Product_Unit_State_Transitions_Min_Fields = {
  __typename: 'product_unit_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "product_unit_state_transitions". */
export type Product_Unit_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "product_unit_state_transitions" */
export enum Product_Unit_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "product_unit_state_transitions" */
export type Product_Unit_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Unit_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Unit_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Product_Unit_States_Enum>;
  toState?: InputMaybe<Product_Unit_States_Enum>;
};

/** columns and relationships of "product_unit_states" */
export type Product_Unit_States = {
  __typename: 'product_unit_states';
  /** An array relationship */
  productUnits: Array<Product_Units>;
  /** An aggregate relationship */
  productUnits_aggregate: Product_Units_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "product_unit_states" */
export type Product_Unit_StatesProductUnitsArgs = {
  distinct_on?: InputMaybe<Array<Product_Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Units_Order_By>>;
  where?: InputMaybe<Product_Units_Bool_Exp>;
};


/** columns and relationships of "product_unit_states" */
export type Product_Unit_StatesProductUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Units_Order_By>>;
  where?: InputMaybe<Product_Units_Bool_Exp>;
};

/** aggregated selection of "product_unit_states" */
export type Product_Unit_States_Aggregate = {
  __typename: 'product_unit_states_aggregate';
  aggregate?: Maybe<Product_Unit_States_Aggregate_Fields>;
  nodes: Array<Product_Unit_States>;
};

/** aggregate fields of "product_unit_states" */
export type Product_Unit_States_Aggregate_Fields = {
  __typename: 'product_unit_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_Unit_States_Max_Fields>;
  min?: Maybe<Product_Unit_States_Min_Fields>;
};


/** aggregate fields of "product_unit_states" */
export type Product_Unit_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Unit_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "product_unit_states". All fields are combined with a logical 'AND'. */
export type Product_Unit_States_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Unit_States_Bool_Exp>>;
  _not?: InputMaybe<Product_Unit_States_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Unit_States_Bool_Exp>>;
  productUnits?: InputMaybe<Product_Units_Bool_Exp>;
  productUnits_aggregate?: InputMaybe<Product_Units_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Product_Unit_States_Enum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

/** Boolean expression to compare columns of type "product_unit_states_enum". All fields are combined with logical 'AND'. */
export type Product_Unit_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Product_Unit_States_Enum>;
  _in?: InputMaybe<Array<Product_Unit_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Product_Unit_States_Enum>;
  _nin?: InputMaybe<Array<Product_Unit_States_Enum>>;
};

/** aggregate max on columns */
export type Product_Unit_States_Max_Fields = {
  __typename: 'product_unit_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Product_Unit_States_Min_Fields = {
  __typename: 'product_unit_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "product_unit_states". */
export type Product_Unit_States_Order_By = {
  productUnits_aggregate?: InputMaybe<Product_Units_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "product_unit_states" */
export enum Product_Unit_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "product_unit_states" */
export type Product_Unit_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Unit_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Unit_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "product_units" */
export type Product_Units = {
  __typename: 'product_units';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  aggregatedDemandPlanResultsViaMonetaryUnit: Array<Aggregated_Demand_Plan_Results>;
  /** An aggregate relationship */
  aggregatedDemandPlanResultsViaMonetaryUnit_aggregate: Aggregated_Demand_Plan_Results_Aggregate;
  /** An array relationship */
  aggregatedDemandPlanResultsViaStorageUnit: Array<Aggregated_Demand_Plan_Results>;
  /** An aggregate relationship */
  aggregatedDemandPlanResultsViaStorageUnit_aggregate: Aggregated_Demand_Plan_Results_Aggregate;
  /** An array relationship */
  aggregatedInventoryItemsByLocationViaMonetaryUnit: Array<Aggregated_Inventory_Items_By_Location>;
  /** An aggregate relationship */
  aggregatedInventoryItemsByLocationViaMonetaryUnit_aggregate: Aggregated_Inventory_Items_By_Location_Aggregate;
  /** An array relationship */
  aggregatedInventoryItemsByLocationViaStorageUnit: Array<Aggregated_Inventory_Items_By_Location>;
  /** An aggregate relationship */
  aggregatedInventoryItemsByLocationViaStorageUnit_aggregate: Aggregated_Inventory_Items_By_Location_Aggregate;
  /** An array relationship */
  calculateCostPriceForProductResults: Array<Calculate_Cost_Price_For_Product_Results>;
  /** An aggregate relationship */
  calculateCostPriceForProductResults_aggregate: Calculate_Cost_Price_For_Product_Results_Aggregate;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  customerGroupPricings: Array<Customer_Group_Pricing>;
  /** An aggregate relationship */
  customerGroupPricings_aggregate: Customer_Group_Pricing_Aggregate;
  /** An array relationship */
  customerOrderContainerReturnItems: Array<Customer_Order_Container_Return_Items>;
  /** An aggregate relationship */
  customerOrderContainerReturnItems_aggregate: Customer_Order_Container_Return_Items_Aggregate;
  /** An array relationship */
  customerOrderItemAdjustments: Array<Customer_Order_Item_Adjustments>;
  /** An aggregate relationship */
  customerOrderItemAdjustments_aggregate: Customer_Order_Item_Adjustments_Aggregate;
  /** An array relationship */
  customerOrderItemFulfillmentPlans: Array<Customer_Order_Item_Fulfillment_Plans>;
  /** An aggregate relationship */
  customerOrderItemFulfillmentPlans_aggregate: Customer_Order_Item_Fulfillment_Plans_Aggregate;
  /** An array relationship */
  customerOrderItemsViaOrderUnit: Array<Customer_Order_Items>;
  /** An aggregate relationship */
  customerOrderItemsViaOrderUnit_aggregate: Customer_Order_Items_Aggregate;
  /** An array relationship */
  customerOrderItemsViaSalesUnit: Array<Customer_Order_Items>;
  /** An aggregate relationship */
  customerOrderItemsViaSalesUnit_aggregate: Customer_Order_Items_Aggregate;
  /** An array relationship */
  customerOrderReturnItemsViaReturnedMonetaryUnit: Array<Customer_Order_Return_Items>;
  /** An aggregate relationship */
  customerOrderReturnItemsViaReturnedMonetaryUnit_aggregate: Customer_Order_Return_Items_Aggregate;
  /** An array relationship */
  customerOrderReturnItemsViaReturnedStorageUnit: Array<Customer_Order_Return_Items>;
  /** An aggregate relationship */
  customerOrderReturnItemsViaReturnedStorageUnit_aggregate: Customer_Order_Return_Items_Aggregate;
  /** An array relationship */
  customerOrderReturnItemsViaTargetMonetaryUnit: Array<Customer_Order_Return_Items>;
  /** An aggregate relationship */
  customerOrderReturnItemsViaTargetMonetaryUnit_aggregate: Customer_Order_Return_Items_Aggregate;
  /** An array relationship */
  customerOrderReturnItemsViaTargetStorageUnit: Array<Customer_Order_Return_Items>;
  /** An aggregate relationship */
  customerOrderReturnItemsViaTargetStorageUnit_aggregate: Customer_Order_Return_Items_Aggregate;
  /** An array relationship */
  cuttingPatternOutputs: Array<Cutting_Pattern_Outputs>;
  /** An array relationship */
  cuttingPatternOutputsDrafts: Array<Cutting_Pattern_Outputs_Draft>;
  /** An aggregate relationship */
  cuttingPatternOutputsDrafts_aggregate: Cutting_Pattern_Outputs_Draft_Aggregate;
  /** An aggregate relationship */
  cuttingPatternOutputs_aggregate: Cutting_Pattern_Outputs_Aggregate;
  /** An array relationship */
  demandsForCoiResults: Array<Demands_For_Coi_Results>;
  /** An aggregate relationship */
  demandsForCoiResults_aggregate: Demands_For_Coi_Results_Aggregate;
  depletionThreshold?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  dismantlingPlanDemands: Array<Dismantling_Plan_Demands>;
  /** An aggregate relationship */
  dismantlingPlanDemands_aggregate: Dismantling_Plan_Demands_Aggregate;
  factor: Scalars['numeric'];
  id: Scalars['bigint'];
  /** An array relationship */
  inventoryDailyPrognosisResultsViaMonetaryUnit: Array<Inventory_Daily_Prognosis_Results>;
  /** An aggregate relationship */
  inventoryDailyPrognosisResultsViaMonetaryUnit_aggregate: Inventory_Daily_Prognosis_Results_Aggregate;
  /** An array relationship */
  inventoryDailyPrognosisResultsViaStorageUnit: Array<Inventory_Daily_Prognosis_Results>;
  /** An aggregate relationship */
  inventoryDailyPrognosisResultsViaStorageUnit_aggregate: Inventory_Daily_Prognosis_Results_Aggregate;
  /** An array relationship */
  inventoryDailyUsageResultViaMonetaryUnit: Array<Inventory_Daily_Usage_Results>;
  /** An aggregate relationship */
  inventoryDailyUsageResultViaMonetaryUnit_aggregate: Inventory_Daily_Usage_Results_Aggregate;
  /** An array relationship */
  inventoryDailyUsageResultViaStorageUnit: Array<Inventory_Daily_Usage_Results>;
  /** An aggregate relationship */
  inventoryDailyUsageResultViaStorageUnit_aggregate: Inventory_Daily_Usage_Results_Aggregate;
  /** An array relationship */
  inventoryItemsByLocationViaMonetaryUnit: Array<Inventory_Items_By_Location>;
  /** An aggregate relationship */
  inventoryItemsByLocationViaMonetaryUnit_aggregate: Inventory_Items_By_Location_Aggregate;
  /** An array relationship */
  inventoryItemsByLocationViaStorageUnit: Array<Inventory_Items_By_Location>;
  /** An aggregate relationship */
  inventoryItemsByLocationViaStorageUnit_aggregate: Inventory_Items_By_Location_Aggregate;
  /** An array relationship */
  inventoryItemsViaMonetaryUnit: Array<Inventory_Items>;
  /** An aggregate relationship */
  inventoryItemsViaMonetaryUnit_aggregate: Inventory_Items_Aggregate;
  /** An array relationship */
  inventoryItemsViaStorageUnit: Array<Inventory_Items>;
  /** An aggregate relationship */
  inventoryItemsViaStorageUnit_aggregate: Inventory_Items_Aggregate;
  /** An array relationship */
  listPurchasedProductsResultViaMonetaryUnit: Array<List_Purchased_Products_Result>;
  /** An aggregate relationship */
  listPurchasedProductsResultViaMonetaryUnit_aggregate: List_Purchased_Products_Result_Aggregate;
  /** An array relationship */
  listPurchasedProductsResultViaStorageUnit: Array<List_Purchased_Products_Result>;
  /** An aggregate relationship */
  listPurchasedProductsResultViaStorageUnit_aggregate: List_Purchased_Products_Result_Aggregate;
  /** An array relationship */
  lotNumberProducts: Array<Lot_Number_Products>;
  /** An aggregate relationship */
  lotNumberProducts_aggregate: Lot_Number_Products_Aggregate;
  ordinalPosition: Scalars['Int'];
  /** An array relationship */
  pickedItems: Array<Picked_Items>;
  /** An array relationship */
  pickedItemsViaSourceMonetaryUnit: Array<Picked_Items>;
  /** An aggregate relationship */
  pickedItemsViaSourceMonetaryUnit_aggregate: Picked_Items_Aggregate;
  /** An array relationship */
  pickedItemsViaSourceStorageUnit: Array<Picked_Items>;
  /** An aggregate relationship */
  pickedItemsViaSourceStorageUnit_aggregate: Picked_Items_Aggregate;
  /** An array relationship */
  pickedItemsViaTargetMonetaryUnit: Array<Picked_Items>;
  /** An aggregate relationship */
  pickedItemsViaTargetMonetaryUnit_aggregate: Picked_Items_Aggregate;
  /** An array relationship */
  pickedItemsViaTargetStorageUnit: Array<Picked_Items>;
  /** An aggregate relationship */
  pickedItemsViaTargetStorageUnit_aggregate: Picked_Items_Aggregate;
  /** An aggregate relationship */
  pickedItems_aggregate: Picked_Items_Aggregate;
  /** An array relationship */
  postingsViaMonetaryUnit: Array<Postings>;
  /** An aggregate relationship */
  postingsViaMonetaryUnit_aggregate: Postings_Aggregate;
  /** An array relationship */
  postingsViaStorageUnit: Array<Postings>;
  /** An aggregate relationship */
  postingsViaStorageUnit_aggregate: Postings_Aggregate;
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  /** An array relationship */
  productRecipeIngredients: Array<Product_Recipe_Ingredients>;
  /** An aggregate relationship */
  productRecipeIngredients_aggregate: Product_Recipe_Ingredients_Aggregate;
  /** An array relationship */
  productRecipes: Array<Product_Recipes>;
  /** An aggregate relationship */
  productRecipes_aggregate: Product_Recipes_Aggregate;
  /** An array relationship */
  productStockLocationsViaMonetaryUnit: Array<Product_Stock_Locations>;
  /** An aggregate relationship */
  productStockLocationsViaMonetaryUnit_aggregate: Product_Stock_Locations_Aggregate;
  /** An array relationship */
  productStockLocationsViaStorageUnit: Array<Product_Stock_Locations>;
  /** An aggregate relationship */
  productStockLocationsViaStorageUnit_aggregate: Product_Stock_Locations_Aggregate;
  /** An array relationship */
  productSuppliersViaOrderUnit: Array<Product_Suppliers>;
  /** An aggregate relationship */
  productSuppliersViaOrderUnit_aggregate: Product_Suppliers_Aggregate;
  /** An array relationship */
  productSuppliersViaPurchasingUnit: Array<Product_Suppliers>;
  /** An aggregate relationship */
  productSuppliersViaPurchasingUnit_aggregate: Product_Suppliers_Aggregate;
  productUnitName?: Maybe<Scalars['String']>;
  /** An array relationship */
  productUnitSalesPriceConfigsViaOrderUnit: Array<Product_Unit_Sales_Price_Config>;
  /** An aggregate relationship */
  productUnitSalesPriceConfigsViaOrderUnit_aggregate: Product_Unit_Sales_Price_Config_Aggregate;
  /** An array relationship */
  productUnitSalesPriceConfigsViaSalesUnit: Array<Product_Unit_Sales_Price_Config>;
  /** An aggregate relationship */
  productUnitSalesPriceConfigsViaSalesUnit_aggregate: Product_Unit_Sales_Price_Config_Aggregate;
  /** An array relationship */
  rollingInventoryItems: Array<Rolling_Inventory_Items>;
  /** An array relationship */
  rollingInventoryItemsViaMonetaryUnit: Array<Rolling_Inventory_Items>;
  /** An aggregate relationship */
  rollingInventoryItemsViaMonetaryUnit_aggregate: Rolling_Inventory_Items_Aggregate;
  /** An array relationship */
  rollingInventoryItemsViaStorageUnit: Array<Rolling_Inventory_Items>;
  /** An aggregate relationship */
  rollingInventoryItemsViaStorageUnit_aggregate: Rolling_Inventory_Items_Aggregate;
  /** An aggregate relationship */
  rollingInventoryItems_aggregate: Rolling_Inventory_Items_Aggregate;
  /** An array relationship */
  rollingInventoryLotSummaryItemsViaMonetaryUnit: Array<Rolling_Inventory_Lot_Summary_Items>;
  /** An aggregate relationship */
  rollingInventoryLotSummaryItemsViaMonetaryUnit_aggregate: Rolling_Inventory_Lot_Summary_Items_Aggregate;
  /** An array relationship */
  rollingInventoryLotSummaryItemsViaStorageUnit: Array<Rolling_Inventory_Lot_Summary_Items>;
  /** An aggregate relationship */
  rollingInventoryLotSummaryItemsViaStorageUnit_aggregate: Rolling_Inventory_Lot_Summary_Items_Aggregate;
  /** An array relationship */
  rollingInventorySummaryItemsViaMonetaryUnit: Array<Rolling_Inventory_Summary_Items>;
  /** An aggregate relationship */
  rollingInventorySummaryItemsViaMonetaryUnit_aggregate: Rolling_Inventory_Summary_Items_Aggregate;
  /** An array relationship */
  rollingInventorySummaryItemsViaStorageUnit: Array<Rolling_Inventory_Summary_Items>;
  /** An aggregate relationship */
  rollingInventorySummaryItemsViaStorageUnit_aggregate: Rolling_Inventory_Summary_Items_Aggregate;
  significantDigits: Scalars['smallint'];
  state: Product_Unit_States_Enum;
  /** An array relationship */
  stockTransactionsViaSourceMonetaryUnit: Array<Stock_Transactions>;
  /** An aggregate relationship */
  stockTransactionsViaSourceMonetaryUnit_aggregate: Stock_Transactions_Aggregate;
  /** An array relationship */
  stockTransactionsViaSourceStorageUnit: Array<Stock_Transactions>;
  /** An aggregate relationship */
  stockTransactionsViaSourceStorageUnit_aggregate: Stock_Transactions_Aggregate;
  /** An array relationship */
  stockTransactionsViaTargetMonetaryUnit: Array<Stock_Transactions>;
  /** An aggregate relationship */
  stockTransactionsViaTargetMonetaryUnit_aggregate: Stock_Transactions_Aggregate;
  /** An array relationship */
  stockTransactionsViaTargetStorageUnit: Array<Stock_Transactions>;
  /** An aggregate relationship */
  stockTransactionsViaTargetStorageUnit_aggregate: Stock_Transactions_Aggregate;
  /** An array relationship */
  supplierOrderGoodsIncomeItemsViaReceivedMonetaryUnit: Array<Supplier_Order_Goods_Income_Items>;
  /** An aggregate relationship */
  supplierOrderGoodsIncomeItemsViaReceivedMonetaryUnit_aggregate: Supplier_Order_Goods_Income_Items_Aggregate;
  /** An array relationship */
  supplierOrderGoodsIncomeItemsViaReceivedStorageUnit: Array<Supplier_Order_Goods_Income_Items>;
  /** An aggregate relationship */
  supplierOrderGoodsIncomeItemsViaReceivedStorageUnit_aggregate: Supplier_Order_Goods_Income_Items_Aggregate;
  /** An array relationship */
  supplierOrderGoodsIncomeItemsViaTargetMonetaryUnit: Array<Supplier_Order_Goods_Income_Items>;
  /** An aggregate relationship */
  supplierOrderGoodsIncomeItemsViaTargetMonetaryUnit_aggregate: Supplier_Order_Goods_Income_Items_Aggregate;
  /** An array relationship */
  supplierOrderGoodsIncomeItemsViaTargetStorageUnit: Array<Supplier_Order_Goods_Income_Items>;
  /** An aggregate relationship */
  supplierOrderGoodsIncomeItemsViaTargetStorageUnit_aggregate: Supplier_Order_Goods_Income_Items_Aggregate;
  /** An array relationship */
  supplierOrderItems: Array<Supplier_Order_Items>;
  /** An array relationship */
  supplierOrderItemsViaOrderUnit: Array<Supplier_Order_Items>;
  /** An aggregate relationship */
  supplierOrderItemsViaOrderUnit_aggregate: Supplier_Order_Items_Aggregate;
  /** An aggregate relationship */
  supplierOrderItems_aggregate: Supplier_Order_Items_Aggregate;
  /** An array relationship */
  supplierOrderReturnItemsViaReturnedMonetaryUnit: Array<Supplier_Order_Return_Items>;
  /** An aggregate relationship */
  supplierOrderReturnItemsViaReturnedMonetaryUnit_aggregate: Supplier_Order_Return_Items_Aggregate;
  /** An array relationship */
  supplierOrderReturnItemsViaReturnedStorageUnit: Array<Supplier_Order_Return_Items>;
  /** An aggregate relationship */
  supplierOrderReturnItemsViaReturnedStorageUnit_aggregate: Supplier_Order_Return_Items_Aggregate;
  /** An array relationship */
  supplierOrderReturnItemsViaSourceMonetaryUnit: Array<Supplier_Order_Return_Items>;
  /** An aggregate relationship */
  supplierOrderReturnItemsViaSourceMonetaryUnit_aggregate: Supplier_Order_Return_Items_Aggregate;
  /** An array relationship */
  supplierOrderReturnItemsViaSourceStorageUnit: Array<Supplier_Order_Return_Items>;
  /** An aggregate relationship */
  supplierOrderReturnItemsViaSourceStorageUnit_aggregate: Supplier_Order_Return_Items_Aggregate;
  /** An array relationship */
  temporalProducts: Array<Temporal_Products>;
  /** An array relationship */
  temporalProductsViaCostPriceUnit: Array<Temporal_Products>;
  /** An aggregate relationship */
  temporalProductsViaCostPriceUnit_aggregate: Temporal_Products_Aggregate;
  /** An aggregate relationship */
  temporalProducts_aggregate: Temporal_Products_Aggregate;
  tenant: Scalars['String'];
  unit: Units_Enum;
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "product_units" */
export type Product_UnitsAggregatedDemandPlanResultsViaMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsAggregatedDemandPlanResultsViaMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsAggregatedDemandPlanResultsViaStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsAggregatedDemandPlanResultsViaStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsAggregatedInventoryItemsByLocationViaMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsAggregatedInventoryItemsByLocationViaMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsAggregatedInventoryItemsByLocationViaStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsAggregatedInventoryItemsByLocationViaStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCalculateCostPriceForProductResultsArgs = {
  distinct_on?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Order_By>>;
  where?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCalculateCostPriceForProductResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Order_By>>;
  where?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCustomerGroupPricingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCustomerGroupPricings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCustomerOrderContainerReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Return_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCustomerOrderContainerReturnItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Return_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCustomerOrderItemAdjustmentsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustments_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCustomerOrderItemAdjustments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustments_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCustomerOrderItemFulfillmentPlansArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCustomerOrderItemFulfillmentPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCustomerOrderItemsViaOrderUnitArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCustomerOrderItemsViaOrderUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCustomerOrderItemsViaSalesUnitArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCustomerOrderItemsViaSalesUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCustomerOrderReturnItemsViaReturnedMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCustomerOrderReturnItemsViaReturnedMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCustomerOrderReturnItemsViaReturnedStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCustomerOrderReturnItemsViaReturnedStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCustomerOrderReturnItemsViaTargetMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCustomerOrderReturnItemsViaTargetMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCustomerOrderReturnItemsViaTargetStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCustomerOrderReturnItemsViaTargetStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCuttingPatternOutputsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCuttingPatternOutputsDraftsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCuttingPatternOutputsDrafts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsCuttingPatternOutputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsDemandsForCoiResultsArgs = {
  distinct_on?: InputMaybe<Array<Demands_For_Coi_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Demands_For_Coi_Results_Order_By>>;
  where?: InputMaybe<Demands_For_Coi_Results_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsDemandsForCoiResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Demands_For_Coi_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Demands_For_Coi_Results_Order_By>>;
  where?: InputMaybe<Demands_For_Coi_Results_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsDismantlingPlanDemandsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Demands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Demands_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsDismantlingPlanDemands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Demands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Demands_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsInventoryDailyPrognosisResultsViaMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsInventoryDailyPrognosisResultsViaMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsInventoryDailyPrognosisResultsViaStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsInventoryDailyPrognosisResultsViaStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsInventoryDailyUsageResultViaMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Usage_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Usage_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Usage_Results_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsInventoryDailyUsageResultViaMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Usage_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Usage_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Usage_Results_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsInventoryDailyUsageResultViaStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Usage_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Usage_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Usage_Results_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsInventoryDailyUsageResultViaStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Usage_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Usage_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Usage_Results_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsInventoryItemsByLocationViaMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsInventoryItemsByLocationViaMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsInventoryItemsByLocationViaStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsInventoryItemsByLocationViaStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsInventoryItemsViaMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsInventoryItemsViaMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsInventoryItemsViaStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsInventoryItemsViaStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsListPurchasedProductsResultViaMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<List_Purchased_Products_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Purchased_Products_Result_Order_By>>;
  where?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsListPurchasedProductsResultViaMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Purchased_Products_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Purchased_Products_Result_Order_By>>;
  where?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsListPurchasedProductsResultViaStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<List_Purchased_Products_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Purchased_Products_Result_Order_By>>;
  where?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsListPurchasedProductsResultViaStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Purchased_Products_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Purchased_Products_Result_Order_By>>;
  where?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsLotNumberProductsArgs = {
  distinct_on?: InputMaybe<Array<Lot_Number_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Number_Products_Order_By>>;
  where?: InputMaybe<Lot_Number_Products_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsLotNumberProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lot_Number_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Number_Products_Order_By>>;
  where?: InputMaybe<Lot_Number_Products_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsPickedItemsArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsPickedItemsViaSourceMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsPickedItemsViaSourceMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsPickedItemsViaSourceStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsPickedItemsViaSourceStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsPickedItemsViaTargetMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsPickedItemsViaTargetMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsPickedItemsViaTargetStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsPickedItemsViaTargetStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsPickedItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsPostingsViaMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Postings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postings_Order_By>>;
  where?: InputMaybe<Postings_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsPostingsViaMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Postings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postings_Order_By>>;
  where?: InputMaybe<Postings_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsPostingsViaStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Postings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postings_Order_By>>;
  where?: InputMaybe<Postings_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsPostingsViaStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Postings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postings_Order_By>>;
  where?: InputMaybe<Postings_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsProductRecipeIngredientsArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_Ingredients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_Ingredients_Order_By>>;
  where?: InputMaybe<Product_Recipe_Ingredients_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsProductRecipeIngredients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_Ingredients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_Ingredients_Order_By>>;
  where?: InputMaybe<Product_Recipe_Ingredients_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsProductRecipesArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipes_Order_By>>;
  where?: InputMaybe<Product_Recipes_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsProductRecipes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipes_Order_By>>;
  where?: InputMaybe<Product_Recipes_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsProductStockLocationsViaMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Product_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Stock_Locations_Order_By>>;
  where?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsProductStockLocationsViaMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Stock_Locations_Order_By>>;
  where?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsProductStockLocationsViaStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Product_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Stock_Locations_Order_By>>;
  where?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsProductStockLocationsViaStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Stock_Locations_Order_By>>;
  where?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsProductSuppliersViaOrderUnitArgs = {
  distinct_on?: InputMaybe<Array<Product_Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Suppliers_Order_By>>;
  where?: InputMaybe<Product_Suppliers_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsProductSuppliersViaOrderUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Suppliers_Order_By>>;
  where?: InputMaybe<Product_Suppliers_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsProductSuppliersViaPurchasingUnitArgs = {
  distinct_on?: InputMaybe<Array<Product_Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Suppliers_Order_By>>;
  where?: InputMaybe<Product_Suppliers_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsProductSuppliersViaPurchasingUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Suppliers_Order_By>>;
  where?: InputMaybe<Product_Suppliers_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsProductUnitSalesPriceConfigsViaOrderUnitArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Order_By>>;
  where?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsProductUnitSalesPriceConfigsViaOrderUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Order_By>>;
  where?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsProductUnitSalesPriceConfigsViaSalesUnitArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Order_By>>;
  where?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsProductUnitSalesPriceConfigsViaSalesUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Order_By>>;
  where?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsRollingInventoryItemsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsRollingInventoryItemsViaMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsRollingInventoryItemsViaMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsRollingInventoryItemsViaStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsRollingInventoryItemsViaStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsRollingInventoryItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsRollingInventoryLotSummaryItemsViaMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsRollingInventoryLotSummaryItemsViaMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsRollingInventoryLotSummaryItemsViaStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsRollingInventoryLotSummaryItemsViaStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsRollingInventorySummaryItemsViaMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsRollingInventorySummaryItemsViaMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsRollingInventorySummaryItemsViaStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsRollingInventorySummaryItemsViaStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsStockTransactionsViaSourceMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsStockTransactionsViaSourceMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsStockTransactionsViaSourceStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsStockTransactionsViaSourceStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsStockTransactionsViaTargetMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsStockTransactionsViaTargetMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsStockTransactionsViaTargetStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsStockTransactionsViaTargetStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsSupplierOrderGoodsIncomeItemsViaReceivedMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsSupplierOrderGoodsIncomeItemsViaReceivedMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsSupplierOrderGoodsIncomeItemsViaReceivedStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsSupplierOrderGoodsIncomeItemsViaReceivedStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsSupplierOrderGoodsIncomeItemsViaTargetMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsSupplierOrderGoodsIncomeItemsViaTargetMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsSupplierOrderGoodsIncomeItemsViaTargetStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsSupplierOrderGoodsIncomeItemsViaTargetStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsSupplierOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsSupplierOrderItemsViaOrderUnitArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsSupplierOrderItemsViaOrderUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsSupplierOrderItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsSupplierOrderReturnItemsViaReturnedMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsSupplierOrderReturnItemsViaReturnedMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsSupplierOrderReturnItemsViaReturnedStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsSupplierOrderReturnItemsViaReturnedStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsSupplierOrderReturnItemsViaSourceMonetaryUnitArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsSupplierOrderReturnItemsViaSourceMonetaryUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsSupplierOrderReturnItemsViaSourceStorageUnitArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsSupplierOrderReturnItemsViaSourceStorageUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsTemporalProductsArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsTemporalProductsViaCostPriceUnitArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsTemporalProductsViaCostPriceUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


/** columns and relationships of "product_units" */
export type Product_UnitsTemporalProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};

/** aggregated selection of "product_units" */
export type Product_Units_Aggregate = {
  __typename: 'product_units_aggregate';
  aggregate?: Maybe<Product_Units_Aggregate_Fields>;
  nodes: Array<Product_Units>;
};

export type Product_Units_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Units_Aggregate_Bool_Exp_Count>;
};

export type Product_Units_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Units_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Product_Units_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_units" */
export type Product_Units_Aggregate_Fields = {
  __typename: 'product_units_aggregate_fields';
  avg?: Maybe<Product_Units_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Units_Max_Fields>;
  min?: Maybe<Product_Units_Min_Fields>;
  stddev?: Maybe<Product_Units_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Units_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Units_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Units_Sum_Fields>;
  var_pop?: Maybe<Product_Units_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Units_Var_Samp_Fields>;
  variance?: Maybe<Product_Units_Variance_Fields>;
};


/** aggregate fields of "product_units" */
export type Product_Units_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Units_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_units" */
export type Product_Units_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Units_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Units_Max_Order_By>;
  min?: InputMaybe<Product_Units_Min_Order_By>;
  stddev?: InputMaybe<Product_Units_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Units_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Units_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Units_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Units_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Units_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Units_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_units" */
export type Product_Units_Arr_Rel_Insert_Input = {
  data: Array<Product_Units_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Units_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Units_Avg_Fields = {
  __typename: 'product_units_avg_fields';
  depletionThreshold?: Maybe<Scalars['Float']>;
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  significantDigits?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_units" */
export type Product_Units_Avg_Order_By = {
  depletionThreshold?: InputMaybe<Order_By>;
  factor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  significantDigits?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_units". All fields are combined with a logical 'AND'. */
export type Product_Units_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Product_Units_Bool_Exp>>;
  _not?: InputMaybe<Product_Units_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Units_Bool_Exp>>;
  aggregatedDemandPlanResultsViaMonetaryUnit?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
  aggregatedDemandPlanResultsViaMonetaryUnit_aggregate?: InputMaybe<Aggregated_Demand_Plan_Results_Aggregate_Bool_Exp>;
  aggregatedDemandPlanResultsViaStorageUnit?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
  aggregatedDemandPlanResultsViaStorageUnit_aggregate?: InputMaybe<Aggregated_Demand_Plan_Results_Aggregate_Bool_Exp>;
  aggregatedInventoryItemsByLocationViaMonetaryUnit?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
  aggregatedInventoryItemsByLocationViaMonetaryUnit_aggregate?: InputMaybe<Aggregated_Inventory_Items_By_Location_Aggregate_Bool_Exp>;
  aggregatedInventoryItemsByLocationViaStorageUnit?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
  aggregatedInventoryItemsByLocationViaStorageUnit_aggregate?: InputMaybe<Aggregated_Inventory_Items_By_Location_Aggregate_Bool_Exp>;
  calculateCostPriceForProductResults?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
  calculateCostPriceForProductResults_aggregate?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerGroupPricings?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
  customerGroupPricings_aggregate?: InputMaybe<Customer_Group_Pricing_Aggregate_Bool_Exp>;
  customerOrderContainerReturnItems?: InputMaybe<Customer_Order_Container_Return_Items_Bool_Exp>;
  customerOrderContainerReturnItems_aggregate?: InputMaybe<Customer_Order_Container_Return_Items_Aggregate_Bool_Exp>;
  customerOrderItemAdjustments?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
  customerOrderItemAdjustments_aggregate?: InputMaybe<Customer_Order_Item_Adjustments_Aggregate_Bool_Exp>;
  customerOrderItemFulfillmentPlans?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
  customerOrderItemFulfillmentPlans_aggregate?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Aggregate_Bool_Exp>;
  customerOrderItemsViaOrderUnit?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  customerOrderItemsViaOrderUnit_aggregate?: InputMaybe<Customer_Order_Items_Aggregate_Bool_Exp>;
  customerOrderItemsViaSalesUnit?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  customerOrderItemsViaSalesUnit_aggregate?: InputMaybe<Customer_Order_Items_Aggregate_Bool_Exp>;
  customerOrderReturnItemsViaReturnedMonetaryUnit?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
  customerOrderReturnItemsViaReturnedMonetaryUnit_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Bool_Exp>;
  customerOrderReturnItemsViaReturnedStorageUnit?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
  customerOrderReturnItemsViaReturnedStorageUnit_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Bool_Exp>;
  customerOrderReturnItemsViaTargetMonetaryUnit?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
  customerOrderReturnItemsViaTargetMonetaryUnit_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Bool_Exp>;
  customerOrderReturnItemsViaTargetStorageUnit?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
  customerOrderReturnItemsViaTargetStorageUnit_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Bool_Exp>;
  cuttingPatternOutputs?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
  cuttingPatternOutputsDrafts?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
  cuttingPatternOutputsDrafts_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Draft_Aggregate_Bool_Exp>;
  cuttingPatternOutputs_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Aggregate_Bool_Exp>;
  demandsForCoiResults?: InputMaybe<Demands_For_Coi_Results_Bool_Exp>;
  demandsForCoiResults_aggregate?: InputMaybe<Demands_For_Coi_Results_Aggregate_Bool_Exp>;
  depletionThreshold?: InputMaybe<Bigint_Comparison_Exp>;
  dismantlingPlanDemands?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
  dismantlingPlanDemands_aggregate?: InputMaybe<Dismantling_Plan_Demands_Aggregate_Bool_Exp>;
  factor?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  inventoryDailyPrognosisResultsViaMonetaryUnit?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
  inventoryDailyPrognosisResultsViaMonetaryUnit_aggregate?: InputMaybe<Inventory_Daily_Prognosis_Results_Aggregate_Bool_Exp>;
  inventoryDailyPrognosisResultsViaStorageUnit?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
  inventoryDailyPrognosisResultsViaStorageUnit_aggregate?: InputMaybe<Inventory_Daily_Prognosis_Results_Aggregate_Bool_Exp>;
  inventoryDailyUsageResultViaMonetaryUnit?: InputMaybe<Inventory_Daily_Usage_Results_Bool_Exp>;
  inventoryDailyUsageResultViaMonetaryUnit_aggregate?: InputMaybe<Inventory_Daily_Usage_Results_Aggregate_Bool_Exp>;
  inventoryDailyUsageResultViaStorageUnit?: InputMaybe<Inventory_Daily_Usage_Results_Bool_Exp>;
  inventoryDailyUsageResultViaStorageUnit_aggregate?: InputMaybe<Inventory_Daily_Usage_Results_Aggregate_Bool_Exp>;
  inventoryItemsByLocationViaMonetaryUnit?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
  inventoryItemsByLocationViaMonetaryUnit_aggregate?: InputMaybe<Inventory_Items_By_Location_Aggregate_Bool_Exp>;
  inventoryItemsByLocationViaStorageUnit?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
  inventoryItemsByLocationViaStorageUnit_aggregate?: InputMaybe<Inventory_Items_By_Location_Aggregate_Bool_Exp>;
  inventoryItemsViaMonetaryUnit?: InputMaybe<Inventory_Items_Bool_Exp>;
  inventoryItemsViaMonetaryUnit_aggregate?: InputMaybe<Inventory_Items_Aggregate_Bool_Exp>;
  inventoryItemsViaStorageUnit?: InputMaybe<Inventory_Items_Bool_Exp>;
  inventoryItemsViaStorageUnit_aggregate?: InputMaybe<Inventory_Items_Aggregate_Bool_Exp>;
  listPurchasedProductsResultViaMonetaryUnit?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
  listPurchasedProductsResultViaMonetaryUnit_aggregate?: InputMaybe<List_Purchased_Products_Result_Aggregate_Bool_Exp>;
  listPurchasedProductsResultViaStorageUnit?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
  listPurchasedProductsResultViaStorageUnit_aggregate?: InputMaybe<List_Purchased_Products_Result_Aggregate_Bool_Exp>;
  lotNumberProducts?: InputMaybe<Lot_Number_Products_Bool_Exp>;
  lotNumberProducts_aggregate?: InputMaybe<Lot_Number_Products_Aggregate_Bool_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  pickedItems?: InputMaybe<Picked_Items_Bool_Exp>;
  pickedItemsViaSourceMonetaryUnit?: InputMaybe<Picked_Items_Bool_Exp>;
  pickedItemsViaSourceMonetaryUnit_aggregate?: InputMaybe<Picked_Items_Aggregate_Bool_Exp>;
  pickedItemsViaSourceStorageUnit?: InputMaybe<Picked_Items_Bool_Exp>;
  pickedItemsViaSourceStorageUnit_aggregate?: InputMaybe<Picked_Items_Aggregate_Bool_Exp>;
  pickedItemsViaTargetMonetaryUnit?: InputMaybe<Picked_Items_Bool_Exp>;
  pickedItemsViaTargetMonetaryUnit_aggregate?: InputMaybe<Picked_Items_Aggregate_Bool_Exp>;
  pickedItemsViaTargetStorageUnit?: InputMaybe<Picked_Items_Bool_Exp>;
  pickedItemsViaTargetStorageUnit_aggregate?: InputMaybe<Picked_Items_Aggregate_Bool_Exp>;
  pickedItems_aggregate?: InputMaybe<Picked_Items_Aggregate_Bool_Exp>;
  postingsViaMonetaryUnit?: InputMaybe<Postings_Bool_Exp>;
  postingsViaMonetaryUnit_aggregate?: InputMaybe<Postings_Aggregate_Bool_Exp>;
  postingsViaStorageUnit?: InputMaybe<Postings_Bool_Exp>;
  postingsViaStorageUnit_aggregate?: InputMaybe<Postings_Aggregate_Bool_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  productRecipeIngredients?: InputMaybe<Product_Recipe_Ingredients_Bool_Exp>;
  productRecipeIngredients_aggregate?: InputMaybe<Product_Recipe_Ingredients_Aggregate_Bool_Exp>;
  productRecipes?: InputMaybe<Product_Recipes_Bool_Exp>;
  productRecipes_aggregate?: InputMaybe<Product_Recipes_Aggregate_Bool_Exp>;
  productStockLocationsViaMonetaryUnit?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
  productStockLocationsViaMonetaryUnit_aggregate?: InputMaybe<Product_Stock_Locations_Aggregate_Bool_Exp>;
  productStockLocationsViaStorageUnit?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
  productStockLocationsViaStorageUnit_aggregate?: InputMaybe<Product_Stock_Locations_Aggregate_Bool_Exp>;
  productSuppliersViaOrderUnit?: InputMaybe<Product_Suppliers_Bool_Exp>;
  productSuppliersViaOrderUnit_aggregate?: InputMaybe<Product_Suppliers_Aggregate_Bool_Exp>;
  productSuppliersViaPurchasingUnit?: InputMaybe<Product_Suppliers_Bool_Exp>;
  productSuppliersViaPurchasingUnit_aggregate?: InputMaybe<Product_Suppliers_Aggregate_Bool_Exp>;
  productUnitName?: InputMaybe<String_Comparison_Exp>;
  productUnitSalesPriceConfigsViaOrderUnit?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
  productUnitSalesPriceConfigsViaOrderUnit_aggregate?: InputMaybe<Product_Unit_Sales_Price_Config_Aggregate_Bool_Exp>;
  productUnitSalesPriceConfigsViaSalesUnit?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
  productUnitSalesPriceConfigsViaSalesUnit_aggregate?: InputMaybe<Product_Unit_Sales_Price_Config_Aggregate_Bool_Exp>;
  rollingInventoryItems?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
  rollingInventoryItemsViaMonetaryUnit?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
  rollingInventoryItemsViaMonetaryUnit_aggregate?: InputMaybe<Rolling_Inventory_Items_Aggregate_Bool_Exp>;
  rollingInventoryItemsViaStorageUnit?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
  rollingInventoryItemsViaStorageUnit_aggregate?: InputMaybe<Rolling_Inventory_Items_Aggregate_Bool_Exp>;
  rollingInventoryItems_aggregate?: InputMaybe<Rolling_Inventory_Items_Aggregate_Bool_Exp>;
  rollingInventoryLotSummaryItemsViaMonetaryUnit?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
  rollingInventoryLotSummaryItemsViaMonetaryUnit_aggregate?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Aggregate_Bool_Exp>;
  rollingInventoryLotSummaryItemsViaStorageUnit?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
  rollingInventoryLotSummaryItemsViaStorageUnit_aggregate?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Aggregate_Bool_Exp>;
  rollingInventorySummaryItemsViaMonetaryUnit?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
  rollingInventorySummaryItemsViaMonetaryUnit_aggregate?: InputMaybe<Rolling_Inventory_Summary_Items_Aggregate_Bool_Exp>;
  rollingInventorySummaryItemsViaStorageUnit?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
  rollingInventorySummaryItemsViaStorageUnit_aggregate?: InputMaybe<Rolling_Inventory_Summary_Items_Aggregate_Bool_Exp>;
  significantDigits?: InputMaybe<Smallint_Comparison_Exp>;
  state?: InputMaybe<Product_Unit_States_Enum_Comparison_Exp>;
  stockTransactionsViaSourceMonetaryUnit?: InputMaybe<Stock_Transactions_Bool_Exp>;
  stockTransactionsViaSourceMonetaryUnit_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Bool_Exp>;
  stockTransactionsViaSourceStorageUnit?: InputMaybe<Stock_Transactions_Bool_Exp>;
  stockTransactionsViaSourceStorageUnit_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Bool_Exp>;
  stockTransactionsViaTargetMonetaryUnit?: InputMaybe<Stock_Transactions_Bool_Exp>;
  stockTransactionsViaTargetMonetaryUnit_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Bool_Exp>;
  stockTransactionsViaTargetStorageUnit?: InputMaybe<Stock_Transactions_Bool_Exp>;
  stockTransactionsViaTargetStorageUnit_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Bool_Exp>;
  supplierOrderGoodsIncomeItemsViaReceivedMonetaryUnit?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
  supplierOrderGoodsIncomeItemsViaReceivedMonetaryUnit_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Bool_Exp>;
  supplierOrderGoodsIncomeItemsViaReceivedStorageUnit?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
  supplierOrderGoodsIncomeItemsViaReceivedStorageUnit_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Bool_Exp>;
  supplierOrderGoodsIncomeItemsViaTargetMonetaryUnit?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
  supplierOrderGoodsIncomeItemsViaTargetMonetaryUnit_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Bool_Exp>;
  supplierOrderGoodsIncomeItemsViaTargetStorageUnit?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
  supplierOrderGoodsIncomeItemsViaTargetStorageUnit_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Bool_Exp>;
  supplierOrderItems?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
  supplierOrderItemsViaOrderUnit?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
  supplierOrderItemsViaOrderUnit_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Bool_Exp>;
  supplierOrderItems_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Bool_Exp>;
  supplierOrderReturnItemsViaReturnedMonetaryUnit?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
  supplierOrderReturnItemsViaReturnedMonetaryUnit_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Bool_Exp>;
  supplierOrderReturnItemsViaReturnedStorageUnit?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
  supplierOrderReturnItemsViaReturnedStorageUnit_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Bool_Exp>;
  supplierOrderReturnItemsViaSourceMonetaryUnit?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
  supplierOrderReturnItemsViaSourceMonetaryUnit_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Bool_Exp>;
  supplierOrderReturnItemsViaSourceStorageUnit?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
  supplierOrderReturnItemsViaSourceStorageUnit_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Bool_Exp>;
  temporalProducts?: InputMaybe<Temporal_Products_Bool_Exp>;
  temporalProductsViaCostPriceUnit?: InputMaybe<Temporal_Products_Bool_Exp>;
  temporalProductsViaCostPriceUnit_aggregate?: InputMaybe<Temporal_Products_Aggregate_Bool_Exp>;
  temporalProducts_aggregate?: InputMaybe<Temporal_Products_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  unit?: InputMaybe<Units_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_units" */
export enum Product_Units_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductUnitsPkey = 'product_units_pkey',
  /** unique or primary key constraint on columns "product_id", "ordinal_position" */
  ProductUnitsProductIdOrdinalPositionKey = 'product_units_product_id_ordinal_position_key',
  /** unique or primary key constraint on columns "product_unit_name", "product_id" */
  ProductUnitsProductIdProductUnitNameIdx = 'product_units_product_id_product_unit_name_idx',
  /** unique or primary key constraint on columns "product_id", "unit" */
  ProductUnitsProductIdUnitIdx = 'product_units_product_id_unit_idx'
}

/** input type for incrementing numeric columns in table "product_units" */
export type Product_Units_Inc_Input = {
  depletionThreshold?: InputMaybe<Scalars['bigint']>;
  factor?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['bigint']>;
  significantDigits?: InputMaybe<Scalars['smallint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_units" */
export type Product_Units_Insert_Input = {
  aggregatedInventoryItemsByLocationViaMonetaryUnit?: InputMaybe<Aggregated_Inventory_Items_By_Location_Arr_Rel_Insert_Input>;
  aggregatedInventoryItemsByLocationViaStorageUnit?: InputMaybe<Aggregated_Inventory_Items_By_Location_Arr_Rel_Insert_Input>;
  customerGroupPricings?: InputMaybe<Customer_Group_Pricing_Arr_Rel_Insert_Input>;
  customerOrderContainerReturnItems?: InputMaybe<Customer_Order_Container_Return_Items_Arr_Rel_Insert_Input>;
  customerOrderItemAdjustments?: InputMaybe<Customer_Order_Item_Adjustments_Arr_Rel_Insert_Input>;
  customerOrderItemFulfillmentPlans?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Arr_Rel_Insert_Input>;
  customerOrderItemsViaOrderUnit?: InputMaybe<Customer_Order_Items_Arr_Rel_Insert_Input>;
  customerOrderItemsViaSalesUnit?: InputMaybe<Customer_Order_Items_Arr_Rel_Insert_Input>;
  customerOrderReturnItemsViaReturnedMonetaryUnit?: InputMaybe<Customer_Order_Return_Items_Arr_Rel_Insert_Input>;
  customerOrderReturnItemsViaReturnedStorageUnit?: InputMaybe<Customer_Order_Return_Items_Arr_Rel_Insert_Input>;
  customerOrderReturnItemsViaTargetMonetaryUnit?: InputMaybe<Customer_Order_Return_Items_Arr_Rel_Insert_Input>;
  customerOrderReturnItemsViaTargetStorageUnit?: InputMaybe<Customer_Order_Return_Items_Arr_Rel_Insert_Input>;
  cuttingPatternOutputs?: InputMaybe<Cutting_Pattern_Outputs_Arr_Rel_Insert_Input>;
  cuttingPatternOutputsDrafts?: InputMaybe<Cutting_Pattern_Outputs_Draft_Arr_Rel_Insert_Input>;
  depletionThreshold?: InputMaybe<Scalars['bigint']>;
  dismantlingPlanDemands?: InputMaybe<Dismantling_Plan_Demands_Arr_Rel_Insert_Input>;
  factor?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['bigint']>;
  inventoryItemsByLocationViaMonetaryUnit?: InputMaybe<Inventory_Items_By_Location_Arr_Rel_Insert_Input>;
  inventoryItemsByLocationViaStorageUnit?: InputMaybe<Inventory_Items_By_Location_Arr_Rel_Insert_Input>;
  inventoryItemsViaMonetaryUnit?: InputMaybe<Inventory_Items_Arr_Rel_Insert_Input>;
  inventoryItemsViaStorageUnit?: InputMaybe<Inventory_Items_Arr_Rel_Insert_Input>;
  lotNumberProducts?: InputMaybe<Lot_Number_Products_Arr_Rel_Insert_Input>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  pickedItems?: InputMaybe<Picked_Items_Arr_Rel_Insert_Input>;
  pickedItemsViaSourceMonetaryUnit?: InputMaybe<Picked_Items_Arr_Rel_Insert_Input>;
  pickedItemsViaSourceStorageUnit?: InputMaybe<Picked_Items_Arr_Rel_Insert_Input>;
  pickedItemsViaTargetMonetaryUnit?: InputMaybe<Picked_Items_Arr_Rel_Insert_Input>;
  pickedItemsViaTargetStorageUnit?: InputMaybe<Picked_Items_Arr_Rel_Insert_Input>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  productRecipeIngredients?: InputMaybe<Product_Recipe_Ingredients_Arr_Rel_Insert_Input>;
  productRecipes?: InputMaybe<Product_Recipes_Arr_Rel_Insert_Input>;
  productStockLocationsViaMonetaryUnit?: InputMaybe<Product_Stock_Locations_Arr_Rel_Insert_Input>;
  productStockLocationsViaStorageUnit?: InputMaybe<Product_Stock_Locations_Arr_Rel_Insert_Input>;
  productSuppliersViaOrderUnit?: InputMaybe<Product_Suppliers_Arr_Rel_Insert_Input>;
  productSuppliersViaPurchasingUnit?: InputMaybe<Product_Suppliers_Arr_Rel_Insert_Input>;
  productUnitName?: InputMaybe<Scalars['String']>;
  productUnitSalesPriceConfigsViaOrderUnit?: InputMaybe<Product_Unit_Sales_Price_Config_Arr_Rel_Insert_Input>;
  productUnitSalesPriceConfigsViaSalesUnit?: InputMaybe<Product_Unit_Sales_Price_Config_Arr_Rel_Insert_Input>;
  rollingInventoryItems?: InputMaybe<Rolling_Inventory_Items_Arr_Rel_Insert_Input>;
  rollingInventoryItemsViaMonetaryUnit?: InputMaybe<Rolling_Inventory_Items_Arr_Rel_Insert_Input>;
  rollingInventoryItemsViaStorageUnit?: InputMaybe<Rolling_Inventory_Items_Arr_Rel_Insert_Input>;
  significantDigits?: InputMaybe<Scalars['smallint']>;
  state?: InputMaybe<Product_Unit_States_Enum>;
  stockTransactionsViaSourceMonetaryUnit?: InputMaybe<Stock_Transactions_Arr_Rel_Insert_Input>;
  stockTransactionsViaSourceStorageUnit?: InputMaybe<Stock_Transactions_Arr_Rel_Insert_Input>;
  stockTransactionsViaTargetMonetaryUnit?: InputMaybe<Stock_Transactions_Arr_Rel_Insert_Input>;
  stockTransactionsViaTargetStorageUnit?: InputMaybe<Stock_Transactions_Arr_Rel_Insert_Input>;
  supplierOrderGoodsIncomeItemsViaReceivedMonetaryUnit?: InputMaybe<Supplier_Order_Goods_Income_Items_Arr_Rel_Insert_Input>;
  supplierOrderGoodsIncomeItemsViaReceivedStorageUnit?: InputMaybe<Supplier_Order_Goods_Income_Items_Arr_Rel_Insert_Input>;
  supplierOrderGoodsIncomeItemsViaTargetMonetaryUnit?: InputMaybe<Supplier_Order_Goods_Income_Items_Arr_Rel_Insert_Input>;
  supplierOrderGoodsIncomeItemsViaTargetStorageUnit?: InputMaybe<Supplier_Order_Goods_Income_Items_Arr_Rel_Insert_Input>;
  supplierOrderItems?: InputMaybe<Supplier_Order_Items_Arr_Rel_Insert_Input>;
  supplierOrderItemsViaOrderUnit?: InputMaybe<Supplier_Order_Items_Arr_Rel_Insert_Input>;
  supplierOrderReturnItemsViaReturnedMonetaryUnit?: InputMaybe<Supplier_Order_Return_Items_Arr_Rel_Insert_Input>;
  supplierOrderReturnItemsViaReturnedStorageUnit?: InputMaybe<Supplier_Order_Return_Items_Arr_Rel_Insert_Input>;
  supplierOrderReturnItemsViaSourceMonetaryUnit?: InputMaybe<Supplier_Order_Return_Items_Arr_Rel_Insert_Input>;
  supplierOrderReturnItemsViaSourceStorageUnit?: InputMaybe<Supplier_Order_Return_Items_Arr_Rel_Insert_Input>;
  temporalProducts?: InputMaybe<Temporal_Products_Arr_Rel_Insert_Input>;
  temporalProductsViaCostPriceUnit?: InputMaybe<Temporal_Products_Arr_Rel_Insert_Input>;
  unit?: InputMaybe<Units_Enum>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Product_Units_Max_Fields = {
  __typename: 'product_units_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  depletionThreshold?: Maybe<Scalars['bigint']>;
  factor?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['bigint']>;
  productUnitName?: Maybe<Scalars['String']>;
  significantDigits?: Maybe<Scalars['smallint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "product_units" */
export type Product_Units_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  depletionThreshold?: InputMaybe<Order_By>;
  factor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitName?: InputMaybe<Order_By>;
  significantDigits?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Units_Min_Fields = {
  __typename: 'product_units_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  depletionThreshold?: Maybe<Scalars['bigint']>;
  factor?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['bigint']>;
  productUnitName?: Maybe<Scalars['String']>;
  significantDigits?: Maybe<Scalars['smallint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "product_units" */
export type Product_Units_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  depletionThreshold?: InputMaybe<Order_By>;
  factor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  productUnitName?: InputMaybe<Order_By>;
  significantDigits?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_units" */
export type Product_Units_Mutation_Response = {
  __typename: 'product_units_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Units>;
};

/** input type for inserting object relation for remote table "product_units" */
export type Product_Units_Obj_Rel_Insert_Input = {
  data: Product_Units_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Units_On_Conflict>;
};

/** on_conflict condition type for table "product_units" */
export type Product_Units_On_Conflict = {
  constraint: Product_Units_Constraint;
  update_columns?: Array<Product_Units_Update_Column>;
  where?: InputMaybe<Product_Units_Bool_Exp>;
};

/** Ordering options when selecting data from "product_units". */
export type Product_Units_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  aggregatedDemandPlanResultsViaMonetaryUnit_aggregate?: InputMaybe<Aggregated_Demand_Plan_Results_Aggregate_Order_By>;
  aggregatedDemandPlanResultsViaStorageUnit_aggregate?: InputMaybe<Aggregated_Demand_Plan_Results_Aggregate_Order_By>;
  aggregatedInventoryItemsByLocationViaMonetaryUnit_aggregate?: InputMaybe<Aggregated_Inventory_Items_By_Location_Aggregate_Order_By>;
  aggregatedInventoryItemsByLocationViaStorageUnit_aggregate?: InputMaybe<Aggregated_Inventory_Items_By_Location_Aggregate_Order_By>;
  calculateCostPriceForProductResults_aggregate?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerGroupPricings_aggregate?: InputMaybe<Customer_Group_Pricing_Aggregate_Order_By>;
  customerOrderContainerReturnItems_aggregate?: InputMaybe<Customer_Order_Container_Return_Items_Aggregate_Order_By>;
  customerOrderItemAdjustments_aggregate?: InputMaybe<Customer_Order_Item_Adjustments_Aggregate_Order_By>;
  customerOrderItemFulfillmentPlans_aggregate?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Aggregate_Order_By>;
  customerOrderItemsViaOrderUnit_aggregate?: InputMaybe<Customer_Order_Items_Aggregate_Order_By>;
  customerOrderItemsViaSalesUnit_aggregate?: InputMaybe<Customer_Order_Items_Aggregate_Order_By>;
  customerOrderReturnItemsViaReturnedMonetaryUnit_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Order_By>;
  customerOrderReturnItemsViaReturnedStorageUnit_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Order_By>;
  customerOrderReturnItemsViaTargetMonetaryUnit_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Order_By>;
  customerOrderReturnItemsViaTargetStorageUnit_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Order_By>;
  cuttingPatternOutputsDrafts_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Draft_Aggregate_Order_By>;
  cuttingPatternOutputs_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Aggregate_Order_By>;
  demandsForCoiResults_aggregate?: InputMaybe<Demands_For_Coi_Results_Aggregate_Order_By>;
  depletionThreshold?: InputMaybe<Order_By>;
  dismantlingPlanDemands_aggregate?: InputMaybe<Dismantling_Plan_Demands_Aggregate_Order_By>;
  factor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inventoryDailyPrognosisResultsViaMonetaryUnit_aggregate?: InputMaybe<Inventory_Daily_Prognosis_Results_Aggregate_Order_By>;
  inventoryDailyPrognosisResultsViaStorageUnit_aggregate?: InputMaybe<Inventory_Daily_Prognosis_Results_Aggregate_Order_By>;
  inventoryDailyUsageResultViaMonetaryUnit_aggregate?: InputMaybe<Inventory_Daily_Usage_Results_Aggregate_Order_By>;
  inventoryDailyUsageResultViaStorageUnit_aggregate?: InputMaybe<Inventory_Daily_Usage_Results_Aggregate_Order_By>;
  inventoryItemsByLocationViaMonetaryUnit_aggregate?: InputMaybe<Inventory_Items_By_Location_Aggregate_Order_By>;
  inventoryItemsByLocationViaStorageUnit_aggregate?: InputMaybe<Inventory_Items_By_Location_Aggregate_Order_By>;
  inventoryItemsViaMonetaryUnit_aggregate?: InputMaybe<Inventory_Items_Aggregate_Order_By>;
  inventoryItemsViaStorageUnit_aggregate?: InputMaybe<Inventory_Items_Aggregate_Order_By>;
  listPurchasedProductsResultViaMonetaryUnit_aggregate?: InputMaybe<List_Purchased_Products_Result_Aggregate_Order_By>;
  listPurchasedProductsResultViaStorageUnit_aggregate?: InputMaybe<List_Purchased_Products_Result_Aggregate_Order_By>;
  lotNumberProducts_aggregate?: InputMaybe<Lot_Number_Products_Aggregate_Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pickedItemsViaSourceMonetaryUnit_aggregate?: InputMaybe<Picked_Items_Aggregate_Order_By>;
  pickedItemsViaSourceStorageUnit_aggregate?: InputMaybe<Picked_Items_Aggregate_Order_By>;
  pickedItemsViaTargetMonetaryUnit_aggregate?: InputMaybe<Picked_Items_Aggregate_Order_By>;
  pickedItemsViaTargetStorageUnit_aggregate?: InputMaybe<Picked_Items_Aggregate_Order_By>;
  pickedItems_aggregate?: InputMaybe<Picked_Items_Aggregate_Order_By>;
  postingsViaMonetaryUnit_aggregate?: InputMaybe<Postings_Aggregate_Order_By>;
  postingsViaStorageUnit_aggregate?: InputMaybe<Postings_Aggregate_Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  productRecipeIngredients_aggregate?: InputMaybe<Product_Recipe_Ingredients_Aggregate_Order_By>;
  productRecipes_aggregate?: InputMaybe<Product_Recipes_Aggregate_Order_By>;
  productStockLocationsViaMonetaryUnit_aggregate?: InputMaybe<Product_Stock_Locations_Aggregate_Order_By>;
  productStockLocationsViaStorageUnit_aggregate?: InputMaybe<Product_Stock_Locations_Aggregate_Order_By>;
  productSuppliersViaOrderUnit_aggregate?: InputMaybe<Product_Suppliers_Aggregate_Order_By>;
  productSuppliersViaPurchasingUnit_aggregate?: InputMaybe<Product_Suppliers_Aggregate_Order_By>;
  productUnitName?: InputMaybe<Order_By>;
  productUnitSalesPriceConfigsViaOrderUnit_aggregate?: InputMaybe<Product_Unit_Sales_Price_Config_Aggregate_Order_By>;
  productUnitSalesPriceConfigsViaSalesUnit_aggregate?: InputMaybe<Product_Unit_Sales_Price_Config_Aggregate_Order_By>;
  rollingInventoryItemsViaMonetaryUnit_aggregate?: InputMaybe<Rolling_Inventory_Items_Aggregate_Order_By>;
  rollingInventoryItemsViaStorageUnit_aggregate?: InputMaybe<Rolling_Inventory_Items_Aggregate_Order_By>;
  rollingInventoryItems_aggregate?: InputMaybe<Rolling_Inventory_Items_Aggregate_Order_By>;
  rollingInventoryLotSummaryItemsViaMonetaryUnit_aggregate?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Aggregate_Order_By>;
  rollingInventoryLotSummaryItemsViaStorageUnit_aggregate?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Aggregate_Order_By>;
  rollingInventorySummaryItemsViaMonetaryUnit_aggregate?: InputMaybe<Rolling_Inventory_Summary_Items_Aggregate_Order_By>;
  rollingInventorySummaryItemsViaStorageUnit_aggregate?: InputMaybe<Rolling_Inventory_Summary_Items_Aggregate_Order_By>;
  significantDigits?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  stockTransactionsViaSourceMonetaryUnit_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Order_By>;
  stockTransactionsViaSourceStorageUnit_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Order_By>;
  stockTransactionsViaTargetMonetaryUnit_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Order_By>;
  stockTransactionsViaTargetStorageUnit_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Order_By>;
  supplierOrderGoodsIncomeItemsViaReceivedMonetaryUnit_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Order_By>;
  supplierOrderGoodsIncomeItemsViaReceivedStorageUnit_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Order_By>;
  supplierOrderGoodsIncomeItemsViaTargetMonetaryUnit_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Order_By>;
  supplierOrderGoodsIncomeItemsViaTargetStorageUnit_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Order_By>;
  supplierOrderItemsViaOrderUnit_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Order_By>;
  supplierOrderItems_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Order_By>;
  supplierOrderReturnItemsViaReturnedMonetaryUnit_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Order_By>;
  supplierOrderReturnItemsViaReturnedStorageUnit_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Order_By>;
  supplierOrderReturnItemsViaSourceMonetaryUnit_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Order_By>;
  supplierOrderReturnItemsViaSourceStorageUnit_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Order_By>;
  temporalProductsViaCostPriceUnit_aggregate?: InputMaybe<Temporal_Products_Aggregate_Order_By>;
  temporalProducts_aggregate?: InputMaybe<Temporal_Products_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_units */
export type Product_Units_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "product_units" */
export enum Product_Units_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DepletionThreshold = 'depletionThreshold',
  /** column name */
  Factor = 'factor',
  /** column name */
  Id = 'id',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ProductUnitName = 'productUnitName',
  /** column name */
  SignificantDigits = 'significantDigits',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  Unit = 'unit',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "product_units" */
export type Product_Units_Set_Input = {
  depletionThreshold?: InputMaybe<Scalars['bigint']>;
  factor?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['bigint']>;
  productUnitName?: InputMaybe<Scalars['String']>;
  significantDigits?: InputMaybe<Scalars['smallint']>;
  state?: InputMaybe<Product_Unit_States_Enum>;
  unit?: InputMaybe<Units_Enum>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Product_Units_Stddev_Fields = {
  __typename: 'product_units_stddev_fields';
  depletionThreshold?: Maybe<Scalars['Float']>;
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  significantDigits?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_units" */
export type Product_Units_Stddev_Order_By = {
  depletionThreshold?: InputMaybe<Order_By>;
  factor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  significantDigits?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Units_Stddev_Pop_Fields = {
  __typename: 'product_units_stddev_pop_fields';
  depletionThreshold?: Maybe<Scalars['Float']>;
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  significantDigits?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_units" */
export type Product_Units_Stddev_Pop_Order_By = {
  depletionThreshold?: InputMaybe<Order_By>;
  factor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  significantDigits?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Units_Stddev_Samp_Fields = {
  __typename: 'product_units_stddev_samp_fields';
  depletionThreshold?: Maybe<Scalars['Float']>;
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  significantDigits?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_units" */
export type Product_Units_Stddev_Samp_Order_By = {
  depletionThreshold?: InputMaybe<Order_By>;
  factor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  significantDigits?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "product_units" */
export type Product_Units_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Units_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Units_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  depletionThreshold?: InputMaybe<Scalars['bigint']>;
  factor?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['bigint']>;
  productUnitName?: InputMaybe<Scalars['String']>;
  significantDigits?: InputMaybe<Scalars['smallint']>;
  state?: InputMaybe<Product_Unit_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Units_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Product_Units_Sum_Fields = {
  __typename: 'product_units_sum_fields';
  depletionThreshold?: Maybe<Scalars['bigint']>;
  factor?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['bigint']>;
  significantDigits?: Maybe<Scalars['smallint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product_units" */
export type Product_Units_Sum_Order_By = {
  depletionThreshold?: InputMaybe<Order_By>;
  factor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  significantDigits?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "product_units" */
export enum Product_Units_Update_Column {
  /** column name */
  DepletionThreshold = 'depletionThreshold',
  /** column name */
  Factor = 'factor',
  /** column name */
  Id = 'id',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ProductUnitName = 'productUnitName',
  /** column name */
  SignificantDigits = 'significantDigits',
  /** column name */
  State = 'state',
  /** column name */
  Unit = 'unit',
  /** column name */
  Version = 'version'
}

export type Product_Units_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Units_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Units_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Units_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Units_Var_Pop_Fields = {
  __typename: 'product_units_var_pop_fields';
  depletionThreshold?: Maybe<Scalars['Float']>;
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  significantDigits?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_units" */
export type Product_Units_Var_Pop_Order_By = {
  depletionThreshold?: InputMaybe<Order_By>;
  factor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  significantDigits?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Units_Var_Samp_Fields = {
  __typename: 'product_units_var_samp_fields';
  depletionThreshold?: Maybe<Scalars['Float']>;
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  significantDigits?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_units" */
export type Product_Units_Var_Samp_Order_By = {
  depletionThreshold?: InputMaybe<Order_By>;
  factor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  significantDigits?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Units_Variance_Fields = {
  __typename: 'product_units_variance_fields';
  depletionThreshold?: Maybe<Scalars['Float']>;
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  significantDigits?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_units" */
export type Product_Units_Variance_Order_By = {
  depletionThreshold?: InputMaybe<Order_By>;
  factor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  significantDigits?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** A join table to designate which stock locations provide inventory to a given production site */
export type Production_Site_Input_Stock_Locations = {
  __typename: 'production_site_input_stock_locations';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  productionSite: Production_Sites;
  productionSiteId: Scalars['bigint'];
  /** An object relationship */
  stockLocation: Stock_Locations;
  stockLocationId: Scalars['bigint'];
  tenant: Scalars['String'];
};

/** aggregated selection of "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_Aggregate = {
  __typename: 'production_site_input_stock_locations_aggregate';
  aggregate?: Maybe<Production_Site_Input_Stock_Locations_Aggregate_Fields>;
  nodes: Array<Production_Site_Input_Stock_Locations>;
};

export type Production_Site_Input_Stock_Locations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Production_Site_Input_Stock_Locations_Aggregate_Bool_Exp_Count>;
};

export type Production_Site_Input_Stock_Locations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Production_Site_Input_Stock_Locations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_Aggregate_Fields = {
  __typename: 'production_site_input_stock_locations_aggregate_fields';
  avg?: Maybe<Production_Site_Input_Stock_Locations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Production_Site_Input_Stock_Locations_Max_Fields>;
  min?: Maybe<Production_Site_Input_Stock_Locations_Min_Fields>;
  stddev?: Maybe<Production_Site_Input_Stock_Locations_Stddev_Fields>;
  stddev_pop?: Maybe<Production_Site_Input_Stock_Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Production_Site_Input_Stock_Locations_Stddev_Samp_Fields>;
  sum?: Maybe<Production_Site_Input_Stock_Locations_Sum_Fields>;
  var_pop?: Maybe<Production_Site_Input_Stock_Locations_Var_Pop_Fields>;
  var_samp?: Maybe<Production_Site_Input_Stock_Locations_Var_Samp_Fields>;
  variance?: Maybe<Production_Site_Input_Stock_Locations_Variance_Fields>;
};


/** aggregate fields of "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_Aggregate_Order_By = {
  avg?: InputMaybe<Production_Site_Input_Stock_Locations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Production_Site_Input_Stock_Locations_Max_Order_By>;
  min?: InputMaybe<Production_Site_Input_Stock_Locations_Min_Order_By>;
  stddev?: InputMaybe<Production_Site_Input_Stock_Locations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Production_Site_Input_Stock_Locations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Production_Site_Input_Stock_Locations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Production_Site_Input_Stock_Locations_Sum_Order_By>;
  var_pop?: InputMaybe<Production_Site_Input_Stock_Locations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Production_Site_Input_Stock_Locations_Var_Samp_Order_By>;
  variance?: InputMaybe<Production_Site_Input_Stock_Locations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_Arr_Rel_Insert_Input = {
  data: Array<Production_Site_Input_Stock_Locations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Production_Site_Input_Stock_Locations_On_Conflict>;
};

/** aggregate avg on columns */
export type Production_Site_Input_Stock_Locations_Avg_Fields = {
  __typename: 'production_site_input_stock_locations_avg_fields';
  productionSiteId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_Avg_Order_By = {
  productionSiteId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "production_site_input_stock_locations". All fields are combined with a logical 'AND'. */
export type Production_Site_Input_Stock_Locations_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Bool_Exp>>;
  _not?: InputMaybe<Production_Site_Input_Stock_Locations_Bool_Exp>;
  _or?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  productionSite?: InputMaybe<Production_Sites_Bool_Exp>;
  productionSiteId?: InputMaybe<Bigint_Comparison_Exp>;
  stockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  stockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "production_site_input_stock_locations" */
export enum Production_Site_Input_Stock_Locations_Constraint {
  /** unique or primary key constraint on columns "production_site_id", "stock_location_id" */
  ProductionSiteInputStockLocationsPkey = 'production_site_input_stock_locations_pkey'
}

/** input type for incrementing numeric columns in table "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_Inc_Input = {
  productionSiteId?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_Insert_Input = {
  productionSite?: InputMaybe<Production_Sites_Obj_Rel_Insert_Input>;
  productionSiteId?: InputMaybe<Scalars['bigint']>;
  stockLocation?: InputMaybe<Stock_Locations_Obj_Rel_Insert_Input>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Production_Site_Input_Stock_Locations_Max_Fields = {
  __typename: 'production_site_input_stock_locations_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  productionSiteId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  productionSiteId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Production_Site_Input_Stock_Locations_Min_Fields = {
  __typename: 'production_site_input_stock_locations_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  productionSiteId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  productionSiteId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_Mutation_Response = {
  __typename: 'production_site_input_stock_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Production_Site_Input_Stock_Locations>;
};

/** on_conflict condition type for table "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_On_Conflict = {
  constraint: Production_Site_Input_Stock_Locations_Constraint;
  update_columns?: Array<Production_Site_Input_Stock_Locations_Update_Column>;
  where?: InputMaybe<Production_Site_Input_Stock_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "production_site_input_stock_locations". */
export type Production_Site_Input_Stock_Locations_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  productionSite?: InputMaybe<Production_Sites_Order_By>;
  productionSiteId?: InputMaybe<Order_By>;
  stockLocation?: InputMaybe<Stock_Locations_Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** primary key columns input for table: production_site_input_stock_locations */
export type Production_Site_Input_Stock_Locations_Pk_Columns_Input = {
  productionSiteId: Scalars['bigint'];
  stockLocationId: Scalars['bigint'];
};

/** select columns of table "production_site_input_stock_locations" */
export enum Production_Site_Input_Stock_Locations_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ProductionSiteId = 'productionSiteId',
  /** column name */
  StockLocationId = 'stockLocationId',
  /** column name */
  Tenant = 'tenant'
}

/** input type for updating data in table "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_Set_Input = {
  productionSiteId?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Production_Site_Input_Stock_Locations_Stddev_Fields = {
  __typename: 'production_site_input_stock_locations_stddev_fields';
  productionSiteId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_Stddev_Order_By = {
  productionSiteId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Production_Site_Input_Stock_Locations_Stddev_Pop_Fields = {
  __typename: 'production_site_input_stock_locations_stddev_pop_fields';
  productionSiteId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_Stddev_Pop_Order_By = {
  productionSiteId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Production_Site_Input_Stock_Locations_Stddev_Samp_Fields = {
  __typename: 'production_site_input_stock_locations_stddev_samp_fields';
  productionSiteId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_Stddev_Samp_Order_By = {
  productionSiteId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Production_Site_Input_Stock_Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Production_Site_Input_Stock_Locations_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  productionSiteId?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Production_Site_Input_Stock_Locations_Sum_Fields = {
  __typename: 'production_site_input_stock_locations_sum_fields';
  productionSiteId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_Sum_Order_By = {
  productionSiteId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
};

/** update columns of table "production_site_input_stock_locations" */
export enum Production_Site_Input_Stock_Locations_Update_Column {
  /** column name */
  ProductionSiteId = 'productionSiteId',
  /** column name */
  StockLocationId = 'stockLocationId'
}

export type Production_Site_Input_Stock_Locations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Production_Site_Input_Stock_Locations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Production_Site_Input_Stock_Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Production_Site_Input_Stock_Locations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Production_Site_Input_Stock_Locations_Var_Pop_Fields = {
  __typename: 'production_site_input_stock_locations_var_pop_fields';
  productionSiteId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_Var_Pop_Order_By = {
  productionSiteId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Production_Site_Input_Stock_Locations_Var_Samp_Fields = {
  __typename: 'production_site_input_stock_locations_var_samp_fields';
  productionSiteId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_Var_Samp_Order_By = {
  productionSiteId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Production_Site_Input_Stock_Locations_Variance_Fields = {
  __typename: 'production_site_input_stock_locations_variance_fields';
  productionSiteId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "production_site_input_stock_locations" */
export type Production_Site_Input_Stock_Locations_Variance_Order_By = {
  productionSiteId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
};

/** A join table to designate which stock locations are eligible to receive the production sites output inventory */
export type Production_Site_Output_Stock_Locations = {
  __typename: 'production_site_output_stock_locations';
  createdAt: Scalars['timestamptz'];
  productionSiteId: Scalars['bigint'];
  stockLocationId: Scalars['bigint'];
  tenant: Scalars['String'];
};

/** aggregated selection of "production_site_output_stock_locations" */
export type Production_Site_Output_Stock_Locations_Aggregate = {
  __typename: 'production_site_output_stock_locations_aggregate';
  aggregate?: Maybe<Production_Site_Output_Stock_Locations_Aggregate_Fields>;
  nodes: Array<Production_Site_Output_Stock_Locations>;
};

/** aggregate fields of "production_site_output_stock_locations" */
export type Production_Site_Output_Stock_Locations_Aggregate_Fields = {
  __typename: 'production_site_output_stock_locations_aggregate_fields';
  avg?: Maybe<Production_Site_Output_Stock_Locations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Production_Site_Output_Stock_Locations_Max_Fields>;
  min?: Maybe<Production_Site_Output_Stock_Locations_Min_Fields>;
  stddev?: Maybe<Production_Site_Output_Stock_Locations_Stddev_Fields>;
  stddev_pop?: Maybe<Production_Site_Output_Stock_Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Production_Site_Output_Stock_Locations_Stddev_Samp_Fields>;
  sum?: Maybe<Production_Site_Output_Stock_Locations_Sum_Fields>;
  var_pop?: Maybe<Production_Site_Output_Stock_Locations_Var_Pop_Fields>;
  var_samp?: Maybe<Production_Site_Output_Stock_Locations_Var_Samp_Fields>;
  variance?: Maybe<Production_Site_Output_Stock_Locations_Variance_Fields>;
};


/** aggregate fields of "production_site_output_stock_locations" */
export type Production_Site_Output_Stock_Locations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Production_Site_Output_Stock_Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Production_Site_Output_Stock_Locations_Avg_Fields = {
  __typename: 'production_site_output_stock_locations_avg_fields';
  productionSiteId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "production_site_output_stock_locations". All fields are combined with a logical 'AND'. */
export type Production_Site_Output_Stock_Locations_Bool_Exp = {
  _and?: InputMaybe<Array<Production_Site_Output_Stock_Locations_Bool_Exp>>;
  _not?: InputMaybe<Production_Site_Output_Stock_Locations_Bool_Exp>;
  _or?: InputMaybe<Array<Production_Site_Output_Stock_Locations_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  productionSiteId?: InputMaybe<Bigint_Comparison_Exp>;
  stockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "production_site_output_stock_locations" */
export enum Production_Site_Output_Stock_Locations_Constraint {
  /** unique or primary key constraint on columns "production_site_id", "stock_location_id" */
  ProductionSiteOutputStockLocationsPkey = 'production_site_output_stock_locations_pkey'
}

/** input type for incrementing numeric columns in table "production_site_output_stock_locations" */
export type Production_Site_Output_Stock_Locations_Inc_Input = {
  productionSiteId?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "production_site_output_stock_locations" */
export type Production_Site_Output_Stock_Locations_Insert_Input = {
  productionSiteId?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Production_Site_Output_Stock_Locations_Max_Fields = {
  __typename: 'production_site_output_stock_locations_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  productionSiteId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Production_Site_Output_Stock_Locations_Min_Fields = {
  __typename: 'production_site_output_stock_locations_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  productionSiteId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "production_site_output_stock_locations" */
export type Production_Site_Output_Stock_Locations_Mutation_Response = {
  __typename: 'production_site_output_stock_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Production_Site_Output_Stock_Locations>;
};

/** on_conflict condition type for table "production_site_output_stock_locations" */
export type Production_Site_Output_Stock_Locations_On_Conflict = {
  constraint: Production_Site_Output_Stock_Locations_Constraint;
  update_columns?: Array<Production_Site_Output_Stock_Locations_Update_Column>;
  where?: InputMaybe<Production_Site_Output_Stock_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "production_site_output_stock_locations". */
export type Production_Site_Output_Stock_Locations_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  productionSiteId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** primary key columns input for table: production_site_output_stock_locations */
export type Production_Site_Output_Stock_Locations_Pk_Columns_Input = {
  productionSiteId: Scalars['bigint'];
  stockLocationId: Scalars['bigint'];
};

/** select columns of table "production_site_output_stock_locations" */
export enum Production_Site_Output_Stock_Locations_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ProductionSiteId = 'productionSiteId',
  /** column name */
  StockLocationId = 'stockLocationId',
  /** column name */
  Tenant = 'tenant'
}

/** input type for updating data in table "production_site_output_stock_locations" */
export type Production_Site_Output_Stock_Locations_Set_Input = {
  productionSiteId?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Production_Site_Output_Stock_Locations_Stddev_Fields = {
  __typename: 'production_site_output_stock_locations_stddev_fields';
  productionSiteId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Production_Site_Output_Stock_Locations_Stddev_Pop_Fields = {
  __typename: 'production_site_output_stock_locations_stddev_pop_fields';
  productionSiteId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Production_Site_Output_Stock_Locations_Stddev_Samp_Fields = {
  __typename: 'production_site_output_stock_locations_stddev_samp_fields';
  productionSiteId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "production_site_output_stock_locations" */
export type Production_Site_Output_Stock_Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Production_Site_Output_Stock_Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Production_Site_Output_Stock_Locations_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  productionSiteId?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Production_Site_Output_Stock_Locations_Sum_Fields = {
  __typename: 'production_site_output_stock_locations_sum_fields';
  productionSiteId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
};

/** update columns of table "production_site_output_stock_locations" */
export enum Production_Site_Output_Stock_Locations_Update_Column {
  /** column name */
  ProductionSiteId = 'productionSiteId',
  /** column name */
  StockLocationId = 'stockLocationId'
}

export type Production_Site_Output_Stock_Locations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Production_Site_Output_Stock_Locations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Production_Site_Output_Stock_Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Production_Site_Output_Stock_Locations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Production_Site_Output_Stock_Locations_Var_Pop_Fields = {
  __typename: 'production_site_output_stock_locations_var_pop_fields';
  productionSiteId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Production_Site_Output_Stock_Locations_Var_Samp_Fields = {
  __typename: 'production_site_output_stock_locations_var_samp_fields';
  productionSiteId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Production_Site_Output_Stock_Locations_Variance_Fields = {
  __typename: 'production_site_output_stock_locations_variance_fields';
  productionSiteId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
};

/** Record presence determines on which days are certain product groups produced at a given production site */
export type Production_Site_Product_Group_Calendar = {
  __typename: 'production_site_product_group_calendar';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  day: Scalars['date'];
  /** An array relationship */
  fullWorkCalendars: Array<Full_Work_Calendar>;
  /** An aggregate relationship */
  fullWorkCalendars_aggregate: Full_Work_Calendar_Aggregate;
  id: Scalars['bigint'];
  /** An object relationship */
  productGroup: Product_Groups;
  productGroupId: Scalars['bigint'];
  /** An object relationship */
  productionSite: Production_Sites;
  productionSiteId: Scalars['bigint'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** Record presence determines on which days are certain product groups produced at a given production site */
export type Production_Site_Product_Group_CalendarFullWorkCalendarsArgs = {
  distinct_on?: InputMaybe<Array<Full_Work_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Full_Work_Calendar_Order_By>>;
  where?: InputMaybe<Full_Work_Calendar_Bool_Exp>;
};


/** Record presence determines on which days are certain product groups produced at a given production site */
export type Production_Site_Product_Group_CalendarFullWorkCalendars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Full_Work_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Full_Work_Calendar_Order_By>>;
  where?: InputMaybe<Full_Work_Calendar_Bool_Exp>;
};

/** aggregated selection of "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_Aggregate = {
  __typename: 'production_site_product_group_calendar_aggregate';
  aggregate?: Maybe<Production_Site_Product_Group_Calendar_Aggregate_Fields>;
  nodes: Array<Production_Site_Product_Group_Calendar>;
};

export type Production_Site_Product_Group_Calendar_Aggregate_Bool_Exp = {
  count?: InputMaybe<Production_Site_Product_Group_Calendar_Aggregate_Bool_Exp_Count>;
};

export type Production_Site_Product_Group_Calendar_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Production_Site_Product_Group_Calendar_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_Aggregate_Fields = {
  __typename: 'production_site_product_group_calendar_aggregate_fields';
  avg?: Maybe<Production_Site_Product_Group_Calendar_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Production_Site_Product_Group_Calendar_Max_Fields>;
  min?: Maybe<Production_Site_Product_Group_Calendar_Min_Fields>;
  stddev?: Maybe<Production_Site_Product_Group_Calendar_Stddev_Fields>;
  stddev_pop?: Maybe<Production_Site_Product_Group_Calendar_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Production_Site_Product_Group_Calendar_Stddev_Samp_Fields>;
  sum?: Maybe<Production_Site_Product_Group_Calendar_Sum_Fields>;
  var_pop?: Maybe<Production_Site_Product_Group_Calendar_Var_Pop_Fields>;
  var_samp?: Maybe<Production_Site_Product_Group_Calendar_Var_Samp_Fields>;
  variance?: Maybe<Production_Site_Product_Group_Calendar_Variance_Fields>;
};


/** aggregate fields of "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_Aggregate_Order_By = {
  avg?: InputMaybe<Production_Site_Product_Group_Calendar_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Production_Site_Product_Group_Calendar_Max_Order_By>;
  min?: InputMaybe<Production_Site_Product_Group_Calendar_Min_Order_By>;
  stddev?: InputMaybe<Production_Site_Product_Group_Calendar_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Production_Site_Product_Group_Calendar_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Production_Site_Product_Group_Calendar_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Production_Site_Product_Group_Calendar_Sum_Order_By>;
  var_pop?: InputMaybe<Production_Site_Product_Group_Calendar_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Production_Site_Product_Group_Calendar_Var_Samp_Order_By>;
  variance?: InputMaybe<Production_Site_Product_Group_Calendar_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_Arr_Rel_Insert_Input = {
  data: Array<Production_Site_Product_Group_Calendar_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Production_Site_Product_Group_Calendar_On_Conflict>;
};

/** aggregate avg on columns */
export type Production_Site_Product_Group_Calendar_Avg_Fields = {
  __typename: 'production_site_product_group_calendar_avg_fields';
  id?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productionSiteId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productionSiteId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "production_site_product_group_calendar". All fields are combined with a logical 'AND'. */
export type Production_Site_Product_Group_Calendar_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Bool_Exp>>;
  _not?: InputMaybe<Production_Site_Product_Group_Calendar_Bool_Exp>;
  _or?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  day?: InputMaybe<Date_Comparison_Exp>;
  fullWorkCalendars?: InputMaybe<Full_Work_Calendar_Bool_Exp>;
  fullWorkCalendars_aggregate?: InputMaybe<Full_Work_Calendar_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  productGroup?: InputMaybe<Product_Groups_Bool_Exp>;
  productGroupId?: InputMaybe<Bigint_Comparison_Exp>;
  productionSite?: InputMaybe<Production_Sites_Bool_Exp>;
  productionSiteId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "production_site_product_group_calendar" */
export enum Production_Site_Product_Group_Calendar_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductionSiteProductGroupCalendarPkey = 'production_site_product_group_calendar_pkey',
  /** unique or primary key constraint on columns "day", "production_site_id", "product_group_id", "tenant" */
  ProductionSiteProductGroupTenantProductionSiteIdDayKey = 'production_site_product_group_tenant_production_site_id_day_key'
}

/** input type for incrementing numeric columns in table "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  productGroupId?: InputMaybe<Scalars['bigint']>;
  productionSiteId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_Insert_Input = {
  day?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  productGroup?: InputMaybe<Product_Groups_Obj_Rel_Insert_Input>;
  productGroupId?: InputMaybe<Scalars['bigint']>;
  productionSite?: InputMaybe<Production_Sites_Obj_Rel_Insert_Input>;
  productionSiteId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Production_Site_Product_Group_Calendar_Max_Fields = {
  __typename: 'production_site_product_group_calendar_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  day?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  productGroupId?: Maybe<Scalars['bigint']>;
  productionSiteId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productionSiteId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Production_Site_Product_Group_Calendar_Min_Fields = {
  __typename: 'production_site_product_group_calendar_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  day?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  productGroupId?: Maybe<Scalars['bigint']>;
  productionSiteId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productionSiteId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_Mutation_Response = {
  __typename: 'production_site_product_group_calendar_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Production_Site_Product_Group_Calendar>;
};

/** on_conflict condition type for table "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_On_Conflict = {
  constraint: Production_Site_Product_Group_Calendar_Constraint;
  update_columns?: Array<Production_Site_Product_Group_Calendar_Update_Column>;
  where?: InputMaybe<Production_Site_Product_Group_Calendar_Bool_Exp>;
};

/** Ordering options when selecting data from "production_site_product_group_calendar". */
export type Production_Site_Product_Group_Calendar_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  fullWorkCalendars_aggregate?: InputMaybe<Full_Work_Calendar_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  productGroup?: InputMaybe<Product_Groups_Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productionSite?: InputMaybe<Production_Sites_Order_By>;
  productionSiteId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: production_site_product_group_calendar */
export type Production_Site_Product_Group_Calendar_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "production_site_product_group_calendar" */
export enum Production_Site_Product_Group_Calendar_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Day = 'day',
  /** column name */
  Id = 'id',
  /** column name */
  ProductGroupId = 'productGroupId',
  /** column name */
  ProductionSiteId = 'productionSiteId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_Set_Input = {
  day?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  productGroupId?: InputMaybe<Scalars['bigint']>;
  productionSiteId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Production_Site_Product_Group_Calendar_Stddev_Fields = {
  __typename: 'production_site_product_group_calendar_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productionSiteId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productionSiteId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Production_Site_Product_Group_Calendar_Stddev_Pop_Fields = {
  __typename: 'production_site_product_group_calendar_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productionSiteId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productionSiteId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Production_Site_Product_Group_Calendar_Stddev_Samp_Fields = {
  __typename: 'production_site_product_group_calendar_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productionSiteId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productionSiteId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Production_Site_Product_Group_Calendar_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Production_Site_Product_Group_Calendar_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  day?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  productGroupId?: InputMaybe<Scalars['bigint']>;
  productionSiteId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Production_Site_Product_Group_Calendar_Sum_Fields = {
  __typename: 'production_site_product_group_calendar_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  productGroupId?: Maybe<Scalars['bigint']>;
  productionSiteId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productionSiteId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "production_site_product_group_calendar" */
export enum Production_Site_Product_Group_Calendar_Update_Column {
  /** column name */
  Day = 'day',
  /** column name */
  Id = 'id',
  /** column name */
  ProductGroupId = 'productGroupId',
  /** column name */
  ProductionSiteId = 'productionSiteId',
  /** column name */
  Version = 'version'
}

export type Production_Site_Product_Group_Calendar_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Production_Site_Product_Group_Calendar_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Production_Site_Product_Group_Calendar_Set_Input>;
  /** filter the rows which have to be updated */
  where: Production_Site_Product_Group_Calendar_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Production_Site_Product_Group_Calendar_Var_Pop_Fields = {
  __typename: 'production_site_product_group_calendar_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productionSiteId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productionSiteId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Production_Site_Product_Group_Calendar_Var_Samp_Fields = {
  __typename: 'production_site_product_group_calendar_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productionSiteId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productionSiteId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Production_Site_Product_Group_Calendar_Variance_Fields = {
  __typename: 'production_site_product_group_calendar_variance_fields';
  id?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['Float']>;
  productionSiteId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "production_site_product_group_calendar" */
export type Production_Site_Product_Group_Calendar_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  productGroupId?: InputMaybe<Order_By>;
  productionSiteId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "production_site_states" */
export type Production_Site_States = {
  __typename: 'production_site_states';
  /** An array relationship */
  productionSites: Array<Production_Sites>;
  /** An aggregate relationship */
  productionSites_aggregate: Production_Sites_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "production_site_states" */
export type Production_Site_StatesProductionSitesArgs = {
  distinct_on?: InputMaybe<Array<Production_Sites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Sites_Order_By>>;
  where?: InputMaybe<Production_Sites_Bool_Exp>;
};


/** columns and relationships of "production_site_states" */
export type Production_Site_StatesProductionSites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Production_Sites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Sites_Order_By>>;
  where?: InputMaybe<Production_Sites_Bool_Exp>;
};

/** aggregated selection of "production_site_states" */
export type Production_Site_States_Aggregate = {
  __typename: 'production_site_states_aggregate';
  aggregate?: Maybe<Production_Site_States_Aggregate_Fields>;
  nodes: Array<Production_Site_States>;
};

/** aggregate fields of "production_site_states" */
export type Production_Site_States_Aggregate_Fields = {
  __typename: 'production_site_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Production_Site_States_Max_Fields>;
  min?: Maybe<Production_Site_States_Min_Fields>;
};


/** aggregate fields of "production_site_states" */
export type Production_Site_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Production_Site_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "production_site_states". All fields are combined with a logical 'AND'. */
export type Production_Site_States_Bool_Exp = {
  _and?: InputMaybe<Array<Production_Site_States_Bool_Exp>>;
  _not?: InputMaybe<Production_Site_States_Bool_Exp>;
  _or?: InputMaybe<Array<Production_Site_States_Bool_Exp>>;
  productionSites?: InputMaybe<Production_Sites_Bool_Exp>;
  productionSites_aggregate?: InputMaybe<Production_Sites_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Production_Site_States_Enum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

/** Boolean expression to compare columns of type "production_site_states_enum". All fields are combined with logical 'AND'. */
export type Production_Site_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Production_Site_States_Enum>;
  _in?: InputMaybe<Array<Production_Site_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Production_Site_States_Enum>;
  _nin?: InputMaybe<Array<Production_Site_States_Enum>>;
};

/** aggregate max on columns */
export type Production_Site_States_Max_Fields = {
  __typename: 'production_site_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Production_Site_States_Min_Fields = {
  __typename: 'production_site_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "production_site_states". */
export type Production_Site_States_Order_By = {
  productionSites_aggregate?: InputMaybe<Production_Sites_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "production_site_states" */
export enum Production_Site_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "production_site_states" */
export type Production_Site_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Production_Site_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Production_Site_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "production_sites" */
export type Production_Sites = {
  __typename: 'production_sites';
  /** An object relationship */
  Tenant: Tenants;
  i18n: Scalars['jsonb'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  ordinalPosition: Scalars['Int'];
  /** An array relationship */
  productionSiteInputStockLocations: Array<Production_Site_Input_Stock_Locations>;
  /** An aggregate relationship */
  productionSiteInputStockLocations_aggregate: Production_Site_Input_Stock_Locations_Aggregate;
  /** An array relationship */
  productionSiteProductGroupCalendars: Array<Production_Site_Product_Group_Calendar>;
  /** An aggregate relationship */
  productionSiteProductGroupCalendars_aggregate: Production_Site_Product_Group_Calendar_Aggregate;
  protected: Scalars['Boolean'];
  state: Production_Site_States_Enum;
  tenant: Scalars['String'];
};


/** columns and relationships of "production_sites" */
export type Production_SitesI18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "production_sites" */
export type Production_SitesProductionSiteInputStockLocationsArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Order_By>>;
  where?: InputMaybe<Production_Site_Input_Stock_Locations_Bool_Exp>;
};


/** columns and relationships of "production_sites" */
export type Production_SitesProductionSiteInputStockLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Order_By>>;
  where?: InputMaybe<Production_Site_Input_Stock_Locations_Bool_Exp>;
};


/** columns and relationships of "production_sites" */
export type Production_SitesProductionSiteProductGroupCalendarsArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Order_By>>;
  where?: InputMaybe<Production_Site_Product_Group_Calendar_Bool_Exp>;
};


/** columns and relationships of "production_sites" */
export type Production_SitesProductionSiteProductGroupCalendars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Order_By>>;
  where?: InputMaybe<Production_Site_Product_Group_Calendar_Bool_Exp>;
};

/** aggregated selection of "production_sites" */
export type Production_Sites_Aggregate = {
  __typename: 'production_sites_aggregate';
  aggregate?: Maybe<Production_Sites_Aggregate_Fields>;
  nodes: Array<Production_Sites>;
};

export type Production_Sites_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Production_Sites_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Production_Sites_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Production_Sites_Aggregate_Bool_Exp_Count>;
};

export type Production_Sites_Aggregate_Bool_Exp_Bool_And = {
  arguments: Production_Sites_Select_Column_Production_Sites_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Production_Sites_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Production_Sites_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Production_Sites_Select_Column_Production_Sites_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Production_Sites_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Production_Sites_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Production_Sites_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Production_Sites_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "production_sites" */
export type Production_Sites_Aggregate_Fields = {
  __typename: 'production_sites_aggregate_fields';
  avg?: Maybe<Production_Sites_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Production_Sites_Max_Fields>;
  min?: Maybe<Production_Sites_Min_Fields>;
  stddev?: Maybe<Production_Sites_Stddev_Fields>;
  stddev_pop?: Maybe<Production_Sites_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Production_Sites_Stddev_Samp_Fields>;
  sum?: Maybe<Production_Sites_Sum_Fields>;
  var_pop?: Maybe<Production_Sites_Var_Pop_Fields>;
  var_samp?: Maybe<Production_Sites_Var_Samp_Fields>;
  variance?: Maybe<Production_Sites_Variance_Fields>;
};


/** aggregate fields of "production_sites" */
export type Production_Sites_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Production_Sites_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "production_sites" */
export type Production_Sites_Aggregate_Order_By = {
  avg?: InputMaybe<Production_Sites_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Production_Sites_Max_Order_By>;
  min?: InputMaybe<Production_Sites_Min_Order_By>;
  stddev?: InputMaybe<Production_Sites_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Production_Sites_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Production_Sites_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Production_Sites_Sum_Order_By>;
  var_pop?: InputMaybe<Production_Sites_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Production_Sites_Var_Samp_Order_By>;
  variance?: InputMaybe<Production_Sites_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Production_Sites_Append_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Production_Sites_Avg_Fields = {
  __typename: 'production_sites_avg_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "production_sites" */
export type Production_Sites_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "production_sites". All fields are combined with a logical 'AND'. */
export type Production_Sites_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Production_Sites_Bool_Exp>>;
  _not?: InputMaybe<Production_Sites_Bool_Exp>;
  _or?: InputMaybe<Array<Production_Sites_Bool_Exp>>;
  i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  productionSiteInputStockLocations?: InputMaybe<Production_Site_Input_Stock_Locations_Bool_Exp>;
  productionSiteInputStockLocations_aggregate?: InputMaybe<Production_Site_Input_Stock_Locations_Aggregate_Bool_Exp>;
  productionSiteProductGroupCalendars?: InputMaybe<Production_Site_Product_Group_Calendar_Bool_Exp>;
  productionSiteProductGroupCalendars_aggregate?: InputMaybe<Production_Site_Product_Group_Calendar_Aggregate_Bool_Exp>;
  protected?: InputMaybe<Boolean_Comparison_Exp>;
  state?: InputMaybe<Production_Site_States_Enum_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "production_sites" */
export enum Production_Sites_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductionSitesPkey = 'production_sites_pkey',
  /** unique or primary key constraint on columns "name", "tenant" */
  ProductionSitesTenantNameKey = 'production_sites_tenant_name_key',
  /** unique or primary key constraint on columns "tenant", "ordinal_position" */
  ProductionSitesTenantOrdinalPositionKey = 'production_sites_tenant_ordinal_position_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Production_Sites_Delete_At_Path_Input = {
  i18n?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Production_Sites_Delete_Elem_Input = {
  i18n?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Production_Sites_Delete_Key_Input = {
  i18n?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "production_sites" */
export type Production_Sites_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "production_sites" */
export type Production_Sites_Insert_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  productionSiteInputStockLocations?: InputMaybe<Production_Site_Input_Stock_Locations_Arr_Rel_Insert_Input>;
  productionSiteProductGroupCalendars?: InputMaybe<Production_Site_Product_Group_Calendar_Arr_Rel_Insert_Input>;
  protected?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Production_Site_States_Enum>;
};

/** aggregate max on columns */
export type Production_Sites_Max_Fields = {
  __typename: 'production_sites_max_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "production_sites" */
export type Production_Sites_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Production_Sites_Min_Fields = {
  __typename: 'production_sites_min_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "production_sites" */
export type Production_Sites_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "production_sites" */
export type Production_Sites_Mutation_Response = {
  __typename: 'production_sites_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Production_Sites>;
};

/** input type for inserting object relation for remote table "production_sites" */
export type Production_Sites_Obj_Rel_Insert_Input = {
  data: Production_Sites_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Production_Sites_On_Conflict>;
};

/** on_conflict condition type for table "production_sites" */
export type Production_Sites_On_Conflict = {
  constraint: Production_Sites_Constraint;
  update_columns?: Array<Production_Sites_Update_Column>;
  where?: InputMaybe<Production_Sites_Bool_Exp>;
};

/** Ordering options when selecting data from "production_sites". */
export type Production_Sites_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  i18n?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productionSiteInputStockLocations_aggregate?: InputMaybe<Production_Site_Input_Stock_Locations_Aggregate_Order_By>;
  productionSiteProductGroupCalendars_aggregate?: InputMaybe<Production_Site_Product_Group_Calendar_Aggregate_Order_By>;
  protected?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** primary key columns input for table: production_sites */
export type Production_Sites_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Production_Sites_Prepend_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "production_sites" */
export enum Production_Sites_Select_Column {
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  Protected = 'protected',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant'
}

/** select "production_sites_aggregate_bool_exp_bool_and_arguments_columns" columns of table "production_sites" */
export enum Production_Sites_Select_Column_Production_Sites_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Protected = 'protected'
}

/** select "production_sites_aggregate_bool_exp_bool_or_arguments_columns" columns of table "production_sites" */
export enum Production_Sites_Select_Column_Production_Sites_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Protected = 'protected'
}

/** input type for updating data in table "production_sites" */
export type Production_Sites_Set_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  protected?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Production_Site_States_Enum>;
};

/** aggregate stddev on columns */
export type Production_Sites_Stddev_Fields = {
  __typename: 'production_sites_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "production_sites" */
export type Production_Sites_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Production_Sites_Stddev_Pop_Fields = {
  __typename: 'production_sites_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "production_sites" */
export type Production_Sites_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Production_Sites_Stddev_Samp_Fields = {
  __typename: 'production_sites_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "production_sites" */
export type Production_Sites_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "production_sites" */
export type Production_Sites_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Production_Sites_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Production_Sites_Stream_Cursor_Value_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  protected?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Production_Site_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Production_Sites_Sum_Fields = {
  __typename: 'production_sites_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "production_sites" */
export type Production_Sites_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** update columns of table "production_sites" */
export enum Production_Sites_Update_Column {
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  Protected = 'protected',
  /** column name */
  State = 'state'
}

export type Production_Sites_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Production_Sites_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Production_Sites_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Production_Sites_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Production_Sites_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Production_Sites_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Production_Sites_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Production_Sites_Set_Input>;
  /** filter the rows which have to be updated */
  where: Production_Sites_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Production_Sites_Var_Pop_Fields = {
  __typename: 'production_sites_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "production_sites" */
export type Production_Sites_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Production_Sites_Var_Samp_Fields = {
  __typename: 'production_sites_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "production_sites" */
export type Production_Sites_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Production_Sites_Variance_Fields = {
  __typename: 'production_sites_variance_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "production_sites" */
export type Production_Sites_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** columns and relationships of "products" */
export type Products = {
  __typename: 'products';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  aggregatedDemandPlanOutgoingGoodsResults: Array<Aggregated_Demand_Plan_Outgoing_Goods_Results>;
  /** An aggregate relationship */
  aggregatedDemandPlanOutgoingGoodsResults_aggregate: Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate;
  /** An array relationship */
  aggregatedDemandPlanResults: Array<Aggregated_Demand_Plan_Results>;
  /** An aggregate relationship */
  aggregatedDemandPlanResults_aggregate: Aggregated_Demand_Plan_Results_Aggregate;
  /** An array relationship */
  aggregatedInventoryItemsByLocations: Array<Aggregated_Inventory_Items_By_Location>;
  /** An aggregate relationship */
  aggregatedInventoryItemsByLocations_aggregate: Aggregated_Inventory_Items_By_Location_Aggregate;
  allowedTenants: Scalars['jsonb'];
  baseQuantity: Scalars['bigint'];
  bestBeforeDurationInDays?: Maybe<Scalars['Int']>;
  /** Determines calculated cost price (temporal/historical) for the product */
  calculatedCostPrice?: Maybe<Array<Calculate_Cost_Price_For_Product_Results>>;
  /** An object relationship */
  costPriceAveragePeriod?: Maybe<Cost_Price_Average_Periods>;
  costPriceAveragePeriodId?: Maybe<Scalars['bigint']>;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  creditNoteItems: Array<Credit_Note_Items>;
  /** An aggregate relationship */
  creditNoteItems_aggregate: Credit_Note_Items_Aggregate;
  csbId?: Maybe<Scalars['bigint']>;
  /** Returns a matching active temporal product which matches the current date. */
  currentTemporalProducts?: Maybe<Array<Temporal_Products>>;
  /** An array relationship */
  customerGroupPricings: Array<Customer_Group_Pricing>;
  /** An aggregate relationship */
  customerGroupPricings_aggregate: Customer_Group_Pricing_Aggregate;
  /** An array relationship */
  customerOrderContainerReturnItems: Array<Customer_Order_Container_Return_Items>;
  /** An aggregate relationship */
  customerOrderContainerReturnItems_aggregate: Customer_Order_Container_Return_Items_Aggregate;
  /** An array relationship */
  customerOrderItemContainerExpectations: Array<Customer_Order_Item_Container_Expectations>;
  /** An aggregate relationship */
  customerOrderItemContainerExpectations_aggregate: Customer_Order_Item_Container_Expectations_Aggregate;
  /** An array relationship */
  customerOrderItems: Array<Customer_Order_Items>;
  /** An array relationship */
  customerOrderItemsViaContainerProduct: Array<Customer_Order_Items>;
  /** An aggregate relationship */
  customerOrderItemsViaContainerProduct_aggregate: Customer_Order_Items_Aggregate;
  /** An aggregate relationship */
  customerOrderItems_aggregate: Customer_Order_Items_Aggregate;
  /** An array relationship */
  customerOrderPickedContainers: Array<Customer_Order_Picked_Container>;
  /** An aggregate relationship */
  customerOrderPickedContainers_aggregate: Customer_Order_Picked_Container_Aggregate;
  /** An array relationship */
  customerOrderReturnItemContainers: Array<Customer_Order_Return_Item_Containers>;
  /** An aggregate relationship */
  customerOrderReturnItemContainers_aggregate: Customer_Order_Return_Item_Containers_Aggregate;
  /** An array relationship */
  customerProductAvailabilities: Array<Customer_Product_Availabilities>;
  /** An aggregate relationship */
  customerProductAvailabilities_aggregate: Customer_Product_Availabilities_Aggregate;
  /** An array relationship */
  cuttingPatternOutputs: Array<Cutting_Pattern_Outputs>;
  /** An array relationship */
  cuttingPatternOutputsDrafts: Array<Cutting_Pattern_Outputs_Draft>;
  /** An aggregate relationship */
  cuttingPatternOutputsDrafts_aggregate: Cutting_Pattern_Outputs_Draft_Aggregate;
  /** An aggregate relationship */
  cuttingPatternOutputs_aggregate: Cutting_Pattern_Outputs_Aggregate;
  /** An array relationship */
  cuttingPatterns: Array<Cutting_Patterns>;
  /** An array relationship */
  cuttingPatternsDrafts: Array<Cutting_Patterns_Draft>;
  /** An aggregate relationship */
  cuttingPatternsDrafts_aggregate: Cutting_Patterns_Draft_Aggregate;
  /** An aggregate relationship */
  cuttingPatterns_aggregate: Cutting_Patterns_Aggregate;
  /** An array relationship */
  dailyPriceLists: Array<Daily_Price_Lists>;
  /** An aggregate relationship */
  dailyPriceLists_aggregate: Daily_Price_Lists_Aggregate;
  /** An array relationship */
  demandsForCoiResults: Array<Demands_For_Coi_Results>;
  /** An aggregate relationship */
  demandsForCoiResults_aggregate: Demands_For_Coi_Results_Aggregate;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  dismantleableProductsResults: Array<Dismantleable_Products_Results>;
  /** An aggregate relationship */
  dismantleableProductsResults_aggregate: Dismantleable_Products_Results_Aggregate;
  /** An array relationship */
  dismantlingOutputWeightResults: Array<Dismantling_Output_Weight_Results>;
  /** An aggregate relationship */
  dismantlingOutputWeightResults_aggregate: Dismantling_Output_Weight_Results_Aggregate;
  /** An array relationship */
  dismantlingPlanDemands: Array<Dismantling_Plan_Demands>;
  /** An aggregate relationship */
  dismantlingPlanDemands_aggregate: Dismantling_Plan_Demands_Aggregate;
  /** An array relationship */
  dismantlingPlanRemainingPieces: Array<Dismantling_Plan_Remaining_Pieces>;
  /** An array relationship */
  dismantlingPlanRemainingPiecesViaContainerProduct: Array<Dismantling_Plan_Remaining_Pieces>;
  /** An aggregate relationship */
  dismantlingPlanRemainingPiecesViaContainerProduct_aggregate: Dismantling_Plan_Remaining_Pieces_Aggregate;
  /** An aggregate relationship */
  dismantlingPlanRemainingPieces_aggregate: Dismantling_Plan_Remaining_Pieces_Aggregate;
  domainId: Scalars['String'];
  /** An array relationship */
  globalSearchResults: Array<Global_Search_Result>;
  /** An aggregate relationship */
  globalSearchResults_aggregate: Global_Search_Result_Aggregate;
  gtin?: Maybe<Scalars['bigint']>;
  highlight?: Maybe<Scalars['String']>;
  i18n: Scalars['jsonb'];
  id: Scalars['bigint'];
  /** An array relationship */
  inventoryDailyPrognosisResults: Array<Inventory_Daily_Prognosis_Results>;
  /** An aggregate relationship */
  inventoryDailyPrognosisResults_aggregate: Inventory_Daily_Prognosis_Results_Aggregate;
  /** An array relationship */
  inventoryDailyUsageResults: Array<Inventory_Daily_Usage_Results>;
  /** An aggregate relationship */
  inventoryDailyUsageResults_aggregate: Inventory_Daily_Usage_Results_Aggregate;
  /** An array relationship */
  inventoryItems: Array<Inventory_Items>;
  /** An array relationship */
  inventoryItemsByLocations: Array<Inventory_Items_By_Location>;
  /** An aggregate relationship */
  inventoryItemsByLocations_aggregate: Inventory_Items_By_Location_Aggregate;
  /** An aggregate relationship */
  inventoryItems_aggregate: Inventory_Items_Aggregate;
  isHighlightedOnWebshop?: Maybe<Scalars['Boolean']>;
  isLotNumberTracked: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  /** An array relationship */
  listPurchasedProductsResults: Array<List_Purchased_Products_Result>;
  /** An aggregate relationship */
  listPurchasedProductsResults_aggregate: List_Purchased_Products_Result_Aggregate;
  /** An array relationship */
  loggedActions: Array<Product_Logged_Actions>;
  /** An aggregate relationship */
  loggedActions_aggregate: Product_Logged_Actions_Aggregate;
  /** An array relationship */
  lotNumberProducts: Array<Lot_Number_Products>;
  /** An aggregate relationship */
  lotNumberProducts_aggregate: Lot_Number_Products_Aggregate;
  name: Scalars['String'];
  /** An object relationship */
  pickingCategory?: Maybe<Picking_Categories>;
  pickingCategoryId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  postings: Array<Postings>;
  /** An aggregate relationship */
  postings_aggregate: Postings_Aggregate;
  /** Determines pricing type for the product */
  pricingType?: Maybe<Scalars['String']>;
  /** An array relationship */
  productAllowedContainersViaContainerProduct: Array<Product_Allowed_Containers>;
  /** An aggregate relationship */
  productAllowedContainersViaContainerProduct_aggregate: Product_Allowed_Containers_Aggregate;
  /** An array relationship */
  productAllowedContainersViaProduct: Array<Product_Allowed_Containers>;
  /** An aggregate relationship */
  productAllowedContainersViaProduct_aggregate: Product_Allowed_Containers_Aggregate;
  /** An array relationship */
  productGroupMembers: Array<Product_Group_Members>;
  /** An aggregate relationship */
  productGroupMembers_aggregate: Product_Group_Members_Aggregate;
  /** An object relationship */
  productImage?: Maybe<Images>;
  productImageId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  productLabelSettings: Array<Product_Label_Settings>;
  /** An aggregate relationship */
  productLabelSettings_aggregate: Product_Label_Settings_Aggregate;
  /** An array relationship */
  productRecipeIngredients: Array<Product_Recipe_Ingredients>;
  /** An aggregate relationship */
  productRecipeIngredients_aggregate: Product_Recipe_Ingredients_Aggregate;
  /** An array relationship */
  productRecipes: Array<Product_Recipes>;
  /** An aggregate relationship */
  productRecipes_aggregate: Product_Recipes_Aggregate;
  /** An array relationship */
  productS3Objects: Array<Product_S3_Objects>;
  /** An aggregate relationship */
  productS3Objects_aggregate: Product_S3_Objects_Aggregate;
  /** An array relationship */
  productSalesChannelMembers: Array<Product_Sales_Channel_Members>;
  /** An aggregate relationship */
  productSalesChannelMembers_aggregate: Product_Sales_Channel_Members_Aggregate;
  /** An array relationship */
  productStockLocations: Array<Product_Stock_Locations>;
  /** An aggregate relationship */
  productStockLocations_aggregate: Product_Stock_Locations_Aggregate;
  /** An array relationship */
  productSuppliers: Array<Product_Suppliers>;
  /** An aggregate relationship */
  productSuppliers_aggregate: Product_Suppliers_Aggregate;
  /** An array relationship */
  productUnitSalesPriceConfigs: Array<Product_Unit_Sales_Price_Config>;
  /** An aggregate relationship */
  productUnitSalesPriceConfigs_aggregate: Product_Unit_Sales_Price_Config_Aggregate;
  /** An array relationship */
  productUnits: Array<Product_Units>;
  /** An aggregate relationship */
  productUnits_aggregate: Product_Units_Aggregate;
  requirePicking: Scalars['Boolean'];
  /** An array relationship */
  rollingInventoryItems: Array<Rolling_Inventory_Items>;
  /** An aggregate relationship */
  rollingInventoryItems_aggregate: Rolling_Inventory_Items_Aggregate;
  /** An array relationship */
  rollingInventoryLotSummaryItems: Array<Rolling_Inventory_Lot_Summary_Items>;
  /** An aggregate relationship */
  rollingInventoryLotSummaryItems_aggregate: Rolling_Inventory_Lot_Summary_Items_Aggregate;
  /** An array relationship */
  rollingInventorySummaryItems: Array<Rolling_Inventory_Summary_Items>;
  /** An aggregate relationship */
  rollingInventorySummaryItems_aggregate: Rolling_Inventory_Summary_Items_Aggregate;
  /** An array relationship */
  searchProductsForCustomerResults: Array<Search_Products_For_Customer_Results>;
  /** An aggregate relationship */
  searchProductsForCustomerResults_aggregate: Search_Products_For_Customer_Results_Aggregate;
  /** An array relationship */
  searchPurchasedProductsResults: Array<Search_Purchased_Products_Results>;
  /** An aggregate relationship */
  searchPurchasedProductsResults_aggregate: Search_Purchased_Products_Results_Aggregate;
  sellable?: Maybe<Scalars['Boolean']>;
  sellableToAllCustomers?: Maybe<Scalars['Boolean']>;
  state: Product_States_Enum;
  /** An array relationship */
  stockTransactions: Array<Stock_Transactions>;
  /** An aggregate relationship */
  stockTransactions_aggregate: Stock_Transactions_Aggregate;
  /** An array relationship */
  supplierOrderGoodsIncomeItemContainers: Array<Supplier_Order_Goods_Income_Item_Containers>;
  /** An aggregate relationship */
  supplierOrderGoodsIncomeItemContainers_aggregate: Supplier_Order_Goods_Income_Item_Containers_Aggregate;
  /** An array relationship */
  supplierOrderItems: Array<Supplier_Order_Items>;
  /** An aggregate relationship */
  supplierOrderItems_aggregate: Supplier_Order_Items_Aggregate;
  /** An array relationship */
  supplierOrderReturnItemContainers: Array<Supplier_Order_Return_Item_Containers>;
  /** An aggregate relationship */
  supplierOrderReturnItemContainers_aggregate: Supplier_Order_Return_Item_Containers_Aggregate;
  /** An array relationship */
  supplierOrderReturnedContainers: Array<Supplier_Order_Returned_Containers>;
  /** An aggregate relationship */
  supplierOrderReturnedContainers_aggregate: Supplier_Order_Returned_Containers_Aggregate;
  /** An array relationship */
  temporalProducts: Array<Temporal_Products>;
  /** An aggregate relationship */
  temporalProducts_aggregate: Temporal_Products_Aggregate;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  vatType?: Maybe<Vat_Types>;
  vatTypeId?: Maybe<Scalars['bigint']>;
  version: Scalars['bigint'];
  webshopId?: Maybe<Scalars['String']>;
};


/** columns and relationships of "products" */
export type ProductsAggregatedDemandPlanOutgoingGoodsResultsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsAggregatedDemandPlanOutgoingGoodsResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsAggregatedDemandPlanResultsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsAggregatedDemandPlanResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsAggregatedInventoryItemsByLocationsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsAggregatedInventoryItemsByLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsAllowedTenantsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "products" */
export type ProductsCalculatedCostPriceArgs = {
  args: CalculatedCostPrice_Products_Args;
  distinct_on?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Order_By>>;
  where?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCreditNoteItemsArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Items_Order_By>>;
  where?: InputMaybe<Credit_Note_Items_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCreditNoteItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Items_Order_By>>;
  where?: InputMaybe<Credit_Note_Items_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCurrentTemporalProductsArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCustomerGroupPricingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCustomerGroupPricings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCustomerOrderContainerReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Return_Items_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCustomerOrderContainerReturnItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Return_Items_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCustomerOrderItemContainerExpectationsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Container_Expectations_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCustomerOrderItemContainerExpectations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Container_Expectations_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCustomerOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCustomerOrderItemsViaContainerProductArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCustomerOrderItemsViaContainerProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCustomerOrderItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCustomerOrderPickedContainersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCustomerOrderPickedContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCustomerOrderReturnItemContainersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Item_Containers_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCustomerOrderReturnItemContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Item_Containers_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCustomerProductAvailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Product_Availabilities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Product_Availabilities_Order_By>>;
  where?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCustomerProductAvailabilities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Product_Availabilities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Product_Availabilities_Order_By>>;
  where?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCuttingPatternOutputsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCuttingPatternOutputsDraftsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCuttingPatternOutputsDrafts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCuttingPatternOutputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCuttingPatternsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCuttingPatternsDraftsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCuttingPatternsDrafts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsCuttingPatterns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsDailyPriceListsArgs = {
  distinct_on?: InputMaybe<Array<Daily_Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Price_Lists_Order_By>>;
  where?: InputMaybe<Daily_Price_Lists_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsDailyPriceLists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Price_Lists_Order_By>>;
  where?: InputMaybe<Daily_Price_Lists_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsDemandsForCoiResultsArgs = {
  distinct_on?: InputMaybe<Array<Demands_For_Coi_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Demands_For_Coi_Results_Order_By>>;
  where?: InputMaybe<Demands_For_Coi_Results_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsDemandsForCoiResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Demands_For_Coi_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Demands_For_Coi_Results_Order_By>>;
  where?: InputMaybe<Demands_For_Coi_Results_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsDismantleableProductsResultsArgs = {
  distinct_on?: InputMaybe<Array<Dismantleable_Products_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantleable_Products_Results_Order_By>>;
  where?: InputMaybe<Dismantleable_Products_Results_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsDismantleableProductsResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantleable_Products_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantleable_Products_Results_Order_By>>;
  where?: InputMaybe<Dismantleable_Products_Results_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsDismantlingOutputWeightResultsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Output_Weight_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Output_Weight_Results_Order_By>>;
  where?: InputMaybe<Dismantling_Output_Weight_Results_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsDismantlingOutputWeightResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Output_Weight_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Output_Weight_Results_Order_By>>;
  where?: InputMaybe<Dismantling_Output_Weight_Results_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsDismantlingPlanDemandsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Demands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Demands_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsDismantlingPlanDemands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Demands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Demands_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsDismantlingPlanRemainingPiecesArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsDismantlingPlanRemainingPiecesViaContainerProductArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsDismantlingPlanRemainingPiecesViaContainerProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsDismantlingPlanRemainingPieces_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsGlobalSearchResultsArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsGlobalSearchResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsI18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "products" */
export type ProductsInventoryDailyPrognosisResultsArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsInventoryDailyPrognosisResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsInventoryDailyUsageResultsArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Usage_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Usage_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Usage_Results_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsInventoryDailyUsageResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Usage_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Usage_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Usage_Results_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsInventoryItemsArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsInventoryItemsByLocationsArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsInventoryItemsByLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsInventoryItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsListPurchasedProductsResultsArgs = {
  distinct_on?: InputMaybe<Array<List_Purchased_Products_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Purchased_Products_Result_Order_By>>;
  where?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsListPurchasedProductsResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Purchased_Products_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Purchased_Products_Result_Order_By>>;
  where?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Product_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Logged_Actions_Order_By>>;
  where?: InputMaybe<Product_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Logged_Actions_Order_By>>;
  where?: InputMaybe<Product_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsLotNumberProductsArgs = {
  distinct_on?: InputMaybe<Array<Lot_Number_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Number_Products_Order_By>>;
  where?: InputMaybe<Lot_Number_Products_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsLotNumberProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lot_Number_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Number_Products_Order_By>>;
  where?: InputMaybe<Lot_Number_Products_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsPostingsArgs = {
  distinct_on?: InputMaybe<Array<Postings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postings_Order_By>>;
  where?: InputMaybe<Postings_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsPostings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Postings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postings_Order_By>>;
  where?: InputMaybe<Postings_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductAllowedContainersViaContainerProductArgs = {
  distinct_on?: InputMaybe<Array<Product_Allowed_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Allowed_Containers_Order_By>>;
  where?: InputMaybe<Product_Allowed_Containers_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductAllowedContainersViaContainerProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Allowed_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Allowed_Containers_Order_By>>;
  where?: InputMaybe<Product_Allowed_Containers_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductAllowedContainersViaProductArgs = {
  distinct_on?: InputMaybe<Array<Product_Allowed_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Allowed_Containers_Order_By>>;
  where?: InputMaybe<Product_Allowed_Containers_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductAllowedContainersViaProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Allowed_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Allowed_Containers_Order_By>>;
  where?: InputMaybe<Product_Allowed_Containers_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductGroupMembersArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_Members_Order_By>>;
  where?: InputMaybe<Product_Group_Members_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductGroupMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_Members_Order_By>>;
  where?: InputMaybe<Product_Group_Members_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductLabelSettingsArgs = {
  distinct_on?: InputMaybe<Array<Product_Label_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Label_Settings_Order_By>>;
  where?: InputMaybe<Product_Label_Settings_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductLabelSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Label_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Label_Settings_Order_By>>;
  where?: InputMaybe<Product_Label_Settings_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductRecipeIngredientsArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_Ingredients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_Ingredients_Order_By>>;
  where?: InputMaybe<Product_Recipe_Ingredients_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductRecipeIngredients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_Ingredients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_Ingredients_Order_By>>;
  where?: InputMaybe<Product_Recipe_Ingredients_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductRecipesArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipes_Order_By>>;
  where?: InputMaybe<Product_Recipes_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductRecipes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipes_Order_By>>;
  where?: InputMaybe<Product_Recipes_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductS3ObjectsArgs = {
  distinct_on?: InputMaybe<Array<Product_S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_S3_Objects_Order_By>>;
  where?: InputMaybe<Product_S3_Objects_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductS3Objects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_S3_Objects_Order_By>>;
  where?: InputMaybe<Product_S3_Objects_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductSalesChannelMembersArgs = {
  distinct_on?: InputMaybe<Array<Product_Sales_Channel_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Sales_Channel_Members_Order_By>>;
  where?: InputMaybe<Product_Sales_Channel_Members_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductSalesChannelMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Sales_Channel_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Sales_Channel_Members_Order_By>>;
  where?: InputMaybe<Product_Sales_Channel_Members_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductStockLocationsArgs = {
  distinct_on?: InputMaybe<Array<Product_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Stock_Locations_Order_By>>;
  where?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductStockLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Stock_Locations_Order_By>>;
  where?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductSuppliersArgs = {
  distinct_on?: InputMaybe<Array<Product_Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Suppliers_Order_By>>;
  where?: InputMaybe<Product_Suppliers_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductSuppliers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Suppliers_Order_By>>;
  where?: InputMaybe<Product_Suppliers_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductUnitSalesPriceConfigsArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Order_By>>;
  where?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductUnitSalesPriceConfigs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Order_By>>;
  where?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductUnitsArgs = {
  distinct_on?: InputMaybe<Array<Product_Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Units_Order_By>>;
  where?: InputMaybe<Product_Units_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProductUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Units_Order_By>>;
  where?: InputMaybe<Product_Units_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsRollingInventoryItemsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsRollingInventoryItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsRollingInventoryLotSummaryItemsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsRollingInventoryLotSummaryItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsRollingInventorySummaryItemsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsRollingInventorySummaryItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsSearchProductsForCustomerResultsArgs = {
  distinct_on?: InputMaybe<Array<Search_Products_For_Customer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Products_For_Customer_Results_Order_By>>;
  where?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsSearchProductsForCustomerResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Search_Products_For_Customer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Products_For_Customer_Results_Order_By>>;
  where?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsSearchPurchasedProductsResultsArgs = {
  distinct_on?: InputMaybe<Array<Search_Purchased_Products_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Purchased_Products_Results_Order_By>>;
  where?: InputMaybe<Search_Purchased_Products_Results_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsSearchPurchasedProductsResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Search_Purchased_Products_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Purchased_Products_Results_Order_By>>;
  where?: InputMaybe<Search_Purchased_Products_Results_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsStockTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsStockTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsSupplierOrderGoodsIncomeItemContainersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsSupplierOrderGoodsIncomeItemContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsSupplierOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsSupplierOrderItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsSupplierOrderReturnItemContainersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Item_Containers_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsSupplierOrderReturnItemContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Item_Containers_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsSupplierOrderReturnedContainersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returned_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returned_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returned_Containers_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsSupplierOrderReturnedContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returned_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returned_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returned_Containers_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsTemporalProductsArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsTemporalProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};

/** aggregated selection of "products" */
export type Products_Aggregate = {
  __typename: 'products_aggregate';
  aggregate?: Maybe<Products_Aggregate_Fields>;
  nodes: Array<Products>;
};

export type Products_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Products_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Products_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Products_Aggregate_Bool_Exp_Count>;
};

export type Products_Aggregate_Bool_Exp_Bool_And = {
  arguments: Products_Select_Column_Products_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Products_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Products_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Products_Select_Column_Products_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Products_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Products_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Products_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "products" */
export type Products_Aggregate_Fields = {
  __typename: 'products_aggregate_fields';
  avg?: Maybe<Products_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Products_Max_Fields>;
  min?: Maybe<Products_Min_Fields>;
  stddev?: Maybe<Products_Stddev_Fields>;
  stddev_pop?: Maybe<Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Products_Stddev_Samp_Fields>;
  sum?: Maybe<Products_Sum_Fields>;
  var_pop?: Maybe<Products_Var_Pop_Fields>;
  var_samp?: Maybe<Products_Var_Samp_Fields>;
  variance?: Maybe<Products_Variance_Fields>;
};


/** aggregate fields of "products" */
export type Products_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "products" */
export type Products_Aggregate_Order_By = {
  avg?: InputMaybe<Products_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Products_Max_Order_By>;
  min?: InputMaybe<Products_Min_Order_By>;
  stddev?: InputMaybe<Products_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Products_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Products_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Products_Sum_Order_By>;
  var_pop?: InputMaybe<Products_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Products_Var_Samp_Order_By>;
  variance?: InputMaybe<Products_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Products_Append_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "products" */
export type Products_Arr_Rel_Insert_Input = {
  data: Array<Products_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Products_On_Conflict>;
};

/** aggregate avg on columns */
export type Products_Avg_Fields = {
  __typename: 'products_avg_fields';
  baseQuantity?: Maybe<Scalars['Float']>;
  bestBeforeDurationInDays?: Maybe<Scalars['Float']>;
  costPriceAveragePeriodId?: Maybe<Scalars['Float']>;
  csbId?: Maybe<Scalars['Float']>;
  gtin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pickingCategoryId?: Maybe<Scalars['Float']>;
  productImageId?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "products" */
export type Products_Avg_Order_By = {
  baseQuantity?: InputMaybe<Order_By>;
  bestBeforeDurationInDays?: InputMaybe<Order_By>;
  costPriceAveragePeriodId?: InputMaybe<Order_By>;
  csbId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pickingCategoryId?: InputMaybe<Order_By>;
  productImageId?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
export type Products_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Products_Bool_Exp>>;
  _not?: InputMaybe<Products_Bool_Exp>;
  _or?: InputMaybe<Array<Products_Bool_Exp>>;
  aggregatedDemandPlanOutgoingGoodsResults?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
  aggregatedDemandPlanOutgoingGoodsResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Bool_Exp>;
  aggregatedDemandPlanResults?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
  aggregatedDemandPlanResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Results_Aggregate_Bool_Exp>;
  aggregatedInventoryItemsByLocations?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
  aggregatedInventoryItemsByLocations_aggregate?: InputMaybe<Aggregated_Inventory_Items_By_Location_Aggregate_Bool_Exp>;
  allowedTenants?: InputMaybe<Jsonb_Comparison_Exp>;
  baseQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  bestBeforeDurationInDays?: InputMaybe<Int_Comparison_Exp>;
  costPriceAveragePeriod?: InputMaybe<Cost_Price_Average_Periods_Bool_Exp>;
  costPriceAveragePeriodId?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  creditNoteItems?: InputMaybe<Credit_Note_Items_Bool_Exp>;
  creditNoteItems_aggregate?: InputMaybe<Credit_Note_Items_Aggregate_Bool_Exp>;
  csbId?: InputMaybe<Bigint_Comparison_Exp>;
  currentTemporalProducts?: InputMaybe<Temporal_Products_Bool_Exp>;
  customerGroupPricings?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
  customerGroupPricings_aggregate?: InputMaybe<Customer_Group_Pricing_Aggregate_Bool_Exp>;
  customerOrderContainerReturnItems?: InputMaybe<Customer_Order_Container_Return_Items_Bool_Exp>;
  customerOrderContainerReturnItems_aggregate?: InputMaybe<Customer_Order_Container_Return_Items_Aggregate_Bool_Exp>;
  customerOrderItemContainerExpectations?: InputMaybe<Customer_Order_Item_Container_Expectations_Bool_Exp>;
  customerOrderItemContainerExpectations_aggregate?: InputMaybe<Customer_Order_Item_Container_Expectations_Aggregate_Bool_Exp>;
  customerOrderItems?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  customerOrderItemsViaContainerProduct?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  customerOrderItemsViaContainerProduct_aggregate?: InputMaybe<Customer_Order_Items_Aggregate_Bool_Exp>;
  customerOrderItems_aggregate?: InputMaybe<Customer_Order_Items_Aggregate_Bool_Exp>;
  customerOrderPickedContainers?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
  customerOrderPickedContainers_aggregate?: InputMaybe<Customer_Order_Picked_Container_Aggregate_Bool_Exp>;
  customerOrderReturnItemContainers?: InputMaybe<Customer_Order_Return_Item_Containers_Bool_Exp>;
  customerOrderReturnItemContainers_aggregate?: InputMaybe<Customer_Order_Return_Item_Containers_Aggregate_Bool_Exp>;
  customerProductAvailabilities?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
  customerProductAvailabilities_aggregate?: InputMaybe<Customer_Product_Availabilities_Aggregate_Bool_Exp>;
  cuttingPatternOutputs?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
  cuttingPatternOutputsDrafts?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
  cuttingPatternOutputsDrafts_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Draft_Aggregate_Bool_Exp>;
  cuttingPatternOutputs_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Aggregate_Bool_Exp>;
  cuttingPatterns?: InputMaybe<Cutting_Patterns_Bool_Exp>;
  cuttingPatternsDrafts?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
  cuttingPatternsDrafts_aggregate?: InputMaybe<Cutting_Patterns_Draft_Aggregate_Bool_Exp>;
  cuttingPatterns_aggregate?: InputMaybe<Cutting_Patterns_Aggregate_Bool_Exp>;
  dailyPriceLists?: InputMaybe<Daily_Price_Lists_Bool_Exp>;
  dailyPriceLists_aggregate?: InputMaybe<Daily_Price_Lists_Aggregate_Bool_Exp>;
  demandsForCoiResults?: InputMaybe<Demands_For_Coi_Results_Bool_Exp>;
  demandsForCoiResults_aggregate?: InputMaybe<Demands_For_Coi_Results_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  dismantleableProductsResults?: InputMaybe<Dismantleable_Products_Results_Bool_Exp>;
  dismantleableProductsResults_aggregate?: InputMaybe<Dismantleable_Products_Results_Aggregate_Bool_Exp>;
  dismantlingOutputWeightResults?: InputMaybe<Dismantling_Output_Weight_Results_Bool_Exp>;
  dismantlingOutputWeightResults_aggregate?: InputMaybe<Dismantling_Output_Weight_Results_Aggregate_Bool_Exp>;
  dismantlingPlanDemands?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
  dismantlingPlanDemands_aggregate?: InputMaybe<Dismantling_Plan_Demands_Aggregate_Bool_Exp>;
  dismantlingPlanRemainingPieces?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
  dismantlingPlanRemainingPiecesViaContainerProduct?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
  dismantlingPlanRemainingPiecesViaContainerProduct_aggregate?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Aggregate_Bool_Exp>;
  dismantlingPlanRemainingPieces_aggregate?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Aggregate_Bool_Exp>;
  domainId?: InputMaybe<String_Comparison_Exp>;
  globalSearchResults?: InputMaybe<Global_Search_Result_Bool_Exp>;
  globalSearchResults_aggregate?: InputMaybe<Global_Search_Result_Aggregate_Bool_Exp>;
  gtin?: InputMaybe<Bigint_Comparison_Exp>;
  highlight?: InputMaybe<String_Comparison_Exp>;
  i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  inventoryDailyPrognosisResults?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
  inventoryDailyPrognosisResults_aggregate?: InputMaybe<Inventory_Daily_Prognosis_Results_Aggregate_Bool_Exp>;
  inventoryDailyUsageResults?: InputMaybe<Inventory_Daily_Usage_Results_Bool_Exp>;
  inventoryDailyUsageResults_aggregate?: InputMaybe<Inventory_Daily_Usage_Results_Aggregate_Bool_Exp>;
  inventoryItems?: InputMaybe<Inventory_Items_Bool_Exp>;
  inventoryItemsByLocations?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
  inventoryItemsByLocations_aggregate?: InputMaybe<Inventory_Items_By_Location_Aggregate_Bool_Exp>;
  inventoryItems_aggregate?: InputMaybe<Inventory_Items_Aggregate_Bool_Exp>;
  isHighlightedOnWebshop?: InputMaybe<Boolean_Comparison_Exp>;
  isLotNumberTracked?: InputMaybe<Boolean_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  listPurchasedProductsResults?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
  listPurchasedProductsResults_aggregate?: InputMaybe<List_Purchased_Products_Result_Aggregate_Bool_Exp>;
  loggedActions?: InputMaybe<Product_Logged_Actions_Bool_Exp>;
  loggedActions_aggregate?: InputMaybe<Product_Logged_Actions_Aggregate_Bool_Exp>;
  lotNumberProducts?: InputMaybe<Lot_Number_Products_Bool_Exp>;
  lotNumberProducts_aggregate?: InputMaybe<Lot_Number_Products_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pickingCategory?: InputMaybe<Picking_Categories_Bool_Exp>;
  pickingCategoryId?: InputMaybe<Bigint_Comparison_Exp>;
  postings?: InputMaybe<Postings_Bool_Exp>;
  postings_aggregate?: InputMaybe<Postings_Aggregate_Bool_Exp>;
  pricingType?: InputMaybe<String_Comparison_Exp>;
  productAllowedContainersViaContainerProduct?: InputMaybe<Product_Allowed_Containers_Bool_Exp>;
  productAllowedContainersViaContainerProduct_aggregate?: InputMaybe<Product_Allowed_Containers_Aggregate_Bool_Exp>;
  productAllowedContainersViaProduct?: InputMaybe<Product_Allowed_Containers_Bool_Exp>;
  productAllowedContainersViaProduct_aggregate?: InputMaybe<Product_Allowed_Containers_Aggregate_Bool_Exp>;
  productGroupMembers?: InputMaybe<Product_Group_Members_Bool_Exp>;
  productGroupMembers_aggregate?: InputMaybe<Product_Group_Members_Aggregate_Bool_Exp>;
  productImage?: InputMaybe<Images_Bool_Exp>;
  productImageId?: InputMaybe<Bigint_Comparison_Exp>;
  productLabelSettings?: InputMaybe<Product_Label_Settings_Bool_Exp>;
  productLabelSettings_aggregate?: InputMaybe<Product_Label_Settings_Aggregate_Bool_Exp>;
  productRecipeIngredients?: InputMaybe<Product_Recipe_Ingredients_Bool_Exp>;
  productRecipeIngredients_aggregate?: InputMaybe<Product_Recipe_Ingredients_Aggregate_Bool_Exp>;
  productRecipes?: InputMaybe<Product_Recipes_Bool_Exp>;
  productRecipes_aggregate?: InputMaybe<Product_Recipes_Aggregate_Bool_Exp>;
  productS3Objects?: InputMaybe<Product_S3_Objects_Bool_Exp>;
  productS3Objects_aggregate?: InputMaybe<Product_S3_Objects_Aggregate_Bool_Exp>;
  productSalesChannelMembers?: InputMaybe<Product_Sales_Channel_Members_Bool_Exp>;
  productSalesChannelMembers_aggregate?: InputMaybe<Product_Sales_Channel_Members_Aggregate_Bool_Exp>;
  productStockLocations?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
  productStockLocations_aggregate?: InputMaybe<Product_Stock_Locations_Aggregate_Bool_Exp>;
  productSuppliers?: InputMaybe<Product_Suppliers_Bool_Exp>;
  productSuppliers_aggregate?: InputMaybe<Product_Suppliers_Aggregate_Bool_Exp>;
  productUnitSalesPriceConfigs?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
  productUnitSalesPriceConfigs_aggregate?: InputMaybe<Product_Unit_Sales_Price_Config_Aggregate_Bool_Exp>;
  productUnits?: InputMaybe<Product_Units_Bool_Exp>;
  productUnits_aggregate?: InputMaybe<Product_Units_Aggregate_Bool_Exp>;
  requirePicking?: InputMaybe<Boolean_Comparison_Exp>;
  rollingInventoryItems?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
  rollingInventoryItems_aggregate?: InputMaybe<Rolling_Inventory_Items_Aggregate_Bool_Exp>;
  rollingInventoryLotSummaryItems?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
  rollingInventoryLotSummaryItems_aggregate?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Aggregate_Bool_Exp>;
  rollingInventorySummaryItems?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
  rollingInventorySummaryItems_aggregate?: InputMaybe<Rolling_Inventory_Summary_Items_Aggregate_Bool_Exp>;
  searchProductsForCustomerResults?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
  searchProductsForCustomerResults_aggregate?: InputMaybe<Search_Products_For_Customer_Results_Aggregate_Bool_Exp>;
  searchPurchasedProductsResults?: InputMaybe<Search_Purchased_Products_Results_Bool_Exp>;
  searchPurchasedProductsResults_aggregate?: InputMaybe<Search_Purchased_Products_Results_Aggregate_Bool_Exp>;
  sellable?: InputMaybe<Boolean_Comparison_Exp>;
  sellableToAllCustomers?: InputMaybe<Boolean_Comparison_Exp>;
  state?: InputMaybe<Product_States_Enum_Comparison_Exp>;
  stockTransactions?: InputMaybe<Stock_Transactions_Bool_Exp>;
  stockTransactions_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Bool_Exp>;
  supplierOrderGoodsIncomeItemContainers?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Bool_Exp>;
  supplierOrderGoodsIncomeItemContainers_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Aggregate_Bool_Exp>;
  supplierOrderItems?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
  supplierOrderItems_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Bool_Exp>;
  supplierOrderReturnItemContainers?: InputMaybe<Supplier_Order_Return_Item_Containers_Bool_Exp>;
  supplierOrderReturnItemContainers_aggregate?: InputMaybe<Supplier_Order_Return_Item_Containers_Aggregate_Bool_Exp>;
  supplierOrderReturnedContainers?: InputMaybe<Supplier_Order_Returned_Containers_Bool_Exp>;
  supplierOrderReturnedContainers_aggregate?: InputMaybe<Supplier_Order_Returned_Containers_Aggregate_Bool_Exp>;
  temporalProducts?: InputMaybe<Temporal_Products_Bool_Exp>;
  temporalProducts_aggregate?: InputMaybe<Temporal_Products_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  vatType?: InputMaybe<Vat_Types_Bool_Exp>;
  vatTypeId?: InputMaybe<Bigint_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
  webshopId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "products" */
export enum Products_Constraint {
  /** unique or primary key constraint on columns "tenant", "csb_id" */
  ProductsCsbId = 'products_csb_id',
  /** unique or primary key constraint on columns "gtin" */
  ProductsGtinKey = 'products_gtin_key',
  /** unique or primary key constraint on columns "name", "tenant" */
  ProductsName = 'products_name',
  /** unique or primary key constraint on columns "id" */
  ProductsPkey = 'products_pkey',
  /** unique or primary key constraint on columns "tenant", "domain_id" */
  ProductsTenantDomainId = 'products_tenant_domain_id',
  /** unique or primary key constraint on columns "webshop_id", "tenant" */
  ProductsWebshopId = 'products_webshop_id'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Products_Delete_At_Path_Input = {
  allowedTenants?: InputMaybe<Array<Scalars['String']>>;
  i18n?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Products_Delete_Elem_Input = {
  allowedTenants?: InputMaybe<Scalars['Int']>;
  i18n?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Products_Delete_Key_Input = {
  allowedTenants?: InputMaybe<Scalars['String']>;
  i18n?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "products" */
export type Products_Inc_Input = {
  baseQuantity?: InputMaybe<Scalars['bigint']>;
  bestBeforeDurationInDays?: InputMaybe<Scalars['Int']>;
  costPriceAveragePeriodId?: InputMaybe<Scalars['bigint']>;
  csbId?: InputMaybe<Scalars['bigint']>;
  gtin?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  pickingCategoryId?: InputMaybe<Scalars['bigint']>;
  productImageId?: InputMaybe<Scalars['bigint']>;
  vatTypeId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "products" */
export type Products_Insert_Input = {
  aggregatedInventoryItemsByLocations?: InputMaybe<Aggregated_Inventory_Items_By_Location_Arr_Rel_Insert_Input>;
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
  baseQuantity?: InputMaybe<Scalars['bigint']>;
  bestBeforeDurationInDays?: InputMaybe<Scalars['Int']>;
  costPriceAveragePeriodId?: InputMaybe<Scalars['bigint']>;
  creditNoteItems?: InputMaybe<Credit_Note_Items_Arr_Rel_Insert_Input>;
  csbId?: InputMaybe<Scalars['bigint']>;
  customerGroupPricings?: InputMaybe<Customer_Group_Pricing_Arr_Rel_Insert_Input>;
  customerOrderContainerReturnItems?: InputMaybe<Customer_Order_Container_Return_Items_Arr_Rel_Insert_Input>;
  customerOrderItemContainerExpectations?: InputMaybe<Customer_Order_Item_Container_Expectations_Arr_Rel_Insert_Input>;
  customerOrderItems?: InputMaybe<Customer_Order_Items_Arr_Rel_Insert_Input>;
  customerOrderItemsViaContainerProduct?: InputMaybe<Customer_Order_Items_Arr_Rel_Insert_Input>;
  customerOrderPickedContainers?: InputMaybe<Customer_Order_Picked_Container_Arr_Rel_Insert_Input>;
  customerOrderReturnItemContainers?: InputMaybe<Customer_Order_Return_Item_Containers_Arr_Rel_Insert_Input>;
  customerProductAvailabilities?: InputMaybe<Customer_Product_Availabilities_Arr_Rel_Insert_Input>;
  cuttingPatternOutputs?: InputMaybe<Cutting_Pattern_Outputs_Arr_Rel_Insert_Input>;
  cuttingPatternOutputsDrafts?: InputMaybe<Cutting_Pattern_Outputs_Draft_Arr_Rel_Insert_Input>;
  cuttingPatterns?: InputMaybe<Cutting_Patterns_Arr_Rel_Insert_Input>;
  cuttingPatternsDrafts?: InputMaybe<Cutting_Patterns_Draft_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  dismantlingPlanDemands?: InputMaybe<Dismantling_Plan_Demands_Arr_Rel_Insert_Input>;
  domainId?: InputMaybe<Scalars['String']>;
  gtin?: InputMaybe<Scalars['bigint']>;
  highlight?: InputMaybe<Scalars['String']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  inventoryItems?: InputMaybe<Inventory_Items_Arr_Rel_Insert_Input>;
  inventoryItemsByLocations?: InputMaybe<Inventory_Items_By_Location_Arr_Rel_Insert_Input>;
  isHighlightedOnWebshop?: InputMaybe<Scalars['Boolean']>;
  isLotNumberTracked?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  lotNumberProducts?: InputMaybe<Lot_Number_Products_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  pickingCategory?: InputMaybe<Picking_Categories_Obj_Rel_Insert_Input>;
  pickingCategoryId?: InputMaybe<Scalars['bigint']>;
  productAllowedContainersViaContainerProduct?: InputMaybe<Product_Allowed_Containers_Arr_Rel_Insert_Input>;
  productAllowedContainersViaProduct?: InputMaybe<Product_Allowed_Containers_Arr_Rel_Insert_Input>;
  productGroupMembers?: InputMaybe<Product_Group_Members_Arr_Rel_Insert_Input>;
  productImage?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  productImageId?: InputMaybe<Scalars['bigint']>;
  productLabelSettings?: InputMaybe<Product_Label_Settings_Arr_Rel_Insert_Input>;
  productRecipeIngredients?: InputMaybe<Product_Recipe_Ingredients_Arr_Rel_Insert_Input>;
  productRecipes?: InputMaybe<Product_Recipes_Arr_Rel_Insert_Input>;
  productS3Objects?: InputMaybe<Product_S3_Objects_Arr_Rel_Insert_Input>;
  productSalesChannelMembers?: InputMaybe<Product_Sales_Channel_Members_Arr_Rel_Insert_Input>;
  productStockLocations?: InputMaybe<Product_Stock_Locations_Arr_Rel_Insert_Input>;
  productSuppliers?: InputMaybe<Product_Suppliers_Arr_Rel_Insert_Input>;
  productUnitSalesPriceConfigs?: InputMaybe<Product_Unit_Sales_Price_Config_Arr_Rel_Insert_Input>;
  productUnits?: InputMaybe<Product_Units_Arr_Rel_Insert_Input>;
  requirePicking?: InputMaybe<Scalars['Boolean']>;
  rollingInventoryItems?: InputMaybe<Rolling_Inventory_Items_Arr_Rel_Insert_Input>;
  sellable?: InputMaybe<Scalars['Boolean']>;
  sellableToAllCustomers?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Product_States_Enum>;
  stockTransactions?: InputMaybe<Stock_Transactions_Arr_Rel_Insert_Input>;
  supplierOrderGoodsIncomeItemContainers?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Arr_Rel_Insert_Input>;
  supplierOrderItems?: InputMaybe<Supplier_Order_Items_Arr_Rel_Insert_Input>;
  supplierOrderReturnItemContainers?: InputMaybe<Supplier_Order_Return_Item_Containers_Arr_Rel_Insert_Input>;
  supplierOrderReturnedContainers?: InputMaybe<Supplier_Order_Returned_Containers_Arr_Rel_Insert_Input>;
  temporalProducts?: InputMaybe<Temporal_Products_Arr_Rel_Insert_Input>;
  vatTypeId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  webshopId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Products_Max_Fields = {
  __typename: 'products_max_fields';
  baseQuantity?: Maybe<Scalars['bigint']>;
  bestBeforeDurationInDays?: Maybe<Scalars['Int']>;
  costPriceAveragePeriodId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  csbId?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  domainId?: Maybe<Scalars['String']>;
  gtin?: Maybe<Scalars['bigint']>;
  highlight?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pickingCategoryId?: Maybe<Scalars['bigint']>;
  productImageId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vatTypeId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
  webshopId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "products" */
export type Products_Max_Order_By = {
  baseQuantity?: InputMaybe<Order_By>;
  bestBeforeDurationInDays?: InputMaybe<Order_By>;
  costPriceAveragePeriodId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  csbId?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  highlight?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pickingCategoryId?: InputMaybe<Order_By>;
  productImageId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  webshopId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Products_Min_Fields = {
  __typename: 'products_min_fields';
  baseQuantity?: Maybe<Scalars['bigint']>;
  bestBeforeDurationInDays?: Maybe<Scalars['Int']>;
  costPriceAveragePeriodId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  csbId?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  domainId?: Maybe<Scalars['String']>;
  gtin?: Maybe<Scalars['bigint']>;
  highlight?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pickingCategoryId?: Maybe<Scalars['bigint']>;
  productImageId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vatTypeId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
  webshopId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "products" */
export type Products_Min_Order_By = {
  baseQuantity?: InputMaybe<Order_By>;
  bestBeforeDurationInDays?: InputMaybe<Order_By>;
  costPriceAveragePeriodId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  csbId?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  highlight?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pickingCategoryId?: InputMaybe<Order_By>;
  productImageId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  webshopId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "products" */
export type Products_Mutation_Response = {
  __typename: 'products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Products>;
};

/** input type for inserting object relation for remote table "products" */
export type Products_Obj_Rel_Insert_Input = {
  data: Products_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Products_On_Conflict>;
};

/** on_conflict condition type for table "products" */
export type Products_On_Conflict = {
  constraint: Products_Constraint;
  update_columns?: Array<Products_Update_Column>;
  where?: InputMaybe<Products_Bool_Exp>;
};

/** Ordering options when selecting data from "products". */
export type Products_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  aggregatedDemandPlanOutgoingGoodsResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate_Order_By>;
  aggregatedDemandPlanResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Results_Aggregate_Order_By>;
  aggregatedInventoryItemsByLocations_aggregate?: InputMaybe<Aggregated_Inventory_Items_By_Location_Aggregate_Order_By>;
  allowedTenants?: InputMaybe<Order_By>;
  baseQuantity?: InputMaybe<Order_By>;
  bestBeforeDurationInDays?: InputMaybe<Order_By>;
  costPriceAveragePeriod?: InputMaybe<Cost_Price_Average_Periods_Order_By>;
  costPriceAveragePeriodId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creditNoteItems_aggregate?: InputMaybe<Credit_Note_Items_Aggregate_Order_By>;
  csbId?: InputMaybe<Order_By>;
  currentTemporalProducts_aggregate?: InputMaybe<Temporal_Products_Aggregate_Order_By>;
  customerGroupPricings_aggregate?: InputMaybe<Customer_Group_Pricing_Aggregate_Order_By>;
  customerOrderContainerReturnItems_aggregate?: InputMaybe<Customer_Order_Container_Return_Items_Aggregate_Order_By>;
  customerOrderItemContainerExpectations_aggregate?: InputMaybe<Customer_Order_Item_Container_Expectations_Aggregate_Order_By>;
  customerOrderItemsViaContainerProduct_aggregate?: InputMaybe<Customer_Order_Items_Aggregate_Order_By>;
  customerOrderItems_aggregate?: InputMaybe<Customer_Order_Items_Aggregate_Order_By>;
  customerOrderPickedContainers_aggregate?: InputMaybe<Customer_Order_Picked_Container_Aggregate_Order_By>;
  customerOrderReturnItemContainers_aggregate?: InputMaybe<Customer_Order_Return_Item_Containers_Aggregate_Order_By>;
  customerProductAvailabilities_aggregate?: InputMaybe<Customer_Product_Availabilities_Aggregate_Order_By>;
  cuttingPatternOutputsDrafts_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Draft_Aggregate_Order_By>;
  cuttingPatternOutputs_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Aggregate_Order_By>;
  cuttingPatternsDrafts_aggregate?: InputMaybe<Cutting_Patterns_Draft_Aggregate_Order_By>;
  cuttingPatterns_aggregate?: InputMaybe<Cutting_Patterns_Aggregate_Order_By>;
  dailyPriceLists_aggregate?: InputMaybe<Daily_Price_Lists_Aggregate_Order_By>;
  demandsForCoiResults_aggregate?: InputMaybe<Demands_For_Coi_Results_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  dismantleableProductsResults_aggregate?: InputMaybe<Dismantleable_Products_Results_Aggregate_Order_By>;
  dismantlingOutputWeightResults_aggregate?: InputMaybe<Dismantling_Output_Weight_Results_Aggregate_Order_By>;
  dismantlingPlanDemands_aggregate?: InputMaybe<Dismantling_Plan_Demands_Aggregate_Order_By>;
  dismantlingPlanRemainingPiecesViaContainerProduct_aggregate?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Aggregate_Order_By>;
  dismantlingPlanRemainingPieces_aggregate?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Aggregate_Order_By>;
  domainId?: InputMaybe<Order_By>;
  globalSearchResults_aggregate?: InputMaybe<Global_Search_Result_Aggregate_Order_By>;
  gtin?: InputMaybe<Order_By>;
  highlight?: InputMaybe<Order_By>;
  i18n?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inventoryDailyPrognosisResults_aggregate?: InputMaybe<Inventory_Daily_Prognosis_Results_Aggregate_Order_By>;
  inventoryDailyUsageResults_aggregate?: InputMaybe<Inventory_Daily_Usage_Results_Aggregate_Order_By>;
  inventoryItemsByLocations_aggregate?: InputMaybe<Inventory_Items_By_Location_Aggregate_Order_By>;
  inventoryItems_aggregate?: InputMaybe<Inventory_Items_Aggregate_Order_By>;
  isHighlightedOnWebshop?: InputMaybe<Order_By>;
  isLotNumberTracked?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  listPurchasedProductsResults_aggregate?: InputMaybe<List_Purchased_Products_Result_Aggregate_Order_By>;
  loggedActions_aggregate?: InputMaybe<Product_Logged_Actions_Aggregate_Order_By>;
  lotNumberProducts_aggregate?: InputMaybe<Lot_Number_Products_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  pickingCategory?: InputMaybe<Picking_Categories_Order_By>;
  pickingCategoryId?: InputMaybe<Order_By>;
  postings_aggregate?: InputMaybe<Postings_Aggregate_Order_By>;
  pricingType?: InputMaybe<Order_By>;
  productAllowedContainersViaContainerProduct_aggregate?: InputMaybe<Product_Allowed_Containers_Aggregate_Order_By>;
  productAllowedContainersViaProduct_aggregate?: InputMaybe<Product_Allowed_Containers_Aggregate_Order_By>;
  productGroupMembers_aggregate?: InputMaybe<Product_Group_Members_Aggregate_Order_By>;
  productImage?: InputMaybe<Images_Order_By>;
  productImageId?: InputMaybe<Order_By>;
  productLabelSettings_aggregate?: InputMaybe<Product_Label_Settings_Aggregate_Order_By>;
  productRecipeIngredients_aggregate?: InputMaybe<Product_Recipe_Ingredients_Aggregate_Order_By>;
  productRecipes_aggregate?: InputMaybe<Product_Recipes_Aggregate_Order_By>;
  productS3Objects_aggregate?: InputMaybe<Product_S3_Objects_Aggregate_Order_By>;
  productSalesChannelMembers_aggregate?: InputMaybe<Product_Sales_Channel_Members_Aggregate_Order_By>;
  productStockLocations_aggregate?: InputMaybe<Product_Stock_Locations_Aggregate_Order_By>;
  productSuppliers_aggregate?: InputMaybe<Product_Suppliers_Aggregate_Order_By>;
  productUnitSalesPriceConfigs_aggregate?: InputMaybe<Product_Unit_Sales_Price_Config_Aggregate_Order_By>;
  productUnits_aggregate?: InputMaybe<Product_Units_Aggregate_Order_By>;
  requirePicking?: InputMaybe<Order_By>;
  rollingInventoryItems_aggregate?: InputMaybe<Rolling_Inventory_Items_Aggregate_Order_By>;
  rollingInventoryLotSummaryItems_aggregate?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Aggregate_Order_By>;
  rollingInventorySummaryItems_aggregate?: InputMaybe<Rolling_Inventory_Summary_Items_Aggregate_Order_By>;
  searchProductsForCustomerResults_aggregate?: InputMaybe<Search_Products_For_Customer_Results_Aggregate_Order_By>;
  searchPurchasedProductsResults_aggregate?: InputMaybe<Search_Purchased_Products_Results_Aggregate_Order_By>;
  sellable?: InputMaybe<Order_By>;
  sellableToAllCustomers?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  stockTransactions_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Order_By>;
  supplierOrderGoodsIncomeItemContainers_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Aggregate_Order_By>;
  supplierOrderItems_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Order_By>;
  supplierOrderReturnItemContainers_aggregate?: InputMaybe<Supplier_Order_Return_Item_Containers_Aggregate_Order_By>;
  supplierOrderReturnedContainers_aggregate?: InputMaybe<Supplier_Order_Returned_Containers_Aggregate_Order_By>;
  temporalProducts_aggregate?: InputMaybe<Temporal_Products_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vatType?: InputMaybe<Vat_Types_Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  webshopId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: products */
export type Products_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Products_Prepend_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
};

export type Products_Pricing_Args = {
  _as_of?: InputMaybe<Scalars['date']>;
};

/** select columns of table "products" */
export enum Products_Select_Column {
  /** column name */
  AllowedTenants = 'allowedTenants',
  /** column name */
  BaseQuantity = 'baseQuantity',
  /** column name */
  BestBeforeDurationInDays = 'bestBeforeDurationInDays',
  /** column name */
  CostPriceAveragePeriodId = 'costPriceAveragePeriodId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CsbId = 'csbId',
  /** column name */
  Description = 'description',
  /** column name */
  DomainId = 'domainId',
  /** column name */
  Gtin = 'gtin',
  /** column name */
  Highlight = 'highlight',
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  IsHighlightedOnWebshop = 'isHighlightedOnWebshop',
  /** column name */
  IsLotNumberTracked = 'isLotNumberTracked',
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name',
  /** column name */
  PickingCategoryId = 'pickingCategoryId',
  /** column name */
  ProductImageId = 'productImageId',
  /** column name */
  RequirePicking = 'requirePicking',
  /** column name */
  Sellable = 'sellable',
  /** column name */
  SellableToAllCustomers = 'sellableToAllCustomers',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VatTypeId = 'vatTypeId',
  /** column name */
  Version = 'version',
  /** column name */
  WebshopId = 'webshopId'
}

/** select "products_aggregate_bool_exp_bool_and_arguments_columns" columns of table "products" */
export enum Products_Select_Column_Products_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsHighlightedOnWebshop = 'isHighlightedOnWebshop',
  /** column name */
  IsLotNumberTracked = 'isLotNumberTracked',
  /** column name */
  RequirePicking = 'requirePicking',
  /** column name */
  Sellable = 'sellable',
  /** column name */
  SellableToAllCustomers = 'sellableToAllCustomers'
}

/** select "products_aggregate_bool_exp_bool_or_arguments_columns" columns of table "products" */
export enum Products_Select_Column_Products_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsHighlightedOnWebshop = 'isHighlightedOnWebshop',
  /** column name */
  IsLotNumberTracked = 'isLotNumberTracked',
  /** column name */
  RequirePicking = 'requirePicking',
  /** column name */
  Sellable = 'sellable',
  /** column name */
  SellableToAllCustomers = 'sellableToAllCustomers'
}

/** input type for updating data in table "products" */
export type Products_Set_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
  baseQuantity?: InputMaybe<Scalars['bigint']>;
  bestBeforeDurationInDays?: InputMaybe<Scalars['Int']>;
  costPriceAveragePeriodId?: InputMaybe<Scalars['bigint']>;
  csbId?: InputMaybe<Scalars['bigint']>;
  description?: InputMaybe<Scalars['String']>;
  domainId?: InputMaybe<Scalars['String']>;
  gtin?: InputMaybe<Scalars['bigint']>;
  highlight?: InputMaybe<Scalars['String']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  isHighlightedOnWebshop?: InputMaybe<Scalars['Boolean']>;
  isLotNumberTracked?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pickingCategoryId?: InputMaybe<Scalars['bigint']>;
  productImageId?: InputMaybe<Scalars['bigint']>;
  requirePicking?: InputMaybe<Scalars['Boolean']>;
  sellable?: InputMaybe<Scalars['Boolean']>;
  sellableToAllCustomers?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Product_States_Enum>;
  vatTypeId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  webshopId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Products_Stddev_Fields = {
  __typename: 'products_stddev_fields';
  baseQuantity?: Maybe<Scalars['Float']>;
  bestBeforeDurationInDays?: Maybe<Scalars['Float']>;
  costPriceAveragePeriodId?: Maybe<Scalars['Float']>;
  csbId?: Maybe<Scalars['Float']>;
  gtin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pickingCategoryId?: Maybe<Scalars['Float']>;
  productImageId?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "products" */
export type Products_Stddev_Order_By = {
  baseQuantity?: InputMaybe<Order_By>;
  bestBeforeDurationInDays?: InputMaybe<Order_By>;
  costPriceAveragePeriodId?: InputMaybe<Order_By>;
  csbId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pickingCategoryId?: InputMaybe<Order_By>;
  productImageId?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Products_Stddev_Pop_Fields = {
  __typename: 'products_stddev_pop_fields';
  baseQuantity?: Maybe<Scalars['Float']>;
  bestBeforeDurationInDays?: Maybe<Scalars['Float']>;
  costPriceAveragePeriodId?: Maybe<Scalars['Float']>;
  csbId?: Maybe<Scalars['Float']>;
  gtin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pickingCategoryId?: Maybe<Scalars['Float']>;
  productImageId?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "products" */
export type Products_Stddev_Pop_Order_By = {
  baseQuantity?: InputMaybe<Order_By>;
  bestBeforeDurationInDays?: InputMaybe<Order_By>;
  costPriceAveragePeriodId?: InputMaybe<Order_By>;
  csbId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pickingCategoryId?: InputMaybe<Order_By>;
  productImageId?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Products_Stddev_Samp_Fields = {
  __typename: 'products_stddev_samp_fields';
  baseQuantity?: Maybe<Scalars['Float']>;
  bestBeforeDurationInDays?: Maybe<Scalars['Float']>;
  costPriceAveragePeriodId?: Maybe<Scalars['Float']>;
  csbId?: Maybe<Scalars['Float']>;
  gtin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pickingCategoryId?: Maybe<Scalars['Float']>;
  productImageId?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "products" */
export type Products_Stddev_Samp_Order_By = {
  baseQuantity?: InputMaybe<Order_By>;
  bestBeforeDurationInDays?: InputMaybe<Order_By>;
  costPriceAveragePeriodId?: InputMaybe<Order_By>;
  csbId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pickingCategoryId?: InputMaybe<Order_By>;
  productImageId?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "products" */
export type Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Products_Stream_Cursor_Value_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
  baseQuantity?: InputMaybe<Scalars['bigint']>;
  bestBeforeDurationInDays?: InputMaybe<Scalars['Int']>;
  costPriceAveragePeriodId?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  csbId?: InputMaybe<Scalars['bigint']>;
  description?: InputMaybe<Scalars['String']>;
  domainId?: InputMaybe<Scalars['String']>;
  gtin?: InputMaybe<Scalars['bigint']>;
  highlight?: InputMaybe<Scalars['String']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  isHighlightedOnWebshop?: InputMaybe<Scalars['Boolean']>;
  isLotNumberTracked?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pickingCategoryId?: InputMaybe<Scalars['bigint']>;
  productImageId?: InputMaybe<Scalars['bigint']>;
  requirePicking?: InputMaybe<Scalars['Boolean']>;
  sellable?: InputMaybe<Scalars['Boolean']>;
  sellableToAllCustomers?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Product_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vatTypeId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  webshopId?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Products_Sum_Fields = {
  __typename: 'products_sum_fields';
  baseQuantity?: Maybe<Scalars['bigint']>;
  bestBeforeDurationInDays?: Maybe<Scalars['Int']>;
  costPriceAveragePeriodId?: Maybe<Scalars['bigint']>;
  csbId?: Maybe<Scalars['bigint']>;
  gtin?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  pickingCategoryId?: Maybe<Scalars['bigint']>;
  productImageId?: Maybe<Scalars['bigint']>;
  vatTypeId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "products" */
export type Products_Sum_Order_By = {
  baseQuantity?: InputMaybe<Order_By>;
  bestBeforeDurationInDays?: InputMaybe<Order_By>;
  costPriceAveragePeriodId?: InputMaybe<Order_By>;
  csbId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pickingCategoryId?: InputMaybe<Order_By>;
  productImageId?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "products" */
export enum Products_Update_Column {
  /** column name */
  AllowedTenants = 'allowedTenants',
  /** column name */
  BaseQuantity = 'baseQuantity',
  /** column name */
  BestBeforeDurationInDays = 'bestBeforeDurationInDays',
  /** column name */
  CostPriceAveragePeriodId = 'costPriceAveragePeriodId',
  /** column name */
  CsbId = 'csbId',
  /** column name */
  Description = 'description',
  /** column name */
  DomainId = 'domainId',
  /** column name */
  Gtin = 'gtin',
  /** column name */
  Highlight = 'highlight',
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  IsHighlightedOnWebshop = 'isHighlightedOnWebshop',
  /** column name */
  IsLotNumberTracked = 'isLotNumberTracked',
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name',
  /** column name */
  PickingCategoryId = 'pickingCategoryId',
  /** column name */
  ProductImageId = 'productImageId',
  /** column name */
  RequirePicking = 'requirePicking',
  /** column name */
  Sellable = 'sellable',
  /** column name */
  SellableToAllCustomers = 'sellableToAllCustomers',
  /** column name */
  State = 'state',
  /** column name */
  VatTypeId = 'vatTypeId',
  /** column name */
  Version = 'version',
  /** column name */
  WebshopId = 'webshopId'
}

export type Products_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Products_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Products_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Products_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Products_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Products_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Products_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Products_Set_Input>;
  /** filter the rows which have to be updated */
  where: Products_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Products_Var_Pop_Fields = {
  __typename: 'products_var_pop_fields';
  baseQuantity?: Maybe<Scalars['Float']>;
  bestBeforeDurationInDays?: Maybe<Scalars['Float']>;
  costPriceAveragePeriodId?: Maybe<Scalars['Float']>;
  csbId?: Maybe<Scalars['Float']>;
  gtin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pickingCategoryId?: Maybe<Scalars['Float']>;
  productImageId?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "products" */
export type Products_Var_Pop_Order_By = {
  baseQuantity?: InputMaybe<Order_By>;
  bestBeforeDurationInDays?: InputMaybe<Order_By>;
  costPriceAveragePeriodId?: InputMaybe<Order_By>;
  csbId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pickingCategoryId?: InputMaybe<Order_By>;
  productImageId?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Products_Var_Samp_Fields = {
  __typename: 'products_var_samp_fields';
  baseQuantity?: Maybe<Scalars['Float']>;
  bestBeforeDurationInDays?: Maybe<Scalars['Float']>;
  costPriceAveragePeriodId?: Maybe<Scalars['Float']>;
  csbId?: Maybe<Scalars['Float']>;
  gtin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pickingCategoryId?: Maybe<Scalars['Float']>;
  productImageId?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "products" */
export type Products_Var_Samp_Order_By = {
  baseQuantity?: InputMaybe<Order_By>;
  bestBeforeDurationInDays?: InputMaybe<Order_By>;
  costPriceAveragePeriodId?: InputMaybe<Order_By>;
  csbId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pickingCategoryId?: InputMaybe<Order_By>;
  productImageId?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Products_Variance_Fields = {
  __typename: 'products_variance_fields';
  baseQuantity?: Maybe<Scalars['Float']>;
  bestBeforeDurationInDays?: Maybe<Scalars['Float']>;
  costPriceAveragePeriodId?: Maybe<Scalars['Float']>;
  csbId?: Maybe<Scalars['Float']>;
  gtin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pickingCategoryId?: Maybe<Scalars['Float']>;
  productImageId?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "products" */
export type Products_Variance_Order_By = {
  baseQuantity?: InputMaybe<Order_By>;
  bestBeforeDurationInDays?: InputMaybe<Order_By>;
  costPriceAveragePeriodId?: InputMaybe<Order_By>;
  csbId?: InputMaybe<Order_By>;
  gtin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pickingCategoryId?: InputMaybe<Order_By>;
  productImageId?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "proletarian_archived_jobs" */
export type Proletarian_Archived_Jobs = {
  __typename: 'proletarian_archived_jobs';
  attempts: Scalars['bigint'];
  enqueuedAt: Scalars['timestamptz'];
  finishedAt: Scalars['timestamptz'];
  jobId: Scalars['uuid'];
  jobType: Scalars['String'];
  payload: Scalars['jsonb'];
  processAt: Scalars['timestamptz'];
  queue: Scalars['String'];
  status: Scalars['String'];
};


/** columns and relationships of "proletarian_archived_jobs" */
export type Proletarian_Archived_JobsPayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "proletarian_archived_jobs" */
export type Proletarian_Archived_Jobs_Aggregate = {
  __typename: 'proletarian_archived_jobs_aggregate';
  aggregate?: Maybe<Proletarian_Archived_Jobs_Aggregate_Fields>;
  nodes: Array<Proletarian_Archived_Jobs>;
};

/** aggregate fields of "proletarian_archived_jobs" */
export type Proletarian_Archived_Jobs_Aggregate_Fields = {
  __typename: 'proletarian_archived_jobs_aggregate_fields';
  avg?: Maybe<Proletarian_Archived_Jobs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Proletarian_Archived_Jobs_Max_Fields>;
  min?: Maybe<Proletarian_Archived_Jobs_Min_Fields>;
  stddev?: Maybe<Proletarian_Archived_Jobs_Stddev_Fields>;
  stddev_pop?: Maybe<Proletarian_Archived_Jobs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Proletarian_Archived_Jobs_Stddev_Samp_Fields>;
  sum?: Maybe<Proletarian_Archived_Jobs_Sum_Fields>;
  var_pop?: Maybe<Proletarian_Archived_Jobs_Var_Pop_Fields>;
  var_samp?: Maybe<Proletarian_Archived_Jobs_Var_Samp_Fields>;
  variance?: Maybe<Proletarian_Archived_Jobs_Variance_Fields>;
};


/** aggregate fields of "proletarian_archived_jobs" */
export type Proletarian_Archived_Jobs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Proletarian_Archived_Jobs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Proletarian_Archived_Jobs_Avg_Fields = {
  __typename: 'proletarian_archived_jobs_avg_fields';
  attempts?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "proletarian_archived_jobs". All fields are combined with a logical 'AND'. */
export type Proletarian_Archived_Jobs_Bool_Exp = {
  _and?: InputMaybe<Array<Proletarian_Archived_Jobs_Bool_Exp>>;
  _not?: InputMaybe<Proletarian_Archived_Jobs_Bool_Exp>;
  _or?: InputMaybe<Array<Proletarian_Archived_Jobs_Bool_Exp>>;
  attempts?: InputMaybe<Bigint_Comparison_Exp>;
  enqueuedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  finishedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  jobId?: InputMaybe<Uuid_Comparison_Exp>;
  jobType?: InputMaybe<String_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  processAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  queue?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Proletarian_Archived_Jobs_Max_Fields = {
  __typename: 'proletarian_archived_jobs_max_fields';
  attempts?: Maybe<Scalars['bigint']>;
  enqueuedAt?: Maybe<Scalars['timestamptz']>;
  finishedAt?: Maybe<Scalars['timestamptz']>;
  jobId?: Maybe<Scalars['uuid']>;
  jobType?: Maybe<Scalars['String']>;
  processAt?: Maybe<Scalars['timestamptz']>;
  queue?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Proletarian_Archived_Jobs_Min_Fields = {
  __typename: 'proletarian_archived_jobs_min_fields';
  attempts?: Maybe<Scalars['bigint']>;
  enqueuedAt?: Maybe<Scalars['timestamptz']>;
  finishedAt?: Maybe<Scalars['timestamptz']>;
  jobId?: Maybe<Scalars['uuid']>;
  jobType?: Maybe<Scalars['String']>;
  processAt?: Maybe<Scalars['timestamptz']>;
  queue?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "proletarian_archived_jobs". */
export type Proletarian_Archived_Jobs_Order_By = {
  attempts?: InputMaybe<Order_By>;
  enqueuedAt?: InputMaybe<Order_By>;
  finishedAt?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  jobType?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  processAt?: InputMaybe<Order_By>;
  queue?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** select columns of table "proletarian_archived_jobs" */
export enum Proletarian_Archived_Jobs_Select_Column {
  /** column name */
  Attempts = 'attempts',
  /** column name */
  EnqueuedAt = 'enqueuedAt',
  /** column name */
  FinishedAt = 'finishedAt',
  /** column name */
  JobId = 'jobId',
  /** column name */
  JobType = 'jobType',
  /** column name */
  Payload = 'payload',
  /** column name */
  ProcessAt = 'processAt',
  /** column name */
  Queue = 'queue',
  /** column name */
  Status = 'status'
}

/** aggregate stddev on columns */
export type Proletarian_Archived_Jobs_Stddev_Fields = {
  __typename: 'proletarian_archived_jobs_stddev_fields';
  attempts?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Proletarian_Archived_Jobs_Stddev_Pop_Fields = {
  __typename: 'proletarian_archived_jobs_stddev_pop_fields';
  attempts?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Proletarian_Archived_Jobs_Stddev_Samp_Fields = {
  __typename: 'proletarian_archived_jobs_stddev_samp_fields';
  attempts?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "proletarian_archived_jobs" */
export type Proletarian_Archived_Jobs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Proletarian_Archived_Jobs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Proletarian_Archived_Jobs_Stream_Cursor_Value_Input = {
  attempts?: InputMaybe<Scalars['bigint']>;
  enqueuedAt?: InputMaybe<Scalars['timestamptz']>;
  finishedAt?: InputMaybe<Scalars['timestamptz']>;
  jobId?: InputMaybe<Scalars['uuid']>;
  jobType?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  processAt?: InputMaybe<Scalars['timestamptz']>;
  queue?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Proletarian_Archived_Jobs_Sum_Fields = {
  __typename: 'proletarian_archived_jobs_sum_fields';
  attempts?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Proletarian_Archived_Jobs_Var_Pop_Fields = {
  __typename: 'proletarian_archived_jobs_var_pop_fields';
  attempts?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Proletarian_Archived_Jobs_Var_Samp_Fields = {
  __typename: 'proletarian_archived_jobs_var_samp_fields';
  attempts?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Proletarian_Archived_Jobs_Variance_Fields = {
  __typename: 'proletarian_archived_jobs_variance_fields';
  attempts?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "proletarian_jobs" */
export type Proletarian_Jobs = {
  __typename: 'proletarian_jobs';
  attempts: Scalars['bigint'];
  enqueuedAt: Scalars['timestamptz'];
  jobId: Scalars['uuid'];
  jobType: Scalars['String'];
  payload: Scalars['jsonb'];
  processAt: Scalars['timestamptz'];
  queue: Scalars['String'];
};


/** columns and relationships of "proletarian_jobs" */
export type Proletarian_JobsPayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "proletarian_jobs" */
export type Proletarian_Jobs_Aggregate = {
  __typename: 'proletarian_jobs_aggregate';
  aggregate?: Maybe<Proletarian_Jobs_Aggregate_Fields>;
  nodes: Array<Proletarian_Jobs>;
};

/** aggregate fields of "proletarian_jobs" */
export type Proletarian_Jobs_Aggregate_Fields = {
  __typename: 'proletarian_jobs_aggregate_fields';
  avg?: Maybe<Proletarian_Jobs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Proletarian_Jobs_Max_Fields>;
  min?: Maybe<Proletarian_Jobs_Min_Fields>;
  stddev?: Maybe<Proletarian_Jobs_Stddev_Fields>;
  stddev_pop?: Maybe<Proletarian_Jobs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Proletarian_Jobs_Stddev_Samp_Fields>;
  sum?: Maybe<Proletarian_Jobs_Sum_Fields>;
  var_pop?: Maybe<Proletarian_Jobs_Var_Pop_Fields>;
  var_samp?: Maybe<Proletarian_Jobs_Var_Samp_Fields>;
  variance?: Maybe<Proletarian_Jobs_Variance_Fields>;
};


/** aggregate fields of "proletarian_jobs" */
export type Proletarian_Jobs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Proletarian_Jobs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Proletarian_Jobs_Avg_Fields = {
  __typename: 'proletarian_jobs_avg_fields';
  attempts?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "proletarian_jobs". All fields are combined with a logical 'AND'. */
export type Proletarian_Jobs_Bool_Exp = {
  _and?: InputMaybe<Array<Proletarian_Jobs_Bool_Exp>>;
  _not?: InputMaybe<Proletarian_Jobs_Bool_Exp>;
  _or?: InputMaybe<Array<Proletarian_Jobs_Bool_Exp>>;
  attempts?: InputMaybe<Bigint_Comparison_Exp>;
  enqueuedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  jobId?: InputMaybe<Uuid_Comparison_Exp>;
  jobType?: InputMaybe<String_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  processAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  queue?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Proletarian_Jobs_Max_Fields = {
  __typename: 'proletarian_jobs_max_fields';
  attempts?: Maybe<Scalars['bigint']>;
  enqueuedAt?: Maybe<Scalars['timestamptz']>;
  jobId?: Maybe<Scalars['uuid']>;
  jobType?: Maybe<Scalars['String']>;
  processAt?: Maybe<Scalars['timestamptz']>;
  queue?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Proletarian_Jobs_Min_Fields = {
  __typename: 'proletarian_jobs_min_fields';
  attempts?: Maybe<Scalars['bigint']>;
  enqueuedAt?: Maybe<Scalars['timestamptz']>;
  jobId?: Maybe<Scalars['uuid']>;
  jobType?: Maybe<Scalars['String']>;
  processAt?: Maybe<Scalars['timestamptz']>;
  queue?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "proletarian_jobs". */
export type Proletarian_Jobs_Order_By = {
  attempts?: InputMaybe<Order_By>;
  enqueuedAt?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  jobType?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  processAt?: InputMaybe<Order_By>;
  queue?: InputMaybe<Order_By>;
};

/** select columns of table "proletarian_jobs" */
export enum Proletarian_Jobs_Select_Column {
  /** column name */
  Attempts = 'attempts',
  /** column name */
  EnqueuedAt = 'enqueuedAt',
  /** column name */
  JobId = 'jobId',
  /** column name */
  JobType = 'jobType',
  /** column name */
  Payload = 'payload',
  /** column name */
  ProcessAt = 'processAt',
  /** column name */
  Queue = 'queue'
}

/** aggregate stddev on columns */
export type Proletarian_Jobs_Stddev_Fields = {
  __typename: 'proletarian_jobs_stddev_fields';
  attempts?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Proletarian_Jobs_Stddev_Pop_Fields = {
  __typename: 'proletarian_jobs_stddev_pop_fields';
  attempts?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Proletarian_Jobs_Stddev_Samp_Fields = {
  __typename: 'proletarian_jobs_stddev_samp_fields';
  attempts?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "proletarian_jobs" */
export type Proletarian_Jobs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Proletarian_Jobs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Proletarian_Jobs_Stream_Cursor_Value_Input = {
  attempts?: InputMaybe<Scalars['bigint']>;
  enqueuedAt?: InputMaybe<Scalars['timestamptz']>;
  jobId?: InputMaybe<Scalars['uuid']>;
  jobType?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  processAt?: InputMaybe<Scalars['timestamptz']>;
  queue?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Proletarian_Jobs_Sum_Fields = {
  __typename: 'proletarian_jobs_sum_fields';
  attempts?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Proletarian_Jobs_Var_Pop_Fields = {
  __typename: 'proletarian_jobs_var_pop_fields';
  attempts?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Proletarian_Jobs_Var_Samp_Fields = {
  __typename: 'proletarian_jobs_var_samp_fields';
  attempts?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Proletarian_Jobs_Variance_Fields = {
  __typename: 'proletarian_jobs_variance_fields';
  attempts?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "public_logged_actions" */
export type Public_Logged_Actions = {
  __typename: 'public_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "public_logged_actions" */
export type Public_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "public_logged_actions" */
export type Public_Logged_Actions_Aggregate = {
  __typename: 'public_logged_actions_aggregate';
  aggregate?: Maybe<Public_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Public_Logged_Actions>;
};

export type Public_Logged_Actions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Public_Logged_Actions_Aggregate_Bool_Exp_Count>;
};

export type Public_Logged_Actions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Public_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Public_Logged_Actions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "public_logged_actions" */
export type Public_Logged_Actions_Aggregate_Fields = {
  __typename: 'public_logged_actions_aggregate_fields';
  avg?: Maybe<Public_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Public_Logged_Actions_Max_Fields>;
  min?: Maybe<Public_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Public_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Public_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Public_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Public_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Public_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Public_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Public_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "public_logged_actions" */
export type Public_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Public_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "public_logged_actions" */
export type Public_Logged_Actions_Aggregate_Order_By = {
  avg?: InputMaybe<Public_Logged_Actions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Public_Logged_Actions_Max_Order_By>;
  min?: InputMaybe<Public_Logged_Actions_Min_Order_By>;
  stddev?: InputMaybe<Public_Logged_Actions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Public_Logged_Actions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Public_Logged_Actions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Public_Logged_Actions_Sum_Order_By>;
  var_pop?: InputMaybe<Public_Logged_Actions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Public_Logged_Actions_Var_Samp_Order_By>;
  variance?: InputMaybe<Public_Logged_Actions_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Public_Logged_Actions_Avg_Fields = {
  __typename: 'public_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "public_logged_actions" */
export type Public_Logged_Actions_Avg_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "public_logged_actions". All fields are combined with a logical 'AND'. */
export type Public_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Public_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Public_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Public_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Public_Logged_Actions_Max_Fields = {
  __typename: 'public_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "public_logged_actions" */
export type Public_Logged_Actions_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Public_Logged_Actions_Min_Fields = {
  __typename: 'public_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "public_logged_actions" */
export type Public_Logged_Actions_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "public_logged_actions". */
export type Public_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "public_logged_actions" */
export enum Public_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Role = 'role',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Public_Logged_Actions_Stddev_Fields = {
  __typename: 'public_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "public_logged_actions" */
export type Public_Logged_Actions_Stddev_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Public_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'public_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "public_logged_actions" */
export type Public_Logged_Actions_Stddev_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Public_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'public_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "public_logged_actions" */
export type Public_Logged_Actions_Stddev_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "public_logged_actions" */
export type Public_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Public_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Public_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  role?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Public_Logged_Actions_Sum_Fields = {
  __typename: 'public_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "public_logged_actions" */
export type Public_Logged_Actions_Sum_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Public_Logged_Actions_Var_Pop_Fields = {
  __typename: 'public_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "public_logged_actions" */
export type Public_Logged_Actions_Var_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Public_Logged_Actions_Var_Samp_Fields = {
  __typename: 'public_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "public_logged_actions" */
export type Public_Logged_Actions_Var_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Public_Logged_Actions_Variance_Fields = {
  __typename: 'public_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "public_logged_actions" */
export type Public_Logged_Actions_Variance_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "public_tenant_info_result" */
export type Public_Tenant_Info_Result = {
  __typename: 'public_tenant_info_result';
  auth0ClientId: Scalars['String'];
  auth0Domain: Scalars['String'];
  languages: Scalars['_text'];
  mainLanguage: Scalars['String'];
  tenant: Scalars['String'];
  tenantShortName: Scalars['String'];
};

export type Public_Tenant_Info_Result_Aggregate = {
  __typename: 'public_tenant_info_result_aggregate';
  aggregate?: Maybe<Public_Tenant_Info_Result_Aggregate_Fields>;
  nodes: Array<Public_Tenant_Info_Result>;
};

/** aggregate fields of "public_tenant_info_result" */
export type Public_Tenant_Info_Result_Aggregate_Fields = {
  __typename: 'public_tenant_info_result_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Public_Tenant_Info_Result_Max_Fields>;
  min?: Maybe<Public_Tenant_Info_Result_Min_Fields>;
};


/** aggregate fields of "public_tenant_info_result" */
export type Public_Tenant_Info_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Public_Tenant_Info_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "public_tenant_info_result". All fields are combined with a logical 'AND'. */
export type Public_Tenant_Info_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Public_Tenant_Info_Result_Bool_Exp>>;
  _not?: InputMaybe<Public_Tenant_Info_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Public_Tenant_Info_Result_Bool_Exp>>;
  auth0ClientId?: InputMaybe<String_Comparison_Exp>;
  auth0Domain?: InputMaybe<String_Comparison_Exp>;
  languages?: InputMaybe<_Text_Comparison_Exp>;
  mainLanguage?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  tenantShortName?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Public_Tenant_Info_Result_Max_Fields = {
  __typename: 'public_tenant_info_result_max_fields';
  auth0ClientId?: Maybe<Scalars['String']>;
  auth0Domain?: Maybe<Scalars['String']>;
  mainLanguage?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  tenantShortName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Public_Tenant_Info_Result_Min_Fields = {
  __typename: 'public_tenant_info_result_min_fields';
  auth0ClientId?: Maybe<Scalars['String']>;
  auth0Domain?: Maybe<Scalars['String']>;
  mainLanguage?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  tenantShortName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "public_tenant_info_result". */
export type Public_Tenant_Info_Result_Order_By = {
  auth0ClientId?: InputMaybe<Order_By>;
  auth0Domain?: InputMaybe<Order_By>;
  languages?: InputMaybe<Order_By>;
  mainLanguage?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  tenantShortName?: InputMaybe<Order_By>;
};

/** select columns of table "public_tenant_info_result" */
export enum Public_Tenant_Info_Result_Select_Column {
  /** column name */
  Auth0ClientId = 'auth0ClientId',
  /** column name */
  Auth0Domain = 'auth0Domain',
  /** column name */
  Languages = 'languages',
  /** column name */
  MainLanguage = 'mainLanguage',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  TenantShortName = 'tenantShortName'
}

/** Streaming cursor of the table "public_tenant_info_result" */
export type Public_Tenant_Info_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Public_Tenant_Info_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Public_Tenant_Info_Result_Stream_Cursor_Value_Input = {
  auth0ClientId?: InputMaybe<Scalars['String']>;
  auth0Domain?: InputMaybe<Scalars['String']>;
  languages?: InputMaybe<Scalars['_text']>;
  mainLanguage?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  tenantShortName?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "purchasing_suggestions" */
export type Purchasing_Suggestions = {
  __typename: 'purchasing_suggestions';
  /** An array relationship */
  aggregatedDemandPlanResults: Array<Aggregated_Demand_Plan_Results>;
  /** An aggregate relationship */
  aggregatedDemandPlanResults_aggregate: Aggregated_Demand_Plan_Results_Aggregate;
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};


/** columns and relationships of "purchasing_suggestions" */
export type Purchasing_SuggestionsAggregatedDemandPlanResultsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};


/** columns and relationships of "purchasing_suggestions" */
export type Purchasing_SuggestionsAggregatedDemandPlanResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};

/** aggregated selection of "purchasing_suggestions" */
export type Purchasing_Suggestions_Aggregate = {
  __typename: 'purchasing_suggestions_aggregate';
  aggregate?: Maybe<Purchasing_Suggestions_Aggregate_Fields>;
  nodes: Array<Purchasing_Suggestions>;
};

/** aggregate fields of "purchasing_suggestions" */
export type Purchasing_Suggestions_Aggregate_Fields = {
  __typename: 'purchasing_suggestions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Purchasing_Suggestions_Max_Fields>;
  min?: Maybe<Purchasing_Suggestions_Min_Fields>;
};


/** aggregate fields of "purchasing_suggestions" */
export type Purchasing_Suggestions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Purchasing_Suggestions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "purchasing_suggestions". All fields are combined with a logical 'AND'. */
export type Purchasing_Suggestions_Bool_Exp = {
  _and?: InputMaybe<Array<Purchasing_Suggestions_Bool_Exp>>;
  _not?: InputMaybe<Purchasing_Suggestions_Bool_Exp>;
  _or?: InputMaybe<Array<Purchasing_Suggestions_Bool_Exp>>;
  aggregatedDemandPlanResults?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
  aggregatedDemandPlanResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Results_Aggregate_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Purchasing_Suggestions_Enum {
  /** you cannot fulfill the expected usage until restock, any(inventory_daily_prognosis(daterange)) <= security_monetary_quantity */
  Critical = 'CRITICAL',
  /** every(inventory_daily_prognosis(daterange)) > notification_monetary_quantity && expected_incoming_goods(daterange) = 0 */
  Ok = 'OK',
  /** any(inventory_daily_prognosis(daterange)) <= notification_monetary_quantity */
  Purchase = 'PURCHASE',
  /** every(inventory_daily_prognosis(daterange)) > security_monetary_quantity && expected_incoming_goods(daterange) > 0 */
  Purchased = 'PURCHASED',
  /** notification_monetary_quantity is null */
  Unknown = 'UNKNOWN'
}

/** Boolean expression to compare columns of type "purchasing_suggestions_enum". All fields are combined with logical 'AND'. */
export type Purchasing_Suggestions_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Purchasing_Suggestions_Enum>;
  _in?: InputMaybe<Array<Purchasing_Suggestions_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Purchasing_Suggestions_Enum>;
  _nin?: InputMaybe<Array<Purchasing_Suggestions_Enum>>;
};

/** aggregate max on columns */
export type Purchasing_Suggestions_Max_Fields = {
  __typename: 'purchasing_suggestions_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Purchasing_Suggestions_Min_Fields = {
  __typename: 'purchasing_suggestions_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "purchasing_suggestions". */
export type Purchasing_Suggestions_Order_By = {
  aggregatedDemandPlanResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Results_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "purchasing_suggestions" */
export enum Purchasing_Suggestions_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "purchasing_suggestions" */
export type Purchasing_Suggestions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Purchasing_Suggestions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Purchasing_Suggestions_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Puspc_With_Customer_Prices_Args = {
  _as_of?: InputMaybe<Scalars['date']>;
  _customer_id?: InputMaybe<Scalars['bigint']>;
  _product_id?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "query_monitoring" */
export type Query_Monitoring = {
  __typename: 'query_monitoring';
  applicationName?: Maybe<Scalars['String']>;
  backendStart?: Maybe<Scalars['timestamptz']>;
  backendType?: Maybe<Scalars['String']>;
  backendXid?: Maybe<Scalars['xid']>;
  backendXmin?: Maybe<Scalars['xid']>;
  capturedAt: Scalars['timestamptz'];
  clientAddr?: Maybe<Scalars['inet']>;
  clientHostname?: Maybe<Scalars['String']>;
  clientPort?: Maybe<Scalars['bigint']>;
  createdAt: Scalars['timestamptz'];
  datid?: Maybe<Scalars['oid']>;
  datname?: Maybe<Scalars['name']>;
  durationSeconds?: Maybe<Scalars['bigint']>;
  endedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  leaderPid?: Maybe<Scalars['bigint']>;
  pid?: Maybe<Scalars['bigint']>;
  query?: Maybe<Scalars['String']>;
  queryStart?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  stateChange?: Maybe<Scalars['timestamptz']>;
  updatedAt: Scalars['timestamptz'];
  usename?: Maybe<Scalars['name']>;
  usesysid?: Maybe<Scalars['oid']>;
  waitEvent?: Maybe<Scalars['String']>;
  waitEventType?: Maybe<Scalars['String']>;
  xactStart?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "query_monitoring" */
export type Query_Monitoring_Aggregate = {
  __typename: 'query_monitoring_aggregate';
  aggregate?: Maybe<Query_Monitoring_Aggregate_Fields>;
  nodes: Array<Query_Monitoring>;
};

/** aggregate fields of "query_monitoring" */
export type Query_Monitoring_Aggregate_Fields = {
  __typename: 'query_monitoring_aggregate_fields';
  avg?: Maybe<Query_Monitoring_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Query_Monitoring_Max_Fields>;
  min?: Maybe<Query_Monitoring_Min_Fields>;
  stddev?: Maybe<Query_Monitoring_Stddev_Fields>;
  stddev_pop?: Maybe<Query_Monitoring_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Query_Monitoring_Stddev_Samp_Fields>;
  sum?: Maybe<Query_Monitoring_Sum_Fields>;
  var_pop?: Maybe<Query_Monitoring_Var_Pop_Fields>;
  var_samp?: Maybe<Query_Monitoring_Var_Samp_Fields>;
  variance?: Maybe<Query_Monitoring_Variance_Fields>;
};


/** aggregate fields of "query_monitoring" */
export type Query_Monitoring_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Query_Monitoring_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Query_Monitoring_Avg_Fields = {
  __typename: 'query_monitoring_avg_fields';
  clientPort?: Maybe<Scalars['Float']>;
  durationSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  leaderPid?: Maybe<Scalars['Float']>;
  pid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "query_monitoring". All fields are combined with a logical 'AND'. */
export type Query_Monitoring_Bool_Exp = {
  _and?: InputMaybe<Array<Query_Monitoring_Bool_Exp>>;
  _not?: InputMaybe<Query_Monitoring_Bool_Exp>;
  _or?: InputMaybe<Array<Query_Monitoring_Bool_Exp>>;
  applicationName?: InputMaybe<String_Comparison_Exp>;
  backendStart?: InputMaybe<Timestamptz_Comparison_Exp>;
  backendType?: InputMaybe<String_Comparison_Exp>;
  backendXid?: InputMaybe<Xid_Comparison_Exp>;
  backendXmin?: InputMaybe<Xid_Comparison_Exp>;
  capturedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  clientAddr?: InputMaybe<Inet_Comparison_Exp>;
  clientHostname?: InputMaybe<String_Comparison_Exp>;
  clientPort?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  datid?: InputMaybe<Oid_Comparison_Exp>;
  datname?: InputMaybe<Name_Comparison_Exp>;
  durationSeconds?: InputMaybe<Bigint_Comparison_Exp>;
  endedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  leaderPid?: InputMaybe<Bigint_Comparison_Exp>;
  pid?: InputMaybe<Bigint_Comparison_Exp>;
  query?: InputMaybe<String_Comparison_Exp>;
  queryStart?: InputMaybe<Timestamptz_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  stateChange?: InputMaybe<Timestamptz_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  usename?: InputMaybe<Name_Comparison_Exp>;
  usesysid?: InputMaybe<Oid_Comparison_Exp>;
  waitEvent?: InputMaybe<String_Comparison_Exp>;
  waitEventType?: InputMaybe<String_Comparison_Exp>;
  xactStart?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Query_Monitoring_Max_Fields = {
  __typename: 'query_monitoring_max_fields';
  applicationName?: Maybe<Scalars['String']>;
  backendStart?: Maybe<Scalars['timestamptz']>;
  backendType?: Maybe<Scalars['String']>;
  capturedAt?: Maybe<Scalars['timestamptz']>;
  clientHostname?: Maybe<Scalars['String']>;
  clientPort?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  durationSeconds?: Maybe<Scalars['bigint']>;
  endedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  leaderPid?: Maybe<Scalars['bigint']>;
  pid?: Maybe<Scalars['bigint']>;
  query?: Maybe<Scalars['String']>;
  queryStart?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  stateChange?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  waitEvent?: Maybe<Scalars['String']>;
  waitEventType?: Maybe<Scalars['String']>;
  xactStart?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Query_Monitoring_Min_Fields = {
  __typename: 'query_monitoring_min_fields';
  applicationName?: Maybe<Scalars['String']>;
  backendStart?: Maybe<Scalars['timestamptz']>;
  backendType?: Maybe<Scalars['String']>;
  capturedAt?: Maybe<Scalars['timestamptz']>;
  clientHostname?: Maybe<Scalars['String']>;
  clientPort?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  durationSeconds?: Maybe<Scalars['bigint']>;
  endedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  leaderPid?: Maybe<Scalars['bigint']>;
  pid?: Maybe<Scalars['bigint']>;
  query?: Maybe<Scalars['String']>;
  queryStart?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  stateChange?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  waitEvent?: Maybe<Scalars['String']>;
  waitEventType?: Maybe<Scalars['String']>;
  xactStart?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "query_monitoring". */
export type Query_Monitoring_Order_By = {
  applicationName?: InputMaybe<Order_By>;
  backendStart?: InputMaybe<Order_By>;
  backendType?: InputMaybe<Order_By>;
  backendXid?: InputMaybe<Order_By>;
  backendXmin?: InputMaybe<Order_By>;
  capturedAt?: InputMaybe<Order_By>;
  clientAddr?: InputMaybe<Order_By>;
  clientHostname?: InputMaybe<Order_By>;
  clientPort?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  datid?: InputMaybe<Order_By>;
  datname?: InputMaybe<Order_By>;
  durationSeconds?: InputMaybe<Order_By>;
  endedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leaderPid?: InputMaybe<Order_By>;
  pid?: InputMaybe<Order_By>;
  query?: InputMaybe<Order_By>;
  queryStart?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  stateChange?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  usename?: InputMaybe<Order_By>;
  usesysid?: InputMaybe<Order_By>;
  waitEvent?: InputMaybe<Order_By>;
  waitEventType?: InputMaybe<Order_By>;
  xactStart?: InputMaybe<Order_By>;
};

/** select columns of table "query_monitoring" */
export enum Query_Monitoring_Select_Column {
  /** column name */
  ApplicationName = 'applicationName',
  /** column name */
  BackendStart = 'backendStart',
  /** column name */
  BackendType = 'backendType',
  /** column name */
  BackendXid = 'backendXid',
  /** column name */
  BackendXmin = 'backendXmin',
  /** column name */
  CapturedAt = 'capturedAt',
  /** column name */
  ClientAddr = 'clientAddr',
  /** column name */
  ClientHostname = 'clientHostname',
  /** column name */
  ClientPort = 'clientPort',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Datid = 'datid',
  /** column name */
  Datname = 'datname',
  /** column name */
  DurationSeconds = 'durationSeconds',
  /** column name */
  EndedAt = 'endedAt',
  /** column name */
  Id = 'id',
  /** column name */
  LeaderPid = 'leaderPid',
  /** column name */
  Pid = 'pid',
  /** column name */
  Query = 'query',
  /** column name */
  QueryStart = 'queryStart',
  /** column name */
  State = 'state',
  /** column name */
  StateChange = 'stateChange',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Usename = 'usename',
  /** column name */
  Usesysid = 'usesysid',
  /** column name */
  WaitEvent = 'waitEvent',
  /** column name */
  WaitEventType = 'waitEventType',
  /** column name */
  XactStart = 'xactStart'
}

/** aggregate stddev on columns */
export type Query_Monitoring_Stddev_Fields = {
  __typename: 'query_monitoring_stddev_fields';
  clientPort?: Maybe<Scalars['Float']>;
  durationSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  leaderPid?: Maybe<Scalars['Float']>;
  pid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Query_Monitoring_Stddev_Pop_Fields = {
  __typename: 'query_monitoring_stddev_pop_fields';
  clientPort?: Maybe<Scalars['Float']>;
  durationSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  leaderPid?: Maybe<Scalars['Float']>;
  pid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Query_Monitoring_Stddev_Samp_Fields = {
  __typename: 'query_monitoring_stddev_samp_fields';
  clientPort?: Maybe<Scalars['Float']>;
  durationSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  leaderPid?: Maybe<Scalars['Float']>;
  pid?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "query_monitoring" */
export type Query_Monitoring_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Query_Monitoring_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Query_Monitoring_Stream_Cursor_Value_Input = {
  applicationName?: InputMaybe<Scalars['String']>;
  backendStart?: InputMaybe<Scalars['timestamptz']>;
  backendType?: InputMaybe<Scalars['String']>;
  backendXid?: InputMaybe<Scalars['xid']>;
  backendXmin?: InputMaybe<Scalars['xid']>;
  capturedAt?: InputMaybe<Scalars['timestamptz']>;
  clientAddr?: InputMaybe<Scalars['inet']>;
  clientHostname?: InputMaybe<Scalars['String']>;
  clientPort?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  datid?: InputMaybe<Scalars['oid']>;
  datname?: InputMaybe<Scalars['name']>;
  durationSeconds?: InputMaybe<Scalars['bigint']>;
  endedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  leaderPid?: InputMaybe<Scalars['bigint']>;
  pid?: InputMaybe<Scalars['bigint']>;
  query?: InputMaybe<Scalars['String']>;
  queryStart?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Scalars['String']>;
  stateChange?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  usename?: InputMaybe<Scalars['name']>;
  usesysid?: InputMaybe<Scalars['oid']>;
  waitEvent?: InputMaybe<Scalars['String']>;
  waitEventType?: InputMaybe<Scalars['String']>;
  xactStart?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Query_Monitoring_Sum_Fields = {
  __typename: 'query_monitoring_sum_fields';
  clientPort?: Maybe<Scalars['bigint']>;
  durationSeconds?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  leaderPid?: Maybe<Scalars['bigint']>;
  pid?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Query_Monitoring_Var_Pop_Fields = {
  __typename: 'query_monitoring_var_pop_fields';
  clientPort?: Maybe<Scalars['Float']>;
  durationSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  leaderPid?: Maybe<Scalars['Float']>;
  pid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Query_Monitoring_Var_Samp_Fields = {
  __typename: 'query_monitoring_var_samp_fields';
  clientPort?: Maybe<Scalars['Float']>;
  durationSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  leaderPid?: Maybe<Scalars['Float']>;
  pid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Query_Monitoring_Variance_Fields = {
  __typename: 'query_monitoring_variance_fields';
  clientPort?: Maybe<Scalars['Float']>;
  durationSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  leaderPid?: Maybe<Scalars['Float']>;
  pid?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename: 'query_root';
  /** fetch data from the table: "abacus_exports" using primary key columns */
  abacusExportByPk?: Maybe<Abacus_Exports>;
  /** An array relationship */
  abacusExports: Array<Abacus_Exports>;
  /** fetch aggregated fields from the table: "abacus_exports" */
  abacusExportsAggregate: Abacus_Exports_Aggregate;
  /** fetch data from the table: "accounts" using primary key columns */
  accountByPk?: Maybe<Accounts>;
  /** An array relationship */
  accounts: Array<Accounts>;
  /** fetch aggregated fields from the table: "accounts" */
  accountsAggregate: Accounts_Aggregate;
  /** An array relationship */
  aggregatedDemandPlanIncomingGoodsResults: Array<Aggregated_Demand_Plan_Incoming_Goods_Results>;
  /** fetch aggregated fields from the table: "aggregated_demand_plan_incoming_goods_results" */
  aggregatedDemandPlanIncomingGoodsResultsAggregate: Aggregated_Demand_Plan_Incoming_Goods_Results_Aggregate;
  /** An array relationship */
  aggregatedDemandPlanOutgoingGoodsResults: Array<Aggregated_Demand_Plan_Outgoing_Goods_Results>;
  /** fetch aggregated fields from the table: "aggregated_demand_plan_outgoing_goods_results" */
  aggregatedDemandPlanOutgoingGoodsResultsAggregate: Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate;
  /** An array relationship */
  aggregatedDemandPlanResults: Array<Aggregated_Demand_Plan_Results>;
  /** fetch aggregated fields from the table: "aggregated_demand_plan_results" */
  aggregatedDemandPlanResultsAggregate: Aggregated_Demand_Plan_Results_Aggregate;
  /** An array relationship */
  aggregatedInventoryItemsByLocations: Array<Aggregated_Inventory_Items_By_Location>;
  /** fetch aggregated fields from the table: "aggregated_inventory_items_by_location" */
  aggregatedInventoryItemsByLocationsAggregate: Aggregated_Inventory_Items_By_Location_Aggregate;
  /** execute function "aggregated_demand_plan" which returns "aggregated_demand_plan_results" */
  aggregated_demand_plan: Array<Aggregated_Demand_Plan_Results>;
  /** execute function "aggregated_demand_plan" and query aggregates on result of table type "aggregated_demand_plan_results" */
  aggregated_demand_plan_aggregate: Aggregated_Demand_Plan_Results_Aggregate;
  /** execute function "aggregated_list_inventory_by_location" which returns "aggregated_inventory_items_by_location" */
  aggregated_list_inventory_by_location: Array<Aggregated_Inventory_Items_By_Location>;
  /** execute function "aggregated_list_inventory_by_location" and query aggregates on result of table type "aggregated_inventory_items_by_location" */
  aggregated_list_inventory_by_location_aggregate: Aggregated_Inventory_Items_By_Location_Aggregate;
  /** An array relationship */
  calculateCostPriceForProductResults: Array<Calculate_Cost_Price_For_Product_Results>;
  /** fetch aggregated fields from the table: "calculate_cost_price_for_product_results" */
  calculateCostPriceForProductResultsAggregate: Calculate_Cost_Price_For_Product_Results_Aggregate;
  /** execute function "calculate_cost_price_for_product" which returns "calculate_cost_price_for_product_results" */
  calculate_cost_price_for_product: Array<Calculate_Cost_Price_For_Product_Results>;
  /** execute function "calculate_cost_price_for_product" and query aggregates on result of table type "calculate_cost_price_for_product_results" */
  calculate_cost_price_for_product_aggregate: Calculate_Cost_Price_For_Product_Results_Aggregate;
  /** execute function "calculate_cost_price_for_product_by_product" which returns "calculate_cost_price_for_product_results" */
  calculate_cost_price_for_product_by_product: Array<Calculate_Cost_Price_For_Product_Results>;
  /** execute function "calculate_cost_price_for_product_by_product" and query aggregates on result of table type "calculate_cost_price_for_product_results" */
  calculate_cost_price_for_product_by_product_aggregate: Calculate_Cost_Price_For_Product_Results_Aggregate;
  /** execute function "calculate_customer_pricing" which returns "customer_group_pricing" */
  calculate_customer_pricing: Array<Customer_Group_Pricing>;
  /** execute function "calculate_customer_pricing" and query aggregates on result of table type "customer_group_pricing" */
  calculate_customer_pricing_aggregate: Customer_Group_Pricing_Aggregate;
  /** fetch data from the table: "change_customer_order_with_token_result" */
  changeCustomerOrderWithTokenResults: Array<Change_Customer_Order_With_Token_Result>;
  /** fetch aggregated fields from the table: "change_customer_order_with_token_result" */
  changeCustomerOrderWithTokenResultsAggregate: Change_Customer_Order_With_Token_Result_Aggregate;
  /** fetch data from the table: "contact_assigned_roles" using primary key columns */
  contactAssignedRoleByPk?: Maybe<Contact_Assigned_Roles>;
  /** An array relationship */
  contactAssignedRoles: Array<Contact_Assigned_Roles>;
  /** fetch aggregated fields from the table: "contact_assigned_roles" */
  contactAssignedRolesAggregate: Contact_Assigned_Roles_Aggregate;
  /** An array relationship */
  contactAvailabilities: Array<Contact_Availability>;
  /** fetch aggregated fields from the table: "contact_availability" */
  contactAvailabilitiesAggregate: Contact_Availability_Aggregate;
  /** fetch data from the table: "contact_availability" using primary key columns */
  contactAvailabilityByPk?: Maybe<Contact_Availability>;
  /** fetch data from the table: "contact_availability_ranges" using primary key columns */
  contactAvailabilityRangeByPk?: Maybe<Contact_Availability_Ranges>;
  /** An array relationship */
  contactAvailabilityRanges: Array<Contact_Availability_Ranges>;
  /** fetch aggregated fields from the table: "contact_availability_ranges" */
  contactAvailabilityRangesAggregate: Contact_Availability_Ranges_Aggregate;
  /** fetch data from the table: "contacts" using primary key columns */
  contactByPk?: Maybe<Contacts>;
  /** fetch data from the table: "contact_roles" using primary key columns */
  contactRoleByPk?: Maybe<Contact_Roles>;
  /** fetch data from the table: "contact_roles" */
  contactRoles: Array<Contact_Roles>;
  /** fetch aggregated fields from the table: "contact_roles" */
  contactRolesAggregate: Contact_Roles_Aggregate;
  /** An array relationship */
  contacts: Array<Contacts>;
  /** fetch aggregated fields from the table: "contacts" */
  contactsAggregate: Contacts_Aggregate;
  /** fetch data from the table: "cost_price_average_periods" using primary key columns */
  costPriceAveragePeriodByPk?: Maybe<Cost_Price_Average_Periods>;
  /** An array relationship */
  costPriceAveragePeriods: Array<Cost_Price_Average_Periods>;
  /** fetch aggregated fields from the table: "cost_price_average_periods" */
  costPriceAveragePeriodsAggregate: Cost_Price_Average_Periods_Aggregate;
  /** fetch data from the table: "credit_notes" using primary key columns */
  creditNoteByPk?: Maybe<Credit_Notes>;
  /** fetch data from the table: "credit_note_items" using primary key columns */
  creditNoteItemByPk?: Maybe<Credit_Note_Items>;
  /** fetch data from the table: "credit_note_item_logged_actions" */
  creditNoteItemLoggedActions: Array<Credit_Note_Item_Logged_Actions>;
  /** fetch aggregated fields from the table: "credit_note_item_logged_actions" */
  creditNoteItemLoggedActionsAggregate: Credit_Note_Item_Logged_Actions_Aggregate;
  /** An array relationship */
  creditNoteItems: Array<Credit_Note_Items>;
  /** fetch aggregated fields from the table: "credit_note_items" */
  creditNoteItemsAggregate: Credit_Note_Items_Aggregate;
  /** fetch data from the table: "credit_note_logged_actions" */
  creditNoteLoggedActions: Array<Credit_Note_Logged_Actions>;
  /** fetch aggregated fields from the table: "credit_note_logged_actions" */
  creditNoteLoggedActionsAggregate: Credit_Note_Logged_Actions_Aggregate;
  /** fetch data from the table: "credit_note_states" using primary key columns */
  creditNoteStateByPk?: Maybe<Credit_Note_States>;
  /** fetch data from the table: "credit_note_state_transitions" using primary key columns */
  creditNoteStateTransitionByPk?: Maybe<Credit_Note_State_Transitions>;
  /** fetch data from the table: "credit_note_state_transitions" */
  creditNoteStateTransitions: Array<Credit_Note_State_Transitions>;
  /** fetch aggregated fields from the table: "credit_note_state_transitions" */
  creditNoteStateTransitionsAggregate: Credit_Note_State_Transitions_Aggregate;
  /** fetch data from the table: "credit_note_states" */
  creditNoteStates: Array<Credit_Note_States>;
  /** fetch aggregated fields from the table: "credit_note_states" */
  creditNoteStatesAggregate: Credit_Note_States_Aggregate;
  /** fetch data from the table: "credit_note_vat_summary_results" */
  creditNoteVatSummaryResults: Array<Credit_Note_Vat_Summary_Results>;
  /** fetch aggregated fields from the table: "credit_note_vat_summary_results" */
  creditNoteVatSummaryResultsAggregate: Credit_Note_Vat_Summary_Results_Aggregate;
  /** An array relationship */
  creditNotes: Array<Credit_Notes>;
  /** fetch aggregated fields from the table: "credit_notes" */
  creditNotesAggregate: Credit_Notes_Aggregate;
  /** fetch data from the table: "currencies" */
  currencies: Array<Currencies>;
  /** fetch aggregated fields from the table: "currencies" */
  currenciesAggregate: Currencies_Aggregate;
  /** fetch data from the table: "currencies" using primary key columns */
  currencyByPk?: Maybe<Currencies>;
  /** fetch data from the table: "customer_addresses" using primary key columns */
  customerAddressByPk?: Maybe<Customer_Addresses>;
  /** An array relationship */
  customerAddresses: Array<Customer_Addresses>;
  /** fetch aggregated fields from the table: "customer_addresses" */
  customerAddressesAggregate: Customer_Addresses_Aggregate;
  /** fetch data from the table: "customer_billing_format" using primary key columns */
  customerBillingFormatByPk?: Maybe<Customer_Billing_Format>;
  /** fetch data from the table: "customer_billing_format" */
  customerBillingFormats: Array<Customer_Billing_Format>;
  /** fetch aggregated fields from the table: "customer_billing_format" */
  customerBillingFormatsAggregate: Customer_Billing_Format_Aggregate;
  /** fetch data from the table: "customer_billing_frequency" */
  customerBillingFrequencies: Array<Customer_Billing_Frequency>;
  /** fetch aggregated fields from the table: "customer_billing_frequency" */
  customerBillingFrequenciesAggregate: Customer_Billing_Frequency_Aggregate;
  /** fetch data from the table: "customer_billing_frequency" using primary key columns */
  customerBillingFrequencyByPk?: Maybe<Customer_Billing_Frequency>;
  /** fetch data from the table: "customer_billing_send_strategies" */
  customerBillingSendStrategies: Array<Customer_Billing_Send_Strategies>;
  /** fetch aggregated fields from the table: "customer_billing_send_strategies" */
  customerBillingSendStrategiesAggregate: Customer_Billing_Send_Strategies_Aggregate;
  /** fetch data from the table: "customer_billing_send_strategies" using primary key columns */
  customerBillingSendStrategyByPk?: Maybe<Customer_Billing_Send_Strategies>;
  /** fetch data from the table: "customer_billing_settings" using primary key columns */
  customerBillingSettingByPk?: Maybe<Customer_Billing_Settings>;
  /** An array relationship */
  customerBillingSettings: Array<Customer_Billing_Settings>;
  /** fetch aggregated fields from the table: "customer_billing_settings" */
  customerBillingSettingsAggregate: Customer_Billing_Settings_Aggregate;
  /** fetch data from the table: "customers" using primary key columns */
  customerByPk?: Maybe<Customers>;
  /** An array relationship */
  customerDeliveryAvailabilities: Array<Customer_Delivery_Availability>;
  /** fetch aggregated fields from the table: "customer_delivery_availability" */
  customerDeliveryAvailabilitiesAggregate: Customer_Delivery_Availability_Aggregate;
  /** fetch data from the table: "customer_delivery_availability" using primary key columns */
  customerDeliveryAvailabilityByPk?: Maybe<Customer_Delivery_Availability>;
  /** fetch data from the table: "customer_delivery_note_formats" using primary key columns */
  customerDeliveryNoteFormatByPk?: Maybe<Customer_Delivery_Note_Formats>;
  /** fetch data from the table: "customer_delivery_note_formats" */
  customerDeliveryNoteFormats: Array<Customer_Delivery_Note_Formats>;
  /** fetch aggregated fields from the table: "customer_delivery_note_formats" */
  customerDeliveryNoteFormatsAggregate: Customer_Delivery_Note_Formats_Aggregate;
  /** fetch data from the table: "customer_groups" using primary key columns */
  customerGroupByPk?: Maybe<Customer_Groups>;
  /** fetch data from the table: "customer_group_pricing" using primary key columns */
  customerGroupPricingByPk?: Maybe<Customer_Group_Pricing>;
  /** An array relationship */
  customerGroupPricings: Array<Customer_Group_Pricing>;
  /** fetch aggregated fields from the table: "customer_group_pricing" */
  customerGroupPricingsAggregate: Customer_Group_Pricing_Aggregate;
  /** fetch data from the table: "customer_group_states" using primary key columns */
  customerGroupStateByPk?: Maybe<Customer_Group_States>;
  /** fetch data from the table: "customer_group_state_transitions" using primary key columns */
  customerGroupStateTransitionByPk?: Maybe<Customer_Group_State_Transitions>;
  /** fetch data from the table: "customer_group_state_transitions" */
  customerGroupStateTransitions: Array<Customer_Group_State_Transitions>;
  /** fetch aggregated fields from the table: "customer_group_state_transitions" */
  customerGroupStateTransitionsAggregate: Customer_Group_State_Transitions_Aggregate;
  /** fetch data from the table: "customer_group_states" */
  customerGroupStates: Array<Customer_Group_States>;
  /** fetch aggregated fields from the table: "customer_group_states" */
  customerGroupStatesAggregate: Customer_Group_States_Aggregate;
  /** An array relationship */
  customerGroups: Array<Customer_Groups>;
  /** fetch aggregated fields from the table: "customer_groups" */
  customerGroupsAggregate: Customer_Groups_Aggregate;
  /** fetch data from the table: "customer_invoices" using primary key columns */
  customerInvoiceByPk?: Maybe<Customer_Invoices>;
  /** An array relationship */
  customerInvoiceLoggedActions: Array<Customer_Invoice_Logged_Actions>;
  /** fetch aggregated fields from the table: "customer_invoice_logged_actions" */
  customerInvoiceLoggedActionsAggregate: Customer_Invoice_Logged_Actions_Aggregate;
  /** fetch data from the table: "customer_invoice_orders" using primary key columns */
  customerInvoiceOrderByPk?: Maybe<Customer_Invoice_Orders>;
  /** An array relationship */
  customerInvoiceOrders: Array<Customer_Invoice_Orders>;
  /** fetch aggregated fields from the table: "customer_invoice_orders" */
  customerInvoiceOrdersAggregate: Customer_Invoice_Orders_Aggregate;
  /** fetch data from the table: "customer_invoice_states" using primary key columns */
  customerInvoiceStateByPk?: Maybe<Customer_Invoice_States>;
  /** fetch data from the table: "customer_invoice_state_transitions" using primary key columns */
  customerInvoiceStateTransitionByPk?: Maybe<Customer_Invoice_State_Transitions>;
  /** fetch data from the table: "customer_invoice_state_transitions" */
  customerInvoiceStateTransitions: Array<Customer_Invoice_State_Transitions>;
  /** fetch aggregated fields from the table: "customer_invoice_state_transitions" */
  customerInvoiceStateTransitionsAggregate: Customer_Invoice_State_Transitions_Aggregate;
  /** fetch data from the table: "customer_invoice_states" */
  customerInvoiceStates: Array<Customer_Invoice_States>;
  /** fetch aggregated fields from the table: "customer_invoice_states" */
  customerInvoiceStatesAggregate: Customer_Invoice_States_Aggregate;
  /** fetch data from the table: "customer_invoice_vat_summary_results" */
  customerInvoiceVatSummaryResults: Array<Customer_Invoice_Vat_Summary_Results>;
  /** fetch aggregated fields from the table: "customer_invoice_vat_summary_results" */
  customerInvoiceVatSummaryResultsAggregate: Customer_Invoice_Vat_Summary_Results_Aggregate;
  /** fetch data from the table: "customer_invoice_versions" using primary key columns */
  customerInvoiceVersionByPk?: Maybe<Customer_Invoice_Versions>;
  /** An array relationship */
  customerInvoiceVersionLoggedActions: Array<Customer_Invoice_Version_Logged_Actions>;
  /** fetch aggregated fields from the table: "customer_invoice_version_logged_actions" */
  customerInvoiceVersionLoggedActionsAggregate: Customer_Invoice_Version_Logged_Actions_Aggregate;
  /** An array relationship */
  customerInvoiceVersions: Array<Customer_Invoice_Versions>;
  /** fetch aggregated fields from the table: "customer_invoice_versions" */
  customerInvoiceVersionsAggregate: Customer_Invoice_Versions_Aggregate;
  /** An array relationship */
  customerInvoices: Array<Customer_Invoices>;
  /** fetch aggregated fields from the table: "customer_invoices" */
  customerInvoicesAggregate: Customer_Invoices_Aggregate;
  /** An array relationship */
  customerLoggedActions: Array<Customer_Logged_Actions>;
  /** fetch aggregated fields from the table: "customer_logged_actions" */
  customerLoggedActionsAggregate: Customer_Logged_Actions_Aggregate;
  /** fetch data from the table: "customer_orders" using primary key columns */
  customerOrderByPk?: Maybe<Customer_Orders>;
  /** fetch data from the table: "customer_order_container_returns" using primary key columns */
  customerOrderContainerReturnByPk?: Maybe<Customer_Order_Container_Returns>;
  /** fetch data from the table: "customer_order_container_return_items" using primary key columns */
  customerOrderContainerReturnItemByPk?: Maybe<Customer_Order_Container_Return_Items>;
  /** An array relationship */
  customerOrderContainerReturnItems: Array<Customer_Order_Container_Return_Items>;
  /** fetch aggregated fields from the table: "customer_order_container_return_items" */
  customerOrderContainerReturnItemsAggregate: Customer_Order_Container_Return_Items_Aggregate;
  /** An array relationship */
  customerOrderContainerReturns: Array<Customer_Order_Container_Returns>;
  /** fetch aggregated fields from the table: "customer_order_container_returns" */
  customerOrderContainerReturnsAggregate: Customer_Order_Container_Returns_Aggregate;
  /** fetch data from the table: "customer_order_item_adjustments" using primary key columns */
  customerOrderItemAdjustmentByPk?: Maybe<Customer_Order_Item_Adjustments>;
  /** fetch data from the table: "customer_order_item_adjustment_logged_actions" */
  customerOrderItemAdjustmentLoggedActions: Array<Customer_Order_Item_Adjustment_Logged_Actions>;
  /** fetch aggregated fields from the table: "customer_order_item_adjustment_logged_actions" */
  customerOrderItemAdjustmentLoggedActionsAggregate: Customer_Order_Item_Adjustment_Logged_Actions_Aggregate;
  /** fetch data from the table: "customer_order_item_adjustment_types" using primary key columns */
  customerOrderItemAdjustmentTypeByPk?: Maybe<Customer_Order_Item_Adjustment_Types>;
  /** fetch data from the table: "customer_order_item_adjustment_types" */
  customerOrderItemAdjustmentTypes: Array<Customer_Order_Item_Adjustment_Types>;
  /** fetch aggregated fields from the table: "customer_order_item_adjustment_types" */
  customerOrderItemAdjustmentTypesAggregate: Customer_Order_Item_Adjustment_Types_Aggregate;
  /** An array relationship */
  customerOrderItemAdjustments: Array<Customer_Order_Item_Adjustments>;
  /** fetch aggregated fields from the table: "customer_order_item_adjustments" */
  customerOrderItemAdjustmentsAggregate: Customer_Order_Item_Adjustments_Aggregate;
  /** fetch data from the table: "customer_order_items" using primary key columns */
  customerOrderItemByPk?: Maybe<Customer_Order_Items>;
  /** fetch data from the table: "customer_order_item_container_expectations" using primary key columns */
  customerOrderItemContainerExpectationByPk?: Maybe<Customer_Order_Item_Container_Expectations>;
  /** An array relationship */
  customerOrderItemContainerExpectations: Array<Customer_Order_Item_Container_Expectations>;
  /** fetch aggregated fields from the table: "customer_order_item_container_expectations" */
  customerOrderItemContainerExpectationsAggregate: Customer_Order_Item_Container_Expectations_Aggregate;
  /** fetch data from the table: "customer_order_item_extras" */
  customerOrderItemExtras: Array<Customer_Order_Item_Extras>;
  /** fetch aggregated fields from the table: "customer_order_item_extras" */
  customerOrderItemExtrasAggregate: Customer_Order_Item_Extras_Aggregate;
  /** fetch data from the table: "customer_order_item_fulfillment_plans" using primary key columns */
  customerOrderItemFulfillmentPlanByPk?: Maybe<Customer_Order_Item_Fulfillment_Plans>;
  /** An array relationship */
  customerOrderItemFulfillmentPlans: Array<Customer_Order_Item_Fulfillment_Plans>;
  /** fetch aggregated fields from the table: "customer_order_item_fulfillment_plans" */
  customerOrderItemFulfillmentPlansAggregate: Customer_Order_Item_Fulfillment_Plans_Aggregate;
  /** An array relationship */
  customerOrderItemLoggedActions: Array<Customer_Order_Item_Logged_Actions>;
  /** fetch aggregated fields from the table: "customer_order_item_logged_actions" */
  customerOrderItemLoggedActionsAggregate: Customer_Order_Item_Logged_Actions_Aggregate;
  /** fetch data from the table: "customer_order_item_return_causes" using primary key columns */
  customerOrderItemReturnCauseByPk?: Maybe<Customer_Order_Item_Return_Causes>;
  /** An array relationship */
  customerOrderItemReturnCauses: Array<Customer_Order_Item_Return_Causes>;
  /** fetch aggregated fields from the table: "customer_order_item_return_causes" */
  customerOrderItemReturnCausesAggregate: Customer_Order_Item_Return_Causes_Aggregate;
  /** fetch data from the table: "customer_order_item_states" using primary key columns */
  customerOrderItemStateByPk?: Maybe<Customer_Order_Item_States>;
  /** fetch data from the table: "customer_order_item_state_transitions" using primary key columns */
  customerOrderItemStateTransitionByPk?: Maybe<Customer_Order_Item_State_Transitions>;
  /** fetch data from the table: "customer_order_item_state_transitions" */
  customerOrderItemStateTransitions: Array<Customer_Order_Item_State_Transitions>;
  /** fetch aggregated fields from the table: "customer_order_item_state_transitions" */
  customerOrderItemStateTransitionsAggregate: Customer_Order_Item_State_Transitions_Aggregate;
  /** fetch data from the table: "customer_order_item_states" */
  customerOrderItemStates: Array<Customer_Order_Item_States>;
  /** fetch aggregated fields from the table: "customer_order_item_states" */
  customerOrderItemStatesAggregate: Customer_Order_Item_States_Aggregate;
  /** An array relationship */
  customerOrderItems: Array<Customer_Order_Items>;
  /** fetch aggregated fields from the table: "customer_order_items" */
  customerOrderItemsAggregate: Customer_Order_Items_Aggregate;
  /** An array relationship */
  customerOrderLoggedActions: Array<Customer_Order_Logged_Actions>;
  /** fetch aggregated fields from the table: "customer_order_logged_actions" */
  customerOrderLoggedActionsAggregate: Customer_Order_Logged_Actions_Aggregate;
  /** fetch data from the table: "customer_order_picked_container" using primary key columns */
  customerOrderPickedContainerByPk?: Maybe<Customer_Order_Picked_Container>;
  /** fetch data from the table: "customer_order_picked_container_states" using primary key columns */
  customerOrderPickedContainerStateByPk?: Maybe<Customer_Order_Picked_Container_States>;
  /** fetch data from the table: "customer_order_picked_container_state_transitions" using primary key columns */
  customerOrderPickedContainerStateTransitionByPk?: Maybe<Customer_Order_Picked_Container_State_Transitions>;
  /** fetch data from the table: "customer_order_picked_container_state_transitions" */
  customerOrderPickedContainerStateTransitions: Array<Customer_Order_Picked_Container_State_Transitions>;
  /** fetch aggregated fields from the table: "customer_order_picked_container_state_transitions" */
  customerOrderPickedContainerStateTransitionsAggregate: Customer_Order_Picked_Container_State_Transitions_Aggregate;
  /** fetch data from the table: "customer_order_picked_container_states" */
  customerOrderPickedContainerStates: Array<Customer_Order_Picked_Container_States>;
  /** fetch aggregated fields from the table: "customer_order_picked_container_states" */
  customerOrderPickedContainerStatesAggregate: Customer_Order_Picked_Container_States_Aggregate;
  /** An array relationship */
  customerOrderPickedContainers: Array<Customer_Order_Picked_Container>;
  /** fetch aggregated fields from the table: "customer_order_picked_container" */
  customerOrderPickedContainersAggregate: Customer_Order_Picked_Container_Aggregate;
  /** fetch data from the table: "customer_order_returns" using primary key columns */
  customerOrderReturnByPk?: Maybe<Customer_Order_Returns>;
  /** fetch data from the table: "customer_order_return_items" using primary key columns */
  customerOrderReturnItemByPk?: Maybe<Customer_Order_Return_Items>;
  /** fetch data from the table: "customer_order_return_item_containers" using primary key columns */
  customerOrderReturnItemContainerByPk?: Maybe<Customer_Order_Return_Item_Containers>;
  /** An array relationship */
  customerOrderReturnItemContainers: Array<Customer_Order_Return_Item_Containers>;
  /** fetch aggregated fields from the table: "customer_order_return_item_containers" */
  customerOrderReturnItemContainersAggregate: Customer_Order_Return_Item_Containers_Aggregate;
  /** An array relationship */
  customerOrderReturnItems: Array<Customer_Order_Return_Items>;
  /** fetch aggregated fields from the table: "customer_order_return_items" */
  customerOrderReturnItemsAggregate: Customer_Order_Return_Items_Aggregate;
  /** fetch data from the table: "customer_order_return_states" using primary key columns */
  customerOrderReturnStateByPk?: Maybe<Customer_Order_Return_States>;
  /** fetch data from the table: "customer_order_return_states" */
  customerOrderReturnStates: Array<Customer_Order_Return_States>;
  /** fetch aggregated fields from the table: "customer_order_return_states" */
  customerOrderReturnStatesAggregate: Customer_Order_Return_States_Aggregate;
  /** An array relationship */
  customerOrderReturns: Array<Customer_Order_Returns>;
  /** fetch aggregated fields from the table: "customer_order_returns" */
  customerOrderReturnsAggregate: Customer_Order_Returns_Aggregate;
  /** fetch data from the table: "customer_order_states" using primary key columns */
  customerOrderStateByPk?: Maybe<Customer_Order_States>;
  /** fetch data from the table: "customer_order_state_transitions" using primary key columns */
  customerOrderStateTransitionByPk?: Maybe<Customer_Order_State_Transitions>;
  /** fetch data from the table: "customer_order_state_transitions" */
  customerOrderStateTransitions: Array<Customer_Order_State_Transitions>;
  /** fetch aggregated fields from the table: "customer_order_state_transitions" */
  customerOrderStateTransitionsAggregate: Customer_Order_State_Transitions_Aggregate;
  /** fetch data from the table: "customer_order_states" */
  customerOrderStates: Array<Customer_Order_States>;
  /** fetch aggregated fields from the table: "customer_order_states" */
  customerOrderStatesAggregate: Customer_Order_States_Aggregate;
  /** fetch data from the table: "customer_order_stock_reservations" */
  customerOrderStockReservations: Array<Customer_Order_Stock_Reservations>;
  /** fetch aggregated fields from the table: "customer_order_stock_reservations" */
  customerOrderStockReservationsAggregate: Customer_Order_Stock_Reservations_Aggregate;
  /** An array relationship */
  customerOrders: Array<Customer_Orders>;
  /** fetch aggregated fields from the table: "customer_orders" */
  customerOrdersAggregate: Customer_Orders_Aggregate;
  /** fetch data from the table: "customer_payment_method" using primary key columns */
  customerPaymentMethodByPk?: Maybe<Customer_Payment_Method>;
  /** fetch data from the table: "customer_payment_method" */
  customerPaymentMethods: Array<Customer_Payment_Method>;
  /** fetch aggregated fields from the table: "customer_payment_method" */
  customerPaymentMethodsAggregate: Customer_Payment_Method_Aggregate;
  /** fetch data from the table: "customer_payment_terms" using primary key columns */
  customerPaymentTermByPk?: Maybe<Customer_Payment_Terms>;
  /** An array relationship */
  customerPaymentTerms: Array<Customer_Payment_Terms>;
  /** fetch aggregated fields from the table: "customer_payment_terms" */
  customerPaymentTermsAggregate: Customer_Payment_Terms_Aggregate;
  /** An array relationship */
  customerProductAvailabilities: Array<Customer_Product_Availabilities>;
  /** fetch aggregated fields from the table: "customer_product_availabilities" */
  customerProductAvailabilitiesAggregate: Customer_Product_Availabilities_Aggregate;
  /** fetch data from the table: "customer_product_availabilities" using primary key columns */
  customerProductAvailabilityByPk?: Maybe<Customer_Product_Availabilities>;
  /** fetch data from the table: "customer_states" using primary key columns */
  customerStateByPk?: Maybe<Customer_States>;
  /** fetch data from the table: "customer_state_transitions" using primary key columns */
  customerStateTransitionByPk?: Maybe<Customer_State_Transitions>;
  /** fetch data from the table: "customer_state_transitions" */
  customerStateTransitions: Array<Customer_State_Transitions>;
  /** fetch aggregated fields from the table: "customer_state_transitions" */
  customerStateTransitionsAggregate: Customer_State_Transitions_Aggregate;
  /** fetch data from the table: "customer_states" */
  customerStates: Array<Customer_States>;
  /** fetch aggregated fields from the table: "customer_states" */
  customerStatesAggregate: Customer_States_Aggregate;
  /** fetch data from the table: "customer_template_settings" using primary key columns */
  customerTemplateSettingByPk?: Maybe<Customer_Template_Settings>;
  /** An array relationship */
  customerTemplateSettings: Array<Customer_Template_Settings>;
  /** fetch aggregated fields from the table: "customer_template_settings" */
  customerTemplateSettingsAggregate: Customer_Template_Settings_Aggregate;
  /** execute function "customer_group_pricing_within_range" which returns "customer_group_pricing" */
  customer_group_pricing_within_range: Array<Customer_Group_Pricing>;
  /** execute function "customer_group_pricing_within_range" and query aggregates on result of table type "customer_group_pricing" */
  customer_group_pricing_within_range_aggregate: Customer_Group_Pricing_Aggregate;
  /** execute function "customer_payment_term_suggestions" which returns "customer_payment_terms" */
  customer_payment_term_suggestions: Array<Customer_Payment_Terms>;
  /** execute function "customer_payment_term_suggestions" and query aggregates on result of table type "customer_payment_terms" */
  customer_payment_term_suggestions_aggregate: Customer_Payment_Terms_Aggregate;
  /** An array relationship */
  customers: Array<Customers>;
  /** fetch aggregated fields from the table: "customers" */
  customersAggregate: Customers_Aggregate;
  /** fetch data from the table: "cutting_patterns" using primary key columns */
  cuttingPatternByPk?: Maybe<Cutting_Patterns>;
  /** fetch data from the table: "cutting_pattern_draft_actions" using primary key columns */
  cuttingPatternDraftActionByPk?: Maybe<Cutting_Pattern_Draft_Actions>;
  /** fetch data from the table: "cutting_pattern_draft_actions" */
  cuttingPatternDraftActions: Array<Cutting_Pattern_Draft_Actions>;
  /** fetch aggregated fields from the table: "cutting_pattern_draft_actions" */
  cuttingPatternDraftActionsAggregate: Cutting_Pattern_Draft_Actions_Aggregate;
  /** fetch data from the table: "cutting_pattern_draft_states" using primary key columns */
  cuttingPatternDraftStateByPk?: Maybe<Cutting_Pattern_Draft_States>;
  /** fetch data from the table: "cutting_pattern_draft_state_transitions" using primary key columns */
  cuttingPatternDraftStateTransitionByPk?: Maybe<Cutting_Pattern_Draft_State_Transitions>;
  /** fetch data from the table: "cutting_pattern_draft_state_transitions" */
  cuttingPatternDraftStateTransitions: Array<Cutting_Pattern_Draft_State_Transitions>;
  /** fetch aggregated fields from the table: "cutting_pattern_draft_state_transitions" */
  cuttingPatternDraftStateTransitionsAggregate: Cutting_Pattern_Draft_State_Transitions_Aggregate;
  /** fetch data from the table: "cutting_pattern_draft_states" */
  cuttingPatternDraftStates: Array<Cutting_Pattern_Draft_States>;
  /** fetch aggregated fields from the table: "cutting_pattern_draft_states" */
  cuttingPatternDraftStatesAggregate: Cutting_Pattern_Draft_States_Aggregate;
  /** fetch data from the table: "cutting_pattern_logged_actions" */
  cuttingPatternLoggedActions: Array<Cutting_Pattern_Logged_Actions>;
  /** fetch aggregated fields from the table: "cutting_pattern_logged_actions" */
  cuttingPatternLoggedActionsAggregate: Cutting_Pattern_Logged_Actions_Aggregate;
  /** fetch data from the table: "cutting_pattern_outputs" using primary key columns */
  cuttingPatternOutputByPk?: Maybe<Cutting_Pattern_Outputs>;
  /** fetch data from the table: "cutting_pattern_output_logged_actions" */
  cuttingPatternOutputLoggedActions: Array<Cutting_Pattern_Output_Logged_Actions>;
  /** fetch aggregated fields from the table: "cutting_pattern_output_logged_actions" */
  cuttingPatternOutputLoggedActionsAggregate: Cutting_Pattern_Output_Logged_Actions_Aggregate;
  /** An array relationship */
  cuttingPatternOutputs: Array<Cutting_Pattern_Outputs>;
  /** fetch aggregated fields from the table: "cutting_pattern_outputs" */
  cuttingPatternOutputsAggregate: Cutting_Pattern_Outputs_Aggregate;
  /** fetch data from the table: "cutting_pattern_outputs_draft" using primary key columns */
  cuttingPatternOutputsDraftByPk?: Maybe<Cutting_Pattern_Outputs_Draft>;
  /** An array relationship */
  cuttingPatternOutputsDrafts: Array<Cutting_Pattern_Outputs_Draft>;
  /** fetch aggregated fields from the table: "cutting_pattern_outputs_draft" */
  cuttingPatternOutputsDraftsAggregate: Cutting_Pattern_Outputs_Draft_Aggregate;
  /** fetch data from the table: "cutting_pattern_states" using primary key columns */
  cuttingPatternStateByPk?: Maybe<Cutting_Pattern_States>;
  /** fetch data from the table: "cutting_pattern_state_transitions" using primary key columns */
  cuttingPatternStateTransitionByPk?: Maybe<Cutting_Pattern_State_Transitions>;
  /** fetch data from the table: "cutting_pattern_state_transitions" */
  cuttingPatternStateTransitions: Array<Cutting_Pattern_State_Transitions>;
  /** fetch aggregated fields from the table: "cutting_pattern_state_transitions" */
  cuttingPatternStateTransitionsAggregate: Cutting_Pattern_State_Transitions_Aggregate;
  /** fetch data from the table: "cutting_pattern_states" */
  cuttingPatternStates: Array<Cutting_Pattern_States>;
  /** fetch aggregated fields from the table: "cutting_pattern_states" */
  cuttingPatternStatesAggregate: Cutting_Pattern_States_Aggregate;
  /** An array relationship */
  cuttingPatterns: Array<Cutting_Patterns>;
  /** fetch aggregated fields from the table: "cutting_patterns" */
  cuttingPatternsAggregate: Cutting_Patterns_Aggregate;
  /** fetch data from the table: "cutting_patterns_draft" using primary key columns */
  cuttingPatternsDraftByPk?: Maybe<Cutting_Patterns_Draft>;
  /** An array relationship */
  cuttingPatternsDrafts: Array<Cutting_Patterns_Draft>;
  /** fetch aggregated fields from the table: "cutting_patterns_draft" */
  cuttingPatternsDraftsAggregate: Cutting_Patterns_Draft_Aggregate;
  /** An array relationship */
  dailyPriceLists: Array<Daily_Price_Lists>;
  /** fetch aggregated fields from the table: "daily_price_lists" */
  dailyPriceListsAggregate: Daily_Price_Lists_Aggregate;
  /** An array relationship */
  demandsForCoiResults: Array<Demands_For_Coi_Results>;
  /** fetch aggregated fields from the table: "demands_for_coi_results" */
  demandsForCoiResultsAggregate: Demands_For_Coi_Results_Aggregate;
  /** An array relationship */
  dismantleableProductsResults: Array<Dismantleable_Products_Results>;
  /** fetch aggregated fields from the table: "dismantleable_products_results" */
  dismantleableProductsResultsAggregate: Dismantleable_Products_Results_Aggregate;
  /** execute function "dismantleable_products" which returns "dismantleable_products_results" */
  dismantleable_products: Array<Dismantleable_Products_Results>;
  /** execute function "dismantleable_products" and query aggregates on result of table type "dismantleable_products_results" */
  dismantleable_products_aggregate: Dismantleable_Products_Results_Aggregate;
  /** An array relationship */
  dismantlingOutputWeightResults: Array<Dismantling_Output_Weight_Results>;
  /** fetch aggregated fields from the table: "dismantling_output_weight_results" */
  dismantlingOutputWeightResultsAggregate: Dismantling_Output_Weight_Results_Aggregate;
  /** fetch data from the table: "dismantling_plans" using primary key columns */
  dismantlingPlanByPk?: Maybe<Dismantling_Plans>;
  /** fetch data from the table: "dismantling_plan_cutting_pattern_choices" using primary key columns */
  dismantlingPlanCuttingPatternChoiceByPk?: Maybe<Dismantling_Plan_Cutting_Pattern_Choices>;
  /** An array relationship */
  dismantlingPlanCuttingPatternChoices: Array<Dismantling_Plan_Cutting_Pattern_Choices>;
  /** fetch aggregated fields from the table: "dismantling_plan_cutting_pattern_choices" */
  dismantlingPlanCuttingPatternChoicesAggregate: Dismantling_Plan_Cutting_Pattern_Choices_Aggregate;
  /** fetch data from the table: "dismantling_plan_demands" using primary key columns */
  dismantlingPlanDemandByPk?: Maybe<Dismantling_Plan_Demands>;
  /** An array relationship */
  dismantlingPlanDemands: Array<Dismantling_Plan_Demands>;
  /** fetch aggregated fields from the table: "dismantling_plan_demands" */
  dismantlingPlanDemandsAggregate: Dismantling_Plan_Demands_Aggregate;
  /** fetch data from the table: "dismantling_plan_instructions" using primary key columns */
  dismantlingPlanInstructionByPk?: Maybe<Dismantling_Plan_Instructions>;
  /** An array relationship */
  dismantlingPlanInstructions: Array<Dismantling_Plan_Instructions>;
  /** fetch aggregated fields from the table: "dismantling_plan_instructions" */
  dismantlingPlanInstructionsAggregate: Dismantling_Plan_Instructions_Aggregate;
  /** An array relationship */
  dismantlingPlanLoggedActions: Array<Dismantling_Plan_Logged_Actions>;
  /** fetch aggregated fields from the table: "dismantling_plan_logged_actions" */
  dismantlingPlanLoggedActionsAggregate: Dismantling_Plan_Logged_Actions_Aggregate;
  /** fetch data from the table: "dismantling_plan_priorities" */
  dismantlingPlanPriorities: Array<Dismantling_Plan_Priorities>;
  /** fetch aggregated fields from the table: "dismantling_plan_priorities" */
  dismantlingPlanPrioritiesAggregate: Dismantling_Plan_Priorities_Aggregate;
  /** fetch data from the table: "dismantling_plan_priorities" using primary key columns */
  dismantlingPlanPriorityByPk?: Maybe<Dismantling_Plan_Priorities>;
  /** fetch data from the table: "dismantling_plan_remaining_pieces" using primary key columns */
  dismantlingPlanRemainingPieceByPk?: Maybe<Dismantling_Plan_Remaining_Pieces>;
  /** An array relationship */
  dismantlingPlanRemainingPieces: Array<Dismantling_Plan_Remaining_Pieces>;
  /** fetch aggregated fields from the table: "dismantling_plan_remaining_pieces" */
  dismantlingPlanRemainingPiecesAggregate: Dismantling_Plan_Remaining_Pieces_Aggregate;
  /** fetch data from the table: "dismantling_plan_states" using primary key columns */
  dismantlingPlanStateByPk?: Maybe<Dismantling_Plan_States>;
  /** fetch data from the table: "dismantling_plan_state_transitions" using primary key columns */
  dismantlingPlanStateTransitionByPk?: Maybe<Dismantling_Plan_State_Transitions>;
  /** fetch data from the table: "dismantling_plan_state_transitions" */
  dismantlingPlanStateTransitions: Array<Dismantling_Plan_State_Transitions>;
  /** fetch aggregated fields from the table: "dismantling_plan_state_transitions" */
  dismantlingPlanStateTransitionsAggregate: Dismantling_Plan_State_Transitions_Aggregate;
  /** fetch data from the table: "dismantling_plan_states" */
  dismantlingPlanStates: Array<Dismantling_Plan_States>;
  /** fetch aggregated fields from the table: "dismantling_plan_states" */
  dismantlingPlanStatesAggregate: Dismantling_Plan_States_Aggregate;
  /** An array relationship */
  dismantlingPlans: Array<Dismantling_Plans>;
  /** fetch aggregated fields from the table: "dismantling_plans" */
  dismantlingPlansAggregate: Dismantling_Plans_Aggregate;
  /** execute function "dismantling_plan_inventory" which returns "inventory_items" */
  dismantling_plan_inventory: Array<Inventory_Items>;
  /** execute function "dismantling_plan_inventory" and query aggregates on result of table type "inventory_items" */
  dismantling_plan_inventory_aggregate: Inventory_Items_Aggregate;
  /** execute function "dismantling_plan_inventory_candidate_search" which returns "inventory_items_by_location" */
  dismantling_plan_inventory_candidate_search: Array<Inventory_Items_By_Location>;
  /** execute function "dismantling_plan_inventory_candidate_search" and query aggregates on result of table type "inventory_items_by_location" */
  dismantling_plan_inventory_candidate_search_aggregate: Inventory_Items_By_Location_Aggregate;
  /** fetch data from the table: "edi_delivery_note_types" using primary key columns */
  ediDeliveryNoteTypeByPk?: Maybe<Edi_Delivery_Note_Types>;
  /** An array relationship */
  ediDeliveryNoteTypes: Array<Edi_Delivery_Note_Types>;
  /** fetch aggregated fields from the table: "edi_delivery_note_types" */
  ediDeliveryNoteTypesAggregate: Edi_Delivery_Note_Types_Aggregate;
  /** fetch data from the table: "edi_invoice_types" using primary key columns */
  ediInvoiceTypeByPk?: Maybe<Edi_Invoice_Types>;
  /** An array relationship */
  ediInvoiceTypes: Array<Edi_Invoice_Types>;
  /** fetch aggregated fields from the table: "edi_invoice_types" */
  ediInvoiceTypesAggregate: Edi_Invoice_Types_Aggregate;
  /** fetch data from the table: "emails" using primary key columns */
  emailByPk?: Maybe<Emails>;
  /** An array relationship */
  emails: Array<Emails>;
  /** fetch aggregated fields from the table: "emails" */
  emailsAggregate: Emails_Aggregate;
  /** fetch data from the table: "environment_state" using primary key columns */
  environmentStateByPk?: Maybe<Environment_State>;
  /** fetch data from the table: "environment_state" */
  environmentStates: Array<Environment_State>;
  /** fetch aggregated fields from the table: "environment_state" */
  environmentStatesAggregate: Environment_State_Aggregate;
  /** fetch data from the table: "exchange_rates" using primary key columns */
  exchangeRateByPk?: Maybe<Exchange_Rates>;
  /** An array relationship */
  exchangeRates: Array<Exchange_Rates>;
  /** fetch aggregated fields from the table: "exchange_rates" */
  exchangeRatesAggregate: Exchange_Rates_Aggregate;
  /** execute function "exchange_rates_for_day" which returns "exchange_rates" */
  exchange_rates_for_day: Array<Exchange_Rates>;
  /** execute function "exchange_rates_for_day" and query aggregates on result of table type "exchange_rates" */
  exchange_rates_for_day_aggregate: Exchange_Rates_Aggregate;
  /** fetch data from the table: "external_errors" using primary key columns */
  externalErrorByPk?: Maybe<External_Errors>;
  /** fetch data from the table: "external_errors" */
  externalErrors: Array<External_Errors>;
  /** fetch aggregated fields from the table: "external_errors" */
  externalErrorsAggregate: External_Errors_Aggregate;
  /** execute function "framework_agreements_within_range" which returns "supplier_orders" */
  framework_agreements_within_range: Array<Supplier_Orders>;
  /** execute function "framework_agreements_within_range" and query aggregates on result of table type "supplier_orders" */
  framework_agreements_within_range_aggregate: Supplier_Orders_Aggregate;
  /** An array relationship */
  fullWorkCalendars: Array<Full_Work_Calendar>;
  /** fetch aggregated fields from the table: "full_work_calendar" */
  fullWorkCalendarsAggregate: Full_Work_Calendar_Aggregate;
  /** fetch data from the table: "gender" using primary key columns */
  genderByPk?: Maybe<Gender>;
  /** fetch data from the table: "gender" */
  genders: Array<Gender>;
  /** fetch aggregated fields from the table: "gender" */
  gendersAggregate: Gender_Aggregate;
  /** fetch data from the table: "generate_presigned_url_result" */
  generatePresignedUrlResults: Array<Generate_Presigned_Url_Result>;
  /** fetch aggregated fields from the table: "generate_presigned_url_result" */
  generatePresignedUrlResultsAggregate: Generate_Presigned_Url_Result_Aggregate;
  /** execute function "get_latest_cutting_patterns_drafts" which returns "cutting_patterns_draft" */
  get_latest_cutting_patterns_drafts: Array<Cutting_Patterns_Draft>;
  /** execute function "get_latest_cutting_patterns_drafts" and query aggregates on result of table type "cutting_patterns_draft" */
  get_latest_cutting_patterns_drafts_aggregate: Cutting_Patterns_Draft_Aggregate;
  /** execute function "get_public_tenant_info" which returns "public_tenant_info_result" */
  get_public_tenant_info: Array<Public_Tenant_Info_Result>;
  /** execute function "get_public_tenant_info" and query aggregates on result of table type "public_tenant_info_result" */
  get_public_tenant_info_aggregate: Public_Tenant_Info_Result_Aggregate;
  /** execute function "get_tenant_info_with_token" which returns "tenant_info_with_token_result" */
  get_tenant_info_with_token: Array<Tenant_Info_With_Token_Result>;
  /** execute function "get_tenant_info_with_token" and query aggregates on result of table type "tenant_info_with_token_result" */
  get_tenant_info_with_token_aggregate: Tenant_Info_With_Token_Result_Aggregate;
  /** An array relationship */
  globalSearchResults: Array<Global_Search_Result>;
  /** fetch aggregated fields from the table: "global_search_result" */
  globalSearchResultsAggregate: Global_Search_Result_Aggregate;
  /** execute function "global_search" which returns "global_search_result" */
  global_search: Array<Global_Search_Result>;
  /** execute function "global_search" and query aggregates on result of table type "global_search_result" */
  global_search_aggregate: Global_Search_Result_Aggregate;
  /** fetch data from the table: "gs1_logistic_types" using primary key columns */
  gs1LogisticTypeByPk?: Maybe<Gs1_Logistic_Types>;
  /** An array relationship */
  gs1LogisticTypes: Array<Gs1_Logistic_Types>;
  /** fetch aggregated fields from the table: "gs1_logistic_types" */
  gs1LogisticTypesAggregate: Gs1_Logistic_Types_Aggregate;
  /** fetch data from the table: "hardware_devices" using primary key columns */
  hardwareDeviceByPk?: Maybe<Hardware_Devices>;
  /** fetch data from the table: "hardware_device_connection_types" using primary key columns */
  hardwareDeviceConnectionTypeByPk?: Maybe<Hardware_Device_Connection_Types>;
  /** fetch data from the table: "hardware_device_connection_types" */
  hardwareDeviceConnectionTypes: Array<Hardware_Device_Connection_Types>;
  /** fetch aggregated fields from the table: "hardware_device_connection_types" */
  hardwareDeviceConnectionTypesAggregate: Hardware_Device_Connection_Types_Aggregate;
  /** fetch data from the table: "hardware_device_types" using primary key columns */
  hardwareDeviceTypeByPk?: Maybe<Hardware_Device_Types>;
  /** fetch data from the table: "hardware_device_types" */
  hardwareDeviceTypes: Array<Hardware_Device_Types>;
  /** fetch aggregated fields from the table: "hardware_device_types" */
  hardwareDeviceTypesAggregate: Hardware_Device_Types_Aggregate;
  /** An array relationship */
  hardwareDevices: Array<Hardware_Devices>;
  /** fetch aggregated fields from the table: "hardware_devices" */
  hardwareDevicesAggregate: Hardware_Devices_Aggregate;
  /** fetch data from the table: "hierarchical_goods_incomes" using primary key columns */
  hierarchicalGoodsIncomeByPk?: Maybe<Hierarchical_Goods_Incomes>;
  /** An array relationship */
  hierarchicalGoodsIncomes: Array<Hierarchical_Goods_Incomes>;
  /** fetch aggregated fields from the table: "hierarchical_goods_incomes" */
  hierarchicalGoodsIncomesAggregate: Hierarchical_Goods_Incomes_Aggregate;
  /** fetch data from the table: "images" using primary key columns */
  imageByPk?: Maybe<Images>;
  /** An array relationship */
  images: Array<Images>;
  /** fetch aggregated fields from the table: "images" */
  imagesAggregate: Images_Aggregate;
  /** An array relationship */
  inventoryDailyPrognosisResults: Array<Inventory_Daily_Prognosis_Results>;
  /** fetch aggregated fields from the table: "inventory_daily_prognosis_results" */
  inventoryDailyPrognosisResultsAggregate: Inventory_Daily_Prognosis_Results_Aggregate;
  /** An array relationship */
  inventoryDailyUsageResults: Array<Inventory_Daily_Usage_Results>;
  /** fetch aggregated fields from the table: "inventory_daily_usage_results" */
  inventoryDailyUsageResultsAggregate: Inventory_Daily_Usage_Results_Aggregate;
  /** An array relationship */
  inventoryItems: Array<Inventory_Items>;
  /** fetch aggregated fields from the table: "inventory_items" */
  inventoryItemsAggregate: Inventory_Items_Aggregate;
  /** An array relationship */
  inventoryItemsByLocations: Array<Inventory_Items_By_Location>;
  /** fetch aggregated fields from the table: "inventory_items_by_location" */
  inventoryItemsByLocationsAggregate: Inventory_Items_By_Location_Aggregate;
  /** execute function "inventory_daily_prognosis" which returns "inventory_daily_prognosis_results" */
  inventory_daily_prognosis: Array<Inventory_Daily_Prognosis_Results>;
  /** execute function "inventory_daily_prognosis" and query aggregates on result of table type "inventory_daily_prognosis_results" */
  inventory_daily_prognosis_aggregate: Inventory_Daily_Prognosis_Results_Aggregate;
  /** An array relationship */
  journalEntries: Array<Journal_Entries>;
  /** fetch aggregated fields from the table: "journal_entries" */
  journalEntriesAggregate: Journal_Entries_Aggregate;
  /** fetch data from the table: "journal_entries" using primary key columns */
  journalEntryByPk?: Maybe<Journal_Entries>;
  /** fetch data from the table: "jscript_templates" using primary key columns */
  jscriptTemplateByPk?: Maybe<Jscript_Templates>;
  /** fetch data from the table: "jscript_template_names" using primary key columns */
  jscriptTemplateNameByPk?: Maybe<Jscript_Template_Names>;
  /** fetch data from the table: "jscript_template_names" */
  jscriptTemplateNames: Array<Jscript_Template_Names>;
  /** fetch aggregated fields from the table: "jscript_template_names" */
  jscriptTemplateNamesAggregate: Jscript_Template_Names_Aggregate;
  /** An array relationship */
  jscriptTemplates: Array<Jscript_Templates>;
  /** fetch aggregated fields from the table: "jscript_templates" */
  jscriptTemplatesAggregate: Jscript_Templates_Aggregate;
  /** fetch data from the table: "jscript_templates_logged_actions" */
  jscriptTemplatesLoggedActions: Array<Jscript_Templates_Logged_Actions>;
  /** fetch aggregated fields from the table: "jscript_templates_logged_actions" */
  jscriptTemplatesLoggedActionsAggregate: Jscript_Templates_Logged_Actions_Aggregate;
  /** fetch data from the table: "languages" using primary key columns */
  languageByPk?: Maybe<Languages>;
  /** fetch data from the table: "languages" */
  languages: Array<Languages>;
  /** fetch aggregated fields from the table: "languages" */
  languagesAggregate: Languages_Aggregate;
  /** fetch data from the table: "leichmehl_templates" using primary key columns */
  leichmehlTemplateByPk?: Maybe<Leichmehl_Templates>;
  /** fetch data from the table: "leichmehl_template_types" using primary key columns */
  leichmehlTemplateTypeByPk?: Maybe<Leichmehl_Template_Types>;
  /** An array relationship */
  leichmehlTemplateTypes: Array<Leichmehl_Template_Types>;
  /** fetch aggregated fields from the table: "leichmehl_template_types" */
  leichmehlTemplateTypesAggregate: Leichmehl_Template_Types_Aggregate;
  /** fetch data from the table: "leichmehl_template_types_logged_actions" */
  leichmehlTemplateTypesLoggedActions: Array<Leichmehl_Template_Types_Logged_Actions>;
  /** fetch aggregated fields from the table: "leichmehl_template_types_logged_actions" */
  leichmehlTemplateTypesLoggedActionsAggregate: Leichmehl_Template_Types_Logged_Actions_Aggregate;
  /** An array relationship */
  leichmehlTemplates: Array<Leichmehl_Templates>;
  /** fetch aggregated fields from the table: "leichmehl_templates" */
  leichmehlTemplatesAggregate: Leichmehl_Templates_Aggregate;
  /** fetch data from the table: "leichmehl_templates_logged_actions" */
  leichmehlTemplatesLoggedActions: Array<Leichmehl_Templates_Logged_Actions>;
  /** fetch aggregated fields from the table: "leichmehl_templates_logged_actions" */
  leichmehlTemplatesLoggedActionsAggregate: Leichmehl_Templates_Logged_Actions_Aggregate;
  /** An array relationship */
  listPurchasedProductsResults: Array<List_Purchased_Products_Result>;
  /** fetch aggregated fields from the table: "list_purchased_products_result" */
  listPurchasedProductsResultsAggregate: List_Purchased_Products_Result_Aggregate;
  /** execute function "list_container_inventory_by_customer" which returns "inventory_items" */
  list_container_inventory_by_customer: Array<Inventory_Items>;
  /** execute function "list_container_inventory_by_customer" and query aggregates on result of table type "inventory_items" */
  list_container_inventory_by_customer_aggregate: Inventory_Items_Aggregate;
  /** execute function "list_inventory" which returns "inventory_items" */
  list_inventory: Array<Inventory_Items>;
  /** execute function "list_inventory" and query aggregates on result of table type "inventory_items" */
  list_inventory_aggregate: Inventory_Items_Aggregate;
  /** execute function "list_inventory_by_location" which returns "inventory_items_by_location" */
  list_inventory_by_location: Array<Inventory_Items_By_Location>;
  /** execute function "list_inventory_by_location" and query aggregates on result of table type "inventory_items_by_location" */
  list_inventory_by_location_aggregate: Inventory_Items_By_Location_Aggregate;
  /** execute function "list_inventory_by_location_with_product_search" which returns "inventory_items_by_location" */
  list_inventory_by_location_with_product_search: Array<Inventory_Items_By_Location>;
  /** execute function "list_inventory_by_location_with_product_search" and query aggregates on result of table type "inventory_items_by_location" */
  list_inventory_by_location_with_product_search_aggregate: Inventory_Items_By_Location_Aggregate;
  /** execute function "list_ordered_customer_order_containers" which returns "inventory_items" */
  list_ordered_customer_order_containers: Array<Inventory_Items>;
  /** execute function "list_ordered_customer_order_containers" and query aggregates on result of table type "inventory_items" */
  list_ordered_customer_order_containers_aggregate: Inventory_Items_Aggregate;
  /** execute function "list_purchased_products_with_inventory" which returns "list_purchased_products_result" */
  list_purchased_products_with_inventory: Array<List_Purchased_Products_Result>;
  /** execute function "list_purchased_products_with_inventory" and query aggregates on result of table type "list_purchased_products_result" */
  list_purchased_products_with_inventory_aggregate: List_Purchased_Products_Result_Aggregate;
  /** fetch data from the table: "lot_numbers" using primary key columns */
  lotNumberByPk?: Maybe<Lot_Numbers>;
  /** fetch data from the table: "lot_number_products" using primary key columns */
  lotNumberProductByPk?: Maybe<Lot_Number_Products>;
  /** An array relationship */
  lotNumberProducts: Array<Lot_Number_Products>;
  /** fetch aggregated fields from the table: "lot_number_products" */
  lotNumberProductsAggregate: Lot_Number_Products_Aggregate;
  /** An array relationship */
  lotNumbers: Array<Lot_Numbers>;
  /** fetch aggregated fields from the table: "lot_numbers" */
  lotNumbersAggregate: Lot_Numbers_Aggregate;
  /** fetch data from the table: "my_user" */
  myUsers: Array<My_User>;
  /** fetch aggregated fields from the table: "my_user" */
  myUsersAggregate: My_User_Aggregate;
  /** fetch data from the table: "next_dismantling_plans" */
  nextDismantlingPlans: Array<Next_Dismantling_Plans>;
  /** fetch aggregated fields from the table: "next_dismantling_plans" */
  nextDismantlingPlansAggregate: Next_Dismantling_Plans_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notificationByPk?: Maybe<Notifications>;
  /** fetch data from the table: "notification_types" using primary key columns */
  notificationTypeByPk?: Maybe<Notification_Types>;
  /** fetch data from the table: "notification_types" */
  notificationTypes: Array<Notification_Types>;
  /** fetch aggregated fields from the table: "notification_types" */
  notificationTypesAggregate: Notification_Types_Aggregate;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** fetch aggregated fields from the table: "notifications" */
  notificationsAggregate: Notifications_Aggregate;
  /** execute function "output_cutting_pattern_products" which returns "products" */
  output_cutting_pattern_products: Array<Products>;
  /** execute function "output_cutting_pattern_products" and query aggregates on result of table type "products" */
  output_cutting_pattern_products_aggregate: Products_Aggregate;
  /** An array relationship */
  partialDeliveries: Array<Partial_Deliveries>;
  /** fetch aggregated fields from the table: "partial_deliveries" */
  partialDeliveriesAggregate: Partial_Deliveries_Aggregate;
  /** fetch data from the table: "partial_deliveries" using primary key columns */
  partialDeliveryByPk?: Maybe<Partial_Deliveries>;
  /** An array relationship */
  partialDeliveryLoggedActions: Array<Partial_Delivery_Logged_Actions>;
  /** fetch aggregated fields from the table: "partial_delivery_logged_actions" */
  partialDeliveryLoggedActionsAggregate: Partial_Delivery_Logged_Actions_Aggregate;
  /** fetch data from the table: "phones" using primary key columns */
  phoneByPk?: Maybe<Phones>;
  /** An array relationship */
  phones: Array<Phones>;
  /** fetch aggregated fields from the table: "phones" */
  phonesAggregate: Phones_Aggregate;
  /** fetch data from the table: "picked_items" using primary key columns */
  pickedItemByPk?: Maybe<Picked_Items>;
  /** fetch data from the table: "picked_item_containers" using primary key columns */
  pickedItemContainerByPk?: Maybe<Picked_Item_Containers>;
  /** An array relationship */
  pickedItemContainers: Array<Picked_Item_Containers>;
  /** fetch aggregated fields from the table: "picked_item_containers" */
  pickedItemContainersAggregate: Picked_Item_Containers_Aggregate;
  /** An array relationship */
  pickedItemLoggedActions: Array<Picked_Item_Logged_Actions>;
  /** fetch aggregated fields from the table: "picked_item_logged_actions" */
  pickedItemLoggedActionsAggregate: Picked_Item_Logged_Actions_Aggregate;
  /** fetch data from the table: "picked_item_states" using primary key columns */
  pickedItemStateByPk?: Maybe<Picked_Item_States>;
  /** fetch data from the table: "picked_item_state_transitions" using primary key columns */
  pickedItemStateTransitionByPk?: Maybe<Picked_Item_State_Transitions>;
  /** fetch data from the table: "picked_item_state_transitions" */
  pickedItemStateTransitions: Array<Picked_Item_State_Transitions>;
  /** fetch aggregated fields from the table: "picked_item_state_transitions" */
  pickedItemStateTransitionsAggregate: Picked_Item_State_Transitions_Aggregate;
  /** fetch data from the table: "picked_item_states" */
  pickedItemStates: Array<Picked_Item_States>;
  /** fetch aggregated fields from the table: "picked_item_states" */
  pickedItemStatesAggregate: Picked_Item_States_Aggregate;
  /** An array relationship */
  pickedItems: Array<Picked_Items>;
  /** fetch aggregated fields from the table: "picked_items" */
  pickedItemsAggregate: Picked_Items_Aggregate;
  /** An array relationship */
  pickingCategories: Array<Picking_Categories>;
  /** fetch aggregated fields from the table: "picking_categories" */
  pickingCategoriesAggregate: Picking_Categories_Aggregate;
  /** fetch data from the table: "picking_categories" using primary key columns */
  pickingCategoryByPk?: Maybe<Picking_Categories>;
  /** fetch data from the table: "picking_deviation_reasons" using primary key columns */
  pickingDeviationReasonByPk?: Maybe<Picking_Deviation_Reasons>;
  /** An array relationship */
  pickingDeviationReasons: Array<Picking_Deviation_Reasons>;
  /** fetch aggregated fields from the table: "picking_deviation_reasons" */
  pickingDeviationReasonsAggregate: Picking_Deviation_Reasons_Aggregate;
  /** execute function "picking_sorted_customer_orders" which returns "customer_orders" */
  picking_sorted_customer_orders: Array<Customer_Orders>;
  /** execute function "picking_sorted_customer_orders" and query aggregates on result of table type "customer_orders" */
  picking_sorted_customer_orders_aggregate: Customer_Orders_Aggregate;
  /** fetch data from the table: "postings" using primary key columns */
  postingByPk?: Maybe<Postings>;
  /** An array relationship */
  postings: Array<Postings>;
  /** fetch aggregated fields from the table: "postings" */
  postingsAggregate: Postings_Aggregate;
  /** fetch data from the table: "price_lists" using primary key columns */
  priceListByPk?: Maybe<Price_Lists>;
  /** An array relationship */
  priceLists: Array<Price_Lists>;
  /** fetch aggregated fields from the table: "price_lists" */
  priceListsAggregate: Price_Lists_Aggregate;
  /** fetch data from the table: "product_allowed_containers" using primary key columns */
  productAllowedContainerByPk?: Maybe<Product_Allowed_Containers>;
  /** An array relationship */
  productAllowedContainers: Array<Product_Allowed_Containers>;
  /** fetch aggregated fields from the table: "product_allowed_containers" */
  productAllowedContainersAggregate: Product_Allowed_Containers_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  productByPk?: Maybe<Products>;
  /** fetch data from the table: "product_groups" using primary key columns */
  productGroupByPk?: Maybe<Product_Groups>;
  /** fetch data from the table: "product_group_members" using primary key columns */
  productGroupMemberByPk?: Maybe<Product_Group_Members>;
  /** An array relationship */
  productGroupMembers: Array<Product_Group_Members>;
  /** fetch aggregated fields from the table: "product_group_members" */
  productGroupMembersAggregate: Product_Group_Members_Aggregate;
  /** fetch data from the table: "product_group_states" using primary key columns */
  productGroupStateByPk?: Maybe<Product_Group_States>;
  /** fetch data from the table: "product_group_state_transitions" using primary key columns */
  productGroupStateTransitionByPk?: Maybe<Product_Group_State_Transitions>;
  /** fetch data from the table: "product_group_state_transitions" */
  productGroupStateTransitions: Array<Product_Group_State_Transitions>;
  /** fetch aggregated fields from the table: "product_group_state_transitions" */
  productGroupStateTransitionsAggregate: Product_Group_State_Transitions_Aggregate;
  /** fetch data from the table: "product_group_states" */
  productGroupStates: Array<Product_Group_States>;
  /** fetch aggregated fields from the table: "product_group_states" */
  productGroupStatesAggregate: Product_Group_States_Aggregate;
  /** fetch data from the table: "product_group_types" using primary key columns */
  productGroupTypeByPk?: Maybe<Product_Group_Types>;
  /** fetch data from the table: "product_group_types" */
  productGroupTypes: Array<Product_Group_Types>;
  /** fetch aggregated fields from the table: "product_group_types" */
  productGroupTypesAggregate: Product_Group_Types_Aggregate;
  /** An array relationship */
  productGroups: Array<Product_Groups>;
  /** fetch aggregated fields from the table: "product_groups" */
  productGroupsAggregate: Product_Groups_Aggregate;
  /** fetch data from the table: "product_label_settings" using primary key columns */
  productLabelSettingByPk?: Maybe<Product_Label_Settings>;
  /** An array relationship */
  productLabelSettings: Array<Product_Label_Settings>;
  /** fetch aggregated fields from the table: "product_label_settings" */
  productLabelSettingsAggregate: Product_Label_Settings_Aggregate;
  /** An array relationship */
  productLoggedActions: Array<Product_Logged_Actions>;
  /** fetch aggregated fields from the table: "product_logged_actions" */
  productLoggedActionsAggregate: Product_Logged_Actions_Aggregate;
  /** fetch data from the table: "product_packaging_configurations" using primary key columns */
  productPackagingConfigurationByPk?: Maybe<Product_Packaging_Configurations>;
  /** An array relationship */
  productPackagingConfigurations: Array<Product_Packaging_Configurations>;
  /** fetch aggregated fields from the table: "product_packaging_configurations" */
  productPackagingConfigurationsAggregate: Product_Packaging_Configurations_Aggregate;
  /** fetch data from the table: "product_packaging_configurations_logged_actions" */
  productPackagingConfigurationsLoggedActions: Array<Product_Packaging_Configurations_Logged_Actions>;
  /** fetch aggregated fields from the table: "product_packaging_configurations_logged_actions" */
  productPackagingConfigurationsLoggedActionsAggregate: Product_Packaging_Configurations_Logged_Actions_Aggregate;
  /** fetch data from the table: "product_recipes" using primary key columns */
  productRecipeByPk?: Maybe<Product_Recipes>;
  /** fetch data from the table: "product_recipe_ingredients" using primary key columns */
  productRecipeIngredientByPk?: Maybe<Product_Recipe_Ingredients>;
  /** An array relationship */
  productRecipeIngredients: Array<Product_Recipe_Ingredients>;
  /** fetch aggregated fields from the table: "product_recipe_ingredients" */
  productRecipeIngredientsAggregate: Product_Recipe_Ingredients_Aggregate;
  /** fetch data from the table: "product_recipe_states" using primary key columns */
  productRecipeStateByPk?: Maybe<Product_Recipe_States>;
  /** fetch data from the table: "product_recipe_state_transitions" using primary key columns */
  productRecipeStateTransitionByPk?: Maybe<Product_Recipe_State_Transitions>;
  /** fetch data from the table: "product_recipe_state_transitions" */
  productRecipeStateTransitions: Array<Product_Recipe_State_Transitions>;
  /** fetch aggregated fields from the table: "product_recipe_state_transitions" */
  productRecipeStateTransitionsAggregate: Product_Recipe_State_Transitions_Aggregate;
  /** fetch data from the table: "product_recipe_states" */
  productRecipeStates: Array<Product_Recipe_States>;
  /** fetch aggregated fields from the table: "product_recipe_states" */
  productRecipeStatesAggregate: Product_Recipe_States_Aggregate;
  /** fetch data from the table: "product_recipe_steps" using primary key columns */
  productRecipeStepByPk?: Maybe<Product_Recipe_Steps>;
  /** An array relationship */
  productRecipeSteps: Array<Product_Recipe_Steps>;
  /** fetch aggregated fields from the table: "product_recipe_steps" */
  productRecipeStepsAggregate: Product_Recipe_Steps_Aggregate;
  /** An array relationship */
  productRecipes: Array<Product_Recipes>;
  /** fetch aggregated fields from the table: "product_recipes" */
  productRecipesAggregate: Product_Recipes_Aggregate;
  /** fetch data from the table: "product_s3_objects" using primary key columns */
  productS3ObjectByPk?: Maybe<Product_S3_Objects>;
  /** An array relationship */
  productS3Objects: Array<Product_S3_Objects>;
  /** fetch aggregated fields from the table: "product_s3_objects" */
  productS3ObjectsAggregate: Product_S3_Objects_Aggregate;
  /** fetch data from the table: "product_sales_channel_members" using primary key columns */
  productSalesChannelMemberByPk?: Maybe<Product_Sales_Channel_Members>;
  /** An array relationship */
  productSalesChannelMembers: Array<Product_Sales_Channel_Members>;
  /** fetch aggregated fields from the table: "product_sales_channel_members" */
  productSalesChannelMembersAggregate: Product_Sales_Channel_Members_Aggregate;
  /** fetch data from the table: "product_states" using primary key columns */
  productStateByPk?: Maybe<Product_States>;
  /** fetch data from the table: "product_state_transitions" using primary key columns */
  productStateTransitionByPk?: Maybe<Product_State_Transitions>;
  /** fetch data from the table: "product_state_transitions" */
  productStateTransitions: Array<Product_State_Transitions>;
  /** fetch aggregated fields from the table: "product_state_transitions" */
  productStateTransitionsAggregate: Product_State_Transitions_Aggregate;
  /** fetch data from the table: "product_states" */
  productStates: Array<Product_States>;
  /** fetch aggregated fields from the table: "product_states" */
  productStatesAggregate: Product_States_Aggregate;
  /** fetch data from the table: "product_stock_locations" using primary key columns */
  productStockLocationByPk?: Maybe<Product_Stock_Locations>;
  /** An array relationship */
  productStockLocations: Array<Product_Stock_Locations>;
  /** fetch aggregated fields from the table: "product_stock_locations" */
  productStockLocationsAggregate: Product_Stock_Locations_Aggregate;
  /** fetch data from the table: "product_suppliers" using primary key columns */
  productSupplierByPk?: Maybe<Product_Suppliers>;
  /** fetch data from the table: "product_supplier_states" using primary key columns */
  productSupplierStateByPk?: Maybe<Product_Supplier_States>;
  /** fetch data from the table: "product_supplier_state_transitions" using primary key columns */
  productSupplierStateTransitionByPk?: Maybe<Product_Supplier_State_Transitions>;
  /** fetch data from the table: "product_supplier_state_transitions" */
  productSupplierStateTransitions: Array<Product_Supplier_State_Transitions>;
  /** fetch aggregated fields from the table: "product_supplier_state_transitions" */
  productSupplierStateTransitionsAggregate: Product_Supplier_State_Transitions_Aggregate;
  /** fetch data from the table: "product_supplier_states" */
  productSupplierStates: Array<Product_Supplier_States>;
  /** fetch aggregated fields from the table: "product_supplier_states" */
  productSupplierStatesAggregate: Product_Supplier_States_Aggregate;
  /** An array relationship */
  productSuppliers: Array<Product_Suppliers>;
  /** fetch aggregated fields from the table: "product_suppliers" */
  productSuppliersAggregate: Product_Suppliers_Aggregate;
  /** fetch data from the table: "product_units" using primary key columns */
  productUnitByPk?: Maybe<Product_Units>;
  /** fetch data from the table: "product_unit_conversion_result" */
  productUnitConversionResults: Array<Product_Unit_Conversion_Result>;
  /** fetch aggregated fields from the table: "product_unit_conversion_result" */
  productUnitConversionResultsAggregate: Product_Unit_Conversion_Result_Aggregate;
  /** fetch data from the table: "product_unit_sales_price_config" using primary key columns */
  productUnitSalesPriceConfigByPk?: Maybe<Product_Unit_Sales_Price_Config>;
  /** An array relationship */
  productUnitSalesPriceConfigs: Array<Product_Unit_Sales_Price_Config>;
  /** fetch aggregated fields from the table: "product_unit_sales_price_config" */
  productUnitSalesPriceConfigsAggregate: Product_Unit_Sales_Price_Config_Aggregate;
  /** fetch data from the table: "product_unit_states" using primary key columns */
  productUnitStateByPk?: Maybe<Product_Unit_States>;
  /** fetch data from the table: "product_unit_state_transitions" using primary key columns */
  productUnitStateTransitionByPk?: Maybe<Product_Unit_State_Transitions>;
  /** fetch data from the table: "product_unit_state_transitions" */
  productUnitStateTransitions: Array<Product_Unit_State_Transitions>;
  /** fetch aggregated fields from the table: "product_unit_state_transitions" */
  productUnitStateTransitionsAggregate: Product_Unit_State_Transitions_Aggregate;
  /** fetch data from the table: "product_unit_states" */
  productUnitStates: Array<Product_Unit_States>;
  /** fetch aggregated fields from the table: "product_unit_states" */
  productUnitStatesAggregate: Product_Unit_States_Aggregate;
  /** An array relationship */
  productUnits: Array<Product_Units>;
  /** fetch aggregated fields from the table: "product_units" */
  productUnitsAggregate: Product_Units_Aggregate;
  /** execute function "product_unit_conversion" which returns "product_unit_conversion_result" */
  product_unit_conversion: Array<Product_Unit_Conversion_Result>;
  /** execute function "product_unit_conversion" and query aggregates on result of table type "product_unit_conversion_result" */
  product_unit_conversion_aggregate: Product_Unit_Conversion_Result_Aggregate;
  /** fetch data from the table: "production_sites" using primary key columns */
  productionSiteByPk?: Maybe<Production_Sites>;
  /** fetch data from the table: "production_site_input_stock_locations" using primary key columns */
  productionSiteInputStockLocationByPk?: Maybe<Production_Site_Input_Stock_Locations>;
  /** An array relationship */
  productionSiteInputStockLocations: Array<Production_Site_Input_Stock_Locations>;
  /** fetch aggregated fields from the table: "production_site_input_stock_locations" */
  productionSiteInputStockLocationsAggregate: Production_Site_Input_Stock_Locations_Aggregate;
  /** fetch data from the table: "production_site_output_stock_locations" using primary key columns */
  productionSiteOutputStockLocationByPk?: Maybe<Production_Site_Output_Stock_Locations>;
  /** fetch data from the table: "production_site_output_stock_locations" */
  productionSiteOutputStockLocations: Array<Production_Site_Output_Stock_Locations>;
  /** fetch aggregated fields from the table: "production_site_output_stock_locations" */
  productionSiteOutputStockLocationsAggregate: Production_Site_Output_Stock_Locations_Aggregate;
  /** fetch data from the table: "production_site_product_group_calendar" using primary key columns */
  productionSiteProductGroupCalendarByPk?: Maybe<Production_Site_Product_Group_Calendar>;
  /** An array relationship */
  productionSiteProductGroupCalendars: Array<Production_Site_Product_Group_Calendar>;
  /** fetch aggregated fields from the table: "production_site_product_group_calendar" */
  productionSiteProductGroupCalendarsAggregate: Production_Site_Product_Group_Calendar_Aggregate;
  /** fetch data from the table: "production_site_states" using primary key columns */
  productionSiteStateByPk?: Maybe<Production_Site_States>;
  /** fetch data from the table: "production_site_states" */
  productionSiteStates: Array<Production_Site_States>;
  /** fetch aggregated fields from the table: "production_site_states" */
  productionSiteStatesAggregate: Production_Site_States_Aggregate;
  /** An array relationship */
  productionSites: Array<Production_Sites>;
  /** fetch aggregated fields from the table: "production_sites" */
  productionSitesAggregate: Production_Sites_Aggregate;
  /** An array relationship */
  products: Array<Products>;
  /** fetch aggregated fields from the table: "products" */
  productsAggregate: Products_Aggregate;
  /** execute function "products_pricing" which returns "temporal_products" */
  products_pricing: Array<Temporal_Products>;
  /** execute function "products_pricing" and query aggregates on result of table type "temporal_products" */
  products_pricing_aggregate: Temporal_Products_Aggregate;
  /** fetch data from the table: "proletarian_archived_jobs" using primary key columns */
  proletarianArchivedJobByPk?: Maybe<Proletarian_Archived_Jobs>;
  /** fetch data from the table: "proletarian_archived_jobs" */
  proletarianArchivedJobs: Array<Proletarian_Archived_Jobs>;
  /** fetch aggregated fields from the table: "proletarian_archived_jobs" */
  proletarianArchivedJobsAggregate: Proletarian_Archived_Jobs_Aggregate;
  /** fetch data from the table: "proletarian_jobs" using primary key columns */
  proletarianJobByPk?: Maybe<Proletarian_Jobs>;
  /** fetch data from the table: "proletarian_jobs" */
  proletarianJobs: Array<Proletarian_Jobs>;
  /** fetch aggregated fields from the table: "proletarian_jobs" */
  proletarianJobsAggregate: Proletarian_Jobs_Aggregate;
  /** An array relationship */
  publicLoggedActions: Array<Public_Logged_Actions>;
  /** fetch aggregated fields from the table: "public_logged_actions" */
  publicLoggedActionsAggregate: Public_Logged_Actions_Aggregate;
  /** fetch data from the table: "public_tenant_info_result" */
  publicTenantInfoResults: Array<Public_Tenant_Info_Result>;
  /** fetch aggregated fields from the table: "public_tenant_info_result" */
  publicTenantInfoResultsAggregate: Public_Tenant_Info_Result_Aggregate;
  /** fetch data from the table: "purchasing_suggestions" using primary key columns */
  purchasingSuggestionByPk?: Maybe<Purchasing_Suggestions>;
  /** fetch data from the table: "purchasing_suggestions" */
  purchasingSuggestions: Array<Purchasing_Suggestions>;
  /** fetch aggregated fields from the table: "purchasing_suggestions" */
  purchasingSuggestionsAggregate: Purchasing_Suggestions_Aggregate;
  /** execute function "puspc_with_customer_prices" which returns "product_unit_sales_price_config" */
  puspc_with_customer_prices: Array<Product_Unit_Sales_Price_Config>;
  /** execute function "puspc_with_customer_prices" and query aggregates on result of table type "product_unit_sales_price_config" */
  puspc_with_customer_prices_aggregate: Product_Unit_Sales_Price_Config_Aggregate;
  /** fetch data from the table: "query_monitoring" using primary key columns */
  queryMonitoringByPk?: Maybe<Query_Monitoring>;
  /** fetch data from the table: "query_monitoring" */
  queryMonitorings: Array<Query_Monitoring>;
  /** fetch aggregated fields from the table: "query_monitoring" */
  queryMonitoringsAggregate: Query_Monitoring_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roleByPk?: Maybe<Roles>;
  /** fetch data from the table: "role_permissions" */
  rolePermissions: Array<Role_Permissions>;
  /** fetch aggregated fields from the table: "role_permissions" */
  rolePermissionsAggregate: Role_Permissions_Aggregate;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  rolesAggregate: Roles_Aggregate;
  /** An array relationship */
  rollingInventories: Array<Rolling_Inventories>;
  /** fetch aggregated fields from the table: "rolling_inventories" */
  rollingInventoriesAggregate: Rolling_Inventories_Aggregate;
  /** fetch data from the table: "rolling_inventory_actors" using primary key columns */
  rollingInventoryActorByPk?: Maybe<Rolling_Inventory_Actors>;
  /** An array relationship */
  rollingInventoryActors: Array<Rolling_Inventory_Actors>;
  /** fetch aggregated fields from the table: "rolling_inventory_actors" */
  rollingInventoryActorsAggregate: Rolling_Inventory_Actors_Aggregate;
  /** fetch data from the table: "rolling_inventories" using primary key columns */
  rollingInventoryByPk?: Maybe<Rolling_Inventories>;
  /** fetch data from the table: "rolling_inventory_items" using primary key columns */
  rollingInventoryItemByPk?: Maybe<Rolling_Inventory_Items>;
  /** An array relationship */
  rollingInventoryItems: Array<Rolling_Inventory_Items>;
  /** fetch aggregated fields from the table: "rolling_inventory_items" */
  rollingInventoryItemsAggregate: Rolling_Inventory_Items_Aggregate;
  /** fetch data from the table: "rolling_inventory_logged_actions" */
  rollingInventoryLoggedActions: Array<Rolling_Inventory_Logged_Actions>;
  /** fetch aggregated fields from the table: "rolling_inventory_logged_actions" */
  rollingInventoryLoggedActionsAggregate: Rolling_Inventory_Logged_Actions_Aggregate;
  /** An array relationship */
  rollingInventoryLotSummaryItems: Array<Rolling_Inventory_Lot_Summary_Items>;
  /** fetch aggregated fields from the table: "rolling_inventory_lot_summary_items" */
  rollingInventoryLotSummaryItemsAggregate: Rolling_Inventory_Lot_Summary_Items_Aggregate;
  /** fetch data from the table: "rolling_inventory_states" using primary key columns */
  rollingInventoryStateByPk?: Maybe<Rolling_Inventory_States>;
  /** fetch data from the table: "rolling_inventory_state_transitions" using primary key columns */
  rollingInventoryStateTransitionByPk?: Maybe<Rolling_Inventory_State_Transitions>;
  /** fetch data from the table: "rolling_inventory_state_transitions" */
  rollingInventoryStateTransitions: Array<Rolling_Inventory_State_Transitions>;
  /** fetch aggregated fields from the table: "rolling_inventory_state_transitions" */
  rollingInventoryStateTransitionsAggregate: Rolling_Inventory_State_Transitions_Aggregate;
  /** fetch data from the table: "rolling_inventory_states" */
  rollingInventoryStates: Array<Rolling_Inventory_States>;
  /** fetch aggregated fields from the table: "rolling_inventory_states" */
  rollingInventoryStatesAggregate: Rolling_Inventory_States_Aggregate;
  /** An array relationship */
  rollingInventorySummaryItems: Array<Rolling_Inventory_Summary_Items>;
  /** fetch aggregated fields from the table: "rolling_inventory_summary_items" */
  rollingInventorySummaryItemsAggregate: Rolling_Inventory_Summary_Items_Aggregate;
  /** execute function "rolling_inventory_lot_summary" which returns "rolling_inventory_lot_summary_items" */
  rolling_inventory_lot_summary: Array<Rolling_Inventory_Lot_Summary_Items>;
  /** execute function "rolling_inventory_lot_summary" and query aggregates on result of table type "rolling_inventory_lot_summary_items" */
  rolling_inventory_lot_summary_aggregate: Rolling_Inventory_Lot_Summary_Items_Aggregate;
  /** execute function "rolling_inventory_summary" which returns "rolling_inventory_summary_items" */
  rolling_inventory_summary: Array<Rolling_Inventory_Summary_Items>;
  /** execute function "rolling_inventory_summary" and query aggregates on result of table type "rolling_inventory_summary_items" */
  rolling_inventory_summary_aggregate: Rolling_Inventory_Summary_Items_Aggregate;
  /** execute function "root_cutting_pattern_products" which returns "products" */
  root_cutting_pattern_products: Array<Products>;
  /** execute function "root_cutting_pattern_products" and query aggregates on result of table type "products" */
  root_cutting_pattern_products_aggregate: Products_Aggregate;
  /** execute function "root_cutting_pattern_products_draft" which returns "products" */
  root_cutting_pattern_products_draft: Array<Products>;
  /** execute function "root_cutting_pattern_products_draft" and query aggregates on result of table type "products" */
  root_cutting_pattern_products_draft_aggregate: Products_Aggregate;
  /** execute function "root_cutting_pattern_temporal_products" which returns "temporal_products" */
  root_cutting_pattern_temporal_products: Array<Temporal_Products>;
  /** execute function "root_cutting_pattern_temporal_products" and query aggregates on result of table type "temporal_products" */
  root_cutting_pattern_temporal_products_aggregate: Temporal_Products_Aggregate;
  /** fetch data from the table: "s3_objects" using primary key columns */
  s3ObjectByPk?: Maybe<S3_Objects>;
  /** fetch data from the table: "s3_object_states" using primary key columns */
  s3ObjectStateByPk?: Maybe<S3_Object_States>;
  /** fetch data from the table: "s3_object_state_transitions" using primary key columns */
  s3ObjectStateTransitionByPk?: Maybe<S3_Object_State_Transitions>;
  /** fetch data from the table: "s3_object_state_transitions" */
  s3ObjectStateTransitions: Array<S3_Object_State_Transitions>;
  /** fetch aggregated fields from the table: "s3_object_state_transitions" */
  s3ObjectStateTransitionsAggregate: S3_Object_State_Transitions_Aggregate;
  /** fetch data from the table: "s3_object_states" */
  s3ObjectStates: Array<S3_Object_States>;
  /** fetch aggregated fields from the table: "s3_object_states" */
  s3ObjectStatesAggregate: S3_Object_States_Aggregate;
  /** fetch data from the table: "s3_object_visibilities" */
  s3ObjectVisibilities: Array<S3_Object_Visibilities>;
  /** fetch aggregated fields from the table: "s3_object_visibilities" */
  s3ObjectVisibilitiesAggregate: S3_Object_Visibilities_Aggregate;
  /** fetch data from the table: "s3_object_visibilities" using primary key columns */
  s3ObjectVisibilityByPk?: Maybe<S3_Object_Visibilities>;
  /** An array relationship */
  s3Objects: Array<S3_Objects>;
  /** fetch aggregated fields from the table: "s3_objects" */
  s3ObjectsAggregate: S3_Objects_Aggregate;
  /** fetch data from the table: "sales_channels" using primary key columns */
  salesChannelByPk?: Maybe<Sales_Channels>;
  /** An array relationship */
  salesChannels: Array<Sales_Channels>;
  /** fetch aggregated fields from the table: "sales_channels" */
  salesChannelsAggregate: Sales_Channels_Aggregate;
  /** fetch data from the table: "sales_teams" using primary key columns */
  salesTeamByPk?: Maybe<Sales_Teams>;
  /** fetch data from the table: "sales_team_states" using primary key columns */
  salesTeamStateByPk?: Maybe<Sales_Team_States>;
  /** fetch data from the table: "sales_team_state_transitions" using primary key columns */
  salesTeamStateTransitionByPk?: Maybe<Sales_Team_State_Transitions>;
  /** fetch data from the table: "sales_team_state_transitions" */
  salesTeamStateTransitions: Array<Sales_Team_State_Transitions>;
  /** fetch aggregated fields from the table: "sales_team_state_transitions" */
  salesTeamStateTransitionsAggregate: Sales_Team_State_Transitions_Aggregate;
  /** fetch data from the table: "sales_team_states" */
  salesTeamStates: Array<Sales_Team_States>;
  /** fetch aggregated fields from the table: "sales_team_states" */
  salesTeamStatesAggregate: Sales_Team_States_Aggregate;
  /** fetch data from the table: "sales_team_users" using primary key columns */
  salesTeamUserByPk?: Maybe<Sales_Team_Users>;
  /** An array relationship */
  salesTeamUsers: Array<Sales_Team_Users>;
  /** fetch aggregated fields from the table: "sales_team_users" */
  salesTeamUsersAggregate: Sales_Team_Users_Aggregate;
  /** An array relationship */
  salesTeams: Array<Sales_Teams>;
  /** fetch aggregated fields from the table: "sales_teams" */
  salesTeamsAggregate: Sales_Teams_Aggregate;
  /** fetch data from the table: "salutations" using primary key columns */
  salutationByPk?: Maybe<Salutations>;
  /** fetch data from the table: "salutations" */
  salutations: Array<Salutations>;
  /** fetch aggregated fields from the table: "salutations" */
  salutationsAggregate: Salutations_Aggregate;
  /** An array relationship */
  searchProductsForCustomerResults: Array<Search_Products_For_Customer_Results>;
  /** fetch aggregated fields from the table: "search_products_for_customer_results" */
  searchProductsForCustomerResultsAggregate: Search_Products_For_Customer_Results_Aggregate;
  /** An array relationship */
  searchPurchasedProductsResults: Array<Search_Purchased_Products_Results>;
  /** fetch aggregated fields from the table: "search_purchased_products_results" */
  searchPurchasedProductsResultsAggregate: Search_Purchased_Products_Results_Aggregate;
  /** execute function "search_contacts" which returns "contacts" */
  search_contacts: Array<Contacts>;
  /** execute function "search_contacts" and query aggregates on result of table type "contacts" */
  search_contacts_aggregate: Contacts_Aggregate;
  /** execute function "search_customer_groups" which returns "customer_groups" */
  search_customer_groups: Array<Customer_Groups>;
  /** execute function "search_customer_groups" and query aggregates on result of table type "customer_groups" */
  search_customer_groups_aggregate: Customer_Groups_Aggregate;
  /** execute function "search_customer_order_item_return_causes" which returns "customer_order_item_return_causes" */
  search_customer_order_item_return_causes: Array<Customer_Order_Item_Return_Causes>;
  /** execute function "search_customer_order_item_return_causes" and query aggregates on result of table type "customer_order_item_return_causes" */
  search_customer_order_item_return_causes_aggregate: Customer_Order_Item_Return_Causes_Aggregate;
  /** execute function "search_customer_orders" which returns "customer_orders" */
  search_customer_orders: Array<Customer_Orders>;
  /** execute function "search_customer_orders" and query aggregates on result of table type "customer_orders" */
  search_customer_orders_aggregate: Customer_Orders_Aggregate;
  /** execute function "search_customers" which returns "customers" */
  search_customers: Array<Customers>;
  /** execute function "search_customers" and query aggregates on result of table type "customers" */
  search_customers_aggregate: Customers_Aggregate;
  /** execute function "search_hardware_devices" which returns "hardware_devices" */
  search_hardware_devices: Array<Hardware_Devices>;
  /** execute function "search_hardware_devices" and query aggregates on result of table type "hardware_devices" */
  search_hardware_devices_aggregate: Hardware_Devices_Aggregate;
  /** execute function "search_lot_numbers" which returns "lot_numbers" */
  search_lot_numbers: Array<Lot_Numbers>;
  /** execute function "search_lot_numbers" and query aggregates on result of table type "lot_numbers" */
  search_lot_numbers_aggregate: Lot_Numbers_Aggregate;
  /** execute function "search_picking_categories" which returns "picking_categories" */
  search_picking_categories: Array<Picking_Categories>;
  /** execute function "search_picking_categories" and query aggregates on result of table type "picking_categories" */
  search_picking_categories_aggregate: Picking_Categories_Aggregate;
  /** execute function "search_product_groups" which returns "product_groups" */
  search_product_groups: Array<Product_Groups>;
  /** execute function "search_product_groups" and query aggregates on result of table type "product_groups" */
  search_product_groups_aggregate: Product_Groups_Aggregate;
  /** execute function "search_products" which returns "products" */
  search_products: Array<Products>;
  /** execute function "search_products" and query aggregates on result of table type "products" */
  search_products_aggregate: Products_Aggregate;
  /** execute function "search_products_for_customer" which returns "search_products_for_customer_results" */
  search_products_for_customer: Array<Search_Products_For_Customer_Results>;
  /** execute function "search_products_for_customer" and query aggregates on result of table type "search_products_for_customer_results" */
  search_products_for_customer_aggregate: Search_Products_For_Customer_Results_Aggregate;
  /** execute function "search_products_for_customer_group" which returns "search_products_for_customer_results" */
  search_products_for_customer_group: Array<Search_Products_For_Customer_Results>;
  /** execute function "search_products_for_customer_group" and query aggregates on result of table type "search_products_for_customer_results" */
  search_products_for_customer_group_aggregate: Search_Products_For_Customer_Results_Aggregate;
  /** execute function "search_purchased_products" which returns "search_products_for_customer_results" */
  search_purchased_products: Array<Search_Products_For_Customer_Results>;
  /** execute function "search_purchased_products" and query aggregates on result of table type "search_products_for_customer_results" */
  search_purchased_products_aggregate: Search_Products_For_Customer_Results_Aggregate;
  /** execute function "search_sales_teams" which returns "sales_teams" */
  search_sales_teams: Array<Sales_Teams>;
  /** execute function "search_sales_teams" and query aggregates on result of table type "sales_teams" */
  search_sales_teams_aggregate: Sales_Teams_Aggregate;
  /** execute function "search_stock_locations" which returns "stock_locations" */
  search_stock_locations: Array<Stock_Locations>;
  /** execute function "search_stock_locations" and query aggregates on result of table type "stock_locations" */
  search_stock_locations_aggregate: Stock_Locations_Aggregate;
  /** execute function "search_supplier_orders" which returns "supplier_orders" */
  search_supplier_orders: Array<Supplier_Orders>;
  /** execute function "search_supplier_orders" and query aggregates on result of table type "supplier_orders" */
  search_supplier_orders_aggregate: Supplier_Orders_Aggregate;
  /** execute function "search_suppliers" which returns "suppliers" */
  search_suppliers: Array<Suppliers>;
  /** execute function "search_suppliers" and query aggregates on result of table type "suppliers" */
  search_suppliers_aggregate: Suppliers_Aggregate;
  /** execute function "search_users" which returns "users" */
  search_users: Array<Users>;
  /** execute function "search_users" and query aggregates on result of table type "users" */
  search_users_aggregate: Users_Aggregate;
  /** execute function "sorted_customer_group_pricing_for_customer" which returns "customer_group_pricing" */
  sorted_customer_group_pricing_for_customer: Array<Customer_Group_Pricing>;
  /** execute function "sorted_customer_group_pricing_for_customer" and query aggregates on result of table type "customer_group_pricing" */
  sorted_customer_group_pricing_for_customer_aggregate: Customer_Group_Pricing_Aggregate;
  /** execute function "sorted_customer_group_pricing_for_customer_group" which returns "customer_group_pricing" */
  sorted_customer_group_pricing_for_customer_group: Array<Customer_Group_Pricing>;
  /** execute function "sorted_customer_group_pricing_for_customer_group" and query aggregates on result of table type "customer_group_pricing" */
  sorted_customer_group_pricing_for_customer_group_aggregate: Customer_Group_Pricing_Aggregate;
  /** execute function "sorted_price_lists" which returns "price_lists" */
  sorted_price_lists: Array<Price_Lists>;
  /** execute function "sorted_price_lists" and query aggregates on result of table type "price_lists" */
  sorted_price_lists_aggregate: Price_Lists_Aggregate;
  /** fetch data from the table: "stock_locations" using primary key columns */
  stockLocationByPk?: Maybe<Stock_Locations>;
  /** fetch data from the table: "stock_location_states" using primary key columns */
  stockLocationStateByPk?: Maybe<Stock_Location_States>;
  /** fetch data from the table: "stock_location_state_transitions" using primary key columns */
  stockLocationStateTransitionByPk?: Maybe<Stock_Location_State_Transitions>;
  /** fetch data from the table: "stock_location_state_transitions" */
  stockLocationStateTransitions: Array<Stock_Location_State_Transitions>;
  /** fetch aggregated fields from the table: "stock_location_state_transitions" */
  stockLocationStateTransitionsAggregate: Stock_Location_State_Transitions_Aggregate;
  /** fetch data from the table: "stock_location_states" */
  stockLocationStates: Array<Stock_Location_States>;
  /** fetch aggregated fields from the table: "stock_location_states" */
  stockLocationStatesAggregate: Stock_Location_States_Aggregate;
  /** An array relationship */
  stockLocations: Array<Stock_Locations>;
  /** fetch aggregated fields from the table: "stock_locations" */
  stockLocationsAggregate: Stock_Locations_Aggregate;
  /** fetch data from the table: "stock_transactions" using primary key columns */
  stockTransactionByPk?: Maybe<Stock_Transactions>;
  /** An array relationship */
  stockTransactionLoggedActions: Array<Stock_Transaction_Logged_Actions>;
  /** fetch aggregated fields from the table: "stock_transaction_logged_actions" */
  stockTransactionLoggedActionsAggregate: Stock_Transaction_Logged_Actions_Aggregate;
  /** fetch data from the table: "stock_transaction_names" using primary key columns */
  stockTransactionNameByPk?: Maybe<Stock_Transaction_Names>;
  /** fetch data from the table: "stock_transaction_names" */
  stockTransactionNames: Array<Stock_Transaction_Names>;
  /** fetch aggregated fields from the table: "stock_transaction_names" */
  stockTransactionNamesAggregate: Stock_Transaction_Names_Aggregate;
  /** An array relationship */
  stockTransactions: Array<Stock_Transactions>;
  /** fetch aggregated fields from the table: "stock_transactions" */
  stockTransactionsAggregate: Stock_Transactions_Aggregate;
  /** fetch data from the table: "supplier_addresses" using primary key columns */
  supplierAddressByPk?: Maybe<Supplier_Addresses>;
  /** An array relationship */
  supplierAddresses: Array<Supplier_Addresses>;
  /** fetch aggregated fields from the table: "supplier_addresses" */
  supplierAddressesAggregate: Supplier_Addresses_Aggregate;
  /** fetch data from the table: "suppliers" using primary key columns */
  supplierByPk?: Maybe<Suppliers>;
  /** fetch data from the table: "supplier_orders" using primary key columns */
  supplierOrderByPk?: Maybe<Supplier_Orders>;
  /** fetch data from the table: "supplier_order_goods_income_items" using primary key columns */
  supplierOrderGoodsIncomeItemByPk?: Maybe<Supplier_Order_Goods_Income_Items>;
  /** fetch data from the table: "supplier_order_goods_income_item_containers" using primary key columns */
  supplierOrderGoodsIncomeItemContainerByPk?: Maybe<Supplier_Order_Goods_Income_Item_Containers>;
  /** An array relationship */
  supplierOrderGoodsIncomeItemContainers: Array<Supplier_Order_Goods_Income_Item_Containers>;
  /** fetch aggregated fields from the table: "supplier_order_goods_income_item_containers" */
  supplierOrderGoodsIncomeItemContainersAggregate: Supplier_Order_Goods_Income_Item_Containers_Aggregate;
  /** fetch data from the table: "supplier_order_goods_income_item_logged_actions" */
  supplierOrderGoodsIncomeItemLoggedActions: Array<Supplier_Order_Goods_Income_Item_Logged_Actions>;
  /** fetch aggregated fields from the table: "supplier_order_goods_income_item_logged_actions" */
  supplierOrderGoodsIncomeItemLoggedActionsAggregate: Supplier_Order_Goods_Income_Item_Logged_Actions_Aggregate;
  /** An array relationship */
  supplierOrderGoodsIncomeItems: Array<Supplier_Order_Goods_Income_Items>;
  /** fetch aggregated fields from the table: "supplier_order_goods_income_items" */
  supplierOrderGoodsIncomeItemsAggregate: Supplier_Order_Goods_Income_Items_Aggregate;
  /** fetch data from the table: "supplier_order_items" using primary key columns */
  supplierOrderItemByPk?: Maybe<Supplier_Order_Items>;
  /** An array relationship */
  supplierOrderItemLoggedActions: Array<Supplier_Order_Item_Logged_Actions>;
  /** fetch aggregated fields from the table: "supplier_order_item_logged_actions" */
  supplierOrderItemLoggedActionsAggregate: Supplier_Order_Item_Logged_Actions_Aggregate;
  /** fetch data from the table: "supplier_order_item_return_causes" using primary key columns */
  supplierOrderItemReturnCauseByPk?: Maybe<Supplier_Order_Item_Return_Causes>;
  /** An array relationship */
  supplierOrderItemReturnCauses: Array<Supplier_Order_Item_Return_Causes>;
  /** fetch aggregated fields from the table: "supplier_order_item_return_causes" */
  supplierOrderItemReturnCausesAggregate: Supplier_Order_Item_Return_Causes_Aggregate;
  /** fetch data from the table: "supplier_order_item_states" using primary key columns */
  supplierOrderItemStateByPk?: Maybe<Supplier_Order_Item_States>;
  /** fetch data from the table: "supplier_order_item_states" */
  supplierOrderItemStates: Array<Supplier_Order_Item_States>;
  /** fetch aggregated fields from the table: "supplier_order_item_states" */
  supplierOrderItemStatesAggregate: Supplier_Order_Item_States_Aggregate;
  /** An array relationship */
  supplierOrderItems: Array<Supplier_Order_Items>;
  /** fetch aggregated fields from the table: "supplier_order_items" */
  supplierOrderItemsAggregate: Supplier_Order_Items_Aggregate;
  /** An array relationship */
  supplierOrderLoggedActions: Array<Supplier_Order_Logged_Actions>;
  /** fetch aggregated fields from the table: "supplier_order_logged_actions" */
  supplierOrderLoggedActionsAggregate: Supplier_Order_Logged_Actions_Aggregate;
  /** fetch data from the table: "supplier_order_returns" using primary key columns */
  supplierOrderReturnByPk?: Maybe<Supplier_Order_Returns>;
  /** fetch data from the table: "supplier_order_return_items" using primary key columns */
  supplierOrderReturnItemByPk?: Maybe<Supplier_Order_Return_Items>;
  /** fetch data from the table: "supplier_order_return_item_containers" using primary key columns */
  supplierOrderReturnItemContainerByPk?: Maybe<Supplier_Order_Return_Item_Containers>;
  /** An array relationship */
  supplierOrderReturnItemContainers: Array<Supplier_Order_Return_Item_Containers>;
  /** fetch aggregated fields from the table: "supplier_order_return_item_containers" */
  supplierOrderReturnItemContainersAggregate: Supplier_Order_Return_Item_Containers_Aggregate;
  /** An array relationship */
  supplierOrderReturnItems: Array<Supplier_Order_Return_Items>;
  /** fetch aggregated fields from the table: "supplier_order_return_items" */
  supplierOrderReturnItemsAggregate: Supplier_Order_Return_Items_Aggregate;
  /** fetch data from the table: "supplier_order_return_states" using primary key columns */
  supplierOrderReturnStateByPk?: Maybe<Supplier_Order_Return_States>;
  /** fetch data from the table: "supplier_order_return_states" */
  supplierOrderReturnStates: Array<Supplier_Order_Return_States>;
  /** fetch aggregated fields from the table: "supplier_order_return_states" */
  supplierOrderReturnStatesAggregate: Supplier_Order_Return_States_Aggregate;
  /** fetch data from the table: "supplier_order_returned_containers" using primary key columns */
  supplierOrderReturnedContainerByPk?: Maybe<Supplier_Order_Returned_Containers>;
  /** An array relationship */
  supplierOrderReturnedContainers: Array<Supplier_Order_Returned_Containers>;
  /** fetch aggregated fields from the table: "supplier_order_returned_containers" */
  supplierOrderReturnedContainersAggregate: Supplier_Order_Returned_Containers_Aggregate;
  /** An array relationship */
  supplierOrderReturns: Array<Supplier_Order_Returns>;
  /** fetch aggregated fields from the table: "supplier_order_returns" */
  supplierOrderReturnsAggregate: Supplier_Order_Returns_Aggregate;
  /** fetch data from the table: "supplier_order_states" using primary key columns */
  supplierOrderStateByPk?: Maybe<Supplier_Order_States>;
  /** fetch data from the table: "supplier_order_state_transitions" using primary key columns */
  supplierOrderStateTransitionByPk?: Maybe<Supplier_Order_State_Transitions>;
  /** fetch data from the table: "supplier_order_state_transitions" */
  supplierOrderStateTransitions: Array<Supplier_Order_State_Transitions>;
  /** fetch aggregated fields from the table: "supplier_order_state_transitions" */
  supplierOrderStateTransitionsAggregate: Supplier_Order_State_Transitions_Aggregate;
  /** fetch data from the table: "supplier_order_states" */
  supplierOrderStates: Array<Supplier_Order_States>;
  /** fetch aggregated fields from the table: "supplier_order_states" */
  supplierOrderStatesAggregate: Supplier_Order_States_Aggregate;
  /** An array relationship */
  supplierOrders: Array<Supplier_Orders>;
  /** fetch aggregated fields from the table: "supplier_orders" */
  supplierOrdersAggregate: Supplier_Orders_Aggregate;
  /** fetch data from the table: "supplier_states" using primary key columns */
  supplierStateByPk?: Maybe<Supplier_States>;
  /** fetch data from the table: "supplier_state_transitions" using primary key columns */
  supplierStateTransitionByPk?: Maybe<Supplier_State_Transitions>;
  /** fetch data from the table: "supplier_state_transitions" */
  supplierStateTransitions: Array<Supplier_State_Transitions>;
  /** fetch aggregated fields from the table: "supplier_state_transitions" */
  supplierStateTransitionsAggregate: Supplier_State_Transitions_Aggregate;
  /** fetch data from the table: "supplier_states" */
  supplierStates: Array<Supplier_States>;
  /** fetch aggregated fields from the table: "supplier_states" */
  supplierStatesAggregate: Supplier_States_Aggregate;
  /** An array relationship */
  suppliers: Array<Suppliers>;
  /** fetch aggregated fields from the table: "suppliers" */
  suppliersAggregate: Suppliers_Aggregate;
  /** fetch data from the table: "temporal_pricing_states" using primary key columns */
  temporalPricingStateByPk?: Maybe<Temporal_Pricing_States>;
  /** fetch data from the table: "temporal_pricing_state_transitions" using primary key columns */
  temporalPricingStateTransitionByPk?: Maybe<Temporal_Pricing_State_Transitions>;
  /** fetch data from the table: "temporal_pricing_state_transitions" */
  temporalPricingStateTransitions: Array<Temporal_Pricing_State_Transitions>;
  /** fetch aggregated fields from the table: "temporal_pricing_state_transitions" */
  temporalPricingStateTransitionsAggregate: Temporal_Pricing_State_Transitions_Aggregate;
  /** fetch data from the table: "temporal_pricing_states" */
  temporalPricingStates: Array<Temporal_Pricing_States>;
  /** fetch aggregated fields from the table: "temporal_pricing_states" */
  temporalPricingStatesAggregate: Temporal_Pricing_States_Aggregate;
  /** fetch data from the table: "temporal_products" using primary key columns */
  temporalProductByPk?: Maybe<Temporal_Products>;
  /** An array relationship */
  temporalProductComparisonResults: Array<Temporal_Product_Comparison_Result>;
  /** fetch aggregated fields from the table: "temporal_product_comparison_result" */
  temporalProductComparisonResultsAggregate: Temporal_Product_Comparison_Result_Aggregate;
  /** An array relationship */
  temporalProducts: Array<Temporal_Products>;
  /** fetch aggregated fields from the table: "temporal_products" */
  temporalProductsAggregate: Temporal_Products_Aggregate;
  /** fetch data from the table: "temporal_sales_teams_customers" using primary key columns */
  temporalSalesTeamsCustomerByPk?: Maybe<Temporal_Sales_Teams_Customers>;
  /** An array relationship */
  temporalSalesTeamsCustomers: Array<Temporal_Sales_Teams_Customers>;
  /** fetch aggregated fields from the table: "temporal_sales_teams_customers" */
  temporalSalesTeamsCustomersAggregate: Temporal_Sales_Teams_Customers_Aggregate;
  /** fetch data from the table: "temporal_vat_types" using primary key columns */
  temporalVatTypeByPk?: Maybe<Temporal_Vat_Types>;
  /** An array relationship */
  temporalVatTypes: Array<Temporal_Vat_Types>;
  /** fetch aggregated fields from the table: "temporal_vat_types" */
  temporalVatTypesAggregate: Temporal_Vat_Types_Aggregate;
  /** execute function "temporal_products_for_cutting_pattern" which returns "temporal_products" */
  temporal_products_for_cutting_pattern: Array<Temporal_Products>;
  /** execute function "temporal_products_for_cutting_pattern" and query aggregates on result of table type "temporal_products" */
  temporal_products_for_cutting_pattern_aggregate: Temporal_Products_Aggregate;
  /** execute function "temporal_products_from_root_product_id" which returns "temporal_products" */
  temporal_products_from_root_product_id: Array<Temporal_Products>;
  /** execute function "temporal_products_from_root_product_id" and query aggregates on result of table type "temporal_products" */
  temporal_products_from_root_product_id_aggregate: Temporal_Products_Aggregate;
  /** fetch data from the table: "tenant_addresses" using primary key columns */
  tenantAddressByPk?: Maybe<Tenant_Addresses>;
  /** An array relationship */
  tenantAddresses: Array<Tenant_Addresses>;
  /** fetch aggregated fields from the table: "tenant_addresses" */
  tenantAddressesAggregate: Tenant_Addresses_Aggregate;
  /** fetch data from the table: "tenant_bank_accounts" using primary key columns */
  tenantBankAccountByPk?: Maybe<Tenant_Bank_Accounts>;
  /** An array relationship */
  tenantBankAccounts: Array<Tenant_Bank_Accounts>;
  /** fetch aggregated fields from the table: "tenant_bank_accounts" */
  tenantBankAccountsAggregate: Tenant_Bank_Accounts_Aggregate;
  /** fetch data from the table: "tenants" using primary key columns */
  tenantByPk?: Maybe<Tenants>;
  /** fetch data from the table: "tenant_company_details" using primary key columns */
  tenantCompanyDetailByPk?: Maybe<Tenant_Company_Details>;
  /** An array relationship */
  tenantCompanyDetails: Array<Tenant_Company_Details>;
  /** fetch aggregated fields from the table: "tenant_company_details" */
  tenantCompanyDetailsAggregate: Tenant_Company_Details_Aggregate;
  /** fetch data from the table: "tenant_configurations" using primary key columns */
  tenantConfigurationByPk?: Maybe<Tenant_Configurations>;
  /** An array relationship */
  tenantConfigurations: Array<Tenant_Configurations>;
  /** fetch aggregated fields from the table: "tenant_configurations" */
  tenantConfigurationsAggregate: Tenant_Configurations_Aggregate;
  /** fetch data from the table: "tenant_info_with_token_result" */
  tenantInfoWithTokenResults: Array<Tenant_Info_With_Token_Result>;
  /** fetch aggregated fields from the table: "tenant_info_with_token_result" */
  tenantInfoWithTokenResultsAggregate: Tenant_Info_With_Token_Result_Aggregate;
  /** fetch data from the table: "tenant_languages" using primary key columns */
  tenantLanguageByPk?: Maybe<Tenant_Languages>;
  /** An array relationship */
  tenantLanguages: Array<Tenant_Languages>;
  /** fetch aggregated fields from the table: "tenant_languages" */
  tenantLanguagesAggregate: Tenant_Languages_Aggregate;
  /** fetch data from the table: "tenant_permissions" using primary key columns */
  tenantPermissionByPk?: Maybe<Tenant_Permissions>;
  /** An array relationship */
  tenantPermissions: Array<Tenant_Permissions>;
  /** fetch aggregated fields from the table: "tenant_permissions" */
  tenantPermissionsAggregate: Tenant_Permissions_Aggregate;
  /** An array relationship */
  tenants: Array<Tenants>;
  /** fetch aggregated fields from the table: "tenants" */
  tenantsAggregate: Tenants_Aggregate;
  /** execute function "todays_todos" which returns "todos" */
  todays_todos: Array<Todos>;
  /** execute function "todays_todos" and query aggregates on result of table type "todos" */
  todays_todos_aggregate: Todos_Aggregate;
  /** fetch data from the table: "todo_actions" using primary key columns */
  todoActionByPk?: Maybe<Todo_Actions>;
  /** fetch data from the table: "todo_actions" */
  todoActions: Array<Todo_Actions>;
  /** fetch aggregated fields from the table: "todo_actions" */
  todoActionsAggregate: Todo_Actions_Aggregate;
  /** fetch data from the table: "todos" using primary key columns */
  todoByPk?: Maybe<Todos>;
  /** fetch data from the table: "todo_recurrences" using primary key columns */
  todoRecurrenceByPk?: Maybe<Todo_Recurrences>;
  /** fetch data from the table: "todo_recurrences" */
  todoRecurrences: Array<Todo_Recurrences>;
  /** fetch aggregated fields from the table: "todo_recurrences" */
  todoRecurrencesAggregate: Todo_Recurrences_Aggregate;
  /** fetch data from the table: "todo_states" using primary key columns */
  todoStateByPk?: Maybe<Todo_States>;
  /** fetch data from the table: "todo_states" */
  todoStates: Array<Todo_States>;
  /** fetch aggregated fields from the table: "todo_states" */
  todoStatesAggregate: Todo_States_Aggregate;
  /** An array relationship */
  todos: Array<Todos>;
  /** fetch aggregated fields from the table: "todos" */
  todosAggregate: Todos_Aggregate;
  /** execute function "todos_on_day" which returns "todos" */
  todos_on_day: Array<Todos>;
  /** execute function "todos_on_day" and query aggregates on result of table type "todos" */
  todos_on_day_aggregate: Todos_Aggregate;
  /** An array relationship */
  topologicallySortedCuttingPatternsResults: Array<Topologically_Sorted_Cutting_Patterns_Results>;
  /** fetch aggregated fields from the table: "topologically_sorted_cutting_patterns_results" */
  topologicallySortedCuttingPatternsResultsAggregate: Topologically_Sorted_Cutting_Patterns_Results_Aggregate;
  /** execute function "topologically_sorted_cutting_patterns" which returns "topologically_sorted_cutting_patterns_results" */
  topologically_sorted_cutting_patterns: Array<Topologically_Sorted_Cutting_Patterns_Results>;
  /** execute function "topologically_sorted_cutting_patterns" and query aggregates on result of table type "topologically_sorted_cutting_patterns_results" */
  topologically_sorted_cutting_patterns_aggregate: Topologically_Sorted_Cutting_Patterns_Results_Aggregate;
  /** fetch data from the table: "tours" using primary key columns */
  tourByPk?: Maybe<Tours>;
  /** fetch data from the table: "tour_routes" using primary key columns */
  tourRouteByPk?: Maybe<Tour_Routes>;
  /** An array relationship */
  tourRoutes: Array<Tour_Routes>;
  /** fetch aggregated fields from the table: "tour_routes" */
  tourRoutesAggregate: Tour_Routes_Aggregate;
  /** fetch data from the table: "tour_states" using primary key columns */
  tourStateByPk?: Maybe<Tour_States>;
  /** fetch data from the table: "tour_state_transitions" using primary key columns */
  tourStateTransitionByPk?: Maybe<Tour_State_Transitions>;
  /** fetch data from the table: "tour_state_transitions" */
  tourStateTransitions: Array<Tour_State_Transitions>;
  /** fetch aggregated fields from the table: "tour_state_transitions" */
  tourStateTransitionsAggregate: Tour_State_Transitions_Aggregate;
  /** fetch data from the table: "tour_states" */
  tourStates: Array<Tour_States>;
  /** fetch aggregated fields from the table: "tour_states" */
  tourStatesAggregate: Tour_States_Aggregate;
  /** An array relationship */
  tours: Array<Tours>;
  /** fetch aggregated fields from the table: "tours" */
  toursAggregate: Tours_Aggregate;
  /** fetch data from the table: "units" using primary key columns */
  unitByPk?: Maybe<Units>;
  /** fetch data from the table: "units" */
  units: Array<Units>;
  /** fetch aggregated fields from the table: "units" */
  unitsAggregate: Units_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  userByPk?: Maybe<Users>;
  userRoles?: Maybe<UserRolesOutput>;
  /** fetch data from the table: "user_sales_goals" using primary key columns */
  userSalesGoalByPk?: Maybe<User_Sales_Goals>;
  /** An array relationship */
  userSalesGoals: Array<User_Sales_Goals>;
  /** fetch aggregated fields from the table: "user_sales_goals" */
  userSalesGoalsAggregate: User_Sales_Goals_Aggregate;
  /** fetch data from the table: "user_states" using primary key columns */
  userStateByPk?: Maybe<User_States>;
  /** fetch data from the table: "user_state_transitions" using primary key columns */
  userStateTransitionByPk?: Maybe<User_State_Transitions>;
  /** fetch data from the table: "user_state_transitions" */
  userStateTransitions: Array<User_State_Transitions>;
  /** fetch aggregated fields from the table: "user_state_transitions" */
  userStateTransitionsAggregate: User_State_Transitions_Aggregate;
  /** fetch data from the table: "user_states" */
  userStates: Array<User_States>;
  /** fetch aggregated fields from the table: "user_states" */
  userStatesAggregate: User_States_Aggregate;
  /** An array relationship */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  usersAggregate: Users_Aggregate;
  /** fetch data from the table: "vat_types" using primary key columns */
  vatTypeByPk?: Maybe<Vat_Types>;
  /** An array relationship */
  vatTypes: Array<Vat_Types>;
  /** fetch aggregated fields from the table: "vat_types" */
  vatTypesAggregate: Vat_Types_Aggregate;
  /** fetch data from the table: "work_calendar" using primary key columns */
  workCalendarByPk?: Maybe<Work_Calendar>;
  /** fetch data from the table: "work_calendar_logged_actions" */
  workCalendarLoggedActions: Array<Work_Calendar_Logged_Actions>;
  /** fetch aggregated fields from the table: "work_calendar_logged_actions" */
  workCalendarLoggedActionsAggregate: Work_Calendar_Logged_Actions_Aggregate;
  /** An array relationship */
  workCalendars: Array<Work_Calendar>;
  /** fetch aggregated fields from the table: "work_calendar" */
  workCalendarsAggregate: Work_Calendar_Aggregate;
  /** fetch data from the table: "working_days" using primary key columns */
  workingDayByPk?: Maybe<Working_Days>;
  /** fetch data from the table: "working_days" */
  workingDays: Array<Working_Days>;
  /** fetch aggregated fields from the table: "working_days" */
  workingDaysAggregate: Working_Days_Aggregate;
  /** fetch data from the table: "workstations" using primary key columns */
  workstationByPk?: Maybe<Workstations>;
  /** fetch data from the table: "workstation_hardware_device_connections" using primary key columns */
  workstationHardwareDeviceConnectionByPk?: Maybe<Workstation_Hardware_Device_Connections>;
  /** An array relationship */
  workstationHardwareDeviceConnections: Array<Workstation_Hardware_Device_Connections>;
  /** fetch aggregated fields from the table: "workstation_hardware_device_connections" */
  workstationHardwareDeviceConnectionsAggregate: Workstation_Hardware_Device_Connections_Aggregate;
  /** An array relationship */
  workstationPickingCategories: Array<Workstation_Picking_Categories>;
  /** fetch aggregated fields from the table: "workstation_picking_categories" */
  workstationPickingCategoriesAggregate: Workstation_Picking_Categories_Aggregate;
  /** An array relationship */
  workstations: Array<Workstations>;
  /** fetch aggregated fields from the table: "workstations" */
  workstationsAggregate: Workstations_Aggregate;
};


export type Query_RootAbacusExportByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAbacusExportsArgs = {
  distinct_on?: InputMaybe<Array<Abacus_Exports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Abacus_Exports_Order_By>>;
  where?: InputMaybe<Abacus_Exports_Bool_Exp>;
};


export type Query_RootAbacusExportsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Abacus_Exports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Abacus_Exports_Order_By>>;
  where?: InputMaybe<Abacus_Exports_Bool_Exp>;
};


export type Query_RootAccountByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Query_RootAccountsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Query_RootAggregatedDemandPlanIncomingGoodsResultsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp>;
};


export type Query_RootAggregatedDemandPlanIncomingGoodsResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp>;
};


export type Query_RootAggregatedDemandPlanOutgoingGoodsResultsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
};


export type Query_RootAggregatedDemandPlanOutgoingGoodsResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
};


export type Query_RootAggregatedDemandPlanResultsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};


export type Query_RootAggregatedDemandPlanResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};


export type Query_RootAggregatedInventoryItemsByLocationsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


export type Query_RootAggregatedInventoryItemsByLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


export type Query_RootAggregated_Demand_PlanArgs = {
  args: Aggregated_Demand_Plan_Args;
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};


export type Query_RootAggregated_Demand_Plan_AggregateArgs = {
  args: Aggregated_Demand_Plan_Args;
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};


export type Query_RootAggregated_List_Inventory_By_LocationArgs = {
  args: Aggregated_List_Inventory_By_Location_Args;
  distinct_on?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


export type Query_RootAggregated_List_Inventory_By_Location_AggregateArgs = {
  args: Aggregated_List_Inventory_By_Location_Args;
  distinct_on?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


export type Query_RootCalculateCostPriceForProductResultsArgs = {
  distinct_on?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Order_By>>;
  where?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
};


export type Query_RootCalculateCostPriceForProductResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Order_By>>;
  where?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
};


export type Query_RootCalculate_Cost_Price_For_ProductArgs = {
  args: Calculate_Cost_Price_For_Product_Args;
  distinct_on?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Order_By>>;
  where?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
};


export type Query_RootCalculate_Cost_Price_For_Product_AggregateArgs = {
  args: Calculate_Cost_Price_For_Product_Args;
  distinct_on?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Order_By>>;
  where?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
};


export type Query_RootCalculate_Cost_Price_For_Product_By_ProductArgs = {
  args: Calculate_Cost_Price_For_Product_By_Product_Args;
  distinct_on?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Order_By>>;
  where?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
};


export type Query_RootCalculate_Cost_Price_For_Product_By_Product_AggregateArgs = {
  args: Calculate_Cost_Price_For_Product_By_Product_Args;
  distinct_on?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Order_By>>;
  where?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
};


export type Query_RootCalculate_Customer_PricingArgs = {
  args: Calculate_Customer_Pricing_Args;
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Query_RootCalculate_Customer_Pricing_AggregateArgs = {
  args: Calculate_Customer_Pricing_Args;
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Query_RootChangeCustomerOrderWithTokenResultsArgs = {
  distinct_on?: InputMaybe<Array<Change_Customer_Order_With_Token_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Change_Customer_Order_With_Token_Result_Order_By>>;
  where?: InputMaybe<Change_Customer_Order_With_Token_Result_Bool_Exp>;
};


export type Query_RootChangeCustomerOrderWithTokenResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Change_Customer_Order_With_Token_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Change_Customer_Order_With_Token_Result_Order_By>>;
  where?: InputMaybe<Change_Customer_Order_With_Token_Result_Bool_Exp>;
};


export type Query_RootContactAssignedRoleByPkArgs = {
  contactId: Scalars['bigint'];
  contactRole: Contact_Roles_Enum;
};


export type Query_RootContactAssignedRolesArgs = {
  distinct_on?: InputMaybe<Array<Contact_Assigned_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Assigned_Roles_Order_By>>;
  where?: InputMaybe<Contact_Assigned_Roles_Bool_Exp>;
};


export type Query_RootContactAssignedRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Assigned_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Assigned_Roles_Order_By>>;
  where?: InputMaybe<Contact_Assigned_Roles_Bool_Exp>;
};


export type Query_RootContactAvailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<Contact_Availability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Availability_Order_By>>;
  where?: InputMaybe<Contact_Availability_Bool_Exp>;
};


export type Query_RootContactAvailabilitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Availability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Availability_Order_By>>;
  where?: InputMaybe<Contact_Availability_Bool_Exp>;
};


export type Query_RootContactAvailabilityByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootContactAvailabilityRangeByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootContactAvailabilityRangesArgs = {
  distinct_on?: InputMaybe<Array<Contact_Availability_Ranges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Availability_Ranges_Order_By>>;
  where?: InputMaybe<Contact_Availability_Ranges_Bool_Exp>;
};


export type Query_RootContactAvailabilityRangesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Availability_Ranges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Availability_Ranges_Order_By>>;
  where?: InputMaybe<Contact_Availability_Ranges_Bool_Exp>;
};


export type Query_RootContactByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootContactRoleByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootContactRolesArgs = {
  distinct_on?: InputMaybe<Array<Contact_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Roles_Order_By>>;
  where?: InputMaybe<Contact_Roles_Bool_Exp>;
};


export type Query_RootContactRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Roles_Order_By>>;
  where?: InputMaybe<Contact_Roles_Bool_Exp>;
};


export type Query_RootContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Query_RootContactsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Query_RootCostPriceAveragePeriodByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCostPriceAveragePeriodsArgs = {
  distinct_on?: InputMaybe<Array<Cost_Price_Average_Periods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cost_Price_Average_Periods_Order_By>>;
  where?: InputMaybe<Cost_Price_Average_Periods_Bool_Exp>;
};


export type Query_RootCostPriceAveragePeriodsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cost_Price_Average_Periods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cost_Price_Average_Periods_Order_By>>;
  where?: InputMaybe<Cost_Price_Average_Periods_Bool_Exp>;
};


export type Query_RootCreditNoteByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCreditNoteItemByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCreditNoteItemLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Credit_Note_Item_Logged_Actions_Bool_Exp>;
};


export type Query_RootCreditNoteItemLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Credit_Note_Item_Logged_Actions_Bool_Exp>;
};


export type Query_RootCreditNoteItemsArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Items_Order_By>>;
  where?: InputMaybe<Credit_Note_Items_Bool_Exp>;
};


export type Query_RootCreditNoteItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Items_Order_By>>;
  where?: InputMaybe<Credit_Note_Items_Bool_Exp>;
};


export type Query_RootCreditNoteLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Logged_Actions_Order_By>>;
  where?: InputMaybe<Credit_Note_Logged_Actions_Bool_Exp>;
};


export type Query_RootCreditNoteLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Logged_Actions_Order_By>>;
  where?: InputMaybe<Credit_Note_Logged_Actions_Bool_Exp>;
};


export type Query_RootCreditNoteStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootCreditNoteStateTransitionByPkArgs = {
  fromState: Credit_Note_States_Enum;
  toState: Credit_Note_States_Enum;
};


export type Query_RootCreditNoteStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_State_Transitions_Order_By>>;
  where?: InputMaybe<Credit_Note_State_Transitions_Bool_Exp>;
};


export type Query_RootCreditNoteStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_State_Transitions_Order_By>>;
  where?: InputMaybe<Credit_Note_State_Transitions_Bool_Exp>;
};


export type Query_RootCreditNoteStatesArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_States_Order_By>>;
  where?: InputMaybe<Credit_Note_States_Bool_Exp>;
};


export type Query_RootCreditNoteStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_States_Order_By>>;
  where?: InputMaybe<Credit_Note_States_Bool_Exp>;
};


export type Query_RootCreditNoteVatSummaryResultsArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Vat_Summary_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Vat_Summary_Results_Order_By>>;
  where?: InputMaybe<Credit_Note_Vat_Summary_Results_Bool_Exp>;
};


export type Query_RootCreditNoteVatSummaryResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Vat_Summary_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Vat_Summary_Results_Order_By>>;
  where?: InputMaybe<Credit_Note_Vat_Summary_Results_Bool_Exp>;
};


export type Query_RootCreditNotesArgs = {
  distinct_on?: InputMaybe<Array<Credit_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Notes_Order_By>>;
  where?: InputMaybe<Credit_Notes_Bool_Exp>;
};


export type Query_RootCreditNotesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Notes_Order_By>>;
  where?: InputMaybe<Credit_Notes_Bool_Exp>;
};


export type Query_RootCurrenciesArgs = {
  distinct_on?: InputMaybe<Array<Currencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currencies_Order_By>>;
  where?: InputMaybe<Currencies_Bool_Exp>;
};


export type Query_RootCurrenciesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Currencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currencies_Order_By>>;
  where?: InputMaybe<Currencies_Bool_Exp>;
};


export type Query_RootCurrencyByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootCustomerAddressByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerAddressesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Addresses_Order_By>>;
  where?: InputMaybe<Customer_Addresses_Bool_Exp>;
};


export type Query_RootCustomerAddressesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Addresses_Order_By>>;
  where?: InputMaybe<Customer_Addresses_Bool_Exp>;
};


export type Query_RootCustomerBillingFormatByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootCustomerBillingFormatsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Format_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Format_Order_By>>;
  where?: InputMaybe<Customer_Billing_Format_Bool_Exp>;
};


export type Query_RootCustomerBillingFormatsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Format_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Format_Order_By>>;
  where?: InputMaybe<Customer_Billing_Format_Bool_Exp>;
};


export type Query_RootCustomerBillingFrequenciesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Frequency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Frequency_Order_By>>;
  where?: InputMaybe<Customer_Billing_Frequency_Bool_Exp>;
};


export type Query_RootCustomerBillingFrequenciesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Frequency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Frequency_Order_By>>;
  where?: InputMaybe<Customer_Billing_Frequency_Bool_Exp>;
};


export type Query_RootCustomerBillingFrequencyByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootCustomerBillingSendStrategiesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Send_Strategies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Send_Strategies_Order_By>>;
  where?: InputMaybe<Customer_Billing_Send_Strategies_Bool_Exp>;
};


export type Query_RootCustomerBillingSendStrategiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Send_Strategies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Send_Strategies_Order_By>>;
  where?: InputMaybe<Customer_Billing_Send_Strategies_Bool_Exp>;
};


export type Query_RootCustomerBillingSendStrategyByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootCustomerBillingSettingByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerBillingSettingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Settings_Order_By>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};


export type Query_RootCustomerBillingSettingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Settings_Order_By>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};


export type Query_RootCustomerByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerDeliveryAvailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Delivery_Availability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Delivery_Availability_Order_By>>;
  where?: InputMaybe<Customer_Delivery_Availability_Bool_Exp>;
};


export type Query_RootCustomerDeliveryAvailabilitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Delivery_Availability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Delivery_Availability_Order_By>>;
  where?: InputMaybe<Customer_Delivery_Availability_Bool_Exp>;
};


export type Query_RootCustomerDeliveryAvailabilityByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerDeliveryNoteFormatByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootCustomerDeliveryNoteFormatsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Delivery_Note_Formats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Delivery_Note_Formats_Order_By>>;
  where?: InputMaybe<Customer_Delivery_Note_Formats_Bool_Exp>;
};


export type Query_RootCustomerDeliveryNoteFormatsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Delivery_Note_Formats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Delivery_Note_Formats_Order_By>>;
  where?: InputMaybe<Customer_Delivery_Note_Formats_Bool_Exp>;
};


export type Query_RootCustomerGroupByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerGroupPricingByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerGroupPricingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Query_RootCustomerGroupPricingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Query_RootCustomerGroupStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootCustomerGroupStateTransitionByPkArgs = {
  fromState: Customer_Group_States_Enum;
  toState: Customer_Group_States_Enum;
};


export type Query_RootCustomerGroupStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_Group_State_Transitions_Bool_Exp>;
};


export type Query_RootCustomerGroupStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_Group_State_Transitions_Bool_Exp>;
};


export type Query_RootCustomerGroupStatesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_States_Order_By>>;
  where?: InputMaybe<Customer_Group_States_Bool_Exp>;
};


export type Query_RootCustomerGroupStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_States_Order_By>>;
  where?: InputMaybe<Customer_Group_States_Bool_Exp>;
};


export type Query_RootCustomerGroupsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Groups_Order_By>>;
  where?: InputMaybe<Customer_Groups_Bool_Exp>;
};


export type Query_RootCustomerGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Groups_Order_By>>;
  where?: InputMaybe<Customer_Groups_Bool_Exp>;
};


export type Query_RootCustomerInvoiceByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerInvoiceLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Logged_Actions_Bool_Exp>;
};


export type Query_RootCustomerInvoiceLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Logged_Actions_Bool_Exp>;
};


export type Query_RootCustomerInvoiceOrderByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerInvoiceOrdersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Orders_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Orders_Bool_Exp>;
};


export type Query_RootCustomerInvoiceOrdersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Orders_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Orders_Bool_Exp>;
};


export type Query_RootCustomerInvoiceStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootCustomerInvoiceStateTransitionByPkArgs = {
  fromState: Customer_Invoice_States_Enum;
  toState: Customer_Invoice_States_Enum;
};


export type Query_RootCustomerInvoiceStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_State_Transitions_Bool_Exp>;
};


export type Query_RootCustomerInvoiceStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_State_Transitions_Bool_Exp>;
};


export type Query_RootCustomerInvoiceStatesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_States_Order_By>>;
  where?: InputMaybe<Customer_Invoice_States_Bool_Exp>;
};


export type Query_RootCustomerInvoiceStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_States_Order_By>>;
  where?: InputMaybe<Customer_Invoice_States_Bool_Exp>;
};


export type Query_RootCustomerInvoiceVatSummaryResultsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Vat_Summary_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Vat_Summary_Results_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Vat_Summary_Results_Bool_Exp>;
};


export type Query_RootCustomerInvoiceVatSummaryResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Vat_Summary_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Vat_Summary_Results_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Vat_Summary_Results_Bool_Exp>;
};


export type Query_RootCustomerInvoiceVersionByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerInvoiceVersionLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Version_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Version_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Bool_Exp>;
};


export type Query_RootCustomerInvoiceVersionLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Version_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Version_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Bool_Exp>;
};


export type Query_RootCustomerInvoiceVersionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Versions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
};


export type Query_RootCustomerInvoiceVersionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Versions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
};


export type Query_RootCustomerInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoices_Order_By>>;
  where?: InputMaybe<Customer_Invoices_Bool_Exp>;
};


export type Query_RootCustomerInvoicesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoices_Order_By>>;
  where?: InputMaybe<Customer_Invoices_Bool_Exp>;
};


export type Query_RootCustomerLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Logged_Actions_Bool_Exp>;
};


export type Query_RootCustomerLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Logged_Actions_Bool_Exp>;
};


export type Query_RootCustomerOrderByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerOrderContainerReturnByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerOrderContainerReturnItemByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerOrderContainerReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Return_Items_Bool_Exp>;
};


export type Query_RootCustomerOrderContainerReturnItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Return_Items_Bool_Exp>;
};


export type Query_RootCustomerOrderContainerReturnsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Returns_Bool_Exp>;
};


export type Query_RootCustomerOrderContainerReturnsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Returns_Bool_Exp>;
};


export type Query_RootCustomerOrderItemAdjustmentByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerOrderItemAdjustmentLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustment_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustment_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustment_Logged_Actions_Bool_Exp>;
};


export type Query_RootCustomerOrderItemAdjustmentLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustment_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustment_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustment_Logged_Actions_Bool_Exp>;
};


export type Query_RootCustomerOrderItemAdjustmentTypeByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootCustomerOrderItemAdjustmentTypesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustment_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustment_Types_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustment_Types_Bool_Exp>;
};


export type Query_RootCustomerOrderItemAdjustmentTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustment_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustment_Types_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustment_Types_Bool_Exp>;
};


export type Query_RootCustomerOrderItemAdjustmentsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustments_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
};


export type Query_RootCustomerOrderItemAdjustmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustments_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
};


export type Query_RootCustomerOrderItemByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerOrderItemContainerExpectationByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerOrderItemContainerExpectationsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Container_Expectations_Bool_Exp>;
};


export type Query_RootCustomerOrderItemContainerExpectationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Container_Expectations_Bool_Exp>;
};


export type Query_RootCustomerOrderItemExtrasArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Extras_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Extras_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Extras_Bool_Exp>;
};


export type Query_RootCustomerOrderItemExtrasAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Extras_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Extras_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Extras_Bool_Exp>;
};


export type Query_RootCustomerOrderItemFulfillmentPlanByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerOrderItemFulfillmentPlansArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
};


export type Query_RootCustomerOrderItemFulfillmentPlansAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
};


export type Query_RootCustomerOrderItemLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Logged_Actions_Bool_Exp>;
};


export type Query_RootCustomerOrderItemLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Logged_Actions_Bool_Exp>;
};


export type Query_RootCustomerOrderItemReturnCauseByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerOrderItemReturnCausesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Return_Causes_Bool_Exp>;
};


export type Query_RootCustomerOrderItemReturnCausesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Return_Causes_Bool_Exp>;
};


export type Query_RootCustomerOrderItemStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootCustomerOrderItemStateTransitionByPkArgs = {
  fromState: Customer_Order_Item_States_Enum;
  toState: Customer_Order_Item_States_Enum;
};


export type Query_RootCustomerOrderItemStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_State_Transitions_Bool_Exp>;
};


export type Query_RootCustomerOrderItemStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_State_Transitions_Bool_Exp>;
};


export type Query_RootCustomerOrderItemStatesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_States_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_States_Bool_Exp>;
};


export type Query_RootCustomerOrderItemStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_States_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_States_Bool_Exp>;
};


export type Query_RootCustomerOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


export type Query_RootCustomerOrderItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


export type Query_RootCustomerOrderLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Order_Logged_Actions_Bool_Exp>;
};


export type Query_RootCustomerOrderLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Order_Logged_Actions_Bool_Exp>;
};


export type Query_RootCustomerOrderPickedContainerByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerOrderPickedContainerStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootCustomerOrderPickedContainerStateTransitionByPkArgs = {
  fromState: Customer_Order_Picked_Container_States_Enum;
  toState: Customer_Order_Picked_Container_States_Enum;
};


export type Query_RootCustomerOrderPickedContainerStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_State_Transitions_Bool_Exp>;
};


export type Query_RootCustomerOrderPickedContainerStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_State_Transitions_Bool_Exp>;
};


export type Query_RootCustomerOrderPickedContainerStatesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_States_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_States_Bool_Exp>;
};


export type Query_RootCustomerOrderPickedContainerStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_States_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_States_Bool_Exp>;
};


export type Query_RootCustomerOrderPickedContainersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
};


export type Query_RootCustomerOrderPickedContainersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
};


export type Query_RootCustomerOrderReturnByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerOrderReturnItemByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerOrderReturnItemContainerByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerOrderReturnItemContainersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Item_Containers_Bool_Exp>;
};


export type Query_RootCustomerOrderReturnItemContainersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Item_Containers_Bool_Exp>;
};


export type Query_RootCustomerOrderReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


export type Query_RootCustomerOrderReturnItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


export type Query_RootCustomerOrderReturnStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootCustomerOrderReturnStatesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_States_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_States_Bool_Exp>;
};


export type Query_RootCustomerOrderReturnStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_States_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_States_Bool_Exp>;
};


export type Query_RootCustomerOrderReturnsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
};


export type Query_RootCustomerOrderReturnsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
};


export type Query_RootCustomerOrderStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootCustomerOrderStateTransitionByPkArgs = {
  fromState: Customer_Order_States_Enum;
  toState: Customer_Order_States_Enum;
};


export type Query_RootCustomerOrderStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_Order_State_Transitions_Bool_Exp>;
};


export type Query_RootCustomerOrderStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_Order_State_Transitions_Bool_Exp>;
};


export type Query_RootCustomerOrderStatesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_States_Order_By>>;
  where?: InputMaybe<Customer_Order_States_Bool_Exp>;
};


export type Query_RootCustomerOrderStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_States_Order_By>>;
  where?: InputMaybe<Customer_Order_States_Bool_Exp>;
};


export type Query_RootCustomerOrderStockReservationsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Stock_Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Stock_Reservations_Order_By>>;
  where?: InputMaybe<Customer_Order_Stock_Reservations_Bool_Exp>;
};


export type Query_RootCustomerOrderStockReservationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Stock_Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Stock_Reservations_Order_By>>;
  where?: InputMaybe<Customer_Order_Stock_Reservations_Bool_Exp>;
};


export type Query_RootCustomerOrdersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


export type Query_RootCustomerOrdersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


export type Query_RootCustomerPaymentMethodByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootCustomerPaymentMethodsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Payment_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Payment_Method_Order_By>>;
  where?: InputMaybe<Customer_Payment_Method_Bool_Exp>;
};


export type Query_RootCustomerPaymentMethodsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Payment_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Payment_Method_Order_By>>;
  where?: InputMaybe<Customer_Payment_Method_Bool_Exp>;
};


export type Query_RootCustomerPaymentTermByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerPaymentTermsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Payment_Terms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Payment_Terms_Order_By>>;
  where?: InputMaybe<Customer_Payment_Terms_Bool_Exp>;
};


export type Query_RootCustomerPaymentTermsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Payment_Terms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Payment_Terms_Order_By>>;
  where?: InputMaybe<Customer_Payment_Terms_Bool_Exp>;
};


export type Query_RootCustomerProductAvailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Product_Availabilities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Product_Availabilities_Order_By>>;
  where?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
};


export type Query_RootCustomerProductAvailabilitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Product_Availabilities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Product_Availabilities_Order_By>>;
  where?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
};


export type Query_RootCustomerProductAvailabilityByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootCustomerStateTransitionByPkArgs = {
  fromState: Customer_States_Enum;
  toState: Customer_States_Enum;
};


export type Query_RootCustomerStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_State_Transitions_Bool_Exp>;
};


export type Query_RootCustomerStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_State_Transitions_Bool_Exp>;
};


export type Query_RootCustomerStatesArgs = {
  distinct_on?: InputMaybe<Array<Customer_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_States_Order_By>>;
  where?: InputMaybe<Customer_States_Bool_Exp>;
};


export type Query_RootCustomerStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_States_Order_By>>;
  where?: InputMaybe<Customer_States_Bool_Exp>;
};


export type Query_RootCustomerTemplateSettingByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCustomerTemplateSettingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Template_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Template_Settings_Order_By>>;
  where?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
};


export type Query_RootCustomerTemplateSettingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Template_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Template_Settings_Order_By>>;
  where?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
};


export type Query_RootCustomer_Group_Pricing_Within_RangeArgs = {
  args: Customer_Group_Pricing_Within_Range_Args;
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Query_RootCustomer_Group_Pricing_Within_Range_AggregateArgs = {
  args: Customer_Group_Pricing_Within_Range_Args;
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Query_RootCustomer_Payment_Term_SuggestionsArgs = {
  args: Customer_Payment_Term_Suggestions_Args;
  distinct_on?: InputMaybe<Array<Customer_Payment_Terms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Payment_Terms_Order_By>>;
  where?: InputMaybe<Customer_Payment_Terms_Bool_Exp>;
};


export type Query_RootCustomer_Payment_Term_Suggestions_AggregateArgs = {
  args: Customer_Payment_Term_Suggestions_Args;
  distinct_on?: InputMaybe<Array<Customer_Payment_Terms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Payment_Terms_Order_By>>;
  where?: InputMaybe<Customer_Payment_Terms_Bool_Exp>;
};


export type Query_RootCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


export type Query_RootCustomersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


export type Query_RootCuttingPatternByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCuttingPatternDraftActionByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootCuttingPatternDraftActionsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Draft_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Draft_Actions_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Draft_Actions_Bool_Exp>;
};


export type Query_RootCuttingPatternDraftActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Draft_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Draft_Actions_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Draft_Actions_Bool_Exp>;
};


export type Query_RootCuttingPatternDraftStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootCuttingPatternDraftStateTransitionByPkArgs = {
  fromState: Cutting_Pattern_Draft_States_Enum;
  toState: Cutting_Pattern_Draft_States_Enum;
};


export type Query_RootCuttingPatternDraftStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Draft_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Draft_State_Transitions_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Draft_State_Transitions_Bool_Exp>;
};


export type Query_RootCuttingPatternDraftStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Draft_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Draft_State_Transitions_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Draft_State_Transitions_Bool_Exp>;
};


export type Query_RootCuttingPatternDraftStatesArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Draft_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Draft_States_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Draft_States_Bool_Exp>;
};


export type Query_RootCuttingPatternDraftStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Draft_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Draft_States_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Draft_States_Bool_Exp>;
};


export type Query_RootCuttingPatternLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Logged_Actions_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Logged_Actions_Bool_Exp>;
};


export type Query_RootCuttingPatternLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Logged_Actions_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Logged_Actions_Bool_Exp>;
};


export type Query_RootCuttingPatternOutputByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCuttingPatternOutputLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Output_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Output_Logged_Actions_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Output_Logged_Actions_Bool_Exp>;
};


export type Query_RootCuttingPatternOutputLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Output_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Output_Logged_Actions_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Output_Logged_Actions_Bool_Exp>;
};


export type Query_RootCuttingPatternOutputsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
};


export type Query_RootCuttingPatternOutputsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
};


export type Query_RootCuttingPatternOutputsDraftByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCuttingPatternOutputsDraftsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
};


export type Query_RootCuttingPatternOutputsDraftsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
};


export type Query_RootCuttingPatternStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootCuttingPatternStateTransitionByPkArgs = {
  fromState: Cutting_Pattern_States_Enum;
  toState: Cutting_Pattern_States_Enum;
};


export type Query_RootCuttingPatternStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_State_Transitions_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_State_Transitions_Bool_Exp>;
};


export type Query_RootCuttingPatternStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_State_Transitions_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_State_Transitions_Bool_Exp>;
};


export type Query_RootCuttingPatternStatesArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_States_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_States_Bool_Exp>;
};


export type Query_RootCuttingPatternStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_States_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_States_Bool_Exp>;
};


export type Query_RootCuttingPatternsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Bool_Exp>;
};


export type Query_RootCuttingPatternsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Bool_Exp>;
};


export type Query_RootCuttingPatternsDraftByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCuttingPatternsDraftsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


export type Query_RootCuttingPatternsDraftsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


export type Query_RootDailyPriceListsArgs = {
  distinct_on?: InputMaybe<Array<Daily_Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Price_Lists_Order_By>>;
  where?: InputMaybe<Daily_Price_Lists_Bool_Exp>;
};


export type Query_RootDailyPriceListsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Price_Lists_Order_By>>;
  where?: InputMaybe<Daily_Price_Lists_Bool_Exp>;
};


export type Query_RootDemandsForCoiResultsArgs = {
  distinct_on?: InputMaybe<Array<Demands_For_Coi_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Demands_For_Coi_Results_Order_By>>;
  where?: InputMaybe<Demands_For_Coi_Results_Bool_Exp>;
};


export type Query_RootDemandsForCoiResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Demands_For_Coi_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Demands_For_Coi_Results_Order_By>>;
  where?: InputMaybe<Demands_For_Coi_Results_Bool_Exp>;
};


export type Query_RootDismantleableProductsResultsArgs = {
  distinct_on?: InputMaybe<Array<Dismantleable_Products_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantleable_Products_Results_Order_By>>;
  where?: InputMaybe<Dismantleable_Products_Results_Bool_Exp>;
};


export type Query_RootDismantleableProductsResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantleable_Products_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantleable_Products_Results_Order_By>>;
  where?: InputMaybe<Dismantleable_Products_Results_Bool_Exp>;
};


export type Query_RootDismantleable_ProductsArgs = {
  args: Dismantleable_Products_Args;
  distinct_on?: InputMaybe<Array<Dismantleable_Products_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantleable_Products_Results_Order_By>>;
  where?: InputMaybe<Dismantleable_Products_Results_Bool_Exp>;
};


export type Query_RootDismantleable_Products_AggregateArgs = {
  args: Dismantleable_Products_Args;
  distinct_on?: InputMaybe<Array<Dismantleable_Products_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantleable_Products_Results_Order_By>>;
  where?: InputMaybe<Dismantleable_Products_Results_Bool_Exp>;
};


export type Query_RootDismantlingOutputWeightResultsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Output_Weight_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Output_Weight_Results_Order_By>>;
  where?: InputMaybe<Dismantling_Output_Weight_Results_Bool_Exp>;
};


export type Query_RootDismantlingOutputWeightResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Output_Weight_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Output_Weight_Results_Order_By>>;
  where?: InputMaybe<Dismantling_Output_Weight_Results_Bool_Exp>;
};


export type Query_RootDismantlingPlanByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDismantlingPlanCuttingPatternChoiceByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDismantlingPlanCuttingPatternChoicesArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>;
};


export type Query_RootDismantlingPlanCuttingPatternChoicesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>;
};


export type Query_RootDismantlingPlanDemandByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDismantlingPlanDemandsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Demands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Demands_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
};


export type Query_RootDismantlingPlanDemandsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Demands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Demands_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
};


export type Query_RootDismantlingPlanInstructionByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDismantlingPlanInstructionsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Instructions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Instructions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Instructions_Bool_Exp>;
};


export type Query_RootDismantlingPlanInstructionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Instructions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Instructions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Instructions_Bool_Exp>;
};


export type Query_RootDismantlingPlanLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Logged_Actions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Logged_Actions_Bool_Exp>;
};


export type Query_RootDismantlingPlanLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Logged_Actions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Logged_Actions_Bool_Exp>;
};


export type Query_RootDismantlingPlanPrioritiesArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Priorities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Priorities_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Priorities_Bool_Exp>;
};


export type Query_RootDismantlingPlanPrioritiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Priorities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Priorities_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Priorities_Bool_Exp>;
};


export type Query_RootDismantlingPlanPriorityByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootDismantlingPlanRemainingPieceByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDismantlingPlanRemainingPiecesArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
};


export type Query_RootDismantlingPlanRemainingPiecesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
};


export type Query_RootDismantlingPlanStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootDismantlingPlanStateTransitionByPkArgs = {
  fromState: Dismantling_Plan_States_Enum;
  toState: Dismantling_Plan_States_Enum;
};


export type Query_RootDismantlingPlanStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_State_Transitions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_State_Transitions_Bool_Exp>;
};


export type Query_RootDismantlingPlanStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_State_Transitions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_State_Transitions_Bool_Exp>;
};


export type Query_RootDismantlingPlanStatesArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_States_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_States_Bool_Exp>;
};


export type Query_RootDismantlingPlanStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_States_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_States_Bool_Exp>;
};


export type Query_RootDismantlingPlansArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plans_Order_By>>;
  where?: InputMaybe<Dismantling_Plans_Bool_Exp>;
};


export type Query_RootDismantlingPlansAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plans_Order_By>>;
  where?: InputMaybe<Dismantling_Plans_Bool_Exp>;
};


export type Query_RootDismantling_Plan_InventoryArgs = {
  args: Dismantling_Plan_Inventory_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Query_RootDismantling_Plan_Inventory_AggregateArgs = {
  args: Dismantling_Plan_Inventory_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Query_RootDismantling_Plan_Inventory_Candidate_SearchArgs = {
  args: Dismantling_Plan_Inventory_Candidate_Search_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


export type Query_RootDismantling_Plan_Inventory_Candidate_Search_AggregateArgs = {
  args: Dismantling_Plan_Inventory_Candidate_Search_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


export type Query_RootEdiDeliveryNoteTypeByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEdiDeliveryNoteTypesArgs = {
  distinct_on?: InputMaybe<Array<Edi_Delivery_Note_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Edi_Delivery_Note_Types_Order_By>>;
  where?: InputMaybe<Edi_Delivery_Note_Types_Bool_Exp>;
};


export type Query_RootEdiDeliveryNoteTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Edi_Delivery_Note_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Edi_Delivery_Note_Types_Order_By>>;
  where?: InputMaybe<Edi_Delivery_Note_Types_Bool_Exp>;
};


export type Query_RootEdiInvoiceTypeByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEdiInvoiceTypesArgs = {
  distinct_on?: InputMaybe<Array<Edi_Invoice_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Edi_Invoice_Types_Order_By>>;
  where?: InputMaybe<Edi_Invoice_Types_Bool_Exp>;
};


export type Query_RootEdiInvoiceTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Edi_Invoice_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Edi_Invoice_Types_Order_By>>;
  where?: InputMaybe<Edi_Invoice_Types_Bool_Exp>;
};


export type Query_RootEmailByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEmailsArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


export type Query_RootEmailsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


export type Query_RootEnvironmentStateByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEnvironmentStatesArgs = {
  distinct_on?: InputMaybe<Array<Environment_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Environment_State_Order_By>>;
  where?: InputMaybe<Environment_State_Bool_Exp>;
};


export type Query_RootEnvironmentStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Environment_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Environment_State_Order_By>>;
  where?: InputMaybe<Environment_State_Bool_Exp>;
};


export type Query_RootExchangeRateByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootExchangeRatesArgs = {
  distinct_on?: InputMaybe<Array<Exchange_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Rates_Order_By>>;
  where?: InputMaybe<Exchange_Rates_Bool_Exp>;
};


export type Query_RootExchangeRatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Exchange_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Rates_Order_By>>;
  where?: InputMaybe<Exchange_Rates_Bool_Exp>;
};


export type Query_RootExchange_Rates_For_DayArgs = {
  args: Exchange_Rates_For_Day_Args;
  distinct_on?: InputMaybe<Array<Exchange_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Rates_Order_By>>;
  where?: InputMaybe<Exchange_Rates_Bool_Exp>;
};


export type Query_RootExchange_Rates_For_Day_AggregateArgs = {
  args: Exchange_Rates_For_Day_Args;
  distinct_on?: InputMaybe<Array<Exchange_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Rates_Order_By>>;
  where?: InputMaybe<Exchange_Rates_Bool_Exp>;
};


export type Query_RootExternalErrorByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootExternalErrorsArgs = {
  distinct_on?: InputMaybe<Array<External_Errors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<External_Errors_Order_By>>;
  where?: InputMaybe<External_Errors_Bool_Exp>;
};


export type Query_RootExternalErrorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<External_Errors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<External_Errors_Order_By>>;
  where?: InputMaybe<External_Errors_Bool_Exp>;
};


export type Query_RootFramework_Agreements_Within_RangeArgs = {
  args: Framework_Agreements_Within_Range_Args;
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


export type Query_RootFramework_Agreements_Within_Range_AggregateArgs = {
  args: Framework_Agreements_Within_Range_Args;
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


export type Query_RootFullWorkCalendarsArgs = {
  distinct_on?: InputMaybe<Array<Full_Work_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Full_Work_Calendar_Order_By>>;
  where?: InputMaybe<Full_Work_Calendar_Bool_Exp>;
};


export type Query_RootFullWorkCalendarsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Full_Work_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Full_Work_Calendar_Order_By>>;
  where?: InputMaybe<Full_Work_Calendar_Bool_Exp>;
};


export type Query_RootGenderByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootGendersArgs = {
  distinct_on?: InputMaybe<Array<Gender_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gender_Order_By>>;
  where?: InputMaybe<Gender_Bool_Exp>;
};


export type Query_RootGendersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Gender_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gender_Order_By>>;
  where?: InputMaybe<Gender_Bool_Exp>;
};


export type Query_RootGeneratePresignedUrlResultsArgs = {
  distinct_on?: InputMaybe<Array<Generate_Presigned_Url_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Generate_Presigned_Url_Result_Order_By>>;
  where?: InputMaybe<Generate_Presigned_Url_Result_Bool_Exp>;
};


export type Query_RootGeneratePresignedUrlResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Generate_Presigned_Url_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Generate_Presigned_Url_Result_Order_By>>;
  where?: InputMaybe<Generate_Presigned_Url_Result_Bool_Exp>;
};


export type Query_RootGet_Latest_Cutting_Patterns_DraftsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


export type Query_RootGet_Latest_Cutting_Patterns_Drafts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


export type Query_RootGet_Public_Tenant_InfoArgs = {
  args: Get_Public_Tenant_Info_Args;
  distinct_on?: InputMaybe<Array<Public_Tenant_Info_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Tenant_Info_Result_Order_By>>;
  where?: InputMaybe<Public_Tenant_Info_Result_Bool_Exp>;
};


export type Query_RootGet_Public_Tenant_Info_AggregateArgs = {
  args: Get_Public_Tenant_Info_Args;
  distinct_on?: InputMaybe<Array<Public_Tenant_Info_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Tenant_Info_Result_Order_By>>;
  where?: InputMaybe<Public_Tenant_Info_Result_Bool_Exp>;
};


export type Query_RootGet_Tenant_Info_With_TokenArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Info_With_Token_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Info_With_Token_Result_Order_By>>;
  where?: InputMaybe<Tenant_Info_With_Token_Result_Bool_Exp>;
};


export type Query_RootGet_Tenant_Info_With_Token_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Info_With_Token_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Info_With_Token_Result_Order_By>>;
  where?: InputMaybe<Tenant_Info_With_Token_Result_Bool_Exp>;
};


export type Query_RootGlobalSearchResultsArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


export type Query_RootGlobalSearchResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


export type Query_RootGlobal_SearchArgs = {
  args: Global_Search_Args;
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


export type Query_RootGlobal_Search_AggregateArgs = {
  args: Global_Search_Args;
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


export type Query_RootGs1LogisticTypeByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootGs1LogisticTypesArgs = {
  distinct_on?: InputMaybe<Array<Gs1_Logistic_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gs1_Logistic_Types_Order_By>>;
  where?: InputMaybe<Gs1_Logistic_Types_Bool_Exp>;
};


export type Query_RootGs1LogisticTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Gs1_Logistic_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gs1_Logistic_Types_Order_By>>;
  where?: InputMaybe<Gs1_Logistic_Types_Bool_Exp>;
};


export type Query_RootHardwareDeviceByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootHardwareDeviceConnectionTypeByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootHardwareDeviceConnectionTypesArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Device_Connection_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Device_Connection_Types_Order_By>>;
  where?: InputMaybe<Hardware_Device_Connection_Types_Bool_Exp>;
};


export type Query_RootHardwareDeviceConnectionTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Device_Connection_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Device_Connection_Types_Order_By>>;
  where?: InputMaybe<Hardware_Device_Connection_Types_Bool_Exp>;
};


export type Query_RootHardwareDeviceTypeByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootHardwareDeviceTypesArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Device_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Device_Types_Order_By>>;
  where?: InputMaybe<Hardware_Device_Types_Bool_Exp>;
};


export type Query_RootHardwareDeviceTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Device_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Device_Types_Order_By>>;
  where?: InputMaybe<Hardware_Device_Types_Bool_Exp>;
};


export type Query_RootHardwareDevicesArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Devices_Order_By>>;
  where?: InputMaybe<Hardware_Devices_Bool_Exp>;
};


export type Query_RootHardwareDevicesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Devices_Order_By>>;
  where?: InputMaybe<Hardware_Devices_Bool_Exp>;
};


export type Query_RootHierarchicalGoodsIncomeByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootHierarchicalGoodsIncomesArgs = {
  distinct_on?: InputMaybe<Array<Hierarchical_Goods_Incomes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hierarchical_Goods_Incomes_Order_By>>;
  where?: InputMaybe<Hierarchical_Goods_Incomes_Bool_Exp>;
};


export type Query_RootHierarchicalGoodsIncomesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Hierarchical_Goods_Incomes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hierarchical_Goods_Incomes_Order_By>>;
  where?: InputMaybe<Hierarchical_Goods_Incomes_Bool_Exp>;
};


export type Query_RootImageByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootImagesArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Query_RootImagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Query_RootInventoryDailyPrognosisResultsArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
};


export type Query_RootInventoryDailyPrognosisResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
};


export type Query_RootInventoryDailyUsageResultsArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Usage_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Usage_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Usage_Results_Bool_Exp>;
};


export type Query_RootInventoryDailyUsageResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Usage_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Usage_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Usage_Results_Bool_Exp>;
};


export type Query_RootInventoryItemsArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Query_RootInventoryItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Query_RootInventoryItemsByLocationsArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


export type Query_RootInventoryItemsByLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


export type Query_RootInventory_Daily_PrognosisArgs = {
  args: Inventory_Daily_Prognosis_Args;
  distinct_on?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
};


export type Query_RootInventory_Daily_Prognosis_AggregateArgs = {
  args: Inventory_Daily_Prognosis_Args;
  distinct_on?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
};


export type Query_RootJournalEntriesArgs = {
  distinct_on?: InputMaybe<Array<Journal_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journal_Entries_Order_By>>;
  where?: InputMaybe<Journal_Entries_Bool_Exp>;
};


export type Query_RootJournalEntriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Journal_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journal_Entries_Order_By>>;
  where?: InputMaybe<Journal_Entries_Bool_Exp>;
};


export type Query_RootJournalEntryByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootJscriptTemplateByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootJscriptTemplateNameByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootJscriptTemplateNamesArgs = {
  distinct_on?: InputMaybe<Array<Jscript_Template_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jscript_Template_Names_Order_By>>;
  where?: InputMaybe<Jscript_Template_Names_Bool_Exp>;
};


export type Query_RootJscriptTemplateNamesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Jscript_Template_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jscript_Template_Names_Order_By>>;
  where?: InputMaybe<Jscript_Template_Names_Bool_Exp>;
};


export type Query_RootJscriptTemplatesArgs = {
  distinct_on?: InputMaybe<Array<Jscript_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jscript_Templates_Order_By>>;
  where?: InputMaybe<Jscript_Templates_Bool_Exp>;
};


export type Query_RootJscriptTemplatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Jscript_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jscript_Templates_Order_By>>;
  where?: InputMaybe<Jscript_Templates_Bool_Exp>;
};


export type Query_RootJscriptTemplatesLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Jscript_Templates_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jscript_Templates_Logged_Actions_Order_By>>;
  where?: InputMaybe<Jscript_Templates_Logged_Actions_Bool_Exp>;
};


export type Query_RootJscriptTemplatesLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Jscript_Templates_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jscript_Templates_Logged_Actions_Order_By>>;
  where?: InputMaybe<Jscript_Templates_Logged_Actions_Bool_Exp>;
};


export type Query_RootLanguageByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootLanguagesArgs = {
  distinct_on?: InputMaybe<Array<Languages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Languages_Order_By>>;
  where?: InputMaybe<Languages_Bool_Exp>;
};


export type Query_RootLanguagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Languages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Languages_Order_By>>;
  where?: InputMaybe<Languages_Bool_Exp>;
};


export type Query_RootLeichmehlTemplateByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootLeichmehlTemplateTypeByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootLeichmehlTemplateTypesArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Template_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Template_Types_Order_By>>;
  where?: InputMaybe<Leichmehl_Template_Types_Bool_Exp>;
};


export type Query_RootLeichmehlTemplateTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Template_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Template_Types_Order_By>>;
  where?: InputMaybe<Leichmehl_Template_Types_Bool_Exp>;
};


export type Query_RootLeichmehlTemplateTypesLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Template_Types_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Template_Types_Logged_Actions_Order_By>>;
  where?: InputMaybe<Leichmehl_Template_Types_Logged_Actions_Bool_Exp>;
};


export type Query_RootLeichmehlTemplateTypesLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Template_Types_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Template_Types_Logged_Actions_Order_By>>;
  where?: InputMaybe<Leichmehl_Template_Types_Logged_Actions_Bool_Exp>;
};


export type Query_RootLeichmehlTemplatesArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Templates_Order_By>>;
  where?: InputMaybe<Leichmehl_Templates_Bool_Exp>;
};


export type Query_RootLeichmehlTemplatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Templates_Order_By>>;
  where?: InputMaybe<Leichmehl_Templates_Bool_Exp>;
};


export type Query_RootLeichmehlTemplatesLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Templates_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Templates_Logged_Actions_Order_By>>;
  where?: InputMaybe<Leichmehl_Templates_Logged_Actions_Bool_Exp>;
};


export type Query_RootLeichmehlTemplatesLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Templates_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Templates_Logged_Actions_Order_By>>;
  where?: InputMaybe<Leichmehl_Templates_Logged_Actions_Bool_Exp>;
};


export type Query_RootListPurchasedProductsResultsArgs = {
  distinct_on?: InputMaybe<Array<List_Purchased_Products_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Purchased_Products_Result_Order_By>>;
  where?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
};


export type Query_RootListPurchasedProductsResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Purchased_Products_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Purchased_Products_Result_Order_By>>;
  where?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
};


export type Query_RootList_Container_Inventory_By_CustomerArgs = {
  args: List_Container_Inventory_By_Customer_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Query_RootList_Container_Inventory_By_Customer_AggregateArgs = {
  args: List_Container_Inventory_By_Customer_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Query_RootList_InventoryArgs = {
  args: List_Inventory_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Query_RootList_Inventory_AggregateArgs = {
  args: List_Inventory_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Query_RootList_Inventory_By_LocationArgs = {
  args: List_Inventory_By_Location_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


export type Query_RootList_Inventory_By_Location_AggregateArgs = {
  args: List_Inventory_By_Location_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


export type Query_RootList_Inventory_By_Location_With_Product_SearchArgs = {
  args: List_Inventory_By_Location_With_Product_Search_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


export type Query_RootList_Inventory_By_Location_With_Product_Search_AggregateArgs = {
  args: List_Inventory_By_Location_With_Product_Search_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


export type Query_RootList_Ordered_Customer_Order_ContainersArgs = {
  args: List_Ordered_Customer_Order_Containers_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Query_RootList_Ordered_Customer_Order_Containers_AggregateArgs = {
  args: List_Ordered_Customer_Order_Containers_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Query_RootList_Purchased_Products_With_InventoryArgs = {
  args: List_Purchased_Products_With_Inventory_Args;
  distinct_on?: InputMaybe<Array<List_Purchased_Products_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Purchased_Products_Result_Order_By>>;
  where?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
};


export type Query_RootList_Purchased_Products_With_Inventory_AggregateArgs = {
  args: List_Purchased_Products_With_Inventory_Args;
  distinct_on?: InputMaybe<Array<List_Purchased_Products_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Purchased_Products_Result_Order_By>>;
  where?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
};


export type Query_RootLotNumberByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootLotNumberProductByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootLotNumberProductsArgs = {
  distinct_on?: InputMaybe<Array<Lot_Number_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Number_Products_Order_By>>;
  where?: InputMaybe<Lot_Number_Products_Bool_Exp>;
};


export type Query_RootLotNumberProductsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Lot_Number_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Number_Products_Order_By>>;
  where?: InputMaybe<Lot_Number_Products_Bool_Exp>;
};


export type Query_RootLotNumbersArgs = {
  distinct_on?: InputMaybe<Array<Lot_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Numbers_Order_By>>;
  where?: InputMaybe<Lot_Numbers_Bool_Exp>;
};


export type Query_RootLotNumbersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Lot_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Numbers_Order_By>>;
  where?: InputMaybe<Lot_Numbers_Bool_Exp>;
};


export type Query_RootMyUsersArgs = {
  distinct_on?: InputMaybe<Array<My_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<My_User_Order_By>>;
  where?: InputMaybe<My_User_Bool_Exp>;
};


export type Query_RootMyUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<My_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<My_User_Order_By>>;
  where?: InputMaybe<My_User_Bool_Exp>;
};


export type Query_RootNextDismantlingPlansArgs = {
  distinct_on?: InputMaybe<Array<Next_Dismantling_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Next_Dismantling_Plans_Order_By>>;
  where?: InputMaybe<Next_Dismantling_Plans_Bool_Exp>;
};


export type Query_RootNextDismantlingPlansAggregateArgs = {
  distinct_on?: InputMaybe<Array<Next_Dismantling_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Next_Dismantling_Plans_Order_By>>;
  where?: InputMaybe<Next_Dismantling_Plans_Bool_Exp>;
};


export type Query_RootNotificationByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootNotificationTypeByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootNotificationTypesArgs = {
  distinct_on?: InputMaybe<Array<Notification_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Types_Order_By>>;
  where?: InputMaybe<Notification_Types_Bool_Exp>;
};


export type Query_RootNotificationTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Types_Order_By>>;
  where?: InputMaybe<Notification_Types_Bool_Exp>;
};


export type Query_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootNotificationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootOutput_Cutting_Pattern_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Query_RootOutput_Cutting_Pattern_Products_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Query_RootPartialDeliveriesArgs = {
  distinct_on?: InputMaybe<Array<Partial_Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Deliveries_Order_By>>;
  where?: InputMaybe<Partial_Deliveries_Bool_Exp>;
};


export type Query_RootPartialDeliveriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Partial_Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Deliveries_Order_By>>;
  where?: InputMaybe<Partial_Deliveries_Bool_Exp>;
};


export type Query_RootPartialDeliveryByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPartialDeliveryLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Partial_Delivery_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Delivery_Logged_Actions_Order_By>>;
  where?: InputMaybe<Partial_Delivery_Logged_Actions_Bool_Exp>;
};


export type Query_RootPartialDeliveryLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Partial_Delivery_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Delivery_Logged_Actions_Order_By>>;
  where?: InputMaybe<Partial_Delivery_Logged_Actions_Bool_Exp>;
};


export type Query_RootPhoneByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPhonesArgs = {
  distinct_on?: InputMaybe<Array<Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phones_Order_By>>;
  where?: InputMaybe<Phones_Bool_Exp>;
};


export type Query_RootPhonesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phones_Order_By>>;
  where?: InputMaybe<Phones_Bool_Exp>;
};


export type Query_RootPickedItemByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPickedItemContainerByPkArgs = {
  customerOrderPickedContainerId: Scalars['bigint'];
  pickedItemId: Scalars['bigint'];
};


export type Query_RootPickedItemContainersArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_Containers_Order_By>>;
  where?: InputMaybe<Picked_Item_Containers_Bool_Exp>;
};


export type Query_RootPickedItemContainersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_Containers_Order_By>>;
  where?: InputMaybe<Picked_Item_Containers_Bool_Exp>;
};


export type Query_RootPickedItemLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Picked_Item_Logged_Actions_Bool_Exp>;
};


export type Query_RootPickedItemLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Picked_Item_Logged_Actions_Bool_Exp>;
};


export type Query_RootPickedItemStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootPickedItemStateTransitionByPkArgs = {
  fromState: Picked_Item_States_Enum;
  toState: Picked_Item_States_Enum;
};


export type Query_RootPickedItemStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_State_Transitions_Order_By>>;
  where?: InputMaybe<Picked_Item_State_Transitions_Bool_Exp>;
};


export type Query_RootPickedItemStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_State_Transitions_Order_By>>;
  where?: InputMaybe<Picked_Item_State_Transitions_Bool_Exp>;
};


export type Query_RootPickedItemStatesArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_States_Order_By>>;
  where?: InputMaybe<Picked_Item_States_Bool_Exp>;
};


export type Query_RootPickedItemStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_States_Order_By>>;
  where?: InputMaybe<Picked_Item_States_Bool_Exp>;
};


export type Query_RootPickedItemsArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


export type Query_RootPickedItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


export type Query_RootPickingCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Picking_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picking_Categories_Order_By>>;
  where?: InputMaybe<Picking_Categories_Bool_Exp>;
};


export type Query_RootPickingCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Picking_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picking_Categories_Order_By>>;
  where?: InputMaybe<Picking_Categories_Bool_Exp>;
};


export type Query_RootPickingCategoryByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPickingDeviationReasonByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPickingDeviationReasonsArgs = {
  distinct_on?: InputMaybe<Array<Picking_Deviation_Reasons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picking_Deviation_Reasons_Order_By>>;
  where?: InputMaybe<Picking_Deviation_Reasons_Bool_Exp>;
};


export type Query_RootPickingDeviationReasonsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Picking_Deviation_Reasons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picking_Deviation_Reasons_Order_By>>;
  where?: InputMaybe<Picking_Deviation_Reasons_Bool_Exp>;
};


export type Query_RootPicking_Sorted_Customer_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


export type Query_RootPicking_Sorted_Customer_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


export type Query_RootPostingByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPostingsArgs = {
  distinct_on?: InputMaybe<Array<Postings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postings_Order_By>>;
  where?: InputMaybe<Postings_Bool_Exp>;
};


export type Query_RootPostingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Postings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postings_Order_By>>;
  where?: InputMaybe<Postings_Bool_Exp>;
};


export type Query_RootPriceListByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPriceListsArgs = {
  distinct_on?: InputMaybe<Array<Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Lists_Order_By>>;
  where?: InputMaybe<Price_Lists_Bool_Exp>;
};


export type Query_RootPriceListsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Lists_Order_By>>;
  where?: InputMaybe<Price_Lists_Bool_Exp>;
};


export type Query_RootProductAllowedContainerByPkArgs = {
  containerProductId: Scalars['bigint'];
  productId: Scalars['bigint'];
};


export type Query_RootProductAllowedContainersArgs = {
  distinct_on?: InputMaybe<Array<Product_Allowed_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Allowed_Containers_Order_By>>;
  where?: InputMaybe<Product_Allowed_Containers_Bool_Exp>;
};


export type Query_RootProductAllowedContainersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Allowed_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Allowed_Containers_Order_By>>;
  where?: InputMaybe<Product_Allowed_Containers_Bool_Exp>;
};


export type Query_RootProductByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProductGroupByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProductGroupMemberByPkArgs = {
  productGroupId: Scalars['bigint'];
  productId: Scalars['bigint'];
};


export type Query_RootProductGroupMembersArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_Members_Order_By>>;
  where?: InputMaybe<Product_Group_Members_Bool_Exp>;
};


export type Query_RootProductGroupMembersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_Members_Order_By>>;
  where?: InputMaybe<Product_Group_Members_Bool_Exp>;
};


export type Query_RootProductGroupStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootProductGroupStateTransitionByPkArgs = {
  fromState: Product_Group_States_Enum;
  toState: Product_Group_States_Enum;
};


export type Query_RootProductGroupStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_State_Transitions_Order_By>>;
  where?: InputMaybe<Product_Group_State_Transitions_Bool_Exp>;
};


export type Query_RootProductGroupStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_State_Transitions_Order_By>>;
  where?: InputMaybe<Product_Group_State_Transitions_Bool_Exp>;
};


export type Query_RootProductGroupStatesArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_States_Order_By>>;
  where?: InputMaybe<Product_Group_States_Bool_Exp>;
};


export type Query_RootProductGroupStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_States_Order_By>>;
  where?: InputMaybe<Product_Group_States_Bool_Exp>;
};


export type Query_RootProductGroupTypeByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootProductGroupTypesArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_Types_Order_By>>;
  where?: InputMaybe<Product_Group_Types_Bool_Exp>;
};


export type Query_RootProductGroupTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_Types_Order_By>>;
  where?: InputMaybe<Product_Group_Types_Bool_Exp>;
};


export type Query_RootProductGroupsArgs = {
  distinct_on?: InputMaybe<Array<Product_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Groups_Order_By>>;
  where?: InputMaybe<Product_Groups_Bool_Exp>;
};


export type Query_RootProductGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Groups_Order_By>>;
  where?: InputMaybe<Product_Groups_Bool_Exp>;
};


export type Query_RootProductLabelSettingByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProductLabelSettingsArgs = {
  distinct_on?: InputMaybe<Array<Product_Label_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Label_Settings_Order_By>>;
  where?: InputMaybe<Product_Label_Settings_Bool_Exp>;
};


export type Query_RootProductLabelSettingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Label_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Label_Settings_Order_By>>;
  where?: InputMaybe<Product_Label_Settings_Bool_Exp>;
};


export type Query_RootProductLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Product_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Logged_Actions_Order_By>>;
  where?: InputMaybe<Product_Logged_Actions_Bool_Exp>;
};


export type Query_RootProductLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Logged_Actions_Order_By>>;
  where?: InputMaybe<Product_Logged_Actions_Bool_Exp>;
};


export type Query_RootProductPackagingConfigurationByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProductPackagingConfigurationsArgs = {
  distinct_on?: InputMaybe<Array<Product_Packaging_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Packaging_Configurations_Order_By>>;
  where?: InputMaybe<Product_Packaging_Configurations_Bool_Exp>;
};


export type Query_RootProductPackagingConfigurationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Packaging_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Packaging_Configurations_Order_By>>;
  where?: InputMaybe<Product_Packaging_Configurations_Bool_Exp>;
};


export type Query_RootProductPackagingConfigurationsLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Product_Packaging_Configurations_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Packaging_Configurations_Logged_Actions_Order_By>>;
  where?: InputMaybe<Product_Packaging_Configurations_Logged_Actions_Bool_Exp>;
};


export type Query_RootProductPackagingConfigurationsLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Packaging_Configurations_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Packaging_Configurations_Logged_Actions_Order_By>>;
  where?: InputMaybe<Product_Packaging_Configurations_Logged_Actions_Bool_Exp>;
};


export type Query_RootProductRecipeByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProductRecipeIngredientByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProductRecipeIngredientsArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_Ingredients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_Ingredients_Order_By>>;
  where?: InputMaybe<Product_Recipe_Ingredients_Bool_Exp>;
};


export type Query_RootProductRecipeIngredientsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_Ingredients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_Ingredients_Order_By>>;
  where?: InputMaybe<Product_Recipe_Ingredients_Bool_Exp>;
};


export type Query_RootProductRecipeStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootProductRecipeStateTransitionByPkArgs = {
  fromState: Product_States_Enum;
  toState: Product_States_Enum;
};


export type Query_RootProductRecipeStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_State_Transitions_Order_By>>;
  where?: InputMaybe<Product_Recipe_State_Transitions_Bool_Exp>;
};


export type Query_RootProductRecipeStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_State_Transitions_Order_By>>;
  where?: InputMaybe<Product_Recipe_State_Transitions_Bool_Exp>;
};


export type Query_RootProductRecipeStatesArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_States_Order_By>>;
  where?: InputMaybe<Product_Recipe_States_Bool_Exp>;
};


export type Query_RootProductRecipeStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_States_Order_By>>;
  where?: InputMaybe<Product_Recipe_States_Bool_Exp>;
};


export type Query_RootProductRecipeStepByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProductRecipeStepsArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_Steps_Order_By>>;
  where?: InputMaybe<Product_Recipe_Steps_Bool_Exp>;
};


export type Query_RootProductRecipeStepsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_Steps_Order_By>>;
  where?: InputMaybe<Product_Recipe_Steps_Bool_Exp>;
};


export type Query_RootProductRecipesArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipes_Order_By>>;
  where?: InputMaybe<Product_Recipes_Bool_Exp>;
};


export type Query_RootProductRecipesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipes_Order_By>>;
  where?: InputMaybe<Product_Recipes_Bool_Exp>;
};


export type Query_RootProductS3ObjectByPkArgs = {
  productId: Scalars['bigint'];
  s3ObjectId: Scalars['bigint'];
};


export type Query_RootProductS3ObjectsArgs = {
  distinct_on?: InputMaybe<Array<Product_S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_S3_Objects_Order_By>>;
  where?: InputMaybe<Product_S3_Objects_Bool_Exp>;
};


export type Query_RootProductS3ObjectsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_S3_Objects_Order_By>>;
  where?: InputMaybe<Product_S3_Objects_Bool_Exp>;
};


export type Query_RootProductSalesChannelMemberByPkArgs = {
  productId: Scalars['bigint'];
  salesChannelId: Scalars['bigint'];
};


export type Query_RootProductSalesChannelMembersArgs = {
  distinct_on?: InputMaybe<Array<Product_Sales_Channel_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Sales_Channel_Members_Order_By>>;
  where?: InputMaybe<Product_Sales_Channel_Members_Bool_Exp>;
};


export type Query_RootProductSalesChannelMembersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Sales_Channel_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Sales_Channel_Members_Order_By>>;
  where?: InputMaybe<Product_Sales_Channel_Members_Bool_Exp>;
};


export type Query_RootProductStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootProductStateTransitionByPkArgs = {
  fromState: Product_States_Enum;
  toState: Product_States_Enum;
};


export type Query_RootProductStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Product_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_State_Transitions_Order_By>>;
  where?: InputMaybe<Product_State_Transitions_Bool_Exp>;
};


export type Query_RootProductStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_State_Transitions_Order_By>>;
  where?: InputMaybe<Product_State_Transitions_Bool_Exp>;
};


export type Query_RootProductStatesArgs = {
  distinct_on?: InputMaybe<Array<Product_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_States_Order_By>>;
  where?: InputMaybe<Product_States_Bool_Exp>;
};


export type Query_RootProductStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_States_Order_By>>;
  where?: InputMaybe<Product_States_Bool_Exp>;
};


export type Query_RootProductStockLocationByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProductStockLocationsArgs = {
  distinct_on?: InputMaybe<Array<Product_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Stock_Locations_Order_By>>;
  where?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
};


export type Query_RootProductStockLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Stock_Locations_Order_By>>;
  where?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
};


export type Query_RootProductSupplierByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProductSupplierStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootProductSupplierStateTransitionByPkArgs = {
  fromState: Product_Supplier_States_Enum;
  toState: Product_Supplier_States_Enum;
};


export type Query_RootProductSupplierStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Product_Supplier_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Supplier_State_Transitions_Order_By>>;
  where?: InputMaybe<Product_Supplier_State_Transitions_Bool_Exp>;
};


export type Query_RootProductSupplierStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Supplier_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Supplier_State_Transitions_Order_By>>;
  where?: InputMaybe<Product_Supplier_State_Transitions_Bool_Exp>;
};


export type Query_RootProductSupplierStatesArgs = {
  distinct_on?: InputMaybe<Array<Product_Supplier_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Supplier_States_Order_By>>;
  where?: InputMaybe<Product_Supplier_States_Bool_Exp>;
};


export type Query_RootProductSupplierStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Supplier_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Supplier_States_Order_By>>;
  where?: InputMaybe<Product_Supplier_States_Bool_Exp>;
};


export type Query_RootProductSuppliersArgs = {
  distinct_on?: InputMaybe<Array<Product_Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Suppliers_Order_By>>;
  where?: InputMaybe<Product_Suppliers_Bool_Exp>;
};


export type Query_RootProductSuppliersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Suppliers_Order_By>>;
  where?: InputMaybe<Product_Suppliers_Bool_Exp>;
};


export type Query_RootProductUnitByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProductUnitConversionResultsArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_Conversion_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Conversion_Result_Order_By>>;
  where?: InputMaybe<Product_Unit_Conversion_Result_Bool_Exp>;
};


export type Query_RootProductUnitConversionResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_Conversion_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Conversion_Result_Order_By>>;
  where?: InputMaybe<Product_Unit_Conversion_Result_Bool_Exp>;
};


export type Query_RootProductUnitSalesPriceConfigByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProductUnitSalesPriceConfigsArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Order_By>>;
  where?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
};


export type Query_RootProductUnitSalesPriceConfigsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Order_By>>;
  where?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
};


export type Query_RootProductUnitStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootProductUnitStateTransitionByPkArgs = {
  fromState: Product_Unit_States_Enum;
  toState: Product_Unit_States_Enum;
};


export type Query_RootProductUnitStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_State_Transitions_Order_By>>;
  where?: InputMaybe<Product_Unit_State_Transitions_Bool_Exp>;
};


export type Query_RootProductUnitStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_State_Transitions_Order_By>>;
  where?: InputMaybe<Product_Unit_State_Transitions_Bool_Exp>;
};


export type Query_RootProductUnitStatesArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_States_Order_By>>;
  where?: InputMaybe<Product_Unit_States_Bool_Exp>;
};


export type Query_RootProductUnitStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_States_Order_By>>;
  where?: InputMaybe<Product_Unit_States_Bool_Exp>;
};


export type Query_RootProductUnitsArgs = {
  distinct_on?: InputMaybe<Array<Product_Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Units_Order_By>>;
  where?: InputMaybe<Product_Units_Bool_Exp>;
};


export type Query_RootProductUnitsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Units_Order_By>>;
  where?: InputMaybe<Product_Units_Bool_Exp>;
};


export type Query_RootProduct_Unit_ConversionArgs = {
  args: Product_Unit_Conversion_Args;
  distinct_on?: InputMaybe<Array<Product_Unit_Conversion_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Conversion_Result_Order_By>>;
  where?: InputMaybe<Product_Unit_Conversion_Result_Bool_Exp>;
};


export type Query_RootProduct_Unit_Conversion_AggregateArgs = {
  args: Product_Unit_Conversion_Args;
  distinct_on?: InputMaybe<Array<Product_Unit_Conversion_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Conversion_Result_Order_By>>;
  where?: InputMaybe<Product_Unit_Conversion_Result_Bool_Exp>;
};


export type Query_RootProductionSiteByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProductionSiteInputStockLocationByPkArgs = {
  productionSiteId: Scalars['bigint'];
  stockLocationId: Scalars['bigint'];
};


export type Query_RootProductionSiteInputStockLocationsArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Order_By>>;
  where?: InputMaybe<Production_Site_Input_Stock_Locations_Bool_Exp>;
};


export type Query_RootProductionSiteInputStockLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Order_By>>;
  where?: InputMaybe<Production_Site_Input_Stock_Locations_Bool_Exp>;
};


export type Query_RootProductionSiteOutputStockLocationByPkArgs = {
  productionSiteId: Scalars['bigint'];
  stockLocationId: Scalars['bigint'];
};


export type Query_RootProductionSiteOutputStockLocationsArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Output_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Output_Stock_Locations_Order_By>>;
  where?: InputMaybe<Production_Site_Output_Stock_Locations_Bool_Exp>;
};


export type Query_RootProductionSiteOutputStockLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Output_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Output_Stock_Locations_Order_By>>;
  where?: InputMaybe<Production_Site_Output_Stock_Locations_Bool_Exp>;
};


export type Query_RootProductionSiteProductGroupCalendarByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProductionSiteProductGroupCalendarsArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Order_By>>;
  where?: InputMaybe<Production_Site_Product_Group_Calendar_Bool_Exp>;
};


export type Query_RootProductionSiteProductGroupCalendarsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Order_By>>;
  where?: InputMaybe<Production_Site_Product_Group_Calendar_Bool_Exp>;
};


export type Query_RootProductionSiteStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootProductionSiteStatesArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_States_Order_By>>;
  where?: InputMaybe<Production_Site_States_Bool_Exp>;
};


export type Query_RootProductionSiteStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_States_Order_By>>;
  where?: InputMaybe<Production_Site_States_Bool_Exp>;
};


export type Query_RootProductionSitesArgs = {
  distinct_on?: InputMaybe<Array<Production_Sites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Sites_Order_By>>;
  where?: InputMaybe<Production_Sites_Bool_Exp>;
};


export type Query_RootProductionSitesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Production_Sites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Sites_Order_By>>;
  where?: InputMaybe<Production_Sites_Bool_Exp>;
};


export type Query_RootProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Query_RootProductsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Query_RootProducts_PricingArgs = {
  args: Products_Pricing_Args;
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Query_RootProducts_Pricing_AggregateArgs = {
  args: Products_Pricing_Args;
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Query_RootProletarianArchivedJobByPkArgs = {
  jobId: Scalars['uuid'];
};


export type Query_RootProletarianArchivedJobsArgs = {
  distinct_on?: InputMaybe<Array<Proletarian_Archived_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Proletarian_Archived_Jobs_Order_By>>;
  where?: InputMaybe<Proletarian_Archived_Jobs_Bool_Exp>;
};


export type Query_RootProletarianArchivedJobsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Proletarian_Archived_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Proletarian_Archived_Jobs_Order_By>>;
  where?: InputMaybe<Proletarian_Archived_Jobs_Bool_Exp>;
};


export type Query_RootProletarianJobByPkArgs = {
  jobId: Scalars['uuid'];
};


export type Query_RootProletarianJobsArgs = {
  distinct_on?: InputMaybe<Array<Proletarian_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Proletarian_Jobs_Order_By>>;
  where?: InputMaybe<Proletarian_Jobs_Bool_Exp>;
};


export type Query_RootProletarianJobsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Proletarian_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Proletarian_Jobs_Order_By>>;
  where?: InputMaybe<Proletarian_Jobs_Bool_Exp>;
};


export type Query_RootPublicLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Public_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Logged_Actions_Order_By>>;
  where?: InputMaybe<Public_Logged_Actions_Bool_Exp>;
};


export type Query_RootPublicLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Public_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Logged_Actions_Order_By>>;
  where?: InputMaybe<Public_Logged_Actions_Bool_Exp>;
};


export type Query_RootPublicTenantInfoResultsArgs = {
  distinct_on?: InputMaybe<Array<Public_Tenant_Info_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Tenant_Info_Result_Order_By>>;
  where?: InputMaybe<Public_Tenant_Info_Result_Bool_Exp>;
};


export type Query_RootPublicTenantInfoResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Public_Tenant_Info_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Tenant_Info_Result_Order_By>>;
  where?: InputMaybe<Public_Tenant_Info_Result_Bool_Exp>;
};


export type Query_RootPurchasingSuggestionByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootPurchasingSuggestionsArgs = {
  distinct_on?: InputMaybe<Array<Purchasing_Suggestions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchasing_Suggestions_Order_By>>;
  where?: InputMaybe<Purchasing_Suggestions_Bool_Exp>;
};


export type Query_RootPurchasingSuggestionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Purchasing_Suggestions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchasing_Suggestions_Order_By>>;
  where?: InputMaybe<Purchasing_Suggestions_Bool_Exp>;
};


export type Query_RootPuspc_With_Customer_PricesArgs = {
  args: Puspc_With_Customer_Prices_Args;
  distinct_on?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Order_By>>;
  where?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
};


export type Query_RootPuspc_With_Customer_Prices_AggregateArgs = {
  args: Puspc_With_Customer_Prices_Args;
  distinct_on?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Order_By>>;
  where?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
};


export type Query_RootQueryMonitoringByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootQueryMonitoringsArgs = {
  distinct_on?: InputMaybe<Array<Query_Monitoring_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Query_Monitoring_Order_By>>;
  where?: InputMaybe<Query_Monitoring_Bool_Exp>;
};


export type Query_RootQueryMonitoringsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Query_Monitoring_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Query_Monitoring_Order_By>>;
  where?: InputMaybe<Query_Monitoring_Bool_Exp>;
};


export type Query_RootRoleByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootRolePermissionsArgs = {
  distinct_on?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Permissions_Order_By>>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};


export type Query_RootRolePermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Permissions_Order_By>>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};


export type Query_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRollingInventoriesArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventories_Order_By>>;
  where?: InputMaybe<Rolling_Inventories_Bool_Exp>;
};


export type Query_RootRollingInventoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventories_Order_By>>;
  where?: InputMaybe<Rolling_Inventories_Bool_Exp>;
};


export type Query_RootRollingInventoryActorByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootRollingInventoryActorsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Actors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Actors_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
};


export type Query_RootRollingInventoryActorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Actors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Actors_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
};


export type Query_RootRollingInventoryByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootRollingInventoryItemByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootRollingInventoryItemsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


export type Query_RootRollingInventoryItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


export type Query_RootRollingInventoryLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Logged_Actions_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Logged_Actions_Bool_Exp>;
};


export type Query_RootRollingInventoryLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Logged_Actions_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Logged_Actions_Bool_Exp>;
};


export type Query_RootRollingInventoryLotSummaryItemsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
};


export type Query_RootRollingInventoryLotSummaryItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
};


export type Query_RootRollingInventoryStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootRollingInventoryStateTransitionByPkArgs = {
  fromState: Rolling_Inventory_States_Enum;
  toState: Rolling_Inventory_States_Enum;
};


export type Query_RootRollingInventoryStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_State_Transitions_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_State_Transitions_Bool_Exp>;
};


export type Query_RootRollingInventoryStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_State_Transitions_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_State_Transitions_Bool_Exp>;
};


export type Query_RootRollingInventoryStatesArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_States_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_States_Bool_Exp>;
};


export type Query_RootRollingInventoryStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_States_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_States_Bool_Exp>;
};


export type Query_RootRollingInventorySummaryItemsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
};


export type Query_RootRollingInventorySummaryItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
};


export type Query_RootRolling_Inventory_Lot_SummaryArgs = {
  args: Rolling_Inventory_Lot_Summary_Args;
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
};


export type Query_RootRolling_Inventory_Lot_Summary_AggregateArgs = {
  args: Rolling_Inventory_Lot_Summary_Args;
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
};


export type Query_RootRolling_Inventory_SummaryArgs = {
  args: Rolling_Inventory_Summary_Args;
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
};


export type Query_RootRolling_Inventory_Summary_AggregateArgs = {
  args: Rolling_Inventory_Summary_Args;
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
};


export type Query_RootRoot_Cutting_Pattern_ProductsArgs = {
  args: Root_Cutting_Pattern_Products_Args;
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Query_RootRoot_Cutting_Pattern_Products_AggregateArgs = {
  args: Root_Cutting_Pattern_Products_Args;
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Query_RootRoot_Cutting_Pattern_Products_DraftArgs = {
  args: Root_Cutting_Pattern_Products_Draft_Args;
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Query_RootRoot_Cutting_Pattern_Products_Draft_AggregateArgs = {
  args: Root_Cutting_Pattern_Products_Draft_Args;
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Query_RootRoot_Cutting_Pattern_Temporal_ProductsArgs = {
  args: Root_Cutting_Pattern_Temporal_Products_Args;
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Query_RootRoot_Cutting_Pattern_Temporal_Products_AggregateArgs = {
  args: Root_Cutting_Pattern_Temporal_Products_Args;
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Query_RootS3ObjectByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootS3ObjectStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootS3ObjectStateTransitionByPkArgs = {
  fromState: S3_Object_States_Enum;
  toState: S3_Object_States_Enum;
};


export type Query_RootS3ObjectStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<S3_Object_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Object_State_Transitions_Order_By>>;
  where?: InputMaybe<S3_Object_State_Transitions_Bool_Exp>;
};


export type Query_RootS3ObjectStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<S3_Object_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Object_State_Transitions_Order_By>>;
  where?: InputMaybe<S3_Object_State_Transitions_Bool_Exp>;
};


export type Query_RootS3ObjectStatesArgs = {
  distinct_on?: InputMaybe<Array<S3_Object_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Object_States_Order_By>>;
  where?: InputMaybe<S3_Object_States_Bool_Exp>;
};


export type Query_RootS3ObjectStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<S3_Object_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Object_States_Order_By>>;
  where?: InputMaybe<S3_Object_States_Bool_Exp>;
};


export type Query_RootS3ObjectVisibilitiesArgs = {
  distinct_on?: InputMaybe<Array<S3_Object_Visibilities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Object_Visibilities_Order_By>>;
  where?: InputMaybe<S3_Object_Visibilities_Bool_Exp>;
};


export type Query_RootS3ObjectVisibilitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<S3_Object_Visibilities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Object_Visibilities_Order_By>>;
  where?: InputMaybe<S3_Object_Visibilities_Bool_Exp>;
};


export type Query_RootS3ObjectVisibilityByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootS3ObjectsArgs = {
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


export type Query_RootS3ObjectsAggregateArgs = {
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


export type Query_RootSalesChannelByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSalesChannelsArgs = {
  distinct_on?: InputMaybe<Array<Sales_Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Channels_Order_By>>;
  where?: InputMaybe<Sales_Channels_Bool_Exp>;
};


export type Query_RootSalesChannelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Sales_Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Channels_Order_By>>;
  where?: InputMaybe<Sales_Channels_Bool_Exp>;
};


export type Query_RootSalesTeamByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSalesTeamStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootSalesTeamStateTransitionByPkArgs = {
  fromState: Sales_Team_States_Enum;
  toState: Sales_Team_States_Enum;
};


export type Query_RootSalesTeamStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Sales_Team_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Team_State_Transitions_Order_By>>;
  where?: InputMaybe<Sales_Team_State_Transitions_Bool_Exp>;
};


export type Query_RootSalesTeamStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Sales_Team_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Team_State_Transitions_Order_By>>;
  where?: InputMaybe<Sales_Team_State_Transitions_Bool_Exp>;
};


export type Query_RootSalesTeamStatesArgs = {
  distinct_on?: InputMaybe<Array<Sales_Team_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Team_States_Order_By>>;
  where?: InputMaybe<Sales_Team_States_Bool_Exp>;
};


export type Query_RootSalesTeamStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Sales_Team_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Team_States_Order_By>>;
  where?: InputMaybe<Sales_Team_States_Bool_Exp>;
};


export type Query_RootSalesTeamUserByPkArgs = {
  salesTeamId: Scalars['bigint'];
  userId: Scalars['bigint'];
};


export type Query_RootSalesTeamUsersArgs = {
  distinct_on?: InputMaybe<Array<Sales_Team_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Team_Users_Order_By>>;
  where?: InputMaybe<Sales_Team_Users_Bool_Exp>;
};


export type Query_RootSalesTeamUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Sales_Team_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Team_Users_Order_By>>;
  where?: InputMaybe<Sales_Team_Users_Bool_Exp>;
};


export type Query_RootSalesTeamsArgs = {
  distinct_on?: InputMaybe<Array<Sales_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Teams_Order_By>>;
  where?: InputMaybe<Sales_Teams_Bool_Exp>;
};


export type Query_RootSalesTeamsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Sales_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Teams_Order_By>>;
  where?: InputMaybe<Sales_Teams_Bool_Exp>;
};


export type Query_RootSalutationByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootSalutationsArgs = {
  distinct_on?: InputMaybe<Array<Salutations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salutations_Order_By>>;
  where?: InputMaybe<Salutations_Bool_Exp>;
};


export type Query_RootSalutationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Salutations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salutations_Order_By>>;
  where?: InputMaybe<Salutations_Bool_Exp>;
};


export type Query_RootSearchProductsForCustomerResultsArgs = {
  distinct_on?: InputMaybe<Array<Search_Products_For_Customer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Products_For_Customer_Results_Order_By>>;
  where?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
};


export type Query_RootSearchProductsForCustomerResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Search_Products_For_Customer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Products_For_Customer_Results_Order_By>>;
  where?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
};


export type Query_RootSearchPurchasedProductsResultsArgs = {
  distinct_on?: InputMaybe<Array<Search_Purchased_Products_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Purchased_Products_Results_Order_By>>;
  where?: InputMaybe<Search_Purchased_Products_Results_Bool_Exp>;
};


export type Query_RootSearchPurchasedProductsResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Search_Purchased_Products_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Purchased_Products_Results_Order_By>>;
  where?: InputMaybe<Search_Purchased_Products_Results_Bool_Exp>;
};


export type Query_RootSearch_ContactsArgs = {
  args: Search_Contacts_Args;
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Query_RootSearch_Contacts_AggregateArgs = {
  args: Search_Contacts_Args;
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Query_RootSearch_Customer_GroupsArgs = {
  args: Search_Customer_Groups_Args;
  distinct_on?: InputMaybe<Array<Customer_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Groups_Order_By>>;
  where?: InputMaybe<Customer_Groups_Bool_Exp>;
};


export type Query_RootSearch_Customer_Groups_AggregateArgs = {
  args: Search_Customer_Groups_Args;
  distinct_on?: InputMaybe<Array<Customer_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Groups_Order_By>>;
  where?: InputMaybe<Customer_Groups_Bool_Exp>;
};


export type Query_RootSearch_Customer_Order_Item_Return_CausesArgs = {
  args: Search_Customer_Order_Item_Return_Causes_Args;
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Return_Causes_Bool_Exp>;
};


export type Query_RootSearch_Customer_Order_Item_Return_Causes_AggregateArgs = {
  args: Search_Customer_Order_Item_Return_Causes_Args;
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Return_Causes_Bool_Exp>;
};


export type Query_RootSearch_Customer_OrdersArgs = {
  args: Search_Customer_Orders_Args;
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


export type Query_RootSearch_Customer_Orders_AggregateArgs = {
  args: Search_Customer_Orders_Args;
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


export type Query_RootSearch_CustomersArgs = {
  args: Search_Customers_Args;
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


export type Query_RootSearch_Customers_AggregateArgs = {
  args: Search_Customers_Args;
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


export type Query_RootSearch_Hardware_DevicesArgs = {
  args: Search_Hardware_Devices_Args;
  distinct_on?: InputMaybe<Array<Hardware_Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Devices_Order_By>>;
  where?: InputMaybe<Hardware_Devices_Bool_Exp>;
};


export type Query_RootSearch_Hardware_Devices_AggregateArgs = {
  args: Search_Hardware_Devices_Args;
  distinct_on?: InputMaybe<Array<Hardware_Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Devices_Order_By>>;
  where?: InputMaybe<Hardware_Devices_Bool_Exp>;
};


export type Query_RootSearch_Lot_NumbersArgs = {
  args: Search_Lot_Numbers_Args;
  distinct_on?: InputMaybe<Array<Lot_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Numbers_Order_By>>;
  where?: InputMaybe<Lot_Numbers_Bool_Exp>;
};


export type Query_RootSearch_Lot_Numbers_AggregateArgs = {
  args: Search_Lot_Numbers_Args;
  distinct_on?: InputMaybe<Array<Lot_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Numbers_Order_By>>;
  where?: InputMaybe<Lot_Numbers_Bool_Exp>;
};


export type Query_RootSearch_Picking_CategoriesArgs = {
  args: Search_Picking_Categories_Args;
  distinct_on?: InputMaybe<Array<Picking_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picking_Categories_Order_By>>;
  where?: InputMaybe<Picking_Categories_Bool_Exp>;
};


export type Query_RootSearch_Picking_Categories_AggregateArgs = {
  args: Search_Picking_Categories_Args;
  distinct_on?: InputMaybe<Array<Picking_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picking_Categories_Order_By>>;
  where?: InputMaybe<Picking_Categories_Bool_Exp>;
};


export type Query_RootSearch_Product_GroupsArgs = {
  args: Search_Product_Groups_Args;
  distinct_on?: InputMaybe<Array<Product_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Groups_Order_By>>;
  where?: InputMaybe<Product_Groups_Bool_Exp>;
};


export type Query_RootSearch_Product_Groups_AggregateArgs = {
  args: Search_Product_Groups_Args;
  distinct_on?: InputMaybe<Array<Product_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Groups_Order_By>>;
  where?: InputMaybe<Product_Groups_Bool_Exp>;
};


export type Query_RootSearch_ProductsArgs = {
  args: Search_Products_Args;
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Query_RootSearch_Products_AggregateArgs = {
  args: Search_Products_Args;
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Query_RootSearch_Products_For_CustomerArgs = {
  args: Search_Products_For_Customer_Args;
  distinct_on?: InputMaybe<Array<Search_Products_For_Customer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Products_For_Customer_Results_Order_By>>;
  where?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
};


export type Query_RootSearch_Products_For_Customer_AggregateArgs = {
  args: Search_Products_For_Customer_Args;
  distinct_on?: InputMaybe<Array<Search_Products_For_Customer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Products_For_Customer_Results_Order_By>>;
  where?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
};


export type Query_RootSearch_Products_For_Customer_GroupArgs = {
  args: Search_Products_For_Customer_Group_Args;
  distinct_on?: InputMaybe<Array<Search_Products_For_Customer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Products_For_Customer_Results_Order_By>>;
  where?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
};


export type Query_RootSearch_Products_For_Customer_Group_AggregateArgs = {
  args: Search_Products_For_Customer_Group_Args;
  distinct_on?: InputMaybe<Array<Search_Products_For_Customer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Products_For_Customer_Results_Order_By>>;
  where?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
};


export type Query_RootSearch_Purchased_ProductsArgs = {
  args: Search_Purchased_Products_Args;
  distinct_on?: InputMaybe<Array<Search_Products_For_Customer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Products_For_Customer_Results_Order_By>>;
  where?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
};


export type Query_RootSearch_Purchased_Products_AggregateArgs = {
  args: Search_Purchased_Products_Args;
  distinct_on?: InputMaybe<Array<Search_Products_For_Customer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Products_For_Customer_Results_Order_By>>;
  where?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
};


export type Query_RootSearch_Sales_TeamsArgs = {
  args: Search_Sales_Teams_Args;
  distinct_on?: InputMaybe<Array<Sales_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Teams_Order_By>>;
  where?: InputMaybe<Sales_Teams_Bool_Exp>;
};


export type Query_RootSearch_Sales_Teams_AggregateArgs = {
  args: Search_Sales_Teams_Args;
  distinct_on?: InputMaybe<Array<Sales_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Teams_Order_By>>;
  where?: InputMaybe<Sales_Teams_Bool_Exp>;
};


export type Query_RootSearch_Stock_LocationsArgs = {
  args: Search_Stock_Locations_Args;
  distinct_on?: InputMaybe<Array<Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Locations_Order_By>>;
  where?: InputMaybe<Stock_Locations_Bool_Exp>;
};


export type Query_RootSearch_Stock_Locations_AggregateArgs = {
  args: Search_Stock_Locations_Args;
  distinct_on?: InputMaybe<Array<Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Locations_Order_By>>;
  where?: InputMaybe<Stock_Locations_Bool_Exp>;
};


export type Query_RootSearch_Supplier_OrdersArgs = {
  args: Search_Supplier_Orders_Args;
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


export type Query_RootSearch_Supplier_Orders_AggregateArgs = {
  args: Search_Supplier_Orders_Args;
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


export type Query_RootSearch_SuppliersArgs = {
  args: Search_Suppliers_Args;
  distinct_on?: InputMaybe<Array<Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Suppliers_Order_By>>;
  where?: InputMaybe<Suppliers_Bool_Exp>;
};


export type Query_RootSearch_Suppliers_AggregateArgs = {
  args: Search_Suppliers_Args;
  distinct_on?: InputMaybe<Array<Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Suppliers_Order_By>>;
  where?: InputMaybe<Suppliers_Bool_Exp>;
};


export type Query_RootSearch_UsersArgs = {
  args: Search_Users_Args;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootSearch_Users_AggregateArgs = {
  args: Search_Users_Args;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootSorted_Customer_Group_Pricing_For_CustomerArgs = {
  args: Sorted_Customer_Group_Pricing_For_Customer_Args;
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Query_RootSorted_Customer_Group_Pricing_For_Customer_AggregateArgs = {
  args: Sorted_Customer_Group_Pricing_For_Customer_Args;
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Query_RootSorted_Customer_Group_Pricing_For_Customer_GroupArgs = {
  args: Sorted_Customer_Group_Pricing_For_Customer_Group_Args;
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Query_RootSorted_Customer_Group_Pricing_For_Customer_Group_AggregateArgs = {
  args: Sorted_Customer_Group_Pricing_For_Customer_Group_Args;
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Query_RootSorted_Price_ListsArgs = {
  args: Sorted_Price_Lists_Args;
  distinct_on?: InputMaybe<Array<Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Lists_Order_By>>;
  where?: InputMaybe<Price_Lists_Bool_Exp>;
};


export type Query_RootSorted_Price_Lists_AggregateArgs = {
  args: Sorted_Price_Lists_Args;
  distinct_on?: InputMaybe<Array<Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Lists_Order_By>>;
  where?: InputMaybe<Price_Lists_Bool_Exp>;
};


export type Query_RootStockLocationByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootStockLocationStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootStockLocationStateTransitionByPkArgs = {
  fromState: Stock_Location_States_Enum;
  toState: Stock_Location_States_Enum;
};


export type Query_RootStockLocationStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Location_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Location_State_Transitions_Order_By>>;
  where?: InputMaybe<Stock_Location_State_Transitions_Bool_Exp>;
};


export type Query_RootStockLocationStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Location_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Location_State_Transitions_Order_By>>;
  where?: InputMaybe<Stock_Location_State_Transitions_Bool_Exp>;
};


export type Query_RootStockLocationStatesArgs = {
  distinct_on?: InputMaybe<Array<Stock_Location_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Location_States_Order_By>>;
  where?: InputMaybe<Stock_Location_States_Bool_Exp>;
};


export type Query_RootStockLocationStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Location_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Location_States_Order_By>>;
  where?: InputMaybe<Stock_Location_States_Bool_Exp>;
};


export type Query_RootStockLocationsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Locations_Order_By>>;
  where?: InputMaybe<Stock_Locations_Bool_Exp>;
};


export type Query_RootStockLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Locations_Order_By>>;
  where?: InputMaybe<Stock_Locations_Bool_Exp>;
};


export type Query_RootStockTransactionByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootStockTransactionLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transaction_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transaction_Logged_Actions_Order_By>>;
  where?: InputMaybe<Stock_Transaction_Logged_Actions_Bool_Exp>;
};


export type Query_RootStockTransactionLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transaction_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transaction_Logged_Actions_Order_By>>;
  where?: InputMaybe<Stock_Transaction_Logged_Actions_Bool_Exp>;
};


export type Query_RootStockTransactionNameByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootStockTransactionNamesArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transaction_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transaction_Names_Order_By>>;
  where?: InputMaybe<Stock_Transaction_Names_Bool_Exp>;
};


export type Query_RootStockTransactionNamesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transaction_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transaction_Names_Order_By>>;
  where?: InputMaybe<Stock_Transaction_Names_Bool_Exp>;
};


export type Query_RootStockTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


export type Query_RootStockTransactionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


export type Query_RootSupplierAddressByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSupplierAddressesArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Addresses_Order_By>>;
  where?: InputMaybe<Supplier_Addresses_Bool_Exp>;
};


export type Query_RootSupplierAddressesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Addresses_Order_By>>;
  where?: InputMaybe<Supplier_Addresses_Bool_Exp>;
};


export type Query_RootSupplierByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSupplierOrderByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSupplierOrderGoodsIncomeItemByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSupplierOrderGoodsIncomeItemContainerByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSupplierOrderGoodsIncomeItemContainersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Bool_Exp>;
};


export type Query_RootSupplierOrderGoodsIncomeItemContainersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Bool_Exp>;
};


export type Query_RootSupplierOrderGoodsIncomeItemLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Item_Logged_Actions_Bool_Exp>;
};


export type Query_RootSupplierOrderGoodsIncomeItemLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Item_Logged_Actions_Bool_Exp>;
};


export type Query_RootSupplierOrderGoodsIncomeItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


export type Query_RootSupplierOrderGoodsIncomeItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


export type Query_RootSupplierOrderItemByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSupplierOrderItemLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Supplier_Order_Item_Logged_Actions_Bool_Exp>;
};


export type Query_RootSupplierOrderItemLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Supplier_Order_Item_Logged_Actions_Bool_Exp>;
};


export type Query_RootSupplierOrderItemReturnCauseByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSupplierOrderItemReturnCausesArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Item_Return_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Item_Return_Causes_Order_By>>;
  where?: InputMaybe<Supplier_Order_Item_Return_Causes_Bool_Exp>;
};


export type Query_RootSupplierOrderItemReturnCausesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Item_Return_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Item_Return_Causes_Order_By>>;
  where?: InputMaybe<Supplier_Order_Item_Return_Causes_Bool_Exp>;
};


export type Query_RootSupplierOrderItemStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootSupplierOrderItemStatesArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Item_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Item_States_Order_By>>;
  where?: InputMaybe<Supplier_Order_Item_States_Bool_Exp>;
};


export type Query_RootSupplierOrderItemStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Item_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Item_States_Order_By>>;
  where?: InputMaybe<Supplier_Order_Item_States_Bool_Exp>;
};


export type Query_RootSupplierOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


export type Query_RootSupplierOrderItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


export type Query_RootSupplierOrderLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Logged_Actions_Order_By>>;
  where?: InputMaybe<Supplier_Order_Logged_Actions_Bool_Exp>;
};


export type Query_RootSupplierOrderLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Logged_Actions_Order_By>>;
  where?: InputMaybe<Supplier_Order_Logged_Actions_Bool_Exp>;
};


export type Query_RootSupplierOrderReturnByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSupplierOrderReturnItemByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSupplierOrderReturnItemContainerByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSupplierOrderReturnItemContainersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Item_Containers_Bool_Exp>;
};


export type Query_RootSupplierOrderReturnItemContainersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Item_Containers_Bool_Exp>;
};


export type Query_RootSupplierOrderReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


export type Query_RootSupplierOrderReturnItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


export type Query_RootSupplierOrderReturnStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootSupplierOrderReturnStatesArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_States_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_States_Bool_Exp>;
};


export type Query_RootSupplierOrderReturnStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_States_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_States_Bool_Exp>;
};


export type Query_RootSupplierOrderReturnedContainerByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSupplierOrderReturnedContainersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returned_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returned_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returned_Containers_Bool_Exp>;
};


export type Query_RootSupplierOrderReturnedContainersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returned_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returned_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returned_Containers_Bool_Exp>;
};


export type Query_RootSupplierOrderReturnsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returns_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
};


export type Query_RootSupplierOrderReturnsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returns_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
};


export type Query_RootSupplierOrderStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootSupplierOrderStateTransitionByPkArgs = {
  fromState: Supplier_Order_States_Enum;
  toState: Supplier_Order_States_Enum;
};


export type Query_RootSupplierOrderStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_State_Transitions_Order_By>>;
  where?: InputMaybe<Supplier_Order_State_Transitions_Bool_Exp>;
};


export type Query_RootSupplierOrderStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_State_Transitions_Order_By>>;
  where?: InputMaybe<Supplier_Order_State_Transitions_Bool_Exp>;
};


export type Query_RootSupplierOrderStatesArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_States_Order_By>>;
  where?: InputMaybe<Supplier_Order_States_Bool_Exp>;
};


export type Query_RootSupplierOrderStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_States_Order_By>>;
  where?: InputMaybe<Supplier_Order_States_Bool_Exp>;
};


export type Query_RootSupplierOrdersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


export type Query_RootSupplierOrdersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


export type Query_RootSupplierStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootSupplierStateTransitionByPkArgs = {
  fromState: Supplier_States_Enum;
  toState: Supplier_States_Enum;
};


export type Query_RootSupplierStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_State_Transitions_Order_By>>;
  where?: InputMaybe<Supplier_State_Transitions_Bool_Exp>;
};


export type Query_RootSupplierStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_State_Transitions_Order_By>>;
  where?: InputMaybe<Supplier_State_Transitions_Bool_Exp>;
};


export type Query_RootSupplierStatesArgs = {
  distinct_on?: InputMaybe<Array<Supplier_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_States_Order_By>>;
  where?: InputMaybe<Supplier_States_Bool_Exp>;
};


export type Query_RootSupplierStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_States_Order_By>>;
  where?: InputMaybe<Supplier_States_Bool_Exp>;
};


export type Query_RootSuppliersArgs = {
  distinct_on?: InputMaybe<Array<Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Suppliers_Order_By>>;
  where?: InputMaybe<Suppliers_Bool_Exp>;
};


export type Query_RootSuppliersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Suppliers_Order_By>>;
  where?: InputMaybe<Suppliers_Bool_Exp>;
};


export type Query_RootTemporalPricingStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootTemporalPricingStateTransitionByPkArgs = {
  fromState: Temporal_Pricing_States_Enum;
  toState: Temporal_Pricing_States_Enum;
};


export type Query_RootTemporalPricingStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Pricing_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Pricing_State_Transitions_Order_By>>;
  where?: InputMaybe<Temporal_Pricing_State_Transitions_Bool_Exp>;
};


export type Query_RootTemporalPricingStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Pricing_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Pricing_State_Transitions_Order_By>>;
  where?: InputMaybe<Temporal_Pricing_State_Transitions_Bool_Exp>;
};


export type Query_RootTemporalPricingStatesArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Pricing_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Pricing_States_Order_By>>;
  where?: InputMaybe<Temporal_Pricing_States_Bool_Exp>;
};


export type Query_RootTemporalPricingStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Pricing_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Pricing_States_Order_By>>;
  where?: InputMaybe<Temporal_Pricing_States_Bool_Exp>;
};


export type Query_RootTemporalProductByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootTemporalProductComparisonResultsArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Product_Comparison_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Product_Comparison_Result_Order_By>>;
  where?: InputMaybe<Temporal_Product_Comparison_Result_Bool_Exp>;
};


export type Query_RootTemporalProductComparisonResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Product_Comparison_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Product_Comparison_Result_Order_By>>;
  where?: InputMaybe<Temporal_Product_Comparison_Result_Bool_Exp>;
};


export type Query_RootTemporalProductsArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Query_RootTemporalProductsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Query_RootTemporalSalesTeamsCustomerByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootTemporalSalesTeamsCustomersArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Order_By>>;
  where?: InputMaybe<Temporal_Sales_Teams_Customers_Bool_Exp>;
};


export type Query_RootTemporalSalesTeamsCustomersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Order_By>>;
  where?: InputMaybe<Temporal_Sales_Teams_Customers_Bool_Exp>;
};


export type Query_RootTemporalVatTypeByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootTemporalVatTypesArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Vat_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Vat_Types_Order_By>>;
  where?: InputMaybe<Temporal_Vat_Types_Bool_Exp>;
};


export type Query_RootTemporalVatTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Vat_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Vat_Types_Order_By>>;
  where?: InputMaybe<Temporal_Vat_Types_Bool_Exp>;
};


export type Query_RootTemporal_Products_For_Cutting_PatternArgs = {
  args: Temporal_Products_For_Cutting_Pattern_Args;
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Query_RootTemporal_Products_For_Cutting_Pattern_AggregateArgs = {
  args: Temporal_Products_For_Cutting_Pattern_Args;
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Query_RootTemporal_Products_From_Root_Product_IdArgs = {
  args: Temporal_Products_From_Root_Product_Id_Args;
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Query_RootTemporal_Products_From_Root_Product_Id_AggregateArgs = {
  args: Temporal_Products_From_Root_Product_Id_Args;
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Query_RootTenantAddressByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootTenantAddressesArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Addresses_Order_By>>;
  where?: InputMaybe<Tenant_Addresses_Bool_Exp>;
};


export type Query_RootTenantAddressesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Addresses_Order_By>>;
  where?: InputMaybe<Tenant_Addresses_Bool_Exp>;
};


export type Query_RootTenantBankAccountByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootTenantBankAccountsArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Bank_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Bank_Accounts_Order_By>>;
  where?: InputMaybe<Tenant_Bank_Accounts_Bool_Exp>;
};


export type Query_RootTenantBankAccountsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Bank_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Bank_Accounts_Order_By>>;
  where?: InputMaybe<Tenant_Bank_Accounts_Bool_Exp>;
};


export type Query_RootTenantByPkArgs = {
  tenant: Scalars['String'];
};


export type Query_RootTenantCompanyDetailByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootTenantCompanyDetailsArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Company_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Company_Details_Order_By>>;
  where?: InputMaybe<Tenant_Company_Details_Bool_Exp>;
};


export type Query_RootTenantCompanyDetailsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Company_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Company_Details_Order_By>>;
  where?: InputMaybe<Tenant_Company_Details_Bool_Exp>;
};


export type Query_RootTenantConfigurationByPkArgs = {
  tenant: Scalars['String'];
};


export type Query_RootTenantConfigurationsArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Configurations_Order_By>>;
  where?: InputMaybe<Tenant_Configurations_Bool_Exp>;
};


export type Query_RootTenantConfigurationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Configurations_Order_By>>;
  where?: InputMaybe<Tenant_Configurations_Bool_Exp>;
};


export type Query_RootTenantInfoWithTokenResultsArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Info_With_Token_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Info_With_Token_Result_Order_By>>;
  where?: InputMaybe<Tenant_Info_With_Token_Result_Bool_Exp>;
};


export type Query_RootTenantInfoWithTokenResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Info_With_Token_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Info_With_Token_Result_Order_By>>;
  where?: InputMaybe<Tenant_Info_With_Token_Result_Bool_Exp>;
};


export type Query_RootTenantLanguageByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootTenantLanguagesArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Languages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Languages_Order_By>>;
  where?: InputMaybe<Tenant_Languages_Bool_Exp>;
};


export type Query_RootTenantLanguagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Languages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Languages_Order_By>>;
  where?: InputMaybe<Tenant_Languages_Bool_Exp>;
};


export type Query_RootTenantPermissionByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootTenantPermissionsArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Permissions_Order_By>>;
  where?: InputMaybe<Tenant_Permissions_Bool_Exp>;
};


export type Query_RootTenantPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Permissions_Order_By>>;
  where?: InputMaybe<Tenant_Permissions_Bool_Exp>;
};


export type Query_RootTenantsArgs = {
  distinct_on?: InputMaybe<Array<Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenants_Order_By>>;
  where?: InputMaybe<Tenants_Bool_Exp>;
};


export type Query_RootTenantsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenants_Order_By>>;
  where?: InputMaybe<Tenants_Bool_Exp>;
};


export type Query_RootTodays_TodosArgs = {
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


export type Query_RootTodays_Todos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


export type Query_RootTodoActionByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootTodoActionsArgs = {
  distinct_on?: InputMaybe<Array<Todo_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todo_Actions_Order_By>>;
  where?: InputMaybe<Todo_Actions_Bool_Exp>;
};


export type Query_RootTodoActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Todo_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todo_Actions_Order_By>>;
  where?: InputMaybe<Todo_Actions_Bool_Exp>;
};


export type Query_RootTodoByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootTodoRecurrenceByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootTodoRecurrencesArgs = {
  distinct_on?: InputMaybe<Array<Todo_Recurrences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todo_Recurrences_Order_By>>;
  where?: InputMaybe<Todo_Recurrences_Bool_Exp>;
};


export type Query_RootTodoRecurrencesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Todo_Recurrences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todo_Recurrences_Order_By>>;
  where?: InputMaybe<Todo_Recurrences_Bool_Exp>;
};


export type Query_RootTodoStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootTodoStatesArgs = {
  distinct_on?: InputMaybe<Array<Todo_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todo_States_Order_By>>;
  where?: InputMaybe<Todo_States_Bool_Exp>;
};


export type Query_RootTodoStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Todo_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todo_States_Order_By>>;
  where?: InputMaybe<Todo_States_Bool_Exp>;
};


export type Query_RootTodosArgs = {
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


export type Query_RootTodosAggregateArgs = {
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


export type Query_RootTodos_On_DayArgs = {
  args: Todos_On_Day_Args;
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


export type Query_RootTodos_On_Day_AggregateArgs = {
  args: Todos_On_Day_Args;
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


export type Query_RootTopologicallySortedCuttingPatternsResultsArgs = {
  distinct_on?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Order_By>>;
  where?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Bool_Exp>;
};


export type Query_RootTopologicallySortedCuttingPatternsResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Order_By>>;
  where?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Bool_Exp>;
};


export type Query_RootTopologically_Sorted_Cutting_PatternsArgs = {
  args: Topologically_Sorted_Cutting_Patterns_Args;
  distinct_on?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Order_By>>;
  where?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Bool_Exp>;
};


export type Query_RootTopologically_Sorted_Cutting_Patterns_AggregateArgs = {
  args: Topologically_Sorted_Cutting_Patterns_Args;
  distinct_on?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Order_By>>;
  where?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Bool_Exp>;
};


export type Query_RootTourByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootTourRouteByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootTourRoutesArgs = {
  distinct_on?: InputMaybe<Array<Tour_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tour_Routes_Order_By>>;
  where?: InputMaybe<Tour_Routes_Bool_Exp>;
};


export type Query_RootTourRoutesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tour_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tour_Routes_Order_By>>;
  where?: InputMaybe<Tour_Routes_Bool_Exp>;
};


export type Query_RootTourStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootTourStateTransitionByPkArgs = {
  fromState: Tour_States_Enum;
  toState: Tour_States_Enum;
};


export type Query_RootTourStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Tour_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tour_State_Transitions_Order_By>>;
  where?: InputMaybe<Tour_State_Transitions_Bool_Exp>;
};


export type Query_RootTourStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tour_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tour_State_Transitions_Order_By>>;
  where?: InputMaybe<Tour_State_Transitions_Bool_Exp>;
};


export type Query_RootTourStatesArgs = {
  distinct_on?: InputMaybe<Array<Tour_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tour_States_Order_By>>;
  where?: InputMaybe<Tour_States_Bool_Exp>;
};


export type Query_RootTourStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tour_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tour_States_Order_By>>;
  where?: InputMaybe<Tour_States_Bool_Exp>;
};


export type Query_RootToursArgs = {
  distinct_on?: InputMaybe<Array<Tours_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tours_Order_By>>;
  where?: InputMaybe<Tours_Bool_Exp>;
};


export type Query_RootToursAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tours_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tours_Order_By>>;
  where?: InputMaybe<Tours_Bool_Exp>;
};


export type Query_RootUnitByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootUnitsArgs = {
  distinct_on?: InputMaybe<Array<Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Units_Order_By>>;
  where?: InputMaybe<Units_Bool_Exp>;
};


export type Query_RootUnitsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Units_Order_By>>;
  where?: InputMaybe<Units_Bool_Exp>;
};


export type Query_RootUserByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootUserRolesArgs = {
  userId: Scalars['bigint'];
};


export type Query_RootUserSalesGoalByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootUserSalesGoalsArgs = {
  distinct_on?: InputMaybe<Array<User_Sales_Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Sales_Goals_Order_By>>;
  where?: InputMaybe<User_Sales_Goals_Bool_Exp>;
};


export type Query_RootUserSalesGoalsAggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Sales_Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Sales_Goals_Order_By>>;
  where?: InputMaybe<User_Sales_Goals_Bool_Exp>;
};


export type Query_RootUserStateByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootUserStateTransitionByPkArgs = {
  fromState: User_States_Enum;
  toState: User_States_Enum;
};


export type Query_RootUserStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<User_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_State_Transitions_Order_By>>;
  where?: InputMaybe<User_State_Transitions_Bool_Exp>;
};


export type Query_RootUserStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<User_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_State_Transitions_Order_By>>;
  where?: InputMaybe<User_State_Transitions_Bool_Exp>;
};


export type Query_RootUserStatesArgs = {
  distinct_on?: InputMaybe<Array<User_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_States_Order_By>>;
  where?: InputMaybe<User_States_Bool_Exp>;
};


export type Query_RootUserStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<User_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_States_Order_By>>;
  where?: InputMaybe<User_States_Bool_Exp>;
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootVatTypeByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootVatTypesArgs = {
  distinct_on?: InputMaybe<Array<Vat_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vat_Types_Order_By>>;
  where?: InputMaybe<Vat_Types_Bool_Exp>;
};


export type Query_RootVatTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Vat_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vat_Types_Order_By>>;
  where?: InputMaybe<Vat_Types_Bool_Exp>;
};


export type Query_RootWorkCalendarByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWorkCalendarLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Work_Calendar_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Work_Calendar_Logged_Actions_Order_By>>;
  where?: InputMaybe<Work_Calendar_Logged_Actions_Bool_Exp>;
};


export type Query_RootWorkCalendarLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Work_Calendar_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Work_Calendar_Logged_Actions_Order_By>>;
  where?: InputMaybe<Work_Calendar_Logged_Actions_Bool_Exp>;
};


export type Query_RootWorkCalendarsArgs = {
  distinct_on?: InputMaybe<Array<Work_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Work_Calendar_Order_By>>;
  where?: InputMaybe<Work_Calendar_Bool_Exp>;
};


export type Query_RootWorkCalendarsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Work_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Work_Calendar_Order_By>>;
  where?: InputMaybe<Work_Calendar_Bool_Exp>;
};


export type Query_RootWorkingDayByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootWorkingDaysArgs = {
  distinct_on?: InputMaybe<Array<Working_Days_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Working_Days_Order_By>>;
  where?: InputMaybe<Working_Days_Bool_Exp>;
};


export type Query_RootWorkingDaysAggregateArgs = {
  distinct_on?: InputMaybe<Array<Working_Days_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Working_Days_Order_By>>;
  where?: InputMaybe<Working_Days_Bool_Exp>;
};


export type Query_RootWorkstationByPkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWorkstationHardwareDeviceConnectionByPkArgs = {
  connectionType: Hardware_Device_Connection_Types_Enum;
  hardwareDeviceId: Scalars['bigint'];
  workstationId: Scalars['bigint'];
};


export type Query_RootWorkstationHardwareDeviceConnectionsArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Order_By>>;
  where?: InputMaybe<Workstation_Hardware_Device_Connections_Bool_Exp>;
};


export type Query_RootWorkstationHardwareDeviceConnectionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Order_By>>;
  where?: InputMaybe<Workstation_Hardware_Device_Connections_Bool_Exp>;
};


export type Query_RootWorkstationPickingCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Picking_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Picking_Categories_Order_By>>;
  where?: InputMaybe<Workstation_Picking_Categories_Bool_Exp>;
};


export type Query_RootWorkstationPickingCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Picking_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Picking_Categories_Order_By>>;
  where?: InputMaybe<Workstation_Picking_Categories_Bool_Exp>;
};


export type Query_RootWorkstationsArgs = {
  distinct_on?: InputMaybe<Array<Workstations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstations_Order_By>>;
  where?: InputMaybe<Workstations_Bool_Exp>;
};


export type Query_RootWorkstationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Workstations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstations_Order_By>>;
  where?: InputMaybe<Workstations_Bool_Exp>;
};

export type Recalculate_Unlocked_Coi_Prices_Args = {
  _customer_order_id?: InputMaybe<Scalars['bigint']>;
};

export type Request_Cutting_Pattern_Draft_Approval_Args = {
  _valid_from?: InputMaybe<Scalars['timestamptz']>;
};

export type Revert_Journal_Entries_Args = {
  _journal_entry_ids?: InputMaybe<Scalars['_int8']>;
  _keep_entry_valid_time?: InputMaybe<Scalars['Boolean']>;
};

export type Revert_Jscript_Template_From_Logged_Action_Args = {
  _event_id?: InputMaybe<Scalars['bigint']>;
  _template_id?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "role_permissions" */
export type Role_Permissions = {
  __typename: 'role_permissions';
  action: Scalars['String'];
  conditions?: Maybe<Scalars['jsonb']>;
  fields?: Maybe<Scalars['_text']>;
  inverted?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
};


/** columns and relationships of "role_permissions" */
export type Role_PermissionsConditionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "role_permissions" */
export type Role_Permissions_Aggregate = {
  __typename: 'role_permissions_aggregate';
  aggregate?: Maybe<Role_Permissions_Aggregate_Fields>;
  nodes: Array<Role_Permissions>;
};

export type Role_Permissions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Role_Permissions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Role_Permissions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Role_Permissions_Aggregate_Bool_Exp_Count>;
};

export type Role_Permissions_Aggregate_Bool_Exp_Bool_And = {
  arguments: Role_Permissions_Select_Column_Role_Permissions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Role_Permissions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Role_Permissions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Role_Permissions_Select_Column_Role_Permissions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Role_Permissions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Role_Permissions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Role_Permissions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "role_permissions" */
export type Role_Permissions_Aggregate_Fields = {
  __typename: 'role_permissions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Role_Permissions_Max_Fields>;
  min?: Maybe<Role_Permissions_Min_Fields>;
};


/** aggregate fields of "role_permissions" */
export type Role_Permissions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "role_permissions" */
export type Role_Permissions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Role_Permissions_Max_Order_By>;
  min?: InputMaybe<Role_Permissions_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "role_permissions". All fields are combined with a logical 'AND'. */
export type Role_Permissions_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Permissions_Bool_Exp>>;
  _not?: InputMaybe<Role_Permissions_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Permissions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  conditions?: InputMaybe<Jsonb_Comparison_Exp>;
  fields?: InputMaybe<_Text_Comparison_Exp>;
  inverted?: InputMaybe<Boolean_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Role_Permissions_Max_Fields = {
  __typename: 'role_permissions_max_fields';
  action?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "role_permissions" */
export type Role_Permissions_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Role_Permissions_Min_Fields = {
  __typename: 'role_permissions_min_fields';
  action?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "role_permissions" */
export type Role_Permissions_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "role_permissions". */
export type Role_Permissions_Order_By = {
  action?: InputMaybe<Order_By>;
  conditions?: InputMaybe<Order_By>;
  fields?: InputMaybe<Order_By>;
  inverted?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
};

/** select columns of table "role_permissions" */
export enum Role_Permissions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  Conditions = 'conditions',
  /** column name */
  Fields = 'fields',
  /** column name */
  Inverted = 'inverted',
  /** column name */
  Reason = 'reason',
  /** column name */
  Subject = 'subject'
}

/** select "role_permissions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "role_permissions" */
export enum Role_Permissions_Select_Column_Role_Permissions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Inverted = 'inverted'
}

/** select "role_permissions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "role_permissions" */
export enum Role_Permissions_Select_Column_Role_Permissions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Inverted = 'inverted'
}

/** Streaming cursor of the table "role_permissions" */
export type Role_Permissions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Permissions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Permissions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  conditions?: InputMaybe<Scalars['jsonb']>;
  fields?: InputMaybe<Scalars['_text']>;
  inverted?: InputMaybe<Scalars['Boolean']>;
  reason?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "roles" */
export type Roles = {
  __typename: 'roles';
  comment?: Maybe<Scalars['String']>;
  /** fetch data from the table: "role_permissions" */
  rolePermissions: Array<Role_Permissions>;
  /** An aggregate relationship */
  rolePermissions_aggregate: Role_Permissions_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "roles" */
export type RolesRolePermissionsArgs = {
  distinct_on?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Permissions_Order_By>>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};


/** columns and relationships of "roles" */
export type RolesRolePermissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Permissions_Order_By>>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};

/** aggregated selection of "roles" */
export type Roles_Aggregate = {
  __typename: 'roles_aggregate';
  aggregate?: Maybe<Roles_Aggregate_Fields>;
  nodes: Array<Roles>;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_Fields = {
  __typename: 'roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Roles_Max_Fields>;
  min?: Maybe<Roles_Min_Fields>;
};


/** aggregate fields of "roles" */
export type Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Roles_Bool_Exp>>;
  _not?: InputMaybe<Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Roles_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  rolePermissions?: InputMaybe<Role_Permissions_Bool_Exp>;
  rolePermissions_aggregate?: InputMaybe<Role_Permissions_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Roles_Max_Fields = {
  __typename: 'roles_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Roles_Min_Fields = {
  __typename: 'roles_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "roles". */
export type Roles_Order_By = {
  comment?: InputMaybe<Order_By>;
  rolePermissions_aggregate?: InputMaybe<Role_Permissions_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "roles" */
export type Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Roles_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "rolling_inventories" */
export type Rolling_Inventories = {
  __typename: 'rolling_inventories';
  /** An object relationship */
  Tenant: Tenants;
  committedAt?: Maybe<Scalars['timestamptz']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  journalEntry?: Maybe<Journal_Entries>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  rollingInventoryActors: Array<Rolling_Inventory_Actors>;
  /** An aggregate relationship */
  rollingInventoryActors_aggregate: Rolling_Inventory_Actors_Aggregate;
  /** An array relationship */
  rollingInventoryItems: Array<Rolling_Inventory_Items>;
  /** An aggregate relationship */
  rollingInventoryItems_aggregate: Rolling_Inventory_Items_Aggregate;
  startedAt?: Maybe<Scalars['timestamptz']>;
  state: Rolling_Inventory_States_Enum;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "rolling_inventories" */
export type Rolling_InventoriesRollingInventoryActorsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Actors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Actors_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
};


/** columns and relationships of "rolling_inventories" */
export type Rolling_InventoriesRollingInventoryActors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Actors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Actors_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
};


/** columns and relationships of "rolling_inventories" */
export type Rolling_InventoriesRollingInventoryItemsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "rolling_inventories" */
export type Rolling_InventoriesRollingInventoryItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};

/** aggregated selection of "rolling_inventories" */
export type Rolling_Inventories_Aggregate = {
  __typename: 'rolling_inventories_aggregate';
  aggregate?: Maybe<Rolling_Inventories_Aggregate_Fields>;
  nodes: Array<Rolling_Inventories>;
};

export type Rolling_Inventories_Aggregate_Bool_Exp = {
  count?: InputMaybe<Rolling_Inventories_Aggregate_Bool_Exp_Count>;
};

export type Rolling_Inventories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rolling_Inventories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rolling_Inventories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rolling_inventories" */
export type Rolling_Inventories_Aggregate_Fields = {
  __typename: 'rolling_inventories_aggregate_fields';
  avg?: Maybe<Rolling_Inventories_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rolling_Inventories_Max_Fields>;
  min?: Maybe<Rolling_Inventories_Min_Fields>;
  stddev?: Maybe<Rolling_Inventories_Stddev_Fields>;
  stddev_pop?: Maybe<Rolling_Inventories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rolling_Inventories_Stddev_Samp_Fields>;
  sum?: Maybe<Rolling_Inventories_Sum_Fields>;
  var_pop?: Maybe<Rolling_Inventories_Var_Pop_Fields>;
  var_samp?: Maybe<Rolling_Inventories_Var_Samp_Fields>;
  variance?: Maybe<Rolling_Inventories_Variance_Fields>;
};


/** aggregate fields of "rolling_inventories" */
export type Rolling_Inventories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rolling_Inventories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rolling_inventories" */
export type Rolling_Inventories_Aggregate_Order_By = {
  avg?: InputMaybe<Rolling_Inventories_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rolling_Inventories_Max_Order_By>;
  min?: InputMaybe<Rolling_Inventories_Min_Order_By>;
  stddev?: InputMaybe<Rolling_Inventories_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rolling_Inventories_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rolling_Inventories_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rolling_Inventories_Sum_Order_By>;
  var_pop?: InputMaybe<Rolling_Inventories_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rolling_Inventories_Var_Samp_Order_By>;
  variance?: InputMaybe<Rolling_Inventories_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rolling_inventories" */
export type Rolling_Inventories_Arr_Rel_Insert_Input = {
  data: Array<Rolling_Inventories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Rolling_Inventories_On_Conflict>;
};

/** aggregate avg on columns */
export type Rolling_Inventories_Avg_Fields = {
  __typename: 'rolling_inventories_avg_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "rolling_inventories" */
export type Rolling_Inventories_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rolling_inventories". All fields are combined with a logical 'AND'. */
export type Rolling_Inventories_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Rolling_Inventories_Bool_Exp>>;
  _not?: InputMaybe<Rolling_Inventories_Bool_Exp>;
  _or?: InputMaybe<Array<Rolling_Inventories_Bool_Exp>>;
  committedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  journalEntry?: InputMaybe<Journal_Entries_Bool_Exp>;
  journalEntryId?: InputMaybe<Bigint_Comparison_Exp>;
  rollingInventoryActors?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
  rollingInventoryActors_aggregate?: InputMaybe<Rolling_Inventory_Actors_Aggregate_Bool_Exp>;
  rollingInventoryItems?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
  rollingInventoryItems_aggregate?: InputMaybe<Rolling_Inventory_Items_Aggregate_Bool_Exp>;
  startedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  state?: InputMaybe<Rolling_Inventory_States_Enum_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "rolling_inventories" */
export enum Rolling_Inventories_Constraint {
  /** unique or primary key constraint on columns "id" */
  RollingInventoriesPkey = 'rolling_inventories_pkey'
}

/** input type for incrementing numeric columns in table "rolling_inventories" */
export type Rolling_Inventories_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "rolling_inventories" */
export type Rolling_Inventories_Insert_Input = {
  committedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntry?: InputMaybe<Journal_Entries_Obj_Rel_Insert_Input>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  rollingInventoryActors?: InputMaybe<Rolling_Inventory_Actors_Arr_Rel_Insert_Input>;
  rollingInventoryItems?: InputMaybe<Rolling_Inventory_Items_Arr_Rel_Insert_Input>;
  startedAt?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Rolling_Inventory_States_Enum>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Rolling_Inventories_Max_Fields = {
  __typename: 'rolling_inventories_max_fields';
  committedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  startedAt?: Maybe<Scalars['timestamptz']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "rolling_inventories" */
export type Rolling_Inventories_Max_Order_By = {
  committedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  startedAt?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rolling_Inventories_Min_Fields = {
  __typename: 'rolling_inventories_min_fields';
  committedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  startedAt?: Maybe<Scalars['timestamptz']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "rolling_inventories" */
export type Rolling_Inventories_Min_Order_By = {
  committedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  startedAt?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rolling_inventories" */
export type Rolling_Inventories_Mutation_Response = {
  __typename: 'rolling_inventories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rolling_Inventories>;
};

/** input type for inserting object relation for remote table "rolling_inventories" */
export type Rolling_Inventories_Obj_Rel_Insert_Input = {
  data: Rolling_Inventories_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Rolling_Inventories_On_Conflict>;
};

/** on_conflict condition type for table "rolling_inventories" */
export type Rolling_Inventories_On_Conflict = {
  constraint: Rolling_Inventories_Constraint;
  update_columns?: Array<Rolling_Inventories_Update_Column>;
  where?: InputMaybe<Rolling_Inventories_Bool_Exp>;
};

/** Ordering options when selecting data from "rolling_inventories". */
export type Rolling_Inventories_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  committedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntry?: InputMaybe<Journal_Entries_Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  rollingInventoryActors_aggregate?: InputMaybe<Rolling_Inventory_Actors_Aggregate_Order_By>;
  rollingInventoryItems_aggregate?: InputMaybe<Rolling_Inventory_Items_Aggregate_Order_By>;
  startedAt?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rolling_inventories */
export type Rolling_Inventories_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "rolling_inventories" */
export enum Rolling_Inventories_Select_Column {
  /** column name */
  CommittedAt = 'committedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  JournalEntryId = 'journalEntryId',
  /** column name */
  StartedAt = 'startedAt',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "rolling_inventories" */
export type Rolling_Inventories_Set_Input = {
  committedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  startedAt?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Rolling_Inventory_States_Enum>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Rolling_Inventories_Stddev_Fields = {
  __typename: 'rolling_inventories_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "rolling_inventories" */
export type Rolling_Inventories_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rolling_Inventories_Stddev_Pop_Fields = {
  __typename: 'rolling_inventories_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "rolling_inventories" */
export type Rolling_Inventories_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rolling_Inventories_Stddev_Samp_Fields = {
  __typename: 'rolling_inventories_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "rolling_inventories" */
export type Rolling_Inventories_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "rolling_inventories" */
export type Rolling_Inventories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rolling_Inventories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rolling_Inventories_Stream_Cursor_Value_Input = {
  committedAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  startedAt?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Rolling_Inventory_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Rolling_Inventories_Sum_Fields = {
  __typename: 'rolling_inventories_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "rolling_inventories" */
export type Rolling_Inventories_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "rolling_inventories" */
export enum Rolling_Inventories_Update_Column {
  /** column name */
  CommittedAt = 'committedAt',
  /** column name */
  Id = 'id',
  /** column name */
  JournalEntryId = 'journalEntryId',
  /** column name */
  StartedAt = 'startedAt',
  /** column name */
  State = 'state',
  /** column name */
  Version = 'version'
}

export type Rolling_Inventories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Rolling_Inventories_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rolling_Inventories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rolling_Inventories_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Rolling_Inventories_Var_Pop_Fields = {
  __typename: 'rolling_inventories_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "rolling_inventories" */
export type Rolling_Inventories_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rolling_Inventories_Var_Samp_Fields = {
  __typename: 'rolling_inventories_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "rolling_inventories" */
export type Rolling_Inventories_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rolling_Inventories_Variance_Fields = {
  __typename: 'rolling_inventories_variance_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "rolling_inventories" */
export type Rolling_Inventories_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "rolling_inventory_actors" */
export type Rolling_Inventory_Actors = {
  __typename: 'rolling_inventory_actors';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  rollingInventory: Rolling_Inventories;
  rollingInventoryId: Scalars['bigint'];
  /** An array relationship */
  rollingInventoryItems: Array<Rolling_Inventory_Items>;
  /** An aggregate relationship */
  rollingInventoryItems_aggregate: Rolling_Inventory_Items_Aggregate;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  userId: Scalars['bigint'];
  version: Scalars['bigint'];
  /** An object relationship */
  workstation?: Maybe<Workstations>;
  workstationId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "rolling_inventory_actors" */
export type Rolling_Inventory_ActorsRollingInventoryItemsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "rolling_inventory_actors" */
export type Rolling_Inventory_ActorsRollingInventoryItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};

/** aggregated selection of "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Aggregate = {
  __typename: 'rolling_inventory_actors_aggregate';
  aggregate?: Maybe<Rolling_Inventory_Actors_Aggregate_Fields>;
  nodes: Array<Rolling_Inventory_Actors>;
};

export type Rolling_Inventory_Actors_Aggregate_Bool_Exp = {
  count?: InputMaybe<Rolling_Inventory_Actors_Aggregate_Bool_Exp_Count>;
};

export type Rolling_Inventory_Actors_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rolling_Inventory_Actors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Aggregate_Fields = {
  __typename: 'rolling_inventory_actors_aggregate_fields';
  avg?: Maybe<Rolling_Inventory_Actors_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rolling_Inventory_Actors_Max_Fields>;
  min?: Maybe<Rolling_Inventory_Actors_Min_Fields>;
  stddev?: Maybe<Rolling_Inventory_Actors_Stddev_Fields>;
  stddev_pop?: Maybe<Rolling_Inventory_Actors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rolling_Inventory_Actors_Stddev_Samp_Fields>;
  sum?: Maybe<Rolling_Inventory_Actors_Sum_Fields>;
  var_pop?: Maybe<Rolling_Inventory_Actors_Var_Pop_Fields>;
  var_samp?: Maybe<Rolling_Inventory_Actors_Var_Samp_Fields>;
  variance?: Maybe<Rolling_Inventory_Actors_Variance_Fields>;
};


/** aggregate fields of "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rolling_Inventory_Actors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Aggregate_Order_By = {
  avg?: InputMaybe<Rolling_Inventory_Actors_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rolling_Inventory_Actors_Max_Order_By>;
  min?: InputMaybe<Rolling_Inventory_Actors_Min_Order_By>;
  stddev?: InputMaybe<Rolling_Inventory_Actors_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rolling_Inventory_Actors_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rolling_Inventory_Actors_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rolling_Inventory_Actors_Sum_Order_By>;
  var_pop?: InputMaybe<Rolling_Inventory_Actors_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rolling_Inventory_Actors_Var_Samp_Order_By>;
  variance?: InputMaybe<Rolling_Inventory_Actors_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Arr_Rel_Insert_Input = {
  data: Array<Rolling_Inventory_Actors_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Rolling_Inventory_Actors_On_Conflict>;
};

/** aggregate avg on columns */
export type Rolling_Inventory_Actors_Avg_Fields = {
  __typename: 'rolling_inventory_actors_avg_fields';
  id?: Maybe<Scalars['Float']>;
  rollingInventoryId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rolling_inventory_actors". All fields are combined with a logical 'AND'. */
export type Rolling_Inventory_Actors_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Rolling_Inventory_Actors_Bool_Exp>>;
  _not?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
  _or?: InputMaybe<Array<Rolling_Inventory_Actors_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  rollingInventory?: InputMaybe<Rolling_Inventories_Bool_Exp>;
  rollingInventoryId?: InputMaybe<Bigint_Comparison_Exp>;
  rollingInventoryItems?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
  rollingInventoryItems_aggregate?: InputMaybe<Rolling_Inventory_Items_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
  workstation?: InputMaybe<Workstations_Bool_Exp>;
  workstationId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "rolling_inventory_actors" */
export enum Rolling_Inventory_Actors_Constraint {
  /** unique or primary key constraint on columns "id" */
  RollingInventoryActorsPkey = 'rolling_inventory_actors_pkey',
  /** unique or primary key constraint on columns "user_id", "rolling_inventory_id" */
  RollingInventoryActorsRollingInventoryIdUserIdIdx = 'rolling_inventory_actors_rolling_inventory_id_user_id_idx',
  /** unique or primary key constraint on columns "workstation_id", "user_id", "rolling_inventory_id" */
  RollingInventoryActorsRollingInventoryIdWorkstationIIdx = 'rolling_inventory_actors_rolling_inventory_id_workstation_i_idx'
}

/** input type for incrementing numeric columns in table "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  rollingInventoryId?: InputMaybe<Scalars['bigint']>;
  userId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  workstationId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  rollingInventory?: InputMaybe<Rolling_Inventories_Obj_Rel_Insert_Input>;
  rollingInventoryId?: InputMaybe<Scalars['bigint']>;
  rollingInventoryItems?: InputMaybe<Rolling_Inventory_Items_Arr_Rel_Insert_Input>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  workstation?: InputMaybe<Workstations_Obj_Rel_Insert_Input>;
  workstationId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Rolling_Inventory_Actors_Max_Fields = {
  __typename: 'rolling_inventory_actors_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  rollingInventoryId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
  workstationId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rolling_Inventory_Actors_Min_Fields = {
  __typename: 'rolling_inventory_actors_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  rollingInventoryId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
  workstationId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Mutation_Response = {
  __typename: 'rolling_inventory_actors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rolling_Inventory_Actors>;
};

/** input type for inserting object relation for remote table "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Obj_Rel_Insert_Input = {
  data: Rolling_Inventory_Actors_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Rolling_Inventory_Actors_On_Conflict>;
};

/** on_conflict condition type for table "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_On_Conflict = {
  constraint: Rolling_Inventory_Actors_Constraint;
  update_columns?: Array<Rolling_Inventory_Actors_Update_Column>;
  where?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
};

/** Ordering options when selecting data from "rolling_inventory_actors". */
export type Rolling_Inventory_Actors_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rollingInventory?: InputMaybe<Rolling_Inventories_Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  rollingInventoryItems_aggregate?: InputMaybe<Rolling_Inventory_Items_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  workstation?: InputMaybe<Workstations_Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rolling_inventory_actors */
export type Rolling_Inventory_Actors_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "rolling_inventory_actors" */
export enum Rolling_Inventory_Actors_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  RollingInventoryId = 'rollingInventoryId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Version = 'version',
  /** column name */
  WorkstationId = 'workstationId'
}

/** input type for updating data in table "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  rollingInventoryId?: InputMaybe<Scalars['bigint']>;
  userId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  workstationId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Rolling_Inventory_Actors_Stddev_Fields = {
  __typename: 'rolling_inventory_actors_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  rollingInventoryId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rolling_Inventory_Actors_Stddev_Pop_Fields = {
  __typename: 'rolling_inventory_actors_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rollingInventoryId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rolling_Inventory_Actors_Stddev_Samp_Fields = {
  __typename: 'rolling_inventory_actors_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rollingInventoryId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rolling_Inventory_Actors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rolling_Inventory_Actors_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  rollingInventoryId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  workstationId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Rolling_Inventory_Actors_Sum_Fields = {
  __typename: 'rolling_inventory_actors_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  rollingInventoryId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
  workstationId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** update columns of table "rolling_inventory_actors" */
export enum Rolling_Inventory_Actors_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  RollingInventoryId = 'rollingInventoryId',
  /** column name */
  UserId = 'userId',
  /** column name */
  Version = 'version',
  /** column name */
  WorkstationId = 'workstationId'
}

export type Rolling_Inventory_Actors_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Rolling_Inventory_Actors_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rolling_Inventory_Actors_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rolling_Inventory_Actors_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Rolling_Inventory_Actors_Var_Pop_Fields = {
  __typename: 'rolling_inventory_actors_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rollingInventoryId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rolling_Inventory_Actors_Var_Samp_Fields = {
  __typename: 'rolling_inventory_actors_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rollingInventoryId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rolling_Inventory_Actors_Variance_Fields = {
  __typename: 'rolling_inventory_actors_variance_fields';
  id?: Maybe<Scalars['Float']>;
  rollingInventoryId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "rolling_inventory_actors" */
export type Rolling_Inventory_Actors_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** columns and relationships of "rolling_inventory_items" */
export type Rolling_Inventory_Items = {
  __typename: 'rolling_inventory_items';
  /** An object relationship */
  LotNumber?: Maybe<Lot_Numbers>;
  /** An object relationship */
  Tenant: Tenants;
  containerCount: Scalars['bigint'];
  createdAt: Scalars['timestamptz'];
  data?: Maybe<Scalars['jsonb']>;
  evict?: Maybe<Scalars['Boolean']>;
  id: Scalars['bigint'];
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  monetaryQuantity: Scalars['bigint'];
  /** An object relationship */
  monetaryUnit: Product_Units;
  monetaryUnitId: Scalars['bigint'];
  netWeight?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  /** An object relationship */
  rollingInventory: Rolling_Inventories;
  /** An object relationship */
  rollingInventoryActor: Rolling_Inventory_Actors;
  rollingInventoryActorId: Scalars['bigint'];
  rollingInventoryId: Scalars['bigint'];
  /** An object relationship */
  stockLocation: Stock_Locations;
  stockLocationId: Scalars['bigint'];
  storageQuantity: Scalars['bigint'];
  /** An object relationship */
  storageUnit: Product_Units;
  storageUnitId: Scalars['bigint'];
  tareWeight: Scalars['bigint'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
  /** An object relationship */
  weightUnit?: Maybe<Product_Units>;
  weightUnitId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "rolling_inventory_items" */
export type Rolling_Inventory_ItemsDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "rolling_inventory_items" */
export type Rolling_Inventory_Items_Aggregate = {
  __typename: 'rolling_inventory_items_aggregate';
  aggregate?: Maybe<Rolling_Inventory_Items_Aggregate_Fields>;
  nodes: Array<Rolling_Inventory_Items>;
};

export type Rolling_Inventory_Items_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Rolling_Inventory_Items_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Rolling_Inventory_Items_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Rolling_Inventory_Items_Aggregate_Bool_Exp_Count>;
};

export type Rolling_Inventory_Items_Aggregate_Bool_Exp_Bool_And = {
  arguments: Rolling_Inventory_Items_Select_Column_Rolling_Inventory_Items_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Rolling_Inventory_Items_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Rolling_Inventory_Items_Select_Column_Rolling_Inventory_Items_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Rolling_Inventory_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rolling_inventory_items" */
export type Rolling_Inventory_Items_Aggregate_Fields = {
  __typename: 'rolling_inventory_items_aggregate_fields';
  avg?: Maybe<Rolling_Inventory_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rolling_Inventory_Items_Max_Fields>;
  min?: Maybe<Rolling_Inventory_Items_Min_Fields>;
  stddev?: Maybe<Rolling_Inventory_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Rolling_Inventory_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rolling_Inventory_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Rolling_Inventory_Items_Sum_Fields>;
  var_pop?: Maybe<Rolling_Inventory_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Rolling_Inventory_Items_Var_Samp_Fields>;
  variance?: Maybe<Rolling_Inventory_Items_Variance_Fields>;
};


/** aggregate fields of "rolling_inventory_items" */
export type Rolling_Inventory_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rolling_inventory_items" */
export type Rolling_Inventory_Items_Aggregate_Order_By = {
  avg?: InputMaybe<Rolling_Inventory_Items_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rolling_Inventory_Items_Max_Order_By>;
  min?: InputMaybe<Rolling_Inventory_Items_Min_Order_By>;
  stddev?: InputMaybe<Rolling_Inventory_Items_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rolling_Inventory_Items_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rolling_Inventory_Items_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rolling_Inventory_Items_Sum_Order_By>;
  var_pop?: InputMaybe<Rolling_Inventory_Items_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rolling_Inventory_Items_Var_Samp_Order_By>;
  variance?: InputMaybe<Rolling_Inventory_Items_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Rolling_Inventory_Items_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "rolling_inventory_items" */
export type Rolling_Inventory_Items_Arr_Rel_Insert_Input = {
  data: Array<Rolling_Inventory_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Rolling_Inventory_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Rolling_Inventory_Items_Avg_Fields = {
  __typename: 'rolling_inventory_items_avg_fields';
  containerCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rollingInventoryActorId?: Maybe<Scalars['Float']>;
  rollingInventoryId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightUnitId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "rolling_inventory_items" */
export type Rolling_Inventory_Items_Avg_Order_By = {
  containerCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rollingInventoryActorId?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rolling_inventory_items". All fields are combined with a logical 'AND'. */
export type Rolling_Inventory_Items_Bool_Exp = {
  LotNumber?: InputMaybe<Lot_Numbers_Bool_Exp>;
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Rolling_Inventory_Items_Bool_Exp>>;
  _not?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Rolling_Inventory_Items_Bool_Exp>>;
  containerCount?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  evict?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  lotNumber?: InputMaybe<String_Comparison_Exp>;
  lotNumberId?: InputMaybe<Bigint_Comparison_Exp>;
  monetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  monetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  monetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  netWeight?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  rollingInventory?: InputMaybe<Rolling_Inventories_Bool_Exp>;
  rollingInventoryActor?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
  rollingInventoryActorId?: InputMaybe<Bigint_Comparison_Exp>;
  rollingInventoryId?: InputMaybe<Bigint_Comparison_Exp>;
  stockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  stockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  storageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  storageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  storageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  tareWeight?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
  weightUnit?: InputMaybe<Product_Units_Bool_Exp>;
  weightUnitId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "rolling_inventory_items" */
export enum Rolling_Inventory_Items_Constraint {
  /** unique or primary key constraint on columns "id" */
  RollingInventoryItemsPkey = 'rolling_inventory_items_pkey',
  /** unique or primary key constraint on columns "monetary_unit_id", "storage_unit_id", "stock_location_id", "product_id", "rolling_inventory_id" */
  RollingInventoryItemsRollingInventoryIdStockLocationIdx = 'rolling_inventory_items_rolling_inventory_id_stock_location_idx'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Rolling_Inventory_Items_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Rolling_Inventory_Items_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Rolling_Inventory_Items_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "rolling_inventory_items" */
export type Rolling_Inventory_Items_Inc_Input = {
  containerCount?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  monetaryQuantity?: InputMaybe<Scalars['bigint']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  netWeight?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  rollingInventoryActorId?: InputMaybe<Scalars['bigint']>;
  rollingInventoryId?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  storageQuantity?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
  tareWeight?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  weightUnitId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "rolling_inventory_items" */
export type Rolling_Inventory_Items_Insert_Input = {
  LotNumber?: InputMaybe<Lot_Numbers_Obj_Rel_Insert_Input>;
  containerCount?: InputMaybe<Scalars['bigint']>;
  data?: InputMaybe<Scalars['jsonb']>;
  evict?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  monetaryQuantity?: InputMaybe<Scalars['bigint']>;
  monetaryUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  netWeight?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  rollingInventory?: InputMaybe<Rolling_Inventories_Obj_Rel_Insert_Input>;
  rollingInventoryActor?: InputMaybe<Rolling_Inventory_Actors_Obj_Rel_Insert_Input>;
  rollingInventoryActorId?: InputMaybe<Scalars['bigint']>;
  rollingInventoryId?: InputMaybe<Scalars['bigint']>;
  stockLocation?: InputMaybe<Stock_Locations_Obj_Rel_Insert_Input>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  storageQuantity?: InputMaybe<Scalars['bigint']>;
  storageUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
  tareWeight?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  weightUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  weightUnitId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Rolling_Inventory_Items_Max_Fields = {
  __typename: 'rolling_inventory_items_max_fields';
  containerCount?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  netWeight?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  rollingInventoryActorId?: Maybe<Scalars['bigint']>;
  rollingInventoryId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageQuantity?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  tareWeight?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  weightUnitId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "rolling_inventory_items" */
export type Rolling_Inventory_Items_Max_Order_By = {
  containerCount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rollingInventoryActorId?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rolling_Inventory_Items_Min_Fields = {
  __typename: 'rolling_inventory_items_min_fields';
  containerCount?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  netWeight?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  rollingInventoryActorId?: Maybe<Scalars['bigint']>;
  rollingInventoryId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageQuantity?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  tareWeight?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  weightUnitId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "rolling_inventory_items" */
export type Rolling_Inventory_Items_Min_Order_By = {
  containerCount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rollingInventoryActorId?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rolling_inventory_items" */
export type Rolling_Inventory_Items_Mutation_Response = {
  __typename: 'rolling_inventory_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rolling_Inventory_Items>;
};

/** on_conflict condition type for table "rolling_inventory_items" */
export type Rolling_Inventory_Items_On_Conflict = {
  constraint: Rolling_Inventory_Items_Constraint;
  update_columns?: Array<Rolling_Inventory_Items_Update_Column>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "rolling_inventory_items". */
export type Rolling_Inventory_Items_Order_By = {
  LotNumber?: InputMaybe<Lot_Numbers_Order_By>;
  Tenant?: InputMaybe<Tenants_Order_By>;
  containerCount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  evict?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnit?: InputMaybe<Product_Units_Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  rollingInventory?: InputMaybe<Rolling_Inventories_Order_By>;
  rollingInventoryActor?: InputMaybe<Rolling_Inventory_Actors_Order_By>;
  rollingInventoryActorId?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  stockLocation?: InputMaybe<Stock_Locations_Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnit?: InputMaybe<Product_Units_Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnit?: InputMaybe<Product_Units_Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rolling_inventory_items */
export type Rolling_Inventory_Items_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Rolling_Inventory_Items_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "rolling_inventory_items" */
export enum Rolling_Inventory_Items_Select_Column {
  /** column name */
  ContainerCount = 'containerCount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  Evict = 'evict',
  /** column name */
  Id = 'id',
  /** column name */
  LotNumber = 'lotNumber',
  /** column name */
  LotNumberId = 'lotNumberId',
  /** column name */
  MonetaryQuantity = 'monetaryQuantity',
  /** column name */
  MonetaryUnitId = 'monetaryUnitId',
  /** column name */
  NetWeight = 'netWeight',
  /** column name */
  ProductId = 'productId',
  /** column name */
  RollingInventoryActorId = 'rollingInventoryActorId',
  /** column name */
  RollingInventoryId = 'rollingInventoryId',
  /** column name */
  StockLocationId = 'stockLocationId',
  /** column name */
  StorageQuantity = 'storageQuantity',
  /** column name */
  StorageUnitId = 'storageUnitId',
  /** column name */
  TareWeight = 'tareWeight',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version',
  /** column name */
  WeightUnitId = 'weightUnitId'
}

/** select "rolling_inventory_items_aggregate_bool_exp_bool_and_arguments_columns" columns of table "rolling_inventory_items" */
export enum Rolling_Inventory_Items_Select_Column_Rolling_Inventory_Items_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Evict = 'evict'
}

/** select "rolling_inventory_items_aggregate_bool_exp_bool_or_arguments_columns" columns of table "rolling_inventory_items" */
export enum Rolling_Inventory_Items_Select_Column_Rolling_Inventory_Items_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Evict = 'evict'
}

/** input type for updating data in table "rolling_inventory_items" */
export type Rolling_Inventory_Items_Set_Input = {
  containerCount?: InputMaybe<Scalars['bigint']>;
  data?: InputMaybe<Scalars['jsonb']>;
  evict?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  monetaryQuantity?: InputMaybe<Scalars['bigint']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  netWeight?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  rollingInventoryActorId?: InputMaybe<Scalars['bigint']>;
  rollingInventoryId?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  storageQuantity?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
  tareWeight?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  weightUnitId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Rolling_Inventory_Items_Stddev_Fields = {
  __typename: 'rolling_inventory_items_stddev_fields';
  containerCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rollingInventoryActorId?: Maybe<Scalars['Float']>;
  rollingInventoryId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "rolling_inventory_items" */
export type Rolling_Inventory_Items_Stddev_Order_By = {
  containerCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rollingInventoryActorId?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rolling_Inventory_Items_Stddev_Pop_Fields = {
  __typename: 'rolling_inventory_items_stddev_pop_fields';
  containerCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rollingInventoryActorId?: Maybe<Scalars['Float']>;
  rollingInventoryId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "rolling_inventory_items" */
export type Rolling_Inventory_Items_Stddev_Pop_Order_By = {
  containerCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rollingInventoryActorId?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rolling_Inventory_Items_Stddev_Samp_Fields = {
  __typename: 'rolling_inventory_items_stddev_samp_fields';
  containerCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rollingInventoryActorId?: Maybe<Scalars['Float']>;
  rollingInventoryId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightUnitId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "rolling_inventory_items" */
export type Rolling_Inventory_Items_Stddev_Samp_Order_By = {
  containerCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rollingInventoryActorId?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "rolling_inventory_items" */
export type Rolling_Inventory_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rolling_Inventory_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rolling_Inventory_Items_Stream_Cursor_Value_Input = {
  containerCount?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  evict?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  monetaryQuantity?: InputMaybe<Scalars['bigint']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  netWeight?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  rollingInventoryActorId?: InputMaybe<Scalars['bigint']>;
  rollingInventoryId?: InputMaybe<Scalars['bigint']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  storageQuantity?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
  tareWeight?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
  weightUnitId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Rolling_Inventory_Items_Sum_Fields = {
  __typename: 'rolling_inventory_items_sum_fields';
  containerCount?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  monetaryQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  netWeight?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  rollingInventoryActorId?: Maybe<Scalars['bigint']>;
  rollingInventoryId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageQuantity?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  tareWeight?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
  weightUnitId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "rolling_inventory_items" */
export type Rolling_Inventory_Items_Sum_Order_By = {
  containerCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rollingInventoryActorId?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** update columns of table "rolling_inventory_items" */
export enum Rolling_Inventory_Items_Update_Column {
  /** column name */
  ContainerCount = 'containerCount',
  /** column name */
  Data = 'data',
  /** column name */
  Evict = 'evict',
  /** column name */
  Id = 'id',
  /** column name */
  LotNumber = 'lotNumber',
  /** column name */
  LotNumberId = 'lotNumberId',
  /** column name */
  MonetaryQuantity = 'monetaryQuantity',
  /** column name */
  MonetaryUnitId = 'monetaryUnitId',
  /** column name */
  NetWeight = 'netWeight',
  /** column name */
  ProductId = 'productId',
  /** column name */
  RollingInventoryActorId = 'rollingInventoryActorId',
  /** column name */
  RollingInventoryId = 'rollingInventoryId',
  /** column name */
  StockLocationId = 'stockLocationId',
  /** column name */
  StorageQuantity = 'storageQuantity',
  /** column name */
  StorageUnitId = 'storageUnitId',
  /** column name */
  TareWeight = 'tareWeight',
  /** column name */
  Version = 'version',
  /** column name */
  WeightUnitId = 'weightUnitId'
}

export type Rolling_Inventory_Items_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Rolling_Inventory_Items_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Rolling_Inventory_Items_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Rolling_Inventory_Items_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Rolling_Inventory_Items_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Rolling_Inventory_Items_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Rolling_Inventory_Items_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rolling_Inventory_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rolling_Inventory_Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Rolling_Inventory_Items_Var_Pop_Fields = {
  __typename: 'rolling_inventory_items_var_pop_fields';
  containerCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rollingInventoryActorId?: Maybe<Scalars['Float']>;
  rollingInventoryId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightUnitId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "rolling_inventory_items" */
export type Rolling_Inventory_Items_Var_Pop_Order_By = {
  containerCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rollingInventoryActorId?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rolling_Inventory_Items_Var_Samp_Fields = {
  __typename: 'rolling_inventory_items_var_samp_fields';
  containerCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rollingInventoryActorId?: Maybe<Scalars['Float']>;
  rollingInventoryId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightUnitId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "rolling_inventory_items" */
export type Rolling_Inventory_Items_Var_Samp_Order_By = {
  containerCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rollingInventoryActorId?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rolling_Inventory_Items_Variance_Fields = {
  __typename: 'rolling_inventory_items_variance_fields';
  containerCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rollingInventoryActorId?: Maybe<Scalars['Float']>;
  rollingInventoryId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageQuantity?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  weightUnitId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "rolling_inventory_items" */
export type Rolling_Inventory_Items_Variance_Order_By = {
  containerCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  netWeight?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rollingInventoryActorId?: InputMaybe<Order_By>;
  rollingInventoryId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageQuantity?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weightUnitId?: InputMaybe<Order_By>;
};

/** columns and relationships of "rolling_inventory_logged_actions" */
export type Rolling_Inventory_Logged_Actions = {
  __typename: 'rolling_inventory_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  relid?: Maybe<Scalars['oid']>;
  role?: Maybe<Scalars['String']>;
  rowData?: Maybe<Scalars['jsonb']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "rolling_inventory_logged_actions" */
export type Rolling_Inventory_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "rolling_inventory_logged_actions" */
export type Rolling_Inventory_Logged_ActionsRowDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "rolling_inventory_logged_actions" */
export type Rolling_Inventory_Logged_Actions_Aggregate = {
  __typename: 'rolling_inventory_logged_actions_aggregate';
  aggregate?: Maybe<Rolling_Inventory_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Rolling_Inventory_Logged_Actions>;
};

/** aggregate fields of "rolling_inventory_logged_actions" */
export type Rolling_Inventory_Logged_Actions_Aggregate_Fields = {
  __typename: 'rolling_inventory_logged_actions_aggregate_fields';
  avg?: Maybe<Rolling_Inventory_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rolling_Inventory_Logged_Actions_Max_Fields>;
  min?: Maybe<Rolling_Inventory_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Rolling_Inventory_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Rolling_Inventory_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rolling_Inventory_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Rolling_Inventory_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Rolling_Inventory_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Rolling_Inventory_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Rolling_Inventory_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "rolling_inventory_logged_actions" */
export type Rolling_Inventory_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rolling_Inventory_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Rolling_Inventory_Logged_Actions_Avg_Fields = {
  __typename: 'rolling_inventory_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "rolling_inventory_logged_actions". All fields are combined with a logical 'AND'. */
export type Rolling_Inventory_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Rolling_Inventory_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Rolling_Inventory_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Rolling_Inventory_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  relid?: InputMaybe<Oid_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  rowData?: InputMaybe<Jsonb_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Rolling_Inventory_Logged_Actions_Max_Fields = {
  __typename: 'rolling_inventory_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Rolling_Inventory_Logged_Actions_Min_Fields = {
  __typename: 'rolling_inventory_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "rolling_inventory_logged_actions". */
export type Rolling_Inventory_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  relid?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  rowData?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "rolling_inventory_logged_actions" */
export enum Rolling_Inventory_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Relid = 'relid',
  /** column name */
  Role = 'role',
  /** column name */
  RowData = 'rowData',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Rolling_Inventory_Logged_Actions_Stddev_Fields = {
  __typename: 'rolling_inventory_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Rolling_Inventory_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'rolling_inventory_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Rolling_Inventory_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'rolling_inventory_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "rolling_inventory_logged_actions" */
export type Rolling_Inventory_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rolling_Inventory_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rolling_Inventory_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  relid?: InputMaybe<Scalars['oid']>;
  role?: InputMaybe<Scalars['String']>;
  rowData?: InputMaybe<Scalars['jsonb']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Rolling_Inventory_Logged_Actions_Sum_Fields = {
  __typename: 'rolling_inventory_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Rolling_Inventory_Logged_Actions_Var_Pop_Fields = {
  __typename: 'rolling_inventory_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Rolling_Inventory_Logged_Actions_Var_Samp_Fields = {
  __typename: 'rolling_inventory_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Rolling_Inventory_Logged_Actions_Variance_Fields = {
  __typename: 'rolling_inventory_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

export type Rolling_Inventory_Lot_Summary_Args = {
  _rolling_inventory_id?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "rolling_inventory_lot_summary_items" */
export type Rolling_Inventory_Lot_Summary_Items = {
  __typename: 'rolling_inventory_lot_summary_items';
  /** An object relationship */
  LotNumber?: Maybe<Lot_Numbers>;
  contents?: Maybe<Scalars['jsonb']>;
  diffMonetaryQuantity?: Maybe<Scalars['bigint']>;
  diffStorageQuantity?: Maybe<Scalars['bigint']>;
  /** Unique identity for each lot summary record */
  hash?: Maybe<Scalars['bigint']>;
  lastRollingInventoryItemChangeAt?: Maybe<Scalars['timestamptz']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  monetaryUnit: Product_Units;
  monetaryUnitId: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  rollingInventoryActorIds?: Maybe<Scalars['_int8']>;
  /** An object relationship */
  stockLocation: Stock_Locations;
  stockLocationId: Scalars['bigint'];
  /** An object relationship */
  storageUnit: Product_Units;
  storageUnitId: Scalars['bigint'];
  sumInventoryMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['bigint']>;
  sumMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumStorageQuantity?: Maybe<Scalars['bigint']>;
  sumTareWeight?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "rolling_inventory_lot_summary_items" */
export type Rolling_Inventory_Lot_Summary_ItemsContentsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "rolling_inventory_lot_summary_items" */
export type Rolling_Inventory_Lot_Summary_Items_Aggregate = {
  __typename: 'rolling_inventory_lot_summary_items_aggregate';
  aggregate?: Maybe<Rolling_Inventory_Lot_Summary_Items_Aggregate_Fields>;
  nodes: Array<Rolling_Inventory_Lot_Summary_Items>;
};

export type Rolling_Inventory_Lot_Summary_Items_Aggregate_Bool_Exp = {
  count?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Aggregate_Bool_Exp_Count>;
};

export type Rolling_Inventory_Lot_Summary_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rolling_inventory_lot_summary_items" */
export type Rolling_Inventory_Lot_Summary_Items_Aggregate_Fields = {
  __typename: 'rolling_inventory_lot_summary_items_aggregate_fields';
  avg?: Maybe<Rolling_Inventory_Lot_Summary_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rolling_Inventory_Lot_Summary_Items_Max_Fields>;
  min?: Maybe<Rolling_Inventory_Lot_Summary_Items_Min_Fields>;
  stddev?: Maybe<Rolling_Inventory_Lot_Summary_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Rolling_Inventory_Lot_Summary_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rolling_Inventory_Lot_Summary_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Rolling_Inventory_Lot_Summary_Items_Sum_Fields>;
  var_pop?: Maybe<Rolling_Inventory_Lot_Summary_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Rolling_Inventory_Lot_Summary_Items_Var_Samp_Fields>;
  variance?: Maybe<Rolling_Inventory_Lot_Summary_Items_Variance_Fields>;
};


/** aggregate fields of "rolling_inventory_lot_summary_items" */
export type Rolling_Inventory_Lot_Summary_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rolling_inventory_lot_summary_items" */
export type Rolling_Inventory_Lot_Summary_Items_Aggregate_Order_By = {
  avg?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Max_Order_By>;
  min?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Min_Order_By>;
  stddev?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Sum_Order_By>;
  var_pop?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Var_Samp_Order_By>;
  variance?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Rolling_Inventory_Lot_Summary_Items_Avg_Fields = {
  __typename: 'rolling_inventory_lot_summary_items_avg_fields';
  diffMonetaryQuantity?: Maybe<Scalars['Float']>;
  diffStorageQuantity?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumInventoryMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  sumTareWeight?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "rolling_inventory_lot_summary_items" */
export type Rolling_Inventory_Lot_Summary_Items_Avg_Order_By = {
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rolling_inventory_lot_summary_items". All fields are combined with a logical 'AND'. */
export type Rolling_Inventory_Lot_Summary_Items_Bool_Exp = {
  LotNumber?: InputMaybe<Lot_Numbers_Bool_Exp>;
  _and?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>>;
  _not?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>>;
  contents?: InputMaybe<Jsonb_Comparison_Exp>;
  diffMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  diffStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  hash?: InputMaybe<Bigint_Comparison_Exp>;
  lastRollingInventoryItemChangeAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  lotNumber?: InputMaybe<String_Comparison_Exp>;
  lotNumberId?: InputMaybe<Bigint_Comparison_Exp>;
  monetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  monetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  rollingInventoryActorIds?: InputMaybe<_Int8_Comparison_Exp>;
  stockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  stockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  storageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  storageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  sumInventoryMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  sumInventoryStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  sumMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  sumStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  sumTareWeight?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Rolling_Inventory_Lot_Summary_Items_Max_Fields = {
  __typename: 'rolling_inventory_lot_summary_items_max_fields';
  diffMonetaryQuantity?: Maybe<Scalars['bigint']>;
  diffStorageQuantity?: Maybe<Scalars['bigint']>;
  lastRollingInventoryItemChangeAt?: Maybe<Scalars['timestamptz']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  sumInventoryMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['bigint']>;
  sumMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumStorageQuantity?: Maybe<Scalars['bigint']>;
  sumTareWeight?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "rolling_inventory_lot_summary_items" */
export type Rolling_Inventory_Lot_Summary_Items_Max_Order_By = {
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  lastRollingInventoryItemChangeAt?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rolling_Inventory_Lot_Summary_Items_Min_Fields = {
  __typename: 'rolling_inventory_lot_summary_items_min_fields';
  diffMonetaryQuantity?: Maybe<Scalars['bigint']>;
  diffStorageQuantity?: Maybe<Scalars['bigint']>;
  lastRollingInventoryItemChangeAt?: Maybe<Scalars['timestamptz']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  sumInventoryMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['bigint']>;
  sumMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumStorageQuantity?: Maybe<Scalars['bigint']>;
  sumTareWeight?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "rolling_inventory_lot_summary_items" */
export type Rolling_Inventory_Lot_Summary_Items_Min_Order_By = {
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  lastRollingInventoryItemChangeAt?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "rolling_inventory_lot_summary_items". */
export type Rolling_Inventory_Lot_Summary_Items_Order_By = {
  LotNumber?: InputMaybe<Lot_Numbers_Order_By>;
  contents?: InputMaybe<Order_By>;
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  lastRollingInventoryItemChangeAt?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryUnit?: InputMaybe<Product_Units_Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  rollingInventoryActorIds?: InputMaybe<Order_By>;
  stockLocation?: InputMaybe<Stock_Locations_Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnit?: InputMaybe<Product_Units_Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** select columns of table "rolling_inventory_lot_summary_items" */
export enum Rolling_Inventory_Lot_Summary_Items_Select_Column {
  /** column name */
  Contents = 'contents',
  /** column name */
  DiffMonetaryQuantity = 'diffMonetaryQuantity',
  /** column name */
  DiffStorageQuantity = 'diffStorageQuantity',
  /** column name */
  LastRollingInventoryItemChangeAt = 'lastRollingInventoryItemChangeAt',
  /** column name */
  LotNumber = 'lotNumber',
  /** column name */
  LotNumberId = 'lotNumberId',
  /** column name */
  MonetaryUnitId = 'monetaryUnitId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  RollingInventoryActorIds = 'rollingInventoryActorIds',
  /** column name */
  StockLocationId = 'stockLocationId',
  /** column name */
  StorageUnitId = 'storageUnitId',
  /** column name */
  SumInventoryMonetaryQuantity = 'sumInventoryMonetaryQuantity',
  /** column name */
  SumInventoryStorageQuantity = 'sumInventoryStorageQuantity',
  /** column name */
  SumMonetaryQuantity = 'sumMonetaryQuantity',
  /** column name */
  SumStorageQuantity = 'sumStorageQuantity',
  /** column name */
  SumTareWeight = 'sumTareWeight'
}

/** aggregate stddev on columns */
export type Rolling_Inventory_Lot_Summary_Items_Stddev_Fields = {
  __typename: 'rolling_inventory_lot_summary_items_stddev_fields';
  diffMonetaryQuantity?: Maybe<Scalars['Float']>;
  diffStorageQuantity?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumInventoryMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  sumTareWeight?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "rolling_inventory_lot_summary_items" */
export type Rolling_Inventory_Lot_Summary_Items_Stddev_Order_By = {
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rolling_Inventory_Lot_Summary_Items_Stddev_Pop_Fields = {
  __typename: 'rolling_inventory_lot_summary_items_stddev_pop_fields';
  diffMonetaryQuantity?: Maybe<Scalars['Float']>;
  diffStorageQuantity?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumInventoryMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  sumTareWeight?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "rolling_inventory_lot_summary_items" */
export type Rolling_Inventory_Lot_Summary_Items_Stddev_Pop_Order_By = {
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rolling_Inventory_Lot_Summary_Items_Stddev_Samp_Fields = {
  __typename: 'rolling_inventory_lot_summary_items_stddev_samp_fields';
  diffMonetaryQuantity?: Maybe<Scalars['Float']>;
  diffStorageQuantity?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumInventoryMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  sumTareWeight?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "rolling_inventory_lot_summary_items" */
export type Rolling_Inventory_Lot_Summary_Items_Stddev_Samp_Order_By = {
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "rolling_inventory_lot_summary_items" */
export type Rolling_Inventory_Lot_Summary_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rolling_Inventory_Lot_Summary_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rolling_Inventory_Lot_Summary_Items_Stream_Cursor_Value_Input = {
  contents?: InputMaybe<Scalars['jsonb']>;
  diffMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  diffStorageQuantity?: InputMaybe<Scalars['bigint']>;
  lastRollingInventoryItemChangeAt?: InputMaybe<Scalars['timestamptz']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  rollingInventoryActorIds?: InputMaybe<Scalars['_int8']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
  sumInventoryMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sumInventoryStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sumMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sumStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sumTareWeight?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Rolling_Inventory_Lot_Summary_Items_Sum_Fields = {
  __typename: 'rolling_inventory_lot_summary_items_sum_fields';
  diffMonetaryQuantity?: Maybe<Scalars['bigint']>;
  diffStorageQuantity?: Maybe<Scalars['bigint']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  sumInventoryMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['bigint']>;
  sumMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumStorageQuantity?: Maybe<Scalars['bigint']>;
  sumTareWeight?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "rolling_inventory_lot_summary_items" */
export type Rolling_Inventory_Lot_Summary_Items_Sum_Order_By = {
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rolling_Inventory_Lot_Summary_Items_Var_Pop_Fields = {
  __typename: 'rolling_inventory_lot_summary_items_var_pop_fields';
  diffMonetaryQuantity?: Maybe<Scalars['Float']>;
  diffStorageQuantity?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumInventoryMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  sumTareWeight?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "rolling_inventory_lot_summary_items" */
export type Rolling_Inventory_Lot_Summary_Items_Var_Pop_Order_By = {
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rolling_Inventory_Lot_Summary_Items_Var_Samp_Fields = {
  __typename: 'rolling_inventory_lot_summary_items_var_samp_fields';
  diffMonetaryQuantity?: Maybe<Scalars['Float']>;
  diffStorageQuantity?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumInventoryMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  sumTareWeight?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "rolling_inventory_lot_summary_items" */
export type Rolling_Inventory_Lot_Summary_Items_Var_Samp_Order_By = {
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rolling_Inventory_Lot_Summary_Items_Variance_Fields = {
  __typename: 'rolling_inventory_lot_summary_items_variance_fields';
  diffMonetaryQuantity?: Maybe<Scalars['Float']>;
  diffStorageQuantity?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumInventoryMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  sumTareWeight?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "rolling_inventory_lot_summary_items" */
export type Rolling_Inventory_Lot_Summary_Items_Variance_Order_By = {
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** columns and relationships of "rolling_inventory_state_transitions" */
export type Rolling_Inventory_State_Transitions = {
  __typename: 'rolling_inventory_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Rolling_Inventory_States_Enum;
  toState: Rolling_Inventory_States_Enum;
};

/** aggregated selection of "rolling_inventory_state_transitions" */
export type Rolling_Inventory_State_Transitions_Aggregate = {
  __typename: 'rolling_inventory_state_transitions_aggregate';
  aggregate?: Maybe<Rolling_Inventory_State_Transitions_Aggregate_Fields>;
  nodes: Array<Rolling_Inventory_State_Transitions>;
};

/** aggregate fields of "rolling_inventory_state_transitions" */
export type Rolling_Inventory_State_Transitions_Aggregate_Fields = {
  __typename: 'rolling_inventory_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Rolling_Inventory_State_Transitions_Max_Fields>;
  min?: Maybe<Rolling_Inventory_State_Transitions_Min_Fields>;
};


/** aggregate fields of "rolling_inventory_state_transitions" */
export type Rolling_Inventory_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rolling_Inventory_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "rolling_inventory_state_transitions". All fields are combined with a logical 'AND'. */
export type Rolling_Inventory_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Rolling_Inventory_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Rolling_Inventory_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Rolling_Inventory_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Rolling_Inventory_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Rolling_Inventory_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Rolling_Inventory_State_Transitions_Max_Fields = {
  __typename: 'rolling_inventory_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Rolling_Inventory_State_Transitions_Min_Fields = {
  __typename: 'rolling_inventory_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "rolling_inventory_state_transitions". */
export type Rolling_Inventory_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "rolling_inventory_state_transitions" */
export enum Rolling_Inventory_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "rolling_inventory_state_transitions" */
export type Rolling_Inventory_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rolling_Inventory_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rolling_Inventory_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Rolling_Inventory_States_Enum>;
  toState?: InputMaybe<Rolling_Inventory_States_Enum>;
};

/** columns and relationships of "rolling_inventory_states" */
export type Rolling_Inventory_States = {
  __typename: 'rolling_inventory_states';
  /** An array relationship */
  rollingInventories: Array<Rolling_Inventories>;
  /** An aggregate relationship */
  rollingInventories_aggregate: Rolling_Inventories_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "rolling_inventory_states" */
export type Rolling_Inventory_StatesRollingInventoriesArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventories_Order_By>>;
  where?: InputMaybe<Rolling_Inventories_Bool_Exp>;
};


/** columns and relationships of "rolling_inventory_states" */
export type Rolling_Inventory_StatesRollingInventories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventories_Order_By>>;
  where?: InputMaybe<Rolling_Inventories_Bool_Exp>;
};

/** aggregated selection of "rolling_inventory_states" */
export type Rolling_Inventory_States_Aggregate = {
  __typename: 'rolling_inventory_states_aggregate';
  aggregate?: Maybe<Rolling_Inventory_States_Aggregate_Fields>;
  nodes: Array<Rolling_Inventory_States>;
};

/** aggregate fields of "rolling_inventory_states" */
export type Rolling_Inventory_States_Aggregate_Fields = {
  __typename: 'rolling_inventory_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Rolling_Inventory_States_Max_Fields>;
  min?: Maybe<Rolling_Inventory_States_Min_Fields>;
};


/** aggregate fields of "rolling_inventory_states" */
export type Rolling_Inventory_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rolling_Inventory_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "rolling_inventory_states". All fields are combined with a logical 'AND'. */
export type Rolling_Inventory_States_Bool_Exp = {
  _and?: InputMaybe<Array<Rolling_Inventory_States_Bool_Exp>>;
  _not?: InputMaybe<Rolling_Inventory_States_Bool_Exp>;
  _or?: InputMaybe<Array<Rolling_Inventory_States_Bool_Exp>>;
  rollingInventories?: InputMaybe<Rolling_Inventories_Bool_Exp>;
  rollingInventories_aggregate?: InputMaybe<Rolling_Inventories_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Rolling_Inventory_States_Enum {
  Active = 'ACTIVE',
  Committed = 'COMMITTED',
  Finished = 'FINISHED',
  Open = 'OPEN'
}

/** Boolean expression to compare columns of type "rolling_inventory_states_enum". All fields are combined with logical 'AND'. */
export type Rolling_Inventory_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Rolling_Inventory_States_Enum>;
  _in?: InputMaybe<Array<Rolling_Inventory_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Rolling_Inventory_States_Enum>;
  _nin?: InputMaybe<Array<Rolling_Inventory_States_Enum>>;
};

/** aggregate max on columns */
export type Rolling_Inventory_States_Max_Fields = {
  __typename: 'rolling_inventory_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Rolling_Inventory_States_Min_Fields = {
  __typename: 'rolling_inventory_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "rolling_inventory_states". */
export type Rolling_Inventory_States_Order_By = {
  rollingInventories_aggregate?: InputMaybe<Rolling_Inventories_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "rolling_inventory_states" */
export enum Rolling_Inventory_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "rolling_inventory_states" */
export type Rolling_Inventory_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rolling_Inventory_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rolling_Inventory_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

export type Rolling_Inventory_Summary_Args = {
  _rolling_inventory_id?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "rolling_inventory_summary_items" */
export type Rolling_Inventory_Summary_Items = {
  __typename: 'rolling_inventory_summary_items';
  contents: Scalars['jsonb'];
  diffMonetaryQuantity?: Maybe<Scalars['bigint']>;
  diffStorageQuantity?: Maybe<Scalars['bigint']>;
  /** Unique identity for each summary record */
  hash?: Maybe<Scalars['bigint']>;
  lastRollingInventoryItemChangeAt: Scalars['timestamptz'];
  /** An object relationship */
  monetaryUnit: Product_Units;
  monetaryUnitId: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  rollingInventoryActorIds: Scalars['_int8'];
  /** An object relationship */
  stockLocation: Stock_Locations;
  stockLocationId: Scalars['bigint'];
  /** An object relationship */
  storageUnit: Product_Units;
  storageUnitId: Scalars['bigint'];
  sumInventoryMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['bigint']>;
  sumMonetaryQuantity: Scalars['bigint'];
  sumStorageQuantity: Scalars['bigint'];
  sumTareWeight: Scalars['bigint'];
};


/** columns and relationships of "rolling_inventory_summary_items" */
export type Rolling_Inventory_Summary_ItemsContentsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "rolling_inventory_summary_items" */
export type Rolling_Inventory_Summary_Items_Aggregate = {
  __typename: 'rolling_inventory_summary_items_aggregate';
  aggregate?: Maybe<Rolling_Inventory_Summary_Items_Aggregate_Fields>;
  nodes: Array<Rolling_Inventory_Summary_Items>;
};

export type Rolling_Inventory_Summary_Items_Aggregate_Bool_Exp = {
  count?: InputMaybe<Rolling_Inventory_Summary_Items_Aggregate_Bool_Exp_Count>;
};

export type Rolling_Inventory_Summary_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rolling_inventory_summary_items" */
export type Rolling_Inventory_Summary_Items_Aggregate_Fields = {
  __typename: 'rolling_inventory_summary_items_aggregate_fields';
  avg?: Maybe<Rolling_Inventory_Summary_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rolling_Inventory_Summary_Items_Max_Fields>;
  min?: Maybe<Rolling_Inventory_Summary_Items_Min_Fields>;
  stddev?: Maybe<Rolling_Inventory_Summary_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Rolling_Inventory_Summary_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rolling_Inventory_Summary_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Rolling_Inventory_Summary_Items_Sum_Fields>;
  var_pop?: Maybe<Rolling_Inventory_Summary_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Rolling_Inventory_Summary_Items_Var_Samp_Fields>;
  variance?: Maybe<Rolling_Inventory_Summary_Items_Variance_Fields>;
};


/** aggregate fields of "rolling_inventory_summary_items" */
export type Rolling_Inventory_Summary_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rolling_inventory_summary_items" */
export type Rolling_Inventory_Summary_Items_Aggregate_Order_By = {
  avg?: InputMaybe<Rolling_Inventory_Summary_Items_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rolling_Inventory_Summary_Items_Max_Order_By>;
  min?: InputMaybe<Rolling_Inventory_Summary_Items_Min_Order_By>;
  stddev?: InputMaybe<Rolling_Inventory_Summary_Items_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rolling_Inventory_Summary_Items_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rolling_Inventory_Summary_Items_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rolling_Inventory_Summary_Items_Sum_Order_By>;
  var_pop?: InputMaybe<Rolling_Inventory_Summary_Items_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rolling_Inventory_Summary_Items_Var_Samp_Order_By>;
  variance?: InputMaybe<Rolling_Inventory_Summary_Items_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Rolling_Inventory_Summary_Items_Avg_Fields = {
  __typename: 'rolling_inventory_summary_items_avg_fields';
  diffMonetaryQuantity?: Maybe<Scalars['Float']>;
  diffStorageQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumInventoryMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  sumTareWeight?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "rolling_inventory_summary_items" */
export type Rolling_Inventory_Summary_Items_Avg_Order_By = {
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rolling_inventory_summary_items". All fields are combined with a logical 'AND'. */
export type Rolling_Inventory_Summary_Items_Bool_Exp = {
  _and?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Bool_Exp>>;
  _not?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Bool_Exp>>;
  contents?: InputMaybe<Jsonb_Comparison_Exp>;
  diffMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  diffStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  hash?: InputMaybe<Bigint_Comparison_Exp>;
  lastRollingInventoryItemChangeAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  monetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  monetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  rollingInventoryActorIds?: InputMaybe<_Int8_Comparison_Exp>;
  stockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  stockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  storageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  storageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  sumInventoryMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  sumInventoryStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  sumMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  sumStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  sumTareWeight?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Rolling_Inventory_Summary_Items_Max_Fields = {
  __typename: 'rolling_inventory_summary_items_max_fields';
  diffMonetaryQuantity?: Maybe<Scalars['bigint']>;
  diffStorageQuantity?: Maybe<Scalars['bigint']>;
  lastRollingInventoryItemChangeAt?: Maybe<Scalars['timestamptz']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  sumInventoryMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['bigint']>;
  sumMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumStorageQuantity?: Maybe<Scalars['bigint']>;
  sumTareWeight?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "rolling_inventory_summary_items" */
export type Rolling_Inventory_Summary_Items_Max_Order_By = {
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  lastRollingInventoryItemChangeAt?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rolling_Inventory_Summary_Items_Min_Fields = {
  __typename: 'rolling_inventory_summary_items_min_fields';
  diffMonetaryQuantity?: Maybe<Scalars['bigint']>;
  diffStorageQuantity?: Maybe<Scalars['bigint']>;
  lastRollingInventoryItemChangeAt?: Maybe<Scalars['timestamptz']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  sumInventoryMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['bigint']>;
  sumMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumStorageQuantity?: Maybe<Scalars['bigint']>;
  sumTareWeight?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "rolling_inventory_summary_items" */
export type Rolling_Inventory_Summary_Items_Min_Order_By = {
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  lastRollingInventoryItemChangeAt?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "rolling_inventory_summary_items". */
export type Rolling_Inventory_Summary_Items_Order_By = {
  contents?: InputMaybe<Order_By>;
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  lastRollingInventoryItemChangeAt?: InputMaybe<Order_By>;
  monetaryUnit?: InputMaybe<Product_Units_Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  rollingInventoryActorIds?: InputMaybe<Order_By>;
  stockLocation?: InputMaybe<Stock_Locations_Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnit?: InputMaybe<Product_Units_Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** select columns of table "rolling_inventory_summary_items" */
export enum Rolling_Inventory_Summary_Items_Select_Column {
  /** column name */
  Contents = 'contents',
  /** column name */
  DiffMonetaryQuantity = 'diffMonetaryQuantity',
  /** column name */
  DiffStorageQuantity = 'diffStorageQuantity',
  /** column name */
  LastRollingInventoryItemChangeAt = 'lastRollingInventoryItemChangeAt',
  /** column name */
  MonetaryUnitId = 'monetaryUnitId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  RollingInventoryActorIds = 'rollingInventoryActorIds',
  /** column name */
  StockLocationId = 'stockLocationId',
  /** column name */
  StorageUnitId = 'storageUnitId',
  /** column name */
  SumInventoryMonetaryQuantity = 'sumInventoryMonetaryQuantity',
  /** column name */
  SumInventoryStorageQuantity = 'sumInventoryStorageQuantity',
  /** column name */
  SumMonetaryQuantity = 'sumMonetaryQuantity',
  /** column name */
  SumStorageQuantity = 'sumStorageQuantity',
  /** column name */
  SumTareWeight = 'sumTareWeight'
}

/** aggregate stddev on columns */
export type Rolling_Inventory_Summary_Items_Stddev_Fields = {
  __typename: 'rolling_inventory_summary_items_stddev_fields';
  diffMonetaryQuantity?: Maybe<Scalars['Float']>;
  diffStorageQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumInventoryMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  sumTareWeight?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "rolling_inventory_summary_items" */
export type Rolling_Inventory_Summary_Items_Stddev_Order_By = {
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rolling_Inventory_Summary_Items_Stddev_Pop_Fields = {
  __typename: 'rolling_inventory_summary_items_stddev_pop_fields';
  diffMonetaryQuantity?: Maybe<Scalars['Float']>;
  diffStorageQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumInventoryMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  sumTareWeight?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "rolling_inventory_summary_items" */
export type Rolling_Inventory_Summary_Items_Stddev_Pop_Order_By = {
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rolling_Inventory_Summary_Items_Stddev_Samp_Fields = {
  __typename: 'rolling_inventory_summary_items_stddev_samp_fields';
  diffMonetaryQuantity?: Maybe<Scalars['Float']>;
  diffStorageQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumInventoryMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  sumTareWeight?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "rolling_inventory_summary_items" */
export type Rolling_Inventory_Summary_Items_Stddev_Samp_Order_By = {
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "rolling_inventory_summary_items" */
export type Rolling_Inventory_Summary_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rolling_Inventory_Summary_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rolling_Inventory_Summary_Items_Stream_Cursor_Value_Input = {
  contents?: InputMaybe<Scalars['jsonb']>;
  diffMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  diffStorageQuantity?: InputMaybe<Scalars['bigint']>;
  lastRollingInventoryItemChangeAt?: InputMaybe<Scalars['timestamptz']>;
  monetaryUnitId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  rollingInventoryActorIds?: InputMaybe<Scalars['_int8']>;
  stockLocationId?: InputMaybe<Scalars['bigint']>;
  storageUnitId?: InputMaybe<Scalars['bigint']>;
  sumInventoryMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sumInventoryStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sumMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sumStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sumTareWeight?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Rolling_Inventory_Summary_Items_Sum_Fields = {
  __typename: 'rolling_inventory_summary_items_sum_fields';
  diffMonetaryQuantity?: Maybe<Scalars['bigint']>;
  diffStorageQuantity?: Maybe<Scalars['bigint']>;
  monetaryUnitId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  stockLocationId?: Maybe<Scalars['bigint']>;
  storageUnitId?: Maybe<Scalars['bigint']>;
  sumInventoryMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['bigint']>;
  sumMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sumStorageQuantity?: Maybe<Scalars['bigint']>;
  sumTareWeight?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "rolling_inventory_summary_items" */
export type Rolling_Inventory_Summary_Items_Sum_Order_By = {
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rolling_Inventory_Summary_Items_Var_Pop_Fields = {
  __typename: 'rolling_inventory_summary_items_var_pop_fields';
  diffMonetaryQuantity?: Maybe<Scalars['Float']>;
  diffStorageQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumInventoryMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  sumTareWeight?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "rolling_inventory_summary_items" */
export type Rolling_Inventory_Summary_Items_Var_Pop_Order_By = {
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rolling_Inventory_Summary_Items_Var_Samp_Fields = {
  __typename: 'rolling_inventory_summary_items_var_samp_fields';
  diffMonetaryQuantity?: Maybe<Scalars['Float']>;
  diffStorageQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumInventoryMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  sumTareWeight?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "rolling_inventory_summary_items" */
export type Rolling_Inventory_Summary_Items_Var_Samp_Order_By = {
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rolling_Inventory_Summary_Items_Variance_Fields = {
  __typename: 'rolling_inventory_summary_items_variance_fields';
  diffMonetaryQuantity?: Maybe<Scalars['Float']>;
  diffStorageQuantity?: Maybe<Scalars['Float']>;
  monetaryUnitId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  stockLocationId?: Maybe<Scalars['Float']>;
  storageUnitId?: Maybe<Scalars['Float']>;
  sumInventoryMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumInventoryStorageQuantity?: Maybe<Scalars['Float']>;
  sumMonetaryQuantity?: Maybe<Scalars['Float']>;
  sumStorageQuantity?: Maybe<Scalars['Float']>;
  sumTareWeight?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "rolling_inventory_summary_items" */
export type Rolling_Inventory_Summary_Items_Variance_Order_By = {
  diffMonetaryQuantity?: InputMaybe<Order_By>;
  diffStorageQuantity?: InputMaybe<Order_By>;
  monetaryUnitId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  stockLocationId?: InputMaybe<Order_By>;
  storageUnitId?: InputMaybe<Order_By>;
  sumInventoryMonetaryQuantity?: InputMaybe<Order_By>;
  sumInventoryStorageQuantity?: InputMaybe<Order_By>;
  sumMonetaryQuantity?: InputMaybe<Order_By>;
  sumStorageQuantity?: InputMaybe<Order_By>;
  sumTareWeight?: InputMaybe<Order_By>;
};

export type Root_Cutting_Pattern_Products_Args = {
  _product_group_id?: InputMaybe<Scalars['bigint']>;
};

export type Root_Cutting_Pattern_Products_Draft_Args = {
  _include_inactive?: InputMaybe<Scalars['Boolean']>;
  _product_group_id?: InputMaybe<Scalars['bigint']>;
};

export type Root_Cutting_Pattern_Temporal_Products_Args = {
  _as_of?: InputMaybe<Scalars['date']>;
};

/** columns and relationships of "s3_object_state_transitions" */
export type S3_Object_State_Transitions = {
  __typename: 's3_object_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: S3_Object_States_Enum;
  toState: S3_Object_States_Enum;
};

/** aggregated selection of "s3_object_state_transitions" */
export type S3_Object_State_Transitions_Aggregate = {
  __typename: 's3_object_state_transitions_aggregate';
  aggregate?: Maybe<S3_Object_State_Transitions_Aggregate_Fields>;
  nodes: Array<S3_Object_State_Transitions>;
};

/** aggregate fields of "s3_object_state_transitions" */
export type S3_Object_State_Transitions_Aggregate_Fields = {
  __typename: 's3_object_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<S3_Object_State_Transitions_Max_Fields>;
  min?: Maybe<S3_Object_State_Transitions_Min_Fields>;
};


/** aggregate fields of "s3_object_state_transitions" */
export type S3_Object_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<S3_Object_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "s3_object_state_transitions". All fields are combined with a logical 'AND'. */
export type S3_Object_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<S3_Object_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<S3_Object_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<S3_Object_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<S3_Object_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<S3_Object_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type S3_Object_State_Transitions_Max_Fields = {
  __typename: 's3_object_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type S3_Object_State_Transitions_Min_Fields = {
  __typename: 's3_object_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "s3_object_state_transitions". */
export type S3_Object_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "s3_object_state_transitions" */
export enum S3_Object_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "s3_object_state_transitions" */
export type S3_Object_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: S3_Object_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type S3_Object_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<S3_Object_States_Enum>;
  toState?: InputMaybe<S3_Object_States_Enum>;
};

/** columns and relationships of "s3_object_states" */
export type S3_Object_States = {
  __typename: 's3_object_states';
  /** An array relationship */
  s3Objects: Array<S3_Objects>;
  /** An aggregate relationship */
  s3Objects_aggregate: S3_Objects_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "s3_object_states" */
export type S3_Object_StatesS3ObjectsArgs = {
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


/** columns and relationships of "s3_object_states" */
export type S3_Object_StatesS3Objects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};

/** aggregated selection of "s3_object_states" */
export type S3_Object_States_Aggregate = {
  __typename: 's3_object_states_aggregate';
  aggregate?: Maybe<S3_Object_States_Aggregate_Fields>;
  nodes: Array<S3_Object_States>;
};

/** aggregate fields of "s3_object_states" */
export type S3_Object_States_Aggregate_Fields = {
  __typename: 's3_object_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<S3_Object_States_Max_Fields>;
  min?: Maybe<S3_Object_States_Min_Fields>;
};


/** aggregate fields of "s3_object_states" */
export type S3_Object_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<S3_Object_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "s3_object_states". All fields are combined with a logical 'AND'. */
export type S3_Object_States_Bool_Exp = {
  _and?: InputMaybe<Array<S3_Object_States_Bool_Exp>>;
  _not?: InputMaybe<S3_Object_States_Bool_Exp>;
  _or?: InputMaybe<Array<S3_Object_States_Bool_Exp>>;
  s3Objects?: InputMaybe<S3_Objects_Bool_Exp>;
  s3Objects_aggregate?: InputMaybe<S3_Objects_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum S3_Object_States_Enum {
  NotUploaded = 'NOT_UPLOADED',
  Uploaded = 'UPLOADED'
}

/** Boolean expression to compare columns of type "s3_object_states_enum". All fields are combined with logical 'AND'. */
export type S3_Object_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<S3_Object_States_Enum>;
  _in?: InputMaybe<Array<S3_Object_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<S3_Object_States_Enum>;
  _nin?: InputMaybe<Array<S3_Object_States_Enum>>;
};

/** aggregate max on columns */
export type S3_Object_States_Max_Fields = {
  __typename: 's3_object_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type S3_Object_States_Min_Fields = {
  __typename: 's3_object_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "s3_object_states". */
export type S3_Object_States_Order_By = {
  s3Objects_aggregate?: InputMaybe<S3_Objects_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "s3_object_states" */
export enum S3_Object_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "s3_object_states" */
export type S3_Object_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: S3_Object_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type S3_Object_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "s3_object_visibilities" */
export type S3_Object_Visibilities = {
  __typename: 's3_object_visibilities';
  /** An array relationship */
  s3Objects: Array<S3_Objects>;
  /** An aggregate relationship */
  s3Objects_aggregate: S3_Objects_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "s3_object_visibilities" */
export type S3_Object_VisibilitiesS3ObjectsArgs = {
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


/** columns and relationships of "s3_object_visibilities" */
export type S3_Object_VisibilitiesS3Objects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};

/** aggregated selection of "s3_object_visibilities" */
export type S3_Object_Visibilities_Aggregate = {
  __typename: 's3_object_visibilities_aggregate';
  aggregate?: Maybe<S3_Object_Visibilities_Aggregate_Fields>;
  nodes: Array<S3_Object_Visibilities>;
};

/** aggregate fields of "s3_object_visibilities" */
export type S3_Object_Visibilities_Aggregate_Fields = {
  __typename: 's3_object_visibilities_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<S3_Object_Visibilities_Max_Fields>;
  min?: Maybe<S3_Object_Visibilities_Min_Fields>;
};


/** aggregate fields of "s3_object_visibilities" */
export type S3_Object_Visibilities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<S3_Object_Visibilities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "s3_object_visibilities". All fields are combined with a logical 'AND'. */
export type S3_Object_Visibilities_Bool_Exp = {
  _and?: InputMaybe<Array<S3_Object_Visibilities_Bool_Exp>>;
  _not?: InputMaybe<S3_Object_Visibilities_Bool_Exp>;
  _or?: InputMaybe<Array<S3_Object_Visibilities_Bool_Exp>>;
  s3Objects?: InputMaybe<S3_Objects_Bool_Exp>;
  s3Objects_aggregate?: InputMaybe<S3_Objects_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum S3_Object_Visibilities_Enum {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

/** Boolean expression to compare columns of type "s3_object_visibilities_enum". All fields are combined with logical 'AND'. */
export type S3_Object_Visibilities_Enum_Comparison_Exp = {
  _eq?: InputMaybe<S3_Object_Visibilities_Enum>;
  _in?: InputMaybe<Array<S3_Object_Visibilities_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<S3_Object_Visibilities_Enum>;
  _nin?: InputMaybe<Array<S3_Object_Visibilities_Enum>>;
};

/** aggregate max on columns */
export type S3_Object_Visibilities_Max_Fields = {
  __typename: 's3_object_visibilities_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type S3_Object_Visibilities_Min_Fields = {
  __typename: 's3_object_visibilities_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "s3_object_visibilities". */
export type S3_Object_Visibilities_Order_By = {
  s3Objects_aggregate?: InputMaybe<S3_Objects_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "s3_object_visibilities" */
export enum S3_Object_Visibilities_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "s3_object_visibilities" */
export type S3_Object_Visibilities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: S3_Object_Visibilities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type S3_Object_Visibilities_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "s3_objects" */
export type S3_Objects = {
  __typename: 's3_objects';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  abacusExports: Array<Abacus_Exports>;
  /** An aggregate relationship */
  abacusExports_aggregate: Abacus_Exports_Aggregate;
  bucket: Scalars['String'];
  contentHash?: Maybe<Scalars['bigint']>;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  creditNotesViaPdfDocument: Array<Credit_Notes>;
  /** An aggregate relationship */
  creditNotesViaPdfDocument_aggregate: Credit_Notes_Aggregate;
  /** An array relationship */
  creditNotesViaXmlDocument: Array<Credit_Notes>;
  /** An aggregate relationship */
  creditNotesViaXmlDocument_aggregate: Credit_Notes_Aggregate;
  /** An array relationship */
  customerInvoiceVersions: Array<Customer_Invoice_Versions>;
  /** An array relationship */
  customerInvoiceVersionsViaPdfDocument: Array<Customer_Invoice_Versions>;
  /** An aggregate relationship */
  customerInvoiceVersionsViaPdfDocument_aggregate: Customer_Invoice_Versions_Aggregate;
  /** An array relationship */
  customerInvoiceVersionsViaXmlDocument: Array<Customer_Invoice_Versions>;
  /** An aggregate relationship */
  customerInvoiceVersionsViaXmlDocument_aggregate: Customer_Invoice_Versions_Aggregate;
  /** An aggregate relationship */
  customerInvoiceVersions_aggregate: Customer_Invoice_Versions_Aggregate;
  /** An array relationship */
  customerOrderReturnItems: Array<Customer_Order_Return_Items>;
  /** An aggregate relationship */
  customerOrderReturnItems_aggregate: Customer_Order_Return_Items_Aggregate;
  /** An array relationship */
  customerOrdersViaDeliveryNotePdf: Array<Customer_Orders>;
  /** An aggregate relationship */
  customerOrdersViaDeliveryNotePdf_aggregate: Customer_Orders_Aggregate;
  /** An array relationship */
  customerOrdersViaPickingSlipPdf: Array<Customer_Orders>;
  /** An aggregate relationship */
  customerOrdersViaPickingSlipPdf_aggregate: Customer_Orders_Aggregate;
  /** An array relationship */
  dismantlingPlans: Array<Dismantling_Plans>;
  /** An array relationship */
  dismantlingPlansViaPdfDocument: Array<Dismantling_Plans>;
  /** An aggregate relationship */
  dismantlingPlansViaPdfDocument_aggregate: Dismantling_Plans_Aggregate;
  /** An aggregate relationship */
  dismantlingPlans_aggregate: Dismantling_Plans_Aggregate;
  id: Scalars['bigint'];
  /** An array relationship */
  images: Array<Images>;
  /** An aggregate relationship */
  images_aggregate: Images_Aggregate;
  objectKey: Scalars['String'];
  /** Returns basename of the object key. */
  objectKeyBasename?: Maybe<Scalars['String']>;
  /** An array relationship */
  partialDeliveries: Array<Partial_Deliveries>;
  /** An aggregate relationship */
  partialDeliveries_aggregate: Partial_Deliveries_Aggregate;
  /** An array relationship */
  pickedItems: Array<Picked_Items>;
  /** An aggregate relationship */
  pickedItems_aggregate: Picked_Items_Aggregate;
  presignedUrl?: Maybe<Scalars['String']>;
  /** An array relationship */
  priceLists: Array<Price_Lists>;
  /** An aggregate relationship */
  priceLists_aggregate: Price_Lists_Aggregate;
  /** An array relationship */
  productS3Objects: Array<Product_S3_Objects>;
  /** An aggregate relationship */
  productS3Objects_aggregate: Product_S3_Objects_Aggregate;
  publicUrl?: Maybe<Scalars['String']>;
  state: S3_Object_States_Enum;
  /** An array relationship */
  supplierOrderReturnItems: Array<Supplier_Order_Return_Items>;
  /** An aggregate relationship */
  supplierOrderReturnItems_aggregate: Supplier_Order_Return_Items_Aggregate;
  temporary: Scalars['Boolean'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  uploaderUser?: Maybe<Users>;
  uploaderUserId?: Maybe<Scalars['bigint']>;
  urlExpiresAt?: Maybe<Scalars['timestamptz']>;
  version: Scalars['bigint'];
  visibility: S3_Object_Visibilities_Enum;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsAbacusExportsArgs = {
  distinct_on?: InputMaybe<Array<Abacus_Exports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Abacus_Exports_Order_By>>;
  where?: InputMaybe<Abacus_Exports_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsAbacusExports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Abacus_Exports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Abacus_Exports_Order_By>>;
  where?: InputMaybe<Abacus_Exports_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsCreditNotesViaPdfDocumentArgs = {
  distinct_on?: InputMaybe<Array<Credit_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Notes_Order_By>>;
  where?: InputMaybe<Credit_Notes_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsCreditNotesViaPdfDocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Notes_Order_By>>;
  where?: InputMaybe<Credit_Notes_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsCreditNotesViaXmlDocumentArgs = {
  distinct_on?: InputMaybe<Array<Credit_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Notes_Order_By>>;
  where?: InputMaybe<Credit_Notes_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsCreditNotesViaXmlDocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Notes_Order_By>>;
  where?: InputMaybe<Credit_Notes_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsCustomerInvoiceVersionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Versions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsCustomerInvoiceVersionsViaPdfDocumentArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Versions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsCustomerInvoiceVersionsViaPdfDocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Versions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsCustomerInvoiceVersionsViaXmlDocumentArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Versions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsCustomerInvoiceVersionsViaXmlDocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Versions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsCustomerInvoiceVersions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Versions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsCustomerOrderReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsCustomerOrderReturnItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsCustomerOrdersViaDeliveryNotePdfArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsCustomerOrdersViaDeliveryNotePdf_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsCustomerOrdersViaPickingSlipPdfArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsCustomerOrdersViaPickingSlipPdf_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsDismantlingPlansArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plans_Order_By>>;
  where?: InputMaybe<Dismantling_Plans_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsDismantlingPlansViaPdfDocumentArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plans_Order_By>>;
  where?: InputMaybe<Dismantling_Plans_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsDismantlingPlansViaPdfDocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plans_Order_By>>;
  where?: InputMaybe<Dismantling_Plans_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsDismantlingPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plans_Order_By>>;
  where?: InputMaybe<Dismantling_Plans_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsImagesArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsImages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsPartialDeliveriesArgs = {
  distinct_on?: InputMaybe<Array<Partial_Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Deliveries_Order_By>>;
  where?: InputMaybe<Partial_Deliveries_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsPartialDeliveries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partial_Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Deliveries_Order_By>>;
  where?: InputMaybe<Partial_Deliveries_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsPickedItemsArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsPickedItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsPriceListsArgs = {
  distinct_on?: InputMaybe<Array<Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Lists_Order_By>>;
  where?: InputMaybe<Price_Lists_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsPriceLists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Lists_Order_By>>;
  where?: InputMaybe<Price_Lists_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsProductS3ObjectsArgs = {
  distinct_on?: InputMaybe<Array<Product_S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_S3_Objects_Order_By>>;
  where?: InputMaybe<Product_S3_Objects_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsProductS3Objects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_S3_Objects_Order_By>>;
  where?: InputMaybe<Product_S3_Objects_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsSupplierOrderReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "s3_objects" */
export type S3_ObjectsSupplierOrderReturnItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};

/** aggregated selection of "s3_objects" */
export type S3_Objects_Aggregate = {
  __typename: 's3_objects_aggregate';
  aggregate?: Maybe<S3_Objects_Aggregate_Fields>;
  nodes: Array<S3_Objects>;
};

export type S3_Objects_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<S3_Objects_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<S3_Objects_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<S3_Objects_Aggregate_Bool_Exp_Count>;
};

export type S3_Objects_Aggregate_Bool_Exp_Bool_And = {
  arguments: S3_Objects_Select_Column_S3_Objects_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<S3_Objects_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type S3_Objects_Aggregate_Bool_Exp_Bool_Or = {
  arguments: S3_Objects_Select_Column_S3_Objects_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<S3_Objects_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type S3_Objects_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<S3_Objects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<S3_Objects_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "s3_objects" */
export type S3_Objects_Aggregate_Fields = {
  __typename: 's3_objects_aggregate_fields';
  avg?: Maybe<S3_Objects_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<S3_Objects_Max_Fields>;
  min?: Maybe<S3_Objects_Min_Fields>;
  stddev?: Maybe<S3_Objects_Stddev_Fields>;
  stddev_pop?: Maybe<S3_Objects_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<S3_Objects_Stddev_Samp_Fields>;
  sum?: Maybe<S3_Objects_Sum_Fields>;
  var_pop?: Maybe<S3_Objects_Var_Pop_Fields>;
  var_samp?: Maybe<S3_Objects_Var_Samp_Fields>;
  variance?: Maybe<S3_Objects_Variance_Fields>;
};


/** aggregate fields of "s3_objects" */
export type S3_Objects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<S3_Objects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "s3_objects" */
export type S3_Objects_Aggregate_Order_By = {
  avg?: InputMaybe<S3_Objects_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<S3_Objects_Max_Order_By>;
  min?: InputMaybe<S3_Objects_Min_Order_By>;
  stddev?: InputMaybe<S3_Objects_Stddev_Order_By>;
  stddev_pop?: InputMaybe<S3_Objects_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<S3_Objects_Stddev_Samp_Order_By>;
  sum?: InputMaybe<S3_Objects_Sum_Order_By>;
  var_pop?: InputMaybe<S3_Objects_Var_Pop_Order_By>;
  var_samp?: InputMaybe<S3_Objects_Var_Samp_Order_By>;
  variance?: InputMaybe<S3_Objects_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "s3_objects" */
export type S3_Objects_Arr_Rel_Insert_Input = {
  data: Array<S3_Objects_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<S3_Objects_On_Conflict>;
};

/** aggregate avg on columns */
export type S3_Objects_Avg_Fields = {
  __typename: 's3_objects_avg_fields';
  contentHash?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uploaderUserId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "s3_objects" */
export type S3_Objects_Avg_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uploaderUserId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "s3_objects". All fields are combined with a logical 'AND'. */
export type S3_Objects_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<S3_Objects_Bool_Exp>>;
  _not?: InputMaybe<S3_Objects_Bool_Exp>;
  _or?: InputMaybe<Array<S3_Objects_Bool_Exp>>;
  abacusExports?: InputMaybe<Abacus_Exports_Bool_Exp>;
  abacusExports_aggregate?: InputMaybe<Abacus_Exports_Aggregate_Bool_Exp>;
  bucket?: InputMaybe<String_Comparison_Exp>;
  contentHash?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  creditNotesViaPdfDocument?: InputMaybe<Credit_Notes_Bool_Exp>;
  creditNotesViaPdfDocument_aggregate?: InputMaybe<Credit_Notes_Aggregate_Bool_Exp>;
  creditNotesViaXmlDocument?: InputMaybe<Credit_Notes_Bool_Exp>;
  creditNotesViaXmlDocument_aggregate?: InputMaybe<Credit_Notes_Aggregate_Bool_Exp>;
  customerInvoiceVersions?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
  customerInvoiceVersionsViaPdfDocument?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
  customerInvoiceVersionsViaPdfDocument_aggregate?: InputMaybe<Customer_Invoice_Versions_Aggregate_Bool_Exp>;
  customerInvoiceVersionsViaXmlDocument?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
  customerInvoiceVersionsViaXmlDocument_aggregate?: InputMaybe<Customer_Invoice_Versions_Aggregate_Bool_Exp>;
  customerInvoiceVersions_aggregate?: InputMaybe<Customer_Invoice_Versions_Aggregate_Bool_Exp>;
  customerOrderReturnItems?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
  customerOrderReturnItems_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Bool_Exp>;
  customerOrdersViaDeliveryNotePdf?: InputMaybe<Customer_Orders_Bool_Exp>;
  customerOrdersViaDeliveryNotePdf_aggregate?: InputMaybe<Customer_Orders_Aggregate_Bool_Exp>;
  customerOrdersViaPickingSlipPdf?: InputMaybe<Customer_Orders_Bool_Exp>;
  customerOrdersViaPickingSlipPdf_aggregate?: InputMaybe<Customer_Orders_Aggregate_Bool_Exp>;
  dismantlingPlans?: InputMaybe<Dismantling_Plans_Bool_Exp>;
  dismantlingPlansViaPdfDocument?: InputMaybe<Dismantling_Plans_Bool_Exp>;
  dismantlingPlansViaPdfDocument_aggregate?: InputMaybe<Dismantling_Plans_Aggregate_Bool_Exp>;
  dismantlingPlans_aggregate?: InputMaybe<Dismantling_Plans_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  images?: InputMaybe<Images_Bool_Exp>;
  images_aggregate?: InputMaybe<Images_Aggregate_Bool_Exp>;
  objectKey?: InputMaybe<String_Comparison_Exp>;
  objectKeyBasename?: InputMaybe<String_Comparison_Exp>;
  partialDeliveries?: InputMaybe<Partial_Deliveries_Bool_Exp>;
  partialDeliveries_aggregate?: InputMaybe<Partial_Deliveries_Aggregate_Bool_Exp>;
  pickedItems?: InputMaybe<Picked_Items_Bool_Exp>;
  pickedItems_aggregate?: InputMaybe<Picked_Items_Aggregate_Bool_Exp>;
  presignedUrl?: InputMaybe<String_Comparison_Exp>;
  priceLists?: InputMaybe<Price_Lists_Bool_Exp>;
  priceLists_aggregate?: InputMaybe<Price_Lists_Aggregate_Bool_Exp>;
  productS3Objects?: InputMaybe<Product_S3_Objects_Bool_Exp>;
  productS3Objects_aggregate?: InputMaybe<Product_S3_Objects_Aggregate_Bool_Exp>;
  publicUrl?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<S3_Object_States_Enum_Comparison_Exp>;
  supplierOrderReturnItems?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
  supplierOrderReturnItems_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Bool_Exp>;
  temporary?: InputMaybe<Boolean_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploaderUser?: InputMaybe<Users_Bool_Exp>;
  uploaderUserId?: InputMaybe<Bigint_Comparison_Exp>;
  urlExpiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
  visibility?: InputMaybe<S3_Object_Visibilities_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "s3_objects" */
export enum S3_Objects_Constraint {
  /** unique or primary key constraint on columns "bucket", "object_key" */
  BucketAndObjectKeyMustBeUnique = 'bucket_and_object_key_must_be_unique',
  /** unique or primary key constraint on columns "id" */
  S3ObjectsPkey = 's3_objects_pkey'
}

/** input type for incrementing numeric columns in table "s3_objects" */
export type S3_Objects_Inc_Input = {
  contentHash?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  uploaderUserId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "s3_objects" */
export type S3_Objects_Insert_Input = {
  bucket?: InputMaybe<Scalars['String']>;
  contentHash?: InputMaybe<Scalars['bigint']>;
  creditNotesViaPdfDocument?: InputMaybe<Credit_Notes_Arr_Rel_Insert_Input>;
  creditNotesViaXmlDocument?: InputMaybe<Credit_Notes_Arr_Rel_Insert_Input>;
  customerInvoiceVersions?: InputMaybe<Customer_Invoice_Versions_Arr_Rel_Insert_Input>;
  customerInvoiceVersionsViaPdfDocument?: InputMaybe<Customer_Invoice_Versions_Arr_Rel_Insert_Input>;
  customerInvoiceVersionsViaXmlDocument?: InputMaybe<Customer_Invoice_Versions_Arr_Rel_Insert_Input>;
  customerOrderReturnItems?: InputMaybe<Customer_Order_Return_Items_Arr_Rel_Insert_Input>;
  customerOrdersViaDeliveryNotePdf?: InputMaybe<Customer_Orders_Arr_Rel_Insert_Input>;
  customerOrdersViaPickingSlipPdf?: InputMaybe<Customer_Orders_Arr_Rel_Insert_Input>;
  dismantlingPlans?: InputMaybe<Dismantling_Plans_Arr_Rel_Insert_Input>;
  dismantlingPlansViaPdfDocument?: InputMaybe<Dismantling_Plans_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  images?: InputMaybe<Images_Arr_Rel_Insert_Input>;
  objectKey?: InputMaybe<Scalars['String']>;
  partialDeliveries?: InputMaybe<Partial_Deliveries_Arr_Rel_Insert_Input>;
  pickedItems?: InputMaybe<Picked_Items_Arr_Rel_Insert_Input>;
  presignedUrl?: InputMaybe<Scalars['String']>;
  priceLists?: InputMaybe<Price_Lists_Arr_Rel_Insert_Input>;
  productS3Objects?: InputMaybe<Product_S3_Objects_Arr_Rel_Insert_Input>;
  publicUrl?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<S3_Object_States_Enum>;
  supplierOrderReturnItems?: InputMaybe<Supplier_Order_Return_Items_Arr_Rel_Insert_Input>;
  temporary?: InputMaybe<Scalars['Boolean']>;
  uploaderUser?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  uploaderUserId?: InputMaybe<Scalars['bigint']>;
  urlExpiresAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
  visibility?: InputMaybe<S3_Object_Visibilities_Enum>;
};

/** aggregate max on columns */
export type S3_Objects_Max_Fields = {
  __typename: 's3_objects_max_fields';
  bucket?: Maybe<Scalars['String']>;
  contentHash?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  objectKey?: Maybe<Scalars['String']>;
  presignedUrl?: Maybe<Scalars['String']>;
  publicUrl?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uploaderUserId?: Maybe<Scalars['bigint']>;
  urlExpiresAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "s3_objects" */
export type S3_Objects_Max_Order_By = {
  bucket?: InputMaybe<Order_By>;
  contentHash?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  objectKey?: InputMaybe<Order_By>;
  presignedUrl?: InputMaybe<Order_By>;
  publicUrl?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploaderUserId?: InputMaybe<Order_By>;
  urlExpiresAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type S3_Objects_Min_Fields = {
  __typename: 's3_objects_min_fields';
  bucket?: Maybe<Scalars['String']>;
  contentHash?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  objectKey?: Maybe<Scalars['String']>;
  presignedUrl?: Maybe<Scalars['String']>;
  publicUrl?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uploaderUserId?: Maybe<Scalars['bigint']>;
  urlExpiresAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "s3_objects" */
export type S3_Objects_Min_Order_By = {
  bucket?: InputMaybe<Order_By>;
  contentHash?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  objectKey?: InputMaybe<Order_By>;
  presignedUrl?: InputMaybe<Order_By>;
  publicUrl?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploaderUserId?: InputMaybe<Order_By>;
  urlExpiresAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "s3_objects" */
export type S3_Objects_Mutation_Response = {
  __typename: 's3_objects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<S3_Objects>;
};

/** input type for inserting object relation for remote table "s3_objects" */
export type S3_Objects_Obj_Rel_Insert_Input = {
  data: S3_Objects_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<S3_Objects_On_Conflict>;
};

/** on_conflict condition type for table "s3_objects" */
export type S3_Objects_On_Conflict = {
  constraint: S3_Objects_Constraint;
  update_columns?: Array<S3_Objects_Update_Column>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};

/** Ordering options when selecting data from "s3_objects". */
export type S3_Objects_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  abacusExports_aggregate?: InputMaybe<Abacus_Exports_Aggregate_Order_By>;
  bucket?: InputMaybe<Order_By>;
  contentHash?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creditNotesViaPdfDocument_aggregate?: InputMaybe<Credit_Notes_Aggregate_Order_By>;
  creditNotesViaXmlDocument_aggregate?: InputMaybe<Credit_Notes_Aggregate_Order_By>;
  customerInvoiceVersionsViaPdfDocument_aggregate?: InputMaybe<Customer_Invoice_Versions_Aggregate_Order_By>;
  customerInvoiceVersionsViaXmlDocument_aggregate?: InputMaybe<Customer_Invoice_Versions_Aggregate_Order_By>;
  customerInvoiceVersions_aggregate?: InputMaybe<Customer_Invoice_Versions_Aggregate_Order_By>;
  customerOrderReturnItems_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Order_By>;
  customerOrdersViaDeliveryNotePdf_aggregate?: InputMaybe<Customer_Orders_Aggregate_Order_By>;
  customerOrdersViaPickingSlipPdf_aggregate?: InputMaybe<Customer_Orders_Aggregate_Order_By>;
  dismantlingPlansViaPdfDocument_aggregate?: InputMaybe<Dismantling_Plans_Aggregate_Order_By>;
  dismantlingPlans_aggregate?: InputMaybe<Dismantling_Plans_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  images_aggregate?: InputMaybe<Images_Aggregate_Order_By>;
  objectKey?: InputMaybe<Order_By>;
  objectKeyBasename?: InputMaybe<Order_By>;
  partialDeliveries_aggregate?: InputMaybe<Partial_Deliveries_Aggregate_Order_By>;
  pickedItems_aggregate?: InputMaybe<Picked_Items_Aggregate_Order_By>;
  presignedUrl?: InputMaybe<Order_By>;
  priceLists_aggregate?: InputMaybe<Price_Lists_Aggregate_Order_By>;
  productS3Objects_aggregate?: InputMaybe<Product_S3_Objects_Aggregate_Order_By>;
  publicUrl?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  supplierOrderReturnItems_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Order_By>;
  temporary?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploaderUser?: InputMaybe<Users_Order_By>;
  uploaderUserId?: InputMaybe<Order_By>;
  urlExpiresAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  visibility?: InputMaybe<Order_By>;
};

/** primary key columns input for table: s3_objects */
export type S3_Objects_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "s3_objects" */
export enum S3_Objects_Select_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  ContentHash = 'contentHash',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectKey = 'objectKey',
  /** column name */
  PresignedUrl = 'presignedUrl',
  /** column name */
  PublicUrl = 'publicUrl',
  /** column name */
  State = 'state',
  /** column name */
  Temporary = 'temporary',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UploaderUserId = 'uploaderUserId',
  /** column name */
  UrlExpiresAt = 'urlExpiresAt',
  /** column name */
  Version = 'version',
  /** column name */
  Visibility = 'visibility'
}

/** select "s3_objects_aggregate_bool_exp_bool_and_arguments_columns" columns of table "s3_objects" */
export enum S3_Objects_Select_Column_S3_Objects_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Temporary = 'temporary'
}

/** select "s3_objects_aggregate_bool_exp_bool_or_arguments_columns" columns of table "s3_objects" */
export enum S3_Objects_Select_Column_S3_Objects_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Temporary = 'temporary'
}

/** input type for updating data in table "s3_objects" */
export type S3_Objects_Set_Input = {
  bucket?: InputMaybe<Scalars['String']>;
  contentHash?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  objectKey?: InputMaybe<Scalars['String']>;
  presignedUrl?: InputMaybe<Scalars['String']>;
  publicUrl?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<S3_Object_States_Enum>;
  temporary?: InputMaybe<Scalars['Boolean']>;
  uploaderUserId?: InputMaybe<Scalars['bigint']>;
  urlExpiresAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
  visibility?: InputMaybe<S3_Object_Visibilities_Enum>;
};

/** aggregate stddev on columns */
export type S3_Objects_Stddev_Fields = {
  __typename: 's3_objects_stddev_fields';
  contentHash?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uploaderUserId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "s3_objects" */
export type S3_Objects_Stddev_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uploaderUserId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type S3_Objects_Stddev_Pop_Fields = {
  __typename: 's3_objects_stddev_pop_fields';
  contentHash?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uploaderUserId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "s3_objects" */
export type S3_Objects_Stddev_Pop_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uploaderUserId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type S3_Objects_Stddev_Samp_Fields = {
  __typename: 's3_objects_stddev_samp_fields';
  contentHash?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uploaderUserId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "s3_objects" */
export type S3_Objects_Stddev_Samp_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uploaderUserId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "s3_objects" */
export type S3_Objects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: S3_Objects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type S3_Objects_Stream_Cursor_Value_Input = {
  bucket?: InputMaybe<Scalars['String']>;
  contentHash?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  objectKey?: InputMaybe<Scalars['String']>;
  presignedUrl?: InputMaybe<Scalars['String']>;
  publicUrl?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<S3_Object_States_Enum>;
  temporary?: InputMaybe<Scalars['Boolean']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  uploaderUserId?: InputMaybe<Scalars['bigint']>;
  urlExpiresAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
  visibility?: InputMaybe<S3_Object_Visibilities_Enum>;
};

/** aggregate sum on columns */
export type S3_Objects_Sum_Fields = {
  __typename: 's3_objects_sum_fields';
  contentHash?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  uploaderUserId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "s3_objects" */
export type S3_Objects_Sum_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uploaderUserId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "s3_objects" */
export enum S3_Objects_Update_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  ContentHash = 'contentHash',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectKey = 'objectKey',
  /** column name */
  PresignedUrl = 'presignedUrl',
  /** column name */
  PublicUrl = 'publicUrl',
  /** column name */
  State = 'state',
  /** column name */
  Temporary = 'temporary',
  /** column name */
  UploaderUserId = 'uploaderUserId',
  /** column name */
  UrlExpiresAt = 'urlExpiresAt',
  /** column name */
  Version = 'version',
  /** column name */
  Visibility = 'visibility'
}

export type S3_Objects_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<S3_Objects_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<S3_Objects_Set_Input>;
  /** filter the rows which have to be updated */
  where: S3_Objects_Bool_Exp;
};

/** aggregate var_pop on columns */
export type S3_Objects_Var_Pop_Fields = {
  __typename: 's3_objects_var_pop_fields';
  contentHash?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uploaderUserId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "s3_objects" */
export type S3_Objects_Var_Pop_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uploaderUserId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type S3_Objects_Var_Samp_Fields = {
  __typename: 's3_objects_var_samp_fields';
  contentHash?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uploaderUserId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "s3_objects" */
export type S3_Objects_Var_Samp_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uploaderUserId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type S3_Objects_Variance_Fields = {
  __typename: 's3_objects_variance_fields';
  contentHash?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  uploaderUserId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "s3_objects" */
export type S3_Objects_Variance_Order_By = {
  contentHash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  uploaderUserId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "sales_channels" */
export type Sales_Channels = {
  __typename: 'sales_channels';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  customerOrders: Array<Customer_Orders>;
  /** An aggregate relationship */
  customerOrders_aggregate: Customer_Orders_Aggregate;
  i18n: Scalars['jsonb'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  ordinalPosition: Scalars['Int'];
  /** An array relationship */
  productSalesChannelMembers: Array<Product_Sales_Channel_Members>;
  /** An aggregate relationship */
  productSalesChannelMembers_aggregate: Product_Sales_Channel_Members_Aggregate;
  protected: Scalars['Boolean'];
  tenant: Scalars['String'];
};


/** columns and relationships of "sales_channels" */
export type Sales_ChannelsCustomerOrdersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** columns and relationships of "sales_channels" */
export type Sales_ChannelsCustomerOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** columns and relationships of "sales_channels" */
export type Sales_ChannelsI18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "sales_channels" */
export type Sales_ChannelsProductSalesChannelMembersArgs = {
  distinct_on?: InputMaybe<Array<Product_Sales_Channel_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Sales_Channel_Members_Order_By>>;
  where?: InputMaybe<Product_Sales_Channel_Members_Bool_Exp>;
};


/** columns and relationships of "sales_channels" */
export type Sales_ChannelsProductSalesChannelMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Sales_Channel_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Sales_Channel_Members_Order_By>>;
  where?: InputMaybe<Product_Sales_Channel_Members_Bool_Exp>;
};

/** aggregated selection of "sales_channels" */
export type Sales_Channels_Aggregate = {
  __typename: 'sales_channels_aggregate';
  aggregate?: Maybe<Sales_Channels_Aggregate_Fields>;
  nodes: Array<Sales_Channels>;
};

export type Sales_Channels_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Sales_Channels_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Sales_Channels_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Sales_Channels_Aggregate_Bool_Exp_Count>;
};

export type Sales_Channels_Aggregate_Bool_Exp_Bool_And = {
  arguments: Sales_Channels_Select_Column_Sales_Channels_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Sales_Channels_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Sales_Channels_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Sales_Channels_Select_Column_Sales_Channels_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Sales_Channels_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Sales_Channels_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Sales_Channels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Sales_Channels_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "sales_channels" */
export type Sales_Channels_Aggregate_Fields = {
  __typename: 'sales_channels_aggregate_fields';
  avg?: Maybe<Sales_Channels_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sales_Channels_Max_Fields>;
  min?: Maybe<Sales_Channels_Min_Fields>;
  stddev?: Maybe<Sales_Channels_Stddev_Fields>;
  stddev_pop?: Maybe<Sales_Channels_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sales_Channels_Stddev_Samp_Fields>;
  sum?: Maybe<Sales_Channels_Sum_Fields>;
  var_pop?: Maybe<Sales_Channels_Var_Pop_Fields>;
  var_samp?: Maybe<Sales_Channels_Var_Samp_Fields>;
  variance?: Maybe<Sales_Channels_Variance_Fields>;
};


/** aggregate fields of "sales_channels" */
export type Sales_Channels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sales_Channels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sales_channels" */
export type Sales_Channels_Aggregate_Order_By = {
  avg?: InputMaybe<Sales_Channels_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sales_Channels_Max_Order_By>;
  min?: InputMaybe<Sales_Channels_Min_Order_By>;
  stddev?: InputMaybe<Sales_Channels_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sales_Channels_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sales_Channels_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sales_Channels_Sum_Order_By>;
  var_pop?: InputMaybe<Sales_Channels_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sales_Channels_Var_Samp_Order_By>;
  variance?: InputMaybe<Sales_Channels_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Sales_Channels_Avg_Fields = {
  __typename: 'sales_channels_avg_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sales_channels" */
export type Sales_Channels_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sales_channels". All fields are combined with a logical 'AND'. */
export type Sales_Channels_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Sales_Channels_Bool_Exp>>;
  _not?: InputMaybe<Sales_Channels_Bool_Exp>;
  _or?: InputMaybe<Array<Sales_Channels_Bool_Exp>>;
  customerOrders?: InputMaybe<Customer_Orders_Bool_Exp>;
  customerOrders_aggregate?: InputMaybe<Customer_Orders_Aggregate_Bool_Exp>;
  i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  productSalesChannelMembers?: InputMaybe<Product_Sales_Channel_Members_Bool_Exp>;
  productSalesChannelMembers_aggregate?: InputMaybe<Product_Sales_Channel_Members_Aggregate_Bool_Exp>;
  protected?: InputMaybe<Boolean_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Sales_Channels_Max_Fields = {
  __typename: 'sales_channels_max_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "sales_channels" */
export type Sales_Channels_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Sales_Channels_Min_Fields = {
  __typename: 'sales_channels_min_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "sales_channels" */
export type Sales_Channels_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "sales_channels". */
export type Sales_Channels_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  customerOrders_aggregate?: InputMaybe<Customer_Orders_Aggregate_Order_By>;
  i18n?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  productSalesChannelMembers_aggregate?: InputMaybe<Product_Sales_Channel_Members_Aggregate_Order_By>;
  protected?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** select columns of table "sales_channels" */
export enum Sales_Channels_Select_Column {
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  Protected = 'protected',
  /** column name */
  Tenant = 'tenant'
}

/** select "sales_channels_aggregate_bool_exp_bool_and_arguments_columns" columns of table "sales_channels" */
export enum Sales_Channels_Select_Column_Sales_Channels_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Protected = 'protected'
}

/** select "sales_channels_aggregate_bool_exp_bool_or_arguments_columns" columns of table "sales_channels" */
export enum Sales_Channels_Select_Column_Sales_Channels_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Protected = 'protected'
}

/** aggregate stddev on columns */
export type Sales_Channels_Stddev_Fields = {
  __typename: 'sales_channels_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sales_channels" */
export type Sales_Channels_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sales_Channels_Stddev_Pop_Fields = {
  __typename: 'sales_channels_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sales_channels" */
export type Sales_Channels_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sales_Channels_Stddev_Samp_Fields = {
  __typename: 'sales_channels_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sales_channels" */
export type Sales_Channels_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "sales_channels" */
export type Sales_Channels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sales_Channels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sales_Channels_Stream_Cursor_Value_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  protected?: InputMaybe<Scalars['Boolean']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Sales_Channels_Sum_Fields = {
  __typename: 'sales_channels_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "sales_channels" */
export type Sales_Channels_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Sales_Channels_Var_Pop_Fields = {
  __typename: 'sales_channels_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sales_channels" */
export type Sales_Channels_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sales_Channels_Var_Samp_Fields = {
  __typename: 'sales_channels_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sales_channels" */
export type Sales_Channels_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Sales_Channels_Variance_Fields = {
  __typename: 'sales_channels_variance_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sales_channels" */
export type Sales_Channels_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** columns and relationships of "sales_team_state_transitions" */
export type Sales_Team_State_Transitions = {
  __typename: 'sales_team_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Sales_Team_States_Enum;
  toState: Sales_Team_States_Enum;
};

/** aggregated selection of "sales_team_state_transitions" */
export type Sales_Team_State_Transitions_Aggregate = {
  __typename: 'sales_team_state_transitions_aggregate';
  aggregate?: Maybe<Sales_Team_State_Transitions_Aggregate_Fields>;
  nodes: Array<Sales_Team_State_Transitions>;
};

/** aggregate fields of "sales_team_state_transitions" */
export type Sales_Team_State_Transitions_Aggregate_Fields = {
  __typename: 'sales_team_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Sales_Team_State_Transitions_Max_Fields>;
  min?: Maybe<Sales_Team_State_Transitions_Min_Fields>;
};


/** aggregate fields of "sales_team_state_transitions" */
export type Sales_Team_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sales_Team_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "sales_team_state_transitions". All fields are combined with a logical 'AND'. */
export type Sales_Team_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Sales_Team_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Sales_Team_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Sales_Team_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Sales_Team_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Sales_Team_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Sales_Team_State_Transitions_Max_Fields = {
  __typename: 'sales_team_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Sales_Team_State_Transitions_Min_Fields = {
  __typename: 'sales_team_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "sales_team_state_transitions". */
export type Sales_Team_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "sales_team_state_transitions" */
export enum Sales_Team_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "sales_team_state_transitions" */
export type Sales_Team_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sales_Team_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sales_Team_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Sales_Team_States_Enum>;
  toState?: InputMaybe<Sales_Team_States_Enum>;
};

/** columns and relationships of "sales_team_states" */
export type Sales_Team_States = {
  __typename: 'sales_team_states';
  /** An array relationship */
  salesTeams: Array<Sales_Teams>;
  /** An aggregate relationship */
  salesTeams_aggregate: Sales_Teams_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "sales_team_states" */
export type Sales_Team_StatesSalesTeamsArgs = {
  distinct_on?: InputMaybe<Array<Sales_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Teams_Order_By>>;
  where?: InputMaybe<Sales_Teams_Bool_Exp>;
};


/** columns and relationships of "sales_team_states" */
export type Sales_Team_StatesSalesTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sales_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Teams_Order_By>>;
  where?: InputMaybe<Sales_Teams_Bool_Exp>;
};

/** aggregated selection of "sales_team_states" */
export type Sales_Team_States_Aggregate = {
  __typename: 'sales_team_states_aggregate';
  aggregate?: Maybe<Sales_Team_States_Aggregate_Fields>;
  nodes: Array<Sales_Team_States>;
};

/** aggregate fields of "sales_team_states" */
export type Sales_Team_States_Aggregate_Fields = {
  __typename: 'sales_team_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Sales_Team_States_Max_Fields>;
  min?: Maybe<Sales_Team_States_Min_Fields>;
};


/** aggregate fields of "sales_team_states" */
export type Sales_Team_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sales_Team_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "sales_team_states". All fields are combined with a logical 'AND'. */
export type Sales_Team_States_Bool_Exp = {
  _and?: InputMaybe<Array<Sales_Team_States_Bool_Exp>>;
  _not?: InputMaybe<Sales_Team_States_Bool_Exp>;
  _or?: InputMaybe<Array<Sales_Team_States_Bool_Exp>>;
  salesTeams?: InputMaybe<Sales_Teams_Bool_Exp>;
  salesTeams_aggregate?: InputMaybe<Sales_Teams_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Sales_Team_States_Enum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

/** Boolean expression to compare columns of type "sales_team_states_enum". All fields are combined with logical 'AND'. */
export type Sales_Team_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Sales_Team_States_Enum>;
  _in?: InputMaybe<Array<Sales_Team_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Sales_Team_States_Enum>;
  _nin?: InputMaybe<Array<Sales_Team_States_Enum>>;
};

/** aggregate max on columns */
export type Sales_Team_States_Max_Fields = {
  __typename: 'sales_team_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Sales_Team_States_Min_Fields = {
  __typename: 'sales_team_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "sales_team_states". */
export type Sales_Team_States_Order_By = {
  salesTeams_aggregate?: InputMaybe<Sales_Teams_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "sales_team_states" */
export enum Sales_Team_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "sales_team_states" */
export type Sales_Team_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sales_Team_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sales_Team_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "sales_team_users" */
export type Sales_Team_Users = {
  __typename: 'sales_team_users';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  salesTeam: Sales_Teams;
  salesTeamId: Scalars['bigint'];
  tenant: Scalars['String'];
  /** An object relationship */
  user: Users;
  userId: Scalars['bigint'];
};

/** aggregated selection of "sales_team_users" */
export type Sales_Team_Users_Aggregate = {
  __typename: 'sales_team_users_aggregate';
  aggregate?: Maybe<Sales_Team_Users_Aggregate_Fields>;
  nodes: Array<Sales_Team_Users>;
};

export type Sales_Team_Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Sales_Team_Users_Aggregate_Bool_Exp_Count>;
};

export type Sales_Team_Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Sales_Team_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Sales_Team_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "sales_team_users" */
export type Sales_Team_Users_Aggregate_Fields = {
  __typename: 'sales_team_users_aggregate_fields';
  avg?: Maybe<Sales_Team_Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sales_Team_Users_Max_Fields>;
  min?: Maybe<Sales_Team_Users_Min_Fields>;
  stddev?: Maybe<Sales_Team_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Sales_Team_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sales_Team_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Sales_Team_Users_Sum_Fields>;
  var_pop?: Maybe<Sales_Team_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Sales_Team_Users_Var_Samp_Fields>;
  variance?: Maybe<Sales_Team_Users_Variance_Fields>;
};


/** aggregate fields of "sales_team_users" */
export type Sales_Team_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sales_Team_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sales_team_users" */
export type Sales_Team_Users_Aggregate_Order_By = {
  avg?: InputMaybe<Sales_Team_Users_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sales_Team_Users_Max_Order_By>;
  min?: InputMaybe<Sales_Team_Users_Min_Order_By>;
  stddev?: InputMaybe<Sales_Team_Users_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sales_Team_Users_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sales_Team_Users_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sales_Team_Users_Sum_Order_By>;
  var_pop?: InputMaybe<Sales_Team_Users_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sales_Team_Users_Var_Samp_Order_By>;
  variance?: InputMaybe<Sales_Team_Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "sales_team_users" */
export type Sales_Team_Users_Arr_Rel_Insert_Input = {
  data: Array<Sales_Team_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Sales_Team_Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Sales_Team_Users_Avg_Fields = {
  __typename: 'sales_team_users_avg_fields';
  salesTeamId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sales_team_users" */
export type Sales_Team_Users_Avg_Order_By = {
  salesTeamId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sales_team_users". All fields are combined with a logical 'AND'. */
export type Sales_Team_Users_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Sales_Team_Users_Bool_Exp>>;
  _not?: InputMaybe<Sales_Team_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Sales_Team_Users_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  salesTeam?: InputMaybe<Sales_Teams_Bool_Exp>;
  salesTeamId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "sales_team_users" */
export enum Sales_Team_Users_Constraint {
  /** unique or primary key constraint on columns "user_id", "sales_team_id" */
  SalesTeamUsersPkey = 'sales_team_users_pkey'
}

/** input type for incrementing numeric columns in table "sales_team_users" */
export type Sales_Team_Users_Inc_Input = {
  salesTeamId?: InputMaybe<Scalars['bigint']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "sales_team_users" */
export type Sales_Team_Users_Insert_Input = {
  salesTeam?: InputMaybe<Sales_Teams_Obj_Rel_Insert_Input>;
  salesTeamId?: InputMaybe<Scalars['bigint']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Sales_Team_Users_Max_Fields = {
  __typename: 'sales_team_users_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  salesTeamId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "sales_team_users" */
export type Sales_Team_Users_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Sales_Team_Users_Min_Fields = {
  __typename: 'sales_team_users_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  salesTeamId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "sales_team_users" */
export type Sales_Team_Users_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "sales_team_users" */
export type Sales_Team_Users_Mutation_Response = {
  __typename: 'sales_team_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sales_Team_Users>;
};

/** on_conflict condition type for table "sales_team_users" */
export type Sales_Team_Users_On_Conflict = {
  constraint: Sales_Team_Users_Constraint;
  update_columns?: Array<Sales_Team_Users_Update_Column>;
  where?: InputMaybe<Sales_Team_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "sales_team_users". */
export type Sales_Team_Users_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  salesTeam?: InputMaybe<Sales_Teams_Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sales_team_users */
export type Sales_Team_Users_Pk_Columns_Input = {
  salesTeamId: Scalars['bigint'];
  userId: Scalars['bigint'];
};

/** select columns of table "sales_team_users" */
export enum Sales_Team_Users_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  SalesTeamId = 'salesTeamId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "sales_team_users" */
export type Sales_Team_Users_Set_Input = {
  salesTeamId?: InputMaybe<Scalars['bigint']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Sales_Team_Users_Stddev_Fields = {
  __typename: 'sales_team_users_stddev_fields';
  salesTeamId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sales_team_users" */
export type Sales_Team_Users_Stddev_Order_By = {
  salesTeamId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sales_Team_Users_Stddev_Pop_Fields = {
  __typename: 'sales_team_users_stddev_pop_fields';
  salesTeamId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sales_team_users" */
export type Sales_Team_Users_Stddev_Pop_Order_By = {
  salesTeamId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sales_Team_Users_Stddev_Samp_Fields = {
  __typename: 'sales_team_users_stddev_samp_fields';
  salesTeamId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sales_team_users" */
export type Sales_Team_Users_Stddev_Samp_Order_By = {
  salesTeamId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "sales_team_users" */
export type Sales_Team_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sales_Team_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sales_Team_Users_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  salesTeamId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Sales_Team_Users_Sum_Fields = {
  __typename: 'sales_team_users_sum_fields';
  salesTeamId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "sales_team_users" */
export type Sales_Team_Users_Sum_Order_By = {
  salesTeamId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** update columns of table "sales_team_users" */
export enum Sales_Team_Users_Update_Column {
  /** column name */
  SalesTeamId = 'salesTeamId',
  /** column name */
  UserId = 'userId'
}

export type Sales_Team_Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sales_Team_Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sales_Team_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sales_Team_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sales_Team_Users_Var_Pop_Fields = {
  __typename: 'sales_team_users_var_pop_fields';
  salesTeamId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sales_team_users" */
export type Sales_Team_Users_Var_Pop_Order_By = {
  salesTeamId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sales_Team_Users_Var_Samp_Fields = {
  __typename: 'sales_team_users_var_samp_fields';
  salesTeamId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sales_team_users" */
export type Sales_Team_Users_Var_Samp_Order_By = {
  salesTeamId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Sales_Team_Users_Variance_Fields = {
  __typename: 'sales_team_users_variance_fields';
  salesTeamId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sales_team_users" */
export type Sales_Team_Users_Variance_Order_By = {
  salesTeamId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "sales_teams" */
export type Sales_Teams = {
  __typename: 'sales_teams';
  /** An object relationship */
  Tenant: Tenants;
  domainId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  globalSearchResults: Array<Global_Search_Result>;
  /** An aggregate relationship */
  globalSearchResults_aggregate: Global_Search_Result_Aggregate;
  id: Scalars['bigint'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  /** An array relationship */
  salesTeamUsers: Array<Sales_Team_Users>;
  /** An aggregate relationship */
  salesTeamUsers_aggregate: Sales_Team_Users_Aggregate;
  state: Sales_Team_States_Enum;
  /** An array relationship */
  temporalSalesTeamsCustomers: Array<Temporal_Sales_Teams_Customers>;
  /** An aggregate relationship */
  temporalSalesTeamsCustomers_aggregate: Temporal_Sales_Teams_Customers_Aggregate;
  tenant: Scalars['String'];
};


/** columns and relationships of "sales_teams" */
export type Sales_TeamsGlobalSearchResultsArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


/** columns and relationships of "sales_teams" */
export type Sales_TeamsGlobalSearchResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


/** columns and relationships of "sales_teams" */
export type Sales_TeamsSalesTeamUsersArgs = {
  distinct_on?: InputMaybe<Array<Sales_Team_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Team_Users_Order_By>>;
  where?: InputMaybe<Sales_Team_Users_Bool_Exp>;
};


/** columns and relationships of "sales_teams" */
export type Sales_TeamsSalesTeamUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sales_Team_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Team_Users_Order_By>>;
  where?: InputMaybe<Sales_Team_Users_Bool_Exp>;
};


/** columns and relationships of "sales_teams" */
export type Sales_TeamsTemporalSalesTeamsCustomersArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Order_By>>;
  where?: InputMaybe<Temporal_Sales_Teams_Customers_Bool_Exp>;
};


/** columns and relationships of "sales_teams" */
export type Sales_TeamsTemporalSalesTeamsCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Order_By>>;
  where?: InputMaybe<Temporal_Sales_Teams_Customers_Bool_Exp>;
};

/** aggregated selection of "sales_teams" */
export type Sales_Teams_Aggregate = {
  __typename: 'sales_teams_aggregate';
  aggregate?: Maybe<Sales_Teams_Aggregate_Fields>;
  nodes: Array<Sales_Teams>;
};

export type Sales_Teams_Aggregate_Bool_Exp = {
  count?: InputMaybe<Sales_Teams_Aggregate_Bool_Exp_Count>;
};

export type Sales_Teams_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Sales_Teams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Sales_Teams_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "sales_teams" */
export type Sales_Teams_Aggregate_Fields = {
  __typename: 'sales_teams_aggregate_fields';
  avg?: Maybe<Sales_Teams_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sales_Teams_Max_Fields>;
  min?: Maybe<Sales_Teams_Min_Fields>;
  stddev?: Maybe<Sales_Teams_Stddev_Fields>;
  stddev_pop?: Maybe<Sales_Teams_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sales_Teams_Stddev_Samp_Fields>;
  sum?: Maybe<Sales_Teams_Sum_Fields>;
  var_pop?: Maybe<Sales_Teams_Var_Pop_Fields>;
  var_samp?: Maybe<Sales_Teams_Var_Samp_Fields>;
  variance?: Maybe<Sales_Teams_Variance_Fields>;
};


/** aggregate fields of "sales_teams" */
export type Sales_Teams_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sales_Teams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sales_teams" */
export type Sales_Teams_Aggregate_Order_By = {
  avg?: InputMaybe<Sales_Teams_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sales_Teams_Max_Order_By>;
  min?: InputMaybe<Sales_Teams_Min_Order_By>;
  stddev?: InputMaybe<Sales_Teams_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sales_Teams_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sales_Teams_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sales_Teams_Sum_Order_By>;
  var_pop?: InputMaybe<Sales_Teams_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sales_Teams_Var_Samp_Order_By>;
  variance?: InputMaybe<Sales_Teams_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Sales_Teams_Avg_Fields = {
  __typename: 'sales_teams_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sales_teams" */
export type Sales_Teams_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sales_teams". All fields are combined with a logical 'AND'. */
export type Sales_Teams_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Sales_Teams_Bool_Exp>>;
  _not?: InputMaybe<Sales_Teams_Bool_Exp>;
  _or?: InputMaybe<Array<Sales_Teams_Bool_Exp>>;
  domainId?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  globalSearchResults?: InputMaybe<Global_Search_Result_Bool_Exp>;
  globalSearchResults_aggregate?: InputMaybe<Global_Search_Result_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  salesTeamUsers?: InputMaybe<Sales_Team_Users_Bool_Exp>;
  salesTeamUsers_aggregate?: InputMaybe<Sales_Team_Users_Aggregate_Bool_Exp>;
  state?: InputMaybe<Sales_Team_States_Enum_Comparison_Exp>;
  temporalSalesTeamsCustomers?: InputMaybe<Temporal_Sales_Teams_Customers_Bool_Exp>;
  temporalSalesTeamsCustomers_aggregate?: InputMaybe<Temporal_Sales_Teams_Customers_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sales_teams" */
export enum Sales_Teams_Constraint {
  /** unique or primary key constraint on columns "name", "tenant" */
  SalesTeamsName = 'sales_teams_name',
  /** unique or primary key constraint on columns "id" */
  SalesTeamsPkey = 'sales_teams_pkey',
  /** unique or primary key constraint on columns "tenant", "domain_id" */
  SalesTeamsTenantDomainId = 'sales_teams_tenant_domain_id'
}

/** input type for incrementing numeric columns in table "sales_teams" */
export type Sales_Teams_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "sales_teams" */
export type Sales_Teams_Insert_Input = {
  domainId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  salesTeamUsers?: InputMaybe<Sales_Team_Users_Arr_Rel_Insert_Input>;
  state?: InputMaybe<Sales_Team_States_Enum>;
  temporalSalesTeamsCustomers?: InputMaybe<Temporal_Sales_Teams_Customers_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Sales_Teams_Max_Fields = {
  __typename: 'sales_teams_max_fields';
  domainId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "sales_teams" */
export type Sales_Teams_Max_Order_By = {
  domainId?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Sales_Teams_Min_Fields = {
  __typename: 'sales_teams_min_fields';
  domainId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "sales_teams" */
export type Sales_Teams_Min_Order_By = {
  domainId?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "sales_teams" */
export type Sales_Teams_Mutation_Response = {
  __typename: 'sales_teams_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sales_Teams>;
};

/** input type for inserting object relation for remote table "sales_teams" */
export type Sales_Teams_Obj_Rel_Insert_Input = {
  data: Sales_Teams_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Sales_Teams_On_Conflict>;
};

/** on_conflict condition type for table "sales_teams" */
export type Sales_Teams_On_Conflict = {
  constraint: Sales_Teams_Constraint;
  update_columns?: Array<Sales_Teams_Update_Column>;
  where?: InputMaybe<Sales_Teams_Bool_Exp>;
};

/** Ordering options when selecting data from "sales_teams". */
export type Sales_Teams_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  domainId?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  globalSearchResults_aggregate?: InputMaybe<Global_Search_Result_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  salesTeamUsers_aggregate?: InputMaybe<Sales_Team_Users_Aggregate_Order_By>;
  state?: InputMaybe<Order_By>;
  temporalSalesTeamsCustomers_aggregate?: InputMaybe<Temporal_Sales_Teams_Customers_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sales_teams */
export type Sales_Teams_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "sales_teams" */
export enum Sales_Teams_Select_Column {
  /** column name */
  DomainId = 'domainId',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant'
}

/** input type for updating data in table "sales_teams" */
export type Sales_Teams_Set_Input = {
  domainId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Sales_Team_States_Enum>;
};

/** aggregate stddev on columns */
export type Sales_Teams_Stddev_Fields = {
  __typename: 'sales_teams_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sales_teams" */
export type Sales_Teams_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sales_Teams_Stddev_Pop_Fields = {
  __typename: 'sales_teams_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sales_teams" */
export type Sales_Teams_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sales_Teams_Stddev_Samp_Fields = {
  __typename: 'sales_teams_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sales_teams" */
export type Sales_Teams_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "sales_teams" */
export type Sales_Teams_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sales_Teams_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sales_Teams_Stream_Cursor_Value_Input = {
  domainId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Sales_Team_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Sales_Teams_Sum_Fields = {
  __typename: 'sales_teams_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "sales_teams" */
export type Sales_Teams_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "sales_teams" */
export enum Sales_Teams_Update_Column {
  /** column name */
  DomainId = 'domainId',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  State = 'state'
}

export type Sales_Teams_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sales_Teams_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sales_Teams_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sales_Teams_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sales_Teams_Var_Pop_Fields = {
  __typename: 'sales_teams_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sales_teams" */
export type Sales_Teams_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sales_Teams_Var_Samp_Fields = {
  __typename: 'sales_teams_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sales_teams" */
export type Sales_Teams_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Sales_Teams_Variance_Fields = {
  __typename: 'sales_teams_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sales_teams" */
export type Sales_Teams_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "salutations" */
export type Salutations = {
  __typename: 'salutations';
  value: Scalars['String'];
};

/** aggregated selection of "salutations" */
export type Salutations_Aggregate = {
  __typename: 'salutations_aggregate';
  aggregate?: Maybe<Salutations_Aggregate_Fields>;
  nodes: Array<Salutations>;
};

/** aggregate fields of "salutations" */
export type Salutations_Aggregate_Fields = {
  __typename: 'salutations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Salutations_Max_Fields>;
  min?: Maybe<Salutations_Min_Fields>;
};


/** aggregate fields of "salutations" */
export type Salutations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Salutations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "salutations". All fields are combined with a logical 'AND'. */
export type Salutations_Bool_Exp = {
  _and?: InputMaybe<Array<Salutations_Bool_Exp>>;
  _not?: InputMaybe<Salutations_Bool_Exp>;
  _or?: InputMaybe<Array<Salutations_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Salutations_Max_Fields = {
  __typename: 'salutations_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Salutations_Min_Fields = {
  __typename: 'salutations_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "salutations". */
export type Salutations_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** select columns of table "salutations" */
export enum Salutations_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "salutations" */
export type Salutations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Salutations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Salutations_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

export type Search_Contacts_Args = {
  lang?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Customer_Groups_Args = {
  lang?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Customer_Order_Item_Return_Causes_Args = {
  lang?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Customer_Orders_Args = {
  lang?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Customers_Args = {
  lang?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Hardware_Devices_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Lot_Numbers_Args = {
  lang?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Picking_Categories_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Product_Groups_Args = {
  lang?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Products_Args = {
  lang?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Products_For_Customer_Args = {
  customer_id?: InputMaybe<Scalars['bigint']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Products_For_Customer_Group_Args = {
  customer_group_id?: InputMaybe<Scalars['bigint']>;
  search?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "search_products_for_customer_results" */
export type Search_Products_For_Customer_Results = {
  __typename: 'search_products_for_customer_results';
  orderedQuantity: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  similarity: Scalars['Float'];
  sumInvoiceQuantity: Scalars['bigint'];
};

/** aggregated selection of "search_products_for_customer_results" */
export type Search_Products_For_Customer_Results_Aggregate = {
  __typename: 'search_products_for_customer_results_aggregate';
  aggregate?: Maybe<Search_Products_For_Customer_Results_Aggregate_Fields>;
  nodes: Array<Search_Products_For_Customer_Results>;
};

export type Search_Products_For_Customer_Results_Aggregate_Bool_Exp = {
  count?: InputMaybe<Search_Products_For_Customer_Results_Aggregate_Bool_Exp_Count>;
};

export type Search_Products_For_Customer_Results_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Search_Products_For_Customer_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "search_products_for_customer_results" */
export type Search_Products_For_Customer_Results_Aggregate_Fields = {
  __typename: 'search_products_for_customer_results_aggregate_fields';
  avg?: Maybe<Search_Products_For_Customer_Results_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Search_Products_For_Customer_Results_Max_Fields>;
  min?: Maybe<Search_Products_For_Customer_Results_Min_Fields>;
  stddev?: Maybe<Search_Products_For_Customer_Results_Stddev_Fields>;
  stddev_pop?: Maybe<Search_Products_For_Customer_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Search_Products_For_Customer_Results_Stddev_Samp_Fields>;
  sum?: Maybe<Search_Products_For_Customer_Results_Sum_Fields>;
  var_pop?: Maybe<Search_Products_For_Customer_Results_Var_Pop_Fields>;
  var_samp?: Maybe<Search_Products_For_Customer_Results_Var_Samp_Fields>;
  variance?: Maybe<Search_Products_For_Customer_Results_Variance_Fields>;
};


/** aggregate fields of "search_products_for_customer_results" */
export type Search_Products_For_Customer_Results_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Search_Products_For_Customer_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "search_products_for_customer_results" */
export type Search_Products_For_Customer_Results_Aggregate_Order_By = {
  avg?: InputMaybe<Search_Products_For_Customer_Results_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Search_Products_For_Customer_Results_Max_Order_By>;
  min?: InputMaybe<Search_Products_For_Customer_Results_Min_Order_By>;
  stddev?: InputMaybe<Search_Products_For_Customer_Results_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Search_Products_For_Customer_Results_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Search_Products_For_Customer_Results_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Search_Products_For_Customer_Results_Sum_Order_By>;
  var_pop?: InputMaybe<Search_Products_For_Customer_Results_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Search_Products_For_Customer_Results_Var_Samp_Order_By>;
  variance?: InputMaybe<Search_Products_For_Customer_Results_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Search_Products_For_Customer_Results_Avg_Fields = {
  __typename: 'search_products_for_customer_results_avg_fields';
  orderedQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
  sumInvoiceQuantity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "search_products_for_customer_results" */
export type Search_Products_For_Customer_Results_Avg_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sumInvoiceQuantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "search_products_for_customer_results". All fields are combined with a logical 'AND'. */
export type Search_Products_For_Customer_Results_Bool_Exp = {
  _and?: InputMaybe<Array<Search_Products_For_Customer_Results_Bool_Exp>>;
  _not?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
  _or?: InputMaybe<Array<Search_Products_For_Customer_Results_Bool_Exp>>;
  orderedQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  similarity?: InputMaybe<Float_Comparison_Exp>;
  sumInvoiceQuantity?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Search_Products_For_Customer_Results_Max_Fields = {
  __typename: 'search_products_for_customer_results_max_fields';
  orderedQuantity?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  similarity?: Maybe<Scalars['Float']>;
  sumInvoiceQuantity?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "search_products_for_customer_results" */
export type Search_Products_For_Customer_Results_Max_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sumInvoiceQuantity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Search_Products_For_Customer_Results_Min_Fields = {
  __typename: 'search_products_for_customer_results_min_fields';
  orderedQuantity?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  similarity?: Maybe<Scalars['Float']>;
  sumInvoiceQuantity?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "search_products_for_customer_results" */
export type Search_Products_For_Customer_Results_Min_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sumInvoiceQuantity?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "search_products_for_customer_results". */
export type Search_Products_For_Customer_Results_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sumInvoiceQuantity?: InputMaybe<Order_By>;
};

/** select columns of table "search_products_for_customer_results" */
export enum Search_Products_For_Customer_Results_Select_Column {
  /** column name */
  OrderedQuantity = 'orderedQuantity',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  SumInvoiceQuantity = 'sumInvoiceQuantity'
}

/** aggregate stddev on columns */
export type Search_Products_For_Customer_Results_Stddev_Fields = {
  __typename: 'search_products_for_customer_results_stddev_fields';
  orderedQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
  sumInvoiceQuantity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "search_products_for_customer_results" */
export type Search_Products_For_Customer_Results_Stddev_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sumInvoiceQuantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Search_Products_For_Customer_Results_Stddev_Pop_Fields = {
  __typename: 'search_products_for_customer_results_stddev_pop_fields';
  orderedQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
  sumInvoiceQuantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "search_products_for_customer_results" */
export type Search_Products_For_Customer_Results_Stddev_Pop_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sumInvoiceQuantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Search_Products_For_Customer_Results_Stddev_Samp_Fields = {
  __typename: 'search_products_for_customer_results_stddev_samp_fields';
  orderedQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
  sumInvoiceQuantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "search_products_for_customer_results" */
export type Search_Products_For_Customer_Results_Stddev_Samp_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sumInvoiceQuantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "search_products_for_customer_results" */
export type Search_Products_For_Customer_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Search_Products_For_Customer_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Search_Products_For_Customer_Results_Stream_Cursor_Value_Input = {
  orderedQuantity?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  similarity?: InputMaybe<Scalars['Float']>;
  sumInvoiceQuantity?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Search_Products_For_Customer_Results_Sum_Fields = {
  __typename: 'search_products_for_customer_results_sum_fields';
  orderedQuantity?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  similarity?: Maybe<Scalars['Float']>;
  sumInvoiceQuantity?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "search_products_for_customer_results" */
export type Search_Products_For_Customer_Results_Sum_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sumInvoiceQuantity?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Search_Products_For_Customer_Results_Var_Pop_Fields = {
  __typename: 'search_products_for_customer_results_var_pop_fields';
  orderedQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
  sumInvoiceQuantity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "search_products_for_customer_results" */
export type Search_Products_For_Customer_Results_Var_Pop_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sumInvoiceQuantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Search_Products_For_Customer_Results_Var_Samp_Fields = {
  __typename: 'search_products_for_customer_results_var_samp_fields';
  orderedQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
  sumInvoiceQuantity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "search_products_for_customer_results" */
export type Search_Products_For_Customer_Results_Var_Samp_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sumInvoiceQuantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Search_Products_For_Customer_Results_Variance_Fields = {
  __typename: 'search_products_for_customer_results_variance_fields';
  orderedQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
  sumInvoiceQuantity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "search_products_for_customer_results" */
export type Search_Products_For_Customer_Results_Variance_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sumInvoiceQuantity?: InputMaybe<Order_By>;
};

export type Search_Purchased_Products_Args = {
  search?: InputMaybe<Scalars['String']>;
  supplier_id?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "search_purchased_products_results" */
export type Search_Purchased_Products_Results = {
  __typename: 'search_purchased_products_results';
  orderedQuantity: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  similarity: Scalars['Float'];
};

/** aggregated selection of "search_purchased_products_results" */
export type Search_Purchased_Products_Results_Aggregate = {
  __typename: 'search_purchased_products_results_aggregate';
  aggregate?: Maybe<Search_Purchased_Products_Results_Aggregate_Fields>;
  nodes: Array<Search_Purchased_Products_Results>;
};

export type Search_Purchased_Products_Results_Aggregate_Bool_Exp = {
  count?: InputMaybe<Search_Purchased_Products_Results_Aggregate_Bool_Exp_Count>;
};

export type Search_Purchased_Products_Results_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Search_Purchased_Products_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Search_Purchased_Products_Results_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "search_purchased_products_results" */
export type Search_Purchased_Products_Results_Aggregate_Fields = {
  __typename: 'search_purchased_products_results_aggregate_fields';
  avg?: Maybe<Search_Purchased_Products_Results_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Search_Purchased_Products_Results_Max_Fields>;
  min?: Maybe<Search_Purchased_Products_Results_Min_Fields>;
  stddev?: Maybe<Search_Purchased_Products_Results_Stddev_Fields>;
  stddev_pop?: Maybe<Search_Purchased_Products_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Search_Purchased_Products_Results_Stddev_Samp_Fields>;
  sum?: Maybe<Search_Purchased_Products_Results_Sum_Fields>;
  var_pop?: Maybe<Search_Purchased_Products_Results_Var_Pop_Fields>;
  var_samp?: Maybe<Search_Purchased_Products_Results_Var_Samp_Fields>;
  variance?: Maybe<Search_Purchased_Products_Results_Variance_Fields>;
};


/** aggregate fields of "search_purchased_products_results" */
export type Search_Purchased_Products_Results_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Search_Purchased_Products_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "search_purchased_products_results" */
export type Search_Purchased_Products_Results_Aggregate_Order_By = {
  avg?: InputMaybe<Search_Purchased_Products_Results_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Search_Purchased_Products_Results_Max_Order_By>;
  min?: InputMaybe<Search_Purchased_Products_Results_Min_Order_By>;
  stddev?: InputMaybe<Search_Purchased_Products_Results_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Search_Purchased_Products_Results_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Search_Purchased_Products_Results_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Search_Purchased_Products_Results_Sum_Order_By>;
  var_pop?: InputMaybe<Search_Purchased_Products_Results_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Search_Purchased_Products_Results_Var_Samp_Order_By>;
  variance?: InputMaybe<Search_Purchased_Products_Results_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Search_Purchased_Products_Results_Avg_Fields = {
  __typename: 'search_purchased_products_results_avg_fields';
  orderedQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "search_purchased_products_results" */
export type Search_Purchased_Products_Results_Avg_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "search_purchased_products_results". All fields are combined with a logical 'AND'. */
export type Search_Purchased_Products_Results_Bool_Exp = {
  _and?: InputMaybe<Array<Search_Purchased_Products_Results_Bool_Exp>>;
  _not?: InputMaybe<Search_Purchased_Products_Results_Bool_Exp>;
  _or?: InputMaybe<Array<Search_Purchased_Products_Results_Bool_Exp>>;
  orderedQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  similarity?: InputMaybe<Float_Comparison_Exp>;
};

/** aggregate max on columns */
export type Search_Purchased_Products_Results_Max_Fields = {
  __typename: 'search_purchased_products_results_max_fields';
  orderedQuantity?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  similarity?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "search_purchased_products_results" */
export type Search_Purchased_Products_Results_Max_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Search_Purchased_Products_Results_Min_Fields = {
  __typename: 'search_purchased_products_results_min_fields';
  orderedQuantity?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  similarity?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "search_purchased_products_results" */
export type Search_Purchased_Products_Results_Min_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "search_purchased_products_results". */
export type Search_Purchased_Products_Results_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
};

/** select columns of table "search_purchased_products_results" */
export enum Search_Purchased_Products_Results_Select_Column {
  /** column name */
  OrderedQuantity = 'orderedQuantity',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Similarity = 'similarity'
}

/** aggregate stddev on columns */
export type Search_Purchased_Products_Results_Stddev_Fields = {
  __typename: 'search_purchased_products_results_stddev_fields';
  orderedQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "search_purchased_products_results" */
export type Search_Purchased_Products_Results_Stddev_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Search_Purchased_Products_Results_Stddev_Pop_Fields = {
  __typename: 'search_purchased_products_results_stddev_pop_fields';
  orderedQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "search_purchased_products_results" */
export type Search_Purchased_Products_Results_Stddev_Pop_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Search_Purchased_Products_Results_Stddev_Samp_Fields = {
  __typename: 'search_purchased_products_results_stddev_samp_fields';
  orderedQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "search_purchased_products_results" */
export type Search_Purchased_Products_Results_Stddev_Samp_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "search_purchased_products_results" */
export type Search_Purchased_Products_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Search_Purchased_Products_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Search_Purchased_Products_Results_Stream_Cursor_Value_Input = {
  orderedQuantity?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  similarity?: InputMaybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Search_Purchased_Products_Results_Sum_Fields = {
  __typename: 'search_purchased_products_results_sum_fields';
  orderedQuantity?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  similarity?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "search_purchased_products_results" */
export type Search_Purchased_Products_Results_Sum_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Search_Purchased_Products_Results_Var_Pop_Fields = {
  __typename: 'search_purchased_products_results_var_pop_fields';
  orderedQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "search_purchased_products_results" */
export type Search_Purchased_Products_Results_Var_Pop_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Search_Purchased_Products_Results_Var_Samp_Fields = {
  __typename: 'search_purchased_products_results_var_samp_fields';
  orderedQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "search_purchased_products_results" */
export type Search_Purchased_Products_Results_Var_Samp_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Search_Purchased_Products_Results_Variance_Fields = {
  __typename: 'search_purchased_products_results_variance_fields';
  orderedQuantity?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "search_purchased_products_results" */
export type Search_Purchased_Products_Results_Variance_Order_By = {
  orderedQuantity?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
};

export type Search_Sales_Teams_Args = {
  lang?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Stock_Locations_Args = {
  lang?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Supplier_Orders_Args = {
  lang?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Suppliers_Args = {
  lang?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Users_Args = {
  lang?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Set_Exchange_Rate_Args = {
  _currency_from?: InputMaybe<Scalars['String']>;
  _currency_to?: InputMaybe<Scalars['String']>;
  _rate?: InputMaybe<Scalars['numeric']>;
  _valid_from?: InputMaybe<Scalars['date']>;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

export type SortedDailyPriceLists_Price_Lists_Args = {
  _search?: InputMaybe<Scalars['String']>;
};

export type Sorted_Customer_Group_Pricing_For_Customer_Args = {
  _as_of?: InputMaybe<Scalars['date']>;
  _customer_id?: InputMaybe<Scalars['bigint']>;
};

export type Sorted_Customer_Group_Pricing_For_Customer_Group_Args = {
  _as_of?: InputMaybe<Scalars['date']>;
  _customer_group_id?: InputMaybe<Scalars['bigint']>;
};

export type Sorted_Price_Lists_Args = {
  _as_of?: InputMaybe<Scalars['date']>;
};

/** columns and relationships of "stock_location_state_transitions" */
export type Stock_Location_State_Transitions = {
  __typename: 'stock_location_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Stock_Location_States_Enum;
  toState: Stock_Location_States_Enum;
};

/** aggregated selection of "stock_location_state_transitions" */
export type Stock_Location_State_Transitions_Aggregate = {
  __typename: 'stock_location_state_transitions_aggregate';
  aggregate?: Maybe<Stock_Location_State_Transitions_Aggregate_Fields>;
  nodes: Array<Stock_Location_State_Transitions>;
};

/** aggregate fields of "stock_location_state_transitions" */
export type Stock_Location_State_Transitions_Aggregate_Fields = {
  __typename: 'stock_location_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Stock_Location_State_Transitions_Max_Fields>;
  min?: Maybe<Stock_Location_State_Transitions_Min_Fields>;
};


/** aggregate fields of "stock_location_state_transitions" */
export type Stock_Location_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Location_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "stock_location_state_transitions". All fields are combined with a logical 'AND'. */
export type Stock_Location_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Stock_Location_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Stock_Location_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Stock_Location_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Stock_Location_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Stock_Location_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Stock_Location_State_Transitions_Max_Fields = {
  __typename: 'stock_location_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Stock_Location_State_Transitions_Min_Fields = {
  __typename: 'stock_location_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "stock_location_state_transitions". */
export type Stock_Location_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "stock_location_state_transitions" */
export enum Stock_Location_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "stock_location_state_transitions" */
export type Stock_Location_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Location_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Location_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Stock_Location_States_Enum>;
  toState?: InputMaybe<Stock_Location_States_Enum>;
};

/** columns and relationships of "stock_location_states" */
export type Stock_Location_States = {
  __typename: 'stock_location_states';
  /** An array relationship */
  stockLocations: Array<Stock_Locations>;
  /** An aggregate relationship */
  stockLocations_aggregate: Stock_Locations_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "stock_location_states" */
export type Stock_Location_StatesStockLocationsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Locations_Order_By>>;
  where?: InputMaybe<Stock_Locations_Bool_Exp>;
};


/** columns and relationships of "stock_location_states" */
export type Stock_Location_StatesStockLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Locations_Order_By>>;
  where?: InputMaybe<Stock_Locations_Bool_Exp>;
};

/** aggregated selection of "stock_location_states" */
export type Stock_Location_States_Aggregate = {
  __typename: 'stock_location_states_aggregate';
  aggregate?: Maybe<Stock_Location_States_Aggregate_Fields>;
  nodes: Array<Stock_Location_States>;
};

/** aggregate fields of "stock_location_states" */
export type Stock_Location_States_Aggregate_Fields = {
  __typename: 'stock_location_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Stock_Location_States_Max_Fields>;
  min?: Maybe<Stock_Location_States_Min_Fields>;
};


/** aggregate fields of "stock_location_states" */
export type Stock_Location_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Location_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "stock_location_states". All fields are combined with a logical 'AND'. */
export type Stock_Location_States_Bool_Exp = {
  _and?: InputMaybe<Array<Stock_Location_States_Bool_Exp>>;
  _not?: InputMaybe<Stock_Location_States_Bool_Exp>;
  _or?: InputMaybe<Array<Stock_Location_States_Bool_Exp>>;
  stockLocations?: InputMaybe<Stock_Locations_Bool_Exp>;
  stockLocations_aggregate?: InputMaybe<Stock_Locations_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Stock_Location_States_Enum {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE'
}

/** Boolean expression to compare columns of type "stock_location_states_enum". All fields are combined with logical 'AND'. */
export type Stock_Location_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Stock_Location_States_Enum>;
  _in?: InputMaybe<Array<Stock_Location_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Stock_Location_States_Enum>;
  _nin?: InputMaybe<Array<Stock_Location_States_Enum>>;
};

/** aggregate max on columns */
export type Stock_Location_States_Max_Fields = {
  __typename: 'stock_location_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Stock_Location_States_Min_Fields = {
  __typename: 'stock_location_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "stock_location_states". */
export type Stock_Location_States_Order_By = {
  stockLocations_aggregate?: InputMaybe<Stock_Locations_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "stock_location_states" */
export enum Stock_Location_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "stock_location_states" */
export type Stock_Location_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Location_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Location_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "stock_locations" */
export type Stock_Locations = {
  __typename: 'stock_locations';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  accounts: Array<Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Accounts_Aggregate;
  /** An array relationship */
  aggregatedDemandPlanResults: Array<Aggregated_Demand_Plan_Results>;
  /** An aggregate relationship */
  aggregatedDemandPlanResults_aggregate: Aggregated_Demand_Plan_Results_Aggregate;
  /** An array relationship */
  aggregatedInventoryItemsByLocations: Array<Aggregated_Inventory_Items_By_Location>;
  /** An aggregate relationship */
  aggregatedInventoryItemsByLocations_aggregate: Aggregated_Inventory_Items_By_Location_Aggregate;
  /** An array relationship */
  customerOrderItemFulfillmentPlans: Array<Customer_Order_Item_Fulfillment_Plans>;
  /** An aggregate relationship */
  customerOrderItemFulfillmentPlans_aggregate: Customer_Order_Item_Fulfillment_Plans_Aggregate;
  /** An array relationship */
  customerOrderReturnItems: Array<Customer_Order_Return_Items>;
  /** An aggregate relationship */
  customerOrderReturnItems_aggregate: Customer_Order_Return_Items_Aggregate;
  /** An array relationship */
  customerOrders: Array<Customer_Orders>;
  /** An aggregate relationship */
  customerOrders_aggregate: Customer_Orders_Aggregate;
  /** An array relationship */
  customers: Array<Customers>;
  /** An array relationship */
  customersViaDefaultOrderStockLocation: Array<Customers>;
  /** An aggregate relationship */
  customersViaDefaultOrderStockLocation_aggregate: Customers_Aggregate;
  /** An aggregate relationship */
  customers_aggregate: Customers_Aggregate;
  /** An array relationship */
  dismantlingPlanRemainingPieces: Array<Dismantling_Plan_Remaining_Pieces>;
  /** An aggregate relationship */
  dismantlingPlanRemainingPieces_aggregate: Dismantling_Plan_Remaining_Pieces_Aggregate;
  domainId: Scalars['String'];
  i18n: Scalars['jsonb'];
  id: Scalars['bigint'];
  /** An array relationship */
  inventoryDailyPrognosisResults: Array<Inventory_Daily_Prognosis_Results>;
  /** An aggregate relationship */
  inventoryDailyPrognosisResults_aggregate: Inventory_Daily_Prognosis_Results_Aggregate;
  /** An array relationship */
  inventoryDailyUsageResults: Array<Inventory_Daily_Usage_Results>;
  /** An aggregate relationship */
  inventoryDailyUsageResults_aggregate: Inventory_Daily_Usage_Results_Aggregate;
  /** An array relationship */
  inventoryItemsByLocations: Array<Inventory_Items_By_Location>;
  /** An aggregate relationship */
  inventoryItemsByLocations_aggregate: Inventory_Items_By_Location_Aggregate;
  name: Scalars['String'];
  ordinalPosition: Scalars['Int'];
  /** An array relationship */
  pickedItems: Array<Picked_Items>;
  /** An aggregate relationship */
  pickedItems_aggregate: Picked_Items_Aggregate;
  /** An array relationship */
  productStockLocations: Array<Product_Stock_Locations>;
  /** An aggregate relationship */
  productStockLocations_aggregate: Product_Stock_Locations_Aggregate;
  /** An array relationship */
  productionSiteInputStockLocations: Array<Production_Site_Input_Stock_Locations>;
  /** An aggregate relationship */
  productionSiteInputStockLocations_aggregate: Production_Site_Input_Stock_Locations_Aggregate;
  /** An array relationship */
  rollingInventoryItems: Array<Rolling_Inventory_Items>;
  /** An aggregate relationship */
  rollingInventoryItems_aggregate: Rolling_Inventory_Items_Aggregate;
  /** An array relationship */
  rollingInventoryLotSummaryItems: Array<Rolling_Inventory_Lot_Summary_Items>;
  /** An aggregate relationship */
  rollingInventoryLotSummaryItems_aggregate: Rolling_Inventory_Lot_Summary_Items_Aggregate;
  /** An array relationship */
  rollingInventorySummaryItems: Array<Rolling_Inventory_Summary_Items>;
  /** An aggregate relationship */
  rollingInventorySummaryItems_aggregate: Rolling_Inventory_Summary_Items_Aggregate;
  state: Stock_Location_States_Enum;
  /** An array relationship */
  stockTransactionsViaSourceStockLocation: Array<Stock_Transactions>;
  /** An aggregate relationship */
  stockTransactionsViaSourceStockLocation_aggregate: Stock_Transactions_Aggregate;
  /** An array relationship */
  stockTransactionsViaTargetStockLocation: Array<Stock_Transactions>;
  /** An aggregate relationship */
  stockTransactionsViaTargetStockLocation_aggregate: Stock_Transactions_Aggregate;
  /** An array relationship */
  supplierOrderGoodsIncomeItems: Array<Supplier_Order_Goods_Income_Items>;
  /** An aggregate relationship */
  supplierOrderGoodsIncomeItems_aggregate: Supplier_Order_Goods_Income_Items_Aggregate;
  /** An array relationship */
  supplierOrderItems: Array<Supplier_Order_Items>;
  /** An aggregate relationship */
  supplierOrderItems_aggregate: Supplier_Order_Items_Aggregate;
  /** An array relationship */
  supplierOrderReturnItems: Array<Supplier_Order_Return_Items>;
  /** An aggregate relationship */
  supplierOrderReturnItems_aggregate: Supplier_Order_Return_Items_Aggregate;
  tenant: Scalars['String'];
  /** An array relationship */
  tenantConfigurations: Array<Tenant_Configurations>;
  /** An aggregate relationship */
  tenantConfigurations_aggregate: Tenant_Configurations_Aggregate;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsAggregatedDemandPlanResultsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsAggregatedDemandPlanResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsAggregatedInventoryItemsByLocationsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsAggregatedInventoryItemsByLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsCustomerOrderItemFulfillmentPlansArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsCustomerOrderItemFulfillmentPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsCustomerOrderReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsCustomerOrderReturnItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsCustomerOrdersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsCustomerOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsCustomersViaDefaultOrderStockLocationArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsCustomersViaDefaultOrderStockLocation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsDismantlingPlanRemainingPiecesArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsDismantlingPlanRemainingPieces_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsI18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsInventoryDailyPrognosisResultsArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsInventoryDailyPrognosisResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsInventoryDailyUsageResultsArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Usage_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Usage_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Usage_Results_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsInventoryDailyUsageResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Usage_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Usage_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Usage_Results_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsInventoryItemsByLocationsArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsInventoryItemsByLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsPickedItemsArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsPickedItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsProductStockLocationsArgs = {
  distinct_on?: InputMaybe<Array<Product_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Stock_Locations_Order_By>>;
  where?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsProductStockLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Stock_Locations_Order_By>>;
  where?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsProductionSiteInputStockLocationsArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Order_By>>;
  where?: InputMaybe<Production_Site_Input_Stock_Locations_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsProductionSiteInputStockLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Order_By>>;
  where?: InputMaybe<Production_Site_Input_Stock_Locations_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsRollingInventoryItemsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsRollingInventoryItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsRollingInventoryLotSummaryItemsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsRollingInventoryLotSummaryItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsRollingInventorySummaryItemsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsRollingInventorySummaryItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsStockTransactionsViaSourceStockLocationArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsStockTransactionsViaSourceStockLocation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsStockTransactionsViaTargetStockLocationArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsStockTransactionsViaTargetStockLocation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsSupplierOrderGoodsIncomeItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsSupplierOrderGoodsIncomeItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsSupplierOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsSupplierOrderItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsSupplierOrderReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsSupplierOrderReturnItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsTenantConfigurationsArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Configurations_Order_By>>;
  where?: InputMaybe<Tenant_Configurations_Bool_Exp>;
};


/** columns and relationships of "stock_locations" */
export type Stock_LocationsTenantConfigurations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Configurations_Order_By>>;
  where?: InputMaybe<Tenant_Configurations_Bool_Exp>;
};

/** aggregated selection of "stock_locations" */
export type Stock_Locations_Aggregate = {
  __typename: 'stock_locations_aggregate';
  aggregate?: Maybe<Stock_Locations_Aggregate_Fields>;
  nodes: Array<Stock_Locations>;
};

export type Stock_Locations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Stock_Locations_Aggregate_Bool_Exp_Count>;
};

export type Stock_Locations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Stock_Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Stock_Locations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "stock_locations" */
export type Stock_Locations_Aggregate_Fields = {
  __typename: 'stock_locations_aggregate_fields';
  avg?: Maybe<Stock_Locations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Stock_Locations_Max_Fields>;
  min?: Maybe<Stock_Locations_Min_Fields>;
  stddev?: Maybe<Stock_Locations_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Locations_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Locations_Sum_Fields>;
  var_pop?: Maybe<Stock_Locations_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Locations_Var_Samp_Fields>;
  variance?: Maybe<Stock_Locations_Variance_Fields>;
};


/** aggregate fields of "stock_locations" */
export type Stock_Locations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_locations" */
export type Stock_Locations_Aggregate_Order_By = {
  avg?: InputMaybe<Stock_Locations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stock_Locations_Max_Order_By>;
  min?: InputMaybe<Stock_Locations_Min_Order_By>;
  stddev?: InputMaybe<Stock_Locations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stock_Locations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stock_Locations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stock_Locations_Sum_Order_By>;
  var_pop?: InputMaybe<Stock_Locations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stock_Locations_Var_Samp_Order_By>;
  variance?: InputMaybe<Stock_Locations_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Stock_Locations_Append_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Stock_Locations_Avg_Fields = {
  __typename: 'stock_locations_avg_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stock_locations" */
export type Stock_Locations_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_locations". All fields are combined with a logical 'AND'. */
export type Stock_Locations_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Stock_Locations_Bool_Exp>>;
  _not?: InputMaybe<Stock_Locations_Bool_Exp>;
  _or?: InputMaybe<Array<Stock_Locations_Bool_Exp>>;
  accounts?: InputMaybe<Accounts_Bool_Exp>;
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Bool_Exp>;
  aggregatedDemandPlanResults?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
  aggregatedDemandPlanResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Results_Aggregate_Bool_Exp>;
  aggregatedInventoryItemsByLocations?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
  aggregatedInventoryItemsByLocations_aggregate?: InputMaybe<Aggregated_Inventory_Items_By_Location_Aggregate_Bool_Exp>;
  customerOrderItemFulfillmentPlans?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
  customerOrderItemFulfillmentPlans_aggregate?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Aggregate_Bool_Exp>;
  customerOrderReturnItems?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
  customerOrderReturnItems_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Bool_Exp>;
  customerOrders?: InputMaybe<Customer_Orders_Bool_Exp>;
  customerOrders_aggregate?: InputMaybe<Customer_Orders_Aggregate_Bool_Exp>;
  customers?: InputMaybe<Customers_Bool_Exp>;
  customersViaDefaultOrderStockLocation?: InputMaybe<Customers_Bool_Exp>;
  customersViaDefaultOrderStockLocation_aggregate?: InputMaybe<Customers_Aggregate_Bool_Exp>;
  customers_aggregate?: InputMaybe<Customers_Aggregate_Bool_Exp>;
  dismantlingPlanRemainingPieces?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
  dismantlingPlanRemainingPieces_aggregate?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Aggregate_Bool_Exp>;
  domainId?: InputMaybe<String_Comparison_Exp>;
  i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  inventoryDailyPrognosisResults?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
  inventoryDailyPrognosisResults_aggregate?: InputMaybe<Inventory_Daily_Prognosis_Results_Aggregate_Bool_Exp>;
  inventoryDailyUsageResults?: InputMaybe<Inventory_Daily_Usage_Results_Bool_Exp>;
  inventoryDailyUsageResults_aggregate?: InputMaybe<Inventory_Daily_Usage_Results_Aggregate_Bool_Exp>;
  inventoryItemsByLocations?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
  inventoryItemsByLocations_aggregate?: InputMaybe<Inventory_Items_By_Location_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  pickedItems?: InputMaybe<Picked_Items_Bool_Exp>;
  pickedItems_aggregate?: InputMaybe<Picked_Items_Aggregate_Bool_Exp>;
  productStockLocations?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
  productStockLocations_aggregate?: InputMaybe<Product_Stock_Locations_Aggregate_Bool_Exp>;
  productionSiteInputStockLocations?: InputMaybe<Production_Site_Input_Stock_Locations_Bool_Exp>;
  productionSiteInputStockLocations_aggregate?: InputMaybe<Production_Site_Input_Stock_Locations_Aggregate_Bool_Exp>;
  rollingInventoryItems?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
  rollingInventoryItems_aggregate?: InputMaybe<Rolling_Inventory_Items_Aggregate_Bool_Exp>;
  rollingInventoryLotSummaryItems?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
  rollingInventoryLotSummaryItems_aggregate?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Aggregate_Bool_Exp>;
  rollingInventorySummaryItems?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
  rollingInventorySummaryItems_aggregate?: InputMaybe<Rolling_Inventory_Summary_Items_Aggregate_Bool_Exp>;
  state?: InputMaybe<Stock_Location_States_Enum_Comparison_Exp>;
  stockTransactionsViaSourceStockLocation?: InputMaybe<Stock_Transactions_Bool_Exp>;
  stockTransactionsViaSourceStockLocation_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Bool_Exp>;
  stockTransactionsViaTargetStockLocation?: InputMaybe<Stock_Transactions_Bool_Exp>;
  stockTransactionsViaTargetStockLocation_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Bool_Exp>;
  supplierOrderGoodsIncomeItems?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
  supplierOrderGoodsIncomeItems_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Bool_Exp>;
  supplierOrderItems?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
  supplierOrderItems_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Bool_Exp>;
  supplierOrderReturnItems?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
  supplierOrderReturnItems_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  tenantConfigurations?: InputMaybe<Tenant_Configurations_Bool_Exp>;
  tenantConfigurations_aggregate?: InputMaybe<Tenant_Configurations_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "stock_locations" */
export enum Stock_Locations_Constraint {
  /** unique or primary key constraint on columns "id" */
  StockLocationsPkey = 'stock_locations_pkey',
  /** unique or primary key constraint on columns "tenant", "domain_id" */
  StockLocationsTenantDomainId = 'stock_locations_tenant_domain_id',
  /** unique or primary key constraint on columns "name", "tenant" */
  StockLocationsTenantNameKey = 'stock_locations_tenant_name_key',
  /** unique or primary key constraint on columns "tenant", "ordinal_position" */
  StockLocationsTenantOrdinalPositionKey = 'stock_locations_tenant_ordinal_position_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Stock_Locations_Delete_At_Path_Input = {
  i18n?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Stock_Locations_Delete_Elem_Input = {
  i18n?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Stock_Locations_Delete_Key_Input = {
  i18n?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "stock_locations" */
export type Stock_Locations_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "stock_locations" */
export type Stock_Locations_Insert_Input = {
  accounts?: InputMaybe<Accounts_Arr_Rel_Insert_Input>;
  aggregatedInventoryItemsByLocations?: InputMaybe<Aggregated_Inventory_Items_By_Location_Arr_Rel_Insert_Input>;
  customerOrderItemFulfillmentPlans?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Arr_Rel_Insert_Input>;
  customerOrderReturnItems?: InputMaybe<Customer_Order_Return_Items_Arr_Rel_Insert_Input>;
  customerOrders?: InputMaybe<Customer_Orders_Arr_Rel_Insert_Input>;
  customers?: InputMaybe<Customers_Arr_Rel_Insert_Input>;
  customersViaDefaultOrderStockLocation?: InputMaybe<Customers_Arr_Rel_Insert_Input>;
  domainId?: InputMaybe<Scalars['String']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  inventoryItemsByLocations?: InputMaybe<Inventory_Items_By_Location_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  pickedItems?: InputMaybe<Picked_Items_Arr_Rel_Insert_Input>;
  productStockLocations?: InputMaybe<Product_Stock_Locations_Arr_Rel_Insert_Input>;
  productionSiteInputStockLocations?: InputMaybe<Production_Site_Input_Stock_Locations_Arr_Rel_Insert_Input>;
  rollingInventoryItems?: InputMaybe<Rolling_Inventory_Items_Arr_Rel_Insert_Input>;
  state?: InputMaybe<Stock_Location_States_Enum>;
  stockTransactionsViaSourceStockLocation?: InputMaybe<Stock_Transactions_Arr_Rel_Insert_Input>;
  stockTransactionsViaTargetStockLocation?: InputMaybe<Stock_Transactions_Arr_Rel_Insert_Input>;
  supplierOrderGoodsIncomeItems?: InputMaybe<Supplier_Order_Goods_Income_Items_Arr_Rel_Insert_Input>;
  supplierOrderItems?: InputMaybe<Supplier_Order_Items_Arr_Rel_Insert_Input>;
  supplierOrderReturnItems?: InputMaybe<Supplier_Order_Return_Items_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Stock_Locations_Max_Fields = {
  __typename: 'stock_locations_max_fields';
  domainId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stock_locations" */
export type Stock_Locations_Max_Order_By = {
  domainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Locations_Min_Fields = {
  __typename: 'stock_locations_min_fields';
  domainId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stock_locations" */
export type Stock_Locations_Min_Order_By = {
  domainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stock_locations" */
export type Stock_Locations_Mutation_Response = {
  __typename: 'stock_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stock_Locations>;
};

/** input type for inserting object relation for remote table "stock_locations" */
export type Stock_Locations_Obj_Rel_Insert_Input = {
  data: Stock_Locations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Stock_Locations_On_Conflict>;
};

/** on_conflict condition type for table "stock_locations" */
export type Stock_Locations_On_Conflict = {
  constraint: Stock_Locations_Constraint;
  update_columns?: Array<Stock_Locations_Update_Column>;
  where?: InputMaybe<Stock_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "stock_locations". */
export type Stock_Locations_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Order_By>;
  aggregatedDemandPlanResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Results_Aggregate_Order_By>;
  aggregatedInventoryItemsByLocations_aggregate?: InputMaybe<Aggregated_Inventory_Items_By_Location_Aggregate_Order_By>;
  customerOrderItemFulfillmentPlans_aggregate?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Aggregate_Order_By>;
  customerOrderReturnItems_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Order_By>;
  customerOrders_aggregate?: InputMaybe<Customer_Orders_Aggregate_Order_By>;
  customersViaDefaultOrderStockLocation_aggregate?: InputMaybe<Customers_Aggregate_Order_By>;
  customers_aggregate?: InputMaybe<Customers_Aggregate_Order_By>;
  dismantlingPlanRemainingPieces_aggregate?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Aggregate_Order_By>;
  domainId?: InputMaybe<Order_By>;
  i18n?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inventoryDailyPrognosisResults_aggregate?: InputMaybe<Inventory_Daily_Prognosis_Results_Aggregate_Order_By>;
  inventoryDailyUsageResults_aggregate?: InputMaybe<Inventory_Daily_Usage_Results_Aggregate_Order_By>;
  inventoryItemsByLocations_aggregate?: InputMaybe<Inventory_Items_By_Location_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  pickedItems_aggregate?: InputMaybe<Picked_Items_Aggregate_Order_By>;
  productStockLocations_aggregate?: InputMaybe<Product_Stock_Locations_Aggregate_Order_By>;
  productionSiteInputStockLocations_aggregate?: InputMaybe<Production_Site_Input_Stock_Locations_Aggregate_Order_By>;
  rollingInventoryItems_aggregate?: InputMaybe<Rolling_Inventory_Items_Aggregate_Order_By>;
  rollingInventoryLotSummaryItems_aggregate?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Aggregate_Order_By>;
  rollingInventorySummaryItems_aggregate?: InputMaybe<Rolling_Inventory_Summary_Items_Aggregate_Order_By>;
  state?: InputMaybe<Order_By>;
  stockTransactionsViaSourceStockLocation_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Order_By>;
  stockTransactionsViaTargetStockLocation_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Order_By>;
  supplierOrderGoodsIncomeItems_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Order_By>;
  supplierOrderItems_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Order_By>;
  supplierOrderReturnItems_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
  tenantConfigurations_aggregate?: InputMaybe<Tenant_Configurations_Aggregate_Order_By>;
};

/** primary key columns input for table: stock_locations */
export type Stock_Locations_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Stock_Locations_Prepend_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "stock_locations" */
export enum Stock_Locations_Select_Column {
  /** column name */
  DomainId = 'domainId',
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant'
}

/** input type for updating data in table "stock_locations" */
export type Stock_Locations_Set_Input = {
  domainId?: InputMaybe<Scalars['String']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Stock_Location_States_Enum>;
};

/** aggregate stddev on columns */
export type Stock_Locations_Stddev_Fields = {
  __typename: 'stock_locations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stock_locations" */
export type Stock_Locations_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Locations_Stddev_Pop_Fields = {
  __typename: 'stock_locations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stock_locations" */
export type Stock_Locations_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Locations_Stddev_Samp_Fields = {
  __typename: 'stock_locations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stock_locations" */
export type Stock_Locations_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "stock_locations" */
export type Stock_Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Locations_Stream_Cursor_Value_Input = {
  domainId?: InputMaybe<Scalars['String']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Stock_Location_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Stock_Locations_Sum_Fields = {
  __typename: 'stock_locations_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "stock_locations" */
export type Stock_Locations_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** update columns of table "stock_locations" */
export enum Stock_Locations_Update_Column {
  /** column name */
  DomainId = 'domainId',
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  State = 'state'
}

export type Stock_Locations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Stock_Locations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Stock_Locations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Stock_Locations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Stock_Locations_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stock_Locations_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Stock_Locations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stock_Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stock_Locations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stock_Locations_Var_Pop_Fields = {
  __typename: 'stock_locations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stock_locations" */
export type Stock_Locations_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Locations_Var_Samp_Fields = {
  __typename: 'stock_locations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stock_locations" */
export type Stock_Locations_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Locations_Variance_Fields = {
  __typename: 'stock_locations_variance_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stock_locations" */
export type Stock_Locations_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** columns and relationships of "stock_transaction_logged_actions" */
export type Stock_Transaction_Logged_Actions = {
  __typename: 'stock_transaction_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  entity?: Maybe<Stock_Transactions>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "stock_transaction_logged_actions" */
export type Stock_Transaction_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "stock_transaction_logged_actions" */
export type Stock_Transaction_Logged_Actions_Aggregate = {
  __typename: 'stock_transaction_logged_actions_aggregate';
  aggregate?: Maybe<Stock_Transaction_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Stock_Transaction_Logged_Actions>;
};

export type Stock_Transaction_Logged_Actions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Stock_Transaction_Logged_Actions_Aggregate_Bool_Exp_Count>;
};

export type Stock_Transaction_Logged_Actions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Stock_Transaction_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Stock_Transaction_Logged_Actions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "stock_transaction_logged_actions" */
export type Stock_Transaction_Logged_Actions_Aggregate_Fields = {
  __typename: 'stock_transaction_logged_actions_aggregate_fields';
  avg?: Maybe<Stock_Transaction_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Stock_Transaction_Logged_Actions_Max_Fields>;
  min?: Maybe<Stock_Transaction_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Stock_Transaction_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Transaction_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Transaction_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Transaction_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Stock_Transaction_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Transaction_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Stock_Transaction_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "stock_transaction_logged_actions" */
export type Stock_Transaction_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Transaction_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_transaction_logged_actions" */
export type Stock_Transaction_Logged_Actions_Aggregate_Order_By = {
  avg?: InputMaybe<Stock_Transaction_Logged_Actions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stock_Transaction_Logged_Actions_Max_Order_By>;
  min?: InputMaybe<Stock_Transaction_Logged_Actions_Min_Order_By>;
  stddev?: InputMaybe<Stock_Transaction_Logged_Actions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stock_Transaction_Logged_Actions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stock_Transaction_Logged_Actions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stock_Transaction_Logged_Actions_Sum_Order_By>;
  var_pop?: InputMaybe<Stock_Transaction_Logged_Actions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stock_Transaction_Logged_Actions_Var_Samp_Order_By>;
  variance?: InputMaybe<Stock_Transaction_Logged_Actions_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Stock_Transaction_Logged_Actions_Avg_Fields = {
  __typename: 'stock_transaction_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stock_transaction_logged_actions" */
export type Stock_Transaction_Logged_Actions_Avg_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_transaction_logged_actions". All fields are combined with a logical 'AND'. */
export type Stock_Transaction_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Stock_Transaction_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Stock_Transaction_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Stock_Transaction_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entity?: InputMaybe<Stock_Transactions_Bool_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Stock_Transaction_Logged_Actions_Max_Fields = {
  __typename: 'stock_transaction_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "stock_transaction_logged_actions" */
export type Stock_Transaction_Logged_Actions_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Transaction_Logged_Actions_Min_Fields = {
  __typename: 'stock_transaction_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "stock_transaction_logged_actions" */
export type Stock_Transaction_Logged_Actions_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "stock_transaction_logged_actions". */
export type Stock_Transaction_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entity?: InputMaybe<Stock_Transactions_Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "stock_transaction_logged_actions" */
export enum Stock_Transaction_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Role = 'role',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Stock_Transaction_Logged_Actions_Stddev_Fields = {
  __typename: 'stock_transaction_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stock_transaction_logged_actions" */
export type Stock_Transaction_Logged_Actions_Stddev_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Transaction_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'stock_transaction_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stock_transaction_logged_actions" */
export type Stock_Transaction_Logged_Actions_Stddev_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Transaction_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'stock_transaction_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stock_transaction_logged_actions" */
export type Stock_Transaction_Logged_Actions_Stddev_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "stock_transaction_logged_actions" */
export type Stock_Transaction_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Transaction_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Transaction_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  role?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Stock_Transaction_Logged_Actions_Sum_Fields = {
  __typename: 'stock_transaction_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "stock_transaction_logged_actions" */
export type Stock_Transaction_Logged_Actions_Sum_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Stock_Transaction_Logged_Actions_Var_Pop_Fields = {
  __typename: 'stock_transaction_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stock_transaction_logged_actions" */
export type Stock_Transaction_Logged_Actions_Var_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Transaction_Logged_Actions_Var_Samp_Fields = {
  __typename: 'stock_transaction_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stock_transaction_logged_actions" */
export type Stock_Transaction_Logged_Actions_Var_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Transaction_Logged_Actions_Variance_Fields = {
  __typename: 'stock_transaction_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stock_transaction_logged_actions" */
export type Stock_Transaction_Logged_Actions_Variance_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "stock_transaction_names" */
export type Stock_Transaction_Names = {
  __typename: 'stock_transaction_names';
  /** An array relationship */
  journalEntries: Array<Journal_Entries>;
  /** An aggregate relationship */
  journalEntries_aggregate: Journal_Entries_Aggregate;
  /** An array relationship */
  stockTransactions: Array<Stock_Transactions>;
  /** An aggregate relationship */
  stockTransactions_aggregate: Stock_Transactions_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "stock_transaction_names" */
export type Stock_Transaction_NamesJournalEntriesArgs = {
  distinct_on?: InputMaybe<Array<Journal_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journal_Entries_Order_By>>;
  where?: InputMaybe<Journal_Entries_Bool_Exp>;
};


/** columns and relationships of "stock_transaction_names" */
export type Stock_Transaction_NamesJournalEntries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Journal_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journal_Entries_Order_By>>;
  where?: InputMaybe<Journal_Entries_Bool_Exp>;
};


/** columns and relationships of "stock_transaction_names" */
export type Stock_Transaction_NamesStockTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "stock_transaction_names" */
export type Stock_Transaction_NamesStockTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};

/** aggregated selection of "stock_transaction_names" */
export type Stock_Transaction_Names_Aggregate = {
  __typename: 'stock_transaction_names_aggregate';
  aggregate?: Maybe<Stock_Transaction_Names_Aggregate_Fields>;
  nodes: Array<Stock_Transaction_Names>;
};

/** aggregate fields of "stock_transaction_names" */
export type Stock_Transaction_Names_Aggregate_Fields = {
  __typename: 'stock_transaction_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Stock_Transaction_Names_Max_Fields>;
  min?: Maybe<Stock_Transaction_Names_Min_Fields>;
};


/** aggregate fields of "stock_transaction_names" */
export type Stock_Transaction_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Transaction_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "stock_transaction_names". All fields are combined with a logical 'AND'. */
export type Stock_Transaction_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Stock_Transaction_Names_Bool_Exp>>;
  _not?: InputMaybe<Stock_Transaction_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Stock_Transaction_Names_Bool_Exp>>;
  journalEntries?: InputMaybe<Journal_Entries_Bool_Exp>;
  journalEntries_aggregate?: InputMaybe<Journal_Entries_Aggregate_Bool_Exp>;
  stockTransactions?: InputMaybe<Stock_Transactions_Bool_Exp>;
  stockTransactions_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Stock_Transaction_Names_Enum {
  Amendment = 'AMENDMENT',
  Correction = 'CORRECTION',
  CustomerCorrectionIn = 'CUSTOMER_CORRECTION_IN',
  CustomerCorrectionOut = 'CUSTOMER_CORRECTION_OUT',
  CustomerOrderAssignment = 'CUSTOMER_ORDER_ASSIGNMENT',
  CustomerTransferIn = 'CUSTOMER_TRANSFER_IN',
  CustomerTransferOut = 'CUSTOMER_TRANSFER_OUT',
  Depletion = 'DEPLETION',
  GoodsIncome = 'GOODS_INCOME',
  IncomingGoods = 'INCOMING_GOODS',
  InternalConsumption = 'INTERNAL_CONSUMPTION',
  Revert = 'REVERT',
  Scraping = 'SCRAPING',
  Transfer = 'TRANSFER',
  Transform = 'TRANSFORM'
}

/** Boolean expression to compare columns of type "stock_transaction_names_enum". All fields are combined with logical 'AND'. */
export type Stock_Transaction_Names_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Stock_Transaction_Names_Enum>;
  _in?: InputMaybe<Array<Stock_Transaction_Names_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Stock_Transaction_Names_Enum>;
  _nin?: InputMaybe<Array<Stock_Transaction_Names_Enum>>;
};

/** aggregate max on columns */
export type Stock_Transaction_Names_Max_Fields = {
  __typename: 'stock_transaction_names_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Stock_Transaction_Names_Min_Fields = {
  __typename: 'stock_transaction_names_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "stock_transaction_names". */
export type Stock_Transaction_Names_Order_By = {
  journalEntries_aggregate?: InputMaybe<Journal_Entries_Aggregate_Order_By>;
  stockTransactions_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "stock_transaction_names" */
export enum Stock_Transaction_Names_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "stock_transaction_names" */
export type Stock_Transaction_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Transaction_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Transaction_Names_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "stock_transactions" */
export type Stock_Transactions = {
  __typename: 'stock_transactions';
  /** An object relationship */
  LotNumber?: Maybe<Lot_Numbers>;
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customer?: Maybe<Customers>;
  customerId?: Maybe<Scalars['bigint']>;
  data?: Maybe<Scalars['jsonb']>;
  id: Scalars['bigint'];
  /** An object relationship */
  journalEntry?: Maybe<Journal_Entries>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  loggedActions: Array<Stock_Transaction_Logged_Actions>;
  /** An aggregate relationship */
  loggedActions_aggregate: Stock_Transaction_Logged_Actions_Aggregate;
  lotNo?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  /** An object relationship */
  sourceAccount?: Maybe<Accounts>;
  sourceAccountId?: Maybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  sourceMonetaryUnit?: Maybe<Product_Units>;
  sourceMonetaryUnitId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  sourceStockLocation?: Maybe<Stock_Locations>;
  sourceStockLocationId?: Maybe<Scalars['bigint']>;
  sourceStorageQuantity?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  sourceStorageUnit?: Maybe<Product_Units>;
  sourceStorageUnitId?: Maybe<Scalars['bigint']>;
  state: Stock_Transfer_States_Enum;
  /** An object relationship */
  targetAccount?: Maybe<Accounts>;
  targetAccountId?: Maybe<Scalars['bigint']>;
  targetMonetaryQuantity?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  targetMonetaryUnit?: Maybe<Product_Units>;
  targetMonetaryUnitId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  targetStockLocation?: Maybe<Stock_Locations>;
  targetStockLocationId?: Maybe<Scalars['bigint']>;
  targetStorageQuantity?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  targetStorageUnit?: Maybe<Product_Units>;
  targetStorageUnitId?: Maybe<Scalars['bigint']>;
  tenant: Scalars['String'];
  transaction: Stock_Transaction_Names_Enum;
  updatedAt: Scalars['timestamptz'];
  validTime?: Maybe<Scalars['timestamptz']>;
  version: Scalars['bigint'];
};


/** columns and relationships of "stock_transactions" */
export type Stock_TransactionsDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "stock_transactions" */
export type Stock_TransactionsLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transaction_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transaction_Logged_Actions_Order_By>>;
  where?: InputMaybe<Stock_Transaction_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "stock_transactions" */
export type Stock_TransactionsLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transaction_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transaction_Logged_Actions_Order_By>>;
  where?: InputMaybe<Stock_Transaction_Logged_Actions_Bool_Exp>;
};

/** aggregated selection of "stock_transactions" */
export type Stock_Transactions_Aggregate = {
  __typename: 'stock_transactions_aggregate';
  aggregate?: Maybe<Stock_Transactions_Aggregate_Fields>;
  nodes: Array<Stock_Transactions>;
};

export type Stock_Transactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Stock_Transactions_Aggregate_Bool_Exp_Count>;
};

export type Stock_Transactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Stock_Transactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "stock_transactions" */
export type Stock_Transactions_Aggregate_Fields = {
  __typename: 'stock_transactions_aggregate_fields';
  avg?: Maybe<Stock_Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Stock_Transactions_Max_Fields>;
  min?: Maybe<Stock_Transactions_Min_Fields>;
  stddev?: Maybe<Stock_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Transactions_Sum_Fields>;
  var_pop?: Maybe<Stock_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Stock_Transactions_Variance_Fields>;
};


/** aggregate fields of "stock_transactions" */
export type Stock_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_transactions" */
export type Stock_Transactions_Aggregate_Order_By = {
  avg?: InputMaybe<Stock_Transactions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stock_Transactions_Max_Order_By>;
  min?: InputMaybe<Stock_Transactions_Min_Order_By>;
  stddev?: InputMaybe<Stock_Transactions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stock_Transactions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stock_Transactions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stock_Transactions_Sum_Order_By>;
  var_pop?: InputMaybe<Stock_Transactions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stock_Transactions_Var_Samp_Order_By>;
  variance?: InputMaybe<Stock_Transactions_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Stock_Transactions_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "stock_transactions" */
export type Stock_Transactions_Arr_Rel_Insert_Input = {
  data: Array<Stock_Transactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Stock_Transactions_On_Conflict>;
};

/** aggregate avg on columns */
export type Stock_Transactions_Avg_Fields = {
  __typename: 'stock_transactions_avg_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  sourceAccountId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStockLocationId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  targetAccountId?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stock_transactions" */
export type Stock_Transactions_Avg_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  sourceAccountId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_transactions". All fields are combined with a logical 'AND'. */
export type Stock_Transactions_Bool_Exp = {
  LotNumber?: InputMaybe<Lot_Numbers_Bool_Exp>;
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Stock_Transactions_Bool_Exp>>;
  _not?: InputMaybe<Stock_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<Stock_Transactions_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer?: InputMaybe<Customers_Bool_Exp>;
  customerId?: InputMaybe<Bigint_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  journalEntry?: InputMaybe<Journal_Entries_Bool_Exp>;
  journalEntryId?: InputMaybe<Bigint_Comparison_Exp>;
  loggedActions?: InputMaybe<Stock_Transaction_Logged_Actions_Bool_Exp>;
  loggedActions_aggregate?: InputMaybe<Stock_Transaction_Logged_Actions_Aggregate_Bool_Exp>;
  lotNo?: InputMaybe<String_Comparison_Exp>;
  lotNumberId?: InputMaybe<Bigint_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  sourceAccount?: InputMaybe<Accounts_Bool_Exp>;
  sourceAccountId?: InputMaybe<Bigint_Comparison_Exp>;
  sourceMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  sourceMonetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  sourceMonetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  sourceStockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  sourceStockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  sourceStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  sourceStorageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  sourceStorageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<Stock_Transfer_States_Enum_Comparison_Exp>;
  targetAccount?: InputMaybe<Accounts_Bool_Exp>;
  targetAccountId?: InputMaybe<Bigint_Comparison_Exp>;
  targetMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  targetMonetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  targetMonetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  targetStockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  targetStockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  targetStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  targetStorageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  targetStorageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  transaction?: InputMaybe<Stock_Transaction_Names_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  validTime?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_transactions" */
export enum Stock_Transactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  StockTransactionsPkey = 'stock_transactions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Stock_Transactions_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Stock_Transactions_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Stock_Transactions_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "stock_transactions" */
export type Stock_Transactions_Inc_Input = {
  customerId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  sourceAccountId?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  sourceStockLocationId?: InputMaybe<Scalars['bigint']>;
  sourceStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sourceStorageUnitId?: InputMaybe<Scalars['bigint']>;
  targetAccountId?: InputMaybe<Scalars['bigint']>;
  targetMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  targetMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  targetStockLocationId?: InputMaybe<Scalars['bigint']>;
  targetStorageQuantity?: InputMaybe<Scalars['bigint']>;
  targetStorageUnitId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "stock_transactions" */
export type Stock_Transactions_Insert_Input = {
  LotNumber?: InputMaybe<Lot_Numbers_Obj_Rel_Insert_Input>;
  customer?: InputMaybe<Customers_Obj_Rel_Insert_Input>;
  customerId?: InputMaybe<Scalars['bigint']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntry?: InputMaybe<Journal_Entries_Obj_Rel_Insert_Input>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  lotNo?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  sourceAccount?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  sourceAccountId?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  sourceMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  sourceStockLocation?: InputMaybe<Stock_Locations_Obj_Rel_Insert_Input>;
  sourceStockLocationId?: InputMaybe<Scalars['bigint']>;
  sourceStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sourceStorageUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  sourceStorageUnitId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Stock_Transfer_States_Enum>;
  targetAccount?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  targetAccountId?: InputMaybe<Scalars['bigint']>;
  targetMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  targetMonetaryUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  targetMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  targetStockLocation?: InputMaybe<Stock_Locations_Obj_Rel_Insert_Input>;
  targetStockLocationId?: InputMaybe<Scalars['bigint']>;
  targetStorageQuantity?: InputMaybe<Scalars['bigint']>;
  targetStorageUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  targetStorageUnitId?: InputMaybe<Scalars['bigint']>;
  transaction?: InputMaybe<Stock_Transaction_Names_Enum>;
  validTime?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Stock_Transactions_Max_Fields = {
  __typename: 'stock_transactions_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  lotNo?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['bigint']>;
  sourceAccountId?: Maybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sourceMonetaryUnitId?: Maybe<Scalars['bigint']>;
  sourceStockLocationId?: Maybe<Scalars['bigint']>;
  sourceStorageQuantity?: Maybe<Scalars['bigint']>;
  sourceStorageUnitId?: Maybe<Scalars['bigint']>;
  targetAccountId?: Maybe<Scalars['bigint']>;
  targetMonetaryQuantity?: Maybe<Scalars['bigint']>;
  targetMonetaryUnitId?: Maybe<Scalars['bigint']>;
  targetStockLocationId?: Maybe<Scalars['bigint']>;
  targetStorageQuantity?: Maybe<Scalars['bigint']>;
  targetStorageUnitId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validTime?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "stock_transactions" */
export type Stock_Transactions_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNo?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  sourceAccountId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  validTime?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Transactions_Min_Fields = {
  __typename: 'stock_transactions_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  lotNo?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['bigint']>;
  sourceAccountId?: Maybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sourceMonetaryUnitId?: Maybe<Scalars['bigint']>;
  sourceStockLocationId?: Maybe<Scalars['bigint']>;
  sourceStorageQuantity?: Maybe<Scalars['bigint']>;
  sourceStorageUnitId?: Maybe<Scalars['bigint']>;
  targetAccountId?: Maybe<Scalars['bigint']>;
  targetMonetaryQuantity?: Maybe<Scalars['bigint']>;
  targetMonetaryUnitId?: Maybe<Scalars['bigint']>;
  targetStockLocationId?: Maybe<Scalars['bigint']>;
  targetStorageQuantity?: Maybe<Scalars['bigint']>;
  targetStorageUnitId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validTime?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "stock_transactions" */
export type Stock_Transactions_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNo?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  sourceAccountId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  validTime?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stock_transactions" */
export type Stock_Transactions_Mutation_Response = {
  __typename: 'stock_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stock_Transactions>;
};

/** on_conflict condition type for table "stock_transactions" */
export type Stock_Transactions_On_Conflict = {
  constraint: Stock_Transactions_Constraint;
  update_columns?: Array<Stock_Transactions_Update_Column>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "stock_transactions". */
export type Stock_Transactions_Order_By = {
  LotNumber?: InputMaybe<Lot_Numbers_Order_By>;
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customer?: InputMaybe<Customers_Order_By>;
  customerId?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntry?: InputMaybe<Journal_Entries_Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  loggedActions_aggregate?: InputMaybe<Stock_Transaction_Logged_Actions_Aggregate_Order_By>;
  lotNo?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  sourceAccount?: InputMaybe<Accounts_Order_By>;
  sourceAccountId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnit?: InputMaybe<Product_Units_Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocation?: InputMaybe<Stock_Locations_Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnit?: InputMaybe<Product_Units_Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  targetAccount?: InputMaybe<Accounts_Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnit?: InputMaybe<Product_Units_Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocation?: InputMaybe<Stock_Locations_Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnit?: InputMaybe<Product_Units_Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  transaction?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  validTime?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stock_transactions */
export type Stock_Transactions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Stock_Transactions_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "stock_transactions" */
export enum Stock_Transactions_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  JournalEntryId = 'journalEntryId',
  /** column name */
  LotNo = 'lotNo',
  /** column name */
  LotNumberId = 'lotNumberId',
  /** column name */
  Notes = 'notes',
  /** column name */
  ProductId = 'productId',
  /** column name */
  SourceAccountId = 'sourceAccountId',
  /** column name */
  SourceMonetaryQuantity = 'sourceMonetaryQuantity',
  /** column name */
  SourceMonetaryUnitId = 'sourceMonetaryUnitId',
  /** column name */
  SourceStockLocationId = 'sourceStockLocationId',
  /** column name */
  SourceStorageQuantity = 'sourceStorageQuantity',
  /** column name */
  SourceStorageUnitId = 'sourceStorageUnitId',
  /** column name */
  State = 'state',
  /** column name */
  TargetAccountId = 'targetAccountId',
  /** column name */
  TargetMonetaryQuantity = 'targetMonetaryQuantity',
  /** column name */
  TargetMonetaryUnitId = 'targetMonetaryUnitId',
  /** column name */
  TargetStockLocationId = 'targetStockLocationId',
  /** column name */
  TargetStorageQuantity = 'targetStorageQuantity',
  /** column name */
  TargetStorageUnitId = 'targetStorageUnitId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  Transaction = 'transaction',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ValidTime = 'validTime',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "stock_transactions" */
export type Stock_Transactions_Set_Input = {
  customerId?: InputMaybe<Scalars['bigint']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  lotNo?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['bigint']>;
  sourceAccountId?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  sourceStockLocationId?: InputMaybe<Scalars['bigint']>;
  sourceStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sourceStorageUnitId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Stock_Transfer_States_Enum>;
  targetAccountId?: InputMaybe<Scalars['bigint']>;
  targetMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  targetMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  targetStockLocationId?: InputMaybe<Scalars['bigint']>;
  targetStorageQuantity?: InputMaybe<Scalars['bigint']>;
  targetStorageUnitId?: InputMaybe<Scalars['bigint']>;
  transaction?: InputMaybe<Stock_Transaction_Names_Enum>;
  validTime?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Stock_Transactions_Stddev_Fields = {
  __typename: 'stock_transactions_stddev_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  sourceAccountId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStockLocationId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  targetAccountId?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stock_transactions" */
export type Stock_Transactions_Stddev_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  sourceAccountId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Transactions_Stddev_Pop_Fields = {
  __typename: 'stock_transactions_stddev_pop_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  sourceAccountId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStockLocationId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  targetAccountId?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stock_transactions" */
export type Stock_Transactions_Stddev_Pop_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  sourceAccountId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Transactions_Stddev_Samp_Fields = {
  __typename: 'stock_transactions_stddev_samp_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  sourceAccountId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStockLocationId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  targetAccountId?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stock_transactions" */
export type Stock_Transactions_Stddev_Samp_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  sourceAccountId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "stock_transactions" */
export type Stock_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Transactions_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerId?: InputMaybe<Scalars['bigint']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  lotNo?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['bigint']>;
  sourceAccountId?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  sourceStockLocationId?: InputMaybe<Scalars['bigint']>;
  sourceStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sourceStorageUnitId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Stock_Transfer_States_Enum>;
  targetAccountId?: InputMaybe<Scalars['bigint']>;
  targetMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  targetMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  targetStockLocationId?: InputMaybe<Scalars['bigint']>;
  targetStorageQuantity?: InputMaybe<Scalars['bigint']>;
  targetStorageUnitId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  transaction?: InputMaybe<Stock_Transaction_Names_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  validTime?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Stock_Transactions_Sum_Fields = {
  __typename: 'stock_transactions_sum_fields';
  customerId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  sourceAccountId?: Maybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sourceMonetaryUnitId?: Maybe<Scalars['bigint']>;
  sourceStockLocationId?: Maybe<Scalars['bigint']>;
  sourceStorageQuantity?: Maybe<Scalars['bigint']>;
  sourceStorageUnitId?: Maybe<Scalars['bigint']>;
  targetAccountId?: Maybe<Scalars['bigint']>;
  targetMonetaryQuantity?: Maybe<Scalars['bigint']>;
  targetMonetaryUnitId?: Maybe<Scalars['bigint']>;
  targetStockLocationId?: Maybe<Scalars['bigint']>;
  targetStorageQuantity?: Maybe<Scalars['bigint']>;
  targetStorageUnitId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "stock_transactions" */
export type Stock_Transactions_Sum_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  sourceAccountId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "stock_transactions" */
export enum Stock_Transactions_Update_Column {
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  JournalEntryId = 'journalEntryId',
  /** column name */
  LotNo = 'lotNo',
  /** column name */
  LotNumberId = 'lotNumberId',
  /** column name */
  Notes = 'notes',
  /** column name */
  ProductId = 'productId',
  /** column name */
  SourceAccountId = 'sourceAccountId',
  /** column name */
  SourceMonetaryQuantity = 'sourceMonetaryQuantity',
  /** column name */
  SourceMonetaryUnitId = 'sourceMonetaryUnitId',
  /** column name */
  SourceStockLocationId = 'sourceStockLocationId',
  /** column name */
  SourceStorageQuantity = 'sourceStorageQuantity',
  /** column name */
  SourceStorageUnitId = 'sourceStorageUnitId',
  /** column name */
  State = 'state',
  /** column name */
  TargetAccountId = 'targetAccountId',
  /** column name */
  TargetMonetaryQuantity = 'targetMonetaryQuantity',
  /** column name */
  TargetMonetaryUnitId = 'targetMonetaryUnitId',
  /** column name */
  TargetStockLocationId = 'targetStockLocationId',
  /** column name */
  TargetStorageQuantity = 'targetStorageQuantity',
  /** column name */
  TargetStorageUnitId = 'targetStorageUnitId',
  /** column name */
  Transaction = 'transaction',
  /** column name */
  ValidTime = 'validTime',
  /** column name */
  Version = 'version'
}

export type Stock_Transactions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Stock_Transactions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Stock_Transactions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Stock_Transactions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Stock_Transactions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stock_Transactions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Stock_Transactions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stock_Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stock_Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stock_Transactions_Var_Pop_Fields = {
  __typename: 'stock_transactions_var_pop_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  sourceAccountId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStockLocationId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  targetAccountId?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stock_transactions" */
export type Stock_Transactions_Var_Pop_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  sourceAccountId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Transactions_Var_Samp_Fields = {
  __typename: 'stock_transactions_var_samp_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  sourceAccountId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStockLocationId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  targetAccountId?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stock_transactions" */
export type Stock_Transactions_Var_Samp_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  sourceAccountId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Transactions_Variance_Fields = {
  __typename: 'stock_transactions_variance_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  sourceAccountId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStockLocationId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  targetAccountId?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stock_transactions" */
export type Stock_Transactions_Variance_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  sourceAccountId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  targetAccountId?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

export enum Stock_Transfer_States_Enum {
  Approved = 'APPROVED',
  Completed = 'COMPLETED',
  Rejected = 'REJECTED',
  Reverted = 'REVERTED',
  WaitingForApproval = 'WAITING_FOR_APPROVAL'
}

/** Boolean expression to compare columns of type "stock_transfer_states_enum". All fields are combined with logical 'AND'. */
export type Stock_Transfer_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Stock_Transfer_States_Enum>;
  _in?: InputMaybe<Array<Stock_Transfer_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Stock_Transfer_States_Enum>;
  _nin?: InputMaybe<Array<Stock_Transfer_States_Enum>>;
};

export type Subscription_Root = {
  __typename: 'subscription_root';
  /** fetch data from the table: "abacus_exports" using primary key columns */
  abacusExportByPk?: Maybe<Abacus_Exports>;
  /** An array relationship */
  abacusExports: Array<Abacus_Exports>;
  /** fetch aggregated fields from the table: "abacus_exports" */
  abacusExportsAggregate: Abacus_Exports_Aggregate;
  /** fetch data from the table in a streaming manner: "abacus_exports" */
  abacus_exports_stream: Array<Abacus_Exports>;
  /** fetch data from the table: "accounts" using primary key columns */
  accountByPk?: Maybe<Accounts>;
  /** An array relationship */
  accounts: Array<Accounts>;
  /** fetch aggregated fields from the table: "accounts" */
  accountsAggregate: Accounts_Aggregate;
  /** fetch data from the table in a streaming manner: "accounts" */
  accounts_stream: Array<Accounts>;
  /** An array relationship */
  aggregatedDemandPlanIncomingGoodsResults: Array<Aggregated_Demand_Plan_Incoming_Goods_Results>;
  /** fetch aggregated fields from the table: "aggregated_demand_plan_incoming_goods_results" */
  aggregatedDemandPlanIncomingGoodsResultsAggregate: Aggregated_Demand_Plan_Incoming_Goods_Results_Aggregate;
  /** An array relationship */
  aggregatedDemandPlanOutgoingGoodsResults: Array<Aggregated_Demand_Plan_Outgoing_Goods_Results>;
  /** fetch aggregated fields from the table: "aggregated_demand_plan_outgoing_goods_results" */
  aggregatedDemandPlanOutgoingGoodsResultsAggregate: Aggregated_Demand_Plan_Outgoing_Goods_Results_Aggregate;
  /** An array relationship */
  aggregatedDemandPlanResults: Array<Aggregated_Demand_Plan_Results>;
  /** fetch aggregated fields from the table: "aggregated_demand_plan_results" */
  aggregatedDemandPlanResultsAggregate: Aggregated_Demand_Plan_Results_Aggregate;
  /** An array relationship */
  aggregatedInventoryItemsByLocations: Array<Aggregated_Inventory_Items_By_Location>;
  /** fetch aggregated fields from the table: "aggregated_inventory_items_by_location" */
  aggregatedInventoryItemsByLocationsAggregate: Aggregated_Inventory_Items_By_Location_Aggregate;
  /** execute function "aggregated_demand_plan" which returns "aggregated_demand_plan_results" */
  aggregated_demand_plan: Array<Aggregated_Demand_Plan_Results>;
  /** execute function "aggregated_demand_plan" and query aggregates on result of table type "aggregated_demand_plan_results" */
  aggregated_demand_plan_aggregate: Aggregated_Demand_Plan_Results_Aggregate;
  /** fetch data from the table in a streaming manner: "aggregated_demand_plan_incoming_goods_results" */
  aggregated_demand_plan_incoming_goods_results_stream: Array<Aggregated_Demand_Plan_Incoming_Goods_Results>;
  /** fetch data from the table in a streaming manner: "aggregated_demand_plan_outgoing_goods_results" */
  aggregated_demand_plan_outgoing_goods_results_stream: Array<Aggregated_Demand_Plan_Outgoing_Goods_Results>;
  /** fetch data from the table in a streaming manner: "aggregated_demand_plan_results" */
  aggregated_demand_plan_results_stream: Array<Aggregated_Demand_Plan_Results>;
  /** fetch data from the table in a streaming manner: "aggregated_inventory_items_by_location" */
  aggregated_inventory_items_by_location_stream: Array<Aggregated_Inventory_Items_By_Location>;
  /** execute function "aggregated_list_inventory_by_location" which returns "aggregated_inventory_items_by_location" */
  aggregated_list_inventory_by_location: Array<Aggregated_Inventory_Items_By_Location>;
  /** execute function "aggregated_list_inventory_by_location" and query aggregates on result of table type "aggregated_inventory_items_by_location" */
  aggregated_list_inventory_by_location_aggregate: Aggregated_Inventory_Items_By_Location_Aggregate;
  /** An array relationship */
  calculateCostPriceForProductResults: Array<Calculate_Cost_Price_For_Product_Results>;
  /** fetch aggregated fields from the table: "calculate_cost_price_for_product_results" */
  calculateCostPriceForProductResultsAggregate: Calculate_Cost_Price_For_Product_Results_Aggregate;
  /** execute function "calculate_cost_price_for_product" which returns "calculate_cost_price_for_product_results" */
  calculate_cost_price_for_product: Array<Calculate_Cost_Price_For_Product_Results>;
  /** execute function "calculate_cost_price_for_product" and query aggregates on result of table type "calculate_cost_price_for_product_results" */
  calculate_cost_price_for_product_aggregate: Calculate_Cost_Price_For_Product_Results_Aggregate;
  /** execute function "calculate_cost_price_for_product_by_product" which returns "calculate_cost_price_for_product_results" */
  calculate_cost_price_for_product_by_product: Array<Calculate_Cost_Price_For_Product_Results>;
  /** execute function "calculate_cost_price_for_product_by_product" and query aggregates on result of table type "calculate_cost_price_for_product_results" */
  calculate_cost_price_for_product_by_product_aggregate: Calculate_Cost_Price_For_Product_Results_Aggregate;
  /** fetch data from the table in a streaming manner: "calculate_cost_price_for_product_results" */
  calculate_cost_price_for_product_results_stream: Array<Calculate_Cost_Price_For_Product_Results>;
  /** execute function "calculate_customer_pricing" which returns "customer_group_pricing" */
  calculate_customer_pricing: Array<Customer_Group_Pricing>;
  /** execute function "calculate_customer_pricing" and query aggregates on result of table type "customer_group_pricing" */
  calculate_customer_pricing_aggregate: Customer_Group_Pricing_Aggregate;
  /** fetch data from the table: "change_customer_order_with_token_result" */
  changeCustomerOrderWithTokenResults: Array<Change_Customer_Order_With_Token_Result>;
  /** fetch aggregated fields from the table: "change_customer_order_with_token_result" */
  changeCustomerOrderWithTokenResultsAggregate: Change_Customer_Order_With_Token_Result_Aggregate;
  /** fetch data from the table in a streaming manner: "change_customer_order_with_token_result" */
  change_customer_order_with_token_result_stream: Array<Change_Customer_Order_With_Token_Result>;
  /** fetch data from the table: "contact_assigned_roles" using primary key columns */
  contactAssignedRoleByPk?: Maybe<Contact_Assigned_Roles>;
  /** An array relationship */
  contactAssignedRoles: Array<Contact_Assigned_Roles>;
  /** fetch aggregated fields from the table: "contact_assigned_roles" */
  contactAssignedRolesAggregate: Contact_Assigned_Roles_Aggregate;
  /** An array relationship */
  contactAvailabilities: Array<Contact_Availability>;
  /** fetch aggregated fields from the table: "contact_availability" */
  contactAvailabilitiesAggregate: Contact_Availability_Aggregate;
  /** fetch data from the table: "contact_availability" using primary key columns */
  contactAvailabilityByPk?: Maybe<Contact_Availability>;
  /** fetch data from the table: "contact_availability_ranges" using primary key columns */
  contactAvailabilityRangeByPk?: Maybe<Contact_Availability_Ranges>;
  /** An array relationship */
  contactAvailabilityRanges: Array<Contact_Availability_Ranges>;
  /** fetch aggregated fields from the table: "contact_availability_ranges" */
  contactAvailabilityRangesAggregate: Contact_Availability_Ranges_Aggregate;
  /** fetch data from the table: "contacts" using primary key columns */
  contactByPk?: Maybe<Contacts>;
  /** fetch data from the table: "contact_roles" using primary key columns */
  contactRoleByPk?: Maybe<Contact_Roles>;
  /** fetch data from the table: "contact_roles" */
  contactRoles: Array<Contact_Roles>;
  /** fetch aggregated fields from the table: "contact_roles" */
  contactRolesAggregate: Contact_Roles_Aggregate;
  /** fetch data from the table in a streaming manner: "contact_assigned_roles" */
  contact_assigned_roles_stream: Array<Contact_Assigned_Roles>;
  /** fetch data from the table in a streaming manner: "contact_availability_ranges" */
  contact_availability_ranges_stream: Array<Contact_Availability_Ranges>;
  /** fetch data from the table in a streaming manner: "contact_availability" */
  contact_availability_stream: Array<Contact_Availability>;
  /** fetch data from the table in a streaming manner: "contact_roles" */
  contact_roles_stream: Array<Contact_Roles>;
  /** An array relationship */
  contacts: Array<Contacts>;
  /** fetch aggregated fields from the table: "contacts" */
  contactsAggregate: Contacts_Aggregate;
  /** fetch data from the table in a streaming manner: "contacts" */
  contacts_stream: Array<Contacts>;
  /** fetch data from the table: "cost_price_average_periods" using primary key columns */
  costPriceAveragePeriodByPk?: Maybe<Cost_Price_Average_Periods>;
  /** An array relationship */
  costPriceAveragePeriods: Array<Cost_Price_Average_Periods>;
  /** fetch aggregated fields from the table: "cost_price_average_periods" */
  costPriceAveragePeriodsAggregate: Cost_Price_Average_Periods_Aggregate;
  /** fetch data from the table in a streaming manner: "cost_price_average_periods" */
  cost_price_average_periods_stream: Array<Cost_Price_Average_Periods>;
  /** fetch data from the table: "credit_notes" using primary key columns */
  creditNoteByPk?: Maybe<Credit_Notes>;
  /** fetch data from the table: "credit_note_items" using primary key columns */
  creditNoteItemByPk?: Maybe<Credit_Note_Items>;
  /** fetch data from the table: "credit_note_item_logged_actions" */
  creditNoteItemLoggedActions: Array<Credit_Note_Item_Logged_Actions>;
  /** fetch aggregated fields from the table: "credit_note_item_logged_actions" */
  creditNoteItemLoggedActionsAggregate: Credit_Note_Item_Logged_Actions_Aggregate;
  /** An array relationship */
  creditNoteItems: Array<Credit_Note_Items>;
  /** fetch aggregated fields from the table: "credit_note_items" */
  creditNoteItemsAggregate: Credit_Note_Items_Aggregate;
  /** fetch data from the table: "credit_note_logged_actions" */
  creditNoteLoggedActions: Array<Credit_Note_Logged_Actions>;
  /** fetch aggregated fields from the table: "credit_note_logged_actions" */
  creditNoteLoggedActionsAggregate: Credit_Note_Logged_Actions_Aggregate;
  /** fetch data from the table: "credit_note_states" using primary key columns */
  creditNoteStateByPk?: Maybe<Credit_Note_States>;
  /** fetch data from the table: "credit_note_state_transitions" using primary key columns */
  creditNoteStateTransitionByPk?: Maybe<Credit_Note_State_Transitions>;
  /** fetch data from the table: "credit_note_state_transitions" */
  creditNoteStateTransitions: Array<Credit_Note_State_Transitions>;
  /** fetch aggregated fields from the table: "credit_note_state_transitions" */
  creditNoteStateTransitionsAggregate: Credit_Note_State_Transitions_Aggregate;
  /** fetch data from the table: "credit_note_states" */
  creditNoteStates: Array<Credit_Note_States>;
  /** fetch aggregated fields from the table: "credit_note_states" */
  creditNoteStatesAggregate: Credit_Note_States_Aggregate;
  /** fetch data from the table: "credit_note_vat_summary_results" */
  creditNoteVatSummaryResults: Array<Credit_Note_Vat_Summary_Results>;
  /** fetch aggregated fields from the table: "credit_note_vat_summary_results" */
  creditNoteVatSummaryResultsAggregate: Credit_Note_Vat_Summary_Results_Aggregate;
  /** An array relationship */
  creditNotes: Array<Credit_Notes>;
  /** fetch aggregated fields from the table: "credit_notes" */
  creditNotesAggregate: Credit_Notes_Aggregate;
  /** fetch data from the table in a streaming manner: "credit_note_item_logged_actions" */
  credit_note_item_logged_actions_stream: Array<Credit_Note_Item_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "credit_note_items" */
  credit_note_items_stream: Array<Credit_Note_Items>;
  /** fetch data from the table in a streaming manner: "credit_note_logged_actions" */
  credit_note_logged_actions_stream: Array<Credit_Note_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "credit_note_state_transitions" */
  credit_note_state_transitions_stream: Array<Credit_Note_State_Transitions>;
  /** fetch data from the table in a streaming manner: "credit_note_states" */
  credit_note_states_stream: Array<Credit_Note_States>;
  /** fetch data from the table in a streaming manner: "credit_note_vat_summary_results" */
  credit_note_vat_summary_results_stream: Array<Credit_Note_Vat_Summary_Results>;
  /** fetch data from the table in a streaming manner: "credit_notes" */
  credit_notes_stream: Array<Credit_Notes>;
  /** fetch data from the table: "currencies" */
  currencies: Array<Currencies>;
  /** fetch aggregated fields from the table: "currencies" */
  currenciesAggregate: Currencies_Aggregate;
  /** fetch data from the table in a streaming manner: "currencies" */
  currencies_stream: Array<Currencies>;
  /** fetch data from the table: "currencies" using primary key columns */
  currencyByPk?: Maybe<Currencies>;
  /** fetch data from the table: "customer_addresses" using primary key columns */
  customerAddressByPk?: Maybe<Customer_Addresses>;
  /** An array relationship */
  customerAddresses: Array<Customer_Addresses>;
  /** fetch aggregated fields from the table: "customer_addresses" */
  customerAddressesAggregate: Customer_Addresses_Aggregate;
  /** fetch data from the table: "customer_billing_format" using primary key columns */
  customerBillingFormatByPk?: Maybe<Customer_Billing_Format>;
  /** fetch data from the table: "customer_billing_format" */
  customerBillingFormats: Array<Customer_Billing_Format>;
  /** fetch aggregated fields from the table: "customer_billing_format" */
  customerBillingFormatsAggregate: Customer_Billing_Format_Aggregate;
  /** fetch data from the table: "customer_billing_frequency" */
  customerBillingFrequencies: Array<Customer_Billing_Frequency>;
  /** fetch aggregated fields from the table: "customer_billing_frequency" */
  customerBillingFrequenciesAggregate: Customer_Billing_Frequency_Aggregate;
  /** fetch data from the table: "customer_billing_frequency" using primary key columns */
  customerBillingFrequencyByPk?: Maybe<Customer_Billing_Frequency>;
  /** fetch data from the table: "customer_billing_send_strategies" */
  customerBillingSendStrategies: Array<Customer_Billing_Send_Strategies>;
  /** fetch aggregated fields from the table: "customer_billing_send_strategies" */
  customerBillingSendStrategiesAggregate: Customer_Billing_Send_Strategies_Aggregate;
  /** fetch data from the table: "customer_billing_send_strategies" using primary key columns */
  customerBillingSendStrategyByPk?: Maybe<Customer_Billing_Send_Strategies>;
  /** fetch data from the table: "customer_billing_settings" using primary key columns */
  customerBillingSettingByPk?: Maybe<Customer_Billing_Settings>;
  /** An array relationship */
  customerBillingSettings: Array<Customer_Billing_Settings>;
  /** fetch aggregated fields from the table: "customer_billing_settings" */
  customerBillingSettingsAggregate: Customer_Billing_Settings_Aggregate;
  /** fetch data from the table: "customers" using primary key columns */
  customerByPk?: Maybe<Customers>;
  /** An array relationship */
  customerDeliveryAvailabilities: Array<Customer_Delivery_Availability>;
  /** fetch aggregated fields from the table: "customer_delivery_availability" */
  customerDeliveryAvailabilitiesAggregate: Customer_Delivery_Availability_Aggregate;
  /** fetch data from the table: "customer_delivery_availability" using primary key columns */
  customerDeliveryAvailabilityByPk?: Maybe<Customer_Delivery_Availability>;
  /** fetch data from the table: "customer_delivery_note_formats" using primary key columns */
  customerDeliveryNoteFormatByPk?: Maybe<Customer_Delivery_Note_Formats>;
  /** fetch data from the table: "customer_delivery_note_formats" */
  customerDeliveryNoteFormats: Array<Customer_Delivery_Note_Formats>;
  /** fetch aggregated fields from the table: "customer_delivery_note_formats" */
  customerDeliveryNoteFormatsAggregate: Customer_Delivery_Note_Formats_Aggregate;
  /** fetch data from the table: "customer_groups" using primary key columns */
  customerGroupByPk?: Maybe<Customer_Groups>;
  /** fetch data from the table: "customer_group_pricing" using primary key columns */
  customerGroupPricingByPk?: Maybe<Customer_Group_Pricing>;
  /** An array relationship */
  customerGroupPricings: Array<Customer_Group_Pricing>;
  /** fetch aggregated fields from the table: "customer_group_pricing" */
  customerGroupPricingsAggregate: Customer_Group_Pricing_Aggregate;
  /** fetch data from the table: "customer_group_states" using primary key columns */
  customerGroupStateByPk?: Maybe<Customer_Group_States>;
  /** fetch data from the table: "customer_group_state_transitions" using primary key columns */
  customerGroupStateTransitionByPk?: Maybe<Customer_Group_State_Transitions>;
  /** fetch data from the table: "customer_group_state_transitions" */
  customerGroupStateTransitions: Array<Customer_Group_State_Transitions>;
  /** fetch aggregated fields from the table: "customer_group_state_transitions" */
  customerGroupStateTransitionsAggregate: Customer_Group_State_Transitions_Aggregate;
  /** fetch data from the table: "customer_group_states" */
  customerGroupStates: Array<Customer_Group_States>;
  /** fetch aggregated fields from the table: "customer_group_states" */
  customerGroupStatesAggregate: Customer_Group_States_Aggregate;
  /** An array relationship */
  customerGroups: Array<Customer_Groups>;
  /** fetch aggregated fields from the table: "customer_groups" */
  customerGroupsAggregate: Customer_Groups_Aggregate;
  /** fetch data from the table: "customer_invoices" using primary key columns */
  customerInvoiceByPk?: Maybe<Customer_Invoices>;
  /** An array relationship */
  customerInvoiceLoggedActions: Array<Customer_Invoice_Logged_Actions>;
  /** fetch aggregated fields from the table: "customer_invoice_logged_actions" */
  customerInvoiceLoggedActionsAggregate: Customer_Invoice_Logged_Actions_Aggregate;
  /** fetch data from the table: "customer_invoice_orders" using primary key columns */
  customerInvoiceOrderByPk?: Maybe<Customer_Invoice_Orders>;
  /** An array relationship */
  customerInvoiceOrders: Array<Customer_Invoice_Orders>;
  /** fetch aggregated fields from the table: "customer_invoice_orders" */
  customerInvoiceOrdersAggregate: Customer_Invoice_Orders_Aggregate;
  /** fetch data from the table: "customer_invoice_states" using primary key columns */
  customerInvoiceStateByPk?: Maybe<Customer_Invoice_States>;
  /** fetch data from the table: "customer_invoice_state_transitions" using primary key columns */
  customerInvoiceStateTransitionByPk?: Maybe<Customer_Invoice_State_Transitions>;
  /** fetch data from the table: "customer_invoice_state_transitions" */
  customerInvoiceStateTransitions: Array<Customer_Invoice_State_Transitions>;
  /** fetch aggregated fields from the table: "customer_invoice_state_transitions" */
  customerInvoiceStateTransitionsAggregate: Customer_Invoice_State_Transitions_Aggregate;
  /** fetch data from the table: "customer_invoice_states" */
  customerInvoiceStates: Array<Customer_Invoice_States>;
  /** fetch aggregated fields from the table: "customer_invoice_states" */
  customerInvoiceStatesAggregate: Customer_Invoice_States_Aggregate;
  /** fetch data from the table: "customer_invoice_vat_summary_results" */
  customerInvoiceVatSummaryResults: Array<Customer_Invoice_Vat_Summary_Results>;
  /** fetch aggregated fields from the table: "customer_invoice_vat_summary_results" */
  customerInvoiceVatSummaryResultsAggregate: Customer_Invoice_Vat_Summary_Results_Aggregate;
  /** fetch data from the table: "customer_invoice_versions" using primary key columns */
  customerInvoiceVersionByPk?: Maybe<Customer_Invoice_Versions>;
  /** An array relationship */
  customerInvoiceVersionLoggedActions: Array<Customer_Invoice_Version_Logged_Actions>;
  /** fetch aggregated fields from the table: "customer_invoice_version_logged_actions" */
  customerInvoiceVersionLoggedActionsAggregate: Customer_Invoice_Version_Logged_Actions_Aggregate;
  /** An array relationship */
  customerInvoiceVersions: Array<Customer_Invoice_Versions>;
  /** fetch aggregated fields from the table: "customer_invoice_versions" */
  customerInvoiceVersionsAggregate: Customer_Invoice_Versions_Aggregate;
  /** An array relationship */
  customerInvoices: Array<Customer_Invoices>;
  /** fetch aggregated fields from the table: "customer_invoices" */
  customerInvoicesAggregate: Customer_Invoices_Aggregate;
  /** An array relationship */
  customerLoggedActions: Array<Customer_Logged_Actions>;
  /** fetch aggregated fields from the table: "customer_logged_actions" */
  customerLoggedActionsAggregate: Customer_Logged_Actions_Aggregate;
  /** fetch data from the table: "customer_orders" using primary key columns */
  customerOrderByPk?: Maybe<Customer_Orders>;
  /** fetch data from the table: "customer_order_container_returns" using primary key columns */
  customerOrderContainerReturnByPk?: Maybe<Customer_Order_Container_Returns>;
  /** fetch data from the table: "customer_order_container_return_items" using primary key columns */
  customerOrderContainerReturnItemByPk?: Maybe<Customer_Order_Container_Return_Items>;
  /** An array relationship */
  customerOrderContainerReturnItems: Array<Customer_Order_Container_Return_Items>;
  /** fetch aggregated fields from the table: "customer_order_container_return_items" */
  customerOrderContainerReturnItemsAggregate: Customer_Order_Container_Return_Items_Aggregate;
  /** An array relationship */
  customerOrderContainerReturns: Array<Customer_Order_Container_Returns>;
  /** fetch aggregated fields from the table: "customer_order_container_returns" */
  customerOrderContainerReturnsAggregate: Customer_Order_Container_Returns_Aggregate;
  /** fetch data from the table: "customer_order_item_adjustments" using primary key columns */
  customerOrderItemAdjustmentByPk?: Maybe<Customer_Order_Item_Adjustments>;
  /** fetch data from the table: "customer_order_item_adjustment_logged_actions" */
  customerOrderItemAdjustmentLoggedActions: Array<Customer_Order_Item_Adjustment_Logged_Actions>;
  /** fetch aggregated fields from the table: "customer_order_item_adjustment_logged_actions" */
  customerOrderItemAdjustmentLoggedActionsAggregate: Customer_Order_Item_Adjustment_Logged_Actions_Aggregate;
  /** fetch data from the table: "customer_order_item_adjustment_types" using primary key columns */
  customerOrderItemAdjustmentTypeByPk?: Maybe<Customer_Order_Item_Adjustment_Types>;
  /** fetch data from the table: "customer_order_item_adjustment_types" */
  customerOrderItemAdjustmentTypes: Array<Customer_Order_Item_Adjustment_Types>;
  /** fetch aggregated fields from the table: "customer_order_item_adjustment_types" */
  customerOrderItemAdjustmentTypesAggregate: Customer_Order_Item_Adjustment_Types_Aggregate;
  /** An array relationship */
  customerOrderItemAdjustments: Array<Customer_Order_Item_Adjustments>;
  /** fetch aggregated fields from the table: "customer_order_item_adjustments" */
  customerOrderItemAdjustmentsAggregate: Customer_Order_Item_Adjustments_Aggregate;
  /** fetch data from the table: "customer_order_items" using primary key columns */
  customerOrderItemByPk?: Maybe<Customer_Order_Items>;
  /** fetch data from the table: "customer_order_item_container_expectations" using primary key columns */
  customerOrderItemContainerExpectationByPk?: Maybe<Customer_Order_Item_Container_Expectations>;
  /** An array relationship */
  customerOrderItemContainerExpectations: Array<Customer_Order_Item_Container_Expectations>;
  /** fetch aggregated fields from the table: "customer_order_item_container_expectations" */
  customerOrderItemContainerExpectationsAggregate: Customer_Order_Item_Container_Expectations_Aggregate;
  /** fetch data from the table: "customer_order_item_extras" */
  customerOrderItemExtras: Array<Customer_Order_Item_Extras>;
  /** fetch aggregated fields from the table: "customer_order_item_extras" */
  customerOrderItemExtrasAggregate: Customer_Order_Item_Extras_Aggregate;
  /** fetch data from the table: "customer_order_item_fulfillment_plans" using primary key columns */
  customerOrderItemFulfillmentPlanByPk?: Maybe<Customer_Order_Item_Fulfillment_Plans>;
  /** An array relationship */
  customerOrderItemFulfillmentPlans: Array<Customer_Order_Item_Fulfillment_Plans>;
  /** fetch aggregated fields from the table: "customer_order_item_fulfillment_plans" */
  customerOrderItemFulfillmentPlansAggregate: Customer_Order_Item_Fulfillment_Plans_Aggregate;
  /** An array relationship */
  customerOrderItemLoggedActions: Array<Customer_Order_Item_Logged_Actions>;
  /** fetch aggregated fields from the table: "customer_order_item_logged_actions" */
  customerOrderItemLoggedActionsAggregate: Customer_Order_Item_Logged_Actions_Aggregate;
  /** fetch data from the table: "customer_order_item_return_causes" using primary key columns */
  customerOrderItemReturnCauseByPk?: Maybe<Customer_Order_Item_Return_Causes>;
  /** An array relationship */
  customerOrderItemReturnCauses: Array<Customer_Order_Item_Return_Causes>;
  /** fetch aggregated fields from the table: "customer_order_item_return_causes" */
  customerOrderItemReturnCausesAggregate: Customer_Order_Item_Return_Causes_Aggregate;
  /** fetch data from the table: "customer_order_item_states" using primary key columns */
  customerOrderItemStateByPk?: Maybe<Customer_Order_Item_States>;
  /** fetch data from the table: "customer_order_item_state_transitions" using primary key columns */
  customerOrderItemStateTransitionByPk?: Maybe<Customer_Order_Item_State_Transitions>;
  /** fetch data from the table: "customer_order_item_state_transitions" */
  customerOrderItemStateTransitions: Array<Customer_Order_Item_State_Transitions>;
  /** fetch aggregated fields from the table: "customer_order_item_state_transitions" */
  customerOrderItemStateTransitionsAggregate: Customer_Order_Item_State_Transitions_Aggregate;
  /** fetch data from the table: "customer_order_item_states" */
  customerOrderItemStates: Array<Customer_Order_Item_States>;
  /** fetch aggregated fields from the table: "customer_order_item_states" */
  customerOrderItemStatesAggregate: Customer_Order_Item_States_Aggregate;
  /** An array relationship */
  customerOrderItems: Array<Customer_Order_Items>;
  /** fetch aggregated fields from the table: "customer_order_items" */
  customerOrderItemsAggregate: Customer_Order_Items_Aggregate;
  /** An array relationship */
  customerOrderLoggedActions: Array<Customer_Order_Logged_Actions>;
  /** fetch aggregated fields from the table: "customer_order_logged_actions" */
  customerOrderLoggedActionsAggregate: Customer_Order_Logged_Actions_Aggregate;
  /** fetch data from the table: "customer_order_picked_container" using primary key columns */
  customerOrderPickedContainerByPk?: Maybe<Customer_Order_Picked_Container>;
  /** fetch data from the table: "customer_order_picked_container_states" using primary key columns */
  customerOrderPickedContainerStateByPk?: Maybe<Customer_Order_Picked_Container_States>;
  /** fetch data from the table: "customer_order_picked_container_state_transitions" using primary key columns */
  customerOrderPickedContainerStateTransitionByPk?: Maybe<Customer_Order_Picked_Container_State_Transitions>;
  /** fetch data from the table: "customer_order_picked_container_state_transitions" */
  customerOrderPickedContainerStateTransitions: Array<Customer_Order_Picked_Container_State_Transitions>;
  /** fetch aggregated fields from the table: "customer_order_picked_container_state_transitions" */
  customerOrderPickedContainerStateTransitionsAggregate: Customer_Order_Picked_Container_State_Transitions_Aggregate;
  /** fetch data from the table: "customer_order_picked_container_states" */
  customerOrderPickedContainerStates: Array<Customer_Order_Picked_Container_States>;
  /** fetch aggregated fields from the table: "customer_order_picked_container_states" */
  customerOrderPickedContainerStatesAggregate: Customer_Order_Picked_Container_States_Aggregate;
  /** An array relationship */
  customerOrderPickedContainers: Array<Customer_Order_Picked_Container>;
  /** fetch aggregated fields from the table: "customer_order_picked_container" */
  customerOrderPickedContainersAggregate: Customer_Order_Picked_Container_Aggregate;
  /** fetch data from the table: "customer_order_returns" using primary key columns */
  customerOrderReturnByPk?: Maybe<Customer_Order_Returns>;
  /** fetch data from the table: "customer_order_return_items" using primary key columns */
  customerOrderReturnItemByPk?: Maybe<Customer_Order_Return_Items>;
  /** fetch data from the table: "customer_order_return_item_containers" using primary key columns */
  customerOrderReturnItemContainerByPk?: Maybe<Customer_Order_Return_Item_Containers>;
  /** An array relationship */
  customerOrderReturnItemContainers: Array<Customer_Order_Return_Item_Containers>;
  /** fetch aggregated fields from the table: "customer_order_return_item_containers" */
  customerOrderReturnItemContainersAggregate: Customer_Order_Return_Item_Containers_Aggregate;
  /** An array relationship */
  customerOrderReturnItems: Array<Customer_Order_Return_Items>;
  /** fetch aggregated fields from the table: "customer_order_return_items" */
  customerOrderReturnItemsAggregate: Customer_Order_Return_Items_Aggregate;
  /** fetch data from the table: "customer_order_return_states" using primary key columns */
  customerOrderReturnStateByPk?: Maybe<Customer_Order_Return_States>;
  /** fetch data from the table: "customer_order_return_states" */
  customerOrderReturnStates: Array<Customer_Order_Return_States>;
  /** fetch aggregated fields from the table: "customer_order_return_states" */
  customerOrderReturnStatesAggregate: Customer_Order_Return_States_Aggregate;
  /** An array relationship */
  customerOrderReturns: Array<Customer_Order_Returns>;
  /** fetch aggregated fields from the table: "customer_order_returns" */
  customerOrderReturnsAggregate: Customer_Order_Returns_Aggregate;
  /** fetch data from the table: "customer_order_states" using primary key columns */
  customerOrderStateByPk?: Maybe<Customer_Order_States>;
  /** fetch data from the table: "customer_order_state_transitions" using primary key columns */
  customerOrderStateTransitionByPk?: Maybe<Customer_Order_State_Transitions>;
  /** fetch data from the table: "customer_order_state_transitions" */
  customerOrderStateTransitions: Array<Customer_Order_State_Transitions>;
  /** fetch aggregated fields from the table: "customer_order_state_transitions" */
  customerOrderStateTransitionsAggregate: Customer_Order_State_Transitions_Aggregate;
  /** fetch data from the table: "customer_order_states" */
  customerOrderStates: Array<Customer_Order_States>;
  /** fetch aggregated fields from the table: "customer_order_states" */
  customerOrderStatesAggregate: Customer_Order_States_Aggregate;
  /** fetch data from the table: "customer_order_stock_reservations" */
  customerOrderStockReservations: Array<Customer_Order_Stock_Reservations>;
  /** fetch aggregated fields from the table: "customer_order_stock_reservations" */
  customerOrderStockReservationsAggregate: Customer_Order_Stock_Reservations_Aggregate;
  /** An array relationship */
  customerOrders: Array<Customer_Orders>;
  /** fetch aggregated fields from the table: "customer_orders" */
  customerOrdersAggregate: Customer_Orders_Aggregate;
  /** fetch data from the table: "customer_payment_method" using primary key columns */
  customerPaymentMethodByPk?: Maybe<Customer_Payment_Method>;
  /** fetch data from the table: "customer_payment_method" */
  customerPaymentMethods: Array<Customer_Payment_Method>;
  /** fetch aggregated fields from the table: "customer_payment_method" */
  customerPaymentMethodsAggregate: Customer_Payment_Method_Aggregate;
  /** fetch data from the table: "customer_payment_terms" using primary key columns */
  customerPaymentTermByPk?: Maybe<Customer_Payment_Terms>;
  /** An array relationship */
  customerPaymentTerms: Array<Customer_Payment_Terms>;
  /** fetch aggregated fields from the table: "customer_payment_terms" */
  customerPaymentTermsAggregate: Customer_Payment_Terms_Aggregate;
  /** An array relationship */
  customerProductAvailabilities: Array<Customer_Product_Availabilities>;
  /** fetch aggregated fields from the table: "customer_product_availabilities" */
  customerProductAvailabilitiesAggregate: Customer_Product_Availabilities_Aggregate;
  /** fetch data from the table: "customer_product_availabilities" using primary key columns */
  customerProductAvailabilityByPk?: Maybe<Customer_Product_Availabilities>;
  /** fetch data from the table: "customer_states" using primary key columns */
  customerStateByPk?: Maybe<Customer_States>;
  /** fetch data from the table: "customer_state_transitions" using primary key columns */
  customerStateTransitionByPk?: Maybe<Customer_State_Transitions>;
  /** fetch data from the table: "customer_state_transitions" */
  customerStateTransitions: Array<Customer_State_Transitions>;
  /** fetch aggregated fields from the table: "customer_state_transitions" */
  customerStateTransitionsAggregate: Customer_State_Transitions_Aggregate;
  /** fetch data from the table: "customer_states" */
  customerStates: Array<Customer_States>;
  /** fetch aggregated fields from the table: "customer_states" */
  customerStatesAggregate: Customer_States_Aggregate;
  /** fetch data from the table: "customer_template_settings" using primary key columns */
  customerTemplateSettingByPk?: Maybe<Customer_Template_Settings>;
  /** An array relationship */
  customerTemplateSettings: Array<Customer_Template_Settings>;
  /** fetch aggregated fields from the table: "customer_template_settings" */
  customerTemplateSettingsAggregate: Customer_Template_Settings_Aggregate;
  /** fetch data from the table in a streaming manner: "customer_addresses" */
  customer_addresses_stream: Array<Customer_Addresses>;
  /** fetch data from the table in a streaming manner: "customer_billing_format" */
  customer_billing_format_stream: Array<Customer_Billing_Format>;
  /** fetch data from the table in a streaming manner: "customer_billing_frequency" */
  customer_billing_frequency_stream: Array<Customer_Billing_Frequency>;
  /** fetch data from the table in a streaming manner: "customer_billing_send_strategies" */
  customer_billing_send_strategies_stream: Array<Customer_Billing_Send_Strategies>;
  /** fetch data from the table in a streaming manner: "customer_billing_settings" */
  customer_billing_settings_stream: Array<Customer_Billing_Settings>;
  /** fetch data from the table in a streaming manner: "customer_delivery_availability" */
  customer_delivery_availability_stream: Array<Customer_Delivery_Availability>;
  /** fetch data from the table in a streaming manner: "customer_delivery_note_formats" */
  customer_delivery_note_formats_stream: Array<Customer_Delivery_Note_Formats>;
  /** fetch data from the table in a streaming manner: "customer_group_pricing" */
  customer_group_pricing_stream: Array<Customer_Group_Pricing>;
  /** execute function "customer_group_pricing_within_range" which returns "customer_group_pricing" */
  customer_group_pricing_within_range: Array<Customer_Group_Pricing>;
  /** execute function "customer_group_pricing_within_range" and query aggregates on result of table type "customer_group_pricing" */
  customer_group_pricing_within_range_aggregate: Customer_Group_Pricing_Aggregate;
  /** fetch data from the table in a streaming manner: "customer_group_state_transitions" */
  customer_group_state_transitions_stream: Array<Customer_Group_State_Transitions>;
  /** fetch data from the table in a streaming manner: "customer_group_states" */
  customer_group_states_stream: Array<Customer_Group_States>;
  /** fetch data from the table in a streaming manner: "customer_groups" */
  customer_groups_stream: Array<Customer_Groups>;
  /** fetch data from the table in a streaming manner: "customer_invoice_logged_actions" */
  customer_invoice_logged_actions_stream: Array<Customer_Invoice_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "customer_invoice_orders" */
  customer_invoice_orders_stream: Array<Customer_Invoice_Orders>;
  /** fetch data from the table in a streaming manner: "customer_invoice_state_transitions" */
  customer_invoice_state_transitions_stream: Array<Customer_Invoice_State_Transitions>;
  /** fetch data from the table in a streaming manner: "customer_invoice_states" */
  customer_invoice_states_stream: Array<Customer_Invoice_States>;
  /** fetch data from the table in a streaming manner: "customer_invoice_vat_summary_results" */
  customer_invoice_vat_summary_results_stream: Array<Customer_Invoice_Vat_Summary_Results>;
  /** fetch data from the table in a streaming manner: "customer_invoice_version_logged_actions" */
  customer_invoice_version_logged_actions_stream: Array<Customer_Invoice_Version_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "customer_invoice_versions" */
  customer_invoice_versions_stream: Array<Customer_Invoice_Versions>;
  /** fetch data from the table in a streaming manner: "customer_invoices" */
  customer_invoices_stream: Array<Customer_Invoices>;
  /** fetch data from the table in a streaming manner: "customer_logged_actions" */
  customer_logged_actions_stream: Array<Customer_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "customer_order_container_return_items" */
  customer_order_container_return_items_stream: Array<Customer_Order_Container_Return_Items>;
  /** fetch data from the table in a streaming manner: "customer_order_container_returns" */
  customer_order_container_returns_stream: Array<Customer_Order_Container_Returns>;
  /** fetch data from the table in a streaming manner: "customer_order_item_adjustment_logged_actions" */
  customer_order_item_adjustment_logged_actions_stream: Array<Customer_Order_Item_Adjustment_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "customer_order_item_adjustment_types" */
  customer_order_item_adjustment_types_stream: Array<Customer_Order_Item_Adjustment_Types>;
  /** fetch data from the table in a streaming manner: "customer_order_item_adjustments" */
  customer_order_item_adjustments_stream: Array<Customer_Order_Item_Adjustments>;
  /** fetch data from the table in a streaming manner: "customer_order_item_container_expectations" */
  customer_order_item_container_expectations_stream: Array<Customer_Order_Item_Container_Expectations>;
  /** fetch data from the table in a streaming manner: "customer_order_item_extras" */
  customer_order_item_extras_stream: Array<Customer_Order_Item_Extras>;
  /** fetch data from the table in a streaming manner: "customer_order_item_fulfillment_plans" */
  customer_order_item_fulfillment_plans_stream: Array<Customer_Order_Item_Fulfillment_Plans>;
  /** fetch data from the table in a streaming manner: "customer_order_item_logged_actions" */
  customer_order_item_logged_actions_stream: Array<Customer_Order_Item_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "customer_order_item_return_causes" */
  customer_order_item_return_causes_stream: Array<Customer_Order_Item_Return_Causes>;
  /** fetch data from the table in a streaming manner: "customer_order_item_state_transitions" */
  customer_order_item_state_transitions_stream: Array<Customer_Order_Item_State_Transitions>;
  /** fetch data from the table in a streaming manner: "customer_order_item_states" */
  customer_order_item_states_stream: Array<Customer_Order_Item_States>;
  /** fetch data from the table in a streaming manner: "customer_order_items" */
  customer_order_items_stream: Array<Customer_Order_Items>;
  /** fetch data from the table in a streaming manner: "customer_order_logged_actions" */
  customer_order_logged_actions_stream: Array<Customer_Order_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "customer_order_picked_container_state_transitions" */
  customer_order_picked_container_state_transitions_stream: Array<Customer_Order_Picked_Container_State_Transitions>;
  /** fetch data from the table in a streaming manner: "customer_order_picked_container_states" */
  customer_order_picked_container_states_stream: Array<Customer_Order_Picked_Container_States>;
  /** fetch data from the table in a streaming manner: "customer_order_picked_container" */
  customer_order_picked_container_stream: Array<Customer_Order_Picked_Container>;
  /** fetch data from the table in a streaming manner: "customer_order_return_item_containers" */
  customer_order_return_item_containers_stream: Array<Customer_Order_Return_Item_Containers>;
  /** fetch data from the table in a streaming manner: "customer_order_return_items" */
  customer_order_return_items_stream: Array<Customer_Order_Return_Items>;
  /** fetch data from the table in a streaming manner: "customer_order_return_states" */
  customer_order_return_states_stream: Array<Customer_Order_Return_States>;
  /** fetch data from the table in a streaming manner: "customer_order_returns" */
  customer_order_returns_stream: Array<Customer_Order_Returns>;
  /** fetch data from the table in a streaming manner: "customer_order_state_transitions" */
  customer_order_state_transitions_stream: Array<Customer_Order_State_Transitions>;
  /** fetch data from the table in a streaming manner: "customer_order_states" */
  customer_order_states_stream: Array<Customer_Order_States>;
  /** fetch data from the table in a streaming manner: "customer_order_stock_reservations" */
  customer_order_stock_reservations_stream: Array<Customer_Order_Stock_Reservations>;
  /** fetch data from the table in a streaming manner: "customer_orders" */
  customer_orders_stream: Array<Customer_Orders>;
  /** fetch data from the table in a streaming manner: "customer_payment_method" */
  customer_payment_method_stream: Array<Customer_Payment_Method>;
  /** execute function "customer_payment_term_suggestions" which returns "customer_payment_terms" */
  customer_payment_term_suggestions: Array<Customer_Payment_Terms>;
  /** execute function "customer_payment_term_suggestions" and query aggregates on result of table type "customer_payment_terms" */
  customer_payment_term_suggestions_aggregate: Customer_Payment_Terms_Aggregate;
  /** fetch data from the table in a streaming manner: "customer_payment_terms" */
  customer_payment_terms_stream: Array<Customer_Payment_Terms>;
  /** fetch data from the table in a streaming manner: "customer_product_availabilities" */
  customer_product_availabilities_stream: Array<Customer_Product_Availabilities>;
  /** fetch data from the table in a streaming manner: "customer_state_transitions" */
  customer_state_transitions_stream: Array<Customer_State_Transitions>;
  /** fetch data from the table in a streaming manner: "customer_states" */
  customer_states_stream: Array<Customer_States>;
  /** fetch data from the table in a streaming manner: "customer_template_settings" */
  customer_template_settings_stream: Array<Customer_Template_Settings>;
  /** An array relationship */
  customers: Array<Customers>;
  /** fetch aggregated fields from the table: "customers" */
  customersAggregate: Customers_Aggregate;
  /** fetch data from the table in a streaming manner: "customers" */
  customers_stream: Array<Customers>;
  /** fetch data from the table: "cutting_patterns" using primary key columns */
  cuttingPatternByPk?: Maybe<Cutting_Patterns>;
  /** fetch data from the table: "cutting_pattern_draft_actions" using primary key columns */
  cuttingPatternDraftActionByPk?: Maybe<Cutting_Pattern_Draft_Actions>;
  /** fetch data from the table: "cutting_pattern_draft_actions" */
  cuttingPatternDraftActions: Array<Cutting_Pattern_Draft_Actions>;
  /** fetch aggregated fields from the table: "cutting_pattern_draft_actions" */
  cuttingPatternDraftActionsAggregate: Cutting_Pattern_Draft_Actions_Aggregate;
  /** fetch data from the table: "cutting_pattern_draft_states" using primary key columns */
  cuttingPatternDraftStateByPk?: Maybe<Cutting_Pattern_Draft_States>;
  /** fetch data from the table: "cutting_pattern_draft_state_transitions" using primary key columns */
  cuttingPatternDraftStateTransitionByPk?: Maybe<Cutting_Pattern_Draft_State_Transitions>;
  /** fetch data from the table: "cutting_pattern_draft_state_transitions" */
  cuttingPatternDraftStateTransitions: Array<Cutting_Pattern_Draft_State_Transitions>;
  /** fetch aggregated fields from the table: "cutting_pattern_draft_state_transitions" */
  cuttingPatternDraftStateTransitionsAggregate: Cutting_Pattern_Draft_State_Transitions_Aggregate;
  /** fetch data from the table: "cutting_pattern_draft_states" */
  cuttingPatternDraftStates: Array<Cutting_Pattern_Draft_States>;
  /** fetch aggregated fields from the table: "cutting_pattern_draft_states" */
  cuttingPatternDraftStatesAggregate: Cutting_Pattern_Draft_States_Aggregate;
  /** fetch data from the table: "cutting_pattern_logged_actions" */
  cuttingPatternLoggedActions: Array<Cutting_Pattern_Logged_Actions>;
  /** fetch aggregated fields from the table: "cutting_pattern_logged_actions" */
  cuttingPatternLoggedActionsAggregate: Cutting_Pattern_Logged_Actions_Aggregate;
  /** fetch data from the table: "cutting_pattern_outputs" using primary key columns */
  cuttingPatternOutputByPk?: Maybe<Cutting_Pattern_Outputs>;
  /** fetch data from the table: "cutting_pattern_output_logged_actions" */
  cuttingPatternOutputLoggedActions: Array<Cutting_Pattern_Output_Logged_Actions>;
  /** fetch aggregated fields from the table: "cutting_pattern_output_logged_actions" */
  cuttingPatternOutputLoggedActionsAggregate: Cutting_Pattern_Output_Logged_Actions_Aggregate;
  /** An array relationship */
  cuttingPatternOutputs: Array<Cutting_Pattern_Outputs>;
  /** fetch aggregated fields from the table: "cutting_pattern_outputs" */
  cuttingPatternOutputsAggregate: Cutting_Pattern_Outputs_Aggregate;
  /** fetch data from the table: "cutting_pattern_outputs_draft" using primary key columns */
  cuttingPatternOutputsDraftByPk?: Maybe<Cutting_Pattern_Outputs_Draft>;
  /** An array relationship */
  cuttingPatternOutputsDrafts: Array<Cutting_Pattern_Outputs_Draft>;
  /** fetch aggregated fields from the table: "cutting_pattern_outputs_draft" */
  cuttingPatternOutputsDraftsAggregate: Cutting_Pattern_Outputs_Draft_Aggregate;
  /** fetch data from the table: "cutting_pattern_states" using primary key columns */
  cuttingPatternStateByPk?: Maybe<Cutting_Pattern_States>;
  /** fetch data from the table: "cutting_pattern_state_transitions" using primary key columns */
  cuttingPatternStateTransitionByPk?: Maybe<Cutting_Pattern_State_Transitions>;
  /** fetch data from the table: "cutting_pattern_state_transitions" */
  cuttingPatternStateTransitions: Array<Cutting_Pattern_State_Transitions>;
  /** fetch aggregated fields from the table: "cutting_pattern_state_transitions" */
  cuttingPatternStateTransitionsAggregate: Cutting_Pattern_State_Transitions_Aggregate;
  /** fetch data from the table: "cutting_pattern_states" */
  cuttingPatternStates: Array<Cutting_Pattern_States>;
  /** fetch aggregated fields from the table: "cutting_pattern_states" */
  cuttingPatternStatesAggregate: Cutting_Pattern_States_Aggregate;
  /** An array relationship */
  cuttingPatterns: Array<Cutting_Patterns>;
  /** fetch aggregated fields from the table: "cutting_patterns" */
  cuttingPatternsAggregate: Cutting_Patterns_Aggregate;
  /** fetch data from the table: "cutting_patterns_draft" using primary key columns */
  cuttingPatternsDraftByPk?: Maybe<Cutting_Patterns_Draft>;
  /** An array relationship */
  cuttingPatternsDrafts: Array<Cutting_Patterns_Draft>;
  /** fetch aggregated fields from the table: "cutting_patterns_draft" */
  cuttingPatternsDraftsAggregate: Cutting_Patterns_Draft_Aggregate;
  /** fetch data from the table in a streaming manner: "cutting_pattern_draft_actions" */
  cutting_pattern_draft_actions_stream: Array<Cutting_Pattern_Draft_Actions>;
  /** fetch data from the table in a streaming manner: "cutting_pattern_draft_state_transitions" */
  cutting_pattern_draft_state_transitions_stream: Array<Cutting_Pattern_Draft_State_Transitions>;
  /** fetch data from the table in a streaming manner: "cutting_pattern_draft_states" */
  cutting_pattern_draft_states_stream: Array<Cutting_Pattern_Draft_States>;
  /** fetch data from the table in a streaming manner: "cutting_pattern_logged_actions" */
  cutting_pattern_logged_actions_stream: Array<Cutting_Pattern_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "cutting_pattern_output_logged_actions" */
  cutting_pattern_output_logged_actions_stream: Array<Cutting_Pattern_Output_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "cutting_pattern_outputs_draft" */
  cutting_pattern_outputs_draft_stream: Array<Cutting_Pattern_Outputs_Draft>;
  /** fetch data from the table in a streaming manner: "cutting_pattern_outputs" */
  cutting_pattern_outputs_stream: Array<Cutting_Pattern_Outputs>;
  /** fetch data from the table in a streaming manner: "cutting_pattern_state_transitions" */
  cutting_pattern_state_transitions_stream: Array<Cutting_Pattern_State_Transitions>;
  /** fetch data from the table in a streaming manner: "cutting_pattern_states" */
  cutting_pattern_states_stream: Array<Cutting_Pattern_States>;
  /** fetch data from the table in a streaming manner: "cutting_patterns_draft" */
  cutting_patterns_draft_stream: Array<Cutting_Patterns_Draft>;
  /** fetch data from the table in a streaming manner: "cutting_patterns" */
  cutting_patterns_stream: Array<Cutting_Patterns>;
  /** An array relationship */
  dailyPriceLists: Array<Daily_Price_Lists>;
  /** fetch aggregated fields from the table: "daily_price_lists" */
  dailyPriceListsAggregate: Daily_Price_Lists_Aggregate;
  /** fetch data from the table in a streaming manner: "daily_price_lists" */
  daily_price_lists_stream: Array<Daily_Price_Lists>;
  /** An array relationship */
  demandsForCoiResults: Array<Demands_For_Coi_Results>;
  /** fetch aggregated fields from the table: "demands_for_coi_results" */
  demandsForCoiResultsAggregate: Demands_For_Coi_Results_Aggregate;
  /** fetch data from the table in a streaming manner: "demands_for_coi_results" */
  demands_for_coi_results_stream: Array<Demands_For_Coi_Results>;
  /** An array relationship */
  dismantleableProductsResults: Array<Dismantleable_Products_Results>;
  /** fetch aggregated fields from the table: "dismantleable_products_results" */
  dismantleableProductsResultsAggregate: Dismantleable_Products_Results_Aggregate;
  /** execute function "dismantleable_products" which returns "dismantleable_products_results" */
  dismantleable_products: Array<Dismantleable_Products_Results>;
  /** execute function "dismantleable_products" and query aggregates on result of table type "dismantleable_products_results" */
  dismantleable_products_aggregate: Dismantleable_Products_Results_Aggregate;
  /** fetch data from the table in a streaming manner: "dismantleable_products_results" */
  dismantleable_products_results_stream: Array<Dismantleable_Products_Results>;
  /** An array relationship */
  dismantlingOutputWeightResults: Array<Dismantling_Output_Weight_Results>;
  /** fetch aggregated fields from the table: "dismantling_output_weight_results" */
  dismantlingOutputWeightResultsAggregate: Dismantling_Output_Weight_Results_Aggregate;
  /** fetch data from the table: "dismantling_plans" using primary key columns */
  dismantlingPlanByPk?: Maybe<Dismantling_Plans>;
  /** fetch data from the table: "dismantling_plan_cutting_pattern_choices" using primary key columns */
  dismantlingPlanCuttingPatternChoiceByPk?: Maybe<Dismantling_Plan_Cutting_Pattern_Choices>;
  /** An array relationship */
  dismantlingPlanCuttingPatternChoices: Array<Dismantling_Plan_Cutting_Pattern_Choices>;
  /** fetch aggregated fields from the table: "dismantling_plan_cutting_pattern_choices" */
  dismantlingPlanCuttingPatternChoicesAggregate: Dismantling_Plan_Cutting_Pattern_Choices_Aggregate;
  /** fetch data from the table: "dismantling_plan_demands" using primary key columns */
  dismantlingPlanDemandByPk?: Maybe<Dismantling_Plan_Demands>;
  /** An array relationship */
  dismantlingPlanDemands: Array<Dismantling_Plan_Demands>;
  /** fetch aggregated fields from the table: "dismantling_plan_demands" */
  dismantlingPlanDemandsAggregate: Dismantling_Plan_Demands_Aggregate;
  /** fetch data from the table: "dismantling_plan_instructions" using primary key columns */
  dismantlingPlanInstructionByPk?: Maybe<Dismantling_Plan_Instructions>;
  /** An array relationship */
  dismantlingPlanInstructions: Array<Dismantling_Plan_Instructions>;
  /** fetch aggregated fields from the table: "dismantling_plan_instructions" */
  dismantlingPlanInstructionsAggregate: Dismantling_Plan_Instructions_Aggregate;
  /** An array relationship */
  dismantlingPlanLoggedActions: Array<Dismantling_Plan_Logged_Actions>;
  /** fetch aggregated fields from the table: "dismantling_plan_logged_actions" */
  dismantlingPlanLoggedActionsAggregate: Dismantling_Plan_Logged_Actions_Aggregate;
  /** fetch data from the table: "dismantling_plan_priorities" */
  dismantlingPlanPriorities: Array<Dismantling_Plan_Priorities>;
  /** fetch aggregated fields from the table: "dismantling_plan_priorities" */
  dismantlingPlanPrioritiesAggregate: Dismantling_Plan_Priorities_Aggregate;
  /** fetch data from the table: "dismantling_plan_priorities" using primary key columns */
  dismantlingPlanPriorityByPk?: Maybe<Dismantling_Plan_Priorities>;
  /** fetch data from the table: "dismantling_plan_remaining_pieces" using primary key columns */
  dismantlingPlanRemainingPieceByPk?: Maybe<Dismantling_Plan_Remaining_Pieces>;
  /** An array relationship */
  dismantlingPlanRemainingPieces: Array<Dismantling_Plan_Remaining_Pieces>;
  /** fetch aggregated fields from the table: "dismantling_plan_remaining_pieces" */
  dismantlingPlanRemainingPiecesAggregate: Dismantling_Plan_Remaining_Pieces_Aggregate;
  /** fetch data from the table: "dismantling_plan_states" using primary key columns */
  dismantlingPlanStateByPk?: Maybe<Dismantling_Plan_States>;
  /** fetch data from the table: "dismantling_plan_state_transitions" using primary key columns */
  dismantlingPlanStateTransitionByPk?: Maybe<Dismantling_Plan_State_Transitions>;
  /** fetch data from the table: "dismantling_plan_state_transitions" */
  dismantlingPlanStateTransitions: Array<Dismantling_Plan_State_Transitions>;
  /** fetch aggregated fields from the table: "dismantling_plan_state_transitions" */
  dismantlingPlanStateTransitionsAggregate: Dismantling_Plan_State_Transitions_Aggregate;
  /** fetch data from the table: "dismantling_plan_states" */
  dismantlingPlanStates: Array<Dismantling_Plan_States>;
  /** fetch aggregated fields from the table: "dismantling_plan_states" */
  dismantlingPlanStatesAggregate: Dismantling_Plan_States_Aggregate;
  /** An array relationship */
  dismantlingPlans: Array<Dismantling_Plans>;
  /** fetch aggregated fields from the table: "dismantling_plans" */
  dismantlingPlansAggregate: Dismantling_Plans_Aggregate;
  /** fetch data from the table in a streaming manner: "dismantling_output_weight_results" */
  dismantling_output_weight_results_stream: Array<Dismantling_Output_Weight_Results>;
  /** fetch data from the table in a streaming manner: "dismantling_plan_cutting_pattern_choices" */
  dismantling_plan_cutting_pattern_choices_stream: Array<Dismantling_Plan_Cutting_Pattern_Choices>;
  /** fetch data from the table in a streaming manner: "dismantling_plan_demands" */
  dismantling_plan_demands_stream: Array<Dismantling_Plan_Demands>;
  /** fetch data from the table in a streaming manner: "dismantling_plan_instructions" */
  dismantling_plan_instructions_stream: Array<Dismantling_Plan_Instructions>;
  /** execute function "dismantling_plan_inventory" which returns "inventory_items" */
  dismantling_plan_inventory: Array<Inventory_Items>;
  /** execute function "dismantling_plan_inventory" and query aggregates on result of table type "inventory_items" */
  dismantling_plan_inventory_aggregate: Inventory_Items_Aggregate;
  /** execute function "dismantling_plan_inventory_candidate_search" which returns "inventory_items_by_location" */
  dismantling_plan_inventory_candidate_search: Array<Inventory_Items_By_Location>;
  /** execute function "dismantling_plan_inventory_candidate_search" and query aggregates on result of table type "inventory_items_by_location" */
  dismantling_plan_inventory_candidate_search_aggregate: Inventory_Items_By_Location_Aggregate;
  /** fetch data from the table in a streaming manner: "dismantling_plan_logged_actions" */
  dismantling_plan_logged_actions_stream: Array<Dismantling_Plan_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "dismantling_plan_priorities" */
  dismantling_plan_priorities_stream: Array<Dismantling_Plan_Priorities>;
  /** fetch data from the table in a streaming manner: "dismantling_plan_remaining_pieces" */
  dismantling_plan_remaining_pieces_stream: Array<Dismantling_Plan_Remaining_Pieces>;
  /** fetch data from the table in a streaming manner: "dismantling_plan_state_transitions" */
  dismantling_plan_state_transitions_stream: Array<Dismantling_Plan_State_Transitions>;
  /** fetch data from the table in a streaming manner: "dismantling_plan_states" */
  dismantling_plan_states_stream: Array<Dismantling_Plan_States>;
  /** fetch data from the table in a streaming manner: "dismantling_plans" */
  dismantling_plans_stream: Array<Dismantling_Plans>;
  /** fetch data from the table: "edi_delivery_note_types" using primary key columns */
  ediDeliveryNoteTypeByPk?: Maybe<Edi_Delivery_Note_Types>;
  /** An array relationship */
  ediDeliveryNoteTypes: Array<Edi_Delivery_Note_Types>;
  /** fetch aggregated fields from the table: "edi_delivery_note_types" */
  ediDeliveryNoteTypesAggregate: Edi_Delivery_Note_Types_Aggregate;
  /** fetch data from the table: "edi_invoice_types" using primary key columns */
  ediInvoiceTypeByPk?: Maybe<Edi_Invoice_Types>;
  /** An array relationship */
  ediInvoiceTypes: Array<Edi_Invoice_Types>;
  /** fetch aggregated fields from the table: "edi_invoice_types" */
  ediInvoiceTypesAggregate: Edi_Invoice_Types_Aggregate;
  /** fetch data from the table in a streaming manner: "edi_delivery_note_types" */
  edi_delivery_note_types_stream: Array<Edi_Delivery_Note_Types>;
  /** fetch data from the table in a streaming manner: "edi_invoice_types" */
  edi_invoice_types_stream: Array<Edi_Invoice_Types>;
  /** fetch data from the table: "emails" using primary key columns */
  emailByPk?: Maybe<Emails>;
  /** An array relationship */
  emails: Array<Emails>;
  /** fetch aggregated fields from the table: "emails" */
  emailsAggregate: Emails_Aggregate;
  /** fetch data from the table in a streaming manner: "emails" */
  emails_stream: Array<Emails>;
  /** fetch data from the table: "environment_state" using primary key columns */
  environmentStateByPk?: Maybe<Environment_State>;
  /** fetch data from the table: "environment_state" */
  environmentStates: Array<Environment_State>;
  /** fetch aggregated fields from the table: "environment_state" */
  environmentStatesAggregate: Environment_State_Aggregate;
  /** fetch data from the table in a streaming manner: "environment_state" */
  environment_state_stream: Array<Environment_State>;
  /** fetch data from the table: "exchange_rates" using primary key columns */
  exchangeRateByPk?: Maybe<Exchange_Rates>;
  /** An array relationship */
  exchangeRates: Array<Exchange_Rates>;
  /** fetch aggregated fields from the table: "exchange_rates" */
  exchangeRatesAggregate: Exchange_Rates_Aggregate;
  /** execute function "exchange_rates_for_day" which returns "exchange_rates" */
  exchange_rates_for_day: Array<Exchange_Rates>;
  /** execute function "exchange_rates_for_day" and query aggregates on result of table type "exchange_rates" */
  exchange_rates_for_day_aggregate: Exchange_Rates_Aggregate;
  /** fetch data from the table in a streaming manner: "exchange_rates" */
  exchange_rates_stream: Array<Exchange_Rates>;
  /** fetch data from the table: "external_errors" using primary key columns */
  externalErrorByPk?: Maybe<External_Errors>;
  /** fetch data from the table: "external_errors" */
  externalErrors: Array<External_Errors>;
  /** fetch aggregated fields from the table: "external_errors" */
  externalErrorsAggregate: External_Errors_Aggregate;
  /** fetch data from the table in a streaming manner: "external_errors" */
  external_errors_stream: Array<External_Errors>;
  /** execute function "framework_agreements_within_range" which returns "supplier_orders" */
  framework_agreements_within_range: Array<Supplier_Orders>;
  /** execute function "framework_agreements_within_range" and query aggregates on result of table type "supplier_orders" */
  framework_agreements_within_range_aggregate: Supplier_Orders_Aggregate;
  /** An array relationship */
  fullWorkCalendars: Array<Full_Work_Calendar>;
  /** fetch aggregated fields from the table: "full_work_calendar" */
  fullWorkCalendarsAggregate: Full_Work_Calendar_Aggregate;
  /** fetch data from the table in a streaming manner: "full_work_calendar" */
  full_work_calendar_stream: Array<Full_Work_Calendar>;
  /** fetch data from the table: "gender" using primary key columns */
  genderByPk?: Maybe<Gender>;
  /** fetch data from the table in a streaming manner: "gender" */
  gender_stream: Array<Gender>;
  /** fetch data from the table: "gender" */
  genders: Array<Gender>;
  /** fetch aggregated fields from the table: "gender" */
  gendersAggregate: Gender_Aggregate;
  /** fetch data from the table: "generate_presigned_url_result" */
  generatePresignedUrlResults: Array<Generate_Presigned_Url_Result>;
  /** fetch aggregated fields from the table: "generate_presigned_url_result" */
  generatePresignedUrlResultsAggregate: Generate_Presigned_Url_Result_Aggregate;
  /** fetch data from the table in a streaming manner: "generate_presigned_url_result" */
  generate_presigned_url_result_stream: Array<Generate_Presigned_Url_Result>;
  /** execute function "get_latest_cutting_patterns_drafts" which returns "cutting_patterns_draft" */
  get_latest_cutting_patterns_drafts: Array<Cutting_Patterns_Draft>;
  /** execute function "get_latest_cutting_patterns_drafts" and query aggregates on result of table type "cutting_patterns_draft" */
  get_latest_cutting_patterns_drafts_aggregate: Cutting_Patterns_Draft_Aggregate;
  /** execute function "get_public_tenant_info" which returns "public_tenant_info_result" */
  get_public_tenant_info: Array<Public_Tenant_Info_Result>;
  /** execute function "get_public_tenant_info" and query aggregates on result of table type "public_tenant_info_result" */
  get_public_tenant_info_aggregate: Public_Tenant_Info_Result_Aggregate;
  /** execute function "get_tenant_info_with_token" which returns "tenant_info_with_token_result" */
  get_tenant_info_with_token: Array<Tenant_Info_With_Token_Result>;
  /** execute function "get_tenant_info_with_token" and query aggregates on result of table type "tenant_info_with_token_result" */
  get_tenant_info_with_token_aggregate: Tenant_Info_With_Token_Result_Aggregate;
  /** An array relationship */
  globalSearchResults: Array<Global_Search_Result>;
  /** fetch aggregated fields from the table: "global_search_result" */
  globalSearchResultsAggregate: Global_Search_Result_Aggregate;
  /** execute function "global_search" which returns "global_search_result" */
  global_search: Array<Global_Search_Result>;
  /** execute function "global_search" and query aggregates on result of table type "global_search_result" */
  global_search_aggregate: Global_Search_Result_Aggregate;
  /** fetch data from the table in a streaming manner: "global_search_result" */
  global_search_result_stream: Array<Global_Search_Result>;
  /** fetch data from the table: "gs1_logistic_types" using primary key columns */
  gs1LogisticTypeByPk?: Maybe<Gs1_Logistic_Types>;
  /** An array relationship */
  gs1LogisticTypes: Array<Gs1_Logistic_Types>;
  /** fetch aggregated fields from the table: "gs1_logistic_types" */
  gs1LogisticTypesAggregate: Gs1_Logistic_Types_Aggregate;
  /** fetch data from the table in a streaming manner: "gs1_logistic_types" */
  gs1_logistic_types_stream: Array<Gs1_Logistic_Types>;
  /** fetch data from the table: "hardware_devices" using primary key columns */
  hardwareDeviceByPk?: Maybe<Hardware_Devices>;
  /** fetch data from the table: "hardware_device_connection_types" using primary key columns */
  hardwareDeviceConnectionTypeByPk?: Maybe<Hardware_Device_Connection_Types>;
  /** fetch data from the table: "hardware_device_connection_types" */
  hardwareDeviceConnectionTypes: Array<Hardware_Device_Connection_Types>;
  /** fetch aggregated fields from the table: "hardware_device_connection_types" */
  hardwareDeviceConnectionTypesAggregate: Hardware_Device_Connection_Types_Aggregate;
  /** fetch data from the table: "hardware_device_types" using primary key columns */
  hardwareDeviceTypeByPk?: Maybe<Hardware_Device_Types>;
  /** fetch data from the table: "hardware_device_types" */
  hardwareDeviceTypes: Array<Hardware_Device_Types>;
  /** fetch aggregated fields from the table: "hardware_device_types" */
  hardwareDeviceTypesAggregate: Hardware_Device_Types_Aggregate;
  /** An array relationship */
  hardwareDevices: Array<Hardware_Devices>;
  /** fetch aggregated fields from the table: "hardware_devices" */
  hardwareDevicesAggregate: Hardware_Devices_Aggregate;
  /** fetch data from the table in a streaming manner: "hardware_device_connection_types" */
  hardware_device_connection_types_stream: Array<Hardware_Device_Connection_Types>;
  /** fetch data from the table in a streaming manner: "hardware_device_types" */
  hardware_device_types_stream: Array<Hardware_Device_Types>;
  /** fetch data from the table in a streaming manner: "hardware_devices" */
  hardware_devices_stream: Array<Hardware_Devices>;
  /** fetch data from the table: "hierarchical_goods_incomes" using primary key columns */
  hierarchicalGoodsIncomeByPk?: Maybe<Hierarchical_Goods_Incomes>;
  /** An array relationship */
  hierarchicalGoodsIncomes: Array<Hierarchical_Goods_Incomes>;
  /** fetch aggregated fields from the table: "hierarchical_goods_incomes" */
  hierarchicalGoodsIncomesAggregate: Hierarchical_Goods_Incomes_Aggregate;
  /** fetch data from the table in a streaming manner: "hierarchical_goods_incomes" */
  hierarchical_goods_incomes_stream: Array<Hierarchical_Goods_Incomes>;
  /** fetch data from the table: "images" using primary key columns */
  imageByPk?: Maybe<Images>;
  /** An array relationship */
  images: Array<Images>;
  /** fetch aggregated fields from the table: "images" */
  imagesAggregate: Images_Aggregate;
  /** fetch data from the table in a streaming manner: "images" */
  images_stream: Array<Images>;
  /** An array relationship */
  inventoryDailyPrognosisResults: Array<Inventory_Daily_Prognosis_Results>;
  /** fetch aggregated fields from the table: "inventory_daily_prognosis_results" */
  inventoryDailyPrognosisResultsAggregate: Inventory_Daily_Prognosis_Results_Aggregate;
  /** An array relationship */
  inventoryDailyUsageResults: Array<Inventory_Daily_Usage_Results>;
  /** fetch aggregated fields from the table: "inventory_daily_usage_results" */
  inventoryDailyUsageResultsAggregate: Inventory_Daily_Usage_Results_Aggregate;
  /** An array relationship */
  inventoryItems: Array<Inventory_Items>;
  /** fetch aggregated fields from the table: "inventory_items" */
  inventoryItemsAggregate: Inventory_Items_Aggregate;
  /** An array relationship */
  inventoryItemsByLocations: Array<Inventory_Items_By_Location>;
  /** fetch aggregated fields from the table: "inventory_items_by_location" */
  inventoryItemsByLocationsAggregate: Inventory_Items_By_Location_Aggregate;
  /** execute function "inventory_daily_prognosis" which returns "inventory_daily_prognosis_results" */
  inventory_daily_prognosis: Array<Inventory_Daily_Prognosis_Results>;
  /** execute function "inventory_daily_prognosis" and query aggregates on result of table type "inventory_daily_prognosis_results" */
  inventory_daily_prognosis_aggregate: Inventory_Daily_Prognosis_Results_Aggregate;
  /** fetch data from the table in a streaming manner: "inventory_daily_prognosis_results" */
  inventory_daily_prognosis_results_stream: Array<Inventory_Daily_Prognosis_Results>;
  /** fetch data from the table in a streaming manner: "inventory_daily_usage_results" */
  inventory_daily_usage_results_stream: Array<Inventory_Daily_Usage_Results>;
  /** fetch data from the table in a streaming manner: "inventory_items_by_location" */
  inventory_items_by_location_stream: Array<Inventory_Items_By_Location>;
  /** fetch data from the table in a streaming manner: "inventory_items" */
  inventory_items_stream: Array<Inventory_Items>;
  /** An array relationship */
  journalEntries: Array<Journal_Entries>;
  /** fetch aggregated fields from the table: "journal_entries" */
  journalEntriesAggregate: Journal_Entries_Aggregate;
  /** fetch data from the table: "journal_entries" using primary key columns */
  journalEntryByPk?: Maybe<Journal_Entries>;
  /** fetch data from the table in a streaming manner: "journal_entries" */
  journal_entries_stream: Array<Journal_Entries>;
  /** fetch data from the table: "jscript_templates" using primary key columns */
  jscriptTemplateByPk?: Maybe<Jscript_Templates>;
  /** fetch data from the table: "jscript_template_names" using primary key columns */
  jscriptTemplateNameByPk?: Maybe<Jscript_Template_Names>;
  /** fetch data from the table: "jscript_template_names" */
  jscriptTemplateNames: Array<Jscript_Template_Names>;
  /** fetch aggregated fields from the table: "jscript_template_names" */
  jscriptTemplateNamesAggregate: Jscript_Template_Names_Aggregate;
  /** An array relationship */
  jscriptTemplates: Array<Jscript_Templates>;
  /** fetch aggregated fields from the table: "jscript_templates" */
  jscriptTemplatesAggregate: Jscript_Templates_Aggregate;
  /** fetch data from the table: "jscript_templates_logged_actions" */
  jscriptTemplatesLoggedActions: Array<Jscript_Templates_Logged_Actions>;
  /** fetch aggregated fields from the table: "jscript_templates_logged_actions" */
  jscriptTemplatesLoggedActionsAggregate: Jscript_Templates_Logged_Actions_Aggregate;
  /** fetch data from the table in a streaming manner: "jscript_template_names" */
  jscript_template_names_stream: Array<Jscript_Template_Names>;
  /** fetch data from the table in a streaming manner: "jscript_templates_logged_actions" */
  jscript_templates_logged_actions_stream: Array<Jscript_Templates_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "jscript_templates" */
  jscript_templates_stream: Array<Jscript_Templates>;
  /** fetch data from the table: "languages" using primary key columns */
  languageByPk?: Maybe<Languages>;
  /** fetch data from the table: "languages" */
  languages: Array<Languages>;
  /** fetch aggregated fields from the table: "languages" */
  languagesAggregate: Languages_Aggregate;
  /** fetch data from the table in a streaming manner: "languages" */
  languages_stream: Array<Languages>;
  /** fetch data from the table: "leichmehl_templates" using primary key columns */
  leichmehlTemplateByPk?: Maybe<Leichmehl_Templates>;
  /** fetch data from the table: "leichmehl_template_types" using primary key columns */
  leichmehlTemplateTypeByPk?: Maybe<Leichmehl_Template_Types>;
  /** An array relationship */
  leichmehlTemplateTypes: Array<Leichmehl_Template_Types>;
  /** fetch aggregated fields from the table: "leichmehl_template_types" */
  leichmehlTemplateTypesAggregate: Leichmehl_Template_Types_Aggregate;
  /** fetch data from the table: "leichmehl_template_types_logged_actions" */
  leichmehlTemplateTypesLoggedActions: Array<Leichmehl_Template_Types_Logged_Actions>;
  /** fetch aggregated fields from the table: "leichmehl_template_types_logged_actions" */
  leichmehlTemplateTypesLoggedActionsAggregate: Leichmehl_Template_Types_Logged_Actions_Aggregate;
  /** An array relationship */
  leichmehlTemplates: Array<Leichmehl_Templates>;
  /** fetch aggregated fields from the table: "leichmehl_templates" */
  leichmehlTemplatesAggregate: Leichmehl_Templates_Aggregate;
  /** fetch data from the table: "leichmehl_templates_logged_actions" */
  leichmehlTemplatesLoggedActions: Array<Leichmehl_Templates_Logged_Actions>;
  /** fetch aggregated fields from the table: "leichmehl_templates_logged_actions" */
  leichmehlTemplatesLoggedActionsAggregate: Leichmehl_Templates_Logged_Actions_Aggregate;
  /** fetch data from the table in a streaming manner: "leichmehl_template_types_logged_actions" */
  leichmehl_template_types_logged_actions_stream: Array<Leichmehl_Template_Types_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "leichmehl_template_types" */
  leichmehl_template_types_stream: Array<Leichmehl_Template_Types>;
  /** fetch data from the table in a streaming manner: "leichmehl_templates_logged_actions" */
  leichmehl_templates_logged_actions_stream: Array<Leichmehl_Templates_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "leichmehl_templates" */
  leichmehl_templates_stream: Array<Leichmehl_Templates>;
  /** An array relationship */
  listPurchasedProductsResults: Array<List_Purchased_Products_Result>;
  /** fetch aggregated fields from the table: "list_purchased_products_result" */
  listPurchasedProductsResultsAggregate: List_Purchased_Products_Result_Aggregate;
  /** execute function "list_container_inventory_by_customer" which returns "inventory_items" */
  list_container_inventory_by_customer: Array<Inventory_Items>;
  /** execute function "list_container_inventory_by_customer" and query aggregates on result of table type "inventory_items" */
  list_container_inventory_by_customer_aggregate: Inventory_Items_Aggregate;
  /** execute function "list_inventory" which returns "inventory_items" */
  list_inventory: Array<Inventory_Items>;
  /** execute function "list_inventory" and query aggregates on result of table type "inventory_items" */
  list_inventory_aggregate: Inventory_Items_Aggregate;
  /** execute function "list_inventory_by_location" which returns "inventory_items_by_location" */
  list_inventory_by_location: Array<Inventory_Items_By_Location>;
  /** execute function "list_inventory_by_location" and query aggregates on result of table type "inventory_items_by_location" */
  list_inventory_by_location_aggregate: Inventory_Items_By_Location_Aggregate;
  /** execute function "list_inventory_by_location_with_product_search" which returns "inventory_items_by_location" */
  list_inventory_by_location_with_product_search: Array<Inventory_Items_By_Location>;
  /** execute function "list_inventory_by_location_with_product_search" and query aggregates on result of table type "inventory_items_by_location" */
  list_inventory_by_location_with_product_search_aggregate: Inventory_Items_By_Location_Aggregate;
  /** execute function "list_ordered_customer_order_containers" which returns "inventory_items" */
  list_ordered_customer_order_containers: Array<Inventory_Items>;
  /** execute function "list_ordered_customer_order_containers" and query aggregates on result of table type "inventory_items" */
  list_ordered_customer_order_containers_aggregate: Inventory_Items_Aggregate;
  /** fetch data from the table in a streaming manner: "list_purchased_products_result" */
  list_purchased_products_result_stream: Array<List_Purchased_Products_Result>;
  /** execute function "list_purchased_products_with_inventory" which returns "list_purchased_products_result" */
  list_purchased_products_with_inventory: Array<List_Purchased_Products_Result>;
  /** execute function "list_purchased_products_with_inventory" and query aggregates on result of table type "list_purchased_products_result" */
  list_purchased_products_with_inventory_aggregate: List_Purchased_Products_Result_Aggregate;
  /** fetch data from the table: "lot_numbers" using primary key columns */
  lotNumberByPk?: Maybe<Lot_Numbers>;
  /** fetch data from the table: "lot_number_products" using primary key columns */
  lotNumberProductByPk?: Maybe<Lot_Number_Products>;
  /** An array relationship */
  lotNumberProducts: Array<Lot_Number_Products>;
  /** fetch aggregated fields from the table: "lot_number_products" */
  lotNumberProductsAggregate: Lot_Number_Products_Aggregate;
  /** An array relationship */
  lotNumbers: Array<Lot_Numbers>;
  /** fetch aggregated fields from the table: "lot_numbers" */
  lotNumbersAggregate: Lot_Numbers_Aggregate;
  /** fetch data from the table in a streaming manner: "lot_number_products" */
  lot_number_products_stream: Array<Lot_Number_Products>;
  /** fetch data from the table in a streaming manner: "lot_numbers" */
  lot_numbers_stream: Array<Lot_Numbers>;
  /** fetch data from the table: "my_user" */
  myUsers: Array<My_User>;
  /** fetch aggregated fields from the table: "my_user" */
  myUsersAggregate: My_User_Aggregate;
  /** fetch data from the table in a streaming manner: "my_user" */
  my_user_stream: Array<My_User>;
  /** fetch data from the table: "next_dismantling_plans" */
  nextDismantlingPlans: Array<Next_Dismantling_Plans>;
  /** fetch aggregated fields from the table: "next_dismantling_plans" */
  nextDismantlingPlansAggregate: Next_Dismantling_Plans_Aggregate;
  /** fetch data from the table in a streaming manner: "next_dismantling_plans" */
  next_dismantling_plans_stream: Array<Next_Dismantling_Plans>;
  /** fetch data from the table: "notifications" using primary key columns */
  notificationByPk?: Maybe<Notifications>;
  /** fetch data from the table: "notification_types" using primary key columns */
  notificationTypeByPk?: Maybe<Notification_Types>;
  /** fetch data from the table: "notification_types" */
  notificationTypes: Array<Notification_Types>;
  /** fetch aggregated fields from the table: "notification_types" */
  notificationTypesAggregate: Notification_Types_Aggregate;
  /** fetch data from the table in a streaming manner: "notification_types" */
  notification_types_stream: Array<Notification_Types>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** fetch aggregated fields from the table: "notifications" */
  notificationsAggregate: Notifications_Aggregate;
  /** fetch data from the table in a streaming manner: "notifications" */
  notifications_stream: Array<Notifications>;
  /** execute function "output_cutting_pattern_products" which returns "products" */
  output_cutting_pattern_products: Array<Products>;
  /** execute function "output_cutting_pattern_products" and query aggregates on result of table type "products" */
  output_cutting_pattern_products_aggregate: Products_Aggregate;
  /** An array relationship */
  partialDeliveries: Array<Partial_Deliveries>;
  /** fetch aggregated fields from the table: "partial_deliveries" */
  partialDeliveriesAggregate: Partial_Deliveries_Aggregate;
  /** fetch data from the table: "partial_deliveries" using primary key columns */
  partialDeliveryByPk?: Maybe<Partial_Deliveries>;
  /** An array relationship */
  partialDeliveryLoggedActions: Array<Partial_Delivery_Logged_Actions>;
  /** fetch aggregated fields from the table: "partial_delivery_logged_actions" */
  partialDeliveryLoggedActionsAggregate: Partial_Delivery_Logged_Actions_Aggregate;
  /** fetch data from the table in a streaming manner: "partial_deliveries" */
  partial_deliveries_stream: Array<Partial_Deliveries>;
  /** fetch data from the table in a streaming manner: "partial_delivery_logged_actions" */
  partial_delivery_logged_actions_stream: Array<Partial_Delivery_Logged_Actions>;
  /** fetch data from the table: "phones" using primary key columns */
  phoneByPk?: Maybe<Phones>;
  /** An array relationship */
  phones: Array<Phones>;
  /** fetch aggregated fields from the table: "phones" */
  phonesAggregate: Phones_Aggregate;
  /** fetch data from the table in a streaming manner: "phones" */
  phones_stream: Array<Phones>;
  /** fetch data from the table: "picked_items" using primary key columns */
  pickedItemByPk?: Maybe<Picked_Items>;
  /** fetch data from the table: "picked_item_containers" using primary key columns */
  pickedItemContainerByPk?: Maybe<Picked_Item_Containers>;
  /** An array relationship */
  pickedItemContainers: Array<Picked_Item_Containers>;
  /** fetch aggregated fields from the table: "picked_item_containers" */
  pickedItemContainersAggregate: Picked_Item_Containers_Aggregate;
  /** An array relationship */
  pickedItemLoggedActions: Array<Picked_Item_Logged_Actions>;
  /** fetch aggregated fields from the table: "picked_item_logged_actions" */
  pickedItemLoggedActionsAggregate: Picked_Item_Logged_Actions_Aggregate;
  /** fetch data from the table: "picked_item_states" using primary key columns */
  pickedItemStateByPk?: Maybe<Picked_Item_States>;
  /** fetch data from the table: "picked_item_state_transitions" using primary key columns */
  pickedItemStateTransitionByPk?: Maybe<Picked_Item_State_Transitions>;
  /** fetch data from the table: "picked_item_state_transitions" */
  pickedItemStateTransitions: Array<Picked_Item_State_Transitions>;
  /** fetch aggregated fields from the table: "picked_item_state_transitions" */
  pickedItemStateTransitionsAggregate: Picked_Item_State_Transitions_Aggregate;
  /** fetch data from the table: "picked_item_states" */
  pickedItemStates: Array<Picked_Item_States>;
  /** fetch aggregated fields from the table: "picked_item_states" */
  pickedItemStatesAggregate: Picked_Item_States_Aggregate;
  /** An array relationship */
  pickedItems: Array<Picked_Items>;
  /** fetch aggregated fields from the table: "picked_items" */
  pickedItemsAggregate: Picked_Items_Aggregate;
  /** fetch data from the table in a streaming manner: "picked_item_containers" */
  picked_item_containers_stream: Array<Picked_Item_Containers>;
  /** fetch data from the table in a streaming manner: "picked_item_logged_actions" */
  picked_item_logged_actions_stream: Array<Picked_Item_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "picked_item_state_transitions" */
  picked_item_state_transitions_stream: Array<Picked_Item_State_Transitions>;
  /** fetch data from the table in a streaming manner: "picked_item_states" */
  picked_item_states_stream: Array<Picked_Item_States>;
  /** fetch data from the table in a streaming manner: "picked_items" */
  picked_items_stream: Array<Picked_Items>;
  /** An array relationship */
  pickingCategories: Array<Picking_Categories>;
  /** fetch aggregated fields from the table: "picking_categories" */
  pickingCategoriesAggregate: Picking_Categories_Aggregate;
  /** fetch data from the table: "picking_categories" using primary key columns */
  pickingCategoryByPk?: Maybe<Picking_Categories>;
  /** fetch data from the table: "picking_deviation_reasons" using primary key columns */
  pickingDeviationReasonByPk?: Maybe<Picking_Deviation_Reasons>;
  /** An array relationship */
  pickingDeviationReasons: Array<Picking_Deviation_Reasons>;
  /** fetch aggregated fields from the table: "picking_deviation_reasons" */
  pickingDeviationReasonsAggregate: Picking_Deviation_Reasons_Aggregate;
  /** fetch data from the table in a streaming manner: "picking_categories" */
  picking_categories_stream: Array<Picking_Categories>;
  /** fetch data from the table in a streaming manner: "picking_deviation_reasons" */
  picking_deviation_reasons_stream: Array<Picking_Deviation_Reasons>;
  /** execute function "picking_sorted_customer_orders" which returns "customer_orders" */
  picking_sorted_customer_orders: Array<Customer_Orders>;
  /** execute function "picking_sorted_customer_orders" and query aggregates on result of table type "customer_orders" */
  picking_sorted_customer_orders_aggregate: Customer_Orders_Aggregate;
  /** fetch data from the table: "postings" using primary key columns */
  postingByPk?: Maybe<Postings>;
  /** An array relationship */
  postings: Array<Postings>;
  /** fetch aggregated fields from the table: "postings" */
  postingsAggregate: Postings_Aggregate;
  /** fetch data from the table in a streaming manner: "postings" */
  postings_stream: Array<Postings>;
  /** fetch data from the table: "price_lists" using primary key columns */
  priceListByPk?: Maybe<Price_Lists>;
  /** An array relationship */
  priceLists: Array<Price_Lists>;
  /** fetch aggregated fields from the table: "price_lists" */
  priceListsAggregate: Price_Lists_Aggregate;
  /** fetch data from the table in a streaming manner: "price_lists" */
  price_lists_stream: Array<Price_Lists>;
  /** fetch data from the table: "product_allowed_containers" using primary key columns */
  productAllowedContainerByPk?: Maybe<Product_Allowed_Containers>;
  /** An array relationship */
  productAllowedContainers: Array<Product_Allowed_Containers>;
  /** fetch aggregated fields from the table: "product_allowed_containers" */
  productAllowedContainersAggregate: Product_Allowed_Containers_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  productByPk?: Maybe<Products>;
  /** fetch data from the table: "product_groups" using primary key columns */
  productGroupByPk?: Maybe<Product_Groups>;
  /** fetch data from the table: "product_group_members" using primary key columns */
  productGroupMemberByPk?: Maybe<Product_Group_Members>;
  /** An array relationship */
  productGroupMembers: Array<Product_Group_Members>;
  /** fetch aggregated fields from the table: "product_group_members" */
  productGroupMembersAggregate: Product_Group_Members_Aggregate;
  /** fetch data from the table: "product_group_states" using primary key columns */
  productGroupStateByPk?: Maybe<Product_Group_States>;
  /** fetch data from the table: "product_group_state_transitions" using primary key columns */
  productGroupStateTransitionByPk?: Maybe<Product_Group_State_Transitions>;
  /** fetch data from the table: "product_group_state_transitions" */
  productGroupStateTransitions: Array<Product_Group_State_Transitions>;
  /** fetch aggregated fields from the table: "product_group_state_transitions" */
  productGroupStateTransitionsAggregate: Product_Group_State_Transitions_Aggregate;
  /** fetch data from the table: "product_group_states" */
  productGroupStates: Array<Product_Group_States>;
  /** fetch aggregated fields from the table: "product_group_states" */
  productGroupStatesAggregate: Product_Group_States_Aggregate;
  /** fetch data from the table: "product_group_types" using primary key columns */
  productGroupTypeByPk?: Maybe<Product_Group_Types>;
  /** fetch data from the table: "product_group_types" */
  productGroupTypes: Array<Product_Group_Types>;
  /** fetch aggregated fields from the table: "product_group_types" */
  productGroupTypesAggregate: Product_Group_Types_Aggregate;
  /** An array relationship */
  productGroups: Array<Product_Groups>;
  /** fetch aggregated fields from the table: "product_groups" */
  productGroupsAggregate: Product_Groups_Aggregate;
  /** fetch data from the table: "product_label_settings" using primary key columns */
  productLabelSettingByPk?: Maybe<Product_Label_Settings>;
  /** An array relationship */
  productLabelSettings: Array<Product_Label_Settings>;
  /** fetch aggregated fields from the table: "product_label_settings" */
  productLabelSettingsAggregate: Product_Label_Settings_Aggregate;
  /** An array relationship */
  productLoggedActions: Array<Product_Logged_Actions>;
  /** fetch aggregated fields from the table: "product_logged_actions" */
  productLoggedActionsAggregate: Product_Logged_Actions_Aggregate;
  /** fetch data from the table: "product_packaging_configurations" using primary key columns */
  productPackagingConfigurationByPk?: Maybe<Product_Packaging_Configurations>;
  /** An array relationship */
  productPackagingConfigurations: Array<Product_Packaging_Configurations>;
  /** fetch aggregated fields from the table: "product_packaging_configurations" */
  productPackagingConfigurationsAggregate: Product_Packaging_Configurations_Aggregate;
  /** fetch data from the table: "product_packaging_configurations_logged_actions" */
  productPackagingConfigurationsLoggedActions: Array<Product_Packaging_Configurations_Logged_Actions>;
  /** fetch aggregated fields from the table: "product_packaging_configurations_logged_actions" */
  productPackagingConfigurationsLoggedActionsAggregate: Product_Packaging_Configurations_Logged_Actions_Aggregate;
  /** fetch data from the table: "product_recipes" using primary key columns */
  productRecipeByPk?: Maybe<Product_Recipes>;
  /** fetch data from the table: "product_recipe_ingredients" using primary key columns */
  productRecipeIngredientByPk?: Maybe<Product_Recipe_Ingredients>;
  /** An array relationship */
  productRecipeIngredients: Array<Product_Recipe_Ingredients>;
  /** fetch aggregated fields from the table: "product_recipe_ingredients" */
  productRecipeIngredientsAggregate: Product_Recipe_Ingredients_Aggregate;
  /** fetch data from the table: "product_recipe_states" using primary key columns */
  productRecipeStateByPk?: Maybe<Product_Recipe_States>;
  /** fetch data from the table: "product_recipe_state_transitions" using primary key columns */
  productRecipeStateTransitionByPk?: Maybe<Product_Recipe_State_Transitions>;
  /** fetch data from the table: "product_recipe_state_transitions" */
  productRecipeStateTransitions: Array<Product_Recipe_State_Transitions>;
  /** fetch aggregated fields from the table: "product_recipe_state_transitions" */
  productRecipeStateTransitionsAggregate: Product_Recipe_State_Transitions_Aggregate;
  /** fetch data from the table: "product_recipe_states" */
  productRecipeStates: Array<Product_Recipe_States>;
  /** fetch aggregated fields from the table: "product_recipe_states" */
  productRecipeStatesAggregate: Product_Recipe_States_Aggregate;
  /** fetch data from the table: "product_recipe_steps" using primary key columns */
  productRecipeStepByPk?: Maybe<Product_Recipe_Steps>;
  /** An array relationship */
  productRecipeSteps: Array<Product_Recipe_Steps>;
  /** fetch aggregated fields from the table: "product_recipe_steps" */
  productRecipeStepsAggregate: Product_Recipe_Steps_Aggregate;
  /** An array relationship */
  productRecipes: Array<Product_Recipes>;
  /** fetch aggregated fields from the table: "product_recipes" */
  productRecipesAggregate: Product_Recipes_Aggregate;
  /** fetch data from the table: "product_s3_objects" using primary key columns */
  productS3ObjectByPk?: Maybe<Product_S3_Objects>;
  /** An array relationship */
  productS3Objects: Array<Product_S3_Objects>;
  /** fetch aggregated fields from the table: "product_s3_objects" */
  productS3ObjectsAggregate: Product_S3_Objects_Aggregate;
  /** fetch data from the table: "product_sales_channel_members" using primary key columns */
  productSalesChannelMemberByPk?: Maybe<Product_Sales_Channel_Members>;
  /** An array relationship */
  productSalesChannelMembers: Array<Product_Sales_Channel_Members>;
  /** fetch aggregated fields from the table: "product_sales_channel_members" */
  productSalesChannelMembersAggregate: Product_Sales_Channel_Members_Aggregate;
  /** fetch data from the table: "product_states" using primary key columns */
  productStateByPk?: Maybe<Product_States>;
  /** fetch data from the table: "product_state_transitions" using primary key columns */
  productStateTransitionByPk?: Maybe<Product_State_Transitions>;
  /** fetch data from the table: "product_state_transitions" */
  productStateTransitions: Array<Product_State_Transitions>;
  /** fetch aggregated fields from the table: "product_state_transitions" */
  productStateTransitionsAggregate: Product_State_Transitions_Aggregate;
  /** fetch data from the table: "product_states" */
  productStates: Array<Product_States>;
  /** fetch aggregated fields from the table: "product_states" */
  productStatesAggregate: Product_States_Aggregate;
  /** fetch data from the table: "product_stock_locations" using primary key columns */
  productStockLocationByPk?: Maybe<Product_Stock_Locations>;
  /** An array relationship */
  productStockLocations: Array<Product_Stock_Locations>;
  /** fetch aggregated fields from the table: "product_stock_locations" */
  productStockLocationsAggregate: Product_Stock_Locations_Aggregate;
  /** fetch data from the table: "product_suppliers" using primary key columns */
  productSupplierByPk?: Maybe<Product_Suppliers>;
  /** fetch data from the table: "product_supplier_states" using primary key columns */
  productSupplierStateByPk?: Maybe<Product_Supplier_States>;
  /** fetch data from the table: "product_supplier_state_transitions" using primary key columns */
  productSupplierStateTransitionByPk?: Maybe<Product_Supplier_State_Transitions>;
  /** fetch data from the table: "product_supplier_state_transitions" */
  productSupplierStateTransitions: Array<Product_Supplier_State_Transitions>;
  /** fetch aggregated fields from the table: "product_supplier_state_transitions" */
  productSupplierStateTransitionsAggregate: Product_Supplier_State_Transitions_Aggregate;
  /** fetch data from the table: "product_supplier_states" */
  productSupplierStates: Array<Product_Supplier_States>;
  /** fetch aggregated fields from the table: "product_supplier_states" */
  productSupplierStatesAggregate: Product_Supplier_States_Aggregate;
  /** An array relationship */
  productSuppliers: Array<Product_Suppliers>;
  /** fetch aggregated fields from the table: "product_suppliers" */
  productSuppliersAggregate: Product_Suppliers_Aggregate;
  /** fetch data from the table: "product_units" using primary key columns */
  productUnitByPk?: Maybe<Product_Units>;
  /** fetch data from the table: "product_unit_conversion_result" */
  productUnitConversionResults: Array<Product_Unit_Conversion_Result>;
  /** fetch aggregated fields from the table: "product_unit_conversion_result" */
  productUnitConversionResultsAggregate: Product_Unit_Conversion_Result_Aggregate;
  /** fetch data from the table: "product_unit_sales_price_config" using primary key columns */
  productUnitSalesPriceConfigByPk?: Maybe<Product_Unit_Sales_Price_Config>;
  /** An array relationship */
  productUnitSalesPriceConfigs: Array<Product_Unit_Sales_Price_Config>;
  /** fetch aggregated fields from the table: "product_unit_sales_price_config" */
  productUnitSalesPriceConfigsAggregate: Product_Unit_Sales_Price_Config_Aggregate;
  /** fetch data from the table: "product_unit_states" using primary key columns */
  productUnitStateByPk?: Maybe<Product_Unit_States>;
  /** fetch data from the table: "product_unit_state_transitions" using primary key columns */
  productUnitStateTransitionByPk?: Maybe<Product_Unit_State_Transitions>;
  /** fetch data from the table: "product_unit_state_transitions" */
  productUnitStateTransitions: Array<Product_Unit_State_Transitions>;
  /** fetch aggregated fields from the table: "product_unit_state_transitions" */
  productUnitStateTransitionsAggregate: Product_Unit_State_Transitions_Aggregate;
  /** fetch data from the table: "product_unit_states" */
  productUnitStates: Array<Product_Unit_States>;
  /** fetch aggregated fields from the table: "product_unit_states" */
  productUnitStatesAggregate: Product_Unit_States_Aggregate;
  /** An array relationship */
  productUnits: Array<Product_Units>;
  /** fetch aggregated fields from the table: "product_units" */
  productUnitsAggregate: Product_Units_Aggregate;
  /** fetch data from the table in a streaming manner: "product_allowed_containers" */
  product_allowed_containers_stream: Array<Product_Allowed_Containers>;
  /** fetch data from the table in a streaming manner: "product_group_members" */
  product_group_members_stream: Array<Product_Group_Members>;
  /** fetch data from the table in a streaming manner: "product_group_state_transitions" */
  product_group_state_transitions_stream: Array<Product_Group_State_Transitions>;
  /** fetch data from the table in a streaming manner: "product_group_states" */
  product_group_states_stream: Array<Product_Group_States>;
  /** fetch data from the table in a streaming manner: "product_group_types" */
  product_group_types_stream: Array<Product_Group_Types>;
  /** fetch data from the table in a streaming manner: "product_groups" */
  product_groups_stream: Array<Product_Groups>;
  /** fetch data from the table in a streaming manner: "product_label_settings" */
  product_label_settings_stream: Array<Product_Label_Settings>;
  /** fetch data from the table in a streaming manner: "product_logged_actions" */
  product_logged_actions_stream: Array<Product_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "product_packaging_configurations_logged_actions" */
  product_packaging_configurations_logged_actions_stream: Array<Product_Packaging_Configurations_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "product_packaging_configurations" */
  product_packaging_configurations_stream: Array<Product_Packaging_Configurations>;
  /** fetch data from the table in a streaming manner: "product_recipe_ingredients" */
  product_recipe_ingredients_stream: Array<Product_Recipe_Ingredients>;
  /** fetch data from the table in a streaming manner: "product_recipe_state_transitions" */
  product_recipe_state_transitions_stream: Array<Product_Recipe_State_Transitions>;
  /** fetch data from the table in a streaming manner: "product_recipe_states" */
  product_recipe_states_stream: Array<Product_Recipe_States>;
  /** fetch data from the table in a streaming manner: "product_recipe_steps" */
  product_recipe_steps_stream: Array<Product_Recipe_Steps>;
  /** fetch data from the table in a streaming manner: "product_recipes" */
  product_recipes_stream: Array<Product_Recipes>;
  /** fetch data from the table in a streaming manner: "product_s3_objects" */
  product_s3_objects_stream: Array<Product_S3_Objects>;
  /** fetch data from the table in a streaming manner: "product_sales_channel_members" */
  product_sales_channel_members_stream: Array<Product_Sales_Channel_Members>;
  /** fetch data from the table in a streaming manner: "product_state_transitions" */
  product_state_transitions_stream: Array<Product_State_Transitions>;
  /** fetch data from the table in a streaming manner: "product_states" */
  product_states_stream: Array<Product_States>;
  /** fetch data from the table in a streaming manner: "product_stock_locations" */
  product_stock_locations_stream: Array<Product_Stock_Locations>;
  /** fetch data from the table in a streaming manner: "product_supplier_state_transitions" */
  product_supplier_state_transitions_stream: Array<Product_Supplier_State_Transitions>;
  /** fetch data from the table in a streaming manner: "product_supplier_states" */
  product_supplier_states_stream: Array<Product_Supplier_States>;
  /** fetch data from the table in a streaming manner: "product_suppliers" */
  product_suppliers_stream: Array<Product_Suppliers>;
  /** execute function "product_unit_conversion" which returns "product_unit_conversion_result" */
  product_unit_conversion: Array<Product_Unit_Conversion_Result>;
  /** execute function "product_unit_conversion" and query aggregates on result of table type "product_unit_conversion_result" */
  product_unit_conversion_aggregate: Product_Unit_Conversion_Result_Aggregate;
  /** fetch data from the table in a streaming manner: "product_unit_conversion_result" */
  product_unit_conversion_result_stream: Array<Product_Unit_Conversion_Result>;
  /** fetch data from the table in a streaming manner: "product_unit_sales_price_config" */
  product_unit_sales_price_config_stream: Array<Product_Unit_Sales_Price_Config>;
  /** fetch data from the table in a streaming manner: "product_unit_state_transitions" */
  product_unit_state_transitions_stream: Array<Product_Unit_State_Transitions>;
  /** fetch data from the table in a streaming manner: "product_unit_states" */
  product_unit_states_stream: Array<Product_Unit_States>;
  /** fetch data from the table in a streaming manner: "product_units" */
  product_units_stream: Array<Product_Units>;
  /** fetch data from the table: "production_sites" using primary key columns */
  productionSiteByPk?: Maybe<Production_Sites>;
  /** fetch data from the table: "production_site_input_stock_locations" using primary key columns */
  productionSiteInputStockLocationByPk?: Maybe<Production_Site_Input_Stock_Locations>;
  /** An array relationship */
  productionSiteInputStockLocations: Array<Production_Site_Input_Stock_Locations>;
  /** fetch aggregated fields from the table: "production_site_input_stock_locations" */
  productionSiteInputStockLocationsAggregate: Production_Site_Input_Stock_Locations_Aggregate;
  /** fetch data from the table: "production_site_output_stock_locations" using primary key columns */
  productionSiteOutputStockLocationByPk?: Maybe<Production_Site_Output_Stock_Locations>;
  /** fetch data from the table: "production_site_output_stock_locations" */
  productionSiteOutputStockLocations: Array<Production_Site_Output_Stock_Locations>;
  /** fetch aggregated fields from the table: "production_site_output_stock_locations" */
  productionSiteOutputStockLocationsAggregate: Production_Site_Output_Stock_Locations_Aggregate;
  /** fetch data from the table: "production_site_product_group_calendar" using primary key columns */
  productionSiteProductGroupCalendarByPk?: Maybe<Production_Site_Product_Group_Calendar>;
  /** An array relationship */
  productionSiteProductGroupCalendars: Array<Production_Site_Product_Group_Calendar>;
  /** fetch aggregated fields from the table: "production_site_product_group_calendar" */
  productionSiteProductGroupCalendarsAggregate: Production_Site_Product_Group_Calendar_Aggregate;
  /** fetch data from the table: "production_site_states" using primary key columns */
  productionSiteStateByPk?: Maybe<Production_Site_States>;
  /** fetch data from the table: "production_site_states" */
  productionSiteStates: Array<Production_Site_States>;
  /** fetch aggregated fields from the table: "production_site_states" */
  productionSiteStatesAggregate: Production_Site_States_Aggregate;
  /** An array relationship */
  productionSites: Array<Production_Sites>;
  /** fetch aggregated fields from the table: "production_sites" */
  productionSitesAggregate: Production_Sites_Aggregate;
  /** fetch data from the table in a streaming manner: "production_site_input_stock_locations" */
  production_site_input_stock_locations_stream: Array<Production_Site_Input_Stock_Locations>;
  /** fetch data from the table in a streaming manner: "production_site_output_stock_locations" */
  production_site_output_stock_locations_stream: Array<Production_Site_Output_Stock_Locations>;
  /** fetch data from the table in a streaming manner: "production_site_product_group_calendar" */
  production_site_product_group_calendar_stream: Array<Production_Site_Product_Group_Calendar>;
  /** fetch data from the table in a streaming manner: "production_site_states" */
  production_site_states_stream: Array<Production_Site_States>;
  /** fetch data from the table in a streaming manner: "production_sites" */
  production_sites_stream: Array<Production_Sites>;
  /** An array relationship */
  products: Array<Products>;
  /** fetch aggregated fields from the table: "products" */
  productsAggregate: Products_Aggregate;
  /** execute function "products_pricing" which returns "temporal_products" */
  products_pricing: Array<Temporal_Products>;
  /** execute function "products_pricing" and query aggregates on result of table type "temporal_products" */
  products_pricing_aggregate: Temporal_Products_Aggregate;
  /** fetch data from the table in a streaming manner: "products" */
  products_stream: Array<Products>;
  /** fetch data from the table: "proletarian_archived_jobs" using primary key columns */
  proletarianArchivedJobByPk?: Maybe<Proletarian_Archived_Jobs>;
  /** fetch data from the table: "proletarian_archived_jobs" */
  proletarianArchivedJobs: Array<Proletarian_Archived_Jobs>;
  /** fetch aggregated fields from the table: "proletarian_archived_jobs" */
  proletarianArchivedJobsAggregate: Proletarian_Archived_Jobs_Aggregate;
  /** fetch data from the table: "proletarian_jobs" using primary key columns */
  proletarianJobByPk?: Maybe<Proletarian_Jobs>;
  /** fetch data from the table: "proletarian_jobs" */
  proletarianJobs: Array<Proletarian_Jobs>;
  /** fetch aggregated fields from the table: "proletarian_jobs" */
  proletarianJobsAggregate: Proletarian_Jobs_Aggregate;
  /** fetch data from the table in a streaming manner: "proletarian_archived_jobs" */
  proletarian_archived_jobs_stream: Array<Proletarian_Archived_Jobs>;
  /** fetch data from the table in a streaming manner: "proletarian_jobs" */
  proletarian_jobs_stream: Array<Proletarian_Jobs>;
  /** An array relationship */
  publicLoggedActions: Array<Public_Logged_Actions>;
  /** fetch aggregated fields from the table: "public_logged_actions" */
  publicLoggedActionsAggregate: Public_Logged_Actions_Aggregate;
  /** fetch data from the table: "public_tenant_info_result" */
  publicTenantInfoResults: Array<Public_Tenant_Info_Result>;
  /** fetch aggregated fields from the table: "public_tenant_info_result" */
  publicTenantInfoResultsAggregate: Public_Tenant_Info_Result_Aggregate;
  /** fetch data from the table in a streaming manner: "public_logged_actions" */
  public_logged_actions_stream: Array<Public_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "public_tenant_info_result" */
  public_tenant_info_result_stream: Array<Public_Tenant_Info_Result>;
  /** fetch data from the table: "purchasing_suggestions" using primary key columns */
  purchasingSuggestionByPk?: Maybe<Purchasing_Suggestions>;
  /** fetch data from the table: "purchasing_suggestions" */
  purchasingSuggestions: Array<Purchasing_Suggestions>;
  /** fetch aggregated fields from the table: "purchasing_suggestions" */
  purchasingSuggestionsAggregate: Purchasing_Suggestions_Aggregate;
  /** fetch data from the table in a streaming manner: "purchasing_suggestions" */
  purchasing_suggestions_stream: Array<Purchasing_Suggestions>;
  /** execute function "puspc_with_customer_prices" which returns "product_unit_sales_price_config" */
  puspc_with_customer_prices: Array<Product_Unit_Sales_Price_Config>;
  /** execute function "puspc_with_customer_prices" and query aggregates on result of table type "product_unit_sales_price_config" */
  puspc_with_customer_prices_aggregate: Product_Unit_Sales_Price_Config_Aggregate;
  /** fetch data from the table: "query_monitoring" using primary key columns */
  queryMonitoringByPk?: Maybe<Query_Monitoring>;
  /** fetch data from the table: "query_monitoring" */
  queryMonitorings: Array<Query_Monitoring>;
  /** fetch aggregated fields from the table: "query_monitoring" */
  queryMonitoringsAggregate: Query_Monitoring_Aggregate;
  /** fetch data from the table in a streaming manner: "query_monitoring" */
  query_monitoring_stream: Array<Query_Monitoring>;
  /** fetch data from the table: "roles" using primary key columns */
  roleByPk?: Maybe<Roles>;
  /** fetch data from the table: "role_permissions" */
  rolePermissions: Array<Role_Permissions>;
  /** fetch aggregated fields from the table: "role_permissions" */
  rolePermissionsAggregate: Role_Permissions_Aggregate;
  /** fetch data from the table in a streaming manner: "role_permissions" */
  role_permissions_stream: Array<Role_Permissions>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  rolesAggregate: Roles_Aggregate;
  /** fetch data from the table in a streaming manner: "roles" */
  roles_stream: Array<Roles>;
  /** An array relationship */
  rollingInventories: Array<Rolling_Inventories>;
  /** fetch aggregated fields from the table: "rolling_inventories" */
  rollingInventoriesAggregate: Rolling_Inventories_Aggregate;
  /** fetch data from the table: "rolling_inventory_actors" using primary key columns */
  rollingInventoryActorByPk?: Maybe<Rolling_Inventory_Actors>;
  /** An array relationship */
  rollingInventoryActors: Array<Rolling_Inventory_Actors>;
  /** fetch aggregated fields from the table: "rolling_inventory_actors" */
  rollingInventoryActorsAggregate: Rolling_Inventory_Actors_Aggregate;
  /** fetch data from the table: "rolling_inventories" using primary key columns */
  rollingInventoryByPk?: Maybe<Rolling_Inventories>;
  /** fetch data from the table: "rolling_inventory_items" using primary key columns */
  rollingInventoryItemByPk?: Maybe<Rolling_Inventory_Items>;
  /** An array relationship */
  rollingInventoryItems: Array<Rolling_Inventory_Items>;
  /** fetch aggregated fields from the table: "rolling_inventory_items" */
  rollingInventoryItemsAggregate: Rolling_Inventory_Items_Aggregate;
  /** fetch data from the table: "rolling_inventory_logged_actions" */
  rollingInventoryLoggedActions: Array<Rolling_Inventory_Logged_Actions>;
  /** fetch aggregated fields from the table: "rolling_inventory_logged_actions" */
  rollingInventoryLoggedActionsAggregate: Rolling_Inventory_Logged_Actions_Aggregate;
  /** An array relationship */
  rollingInventoryLotSummaryItems: Array<Rolling_Inventory_Lot_Summary_Items>;
  /** fetch aggregated fields from the table: "rolling_inventory_lot_summary_items" */
  rollingInventoryLotSummaryItemsAggregate: Rolling_Inventory_Lot_Summary_Items_Aggregate;
  /** fetch data from the table: "rolling_inventory_states" using primary key columns */
  rollingInventoryStateByPk?: Maybe<Rolling_Inventory_States>;
  /** fetch data from the table: "rolling_inventory_state_transitions" using primary key columns */
  rollingInventoryStateTransitionByPk?: Maybe<Rolling_Inventory_State_Transitions>;
  /** fetch data from the table: "rolling_inventory_state_transitions" */
  rollingInventoryStateTransitions: Array<Rolling_Inventory_State_Transitions>;
  /** fetch aggregated fields from the table: "rolling_inventory_state_transitions" */
  rollingInventoryStateTransitionsAggregate: Rolling_Inventory_State_Transitions_Aggregate;
  /** fetch data from the table: "rolling_inventory_states" */
  rollingInventoryStates: Array<Rolling_Inventory_States>;
  /** fetch aggregated fields from the table: "rolling_inventory_states" */
  rollingInventoryStatesAggregate: Rolling_Inventory_States_Aggregate;
  /** An array relationship */
  rollingInventorySummaryItems: Array<Rolling_Inventory_Summary_Items>;
  /** fetch aggregated fields from the table: "rolling_inventory_summary_items" */
  rollingInventorySummaryItemsAggregate: Rolling_Inventory_Summary_Items_Aggregate;
  /** fetch data from the table in a streaming manner: "rolling_inventories" */
  rolling_inventories_stream: Array<Rolling_Inventories>;
  /** fetch data from the table in a streaming manner: "rolling_inventory_actors" */
  rolling_inventory_actors_stream: Array<Rolling_Inventory_Actors>;
  /** fetch data from the table in a streaming manner: "rolling_inventory_items" */
  rolling_inventory_items_stream: Array<Rolling_Inventory_Items>;
  /** fetch data from the table in a streaming manner: "rolling_inventory_logged_actions" */
  rolling_inventory_logged_actions_stream: Array<Rolling_Inventory_Logged_Actions>;
  /** execute function "rolling_inventory_lot_summary" which returns "rolling_inventory_lot_summary_items" */
  rolling_inventory_lot_summary: Array<Rolling_Inventory_Lot_Summary_Items>;
  /** execute function "rolling_inventory_lot_summary" and query aggregates on result of table type "rolling_inventory_lot_summary_items" */
  rolling_inventory_lot_summary_aggregate: Rolling_Inventory_Lot_Summary_Items_Aggregate;
  /** fetch data from the table in a streaming manner: "rolling_inventory_lot_summary_items" */
  rolling_inventory_lot_summary_items_stream: Array<Rolling_Inventory_Lot_Summary_Items>;
  /** fetch data from the table in a streaming manner: "rolling_inventory_state_transitions" */
  rolling_inventory_state_transitions_stream: Array<Rolling_Inventory_State_Transitions>;
  /** fetch data from the table in a streaming manner: "rolling_inventory_states" */
  rolling_inventory_states_stream: Array<Rolling_Inventory_States>;
  /** execute function "rolling_inventory_summary" which returns "rolling_inventory_summary_items" */
  rolling_inventory_summary: Array<Rolling_Inventory_Summary_Items>;
  /** execute function "rolling_inventory_summary" and query aggregates on result of table type "rolling_inventory_summary_items" */
  rolling_inventory_summary_aggregate: Rolling_Inventory_Summary_Items_Aggregate;
  /** fetch data from the table in a streaming manner: "rolling_inventory_summary_items" */
  rolling_inventory_summary_items_stream: Array<Rolling_Inventory_Summary_Items>;
  /** execute function "root_cutting_pattern_products" which returns "products" */
  root_cutting_pattern_products: Array<Products>;
  /** execute function "root_cutting_pattern_products" and query aggregates on result of table type "products" */
  root_cutting_pattern_products_aggregate: Products_Aggregate;
  /** execute function "root_cutting_pattern_products_draft" which returns "products" */
  root_cutting_pattern_products_draft: Array<Products>;
  /** execute function "root_cutting_pattern_products_draft" and query aggregates on result of table type "products" */
  root_cutting_pattern_products_draft_aggregate: Products_Aggregate;
  /** execute function "root_cutting_pattern_temporal_products" which returns "temporal_products" */
  root_cutting_pattern_temporal_products: Array<Temporal_Products>;
  /** execute function "root_cutting_pattern_temporal_products" and query aggregates on result of table type "temporal_products" */
  root_cutting_pattern_temporal_products_aggregate: Temporal_Products_Aggregate;
  /** fetch data from the table: "s3_objects" using primary key columns */
  s3ObjectByPk?: Maybe<S3_Objects>;
  /** fetch data from the table: "s3_object_states" using primary key columns */
  s3ObjectStateByPk?: Maybe<S3_Object_States>;
  /** fetch data from the table: "s3_object_state_transitions" using primary key columns */
  s3ObjectStateTransitionByPk?: Maybe<S3_Object_State_Transitions>;
  /** fetch data from the table: "s3_object_state_transitions" */
  s3ObjectStateTransitions: Array<S3_Object_State_Transitions>;
  /** fetch aggregated fields from the table: "s3_object_state_transitions" */
  s3ObjectStateTransitionsAggregate: S3_Object_State_Transitions_Aggregate;
  /** fetch data from the table: "s3_object_states" */
  s3ObjectStates: Array<S3_Object_States>;
  /** fetch aggregated fields from the table: "s3_object_states" */
  s3ObjectStatesAggregate: S3_Object_States_Aggregate;
  /** fetch data from the table: "s3_object_visibilities" */
  s3ObjectVisibilities: Array<S3_Object_Visibilities>;
  /** fetch aggregated fields from the table: "s3_object_visibilities" */
  s3ObjectVisibilitiesAggregate: S3_Object_Visibilities_Aggregate;
  /** fetch data from the table: "s3_object_visibilities" using primary key columns */
  s3ObjectVisibilityByPk?: Maybe<S3_Object_Visibilities>;
  /** An array relationship */
  s3Objects: Array<S3_Objects>;
  /** fetch aggregated fields from the table: "s3_objects" */
  s3ObjectsAggregate: S3_Objects_Aggregate;
  /** fetch data from the table in a streaming manner: "s3_object_state_transitions" */
  s3_object_state_transitions_stream: Array<S3_Object_State_Transitions>;
  /** fetch data from the table in a streaming manner: "s3_object_states" */
  s3_object_states_stream: Array<S3_Object_States>;
  /** fetch data from the table in a streaming manner: "s3_object_visibilities" */
  s3_object_visibilities_stream: Array<S3_Object_Visibilities>;
  /** fetch data from the table in a streaming manner: "s3_objects" */
  s3_objects_stream: Array<S3_Objects>;
  /** fetch data from the table: "sales_channels" using primary key columns */
  salesChannelByPk?: Maybe<Sales_Channels>;
  /** An array relationship */
  salesChannels: Array<Sales_Channels>;
  /** fetch aggregated fields from the table: "sales_channels" */
  salesChannelsAggregate: Sales_Channels_Aggregate;
  /** fetch data from the table: "sales_teams" using primary key columns */
  salesTeamByPk?: Maybe<Sales_Teams>;
  /** fetch data from the table: "sales_team_states" using primary key columns */
  salesTeamStateByPk?: Maybe<Sales_Team_States>;
  /** fetch data from the table: "sales_team_state_transitions" using primary key columns */
  salesTeamStateTransitionByPk?: Maybe<Sales_Team_State_Transitions>;
  /** fetch data from the table: "sales_team_state_transitions" */
  salesTeamStateTransitions: Array<Sales_Team_State_Transitions>;
  /** fetch aggregated fields from the table: "sales_team_state_transitions" */
  salesTeamStateTransitionsAggregate: Sales_Team_State_Transitions_Aggregate;
  /** fetch data from the table: "sales_team_states" */
  salesTeamStates: Array<Sales_Team_States>;
  /** fetch aggregated fields from the table: "sales_team_states" */
  salesTeamStatesAggregate: Sales_Team_States_Aggregate;
  /** fetch data from the table: "sales_team_users" using primary key columns */
  salesTeamUserByPk?: Maybe<Sales_Team_Users>;
  /** An array relationship */
  salesTeamUsers: Array<Sales_Team_Users>;
  /** fetch aggregated fields from the table: "sales_team_users" */
  salesTeamUsersAggregate: Sales_Team_Users_Aggregate;
  /** An array relationship */
  salesTeams: Array<Sales_Teams>;
  /** fetch aggregated fields from the table: "sales_teams" */
  salesTeamsAggregate: Sales_Teams_Aggregate;
  /** fetch data from the table in a streaming manner: "sales_channels" */
  sales_channels_stream: Array<Sales_Channels>;
  /** fetch data from the table in a streaming manner: "sales_team_state_transitions" */
  sales_team_state_transitions_stream: Array<Sales_Team_State_Transitions>;
  /** fetch data from the table in a streaming manner: "sales_team_states" */
  sales_team_states_stream: Array<Sales_Team_States>;
  /** fetch data from the table in a streaming manner: "sales_team_users" */
  sales_team_users_stream: Array<Sales_Team_Users>;
  /** fetch data from the table in a streaming manner: "sales_teams" */
  sales_teams_stream: Array<Sales_Teams>;
  /** fetch data from the table: "salutations" using primary key columns */
  salutationByPk?: Maybe<Salutations>;
  /** fetch data from the table: "salutations" */
  salutations: Array<Salutations>;
  /** fetch aggregated fields from the table: "salutations" */
  salutationsAggregate: Salutations_Aggregate;
  /** fetch data from the table in a streaming manner: "salutations" */
  salutations_stream: Array<Salutations>;
  /** An array relationship */
  searchProductsForCustomerResults: Array<Search_Products_For_Customer_Results>;
  /** fetch aggregated fields from the table: "search_products_for_customer_results" */
  searchProductsForCustomerResultsAggregate: Search_Products_For_Customer_Results_Aggregate;
  /** An array relationship */
  searchPurchasedProductsResults: Array<Search_Purchased_Products_Results>;
  /** fetch aggregated fields from the table: "search_purchased_products_results" */
  searchPurchasedProductsResultsAggregate: Search_Purchased_Products_Results_Aggregate;
  /** execute function "search_contacts" which returns "contacts" */
  search_contacts: Array<Contacts>;
  /** execute function "search_contacts" and query aggregates on result of table type "contacts" */
  search_contacts_aggregate: Contacts_Aggregate;
  /** execute function "search_customer_groups" which returns "customer_groups" */
  search_customer_groups: Array<Customer_Groups>;
  /** execute function "search_customer_groups" and query aggregates on result of table type "customer_groups" */
  search_customer_groups_aggregate: Customer_Groups_Aggregate;
  /** execute function "search_customer_order_item_return_causes" which returns "customer_order_item_return_causes" */
  search_customer_order_item_return_causes: Array<Customer_Order_Item_Return_Causes>;
  /** execute function "search_customer_order_item_return_causes" and query aggregates on result of table type "customer_order_item_return_causes" */
  search_customer_order_item_return_causes_aggregate: Customer_Order_Item_Return_Causes_Aggregate;
  /** execute function "search_customer_orders" which returns "customer_orders" */
  search_customer_orders: Array<Customer_Orders>;
  /** execute function "search_customer_orders" and query aggregates on result of table type "customer_orders" */
  search_customer_orders_aggregate: Customer_Orders_Aggregate;
  /** execute function "search_customers" which returns "customers" */
  search_customers: Array<Customers>;
  /** execute function "search_customers" and query aggregates on result of table type "customers" */
  search_customers_aggregate: Customers_Aggregate;
  /** execute function "search_hardware_devices" which returns "hardware_devices" */
  search_hardware_devices: Array<Hardware_Devices>;
  /** execute function "search_hardware_devices" and query aggregates on result of table type "hardware_devices" */
  search_hardware_devices_aggregate: Hardware_Devices_Aggregate;
  /** execute function "search_lot_numbers" which returns "lot_numbers" */
  search_lot_numbers: Array<Lot_Numbers>;
  /** execute function "search_lot_numbers" and query aggregates on result of table type "lot_numbers" */
  search_lot_numbers_aggregate: Lot_Numbers_Aggregate;
  /** execute function "search_picking_categories" which returns "picking_categories" */
  search_picking_categories: Array<Picking_Categories>;
  /** execute function "search_picking_categories" and query aggregates on result of table type "picking_categories" */
  search_picking_categories_aggregate: Picking_Categories_Aggregate;
  /** execute function "search_product_groups" which returns "product_groups" */
  search_product_groups: Array<Product_Groups>;
  /** execute function "search_product_groups" and query aggregates on result of table type "product_groups" */
  search_product_groups_aggregate: Product_Groups_Aggregate;
  /** execute function "search_products" which returns "products" */
  search_products: Array<Products>;
  /** execute function "search_products" and query aggregates on result of table type "products" */
  search_products_aggregate: Products_Aggregate;
  /** execute function "search_products_for_customer" which returns "search_products_for_customer_results" */
  search_products_for_customer: Array<Search_Products_For_Customer_Results>;
  /** execute function "search_products_for_customer" and query aggregates on result of table type "search_products_for_customer_results" */
  search_products_for_customer_aggregate: Search_Products_For_Customer_Results_Aggregate;
  /** execute function "search_products_for_customer_group" which returns "search_products_for_customer_results" */
  search_products_for_customer_group: Array<Search_Products_For_Customer_Results>;
  /** execute function "search_products_for_customer_group" and query aggregates on result of table type "search_products_for_customer_results" */
  search_products_for_customer_group_aggregate: Search_Products_For_Customer_Results_Aggregate;
  /** fetch data from the table in a streaming manner: "search_products_for_customer_results" */
  search_products_for_customer_results_stream: Array<Search_Products_For_Customer_Results>;
  /** execute function "search_purchased_products" which returns "search_products_for_customer_results" */
  search_purchased_products: Array<Search_Products_For_Customer_Results>;
  /** execute function "search_purchased_products" and query aggregates on result of table type "search_products_for_customer_results" */
  search_purchased_products_aggregate: Search_Products_For_Customer_Results_Aggregate;
  /** fetch data from the table in a streaming manner: "search_purchased_products_results" */
  search_purchased_products_results_stream: Array<Search_Purchased_Products_Results>;
  /** execute function "search_sales_teams" which returns "sales_teams" */
  search_sales_teams: Array<Sales_Teams>;
  /** execute function "search_sales_teams" and query aggregates on result of table type "sales_teams" */
  search_sales_teams_aggregate: Sales_Teams_Aggregate;
  /** execute function "search_stock_locations" which returns "stock_locations" */
  search_stock_locations: Array<Stock_Locations>;
  /** execute function "search_stock_locations" and query aggregates on result of table type "stock_locations" */
  search_stock_locations_aggregate: Stock_Locations_Aggregate;
  /** execute function "search_supplier_orders" which returns "supplier_orders" */
  search_supplier_orders: Array<Supplier_Orders>;
  /** execute function "search_supplier_orders" and query aggregates on result of table type "supplier_orders" */
  search_supplier_orders_aggregate: Supplier_Orders_Aggregate;
  /** execute function "search_suppliers" which returns "suppliers" */
  search_suppliers: Array<Suppliers>;
  /** execute function "search_suppliers" and query aggregates on result of table type "suppliers" */
  search_suppliers_aggregate: Suppliers_Aggregate;
  /** execute function "search_users" which returns "users" */
  search_users: Array<Users>;
  /** execute function "search_users" and query aggregates on result of table type "users" */
  search_users_aggregate: Users_Aggregate;
  /** execute function "sorted_customer_group_pricing_for_customer" which returns "customer_group_pricing" */
  sorted_customer_group_pricing_for_customer: Array<Customer_Group_Pricing>;
  /** execute function "sorted_customer_group_pricing_for_customer" and query aggregates on result of table type "customer_group_pricing" */
  sorted_customer_group_pricing_for_customer_aggregate: Customer_Group_Pricing_Aggregate;
  /** execute function "sorted_customer_group_pricing_for_customer_group" which returns "customer_group_pricing" */
  sorted_customer_group_pricing_for_customer_group: Array<Customer_Group_Pricing>;
  /** execute function "sorted_customer_group_pricing_for_customer_group" and query aggregates on result of table type "customer_group_pricing" */
  sorted_customer_group_pricing_for_customer_group_aggregate: Customer_Group_Pricing_Aggregate;
  /** execute function "sorted_price_lists" which returns "price_lists" */
  sorted_price_lists: Array<Price_Lists>;
  /** execute function "sorted_price_lists" and query aggregates on result of table type "price_lists" */
  sorted_price_lists_aggregate: Price_Lists_Aggregate;
  /** fetch data from the table: "stock_locations" using primary key columns */
  stockLocationByPk?: Maybe<Stock_Locations>;
  /** fetch data from the table: "stock_location_states" using primary key columns */
  stockLocationStateByPk?: Maybe<Stock_Location_States>;
  /** fetch data from the table: "stock_location_state_transitions" using primary key columns */
  stockLocationStateTransitionByPk?: Maybe<Stock_Location_State_Transitions>;
  /** fetch data from the table: "stock_location_state_transitions" */
  stockLocationStateTransitions: Array<Stock_Location_State_Transitions>;
  /** fetch aggregated fields from the table: "stock_location_state_transitions" */
  stockLocationStateTransitionsAggregate: Stock_Location_State_Transitions_Aggregate;
  /** fetch data from the table: "stock_location_states" */
  stockLocationStates: Array<Stock_Location_States>;
  /** fetch aggregated fields from the table: "stock_location_states" */
  stockLocationStatesAggregate: Stock_Location_States_Aggregate;
  /** An array relationship */
  stockLocations: Array<Stock_Locations>;
  /** fetch aggregated fields from the table: "stock_locations" */
  stockLocationsAggregate: Stock_Locations_Aggregate;
  /** fetch data from the table: "stock_transactions" using primary key columns */
  stockTransactionByPk?: Maybe<Stock_Transactions>;
  /** An array relationship */
  stockTransactionLoggedActions: Array<Stock_Transaction_Logged_Actions>;
  /** fetch aggregated fields from the table: "stock_transaction_logged_actions" */
  stockTransactionLoggedActionsAggregate: Stock_Transaction_Logged_Actions_Aggregate;
  /** fetch data from the table: "stock_transaction_names" using primary key columns */
  stockTransactionNameByPk?: Maybe<Stock_Transaction_Names>;
  /** fetch data from the table: "stock_transaction_names" */
  stockTransactionNames: Array<Stock_Transaction_Names>;
  /** fetch aggregated fields from the table: "stock_transaction_names" */
  stockTransactionNamesAggregate: Stock_Transaction_Names_Aggregate;
  /** An array relationship */
  stockTransactions: Array<Stock_Transactions>;
  /** fetch aggregated fields from the table: "stock_transactions" */
  stockTransactionsAggregate: Stock_Transactions_Aggregate;
  /** fetch data from the table in a streaming manner: "stock_location_state_transitions" */
  stock_location_state_transitions_stream: Array<Stock_Location_State_Transitions>;
  /** fetch data from the table in a streaming manner: "stock_location_states" */
  stock_location_states_stream: Array<Stock_Location_States>;
  /** fetch data from the table in a streaming manner: "stock_locations" */
  stock_locations_stream: Array<Stock_Locations>;
  /** fetch data from the table in a streaming manner: "stock_transaction_logged_actions" */
  stock_transaction_logged_actions_stream: Array<Stock_Transaction_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "stock_transaction_names" */
  stock_transaction_names_stream: Array<Stock_Transaction_Names>;
  /** fetch data from the table in a streaming manner: "stock_transactions" */
  stock_transactions_stream: Array<Stock_Transactions>;
  /** fetch data from the table: "supplier_addresses" using primary key columns */
  supplierAddressByPk?: Maybe<Supplier_Addresses>;
  /** An array relationship */
  supplierAddresses: Array<Supplier_Addresses>;
  /** fetch aggregated fields from the table: "supplier_addresses" */
  supplierAddressesAggregate: Supplier_Addresses_Aggregate;
  /** fetch data from the table: "suppliers" using primary key columns */
  supplierByPk?: Maybe<Suppliers>;
  /** fetch data from the table: "supplier_orders" using primary key columns */
  supplierOrderByPk?: Maybe<Supplier_Orders>;
  /** fetch data from the table: "supplier_order_goods_income_items" using primary key columns */
  supplierOrderGoodsIncomeItemByPk?: Maybe<Supplier_Order_Goods_Income_Items>;
  /** fetch data from the table: "supplier_order_goods_income_item_containers" using primary key columns */
  supplierOrderGoodsIncomeItemContainerByPk?: Maybe<Supplier_Order_Goods_Income_Item_Containers>;
  /** An array relationship */
  supplierOrderGoodsIncomeItemContainers: Array<Supplier_Order_Goods_Income_Item_Containers>;
  /** fetch aggregated fields from the table: "supplier_order_goods_income_item_containers" */
  supplierOrderGoodsIncomeItemContainersAggregate: Supplier_Order_Goods_Income_Item_Containers_Aggregate;
  /** fetch data from the table: "supplier_order_goods_income_item_logged_actions" */
  supplierOrderGoodsIncomeItemLoggedActions: Array<Supplier_Order_Goods_Income_Item_Logged_Actions>;
  /** fetch aggregated fields from the table: "supplier_order_goods_income_item_logged_actions" */
  supplierOrderGoodsIncomeItemLoggedActionsAggregate: Supplier_Order_Goods_Income_Item_Logged_Actions_Aggregate;
  /** An array relationship */
  supplierOrderGoodsIncomeItems: Array<Supplier_Order_Goods_Income_Items>;
  /** fetch aggregated fields from the table: "supplier_order_goods_income_items" */
  supplierOrderGoodsIncomeItemsAggregate: Supplier_Order_Goods_Income_Items_Aggregate;
  /** fetch data from the table: "supplier_order_items" using primary key columns */
  supplierOrderItemByPk?: Maybe<Supplier_Order_Items>;
  /** An array relationship */
  supplierOrderItemLoggedActions: Array<Supplier_Order_Item_Logged_Actions>;
  /** fetch aggregated fields from the table: "supplier_order_item_logged_actions" */
  supplierOrderItemLoggedActionsAggregate: Supplier_Order_Item_Logged_Actions_Aggregate;
  /** fetch data from the table: "supplier_order_item_return_causes" using primary key columns */
  supplierOrderItemReturnCauseByPk?: Maybe<Supplier_Order_Item_Return_Causes>;
  /** An array relationship */
  supplierOrderItemReturnCauses: Array<Supplier_Order_Item_Return_Causes>;
  /** fetch aggregated fields from the table: "supplier_order_item_return_causes" */
  supplierOrderItemReturnCausesAggregate: Supplier_Order_Item_Return_Causes_Aggregate;
  /** fetch data from the table: "supplier_order_item_states" using primary key columns */
  supplierOrderItemStateByPk?: Maybe<Supplier_Order_Item_States>;
  /** fetch data from the table: "supplier_order_item_states" */
  supplierOrderItemStates: Array<Supplier_Order_Item_States>;
  /** fetch aggregated fields from the table: "supplier_order_item_states" */
  supplierOrderItemStatesAggregate: Supplier_Order_Item_States_Aggregate;
  /** An array relationship */
  supplierOrderItems: Array<Supplier_Order_Items>;
  /** fetch aggregated fields from the table: "supplier_order_items" */
  supplierOrderItemsAggregate: Supplier_Order_Items_Aggregate;
  /** An array relationship */
  supplierOrderLoggedActions: Array<Supplier_Order_Logged_Actions>;
  /** fetch aggregated fields from the table: "supplier_order_logged_actions" */
  supplierOrderLoggedActionsAggregate: Supplier_Order_Logged_Actions_Aggregate;
  /** fetch data from the table: "supplier_order_returns" using primary key columns */
  supplierOrderReturnByPk?: Maybe<Supplier_Order_Returns>;
  /** fetch data from the table: "supplier_order_return_items" using primary key columns */
  supplierOrderReturnItemByPk?: Maybe<Supplier_Order_Return_Items>;
  /** fetch data from the table: "supplier_order_return_item_containers" using primary key columns */
  supplierOrderReturnItemContainerByPk?: Maybe<Supplier_Order_Return_Item_Containers>;
  /** An array relationship */
  supplierOrderReturnItemContainers: Array<Supplier_Order_Return_Item_Containers>;
  /** fetch aggregated fields from the table: "supplier_order_return_item_containers" */
  supplierOrderReturnItemContainersAggregate: Supplier_Order_Return_Item_Containers_Aggregate;
  /** An array relationship */
  supplierOrderReturnItems: Array<Supplier_Order_Return_Items>;
  /** fetch aggregated fields from the table: "supplier_order_return_items" */
  supplierOrderReturnItemsAggregate: Supplier_Order_Return_Items_Aggregate;
  /** fetch data from the table: "supplier_order_return_states" using primary key columns */
  supplierOrderReturnStateByPk?: Maybe<Supplier_Order_Return_States>;
  /** fetch data from the table: "supplier_order_return_states" */
  supplierOrderReturnStates: Array<Supplier_Order_Return_States>;
  /** fetch aggregated fields from the table: "supplier_order_return_states" */
  supplierOrderReturnStatesAggregate: Supplier_Order_Return_States_Aggregate;
  /** fetch data from the table: "supplier_order_returned_containers" using primary key columns */
  supplierOrderReturnedContainerByPk?: Maybe<Supplier_Order_Returned_Containers>;
  /** An array relationship */
  supplierOrderReturnedContainers: Array<Supplier_Order_Returned_Containers>;
  /** fetch aggregated fields from the table: "supplier_order_returned_containers" */
  supplierOrderReturnedContainersAggregate: Supplier_Order_Returned_Containers_Aggregate;
  /** An array relationship */
  supplierOrderReturns: Array<Supplier_Order_Returns>;
  /** fetch aggregated fields from the table: "supplier_order_returns" */
  supplierOrderReturnsAggregate: Supplier_Order_Returns_Aggregate;
  /** fetch data from the table: "supplier_order_states" using primary key columns */
  supplierOrderStateByPk?: Maybe<Supplier_Order_States>;
  /** fetch data from the table: "supplier_order_state_transitions" using primary key columns */
  supplierOrderStateTransitionByPk?: Maybe<Supplier_Order_State_Transitions>;
  /** fetch data from the table: "supplier_order_state_transitions" */
  supplierOrderStateTransitions: Array<Supplier_Order_State_Transitions>;
  /** fetch aggregated fields from the table: "supplier_order_state_transitions" */
  supplierOrderStateTransitionsAggregate: Supplier_Order_State_Transitions_Aggregate;
  /** fetch data from the table: "supplier_order_states" */
  supplierOrderStates: Array<Supplier_Order_States>;
  /** fetch aggregated fields from the table: "supplier_order_states" */
  supplierOrderStatesAggregate: Supplier_Order_States_Aggregate;
  /** An array relationship */
  supplierOrders: Array<Supplier_Orders>;
  /** fetch aggregated fields from the table: "supplier_orders" */
  supplierOrdersAggregate: Supplier_Orders_Aggregate;
  /** fetch data from the table: "supplier_states" using primary key columns */
  supplierStateByPk?: Maybe<Supplier_States>;
  /** fetch data from the table: "supplier_state_transitions" using primary key columns */
  supplierStateTransitionByPk?: Maybe<Supplier_State_Transitions>;
  /** fetch data from the table: "supplier_state_transitions" */
  supplierStateTransitions: Array<Supplier_State_Transitions>;
  /** fetch aggregated fields from the table: "supplier_state_transitions" */
  supplierStateTransitionsAggregate: Supplier_State_Transitions_Aggregate;
  /** fetch data from the table: "supplier_states" */
  supplierStates: Array<Supplier_States>;
  /** fetch aggregated fields from the table: "supplier_states" */
  supplierStatesAggregate: Supplier_States_Aggregate;
  /** fetch data from the table in a streaming manner: "supplier_addresses" */
  supplier_addresses_stream: Array<Supplier_Addresses>;
  /** fetch data from the table in a streaming manner: "supplier_order_goods_income_item_containers" */
  supplier_order_goods_income_item_containers_stream: Array<Supplier_Order_Goods_Income_Item_Containers>;
  /** fetch data from the table in a streaming manner: "supplier_order_goods_income_item_logged_actions" */
  supplier_order_goods_income_item_logged_actions_stream: Array<Supplier_Order_Goods_Income_Item_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "supplier_order_goods_income_items" */
  supplier_order_goods_income_items_stream: Array<Supplier_Order_Goods_Income_Items>;
  /** fetch data from the table in a streaming manner: "supplier_order_item_logged_actions" */
  supplier_order_item_logged_actions_stream: Array<Supplier_Order_Item_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "supplier_order_item_return_causes" */
  supplier_order_item_return_causes_stream: Array<Supplier_Order_Item_Return_Causes>;
  /** fetch data from the table in a streaming manner: "supplier_order_item_states" */
  supplier_order_item_states_stream: Array<Supplier_Order_Item_States>;
  /** fetch data from the table in a streaming manner: "supplier_order_items" */
  supplier_order_items_stream: Array<Supplier_Order_Items>;
  /** fetch data from the table in a streaming manner: "supplier_order_logged_actions" */
  supplier_order_logged_actions_stream: Array<Supplier_Order_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "supplier_order_return_item_containers" */
  supplier_order_return_item_containers_stream: Array<Supplier_Order_Return_Item_Containers>;
  /** fetch data from the table in a streaming manner: "supplier_order_return_items" */
  supplier_order_return_items_stream: Array<Supplier_Order_Return_Items>;
  /** fetch data from the table in a streaming manner: "supplier_order_return_states" */
  supplier_order_return_states_stream: Array<Supplier_Order_Return_States>;
  /** fetch data from the table in a streaming manner: "supplier_order_returned_containers" */
  supplier_order_returned_containers_stream: Array<Supplier_Order_Returned_Containers>;
  /** fetch data from the table in a streaming manner: "supplier_order_returns" */
  supplier_order_returns_stream: Array<Supplier_Order_Returns>;
  /** fetch data from the table in a streaming manner: "supplier_order_state_transitions" */
  supplier_order_state_transitions_stream: Array<Supplier_Order_State_Transitions>;
  /** fetch data from the table in a streaming manner: "supplier_order_states" */
  supplier_order_states_stream: Array<Supplier_Order_States>;
  /** fetch data from the table in a streaming manner: "supplier_orders" */
  supplier_orders_stream: Array<Supplier_Orders>;
  /** fetch data from the table in a streaming manner: "supplier_state_transitions" */
  supplier_state_transitions_stream: Array<Supplier_State_Transitions>;
  /** fetch data from the table in a streaming manner: "supplier_states" */
  supplier_states_stream: Array<Supplier_States>;
  /** An array relationship */
  suppliers: Array<Suppliers>;
  /** fetch aggregated fields from the table: "suppliers" */
  suppliersAggregate: Suppliers_Aggregate;
  /** fetch data from the table in a streaming manner: "suppliers" */
  suppliers_stream: Array<Suppliers>;
  /** fetch data from the table: "temporal_pricing_states" using primary key columns */
  temporalPricingStateByPk?: Maybe<Temporal_Pricing_States>;
  /** fetch data from the table: "temporal_pricing_state_transitions" using primary key columns */
  temporalPricingStateTransitionByPk?: Maybe<Temporal_Pricing_State_Transitions>;
  /** fetch data from the table: "temporal_pricing_state_transitions" */
  temporalPricingStateTransitions: Array<Temporal_Pricing_State_Transitions>;
  /** fetch aggregated fields from the table: "temporal_pricing_state_transitions" */
  temporalPricingStateTransitionsAggregate: Temporal_Pricing_State_Transitions_Aggregate;
  /** fetch data from the table: "temporal_pricing_states" */
  temporalPricingStates: Array<Temporal_Pricing_States>;
  /** fetch aggregated fields from the table: "temporal_pricing_states" */
  temporalPricingStatesAggregate: Temporal_Pricing_States_Aggregate;
  /** fetch data from the table: "temporal_products" using primary key columns */
  temporalProductByPk?: Maybe<Temporal_Products>;
  /** An array relationship */
  temporalProductComparisonResults: Array<Temporal_Product_Comparison_Result>;
  /** fetch aggregated fields from the table: "temporal_product_comparison_result" */
  temporalProductComparisonResultsAggregate: Temporal_Product_Comparison_Result_Aggregate;
  /** An array relationship */
  temporalProducts: Array<Temporal_Products>;
  /** fetch aggregated fields from the table: "temporal_products" */
  temporalProductsAggregate: Temporal_Products_Aggregate;
  /** fetch data from the table: "temporal_sales_teams_customers" using primary key columns */
  temporalSalesTeamsCustomerByPk?: Maybe<Temporal_Sales_Teams_Customers>;
  /** An array relationship */
  temporalSalesTeamsCustomers: Array<Temporal_Sales_Teams_Customers>;
  /** fetch aggregated fields from the table: "temporal_sales_teams_customers" */
  temporalSalesTeamsCustomersAggregate: Temporal_Sales_Teams_Customers_Aggregate;
  /** fetch data from the table: "temporal_vat_types" using primary key columns */
  temporalVatTypeByPk?: Maybe<Temporal_Vat_Types>;
  /** An array relationship */
  temporalVatTypes: Array<Temporal_Vat_Types>;
  /** fetch aggregated fields from the table: "temporal_vat_types" */
  temporalVatTypesAggregate: Temporal_Vat_Types_Aggregate;
  /** fetch data from the table in a streaming manner: "temporal_pricing_state_transitions" */
  temporal_pricing_state_transitions_stream: Array<Temporal_Pricing_State_Transitions>;
  /** fetch data from the table in a streaming manner: "temporal_pricing_states" */
  temporal_pricing_states_stream: Array<Temporal_Pricing_States>;
  /** fetch data from the table in a streaming manner: "temporal_product_comparison_result" */
  temporal_product_comparison_result_stream: Array<Temporal_Product_Comparison_Result>;
  /** execute function "temporal_products_for_cutting_pattern" which returns "temporal_products" */
  temporal_products_for_cutting_pattern: Array<Temporal_Products>;
  /** execute function "temporal_products_for_cutting_pattern" and query aggregates on result of table type "temporal_products" */
  temporal_products_for_cutting_pattern_aggregate: Temporal_Products_Aggregate;
  /** execute function "temporal_products_from_root_product_id" which returns "temporal_products" */
  temporal_products_from_root_product_id: Array<Temporal_Products>;
  /** execute function "temporal_products_from_root_product_id" and query aggregates on result of table type "temporal_products" */
  temporal_products_from_root_product_id_aggregate: Temporal_Products_Aggregate;
  /** fetch data from the table in a streaming manner: "temporal_products" */
  temporal_products_stream: Array<Temporal_Products>;
  /** fetch data from the table in a streaming manner: "temporal_sales_teams_customers" */
  temporal_sales_teams_customers_stream: Array<Temporal_Sales_Teams_Customers>;
  /** fetch data from the table in a streaming manner: "temporal_vat_types" */
  temporal_vat_types_stream: Array<Temporal_Vat_Types>;
  /** fetch data from the table: "tenant_addresses" using primary key columns */
  tenantAddressByPk?: Maybe<Tenant_Addresses>;
  /** An array relationship */
  tenantAddresses: Array<Tenant_Addresses>;
  /** fetch aggregated fields from the table: "tenant_addresses" */
  tenantAddressesAggregate: Tenant_Addresses_Aggregate;
  /** fetch data from the table: "tenant_bank_accounts" using primary key columns */
  tenantBankAccountByPk?: Maybe<Tenant_Bank_Accounts>;
  /** An array relationship */
  tenantBankAccounts: Array<Tenant_Bank_Accounts>;
  /** fetch aggregated fields from the table: "tenant_bank_accounts" */
  tenantBankAccountsAggregate: Tenant_Bank_Accounts_Aggregate;
  /** fetch data from the table: "tenants" using primary key columns */
  tenantByPk?: Maybe<Tenants>;
  /** fetch data from the table: "tenant_company_details" using primary key columns */
  tenantCompanyDetailByPk?: Maybe<Tenant_Company_Details>;
  /** An array relationship */
  tenantCompanyDetails: Array<Tenant_Company_Details>;
  /** fetch aggregated fields from the table: "tenant_company_details" */
  tenantCompanyDetailsAggregate: Tenant_Company_Details_Aggregate;
  /** fetch data from the table: "tenant_configurations" using primary key columns */
  tenantConfigurationByPk?: Maybe<Tenant_Configurations>;
  /** An array relationship */
  tenantConfigurations: Array<Tenant_Configurations>;
  /** fetch aggregated fields from the table: "tenant_configurations" */
  tenantConfigurationsAggregate: Tenant_Configurations_Aggregate;
  /** fetch data from the table: "tenant_info_with_token_result" */
  tenantInfoWithTokenResults: Array<Tenant_Info_With_Token_Result>;
  /** fetch aggregated fields from the table: "tenant_info_with_token_result" */
  tenantInfoWithTokenResultsAggregate: Tenant_Info_With_Token_Result_Aggregate;
  /** fetch data from the table: "tenant_languages" using primary key columns */
  tenantLanguageByPk?: Maybe<Tenant_Languages>;
  /** An array relationship */
  tenantLanguages: Array<Tenant_Languages>;
  /** fetch aggregated fields from the table: "tenant_languages" */
  tenantLanguagesAggregate: Tenant_Languages_Aggregate;
  /** fetch data from the table: "tenant_permissions" using primary key columns */
  tenantPermissionByPk?: Maybe<Tenant_Permissions>;
  /** An array relationship */
  tenantPermissions: Array<Tenant_Permissions>;
  /** fetch aggregated fields from the table: "tenant_permissions" */
  tenantPermissionsAggregate: Tenant_Permissions_Aggregate;
  /** fetch data from the table in a streaming manner: "tenant_addresses" */
  tenant_addresses_stream: Array<Tenant_Addresses>;
  /** fetch data from the table in a streaming manner: "tenant_bank_accounts" */
  tenant_bank_accounts_stream: Array<Tenant_Bank_Accounts>;
  /** fetch data from the table in a streaming manner: "tenant_company_details" */
  tenant_company_details_stream: Array<Tenant_Company_Details>;
  /** fetch data from the table in a streaming manner: "tenant_configurations" */
  tenant_configurations_stream: Array<Tenant_Configurations>;
  /** fetch data from the table in a streaming manner: "tenant_info_with_token_result" */
  tenant_info_with_token_result_stream: Array<Tenant_Info_With_Token_Result>;
  /** fetch data from the table in a streaming manner: "tenant_languages" */
  tenant_languages_stream: Array<Tenant_Languages>;
  /** fetch data from the table in a streaming manner: "tenant_permissions" */
  tenant_permissions_stream: Array<Tenant_Permissions>;
  /** An array relationship */
  tenants: Array<Tenants>;
  /** fetch aggregated fields from the table: "tenants" */
  tenantsAggregate: Tenants_Aggregate;
  /** fetch data from the table in a streaming manner: "tenants" */
  tenants_stream: Array<Tenants>;
  /** execute function "todays_todos" which returns "todos" */
  todays_todos: Array<Todos>;
  /** execute function "todays_todos" and query aggregates on result of table type "todos" */
  todays_todos_aggregate: Todos_Aggregate;
  /** fetch data from the table: "todo_actions" using primary key columns */
  todoActionByPk?: Maybe<Todo_Actions>;
  /** fetch data from the table: "todo_actions" */
  todoActions: Array<Todo_Actions>;
  /** fetch aggregated fields from the table: "todo_actions" */
  todoActionsAggregate: Todo_Actions_Aggregate;
  /** fetch data from the table: "todos" using primary key columns */
  todoByPk?: Maybe<Todos>;
  /** fetch data from the table: "todo_recurrences" using primary key columns */
  todoRecurrenceByPk?: Maybe<Todo_Recurrences>;
  /** fetch data from the table: "todo_recurrences" */
  todoRecurrences: Array<Todo_Recurrences>;
  /** fetch aggregated fields from the table: "todo_recurrences" */
  todoRecurrencesAggregate: Todo_Recurrences_Aggregate;
  /** fetch data from the table: "todo_states" using primary key columns */
  todoStateByPk?: Maybe<Todo_States>;
  /** fetch data from the table: "todo_states" */
  todoStates: Array<Todo_States>;
  /** fetch aggregated fields from the table: "todo_states" */
  todoStatesAggregate: Todo_States_Aggregate;
  /** fetch data from the table in a streaming manner: "todo_actions" */
  todo_actions_stream: Array<Todo_Actions>;
  /** fetch data from the table in a streaming manner: "todo_recurrences" */
  todo_recurrences_stream: Array<Todo_Recurrences>;
  /** fetch data from the table in a streaming manner: "todo_states" */
  todo_states_stream: Array<Todo_States>;
  /** An array relationship */
  todos: Array<Todos>;
  /** fetch aggregated fields from the table: "todos" */
  todosAggregate: Todos_Aggregate;
  /** execute function "todos_on_day" which returns "todos" */
  todos_on_day: Array<Todos>;
  /** execute function "todos_on_day" and query aggregates on result of table type "todos" */
  todos_on_day_aggregate: Todos_Aggregate;
  /** fetch data from the table in a streaming manner: "todos" */
  todos_stream: Array<Todos>;
  /** An array relationship */
  topologicallySortedCuttingPatternsResults: Array<Topologically_Sorted_Cutting_Patterns_Results>;
  /** fetch aggregated fields from the table: "topologically_sorted_cutting_patterns_results" */
  topologicallySortedCuttingPatternsResultsAggregate: Topologically_Sorted_Cutting_Patterns_Results_Aggregate;
  /** execute function "topologically_sorted_cutting_patterns" which returns "topologically_sorted_cutting_patterns_results" */
  topologically_sorted_cutting_patterns: Array<Topologically_Sorted_Cutting_Patterns_Results>;
  /** execute function "topologically_sorted_cutting_patterns" and query aggregates on result of table type "topologically_sorted_cutting_patterns_results" */
  topologically_sorted_cutting_patterns_aggregate: Topologically_Sorted_Cutting_Patterns_Results_Aggregate;
  /** fetch data from the table in a streaming manner: "topologically_sorted_cutting_patterns_results" */
  topologically_sorted_cutting_patterns_results_stream: Array<Topologically_Sorted_Cutting_Patterns_Results>;
  /** fetch data from the table: "tours" using primary key columns */
  tourByPk?: Maybe<Tours>;
  /** fetch data from the table: "tour_routes" using primary key columns */
  tourRouteByPk?: Maybe<Tour_Routes>;
  /** An array relationship */
  tourRoutes: Array<Tour_Routes>;
  /** fetch aggregated fields from the table: "tour_routes" */
  tourRoutesAggregate: Tour_Routes_Aggregate;
  /** fetch data from the table: "tour_states" using primary key columns */
  tourStateByPk?: Maybe<Tour_States>;
  /** fetch data from the table: "tour_state_transitions" using primary key columns */
  tourStateTransitionByPk?: Maybe<Tour_State_Transitions>;
  /** fetch data from the table: "tour_state_transitions" */
  tourStateTransitions: Array<Tour_State_Transitions>;
  /** fetch aggregated fields from the table: "tour_state_transitions" */
  tourStateTransitionsAggregate: Tour_State_Transitions_Aggregate;
  /** fetch data from the table: "tour_states" */
  tourStates: Array<Tour_States>;
  /** fetch aggregated fields from the table: "tour_states" */
  tourStatesAggregate: Tour_States_Aggregate;
  /** fetch data from the table in a streaming manner: "tour_routes" */
  tour_routes_stream: Array<Tour_Routes>;
  /** fetch data from the table in a streaming manner: "tour_state_transitions" */
  tour_state_transitions_stream: Array<Tour_State_Transitions>;
  /** fetch data from the table in a streaming manner: "tour_states" */
  tour_states_stream: Array<Tour_States>;
  /** An array relationship */
  tours: Array<Tours>;
  /** fetch aggregated fields from the table: "tours" */
  toursAggregate: Tours_Aggregate;
  /** fetch data from the table in a streaming manner: "tours" */
  tours_stream: Array<Tours>;
  /** fetch data from the table: "units" using primary key columns */
  unitByPk?: Maybe<Units>;
  /** fetch data from the table: "units" */
  units: Array<Units>;
  /** fetch aggregated fields from the table: "units" */
  unitsAggregate: Units_Aggregate;
  /** fetch data from the table in a streaming manner: "units" */
  units_stream: Array<Units>;
  /** fetch data from the table: "users" using primary key columns */
  userByPk?: Maybe<Users>;
  /** fetch data from the table: "user_sales_goals" using primary key columns */
  userSalesGoalByPk?: Maybe<User_Sales_Goals>;
  /** An array relationship */
  userSalesGoals: Array<User_Sales_Goals>;
  /** fetch aggregated fields from the table: "user_sales_goals" */
  userSalesGoalsAggregate: User_Sales_Goals_Aggregate;
  /** fetch data from the table: "user_states" using primary key columns */
  userStateByPk?: Maybe<User_States>;
  /** fetch data from the table: "user_state_transitions" using primary key columns */
  userStateTransitionByPk?: Maybe<User_State_Transitions>;
  /** fetch data from the table: "user_state_transitions" */
  userStateTransitions: Array<User_State_Transitions>;
  /** fetch aggregated fields from the table: "user_state_transitions" */
  userStateTransitionsAggregate: User_State_Transitions_Aggregate;
  /** fetch data from the table: "user_states" */
  userStates: Array<User_States>;
  /** fetch aggregated fields from the table: "user_states" */
  userStatesAggregate: User_States_Aggregate;
  /** fetch data from the table in a streaming manner: "user_sales_goals" */
  user_sales_goals_stream: Array<User_Sales_Goals>;
  /** fetch data from the table in a streaming manner: "user_state_transitions" */
  user_state_transitions_stream: Array<User_State_Transitions>;
  /** fetch data from the table in a streaming manner: "user_states" */
  user_states_stream: Array<User_States>;
  /** An array relationship */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  usersAggregate: Users_Aggregate;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "vat_types" using primary key columns */
  vatTypeByPk?: Maybe<Vat_Types>;
  /** An array relationship */
  vatTypes: Array<Vat_Types>;
  /** fetch aggregated fields from the table: "vat_types" */
  vatTypesAggregate: Vat_Types_Aggregate;
  /** fetch data from the table in a streaming manner: "vat_types" */
  vat_types_stream: Array<Vat_Types>;
  /** fetch data from the table: "work_calendar" using primary key columns */
  workCalendarByPk?: Maybe<Work_Calendar>;
  /** fetch data from the table: "work_calendar_logged_actions" */
  workCalendarLoggedActions: Array<Work_Calendar_Logged_Actions>;
  /** fetch aggregated fields from the table: "work_calendar_logged_actions" */
  workCalendarLoggedActionsAggregate: Work_Calendar_Logged_Actions_Aggregate;
  /** An array relationship */
  workCalendars: Array<Work_Calendar>;
  /** fetch aggregated fields from the table: "work_calendar" */
  workCalendarsAggregate: Work_Calendar_Aggregate;
  /** fetch data from the table in a streaming manner: "work_calendar_logged_actions" */
  work_calendar_logged_actions_stream: Array<Work_Calendar_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "work_calendar" */
  work_calendar_stream: Array<Work_Calendar>;
  /** fetch data from the table: "working_days" using primary key columns */
  workingDayByPk?: Maybe<Working_Days>;
  /** fetch data from the table: "working_days" */
  workingDays: Array<Working_Days>;
  /** fetch aggregated fields from the table: "working_days" */
  workingDaysAggregate: Working_Days_Aggregate;
  /** fetch data from the table in a streaming manner: "working_days" */
  working_days_stream: Array<Working_Days>;
  /** fetch data from the table: "workstations" using primary key columns */
  workstationByPk?: Maybe<Workstations>;
  /** fetch data from the table: "workstation_hardware_device_connections" using primary key columns */
  workstationHardwareDeviceConnectionByPk?: Maybe<Workstation_Hardware_Device_Connections>;
  /** An array relationship */
  workstationHardwareDeviceConnections: Array<Workstation_Hardware_Device_Connections>;
  /** fetch aggregated fields from the table: "workstation_hardware_device_connections" */
  workstationHardwareDeviceConnectionsAggregate: Workstation_Hardware_Device_Connections_Aggregate;
  /** An array relationship */
  workstationPickingCategories: Array<Workstation_Picking_Categories>;
  /** fetch aggregated fields from the table: "workstation_picking_categories" */
  workstationPickingCategoriesAggregate: Workstation_Picking_Categories_Aggregate;
  /** fetch data from the table in a streaming manner: "workstation_hardware_device_connections" */
  workstation_hardware_device_connections_stream: Array<Workstation_Hardware_Device_Connections>;
  /** fetch data from the table in a streaming manner: "workstation_picking_categories" */
  workstation_picking_categories_stream: Array<Workstation_Picking_Categories>;
  /** An array relationship */
  workstations: Array<Workstations>;
  /** fetch aggregated fields from the table: "workstations" */
  workstationsAggregate: Workstations_Aggregate;
  /** fetch data from the table in a streaming manner: "workstations" */
  workstations_stream: Array<Workstations>;
};


export type Subscription_RootAbacusExportByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAbacusExportsArgs = {
  distinct_on?: InputMaybe<Array<Abacus_Exports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Abacus_Exports_Order_By>>;
  where?: InputMaybe<Abacus_Exports_Bool_Exp>;
};


export type Subscription_RootAbacusExportsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Abacus_Exports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Abacus_Exports_Order_By>>;
  where?: InputMaybe<Abacus_Exports_Bool_Exp>;
};


export type Subscription_RootAbacus_Exports_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Abacus_Exports_Stream_Cursor_Input>>;
  where?: InputMaybe<Abacus_Exports_Bool_Exp>;
};


export type Subscription_RootAccountByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Subscription_RootAccountsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Subscription_RootAccounts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Accounts_Stream_Cursor_Input>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Subscription_RootAggregatedDemandPlanIncomingGoodsResultsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp>;
};


export type Subscription_RootAggregatedDemandPlanIncomingGoodsResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp>;
};


export type Subscription_RootAggregatedDemandPlanOutgoingGoodsResultsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
};


export type Subscription_RootAggregatedDemandPlanOutgoingGoodsResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Outgoing_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
};


export type Subscription_RootAggregatedDemandPlanResultsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};


export type Subscription_RootAggregatedDemandPlanResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};


export type Subscription_RootAggregatedInventoryItemsByLocationsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


export type Subscription_RootAggregatedInventoryItemsByLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


export type Subscription_RootAggregated_Demand_PlanArgs = {
  args: Aggregated_Demand_Plan_Args;
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};


export type Subscription_RootAggregated_Demand_Plan_AggregateArgs = {
  args: Aggregated_Demand_Plan_Args;
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};


export type Subscription_RootAggregated_Demand_Plan_Incoming_Goods_Results_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Stream_Cursor_Input>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp>;
};


export type Subscription_RootAggregated_Demand_Plan_Outgoing_Goods_Results_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Stream_Cursor_Input>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Outgoing_Goods_Results_Bool_Exp>;
};


export type Subscription_RootAggregated_Demand_Plan_Results_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Aggregated_Demand_Plan_Results_Stream_Cursor_Input>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};


export type Subscription_RootAggregated_Inventory_Items_By_Location_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Aggregated_Inventory_Items_By_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


export type Subscription_RootAggregated_List_Inventory_By_LocationArgs = {
  args: Aggregated_List_Inventory_By_Location_Args;
  distinct_on?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


export type Subscription_RootAggregated_List_Inventory_By_Location_AggregateArgs = {
  args: Aggregated_List_Inventory_By_Location_Args;
  distinct_on?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Aggregated_Inventory_Items_By_Location_Bool_Exp>;
};


export type Subscription_RootCalculateCostPriceForProductResultsArgs = {
  distinct_on?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Order_By>>;
  where?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
};


export type Subscription_RootCalculateCostPriceForProductResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Order_By>>;
  where?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
};


export type Subscription_RootCalculate_Cost_Price_For_ProductArgs = {
  args: Calculate_Cost_Price_For_Product_Args;
  distinct_on?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Order_By>>;
  where?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
};


export type Subscription_RootCalculate_Cost_Price_For_Product_AggregateArgs = {
  args: Calculate_Cost_Price_For_Product_Args;
  distinct_on?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Order_By>>;
  where?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
};


export type Subscription_RootCalculate_Cost_Price_For_Product_By_ProductArgs = {
  args: Calculate_Cost_Price_For_Product_By_Product_Args;
  distinct_on?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Order_By>>;
  where?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
};


export type Subscription_RootCalculate_Cost_Price_For_Product_By_Product_AggregateArgs = {
  args: Calculate_Cost_Price_For_Product_By_Product_Args;
  distinct_on?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Order_By>>;
  where?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
};


export type Subscription_RootCalculate_Cost_Price_For_Product_Results_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Calculate_Cost_Price_For_Product_Results_Stream_Cursor_Input>>;
  where?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
};


export type Subscription_RootCalculate_Customer_PricingArgs = {
  args: Calculate_Customer_Pricing_Args;
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Subscription_RootCalculate_Customer_Pricing_AggregateArgs = {
  args: Calculate_Customer_Pricing_Args;
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Subscription_RootChangeCustomerOrderWithTokenResultsArgs = {
  distinct_on?: InputMaybe<Array<Change_Customer_Order_With_Token_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Change_Customer_Order_With_Token_Result_Order_By>>;
  where?: InputMaybe<Change_Customer_Order_With_Token_Result_Bool_Exp>;
};


export type Subscription_RootChangeCustomerOrderWithTokenResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Change_Customer_Order_With_Token_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Change_Customer_Order_With_Token_Result_Order_By>>;
  where?: InputMaybe<Change_Customer_Order_With_Token_Result_Bool_Exp>;
};


export type Subscription_RootChange_Customer_Order_With_Token_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Change_Customer_Order_With_Token_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Change_Customer_Order_With_Token_Result_Bool_Exp>;
};


export type Subscription_RootContactAssignedRoleByPkArgs = {
  contactId: Scalars['bigint'];
  contactRole: Contact_Roles_Enum;
};


export type Subscription_RootContactAssignedRolesArgs = {
  distinct_on?: InputMaybe<Array<Contact_Assigned_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Assigned_Roles_Order_By>>;
  where?: InputMaybe<Contact_Assigned_Roles_Bool_Exp>;
};


export type Subscription_RootContactAssignedRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Assigned_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Assigned_Roles_Order_By>>;
  where?: InputMaybe<Contact_Assigned_Roles_Bool_Exp>;
};


export type Subscription_RootContactAvailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<Contact_Availability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Availability_Order_By>>;
  where?: InputMaybe<Contact_Availability_Bool_Exp>;
};


export type Subscription_RootContactAvailabilitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Availability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Availability_Order_By>>;
  where?: InputMaybe<Contact_Availability_Bool_Exp>;
};


export type Subscription_RootContactAvailabilityByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootContactAvailabilityRangeByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootContactAvailabilityRangesArgs = {
  distinct_on?: InputMaybe<Array<Contact_Availability_Ranges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Availability_Ranges_Order_By>>;
  where?: InputMaybe<Contact_Availability_Ranges_Bool_Exp>;
};


export type Subscription_RootContactAvailabilityRangesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Availability_Ranges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Availability_Ranges_Order_By>>;
  where?: InputMaybe<Contact_Availability_Ranges_Bool_Exp>;
};


export type Subscription_RootContactByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootContactRoleByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootContactRolesArgs = {
  distinct_on?: InputMaybe<Array<Contact_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Roles_Order_By>>;
  where?: InputMaybe<Contact_Roles_Bool_Exp>;
};


export type Subscription_RootContactRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Roles_Order_By>>;
  where?: InputMaybe<Contact_Roles_Bool_Exp>;
};


export type Subscription_RootContact_Assigned_Roles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Contact_Assigned_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Contact_Assigned_Roles_Bool_Exp>;
};


export type Subscription_RootContact_Availability_Ranges_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Contact_Availability_Ranges_Stream_Cursor_Input>>;
  where?: InputMaybe<Contact_Availability_Ranges_Bool_Exp>;
};


export type Subscription_RootContact_Availability_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Contact_Availability_Stream_Cursor_Input>>;
  where?: InputMaybe<Contact_Availability_Bool_Exp>;
};


export type Subscription_RootContact_Roles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Contact_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Contact_Roles_Bool_Exp>;
};


export type Subscription_RootContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Subscription_RootContactsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Subscription_RootContacts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Contacts_Stream_Cursor_Input>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Subscription_RootCostPriceAveragePeriodByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCostPriceAveragePeriodsArgs = {
  distinct_on?: InputMaybe<Array<Cost_Price_Average_Periods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cost_Price_Average_Periods_Order_By>>;
  where?: InputMaybe<Cost_Price_Average_Periods_Bool_Exp>;
};


export type Subscription_RootCostPriceAveragePeriodsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cost_Price_Average_Periods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cost_Price_Average_Periods_Order_By>>;
  where?: InputMaybe<Cost_Price_Average_Periods_Bool_Exp>;
};


export type Subscription_RootCost_Price_Average_Periods_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cost_Price_Average_Periods_Stream_Cursor_Input>>;
  where?: InputMaybe<Cost_Price_Average_Periods_Bool_Exp>;
};


export type Subscription_RootCreditNoteByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCreditNoteItemByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCreditNoteItemLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Credit_Note_Item_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCreditNoteItemLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Credit_Note_Item_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCreditNoteItemsArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Items_Order_By>>;
  where?: InputMaybe<Credit_Note_Items_Bool_Exp>;
};


export type Subscription_RootCreditNoteItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Items_Order_By>>;
  where?: InputMaybe<Credit_Note_Items_Bool_Exp>;
};


export type Subscription_RootCreditNoteLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Logged_Actions_Order_By>>;
  where?: InputMaybe<Credit_Note_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCreditNoteLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Logged_Actions_Order_By>>;
  where?: InputMaybe<Credit_Note_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCreditNoteStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCreditNoteStateTransitionByPkArgs = {
  fromState: Credit_Note_States_Enum;
  toState: Credit_Note_States_Enum;
};


export type Subscription_RootCreditNoteStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_State_Transitions_Order_By>>;
  where?: InputMaybe<Credit_Note_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCreditNoteStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_State_Transitions_Order_By>>;
  where?: InputMaybe<Credit_Note_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCreditNoteStatesArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_States_Order_By>>;
  where?: InputMaybe<Credit_Note_States_Bool_Exp>;
};


export type Subscription_RootCreditNoteStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_States_Order_By>>;
  where?: InputMaybe<Credit_Note_States_Bool_Exp>;
};


export type Subscription_RootCreditNoteVatSummaryResultsArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Vat_Summary_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Vat_Summary_Results_Order_By>>;
  where?: InputMaybe<Credit_Note_Vat_Summary_Results_Bool_Exp>;
};


export type Subscription_RootCreditNoteVatSummaryResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Vat_Summary_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Vat_Summary_Results_Order_By>>;
  where?: InputMaybe<Credit_Note_Vat_Summary_Results_Bool_Exp>;
};


export type Subscription_RootCreditNotesArgs = {
  distinct_on?: InputMaybe<Array<Credit_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Notes_Order_By>>;
  where?: InputMaybe<Credit_Notes_Bool_Exp>;
};


export type Subscription_RootCreditNotesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Notes_Order_By>>;
  where?: InputMaybe<Credit_Notes_Bool_Exp>;
};


export type Subscription_RootCredit_Note_Item_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Credit_Note_Item_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Credit_Note_Item_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCredit_Note_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Credit_Note_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Credit_Note_Items_Bool_Exp>;
};


export type Subscription_RootCredit_Note_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Credit_Note_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Credit_Note_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCredit_Note_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Credit_Note_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Credit_Note_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCredit_Note_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Credit_Note_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Credit_Note_States_Bool_Exp>;
};


export type Subscription_RootCredit_Note_Vat_Summary_Results_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Credit_Note_Vat_Summary_Results_Stream_Cursor_Input>>;
  where?: InputMaybe<Credit_Note_Vat_Summary_Results_Bool_Exp>;
};


export type Subscription_RootCredit_Notes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Credit_Notes_Stream_Cursor_Input>>;
  where?: InputMaybe<Credit_Notes_Bool_Exp>;
};


export type Subscription_RootCurrenciesArgs = {
  distinct_on?: InputMaybe<Array<Currencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currencies_Order_By>>;
  where?: InputMaybe<Currencies_Bool_Exp>;
};


export type Subscription_RootCurrenciesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Currencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currencies_Order_By>>;
  where?: InputMaybe<Currencies_Bool_Exp>;
};


export type Subscription_RootCurrencies_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Currencies_Stream_Cursor_Input>>;
  where?: InputMaybe<Currencies_Bool_Exp>;
};


export type Subscription_RootCurrencyByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCustomerAddressByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerAddressesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Addresses_Order_By>>;
  where?: InputMaybe<Customer_Addresses_Bool_Exp>;
};


export type Subscription_RootCustomerAddressesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Addresses_Order_By>>;
  where?: InputMaybe<Customer_Addresses_Bool_Exp>;
};


export type Subscription_RootCustomerBillingFormatByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCustomerBillingFormatsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Format_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Format_Order_By>>;
  where?: InputMaybe<Customer_Billing_Format_Bool_Exp>;
};


export type Subscription_RootCustomerBillingFormatsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Format_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Format_Order_By>>;
  where?: InputMaybe<Customer_Billing_Format_Bool_Exp>;
};


export type Subscription_RootCustomerBillingFrequenciesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Frequency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Frequency_Order_By>>;
  where?: InputMaybe<Customer_Billing_Frequency_Bool_Exp>;
};


export type Subscription_RootCustomerBillingFrequenciesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Frequency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Frequency_Order_By>>;
  where?: InputMaybe<Customer_Billing_Frequency_Bool_Exp>;
};


export type Subscription_RootCustomerBillingFrequencyByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCustomerBillingSendStrategiesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Send_Strategies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Send_Strategies_Order_By>>;
  where?: InputMaybe<Customer_Billing_Send_Strategies_Bool_Exp>;
};


export type Subscription_RootCustomerBillingSendStrategiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Send_Strategies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Send_Strategies_Order_By>>;
  where?: InputMaybe<Customer_Billing_Send_Strategies_Bool_Exp>;
};


export type Subscription_RootCustomerBillingSendStrategyByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCustomerBillingSettingByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerBillingSettingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Settings_Order_By>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};


export type Subscription_RootCustomerBillingSettingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Settings_Order_By>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};


export type Subscription_RootCustomerByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerDeliveryAvailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Delivery_Availability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Delivery_Availability_Order_By>>;
  where?: InputMaybe<Customer_Delivery_Availability_Bool_Exp>;
};


export type Subscription_RootCustomerDeliveryAvailabilitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Delivery_Availability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Delivery_Availability_Order_By>>;
  where?: InputMaybe<Customer_Delivery_Availability_Bool_Exp>;
};


export type Subscription_RootCustomerDeliveryAvailabilityByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerDeliveryNoteFormatByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCustomerDeliveryNoteFormatsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Delivery_Note_Formats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Delivery_Note_Formats_Order_By>>;
  where?: InputMaybe<Customer_Delivery_Note_Formats_Bool_Exp>;
};


export type Subscription_RootCustomerDeliveryNoteFormatsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Delivery_Note_Formats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Delivery_Note_Formats_Order_By>>;
  where?: InputMaybe<Customer_Delivery_Note_Formats_Bool_Exp>;
};


export type Subscription_RootCustomerGroupByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerGroupPricingByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerGroupPricingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Subscription_RootCustomerGroupPricingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Subscription_RootCustomerGroupStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCustomerGroupStateTransitionByPkArgs = {
  fromState: Customer_Group_States_Enum;
  toState: Customer_Group_States_Enum;
};


export type Subscription_RootCustomerGroupStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_Group_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCustomerGroupStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_Group_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCustomerGroupStatesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_States_Order_By>>;
  where?: InputMaybe<Customer_Group_States_Bool_Exp>;
};


export type Subscription_RootCustomerGroupStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_States_Order_By>>;
  where?: InputMaybe<Customer_Group_States_Bool_Exp>;
};


export type Subscription_RootCustomerGroupsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Groups_Order_By>>;
  where?: InputMaybe<Customer_Groups_Bool_Exp>;
};


export type Subscription_RootCustomerGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Groups_Order_By>>;
  where?: InputMaybe<Customer_Groups_Bool_Exp>;
};


export type Subscription_RootCustomerInvoiceByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerInvoiceLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCustomerInvoiceLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCustomerInvoiceOrderByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerInvoiceOrdersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Orders_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Orders_Bool_Exp>;
};


export type Subscription_RootCustomerInvoiceOrdersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Orders_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Orders_Bool_Exp>;
};


export type Subscription_RootCustomerInvoiceStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCustomerInvoiceStateTransitionByPkArgs = {
  fromState: Customer_Invoice_States_Enum;
  toState: Customer_Invoice_States_Enum;
};


export type Subscription_RootCustomerInvoiceStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCustomerInvoiceStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCustomerInvoiceStatesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_States_Order_By>>;
  where?: InputMaybe<Customer_Invoice_States_Bool_Exp>;
};


export type Subscription_RootCustomerInvoiceStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_States_Order_By>>;
  where?: InputMaybe<Customer_Invoice_States_Bool_Exp>;
};


export type Subscription_RootCustomerInvoiceVatSummaryResultsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Vat_Summary_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Vat_Summary_Results_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Vat_Summary_Results_Bool_Exp>;
};


export type Subscription_RootCustomerInvoiceVatSummaryResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Vat_Summary_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Vat_Summary_Results_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Vat_Summary_Results_Bool_Exp>;
};


export type Subscription_RootCustomerInvoiceVersionByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerInvoiceVersionLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Version_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Version_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCustomerInvoiceVersionLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Version_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Version_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCustomerInvoiceVersionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Versions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
};


export type Subscription_RootCustomerInvoiceVersionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Versions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
};


export type Subscription_RootCustomerInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoices_Order_By>>;
  where?: InputMaybe<Customer_Invoices_Bool_Exp>;
};


export type Subscription_RootCustomerInvoicesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoices_Order_By>>;
  where?: InputMaybe<Customer_Invoices_Bool_Exp>;
};


export type Subscription_RootCustomerLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCustomerLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCustomerOrderByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerOrderContainerReturnByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerOrderContainerReturnItemByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerOrderContainerReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Return_Items_Bool_Exp>;
};


export type Subscription_RootCustomerOrderContainerReturnItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Return_Items_Bool_Exp>;
};


export type Subscription_RootCustomerOrderContainerReturnsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Returns_Bool_Exp>;
};


export type Subscription_RootCustomerOrderContainerReturnsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Returns_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemAdjustmentByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerOrderItemAdjustmentLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustment_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustment_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustment_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemAdjustmentLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustment_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustment_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustment_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemAdjustmentTypeByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCustomerOrderItemAdjustmentTypesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustment_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustment_Types_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustment_Types_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemAdjustmentTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustment_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustment_Types_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustment_Types_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemAdjustmentsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustments_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemAdjustmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustments_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerOrderItemContainerExpectationByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerOrderItemContainerExpectationsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Container_Expectations_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemContainerExpectationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Container_Expectations_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemExtrasArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Extras_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Extras_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Extras_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemExtrasAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Extras_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Extras_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Extras_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemFulfillmentPlanByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerOrderItemFulfillmentPlansArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemFulfillmentPlansAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemReturnCauseByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerOrderItemReturnCausesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Return_Causes_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemReturnCausesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Return_Causes_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCustomerOrderItemStateTransitionByPkArgs = {
  fromState: Customer_Order_Item_States_Enum;
  toState: Customer_Order_Item_States_Enum;
};


export type Subscription_RootCustomerOrderItemStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemStatesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_States_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_States_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_States_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_States_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


export type Subscription_RootCustomerOrderItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


export type Subscription_RootCustomerOrderLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Order_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCustomerOrderLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Order_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCustomerOrderPickedContainerByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerOrderPickedContainerStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCustomerOrderPickedContainerStateTransitionByPkArgs = {
  fromState: Customer_Order_Picked_Container_States_Enum;
  toState: Customer_Order_Picked_Container_States_Enum;
};


export type Subscription_RootCustomerOrderPickedContainerStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCustomerOrderPickedContainerStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCustomerOrderPickedContainerStatesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_States_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_States_Bool_Exp>;
};


export type Subscription_RootCustomerOrderPickedContainerStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_States_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_States_Bool_Exp>;
};


export type Subscription_RootCustomerOrderPickedContainersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
};


export type Subscription_RootCustomerOrderPickedContainersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
};


export type Subscription_RootCustomerOrderReturnByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerOrderReturnItemByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerOrderReturnItemContainerByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerOrderReturnItemContainersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Item_Containers_Bool_Exp>;
};


export type Subscription_RootCustomerOrderReturnItemContainersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Item_Containers_Bool_Exp>;
};


export type Subscription_RootCustomerOrderReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


export type Subscription_RootCustomerOrderReturnItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


export type Subscription_RootCustomerOrderReturnStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCustomerOrderReturnStatesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_States_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_States_Bool_Exp>;
};


export type Subscription_RootCustomerOrderReturnStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_States_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_States_Bool_Exp>;
};


export type Subscription_RootCustomerOrderReturnsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
};


export type Subscription_RootCustomerOrderReturnsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
};


export type Subscription_RootCustomerOrderStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCustomerOrderStateTransitionByPkArgs = {
  fromState: Customer_Order_States_Enum;
  toState: Customer_Order_States_Enum;
};


export type Subscription_RootCustomerOrderStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_Order_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCustomerOrderStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_Order_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCustomerOrderStatesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_States_Order_By>>;
  where?: InputMaybe<Customer_Order_States_Bool_Exp>;
};


export type Subscription_RootCustomerOrderStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_States_Order_By>>;
  where?: InputMaybe<Customer_Order_States_Bool_Exp>;
};


export type Subscription_RootCustomerOrderStockReservationsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Stock_Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Stock_Reservations_Order_By>>;
  where?: InputMaybe<Customer_Order_Stock_Reservations_Bool_Exp>;
};


export type Subscription_RootCustomerOrderStockReservationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Stock_Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Stock_Reservations_Order_By>>;
  where?: InputMaybe<Customer_Order_Stock_Reservations_Bool_Exp>;
};


export type Subscription_RootCustomerOrdersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


export type Subscription_RootCustomerOrdersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


export type Subscription_RootCustomerPaymentMethodByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCustomerPaymentMethodsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Payment_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Payment_Method_Order_By>>;
  where?: InputMaybe<Customer_Payment_Method_Bool_Exp>;
};


export type Subscription_RootCustomerPaymentMethodsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Payment_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Payment_Method_Order_By>>;
  where?: InputMaybe<Customer_Payment_Method_Bool_Exp>;
};


export type Subscription_RootCustomerPaymentTermByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerPaymentTermsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Payment_Terms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Payment_Terms_Order_By>>;
  where?: InputMaybe<Customer_Payment_Terms_Bool_Exp>;
};


export type Subscription_RootCustomerPaymentTermsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Payment_Terms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Payment_Terms_Order_By>>;
  where?: InputMaybe<Customer_Payment_Terms_Bool_Exp>;
};


export type Subscription_RootCustomerProductAvailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Product_Availabilities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Product_Availabilities_Order_By>>;
  where?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
};


export type Subscription_RootCustomerProductAvailabilitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Product_Availabilities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Product_Availabilities_Order_By>>;
  where?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
};


export type Subscription_RootCustomerProductAvailabilityByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCustomerStateTransitionByPkArgs = {
  fromState: Customer_States_Enum;
  toState: Customer_States_Enum;
};


export type Subscription_RootCustomerStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCustomerStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_State_Transitions_Order_By>>;
  where?: InputMaybe<Customer_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCustomerStatesArgs = {
  distinct_on?: InputMaybe<Array<Customer_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_States_Order_By>>;
  where?: InputMaybe<Customer_States_Bool_Exp>;
};


export type Subscription_RootCustomerStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_States_Order_By>>;
  where?: InputMaybe<Customer_States_Bool_Exp>;
};


export type Subscription_RootCustomerTemplateSettingByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCustomerTemplateSettingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Template_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Template_Settings_Order_By>>;
  where?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
};


export type Subscription_RootCustomerTemplateSettingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Template_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Template_Settings_Order_By>>;
  where?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
};


export type Subscription_RootCustomer_Addresses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Addresses_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Addresses_Bool_Exp>;
};


export type Subscription_RootCustomer_Billing_Format_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Billing_Format_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Billing_Format_Bool_Exp>;
};


export type Subscription_RootCustomer_Billing_Frequency_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Billing_Frequency_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Billing_Frequency_Bool_Exp>;
};


export type Subscription_RootCustomer_Billing_Send_Strategies_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Billing_Send_Strategies_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Billing_Send_Strategies_Bool_Exp>;
};


export type Subscription_RootCustomer_Billing_Settings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Billing_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};


export type Subscription_RootCustomer_Delivery_Availability_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Delivery_Availability_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Delivery_Availability_Bool_Exp>;
};


export type Subscription_RootCustomer_Delivery_Note_Formats_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Delivery_Note_Formats_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Delivery_Note_Formats_Bool_Exp>;
};


export type Subscription_RootCustomer_Group_Pricing_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Group_Pricing_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Subscription_RootCustomer_Group_Pricing_Within_RangeArgs = {
  args: Customer_Group_Pricing_Within_Range_Args;
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Subscription_RootCustomer_Group_Pricing_Within_Range_AggregateArgs = {
  args: Customer_Group_Pricing_Within_Range_Args;
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Subscription_RootCustomer_Group_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Group_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Group_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCustomer_Group_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Group_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Group_States_Bool_Exp>;
};


export type Subscription_RootCustomer_Groups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Groups_Bool_Exp>;
};


export type Subscription_RootCustomer_Invoice_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Invoice_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Invoice_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCustomer_Invoice_Orders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Invoice_Orders_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Invoice_Orders_Bool_Exp>;
};


export type Subscription_RootCustomer_Invoice_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Invoice_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Invoice_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCustomer_Invoice_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Invoice_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Invoice_States_Bool_Exp>;
};


export type Subscription_RootCustomer_Invoice_Vat_Summary_Results_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Invoice_Vat_Summary_Results_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Invoice_Vat_Summary_Results_Bool_Exp>;
};


export type Subscription_RootCustomer_Invoice_Version_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Invoice_Version_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCustomer_Invoice_Versions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Invoice_Versions_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
};


export type Subscription_RootCustomer_Invoices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Invoices_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Invoices_Bool_Exp>;
};


export type Subscription_RootCustomer_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Container_Return_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Container_Return_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Container_Return_Items_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Container_Returns_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Container_Returns_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Container_Returns_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Item_Adjustment_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Item_Adjustment_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Item_Adjustment_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Item_Adjustment_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Item_Adjustment_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Item_Adjustment_Types_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Item_Adjustments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Item_Adjustments_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Item_Container_Expectations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Item_Container_Expectations_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Item_Container_Expectations_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Item_Extras_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Item_Extras_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Item_Extras_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Item_Fulfillment_Plans_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Item_Fulfillment_Plans_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Item_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Item_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Item_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Item_Return_Causes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Item_Return_Causes_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Item_Return_Causes_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Item_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Item_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Item_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Item_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Item_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Item_States_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Picked_Container_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Picked_Container_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Picked_Container_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Picked_Container_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Picked_Container_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Picked_Container_States_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Picked_Container_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Picked_Container_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Return_Item_Containers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Return_Item_Containers_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Return_Item_Containers_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Return_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Return_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Return_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Return_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Return_States_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Returns_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Returns_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_States_Bool_Exp>;
};


export type Subscription_RootCustomer_Order_Stock_Reservations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Order_Stock_Reservations_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Order_Stock_Reservations_Bool_Exp>;
};


export type Subscription_RootCustomer_Orders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Orders_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


export type Subscription_RootCustomer_Payment_Method_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Payment_Method_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Payment_Method_Bool_Exp>;
};


export type Subscription_RootCustomer_Payment_Term_SuggestionsArgs = {
  args: Customer_Payment_Term_Suggestions_Args;
  distinct_on?: InputMaybe<Array<Customer_Payment_Terms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Payment_Terms_Order_By>>;
  where?: InputMaybe<Customer_Payment_Terms_Bool_Exp>;
};


export type Subscription_RootCustomer_Payment_Term_Suggestions_AggregateArgs = {
  args: Customer_Payment_Term_Suggestions_Args;
  distinct_on?: InputMaybe<Array<Customer_Payment_Terms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Payment_Terms_Order_By>>;
  where?: InputMaybe<Customer_Payment_Terms_Bool_Exp>;
};


export type Subscription_RootCustomer_Payment_Terms_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Payment_Terms_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Payment_Terms_Bool_Exp>;
};


export type Subscription_RootCustomer_Product_Availabilities_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Product_Availabilities_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
};


export type Subscription_RootCustomer_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCustomer_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_States_Bool_Exp>;
};


export type Subscription_RootCustomer_Template_Settings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Template_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
};


export type Subscription_RootCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


export type Subscription_RootCustomersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


export type Subscription_RootCustomers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customers_Stream_Cursor_Input>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


export type Subscription_RootCuttingPatternByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCuttingPatternDraftActionByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCuttingPatternDraftActionsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Draft_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Draft_Actions_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Draft_Actions_Bool_Exp>;
};


export type Subscription_RootCuttingPatternDraftActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Draft_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Draft_Actions_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Draft_Actions_Bool_Exp>;
};


export type Subscription_RootCuttingPatternDraftStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCuttingPatternDraftStateTransitionByPkArgs = {
  fromState: Cutting_Pattern_Draft_States_Enum;
  toState: Cutting_Pattern_Draft_States_Enum;
};


export type Subscription_RootCuttingPatternDraftStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Draft_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Draft_State_Transitions_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Draft_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCuttingPatternDraftStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Draft_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Draft_State_Transitions_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Draft_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCuttingPatternDraftStatesArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Draft_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Draft_States_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Draft_States_Bool_Exp>;
};


export type Subscription_RootCuttingPatternDraftStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Draft_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Draft_States_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Draft_States_Bool_Exp>;
};


export type Subscription_RootCuttingPatternLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Logged_Actions_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCuttingPatternLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Logged_Actions_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCuttingPatternOutputByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCuttingPatternOutputLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Output_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Output_Logged_Actions_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Output_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCuttingPatternOutputLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Output_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Output_Logged_Actions_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Output_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCuttingPatternOutputsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
};


export type Subscription_RootCuttingPatternOutputsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
};


export type Subscription_RootCuttingPatternOutputsDraftByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCuttingPatternOutputsDraftsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
};


export type Subscription_RootCuttingPatternOutputsDraftsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
};


export type Subscription_RootCuttingPatternStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCuttingPatternStateTransitionByPkArgs = {
  fromState: Cutting_Pattern_States_Enum;
  toState: Cutting_Pattern_States_Enum;
};


export type Subscription_RootCuttingPatternStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_State_Transitions_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCuttingPatternStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_State_Transitions_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCuttingPatternStatesArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_States_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_States_Bool_Exp>;
};


export type Subscription_RootCuttingPatternStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_States_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_States_Bool_Exp>;
};


export type Subscription_RootCuttingPatternsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Bool_Exp>;
};


export type Subscription_RootCuttingPatternsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Bool_Exp>;
};


export type Subscription_RootCuttingPatternsDraftByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCuttingPatternsDraftsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


export type Subscription_RootCuttingPatternsDraftsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


export type Subscription_RootCutting_Pattern_Draft_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cutting_Pattern_Draft_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Cutting_Pattern_Draft_Actions_Bool_Exp>;
};


export type Subscription_RootCutting_Pattern_Draft_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cutting_Pattern_Draft_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Cutting_Pattern_Draft_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCutting_Pattern_Draft_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cutting_Pattern_Draft_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Cutting_Pattern_Draft_States_Bool_Exp>;
};


export type Subscription_RootCutting_Pattern_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cutting_Pattern_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Cutting_Pattern_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCutting_Pattern_Output_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cutting_Pattern_Output_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Cutting_Pattern_Output_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootCutting_Pattern_Outputs_Draft_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cutting_Pattern_Outputs_Draft_Stream_Cursor_Input>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
};


export type Subscription_RootCutting_Pattern_Outputs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cutting_Pattern_Outputs_Stream_Cursor_Input>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
};


export type Subscription_RootCutting_Pattern_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cutting_Pattern_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Cutting_Pattern_State_Transitions_Bool_Exp>;
};


export type Subscription_RootCutting_Pattern_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cutting_Pattern_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Cutting_Pattern_States_Bool_Exp>;
};


export type Subscription_RootCutting_Patterns_Draft_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cutting_Patterns_Draft_Stream_Cursor_Input>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


export type Subscription_RootCutting_Patterns_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cutting_Patterns_Stream_Cursor_Input>>;
  where?: InputMaybe<Cutting_Patterns_Bool_Exp>;
};


export type Subscription_RootDailyPriceListsArgs = {
  distinct_on?: InputMaybe<Array<Daily_Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Price_Lists_Order_By>>;
  where?: InputMaybe<Daily_Price_Lists_Bool_Exp>;
};


export type Subscription_RootDailyPriceListsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Price_Lists_Order_By>>;
  where?: InputMaybe<Daily_Price_Lists_Bool_Exp>;
};


export type Subscription_RootDaily_Price_Lists_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Daily_Price_Lists_Stream_Cursor_Input>>;
  where?: InputMaybe<Daily_Price_Lists_Bool_Exp>;
};


export type Subscription_RootDemandsForCoiResultsArgs = {
  distinct_on?: InputMaybe<Array<Demands_For_Coi_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Demands_For_Coi_Results_Order_By>>;
  where?: InputMaybe<Demands_For_Coi_Results_Bool_Exp>;
};


export type Subscription_RootDemandsForCoiResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Demands_For_Coi_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Demands_For_Coi_Results_Order_By>>;
  where?: InputMaybe<Demands_For_Coi_Results_Bool_Exp>;
};


export type Subscription_RootDemands_For_Coi_Results_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Demands_For_Coi_Results_Stream_Cursor_Input>>;
  where?: InputMaybe<Demands_For_Coi_Results_Bool_Exp>;
};


export type Subscription_RootDismantleableProductsResultsArgs = {
  distinct_on?: InputMaybe<Array<Dismantleable_Products_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantleable_Products_Results_Order_By>>;
  where?: InputMaybe<Dismantleable_Products_Results_Bool_Exp>;
};


export type Subscription_RootDismantleableProductsResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantleable_Products_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantleable_Products_Results_Order_By>>;
  where?: InputMaybe<Dismantleable_Products_Results_Bool_Exp>;
};


export type Subscription_RootDismantleable_ProductsArgs = {
  args: Dismantleable_Products_Args;
  distinct_on?: InputMaybe<Array<Dismantleable_Products_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantleable_Products_Results_Order_By>>;
  where?: InputMaybe<Dismantleable_Products_Results_Bool_Exp>;
};


export type Subscription_RootDismantleable_Products_AggregateArgs = {
  args: Dismantleable_Products_Args;
  distinct_on?: InputMaybe<Array<Dismantleable_Products_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantleable_Products_Results_Order_By>>;
  where?: InputMaybe<Dismantleable_Products_Results_Bool_Exp>;
};


export type Subscription_RootDismantleable_Products_Results_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dismantleable_Products_Results_Stream_Cursor_Input>>;
  where?: InputMaybe<Dismantleable_Products_Results_Bool_Exp>;
};


export type Subscription_RootDismantlingOutputWeightResultsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Output_Weight_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Output_Weight_Results_Order_By>>;
  where?: InputMaybe<Dismantling_Output_Weight_Results_Bool_Exp>;
};


export type Subscription_RootDismantlingOutputWeightResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Output_Weight_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Output_Weight_Results_Order_By>>;
  where?: InputMaybe<Dismantling_Output_Weight_Results_Bool_Exp>;
};


export type Subscription_RootDismantlingPlanByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDismantlingPlanCuttingPatternChoiceByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDismantlingPlanCuttingPatternChoicesArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>;
};


export type Subscription_RootDismantlingPlanCuttingPatternChoicesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>;
};


export type Subscription_RootDismantlingPlanDemandByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDismantlingPlanDemandsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Demands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Demands_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
};


export type Subscription_RootDismantlingPlanDemandsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Demands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Demands_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
};


export type Subscription_RootDismantlingPlanInstructionByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDismantlingPlanInstructionsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Instructions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Instructions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Instructions_Bool_Exp>;
};


export type Subscription_RootDismantlingPlanInstructionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Instructions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Instructions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Instructions_Bool_Exp>;
};


export type Subscription_RootDismantlingPlanLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Logged_Actions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootDismantlingPlanLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Logged_Actions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootDismantlingPlanPrioritiesArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Priorities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Priorities_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Priorities_Bool_Exp>;
};


export type Subscription_RootDismantlingPlanPrioritiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Priorities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Priorities_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Priorities_Bool_Exp>;
};


export type Subscription_RootDismantlingPlanPriorityByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootDismantlingPlanRemainingPieceByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDismantlingPlanRemainingPiecesArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
};


export type Subscription_RootDismantlingPlanRemainingPiecesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
};


export type Subscription_RootDismantlingPlanStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootDismantlingPlanStateTransitionByPkArgs = {
  fromState: Dismantling_Plan_States_Enum;
  toState: Dismantling_Plan_States_Enum;
};


export type Subscription_RootDismantlingPlanStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_State_Transitions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_State_Transitions_Bool_Exp>;
};


export type Subscription_RootDismantlingPlanStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_State_Transitions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_State_Transitions_Bool_Exp>;
};


export type Subscription_RootDismantlingPlanStatesArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_States_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_States_Bool_Exp>;
};


export type Subscription_RootDismantlingPlanStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_States_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_States_Bool_Exp>;
};


export type Subscription_RootDismantlingPlansArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plans_Order_By>>;
  where?: InputMaybe<Dismantling_Plans_Bool_Exp>;
};


export type Subscription_RootDismantlingPlansAggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plans_Order_By>>;
  where?: InputMaybe<Dismantling_Plans_Bool_Exp>;
};


export type Subscription_RootDismantling_Output_Weight_Results_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dismantling_Output_Weight_Results_Stream_Cursor_Input>>;
  where?: InputMaybe<Dismantling_Output_Weight_Results_Bool_Exp>;
};


export type Subscription_RootDismantling_Plan_Cutting_Pattern_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>;
};


export type Subscription_RootDismantling_Plan_Demands_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dismantling_Plan_Demands_Stream_Cursor_Input>>;
  where?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
};


export type Subscription_RootDismantling_Plan_Instructions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dismantling_Plan_Instructions_Stream_Cursor_Input>>;
  where?: InputMaybe<Dismantling_Plan_Instructions_Bool_Exp>;
};


export type Subscription_RootDismantling_Plan_InventoryArgs = {
  args: Dismantling_Plan_Inventory_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Subscription_RootDismantling_Plan_Inventory_AggregateArgs = {
  args: Dismantling_Plan_Inventory_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Subscription_RootDismantling_Plan_Inventory_Candidate_SearchArgs = {
  args: Dismantling_Plan_Inventory_Candidate_Search_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


export type Subscription_RootDismantling_Plan_Inventory_Candidate_Search_AggregateArgs = {
  args: Dismantling_Plan_Inventory_Candidate_Search_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


export type Subscription_RootDismantling_Plan_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dismantling_Plan_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Dismantling_Plan_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootDismantling_Plan_Priorities_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dismantling_Plan_Priorities_Stream_Cursor_Input>>;
  where?: InputMaybe<Dismantling_Plan_Priorities_Bool_Exp>;
};


export type Subscription_RootDismantling_Plan_Remaining_Pieces_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dismantling_Plan_Remaining_Pieces_Stream_Cursor_Input>>;
  where?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
};


export type Subscription_RootDismantling_Plan_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dismantling_Plan_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Dismantling_Plan_State_Transitions_Bool_Exp>;
};


export type Subscription_RootDismantling_Plan_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dismantling_Plan_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Dismantling_Plan_States_Bool_Exp>;
};


export type Subscription_RootDismantling_Plans_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dismantling_Plans_Stream_Cursor_Input>>;
  where?: InputMaybe<Dismantling_Plans_Bool_Exp>;
};


export type Subscription_RootEdiDeliveryNoteTypeByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEdiDeliveryNoteTypesArgs = {
  distinct_on?: InputMaybe<Array<Edi_Delivery_Note_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Edi_Delivery_Note_Types_Order_By>>;
  where?: InputMaybe<Edi_Delivery_Note_Types_Bool_Exp>;
};


export type Subscription_RootEdiDeliveryNoteTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Edi_Delivery_Note_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Edi_Delivery_Note_Types_Order_By>>;
  where?: InputMaybe<Edi_Delivery_Note_Types_Bool_Exp>;
};


export type Subscription_RootEdiInvoiceTypeByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEdiInvoiceTypesArgs = {
  distinct_on?: InputMaybe<Array<Edi_Invoice_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Edi_Invoice_Types_Order_By>>;
  where?: InputMaybe<Edi_Invoice_Types_Bool_Exp>;
};


export type Subscription_RootEdiInvoiceTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Edi_Invoice_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Edi_Invoice_Types_Order_By>>;
  where?: InputMaybe<Edi_Invoice_Types_Bool_Exp>;
};


export type Subscription_RootEdi_Delivery_Note_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Edi_Delivery_Note_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Edi_Delivery_Note_Types_Bool_Exp>;
};


export type Subscription_RootEdi_Invoice_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Edi_Invoice_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Edi_Invoice_Types_Bool_Exp>;
};


export type Subscription_RootEmailByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEmailsArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


export type Subscription_RootEmailsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


export type Subscription_RootEmails_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Emails_Stream_Cursor_Input>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


export type Subscription_RootEnvironmentStateByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEnvironmentStatesArgs = {
  distinct_on?: InputMaybe<Array<Environment_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Environment_State_Order_By>>;
  where?: InputMaybe<Environment_State_Bool_Exp>;
};


export type Subscription_RootEnvironmentStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Environment_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Environment_State_Order_By>>;
  where?: InputMaybe<Environment_State_Bool_Exp>;
};


export type Subscription_RootEnvironment_State_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Environment_State_Stream_Cursor_Input>>;
  where?: InputMaybe<Environment_State_Bool_Exp>;
};


export type Subscription_RootExchangeRateByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootExchangeRatesArgs = {
  distinct_on?: InputMaybe<Array<Exchange_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Rates_Order_By>>;
  where?: InputMaybe<Exchange_Rates_Bool_Exp>;
};


export type Subscription_RootExchangeRatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Exchange_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Rates_Order_By>>;
  where?: InputMaybe<Exchange_Rates_Bool_Exp>;
};


export type Subscription_RootExchange_Rates_For_DayArgs = {
  args: Exchange_Rates_For_Day_Args;
  distinct_on?: InputMaybe<Array<Exchange_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Rates_Order_By>>;
  where?: InputMaybe<Exchange_Rates_Bool_Exp>;
};


export type Subscription_RootExchange_Rates_For_Day_AggregateArgs = {
  args: Exchange_Rates_For_Day_Args;
  distinct_on?: InputMaybe<Array<Exchange_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Rates_Order_By>>;
  where?: InputMaybe<Exchange_Rates_Bool_Exp>;
};


export type Subscription_RootExchange_Rates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Exchange_Rates_Stream_Cursor_Input>>;
  where?: InputMaybe<Exchange_Rates_Bool_Exp>;
};


export type Subscription_RootExternalErrorByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootExternalErrorsArgs = {
  distinct_on?: InputMaybe<Array<External_Errors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<External_Errors_Order_By>>;
  where?: InputMaybe<External_Errors_Bool_Exp>;
};


export type Subscription_RootExternalErrorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<External_Errors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<External_Errors_Order_By>>;
  where?: InputMaybe<External_Errors_Bool_Exp>;
};


export type Subscription_RootExternal_Errors_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<External_Errors_Stream_Cursor_Input>>;
  where?: InputMaybe<External_Errors_Bool_Exp>;
};


export type Subscription_RootFramework_Agreements_Within_RangeArgs = {
  args: Framework_Agreements_Within_Range_Args;
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


export type Subscription_RootFramework_Agreements_Within_Range_AggregateArgs = {
  args: Framework_Agreements_Within_Range_Args;
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


export type Subscription_RootFullWorkCalendarsArgs = {
  distinct_on?: InputMaybe<Array<Full_Work_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Full_Work_Calendar_Order_By>>;
  where?: InputMaybe<Full_Work_Calendar_Bool_Exp>;
};


export type Subscription_RootFullWorkCalendarsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Full_Work_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Full_Work_Calendar_Order_By>>;
  where?: InputMaybe<Full_Work_Calendar_Bool_Exp>;
};


export type Subscription_RootFull_Work_Calendar_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Full_Work_Calendar_Stream_Cursor_Input>>;
  where?: InputMaybe<Full_Work_Calendar_Bool_Exp>;
};


export type Subscription_RootGenderByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootGender_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Gender_Stream_Cursor_Input>>;
  where?: InputMaybe<Gender_Bool_Exp>;
};


export type Subscription_RootGendersArgs = {
  distinct_on?: InputMaybe<Array<Gender_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gender_Order_By>>;
  where?: InputMaybe<Gender_Bool_Exp>;
};


export type Subscription_RootGendersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Gender_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gender_Order_By>>;
  where?: InputMaybe<Gender_Bool_Exp>;
};


export type Subscription_RootGeneratePresignedUrlResultsArgs = {
  distinct_on?: InputMaybe<Array<Generate_Presigned_Url_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Generate_Presigned_Url_Result_Order_By>>;
  where?: InputMaybe<Generate_Presigned_Url_Result_Bool_Exp>;
};


export type Subscription_RootGeneratePresignedUrlResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Generate_Presigned_Url_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Generate_Presigned_Url_Result_Order_By>>;
  where?: InputMaybe<Generate_Presigned_Url_Result_Bool_Exp>;
};


export type Subscription_RootGenerate_Presigned_Url_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Generate_Presigned_Url_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Generate_Presigned_Url_Result_Bool_Exp>;
};


export type Subscription_RootGet_Latest_Cutting_Patterns_DraftsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


export type Subscription_RootGet_Latest_Cutting_Patterns_Drafts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


export type Subscription_RootGet_Public_Tenant_InfoArgs = {
  args: Get_Public_Tenant_Info_Args;
  distinct_on?: InputMaybe<Array<Public_Tenant_Info_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Tenant_Info_Result_Order_By>>;
  where?: InputMaybe<Public_Tenant_Info_Result_Bool_Exp>;
};


export type Subscription_RootGet_Public_Tenant_Info_AggregateArgs = {
  args: Get_Public_Tenant_Info_Args;
  distinct_on?: InputMaybe<Array<Public_Tenant_Info_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Tenant_Info_Result_Order_By>>;
  where?: InputMaybe<Public_Tenant_Info_Result_Bool_Exp>;
};


export type Subscription_RootGet_Tenant_Info_With_TokenArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Info_With_Token_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Info_With_Token_Result_Order_By>>;
  where?: InputMaybe<Tenant_Info_With_Token_Result_Bool_Exp>;
};


export type Subscription_RootGet_Tenant_Info_With_Token_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Info_With_Token_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Info_With_Token_Result_Order_By>>;
  where?: InputMaybe<Tenant_Info_With_Token_Result_Bool_Exp>;
};


export type Subscription_RootGlobalSearchResultsArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


export type Subscription_RootGlobalSearchResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


export type Subscription_RootGlobal_SearchArgs = {
  args: Global_Search_Args;
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


export type Subscription_RootGlobal_Search_AggregateArgs = {
  args: Global_Search_Args;
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


export type Subscription_RootGlobal_Search_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Global_Search_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


export type Subscription_RootGs1LogisticTypeByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootGs1LogisticTypesArgs = {
  distinct_on?: InputMaybe<Array<Gs1_Logistic_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gs1_Logistic_Types_Order_By>>;
  where?: InputMaybe<Gs1_Logistic_Types_Bool_Exp>;
};


export type Subscription_RootGs1LogisticTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Gs1_Logistic_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gs1_Logistic_Types_Order_By>>;
  where?: InputMaybe<Gs1_Logistic_Types_Bool_Exp>;
};


export type Subscription_RootGs1_Logistic_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Gs1_Logistic_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Gs1_Logistic_Types_Bool_Exp>;
};


export type Subscription_RootHardwareDeviceByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootHardwareDeviceConnectionTypeByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootHardwareDeviceConnectionTypesArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Device_Connection_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Device_Connection_Types_Order_By>>;
  where?: InputMaybe<Hardware_Device_Connection_Types_Bool_Exp>;
};


export type Subscription_RootHardwareDeviceConnectionTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Device_Connection_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Device_Connection_Types_Order_By>>;
  where?: InputMaybe<Hardware_Device_Connection_Types_Bool_Exp>;
};


export type Subscription_RootHardwareDeviceTypeByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootHardwareDeviceTypesArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Device_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Device_Types_Order_By>>;
  where?: InputMaybe<Hardware_Device_Types_Bool_Exp>;
};


export type Subscription_RootHardwareDeviceTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Device_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Device_Types_Order_By>>;
  where?: InputMaybe<Hardware_Device_Types_Bool_Exp>;
};


export type Subscription_RootHardwareDevicesArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Devices_Order_By>>;
  where?: InputMaybe<Hardware_Devices_Bool_Exp>;
};


export type Subscription_RootHardwareDevicesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Devices_Order_By>>;
  where?: InputMaybe<Hardware_Devices_Bool_Exp>;
};


export type Subscription_RootHardware_Device_Connection_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Hardware_Device_Connection_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Hardware_Device_Connection_Types_Bool_Exp>;
};


export type Subscription_RootHardware_Device_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Hardware_Device_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Hardware_Device_Types_Bool_Exp>;
};


export type Subscription_RootHardware_Devices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Hardware_Devices_Stream_Cursor_Input>>;
  where?: InputMaybe<Hardware_Devices_Bool_Exp>;
};


export type Subscription_RootHierarchicalGoodsIncomeByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootHierarchicalGoodsIncomesArgs = {
  distinct_on?: InputMaybe<Array<Hierarchical_Goods_Incomes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hierarchical_Goods_Incomes_Order_By>>;
  where?: InputMaybe<Hierarchical_Goods_Incomes_Bool_Exp>;
};


export type Subscription_RootHierarchicalGoodsIncomesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Hierarchical_Goods_Incomes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hierarchical_Goods_Incomes_Order_By>>;
  where?: InputMaybe<Hierarchical_Goods_Incomes_Bool_Exp>;
};


export type Subscription_RootHierarchical_Goods_Incomes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Hierarchical_Goods_Incomes_Stream_Cursor_Input>>;
  where?: InputMaybe<Hierarchical_Goods_Incomes_Bool_Exp>;
};


export type Subscription_RootImageByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootImagesArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Subscription_RootImagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Subscription_RootImages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Images_Stream_Cursor_Input>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Subscription_RootInventoryDailyPrognosisResultsArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
};


export type Subscription_RootInventoryDailyPrognosisResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
};


export type Subscription_RootInventoryDailyUsageResultsArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Usage_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Usage_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Usage_Results_Bool_Exp>;
};


export type Subscription_RootInventoryDailyUsageResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Daily_Usage_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Usage_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Usage_Results_Bool_Exp>;
};


export type Subscription_RootInventoryItemsArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Subscription_RootInventoryItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Subscription_RootInventoryItemsByLocationsArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


export type Subscription_RootInventoryItemsByLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


export type Subscription_RootInventory_Daily_PrognosisArgs = {
  args: Inventory_Daily_Prognosis_Args;
  distinct_on?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
};


export type Subscription_RootInventory_Daily_Prognosis_AggregateArgs = {
  args: Inventory_Daily_Prognosis_Args;
  distinct_on?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Daily_Prognosis_Results_Order_By>>;
  where?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
};


export type Subscription_RootInventory_Daily_Prognosis_Results_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Inventory_Daily_Prognosis_Results_Stream_Cursor_Input>>;
  where?: InputMaybe<Inventory_Daily_Prognosis_Results_Bool_Exp>;
};


export type Subscription_RootInventory_Daily_Usage_Results_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Inventory_Daily_Usage_Results_Stream_Cursor_Input>>;
  where?: InputMaybe<Inventory_Daily_Usage_Results_Bool_Exp>;
};


export type Subscription_RootInventory_Items_By_Location_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Inventory_Items_By_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


export type Subscription_RootInventory_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Inventory_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Subscription_RootJournalEntriesArgs = {
  distinct_on?: InputMaybe<Array<Journal_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journal_Entries_Order_By>>;
  where?: InputMaybe<Journal_Entries_Bool_Exp>;
};


export type Subscription_RootJournalEntriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Journal_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journal_Entries_Order_By>>;
  where?: InputMaybe<Journal_Entries_Bool_Exp>;
};


export type Subscription_RootJournalEntryByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootJournal_Entries_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Journal_Entries_Stream_Cursor_Input>>;
  where?: InputMaybe<Journal_Entries_Bool_Exp>;
};


export type Subscription_RootJscriptTemplateByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootJscriptTemplateNameByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootJscriptTemplateNamesArgs = {
  distinct_on?: InputMaybe<Array<Jscript_Template_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jscript_Template_Names_Order_By>>;
  where?: InputMaybe<Jscript_Template_Names_Bool_Exp>;
};


export type Subscription_RootJscriptTemplateNamesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Jscript_Template_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jscript_Template_Names_Order_By>>;
  where?: InputMaybe<Jscript_Template_Names_Bool_Exp>;
};


export type Subscription_RootJscriptTemplatesArgs = {
  distinct_on?: InputMaybe<Array<Jscript_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jscript_Templates_Order_By>>;
  where?: InputMaybe<Jscript_Templates_Bool_Exp>;
};


export type Subscription_RootJscriptTemplatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Jscript_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jscript_Templates_Order_By>>;
  where?: InputMaybe<Jscript_Templates_Bool_Exp>;
};


export type Subscription_RootJscriptTemplatesLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Jscript_Templates_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jscript_Templates_Logged_Actions_Order_By>>;
  where?: InputMaybe<Jscript_Templates_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootJscriptTemplatesLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Jscript_Templates_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jscript_Templates_Logged_Actions_Order_By>>;
  where?: InputMaybe<Jscript_Templates_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootJscript_Template_Names_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Jscript_Template_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Jscript_Template_Names_Bool_Exp>;
};


export type Subscription_RootJscript_Templates_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Jscript_Templates_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Jscript_Templates_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootJscript_Templates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Jscript_Templates_Stream_Cursor_Input>>;
  where?: InputMaybe<Jscript_Templates_Bool_Exp>;
};


export type Subscription_RootLanguageByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootLanguagesArgs = {
  distinct_on?: InputMaybe<Array<Languages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Languages_Order_By>>;
  where?: InputMaybe<Languages_Bool_Exp>;
};


export type Subscription_RootLanguagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Languages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Languages_Order_By>>;
  where?: InputMaybe<Languages_Bool_Exp>;
};


export type Subscription_RootLanguages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Languages_Stream_Cursor_Input>>;
  where?: InputMaybe<Languages_Bool_Exp>;
};


export type Subscription_RootLeichmehlTemplateByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootLeichmehlTemplateTypeByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootLeichmehlTemplateTypesArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Template_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Template_Types_Order_By>>;
  where?: InputMaybe<Leichmehl_Template_Types_Bool_Exp>;
};


export type Subscription_RootLeichmehlTemplateTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Template_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Template_Types_Order_By>>;
  where?: InputMaybe<Leichmehl_Template_Types_Bool_Exp>;
};


export type Subscription_RootLeichmehlTemplateTypesLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Template_Types_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Template_Types_Logged_Actions_Order_By>>;
  where?: InputMaybe<Leichmehl_Template_Types_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootLeichmehlTemplateTypesLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Template_Types_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Template_Types_Logged_Actions_Order_By>>;
  where?: InputMaybe<Leichmehl_Template_Types_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootLeichmehlTemplatesArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Templates_Order_By>>;
  where?: InputMaybe<Leichmehl_Templates_Bool_Exp>;
};


export type Subscription_RootLeichmehlTemplatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Templates_Order_By>>;
  where?: InputMaybe<Leichmehl_Templates_Bool_Exp>;
};


export type Subscription_RootLeichmehlTemplatesLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Templates_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Templates_Logged_Actions_Order_By>>;
  where?: InputMaybe<Leichmehl_Templates_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootLeichmehlTemplatesLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Templates_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Templates_Logged_Actions_Order_By>>;
  where?: InputMaybe<Leichmehl_Templates_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootLeichmehl_Template_Types_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Leichmehl_Template_Types_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Leichmehl_Template_Types_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootLeichmehl_Template_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Leichmehl_Template_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Leichmehl_Template_Types_Bool_Exp>;
};


export type Subscription_RootLeichmehl_Templates_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Leichmehl_Templates_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Leichmehl_Templates_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootLeichmehl_Templates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Leichmehl_Templates_Stream_Cursor_Input>>;
  where?: InputMaybe<Leichmehl_Templates_Bool_Exp>;
};


export type Subscription_RootListPurchasedProductsResultsArgs = {
  distinct_on?: InputMaybe<Array<List_Purchased_Products_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Purchased_Products_Result_Order_By>>;
  where?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
};


export type Subscription_RootListPurchasedProductsResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Purchased_Products_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Purchased_Products_Result_Order_By>>;
  where?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
};


export type Subscription_RootList_Container_Inventory_By_CustomerArgs = {
  args: List_Container_Inventory_By_Customer_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Subscription_RootList_Container_Inventory_By_Customer_AggregateArgs = {
  args: List_Container_Inventory_By_Customer_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Subscription_RootList_InventoryArgs = {
  args: List_Inventory_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Subscription_RootList_Inventory_AggregateArgs = {
  args: List_Inventory_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Subscription_RootList_Inventory_By_LocationArgs = {
  args: List_Inventory_By_Location_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


export type Subscription_RootList_Inventory_By_Location_AggregateArgs = {
  args: List_Inventory_By_Location_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


export type Subscription_RootList_Inventory_By_Location_With_Product_SearchArgs = {
  args: List_Inventory_By_Location_With_Product_Search_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


export type Subscription_RootList_Inventory_By_Location_With_Product_Search_AggregateArgs = {
  args: List_Inventory_By_Location_With_Product_Search_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_By_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_By_Location_Order_By>>;
  where?: InputMaybe<Inventory_Items_By_Location_Bool_Exp>;
};


export type Subscription_RootList_Ordered_Customer_Order_ContainersArgs = {
  args: List_Ordered_Customer_Order_Containers_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Subscription_RootList_Ordered_Customer_Order_Containers_AggregateArgs = {
  args: List_Ordered_Customer_Order_Containers_Args;
  distinct_on?: InputMaybe<Array<Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inventory_Items_Order_By>>;
  where?: InputMaybe<Inventory_Items_Bool_Exp>;
};


export type Subscription_RootList_Purchased_Products_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<List_Purchased_Products_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
};


export type Subscription_RootList_Purchased_Products_With_InventoryArgs = {
  args: List_Purchased_Products_With_Inventory_Args;
  distinct_on?: InputMaybe<Array<List_Purchased_Products_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Purchased_Products_Result_Order_By>>;
  where?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
};


export type Subscription_RootList_Purchased_Products_With_Inventory_AggregateArgs = {
  args: List_Purchased_Products_With_Inventory_Args;
  distinct_on?: InputMaybe<Array<List_Purchased_Products_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Purchased_Products_Result_Order_By>>;
  where?: InputMaybe<List_Purchased_Products_Result_Bool_Exp>;
};


export type Subscription_RootLotNumberByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootLotNumberProductByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootLotNumberProductsArgs = {
  distinct_on?: InputMaybe<Array<Lot_Number_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Number_Products_Order_By>>;
  where?: InputMaybe<Lot_Number_Products_Bool_Exp>;
};


export type Subscription_RootLotNumberProductsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Lot_Number_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Number_Products_Order_By>>;
  where?: InputMaybe<Lot_Number_Products_Bool_Exp>;
};


export type Subscription_RootLotNumbersArgs = {
  distinct_on?: InputMaybe<Array<Lot_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Numbers_Order_By>>;
  where?: InputMaybe<Lot_Numbers_Bool_Exp>;
};


export type Subscription_RootLotNumbersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Lot_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Numbers_Order_By>>;
  where?: InputMaybe<Lot_Numbers_Bool_Exp>;
};


export type Subscription_RootLot_Number_Products_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lot_Number_Products_Stream_Cursor_Input>>;
  where?: InputMaybe<Lot_Number_Products_Bool_Exp>;
};


export type Subscription_RootLot_Numbers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lot_Numbers_Stream_Cursor_Input>>;
  where?: InputMaybe<Lot_Numbers_Bool_Exp>;
};


export type Subscription_RootMyUsersArgs = {
  distinct_on?: InputMaybe<Array<My_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<My_User_Order_By>>;
  where?: InputMaybe<My_User_Bool_Exp>;
};


export type Subscription_RootMyUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<My_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<My_User_Order_By>>;
  where?: InputMaybe<My_User_Bool_Exp>;
};


export type Subscription_RootMy_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<My_User_Stream_Cursor_Input>>;
  where?: InputMaybe<My_User_Bool_Exp>;
};


export type Subscription_RootNextDismantlingPlansArgs = {
  distinct_on?: InputMaybe<Array<Next_Dismantling_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Next_Dismantling_Plans_Order_By>>;
  where?: InputMaybe<Next_Dismantling_Plans_Bool_Exp>;
};


export type Subscription_RootNextDismantlingPlansAggregateArgs = {
  distinct_on?: InputMaybe<Array<Next_Dismantling_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Next_Dismantling_Plans_Order_By>>;
  where?: InputMaybe<Next_Dismantling_Plans_Bool_Exp>;
};


export type Subscription_RootNext_Dismantling_Plans_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Next_Dismantling_Plans_Stream_Cursor_Input>>;
  where?: InputMaybe<Next_Dismantling_Plans_Bool_Exp>;
};


export type Subscription_RootNotificationByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootNotificationTypeByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootNotificationTypesArgs = {
  distinct_on?: InputMaybe<Array<Notification_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Types_Order_By>>;
  where?: InputMaybe<Notification_Types_Bool_Exp>;
};


export type Subscription_RootNotificationTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Types_Order_By>>;
  where?: InputMaybe<Notification_Types_Bool_Exp>;
};


export type Subscription_RootNotification_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Notification_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Types_Bool_Exp>;
};


export type Subscription_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotificationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootOutput_Cutting_Pattern_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Subscription_RootOutput_Cutting_Pattern_Products_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Subscription_RootPartialDeliveriesArgs = {
  distinct_on?: InputMaybe<Array<Partial_Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Deliveries_Order_By>>;
  where?: InputMaybe<Partial_Deliveries_Bool_Exp>;
};


export type Subscription_RootPartialDeliveriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Partial_Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Deliveries_Order_By>>;
  where?: InputMaybe<Partial_Deliveries_Bool_Exp>;
};


export type Subscription_RootPartialDeliveryByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPartialDeliveryLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Partial_Delivery_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Delivery_Logged_Actions_Order_By>>;
  where?: InputMaybe<Partial_Delivery_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootPartialDeliveryLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Partial_Delivery_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Delivery_Logged_Actions_Order_By>>;
  where?: InputMaybe<Partial_Delivery_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootPartial_Deliveries_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partial_Deliveries_Stream_Cursor_Input>>;
  where?: InputMaybe<Partial_Deliveries_Bool_Exp>;
};


export type Subscription_RootPartial_Delivery_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partial_Delivery_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Partial_Delivery_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootPhoneByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPhonesArgs = {
  distinct_on?: InputMaybe<Array<Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phones_Order_By>>;
  where?: InputMaybe<Phones_Bool_Exp>;
};


export type Subscription_RootPhonesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phones_Order_By>>;
  where?: InputMaybe<Phones_Bool_Exp>;
};


export type Subscription_RootPhones_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Phones_Stream_Cursor_Input>>;
  where?: InputMaybe<Phones_Bool_Exp>;
};


export type Subscription_RootPickedItemByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPickedItemContainerByPkArgs = {
  customerOrderPickedContainerId: Scalars['bigint'];
  pickedItemId: Scalars['bigint'];
};


export type Subscription_RootPickedItemContainersArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_Containers_Order_By>>;
  where?: InputMaybe<Picked_Item_Containers_Bool_Exp>;
};


export type Subscription_RootPickedItemContainersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_Containers_Order_By>>;
  where?: InputMaybe<Picked_Item_Containers_Bool_Exp>;
};


export type Subscription_RootPickedItemLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Picked_Item_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootPickedItemLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Picked_Item_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootPickedItemStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootPickedItemStateTransitionByPkArgs = {
  fromState: Picked_Item_States_Enum;
  toState: Picked_Item_States_Enum;
};


export type Subscription_RootPickedItemStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_State_Transitions_Order_By>>;
  where?: InputMaybe<Picked_Item_State_Transitions_Bool_Exp>;
};


export type Subscription_RootPickedItemStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_State_Transitions_Order_By>>;
  where?: InputMaybe<Picked_Item_State_Transitions_Bool_Exp>;
};


export type Subscription_RootPickedItemStatesArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_States_Order_By>>;
  where?: InputMaybe<Picked_Item_States_Bool_Exp>;
};


export type Subscription_RootPickedItemStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_States_Order_By>>;
  where?: InputMaybe<Picked_Item_States_Bool_Exp>;
};


export type Subscription_RootPickedItemsArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


export type Subscription_RootPickedItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


export type Subscription_RootPicked_Item_Containers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Picked_Item_Containers_Stream_Cursor_Input>>;
  where?: InputMaybe<Picked_Item_Containers_Bool_Exp>;
};


export type Subscription_RootPicked_Item_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Picked_Item_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Picked_Item_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootPicked_Item_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Picked_Item_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Picked_Item_State_Transitions_Bool_Exp>;
};


export type Subscription_RootPicked_Item_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Picked_Item_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Picked_Item_States_Bool_Exp>;
};


export type Subscription_RootPicked_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Picked_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


export type Subscription_RootPickingCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Picking_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picking_Categories_Order_By>>;
  where?: InputMaybe<Picking_Categories_Bool_Exp>;
};


export type Subscription_RootPickingCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Picking_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picking_Categories_Order_By>>;
  where?: InputMaybe<Picking_Categories_Bool_Exp>;
};


export type Subscription_RootPickingCategoryByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPickingDeviationReasonByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPickingDeviationReasonsArgs = {
  distinct_on?: InputMaybe<Array<Picking_Deviation_Reasons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picking_Deviation_Reasons_Order_By>>;
  where?: InputMaybe<Picking_Deviation_Reasons_Bool_Exp>;
};


export type Subscription_RootPickingDeviationReasonsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Picking_Deviation_Reasons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picking_Deviation_Reasons_Order_By>>;
  where?: InputMaybe<Picking_Deviation_Reasons_Bool_Exp>;
};


export type Subscription_RootPicking_Categories_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Picking_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Picking_Categories_Bool_Exp>;
};


export type Subscription_RootPicking_Deviation_Reasons_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Picking_Deviation_Reasons_Stream_Cursor_Input>>;
  where?: InputMaybe<Picking_Deviation_Reasons_Bool_Exp>;
};


export type Subscription_RootPicking_Sorted_Customer_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


export type Subscription_RootPicking_Sorted_Customer_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


export type Subscription_RootPostingByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPostingsArgs = {
  distinct_on?: InputMaybe<Array<Postings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postings_Order_By>>;
  where?: InputMaybe<Postings_Bool_Exp>;
};


export type Subscription_RootPostingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Postings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postings_Order_By>>;
  where?: InputMaybe<Postings_Bool_Exp>;
};


export type Subscription_RootPostings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Postings_Stream_Cursor_Input>>;
  where?: InputMaybe<Postings_Bool_Exp>;
};


export type Subscription_RootPriceListByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPriceListsArgs = {
  distinct_on?: InputMaybe<Array<Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Lists_Order_By>>;
  where?: InputMaybe<Price_Lists_Bool_Exp>;
};


export type Subscription_RootPriceListsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Lists_Order_By>>;
  where?: InputMaybe<Price_Lists_Bool_Exp>;
};


export type Subscription_RootPrice_Lists_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Price_Lists_Stream_Cursor_Input>>;
  where?: InputMaybe<Price_Lists_Bool_Exp>;
};


export type Subscription_RootProductAllowedContainerByPkArgs = {
  containerProductId: Scalars['bigint'];
  productId: Scalars['bigint'];
};


export type Subscription_RootProductAllowedContainersArgs = {
  distinct_on?: InputMaybe<Array<Product_Allowed_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Allowed_Containers_Order_By>>;
  where?: InputMaybe<Product_Allowed_Containers_Bool_Exp>;
};


export type Subscription_RootProductAllowedContainersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Allowed_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Allowed_Containers_Order_By>>;
  where?: InputMaybe<Product_Allowed_Containers_Bool_Exp>;
};


export type Subscription_RootProductByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProductGroupByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProductGroupMemberByPkArgs = {
  productGroupId: Scalars['bigint'];
  productId: Scalars['bigint'];
};


export type Subscription_RootProductGroupMembersArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_Members_Order_By>>;
  where?: InputMaybe<Product_Group_Members_Bool_Exp>;
};


export type Subscription_RootProductGroupMembersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_Members_Order_By>>;
  where?: InputMaybe<Product_Group_Members_Bool_Exp>;
};


export type Subscription_RootProductGroupStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootProductGroupStateTransitionByPkArgs = {
  fromState: Product_Group_States_Enum;
  toState: Product_Group_States_Enum;
};


export type Subscription_RootProductGroupStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_State_Transitions_Order_By>>;
  where?: InputMaybe<Product_Group_State_Transitions_Bool_Exp>;
};


export type Subscription_RootProductGroupStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_State_Transitions_Order_By>>;
  where?: InputMaybe<Product_Group_State_Transitions_Bool_Exp>;
};


export type Subscription_RootProductGroupStatesArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_States_Order_By>>;
  where?: InputMaybe<Product_Group_States_Bool_Exp>;
};


export type Subscription_RootProductGroupStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_States_Order_By>>;
  where?: InputMaybe<Product_Group_States_Bool_Exp>;
};


export type Subscription_RootProductGroupTypeByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootProductGroupTypesArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_Types_Order_By>>;
  where?: InputMaybe<Product_Group_Types_Bool_Exp>;
};


export type Subscription_RootProductGroupTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_Types_Order_By>>;
  where?: InputMaybe<Product_Group_Types_Bool_Exp>;
};


export type Subscription_RootProductGroupsArgs = {
  distinct_on?: InputMaybe<Array<Product_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Groups_Order_By>>;
  where?: InputMaybe<Product_Groups_Bool_Exp>;
};


export type Subscription_RootProductGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Groups_Order_By>>;
  where?: InputMaybe<Product_Groups_Bool_Exp>;
};


export type Subscription_RootProductLabelSettingByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProductLabelSettingsArgs = {
  distinct_on?: InputMaybe<Array<Product_Label_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Label_Settings_Order_By>>;
  where?: InputMaybe<Product_Label_Settings_Bool_Exp>;
};


export type Subscription_RootProductLabelSettingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Label_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Label_Settings_Order_By>>;
  where?: InputMaybe<Product_Label_Settings_Bool_Exp>;
};


export type Subscription_RootProductLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Product_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Logged_Actions_Order_By>>;
  where?: InputMaybe<Product_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootProductLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Logged_Actions_Order_By>>;
  where?: InputMaybe<Product_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootProductPackagingConfigurationByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProductPackagingConfigurationsArgs = {
  distinct_on?: InputMaybe<Array<Product_Packaging_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Packaging_Configurations_Order_By>>;
  where?: InputMaybe<Product_Packaging_Configurations_Bool_Exp>;
};


export type Subscription_RootProductPackagingConfigurationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Packaging_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Packaging_Configurations_Order_By>>;
  where?: InputMaybe<Product_Packaging_Configurations_Bool_Exp>;
};


export type Subscription_RootProductPackagingConfigurationsLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Product_Packaging_Configurations_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Packaging_Configurations_Logged_Actions_Order_By>>;
  where?: InputMaybe<Product_Packaging_Configurations_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootProductPackagingConfigurationsLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Packaging_Configurations_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Packaging_Configurations_Logged_Actions_Order_By>>;
  where?: InputMaybe<Product_Packaging_Configurations_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootProductRecipeByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProductRecipeIngredientByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProductRecipeIngredientsArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_Ingredients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_Ingredients_Order_By>>;
  where?: InputMaybe<Product_Recipe_Ingredients_Bool_Exp>;
};


export type Subscription_RootProductRecipeIngredientsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_Ingredients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_Ingredients_Order_By>>;
  where?: InputMaybe<Product_Recipe_Ingredients_Bool_Exp>;
};


export type Subscription_RootProductRecipeStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootProductRecipeStateTransitionByPkArgs = {
  fromState: Product_States_Enum;
  toState: Product_States_Enum;
};


export type Subscription_RootProductRecipeStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_State_Transitions_Order_By>>;
  where?: InputMaybe<Product_Recipe_State_Transitions_Bool_Exp>;
};


export type Subscription_RootProductRecipeStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_State_Transitions_Order_By>>;
  where?: InputMaybe<Product_Recipe_State_Transitions_Bool_Exp>;
};


export type Subscription_RootProductRecipeStatesArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_States_Order_By>>;
  where?: InputMaybe<Product_Recipe_States_Bool_Exp>;
};


export type Subscription_RootProductRecipeStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_States_Order_By>>;
  where?: InputMaybe<Product_Recipe_States_Bool_Exp>;
};


export type Subscription_RootProductRecipeStepByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProductRecipeStepsArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_Steps_Order_By>>;
  where?: InputMaybe<Product_Recipe_Steps_Bool_Exp>;
};


export type Subscription_RootProductRecipeStepsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_Steps_Order_By>>;
  where?: InputMaybe<Product_Recipe_Steps_Bool_Exp>;
};


export type Subscription_RootProductRecipesArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipes_Order_By>>;
  where?: InputMaybe<Product_Recipes_Bool_Exp>;
};


export type Subscription_RootProductRecipesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipes_Order_By>>;
  where?: InputMaybe<Product_Recipes_Bool_Exp>;
};


export type Subscription_RootProductS3ObjectByPkArgs = {
  productId: Scalars['bigint'];
  s3ObjectId: Scalars['bigint'];
};


export type Subscription_RootProductS3ObjectsArgs = {
  distinct_on?: InputMaybe<Array<Product_S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_S3_Objects_Order_By>>;
  where?: InputMaybe<Product_S3_Objects_Bool_Exp>;
};


export type Subscription_RootProductS3ObjectsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_S3_Objects_Order_By>>;
  where?: InputMaybe<Product_S3_Objects_Bool_Exp>;
};


export type Subscription_RootProductSalesChannelMemberByPkArgs = {
  productId: Scalars['bigint'];
  salesChannelId: Scalars['bigint'];
};


export type Subscription_RootProductSalesChannelMembersArgs = {
  distinct_on?: InputMaybe<Array<Product_Sales_Channel_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Sales_Channel_Members_Order_By>>;
  where?: InputMaybe<Product_Sales_Channel_Members_Bool_Exp>;
};


export type Subscription_RootProductSalesChannelMembersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Sales_Channel_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Sales_Channel_Members_Order_By>>;
  where?: InputMaybe<Product_Sales_Channel_Members_Bool_Exp>;
};


export type Subscription_RootProductStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootProductStateTransitionByPkArgs = {
  fromState: Product_States_Enum;
  toState: Product_States_Enum;
};


export type Subscription_RootProductStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Product_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_State_Transitions_Order_By>>;
  where?: InputMaybe<Product_State_Transitions_Bool_Exp>;
};


export type Subscription_RootProductStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_State_Transitions_Order_By>>;
  where?: InputMaybe<Product_State_Transitions_Bool_Exp>;
};


export type Subscription_RootProductStatesArgs = {
  distinct_on?: InputMaybe<Array<Product_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_States_Order_By>>;
  where?: InputMaybe<Product_States_Bool_Exp>;
};


export type Subscription_RootProductStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_States_Order_By>>;
  where?: InputMaybe<Product_States_Bool_Exp>;
};


export type Subscription_RootProductStockLocationByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProductStockLocationsArgs = {
  distinct_on?: InputMaybe<Array<Product_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Stock_Locations_Order_By>>;
  where?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
};


export type Subscription_RootProductStockLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Stock_Locations_Order_By>>;
  where?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
};


export type Subscription_RootProductSupplierByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProductSupplierStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootProductSupplierStateTransitionByPkArgs = {
  fromState: Product_Supplier_States_Enum;
  toState: Product_Supplier_States_Enum;
};


export type Subscription_RootProductSupplierStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Product_Supplier_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Supplier_State_Transitions_Order_By>>;
  where?: InputMaybe<Product_Supplier_State_Transitions_Bool_Exp>;
};


export type Subscription_RootProductSupplierStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Supplier_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Supplier_State_Transitions_Order_By>>;
  where?: InputMaybe<Product_Supplier_State_Transitions_Bool_Exp>;
};


export type Subscription_RootProductSupplierStatesArgs = {
  distinct_on?: InputMaybe<Array<Product_Supplier_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Supplier_States_Order_By>>;
  where?: InputMaybe<Product_Supplier_States_Bool_Exp>;
};


export type Subscription_RootProductSupplierStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Supplier_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Supplier_States_Order_By>>;
  where?: InputMaybe<Product_Supplier_States_Bool_Exp>;
};


export type Subscription_RootProductSuppliersArgs = {
  distinct_on?: InputMaybe<Array<Product_Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Suppliers_Order_By>>;
  where?: InputMaybe<Product_Suppliers_Bool_Exp>;
};


export type Subscription_RootProductSuppliersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Suppliers_Order_By>>;
  where?: InputMaybe<Product_Suppliers_Bool_Exp>;
};


export type Subscription_RootProductUnitByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProductUnitConversionResultsArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_Conversion_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Conversion_Result_Order_By>>;
  where?: InputMaybe<Product_Unit_Conversion_Result_Bool_Exp>;
};


export type Subscription_RootProductUnitConversionResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_Conversion_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Conversion_Result_Order_By>>;
  where?: InputMaybe<Product_Unit_Conversion_Result_Bool_Exp>;
};


export type Subscription_RootProductUnitSalesPriceConfigByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProductUnitSalesPriceConfigsArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Order_By>>;
  where?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
};


export type Subscription_RootProductUnitSalesPriceConfigsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Order_By>>;
  where?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
};


export type Subscription_RootProductUnitStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootProductUnitStateTransitionByPkArgs = {
  fromState: Product_Unit_States_Enum;
  toState: Product_Unit_States_Enum;
};


export type Subscription_RootProductUnitStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_State_Transitions_Order_By>>;
  where?: InputMaybe<Product_Unit_State_Transitions_Bool_Exp>;
};


export type Subscription_RootProductUnitStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_State_Transitions_Order_By>>;
  where?: InputMaybe<Product_Unit_State_Transitions_Bool_Exp>;
};


export type Subscription_RootProductUnitStatesArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_States_Order_By>>;
  where?: InputMaybe<Product_Unit_States_Bool_Exp>;
};


export type Subscription_RootProductUnitStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_States_Order_By>>;
  where?: InputMaybe<Product_Unit_States_Bool_Exp>;
};


export type Subscription_RootProductUnitsArgs = {
  distinct_on?: InputMaybe<Array<Product_Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Units_Order_By>>;
  where?: InputMaybe<Product_Units_Bool_Exp>;
};


export type Subscription_RootProductUnitsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Units_Order_By>>;
  where?: InputMaybe<Product_Units_Bool_Exp>;
};


export type Subscription_RootProduct_Allowed_Containers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Allowed_Containers_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Allowed_Containers_Bool_Exp>;
};


export type Subscription_RootProduct_Group_Members_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Group_Members_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Group_Members_Bool_Exp>;
};


export type Subscription_RootProduct_Group_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Group_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Group_State_Transitions_Bool_Exp>;
};


export type Subscription_RootProduct_Group_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Group_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Group_States_Bool_Exp>;
};


export type Subscription_RootProduct_Group_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Group_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Group_Types_Bool_Exp>;
};


export type Subscription_RootProduct_Groups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Groups_Bool_Exp>;
};


export type Subscription_RootProduct_Label_Settings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Label_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Label_Settings_Bool_Exp>;
};


export type Subscription_RootProduct_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootProduct_Packaging_Configurations_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Packaging_Configurations_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Packaging_Configurations_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootProduct_Packaging_Configurations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Packaging_Configurations_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Packaging_Configurations_Bool_Exp>;
};


export type Subscription_RootProduct_Recipe_Ingredients_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Recipe_Ingredients_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Recipe_Ingredients_Bool_Exp>;
};


export type Subscription_RootProduct_Recipe_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Recipe_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Recipe_State_Transitions_Bool_Exp>;
};


export type Subscription_RootProduct_Recipe_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Recipe_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Recipe_States_Bool_Exp>;
};


export type Subscription_RootProduct_Recipe_Steps_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Recipe_Steps_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Recipe_Steps_Bool_Exp>;
};


export type Subscription_RootProduct_Recipes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Recipes_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Recipes_Bool_Exp>;
};


export type Subscription_RootProduct_S3_Objects_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_S3_Objects_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_S3_Objects_Bool_Exp>;
};


export type Subscription_RootProduct_Sales_Channel_Members_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Sales_Channel_Members_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Sales_Channel_Members_Bool_Exp>;
};


export type Subscription_RootProduct_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_State_Transitions_Bool_Exp>;
};


export type Subscription_RootProduct_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_States_Bool_Exp>;
};


export type Subscription_RootProduct_Stock_Locations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Stock_Locations_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
};


export type Subscription_RootProduct_Supplier_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Supplier_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Supplier_State_Transitions_Bool_Exp>;
};


export type Subscription_RootProduct_Supplier_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Supplier_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Supplier_States_Bool_Exp>;
};


export type Subscription_RootProduct_Suppliers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Suppliers_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Suppliers_Bool_Exp>;
};


export type Subscription_RootProduct_Unit_ConversionArgs = {
  args: Product_Unit_Conversion_Args;
  distinct_on?: InputMaybe<Array<Product_Unit_Conversion_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Conversion_Result_Order_By>>;
  where?: InputMaybe<Product_Unit_Conversion_Result_Bool_Exp>;
};


export type Subscription_RootProduct_Unit_Conversion_AggregateArgs = {
  args: Product_Unit_Conversion_Args;
  distinct_on?: InputMaybe<Array<Product_Unit_Conversion_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Conversion_Result_Order_By>>;
  where?: InputMaybe<Product_Unit_Conversion_Result_Bool_Exp>;
};


export type Subscription_RootProduct_Unit_Conversion_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Unit_Conversion_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Unit_Conversion_Result_Bool_Exp>;
};


export type Subscription_RootProduct_Unit_Sales_Price_Config_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Unit_Sales_Price_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
};


export type Subscription_RootProduct_Unit_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Unit_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Unit_State_Transitions_Bool_Exp>;
};


export type Subscription_RootProduct_Unit_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Unit_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Unit_States_Bool_Exp>;
};


export type Subscription_RootProduct_Units_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Units_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Units_Bool_Exp>;
};


export type Subscription_RootProductionSiteByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProductionSiteInputStockLocationByPkArgs = {
  productionSiteId: Scalars['bigint'];
  stockLocationId: Scalars['bigint'];
};


export type Subscription_RootProductionSiteInputStockLocationsArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Order_By>>;
  where?: InputMaybe<Production_Site_Input_Stock_Locations_Bool_Exp>;
};


export type Subscription_RootProductionSiteInputStockLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Order_By>>;
  where?: InputMaybe<Production_Site_Input_Stock_Locations_Bool_Exp>;
};


export type Subscription_RootProductionSiteOutputStockLocationByPkArgs = {
  productionSiteId: Scalars['bigint'];
  stockLocationId: Scalars['bigint'];
};


export type Subscription_RootProductionSiteOutputStockLocationsArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Output_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Output_Stock_Locations_Order_By>>;
  where?: InputMaybe<Production_Site_Output_Stock_Locations_Bool_Exp>;
};


export type Subscription_RootProductionSiteOutputStockLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Output_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Output_Stock_Locations_Order_By>>;
  where?: InputMaybe<Production_Site_Output_Stock_Locations_Bool_Exp>;
};


export type Subscription_RootProductionSiteProductGroupCalendarByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProductionSiteProductGroupCalendarsArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Order_By>>;
  where?: InputMaybe<Production_Site_Product_Group_Calendar_Bool_Exp>;
};


export type Subscription_RootProductionSiteProductGroupCalendarsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Order_By>>;
  where?: InputMaybe<Production_Site_Product_Group_Calendar_Bool_Exp>;
};


export type Subscription_RootProductionSiteStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootProductionSiteStatesArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_States_Order_By>>;
  where?: InputMaybe<Production_Site_States_Bool_Exp>;
};


export type Subscription_RootProductionSiteStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_States_Order_By>>;
  where?: InputMaybe<Production_Site_States_Bool_Exp>;
};


export type Subscription_RootProductionSitesArgs = {
  distinct_on?: InputMaybe<Array<Production_Sites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Sites_Order_By>>;
  where?: InputMaybe<Production_Sites_Bool_Exp>;
};


export type Subscription_RootProductionSitesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Production_Sites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Sites_Order_By>>;
  where?: InputMaybe<Production_Sites_Bool_Exp>;
};


export type Subscription_RootProduction_Site_Input_Stock_Locations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Production_Site_Input_Stock_Locations_Stream_Cursor_Input>>;
  where?: InputMaybe<Production_Site_Input_Stock_Locations_Bool_Exp>;
};


export type Subscription_RootProduction_Site_Output_Stock_Locations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Production_Site_Output_Stock_Locations_Stream_Cursor_Input>>;
  where?: InputMaybe<Production_Site_Output_Stock_Locations_Bool_Exp>;
};


export type Subscription_RootProduction_Site_Product_Group_Calendar_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Production_Site_Product_Group_Calendar_Stream_Cursor_Input>>;
  where?: InputMaybe<Production_Site_Product_Group_Calendar_Bool_Exp>;
};


export type Subscription_RootProduction_Site_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Production_Site_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Production_Site_States_Bool_Exp>;
};


export type Subscription_RootProduction_Sites_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Production_Sites_Stream_Cursor_Input>>;
  where?: InputMaybe<Production_Sites_Bool_Exp>;
};


export type Subscription_RootProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Subscription_RootProductsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Subscription_RootProducts_PricingArgs = {
  args: Products_Pricing_Args;
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Subscription_RootProducts_Pricing_AggregateArgs = {
  args: Products_Pricing_Args;
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Subscription_RootProducts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Products_Stream_Cursor_Input>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Subscription_RootProletarianArchivedJobByPkArgs = {
  jobId: Scalars['uuid'];
};


export type Subscription_RootProletarianArchivedJobsArgs = {
  distinct_on?: InputMaybe<Array<Proletarian_Archived_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Proletarian_Archived_Jobs_Order_By>>;
  where?: InputMaybe<Proletarian_Archived_Jobs_Bool_Exp>;
};


export type Subscription_RootProletarianArchivedJobsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Proletarian_Archived_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Proletarian_Archived_Jobs_Order_By>>;
  where?: InputMaybe<Proletarian_Archived_Jobs_Bool_Exp>;
};


export type Subscription_RootProletarianJobByPkArgs = {
  jobId: Scalars['uuid'];
};


export type Subscription_RootProletarianJobsArgs = {
  distinct_on?: InputMaybe<Array<Proletarian_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Proletarian_Jobs_Order_By>>;
  where?: InputMaybe<Proletarian_Jobs_Bool_Exp>;
};


export type Subscription_RootProletarianJobsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Proletarian_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Proletarian_Jobs_Order_By>>;
  where?: InputMaybe<Proletarian_Jobs_Bool_Exp>;
};


export type Subscription_RootProletarian_Archived_Jobs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Proletarian_Archived_Jobs_Stream_Cursor_Input>>;
  where?: InputMaybe<Proletarian_Archived_Jobs_Bool_Exp>;
};


export type Subscription_RootProletarian_Jobs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Proletarian_Jobs_Stream_Cursor_Input>>;
  where?: InputMaybe<Proletarian_Jobs_Bool_Exp>;
};


export type Subscription_RootPublicLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Public_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Logged_Actions_Order_By>>;
  where?: InputMaybe<Public_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootPublicLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Public_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Logged_Actions_Order_By>>;
  where?: InputMaybe<Public_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootPublicTenantInfoResultsArgs = {
  distinct_on?: InputMaybe<Array<Public_Tenant_Info_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Tenant_Info_Result_Order_By>>;
  where?: InputMaybe<Public_Tenant_Info_Result_Bool_Exp>;
};


export type Subscription_RootPublicTenantInfoResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Public_Tenant_Info_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Tenant_Info_Result_Order_By>>;
  where?: InputMaybe<Public_Tenant_Info_Result_Bool_Exp>;
};


export type Subscription_RootPublic_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Public_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Public_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootPublic_Tenant_Info_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Public_Tenant_Info_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Public_Tenant_Info_Result_Bool_Exp>;
};


export type Subscription_RootPurchasingSuggestionByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootPurchasingSuggestionsArgs = {
  distinct_on?: InputMaybe<Array<Purchasing_Suggestions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchasing_Suggestions_Order_By>>;
  where?: InputMaybe<Purchasing_Suggestions_Bool_Exp>;
};


export type Subscription_RootPurchasingSuggestionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Purchasing_Suggestions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchasing_Suggestions_Order_By>>;
  where?: InputMaybe<Purchasing_Suggestions_Bool_Exp>;
};


export type Subscription_RootPurchasing_Suggestions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Purchasing_Suggestions_Stream_Cursor_Input>>;
  where?: InputMaybe<Purchasing_Suggestions_Bool_Exp>;
};


export type Subscription_RootPuspc_With_Customer_PricesArgs = {
  args: Puspc_With_Customer_Prices_Args;
  distinct_on?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Order_By>>;
  where?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
};


export type Subscription_RootPuspc_With_Customer_Prices_AggregateArgs = {
  args: Puspc_With_Customer_Prices_Args;
  distinct_on?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Order_By>>;
  where?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
};


export type Subscription_RootQueryMonitoringByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootQueryMonitoringsArgs = {
  distinct_on?: InputMaybe<Array<Query_Monitoring_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Query_Monitoring_Order_By>>;
  where?: InputMaybe<Query_Monitoring_Bool_Exp>;
};


export type Subscription_RootQueryMonitoringsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Query_Monitoring_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Query_Monitoring_Order_By>>;
  where?: InputMaybe<Query_Monitoring_Bool_Exp>;
};


export type Subscription_RootQuery_Monitoring_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Query_Monitoring_Stream_Cursor_Input>>;
  where?: InputMaybe<Query_Monitoring_Bool_Exp>;
};


export type Subscription_RootRoleByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootRolePermissionsArgs = {
  distinct_on?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Permissions_Order_By>>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};


export type Subscription_RootRolePermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Permissions_Order_By>>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};


export type Subscription_RootRole_Permissions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Role_Permissions_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};


export type Subscription_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRollingInventoriesArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventories_Order_By>>;
  where?: InputMaybe<Rolling_Inventories_Bool_Exp>;
};


export type Subscription_RootRollingInventoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventories_Order_By>>;
  where?: InputMaybe<Rolling_Inventories_Bool_Exp>;
};


export type Subscription_RootRollingInventoryActorByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootRollingInventoryActorsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Actors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Actors_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
};


export type Subscription_RootRollingInventoryActorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Actors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Actors_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
};


export type Subscription_RootRollingInventoryByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootRollingInventoryItemByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootRollingInventoryItemsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


export type Subscription_RootRollingInventoryItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


export type Subscription_RootRollingInventoryLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Logged_Actions_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootRollingInventoryLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Logged_Actions_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootRollingInventoryLotSummaryItemsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
};


export type Subscription_RootRollingInventoryLotSummaryItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
};


export type Subscription_RootRollingInventoryStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootRollingInventoryStateTransitionByPkArgs = {
  fromState: Rolling_Inventory_States_Enum;
  toState: Rolling_Inventory_States_Enum;
};


export type Subscription_RootRollingInventoryStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_State_Transitions_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_State_Transitions_Bool_Exp>;
};


export type Subscription_RootRollingInventoryStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_State_Transitions_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_State_Transitions_Bool_Exp>;
};


export type Subscription_RootRollingInventoryStatesArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_States_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_States_Bool_Exp>;
};


export type Subscription_RootRollingInventoryStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_States_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_States_Bool_Exp>;
};


export type Subscription_RootRollingInventorySummaryItemsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
};


export type Subscription_RootRollingInventorySummaryItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
};


export type Subscription_RootRolling_Inventories_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rolling_Inventories_Stream_Cursor_Input>>;
  where?: InputMaybe<Rolling_Inventories_Bool_Exp>;
};


export type Subscription_RootRolling_Inventory_Actors_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rolling_Inventory_Actors_Stream_Cursor_Input>>;
  where?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
};


export type Subscription_RootRolling_Inventory_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rolling_Inventory_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


export type Subscription_RootRolling_Inventory_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rolling_Inventory_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Rolling_Inventory_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootRolling_Inventory_Lot_SummaryArgs = {
  args: Rolling_Inventory_Lot_Summary_Args;
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
};


export type Subscription_RootRolling_Inventory_Lot_Summary_AggregateArgs = {
  args: Rolling_Inventory_Lot_Summary_Args;
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Lot_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
};


export type Subscription_RootRolling_Inventory_Lot_Summary_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rolling_Inventory_Lot_Summary_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Rolling_Inventory_Lot_Summary_Items_Bool_Exp>;
};


export type Subscription_RootRolling_Inventory_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rolling_Inventory_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Rolling_Inventory_State_Transitions_Bool_Exp>;
};


export type Subscription_RootRolling_Inventory_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rolling_Inventory_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Rolling_Inventory_States_Bool_Exp>;
};


export type Subscription_RootRolling_Inventory_SummaryArgs = {
  args: Rolling_Inventory_Summary_Args;
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
};


export type Subscription_RootRolling_Inventory_Summary_AggregateArgs = {
  args: Rolling_Inventory_Summary_Args;
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Summary_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
};


export type Subscription_RootRolling_Inventory_Summary_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rolling_Inventory_Summary_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Rolling_Inventory_Summary_Items_Bool_Exp>;
};


export type Subscription_RootRoot_Cutting_Pattern_ProductsArgs = {
  args: Root_Cutting_Pattern_Products_Args;
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Subscription_RootRoot_Cutting_Pattern_Products_AggregateArgs = {
  args: Root_Cutting_Pattern_Products_Args;
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Subscription_RootRoot_Cutting_Pattern_Products_DraftArgs = {
  args: Root_Cutting_Pattern_Products_Draft_Args;
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Subscription_RootRoot_Cutting_Pattern_Products_Draft_AggregateArgs = {
  args: Root_Cutting_Pattern_Products_Draft_Args;
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Subscription_RootRoot_Cutting_Pattern_Temporal_ProductsArgs = {
  args: Root_Cutting_Pattern_Temporal_Products_Args;
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Subscription_RootRoot_Cutting_Pattern_Temporal_Products_AggregateArgs = {
  args: Root_Cutting_Pattern_Temporal_Products_Args;
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Subscription_RootS3ObjectByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootS3ObjectStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootS3ObjectStateTransitionByPkArgs = {
  fromState: S3_Object_States_Enum;
  toState: S3_Object_States_Enum;
};


export type Subscription_RootS3ObjectStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<S3_Object_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Object_State_Transitions_Order_By>>;
  where?: InputMaybe<S3_Object_State_Transitions_Bool_Exp>;
};


export type Subscription_RootS3ObjectStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<S3_Object_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Object_State_Transitions_Order_By>>;
  where?: InputMaybe<S3_Object_State_Transitions_Bool_Exp>;
};


export type Subscription_RootS3ObjectStatesArgs = {
  distinct_on?: InputMaybe<Array<S3_Object_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Object_States_Order_By>>;
  where?: InputMaybe<S3_Object_States_Bool_Exp>;
};


export type Subscription_RootS3ObjectStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<S3_Object_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Object_States_Order_By>>;
  where?: InputMaybe<S3_Object_States_Bool_Exp>;
};


export type Subscription_RootS3ObjectVisibilitiesArgs = {
  distinct_on?: InputMaybe<Array<S3_Object_Visibilities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Object_Visibilities_Order_By>>;
  where?: InputMaybe<S3_Object_Visibilities_Bool_Exp>;
};


export type Subscription_RootS3ObjectVisibilitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<S3_Object_Visibilities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Object_Visibilities_Order_By>>;
  where?: InputMaybe<S3_Object_Visibilities_Bool_Exp>;
};


export type Subscription_RootS3ObjectVisibilityByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootS3ObjectsArgs = {
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


export type Subscription_RootS3ObjectsAggregateArgs = {
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


export type Subscription_RootS3_Object_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<S3_Object_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<S3_Object_State_Transitions_Bool_Exp>;
};


export type Subscription_RootS3_Object_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<S3_Object_States_Stream_Cursor_Input>>;
  where?: InputMaybe<S3_Object_States_Bool_Exp>;
};


export type Subscription_RootS3_Object_Visibilities_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<S3_Object_Visibilities_Stream_Cursor_Input>>;
  where?: InputMaybe<S3_Object_Visibilities_Bool_Exp>;
};


export type Subscription_RootS3_Objects_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<S3_Objects_Stream_Cursor_Input>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


export type Subscription_RootSalesChannelByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSalesChannelsArgs = {
  distinct_on?: InputMaybe<Array<Sales_Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Channels_Order_By>>;
  where?: InputMaybe<Sales_Channels_Bool_Exp>;
};


export type Subscription_RootSalesChannelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Sales_Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Channels_Order_By>>;
  where?: InputMaybe<Sales_Channels_Bool_Exp>;
};


export type Subscription_RootSalesTeamByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSalesTeamStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootSalesTeamStateTransitionByPkArgs = {
  fromState: Sales_Team_States_Enum;
  toState: Sales_Team_States_Enum;
};


export type Subscription_RootSalesTeamStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Sales_Team_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Team_State_Transitions_Order_By>>;
  where?: InputMaybe<Sales_Team_State_Transitions_Bool_Exp>;
};


export type Subscription_RootSalesTeamStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Sales_Team_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Team_State_Transitions_Order_By>>;
  where?: InputMaybe<Sales_Team_State_Transitions_Bool_Exp>;
};


export type Subscription_RootSalesTeamStatesArgs = {
  distinct_on?: InputMaybe<Array<Sales_Team_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Team_States_Order_By>>;
  where?: InputMaybe<Sales_Team_States_Bool_Exp>;
};


export type Subscription_RootSalesTeamStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Sales_Team_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Team_States_Order_By>>;
  where?: InputMaybe<Sales_Team_States_Bool_Exp>;
};


export type Subscription_RootSalesTeamUserByPkArgs = {
  salesTeamId: Scalars['bigint'];
  userId: Scalars['bigint'];
};


export type Subscription_RootSalesTeamUsersArgs = {
  distinct_on?: InputMaybe<Array<Sales_Team_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Team_Users_Order_By>>;
  where?: InputMaybe<Sales_Team_Users_Bool_Exp>;
};


export type Subscription_RootSalesTeamUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Sales_Team_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Team_Users_Order_By>>;
  where?: InputMaybe<Sales_Team_Users_Bool_Exp>;
};


export type Subscription_RootSalesTeamsArgs = {
  distinct_on?: InputMaybe<Array<Sales_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Teams_Order_By>>;
  where?: InputMaybe<Sales_Teams_Bool_Exp>;
};


export type Subscription_RootSalesTeamsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Sales_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Teams_Order_By>>;
  where?: InputMaybe<Sales_Teams_Bool_Exp>;
};


export type Subscription_RootSales_Channels_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Sales_Channels_Stream_Cursor_Input>>;
  where?: InputMaybe<Sales_Channels_Bool_Exp>;
};


export type Subscription_RootSales_Team_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Sales_Team_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Sales_Team_State_Transitions_Bool_Exp>;
};


export type Subscription_RootSales_Team_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Sales_Team_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Sales_Team_States_Bool_Exp>;
};


export type Subscription_RootSales_Team_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Sales_Team_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Sales_Team_Users_Bool_Exp>;
};


export type Subscription_RootSales_Teams_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Sales_Teams_Stream_Cursor_Input>>;
  where?: InputMaybe<Sales_Teams_Bool_Exp>;
};


export type Subscription_RootSalutationByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootSalutationsArgs = {
  distinct_on?: InputMaybe<Array<Salutations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salutations_Order_By>>;
  where?: InputMaybe<Salutations_Bool_Exp>;
};


export type Subscription_RootSalutationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Salutations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salutations_Order_By>>;
  where?: InputMaybe<Salutations_Bool_Exp>;
};


export type Subscription_RootSalutations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Salutations_Stream_Cursor_Input>>;
  where?: InputMaybe<Salutations_Bool_Exp>;
};


export type Subscription_RootSearchProductsForCustomerResultsArgs = {
  distinct_on?: InputMaybe<Array<Search_Products_For_Customer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Products_For_Customer_Results_Order_By>>;
  where?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
};


export type Subscription_RootSearchProductsForCustomerResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Search_Products_For_Customer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Products_For_Customer_Results_Order_By>>;
  where?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
};


export type Subscription_RootSearchPurchasedProductsResultsArgs = {
  distinct_on?: InputMaybe<Array<Search_Purchased_Products_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Purchased_Products_Results_Order_By>>;
  where?: InputMaybe<Search_Purchased_Products_Results_Bool_Exp>;
};


export type Subscription_RootSearchPurchasedProductsResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Search_Purchased_Products_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Purchased_Products_Results_Order_By>>;
  where?: InputMaybe<Search_Purchased_Products_Results_Bool_Exp>;
};


export type Subscription_RootSearch_ContactsArgs = {
  args: Search_Contacts_Args;
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Subscription_RootSearch_Contacts_AggregateArgs = {
  args: Search_Contacts_Args;
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Subscription_RootSearch_Customer_GroupsArgs = {
  args: Search_Customer_Groups_Args;
  distinct_on?: InputMaybe<Array<Customer_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Groups_Order_By>>;
  where?: InputMaybe<Customer_Groups_Bool_Exp>;
};


export type Subscription_RootSearch_Customer_Groups_AggregateArgs = {
  args: Search_Customer_Groups_Args;
  distinct_on?: InputMaybe<Array<Customer_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Groups_Order_By>>;
  where?: InputMaybe<Customer_Groups_Bool_Exp>;
};


export type Subscription_RootSearch_Customer_Order_Item_Return_CausesArgs = {
  args: Search_Customer_Order_Item_Return_Causes_Args;
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Return_Causes_Bool_Exp>;
};


export type Subscription_RootSearch_Customer_Order_Item_Return_Causes_AggregateArgs = {
  args: Search_Customer_Order_Item_Return_Causes_Args;
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Return_Causes_Bool_Exp>;
};


export type Subscription_RootSearch_Customer_OrdersArgs = {
  args: Search_Customer_Orders_Args;
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


export type Subscription_RootSearch_Customer_Orders_AggregateArgs = {
  args: Search_Customer_Orders_Args;
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


export type Subscription_RootSearch_CustomersArgs = {
  args: Search_Customers_Args;
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


export type Subscription_RootSearch_Customers_AggregateArgs = {
  args: Search_Customers_Args;
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


export type Subscription_RootSearch_Hardware_DevicesArgs = {
  args: Search_Hardware_Devices_Args;
  distinct_on?: InputMaybe<Array<Hardware_Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Devices_Order_By>>;
  where?: InputMaybe<Hardware_Devices_Bool_Exp>;
};


export type Subscription_RootSearch_Hardware_Devices_AggregateArgs = {
  args: Search_Hardware_Devices_Args;
  distinct_on?: InputMaybe<Array<Hardware_Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Devices_Order_By>>;
  where?: InputMaybe<Hardware_Devices_Bool_Exp>;
};


export type Subscription_RootSearch_Lot_NumbersArgs = {
  args: Search_Lot_Numbers_Args;
  distinct_on?: InputMaybe<Array<Lot_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Numbers_Order_By>>;
  where?: InputMaybe<Lot_Numbers_Bool_Exp>;
};


export type Subscription_RootSearch_Lot_Numbers_AggregateArgs = {
  args: Search_Lot_Numbers_Args;
  distinct_on?: InputMaybe<Array<Lot_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Numbers_Order_By>>;
  where?: InputMaybe<Lot_Numbers_Bool_Exp>;
};


export type Subscription_RootSearch_Picking_CategoriesArgs = {
  args: Search_Picking_Categories_Args;
  distinct_on?: InputMaybe<Array<Picking_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picking_Categories_Order_By>>;
  where?: InputMaybe<Picking_Categories_Bool_Exp>;
};


export type Subscription_RootSearch_Picking_Categories_AggregateArgs = {
  args: Search_Picking_Categories_Args;
  distinct_on?: InputMaybe<Array<Picking_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picking_Categories_Order_By>>;
  where?: InputMaybe<Picking_Categories_Bool_Exp>;
};


export type Subscription_RootSearch_Product_GroupsArgs = {
  args: Search_Product_Groups_Args;
  distinct_on?: InputMaybe<Array<Product_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Groups_Order_By>>;
  where?: InputMaybe<Product_Groups_Bool_Exp>;
};


export type Subscription_RootSearch_Product_Groups_AggregateArgs = {
  args: Search_Product_Groups_Args;
  distinct_on?: InputMaybe<Array<Product_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Groups_Order_By>>;
  where?: InputMaybe<Product_Groups_Bool_Exp>;
};


export type Subscription_RootSearch_ProductsArgs = {
  args: Search_Products_Args;
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Subscription_RootSearch_Products_AggregateArgs = {
  args: Search_Products_Args;
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Subscription_RootSearch_Products_For_CustomerArgs = {
  args: Search_Products_For_Customer_Args;
  distinct_on?: InputMaybe<Array<Search_Products_For_Customer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Products_For_Customer_Results_Order_By>>;
  where?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
};


export type Subscription_RootSearch_Products_For_Customer_AggregateArgs = {
  args: Search_Products_For_Customer_Args;
  distinct_on?: InputMaybe<Array<Search_Products_For_Customer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Products_For_Customer_Results_Order_By>>;
  where?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
};


export type Subscription_RootSearch_Products_For_Customer_GroupArgs = {
  args: Search_Products_For_Customer_Group_Args;
  distinct_on?: InputMaybe<Array<Search_Products_For_Customer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Products_For_Customer_Results_Order_By>>;
  where?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
};


export type Subscription_RootSearch_Products_For_Customer_Group_AggregateArgs = {
  args: Search_Products_For_Customer_Group_Args;
  distinct_on?: InputMaybe<Array<Search_Products_For_Customer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Products_For_Customer_Results_Order_By>>;
  where?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
};


export type Subscription_RootSearch_Products_For_Customer_Results_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Search_Products_For_Customer_Results_Stream_Cursor_Input>>;
  where?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
};


export type Subscription_RootSearch_Purchased_ProductsArgs = {
  args: Search_Purchased_Products_Args;
  distinct_on?: InputMaybe<Array<Search_Products_For_Customer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Products_For_Customer_Results_Order_By>>;
  where?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
};


export type Subscription_RootSearch_Purchased_Products_AggregateArgs = {
  args: Search_Purchased_Products_Args;
  distinct_on?: InputMaybe<Array<Search_Products_For_Customer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Products_For_Customer_Results_Order_By>>;
  where?: InputMaybe<Search_Products_For_Customer_Results_Bool_Exp>;
};


export type Subscription_RootSearch_Purchased_Products_Results_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Search_Purchased_Products_Results_Stream_Cursor_Input>>;
  where?: InputMaybe<Search_Purchased_Products_Results_Bool_Exp>;
};


export type Subscription_RootSearch_Sales_TeamsArgs = {
  args: Search_Sales_Teams_Args;
  distinct_on?: InputMaybe<Array<Sales_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Teams_Order_By>>;
  where?: InputMaybe<Sales_Teams_Bool_Exp>;
};


export type Subscription_RootSearch_Sales_Teams_AggregateArgs = {
  args: Search_Sales_Teams_Args;
  distinct_on?: InputMaybe<Array<Sales_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Teams_Order_By>>;
  where?: InputMaybe<Sales_Teams_Bool_Exp>;
};


export type Subscription_RootSearch_Stock_LocationsArgs = {
  args: Search_Stock_Locations_Args;
  distinct_on?: InputMaybe<Array<Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Locations_Order_By>>;
  where?: InputMaybe<Stock_Locations_Bool_Exp>;
};


export type Subscription_RootSearch_Stock_Locations_AggregateArgs = {
  args: Search_Stock_Locations_Args;
  distinct_on?: InputMaybe<Array<Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Locations_Order_By>>;
  where?: InputMaybe<Stock_Locations_Bool_Exp>;
};


export type Subscription_RootSearch_Supplier_OrdersArgs = {
  args: Search_Supplier_Orders_Args;
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


export type Subscription_RootSearch_Supplier_Orders_AggregateArgs = {
  args: Search_Supplier_Orders_Args;
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


export type Subscription_RootSearch_SuppliersArgs = {
  args: Search_Suppliers_Args;
  distinct_on?: InputMaybe<Array<Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Suppliers_Order_By>>;
  where?: InputMaybe<Suppliers_Bool_Exp>;
};


export type Subscription_RootSearch_Suppliers_AggregateArgs = {
  args: Search_Suppliers_Args;
  distinct_on?: InputMaybe<Array<Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Suppliers_Order_By>>;
  where?: InputMaybe<Suppliers_Bool_Exp>;
};


export type Subscription_RootSearch_UsersArgs = {
  args: Search_Users_Args;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootSearch_Users_AggregateArgs = {
  args: Search_Users_Args;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootSorted_Customer_Group_Pricing_For_CustomerArgs = {
  args: Sorted_Customer_Group_Pricing_For_Customer_Args;
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Subscription_RootSorted_Customer_Group_Pricing_For_Customer_AggregateArgs = {
  args: Sorted_Customer_Group_Pricing_For_Customer_Args;
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Subscription_RootSorted_Customer_Group_Pricing_For_Customer_GroupArgs = {
  args: Sorted_Customer_Group_Pricing_For_Customer_Group_Args;
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Subscription_RootSorted_Customer_Group_Pricing_For_Customer_Group_AggregateArgs = {
  args: Sorted_Customer_Group_Pricing_For_Customer_Group_Args;
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


export type Subscription_RootSorted_Price_ListsArgs = {
  args: Sorted_Price_Lists_Args;
  distinct_on?: InputMaybe<Array<Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Lists_Order_By>>;
  where?: InputMaybe<Price_Lists_Bool_Exp>;
};


export type Subscription_RootSorted_Price_Lists_AggregateArgs = {
  args: Sorted_Price_Lists_Args;
  distinct_on?: InputMaybe<Array<Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Lists_Order_By>>;
  where?: InputMaybe<Price_Lists_Bool_Exp>;
};


export type Subscription_RootStockLocationByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootStockLocationStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootStockLocationStateTransitionByPkArgs = {
  fromState: Stock_Location_States_Enum;
  toState: Stock_Location_States_Enum;
};


export type Subscription_RootStockLocationStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Location_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Location_State_Transitions_Order_By>>;
  where?: InputMaybe<Stock_Location_State_Transitions_Bool_Exp>;
};


export type Subscription_RootStockLocationStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Location_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Location_State_Transitions_Order_By>>;
  where?: InputMaybe<Stock_Location_State_Transitions_Bool_Exp>;
};


export type Subscription_RootStockLocationStatesArgs = {
  distinct_on?: InputMaybe<Array<Stock_Location_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Location_States_Order_By>>;
  where?: InputMaybe<Stock_Location_States_Bool_Exp>;
};


export type Subscription_RootStockLocationStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Location_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Location_States_Order_By>>;
  where?: InputMaybe<Stock_Location_States_Bool_Exp>;
};


export type Subscription_RootStockLocationsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Locations_Order_By>>;
  where?: InputMaybe<Stock_Locations_Bool_Exp>;
};


export type Subscription_RootStockLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Locations_Order_By>>;
  where?: InputMaybe<Stock_Locations_Bool_Exp>;
};


export type Subscription_RootStockTransactionByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootStockTransactionLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transaction_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transaction_Logged_Actions_Order_By>>;
  where?: InputMaybe<Stock_Transaction_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootStockTransactionLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transaction_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transaction_Logged_Actions_Order_By>>;
  where?: InputMaybe<Stock_Transaction_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootStockTransactionNameByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootStockTransactionNamesArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transaction_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transaction_Names_Order_By>>;
  where?: InputMaybe<Stock_Transaction_Names_Bool_Exp>;
};


export type Subscription_RootStockTransactionNamesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transaction_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transaction_Names_Order_By>>;
  where?: InputMaybe<Stock_Transaction_Names_Bool_Exp>;
};


export type Subscription_RootStockTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


export type Subscription_RootStockTransactionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


export type Subscription_RootStock_Location_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Stock_Location_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Stock_Location_State_Transitions_Bool_Exp>;
};


export type Subscription_RootStock_Location_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Stock_Location_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Stock_Location_States_Bool_Exp>;
};


export type Subscription_RootStock_Locations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Stock_Locations_Stream_Cursor_Input>>;
  where?: InputMaybe<Stock_Locations_Bool_Exp>;
};


export type Subscription_RootStock_Transaction_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Stock_Transaction_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Stock_Transaction_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootStock_Transaction_Names_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Stock_Transaction_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Stock_Transaction_Names_Bool_Exp>;
};


export type Subscription_RootStock_Transactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Stock_Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


export type Subscription_RootSupplierAddressByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSupplierAddressesArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Addresses_Order_By>>;
  where?: InputMaybe<Supplier_Addresses_Bool_Exp>;
};


export type Subscription_RootSupplierAddressesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Addresses_Order_By>>;
  where?: InputMaybe<Supplier_Addresses_Bool_Exp>;
};


export type Subscription_RootSupplierByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSupplierOrderByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSupplierOrderGoodsIncomeItemByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSupplierOrderGoodsIncomeItemContainerByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSupplierOrderGoodsIncomeItemContainersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Bool_Exp>;
};


export type Subscription_RootSupplierOrderGoodsIncomeItemContainersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Bool_Exp>;
};


export type Subscription_RootSupplierOrderGoodsIncomeItemLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Item_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootSupplierOrderGoodsIncomeItemLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Item_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootSupplierOrderGoodsIncomeItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


export type Subscription_RootSupplierOrderGoodsIncomeItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


export type Subscription_RootSupplierOrderItemByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSupplierOrderItemLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Supplier_Order_Item_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootSupplierOrderItemLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Supplier_Order_Item_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootSupplierOrderItemReturnCauseByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSupplierOrderItemReturnCausesArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Item_Return_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Item_Return_Causes_Order_By>>;
  where?: InputMaybe<Supplier_Order_Item_Return_Causes_Bool_Exp>;
};


export type Subscription_RootSupplierOrderItemReturnCausesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Item_Return_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Item_Return_Causes_Order_By>>;
  where?: InputMaybe<Supplier_Order_Item_Return_Causes_Bool_Exp>;
};


export type Subscription_RootSupplierOrderItemStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootSupplierOrderItemStatesArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Item_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Item_States_Order_By>>;
  where?: InputMaybe<Supplier_Order_Item_States_Bool_Exp>;
};


export type Subscription_RootSupplierOrderItemStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Item_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Item_States_Order_By>>;
  where?: InputMaybe<Supplier_Order_Item_States_Bool_Exp>;
};


export type Subscription_RootSupplierOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


export type Subscription_RootSupplierOrderItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


export type Subscription_RootSupplierOrderLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Logged_Actions_Order_By>>;
  where?: InputMaybe<Supplier_Order_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootSupplierOrderLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Logged_Actions_Order_By>>;
  where?: InputMaybe<Supplier_Order_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootSupplierOrderReturnByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSupplierOrderReturnItemByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSupplierOrderReturnItemContainerByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSupplierOrderReturnItemContainersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Item_Containers_Bool_Exp>;
};


export type Subscription_RootSupplierOrderReturnItemContainersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Item_Containers_Bool_Exp>;
};


export type Subscription_RootSupplierOrderReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


export type Subscription_RootSupplierOrderReturnItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


export type Subscription_RootSupplierOrderReturnStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootSupplierOrderReturnStatesArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_States_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_States_Bool_Exp>;
};


export type Subscription_RootSupplierOrderReturnStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_States_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_States_Bool_Exp>;
};


export type Subscription_RootSupplierOrderReturnedContainerByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSupplierOrderReturnedContainersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returned_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returned_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returned_Containers_Bool_Exp>;
};


export type Subscription_RootSupplierOrderReturnedContainersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returned_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returned_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returned_Containers_Bool_Exp>;
};


export type Subscription_RootSupplierOrderReturnsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returns_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
};


export type Subscription_RootSupplierOrderReturnsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returns_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
};


export type Subscription_RootSupplierOrderStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootSupplierOrderStateTransitionByPkArgs = {
  fromState: Supplier_Order_States_Enum;
  toState: Supplier_Order_States_Enum;
};


export type Subscription_RootSupplierOrderStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_State_Transitions_Order_By>>;
  where?: InputMaybe<Supplier_Order_State_Transitions_Bool_Exp>;
};


export type Subscription_RootSupplierOrderStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_State_Transitions_Order_By>>;
  where?: InputMaybe<Supplier_Order_State_Transitions_Bool_Exp>;
};


export type Subscription_RootSupplierOrderStatesArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_States_Order_By>>;
  where?: InputMaybe<Supplier_Order_States_Bool_Exp>;
};


export type Subscription_RootSupplierOrderStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_States_Order_By>>;
  where?: InputMaybe<Supplier_Order_States_Bool_Exp>;
};


export type Subscription_RootSupplierOrdersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


export type Subscription_RootSupplierOrdersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


export type Subscription_RootSupplierStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootSupplierStateTransitionByPkArgs = {
  fromState: Supplier_States_Enum;
  toState: Supplier_States_Enum;
};


export type Subscription_RootSupplierStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_State_Transitions_Order_By>>;
  where?: InputMaybe<Supplier_State_Transitions_Bool_Exp>;
};


export type Subscription_RootSupplierStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_State_Transitions_Order_By>>;
  where?: InputMaybe<Supplier_State_Transitions_Bool_Exp>;
};


export type Subscription_RootSupplierStatesArgs = {
  distinct_on?: InputMaybe<Array<Supplier_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_States_Order_By>>;
  where?: InputMaybe<Supplier_States_Bool_Exp>;
};


export type Subscription_RootSupplierStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_States_Order_By>>;
  where?: InputMaybe<Supplier_States_Bool_Exp>;
};


export type Subscription_RootSupplier_Addresses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supplier_Addresses_Stream_Cursor_Input>>;
  where?: InputMaybe<Supplier_Addresses_Bool_Exp>;
};


export type Subscription_RootSupplier_Order_Goods_Income_Item_Containers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Stream_Cursor_Input>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Bool_Exp>;
};


export type Subscription_RootSupplier_Order_Goods_Income_Item_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supplier_Order_Goods_Income_Item_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Item_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootSupplier_Order_Goods_Income_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supplier_Order_Goods_Income_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


export type Subscription_RootSupplier_Order_Item_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supplier_Order_Item_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Supplier_Order_Item_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootSupplier_Order_Item_Return_Causes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supplier_Order_Item_Return_Causes_Stream_Cursor_Input>>;
  where?: InputMaybe<Supplier_Order_Item_Return_Causes_Bool_Exp>;
};


export type Subscription_RootSupplier_Order_Item_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supplier_Order_Item_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Supplier_Order_Item_States_Bool_Exp>;
};


export type Subscription_RootSupplier_Order_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supplier_Order_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


export type Subscription_RootSupplier_Order_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supplier_Order_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Supplier_Order_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootSupplier_Order_Return_Item_Containers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supplier_Order_Return_Item_Containers_Stream_Cursor_Input>>;
  where?: InputMaybe<Supplier_Order_Return_Item_Containers_Bool_Exp>;
};


export type Subscription_RootSupplier_Order_Return_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supplier_Order_Return_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


export type Subscription_RootSupplier_Order_Return_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supplier_Order_Return_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Supplier_Order_Return_States_Bool_Exp>;
};


export type Subscription_RootSupplier_Order_Returned_Containers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supplier_Order_Returned_Containers_Stream_Cursor_Input>>;
  where?: InputMaybe<Supplier_Order_Returned_Containers_Bool_Exp>;
};


export type Subscription_RootSupplier_Order_Returns_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supplier_Order_Returns_Stream_Cursor_Input>>;
  where?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
};


export type Subscription_RootSupplier_Order_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supplier_Order_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Supplier_Order_State_Transitions_Bool_Exp>;
};


export type Subscription_RootSupplier_Order_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supplier_Order_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Supplier_Order_States_Bool_Exp>;
};


export type Subscription_RootSupplier_Orders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supplier_Orders_Stream_Cursor_Input>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


export type Subscription_RootSupplier_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supplier_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Supplier_State_Transitions_Bool_Exp>;
};


export type Subscription_RootSupplier_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supplier_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Supplier_States_Bool_Exp>;
};


export type Subscription_RootSuppliersArgs = {
  distinct_on?: InputMaybe<Array<Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Suppliers_Order_By>>;
  where?: InputMaybe<Suppliers_Bool_Exp>;
};


export type Subscription_RootSuppliersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Suppliers_Order_By>>;
  where?: InputMaybe<Suppliers_Bool_Exp>;
};


export type Subscription_RootSuppliers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Suppliers_Stream_Cursor_Input>>;
  where?: InputMaybe<Suppliers_Bool_Exp>;
};


export type Subscription_RootTemporalPricingStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTemporalPricingStateTransitionByPkArgs = {
  fromState: Temporal_Pricing_States_Enum;
  toState: Temporal_Pricing_States_Enum;
};


export type Subscription_RootTemporalPricingStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Pricing_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Pricing_State_Transitions_Order_By>>;
  where?: InputMaybe<Temporal_Pricing_State_Transitions_Bool_Exp>;
};


export type Subscription_RootTemporalPricingStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Pricing_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Pricing_State_Transitions_Order_By>>;
  where?: InputMaybe<Temporal_Pricing_State_Transitions_Bool_Exp>;
};


export type Subscription_RootTemporalPricingStatesArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Pricing_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Pricing_States_Order_By>>;
  where?: InputMaybe<Temporal_Pricing_States_Bool_Exp>;
};


export type Subscription_RootTemporalPricingStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Pricing_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Pricing_States_Order_By>>;
  where?: InputMaybe<Temporal_Pricing_States_Bool_Exp>;
};


export type Subscription_RootTemporalProductByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootTemporalProductComparisonResultsArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Product_Comparison_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Product_Comparison_Result_Order_By>>;
  where?: InputMaybe<Temporal_Product_Comparison_Result_Bool_Exp>;
};


export type Subscription_RootTemporalProductComparisonResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Product_Comparison_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Product_Comparison_Result_Order_By>>;
  where?: InputMaybe<Temporal_Product_Comparison_Result_Bool_Exp>;
};


export type Subscription_RootTemporalProductsArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Subscription_RootTemporalProductsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Subscription_RootTemporalSalesTeamsCustomerByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootTemporalSalesTeamsCustomersArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Order_By>>;
  where?: InputMaybe<Temporal_Sales_Teams_Customers_Bool_Exp>;
};


export type Subscription_RootTemporalSalesTeamsCustomersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Order_By>>;
  where?: InputMaybe<Temporal_Sales_Teams_Customers_Bool_Exp>;
};


export type Subscription_RootTemporalVatTypeByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootTemporalVatTypesArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Vat_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Vat_Types_Order_By>>;
  where?: InputMaybe<Temporal_Vat_Types_Bool_Exp>;
};


export type Subscription_RootTemporalVatTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Vat_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Vat_Types_Order_By>>;
  where?: InputMaybe<Temporal_Vat_Types_Bool_Exp>;
};


export type Subscription_RootTemporal_Pricing_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Temporal_Pricing_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Temporal_Pricing_State_Transitions_Bool_Exp>;
};


export type Subscription_RootTemporal_Pricing_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Temporal_Pricing_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Temporal_Pricing_States_Bool_Exp>;
};


export type Subscription_RootTemporal_Product_Comparison_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Temporal_Product_Comparison_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Temporal_Product_Comparison_Result_Bool_Exp>;
};


export type Subscription_RootTemporal_Products_For_Cutting_PatternArgs = {
  args: Temporal_Products_For_Cutting_Pattern_Args;
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Subscription_RootTemporal_Products_For_Cutting_Pattern_AggregateArgs = {
  args: Temporal_Products_For_Cutting_Pattern_Args;
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Subscription_RootTemporal_Products_From_Root_Product_IdArgs = {
  args: Temporal_Products_From_Root_Product_Id_Args;
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Subscription_RootTemporal_Products_From_Root_Product_Id_AggregateArgs = {
  args: Temporal_Products_From_Root_Product_Id_Args;
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Subscription_RootTemporal_Products_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Temporal_Products_Stream_Cursor_Input>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


export type Subscription_RootTemporal_Sales_Teams_Customers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Temporal_Sales_Teams_Customers_Stream_Cursor_Input>>;
  where?: InputMaybe<Temporal_Sales_Teams_Customers_Bool_Exp>;
};


export type Subscription_RootTemporal_Vat_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Temporal_Vat_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Temporal_Vat_Types_Bool_Exp>;
};


export type Subscription_RootTenantAddressByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootTenantAddressesArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Addresses_Order_By>>;
  where?: InputMaybe<Tenant_Addresses_Bool_Exp>;
};


export type Subscription_RootTenantAddressesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Addresses_Order_By>>;
  where?: InputMaybe<Tenant_Addresses_Bool_Exp>;
};


export type Subscription_RootTenantBankAccountByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootTenantBankAccountsArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Bank_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Bank_Accounts_Order_By>>;
  where?: InputMaybe<Tenant_Bank_Accounts_Bool_Exp>;
};


export type Subscription_RootTenantBankAccountsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Bank_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Bank_Accounts_Order_By>>;
  where?: InputMaybe<Tenant_Bank_Accounts_Bool_Exp>;
};


export type Subscription_RootTenantByPkArgs = {
  tenant: Scalars['String'];
};


export type Subscription_RootTenantCompanyDetailByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootTenantCompanyDetailsArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Company_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Company_Details_Order_By>>;
  where?: InputMaybe<Tenant_Company_Details_Bool_Exp>;
};


export type Subscription_RootTenantCompanyDetailsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Company_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Company_Details_Order_By>>;
  where?: InputMaybe<Tenant_Company_Details_Bool_Exp>;
};


export type Subscription_RootTenantConfigurationByPkArgs = {
  tenant: Scalars['String'];
};


export type Subscription_RootTenantConfigurationsArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Configurations_Order_By>>;
  where?: InputMaybe<Tenant_Configurations_Bool_Exp>;
};


export type Subscription_RootTenantConfigurationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Configurations_Order_By>>;
  where?: InputMaybe<Tenant_Configurations_Bool_Exp>;
};


export type Subscription_RootTenantInfoWithTokenResultsArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Info_With_Token_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Info_With_Token_Result_Order_By>>;
  where?: InputMaybe<Tenant_Info_With_Token_Result_Bool_Exp>;
};


export type Subscription_RootTenantInfoWithTokenResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Info_With_Token_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Info_With_Token_Result_Order_By>>;
  where?: InputMaybe<Tenant_Info_With_Token_Result_Bool_Exp>;
};


export type Subscription_RootTenantLanguageByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootTenantLanguagesArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Languages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Languages_Order_By>>;
  where?: InputMaybe<Tenant_Languages_Bool_Exp>;
};


export type Subscription_RootTenantLanguagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Languages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Languages_Order_By>>;
  where?: InputMaybe<Tenant_Languages_Bool_Exp>;
};


export type Subscription_RootTenantPermissionByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootTenantPermissionsArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Permissions_Order_By>>;
  where?: InputMaybe<Tenant_Permissions_Bool_Exp>;
};


export type Subscription_RootTenantPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Permissions_Order_By>>;
  where?: InputMaybe<Tenant_Permissions_Bool_Exp>;
};


export type Subscription_RootTenant_Addresses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tenant_Addresses_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenant_Addresses_Bool_Exp>;
};


export type Subscription_RootTenant_Bank_Accounts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tenant_Bank_Accounts_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenant_Bank_Accounts_Bool_Exp>;
};


export type Subscription_RootTenant_Company_Details_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tenant_Company_Details_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenant_Company_Details_Bool_Exp>;
};


export type Subscription_RootTenant_Configurations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tenant_Configurations_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenant_Configurations_Bool_Exp>;
};


export type Subscription_RootTenant_Info_With_Token_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tenant_Info_With_Token_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenant_Info_With_Token_Result_Bool_Exp>;
};


export type Subscription_RootTenant_Languages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tenant_Languages_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenant_Languages_Bool_Exp>;
};


export type Subscription_RootTenant_Permissions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tenant_Permissions_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenant_Permissions_Bool_Exp>;
};


export type Subscription_RootTenantsArgs = {
  distinct_on?: InputMaybe<Array<Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenants_Order_By>>;
  where?: InputMaybe<Tenants_Bool_Exp>;
};


export type Subscription_RootTenantsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenants_Order_By>>;
  where?: InputMaybe<Tenants_Bool_Exp>;
};


export type Subscription_RootTenants_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tenants_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenants_Bool_Exp>;
};


export type Subscription_RootTodays_TodosArgs = {
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


export type Subscription_RootTodays_Todos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


export type Subscription_RootTodoActionByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTodoActionsArgs = {
  distinct_on?: InputMaybe<Array<Todo_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todo_Actions_Order_By>>;
  where?: InputMaybe<Todo_Actions_Bool_Exp>;
};


export type Subscription_RootTodoActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Todo_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todo_Actions_Order_By>>;
  where?: InputMaybe<Todo_Actions_Bool_Exp>;
};


export type Subscription_RootTodoByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootTodoRecurrenceByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTodoRecurrencesArgs = {
  distinct_on?: InputMaybe<Array<Todo_Recurrences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todo_Recurrences_Order_By>>;
  where?: InputMaybe<Todo_Recurrences_Bool_Exp>;
};


export type Subscription_RootTodoRecurrencesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Todo_Recurrences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todo_Recurrences_Order_By>>;
  where?: InputMaybe<Todo_Recurrences_Bool_Exp>;
};


export type Subscription_RootTodoStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTodoStatesArgs = {
  distinct_on?: InputMaybe<Array<Todo_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todo_States_Order_By>>;
  where?: InputMaybe<Todo_States_Bool_Exp>;
};


export type Subscription_RootTodoStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Todo_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todo_States_Order_By>>;
  where?: InputMaybe<Todo_States_Bool_Exp>;
};


export type Subscription_RootTodo_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Todo_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Todo_Actions_Bool_Exp>;
};


export type Subscription_RootTodo_Recurrences_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Todo_Recurrences_Stream_Cursor_Input>>;
  where?: InputMaybe<Todo_Recurrences_Bool_Exp>;
};


export type Subscription_RootTodo_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Todo_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Todo_States_Bool_Exp>;
};


export type Subscription_RootTodosArgs = {
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


export type Subscription_RootTodosAggregateArgs = {
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


export type Subscription_RootTodos_On_DayArgs = {
  args: Todos_On_Day_Args;
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


export type Subscription_RootTodos_On_Day_AggregateArgs = {
  args: Todos_On_Day_Args;
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


export type Subscription_RootTodos_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Todos_Stream_Cursor_Input>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


export type Subscription_RootTopologicallySortedCuttingPatternsResultsArgs = {
  distinct_on?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Order_By>>;
  where?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Bool_Exp>;
};


export type Subscription_RootTopologicallySortedCuttingPatternsResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Order_By>>;
  where?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Bool_Exp>;
};


export type Subscription_RootTopologically_Sorted_Cutting_PatternsArgs = {
  args: Topologically_Sorted_Cutting_Patterns_Args;
  distinct_on?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Order_By>>;
  where?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Bool_Exp>;
};


export type Subscription_RootTopologically_Sorted_Cutting_Patterns_AggregateArgs = {
  args: Topologically_Sorted_Cutting_Patterns_Args;
  distinct_on?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Order_By>>;
  where?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Bool_Exp>;
};


export type Subscription_RootTopologically_Sorted_Cutting_Patterns_Results_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Stream_Cursor_Input>>;
  where?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Bool_Exp>;
};


export type Subscription_RootTourByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootTourRouteByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootTourRoutesArgs = {
  distinct_on?: InputMaybe<Array<Tour_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tour_Routes_Order_By>>;
  where?: InputMaybe<Tour_Routes_Bool_Exp>;
};


export type Subscription_RootTourRoutesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tour_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tour_Routes_Order_By>>;
  where?: InputMaybe<Tour_Routes_Bool_Exp>;
};


export type Subscription_RootTourStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTourStateTransitionByPkArgs = {
  fromState: Tour_States_Enum;
  toState: Tour_States_Enum;
};


export type Subscription_RootTourStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<Tour_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tour_State_Transitions_Order_By>>;
  where?: InputMaybe<Tour_State_Transitions_Bool_Exp>;
};


export type Subscription_RootTourStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tour_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tour_State_Transitions_Order_By>>;
  where?: InputMaybe<Tour_State_Transitions_Bool_Exp>;
};


export type Subscription_RootTourStatesArgs = {
  distinct_on?: InputMaybe<Array<Tour_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tour_States_Order_By>>;
  where?: InputMaybe<Tour_States_Bool_Exp>;
};


export type Subscription_RootTourStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tour_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tour_States_Order_By>>;
  where?: InputMaybe<Tour_States_Bool_Exp>;
};


export type Subscription_RootTour_Routes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tour_Routes_Stream_Cursor_Input>>;
  where?: InputMaybe<Tour_Routes_Bool_Exp>;
};


export type Subscription_RootTour_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tour_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Tour_State_Transitions_Bool_Exp>;
};


export type Subscription_RootTour_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tour_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Tour_States_Bool_Exp>;
};


export type Subscription_RootToursArgs = {
  distinct_on?: InputMaybe<Array<Tours_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tours_Order_By>>;
  where?: InputMaybe<Tours_Bool_Exp>;
};


export type Subscription_RootToursAggregateArgs = {
  distinct_on?: InputMaybe<Array<Tours_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tours_Order_By>>;
  where?: InputMaybe<Tours_Bool_Exp>;
};


export type Subscription_RootTours_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tours_Stream_Cursor_Input>>;
  where?: InputMaybe<Tours_Bool_Exp>;
};


export type Subscription_RootUnitByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootUnitsArgs = {
  distinct_on?: InputMaybe<Array<Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Units_Order_By>>;
  where?: InputMaybe<Units_Bool_Exp>;
};


export type Subscription_RootUnitsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Units_Order_By>>;
  where?: InputMaybe<Units_Bool_Exp>;
};


export type Subscription_RootUnits_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Units_Stream_Cursor_Input>>;
  where?: InputMaybe<Units_Bool_Exp>;
};


export type Subscription_RootUserByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootUserSalesGoalByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootUserSalesGoalsArgs = {
  distinct_on?: InputMaybe<Array<User_Sales_Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Sales_Goals_Order_By>>;
  where?: InputMaybe<User_Sales_Goals_Bool_Exp>;
};


export type Subscription_RootUserSalesGoalsAggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Sales_Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Sales_Goals_Order_By>>;
  where?: InputMaybe<User_Sales_Goals_Bool_Exp>;
};


export type Subscription_RootUserStateByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootUserStateTransitionByPkArgs = {
  fromState: User_States_Enum;
  toState: User_States_Enum;
};


export type Subscription_RootUserStateTransitionsArgs = {
  distinct_on?: InputMaybe<Array<User_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_State_Transitions_Order_By>>;
  where?: InputMaybe<User_State_Transitions_Bool_Exp>;
};


export type Subscription_RootUserStateTransitionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<User_State_Transitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_State_Transitions_Order_By>>;
  where?: InputMaybe<User_State_Transitions_Bool_Exp>;
};


export type Subscription_RootUserStatesArgs = {
  distinct_on?: InputMaybe<Array<User_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_States_Order_By>>;
  where?: InputMaybe<User_States_Bool_Exp>;
};


export type Subscription_RootUserStatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<User_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_States_Order_By>>;
  where?: InputMaybe<User_States_Bool_Exp>;
};


export type Subscription_RootUser_Sales_Goals_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Sales_Goals_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Sales_Goals_Bool_Exp>;
};


export type Subscription_RootUser_State_Transitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_State_Transitions_Stream_Cursor_Input>>;
  where?: InputMaybe<User_State_Transitions_Bool_Exp>;
};


export type Subscription_RootUser_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_States_Stream_Cursor_Input>>;
  where?: InputMaybe<User_States_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootVatTypeByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootVatTypesArgs = {
  distinct_on?: InputMaybe<Array<Vat_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vat_Types_Order_By>>;
  where?: InputMaybe<Vat_Types_Bool_Exp>;
};


export type Subscription_RootVatTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Vat_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vat_Types_Order_By>>;
  where?: InputMaybe<Vat_Types_Bool_Exp>;
};


export type Subscription_RootVat_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Vat_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Vat_Types_Bool_Exp>;
};


export type Subscription_RootWorkCalendarByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWorkCalendarLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Work_Calendar_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Work_Calendar_Logged_Actions_Order_By>>;
  where?: InputMaybe<Work_Calendar_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootWorkCalendarLoggedActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Work_Calendar_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Work_Calendar_Logged_Actions_Order_By>>;
  where?: InputMaybe<Work_Calendar_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootWorkCalendarsArgs = {
  distinct_on?: InputMaybe<Array<Work_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Work_Calendar_Order_By>>;
  where?: InputMaybe<Work_Calendar_Bool_Exp>;
};


export type Subscription_RootWorkCalendarsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Work_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Work_Calendar_Order_By>>;
  where?: InputMaybe<Work_Calendar_Bool_Exp>;
};


export type Subscription_RootWork_Calendar_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Work_Calendar_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Work_Calendar_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootWork_Calendar_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Work_Calendar_Stream_Cursor_Input>>;
  where?: InputMaybe<Work_Calendar_Bool_Exp>;
};


export type Subscription_RootWorkingDayByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootWorkingDaysArgs = {
  distinct_on?: InputMaybe<Array<Working_Days_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Working_Days_Order_By>>;
  where?: InputMaybe<Working_Days_Bool_Exp>;
};


export type Subscription_RootWorkingDaysAggregateArgs = {
  distinct_on?: InputMaybe<Array<Working_Days_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Working_Days_Order_By>>;
  where?: InputMaybe<Working_Days_Bool_Exp>;
};


export type Subscription_RootWorking_Days_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Working_Days_Stream_Cursor_Input>>;
  where?: InputMaybe<Working_Days_Bool_Exp>;
};


export type Subscription_RootWorkstationByPkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWorkstationHardwareDeviceConnectionByPkArgs = {
  connectionType: Hardware_Device_Connection_Types_Enum;
  hardwareDeviceId: Scalars['bigint'];
  workstationId: Scalars['bigint'];
};


export type Subscription_RootWorkstationHardwareDeviceConnectionsArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Order_By>>;
  where?: InputMaybe<Workstation_Hardware_Device_Connections_Bool_Exp>;
};


export type Subscription_RootWorkstationHardwareDeviceConnectionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Order_By>>;
  where?: InputMaybe<Workstation_Hardware_Device_Connections_Bool_Exp>;
};


export type Subscription_RootWorkstationPickingCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Picking_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Picking_Categories_Order_By>>;
  where?: InputMaybe<Workstation_Picking_Categories_Bool_Exp>;
};


export type Subscription_RootWorkstationPickingCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Picking_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Picking_Categories_Order_By>>;
  where?: InputMaybe<Workstation_Picking_Categories_Bool_Exp>;
};


export type Subscription_RootWorkstation_Hardware_Device_Connections_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Workstation_Hardware_Device_Connections_Stream_Cursor_Input>>;
  where?: InputMaybe<Workstation_Hardware_Device_Connections_Bool_Exp>;
};


export type Subscription_RootWorkstation_Picking_Categories_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Workstation_Picking_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Workstation_Picking_Categories_Bool_Exp>;
};


export type Subscription_RootWorkstationsArgs = {
  distinct_on?: InputMaybe<Array<Workstations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstations_Order_By>>;
  where?: InputMaybe<Workstations_Bool_Exp>;
};


export type Subscription_RootWorkstationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Workstations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstations_Order_By>>;
  where?: InputMaybe<Workstations_Bool_Exp>;
};


export type Subscription_RootWorkstations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Workstations_Stream_Cursor_Input>>;
  where?: InputMaybe<Workstations_Bool_Exp>;
};

/** columns and relationships of "supplier_addresses" */
export type Supplier_Addresses = {
  __typename: 'supplier_addresses';
  /** An object relationship */
  Tenant: Tenants;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  state?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  /** An array relationship */
  suppliers: Array<Suppliers>;
  /** An aggregate relationship */
  suppliers_aggregate: Suppliers_Aggregate;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
  zip?: Maybe<Scalars['String']>;
};


/** columns and relationships of "supplier_addresses" */
export type Supplier_AddressesSuppliersArgs = {
  distinct_on?: InputMaybe<Array<Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Suppliers_Order_By>>;
  where?: InputMaybe<Suppliers_Bool_Exp>;
};


/** columns and relationships of "supplier_addresses" */
export type Supplier_AddressesSuppliers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Suppliers_Order_By>>;
  where?: InputMaybe<Suppliers_Bool_Exp>;
};

/** aggregated selection of "supplier_addresses" */
export type Supplier_Addresses_Aggregate = {
  __typename: 'supplier_addresses_aggregate';
  aggregate?: Maybe<Supplier_Addresses_Aggregate_Fields>;
  nodes: Array<Supplier_Addresses>;
};

export type Supplier_Addresses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Supplier_Addresses_Aggregate_Bool_Exp_Count>;
};

export type Supplier_Addresses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Supplier_Addresses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Supplier_Addresses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "supplier_addresses" */
export type Supplier_Addresses_Aggregate_Fields = {
  __typename: 'supplier_addresses_aggregate_fields';
  avg?: Maybe<Supplier_Addresses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supplier_Addresses_Max_Fields>;
  min?: Maybe<Supplier_Addresses_Min_Fields>;
  stddev?: Maybe<Supplier_Addresses_Stddev_Fields>;
  stddev_pop?: Maybe<Supplier_Addresses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supplier_Addresses_Stddev_Samp_Fields>;
  sum?: Maybe<Supplier_Addresses_Sum_Fields>;
  var_pop?: Maybe<Supplier_Addresses_Var_Pop_Fields>;
  var_samp?: Maybe<Supplier_Addresses_Var_Samp_Fields>;
  variance?: Maybe<Supplier_Addresses_Variance_Fields>;
};


/** aggregate fields of "supplier_addresses" */
export type Supplier_Addresses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supplier_Addresses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "supplier_addresses" */
export type Supplier_Addresses_Aggregate_Order_By = {
  avg?: InputMaybe<Supplier_Addresses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Supplier_Addresses_Max_Order_By>;
  min?: InputMaybe<Supplier_Addresses_Min_Order_By>;
  stddev?: InputMaybe<Supplier_Addresses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Supplier_Addresses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Supplier_Addresses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Supplier_Addresses_Sum_Order_By>;
  var_pop?: InputMaybe<Supplier_Addresses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Supplier_Addresses_Var_Samp_Order_By>;
  variance?: InputMaybe<Supplier_Addresses_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Supplier_Addresses_Avg_Fields = {
  __typename: 'supplier_addresses_avg_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "supplier_addresses" */
export type Supplier_Addresses_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "supplier_addresses". All fields are combined with a logical 'AND'. */
export type Supplier_Addresses_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Supplier_Addresses_Bool_Exp>>;
  _not?: InputMaybe<Supplier_Addresses_Bool_Exp>;
  _or?: InputMaybe<Array<Supplier_Addresses_Bool_Exp>>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  street1?: InputMaybe<String_Comparison_Exp>;
  street2?: InputMaybe<String_Comparison_Exp>;
  suppliers?: InputMaybe<Suppliers_Bool_Exp>;
  suppliers_aggregate?: InputMaybe<Suppliers_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
  zip?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "supplier_addresses" */
export enum Supplier_Addresses_Constraint {
  /** unique or primary key constraint on columns "id" */
  SupplierAddressesPkey = 'supplier_addresses_pkey'
}

/** input type for incrementing numeric columns in table "supplier_addresses" */
export type Supplier_Addresses_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "supplier_addresses" */
export type Supplier_Addresses_Insert_Input = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  suppliers?: InputMaybe<Suppliers_Arr_Rel_Insert_Input>;
  version?: InputMaybe<Scalars['bigint']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Supplier_Addresses_Max_Fields = {
  __typename: 'supplier_addresses_max_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "supplier_addresses" */
export type Supplier_Addresses_Max_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street1?: InputMaybe<Order_By>;
  street2?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  zip?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Supplier_Addresses_Min_Fields = {
  __typename: 'supplier_addresses_min_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "supplier_addresses" */
export type Supplier_Addresses_Min_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street1?: InputMaybe<Order_By>;
  street2?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  zip?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "supplier_addresses" */
export type Supplier_Addresses_Mutation_Response = {
  __typename: 'supplier_addresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Supplier_Addresses>;
};

/** input type for inserting object relation for remote table "supplier_addresses" */
export type Supplier_Addresses_Obj_Rel_Insert_Input = {
  data: Supplier_Addresses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Supplier_Addresses_On_Conflict>;
};

/** on_conflict condition type for table "supplier_addresses" */
export type Supplier_Addresses_On_Conflict = {
  constraint: Supplier_Addresses_Constraint;
  update_columns?: Array<Supplier_Addresses_Update_Column>;
  where?: InputMaybe<Supplier_Addresses_Bool_Exp>;
};

/** Ordering options when selecting data from "supplier_addresses". */
export type Supplier_Addresses_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street1?: InputMaybe<Order_By>;
  street2?: InputMaybe<Order_By>;
  suppliers_aggregate?: InputMaybe<Suppliers_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  zip?: InputMaybe<Order_By>;
};

/** primary key columns input for table: supplier_addresses */
export type Supplier_Addresses_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "supplier_addresses" */
export enum Supplier_Addresses_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  Street1 = 'street1',
  /** column name */
  Street2 = 'street2',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version',
  /** column name */
  Zip = 'zip'
}

/** input type for updating data in table "supplier_addresses" */
export type Supplier_Addresses_Set_Input = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Supplier_Addresses_Stddev_Fields = {
  __typename: 'supplier_addresses_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "supplier_addresses" */
export type Supplier_Addresses_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Supplier_Addresses_Stddev_Pop_Fields = {
  __typename: 'supplier_addresses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "supplier_addresses" */
export type Supplier_Addresses_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Supplier_Addresses_Stddev_Samp_Fields = {
  __typename: 'supplier_addresses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "supplier_addresses" */
export type Supplier_Addresses_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "supplier_addresses" */
export type Supplier_Addresses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supplier_Addresses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supplier_Addresses_Stream_Cursor_Value_Input = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Supplier_Addresses_Sum_Fields = {
  __typename: 'supplier_addresses_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "supplier_addresses" */
export type Supplier_Addresses_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "supplier_addresses" */
export enum Supplier_Addresses_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  Street1 = 'street1',
  /** column name */
  Street2 = 'street2',
  /** column name */
  Version = 'version',
  /** column name */
  Zip = 'zip'
}

export type Supplier_Addresses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Supplier_Addresses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Supplier_Addresses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supplier_Addresses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Supplier_Addresses_Var_Pop_Fields = {
  __typename: 'supplier_addresses_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "supplier_addresses" */
export type Supplier_Addresses_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Supplier_Addresses_Var_Samp_Fields = {
  __typename: 'supplier_addresses_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "supplier_addresses" */
export type Supplier_Addresses_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Supplier_Addresses_Variance_Fields = {
  __typename: 'supplier_addresses_variance_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "supplier_addresses" */
export type Supplier_Addresses_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers = {
  __typename: 'supplier_order_goods_income_item_containers';
  /** An object relationship */
  Tenant: Tenants;
  /** An object relationship */
  containerProduct: Products;
  containerProductId: Scalars['bigint'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  quantity: Scalars['bigint'];
  /** An object relationship */
  supplierOrderGoodsIncomeItem: Supplier_Order_Goods_Income_Items;
  supplierOrderGoodsIncomeItemId: Scalars['bigint'];
  tenant: Scalars['String'];
  totalTareWeight?: Maybe<Scalars['bigint']>;
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};

/** aggregated selection of "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_Aggregate = {
  __typename: 'supplier_order_goods_income_item_containers_aggregate';
  aggregate?: Maybe<Supplier_Order_Goods_Income_Item_Containers_Aggregate_Fields>;
  nodes: Array<Supplier_Order_Goods_Income_Item_Containers>;
};

export type Supplier_Order_Goods_Income_Item_Containers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Aggregate_Bool_Exp_Count>;
};

export type Supplier_Order_Goods_Income_Item_Containers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_Aggregate_Fields = {
  __typename: 'supplier_order_goods_income_item_containers_aggregate_fields';
  avg?: Maybe<Supplier_Order_Goods_Income_Item_Containers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supplier_Order_Goods_Income_Item_Containers_Max_Fields>;
  min?: Maybe<Supplier_Order_Goods_Income_Item_Containers_Min_Fields>;
  stddev?: Maybe<Supplier_Order_Goods_Income_Item_Containers_Stddev_Fields>;
  stddev_pop?: Maybe<Supplier_Order_Goods_Income_Item_Containers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supplier_Order_Goods_Income_Item_Containers_Stddev_Samp_Fields>;
  sum?: Maybe<Supplier_Order_Goods_Income_Item_Containers_Sum_Fields>;
  var_pop?: Maybe<Supplier_Order_Goods_Income_Item_Containers_Var_Pop_Fields>;
  var_samp?: Maybe<Supplier_Order_Goods_Income_Item_Containers_Var_Samp_Fields>;
  variance?: Maybe<Supplier_Order_Goods_Income_Item_Containers_Variance_Fields>;
};


/** aggregate fields of "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_Aggregate_Order_By = {
  avg?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Max_Order_By>;
  min?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Min_Order_By>;
  stddev?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Sum_Order_By>;
  var_pop?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Var_Samp_Order_By>;
  variance?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_Arr_Rel_Insert_Input = {
  data: Array<Supplier_Order_Goods_Income_Item_Containers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_On_Conflict>;
};

/** aggregate avg on columns */
export type Supplier_Order_Goods_Income_Item_Containers_Avg_Fields = {
  __typename: 'supplier_order_goods_income_item_containers_avg_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  supplierOrderGoodsIncomeItemId?: Maybe<Scalars['Float']>;
  totalTareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_Avg_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderGoodsIncomeItemId?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "supplier_order_goods_income_item_containers". All fields are combined with a logical 'AND'. */
export type Supplier_Order_Goods_Income_Item_Containers_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Bool_Exp>>;
  _not?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Bool_Exp>;
  _or?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Bool_Exp>>;
  containerProduct?: InputMaybe<Products_Bool_Exp>;
  containerProductId?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  quantity?: InputMaybe<Bigint_Comparison_Exp>;
  supplierOrderGoodsIncomeItem?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
  supplierOrderGoodsIncomeItemId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  totalTareWeight?: InputMaybe<Bigint_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "supplier_order_goods_income_item_containers" */
export enum Supplier_Order_Goods_Income_Item_Containers_Constraint {
  /** unique or primary key constraint on columns "id" */
  SupplierOrderGoodsIncomeItemContainersPkey = 'supplier_order_goods_income_item_containers_pkey'
}

/** input type for incrementing numeric columns in table "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_Inc_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  supplierOrderGoodsIncomeItemId?: InputMaybe<Scalars['bigint']>;
  totalTareWeight?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_Insert_Input = {
  containerProduct?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  containerProductId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  supplierOrderGoodsIncomeItem?: InputMaybe<Supplier_Order_Goods_Income_Items_Obj_Rel_Insert_Input>;
  supplierOrderGoodsIncomeItemId?: InputMaybe<Scalars['bigint']>;
  totalTareWeight?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Supplier_Order_Goods_Income_Item_Containers_Max_Fields = {
  __typename: 'supplier_order_goods_income_item_containers_max_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  supplierOrderGoodsIncomeItemId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  totalTareWeight?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_Max_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderGoodsIncomeItemId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Supplier_Order_Goods_Income_Item_Containers_Min_Fields = {
  __typename: 'supplier_order_goods_income_item_containers_min_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  supplierOrderGoodsIncomeItemId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  totalTareWeight?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_Min_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderGoodsIncomeItemId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_Mutation_Response = {
  __typename: 'supplier_order_goods_income_item_containers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Supplier_Order_Goods_Income_Item_Containers>;
};

/** on_conflict condition type for table "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_On_Conflict = {
  constraint: Supplier_Order_Goods_Income_Item_Containers_Constraint;
  update_columns?: Array<Supplier_Order_Goods_Income_Item_Containers_Update_Column>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Bool_Exp>;
};

/** Ordering options when selecting data from "supplier_order_goods_income_item_containers". */
export type Supplier_Order_Goods_Income_Item_Containers_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  containerProduct?: InputMaybe<Products_Order_By>;
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderGoodsIncomeItem?: InputMaybe<Supplier_Order_Goods_Income_Items_Order_By>;
  supplierOrderGoodsIncomeItemId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: supplier_order_goods_income_item_containers */
export type Supplier_Order_Goods_Income_Item_Containers_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "supplier_order_goods_income_item_containers" */
export enum Supplier_Order_Goods_Income_Item_Containers_Select_Column {
  /** column name */
  ContainerProductId = 'containerProductId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SupplierOrderGoodsIncomeItemId = 'supplierOrderGoodsIncomeItemId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  TotalTareWeight = 'totalTareWeight',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_Set_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  supplierOrderGoodsIncomeItemId?: InputMaybe<Scalars['bigint']>;
  totalTareWeight?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Supplier_Order_Goods_Income_Item_Containers_Stddev_Fields = {
  __typename: 'supplier_order_goods_income_item_containers_stddev_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  supplierOrderGoodsIncomeItemId?: Maybe<Scalars['Float']>;
  totalTareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_Stddev_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderGoodsIncomeItemId?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Supplier_Order_Goods_Income_Item_Containers_Stddev_Pop_Fields = {
  __typename: 'supplier_order_goods_income_item_containers_stddev_pop_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  supplierOrderGoodsIncomeItemId?: Maybe<Scalars['Float']>;
  totalTareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_Stddev_Pop_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderGoodsIncomeItemId?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Supplier_Order_Goods_Income_Item_Containers_Stddev_Samp_Fields = {
  __typename: 'supplier_order_goods_income_item_containers_stddev_samp_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  supplierOrderGoodsIncomeItemId?: Maybe<Scalars['Float']>;
  totalTareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_Stddev_Samp_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderGoodsIncomeItemId?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supplier_Order_Goods_Income_Item_Containers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supplier_Order_Goods_Income_Item_Containers_Stream_Cursor_Value_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  supplierOrderGoodsIncomeItemId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  totalTareWeight?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Supplier_Order_Goods_Income_Item_Containers_Sum_Fields = {
  __typename: 'supplier_order_goods_income_item_containers_sum_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  supplierOrderGoodsIncomeItemId?: Maybe<Scalars['bigint']>;
  totalTareWeight?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_Sum_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderGoodsIncomeItemId?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "supplier_order_goods_income_item_containers" */
export enum Supplier_Order_Goods_Income_Item_Containers_Update_Column {
  /** column name */
  ContainerProductId = 'containerProductId',
  /** column name */
  Id = 'id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SupplierOrderGoodsIncomeItemId = 'supplierOrderGoodsIncomeItemId',
  /** column name */
  TotalTareWeight = 'totalTareWeight',
  /** column name */
  Version = 'version'
}

export type Supplier_Order_Goods_Income_Item_Containers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supplier_Order_Goods_Income_Item_Containers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Supplier_Order_Goods_Income_Item_Containers_Var_Pop_Fields = {
  __typename: 'supplier_order_goods_income_item_containers_var_pop_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  supplierOrderGoodsIncomeItemId?: Maybe<Scalars['Float']>;
  totalTareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_Var_Pop_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderGoodsIncomeItemId?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Supplier_Order_Goods_Income_Item_Containers_Var_Samp_Fields = {
  __typename: 'supplier_order_goods_income_item_containers_var_samp_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  supplierOrderGoodsIncomeItemId?: Maybe<Scalars['Float']>;
  totalTareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_Var_Samp_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderGoodsIncomeItemId?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Supplier_Order_Goods_Income_Item_Containers_Variance_Fields = {
  __typename: 'supplier_order_goods_income_item_containers_variance_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  supplierOrderGoodsIncomeItemId?: Maybe<Scalars['Float']>;
  totalTareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "supplier_order_goods_income_item_containers" */
export type Supplier_Order_Goods_Income_Item_Containers_Variance_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderGoodsIncomeItemId?: InputMaybe<Order_By>;
  totalTareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "supplier_order_goods_income_item_logged_actions" */
export type Supplier_Order_Goods_Income_Item_Logged_Actions = {
  __typename: 'supplier_order_goods_income_item_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "supplier_order_goods_income_item_logged_actions" */
export type Supplier_Order_Goods_Income_Item_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "supplier_order_goods_income_item_logged_actions" */
export type Supplier_Order_Goods_Income_Item_Logged_Actions_Aggregate = {
  __typename: 'supplier_order_goods_income_item_logged_actions_aggregate';
  aggregate?: Maybe<Supplier_Order_Goods_Income_Item_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Supplier_Order_Goods_Income_Item_Logged_Actions>;
};

/** aggregate fields of "supplier_order_goods_income_item_logged_actions" */
export type Supplier_Order_Goods_Income_Item_Logged_Actions_Aggregate_Fields = {
  __typename: 'supplier_order_goods_income_item_logged_actions_aggregate_fields';
  avg?: Maybe<Supplier_Order_Goods_Income_Item_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supplier_Order_Goods_Income_Item_Logged_Actions_Max_Fields>;
  min?: Maybe<Supplier_Order_Goods_Income_Item_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Supplier_Order_Goods_Income_Item_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Supplier_Order_Goods_Income_Item_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supplier_Order_Goods_Income_Item_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Supplier_Order_Goods_Income_Item_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Supplier_Order_Goods_Income_Item_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Supplier_Order_Goods_Income_Item_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Supplier_Order_Goods_Income_Item_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "supplier_order_goods_income_item_logged_actions" */
export type Supplier_Order_Goods_Income_Item_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Supplier_Order_Goods_Income_Item_Logged_Actions_Avg_Fields = {
  __typename: 'supplier_order_goods_income_item_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "supplier_order_goods_income_item_logged_actions". All fields are combined with a logical 'AND'. */
export type Supplier_Order_Goods_Income_Item_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Supplier_Order_Goods_Income_Item_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Supplier_Order_Goods_Income_Item_Logged_Actions_Max_Fields = {
  __typename: 'supplier_order_goods_income_item_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Supplier_Order_Goods_Income_Item_Logged_Actions_Min_Fields = {
  __typename: 'supplier_order_goods_income_item_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "supplier_order_goods_income_item_logged_actions". */
export type Supplier_Order_Goods_Income_Item_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "supplier_order_goods_income_item_logged_actions" */
export enum Supplier_Order_Goods_Income_Item_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Role = 'role',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Supplier_Order_Goods_Income_Item_Logged_Actions_Stddev_Fields = {
  __typename: 'supplier_order_goods_income_item_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Supplier_Order_Goods_Income_Item_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'supplier_order_goods_income_item_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Supplier_Order_Goods_Income_Item_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'supplier_order_goods_income_item_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "supplier_order_goods_income_item_logged_actions" */
export type Supplier_Order_Goods_Income_Item_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supplier_Order_Goods_Income_Item_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supplier_Order_Goods_Income_Item_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  role?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Supplier_Order_Goods_Income_Item_Logged_Actions_Sum_Fields = {
  __typename: 'supplier_order_goods_income_item_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Supplier_Order_Goods_Income_Item_Logged_Actions_Var_Pop_Fields = {
  __typename: 'supplier_order_goods_income_item_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Supplier_Order_Goods_Income_Item_Logged_Actions_Var_Samp_Fields = {
  __typename: 'supplier_order_goods_income_item_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Supplier_Order_Goods_Income_Item_Logged_Actions_Variance_Fields = {
  __typename: 'supplier_order_goods_income_item_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items = {
  __typename: 'supplier_order_goods_income_items';
  /** An object relationship */
  LotNumber?: Maybe<Lot_Numbers>;
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  currency: Currencies_Enum;
  id: Scalars['bigint'];
  /** An object relationship */
  journalEntry?: Maybe<Journal_Entries>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  purchasingPrice: Scalars['bigint'];
  receivedMonetaryQuantity: Scalars['bigint'];
  /** An object relationship */
  receivedMonetaryUnit: Product_Units;
  receivedMonetaryUnitId: Scalars['bigint'];
  receivedStorageQuantity: Scalars['bigint'];
  /** An object relationship */
  receivedStorageUnit: Product_Units;
  receivedStorageUnitId: Scalars['bigint'];
  /** An array relationship */
  supplierOrderGoodsIncomeItemContainers: Array<Supplier_Order_Goods_Income_Item_Containers>;
  /** An aggregate relationship */
  supplierOrderGoodsIncomeItemContainers_aggregate: Supplier_Order_Goods_Income_Item_Containers_Aggregate;
  /** An object relationship */
  supplierOrderItem: Supplier_Order_Items;
  supplierOrderItemId: Scalars['bigint'];
  tareWeight?: Maybe<Scalars['bigint']>;
  targetMonetaryQuantity?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  targetMonetaryUnit?: Maybe<Product_Units>;
  targetMonetaryUnitId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  targetStockLocation?: Maybe<Stock_Locations>;
  targetStockLocationId?: Maybe<Scalars['bigint']>;
  targetStorageQuantity?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  targetStorageUnit?: Maybe<Product_Units>;
  targetStorageUnitId?: Maybe<Scalars['bigint']>;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_ItemsSupplierOrderGoodsIncomeItemContainersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Bool_Exp>;
};


/** columns and relationships of "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_ItemsSupplierOrderGoodsIncomeItemContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Bool_Exp>;
};

/** aggregated selection of "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Aggregate = {
  __typename: 'supplier_order_goods_income_items_aggregate';
  aggregate?: Maybe<Supplier_Order_Goods_Income_Items_Aggregate_Fields>;
  nodes: Array<Supplier_Order_Goods_Income_Items>;
};

export type Supplier_Order_Goods_Income_Items_Aggregate_Bool_Exp = {
  count?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Bool_Exp_Count>;
};

export type Supplier_Order_Goods_Income_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Aggregate_Fields = {
  __typename: 'supplier_order_goods_income_items_aggregate_fields';
  avg?: Maybe<Supplier_Order_Goods_Income_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supplier_Order_Goods_Income_Items_Max_Fields>;
  min?: Maybe<Supplier_Order_Goods_Income_Items_Min_Fields>;
  stddev?: Maybe<Supplier_Order_Goods_Income_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Supplier_Order_Goods_Income_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supplier_Order_Goods_Income_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Supplier_Order_Goods_Income_Items_Sum_Fields>;
  var_pop?: Maybe<Supplier_Order_Goods_Income_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Supplier_Order_Goods_Income_Items_Var_Samp_Fields>;
  variance?: Maybe<Supplier_Order_Goods_Income_Items_Variance_Fields>;
};


/** aggregate fields of "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Aggregate_Order_By = {
  avg?: InputMaybe<Supplier_Order_Goods_Income_Items_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Supplier_Order_Goods_Income_Items_Max_Order_By>;
  min?: InputMaybe<Supplier_Order_Goods_Income_Items_Min_Order_By>;
  stddev?: InputMaybe<Supplier_Order_Goods_Income_Items_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Supplier_Order_Goods_Income_Items_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Supplier_Order_Goods_Income_Items_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Supplier_Order_Goods_Income_Items_Sum_Order_By>;
  var_pop?: InputMaybe<Supplier_Order_Goods_Income_Items_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Supplier_Order_Goods_Income_Items_Var_Samp_Order_By>;
  variance?: InputMaybe<Supplier_Order_Goods_Income_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Arr_Rel_Insert_Input = {
  data: Array<Supplier_Order_Goods_Income_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Supplier_Order_Goods_Income_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Supplier_Order_Goods_Income_Items_Avg_Fields = {
  __typename: 'supplier_order_goods_income_items_avg_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  purchasingPrice?: Maybe<Scalars['Float']>;
  receivedMonetaryQuantity?: Maybe<Scalars['Float']>;
  receivedMonetaryUnitId?: Maybe<Scalars['Float']>;
  receivedStorageQuantity?: Maybe<Scalars['Float']>;
  receivedStorageUnitId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  receivedMonetaryQuantity?: InputMaybe<Order_By>;
  receivedMonetaryUnitId?: InputMaybe<Order_By>;
  receivedStorageQuantity?: InputMaybe<Order_By>;
  receivedStorageUnitId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "supplier_order_goods_income_items". All fields are combined with a logical 'AND'. */
export type Supplier_Order_Goods_Income_Items_Bool_Exp = {
  LotNumber?: InputMaybe<Lot_Numbers_Bool_Exp>;
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Bool_Exp>>;
  _not?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  journalEntry?: InputMaybe<Journal_Entries_Bool_Exp>;
  journalEntryId?: InputMaybe<Bigint_Comparison_Exp>;
  lotNumber?: InputMaybe<String_Comparison_Exp>;
  lotNumberId?: InputMaybe<Bigint_Comparison_Exp>;
  purchasingPrice?: InputMaybe<Bigint_Comparison_Exp>;
  receivedMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  receivedMonetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  receivedMonetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  receivedStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  receivedStorageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  receivedStorageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  supplierOrderGoodsIncomeItemContainers?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Bool_Exp>;
  supplierOrderGoodsIncomeItemContainers_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Aggregate_Bool_Exp>;
  supplierOrderItem?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
  supplierOrderItemId?: InputMaybe<Bigint_Comparison_Exp>;
  tareWeight?: InputMaybe<Bigint_Comparison_Exp>;
  targetMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  targetMonetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  targetMonetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  targetStockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  targetStockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  targetStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  targetStorageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  targetStorageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "supplier_order_goods_income_items" */
export enum Supplier_Order_Goods_Income_Items_Constraint {
  /** unique or primary key constraint on columns "id" */
  SupplierOrderGoodsIncomeItemsPkey = 'supplier_order_goods_income_items_pkey'
}

/** input type for incrementing numeric columns in table "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  purchasingPrice?: InputMaybe<Scalars['bigint']>;
  receivedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  receivedMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  receivedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  receivedStorageUnitId?: InputMaybe<Scalars['bigint']>;
  supplierOrderItemId?: InputMaybe<Scalars['bigint']>;
  tareWeight?: InputMaybe<Scalars['bigint']>;
  targetMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  targetMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  targetStockLocationId?: InputMaybe<Scalars['bigint']>;
  targetStorageQuantity?: InputMaybe<Scalars['bigint']>;
  targetStorageUnitId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Insert_Input = {
  LotNumber?: InputMaybe<Lot_Numbers_Obj_Rel_Insert_Input>;
  currency?: InputMaybe<Currencies_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntry?: InputMaybe<Journal_Entries_Obj_Rel_Insert_Input>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  purchasingPrice?: InputMaybe<Scalars['bigint']>;
  receivedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  receivedMonetaryUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  receivedMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  receivedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  receivedStorageUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  receivedStorageUnitId?: InputMaybe<Scalars['bigint']>;
  supplierOrderGoodsIncomeItemContainers?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Arr_Rel_Insert_Input>;
  supplierOrderItem?: InputMaybe<Supplier_Order_Items_Obj_Rel_Insert_Input>;
  supplierOrderItemId?: InputMaybe<Scalars['bigint']>;
  tareWeight?: InputMaybe<Scalars['bigint']>;
  targetMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  targetMonetaryUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  targetMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  targetStockLocation?: InputMaybe<Stock_Locations_Obj_Rel_Insert_Input>;
  targetStockLocationId?: InputMaybe<Scalars['bigint']>;
  targetStorageQuantity?: InputMaybe<Scalars['bigint']>;
  targetStorageUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  targetStorageUnitId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Supplier_Order_Goods_Income_Items_Max_Fields = {
  __typename: 'supplier_order_goods_income_items_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  purchasingPrice?: Maybe<Scalars['bigint']>;
  receivedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  receivedMonetaryUnitId?: Maybe<Scalars['bigint']>;
  receivedStorageQuantity?: Maybe<Scalars['bigint']>;
  receivedStorageUnitId?: Maybe<Scalars['bigint']>;
  supplierOrderItemId?: Maybe<Scalars['bigint']>;
  tareWeight?: Maybe<Scalars['bigint']>;
  targetMonetaryQuantity?: Maybe<Scalars['bigint']>;
  targetMonetaryUnitId?: Maybe<Scalars['bigint']>;
  targetStockLocationId?: Maybe<Scalars['bigint']>;
  targetStorageQuantity?: Maybe<Scalars['bigint']>;
  targetStorageUnitId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  receivedMonetaryQuantity?: InputMaybe<Order_By>;
  receivedMonetaryUnitId?: InputMaybe<Order_By>;
  receivedStorageQuantity?: InputMaybe<Order_By>;
  receivedStorageUnitId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Supplier_Order_Goods_Income_Items_Min_Fields = {
  __typename: 'supplier_order_goods_income_items_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  purchasingPrice?: Maybe<Scalars['bigint']>;
  receivedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  receivedMonetaryUnitId?: Maybe<Scalars['bigint']>;
  receivedStorageQuantity?: Maybe<Scalars['bigint']>;
  receivedStorageUnitId?: Maybe<Scalars['bigint']>;
  supplierOrderItemId?: Maybe<Scalars['bigint']>;
  tareWeight?: Maybe<Scalars['bigint']>;
  targetMonetaryQuantity?: Maybe<Scalars['bigint']>;
  targetMonetaryUnitId?: Maybe<Scalars['bigint']>;
  targetStockLocationId?: Maybe<Scalars['bigint']>;
  targetStorageQuantity?: Maybe<Scalars['bigint']>;
  targetStorageUnitId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  receivedMonetaryQuantity?: InputMaybe<Order_By>;
  receivedMonetaryUnitId?: InputMaybe<Order_By>;
  receivedStorageQuantity?: InputMaybe<Order_By>;
  receivedStorageUnitId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Mutation_Response = {
  __typename: 'supplier_order_goods_income_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Supplier_Order_Goods_Income_Items>;
};

/** input type for inserting object relation for remote table "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Obj_Rel_Insert_Input = {
  data: Supplier_Order_Goods_Income_Items_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Supplier_Order_Goods_Income_Items_On_Conflict>;
};

/** on_conflict condition type for table "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_On_Conflict = {
  constraint: Supplier_Order_Goods_Income_Items_Constraint;
  update_columns?: Array<Supplier_Order_Goods_Income_Items_Update_Column>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "supplier_order_goods_income_items". */
export type Supplier_Order_Goods_Income_Items_Order_By = {
  LotNumber?: InputMaybe<Lot_Numbers_Order_By>;
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntry?: InputMaybe<Journal_Entries_Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  receivedMonetaryQuantity?: InputMaybe<Order_By>;
  receivedMonetaryUnit?: InputMaybe<Product_Units_Order_By>;
  receivedMonetaryUnitId?: InputMaybe<Order_By>;
  receivedStorageQuantity?: InputMaybe<Order_By>;
  receivedStorageUnit?: InputMaybe<Product_Units_Order_By>;
  receivedStorageUnitId?: InputMaybe<Order_By>;
  supplierOrderGoodsIncomeItemContainers_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Aggregate_Order_By>;
  supplierOrderItem?: InputMaybe<Supplier_Order_Items_Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnit?: InputMaybe<Product_Units_Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocation?: InputMaybe<Stock_Locations_Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnit?: InputMaybe<Product_Units_Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: supplier_order_goods_income_items */
export type Supplier_Order_Goods_Income_Items_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "supplier_order_goods_income_items" */
export enum Supplier_Order_Goods_Income_Items_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  JournalEntryId = 'journalEntryId',
  /** column name */
  LotNumber = 'lotNumber',
  /** column name */
  LotNumberId = 'lotNumberId',
  /** column name */
  PurchasingPrice = 'purchasingPrice',
  /** column name */
  ReceivedMonetaryQuantity = 'receivedMonetaryQuantity',
  /** column name */
  ReceivedMonetaryUnitId = 'receivedMonetaryUnitId',
  /** column name */
  ReceivedStorageQuantity = 'receivedStorageQuantity',
  /** column name */
  ReceivedStorageUnitId = 'receivedStorageUnitId',
  /** column name */
  SupplierOrderItemId = 'supplierOrderItemId',
  /** column name */
  TareWeight = 'tareWeight',
  /** column name */
  TargetMonetaryQuantity = 'targetMonetaryQuantity',
  /** column name */
  TargetMonetaryUnitId = 'targetMonetaryUnitId',
  /** column name */
  TargetStockLocationId = 'targetStockLocationId',
  /** column name */
  TargetStorageQuantity = 'targetStorageQuantity',
  /** column name */
  TargetStorageUnitId = 'targetStorageUnitId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Set_Input = {
  currency?: InputMaybe<Currencies_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  purchasingPrice?: InputMaybe<Scalars['bigint']>;
  receivedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  receivedMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  receivedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  receivedStorageUnitId?: InputMaybe<Scalars['bigint']>;
  supplierOrderItemId?: InputMaybe<Scalars['bigint']>;
  tareWeight?: InputMaybe<Scalars['bigint']>;
  targetMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  targetMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  targetStockLocationId?: InputMaybe<Scalars['bigint']>;
  targetStorageQuantity?: InputMaybe<Scalars['bigint']>;
  targetStorageUnitId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Supplier_Order_Goods_Income_Items_Stddev_Fields = {
  __typename: 'supplier_order_goods_income_items_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  purchasingPrice?: Maybe<Scalars['Float']>;
  receivedMonetaryQuantity?: Maybe<Scalars['Float']>;
  receivedMonetaryUnitId?: Maybe<Scalars['Float']>;
  receivedStorageQuantity?: Maybe<Scalars['Float']>;
  receivedStorageUnitId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  receivedMonetaryQuantity?: InputMaybe<Order_By>;
  receivedMonetaryUnitId?: InputMaybe<Order_By>;
  receivedStorageQuantity?: InputMaybe<Order_By>;
  receivedStorageUnitId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Supplier_Order_Goods_Income_Items_Stddev_Pop_Fields = {
  __typename: 'supplier_order_goods_income_items_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  purchasingPrice?: Maybe<Scalars['Float']>;
  receivedMonetaryQuantity?: Maybe<Scalars['Float']>;
  receivedMonetaryUnitId?: Maybe<Scalars['Float']>;
  receivedStorageQuantity?: Maybe<Scalars['Float']>;
  receivedStorageUnitId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  receivedMonetaryQuantity?: InputMaybe<Order_By>;
  receivedMonetaryUnitId?: InputMaybe<Order_By>;
  receivedStorageQuantity?: InputMaybe<Order_By>;
  receivedStorageUnitId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Supplier_Order_Goods_Income_Items_Stddev_Samp_Fields = {
  __typename: 'supplier_order_goods_income_items_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  purchasingPrice?: Maybe<Scalars['Float']>;
  receivedMonetaryQuantity?: Maybe<Scalars['Float']>;
  receivedMonetaryUnitId?: Maybe<Scalars['Float']>;
  receivedStorageQuantity?: Maybe<Scalars['Float']>;
  receivedStorageUnitId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  receivedMonetaryQuantity?: InputMaybe<Order_By>;
  receivedMonetaryUnitId?: InputMaybe<Order_By>;
  receivedStorageQuantity?: InputMaybe<Order_By>;
  receivedStorageUnitId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supplier_Order_Goods_Income_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supplier_Order_Goods_Income_Items_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Currencies_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  purchasingPrice?: InputMaybe<Scalars['bigint']>;
  receivedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  receivedMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  receivedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  receivedStorageUnitId?: InputMaybe<Scalars['bigint']>;
  supplierOrderItemId?: InputMaybe<Scalars['bigint']>;
  tareWeight?: InputMaybe<Scalars['bigint']>;
  targetMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  targetMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  targetStockLocationId?: InputMaybe<Scalars['bigint']>;
  targetStorageQuantity?: InputMaybe<Scalars['bigint']>;
  targetStorageUnitId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Supplier_Order_Goods_Income_Items_Sum_Fields = {
  __typename: 'supplier_order_goods_income_items_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  purchasingPrice?: Maybe<Scalars['bigint']>;
  receivedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  receivedMonetaryUnitId?: Maybe<Scalars['bigint']>;
  receivedStorageQuantity?: Maybe<Scalars['bigint']>;
  receivedStorageUnitId?: Maybe<Scalars['bigint']>;
  supplierOrderItemId?: Maybe<Scalars['bigint']>;
  tareWeight?: Maybe<Scalars['bigint']>;
  targetMonetaryQuantity?: Maybe<Scalars['bigint']>;
  targetMonetaryUnitId?: Maybe<Scalars['bigint']>;
  targetStockLocationId?: Maybe<Scalars['bigint']>;
  targetStorageQuantity?: Maybe<Scalars['bigint']>;
  targetStorageUnitId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  receivedMonetaryQuantity?: InputMaybe<Order_By>;
  receivedMonetaryUnitId?: InputMaybe<Order_By>;
  receivedStorageQuantity?: InputMaybe<Order_By>;
  receivedStorageUnitId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "supplier_order_goods_income_items" */
export enum Supplier_Order_Goods_Income_Items_Update_Column {
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  JournalEntryId = 'journalEntryId',
  /** column name */
  LotNumber = 'lotNumber',
  /** column name */
  LotNumberId = 'lotNumberId',
  /** column name */
  PurchasingPrice = 'purchasingPrice',
  /** column name */
  ReceivedMonetaryQuantity = 'receivedMonetaryQuantity',
  /** column name */
  ReceivedMonetaryUnitId = 'receivedMonetaryUnitId',
  /** column name */
  ReceivedStorageQuantity = 'receivedStorageQuantity',
  /** column name */
  ReceivedStorageUnitId = 'receivedStorageUnitId',
  /** column name */
  SupplierOrderItemId = 'supplierOrderItemId',
  /** column name */
  TareWeight = 'tareWeight',
  /** column name */
  TargetMonetaryQuantity = 'targetMonetaryQuantity',
  /** column name */
  TargetMonetaryUnitId = 'targetMonetaryUnitId',
  /** column name */
  TargetStockLocationId = 'targetStockLocationId',
  /** column name */
  TargetStorageQuantity = 'targetStorageQuantity',
  /** column name */
  TargetStorageUnitId = 'targetStorageUnitId',
  /** column name */
  Version = 'version'
}

export type Supplier_Order_Goods_Income_Items_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Supplier_Order_Goods_Income_Items_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Supplier_Order_Goods_Income_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supplier_Order_Goods_Income_Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Supplier_Order_Goods_Income_Items_Var_Pop_Fields = {
  __typename: 'supplier_order_goods_income_items_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  purchasingPrice?: Maybe<Scalars['Float']>;
  receivedMonetaryQuantity?: Maybe<Scalars['Float']>;
  receivedMonetaryUnitId?: Maybe<Scalars['Float']>;
  receivedStorageQuantity?: Maybe<Scalars['Float']>;
  receivedStorageUnitId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  receivedMonetaryQuantity?: InputMaybe<Order_By>;
  receivedMonetaryUnitId?: InputMaybe<Order_By>;
  receivedStorageQuantity?: InputMaybe<Order_By>;
  receivedStorageUnitId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Supplier_Order_Goods_Income_Items_Var_Samp_Fields = {
  __typename: 'supplier_order_goods_income_items_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  purchasingPrice?: Maybe<Scalars['Float']>;
  receivedMonetaryQuantity?: Maybe<Scalars['Float']>;
  receivedMonetaryUnitId?: Maybe<Scalars['Float']>;
  receivedStorageQuantity?: Maybe<Scalars['Float']>;
  receivedStorageUnitId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  receivedMonetaryQuantity?: InputMaybe<Order_By>;
  receivedMonetaryUnitId?: InputMaybe<Order_By>;
  receivedStorageQuantity?: InputMaybe<Order_By>;
  receivedStorageUnitId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Supplier_Order_Goods_Income_Items_Variance_Fields = {
  __typename: 'supplier_order_goods_income_items_variance_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  purchasingPrice?: Maybe<Scalars['Float']>;
  receivedMonetaryQuantity?: Maybe<Scalars['Float']>;
  receivedMonetaryUnitId?: Maybe<Scalars['Float']>;
  receivedStorageQuantity?: Maybe<Scalars['Float']>;
  receivedStorageUnitId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  targetMonetaryQuantity?: Maybe<Scalars['Float']>;
  targetMonetaryUnitId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  targetStorageQuantity?: Maybe<Scalars['Float']>;
  targetStorageUnitId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "supplier_order_goods_income_items" */
export type Supplier_Order_Goods_Income_Items_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  receivedMonetaryQuantity?: InputMaybe<Order_By>;
  receivedMonetaryUnitId?: InputMaybe<Order_By>;
  receivedStorageQuantity?: InputMaybe<Order_By>;
  receivedStorageUnitId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  targetMonetaryQuantity?: InputMaybe<Order_By>;
  targetMonetaryUnitId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  targetStorageQuantity?: InputMaybe<Order_By>;
  targetStorageUnitId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "supplier_order_item_logged_actions" */
export type Supplier_Order_Item_Logged_Actions = {
  __typename: 'supplier_order_item_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  entity?: Maybe<Supplier_Order_Items>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "supplier_order_item_logged_actions" */
export type Supplier_Order_Item_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "supplier_order_item_logged_actions" */
export type Supplier_Order_Item_Logged_Actions_Aggregate = {
  __typename: 'supplier_order_item_logged_actions_aggregate';
  aggregate?: Maybe<Supplier_Order_Item_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Supplier_Order_Item_Logged_Actions>;
};

export type Supplier_Order_Item_Logged_Actions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Supplier_Order_Item_Logged_Actions_Aggregate_Bool_Exp_Count>;
};

export type Supplier_Order_Item_Logged_Actions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Supplier_Order_Item_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Supplier_Order_Item_Logged_Actions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "supplier_order_item_logged_actions" */
export type Supplier_Order_Item_Logged_Actions_Aggregate_Fields = {
  __typename: 'supplier_order_item_logged_actions_aggregate_fields';
  avg?: Maybe<Supplier_Order_Item_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supplier_Order_Item_Logged_Actions_Max_Fields>;
  min?: Maybe<Supplier_Order_Item_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Supplier_Order_Item_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Supplier_Order_Item_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supplier_Order_Item_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Supplier_Order_Item_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Supplier_Order_Item_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Supplier_Order_Item_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Supplier_Order_Item_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "supplier_order_item_logged_actions" */
export type Supplier_Order_Item_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supplier_Order_Item_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "supplier_order_item_logged_actions" */
export type Supplier_Order_Item_Logged_Actions_Aggregate_Order_By = {
  avg?: InputMaybe<Supplier_Order_Item_Logged_Actions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Supplier_Order_Item_Logged_Actions_Max_Order_By>;
  min?: InputMaybe<Supplier_Order_Item_Logged_Actions_Min_Order_By>;
  stddev?: InputMaybe<Supplier_Order_Item_Logged_Actions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Supplier_Order_Item_Logged_Actions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Supplier_Order_Item_Logged_Actions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Supplier_Order_Item_Logged_Actions_Sum_Order_By>;
  var_pop?: InputMaybe<Supplier_Order_Item_Logged_Actions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Supplier_Order_Item_Logged_Actions_Var_Samp_Order_By>;
  variance?: InputMaybe<Supplier_Order_Item_Logged_Actions_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Supplier_Order_Item_Logged_Actions_Avg_Fields = {
  __typename: 'supplier_order_item_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "supplier_order_item_logged_actions" */
export type Supplier_Order_Item_Logged_Actions_Avg_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "supplier_order_item_logged_actions". All fields are combined with a logical 'AND'. */
export type Supplier_Order_Item_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Supplier_Order_Item_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Supplier_Order_Item_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Supplier_Order_Item_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entity?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Supplier_Order_Item_Logged_Actions_Max_Fields = {
  __typename: 'supplier_order_item_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "supplier_order_item_logged_actions" */
export type Supplier_Order_Item_Logged_Actions_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Supplier_Order_Item_Logged_Actions_Min_Fields = {
  __typename: 'supplier_order_item_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "supplier_order_item_logged_actions" */
export type Supplier_Order_Item_Logged_Actions_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "supplier_order_item_logged_actions". */
export type Supplier_Order_Item_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entity?: InputMaybe<Supplier_Order_Items_Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "supplier_order_item_logged_actions" */
export enum Supplier_Order_Item_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Role = 'role',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Supplier_Order_Item_Logged_Actions_Stddev_Fields = {
  __typename: 'supplier_order_item_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "supplier_order_item_logged_actions" */
export type Supplier_Order_Item_Logged_Actions_Stddev_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Supplier_Order_Item_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'supplier_order_item_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "supplier_order_item_logged_actions" */
export type Supplier_Order_Item_Logged_Actions_Stddev_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Supplier_Order_Item_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'supplier_order_item_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "supplier_order_item_logged_actions" */
export type Supplier_Order_Item_Logged_Actions_Stddev_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "supplier_order_item_logged_actions" */
export type Supplier_Order_Item_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supplier_Order_Item_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supplier_Order_Item_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  role?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Supplier_Order_Item_Logged_Actions_Sum_Fields = {
  __typename: 'supplier_order_item_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "supplier_order_item_logged_actions" */
export type Supplier_Order_Item_Logged_Actions_Sum_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Supplier_Order_Item_Logged_Actions_Var_Pop_Fields = {
  __typename: 'supplier_order_item_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "supplier_order_item_logged_actions" */
export type Supplier_Order_Item_Logged_Actions_Var_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Supplier_Order_Item_Logged_Actions_Var_Samp_Fields = {
  __typename: 'supplier_order_item_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "supplier_order_item_logged_actions" */
export type Supplier_Order_Item_Logged_Actions_Var_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Supplier_Order_Item_Logged_Actions_Variance_Fields = {
  __typename: 'supplier_order_item_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "supplier_order_item_logged_actions" */
export type Supplier_Order_Item_Logged_Actions_Variance_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes = {
  __typename: 'supplier_order_item_return_causes';
  /** An object relationship */
  Tenant: Tenants;
  i18n: Scalars['jsonb'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  ordinalPosition: Scalars['Int'];
  /** An array relationship */
  supplierOrderItems: Array<Supplier_Order_Items>;
  /** An aggregate relationship */
  supplierOrderItems_aggregate: Supplier_Order_Items_Aggregate;
  /** An array relationship */
  supplierOrderReturnItems: Array<Supplier_Order_Return_Items>;
  /** An aggregate relationship */
  supplierOrderReturnItems_aggregate: Supplier_Order_Return_Items_Aggregate;
  tenant: Scalars['String'];
};


/** columns and relationships of "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_CausesI18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_CausesSupplierOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** columns and relationships of "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_CausesSupplierOrderItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** columns and relationships of "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_CausesSupplierOrderReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_CausesSupplierOrderReturnItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};

/** aggregated selection of "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_Aggregate = {
  __typename: 'supplier_order_item_return_causes_aggregate';
  aggregate?: Maybe<Supplier_Order_Item_Return_Causes_Aggregate_Fields>;
  nodes: Array<Supplier_Order_Item_Return_Causes>;
};

export type Supplier_Order_Item_Return_Causes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Supplier_Order_Item_Return_Causes_Aggregate_Bool_Exp_Count>;
};

export type Supplier_Order_Item_Return_Causes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Supplier_Order_Item_Return_Causes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Supplier_Order_Item_Return_Causes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_Aggregate_Fields = {
  __typename: 'supplier_order_item_return_causes_aggregate_fields';
  avg?: Maybe<Supplier_Order_Item_Return_Causes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supplier_Order_Item_Return_Causes_Max_Fields>;
  min?: Maybe<Supplier_Order_Item_Return_Causes_Min_Fields>;
  stddev?: Maybe<Supplier_Order_Item_Return_Causes_Stddev_Fields>;
  stddev_pop?: Maybe<Supplier_Order_Item_Return_Causes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supplier_Order_Item_Return_Causes_Stddev_Samp_Fields>;
  sum?: Maybe<Supplier_Order_Item_Return_Causes_Sum_Fields>;
  var_pop?: Maybe<Supplier_Order_Item_Return_Causes_Var_Pop_Fields>;
  var_samp?: Maybe<Supplier_Order_Item_Return_Causes_Var_Samp_Fields>;
  variance?: Maybe<Supplier_Order_Item_Return_Causes_Variance_Fields>;
};


/** aggregate fields of "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supplier_Order_Item_Return_Causes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_Aggregate_Order_By = {
  avg?: InputMaybe<Supplier_Order_Item_Return_Causes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Supplier_Order_Item_Return_Causes_Max_Order_By>;
  min?: InputMaybe<Supplier_Order_Item_Return_Causes_Min_Order_By>;
  stddev?: InputMaybe<Supplier_Order_Item_Return_Causes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Supplier_Order_Item_Return_Causes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Supplier_Order_Item_Return_Causes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Supplier_Order_Item_Return_Causes_Sum_Order_By>;
  var_pop?: InputMaybe<Supplier_Order_Item_Return_Causes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Supplier_Order_Item_Return_Causes_Var_Samp_Order_By>;
  variance?: InputMaybe<Supplier_Order_Item_Return_Causes_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Supplier_Order_Item_Return_Causes_Append_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Supplier_Order_Item_Return_Causes_Avg_Fields = {
  __typename: 'supplier_order_item_return_causes_avg_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "supplier_order_item_return_causes". All fields are combined with a logical 'AND'. */
export type Supplier_Order_Item_Return_Causes_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Supplier_Order_Item_Return_Causes_Bool_Exp>>;
  _not?: InputMaybe<Supplier_Order_Item_Return_Causes_Bool_Exp>;
  _or?: InputMaybe<Array<Supplier_Order_Item_Return_Causes_Bool_Exp>>;
  i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  supplierOrderItems?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
  supplierOrderItems_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Bool_Exp>;
  supplierOrderReturnItems?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
  supplierOrderReturnItems_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "supplier_order_item_return_causes" */
export enum Supplier_Order_Item_Return_Causes_Constraint {
  /** unique or primary key constraint on columns "id" */
  SupplierOrderItemReturnCausesPkey = 'supplier_order_item_return_causes_pkey',
  /** unique or primary key constraint on columns "name", "tenant" */
  SupplierOrderItemReturnCausesTenantNameKey = 'supplier_order_item_return_causes_tenant_name_key',
  /** unique or primary key constraint on columns "tenant", "ordinal_position" */
  SupplierOrderItemReturnCausesTenantOrdinalPositionUniq = 'supplier_order_item_return_causes_tenant_ordinal_position_uniq'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Supplier_Order_Item_Return_Causes_Delete_At_Path_Input = {
  i18n?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Supplier_Order_Item_Return_Causes_Delete_Elem_Input = {
  i18n?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Supplier_Order_Item_Return_Causes_Delete_Key_Input = {
  i18n?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_Insert_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  supplierOrderItems?: InputMaybe<Supplier_Order_Items_Arr_Rel_Insert_Input>;
  supplierOrderReturnItems?: InputMaybe<Supplier_Order_Return_Items_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Supplier_Order_Item_Return_Causes_Max_Fields = {
  __typename: 'supplier_order_item_return_causes_max_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Supplier_Order_Item_Return_Causes_Min_Fields = {
  __typename: 'supplier_order_item_return_causes_min_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_Mutation_Response = {
  __typename: 'supplier_order_item_return_causes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Supplier_Order_Item_Return_Causes>;
};

/** input type for inserting object relation for remote table "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_Obj_Rel_Insert_Input = {
  data: Supplier_Order_Item_Return_Causes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Supplier_Order_Item_Return_Causes_On_Conflict>;
};

/** on_conflict condition type for table "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_On_Conflict = {
  constraint: Supplier_Order_Item_Return_Causes_Constraint;
  update_columns?: Array<Supplier_Order_Item_Return_Causes_Update_Column>;
  where?: InputMaybe<Supplier_Order_Item_Return_Causes_Bool_Exp>;
};

/** Ordering options when selecting data from "supplier_order_item_return_causes". */
export type Supplier_Order_Item_Return_Causes_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  i18n?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  supplierOrderItems_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Order_By>;
  supplierOrderReturnItems_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** primary key columns input for table: supplier_order_item_return_causes */
export type Supplier_Order_Item_Return_Causes_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Supplier_Order_Item_Return_Causes_Prepend_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "supplier_order_item_return_causes" */
export enum Supplier_Order_Item_Return_Causes_Select_Column {
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  Tenant = 'tenant'
}

/** input type for updating data in table "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_Set_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Supplier_Order_Item_Return_Causes_Stddev_Fields = {
  __typename: 'supplier_order_item_return_causes_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Supplier_Order_Item_Return_Causes_Stddev_Pop_Fields = {
  __typename: 'supplier_order_item_return_causes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Supplier_Order_Item_Return_Causes_Stddev_Samp_Fields = {
  __typename: 'supplier_order_item_return_causes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supplier_Order_Item_Return_Causes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supplier_Order_Item_Return_Causes_Stream_Cursor_Value_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Supplier_Order_Item_Return_Causes_Sum_Fields = {
  __typename: 'supplier_order_item_return_causes_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** update columns of table "supplier_order_item_return_causes" */
export enum Supplier_Order_Item_Return_Causes_Update_Column {
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition'
}

export type Supplier_Order_Item_Return_Causes_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Supplier_Order_Item_Return_Causes_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Supplier_Order_Item_Return_Causes_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Supplier_Order_Item_Return_Causes_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Supplier_Order_Item_Return_Causes_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Supplier_Order_Item_Return_Causes_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Supplier_Order_Item_Return_Causes_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Supplier_Order_Item_Return_Causes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supplier_Order_Item_Return_Causes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Supplier_Order_Item_Return_Causes_Var_Pop_Fields = {
  __typename: 'supplier_order_item_return_causes_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Supplier_Order_Item_Return_Causes_Var_Samp_Fields = {
  __typename: 'supplier_order_item_return_causes_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Supplier_Order_Item_Return_Causes_Variance_Fields = {
  __typename: 'supplier_order_item_return_causes_variance_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "supplier_order_item_return_causes" */
export type Supplier_Order_Item_Return_Causes_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
};

/** columns and relationships of "supplier_order_item_states" */
export type Supplier_Order_Item_States = {
  __typename: 'supplier_order_item_states';
  /** An array relationship */
  supplierOrderItems: Array<Supplier_Order_Items>;
  /** An aggregate relationship */
  supplierOrderItems_aggregate: Supplier_Order_Items_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "supplier_order_item_states" */
export type Supplier_Order_Item_StatesSupplierOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** columns and relationships of "supplier_order_item_states" */
export type Supplier_Order_Item_StatesSupplierOrderItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};

/** aggregated selection of "supplier_order_item_states" */
export type Supplier_Order_Item_States_Aggregate = {
  __typename: 'supplier_order_item_states_aggregate';
  aggregate?: Maybe<Supplier_Order_Item_States_Aggregate_Fields>;
  nodes: Array<Supplier_Order_Item_States>;
};

/** aggregate fields of "supplier_order_item_states" */
export type Supplier_Order_Item_States_Aggregate_Fields = {
  __typename: 'supplier_order_item_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Supplier_Order_Item_States_Max_Fields>;
  min?: Maybe<Supplier_Order_Item_States_Min_Fields>;
};


/** aggregate fields of "supplier_order_item_states" */
export type Supplier_Order_Item_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supplier_Order_Item_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "supplier_order_item_states". All fields are combined with a logical 'AND'. */
export type Supplier_Order_Item_States_Bool_Exp = {
  _and?: InputMaybe<Array<Supplier_Order_Item_States_Bool_Exp>>;
  _not?: InputMaybe<Supplier_Order_Item_States_Bool_Exp>;
  _or?: InputMaybe<Array<Supplier_Order_Item_States_Bool_Exp>>;
  supplierOrderItems?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
  supplierOrderItems_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Supplier_Order_Item_States_Enum {
  Canceled = 'CANCELED',
  Open = 'OPEN',
  PartialReceipt = 'PARTIAL_RECEIPT',
  Receipt = 'RECEIPT'
}

/** Boolean expression to compare columns of type "supplier_order_item_states_enum". All fields are combined with logical 'AND'. */
export type Supplier_Order_Item_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Supplier_Order_Item_States_Enum>;
  _in?: InputMaybe<Array<Supplier_Order_Item_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Supplier_Order_Item_States_Enum>;
  _nin?: InputMaybe<Array<Supplier_Order_Item_States_Enum>>;
};

/** aggregate max on columns */
export type Supplier_Order_Item_States_Max_Fields = {
  __typename: 'supplier_order_item_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Supplier_Order_Item_States_Min_Fields = {
  __typename: 'supplier_order_item_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "supplier_order_item_states". */
export type Supplier_Order_Item_States_Order_By = {
  supplierOrderItems_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "supplier_order_item_states" */
export enum Supplier_Order_Item_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "supplier_order_item_states" */
export type Supplier_Order_Item_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supplier_Order_Item_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supplier_Order_Item_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "supplier_order_items" */
export type Supplier_Order_Items = {
  __typename: 'supplier_order_items';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  aggregatedDemandPlanIncomingGoodsResults: Array<Aggregated_Demand_Plan_Incoming_Goods_Results>;
  /** An aggregate relationship */
  aggregatedDemandPlanIncomingGoodsResults_aggregate: Aggregated_Demand_Plan_Incoming_Goods_Results_Aggregate;
  createdAt: Scalars['timestamptz'];
  currency: Currencies_Enum;
  id: Scalars['bigint'];
  /** An array relationship */
  loggedActions: Array<Supplier_Order_Item_Logged_Actions>;
  /** An aggregate relationship */
  loggedActions_aggregate: Supplier_Order_Item_Logged_Actions_Aggregate;
  notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  orderUnit: Product_Units;
  orderUnitId: Scalars['bigint'];
  orderUnitToPurchasingUnitFactor: Scalars['numeric'];
  /** An object relationship */
  parentSupplierOrderItem?: Maybe<Supplier_Order_Items>;
  parentSupplierOrderItemId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  pickingDeviationReason?: Maybe<Picking_Deviation_Reasons>;
  pickingDeviationReasonId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  purchasingPrice?: Maybe<Scalars['bigint']>;
  purchasingPricePerUnit: Scalars['bigint'];
  /** An object relationship */
  purchasingUnit: Product_Units;
  purchasingUnitId: Scalars['bigint'];
  quantity?: Maybe<Scalars['bigint']>;
  receivedQuantity?: Maybe<Scalars['bigint']>;
  remainingQuantityFactor?: Maybe<Scalars['numeric']>;
  state: Supplier_Order_Item_States_Enum;
  /** An object relationship */
  supplierOrder: Supplier_Orders;
  /** An array relationship */
  supplierOrderGoodsIncomeItems: Array<Supplier_Order_Goods_Income_Items>;
  /** An aggregate relationship */
  supplierOrderGoodsIncomeItems_aggregate: Supplier_Order_Goods_Income_Items_Aggregate;
  supplierOrderId: Scalars['bigint'];
  /** An object relationship */
  supplierOrderItemReturnCause?: Maybe<Supplier_Order_Item_Return_Causes>;
  supplierOrderItemReturnCauseId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  supplierOrderItems: Array<Supplier_Order_Items>;
  /** An aggregate relationship */
  supplierOrderItems_aggregate: Supplier_Order_Items_Aggregate;
  /** An array relationship */
  supplierOrderReturnItems: Array<Supplier_Order_Return_Items>;
  /** An aggregate relationship */
  supplierOrderReturnItems_aggregate: Supplier_Order_Return_Items_Aggregate;
  /** An object relationship */
  targetStockLocation?: Maybe<Stock_Locations>;
  targetStockLocationId?: Maybe<Scalars['bigint']>;
  tenant: Scalars['String'];
  totalOrderedQuantity?: Maybe<Scalars['bigint']>;
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "supplier_order_items" */
export type Supplier_Order_ItemsAggregatedDemandPlanIncomingGoodsResultsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp>;
};


/** columns and relationships of "supplier_order_items" */
export type Supplier_Order_ItemsAggregatedDemandPlanIncomingGoodsResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp>;
};


/** columns and relationships of "supplier_order_items" */
export type Supplier_Order_ItemsLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Supplier_Order_Item_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "supplier_order_items" */
export type Supplier_Order_ItemsLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Supplier_Order_Item_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "supplier_order_items" */
export type Supplier_Order_ItemsSupplierOrderGoodsIncomeItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


/** columns and relationships of "supplier_order_items" */
export type Supplier_Order_ItemsSupplierOrderGoodsIncomeItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


/** columns and relationships of "supplier_order_items" */
export type Supplier_Order_ItemsSupplierOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** columns and relationships of "supplier_order_items" */
export type Supplier_Order_ItemsSupplierOrderItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** columns and relationships of "supplier_order_items" */
export type Supplier_Order_ItemsSupplierOrderReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "supplier_order_items" */
export type Supplier_Order_ItemsSupplierOrderReturnItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};

/** aggregated selection of "supplier_order_items" */
export type Supplier_Order_Items_Aggregate = {
  __typename: 'supplier_order_items_aggregate';
  aggregate?: Maybe<Supplier_Order_Items_Aggregate_Fields>;
  nodes: Array<Supplier_Order_Items>;
};

export type Supplier_Order_Items_Aggregate_Bool_Exp = {
  count?: InputMaybe<Supplier_Order_Items_Aggregate_Bool_Exp_Count>;
};

export type Supplier_Order_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "supplier_order_items" */
export type Supplier_Order_Items_Aggregate_Fields = {
  __typename: 'supplier_order_items_aggregate_fields';
  avg?: Maybe<Supplier_Order_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supplier_Order_Items_Max_Fields>;
  min?: Maybe<Supplier_Order_Items_Min_Fields>;
  stddev?: Maybe<Supplier_Order_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Supplier_Order_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supplier_Order_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Supplier_Order_Items_Sum_Fields>;
  var_pop?: Maybe<Supplier_Order_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Supplier_Order_Items_Var_Samp_Fields>;
  variance?: Maybe<Supplier_Order_Items_Variance_Fields>;
};


/** aggregate fields of "supplier_order_items" */
export type Supplier_Order_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "supplier_order_items" */
export type Supplier_Order_Items_Aggregate_Order_By = {
  avg?: InputMaybe<Supplier_Order_Items_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Supplier_Order_Items_Max_Order_By>;
  min?: InputMaybe<Supplier_Order_Items_Min_Order_By>;
  stddev?: InputMaybe<Supplier_Order_Items_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Supplier_Order_Items_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Supplier_Order_Items_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Supplier_Order_Items_Sum_Order_By>;
  var_pop?: InputMaybe<Supplier_Order_Items_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Supplier_Order_Items_Var_Samp_Order_By>;
  variance?: InputMaybe<Supplier_Order_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "supplier_order_items" */
export type Supplier_Order_Items_Arr_Rel_Insert_Input = {
  data: Array<Supplier_Order_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Supplier_Order_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Supplier_Order_Items_Avg_Fields = {
  __typename: 'supplier_order_items_avg_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  orderUnitToPurchasingUnitFactor?: Maybe<Scalars['Float']>;
  parentSupplierOrderItemId?: Maybe<Scalars['Float']>;
  pickingDeviationReasonId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  purchasingPrice?: Maybe<Scalars['Float']>;
  purchasingPricePerUnit?: Maybe<Scalars['Float']>;
  purchasingUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  receivedQuantity?: Maybe<Scalars['Float']>;
  remainingQuantityFactor?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  supplierOrderItemReturnCauseId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  totalOrderedQuantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "supplier_order_items" */
export type Supplier_Order_Items_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToPurchasingUnitFactor?: InputMaybe<Order_By>;
  parentSupplierOrderItemId?: InputMaybe<Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  receivedQuantity?: InputMaybe<Order_By>;
  remainingQuantityFactor?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemReturnCauseId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  totalOrderedQuantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "supplier_order_items". All fields are combined with a logical 'AND'. */
export type Supplier_Order_Items_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Supplier_Order_Items_Bool_Exp>>;
  _not?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Supplier_Order_Items_Bool_Exp>>;
  aggregatedDemandPlanIncomingGoodsResults?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp>;
  aggregatedDemandPlanIncomingGoodsResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  loggedActions?: InputMaybe<Supplier_Order_Item_Logged_Actions_Bool_Exp>;
  loggedActions_aggregate?: InputMaybe<Supplier_Order_Item_Logged_Actions_Aggregate_Bool_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  orderUnit?: InputMaybe<Product_Units_Bool_Exp>;
  orderUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  orderUnitToPurchasingUnitFactor?: InputMaybe<Numeric_Comparison_Exp>;
  parentSupplierOrderItem?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
  parentSupplierOrderItemId?: InputMaybe<Bigint_Comparison_Exp>;
  pickingDeviationReason?: InputMaybe<Picking_Deviation_Reasons_Bool_Exp>;
  pickingDeviationReasonId?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  purchasingPrice?: InputMaybe<Bigint_Comparison_Exp>;
  purchasingPricePerUnit?: InputMaybe<Bigint_Comparison_Exp>;
  purchasingUnit?: InputMaybe<Product_Units_Bool_Exp>;
  purchasingUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  quantity?: InputMaybe<Bigint_Comparison_Exp>;
  receivedQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  remainingQuantityFactor?: InputMaybe<Numeric_Comparison_Exp>;
  state?: InputMaybe<Supplier_Order_Item_States_Enum_Comparison_Exp>;
  supplierOrder?: InputMaybe<Supplier_Orders_Bool_Exp>;
  supplierOrderGoodsIncomeItems?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
  supplierOrderGoodsIncomeItems_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Bool_Exp>;
  supplierOrderId?: InputMaybe<Bigint_Comparison_Exp>;
  supplierOrderItemReturnCause?: InputMaybe<Supplier_Order_Item_Return_Causes_Bool_Exp>;
  supplierOrderItemReturnCauseId?: InputMaybe<Bigint_Comparison_Exp>;
  supplierOrderItems?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
  supplierOrderItems_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Bool_Exp>;
  supplierOrderReturnItems?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
  supplierOrderReturnItems_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Bool_Exp>;
  targetStockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  targetStockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  totalOrderedQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "supplier_order_items" */
export enum Supplier_Order_Items_Constraint {
  /** unique or primary key constraint on columns "id" */
  SupplierOrderItemsPkey = 'supplier_order_items_pkey',
  /** unique or primary key constraint on columns "product_id", "tenant", "supplier_order_id" */
  SupplierOrderItemsTenantSupplierOrderIdProductIdKey = 'supplier_order_items_tenant_supplier_order_id_product_id_key'
}

/** input type for incrementing numeric columns in table "supplier_order_items" */
export type Supplier_Order_Items_Inc_Input = {
  orderUnitId?: InputMaybe<Scalars['bigint']>;
  pickingDeviationReasonId?: InputMaybe<Scalars['bigint']>;
  purchasingPricePerUnit?: InputMaybe<Scalars['bigint']>;
  purchasingUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  supplierOrderItemReturnCauseId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "supplier_order_items" */
export type Supplier_Order_Items_Insert_Input = {
  currency?: InputMaybe<Currencies_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  orderUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  orderUnitId?: InputMaybe<Scalars['bigint']>;
  orderUnitToPurchasingUnitFactor?: InputMaybe<Scalars['numeric']>;
  parentSupplierOrderItem?: InputMaybe<Supplier_Order_Items_Obj_Rel_Insert_Input>;
  parentSupplierOrderItemId?: InputMaybe<Scalars['bigint']>;
  pickingDeviationReasonId?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  purchasingPrice?: InputMaybe<Scalars['bigint']>;
  purchasingPricePerUnit?: InputMaybe<Scalars['bigint']>;
  purchasingUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  purchasingUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  receivedQuantity?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Supplier_Order_Item_States_Enum>;
  supplierOrder?: InputMaybe<Supplier_Orders_Obj_Rel_Insert_Input>;
  supplierOrderGoodsIncomeItems?: InputMaybe<Supplier_Order_Goods_Income_Items_Arr_Rel_Insert_Input>;
  supplierOrderId?: InputMaybe<Scalars['bigint']>;
  supplierOrderItemReturnCause?: InputMaybe<Supplier_Order_Item_Return_Causes_Obj_Rel_Insert_Input>;
  supplierOrderItemReturnCauseId?: InputMaybe<Scalars['bigint']>;
  supplierOrderItems?: InputMaybe<Supplier_Order_Items_Arr_Rel_Insert_Input>;
  supplierOrderReturnItems?: InputMaybe<Supplier_Order_Return_Items_Arr_Rel_Insert_Input>;
  targetStockLocation?: InputMaybe<Stock_Locations_Obj_Rel_Insert_Input>;
  targetStockLocationId?: InputMaybe<Scalars['bigint']>;
  totalOrderedQuantity?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Supplier_Order_Items_Max_Fields = {
  __typename: 'supplier_order_items_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  orderUnitId?: Maybe<Scalars['bigint']>;
  orderUnitToPurchasingUnitFactor?: Maybe<Scalars['numeric']>;
  parentSupplierOrderItemId?: Maybe<Scalars['bigint']>;
  pickingDeviationReasonId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  purchasingPrice?: Maybe<Scalars['bigint']>;
  purchasingPricePerUnit?: Maybe<Scalars['bigint']>;
  purchasingUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  receivedQuantity?: Maybe<Scalars['bigint']>;
  remainingQuantityFactor?: Maybe<Scalars['numeric']>;
  supplierOrderId?: Maybe<Scalars['bigint']>;
  supplierOrderItemReturnCauseId?: Maybe<Scalars['bigint']>;
  targetStockLocationId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  totalOrderedQuantity?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "supplier_order_items" */
export type Supplier_Order_Items_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToPurchasingUnitFactor?: InputMaybe<Order_By>;
  parentSupplierOrderItemId?: InputMaybe<Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  receivedQuantity?: InputMaybe<Order_By>;
  remainingQuantityFactor?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemReturnCauseId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  totalOrderedQuantity?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Supplier_Order_Items_Min_Fields = {
  __typename: 'supplier_order_items_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  orderUnitId?: Maybe<Scalars['bigint']>;
  orderUnitToPurchasingUnitFactor?: Maybe<Scalars['numeric']>;
  parentSupplierOrderItemId?: Maybe<Scalars['bigint']>;
  pickingDeviationReasonId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  purchasingPrice?: Maybe<Scalars['bigint']>;
  purchasingPricePerUnit?: Maybe<Scalars['bigint']>;
  purchasingUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  receivedQuantity?: Maybe<Scalars['bigint']>;
  remainingQuantityFactor?: Maybe<Scalars['numeric']>;
  supplierOrderId?: Maybe<Scalars['bigint']>;
  supplierOrderItemReturnCauseId?: Maybe<Scalars['bigint']>;
  targetStockLocationId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  totalOrderedQuantity?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "supplier_order_items" */
export type Supplier_Order_Items_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToPurchasingUnitFactor?: InputMaybe<Order_By>;
  parentSupplierOrderItemId?: InputMaybe<Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  receivedQuantity?: InputMaybe<Order_By>;
  remainingQuantityFactor?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemReturnCauseId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  totalOrderedQuantity?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "supplier_order_items" */
export type Supplier_Order_Items_Mutation_Response = {
  __typename: 'supplier_order_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Supplier_Order_Items>;
};

/** input type for inserting object relation for remote table "supplier_order_items" */
export type Supplier_Order_Items_Obj_Rel_Insert_Input = {
  data: Supplier_Order_Items_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Supplier_Order_Items_On_Conflict>;
};

/** on_conflict condition type for table "supplier_order_items" */
export type Supplier_Order_Items_On_Conflict = {
  constraint: Supplier_Order_Items_Constraint;
  update_columns?: Array<Supplier_Order_Items_Update_Column>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "supplier_order_items". */
export type Supplier_Order_Items_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  aggregatedDemandPlanIncomingGoodsResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loggedActions_aggregate?: InputMaybe<Supplier_Order_Item_Logged_Actions_Aggregate_Order_By>;
  notes?: InputMaybe<Order_By>;
  orderUnit?: InputMaybe<Product_Units_Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToPurchasingUnitFactor?: InputMaybe<Order_By>;
  parentSupplierOrderItem?: InputMaybe<Supplier_Order_Items_Order_By>;
  parentSupplierOrderItemId?: InputMaybe<Order_By>;
  pickingDeviationReason?: InputMaybe<Picking_Deviation_Reasons_Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnit?: InputMaybe<Product_Units_Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  receivedQuantity?: InputMaybe<Order_By>;
  remainingQuantityFactor?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  supplierOrder?: InputMaybe<Supplier_Orders_Order_By>;
  supplierOrderGoodsIncomeItems_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemReturnCause?: InputMaybe<Supplier_Order_Item_Return_Causes_Order_By>;
  supplierOrderItemReturnCauseId?: InputMaybe<Order_By>;
  supplierOrderItems_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Order_By>;
  supplierOrderReturnItems_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Order_By>;
  targetStockLocation?: InputMaybe<Stock_Locations_Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  totalOrderedQuantity?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: supplier_order_items */
export type Supplier_Order_Items_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "supplier_order_items" */
export enum Supplier_Order_Items_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrderUnitId = 'orderUnitId',
  /** column name */
  OrderUnitToPurchasingUnitFactor = 'orderUnitToPurchasingUnitFactor',
  /** column name */
  ParentSupplierOrderItemId = 'parentSupplierOrderItemId',
  /** column name */
  PickingDeviationReasonId = 'pickingDeviationReasonId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  PurchasingPrice = 'purchasingPrice',
  /** column name */
  PurchasingPricePerUnit = 'purchasingPricePerUnit',
  /** column name */
  PurchasingUnitId = 'purchasingUnitId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ReceivedQuantity = 'receivedQuantity',
  /** column name */
  RemainingQuantityFactor = 'remainingQuantityFactor',
  /** column name */
  State = 'state',
  /** column name */
  SupplierOrderId = 'supplierOrderId',
  /** column name */
  SupplierOrderItemReturnCauseId = 'supplierOrderItemReturnCauseId',
  /** column name */
  TargetStockLocationId = 'targetStockLocationId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  TotalOrderedQuantity = 'totalOrderedQuantity',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "supplier_order_items" */
export type Supplier_Order_Items_Set_Input = {
  notes?: InputMaybe<Scalars['String']>;
  orderUnitId?: InputMaybe<Scalars['bigint']>;
  pickingDeviationReasonId?: InputMaybe<Scalars['bigint']>;
  purchasingPricePerUnit?: InputMaybe<Scalars['bigint']>;
  purchasingUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Supplier_Order_Item_States_Enum>;
  supplierOrderItemReturnCauseId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Supplier_Order_Items_Stddev_Fields = {
  __typename: 'supplier_order_items_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  orderUnitToPurchasingUnitFactor?: Maybe<Scalars['Float']>;
  parentSupplierOrderItemId?: Maybe<Scalars['Float']>;
  pickingDeviationReasonId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  purchasingPrice?: Maybe<Scalars['Float']>;
  purchasingPricePerUnit?: Maybe<Scalars['Float']>;
  purchasingUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  receivedQuantity?: Maybe<Scalars['Float']>;
  remainingQuantityFactor?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  supplierOrderItemReturnCauseId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  totalOrderedQuantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "supplier_order_items" */
export type Supplier_Order_Items_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToPurchasingUnitFactor?: InputMaybe<Order_By>;
  parentSupplierOrderItemId?: InputMaybe<Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  receivedQuantity?: InputMaybe<Order_By>;
  remainingQuantityFactor?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemReturnCauseId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  totalOrderedQuantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Supplier_Order_Items_Stddev_Pop_Fields = {
  __typename: 'supplier_order_items_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  orderUnitToPurchasingUnitFactor?: Maybe<Scalars['Float']>;
  parentSupplierOrderItemId?: Maybe<Scalars['Float']>;
  pickingDeviationReasonId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  purchasingPrice?: Maybe<Scalars['Float']>;
  purchasingPricePerUnit?: Maybe<Scalars['Float']>;
  purchasingUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  receivedQuantity?: Maybe<Scalars['Float']>;
  remainingQuantityFactor?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  supplierOrderItemReturnCauseId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  totalOrderedQuantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "supplier_order_items" */
export type Supplier_Order_Items_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToPurchasingUnitFactor?: InputMaybe<Order_By>;
  parentSupplierOrderItemId?: InputMaybe<Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  receivedQuantity?: InputMaybe<Order_By>;
  remainingQuantityFactor?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemReturnCauseId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  totalOrderedQuantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Supplier_Order_Items_Stddev_Samp_Fields = {
  __typename: 'supplier_order_items_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  orderUnitToPurchasingUnitFactor?: Maybe<Scalars['Float']>;
  parentSupplierOrderItemId?: Maybe<Scalars['Float']>;
  pickingDeviationReasonId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  purchasingPrice?: Maybe<Scalars['Float']>;
  purchasingPricePerUnit?: Maybe<Scalars['Float']>;
  purchasingUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  receivedQuantity?: Maybe<Scalars['Float']>;
  remainingQuantityFactor?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  supplierOrderItemReturnCauseId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  totalOrderedQuantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "supplier_order_items" */
export type Supplier_Order_Items_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToPurchasingUnitFactor?: InputMaybe<Order_By>;
  parentSupplierOrderItemId?: InputMaybe<Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  receivedQuantity?: InputMaybe<Order_By>;
  remainingQuantityFactor?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemReturnCauseId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  totalOrderedQuantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "supplier_order_items" */
export type Supplier_Order_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supplier_Order_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supplier_Order_Items_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Currencies_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  orderUnitId?: InputMaybe<Scalars['bigint']>;
  orderUnitToPurchasingUnitFactor?: InputMaybe<Scalars['numeric']>;
  parentSupplierOrderItemId?: InputMaybe<Scalars['bigint']>;
  pickingDeviationReasonId?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  purchasingPrice?: InputMaybe<Scalars['bigint']>;
  purchasingPricePerUnit?: InputMaybe<Scalars['bigint']>;
  purchasingUnitId?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  receivedQuantity?: InputMaybe<Scalars['bigint']>;
  remainingQuantityFactor?: InputMaybe<Scalars['numeric']>;
  state?: InputMaybe<Supplier_Order_Item_States_Enum>;
  supplierOrderId?: InputMaybe<Scalars['bigint']>;
  supplierOrderItemReturnCauseId?: InputMaybe<Scalars['bigint']>;
  targetStockLocationId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  totalOrderedQuantity?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Supplier_Order_Items_Sum_Fields = {
  __typename: 'supplier_order_items_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  orderUnitId?: Maybe<Scalars['bigint']>;
  orderUnitToPurchasingUnitFactor?: Maybe<Scalars['numeric']>;
  parentSupplierOrderItemId?: Maybe<Scalars['bigint']>;
  pickingDeviationReasonId?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  purchasingPrice?: Maybe<Scalars['bigint']>;
  purchasingPricePerUnit?: Maybe<Scalars['bigint']>;
  purchasingUnitId?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  receivedQuantity?: Maybe<Scalars['bigint']>;
  remainingQuantityFactor?: Maybe<Scalars['numeric']>;
  supplierOrderId?: Maybe<Scalars['bigint']>;
  supplierOrderItemReturnCauseId?: Maybe<Scalars['bigint']>;
  targetStockLocationId?: Maybe<Scalars['bigint']>;
  totalOrderedQuantity?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "supplier_order_items" */
export type Supplier_Order_Items_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToPurchasingUnitFactor?: InputMaybe<Order_By>;
  parentSupplierOrderItemId?: InputMaybe<Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  receivedQuantity?: InputMaybe<Order_By>;
  remainingQuantityFactor?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemReturnCauseId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  totalOrderedQuantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "supplier_order_items" */
export enum Supplier_Order_Items_Update_Column {
  /** column name */
  Notes = 'notes',
  /** column name */
  OrderUnitId = 'orderUnitId',
  /** column name */
  PickingDeviationReasonId = 'pickingDeviationReasonId',
  /** column name */
  PurchasingPricePerUnit = 'purchasingPricePerUnit',
  /** column name */
  PurchasingUnitId = 'purchasingUnitId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  State = 'state',
  /** column name */
  SupplierOrderItemReturnCauseId = 'supplierOrderItemReturnCauseId'
}

export type Supplier_Order_Items_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Supplier_Order_Items_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Supplier_Order_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supplier_Order_Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Supplier_Order_Items_Var_Pop_Fields = {
  __typename: 'supplier_order_items_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  orderUnitToPurchasingUnitFactor?: Maybe<Scalars['Float']>;
  parentSupplierOrderItemId?: Maybe<Scalars['Float']>;
  pickingDeviationReasonId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  purchasingPrice?: Maybe<Scalars['Float']>;
  purchasingPricePerUnit?: Maybe<Scalars['Float']>;
  purchasingUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  receivedQuantity?: Maybe<Scalars['Float']>;
  remainingQuantityFactor?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  supplierOrderItemReturnCauseId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  totalOrderedQuantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "supplier_order_items" */
export type Supplier_Order_Items_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToPurchasingUnitFactor?: InputMaybe<Order_By>;
  parentSupplierOrderItemId?: InputMaybe<Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  receivedQuantity?: InputMaybe<Order_By>;
  remainingQuantityFactor?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemReturnCauseId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  totalOrderedQuantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Supplier_Order_Items_Var_Samp_Fields = {
  __typename: 'supplier_order_items_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  orderUnitToPurchasingUnitFactor?: Maybe<Scalars['Float']>;
  parentSupplierOrderItemId?: Maybe<Scalars['Float']>;
  pickingDeviationReasonId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  purchasingPrice?: Maybe<Scalars['Float']>;
  purchasingPricePerUnit?: Maybe<Scalars['Float']>;
  purchasingUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  receivedQuantity?: Maybe<Scalars['Float']>;
  remainingQuantityFactor?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  supplierOrderItemReturnCauseId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  totalOrderedQuantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "supplier_order_items" */
export type Supplier_Order_Items_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToPurchasingUnitFactor?: InputMaybe<Order_By>;
  parentSupplierOrderItemId?: InputMaybe<Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  receivedQuantity?: InputMaybe<Order_By>;
  remainingQuantityFactor?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemReturnCauseId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  totalOrderedQuantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Supplier_Order_Items_Variance_Fields = {
  __typename: 'supplier_order_items_variance_fields';
  id?: Maybe<Scalars['Float']>;
  orderUnitId?: Maybe<Scalars['Float']>;
  orderUnitToPurchasingUnitFactor?: Maybe<Scalars['Float']>;
  parentSupplierOrderItemId?: Maybe<Scalars['Float']>;
  pickingDeviationReasonId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  purchasingPrice?: Maybe<Scalars['Float']>;
  purchasingPricePerUnit?: Maybe<Scalars['Float']>;
  purchasingUnitId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  receivedQuantity?: Maybe<Scalars['Float']>;
  remainingQuantityFactor?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  supplierOrderItemReturnCauseId?: Maybe<Scalars['Float']>;
  targetStockLocationId?: Maybe<Scalars['Float']>;
  totalOrderedQuantity?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "supplier_order_items" */
export type Supplier_Order_Items_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  orderUnitId?: InputMaybe<Order_By>;
  orderUnitToPurchasingUnitFactor?: InputMaybe<Order_By>;
  parentSupplierOrderItemId?: InputMaybe<Order_By>;
  pickingDeviationReasonId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  purchasingPrice?: InputMaybe<Order_By>;
  purchasingPricePerUnit?: InputMaybe<Order_By>;
  purchasingUnitId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  receivedQuantity?: InputMaybe<Order_By>;
  remainingQuantityFactor?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderItemReturnCauseId?: InputMaybe<Order_By>;
  targetStockLocationId?: InputMaybe<Order_By>;
  totalOrderedQuantity?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "supplier_order_logged_actions" */
export type Supplier_Order_Logged_Actions = {
  __typename: 'supplier_order_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  entity?: Maybe<Supplier_Orders>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "supplier_order_logged_actions" */
export type Supplier_Order_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "supplier_order_logged_actions" */
export type Supplier_Order_Logged_Actions_Aggregate = {
  __typename: 'supplier_order_logged_actions_aggregate';
  aggregate?: Maybe<Supplier_Order_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Supplier_Order_Logged_Actions>;
};

export type Supplier_Order_Logged_Actions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Supplier_Order_Logged_Actions_Aggregate_Bool_Exp_Count>;
};

export type Supplier_Order_Logged_Actions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Supplier_Order_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Supplier_Order_Logged_Actions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "supplier_order_logged_actions" */
export type Supplier_Order_Logged_Actions_Aggregate_Fields = {
  __typename: 'supplier_order_logged_actions_aggregate_fields';
  avg?: Maybe<Supplier_Order_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supplier_Order_Logged_Actions_Max_Fields>;
  min?: Maybe<Supplier_Order_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Supplier_Order_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Supplier_Order_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supplier_Order_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Supplier_Order_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Supplier_Order_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Supplier_Order_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Supplier_Order_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "supplier_order_logged_actions" */
export type Supplier_Order_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supplier_Order_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "supplier_order_logged_actions" */
export type Supplier_Order_Logged_Actions_Aggregate_Order_By = {
  avg?: InputMaybe<Supplier_Order_Logged_Actions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Supplier_Order_Logged_Actions_Max_Order_By>;
  min?: InputMaybe<Supplier_Order_Logged_Actions_Min_Order_By>;
  stddev?: InputMaybe<Supplier_Order_Logged_Actions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Supplier_Order_Logged_Actions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Supplier_Order_Logged_Actions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Supplier_Order_Logged_Actions_Sum_Order_By>;
  var_pop?: InputMaybe<Supplier_Order_Logged_Actions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Supplier_Order_Logged_Actions_Var_Samp_Order_By>;
  variance?: InputMaybe<Supplier_Order_Logged_Actions_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Supplier_Order_Logged_Actions_Avg_Fields = {
  __typename: 'supplier_order_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "supplier_order_logged_actions" */
export type Supplier_Order_Logged_Actions_Avg_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "supplier_order_logged_actions". All fields are combined with a logical 'AND'. */
export type Supplier_Order_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Supplier_Order_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Supplier_Order_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Supplier_Order_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entity?: InputMaybe<Supplier_Orders_Bool_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Supplier_Order_Logged_Actions_Max_Fields = {
  __typename: 'supplier_order_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "supplier_order_logged_actions" */
export type Supplier_Order_Logged_Actions_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Supplier_Order_Logged_Actions_Min_Fields = {
  __typename: 'supplier_order_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "supplier_order_logged_actions" */
export type Supplier_Order_Logged_Actions_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "supplier_order_logged_actions". */
export type Supplier_Order_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entity?: InputMaybe<Supplier_Orders_Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "supplier_order_logged_actions" */
export enum Supplier_Order_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Role = 'role',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Supplier_Order_Logged_Actions_Stddev_Fields = {
  __typename: 'supplier_order_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "supplier_order_logged_actions" */
export type Supplier_Order_Logged_Actions_Stddev_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Supplier_Order_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'supplier_order_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "supplier_order_logged_actions" */
export type Supplier_Order_Logged_Actions_Stddev_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Supplier_Order_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'supplier_order_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "supplier_order_logged_actions" */
export type Supplier_Order_Logged_Actions_Stddev_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "supplier_order_logged_actions" */
export type Supplier_Order_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supplier_Order_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supplier_Order_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  role?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Supplier_Order_Logged_Actions_Sum_Fields = {
  __typename: 'supplier_order_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "supplier_order_logged_actions" */
export type Supplier_Order_Logged_Actions_Sum_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Supplier_Order_Logged_Actions_Var_Pop_Fields = {
  __typename: 'supplier_order_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "supplier_order_logged_actions" */
export type Supplier_Order_Logged_Actions_Var_Pop_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Supplier_Order_Logged_Actions_Var_Samp_Fields = {
  __typename: 'supplier_order_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "supplier_order_logged_actions" */
export type Supplier_Order_Logged_Actions_Var_Samp_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Supplier_Order_Logged_Actions_Variance_Fields = {
  __typename: 'supplier_order_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "supplier_order_logged_actions" */
export type Supplier_Order_Logged_Actions_Variance_Order_By = {
  entityId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers = {
  __typename: 'supplier_order_return_item_containers';
  /** An object relationship */
  Tenant: Tenants;
  /** An object relationship */
  containerProduct: Products;
  containerProductId: Scalars['bigint'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  quantity: Scalars['bigint'];
  /** An object relationship */
  supplierOrderReturnItem: Supplier_Order_Return_Items;
  supplierOrderReturnItemId: Scalars['bigint'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};

/** aggregated selection of "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_Aggregate = {
  __typename: 'supplier_order_return_item_containers_aggregate';
  aggregate?: Maybe<Supplier_Order_Return_Item_Containers_Aggregate_Fields>;
  nodes: Array<Supplier_Order_Return_Item_Containers>;
};

export type Supplier_Order_Return_Item_Containers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Supplier_Order_Return_Item_Containers_Aggregate_Bool_Exp_Count>;
};

export type Supplier_Order_Return_Item_Containers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Supplier_Order_Return_Item_Containers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_Aggregate_Fields = {
  __typename: 'supplier_order_return_item_containers_aggregate_fields';
  avg?: Maybe<Supplier_Order_Return_Item_Containers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supplier_Order_Return_Item_Containers_Max_Fields>;
  min?: Maybe<Supplier_Order_Return_Item_Containers_Min_Fields>;
  stddev?: Maybe<Supplier_Order_Return_Item_Containers_Stddev_Fields>;
  stddev_pop?: Maybe<Supplier_Order_Return_Item_Containers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supplier_Order_Return_Item_Containers_Stddev_Samp_Fields>;
  sum?: Maybe<Supplier_Order_Return_Item_Containers_Sum_Fields>;
  var_pop?: Maybe<Supplier_Order_Return_Item_Containers_Var_Pop_Fields>;
  var_samp?: Maybe<Supplier_Order_Return_Item_Containers_Var_Samp_Fields>;
  variance?: Maybe<Supplier_Order_Return_Item_Containers_Variance_Fields>;
};


/** aggregate fields of "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_Aggregate_Order_By = {
  avg?: InputMaybe<Supplier_Order_Return_Item_Containers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Supplier_Order_Return_Item_Containers_Max_Order_By>;
  min?: InputMaybe<Supplier_Order_Return_Item_Containers_Min_Order_By>;
  stddev?: InputMaybe<Supplier_Order_Return_Item_Containers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Supplier_Order_Return_Item_Containers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Supplier_Order_Return_Item_Containers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Supplier_Order_Return_Item_Containers_Sum_Order_By>;
  var_pop?: InputMaybe<Supplier_Order_Return_Item_Containers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Supplier_Order_Return_Item_Containers_Var_Samp_Order_By>;
  variance?: InputMaybe<Supplier_Order_Return_Item_Containers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_Arr_Rel_Insert_Input = {
  data: Array<Supplier_Order_Return_Item_Containers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Supplier_Order_Return_Item_Containers_On_Conflict>;
};

/** aggregate avg on columns */
export type Supplier_Order_Return_Item_Containers_Avg_Fields = {
  __typename: 'supplier_order_return_item_containers_avg_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  supplierOrderReturnItemId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_Avg_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderReturnItemId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "supplier_order_return_item_containers". All fields are combined with a logical 'AND'. */
export type Supplier_Order_Return_Item_Containers_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Bool_Exp>>;
  _not?: InputMaybe<Supplier_Order_Return_Item_Containers_Bool_Exp>;
  _or?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Bool_Exp>>;
  containerProduct?: InputMaybe<Products_Bool_Exp>;
  containerProductId?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  quantity?: InputMaybe<Bigint_Comparison_Exp>;
  supplierOrderReturnItem?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
  supplierOrderReturnItemId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "supplier_order_return_item_containers" */
export enum Supplier_Order_Return_Item_Containers_Constraint {
  /** unique or primary key constraint on columns "container_product_id", "supplier_order_return_item_id" */
  SupplierOrderReturnItemCoSupplierOrderReturnItemIdKey = 'supplier_order_return_item_co_supplier_order_return_item_id_key',
  /** unique or primary key constraint on columns "id" */
  SupplierOrderReturnItemContainersPkey = 'supplier_order_return_item_containers_pkey'
}

/** input type for incrementing numeric columns in table "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_Inc_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  supplierOrderReturnItemId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_Insert_Input = {
  containerProduct?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  containerProductId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  supplierOrderReturnItem?: InputMaybe<Supplier_Order_Return_Items_Obj_Rel_Insert_Input>;
  supplierOrderReturnItemId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Supplier_Order_Return_Item_Containers_Max_Fields = {
  __typename: 'supplier_order_return_item_containers_max_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  supplierOrderReturnItemId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_Max_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderReturnItemId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Supplier_Order_Return_Item_Containers_Min_Fields = {
  __typename: 'supplier_order_return_item_containers_min_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  supplierOrderReturnItemId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_Min_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderReturnItemId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_Mutation_Response = {
  __typename: 'supplier_order_return_item_containers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Supplier_Order_Return_Item_Containers>;
};

/** on_conflict condition type for table "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_On_Conflict = {
  constraint: Supplier_Order_Return_Item_Containers_Constraint;
  update_columns?: Array<Supplier_Order_Return_Item_Containers_Update_Column>;
  where?: InputMaybe<Supplier_Order_Return_Item_Containers_Bool_Exp>;
};

/** Ordering options when selecting data from "supplier_order_return_item_containers". */
export type Supplier_Order_Return_Item_Containers_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  containerProduct?: InputMaybe<Products_Order_By>;
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderReturnItem?: InputMaybe<Supplier_Order_Return_Items_Order_By>;
  supplierOrderReturnItemId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: supplier_order_return_item_containers */
export type Supplier_Order_Return_Item_Containers_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "supplier_order_return_item_containers" */
export enum Supplier_Order_Return_Item_Containers_Select_Column {
  /** column name */
  ContainerProductId = 'containerProductId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SupplierOrderReturnItemId = 'supplierOrderReturnItemId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_Set_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  supplierOrderReturnItemId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Supplier_Order_Return_Item_Containers_Stddev_Fields = {
  __typename: 'supplier_order_return_item_containers_stddev_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  supplierOrderReturnItemId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_Stddev_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderReturnItemId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Supplier_Order_Return_Item_Containers_Stddev_Pop_Fields = {
  __typename: 'supplier_order_return_item_containers_stddev_pop_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  supplierOrderReturnItemId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_Stddev_Pop_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderReturnItemId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Supplier_Order_Return_Item_Containers_Stddev_Samp_Fields = {
  __typename: 'supplier_order_return_item_containers_stddev_samp_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  supplierOrderReturnItemId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_Stddev_Samp_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderReturnItemId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supplier_Order_Return_Item_Containers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supplier_Order_Return_Item_Containers_Stream_Cursor_Value_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['bigint']>;
  supplierOrderReturnItemId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Supplier_Order_Return_Item_Containers_Sum_Fields = {
  __typename: 'supplier_order_return_item_containers_sum_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  quantity?: Maybe<Scalars['bigint']>;
  supplierOrderReturnItemId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_Sum_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderReturnItemId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "supplier_order_return_item_containers" */
export enum Supplier_Order_Return_Item_Containers_Update_Column {
  /** column name */
  ContainerProductId = 'containerProductId',
  /** column name */
  Id = 'id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SupplierOrderReturnItemId = 'supplierOrderReturnItemId',
  /** column name */
  Version = 'version'
}

export type Supplier_Order_Return_Item_Containers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Supplier_Order_Return_Item_Containers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Supplier_Order_Return_Item_Containers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supplier_Order_Return_Item_Containers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Supplier_Order_Return_Item_Containers_Var_Pop_Fields = {
  __typename: 'supplier_order_return_item_containers_var_pop_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  supplierOrderReturnItemId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_Var_Pop_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderReturnItemId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Supplier_Order_Return_Item_Containers_Var_Samp_Fields = {
  __typename: 'supplier_order_return_item_containers_var_samp_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  supplierOrderReturnItemId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_Var_Samp_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderReturnItemId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Supplier_Order_Return_Item_Containers_Variance_Fields = {
  __typename: 'supplier_order_return_item_containers_variance_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  supplierOrderReturnItemId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "supplier_order_return_item_containers" */
export type Supplier_Order_Return_Item_Containers_Variance_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  supplierOrderReturnItemId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "supplier_order_return_items" */
export type Supplier_Order_Return_Items = {
  __typename: 'supplier_order_return_items';
  /** An object relationship */
  LotNumber?: Maybe<Lot_Numbers>;
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  imageAttachment?: Maybe<S3_Objects>;
  imageAttachmentId?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  returnCause?: Maybe<Supplier_Order_Item_Return_Causes>;
  returnCauseId?: Maybe<Scalars['bigint']>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity: Scalars['bigint'];
  returnedMonetaryQuantity: Scalars['bigint'];
  /** An object relationship */
  returnedMonetaryUnit: Product_Units;
  returnedMonetaryUnitId: Scalars['bigint'];
  returnedStorageQuantity: Scalars['bigint'];
  /** An object relationship */
  returnedStorageUnit: Product_Units;
  returnedStorageUnitId: Scalars['bigint'];
  sourceMonetaryQuantity: Scalars['bigint'];
  /** An object relationship */
  sourceMonetaryUnit: Product_Units;
  sourceMonetaryUnitId: Scalars['bigint'];
  /** An object relationship */
  sourceStockLocation: Stock_Locations;
  sourceStockLocationId: Scalars['bigint'];
  sourceStorageQuantity: Scalars['bigint'];
  /** An object relationship */
  sourceStorageUnit: Product_Units;
  sourceStorageUnitId: Scalars['bigint'];
  supplierNote?: Maybe<Scalars['String']>;
  /** An object relationship */
  supplierOrderItem: Supplier_Order_Items;
  supplierOrderItemId: Scalars['bigint'];
  /** An object relationship */
  supplierOrderReturn: Supplier_Order_Returns;
  supplierOrderReturnId: Scalars['bigint'];
  /** An array relationship */
  supplierOrderReturnItemContainers: Array<Supplier_Order_Return_Item_Containers>;
  /** An aggregate relationship */
  supplierOrderReturnItemContainers_aggregate: Supplier_Order_Return_Item_Containers_Aggregate;
  tareWeight: Scalars['bigint'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "supplier_order_return_items" */
export type Supplier_Order_Return_ItemsSupplierOrderReturnItemContainersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Item_Containers_Bool_Exp>;
};


/** columns and relationships of "supplier_order_return_items" */
export type Supplier_Order_Return_ItemsSupplierOrderReturnItemContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Item_Containers_Bool_Exp>;
};

/** aggregated selection of "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Aggregate = {
  __typename: 'supplier_order_return_items_aggregate';
  aggregate?: Maybe<Supplier_Order_Return_Items_Aggregate_Fields>;
  nodes: Array<Supplier_Order_Return_Items>;
};

export type Supplier_Order_Return_Items_Aggregate_Bool_Exp = {
  count?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Bool_Exp_Count>;
};

export type Supplier_Order_Return_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Aggregate_Fields = {
  __typename: 'supplier_order_return_items_aggregate_fields';
  avg?: Maybe<Supplier_Order_Return_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supplier_Order_Return_Items_Max_Fields>;
  min?: Maybe<Supplier_Order_Return_Items_Min_Fields>;
  stddev?: Maybe<Supplier_Order_Return_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Supplier_Order_Return_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supplier_Order_Return_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Supplier_Order_Return_Items_Sum_Fields>;
  var_pop?: Maybe<Supplier_Order_Return_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Supplier_Order_Return_Items_Var_Samp_Fields>;
  variance?: Maybe<Supplier_Order_Return_Items_Variance_Fields>;
};


/** aggregate fields of "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Aggregate_Order_By = {
  avg?: InputMaybe<Supplier_Order_Return_Items_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Supplier_Order_Return_Items_Max_Order_By>;
  min?: InputMaybe<Supplier_Order_Return_Items_Min_Order_By>;
  stddev?: InputMaybe<Supplier_Order_Return_Items_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Supplier_Order_Return_Items_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Supplier_Order_Return_Items_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Supplier_Order_Return_Items_Sum_Order_By>;
  var_pop?: InputMaybe<Supplier_Order_Return_Items_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Supplier_Order_Return_Items_Var_Samp_Order_By>;
  variance?: InputMaybe<Supplier_Order_Return_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Arr_Rel_Insert_Input = {
  data: Array<Supplier_Order_Return_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Supplier_Order_Return_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Supplier_Order_Return_Items_Avg_Fields = {
  __typename: 'supplier_order_return_items_avg_fields';
  id?: Maybe<Scalars['Float']>;
  imageAttachmentId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  returnCauseId?: Maybe<Scalars['Float']>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryUnitId?: Maybe<Scalars['Float']>;
  returnedStorageQuantity?: Maybe<Scalars['Float']>;
  returnedStorageUnitId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStockLocationId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
  supplierOrderReturnId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  supplierOrderReturnId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "supplier_order_return_items". All fields are combined with a logical 'AND'. */
export type Supplier_Order_Return_Items_Bool_Exp = {
  LotNumber?: InputMaybe<Lot_Numbers_Bool_Exp>;
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Supplier_Order_Return_Items_Bool_Exp>>;
  _not?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Supplier_Order_Return_Items_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  imageAttachment?: InputMaybe<S3_Objects_Bool_Exp>;
  imageAttachmentId?: InputMaybe<Bigint_Comparison_Exp>;
  lotNumber?: InputMaybe<String_Comparison_Exp>;
  lotNumberId?: InputMaybe<Bigint_Comparison_Exp>;
  returnCause?: InputMaybe<Supplier_Order_Item_Return_Causes_Bool_Exp>;
  returnCauseId?: InputMaybe<Bigint_Comparison_Exp>;
  returnedCustomQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  returnedMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  returnedMonetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  returnedMonetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  returnedStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  returnedStorageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  returnedStorageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  sourceMonetaryQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  sourceMonetaryUnit?: InputMaybe<Product_Units_Bool_Exp>;
  sourceMonetaryUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  sourceStockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  sourceStockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  sourceStorageQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  sourceStorageUnit?: InputMaybe<Product_Units_Bool_Exp>;
  sourceStorageUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  supplierNote?: InputMaybe<String_Comparison_Exp>;
  supplierOrderItem?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
  supplierOrderItemId?: InputMaybe<Bigint_Comparison_Exp>;
  supplierOrderReturn?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
  supplierOrderReturnId?: InputMaybe<Bigint_Comparison_Exp>;
  supplierOrderReturnItemContainers?: InputMaybe<Supplier_Order_Return_Item_Containers_Bool_Exp>;
  supplierOrderReturnItemContainers_aggregate?: InputMaybe<Supplier_Order_Return_Item_Containers_Aggregate_Bool_Exp>;
  tareWeight?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "supplier_order_return_items" */
export enum Supplier_Order_Return_Items_Constraint {
  /** unique or primary key constraint on columns "id" */
  SupplierOrderReturnItemsPkey = 'supplier_order_return_items_pkey'
}

/** input type for incrementing numeric columns in table "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  imageAttachmentId?: InputMaybe<Scalars['bigint']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  returnCauseId?: InputMaybe<Scalars['bigint']>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: InputMaybe<Scalars['bigint']>;
  returnedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  returnedMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  returnedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  returnedStorageUnitId?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  sourceStockLocationId?: InputMaybe<Scalars['bigint']>;
  sourceStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sourceStorageUnitId?: InputMaybe<Scalars['bigint']>;
  supplierOrderItemId?: InputMaybe<Scalars['bigint']>;
  supplierOrderReturnId?: InputMaybe<Scalars['bigint']>;
  tareWeight?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Insert_Input = {
  LotNumber?: InputMaybe<Lot_Numbers_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  imageAttachment?: InputMaybe<S3_Objects_Obj_Rel_Insert_Input>;
  imageAttachmentId?: InputMaybe<Scalars['bigint']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  returnCause?: InputMaybe<Supplier_Order_Item_Return_Causes_Obj_Rel_Insert_Input>;
  returnCauseId?: InputMaybe<Scalars['bigint']>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: InputMaybe<Scalars['bigint']>;
  returnedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  returnedMonetaryUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  returnedMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  returnedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  returnedStorageUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  returnedStorageUnitId?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  sourceMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  sourceStockLocation?: InputMaybe<Stock_Locations_Obj_Rel_Insert_Input>;
  sourceStockLocationId?: InputMaybe<Scalars['bigint']>;
  sourceStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sourceStorageUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  sourceStorageUnitId?: InputMaybe<Scalars['bigint']>;
  supplierNote?: InputMaybe<Scalars['String']>;
  supplierOrderItem?: InputMaybe<Supplier_Order_Items_Obj_Rel_Insert_Input>;
  supplierOrderItemId?: InputMaybe<Scalars['bigint']>;
  supplierOrderReturn?: InputMaybe<Supplier_Order_Returns_Obj_Rel_Insert_Input>;
  supplierOrderReturnId?: InputMaybe<Scalars['bigint']>;
  supplierOrderReturnItemContainers?: InputMaybe<Supplier_Order_Return_Item_Containers_Arr_Rel_Insert_Input>;
  tareWeight?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Supplier_Order_Return_Items_Max_Fields = {
  __typename: 'supplier_order_return_items_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  imageAttachmentId?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  returnCauseId?: Maybe<Scalars['bigint']>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: Maybe<Scalars['bigint']>;
  returnedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  returnedMonetaryUnitId?: Maybe<Scalars['bigint']>;
  returnedStorageQuantity?: Maybe<Scalars['bigint']>;
  returnedStorageUnitId?: Maybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sourceMonetaryUnitId?: Maybe<Scalars['bigint']>;
  sourceStockLocationId?: Maybe<Scalars['bigint']>;
  sourceStorageQuantity?: Maybe<Scalars['bigint']>;
  sourceStorageUnitId?: Maybe<Scalars['bigint']>;
  supplierNote?: Maybe<Scalars['String']>;
  supplierOrderItemId?: Maybe<Scalars['bigint']>;
  supplierOrderReturnId?: Maybe<Scalars['bigint']>;
  tareWeight?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  supplierNote?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  supplierOrderReturnId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Supplier_Order_Return_Items_Min_Fields = {
  __typename: 'supplier_order_return_items_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  imageAttachmentId?: Maybe<Scalars['bigint']>;
  lotNumber?: Maybe<Scalars['String']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  returnCauseId?: Maybe<Scalars['bigint']>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: Maybe<Scalars['bigint']>;
  returnedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  returnedMonetaryUnitId?: Maybe<Scalars['bigint']>;
  returnedStorageQuantity?: Maybe<Scalars['bigint']>;
  returnedStorageUnitId?: Maybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sourceMonetaryUnitId?: Maybe<Scalars['bigint']>;
  sourceStockLocationId?: Maybe<Scalars['bigint']>;
  sourceStorageQuantity?: Maybe<Scalars['bigint']>;
  sourceStorageUnitId?: Maybe<Scalars['bigint']>;
  supplierNote?: Maybe<Scalars['String']>;
  supplierOrderItemId?: Maybe<Scalars['bigint']>;
  supplierOrderReturnId?: Maybe<Scalars['bigint']>;
  tareWeight?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  supplierNote?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  supplierOrderReturnId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Mutation_Response = {
  __typename: 'supplier_order_return_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Supplier_Order_Return_Items>;
};

/** input type for inserting object relation for remote table "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Obj_Rel_Insert_Input = {
  data: Supplier_Order_Return_Items_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Supplier_Order_Return_Items_On_Conflict>;
};

/** on_conflict condition type for table "supplier_order_return_items" */
export type Supplier_Order_Return_Items_On_Conflict = {
  constraint: Supplier_Order_Return_Items_Constraint;
  update_columns?: Array<Supplier_Order_Return_Items_Update_Column>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "supplier_order_return_items". */
export type Supplier_Order_Return_Items_Order_By = {
  LotNumber?: InputMaybe<Lot_Numbers_Order_By>;
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageAttachment?: InputMaybe<S3_Objects_Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumber?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCause?: InputMaybe<Supplier_Order_Item_Return_Causes_Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnit?: InputMaybe<Product_Units_Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnit?: InputMaybe<Product_Units_Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnit?: InputMaybe<Product_Units_Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocation?: InputMaybe<Stock_Locations_Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnit?: InputMaybe<Product_Units_Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  supplierNote?: InputMaybe<Order_By>;
  supplierOrderItem?: InputMaybe<Supplier_Order_Items_Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  supplierOrderReturn?: InputMaybe<Supplier_Order_Returns_Order_By>;
  supplierOrderReturnId?: InputMaybe<Order_By>;
  supplierOrderReturnItemContainers_aggregate?: InputMaybe<Supplier_Order_Return_Item_Containers_Aggregate_Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: supplier_order_return_items */
export type Supplier_Order_Return_Items_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "supplier_order_return_items" */
export enum Supplier_Order_Return_Items_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ImageAttachmentId = 'imageAttachmentId',
  /** column name */
  LotNumber = 'lotNumber',
  /** column name */
  LotNumberId = 'lotNumberId',
  /** column name */
  ReturnCauseId = 'returnCauseId',
  /** column name */
  ReturnedCustomQuantity = 'returnedCustomQuantity',
  /** column name */
  ReturnedMonetaryQuantity = 'returnedMonetaryQuantity',
  /** column name */
  ReturnedMonetaryUnitId = 'returnedMonetaryUnitId',
  /** column name */
  ReturnedStorageQuantity = 'returnedStorageQuantity',
  /** column name */
  ReturnedStorageUnitId = 'returnedStorageUnitId',
  /** column name */
  SourceMonetaryQuantity = 'sourceMonetaryQuantity',
  /** column name */
  SourceMonetaryUnitId = 'sourceMonetaryUnitId',
  /** column name */
  SourceStockLocationId = 'sourceStockLocationId',
  /** column name */
  SourceStorageQuantity = 'sourceStorageQuantity',
  /** column name */
  SourceStorageUnitId = 'sourceStorageUnitId',
  /** column name */
  SupplierNote = 'supplierNote',
  /** column name */
  SupplierOrderItemId = 'supplierOrderItemId',
  /** column name */
  SupplierOrderReturnId = 'supplierOrderReturnId',
  /** column name */
  TareWeight = 'tareWeight',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  imageAttachmentId?: InputMaybe<Scalars['bigint']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  returnCauseId?: InputMaybe<Scalars['bigint']>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: InputMaybe<Scalars['bigint']>;
  returnedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  returnedMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  returnedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  returnedStorageUnitId?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  sourceStockLocationId?: InputMaybe<Scalars['bigint']>;
  sourceStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sourceStorageUnitId?: InputMaybe<Scalars['bigint']>;
  supplierNote?: InputMaybe<Scalars['String']>;
  supplierOrderItemId?: InputMaybe<Scalars['bigint']>;
  supplierOrderReturnId?: InputMaybe<Scalars['bigint']>;
  tareWeight?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Supplier_Order_Return_Items_Stddev_Fields = {
  __typename: 'supplier_order_return_items_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  imageAttachmentId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  returnCauseId?: Maybe<Scalars['Float']>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryUnitId?: Maybe<Scalars['Float']>;
  returnedStorageQuantity?: Maybe<Scalars['Float']>;
  returnedStorageUnitId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStockLocationId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
  supplierOrderReturnId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  supplierOrderReturnId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Supplier_Order_Return_Items_Stddev_Pop_Fields = {
  __typename: 'supplier_order_return_items_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  imageAttachmentId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  returnCauseId?: Maybe<Scalars['Float']>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryUnitId?: Maybe<Scalars['Float']>;
  returnedStorageQuantity?: Maybe<Scalars['Float']>;
  returnedStorageUnitId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStockLocationId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
  supplierOrderReturnId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  supplierOrderReturnId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Supplier_Order_Return_Items_Stddev_Samp_Fields = {
  __typename: 'supplier_order_return_items_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  imageAttachmentId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  returnCauseId?: Maybe<Scalars['Float']>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryUnitId?: Maybe<Scalars['Float']>;
  returnedStorageQuantity?: Maybe<Scalars['Float']>;
  returnedStorageUnitId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStockLocationId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
  supplierOrderReturnId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  supplierOrderReturnId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supplier_Order_Return_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supplier_Order_Return_Items_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  imageAttachmentId?: InputMaybe<Scalars['bigint']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  lotNumberId?: InputMaybe<Scalars['bigint']>;
  returnCauseId?: InputMaybe<Scalars['bigint']>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: InputMaybe<Scalars['bigint']>;
  returnedMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  returnedMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  returnedStorageQuantity?: InputMaybe<Scalars['bigint']>;
  returnedStorageUnitId?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: InputMaybe<Scalars['bigint']>;
  sourceMonetaryUnitId?: InputMaybe<Scalars['bigint']>;
  sourceStockLocationId?: InputMaybe<Scalars['bigint']>;
  sourceStorageQuantity?: InputMaybe<Scalars['bigint']>;
  sourceStorageUnitId?: InputMaybe<Scalars['bigint']>;
  supplierNote?: InputMaybe<Scalars['String']>;
  supplierOrderItemId?: InputMaybe<Scalars['bigint']>;
  supplierOrderReturnId?: InputMaybe<Scalars['bigint']>;
  tareWeight?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Supplier_Order_Return_Items_Sum_Fields = {
  __typename: 'supplier_order_return_items_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  imageAttachmentId?: Maybe<Scalars['bigint']>;
  lotNumberId?: Maybe<Scalars['bigint']>;
  returnCauseId?: Maybe<Scalars['bigint']>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: Maybe<Scalars['bigint']>;
  returnedMonetaryQuantity?: Maybe<Scalars['bigint']>;
  returnedMonetaryUnitId?: Maybe<Scalars['bigint']>;
  returnedStorageQuantity?: Maybe<Scalars['bigint']>;
  returnedStorageUnitId?: Maybe<Scalars['bigint']>;
  sourceMonetaryQuantity?: Maybe<Scalars['bigint']>;
  sourceMonetaryUnitId?: Maybe<Scalars['bigint']>;
  sourceStockLocationId?: Maybe<Scalars['bigint']>;
  sourceStorageQuantity?: Maybe<Scalars['bigint']>;
  sourceStorageUnitId?: Maybe<Scalars['bigint']>;
  supplierOrderItemId?: Maybe<Scalars['bigint']>;
  supplierOrderReturnId?: Maybe<Scalars['bigint']>;
  tareWeight?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  supplierOrderReturnId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "supplier_order_return_items" */
export enum Supplier_Order_Return_Items_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ImageAttachmentId = 'imageAttachmentId',
  /** column name */
  LotNumber = 'lotNumber',
  /** column name */
  LotNumberId = 'lotNumberId',
  /** column name */
  ReturnCauseId = 'returnCauseId',
  /** column name */
  ReturnedCustomQuantity = 'returnedCustomQuantity',
  /** column name */
  ReturnedMonetaryQuantity = 'returnedMonetaryQuantity',
  /** column name */
  ReturnedMonetaryUnitId = 'returnedMonetaryUnitId',
  /** column name */
  ReturnedStorageQuantity = 'returnedStorageQuantity',
  /** column name */
  ReturnedStorageUnitId = 'returnedStorageUnitId',
  /** column name */
  SourceMonetaryQuantity = 'sourceMonetaryQuantity',
  /** column name */
  SourceMonetaryUnitId = 'sourceMonetaryUnitId',
  /** column name */
  SourceStockLocationId = 'sourceStockLocationId',
  /** column name */
  SourceStorageQuantity = 'sourceStorageQuantity',
  /** column name */
  SourceStorageUnitId = 'sourceStorageUnitId',
  /** column name */
  SupplierNote = 'supplierNote',
  /** column name */
  SupplierOrderItemId = 'supplierOrderItemId',
  /** column name */
  SupplierOrderReturnId = 'supplierOrderReturnId',
  /** column name */
  TareWeight = 'tareWeight',
  /** column name */
  Version = 'version'
}

export type Supplier_Order_Return_Items_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Supplier_Order_Return_Items_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Supplier_Order_Return_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supplier_Order_Return_Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Supplier_Order_Return_Items_Var_Pop_Fields = {
  __typename: 'supplier_order_return_items_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  imageAttachmentId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  returnCauseId?: Maybe<Scalars['Float']>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryUnitId?: Maybe<Scalars['Float']>;
  returnedStorageQuantity?: Maybe<Scalars['Float']>;
  returnedStorageUnitId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStockLocationId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
  supplierOrderReturnId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  supplierOrderReturnId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Supplier_Order_Return_Items_Var_Samp_Fields = {
  __typename: 'supplier_order_return_items_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  imageAttachmentId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  returnCauseId?: Maybe<Scalars['Float']>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryUnitId?: Maybe<Scalars['Float']>;
  returnedStorageQuantity?: Maybe<Scalars['Float']>;
  returnedStorageUnitId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStockLocationId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
  supplierOrderReturnId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  supplierOrderReturnId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Supplier_Order_Return_Items_Variance_Fields = {
  __typename: 'supplier_order_return_items_variance_fields';
  id?: Maybe<Scalars['Float']>;
  imageAttachmentId?: Maybe<Scalars['Float']>;
  lotNumberId?: Maybe<Scalars['Float']>;
  returnCauseId?: Maybe<Scalars['Float']>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryQuantity?: Maybe<Scalars['Float']>;
  returnedMonetaryUnitId?: Maybe<Scalars['Float']>;
  returnedStorageQuantity?: Maybe<Scalars['Float']>;
  returnedStorageUnitId?: Maybe<Scalars['Float']>;
  sourceMonetaryQuantity?: Maybe<Scalars['Float']>;
  sourceMonetaryUnitId?: Maybe<Scalars['Float']>;
  sourceStockLocationId?: Maybe<Scalars['Float']>;
  sourceStorageQuantity?: Maybe<Scalars['Float']>;
  sourceStorageUnitId?: Maybe<Scalars['Float']>;
  supplierOrderItemId?: Maybe<Scalars['Float']>;
  supplierOrderReturnId?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "supplier_order_return_items" */
export type Supplier_Order_Return_Items_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  imageAttachmentId?: InputMaybe<Order_By>;
  lotNumberId?: InputMaybe<Order_By>;
  returnCauseId?: InputMaybe<Order_By>;
  /** How much will be reimbursed in sales quantity for (sending back 12 boxes, getting back money for 11 boxes) */
  returnedCustomQuantity?: InputMaybe<Order_By>;
  returnedMonetaryQuantity?: InputMaybe<Order_By>;
  returnedMonetaryUnitId?: InputMaybe<Order_By>;
  returnedStorageQuantity?: InputMaybe<Order_By>;
  returnedStorageUnitId?: InputMaybe<Order_By>;
  sourceMonetaryQuantity?: InputMaybe<Order_By>;
  sourceMonetaryUnitId?: InputMaybe<Order_By>;
  sourceStockLocationId?: InputMaybe<Order_By>;
  sourceStorageQuantity?: InputMaybe<Order_By>;
  sourceStorageUnitId?: InputMaybe<Order_By>;
  supplierOrderItemId?: InputMaybe<Order_By>;
  supplierOrderReturnId?: InputMaybe<Order_By>;
  tareWeight?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "supplier_order_return_states" */
export type Supplier_Order_Return_States = {
  __typename: 'supplier_order_return_states';
  /** An array relationship */
  supplierOrderReturns: Array<Supplier_Order_Returns>;
  /** An aggregate relationship */
  supplierOrderReturns_aggregate: Supplier_Order_Returns_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "supplier_order_return_states" */
export type Supplier_Order_Return_StatesSupplierOrderReturnsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returns_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
};


/** columns and relationships of "supplier_order_return_states" */
export type Supplier_Order_Return_StatesSupplierOrderReturns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returns_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
};

/** aggregated selection of "supplier_order_return_states" */
export type Supplier_Order_Return_States_Aggregate = {
  __typename: 'supplier_order_return_states_aggregate';
  aggregate?: Maybe<Supplier_Order_Return_States_Aggregate_Fields>;
  nodes: Array<Supplier_Order_Return_States>;
};

/** aggregate fields of "supplier_order_return_states" */
export type Supplier_Order_Return_States_Aggregate_Fields = {
  __typename: 'supplier_order_return_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Supplier_Order_Return_States_Max_Fields>;
  min?: Maybe<Supplier_Order_Return_States_Min_Fields>;
};


/** aggregate fields of "supplier_order_return_states" */
export type Supplier_Order_Return_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supplier_Order_Return_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "supplier_order_return_states". All fields are combined with a logical 'AND'. */
export type Supplier_Order_Return_States_Bool_Exp = {
  _and?: InputMaybe<Array<Supplier_Order_Return_States_Bool_Exp>>;
  _not?: InputMaybe<Supplier_Order_Return_States_Bool_Exp>;
  _or?: InputMaybe<Array<Supplier_Order_Return_States_Bool_Exp>>;
  supplierOrderReturns?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
  supplierOrderReturns_aggregate?: InputMaybe<Supplier_Order_Returns_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Supplier_Order_Return_States_Enum {
  Active = 'ACTIVE',
  Open = 'OPEN'
}

/** Boolean expression to compare columns of type "supplier_order_return_states_enum". All fields are combined with logical 'AND'. */
export type Supplier_Order_Return_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Supplier_Order_Return_States_Enum>;
  _in?: InputMaybe<Array<Supplier_Order_Return_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Supplier_Order_Return_States_Enum>;
  _nin?: InputMaybe<Array<Supplier_Order_Return_States_Enum>>;
};

/** aggregate max on columns */
export type Supplier_Order_Return_States_Max_Fields = {
  __typename: 'supplier_order_return_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Supplier_Order_Return_States_Min_Fields = {
  __typename: 'supplier_order_return_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "supplier_order_return_states". */
export type Supplier_Order_Return_States_Order_By = {
  supplierOrderReturns_aggregate?: InputMaybe<Supplier_Order_Returns_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "supplier_order_return_states" */
export enum Supplier_Order_Return_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "supplier_order_return_states" */
export type Supplier_Order_Return_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supplier_Order_Return_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supplier_Order_Return_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers = {
  __typename: 'supplier_order_returned_containers';
  /** An object relationship */
  Tenant: Tenants;
  /** An object relationship */
  containerProduct: Products;
  containerProductId: Scalars['bigint'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  journalEntry: Journal_Entries;
  journalEntryId: Scalars['bigint'];
  returnedQuantity: Scalars['bigint'];
  /** An object relationship */
  supplierOrder: Supplier_Orders;
  supplierOrderId: Scalars['bigint'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};

/** aggregated selection of "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_Aggregate = {
  __typename: 'supplier_order_returned_containers_aggregate';
  aggregate?: Maybe<Supplier_Order_Returned_Containers_Aggregate_Fields>;
  nodes: Array<Supplier_Order_Returned_Containers>;
};

export type Supplier_Order_Returned_Containers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Supplier_Order_Returned_Containers_Aggregate_Bool_Exp_Count>;
};

export type Supplier_Order_Returned_Containers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Supplier_Order_Returned_Containers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Supplier_Order_Returned_Containers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_Aggregate_Fields = {
  __typename: 'supplier_order_returned_containers_aggregate_fields';
  avg?: Maybe<Supplier_Order_Returned_Containers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supplier_Order_Returned_Containers_Max_Fields>;
  min?: Maybe<Supplier_Order_Returned_Containers_Min_Fields>;
  stddev?: Maybe<Supplier_Order_Returned_Containers_Stddev_Fields>;
  stddev_pop?: Maybe<Supplier_Order_Returned_Containers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supplier_Order_Returned_Containers_Stddev_Samp_Fields>;
  sum?: Maybe<Supplier_Order_Returned_Containers_Sum_Fields>;
  var_pop?: Maybe<Supplier_Order_Returned_Containers_Var_Pop_Fields>;
  var_samp?: Maybe<Supplier_Order_Returned_Containers_Var_Samp_Fields>;
  variance?: Maybe<Supplier_Order_Returned_Containers_Variance_Fields>;
};


/** aggregate fields of "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supplier_Order_Returned_Containers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_Aggregate_Order_By = {
  avg?: InputMaybe<Supplier_Order_Returned_Containers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Supplier_Order_Returned_Containers_Max_Order_By>;
  min?: InputMaybe<Supplier_Order_Returned_Containers_Min_Order_By>;
  stddev?: InputMaybe<Supplier_Order_Returned_Containers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Supplier_Order_Returned_Containers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Supplier_Order_Returned_Containers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Supplier_Order_Returned_Containers_Sum_Order_By>;
  var_pop?: InputMaybe<Supplier_Order_Returned_Containers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Supplier_Order_Returned_Containers_Var_Samp_Order_By>;
  variance?: InputMaybe<Supplier_Order_Returned_Containers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_Arr_Rel_Insert_Input = {
  data: Array<Supplier_Order_Returned_Containers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Supplier_Order_Returned_Containers_On_Conflict>;
};

/** aggregate avg on columns */
export type Supplier_Order_Returned_Containers_Avg_Fields = {
  __typename: 'supplier_order_returned_containers_avg_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  returnedQuantity?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_Avg_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  returnedQuantity?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "supplier_order_returned_containers". All fields are combined with a logical 'AND'. */
export type Supplier_Order_Returned_Containers_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Supplier_Order_Returned_Containers_Bool_Exp>>;
  _not?: InputMaybe<Supplier_Order_Returned_Containers_Bool_Exp>;
  _or?: InputMaybe<Array<Supplier_Order_Returned_Containers_Bool_Exp>>;
  containerProduct?: InputMaybe<Products_Bool_Exp>;
  containerProductId?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  journalEntry?: InputMaybe<Journal_Entries_Bool_Exp>;
  journalEntryId?: InputMaybe<Bigint_Comparison_Exp>;
  returnedQuantity?: InputMaybe<Bigint_Comparison_Exp>;
  supplierOrder?: InputMaybe<Supplier_Orders_Bool_Exp>;
  supplierOrderId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "supplier_order_returned_containers" */
export enum Supplier_Order_Returned_Containers_Constraint {
  /** unique or primary key constraint on columns "container_product_id", "supplier_order_id" */
  SupplierOrderReturnedContaSupplierOrderIdContainerPKey = 'supplier_order_returned_conta_supplier_order_id_container_p_key',
  /** unique or primary key constraint on columns "id" */
  SupplierOrderReturnedContainersPkey = 'supplier_order_returned_containers_pkey'
}

/** input type for incrementing numeric columns in table "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_Inc_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  returnedQuantity?: InputMaybe<Scalars['bigint']>;
  supplierOrderId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_Insert_Input = {
  containerProduct?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  containerProductId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntry?: InputMaybe<Journal_Entries_Obj_Rel_Insert_Input>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  returnedQuantity?: InputMaybe<Scalars['bigint']>;
  supplierOrder?: InputMaybe<Supplier_Orders_Obj_Rel_Insert_Input>;
  supplierOrderId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Supplier_Order_Returned_Containers_Max_Fields = {
  __typename: 'supplier_order_returned_containers_max_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  returnedQuantity?: Maybe<Scalars['bigint']>;
  supplierOrderId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_Max_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  returnedQuantity?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Supplier_Order_Returned_Containers_Min_Fields = {
  __typename: 'supplier_order_returned_containers_min_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  returnedQuantity?: Maybe<Scalars['bigint']>;
  supplierOrderId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_Min_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  returnedQuantity?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_Mutation_Response = {
  __typename: 'supplier_order_returned_containers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Supplier_Order_Returned_Containers>;
};

/** on_conflict condition type for table "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_On_Conflict = {
  constraint: Supplier_Order_Returned_Containers_Constraint;
  update_columns?: Array<Supplier_Order_Returned_Containers_Update_Column>;
  where?: InputMaybe<Supplier_Order_Returned_Containers_Bool_Exp>;
};

/** Ordering options when selecting data from "supplier_order_returned_containers". */
export type Supplier_Order_Returned_Containers_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  containerProduct?: InputMaybe<Products_Order_By>;
  containerProductId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntry?: InputMaybe<Journal_Entries_Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  returnedQuantity?: InputMaybe<Order_By>;
  supplierOrder?: InputMaybe<Supplier_Orders_Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: supplier_order_returned_containers */
export type Supplier_Order_Returned_Containers_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "supplier_order_returned_containers" */
export enum Supplier_Order_Returned_Containers_Select_Column {
  /** column name */
  ContainerProductId = 'containerProductId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  JournalEntryId = 'journalEntryId',
  /** column name */
  ReturnedQuantity = 'returnedQuantity',
  /** column name */
  SupplierOrderId = 'supplierOrderId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_Set_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  returnedQuantity?: InputMaybe<Scalars['bigint']>;
  supplierOrderId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Supplier_Order_Returned_Containers_Stddev_Fields = {
  __typename: 'supplier_order_returned_containers_stddev_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  returnedQuantity?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_Stddev_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  returnedQuantity?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Supplier_Order_Returned_Containers_Stddev_Pop_Fields = {
  __typename: 'supplier_order_returned_containers_stddev_pop_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  returnedQuantity?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_Stddev_Pop_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  returnedQuantity?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Supplier_Order_Returned_Containers_Stddev_Samp_Fields = {
  __typename: 'supplier_order_returned_containers_stddev_samp_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  returnedQuantity?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_Stddev_Samp_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  returnedQuantity?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supplier_Order_Returned_Containers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supplier_Order_Returned_Containers_Stream_Cursor_Value_Input = {
  containerProductId?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  returnedQuantity?: InputMaybe<Scalars['bigint']>;
  supplierOrderId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Supplier_Order_Returned_Containers_Sum_Fields = {
  __typename: 'supplier_order_returned_containers_sum_fields';
  containerProductId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  returnedQuantity?: Maybe<Scalars['bigint']>;
  supplierOrderId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_Sum_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  returnedQuantity?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "supplier_order_returned_containers" */
export enum Supplier_Order_Returned_Containers_Update_Column {
  /** column name */
  ContainerProductId = 'containerProductId',
  /** column name */
  Id = 'id',
  /** column name */
  JournalEntryId = 'journalEntryId',
  /** column name */
  ReturnedQuantity = 'returnedQuantity',
  /** column name */
  SupplierOrderId = 'supplierOrderId',
  /** column name */
  Version = 'version'
}

export type Supplier_Order_Returned_Containers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Supplier_Order_Returned_Containers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Supplier_Order_Returned_Containers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supplier_Order_Returned_Containers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Supplier_Order_Returned_Containers_Var_Pop_Fields = {
  __typename: 'supplier_order_returned_containers_var_pop_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  returnedQuantity?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_Var_Pop_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  returnedQuantity?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Supplier_Order_Returned_Containers_Var_Samp_Fields = {
  __typename: 'supplier_order_returned_containers_var_samp_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  returnedQuantity?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_Var_Samp_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  returnedQuantity?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Supplier_Order_Returned_Containers_Variance_Fields = {
  __typename: 'supplier_order_returned_containers_variance_fields';
  containerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  returnedQuantity?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "supplier_order_returned_containers" */
export type Supplier_Order_Returned_Containers_Variance_Order_By = {
  containerProductId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  returnedQuantity?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "supplier_order_returns" */
export type Supplier_Order_Returns = {
  __typename: 'supplier_order_returns';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  journalEntry?: Maybe<Journal_Entries>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  state: Supplier_Order_Return_States_Enum;
  /** An object relationship */
  supplierOrder: Supplier_Orders;
  supplierOrderId: Scalars['bigint'];
  /** An array relationship */
  supplierOrderReturnItems: Array<Supplier_Order_Return_Items>;
  /** An aggregate relationship */
  supplierOrderReturnItems_aggregate: Supplier_Order_Return_Items_Aggregate;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "supplier_order_returns" */
export type Supplier_Order_ReturnsSupplierOrderReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "supplier_order_returns" */
export type Supplier_Order_ReturnsSupplierOrderReturnItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};

/** aggregated selection of "supplier_order_returns" */
export type Supplier_Order_Returns_Aggregate = {
  __typename: 'supplier_order_returns_aggregate';
  aggregate?: Maybe<Supplier_Order_Returns_Aggregate_Fields>;
  nodes: Array<Supplier_Order_Returns>;
};

export type Supplier_Order_Returns_Aggregate_Bool_Exp = {
  count?: InputMaybe<Supplier_Order_Returns_Aggregate_Bool_Exp_Count>;
};

export type Supplier_Order_Returns_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Supplier_Order_Returns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "supplier_order_returns" */
export type Supplier_Order_Returns_Aggregate_Fields = {
  __typename: 'supplier_order_returns_aggregate_fields';
  avg?: Maybe<Supplier_Order_Returns_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supplier_Order_Returns_Max_Fields>;
  min?: Maybe<Supplier_Order_Returns_Min_Fields>;
  stddev?: Maybe<Supplier_Order_Returns_Stddev_Fields>;
  stddev_pop?: Maybe<Supplier_Order_Returns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supplier_Order_Returns_Stddev_Samp_Fields>;
  sum?: Maybe<Supplier_Order_Returns_Sum_Fields>;
  var_pop?: Maybe<Supplier_Order_Returns_Var_Pop_Fields>;
  var_samp?: Maybe<Supplier_Order_Returns_Var_Samp_Fields>;
  variance?: Maybe<Supplier_Order_Returns_Variance_Fields>;
};


/** aggregate fields of "supplier_order_returns" */
export type Supplier_Order_Returns_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supplier_Order_Returns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "supplier_order_returns" */
export type Supplier_Order_Returns_Aggregate_Order_By = {
  avg?: InputMaybe<Supplier_Order_Returns_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Supplier_Order_Returns_Max_Order_By>;
  min?: InputMaybe<Supplier_Order_Returns_Min_Order_By>;
  stddev?: InputMaybe<Supplier_Order_Returns_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Supplier_Order_Returns_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Supplier_Order_Returns_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Supplier_Order_Returns_Sum_Order_By>;
  var_pop?: InputMaybe<Supplier_Order_Returns_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Supplier_Order_Returns_Var_Samp_Order_By>;
  variance?: InputMaybe<Supplier_Order_Returns_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "supplier_order_returns" */
export type Supplier_Order_Returns_Arr_Rel_Insert_Input = {
  data: Array<Supplier_Order_Returns_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Supplier_Order_Returns_On_Conflict>;
};

/** aggregate avg on columns */
export type Supplier_Order_Returns_Avg_Fields = {
  __typename: 'supplier_order_returns_avg_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "supplier_order_returns" */
export type Supplier_Order_Returns_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "supplier_order_returns". All fields are combined with a logical 'AND'. */
export type Supplier_Order_Returns_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Supplier_Order_Returns_Bool_Exp>>;
  _not?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
  _or?: InputMaybe<Array<Supplier_Order_Returns_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  journalEntry?: InputMaybe<Journal_Entries_Bool_Exp>;
  journalEntryId?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<Supplier_Order_Return_States_Enum_Comparison_Exp>;
  supplierOrder?: InputMaybe<Supplier_Orders_Bool_Exp>;
  supplierOrderId?: InputMaybe<Bigint_Comparison_Exp>;
  supplierOrderReturnItems?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
  supplierOrderReturnItems_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "supplier_order_returns" */
export enum Supplier_Order_Returns_Constraint {
  /** unique or primary key constraint on columns "id" */
  SupplierOrderReturnsPkey = 'supplier_order_returns_pkey'
}

/** input type for incrementing numeric columns in table "supplier_order_returns" */
export type Supplier_Order_Returns_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  supplierOrderId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "supplier_order_returns" */
export type Supplier_Order_Returns_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  journalEntry?: InputMaybe<Journal_Entries_Obj_Rel_Insert_Input>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Supplier_Order_Return_States_Enum>;
  supplierOrder?: InputMaybe<Supplier_Orders_Obj_Rel_Insert_Input>;
  supplierOrderId?: InputMaybe<Scalars['bigint']>;
  supplierOrderReturnItems?: InputMaybe<Supplier_Order_Return_Items_Arr_Rel_Insert_Input>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Supplier_Order_Returns_Max_Fields = {
  __typename: 'supplier_order_returns_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  supplierOrderId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "supplier_order_returns" */
export type Supplier_Order_Returns_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Supplier_Order_Returns_Min_Fields = {
  __typename: 'supplier_order_returns_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  supplierOrderId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "supplier_order_returns" */
export type Supplier_Order_Returns_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "supplier_order_returns" */
export type Supplier_Order_Returns_Mutation_Response = {
  __typename: 'supplier_order_returns_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Supplier_Order_Returns>;
};

/** input type for inserting object relation for remote table "supplier_order_returns" */
export type Supplier_Order_Returns_Obj_Rel_Insert_Input = {
  data: Supplier_Order_Returns_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Supplier_Order_Returns_On_Conflict>;
};

/** on_conflict condition type for table "supplier_order_returns" */
export type Supplier_Order_Returns_On_Conflict = {
  constraint: Supplier_Order_Returns_Constraint;
  update_columns?: Array<Supplier_Order_Returns_Update_Column>;
  where?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
};

/** Ordering options when selecting data from "supplier_order_returns". */
export type Supplier_Order_Returns_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journalEntry?: InputMaybe<Journal_Entries_Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  supplierOrder?: InputMaybe<Supplier_Orders_Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  supplierOrderReturnItems_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: supplier_order_returns */
export type Supplier_Order_Returns_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "supplier_order_returns" */
export enum Supplier_Order_Returns_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  JournalEntryId = 'journalEntryId',
  /** column name */
  State = 'state',
  /** column name */
  SupplierOrderId = 'supplierOrderId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "supplier_order_returns" */
export type Supplier_Order_Returns_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Supplier_Order_Return_States_Enum>;
  supplierOrderId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Supplier_Order_Returns_Stddev_Fields = {
  __typename: 'supplier_order_returns_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "supplier_order_returns" */
export type Supplier_Order_Returns_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Supplier_Order_Returns_Stddev_Pop_Fields = {
  __typename: 'supplier_order_returns_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "supplier_order_returns" */
export type Supplier_Order_Returns_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Supplier_Order_Returns_Stddev_Samp_Fields = {
  __typename: 'supplier_order_returns_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "supplier_order_returns" */
export type Supplier_Order_Returns_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "supplier_order_returns" */
export type Supplier_Order_Returns_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supplier_Order_Returns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supplier_Order_Returns_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  journalEntryId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Supplier_Order_Return_States_Enum>;
  supplierOrderId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Supplier_Order_Returns_Sum_Fields = {
  __typename: 'supplier_order_returns_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  journalEntryId?: Maybe<Scalars['bigint']>;
  supplierOrderId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "supplier_order_returns" */
export type Supplier_Order_Returns_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "supplier_order_returns" */
export enum Supplier_Order_Returns_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  JournalEntryId = 'journalEntryId',
  /** column name */
  State = 'state',
  /** column name */
  SupplierOrderId = 'supplierOrderId',
  /** column name */
  Version = 'version'
}

export type Supplier_Order_Returns_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Supplier_Order_Returns_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Supplier_Order_Returns_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supplier_Order_Returns_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Supplier_Order_Returns_Var_Pop_Fields = {
  __typename: 'supplier_order_returns_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "supplier_order_returns" */
export type Supplier_Order_Returns_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Supplier_Order_Returns_Var_Samp_Fields = {
  __typename: 'supplier_order_returns_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "supplier_order_returns" */
export type Supplier_Order_Returns_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Supplier_Order_Returns_Variance_Fields = {
  __typename: 'supplier_order_returns_variance_fields';
  id?: Maybe<Scalars['Float']>;
  journalEntryId?: Maybe<Scalars['Float']>;
  supplierOrderId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "supplier_order_returns" */
export type Supplier_Order_Returns_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  journalEntryId?: InputMaybe<Order_By>;
  supplierOrderId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "supplier_order_state_transitions" */
export type Supplier_Order_State_Transitions = {
  __typename: 'supplier_order_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Supplier_Order_States_Enum;
  toState: Supplier_Order_States_Enum;
};

/** aggregated selection of "supplier_order_state_transitions" */
export type Supplier_Order_State_Transitions_Aggregate = {
  __typename: 'supplier_order_state_transitions_aggregate';
  aggregate?: Maybe<Supplier_Order_State_Transitions_Aggregate_Fields>;
  nodes: Array<Supplier_Order_State_Transitions>;
};

/** aggregate fields of "supplier_order_state_transitions" */
export type Supplier_Order_State_Transitions_Aggregate_Fields = {
  __typename: 'supplier_order_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Supplier_Order_State_Transitions_Max_Fields>;
  min?: Maybe<Supplier_Order_State_Transitions_Min_Fields>;
};


/** aggregate fields of "supplier_order_state_transitions" */
export type Supplier_Order_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supplier_Order_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "supplier_order_state_transitions". All fields are combined with a logical 'AND'. */
export type Supplier_Order_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Supplier_Order_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Supplier_Order_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Supplier_Order_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Supplier_Order_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Supplier_Order_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Supplier_Order_State_Transitions_Max_Fields = {
  __typename: 'supplier_order_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Supplier_Order_State_Transitions_Min_Fields = {
  __typename: 'supplier_order_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "supplier_order_state_transitions". */
export type Supplier_Order_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "supplier_order_state_transitions" */
export enum Supplier_Order_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "supplier_order_state_transitions" */
export type Supplier_Order_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supplier_Order_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supplier_Order_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Supplier_Order_States_Enum>;
  toState?: InputMaybe<Supplier_Order_States_Enum>;
};

/** columns and relationships of "supplier_order_states" */
export type Supplier_Order_States = {
  __typename: 'supplier_order_states';
  /** An array relationship */
  supplierOrders: Array<Supplier_Orders>;
  /** An aggregate relationship */
  supplierOrders_aggregate: Supplier_Orders_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "supplier_order_states" */
export type Supplier_Order_StatesSupplierOrdersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


/** columns and relationships of "supplier_order_states" */
export type Supplier_Order_StatesSupplierOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};

/** aggregated selection of "supplier_order_states" */
export type Supplier_Order_States_Aggregate = {
  __typename: 'supplier_order_states_aggregate';
  aggregate?: Maybe<Supplier_Order_States_Aggregate_Fields>;
  nodes: Array<Supplier_Order_States>;
};

/** aggregate fields of "supplier_order_states" */
export type Supplier_Order_States_Aggregate_Fields = {
  __typename: 'supplier_order_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Supplier_Order_States_Max_Fields>;
  min?: Maybe<Supplier_Order_States_Min_Fields>;
};


/** aggregate fields of "supplier_order_states" */
export type Supplier_Order_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supplier_Order_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "supplier_order_states". All fields are combined with a logical 'AND'. */
export type Supplier_Order_States_Bool_Exp = {
  _and?: InputMaybe<Array<Supplier_Order_States_Bool_Exp>>;
  _not?: InputMaybe<Supplier_Order_States_Bool_Exp>;
  _or?: InputMaybe<Array<Supplier_Order_States_Bool_Exp>>;
  supplierOrders?: InputMaybe<Supplier_Orders_Bool_Exp>;
  supplierOrders_aggregate?: InputMaybe<Supplier_Orders_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Supplier_Order_States_Enum {
  ApprovedReceipt = 'APPROVED_RECEIPT',
  Canceled = 'CANCELED',
  Open = 'OPEN',
  Ordered = 'ORDERED',
  OrderRequested = 'ORDER_REQUESTED',
  PartialReceipt = 'PARTIAL_RECEIPT',
  Receipt = 'RECEIPT'
}

/** Boolean expression to compare columns of type "supplier_order_states_enum". All fields are combined with logical 'AND'. */
export type Supplier_Order_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Supplier_Order_States_Enum>;
  _in?: InputMaybe<Array<Supplier_Order_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Supplier_Order_States_Enum>;
  _nin?: InputMaybe<Array<Supplier_Order_States_Enum>>;
};

/** aggregate max on columns */
export type Supplier_Order_States_Max_Fields = {
  __typename: 'supplier_order_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Supplier_Order_States_Min_Fields = {
  __typename: 'supplier_order_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "supplier_order_states". */
export type Supplier_Order_States_Order_By = {
  supplierOrders_aggregate?: InputMaybe<Supplier_Orders_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "supplier_order_states" */
export enum Supplier_Order_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "supplier_order_states" */
export type Supplier_Order_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supplier_Order_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supplier_Order_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "supplier_orders" */
export type Supplier_Orders = {
  __typename: 'supplier_orders';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  aggregatedDemandPlanIncomingGoodsResults: Array<Aggregated_Demand_Plan_Incoming_Goods_Results>;
  /** An aggregate relationship */
  aggregatedDemandPlanIncomingGoodsResults_aggregate: Aggregated_Demand_Plan_Incoming_Goods_Results_Aggregate;
  createdAt: Scalars['timestamptz'];
  currency: Currencies_Enum;
  deliveryDate?: Maybe<Scalars['date']>;
  domainId: Scalars['String'];
  emailRecipients?: Maybe<Scalars['jsonb']>;
  id: Scalars['bigint'];
  /** Returns whether the supplier order is a framework agreement or not. */
  isFrameworkAgreement?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  loggedActions: Array<Supplier_Order_Logged_Actions>;
  /** An aggregate relationship */
  loggedActions_aggregate: Supplier_Order_Logged_Actions_Aggregate;
  /** Returns the next order delivery date */
  nextDeliveryDate?: Maybe<Scalars['date']>;
  notes?: Maybe<Scalars['String']>;
  notesSupplier?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  orderedByUser?: Maybe<Users>;
  orderedByUserId?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  parentSupplierOrder?: Maybe<Supplier_Orders>;
  parentSupplierOrderId?: Maybe<Scalars['bigint']>;
  state: Supplier_Order_States_Enum;
  /** An object relationship */
  supplier?: Maybe<Suppliers>;
  supplierId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  supplierOrderItems: Array<Supplier_Order_Items>;
  /** An aggregate relationship */
  supplierOrderItems_aggregate: Supplier_Order_Items_Aggregate;
  /** An array relationship */
  supplierOrderReturnedContainers: Array<Supplier_Order_Returned_Containers>;
  /** An aggregate relationship */
  supplierOrderReturnedContainers_aggregate: Supplier_Order_Returned_Containers_Aggregate;
  /** An array relationship */
  supplierOrderReturns: Array<Supplier_Order_Returns>;
  /** An aggregate relationship */
  supplierOrderReturns_aggregate: Supplier_Order_Returns_Aggregate;
  /** An array relationship */
  supplierOrders: Array<Supplier_Orders>;
  /** An aggregate relationship */
  supplierOrders_aggregate: Supplier_Orders_Aggregate;
  tenant: Scalars['String'];
  /** Returns supplier order total. */
  totalAmount?: Maybe<Scalars['numeric']>;
  /** Returns supplier order total with returns. */
  totalAmountWithReturns?: Maybe<Scalars['numeric']>;
  totalPurchasingPrice?: Maybe<Scalars['bigint']>;
  updatedAt: Scalars['timestamptz'];
  valid?: Maybe<Scalars['daterange']>;
  version: Scalars['bigint'];
};


/** columns and relationships of "supplier_orders" */
export type Supplier_OrdersAggregatedDemandPlanIncomingGoodsResultsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp>;
};


/** columns and relationships of "supplier_orders" */
export type Supplier_OrdersAggregatedDemandPlanIncomingGoodsResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp>;
};


/** columns and relationships of "supplier_orders" */
export type Supplier_OrdersEmailRecipientsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "supplier_orders" */
export type Supplier_OrdersLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Logged_Actions_Order_By>>;
  where?: InputMaybe<Supplier_Order_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "supplier_orders" */
export type Supplier_OrdersLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Logged_Actions_Order_By>>;
  where?: InputMaybe<Supplier_Order_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "supplier_orders" */
export type Supplier_OrdersSupplierOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** columns and relationships of "supplier_orders" */
export type Supplier_OrdersSupplierOrderItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** columns and relationships of "supplier_orders" */
export type Supplier_OrdersSupplierOrderReturnedContainersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returned_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returned_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returned_Containers_Bool_Exp>;
};


/** columns and relationships of "supplier_orders" */
export type Supplier_OrdersSupplierOrderReturnedContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returned_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returned_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returned_Containers_Bool_Exp>;
};


/** columns and relationships of "supplier_orders" */
export type Supplier_OrdersSupplierOrderReturnsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returns_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
};


/** columns and relationships of "supplier_orders" */
export type Supplier_OrdersSupplierOrderReturns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returns_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
};


/** columns and relationships of "supplier_orders" */
export type Supplier_OrdersSupplierOrdersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


/** columns and relationships of "supplier_orders" */
export type Supplier_OrdersSupplierOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};

/** aggregated selection of "supplier_orders" */
export type Supplier_Orders_Aggregate = {
  __typename: 'supplier_orders_aggregate';
  aggregate?: Maybe<Supplier_Orders_Aggregate_Fields>;
  nodes: Array<Supplier_Orders>;
};

export type Supplier_Orders_Aggregate_Bool_Exp = {
  count?: InputMaybe<Supplier_Orders_Aggregate_Bool_Exp_Count>;
};

export type Supplier_Orders_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Supplier_Orders_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "supplier_orders" */
export type Supplier_Orders_Aggregate_Fields = {
  __typename: 'supplier_orders_aggregate_fields';
  avg?: Maybe<Supplier_Orders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supplier_Orders_Max_Fields>;
  min?: Maybe<Supplier_Orders_Min_Fields>;
  stddev?: Maybe<Supplier_Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Supplier_Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supplier_Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Supplier_Orders_Sum_Fields>;
  var_pop?: Maybe<Supplier_Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Supplier_Orders_Var_Samp_Fields>;
  variance?: Maybe<Supplier_Orders_Variance_Fields>;
};


/** aggregate fields of "supplier_orders" */
export type Supplier_Orders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "supplier_orders" */
export type Supplier_Orders_Aggregate_Order_By = {
  avg?: InputMaybe<Supplier_Orders_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Supplier_Orders_Max_Order_By>;
  min?: InputMaybe<Supplier_Orders_Min_Order_By>;
  stddev?: InputMaybe<Supplier_Orders_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Supplier_Orders_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Supplier_Orders_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Supplier_Orders_Sum_Order_By>;
  var_pop?: InputMaybe<Supplier_Orders_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Supplier_Orders_Var_Samp_Order_By>;
  variance?: InputMaybe<Supplier_Orders_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Supplier_Orders_Append_Input = {
  emailRecipients?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "supplier_orders" */
export type Supplier_Orders_Arr_Rel_Insert_Input = {
  data: Array<Supplier_Orders_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Supplier_Orders_On_Conflict>;
};

/** aggregate avg on columns */
export type Supplier_Orders_Avg_Fields = {
  __typename: 'supplier_orders_avg_fields';
  id?: Maybe<Scalars['Float']>;
  orderedByUserId?: Maybe<Scalars['Float']>;
  parentSupplierOrderId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  totalPurchasingPrice?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "supplier_orders" */
export type Supplier_Orders_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  orderedByUserId?: InputMaybe<Order_By>;
  parentSupplierOrderId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  totalPurchasingPrice?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "supplier_orders". All fields are combined with a logical 'AND'. */
export type Supplier_Orders_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Supplier_Orders_Bool_Exp>>;
  _not?: InputMaybe<Supplier_Orders_Bool_Exp>;
  _or?: InputMaybe<Array<Supplier_Orders_Bool_Exp>>;
  aggregatedDemandPlanIncomingGoodsResults?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp>;
  aggregatedDemandPlanIncomingGoodsResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  deliveryDate?: InputMaybe<Date_Comparison_Exp>;
  domainId?: InputMaybe<String_Comparison_Exp>;
  emailRecipients?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  isFrameworkAgreement?: InputMaybe<Boolean_Comparison_Exp>;
  loggedActions?: InputMaybe<Supplier_Order_Logged_Actions_Bool_Exp>;
  loggedActions_aggregate?: InputMaybe<Supplier_Order_Logged_Actions_Aggregate_Bool_Exp>;
  nextDeliveryDate?: InputMaybe<Date_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  notesSupplier?: InputMaybe<String_Comparison_Exp>;
  orderDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  orderedByUser?: InputMaybe<Users_Bool_Exp>;
  orderedByUserId?: InputMaybe<Bigint_Comparison_Exp>;
  parentSupplierOrder?: InputMaybe<Supplier_Orders_Bool_Exp>;
  parentSupplierOrderId?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<Supplier_Order_States_Enum_Comparison_Exp>;
  supplier?: InputMaybe<Suppliers_Bool_Exp>;
  supplierId?: InputMaybe<Bigint_Comparison_Exp>;
  supplierOrderItems?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
  supplierOrderItems_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Bool_Exp>;
  supplierOrderReturnedContainers?: InputMaybe<Supplier_Order_Returned_Containers_Bool_Exp>;
  supplierOrderReturnedContainers_aggregate?: InputMaybe<Supplier_Order_Returned_Containers_Aggregate_Bool_Exp>;
  supplierOrderReturns?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
  supplierOrderReturns_aggregate?: InputMaybe<Supplier_Order_Returns_Aggregate_Bool_Exp>;
  supplierOrders?: InputMaybe<Supplier_Orders_Bool_Exp>;
  supplierOrders_aggregate?: InputMaybe<Supplier_Orders_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  totalAmount?: InputMaybe<Numeric_Comparison_Exp>;
  totalAmountWithReturns?: InputMaybe<Numeric_Comparison_Exp>;
  totalPurchasingPrice?: InputMaybe<Bigint_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid?: InputMaybe<Daterange_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "supplier_orders" */
export enum Supplier_Orders_Constraint {
  /** unique or primary key constraint on columns "id" */
  SupplierOrdersPkey = 'supplier_orders_pkey',
  /** unique or primary key constraint on columns "tenant", "domain_id" */
  SupplierOrdersTenantDomainId = 'supplier_orders_tenant_domain_id'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Supplier_Orders_Delete_At_Path_Input = {
  emailRecipients?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Supplier_Orders_Delete_Elem_Input = {
  emailRecipients?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Supplier_Orders_Delete_Key_Input = {
  emailRecipients?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "supplier_orders" */
export type Supplier_Orders_Inc_Input = {
  supplierId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "supplier_orders" */
export type Supplier_Orders_Insert_Input = {
  currency?: InputMaybe<Currencies_Enum>;
  deliveryDate?: InputMaybe<Scalars['date']>;
  domainId?: InputMaybe<Scalars['String']>;
  emailRecipients?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  notesSupplier?: InputMaybe<Scalars['String']>;
  orderDate?: InputMaybe<Scalars['timestamptz']>;
  orderedByUser?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  orderedByUserId?: InputMaybe<Scalars['bigint']>;
  parentSupplierOrder?: InputMaybe<Supplier_Orders_Obj_Rel_Insert_Input>;
  parentSupplierOrderId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Supplier_Order_States_Enum>;
  supplier?: InputMaybe<Suppliers_Obj_Rel_Insert_Input>;
  supplierId?: InputMaybe<Scalars['bigint']>;
  supplierOrderItems?: InputMaybe<Supplier_Order_Items_Arr_Rel_Insert_Input>;
  supplierOrderReturnedContainers?: InputMaybe<Supplier_Order_Returned_Containers_Arr_Rel_Insert_Input>;
  supplierOrderReturns?: InputMaybe<Supplier_Order_Returns_Arr_Rel_Insert_Input>;
  supplierOrders?: InputMaybe<Supplier_Orders_Arr_Rel_Insert_Input>;
  totalPurchasingPrice?: InputMaybe<Scalars['bigint']>;
  valid?: InputMaybe<Scalars['daterange']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Supplier_Orders_Max_Fields = {
  __typename: 'supplier_orders_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deliveryDate?: Maybe<Scalars['date']>;
  domainId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  notesSupplier?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['timestamptz']>;
  orderedByUserId?: Maybe<Scalars['bigint']>;
  parentSupplierOrderId?: Maybe<Scalars['bigint']>;
  supplierId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  totalPurchasingPrice?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "supplier_orders" */
export type Supplier_Orders_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deliveryDate?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  notesSupplier?: InputMaybe<Order_By>;
  orderDate?: InputMaybe<Order_By>;
  orderedByUserId?: InputMaybe<Order_By>;
  parentSupplierOrderId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  totalPurchasingPrice?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Supplier_Orders_Min_Fields = {
  __typename: 'supplier_orders_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deliveryDate?: Maybe<Scalars['date']>;
  domainId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  notesSupplier?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['timestamptz']>;
  orderedByUserId?: Maybe<Scalars['bigint']>;
  parentSupplierOrderId?: Maybe<Scalars['bigint']>;
  supplierId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  totalPurchasingPrice?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "supplier_orders" */
export type Supplier_Orders_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deliveryDate?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  notesSupplier?: InputMaybe<Order_By>;
  orderDate?: InputMaybe<Order_By>;
  orderedByUserId?: InputMaybe<Order_By>;
  parentSupplierOrderId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  totalPurchasingPrice?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "supplier_orders" */
export type Supplier_Orders_Mutation_Response = {
  __typename: 'supplier_orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Supplier_Orders>;
};

/** input type for inserting object relation for remote table "supplier_orders" */
export type Supplier_Orders_Obj_Rel_Insert_Input = {
  data: Supplier_Orders_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Supplier_Orders_On_Conflict>;
};

/** on_conflict condition type for table "supplier_orders" */
export type Supplier_Orders_On_Conflict = {
  constraint: Supplier_Orders_Constraint;
  update_columns?: Array<Supplier_Orders_Update_Column>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "supplier_orders". */
export type Supplier_Orders_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  aggregatedDemandPlanIncomingGoodsResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  deliveryDate?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  emailRecipients?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isFrameworkAgreement?: InputMaybe<Order_By>;
  loggedActions_aggregate?: InputMaybe<Supplier_Order_Logged_Actions_Aggregate_Order_By>;
  nextDeliveryDate?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  notesSupplier?: InputMaybe<Order_By>;
  orderDate?: InputMaybe<Order_By>;
  orderedByUser?: InputMaybe<Users_Order_By>;
  orderedByUserId?: InputMaybe<Order_By>;
  parentSupplierOrder?: InputMaybe<Supplier_Orders_Order_By>;
  parentSupplierOrderId?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  supplier?: InputMaybe<Suppliers_Order_By>;
  supplierId?: InputMaybe<Order_By>;
  supplierOrderItems_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Order_By>;
  supplierOrderReturnedContainers_aggregate?: InputMaybe<Supplier_Order_Returned_Containers_Aggregate_Order_By>;
  supplierOrderReturns_aggregate?: InputMaybe<Supplier_Order_Returns_Aggregate_Order_By>;
  supplierOrders_aggregate?: InputMaybe<Supplier_Orders_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
  totalAmount?: InputMaybe<Order_By>;
  totalAmountWithReturns?: InputMaybe<Order_By>;
  totalPurchasingPrice?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  valid?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: supplier_orders */
export type Supplier_Orders_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Supplier_Orders_Prepend_Input = {
  emailRecipients?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "supplier_orders" */
export enum Supplier_Orders_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  DeliveryDate = 'deliveryDate',
  /** column name */
  DomainId = 'domainId',
  /** column name */
  EmailRecipients = 'emailRecipients',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  NotesSupplier = 'notesSupplier',
  /** column name */
  OrderDate = 'orderDate',
  /** column name */
  OrderedByUserId = 'orderedByUserId',
  /** column name */
  ParentSupplierOrderId = 'parentSupplierOrderId',
  /** column name */
  State = 'state',
  /** column name */
  SupplierId = 'supplierId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  TotalPurchasingPrice = 'totalPurchasingPrice',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Valid = 'valid',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "supplier_orders" */
export type Supplier_Orders_Set_Input = {
  deliveryDate?: InputMaybe<Scalars['date']>;
  emailRecipients?: InputMaybe<Scalars['jsonb']>;
  notes?: InputMaybe<Scalars['String']>;
  notesSupplier?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Supplier_Order_States_Enum>;
  supplierId?: InputMaybe<Scalars['bigint']>;
  valid?: InputMaybe<Scalars['daterange']>;
};

/** aggregate stddev on columns */
export type Supplier_Orders_Stddev_Fields = {
  __typename: 'supplier_orders_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  orderedByUserId?: Maybe<Scalars['Float']>;
  parentSupplierOrderId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  totalPurchasingPrice?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "supplier_orders" */
export type Supplier_Orders_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  orderedByUserId?: InputMaybe<Order_By>;
  parentSupplierOrderId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  totalPurchasingPrice?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Supplier_Orders_Stddev_Pop_Fields = {
  __typename: 'supplier_orders_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  orderedByUserId?: Maybe<Scalars['Float']>;
  parentSupplierOrderId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  totalPurchasingPrice?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "supplier_orders" */
export type Supplier_Orders_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  orderedByUserId?: InputMaybe<Order_By>;
  parentSupplierOrderId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  totalPurchasingPrice?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Supplier_Orders_Stddev_Samp_Fields = {
  __typename: 'supplier_orders_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  orderedByUserId?: Maybe<Scalars['Float']>;
  parentSupplierOrderId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  totalPurchasingPrice?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "supplier_orders" */
export type Supplier_Orders_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  orderedByUserId?: InputMaybe<Order_By>;
  parentSupplierOrderId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  totalPurchasingPrice?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "supplier_orders" */
export type Supplier_Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supplier_Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supplier_Orders_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Currencies_Enum>;
  deliveryDate?: InputMaybe<Scalars['date']>;
  domainId?: InputMaybe<Scalars['String']>;
  emailRecipients?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  notesSupplier?: InputMaybe<Scalars['String']>;
  orderDate?: InputMaybe<Scalars['timestamptz']>;
  orderedByUserId?: InputMaybe<Scalars['bigint']>;
  parentSupplierOrderId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Supplier_Order_States_Enum>;
  supplierId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  totalPurchasingPrice?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  valid?: InputMaybe<Scalars['daterange']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Supplier_Orders_Sum_Fields = {
  __typename: 'supplier_orders_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  orderedByUserId?: Maybe<Scalars['bigint']>;
  parentSupplierOrderId?: Maybe<Scalars['bigint']>;
  supplierId?: Maybe<Scalars['bigint']>;
  totalPurchasingPrice?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "supplier_orders" */
export type Supplier_Orders_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  orderedByUserId?: InputMaybe<Order_By>;
  parentSupplierOrderId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  totalPurchasingPrice?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "supplier_orders" */
export enum Supplier_Orders_Update_Column {
  /** column name */
  DeliveryDate = 'deliveryDate',
  /** column name */
  EmailRecipients = 'emailRecipients',
  /** column name */
  Notes = 'notes',
  /** column name */
  NotesSupplier = 'notesSupplier',
  /** column name */
  State = 'state',
  /** column name */
  SupplierId = 'supplierId',
  /** column name */
  Valid = 'valid'
}

export type Supplier_Orders_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Supplier_Orders_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Supplier_Orders_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Supplier_Orders_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Supplier_Orders_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Supplier_Orders_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Supplier_Orders_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Supplier_Orders_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supplier_Orders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Supplier_Orders_Var_Pop_Fields = {
  __typename: 'supplier_orders_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  orderedByUserId?: Maybe<Scalars['Float']>;
  parentSupplierOrderId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  totalPurchasingPrice?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "supplier_orders" */
export type Supplier_Orders_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  orderedByUserId?: InputMaybe<Order_By>;
  parentSupplierOrderId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  totalPurchasingPrice?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Supplier_Orders_Var_Samp_Fields = {
  __typename: 'supplier_orders_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  orderedByUserId?: Maybe<Scalars['Float']>;
  parentSupplierOrderId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  totalPurchasingPrice?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "supplier_orders" */
export type Supplier_Orders_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  orderedByUserId?: InputMaybe<Order_By>;
  parentSupplierOrderId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  totalPurchasingPrice?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Supplier_Orders_Variance_Fields = {
  __typename: 'supplier_orders_variance_fields';
  id?: Maybe<Scalars['Float']>;
  orderedByUserId?: Maybe<Scalars['Float']>;
  parentSupplierOrderId?: Maybe<Scalars['Float']>;
  supplierId?: Maybe<Scalars['Float']>;
  totalPurchasingPrice?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "supplier_orders" */
export type Supplier_Orders_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  orderedByUserId?: InputMaybe<Order_By>;
  parentSupplierOrderId?: InputMaybe<Order_By>;
  supplierId?: InputMaybe<Order_By>;
  totalPurchasingPrice?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "supplier_state_transitions" */
export type Supplier_State_Transitions = {
  __typename: 'supplier_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Supplier_States_Enum;
  toState: Supplier_States_Enum;
};

/** aggregated selection of "supplier_state_transitions" */
export type Supplier_State_Transitions_Aggregate = {
  __typename: 'supplier_state_transitions_aggregate';
  aggregate?: Maybe<Supplier_State_Transitions_Aggregate_Fields>;
  nodes: Array<Supplier_State_Transitions>;
};

/** aggregate fields of "supplier_state_transitions" */
export type Supplier_State_Transitions_Aggregate_Fields = {
  __typename: 'supplier_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Supplier_State_Transitions_Max_Fields>;
  min?: Maybe<Supplier_State_Transitions_Min_Fields>;
};


/** aggregate fields of "supplier_state_transitions" */
export type Supplier_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supplier_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "supplier_state_transitions". All fields are combined with a logical 'AND'. */
export type Supplier_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Supplier_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Supplier_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Supplier_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Supplier_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Supplier_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Supplier_State_Transitions_Max_Fields = {
  __typename: 'supplier_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Supplier_State_Transitions_Min_Fields = {
  __typename: 'supplier_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "supplier_state_transitions". */
export type Supplier_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "supplier_state_transitions" */
export enum Supplier_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "supplier_state_transitions" */
export type Supplier_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supplier_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supplier_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Supplier_States_Enum>;
  toState?: InputMaybe<Supplier_States_Enum>;
};

/** columns and relationships of "supplier_states" */
export type Supplier_States = {
  __typename: 'supplier_states';
  /** An array relationship */
  suppliers: Array<Suppliers>;
  /** An aggregate relationship */
  suppliers_aggregate: Suppliers_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "supplier_states" */
export type Supplier_StatesSuppliersArgs = {
  distinct_on?: InputMaybe<Array<Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Suppliers_Order_By>>;
  where?: InputMaybe<Suppliers_Bool_Exp>;
};


/** columns and relationships of "supplier_states" */
export type Supplier_StatesSuppliers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Suppliers_Order_By>>;
  where?: InputMaybe<Suppliers_Bool_Exp>;
};

/** aggregated selection of "supplier_states" */
export type Supplier_States_Aggregate = {
  __typename: 'supplier_states_aggregate';
  aggregate?: Maybe<Supplier_States_Aggregate_Fields>;
  nodes: Array<Supplier_States>;
};

/** aggregate fields of "supplier_states" */
export type Supplier_States_Aggregate_Fields = {
  __typename: 'supplier_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Supplier_States_Max_Fields>;
  min?: Maybe<Supplier_States_Min_Fields>;
};


/** aggregate fields of "supplier_states" */
export type Supplier_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supplier_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "supplier_states". All fields are combined with a logical 'AND'. */
export type Supplier_States_Bool_Exp = {
  _and?: InputMaybe<Array<Supplier_States_Bool_Exp>>;
  _not?: InputMaybe<Supplier_States_Bool_Exp>;
  _or?: InputMaybe<Array<Supplier_States_Bool_Exp>>;
  suppliers?: InputMaybe<Suppliers_Bool_Exp>;
  suppliers_aggregate?: InputMaybe<Suppliers_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Supplier_States_Enum {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE'
}

/** Boolean expression to compare columns of type "supplier_states_enum". All fields are combined with logical 'AND'. */
export type Supplier_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Supplier_States_Enum>;
  _in?: InputMaybe<Array<Supplier_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Supplier_States_Enum>;
  _nin?: InputMaybe<Array<Supplier_States_Enum>>;
};

/** aggregate max on columns */
export type Supplier_States_Max_Fields = {
  __typename: 'supplier_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Supplier_States_Min_Fields = {
  __typename: 'supplier_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "supplier_states". */
export type Supplier_States_Order_By = {
  suppliers_aggregate?: InputMaybe<Suppliers_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "supplier_states" */
export enum Supplier_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "supplier_states" */
export type Supplier_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supplier_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supplier_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "suppliers" */
export type Suppliers = {
  __typename: 'suppliers';
  /** An object relationship */
  Tenant: Tenants;
  /** An array relationship */
  accounts: Array<Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Accounts_Aggregate;
  /** An array relationship */
  aggregatedDemandPlanIncomingGoodsResults: Array<Aggregated_Demand_Plan_Incoming_Goods_Results>;
  /** An aggregate relationship */
  aggregatedDemandPlanIncomingGoodsResults_aggregate: Aggregated_Demand_Plan_Incoming_Goods_Results_Aggregate;
  /** An array relationship */
  aggregatedDemandPlanResults: Array<Aggregated_Demand_Plan_Results>;
  /** An aggregate relationship */
  aggregatedDemandPlanResults_aggregate: Aggregated_Demand_Plan_Results_Aggregate;
  allowedTenants: Scalars['jsonb'];
  color?: Maybe<Scalars['String']>;
  communicationLanguage: Languages_Enum;
  /** An array relationship */
  contacts: Array<Contacts>;
  /** An aggregate relationship */
  contacts_aggregate: Contacts_Aggregate;
  createdAt: Scalars['timestamptz'];
  currency: Currencies_Enum;
  description?: Maybe<Scalars['String']>;
  domainId: Scalars['String'];
  /** An array relationship */
  globalSearchResults: Array<Global_Search_Result>;
  /** An aggregate relationship */
  globalSearchResults_aggregate: Global_Search_Result_Aggregate;
  id: Scalars['bigint'];
  /** An object relationship */
  mainAddress?: Maybe<Supplier_Addresses>;
  mainAddressId?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  /** An array relationship */
  productSuppliers: Array<Product_Suppliers>;
  /** An aggregate relationship */
  productSuppliers_aggregate: Product_Suppliers_Aggregate;
  state: Supplier_States_Enum;
  /** An array relationship */
  supplierOrders: Array<Supplier_Orders>;
  /** An aggregate relationship */
  supplierOrders_aggregate: Supplier_Orders_Aggregate;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "suppliers" */
export type SuppliersAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


/** columns and relationships of "suppliers" */
export type SuppliersAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


/** columns and relationships of "suppliers" */
export type SuppliersAggregatedDemandPlanIncomingGoodsResultsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp>;
};


/** columns and relationships of "suppliers" */
export type SuppliersAggregatedDemandPlanIncomingGoodsResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Incoming_Goods_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp>;
};


/** columns and relationships of "suppliers" */
export type SuppliersAggregatedDemandPlanResultsArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};


/** columns and relationships of "suppliers" */
export type SuppliersAggregatedDemandPlanResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregated_Demand_Plan_Results_Order_By>>;
  where?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
};


/** columns and relationships of "suppliers" */
export type SuppliersAllowedTenantsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "suppliers" */
export type SuppliersContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


/** columns and relationships of "suppliers" */
export type SuppliersContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


/** columns and relationships of "suppliers" */
export type SuppliersGlobalSearchResultsArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


/** columns and relationships of "suppliers" */
export type SuppliersGlobalSearchResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Search_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Search_Result_Order_By>>;
  where?: InputMaybe<Global_Search_Result_Bool_Exp>;
};


/** columns and relationships of "suppliers" */
export type SuppliersProductSuppliersArgs = {
  distinct_on?: InputMaybe<Array<Product_Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Suppliers_Order_By>>;
  where?: InputMaybe<Product_Suppliers_Bool_Exp>;
};


/** columns and relationships of "suppliers" */
export type SuppliersProductSuppliers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Suppliers_Order_By>>;
  where?: InputMaybe<Product_Suppliers_Bool_Exp>;
};


/** columns and relationships of "suppliers" */
export type SuppliersSupplierOrdersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


/** columns and relationships of "suppliers" */
export type SuppliersSupplierOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};

/** aggregated selection of "suppliers" */
export type Suppliers_Aggregate = {
  __typename: 'suppliers_aggregate';
  aggregate?: Maybe<Suppliers_Aggregate_Fields>;
  nodes: Array<Suppliers>;
};

export type Suppliers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Suppliers_Aggregate_Bool_Exp_Count>;
};

export type Suppliers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Suppliers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Suppliers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "suppliers" */
export type Suppliers_Aggregate_Fields = {
  __typename: 'suppliers_aggregate_fields';
  avg?: Maybe<Suppliers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Suppliers_Max_Fields>;
  min?: Maybe<Suppliers_Min_Fields>;
  stddev?: Maybe<Suppliers_Stddev_Fields>;
  stddev_pop?: Maybe<Suppliers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Suppliers_Stddev_Samp_Fields>;
  sum?: Maybe<Suppliers_Sum_Fields>;
  var_pop?: Maybe<Suppliers_Var_Pop_Fields>;
  var_samp?: Maybe<Suppliers_Var_Samp_Fields>;
  variance?: Maybe<Suppliers_Variance_Fields>;
};


/** aggregate fields of "suppliers" */
export type Suppliers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Suppliers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "suppliers" */
export type Suppliers_Aggregate_Order_By = {
  avg?: InputMaybe<Suppliers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Suppliers_Max_Order_By>;
  min?: InputMaybe<Suppliers_Min_Order_By>;
  stddev?: InputMaybe<Suppliers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Suppliers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Suppliers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Suppliers_Sum_Order_By>;
  var_pop?: InputMaybe<Suppliers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Suppliers_Var_Samp_Order_By>;
  variance?: InputMaybe<Suppliers_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Suppliers_Append_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "suppliers" */
export type Suppliers_Arr_Rel_Insert_Input = {
  data: Array<Suppliers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Suppliers_On_Conflict>;
};

/** aggregate avg on columns */
export type Suppliers_Avg_Fields = {
  __typename: 'suppliers_avg_fields';
  id?: Maybe<Scalars['Float']>;
  mainAddressId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "suppliers" */
export type Suppliers_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  mainAddressId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "suppliers". All fields are combined with a logical 'AND'. */
export type Suppliers_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Suppliers_Bool_Exp>>;
  _not?: InputMaybe<Suppliers_Bool_Exp>;
  _or?: InputMaybe<Array<Suppliers_Bool_Exp>>;
  accounts?: InputMaybe<Accounts_Bool_Exp>;
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Bool_Exp>;
  aggregatedDemandPlanIncomingGoodsResults?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Bool_Exp>;
  aggregatedDemandPlanIncomingGoodsResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Aggregate_Bool_Exp>;
  aggregatedDemandPlanResults?: InputMaybe<Aggregated_Demand_Plan_Results_Bool_Exp>;
  aggregatedDemandPlanResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Results_Aggregate_Bool_Exp>;
  allowedTenants?: InputMaybe<Jsonb_Comparison_Exp>;
  color?: InputMaybe<String_Comparison_Exp>;
  communicationLanguage?: InputMaybe<Languages_Enum_Comparison_Exp>;
  contacts?: InputMaybe<Contacts_Bool_Exp>;
  contacts_aggregate?: InputMaybe<Contacts_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  domainId?: InputMaybe<String_Comparison_Exp>;
  globalSearchResults?: InputMaybe<Global_Search_Result_Bool_Exp>;
  globalSearchResults_aggregate?: InputMaybe<Global_Search_Result_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  mainAddress?: InputMaybe<Supplier_Addresses_Bool_Exp>;
  mainAddressId?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  productSuppliers?: InputMaybe<Product_Suppliers_Bool_Exp>;
  productSuppliers_aggregate?: InputMaybe<Product_Suppliers_Aggregate_Bool_Exp>;
  state?: InputMaybe<Supplier_States_Enum_Comparison_Exp>;
  supplierOrders?: InputMaybe<Supplier_Orders_Bool_Exp>;
  supplierOrders_aggregate?: InputMaybe<Supplier_Orders_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "suppliers" */
export enum Suppliers_Constraint {
  /** unique or primary key constraint on columns "id" */
  SuppliersPkey = 'suppliers_pkey',
  /** unique or primary key constraint on columns "tenant", "domain_id" */
  SuppliersTenantDomainId = 'suppliers_tenant_domain_id'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Suppliers_Delete_At_Path_Input = {
  allowedTenants?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Suppliers_Delete_Elem_Input = {
  allowedTenants?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Suppliers_Delete_Key_Input = {
  allowedTenants?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "suppliers" */
export type Suppliers_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  mainAddressId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "suppliers" */
export type Suppliers_Insert_Input = {
  accounts?: InputMaybe<Accounts_Arr_Rel_Insert_Input>;
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
  color?: InputMaybe<Scalars['String']>;
  communicationLanguage?: InputMaybe<Languages_Enum>;
  contacts?: InputMaybe<Contacts_Arr_Rel_Insert_Input>;
  currency?: InputMaybe<Currencies_Enum>;
  description?: InputMaybe<Scalars['String']>;
  domainId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  mainAddress?: InputMaybe<Supplier_Addresses_Obj_Rel_Insert_Input>;
  mainAddressId?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  productSuppliers?: InputMaybe<Product_Suppliers_Arr_Rel_Insert_Input>;
  state?: InputMaybe<Supplier_States_Enum>;
  supplierOrders?: InputMaybe<Supplier_Orders_Arr_Rel_Insert_Input>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Suppliers_Max_Fields = {
  __typename: 'suppliers_max_fields';
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  domainId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  mainAddressId?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "suppliers" */
export type Suppliers_Max_Order_By = {
  color?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mainAddressId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Suppliers_Min_Fields = {
  __typename: 'suppliers_min_fields';
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  domainId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  mainAddressId?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "suppliers" */
export type Suppliers_Min_Order_By = {
  color?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mainAddressId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "suppliers" */
export type Suppliers_Mutation_Response = {
  __typename: 'suppliers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Suppliers>;
};

/** input type for inserting object relation for remote table "suppliers" */
export type Suppliers_Obj_Rel_Insert_Input = {
  data: Suppliers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Suppliers_On_Conflict>;
};

/** on_conflict condition type for table "suppliers" */
export type Suppliers_On_Conflict = {
  constraint: Suppliers_Constraint;
  update_columns?: Array<Suppliers_Update_Column>;
  where?: InputMaybe<Suppliers_Bool_Exp>;
};

/** Ordering options when selecting data from "suppliers". */
export type Suppliers_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Order_By>;
  aggregatedDemandPlanIncomingGoodsResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Incoming_Goods_Results_Aggregate_Order_By>;
  aggregatedDemandPlanResults_aggregate?: InputMaybe<Aggregated_Demand_Plan_Results_Aggregate_Order_By>;
  allowedTenants?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  communicationLanguage?: InputMaybe<Order_By>;
  contacts_aggregate?: InputMaybe<Contacts_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  globalSearchResults_aggregate?: InputMaybe<Global_Search_Result_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  mainAddress?: InputMaybe<Supplier_Addresses_Order_By>;
  mainAddressId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  productSuppliers_aggregate?: InputMaybe<Product_Suppliers_Aggregate_Order_By>;
  state?: InputMaybe<Order_By>;
  supplierOrders_aggregate?: InputMaybe<Supplier_Orders_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: suppliers */
export type Suppliers_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Suppliers_Prepend_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "suppliers" */
export enum Suppliers_Select_Column {
  /** column name */
  AllowedTenants = 'allowedTenants',
  /** column name */
  Color = 'color',
  /** column name */
  CommunicationLanguage = 'communicationLanguage',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  Description = 'description',
  /** column name */
  DomainId = 'domainId',
  /** column name */
  Id = 'id',
  /** column name */
  MainAddressId = 'mainAddressId',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "suppliers" */
export type Suppliers_Set_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
  color?: InputMaybe<Scalars['String']>;
  communicationLanguage?: InputMaybe<Languages_Enum>;
  currency?: InputMaybe<Currencies_Enum>;
  description?: InputMaybe<Scalars['String']>;
  domainId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  mainAddressId?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Supplier_States_Enum>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Suppliers_Stddev_Fields = {
  __typename: 'suppliers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  mainAddressId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "suppliers" */
export type Suppliers_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  mainAddressId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Suppliers_Stddev_Pop_Fields = {
  __typename: 'suppliers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  mainAddressId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "suppliers" */
export type Suppliers_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  mainAddressId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Suppliers_Stddev_Samp_Fields = {
  __typename: 'suppliers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  mainAddressId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "suppliers" */
export type Suppliers_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  mainAddressId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "suppliers" */
export type Suppliers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Suppliers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Suppliers_Stream_Cursor_Value_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
  color?: InputMaybe<Scalars['String']>;
  communicationLanguage?: InputMaybe<Languages_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Currencies_Enum>;
  description?: InputMaybe<Scalars['String']>;
  domainId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  mainAddressId?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Supplier_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Suppliers_Sum_Fields = {
  __typename: 'suppliers_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  mainAddressId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "suppliers" */
export type Suppliers_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  mainAddressId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "suppliers" */
export enum Suppliers_Update_Column {
  /** column name */
  AllowedTenants = 'allowedTenants',
  /** column name */
  Color = 'color',
  /** column name */
  CommunicationLanguage = 'communicationLanguage',
  /** column name */
  Currency = 'currency',
  /** column name */
  Description = 'description',
  /** column name */
  DomainId = 'domainId',
  /** column name */
  Id = 'id',
  /** column name */
  MainAddressId = 'mainAddressId',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  State = 'state',
  /** column name */
  Version = 'version'
}

export type Suppliers_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Suppliers_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Suppliers_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Suppliers_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Suppliers_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Suppliers_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Suppliers_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Suppliers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Suppliers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Suppliers_Var_Pop_Fields = {
  __typename: 'suppliers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  mainAddressId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "suppliers" */
export type Suppliers_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  mainAddressId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Suppliers_Var_Samp_Fields = {
  __typename: 'suppliers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  mainAddressId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "suppliers" */
export type Suppliers_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  mainAddressId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Suppliers_Variance_Fields = {
  __typename: 'suppliers_variance_fields';
  id?: Maybe<Scalars['Float']>;
  mainAddressId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "suppliers" */
export type Suppliers_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  mainAddressId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "temporal_pricing_state_transitions" */
export type Temporal_Pricing_State_Transitions = {
  __typename: 'temporal_pricing_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Temporal_Pricing_States_Enum;
  toState: Temporal_Pricing_States_Enum;
};

/** aggregated selection of "temporal_pricing_state_transitions" */
export type Temporal_Pricing_State_Transitions_Aggregate = {
  __typename: 'temporal_pricing_state_transitions_aggregate';
  aggregate?: Maybe<Temporal_Pricing_State_Transitions_Aggregate_Fields>;
  nodes: Array<Temporal_Pricing_State_Transitions>;
};

/** aggregate fields of "temporal_pricing_state_transitions" */
export type Temporal_Pricing_State_Transitions_Aggregate_Fields = {
  __typename: 'temporal_pricing_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Temporal_Pricing_State_Transitions_Max_Fields>;
  min?: Maybe<Temporal_Pricing_State_Transitions_Min_Fields>;
};


/** aggregate fields of "temporal_pricing_state_transitions" */
export type Temporal_Pricing_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Temporal_Pricing_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "temporal_pricing_state_transitions". All fields are combined with a logical 'AND'. */
export type Temporal_Pricing_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Temporal_Pricing_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Temporal_Pricing_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Temporal_Pricing_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Temporal_Pricing_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Temporal_Pricing_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Temporal_Pricing_State_Transitions_Max_Fields = {
  __typename: 'temporal_pricing_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Temporal_Pricing_State_Transitions_Min_Fields = {
  __typename: 'temporal_pricing_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "temporal_pricing_state_transitions". */
export type Temporal_Pricing_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "temporal_pricing_state_transitions" */
export enum Temporal_Pricing_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "temporal_pricing_state_transitions" */
export type Temporal_Pricing_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Temporal_Pricing_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Temporal_Pricing_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Temporal_Pricing_States_Enum>;
  toState?: InputMaybe<Temporal_Pricing_States_Enum>;
};

/** columns and relationships of "temporal_pricing_states" */
export type Temporal_Pricing_States = {
  __typename: 'temporal_pricing_states';
  /** An array relationship */
  customerGroupPricings: Array<Customer_Group_Pricing>;
  /** An aggregate relationship */
  customerGroupPricings_aggregate: Customer_Group_Pricing_Aggregate;
  /** An array relationship */
  priceLists: Array<Price_Lists>;
  /** An aggregate relationship */
  priceLists_aggregate: Price_Lists_Aggregate;
  /** An array relationship */
  temporalProducts: Array<Temporal_Products>;
  /** An aggregate relationship */
  temporalProducts_aggregate: Temporal_Products_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "temporal_pricing_states" */
export type Temporal_Pricing_StatesCustomerGroupPricingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


/** columns and relationships of "temporal_pricing_states" */
export type Temporal_Pricing_StatesCustomerGroupPricings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


/** columns and relationships of "temporal_pricing_states" */
export type Temporal_Pricing_StatesPriceListsArgs = {
  distinct_on?: InputMaybe<Array<Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Lists_Order_By>>;
  where?: InputMaybe<Price_Lists_Bool_Exp>;
};


/** columns and relationships of "temporal_pricing_states" */
export type Temporal_Pricing_StatesPriceLists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Lists_Order_By>>;
  where?: InputMaybe<Price_Lists_Bool_Exp>;
};


/** columns and relationships of "temporal_pricing_states" */
export type Temporal_Pricing_StatesTemporalProductsArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


/** columns and relationships of "temporal_pricing_states" */
export type Temporal_Pricing_StatesTemporalProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};

/** aggregated selection of "temporal_pricing_states" */
export type Temporal_Pricing_States_Aggregate = {
  __typename: 'temporal_pricing_states_aggregate';
  aggregate?: Maybe<Temporal_Pricing_States_Aggregate_Fields>;
  nodes: Array<Temporal_Pricing_States>;
};

/** aggregate fields of "temporal_pricing_states" */
export type Temporal_Pricing_States_Aggregate_Fields = {
  __typename: 'temporal_pricing_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Temporal_Pricing_States_Max_Fields>;
  min?: Maybe<Temporal_Pricing_States_Min_Fields>;
};


/** aggregate fields of "temporal_pricing_states" */
export type Temporal_Pricing_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Temporal_Pricing_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "temporal_pricing_states". All fields are combined with a logical 'AND'. */
export type Temporal_Pricing_States_Bool_Exp = {
  _and?: InputMaybe<Array<Temporal_Pricing_States_Bool_Exp>>;
  _not?: InputMaybe<Temporal_Pricing_States_Bool_Exp>;
  _or?: InputMaybe<Array<Temporal_Pricing_States_Bool_Exp>>;
  customerGroupPricings?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
  customerGroupPricings_aggregate?: InputMaybe<Customer_Group_Pricing_Aggregate_Bool_Exp>;
  priceLists?: InputMaybe<Price_Lists_Bool_Exp>;
  priceLists_aggregate?: InputMaybe<Price_Lists_Aggregate_Bool_Exp>;
  temporalProducts?: InputMaybe<Temporal_Products_Bool_Exp>;
  temporalProducts_aggregate?: InputMaybe<Temporal_Products_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Temporal_Pricing_States_Enum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

/** Boolean expression to compare columns of type "temporal_pricing_states_enum". All fields are combined with logical 'AND'. */
export type Temporal_Pricing_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Temporal_Pricing_States_Enum>;
  _in?: InputMaybe<Array<Temporal_Pricing_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Temporal_Pricing_States_Enum>;
  _nin?: InputMaybe<Array<Temporal_Pricing_States_Enum>>;
};

/** aggregate max on columns */
export type Temporal_Pricing_States_Max_Fields = {
  __typename: 'temporal_pricing_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Temporal_Pricing_States_Min_Fields = {
  __typename: 'temporal_pricing_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "temporal_pricing_states". */
export type Temporal_Pricing_States_Order_By = {
  customerGroupPricings_aggregate?: InputMaybe<Customer_Group_Pricing_Aggregate_Order_By>;
  priceLists_aggregate?: InputMaybe<Price_Lists_Aggregate_Order_By>;
  temporalProducts_aggregate?: InputMaybe<Temporal_Products_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "temporal_pricing_states" */
export enum Temporal_Pricing_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "temporal_pricing_states" */
export type Temporal_Pricing_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Temporal_Pricing_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Temporal_Pricing_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "temporal_product_comparison_result" */
export type Temporal_Product_Comparison_Result = {
  __typename: 'temporal_product_comparison_result';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['numeric']>;
  costPriceChange?: Maybe<Scalars['bigint']>;
  costPricePercentageChange?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  lastTemporalProduct?: Maybe<Temporal_Products>;
  lastTemporalProductId?: Maybe<Scalars['bigint']>;
  valuationPriceAbsolutePercentageChange?: Maybe<Scalars['numeric']>;
  valuationPriceChange?: Maybe<Scalars['bigint']>;
  valuationPricePercentageChange?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "temporal_product_comparison_result" */
export type Temporal_Product_Comparison_Result_Aggregate = {
  __typename: 'temporal_product_comparison_result_aggregate';
  aggregate?: Maybe<Temporal_Product_Comparison_Result_Aggregate_Fields>;
  nodes: Array<Temporal_Product_Comparison_Result>;
};

export type Temporal_Product_Comparison_Result_Aggregate_Bool_Exp = {
  count?: InputMaybe<Temporal_Product_Comparison_Result_Aggregate_Bool_Exp_Count>;
};

export type Temporal_Product_Comparison_Result_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Temporal_Product_Comparison_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Temporal_Product_Comparison_Result_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "temporal_product_comparison_result" */
export type Temporal_Product_Comparison_Result_Aggregate_Fields = {
  __typename: 'temporal_product_comparison_result_aggregate_fields';
  avg?: Maybe<Temporal_Product_Comparison_Result_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Temporal_Product_Comparison_Result_Max_Fields>;
  min?: Maybe<Temporal_Product_Comparison_Result_Min_Fields>;
  stddev?: Maybe<Temporal_Product_Comparison_Result_Stddev_Fields>;
  stddev_pop?: Maybe<Temporal_Product_Comparison_Result_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Temporal_Product_Comparison_Result_Stddev_Samp_Fields>;
  sum?: Maybe<Temporal_Product_Comparison_Result_Sum_Fields>;
  var_pop?: Maybe<Temporal_Product_Comparison_Result_Var_Pop_Fields>;
  var_samp?: Maybe<Temporal_Product_Comparison_Result_Var_Samp_Fields>;
  variance?: Maybe<Temporal_Product_Comparison_Result_Variance_Fields>;
};


/** aggregate fields of "temporal_product_comparison_result" */
export type Temporal_Product_Comparison_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Temporal_Product_Comparison_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "temporal_product_comparison_result" */
export type Temporal_Product_Comparison_Result_Aggregate_Order_By = {
  avg?: InputMaybe<Temporal_Product_Comparison_Result_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Temporal_Product_Comparison_Result_Max_Order_By>;
  min?: InputMaybe<Temporal_Product_Comparison_Result_Min_Order_By>;
  stddev?: InputMaybe<Temporal_Product_Comparison_Result_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Temporal_Product_Comparison_Result_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Temporal_Product_Comparison_Result_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Temporal_Product_Comparison_Result_Sum_Order_By>;
  var_pop?: InputMaybe<Temporal_Product_Comparison_Result_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Temporal_Product_Comparison_Result_Var_Samp_Order_By>;
  variance?: InputMaybe<Temporal_Product_Comparison_Result_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Temporal_Product_Comparison_Result_Avg_Fields = {
  __typename: 'temporal_product_comparison_result_avg_fields';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  costPriceChange?: Maybe<Scalars['Float']>;
  costPricePercentageChange?: Maybe<Scalars['Float']>;
  lastTemporalProductId?: Maybe<Scalars['Float']>;
  valuationPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  valuationPriceChange?: Maybe<Scalars['Float']>;
  valuationPricePercentageChange?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "temporal_product_comparison_result" */
export type Temporal_Product_Comparison_Result_Avg_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  lastTemporalProductId?: InputMaybe<Order_By>;
  valuationPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  valuationPriceChange?: InputMaybe<Order_By>;
  valuationPricePercentageChange?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "temporal_product_comparison_result". All fields are combined with a logical 'AND'. */
export type Temporal_Product_Comparison_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Temporal_Product_Comparison_Result_Bool_Exp>>;
  _not?: InputMaybe<Temporal_Product_Comparison_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Temporal_Product_Comparison_Result_Bool_Exp>>;
  costPriceAbsolutePercentageChange?: InputMaybe<Numeric_Comparison_Exp>;
  costPriceChange?: InputMaybe<Bigint_Comparison_Exp>;
  costPricePercentageChange?: InputMaybe<Numeric_Comparison_Exp>;
  lastTemporalProduct?: InputMaybe<Temporal_Products_Bool_Exp>;
  lastTemporalProductId?: InputMaybe<Bigint_Comparison_Exp>;
  valuationPriceAbsolutePercentageChange?: InputMaybe<Numeric_Comparison_Exp>;
  valuationPriceChange?: InputMaybe<Bigint_Comparison_Exp>;
  valuationPricePercentageChange?: InputMaybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Temporal_Product_Comparison_Result_Max_Fields = {
  __typename: 'temporal_product_comparison_result_max_fields';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['numeric']>;
  costPriceChange?: Maybe<Scalars['bigint']>;
  costPricePercentageChange?: Maybe<Scalars['numeric']>;
  lastTemporalProductId?: Maybe<Scalars['bigint']>;
  valuationPriceAbsolutePercentageChange?: Maybe<Scalars['numeric']>;
  valuationPriceChange?: Maybe<Scalars['bigint']>;
  valuationPricePercentageChange?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "temporal_product_comparison_result" */
export type Temporal_Product_Comparison_Result_Max_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  lastTemporalProductId?: InputMaybe<Order_By>;
  valuationPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  valuationPriceChange?: InputMaybe<Order_By>;
  valuationPricePercentageChange?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Temporal_Product_Comparison_Result_Min_Fields = {
  __typename: 'temporal_product_comparison_result_min_fields';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['numeric']>;
  costPriceChange?: Maybe<Scalars['bigint']>;
  costPricePercentageChange?: Maybe<Scalars['numeric']>;
  lastTemporalProductId?: Maybe<Scalars['bigint']>;
  valuationPriceAbsolutePercentageChange?: Maybe<Scalars['numeric']>;
  valuationPriceChange?: Maybe<Scalars['bigint']>;
  valuationPricePercentageChange?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "temporal_product_comparison_result" */
export type Temporal_Product_Comparison_Result_Min_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  lastTemporalProductId?: InputMaybe<Order_By>;
  valuationPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  valuationPriceChange?: InputMaybe<Order_By>;
  valuationPricePercentageChange?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "temporal_product_comparison_result". */
export type Temporal_Product_Comparison_Result_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  lastTemporalProduct?: InputMaybe<Temporal_Products_Order_By>;
  lastTemporalProductId?: InputMaybe<Order_By>;
  valuationPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  valuationPriceChange?: InputMaybe<Order_By>;
  valuationPricePercentageChange?: InputMaybe<Order_By>;
};

/** select columns of table "temporal_product_comparison_result" */
export enum Temporal_Product_Comparison_Result_Select_Column {
  /** column name */
  CostPriceAbsolutePercentageChange = 'costPriceAbsolutePercentageChange',
  /** column name */
  CostPriceChange = 'costPriceChange',
  /** column name */
  CostPricePercentageChange = 'costPricePercentageChange',
  /** column name */
  LastTemporalProductId = 'lastTemporalProductId',
  /** column name */
  ValuationPriceAbsolutePercentageChange = 'valuationPriceAbsolutePercentageChange',
  /** column name */
  ValuationPriceChange = 'valuationPriceChange',
  /** column name */
  ValuationPricePercentageChange = 'valuationPricePercentageChange'
}

/** aggregate stddev on columns */
export type Temporal_Product_Comparison_Result_Stddev_Fields = {
  __typename: 'temporal_product_comparison_result_stddev_fields';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  costPriceChange?: Maybe<Scalars['Float']>;
  costPricePercentageChange?: Maybe<Scalars['Float']>;
  lastTemporalProductId?: Maybe<Scalars['Float']>;
  valuationPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  valuationPriceChange?: Maybe<Scalars['Float']>;
  valuationPricePercentageChange?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "temporal_product_comparison_result" */
export type Temporal_Product_Comparison_Result_Stddev_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  lastTemporalProductId?: InputMaybe<Order_By>;
  valuationPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  valuationPriceChange?: InputMaybe<Order_By>;
  valuationPricePercentageChange?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Temporal_Product_Comparison_Result_Stddev_Pop_Fields = {
  __typename: 'temporal_product_comparison_result_stddev_pop_fields';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  costPriceChange?: Maybe<Scalars['Float']>;
  costPricePercentageChange?: Maybe<Scalars['Float']>;
  lastTemporalProductId?: Maybe<Scalars['Float']>;
  valuationPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  valuationPriceChange?: Maybe<Scalars['Float']>;
  valuationPricePercentageChange?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "temporal_product_comparison_result" */
export type Temporal_Product_Comparison_Result_Stddev_Pop_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  lastTemporalProductId?: InputMaybe<Order_By>;
  valuationPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  valuationPriceChange?: InputMaybe<Order_By>;
  valuationPricePercentageChange?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Temporal_Product_Comparison_Result_Stddev_Samp_Fields = {
  __typename: 'temporal_product_comparison_result_stddev_samp_fields';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  costPriceChange?: Maybe<Scalars['Float']>;
  costPricePercentageChange?: Maybe<Scalars['Float']>;
  lastTemporalProductId?: Maybe<Scalars['Float']>;
  valuationPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  valuationPriceChange?: Maybe<Scalars['Float']>;
  valuationPricePercentageChange?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "temporal_product_comparison_result" */
export type Temporal_Product_Comparison_Result_Stddev_Samp_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  lastTemporalProductId?: InputMaybe<Order_By>;
  valuationPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  valuationPriceChange?: InputMaybe<Order_By>;
  valuationPricePercentageChange?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "temporal_product_comparison_result" */
export type Temporal_Product_Comparison_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Temporal_Product_Comparison_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Temporal_Product_Comparison_Result_Stream_Cursor_Value_Input = {
  costPriceAbsolutePercentageChange?: InputMaybe<Scalars['numeric']>;
  costPriceChange?: InputMaybe<Scalars['bigint']>;
  costPricePercentageChange?: InputMaybe<Scalars['numeric']>;
  lastTemporalProductId?: InputMaybe<Scalars['bigint']>;
  valuationPriceAbsolutePercentageChange?: InputMaybe<Scalars['numeric']>;
  valuationPriceChange?: InputMaybe<Scalars['bigint']>;
  valuationPricePercentageChange?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Temporal_Product_Comparison_Result_Sum_Fields = {
  __typename: 'temporal_product_comparison_result_sum_fields';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['numeric']>;
  costPriceChange?: Maybe<Scalars['bigint']>;
  costPricePercentageChange?: Maybe<Scalars['numeric']>;
  lastTemporalProductId?: Maybe<Scalars['bigint']>;
  valuationPriceAbsolutePercentageChange?: Maybe<Scalars['numeric']>;
  valuationPriceChange?: Maybe<Scalars['bigint']>;
  valuationPricePercentageChange?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "temporal_product_comparison_result" */
export type Temporal_Product_Comparison_Result_Sum_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  lastTemporalProductId?: InputMaybe<Order_By>;
  valuationPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  valuationPriceChange?: InputMaybe<Order_By>;
  valuationPricePercentageChange?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Temporal_Product_Comparison_Result_Var_Pop_Fields = {
  __typename: 'temporal_product_comparison_result_var_pop_fields';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  costPriceChange?: Maybe<Scalars['Float']>;
  costPricePercentageChange?: Maybe<Scalars['Float']>;
  lastTemporalProductId?: Maybe<Scalars['Float']>;
  valuationPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  valuationPriceChange?: Maybe<Scalars['Float']>;
  valuationPricePercentageChange?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "temporal_product_comparison_result" */
export type Temporal_Product_Comparison_Result_Var_Pop_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  lastTemporalProductId?: InputMaybe<Order_By>;
  valuationPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  valuationPriceChange?: InputMaybe<Order_By>;
  valuationPricePercentageChange?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Temporal_Product_Comparison_Result_Var_Samp_Fields = {
  __typename: 'temporal_product_comparison_result_var_samp_fields';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  costPriceChange?: Maybe<Scalars['Float']>;
  costPricePercentageChange?: Maybe<Scalars['Float']>;
  lastTemporalProductId?: Maybe<Scalars['Float']>;
  valuationPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  valuationPriceChange?: Maybe<Scalars['Float']>;
  valuationPricePercentageChange?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "temporal_product_comparison_result" */
export type Temporal_Product_Comparison_Result_Var_Samp_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  lastTemporalProductId?: InputMaybe<Order_By>;
  valuationPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  valuationPriceChange?: InputMaybe<Order_By>;
  valuationPricePercentageChange?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Temporal_Product_Comparison_Result_Variance_Fields = {
  __typename: 'temporal_product_comparison_result_variance_fields';
  costPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  costPriceChange?: Maybe<Scalars['Float']>;
  costPricePercentageChange?: Maybe<Scalars['Float']>;
  lastTemporalProductId?: Maybe<Scalars['Float']>;
  valuationPriceAbsolutePercentageChange?: Maybe<Scalars['Float']>;
  valuationPriceChange?: Maybe<Scalars['Float']>;
  valuationPricePercentageChange?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "temporal_product_comparison_result" */
export type Temporal_Product_Comparison_Result_Variance_Order_By = {
  costPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  costPriceChange?: InputMaybe<Order_By>;
  costPricePercentageChange?: InputMaybe<Order_By>;
  lastTemporalProductId?: InputMaybe<Order_By>;
  valuationPriceAbsolutePercentageChange?: InputMaybe<Order_By>;
  valuationPriceChange?: InputMaybe<Order_By>;
  valuationPricePercentageChange?: InputMaybe<Order_By>;
};

/** columns and relationships of "temporal_products" */
export type Temporal_Products = {
  __typename: 'temporal_products';
  /** An object relationship */
  Tenant: Tenants;
  baseSalesPricePerUnit?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  baseSalesPriceUnit?: Maybe<Product_Units>;
  baseSalesPriceUnitId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  calculateCostPriceForProductResults: Array<Calculate_Cost_Price_For_Product_Results>;
  /** An aggregate relationship */
  calculateCostPriceForProductResults_aggregate: Calculate_Cost_Price_For_Product_Results_Aggregate;
  /** Finds and compares attribute between the given TP and the previously active TP for the same product, if it exist. */
  comparedTo?: Maybe<Array<Temporal_Product_Comparison_Result>>;
  costPricePerUnit?: Maybe<Scalars['bigint']>;
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  costPriceUnit?: Maybe<Product_Units>;
  costPriceUnitId?: Maybe<Scalars['bigint']>;
  createdAt: Scalars['timestamptz'];
  currency: Currencies_Enum;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: Maybe<Scalars['bigint']>;
  fixedCostPrice: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** An object relationship */
  product: Products;
  productId: Scalars['bigint'];
  state: Temporal_Pricing_States_Enum;
  /** An array relationship */
  temporalProductComparisonResults: Array<Temporal_Product_Comparison_Result>;
  /** An aggregate relationship */
  temporalProductComparisonResults_aggregate: Temporal_Product_Comparison_Result_Aggregate;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  valid: Scalars['daterange'];
  valuationPricePerKg?: Maybe<Scalars['bigint']>;
  version: Scalars['bigint'];
};


/** columns and relationships of "temporal_products" */
export type Temporal_ProductsCalculateCostPriceForProductResultsArgs = {
  distinct_on?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Order_By>>;
  where?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
};


/** columns and relationships of "temporal_products" */
export type Temporal_ProductsCalculateCostPriceForProductResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculate_Cost_Price_For_Product_Results_Order_By>>;
  where?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
};


/** columns and relationships of "temporal_products" */
export type Temporal_ProductsComparedToArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Product_Comparison_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Product_Comparison_Result_Order_By>>;
  where?: InputMaybe<Temporal_Product_Comparison_Result_Bool_Exp>;
};


/** columns and relationships of "temporal_products" */
export type Temporal_ProductsTemporalProductComparisonResultsArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Product_Comparison_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Product_Comparison_Result_Order_By>>;
  where?: InputMaybe<Temporal_Product_Comparison_Result_Bool_Exp>;
};


/** columns and relationships of "temporal_products" */
export type Temporal_ProductsTemporalProductComparisonResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Product_Comparison_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Product_Comparison_Result_Order_By>>;
  where?: InputMaybe<Temporal_Product_Comparison_Result_Bool_Exp>;
};

/** aggregated selection of "temporal_products" */
export type Temporal_Products_Aggregate = {
  __typename: 'temporal_products_aggregate';
  aggregate?: Maybe<Temporal_Products_Aggregate_Fields>;
  nodes: Array<Temporal_Products>;
};

export type Temporal_Products_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Temporal_Products_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Temporal_Products_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Temporal_Products_Aggregate_Bool_Exp_Count>;
};

export type Temporal_Products_Aggregate_Bool_Exp_Bool_And = {
  arguments: Temporal_Products_Select_Column_Temporal_Products_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Temporal_Products_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Temporal_Products_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Temporal_Products_Select_Column_Temporal_Products_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Temporal_Products_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Temporal_Products_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Temporal_Products_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "temporal_products" */
export type Temporal_Products_Aggregate_Fields = {
  __typename: 'temporal_products_aggregate_fields';
  avg?: Maybe<Temporal_Products_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Temporal_Products_Max_Fields>;
  min?: Maybe<Temporal_Products_Min_Fields>;
  stddev?: Maybe<Temporal_Products_Stddev_Fields>;
  stddev_pop?: Maybe<Temporal_Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Temporal_Products_Stddev_Samp_Fields>;
  sum?: Maybe<Temporal_Products_Sum_Fields>;
  var_pop?: Maybe<Temporal_Products_Var_Pop_Fields>;
  var_samp?: Maybe<Temporal_Products_Var_Samp_Fields>;
  variance?: Maybe<Temporal_Products_Variance_Fields>;
};


/** aggregate fields of "temporal_products" */
export type Temporal_Products_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "temporal_products" */
export type Temporal_Products_Aggregate_Order_By = {
  avg?: InputMaybe<Temporal_Products_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Temporal_Products_Max_Order_By>;
  min?: InputMaybe<Temporal_Products_Min_Order_By>;
  stddev?: InputMaybe<Temporal_Products_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Temporal_Products_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Temporal_Products_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Temporal_Products_Sum_Order_By>;
  var_pop?: InputMaybe<Temporal_Products_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Temporal_Products_Var_Samp_Order_By>;
  variance?: InputMaybe<Temporal_Products_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "temporal_products" */
export type Temporal_Products_Arr_Rel_Insert_Input = {
  data: Array<Temporal_Products_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Temporal_Products_On_Conflict>;
};

/** aggregate avg on columns */
export type Temporal_Products_Avg_Fields = {
  __typename: 'temporal_products_avg_fields';
  baseSalesPricePerUnit?: Maybe<Scalars['Float']>;
  baseSalesPriceUnitId?: Maybe<Scalars['Float']>;
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  valuationPricePerKg?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "temporal_products" */
export type Temporal_Products_Avg_Order_By = {
  baseSalesPricePerUnit?: InputMaybe<Order_By>;
  baseSalesPriceUnitId?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  valuationPricePerKg?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "temporal_products". All fields are combined with a logical 'AND'. */
export type Temporal_Products_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Temporal_Products_Bool_Exp>>;
  _not?: InputMaybe<Temporal_Products_Bool_Exp>;
  _or?: InputMaybe<Array<Temporal_Products_Bool_Exp>>;
  baseSalesPricePerUnit?: InputMaybe<Bigint_Comparison_Exp>;
  baseSalesPriceUnit?: InputMaybe<Product_Units_Bool_Exp>;
  baseSalesPriceUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  calculateCostPriceForProductResults?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Bool_Exp>;
  calculateCostPriceForProductResults_aggregate?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Aggregate_Bool_Exp>;
  comparedTo?: InputMaybe<Temporal_Product_Comparison_Result_Bool_Exp>;
  costPricePerUnit?: InputMaybe<Bigint_Comparison_Exp>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Bigint_Comparison_Exp>;
  costPriceUnit?: InputMaybe<Product_Units_Bool_Exp>;
  costPriceUnitId?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Currencies_Enum_Comparison_Exp>;
  dnaTestPricePerKg?: InputMaybe<Bigint_Comparison_Exp>;
  fixedCostPrice?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<Temporal_Pricing_States_Enum_Comparison_Exp>;
  temporalProductComparisonResults?: InputMaybe<Temporal_Product_Comparison_Result_Bool_Exp>;
  temporalProductComparisonResults_aggregate?: InputMaybe<Temporal_Product_Comparison_Result_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid?: InputMaybe<Daterange_Comparison_Exp>;
  valuationPricePerKg?: InputMaybe<Bigint_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "temporal_products" */
export enum Temporal_Products_Constraint {
  /** unique or primary key constraint on columns "id" */
  TemporalProductsPkey = 'temporal_products_pkey',
  /** unique or primary key constraint on columns "valid", "state", "product_id", "tenant" */
  TemporalProductsProductIdValidStateKey = 'temporal_products_product_id_valid_state_key'
}

export type Temporal_Products_For_Cutting_Pattern_Args = {
  _as_of?: InputMaybe<Scalars['date']>;
  _cutting_pattern_id?: InputMaybe<Scalars['bigint']>;
};

export type Temporal_Products_From_Root_Product_Id_Args = {
  _as_of?: InputMaybe<Scalars['date']>;
  _root_cp_product_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for incrementing numeric columns in table "temporal_products" */
export type Temporal_Products_Inc_Input = {
  baseSalesPricePerUnit?: InputMaybe<Scalars['bigint']>;
  baseSalesPriceUnitId?: InputMaybe<Scalars['bigint']>;
  costPricePerUnit?: InputMaybe<Scalars['bigint']>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Scalars['bigint']>;
  costPriceUnitId?: InputMaybe<Scalars['bigint']>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  valuationPricePerKg?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "temporal_products" */
export type Temporal_Products_Insert_Input = {
  baseSalesPricePerUnit?: InputMaybe<Scalars['bigint']>;
  baseSalesPriceUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  baseSalesPriceUnitId?: InputMaybe<Scalars['bigint']>;
  costPricePerUnit?: InputMaybe<Scalars['bigint']>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Scalars['bigint']>;
  costPriceUnit?: InputMaybe<Product_Units_Obj_Rel_Insert_Input>;
  costPriceUnitId?: InputMaybe<Scalars['bigint']>;
  currency?: InputMaybe<Currencies_Enum>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: InputMaybe<Scalars['bigint']>;
  fixedCostPrice?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Temporal_Pricing_States_Enum>;
  valid?: InputMaybe<Scalars['daterange']>;
  valuationPricePerKg?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Temporal_Products_Max_Fields = {
  __typename: 'temporal_products_max_fields';
  baseSalesPricePerUnit?: Maybe<Scalars['bigint']>;
  baseSalesPriceUnitId?: Maybe<Scalars['bigint']>;
  costPricePerUnit?: Maybe<Scalars['bigint']>;
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['bigint']>;
  costPriceUnitId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  valuationPricePerKg?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "temporal_products" */
export type Temporal_Products_Max_Order_By = {
  baseSalesPricePerUnit?: InputMaybe<Order_By>;
  baseSalesPriceUnitId?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  valuationPricePerKg?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Temporal_Products_Min_Fields = {
  __typename: 'temporal_products_min_fields';
  baseSalesPricePerUnit?: Maybe<Scalars['bigint']>;
  baseSalesPriceUnitId?: Maybe<Scalars['bigint']>;
  costPricePerUnit?: Maybe<Scalars['bigint']>;
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['bigint']>;
  costPriceUnitId?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  valuationPricePerKg?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "temporal_products" */
export type Temporal_Products_Min_Order_By = {
  baseSalesPricePerUnit?: InputMaybe<Order_By>;
  baseSalesPriceUnitId?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  valuationPricePerKg?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "temporal_products" */
export type Temporal_Products_Mutation_Response = {
  __typename: 'temporal_products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Temporal_Products>;
};

/** on_conflict condition type for table "temporal_products" */
export type Temporal_Products_On_Conflict = {
  constraint: Temporal_Products_Constraint;
  update_columns?: Array<Temporal_Products_Update_Column>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};

/** Ordering options when selecting data from "temporal_products". */
export type Temporal_Products_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  baseSalesPricePerUnit?: InputMaybe<Order_By>;
  baseSalesPriceUnit?: InputMaybe<Product_Units_Order_By>;
  baseSalesPriceUnitId?: InputMaybe<Order_By>;
  calculateCostPriceForProductResults_aggregate?: InputMaybe<Calculate_Cost_Price_For_Product_Results_Aggregate_Order_By>;
  comparedTo_aggregate?: InputMaybe<Temporal_Product_Comparison_Result_Aggregate_Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPriceUnit?: InputMaybe<Product_Units_Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  dnaTestPricePerKg?: InputMaybe<Order_By>;
  fixedCostPrice?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  temporalProductComparisonResults_aggregate?: InputMaybe<Temporal_Product_Comparison_Result_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  valid?: InputMaybe<Order_By>;
  valuationPricePerKg?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: temporal_products */
export type Temporal_Products_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "temporal_products" */
export enum Temporal_Products_Select_Column {
  /** column name */
  BaseSalesPricePerUnit = 'baseSalesPricePerUnit',
  /** column name */
  BaseSalesPriceUnitId = 'baseSalesPriceUnitId',
  /** column name */
  CostPricePerUnit = 'costPricePerUnit',
  /** column name */
  CostPricePerUnitAbsoluteMargin = 'costPricePerUnitAbsoluteMargin',
  /** column name */
  CostPriceUnitId = 'costPriceUnitId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  DnaTestPricePerKg = 'dnaTestPricePerKg',
  /** column name */
  FixedCostPrice = 'fixedCostPrice',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Valid = 'valid',
  /** column name */
  ValuationPricePerKg = 'valuationPricePerKg',
  /** column name */
  Version = 'version'
}

/** select "temporal_products_aggregate_bool_exp_bool_and_arguments_columns" columns of table "temporal_products" */
export enum Temporal_Products_Select_Column_Temporal_Products_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  FixedCostPrice = 'fixedCostPrice'
}

/** select "temporal_products_aggregate_bool_exp_bool_or_arguments_columns" columns of table "temporal_products" */
export enum Temporal_Products_Select_Column_Temporal_Products_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  FixedCostPrice = 'fixedCostPrice'
}

/** input type for updating data in table "temporal_products" */
export type Temporal_Products_Set_Input = {
  baseSalesPricePerUnit?: InputMaybe<Scalars['bigint']>;
  baseSalesPriceUnitId?: InputMaybe<Scalars['bigint']>;
  costPricePerUnit?: InputMaybe<Scalars['bigint']>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Scalars['bigint']>;
  costPriceUnitId?: InputMaybe<Scalars['bigint']>;
  currency?: InputMaybe<Currencies_Enum>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: InputMaybe<Scalars['bigint']>;
  fixedCostPrice?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Temporal_Pricing_States_Enum>;
  valid?: InputMaybe<Scalars['daterange']>;
  valuationPricePerKg?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Temporal_Products_Stddev_Fields = {
  __typename: 'temporal_products_stddev_fields';
  baseSalesPricePerUnit?: Maybe<Scalars['Float']>;
  baseSalesPriceUnitId?: Maybe<Scalars['Float']>;
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  valuationPricePerKg?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "temporal_products" */
export type Temporal_Products_Stddev_Order_By = {
  baseSalesPricePerUnit?: InputMaybe<Order_By>;
  baseSalesPriceUnitId?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  valuationPricePerKg?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Temporal_Products_Stddev_Pop_Fields = {
  __typename: 'temporal_products_stddev_pop_fields';
  baseSalesPricePerUnit?: Maybe<Scalars['Float']>;
  baseSalesPriceUnitId?: Maybe<Scalars['Float']>;
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  valuationPricePerKg?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "temporal_products" */
export type Temporal_Products_Stddev_Pop_Order_By = {
  baseSalesPricePerUnit?: InputMaybe<Order_By>;
  baseSalesPriceUnitId?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  valuationPricePerKg?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Temporal_Products_Stddev_Samp_Fields = {
  __typename: 'temporal_products_stddev_samp_fields';
  baseSalesPricePerUnit?: Maybe<Scalars['Float']>;
  baseSalesPriceUnitId?: Maybe<Scalars['Float']>;
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  valuationPricePerKg?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "temporal_products" */
export type Temporal_Products_Stddev_Samp_Order_By = {
  baseSalesPricePerUnit?: InputMaybe<Order_By>;
  baseSalesPriceUnitId?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  valuationPricePerKg?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "temporal_products" */
export type Temporal_Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Temporal_Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Temporal_Products_Stream_Cursor_Value_Input = {
  baseSalesPricePerUnit?: InputMaybe<Scalars['bigint']>;
  baseSalesPriceUnitId?: InputMaybe<Scalars['bigint']>;
  costPricePerUnit?: InputMaybe<Scalars['bigint']>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Scalars['bigint']>;
  costPriceUnitId?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Currencies_Enum>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: InputMaybe<Scalars['bigint']>;
  fixedCostPrice?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Temporal_Pricing_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  valid?: InputMaybe<Scalars['daterange']>;
  valuationPricePerKg?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Temporal_Products_Sum_Fields = {
  __typename: 'temporal_products_sum_fields';
  baseSalesPricePerUnit?: Maybe<Scalars['bigint']>;
  baseSalesPriceUnitId?: Maybe<Scalars['bigint']>;
  costPricePerUnit?: Maybe<Scalars['bigint']>;
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['bigint']>;
  costPriceUnitId?: Maybe<Scalars['bigint']>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['bigint']>;
  valuationPricePerKg?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "temporal_products" */
export type Temporal_Products_Sum_Order_By = {
  baseSalesPricePerUnit?: InputMaybe<Order_By>;
  baseSalesPriceUnitId?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  valuationPricePerKg?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "temporal_products" */
export enum Temporal_Products_Update_Column {
  /** column name */
  BaseSalesPricePerUnit = 'baseSalesPricePerUnit',
  /** column name */
  BaseSalesPriceUnitId = 'baseSalesPriceUnitId',
  /** column name */
  CostPricePerUnit = 'costPricePerUnit',
  /** column name */
  CostPricePerUnitAbsoluteMargin = 'costPricePerUnitAbsoluteMargin',
  /** column name */
  CostPriceUnitId = 'costPriceUnitId',
  /** column name */
  Currency = 'currency',
  /** column name */
  DnaTestPricePerKg = 'dnaTestPricePerKg',
  /** column name */
  FixedCostPrice = 'fixedCostPrice',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  State = 'state',
  /** column name */
  Valid = 'valid',
  /** column name */
  ValuationPricePerKg = 'valuationPricePerKg',
  /** column name */
  Version = 'version'
}

export type Temporal_Products_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Temporal_Products_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Temporal_Products_Set_Input>;
  /** filter the rows which have to be updated */
  where: Temporal_Products_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Temporal_Products_Var_Pop_Fields = {
  __typename: 'temporal_products_var_pop_fields';
  baseSalesPricePerUnit?: Maybe<Scalars['Float']>;
  baseSalesPriceUnitId?: Maybe<Scalars['Float']>;
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  valuationPricePerKg?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "temporal_products" */
export type Temporal_Products_Var_Pop_Order_By = {
  baseSalesPricePerUnit?: InputMaybe<Order_By>;
  baseSalesPriceUnitId?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  valuationPricePerKg?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Temporal_Products_Var_Samp_Fields = {
  __typename: 'temporal_products_var_samp_fields';
  baseSalesPricePerUnit?: Maybe<Scalars['Float']>;
  baseSalesPriceUnitId?: Maybe<Scalars['Float']>;
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  valuationPricePerKg?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "temporal_products" */
export type Temporal_Products_Var_Samp_Order_By = {
  baseSalesPricePerUnit?: InputMaybe<Order_By>;
  baseSalesPriceUnitId?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  valuationPricePerKg?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Temporal_Products_Variance_Fields = {
  __typename: 'temporal_products_variance_fields';
  baseSalesPricePerUnit?: Maybe<Scalars['Float']>;
  baseSalesPriceUnitId?: Maybe<Scalars['Float']>;
  costPricePerUnit?: Maybe<Scalars['Float']>;
  costPricePerUnitAbsoluteMargin?: Maybe<Scalars['Float']>;
  costPriceUnitId?: Maybe<Scalars['Float']>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  valuationPricePerKg?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "temporal_products" */
export type Temporal_Products_Variance_Order_By = {
  baseSalesPricePerUnit?: InputMaybe<Order_By>;
  baseSalesPriceUnitId?: InputMaybe<Order_By>;
  costPricePerUnit?: InputMaybe<Order_By>;
  costPricePerUnitAbsoluteMargin?: InputMaybe<Order_By>;
  costPriceUnitId?: InputMaybe<Order_By>;
  /** For root cutting pattern products this price determines how much it costs to perform DNA test */
  dnaTestPricePerKg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  valuationPricePerKg?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers = {
  __typename: 'temporal_sales_teams_customers';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customer: Customers;
  customerId: Scalars['bigint'];
  id: Scalars['bigint'];
  /** An object relationship */
  salesTeam: Sales_Teams;
  salesTeamId: Scalars['bigint'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  valid: Scalars['daterange'];
  version: Scalars['bigint'];
};

/** aggregated selection of "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_Aggregate = {
  __typename: 'temporal_sales_teams_customers_aggregate';
  aggregate?: Maybe<Temporal_Sales_Teams_Customers_Aggregate_Fields>;
  nodes: Array<Temporal_Sales_Teams_Customers>;
};

export type Temporal_Sales_Teams_Customers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Temporal_Sales_Teams_Customers_Aggregate_Bool_Exp_Count>;
};

export type Temporal_Sales_Teams_Customers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Temporal_Sales_Teams_Customers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_Aggregate_Fields = {
  __typename: 'temporal_sales_teams_customers_aggregate_fields';
  avg?: Maybe<Temporal_Sales_Teams_Customers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Temporal_Sales_Teams_Customers_Max_Fields>;
  min?: Maybe<Temporal_Sales_Teams_Customers_Min_Fields>;
  stddev?: Maybe<Temporal_Sales_Teams_Customers_Stddev_Fields>;
  stddev_pop?: Maybe<Temporal_Sales_Teams_Customers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Temporal_Sales_Teams_Customers_Stddev_Samp_Fields>;
  sum?: Maybe<Temporal_Sales_Teams_Customers_Sum_Fields>;
  var_pop?: Maybe<Temporal_Sales_Teams_Customers_Var_Pop_Fields>;
  var_samp?: Maybe<Temporal_Sales_Teams_Customers_Var_Samp_Fields>;
  variance?: Maybe<Temporal_Sales_Teams_Customers_Variance_Fields>;
};


/** aggregate fields of "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_Aggregate_Order_By = {
  avg?: InputMaybe<Temporal_Sales_Teams_Customers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Temporal_Sales_Teams_Customers_Max_Order_By>;
  min?: InputMaybe<Temporal_Sales_Teams_Customers_Min_Order_By>;
  stddev?: InputMaybe<Temporal_Sales_Teams_Customers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Temporal_Sales_Teams_Customers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Temporal_Sales_Teams_Customers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Temporal_Sales_Teams_Customers_Sum_Order_By>;
  var_pop?: InputMaybe<Temporal_Sales_Teams_Customers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Temporal_Sales_Teams_Customers_Var_Samp_Order_By>;
  variance?: InputMaybe<Temporal_Sales_Teams_Customers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_Arr_Rel_Insert_Input = {
  data: Array<Temporal_Sales_Teams_Customers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Temporal_Sales_Teams_Customers_On_Conflict>;
};

/** aggregate avg on columns */
export type Temporal_Sales_Teams_Customers_Avg_Fields = {
  __typename: 'temporal_sales_teams_customers_avg_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salesTeamId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_Avg_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "temporal_sales_teams_customers". All fields are combined with a logical 'AND'. */
export type Temporal_Sales_Teams_Customers_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Bool_Exp>>;
  _not?: InputMaybe<Temporal_Sales_Teams_Customers_Bool_Exp>;
  _or?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer?: InputMaybe<Customers_Bool_Exp>;
  customerId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  salesTeam?: InputMaybe<Sales_Teams_Bool_Exp>;
  salesTeamId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid?: InputMaybe<Daterange_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "temporal_sales_teams_customers" */
export enum Temporal_Sales_Teams_Customers_Constraint {
  /** unique or primary key constraint on columns "id" */
  TemporalSalesTeamsCustomersPkey = 'temporal_sales_teams_customers_pkey'
}

/** input type for incrementing numeric columns in table "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_Inc_Input = {
  customerId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  salesTeamId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_Insert_Input = {
  customer?: InputMaybe<Customers_Obj_Rel_Insert_Input>;
  customerId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  salesTeam?: InputMaybe<Sales_Teams_Obj_Rel_Insert_Input>;
  salesTeamId?: InputMaybe<Scalars['bigint']>;
  valid?: InputMaybe<Scalars['daterange']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Temporal_Sales_Teams_Customers_Max_Fields = {
  __typename: 'temporal_sales_teams_customers_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  salesTeamId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Temporal_Sales_Teams_Customers_Min_Fields = {
  __typename: 'temporal_sales_teams_customers_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  salesTeamId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_Mutation_Response = {
  __typename: 'temporal_sales_teams_customers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Temporal_Sales_Teams_Customers>;
};

/** on_conflict condition type for table "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_On_Conflict = {
  constraint: Temporal_Sales_Teams_Customers_Constraint;
  update_columns?: Array<Temporal_Sales_Teams_Customers_Update_Column>;
  where?: InputMaybe<Temporal_Sales_Teams_Customers_Bool_Exp>;
};

/** Ordering options when selecting data from "temporal_sales_teams_customers". */
export type Temporal_Sales_Teams_Customers_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customer?: InputMaybe<Customers_Order_By>;
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesTeam?: InputMaybe<Sales_Teams_Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  valid?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: temporal_sales_teams_customers */
export type Temporal_Sales_Teams_Customers_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "temporal_sales_teams_customers" */
export enum Temporal_Sales_Teams_Customers_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Id = 'id',
  /** column name */
  SalesTeamId = 'salesTeamId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Valid = 'valid',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_Set_Input = {
  customerId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  salesTeamId?: InputMaybe<Scalars['bigint']>;
  valid?: InputMaybe<Scalars['daterange']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Temporal_Sales_Teams_Customers_Stddev_Fields = {
  __typename: 'temporal_sales_teams_customers_stddev_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salesTeamId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_Stddev_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Temporal_Sales_Teams_Customers_Stddev_Pop_Fields = {
  __typename: 'temporal_sales_teams_customers_stddev_pop_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salesTeamId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_Stddev_Pop_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Temporal_Sales_Teams_Customers_Stddev_Samp_Fields = {
  __typename: 'temporal_sales_teams_customers_stddev_samp_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salesTeamId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_Stddev_Samp_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Temporal_Sales_Teams_Customers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Temporal_Sales_Teams_Customers_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  salesTeamId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  valid?: InputMaybe<Scalars['daterange']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Temporal_Sales_Teams_Customers_Sum_Fields = {
  __typename: 'temporal_sales_teams_customers_sum_fields';
  customerId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  salesTeamId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_Sum_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "temporal_sales_teams_customers" */
export enum Temporal_Sales_Teams_Customers_Update_Column {
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Id = 'id',
  /** column name */
  SalesTeamId = 'salesTeamId',
  /** column name */
  Valid = 'valid',
  /** column name */
  Version = 'version'
}

export type Temporal_Sales_Teams_Customers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Temporal_Sales_Teams_Customers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Temporal_Sales_Teams_Customers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Temporal_Sales_Teams_Customers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Temporal_Sales_Teams_Customers_Var_Pop_Fields = {
  __typename: 'temporal_sales_teams_customers_var_pop_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salesTeamId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_Var_Pop_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Temporal_Sales_Teams_Customers_Var_Samp_Fields = {
  __typename: 'temporal_sales_teams_customers_var_samp_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salesTeamId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_Var_Samp_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Temporal_Sales_Teams_Customers_Variance_Fields = {
  __typename: 'temporal_sales_teams_customers_variance_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salesTeamId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "temporal_sales_teams_customers" */
export type Temporal_Sales_Teams_Customers_Variance_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesTeamId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "temporal_vat_types" */
export type Temporal_Vat_Types = {
  __typename: 'temporal_vat_types';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  valid: Scalars['daterange'];
  vatPct: Scalars['numeric'];
  /** An object relationship */
  vatType: Vat_Types;
  vatTypeId: Scalars['bigint'];
  version: Scalars['bigint'];
};

/** aggregated selection of "temporal_vat_types" */
export type Temporal_Vat_Types_Aggregate = {
  __typename: 'temporal_vat_types_aggregate';
  aggregate?: Maybe<Temporal_Vat_Types_Aggregate_Fields>;
  nodes: Array<Temporal_Vat_Types>;
};

export type Temporal_Vat_Types_Aggregate_Bool_Exp = {
  count?: InputMaybe<Temporal_Vat_Types_Aggregate_Bool_Exp_Count>;
};

export type Temporal_Vat_Types_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Temporal_Vat_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Temporal_Vat_Types_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "temporal_vat_types" */
export type Temporal_Vat_Types_Aggregate_Fields = {
  __typename: 'temporal_vat_types_aggregate_fields';
  avg?: Maybe<Temporal_Vat_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Temporal_Vat_Types_Max_Fields>;
  min?: Maybe<Temporal_Vat_Types_Min_Fields>;
  stddev?: Maybe<Temporal_Vat_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Temporal_Vat_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Temporal_Vat_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Temporal_Vat_Types_Sum_Fields>;
  var_pop?: Maybe<Temporal_Vat_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Temporal_Vat_Types_Var_Samp_Fields>;
  variance?: Maybe<Temporal_Vat_Types_Variance_Fields>;
};


/** aggregate fields of "temporal_vat_types" */
export type Temporal_Vat_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Temporal_Vat_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "temporal_vat_types" */
export type Temporal_Vat_Types_Aggregate_Order_By = {
  avg?: InputMaybe<Temporal_Vat_Types_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Temporal_Vat_Types_Max_Order_By>;
  min?: InputMaybe<Temporal_Vat_Types_Min_Order_By>;
  stddev?: InputMaybe<Temporal_Vat_Types_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Temporal_Vat_Types_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Temporal_Vat_Types_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Temporal_Vat_Types_Sum_Order_By>;
  var_pop?: InputMaybe<Temporal_Vat_Types_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Temporal_Vat_Types_Var_Samp_Order_By>;
  variance?: InputMaybe<Temporal_Vat_Types_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Temporal_Vat_Types_Avg_Fields = {
  __typename: 'temporal_vat_types_avg_fields';
  id?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "temporal_vat_types" */
export type Temporal_Vat_Types_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "temporal_vat_types". All fields are combined with a logical 'AND'. */
export type Temporal_Vat_Types_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Temporal_Vat_Types_Bool_Exp>>;
  _not?: InputMaybe<Temporal_Vat_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Temporal_Vat_Types_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid?: InputMaybe<Daterange_Comparison_Exp>;
  vatPct?: InputMaybe<Numeric_Comparison_Exp>;
  vatType?: InputMaybe<Vat_Types_Bool_Exp>;
  vatTypeId?: InputMaybe<Bigint_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Temporal_Vat_Types_Max_Fields = {
  __typename: 'temporal_vat_types_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vatPct?: Maybe<Scalars['numeric']>;
  vatTypeId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "temporal_vat_types" */
export type Temporal_Vat_Types_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Temporal_Vat_Types_Min_Fields = {
  __typename: 'temporal_vat_types_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vatPct?: Maybe<Scalars['numeric']>;
  vatTypeId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "temporal_vat_types" */
export type Temporal_Vat_Types_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "temporal_vat_types". */
export type Temporal_Vat_Types_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  valid?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatType?: InputMaybe<Vat_Types_Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** select columns of table "temporal_vat_types" */
export enum Temporal_Vat_Types_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Valid = 'valid',
  /** column name */
  VatPct = 'vatPct',
  /** column name */
  VatTypeId = 'vatTypeId',
  /** column name */
  Version = 'version'
}

/** aggregate stddev on columns */
export type Temporal_Vat_Types_Stddev_Fields = {
  __typename: 'temporal_vat_types_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "temporal_vat_types" */
export type Temporal_Vat_Types_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Temporal_Vat_Types_Stddev_Pop_Fields = {
  __typename: 'temporal_vat_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "temporal_vat_types" */
export type Temporal_Vat_Types_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Temporal_Vat_Types_Stddev_Samp_Fields = {
  __typename: 'temporal_vat_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "temporal_vat_types" */
export type Temporal_Vat_Types_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "temporal_vat_types" */
export type Temporal_Vat_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Temporal_Vat_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Temporal_Vat_Types_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  valid?: InputMaybe<Scalars['daterange']>;
  vatPct?: InputMaybe<Scalars['numeric']>;
  vatTypeId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Temporal_Vat_Types_Sum_Fields = {
  __typename: 'temporal_vat_types_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  vatPct?: Maybe<Scalars['numeric']>;
  vatTypeId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "temporal_vat_types" */
export type Temporal_Vat_Types_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Temporal_Vat_Types_Var_Pop_Fields = {
  __typename: 'temporal_vat_types_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "temporal_vat_types" */
export type Temporal_Vat_Types_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Temporal_Vat_Types_Var_Samp_Fields = {
  __typename: 'temporal_vat_types_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "temporal_vat_types" */
export type Temporal_Vat_Types_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Temporal_Vat_Types_Variance_Fields = {
  __typename: 'temporal_vat_types_variance_fields';
  id?: Maybe<Scalars['Float']>;
  vatPct?: Maybe<Scalars['Float']>;
  vatTypeId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "temporal_vat_types" */
export type Temporal_Vat_Types_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatTypeId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "tenant_addresses" */
export type Tenant_Addresses = {
  __typename: 'tenant_addresses';
  /** An object relationship */
  Tenant: Tenants;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCodeAlpha2?: Maybe<Scalars['bpchar']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  state?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  tenant: Scalars['String'];
  /** An array relationship */
  tenants: Array<Tenants>;
  /** An aggregate relationship */
  tenants_aggregate: Tenants_Aggregate;
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
  zip?: Maybe<Scalars['String']>;
};


/** columns and relationships of "tenant_addresses" */
export type Tenant_AddressesTenantsArgs = {
  distinct_on?: InputMaybe<Array<Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenants_Order_By>>;
  where?: InputMaybe<Tenants_Bool_Exp>;
};


/** columns and relationships of "tenant_addresses" */
export type Tenant_AddressesTenants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenants_Order_By>>;
  where?: InputMaybe<Tenants_Bool_Exp>;
};

/** aggregated selection of "tenant_addresses" */
export type Tenant_Addresses_Aggregate = {
  __typename: 'tenant_addresses_aggregate';
  aggregate?: Maybe<Tenant_Addresses_Aggregate_Fields>;
  nodes: Array<Tenant_Addresses>;
};

export type Tenant_Addresses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenant_Addresses_Aggregate_Bool_Exp_Count>;
};

export type Tenant_Addresses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenant_Addresses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Addresses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenant_addresses" */
export type Tenant_Addresses_Aggregate_Fields = {
  __typename: 'tenant_addresses_aggregate_fields';
  avg?: Maybe<Tenant_Addresses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tenant_Addresses_Max_Fields>;
  min?: Maybe<Tenant_Addresses_Min_Fields>;
  stddev?: Maybe<Tenant_Addresses_Stddev_Fields>;
  stddev_pop?: Maybe<Tenant_Addresses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tenant_Addresses_Stddev_Samp_Fields>;
  sum?: Maybe<Tenant_Addresses_Sum_Fields>;
  var_pop?: Maybe<Tenant_Addresses_Var_Pop_Fields>;
  var_samp?: Maybe<Tenant_Addresses_Var_Samp_Fields>;
  variance?: Maybe<Tenant_Addresses_Variance_Fields>;
};


/** aggregate fields of "tenant_addresses" */
export type Tenant_Addresses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenant_Addresses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tenant_addresses" */
export type Tenant_Addresses_Aggregate_Order_By = {
  avg?: InputMaybe<Tenant_Addresses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenant_Addresses_Max_Order_By>;
  min?: InputMaybe<Tenant_Addresses_Min_Order_By>;
  stddev?: InputMaybe<Tenant_Addresses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tenant_Addresses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tenant_Addresses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tenant_Addresses_Sum_Order_By>;
  var_pop?: InputMaybe<Tenant_Addresses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tenant_Addresses_Var_Samp_Order_By>;
  variance?: InputMaybe<Tenant_Addresses_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Tenant_Addresses_Avg_Fields = {
  __typename: 'tenant_addresses_avg_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tenant_addresses" */
export type Tenant_Addresses_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tenant_addresses". All fields are combined with a logical 'AND'. */
export type Tenant_Addresses_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Tenant_Addresses_Bool_Exp>>;
  _not?: InputMaybe<Tenant_Addresses_Bool_Exp>;
  _or?: InputMaybe<Array<Tenant_Addresses_Bool_Exp>>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  countryCodeAlpha2?: InputMaybe<Bpchar_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  street1?: InputMaybe<String_Comparison_Exp>;
  street2?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  tenants?: InputMaybe<Tenants_Bool_Exp>;
  tenants_aggregate?: InputMaybe<Tenants_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
  zip?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Tenant_Addresses_Max_Fields = {
  __typename: 'tenant_addresses_max_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCodeAlpha2?: Maybe<Scalars['bpchar']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "tenant_addresses" */
export type Tenant_Addresses_Max_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  countryCodeAlpha2?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street1?: InputMaybe<Order_By>;
  street2?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  zip?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenant_Addresses_Min_Fields = {
  __typename: 'tenant_addresses_min_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCodeAlpha2?: Maybe<Scalars['bpchar']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "tenant_addresses" */
export type Tenant_Addresses_Min_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  countryCodeAlpha2?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street1?: InputMaybe<Order_By>;
  street2?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  zip?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "tenant_addresses". */
export type Tenant_Addresses_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  countryCodeAlpha2?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street1?: InputMaybe<Order_By>;
  street2?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  tenants_aggregate?: InputMaybe<Tenants_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  zip?: InputMaybe<Order_By>;
};

/** select columns of table "tenant_addresses" */
export enum Tenant_Addresses_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CountryCodeAlpha2 = 'countryCodeAlpha2',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  Street1 = 'street1',
  /** column name */
  Street2 = 'street2',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version',
  /** column name */
  Zip = 'zip'
}

/** aggregate stddev on columns */
export type Tenant_Addresses_Stddev_Fields = {
  __typename: 'tenant_addresses_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tenant_addresses" */
export type Tenant_Addresses_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tenant_Addresses_Stddev_Pop_Fields = {
  __typename: 'tenant_addresses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tenant_addresses" */
export type Tenant_Addresses_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tenant_Addresses_Stddev_Samp_Fields = {
  __typename: 'tenant_addresses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tenant_addresses" */
export type Tenant_Addresses_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tenant_addresses" */
export type Tenant_Addresses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Addresses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenant_Addresses_Stream_Cursor_Value_Input = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  countryCodeAlpha2?: InputMaybe<Scalars['bpchar']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Tenant_Addresses_Sum_Fields = {
  __typename: 'tenant_addresses_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "tenant_addresses" */
export type Tenant_Addresses_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Tenant_Addresses_Var_Pop_Fields = {
  __typename: 'tenant_addresses_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tenant_addresses" */
export type Tenant_Addresses_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tenant_Addresses_Var_Samp_Fields = {
  __typename: 'tenant_addresses_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tenant_addresses" */
export type Tenant_Addresses_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tenant_Addresses_Variance_Fields = {
  __typename: 'tenant_addresses_variance_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tenant_addresses" */
export type Tenant_Addresses_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "tenant_bank_accounts" */
export type Tenant_Bank_Accounts = {
  __typename: 'tenant_bank_accounts';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  customerBillingSettings: Array<Customer_Billing_Settings>;
  /** An aggregate relationship */
  customerBillingSettings_aggregate: Customer_Billing_Settings_Aggregate;
  i18n: Scalars['jsonb'];
  iban: Scalars['String'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  ordinalPosition: Scalars['Int'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "tenant_bank_accounts" */
export type Tenant_Bank_AccountsCustomerBillingSettingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Settings_Order_By>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};


/** columns and relationships of "tenant_bank_accounts" */
export type Tenant_Bank_AccountsCustomerBillingSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Settings_Order_By>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};


/** columns and relationships of "tenant_bank_accounts" */
export type Tenant_Bank_AccountsI18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_Aggregate = {
  __typename: 'tenant_bank_accounts_aggregate';
  aggregate?: Maybe<Tenant_Bank_Accounts_Aggregate_Fields>;
  nodes: Array<Tenant_Bank_Accounts>;
};

export type Tenant_Bank_Accounts_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenant_Bank_Accounts_Aggregate_Bool_Exp_Count>;
};

export type Tenant_Bank_Accounts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenant_Bank_Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Bank_Accounts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_Aggregate_Fields = {
  __typename: 'tenant_bank_accounts_aggregate_fields';
  avg?: Maybe<Tenant_Bank_Accounts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tenant_Bank_Accounts_Max_Fields>;
  min?: Maybe<Tenant_Bank_Accounts_Min_Fields>;
  stddev?: Maybe<Tenant_Bank_Accounts_Stddev_Fields>;
  stddev_pop?: Maybe<Tenant_Bank_Accounts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tenant_Bank_Accounts_Stddev_Samp_Fields>;
  sum?: Maybe<Tenant_Bank_Accounts_Sum_Fields>;
  var_pop?: Maybe<Tenant_Bank_Accounts_Var_Pop_Fields>;
  var_samp?: Maybe<Tenant_Bank_Accounts_Var_Samp_Fields>;
  variance?: Maybe<Tenant_Bank_Accounts_Variance_Fields>;
};


/** aggregate fields of "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenant_Bank_Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_Aggregate_Order_By = {
  avg?: InputMaybe<Tenant_Bank_Accounts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenant_Bank_Accounts_Max_Order_By>;
  min?: InputMaybe<Tenant_Bank_Accounts_Min_Order_By>;
  stddev?: InputMaybe<Tenant_Bank_Accounts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tenant_Bank_Accounts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tenant_Bank_Accounts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tenant_Bank_Accounts_Sum_Order_By>;
  var_pop?: InputMaybe<Tenant_Bank_Accounts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tenant_Bank_Accounts_Var_Samp_Order_By>;
  variance?: InputMaybe<Tenant_Bank_Accounts_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenant_Bank_Accounts_Append_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Tenant_Bank_Accounts_Avg_Fields = {
  __typename: 'tenant_bank_accounts_avg_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tenant_bank_accounts". All fields are combined with a logical 'AND'. */
export type Tenant_Bank_Accounts_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Tenant_Bank_Accounts_Bool_Exp>>;
  _not?: InputMaybe<Tenant_Bank_Accounts_Bool_Exp>;
  _or?: InputMaybe<Array<Tenant_Bank_Accounts_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerBillingSettings?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
  customerBillingSettings_aggregate?: InputMaybe<Customer_Billing_Settings_Aggregate_Bool_Exp>;
  i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  iban?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ordinalPosition?: InputMaybe<Int_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenant_bank_accounts" */
export enum Tenant_Bank_Accounts_Constraint {
  /** unique or primary key constraint on columns "id" */
  TenantBankAccountsPkey = 'tenant_bank_accounts_pkey',
  /** unique or primary key constraint on columns "name", "tenant" */
  TenantBankAccountsTenantNameKey = 'tenant_bank_accounts_tenant_name_key',
  /** unique or primary key constraint on columns "tenant", "ordinal_position" */
  TenantBankAccountsTenantOrdinalPositionKey = 'tenant_bank_accounts_tenant_ordinal_position_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenant_Bank_Accounts_Delete_At_Path_Input = {
  i18n?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenant_Bank_Accounts_Delete_Elem_Input = {
  i18n?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenant_Bank_Accounts_Delete_Key_Input = {
  i18n?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_Insert_Input = {
  customerBillingSettings?: InputMaybe<Customer_Billing_Settings_Arr_Rel_Insert_Input>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  iban?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Tenant_Bank_Accounts_Max_Fields = {
  __typename: 'tenant_bank_accounts_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  iban?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenant_Bank_Accounts_Min_Fields = {
  __typename: 'tenant_bank_accounts_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  iban?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_Mutation_Response = {
  __typename: 'tenant_bank_accounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenant_Bank_Accounts>;
};

/** input type for inserting object relation for remote table "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_Obj_Rel_Insert_Input = {
  data: Tenant_Bank_Accounts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenant_Bank_Accounts_On_Conflict>;
};

/** on_conflict condition type for table "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_On_Conflict = {
  constraint: Tenant_Bank_Accounts_Constraint;
  update_columns?: Array<Tenant_Bank_Accounts_Update_Column>;
  where?: InputMaybe<Tenant_Bank_Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "tenant_bank_accounts". */
export type Tenant_Bank_Accounts_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerBillingSettings_aggregate?: InputMaybe<Customer_Billing_Settings_Aggregate_Order_By>;
  i18n?: InputMaybe<Order_By>;
  iban?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenant_bank_accounts */
export type Tenant_Bank_Accounts_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenant_Bank_Accounts_Prepend_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "tenant_bank_accounts" */
export enum Tenant_Bank_Accounts_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  I18n = 'i18n',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_Set_Input = {
  i18n?: InputMaybe<Scalars['jsonb']>;
  iban?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Tenant_Bank_Accounts_Stddev_Fields = {
  __typename: 'tenant_bank_accounts_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tenant_Bank_Accounts_Stddev_Pop_Fields = {
  __typename: 'tenant_bank_accounts_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tenant_Bank_Accounts_Stddev_Samp_Fields = {
  __typename: 'tenant_bank_accounts_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Bank_Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenant_Bank_Accounts_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  iban?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  ordinalPosition?: InputMaybe<Scalars['Int']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Tenant_Bank_Accounts_Sum_Fields = {
  __typename: 'tenant_bank_accounts_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  ordinalPosition?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "tenant_bank_accounts" */
export enum Tenant_Bank_Accounts_Update_Column {
  /** column name */
  I18n = 'i18n',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrdinalPosition = 'ordinalPosition',
  /** column name */
  Version = 'version'
}

export type Tenant_Bank_Accounts_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenant_Bank_Accounts_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenant_Bank_Accounts_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenant_Bank_Accounts_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenant_Bank_Accounts_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tenant_Bank_Accounts_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenant_Bank_Accounts_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenant_Bank_Accounts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenant_Bank_Accounts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tenant_Bank_Accounts_Var_Pop_Fields = {
  __typename: 'tenant_bank_accounts_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tenant_Bank_Accounts_Var_Samp_Fields = {
  __typename: 'tenant_bank_accounts_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tenant_Bank_Accounts_Variance_Fields = {
  __typename: 'tenant_bank_accounts_variance_fields';
  id?: Maybe<Scalars['Float']>;
  ordinalPosition?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tenant_bank_accounts" */
export type Tenant_Bank_Accounts_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  ordinalPosition?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "tenant_company_details" */
export type Tenant_Company_Details = {
  __typename: 'tenant_company_details';
  /** An object relationship */
  Tenant: Tenants;
  bankAddress?: Maybe<Scalars['String']>;
  bankCustomerId: Scalars['String'];
  bankName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  email?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  gln?: Maybe<Scalars['bigint']>;
  gs1CompanyPrefix?: Maybe<Scalars['bigint']>;
  gs1ExtensionDigit: Scalars['smallint'];
  i18n: Scalars['jsonb'];
  id: Scalars['bigint'];
  phoneNumber?: Maybe<Scalars['String']>;
  postAccountNumber?: Maybe<Scalars['String']>;
  tenant: Scalars['String'];
  /** An array relationship */
  tenants: Array<Tenants>;
  /** An aggregate relationship */
  tenants_aggregate: Tenants_Aggregate;
  updatedAt: Scalars['timestamptz'];
  vatCompanyId?: Maybe<Scalars['String']>;
  vatRegistrationNumber?: Maybe<Scalars['String']>;
  version: Scalars['bigint'];
  websiteUrl: Scalars['String'];
};


/** columns and relationships of "tenant_company_details" */
export type Tenant_Company_DetailsI18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "tenant_company_details" */
export type Tenant_Company_DetailsTenantsArgs = {
  distinct_on?: InputMaybe<Array<Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenants_Order_By>>;
  where?: InputMaybe<Tenants_Bool_Exp>;
};


/** columns and relationships of "tenant_company_details" */
export type Tenant_Company_DetailsTenants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenants_Order_By>>;
  where?: InputMaybe<Tenants_Bool_Exp>;
};

/** aggregated selection of "tenant_company_details" */
export type Tenant_Company_Details_Aggregate = {
  __typename: 'tenant_company_details_aggregate';
  aggregate?: Maybe<Tenant_Company_Details_Aggregate_Fields>;
  nodes: Array<Tenant_Company_Details>;
};

export type Tenant_Company_Details_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenant_Company_Details_Aggregate_Bool_Exp_Count>;
};

export type Tenant_Company_Details_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenant_Company_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Company_Details_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenant_company_details" */
export type Tenant_Company_Details_Aggregate_Fields = {
  __typename: 'tenant_company_details_aggregate_fields';
  avg?: Maybe<Tenant_Company_Details_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tenant_Company_Details_Max_Fields>;
  min?: Maybe<Tenant_Company_Details_Min_Fields>;
  stddev?: Maybe<Tenant_Company_Details_Stddev_Fields>;
  stddev_pop?: Maybe<Tenant_Company_Details_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tenant_Company_Details_Stddev_Samp_Fields>;
  sum?: Maybe<Tenant_Company_Details_Sum_Fields>;
  var_pop?: Maybe<Tenant_Company_Details_Var_Pop_Fields>;
  var_samp?: Maybe<Tenant_Company_Details_Var_Samp_Fields>;
  variance?: Maybe<Tenant_Company_Details_Variance_Fields>;
};


/** aggregate fields of "tenant_company_details" */
export type Tenant_Company_Details_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenant_Company_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tenant_company_details" */
export type Tenant_Company_Details_Aggregate_Order_By = {
  avg?: InputMaybe<Tenant_Company_Details_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenant_Company_Details_Max_Order_By>;
  min?: InputMaybe<Tenant_Company_Details_Min_Order_By>;
  stddev?: InputMaybe<Tenant_Company_Details_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tenant_Company_Details_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tenant_Company_Details_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tenant_Company_Details_Sum_Order_By>;
  var_pop?: InputMaybe<Tenant_Company_Details_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tenant_Company_Details_Var_Samp_Order_By>;
  variance?: InputMaybe<Tenant_Company_Details_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Tenant_Company_Details_Avg_Fields = {
  __typename: 'tenant_company_details_avg_fields';
  gln?: Maybe<Scalars['Float']>;
  gs1CompanyPrefix?: Maybe<Scalars['Float']>;
  gs1ExtensionDigit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tenant_company_details" */
export type Tenant_Company_Details_Avg_Order_By = {
  gln?: InputMaybe<Order_By>;
  gs1CompanyPrefix?: InputMaybe<Order_By>;
  gs1ExtensionDigit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tenant_company_details". All fields are combined with a logical 'AND'. */
export type Tenant_Company_Details_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Tenant_Company_Details_Bool_Exp>>;
  _not?: InputMaybe<Tenant_Company_Details_Bool_Exp>;
  _or?: InputMaybe<Array<Tenant_Company_Details_Bool_Exp>>;
  bankAddress?: InputMaybe<String_Comparison_Exp>;
  bankCustomerId?: InputMaybe<String_Comparison_Exp>;
  bankName?: InputMaybe<String_Comparison_Exp>;
  companyName?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  faxNumber?: InputMaybe<String_Comparison_Exp>;
  gln?: InputMaybe<Bigint_Comparison_Exp>;
  gs1CompanyPrefix?: InputMaybe<Bigint_Comparison_Exp>;
  gs1ExtensionDigit?: InputMaybe<Smallint_Comparison_Exp>;
  i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  postAccountNumber?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  tenants?: InputMaybe<Tenants_Bool_Exp>;
  tenants_aggregate?: InputMaybe<Tenants_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  vatCompanyId?: InputMaybe<String_Comparison_Exp>;
  vatRegistrationNumber?: InputMaybe<String_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
  websiteUrl?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Tenant_Company_Details_Max_Fields = {
  __typename: 'tenant_company_details_max_fields';
  bankAddress?: Maybe<Scalars['String']>;
  bankCustomerId?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  gln?: Maybe<Scalars['bigint']>;
  gs1CompanyPrefix?: Maybe<Scalars['bigint']>;
  gs1ExtensionDigit?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['bigint']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postAccountNumber?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vatCompanyId?: Maybe<Scalars['String']>;
  vatRegistrationNumber?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['bigint']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "tenant_company_details" */
export type Tenant_Company_Details_Max_Order_By = {
  bankAddress?: InputMaybe<Order_By>;
  bankCustomerId?: InputMaybe<Order_By>;
  bankName?: InputMaybe<Order_By>;
  companyName?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  faxNumber?: InputMaybe<Order_By>;
  gln?: InputMaybe<Order_By>;
  gs1CompanyPrefix?: InputMaybe<Order_By>;
  gs1ExtensionDigit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  postAccountNumber?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vatCompanyId?: InputMaybe<Order_By>;
  vatRegistrationNumber?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  websiteUrl?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenant_Company_Details_Min_Fields = {
  __typename: 'tenant_company_details_min_fields';
  bankAddress?: Maybe<Scalars['String']>;
  bankCustomerId?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  gln?: Maybe<Scalars['bigint']>;
  gs1CompanyPrefix?: Maybe<Scalars['bigint']>;
  gs1ExtensionDigit?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['bigint']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postAccountNumber?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vatCompanyId?: Maybe<Scalars['String']>;
  vatRegistrationNumber?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['bigint']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "tenant_company_details" */
export type Tenant_Company_Details_Min_Order_By = {
  bankAddress?: InputMaybe<Order_By>;
  bankCustomerId?: InputMaybe<Order_By>;
  bankName?: InputMaybe<Order_By>;
  companyName?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  faxNumber?: InputMaybe<Order_By>;
  gln?: InputMaybe<Order_By>;
  gs1CompanyPrefix?: InputMaybe<Order_By>;
  gs1ExtensionDigit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  postAccountNumber?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vatCompanyId?: InputMaybe<Order_By>;
  vatRegistrationNumber?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  websiteUrl?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "tenant_company_details". */
export type Tenant_Company_Details_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  bankAddress?: InputMaybe<Order_By>;
  bankCustomerId?: InputMaybe<Order_By>;
  bankName?: InputMaybe<Order_By>;
  companyName?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  faxNumber?: InputMaybe<Order_By>;
  gln?: InputMaybe<Order_By>;
  gs1CompanyPrefix?: InputMaybe<Order_By>;
  gs1ExtensionDigit?: InputMaybe<Order_By>;
  i18n?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  postAccountNumber?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  tenants_aggregate?: InputMaybe<Tenants_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vatCompanyId?: InputMaybe<Order_By>;
  vatRegistrationNumber?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  websiteUrl?: InputMaybe<Order_By>;
};

/** select columns of table "tenant_company_details" */
export enum Tenant_Company_Details_Select_Column {
  /** column name */
  BankAddress = 'bankAddress',
  /** column name */
  BankCustomerId = 'bankCustomerId',
  /** column name */
  BankName = 'bankName',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FaxNumber = 'faxNumber',
  /** column name */
  Gln = 'gln',
  /** column name */
  Gs1CompanyPrefix = 'gs1CompanyPrefix',
  /** column name */
  Gs1ExtensionDigit = 'gs1ExtensionDigit',
  /** column name */
  I18n = 'i18n',
  /** column name */
  Id = 'id',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PostAccountNumber = 'postAccountNumber',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VatCompanyId = 'vatCompanyId',
  /** column name */
  VatRegistrationNumber = 'vatRegistrationNumber',
  /** column name */
  Version = 'version',
  /** column name */
  WebsiteUrl = 'websiteUrl'
}

/** aggregate stddev on columns */
export type Tenant_Company_Details_Stddev_Fields = {
  __typename: 'tenant_company_details_stddev_fields';
  gln?: Maybe<Scalars['Float']>;
  gs1CompanyPrefix?: Maybe<Scalars['Float']>;
  gs1ExtensionDigit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tenant_company_details" */
export type Tenant_Company_Details_Stddev_Order_By = {
  gln?: InputMaybe<Order_By>;
  gs1CompanyPrefix?: InputMaybe<Order_By>;
  gs1ExtensionDigit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tenant_Company_Details_Stddev_Pop_Fields = {
  __typename: 'tenant_company_details_stddev_pop_fields';
  gln?: Maybe<Scalars['Float']>;
  gs1CompanyPrefix?: Maybe<Scalars['Float']>;
  gs1ExtensionDigit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tenant_company_details" */
export type Tenant_Company_Details_Stddev_Pop_Order_By = {
  gln?: InputMaybe<Order_By>;
  gs1CompanyPrefix?: InputMaybe<Order_By>;
  gs1ExtensionDigit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tenant_Company_Details_Stddev_Samp_Fields = {
  __typename: 'tenant_company_details_stddev_samp_fields';
  gln?: Maybe<Scalars['Float']>;
  gs1CompanyPrefix?: Maybe<Scalars['Float']>;
  gs1ExtensionDigit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tenant_company_details" */
export type Tenant_Company_Details_Stddev_Samp_Order_By = {
  gln?: InputMaybe<Order_By>;
  gs1CompanyPrefix?: InputMaybe<Order_By>;
  gs1ExtensionDigit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tenant_company_details" */
export type Tenant_Company_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Company_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenant_Company_Details_Stream_Cursor_Value_Input = {
  bankAddress?: InputMaybe<Scalars['String']>;
  bankCustomerId?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  faxNumber?: InputMaybe<Scalars['String']>;
  gln?: InputMaybe<Scalars['bigint']>;
  gs1CompanyPrefix?: InputMaybe<Scalars['bigint']>;
  gs1ExtensionDigit?: InputMaybe<Scalars['smallint']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postAccountNumber?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vatCompanyId?: InputMaybe<Scalars['String']>;
  vatRegistrationNumber?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Tenant_Company_Details_Sum_Fields = {
  __typename: 'tenant_company_details_sum_fields';
  gln?: Maybe<Scalars['bigint']>;
  gs1CompanyPrefix?: Maybe<Scalars['bigint']>;
  gs1ExtensionDigit?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "tenant_company_details" */
export type Tenant_Company_Details_Sum_Order_By = {
  gln?: InputMaybe<Order_By>;
  gs1CompanyPrefix?: InputMaybe<Order_By>;
  gs1ExtensionDigit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Tenant_Company_Details_Var_Pop_Fields = {
  __typename: 'tenant_company_details_var_pop_fields';
  gln?: Maybe<Scalars['Float']>;
  gs1CompanyPrefix?: Maybe<Scalars['Float']>;
  gs1ExtensionDigit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tenant_company_details" */
export type Tenant_Company_Details_Var_Pop_Order_By = {
  gln?: InputMaybe<Order_By>;
  gs1CompanyPrefix?: InputMaybe<Order_By>;
  gs1ExtensionDigit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tenant_Company_Details_Var_Samp_Fields = {
  __typename: 'tenant_company_details_var_samp_fields';
  gln?: Maybe<Scalars['Float']>;
  gs1CompanyPrefix?: Maybe<Scalars['Float']>;
  gs1ExtensionDigit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tenant_company_details" */
export type Tenant_Company_Details_Var_Samp_Order_By = {
  gln?: InputMaybe<Order_By>;
  gs1CompanyPrefix?: InputMaybe<Order_By>;
  gs1ExtensionDigit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tenant_Company_Details_Variance_Fields = {
  __typename: 'tenant_company_details_variance_fields';
  gln?: Maybe<Scalars['Float']>;
  gs1CompanyPrefix?: Maybe<Scalars['Float']>;
  gs1ExtensionDigit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tenant_company_details" */
export type Tenant_Company_Details_Variance_Order_By = {
  gln?: InputMaybe<Order_By>;
  gs1CompanyPrefix?: InputMaybe<Order_By>;
  gs1ExtensionDigit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "tenant_configurations" */
export type Tenant_Configurations = {
  __typename: 'tenant_configurations';
  /** An object relationship */
  Tenant: Tenants;
  /** An object relationship */
  containerStockLocation: Stock_Locations;
  containerStockLocationId: Scalars['bigint'];
  customerOrderDeliveryDateOffset: Scalars['smallint'];
  tenant: Scalars['String'];
};

/** aggregated selection of "tenant_configurations" */
export type Tenant_Configurations_Aggregate = {
  __typename: 'tenant_configurations_aggregate';
  aggregate?: Maybe<Tenant_Configurations_Aggregate_Fields>;
  nodes: Array<Tenant_Configurations>;
};

export type Tenant_Configurations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenant_Configurations_Aggregate_Bool_Exp_Count>;
};

export type Tenant_Configurations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenant_Configurations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Configurations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenant_configurations" */
export type Tenant_Configurations_Aggregate_Fields = {
  __typename: 'tenant_configurations_aggregate_fields';
  avg?: Maybe<Tenant_Configurations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tenant_Configurations_Max_Fields>;
  min?: Maybe<Tenant_Configurations_Min_Fields>;
  stddev?: Maybe<Tenant_Configurations_Stddev_Fields>;
  stddev_pop?: Maybe<Tenant_Configurations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tenant_Configurations_Stddev_Samp_Fields>;
  sum?: Maybe<Tenant_Configurations_Sum_Fields>;
  var_pop?: Maybe<Tenant_Configurations_Var_Pop_Fields>;
  var_samp?: Maybe<Tenant_Configurations_Var_Samp_Fields>;
  variance?: Maybe<Tenant_Configurations_Variance_Fields>;
};


/** aggregate fields of "tenant_configurations" */
export type Tenant_Configurations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenant_Configurations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tenant_configurations" */
export type Tenant_Configurations_Aggregate_Order_By = {
  avg?: InputMaybe<Tenant_Configurations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenant_Configurations_Max_Order_By>;
  min?: InputMaybe<Tenant_Configurations_Min_Order_By>;
  stddev?: InputMaybe<Tenant_Configurations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tenant_Configurations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tenant_Configurations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tenant_Configurations_Sum_Order_By>;
  var_pop?: InputMaybe<Tenant_Configurations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tenant_Configurations_Var_Samp_Order_By>;
  variance?: InputMaybe<Tenant_Configurations_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Tenant_Configurations_Avg_Fields = {
  __typename: 'tenant_configurations_avg_fields';
  containerStockLocationId?: Maybe<Scalars['Float']>;
  customerOrderDeliveryDateOffset?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tenant_configurations" */
export type Tenant_Configurations_Avg_Order_By = {
  containerStockLocationId?: InputMaybe<Order_By>;
  customerOrderDeliveryDateOffset?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tenant_configurations". All fields are combined with a logical 'AND'. */
export type Tenant_Configurations_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Tenant_Configurations_Bool_Exp>>;
  _not?: InputMaybe<Tenant_Configurations_Bool_Exp>;
  _or?: InputMaybe<Array<Tenant_Configurations_Bool_Exp>>;
  containerStockLocation?: InputMaybe<Stock_Locations_Bool_Exp>;
  containerStockLocationId?: InputMaybe<Bigint_Comparison_Exp>;
  customerOrderDeliveryDateOffset?: InputMaybe<Smallint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Tenant_Configurations_Max_Fields = {
  __typename: 'tenant_configurations_max_fields';
  containerStockLocationId?: Maybe<Scalars['bigint']>;
  customerOrderDeliveryDateOffset?: Maybe<Scalars['smallint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "tenant_configurations" */
export type Tenant_Configurations_Max_Order_By = {
  containerStockLocationId?: InputMaybe<Order_By>;
  customerOrderDeliveryDateOffset?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenant_Configurations_Min_Fields = {
  __typename: 'tenant_configurations_min_fields';
  containerStockLocationId?: Maybe<Scalars['bigint']>;
  customerOrderDeliveryDateOffset?: Maybe<Scalars['smallint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "tenant_configurations" */
export type Tenant_Configurations_Min_Order_By = {
  containerStockLocationId?: InputMaybe<Order_By>;
  customerOrderDeliveryDateOffset?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "tenant_configurations". */
export type Tenant_Configurations_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  containerStockLocation?: InputMaybe<Stock_Locations_Order_By>;
  containerStockLocationId?: InputMaybe<Order_By>;
  customerOrderDeliveryDateOffset?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** select columns of table "tenant_configurations" */
export enum Tenant_Configurations_Select_Column {
  /** column name */
  ContainerStockLocationId = 'containerStockLocationId',
  /** column name */
  CustomerOrderDeliveryDateOffset = 'customerOrderDeliveryDateOffset',
  /** column name */
  Tenant = 'tenant'
}

/** aggregate stddev on columns */
export type Tenant_Configurations_Stddev_Fields = {
  __typename: 'tenant_configurations_stddev_fields';
  containerStockLocationId?: Maybe<Scalars['Float']>;
  customerOrderDeliveryDateOffset?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tenant_configurations" */
export type Tenant_Configurations_Stddev_Order_By = {
  containerStockLocationId?: InputMaybe<Order_By>;
  customerOrderDeliveryDateOffset?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tenant_Configurations_Stddev_Pop_Fields = {
  __typename: 'tenant_configurations_stddev_pop_fields';
  containerStockLocationId?: Maybe<Scalars['Float']>;
  customerOrderDeliveryDateOffset?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tenant_configurations" */
export type Tenant_Configurations_Stddev_Pop_Order_By = {
  containerStockLocationId?: InputMaybe<Order_By>;
  customerOrderDeliveryDateOffset?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tenant_Configurations_Stddev_Samp_Fields = {
  __typename: 'tenant_configurations_stddev_samp_fields';
  containerStockLocationId?: Maybe<Scalars['Float']>;
  customerOrderDeliveryDateOffset?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tenant_configurations" */
export type Tenant_Configurations_Stddev_Samp_Order_By = {
  containerStockLocationId?: InputMaybe<Order_By>;
  customerOrderDeliveryDateOffset?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tenant_configurations" */
export type Tenant_Configurations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Configurations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenant_Configurations_Stream_Cursor_Value_Input = {
  containerStockLocationId?: InputMaybe<Scalars['bigint']>;
  customerOrderDeliveryDateOffset?: InputMaybe<Scalars['smallint']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Tenant_Configurations_Sum_Fields = {
  __typename: 'tenant_configurations_sum_fields';
  containerStockLocationId?: Maybe<Scalars['bigint']>;
  customerOrderDeliveryDateOffset?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "tenant_configurations" */
export type Tenant_Configurations_Sum_Order_By = {
  containerStockLocationId?: InputMaybe<Order_By>;
  customerOrderDeliveryDateOffset?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Tenant_Configurations_Var_Pop_Fields = {
  __typename: 'tenant_configurations_var_pop_fields';
  containerStockLocationId?: Maybe<Scalars['Float']>;
  customerOrderDeliveryDateOffset?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tenant_configurations" */
export type Tenant_Configurations_Var_Pop_Order_By = {
  containerStockLocationId?: InputMaybe<Order_By>;
  customerOrderDeliveryDateOffset?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tenant_Configurations_Var_Samp_Fields = {
  __typename: 'tenant_configurations_var_samp_fields';
  containerStockLocationId?: Maybe<Scalars['Float']>;
  customerOrderDeliveryDateOffset?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tenant_configurations" */
export type Tenant_Configurations_Var_Samp_Order_By = {
  containerStockLocationId?: InputMaybe<Order_By>;
  customerOrderDeliveryDateOffset?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tenant_Configurations_Variance_Fields = {
  __typename: 'tenant_configurations_variance_fields';
  containerStockLocationId?: Maybe<Scalars['Float']>;
  customerOrderDeliveryDateOffset?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tenant_configurations" */
export type Tenant_Configurations_Variance_Order_By = {
  containerStockLocationId?: InputMaybe<Order_By>;
  customerOrderDeliveryDateOffset?: InputMaybe<Order_By>;
};

/** columns and relationships of "tenant_info_with_token_result" */
export type Tenant_Info_With_Token_Result = {
  __typename: 'tenant_info_with_token_result';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCodeAlpha2?: Maybe<Scalars['String']>;
  i18n: Scalars['jsonb'];
  logoUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  tenantName: Scalars['String'];
  zip?: Maybe<Scalars['String']>;
};


/** columns and relationships of "tenant_info_with_token_result" */
export type Tenant_Info_With_Token_ResultI18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Tenant_Info_With_Token_Result_Aggregate = {
  __typename: 'tenant_info_with_token_result_aggregate';
  aggregate?: Maybe<Tenant_Info_With_Token_Result_Aggregate_Fields>;
  nodes: Array<Tenant_Info_With_Token_Result>;
};

/** aggregate fields of "tenant_info_with_token_result" */
export type Tenant_Info_With_Token_Result_Aggregate_Fields = {
  __typename: 'tenant_info_with_token_result_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tenant_Info_With_Token_Result_Max_Fields>;
  min?: Maybe<Tenant_Info_With_Token_Result_Min_Fields>;
};


/** aggregate fields of "tenant_info_with_token_result" */
export type Tenant_Info_With_Token_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenant_Info_With_Token_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tenant_info_with_token_result". All fields are combined with a logical 'AND'. */
export type Tenant_Info_With_Token_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Tenant_Info_With_Token_Result_Bool_Exp>>;
  _not?: InputMaybe<Tenant_Info_With_Token_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Tenant_Info_With_Token_Result_Bool_Exp>>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  countryCodeAlpha2?: InputMaybe<String_Comparison_Exp>;
  i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  logoUrl?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  street1?: InputMaybe<String_Comparison_Exp>;
  street2?: InputMaybe<String_Comparison_Exp>;
  tenantName?: InputMaybe<String_Comparison_Exp>;
  zip?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Tenant_Info_With_Token_Result_Max_Fields = {
  __typename: 'tenant_info_with_token_result_max_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCodeAlpha2?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  tenantName?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Tenant_Info_With_Token_Result_Min_Fields = {
  __typename: 'tenant_info_with_token_result_min_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCodeAlpha2?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  tenantName?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "tenant_info_with_token_result". */
export type Tenant_Info_With_Token_Result_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  countryCodeAlpha2?: InputMaybe<Order_By>;
  i18n?: InputMaybe<Order_By>;
  logoUrl?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street1?: InputMaybe<Order_By>;
  street2?: InputMaybe<Order_By>;
  tenantName?: InputMaybe<Order_By>;
  zip?: InputMaybe<Order_By>;
};

/** select columns of table "tenant_info_with_token_result" */
export enum Tenant_Info_With_Token_Result_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CountryCodeAlpha2 = 'countryCodeAlpha2',
  /** column name */
  I18n = 'i18n',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  State = 'state',
  /** column name */
  Street1 = 'street1',
  /** column name */
  Street2 = 'street2',
  /** column name */
  TenantName = 'tenantName',
  /** column name */
  Zip = 'zip'
}

/** Streaming cursor of the table "tenant_info_with_token_result" */
export type Tenant_Info_With_Token_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Info_With_Token_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenant_Info_With_Token_Result_Stream_Cursor_Value_Input = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  countryCodeAlpha2?: InputMaybe<Scalars['String']>;
  i18n?: InputMaybe<Scalars['jsonb']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  tenantName?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "tenant_languages" */
export type Tenant_Languages = {
  __typename: 'tenant_languages';
  /** An object relationship */
  Tenant: Tenants;
  id: Scalars['bigint'];
  language: Languages_Enum;
  tenant: Scalars['String'];
  /** An array relationship */
  tenants: Array<Tenants>;
  /** An aggregate relationship */
  tenants_aggregate: Tenants_Aggregate;
};


/** columns and relationships of "tenant_languages" */
export type Tenant_LanguagesTenantsArgs = {
  distinct_on?: InputMaybe<Array<Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenants_Order_By>>;
  where?: InputMaybe<Tenants_Bool_Exp>;
};


/** columns and relationships of "tenant_languages" */
export type Tenant_LanguagesTenants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenants_Order_By>>;
  where?: InputMaybe<Tenants_Bool_Exp>;
};

/** aggregated selection of "tenant_languages" */
export type Tenant_Languages_Aggregate = {
  __typename: 'tenant_languages_aggregate';
  aggregate?: Maybe<Tenant_Languages_Aggregate_Fields>;
  nodes: Array<Tenant_Languages>;
};

export type Tenant_Languages_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenant_Languages_Aggregate_Bool_Exp_Count>;
};

export type Tenant_Languages_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenant_Languages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Languages_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenant_languages" */
export type Tenant_Languages_Aggregate_Fields = {
  __typename: 'tenant_languages_aggregate_fields';
  avg?: Maybe<Tenant_Languages_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tenant_Languages_Max_Fields>;
  min?: Maybe<Tenant_Languages_Min_Fields>;
  stddev?: Maybe<Tenant_Languages_Stddev_Fields>;
  stddev_pop?: Maybe<Tenant_Languages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tenant_Languages_Stddev_Samp_Fields>;
  sum?: Maybe<Tenant_Languages_Sum_Fields>;
  var_pop?: Maybe<Tenant_Languages_Var_Pop_Fields>;
  var_samp?: Maybe<Tenant_Languages_Var_Samp_Fields>;
  variance?: Maybe<Tenant_Languages_Variance_Fields>;
};


/** aggregate fields of "tenant_languages" */
export type Tenant_Languages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenant_Languages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tenant_languages" */
export type Tenant_Languages_Aggregate_Order_By = {
  avg?: InputMaybe<Tenant_Languages_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenant_Languages_Max_Order_By>;
  min?: InputMaybe<Tenant_Languages_Min_Order_By>;
  stddev?: InputMaybe<Tenant_Languages_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tenant_Languages_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tenant_Languages_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tenant_Languages_Sum_Order_By>;
  var_pop?: InputMaybe<Tenant_Languages_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tenant_Languages_Var_Samp_Order_By>;
  variance?: InputMaybe<Tenant_Languages_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Tenant_Languages_Avg_Fields = {
  __typename: 'tenant_languages_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tenant_languages" */
export type Tenant_Languages_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tenant_languages". All fields are combined with a logical 'AND'. */
export type Tenant_Languages_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Tenant_Languages_Bool_Exp>>;
  _not?: InputMaybe<Tenant_Languages_Bool_Exp>;
  _or?: InputMaybe<Array<Tenant_Languages_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  language?: InputMaybe<Languages_Enum_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  tenants?: InputMaybe<Tenants_Bool_Exp>;
  tenants_aggregate?: InputMaybe<Tenants_Aggregate_Bool_Exp>;
};

/** aggregate max on columns */
export type Tenant_Languages_Max_Fields = {
  __typename: 'tenant_languages_max_fields';
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "tenant_languages" */
export type Tenant_Languages_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenant_Languages_Min_Fields = {
  __typename: 'tenant_languages_min_fields';
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "tenant_languages" */
export type Tenant_Languages_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "tenant_languages". */
export type Tenant_Languages_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  tenants_aggregate?: InputMaybe<Tenants_Aggregate_Order_By>;
};

/** select columns of table "tenant_languages" */
export enum Tenant_Languages_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Tenant = 'tenant'
}

/** aggregate stddev on columns */
export type Tenant_Languages_Stddev_Fields = {
  __typename: 'tenant_languages_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tenant_languages" */
export type Tenant_Languages_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tenant_Languages_Stddev_Pop_Fields = {
  __typename: 'tenant_languages_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tenant_languages" */
export type Tenant_Languages_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tenant_Languages_Stddev_Samp_Fields = {
  __typename: 'tenant_languages_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tenant_languages" */
export type Tenant_Languages_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tenant_languages" */
export type Tenant_Languages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Languages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenant_Languages_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  language?: InputMaybe<Languages_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Tenant_Languages_Sum_Fields = {
  __typename: 'tenant_languages_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "tenant_languages" */
export type Tenant_Languages_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Tenant_Languages_Var_Pop_Fields = {
  __typename: 'tenant_languages_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tenant_languages" */
export type Tenant_Languages_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tenant_Languages_Var_Samp_Fields = {
  __typename: 'tenant_languages_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tenant_languages" */
export type Tenant_Languages_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tenant_Languages_Variance_Fields = {
  __typename: 'tenant_languages_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tenant_languages" */
export type Tenant_Languages_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "tenant_permissions" */
export type Tenant_Permissions = {
  __typename: 'tenant_permissions';
  /** An object relationship */
  Tenant: Tenants;
  action: Scalars['String'];
  conditions?: Maybe<Scalars['jsonb']>;
  fields?: Maybe<Scalars['_text']>;
  id: Scalars['bigint'];
  inverted?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  tenant: Scalars['String'];
};


/** columns and relationships of "tenant_permissions" */
export type Tenant_PermissionsConditionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "tenant_permissions" */
export type Tenant_Permissions_Aggregate = {
  __typename: 'tenant_permissions_aggregate';
  aggregate?: Maybe<Tenant_Permissions_Aggregate_Fields>;
  nodes: Array<Tenant_Permissions>;
};

export type Tenant_Permissions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tenant_Permissions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tenant_Permissions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tenant_Permissions_Aggregate_Bool_Exp_Count>;
};

export type Tenant_Permissions_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tenant_Permissions_Select_Column_Tenant_Permissions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Permissions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenant_Permissions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tenant_Permissions_Select_Column_Tenant_Permissions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Permissions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenant_Permissions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenant_Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Permissions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenant_permissions" */
export type Tenant_Permissions_Aggregate_Fields = {
  __typename: 'tenant_permissions_aggregate_fields';
  avg?: Maybe<Tenant_Permissions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tenant_Permissions_Max_Fields>;
  min?: Maybe<Tenant_Permissions_Min_Fields>;
  stddev?: Maybe<Tenant_Permissions_Stddev_Fields>;
  stddev_pop?: Maybe<Tenant_Permissions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tenant_Permissions_Stddev_Samp_Fields>;
  sum?: Maybe<Tenant_Permissions_Sum_Fields>;
  var_pop?: Maybe<Tenant_Permissions_Var_Pop_Fields>;
  var_samp?: Maybe<Tenant_Permissions_Var_Samp_Fields>;
  variance?: Maybe<Tenant_Permissions_Variance_Fields>;
};


/** aggregate fields of "tenant_permissions" */
export type Tenant_Permissions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenant_Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tenant_permissions" */
export type Tenant_Permissions_Aggregate_Order_By = {
  avg?: InputMaybe<Tenant_Permissions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenant_Permissions_Max_Order_By>;
  min?: InputMaybe<Tenant_Permissions_Min_Order_By>;
  stddev?: InputMaybe<Tenant_Permissions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tenant_Permissions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tenant_Permissions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tenant_Permissions_Sum_Order_By>;
  var_pop?: InputMaybe<Tenant_Permissions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tenant_Permissions_Var_Samp_Order_By>;
  variance?: InputMaybe<Tenant_Permissions_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Tenant_Permissions_Avg_Fields = {
  __typename: 'tenant_permissions_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tenant_permissions" */
export type Tenant_Permissions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tenant_permissions". All fields are combined with a logical 'AND'. */
export type Tenant_Permissions_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Tenant_Permissions_Bool_Exp>>;
  _not?: InputMaybe<Tenant_Permissions_Bool_Exp>;
  _or?: InputMaybe<Array<Tenant_Permissions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  conditions?: InputMaybe<Jsonb_Comparison_Exp>;
  fields?: InputMaybe<_Text_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  inverted?: InputMaybe<Boolean_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Tenant_Permissions_Max_Fields = {
  __typename: 'tenant_permissions_max_fields';
  action?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  reason?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "tenant_permissions" */
export type Tenant_Permissions_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenant_Permissions_Min_Fields = {
  __typename: 'tenant_permissions_min_fields';
  action?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  reason?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "tenant_permissions" */
export type Tenant_Permissions_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "tenant_permissions". */
export type Tenant_Permissions_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  action?: InputMaybe<Order_By>;
  conditions?: InputMaybe<Order_By>;
  fields?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inverted?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
};

/** select columns of table "tenant_permissions" */
export enum Tenant_Permissions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  Conditions = 'conditions',
  /** column name */
  Fields = 'fields',
  /** column name */
  Id = 'id',
  /** column name */
  Inverted = 'inverted',
  /** column name */
  Reason = 'reason',
  /** column name */
  Subject = 'subject',
  /** column name */
  Tenant = 'tenant'
}

/** select "tenant_permissions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tenant_permissions" */
export enum Tenant_Permissions_Select_Column_Tenant_Permissions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Inverted = 'inverted'
}

/** select "tenant_permissions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tenant_permissions" */
export enum Tenant_Permissions_Select_Column_Tenant_Permissions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Inverted = 'inverted'
}

/** aggregate stddev on columns */
export type Tenant_Permissions_Stddev_Fields = {
  __typename: 'tenant_permissions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tenant_permissions" */
export type Tenant_Permissions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tenant_Permissions_Stddev_Pop_Fields = {
  __typename: 'tenant_permissions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tenant_permissions" */
export type Tenant_Permissions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tenant_Permissions_Stddev_Samp_Fields = {
  __typename: 'tenant_permissions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tenant_permissions" */
export type Tenant_Permissions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tenant_permissions" */
export type Tenant_Permissions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Permissions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenant_Permissions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  conditions?: InputMaybe<Scalars['jsonb']>;
  fields?: InputMaybe<Scalars['_text']>;
  id?: InputMaybe<Scalars['bigint']>;
  inverted?: InputMaybe<Scalars['Boolean']>;
  reason?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Tenant_Permissions_Sum_Fields = {
  __typename: 'tenant_permissions_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "tenant_permissions" */
export type Tenant_Permissions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Tenant_Permissions_Var_Pop_Fields = {
  __typename: 'tenant_permissions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tenant_permissions" */
export type Tenant_Permissions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tenant_Permissions_Var_Samp_Fields = {
  __typename: 'tenant_permissions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tenant_permissions" */
export type Tenant_Permissions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tenant_Permissions_Variance_Fields = {
  __typename: 'tenant_permissions_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tenant_permissions" */
export type Tenant_Permissions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "tenants" */
export type Tenants = {
  __typename: 'tenants';
  /** An array relationship */
  abacusExports: Array<Abacus_Exports>;
  /** An aggregate relationship */
  abacusExports_aggregate: Abacus_Exports_Aggregate;
  /** An array relationship */
  accounts: Array<Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Accounts_Aggregate;
  /** An object relationship */
  companyLogoImage?: Maybe<Images>;
  companyLogoImageId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  contactAssignedRoles: Array<Contact_Assigned_Roles>;
  /** An aggregate relationship */
  contactAssignedRoles_aggregate: Contact_Assigned_Roles_Aggregate;
  /** An array relationship */
  contactAvailabilities: Array<Contact_Availability>;
  /** An aggregate relationship */
  contactAvailabilities_aggregate: Contact_Availability_Aggregate;
  /** An array relationship */
  contactAvailabilityRanges: Array<Contact_Availability_Ranges>;
  /** An aggregate relationship */
  contactAvailabilityRanges_aggregate: Contact_Availability_Ranges_Aggregate;
  /** An array relationship */
  contacts: Array<Contacts>;
  /** An aggregate relationship */
  contacts_aggregate: Contacts_Aggregate;
  /** An array relationship */
  costPriceAveragePeriods: Array<Cost_Price_Average_Periods>;
  /** An aggregate relationship */
  costPriceAveragePeriods_aggregate: Cost_Price_Average_Periods_Aggregate;
  /** An array relationship */
  creditNoteItems: Array<Credit_Note_Items>;
  /** An aggregate relationship */
  creditNoteItems_aggregate: Credit_Note_Items_Aggregate;
  /** An array relationship */
  creditNotes: Array<Credit_Notes>;
  /** An aggregate relationship */
  creditNotes_aggregate: Credit_Notes_Aggregate;
  /** An array relationship */
  customerAddresses: Array<Customer_Addresses>;
  /** An aggregate relationship */
  customerAddresses_aggregate: Customer_Addresses_Aggregate;
  /** An array relationship */
  customerBillingSettings: Array<Customer_Billing_Settings>;
  /** An aggregate relationship */
  customerBillingSettings_aggregate: Customer_Billing_Settings_Aggregate;
  /** An array relationship */
  customerDeliveryAvailabilities: Array<Customer_Delivery_Availability>;
  /** An aggregate relationship */
  customerDeliveryAvailabilities_aggregate: Customer_Delivery_Availability_Aggregate;
  /** An array relationship */
  customerGroupPricings: Array<Customer_Group_Pricing>;
  /** An aggregate relationship */
  customerGroupPricings_aggregate: Customer_Group_Pricing_Aggregate;
  /** An array relationship */
  customerGroups: Array<Customer_Groups>;
  /** An aggregate relationship */
  customerGroups_aggregate: Customer_Groups_Aggregate;
  /** An array relationship */
  customerInvoiceOrders: Array<Customer_Invoice_Orders>;
  /** An aggregate relationship */
  customerInvoiceOrders_aggregate: Customer_Invoice_Orders_Aggregate;
  /** An array relationship */
  customerInvoiceVersions: Array<Customer_Invoice_Versions>;
  /** An aggregate relationship */
  customerInvoiceVersions_aggregate: Customer_Invoice_Versions_Aggregate;
  /** An array relationship */
  customerInvoices: Array<Customer_Invoices>;
  /** An aggregate relationship */
  customerInvoices_aggregate: Customer_Invoices_Aggregate;
  /** An array relationship */
  customerOrderContainerReturnItems: Array<Customer_Order_Container_Return_Items>;
  /** An aggregate relationship */
  customerOrderContainerReturnItems_aggregate: Customer_Order_Container_Return_Items_Aggregate;
  /** An array relationship */
  customerOrderContainerReturns: Array<Customer_Order_Container_Returns>;
  /** An aggregate relationship */
  customerOrderContainerReturns_aggregate: Customer_Order_Container_Returns_Aggregate;
  /** An array relationship */
  customerOrderItemAdjustments: Array<Customer_Order_Item_Adjustments>;
  /** An aggregate relationship */
  customerOrderItemAdjustments_aggregate: Customer_Order_Item_Adjustments_Aggregate;
  /** An array relationship */
  customerOrderItemContainerExpectations: Array<Customer_Order_Item_Container_Expectations>;
  /** An aggregate relationship */
  customerOrderItemContainerExpectations_aggregate: Customer_Order_Item_Container_Expectations_Aggregate;
  /** An array relationship */
  customerOrderItemFulfillmentPlans: Array<Customer_Order_Item_Fulfillment_Plans>;
  /** An aggregate relationship */
  customerOrderItemFulfillmentPlans_aggregate: Customer_Order_Item_Fulfillment_Plans_Aggregate;
  /** An array relationship */
  customerOrderItemReturnCauses: Array<Customer_Order_Item_Return_Causes>;
  /** An aggregate relationship */
  customerOrderItemReturnCauses_aggregate: Customer_Order_Item_Return_Causes_Aggregate;
  /** An array relationship */
  customerOrderItems: Array<Customer_Order_Items>;
  /** An aggregate relationship */
  customerOrderItems_aggregate: Customer_Order_Items_Aggregate;
  /** An array relationship */
  customerOrderPickedContainers: Array<Customer_Order_Picked_Container>;
  /** An aggregate relationship */
  customerOrderPickedContainers_aggregate: Customer_Order_Picked_Container_Aggregate;
  /** An array relationship */
  customerOrderReturnItemContainers: Array<Customer_Order_Return_Item_Containers>;
  /** An aggregate relationship */
  customerOrderReturnItemContainers_aggregate: Customer_Order_Return_Item_Containers_Aggregate;
  /** An array relationship */
  customerOrderReturnItems: Array<Customer_Order_Return_Items>;
  /** An aggregate relationship */
  customerOrderReturnItems_aggregate: Customer_Order_Return_Items_Aggregate;
  /** An array relationship */
  customerOrderReturns: Array<Customer_Order_Returns>;
  /** An aggregate relationship */
  customerOrderReturns_aggregate: Customer_Order_Returns_Aggregate;
  /** An array relationship */
  customerOrders: Array<Customer_Orders>;
  /** An aggregate relationship */
  customerOrders_aggregate: Customer_Orders_Aggregate;
  /** An array relationship */
  customerPaymentTerms: Array<Customer_Payment_Terms>;
  /** An aggregate relationship */
  customerPaymentTerms_aggregate: Customer_Payment_Terms_Aggregate;
  /** An array relationship */
  customerProductAvailabilities: Array<Customer_Product_Availabilities>;
  /** An aggregate relationship */
  customerProductAvailabilities_aggregate: Customer_Product_Availabilities_Aggregate;
  /** An array relationship */
  customerTemplateSettings: Array<Customer_Template_Settings>;
  /** An aggregate relationship */
  customerTemplateSettings_aggregate: Customer_Template_Settings_Aggregate;
  /** An array relationship */
  customers: Array<Customers>;
  /** An aggregate relationship */
  customers_aggregate: Customers_Aggregate;
  /** An array relationship */
  cuttingPatternOutputs: Array<Cutting_Pattern_Outputs>;
  /** An array relationship */
  cuttingPatternOutputsDrafts: Array<Cutting_Pattern_Outputs_Draft>;
  /** An aggregate relationship */
  cuttingPatternOutputsDrafts_aggregate: Cutting_Pattern_Outputs_Draft_Aggregate;
  /** An aggregate relationship */
  cuttingPatternOutputs_aggregate: Cutting_Pattern_Outputs_Aggregate;
  /** An array relationship */
  cuttingPatterns: Array<Cutting_Patterns>;
  /** An array relationship */
  cuttingPatternsDrafts: Array<Cutting_Patterns_Draft>;
  /** An aggregate relationship */
  cuttingPatternsDrafts_aggregate: Cutting_Patterns_Draft_Aggregate;
  /** An aggregate relationship */
  cuttingPatterns_aggregate: Cutting_Patterns_Aggregate;
  /** An array relationship */
  dismantlingPlanCuttingPatternChoices: Array<Dismantling_Plan_Cutting_Pattern_Choices>;
  /** An aggregate relationship */
  dismantlingPlanCuttingPatternChoices_aggregate: Dismantling_Plan_Cutting_Pattern_Choices_Aggregate;
  /** An array relationship */
  dismantlingPlanDemands: Array<Dismantling_Plan_Demands>;
  /** An aggregate relationship */
  dismantlingPlanDemands_aggregate: Dismantling_Plan_Demands_Aggregate;
  /** An array relationship */
  dismantlingPlanInstructions: Array<Dismantling_Plan_Instructions>;
  /** An aggregate relationship */
  dismantlingPlanInstructions_aggregate: Dismantling_Plan_Instructions_Aggregate;
  /** An array relationship */
  dismantlingPlanRemainingPieces: Array<Dismantling_Plan_Remaining_Pieces>;
  /** An aggregate relationship */
  dismantlingPlanRemainingPieces_aggregate: Dismantling_Plan_Remaining_Pieces_Aggregate;
  /** An array relationship */
  dismantlingPlans: Array<Dismantling_Plans>;
  /** An aggregate relationship */
  dismantlingPlans_aggregate: Dismantling_Plans_Aggregate;
  /** An array relationship */
  ediDeliveryNoteTypes: Array<Edi_Delivery_Note_Types>;
  /** An aggregate relationship */
  ediDeliveryNoteTypes_aggregate: Edi_Delivery_Note_Types_Aggregate;
  /** An array relationship */
  ediInvoiceTypes: Array<Edi_Invoice_Types>;
  /** An aggregate relationship */
  ediInvoiceTypes_aggregate: Edi_Invoice_Types_Aggregate;
  emailFrom: Scalars['String'];
  /** An array relationship */
  emails: Array<Emails>;
  /** An aggregate relationship */
  emails_aggregate: Emails_Aggregate;
  /** An array relationship */
  exchangeRates: Array<Exchange_Rates>;
  /** An aggregate relationship */
  exchangeRates_aggregate: Exchange_Rates_Aggregate;
  /** An array relationship */
  gs1LogisticTypes: Array<Gs1_Logistic_Types>;
  /** An aggregate relationship */
  gs1LogisticTypes_aggregate: Gs1_Logistic_Types_Aggregate;
  /** An array relationship */
  hardwareDevices: Array<Hardware_Devices>;
  /** An aggregate relationship */
  hardwareDevices_aggregate: Hardware_Devices_Aggregate;
  /** An array relationship */
  hierarchicalGoodsIncomes: Array<Hierarchical_Goods_Incomes>;
  /** An aggregate relationship */
  hierarchicalGoodsIncomes_aggregate: Hierarchical_Goods_Incomes_Aggregate;
  /** An array relationship */
  images: Array<Images>;
  /** An aggregate relationship */
  images_aggregate: Images_Aggregate;
  /** An array relationship */
  journalEntries: Array<Journal_Entries>;
  /** An aggregate relationship */
  journalEntries_aggregate: Journal_Entries_Aggregate;
  /** An array relationship */
  jscriptTemplates: Array<Jscript_Templates>;
  /** An aggregate relationship */
  jscriptTemplates_aggregate: Jscript_Templates_Aggregate;
  /** An array relationship */
  leichmehlTemplateTypes: Array<Leichmehl_Template_Types>;
  /** An aggregate relationship */
  leichmehlTemplateTypes_aggregate: Leichmehl_Template_Types_Aggregate;
  /** An array relationship */
  leichmehlTemplates: Array<Leichmehl_Templates>;
  /** An aggregate relationship */
  leichmehlTemplates_aggregate: Leichmehl_Templates_Aggregate;
  /** An array relationship */
  lotNumberProducts: Array<Lot_Number_Products>;
  /** An aggregate relationship */
  lotNumberProducts_aggregate: Lot_Number_Products_Aggregate;
  /** An array relationship */
  lotNumbers: Array<Lot_Numbers>;
  /** An aggregate relationship */
  lotNumbers_aggregate: Lot_Numbers_Aggregate;
  /** An object relationship */
  mainLanguage?: Maybe<Tenant_Languages>;
  mainLanguageId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  /** An array relationship */
  partialDeliveries: Array<Partial_Deliveries>;
  /** An aggregate relationship */
  partialDeliveries_aggregate: Partial_Deliveries_Aggregate;
  /** An array relationship */
  phones: Array<Phones>;
  /** An aggregate relationship */
  phones_aggregate: Phones_Aggregate;
  /** An array relationship */
  pickedItemContainers: Array<Picked_Item_Containers>;
  /** An aggregate relationship */
  pickedItemContainers_aggregate: Picked_Item_Containers_Aggregate;
  /** An array relationship */
  pickedItems: Array<Picked_Items>;
  /** An aggregate relationship */
  pickedItems_aggregate: Picked_Items_Aggregate;
  /** An array relationship */
  pickingCategories: Array<Picking_Categories>;
  /** An aggregate relationship */
  pickingCategories_aggregate: Picking_Categories_Aggregate;
  /** An array relationship */
  pickingDeviationReasons: Array<Picking_Deviation_Reasons>;
  /** An aggregate relationship */
  pickingDeviationReasons_aggregate: Picking_Deviation_Reasons_Aggregate;
  /** An array relationship */
  postings: Array<Postings>;
  /** An aggregate relationship */
  postings_aggregate: Postings_Aggregate;
  /** An array relationship */
  priceLists: Array<Price_Lists>;
  /** An aggregate relationship */
  priceLists_aggregate: Price_Lists_Aggregate;
  /** An array relationship */
  productAllowedContainers: Array<Product_Allowed_Containers>;
  /** An aggregate relationship */
  productAllowedContainers_aggregate: Product_Allowed_Containers_Aggregate;
  /** An array relationship */
  productGroupMembers: Array<Product_Group_Members>;
  /** An aggregate relationship */
  productGroupMembers_aggregate: Product_Group_Members_Aggregate;
  /** An array relationship */
  productGroups: Array<Product_Groups>;
  /** An aggregate relationship */
  productGroups_aggregate: Product_Groups_Aggregate;
  /** An array relationship */
  productLabelSettings: Array<Product_Label_Settings>;
  /** An aggregate relationship */
  productLabelSettings_aggregate: Product_Label_Settings_Aggregate;
  /** An array relationship */
  productPackagingConfigurations: Array<Product_Packaging_Configurations>;
  /** An aggregate relationship */
  productPackagingConfigurations_aggregate: Product_Packaging_Configurations_Aggregate;
  /** An array relationship */
  productRecipeIngredients: Array<Product_Recipe_Ingredients>;
  /** An aggregate relationship */
  productRecipeIngredients_aggregate: Product_Recipe_Ingredients_Aggregate;
  /** An array relationship */
  productRecipeSteps: Array<Product_Recipe_Steps>;
  /** An aggregate relationship */
  productRecipeSteps_aggregate: Product_Recipe_Steps_Aggregate;
  /** An array relationship */
  productRecipes: Array<Product_Recipes>;
  /** An aggregate relationship */
  productRecipes_aggregate: Product_Recipes_Aggregate;
  /** An array relationship */
  productS3Objects: Array<Product_S3_Objects>;
  /** An aggregate relationship */
  productS3Objects_aggregate: Product_S3_Objects_Aggregate;
  /** An array relationship */
  productSalesChannelMembers: Array<Product_Sales_Channel_Members>;
  /** An aggregate relationship */
  productSalesChannelMembers_aggregate: Product_Sales_Channel_Members_Aggregate;
  /** An array relationship */
  productStockLocations: Array<Product_Stock_Locations>;
  /** An aggregate relationship */
  productStockLocations_aggregate: Product_Stock_Locations_Aggregate;
  /** An array relationship */
  productSuppliers: Array<Product_Suppliers>;
  /** An aggregate relationship */
  productSuppliers_aggregate: Product_Suppliers_Aggregate;
  /** An array relationship */
  productUnitSalesPriceConfigs: Array<Product_Unit_Sales_Price_Config>;
  /** An aggregate relationship */
  productUnitSalesPriceConfigs_aggregate: Product_Unit_Sales_Price_Config_Aggregate;
  /** An array relationship */
  productUnits: Array<Product_Units>;
  /** An aggregate relationship */
  productUnits_aggregate: Product_Units_Aggregate;
  /** An array relationship */
  productionSiteInputStockLocations: Array<Production_Site_Input_Stock_Locations>;
  /** An aggregate relationship */
  productionSiteInputStockLocations_aggregate: Production_Site_Input_Stock_Locations_Aggregate;
  /** An array relationship */
  productionSiteProductGroupCalendars: Array<Production_Site_Product_Group_Calendar>;
  /** An aggregate relationship */
  productionSiteProductGroupCalendars_aggregate: Production_Site_Product_Group_Calendar_Aggregate;
  /** An array relationship */
  productionSites: Array<Production_Sites>;
  /** An aggregate relationship */
  productionSites_aggregate: Production_Sites_Aggregate;
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  /** An array relationship */
  rollingInventories: Array<Rolling_Inventories>;
  /** An aggregate relationship */
  rollingInventories_aggregate: Rolling_Inventories_Aggregate;
  /** An array relationship */
  rollingInventoryActors: Array<Rolling_Inventory_Actors>;
  /** An aggregate relationship */
  rollingInventoryActors_aggregate: Rolling_Inventory_Actors_Aggregate;
  /** An array relationship */
  rollingInventoryItems: Array<Rolling_Inventory_Items>;
  /** An aggregate relationship */
  rollingInventoryItems_aggregate: Rolling_Inventory_Items_Aggregate;
  /** An array relationship */
  s3Objects: Array<S3_Objects>;
  /** An aggregate relationship */
  s3Objects_aggregate: S3_Objects_Aggregate;
  /** An array relationship */
  salesChannels: Array<Sales_Channels>;
  /** An aggregate relationship */
  salesChannels_aggregate: Sales_Channels_Aggregate;
  /** An array relationship */
  salesTeamUsers: Array<Sales_Team_Users>;
  /** An aggregate relationship */
  salesTeamUsers_aggregate: Sales_Team_Users_Aggregate;
  /** An array relationship */
  salesTeams: Array<Sales_Teams>;
  /** An aggregate relationship */
  salesTeams_aggregate: Sales_Teams_Aggregate;
  shortName: Scalars['String'];
  /** An array relationship */
  stockLocations: Array<Stock_Locations>;
  /** An aggregate relationship */
  stockLocations_aggregate: Stock_Locations_Aggregate;
  /** An array relationship */
  stockTransactions: Array<Stock_Transactions>;
  /** An aggregate relationship */
  stockTransactions_aggregate: Stock_Transactions_Aggregate;
  /** An array relationship */
  supplierAddresses: Array<Supplier_Addresses>;
  /** An aggregate relationship */
  supplierAddresses_aggregate: Supplier_Addresses_Aggregate;
  /** An array relationship */
  supplierOrderGoodsIncomeItemContainers: Array<Supplier_Order_Goods_Income_Item_Containers>;
  /** An aggregate relationship */
  supplierOrderGoodsIncomeItemContainers_aggregate: Supplier_Order_Goods_Income_Item_Containers_Aggregate;
  /** An array relationship */
  supplierOrderGoodsIncomeItems: Array<Supplier_Order_Goods_Income_Items>;
  /** An aggregate relationship */
  supplierOrderGoodsIncomeItems_aggregate: Supplier_Order_Goods_Income_Items_Aggregate;
  /** An array relationship */
  supplierOrderItemReturnCauses: Array<Supplier_Order_Item_Return_Causes>;
  /** An aggregate relationship */
  supplierOrderItemReturnCauses_aggregate: Supplier_Order_Item_Return_Causes_Aggregate;
  /** An array relationship */
  supplierOrderItems: Array<Supplier_Order_Items>;
  /** An aggregate relationship */
  supplierOrderItems_aggregate: Supplier_Order_Items_Aggregate;
  /** An array relationship */
  supplierOrderReturnItemContainers: Array<Supplier_Order_Return_Item_Containers>;
  /** An aggregate relationship */
  supplierOrderReturnItemContainers_aggregate: Supplier_Order_Return_Item_Containers_Aggregate;
  /** An array relationship */
  supplierOrderReturnItems: Array<Supplier_Order_Return_Items>;
  /** An aggregate relationship */
  supplierOrderReturnItems_aggregate: Supplier_Order_Return_Items_Aggregate;
  /** An array relationship */
  supplierOrderReturnedContainers: Array<Supplier_Order_Returned_Containers>;
  /** An aggregate relationship */
  supplierOrderReturnedContainers_aggregate: Supplier_Order_Returned_Containers_Aggregate;
  /** An array relationship */
  supplierOrderReturns: Array<Supplier_Order_Returns>;
  /** An aggregate relationship */
  supplierOrderReturns_aggregate: Supplier_Order_Returns_Aggregate;
  /** An array relationship */
  supplierOrders: Array<Supplier_Orders>;
  /** An aggregate relationship */
  supplierOrders_aggregate: Supplier_Orders_Aggregate;
  /** An array relationship */
  suppliers: Array<Suppliers>;
  /** An aggregate relationship */
  suppliers_aggregate: Suppliers_Aggregate;
  /** An array relationship */
  temporalProducts: Array<Temporal_Products>;
  /** An aggregate relationship */
  temporalProducts_aggregate: Temporal_Products_Aggregate;
  /** An array relationship */
  temporalSalesTeamsCustomers: Array<Temporal_Sales_Teams_Customers>;
  /** An aggregate relationship */
  temporalSalesTeamsCustomers_aggregate: Temporal_Sales_Teams_Customers_Aggregate;
  /** An array relationship */
  temporalVatTypes: Array<Temporal_Vat_Types>;
  /** An aggregate relationship */
  temporalVatTypes_aggregate: Temporal_Vat_Types_Aggregate;
  tenant: Scalars['String'];
  /** An object relationship */
  tenantAddress?: Maybe<Tenant_Addresses>;
  tenantAddressId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  tenantAddresses: Array<Tenant_Addresses>;
  /** An aggregate relationship */
  tenantAddresses_aggregate: Tenant_Addresses_Aggregate;
  /** An array relationship */
  tenantBankAccounts: Array<Tenant_Bank_Accounts>;
  /** An aggregate relationship */
  tenantBankAccounts_aggregate: Tenant_Bank_Accounts_Aggregate;
  /** An object relationship */
  tenantCompanyDetail?: Maybe<Tenant_Company_Details>;
  tenantCompanyDetailId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  tenantCompanyDetails: Array<Tenant_Company_Details>;
  /** An aggregate relationship */
  tenantCompanyDetails_aggregate: Tenant_Company_Details_Aggregate;
  /** An array relationship */
  tenantConfigurations: Array<Tenant_Configurations>;
  /** An aggregate relationship */
  tenantConfigurations_aggregate: Tenant_Configurations_Aggregate;
  /** An array relationship */
  tenantLanguages: Array<Tenant_Languages>;
  /** An aggregate relationship */
  tenantLanguages_aggregate: Tenant_Languages_Aggregate;
  tenantName: Scalars['String'];
  /** An array relationship */
  tenantPermissions: Array<Tenant_Permissions>;
  /** An aggregate relationship */
  tenantPermissions_aggregate: Tenant_Permissions_Aggregate;
  /** An array relationship */
  todos: Array<Todos>;
  /** An aggregate relationship */
  todos_aggregate: Todos_Aggregate;
  /** An array relationship */
  tourRoutes: Array<Tour_Routes>;
  /** An aggregate relationship */
  tourRoutes_aggregate: Tour_Routes_Aggregate;
  /** An array relationship */
  tours: Array<Tours>;
  /** An aggregate relationship */
  tours_aggregate: Tours_Aggregate;
  /** An array relationship */
  userSalesGoals: Array<User_Sales_Goals>;
  /** An aggregate relationship */
  userSalesGoals_aggregate: User_Sales_Goals_Aggregate;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** An array relationship */
  vatTypes: Array<Vat_Types>;
  /** An aggregate relationship */
  vatTypes_aggregate: Vat_Types_Aggregate;
  /** An array relationship */
  workCalendars: Array<Work_Calendar>;
  /** An aggregate relationship */
  workCalendars_aggregate: Work_Calendar_Aggregate;
  /** An array relationship */
  workstationHardwareDeviceConnections: Array<Workstation_Hardware_Device_Connections>;
  /** An aggregate relationship */
  workstationHardwareDeviceConnections_aggregate: Workstation_Hardware_Device_Connections_Aggregate;
  /** An array relationship */
  workstationPickingCategories: Array<Workstation_Picking_Categories>;
  /** An aggregate relationship */
  workstationPickingCategories_aggregate: Workstation_Picking_Categories_Aggregate;
  /** An array relationship */
  workstations: Array<Workstations>;
  /** An aggregate relationship */
  workstations_aggregate: Workstations_Aggregate;
};


/** columns and relationships of "tenants" */
export type TenantsAbacusExportsArgs = {
  distinct_on?: InputMaybe<Array<Abacus_Exports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Abacus_Exports_Order_By>>;
  where?: InputMaybe<Abacus_Exports_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsAbacusExports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Abacus_Exports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Abacus_Exports_Order_By>>;
  where?: InputMaybe<Abacus_Exports_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsContactAssignedRolesArgs = {
  distinct_on?: InputMaybe<Array<Contact_Assigned_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Assigned_Roles_Order_By>>;
  where?: InputMaybe<Contact_Assigned_Roles_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsContactAssignedRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Assigned_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Assigned_Roles_Order_By>>;
  where?: InputMaybe<Contact_Assigned_Roles_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsContactAvailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<Contact_Availability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Availability_Order_By>>;
  where?: InputMaybe<Contact_Availability_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsContactAvailabilities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Availability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Availability_Order_By>>;
  where?: InputMaybe<Contact_Availability_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsContactAvailabilityRangesArgs = {
  distinct_on?: InputMaybe<Array<Contact_Availability_Ranges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Availability_Ranges_Order_By>>;
  where?: InputMaybe<Contact_Availability_Ranges_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsContactAvailabilityRanges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Availability_Ranges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Availability_Ranges_Order_By>>;
  where?: InputMaybe<Contact_Availability_Ranges_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCostPriceAveragePeriodsArgs = {
  distinct_on?: InputMaybe<Array<Cost_Price_Average_Periods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cost_Price_Average_Periods_Order_By>>;
  where?: InputMaybe<Cost_Price_Average_Periods_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCostPriceAveragePeriods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cost_Price_Average_Periods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cost_Price_Average_Periods_Order_By>>;
  where?: InputMaybe<Cost_Price_Average_Periods_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCreditNoteItemsArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Items_Order_By>>;
  where?: InputMaybe<Credit_Note_Items_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCreditNoteItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Items_Order_By>>;
  where?: InputMaybe<Credit_Note_Items_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCreditNotesArgs = {
  distinct_on?: InputMaybe<Array<Credit_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Notes_Order_By>>;
  where?: InputMaybe<Credit_Notes_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCreditNotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Notes_Order_By>>;
  where?: InputMaybe<Credit_Notes_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerAddressesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Addresses_Order_By>>;
  where?: InputMaybe<Customer_Addresses_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Addresses_Order_By>>;
  where?: InputMaybe<Customer_Addresses_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerBillingSettingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Settings_Order_By>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerBillingSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Billing_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Billing_Settings_Order_By>>;
  where?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerDeliveryAvailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Delivery_Availability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Delivery_Availability_Order_By>>;
  where?: InputMaybe<Customer_Delivery_Availability_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerDeliveryAvailabilities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Delivery_Availability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Delivery_Availability_Order_By>>;
  where?: InputMaybe<Customer_Delivery_Availability_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerGroupPricingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerGroupPricings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Group_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Group_Pricing_Order_By>>;
  where?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerGroupsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Groups_Order_By>>;
  where?: InputMaybe<Customer_Groups_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Groups_Order_By>>;
  where?: InputMaybe<Customer_Groups_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerInvoiceOrdersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Orders_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Orders_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerInvoiceOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Orders_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Orders_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerInvoiceVersionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Versions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerInvoiceVersions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Versions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoices_Order_By>>;
  where?: InputMaybe<Customer_Invoices_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerInvoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoices_Order_By>>;
  where?: InputMaybe<Customer_Invoices_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderContainerReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Return_Items_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderContainerReturnItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Return_Items_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderContainerReturnsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Returns_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderContainerReturns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Container_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Container_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Container_Returns_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderItemAdjustmentsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustments_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderItemAdjustments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Adjustments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Adjustments_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderItemContainerExpectationsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Container_Expectations_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderItemContainerExpectations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Container_Expectations_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Container_Expectations_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderItemFulfillmentPlansArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderItemFulfillmentPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Fulfillment_Plans_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderItemReturnCausesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Return_Causes_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderItemReturnCauses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Return_Causes_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Return_Causes_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Items_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderPickedContainersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderPickedContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Picked_Container_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Picked_Container_Order_By>>;
  where?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderReturnItemContainersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Item_Containers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderReturnItemContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Item_Containers_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Item_Containers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderReturnItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderReturnsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrderReturns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Returns_Order_By>>;
  where?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrdersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerPaymentTermsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Payment_Terms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Payment_Terms_Order_By>>;
  where?: InputMaybe<Customer_Payment_Terms_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerPaymentTerms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Payment_Terms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Payment_Terms_Order_By>>;
  where?: InputMaybe<Customer_Payment_Terms_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerProductAvailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Product_Availabilities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Product_Availabilities_Order_By>>;
  where?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerProductAvailabilities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Product_Availabilities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Product_Availabilities_Order_By>>;
  where?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerTemplateSettingsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Template_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Template_Settings_Order_By>>;
  where?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomerTemplateSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Template_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Template_Settings_Order_By>>;
  where?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCuttingPatternOutputsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCuttingPatternOutputsDraftsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCuttingPatternOutputsDrafts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCuttingPatternOutputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Pattern_Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Pattern_Outputs_Order_By>>;
  where?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCuttingPatternsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCuttingPatternsDraftsArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCuttingPatternsDrafts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Draft_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Draft_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsCuttingPatterns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cutting_Patterns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cutting_Patterns_Order_By>>;
  where?: InputMaybe<Cutting_Patterns_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsDismantlingPlanCuttingPatternChoicesArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsDismantlingPlanCuttingPatternChoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Cutting_Pattern_Choices_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsDismantlingPlanDemandsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Demands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Demands_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsDismantlingPlanDemands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Demands_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Demands_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsDismantlingPlanInstructionsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Instructions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Instructions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Instructions_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsDismantlingPlanInstructions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Instructions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Instructions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Instructions_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsDismantlingPlanRemainingPiecesArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsDismantlingPlanRemainingPieces_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Remaining_Pieces_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsDismantlingPlansArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plans_Order_By>>;
  where?: InputMaybe<Dismantling_Plans_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsDismantlingPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plans_Order_By>>;
  where?: InputMaybe<Dismantling_Plans_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsEdiDeliveryNoteTypesArgs = {
  distinct_on?: InputMaybe<Array<Edi_Delivery_Note_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Edi_Delivery_Note_Types_Order_By>>;
  where?: InputMaybe<Edi_Delivery_Note_Types_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsEdiDeliveryNoteTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Edi_Delivery_Note_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Edi_Delivery_Note_Types_Order_By>>;
  where?: InputMaybe<Edi_Delivery_Note_Types_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsEdiInvoiceTypesArgs = {
  distinct_on?: InputMaybe<Array<Edi_Invoice_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Edi_Invoice_Types_Order_By>>;
  where?: InputMaybe<Edi_Invoice_Types_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsEdiInvoiceTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Edi_Invoice_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Edi_Invoice_Types_Order_By>>;
  where?: InputMaybe<Edi_Invoice_Types_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsEmailsArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsExchangeRatesArgs = {
  distinct_on?: InputMaybe<Array<Exchange_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Rates_Order_By>>;
  where?: InputMaybe<Exchange_Rates_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsExchangeRates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exchange_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Rates_Order_By>>;
  where?: InputMaybe<Exchange_Rates_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsGs1LogisticTypesArgs = {
  distinct_on?: InputMaybe<Array<Gs1_Logistic_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gs1_Logistic_Types_Order_By>>;
  where?: InputMaybe<Gs1_Logistic_Types_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsGs1LogisticTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gs1_Logistic_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gs1_Logistic_Types_Order_By>>;
  where?: InputMaybe<Gs1_Logistic_Types_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsHardwareDevicesArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Devices_Order_By>>;
  where?: InputMaybe<Hardware_Devices_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsHardwareDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Devices_Order_By>>;
  where?: InputMaybe<Hardware_Devices_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsHierarchicalGoodsIncomesArgs = {
  distinct_on?: InputMaybe<Array<Hierarchical_Goods_Incomes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hierarchical_Goods_Incomes_Order_By>>;
  where?: InputMaybe<Hierarchical_Goods_Incomes_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsHierarchicalGoodsIncomes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hierarchical_Goods_Incomes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hierarchical_Goods_Incomes_Order_By>>;
  where?: InputMaybe<Hierarchical_Goods_Incomes_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsImagesArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsImages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsJournalEntriesArgs = {
  distinct_on?: InputMaybe<Array<Journal_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journal_Entries_Order_By>>;
  where?: InputMaybe<Journal_Entries_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsJournalEntries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Journal_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journal_Entries_Order_By>>;
  where?: InputMaybe<Journal_Entries_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsJscriptTemplatesArgs = {
  distinct_on?: InputMaybe<Array<Jscript_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jscript_Templates_Order_By>>;
  where?: InputMaybe<Jscript_Templates_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsJscriptTemplates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jscript_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jscript_Templates_Order_By>>;
  where?: InputMaybe<Jscript_Templates_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsLeichmehlTemplateTypesArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Template_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Template_Types_Order_By>>;
  where?: InputMaybe<Leichmehl_Template_Types_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsLeichmehlTemplateTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Template_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Template_Types_Order_By>>;
  where?: InputMaybe<Leichmehl_Template_Types_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsLeichmehlTemplatesArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Templates_Order_By>>;
  where?: InputMaybe<Leichmehl_Templates_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsLeichmehlTemplates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leichmehl_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Leichmehl_Templates_Order_By>>;
  where?: InputMaybe<Leichmehl_Templates_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsLotNumberProductsArgs = {
  distinct_on?: InputMaybe<Array<Lot_Number_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Number_Products_Order_By>>;
  where?: InputMaybe<Lot_Number_Products_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsLotNumberProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lot_Number_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Number_Products_Order_By>>;
  where?: InputMaybe<Lot_Number_Products_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsLotNumbersArgs = {
  distinct_on?: InputMaybe<Array<Lot_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Numbers_Order_By>>;
  where?: InputMaybe<Lot_Numbers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsLotNumbers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lot_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lot_Numbers_Order_By>>;
  where?: InputMaybe<Lot_Numbers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsPartialDeliveriesArgs = {
  distinct_on?: InputMaybe<Array<Partial_Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Deliveries_Order_By>>;
  where?: InputMaybe<Partial_Deliveries_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsPartialDeliveries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partial_Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Deliveries_Order_By>>;
  where?: InputMaybe<Partial_Deliveries_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsPhonesArgs = {
  distinct_on?: InputMaybe<Array<Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phones_Order_By>>;
  where?: InputMaybe<Phones_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsPhones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phones_Order_By>>;
  where?: InputMaybe<Phones_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsPickedItemContainersArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_Containers_Order_By>>;
  where?: InputMaybe<Picked_Item_Containers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsPickedItemContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_Containers_Order_By>>;
  where?: InputMaybe<Picked_Item_Containers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsPickedItemsArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsPickedItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Items_Order_By>>;
  where?: InputMaybe<Picked_Items_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsPickingCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Picking_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picking_Categories_Order_By>>;
  where?: InputMaybe<Picking_Categories_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsPickingCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picking_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picking_Categories_Order_By>>;
  where?: InputMaybe<Picking_Categories_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsPickingDeviationReasonsArgs = {
  distinct_on?: InputMaybe<Array<Picking_Deviation_Reasons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picking_Deviation_Reasons_Order_By>>;
  where?: InputMaybe<Picking_Deviation_Reasons_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsPickingDeviationReasons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picking_Deviation_Reasons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picking_Deviation_Reasons_Order_By>>;
  where?: InputMaybe<Picking_Deviation_Reasons_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsPostingsArgs = {
  distinct_on?: InputMaybe<Array<Postings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postings_Order_By>>;
  where?: InputMaybe<Postings_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsPostings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Postings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postings_Order_By>>;
  where?: InputMaybe<Postings_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsPriceListsArgs = {
  distinct_on?: InputMaybe<Array<Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Lists_Order_By>>;
  where?: InputMaybe<Price_Lists_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsPriceLists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Price_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Lists_Order_By>>;
  where?: InputMaybe<Price_Lists_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductAllowedContainersArgs = {
  distinct_on?: InputMaybe<Array<Product_Allowed_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Allowed_Containers_Order_By>>;
  where?: InputMaybe<Product_Allowed_Containers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductAllowedContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Allowed_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Allowed_Containers_Order_By>>;
  where?: InputMaybe<Product_Allowed_Containers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductGroupMembersArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_Members_Order_By>>;
  where?: InputMaybe<Product_Group_Members_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductGroupMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Group_Members_Order_By>>;
  where?: InputMaybe<Product_Group_Members_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductGroupsArgs = {
  distinct_on?: InputMaybe<Array<Product_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Groups_Order_By>>;
  where?: InputMaybe<Product_Groups_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Groups_Order_By>>;
  where?: InputMaybe<Product_Groups_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductLabelSettingsArgs = {
  distinct_on?: InputMaybe<Array<Product_Label_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Label_Settings_Order_By>>;
  where?: InputMaybe<Product_Label_Settings_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductLabelSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Label_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Label_Settings_Order_By>>;
  where?: InputMaybe<Product_Label_Settings_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductPackagingConfigurationsArgs = {
  distinct_on?: InputMaybe<Array<Product_Packaging_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Packaging_Configurations_Order_By>>;
  where?: InputMaybe<Product_Packaging_Configurations_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductPackagingConfigurations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Packaging_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Packaging_Configurations_Order_By>>;
  where?: InputMaybe<Product_Packaging_Configurations_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductRecipeIngredientsArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_Ingredients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_Ingredients_Order_By>>;
  where?: InputMaybe<Product_Recipe_Ingredients_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductRecipeIngredients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_Ingredients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_Ingredients_Order_By>>;
  where?: InputMaybe<Product_Recipe_Ingredients_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductRecipeStepsArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_Steps_Order_By>>;
  where?: InputMaybe<Product_Recipe_Steps_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductRecipeSteps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipe_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipe_Steps_Order_By>>;
  where?: InputMaybe<Product_Recipe_Steps_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductRecipesArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipes_Order_By>>;
  where?: InputMaybe<Product_Recipes_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductRecipes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Recipes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Recipes_Order_By>>;
  where?: InputMaybe<Product_Recipes_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductS3ObjectsArgs = {
  distinct_on?: InputMaybe<Array<Product_S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_S3_Objects_Order_By>>;
  where?: InputMaybe<Product_S3_Objects_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductS3Objects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_S3_Objects_Order_By>>;
  where?: InputMaybe<Product_S3_Objects_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductSalesChannelMembersArgs = {
  distinct_on?: InputMaybe<Array<Product_Sales_Channel_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Sales_Channel_Members_Order_By>>;
  where?: InputMaybe<Product_Sales_Channel_Members_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductSalesChannelMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Sales_Channel_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Sales_Channel_Members_Order_By>>;
  where?: InputMaybe<Product_Sales_Channel_Members_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductStockLocationsArgs = {
  distinct_on?: InputMaybe<Array<Product_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Stock_Locations_Order_By>>;
  where?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductStockLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Stock_Locations_Order_By>>;
  where?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductSuppliersArgs = {
  distinct_on?: InputMaybe<Array<Product_Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Suppliers_Order_By>>;
  where?: InputMaybe<Product_Suppliers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductSuppliers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Suppliers_Order_By>>;
  where?: InputMaybe<Product_Suppliers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductUnitSalesPriceConfigsArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Order_By>>;
  where?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductUnitSalesPriceConfigs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Unit_Sales_Price_Config_Order_By>>;
  where?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductUnitsArgs = {
  distinct_on?: InputMaybe<Array<Product_Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Units_Order_By>>;
  where?: InputMaybe<Product_Units_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Units_Order_By>>;
  where?: InputMaybe<Product_Units_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductionSiteInputStockLocationsArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Order_By>>;
  where?: InputMaybe<Production_Site_Input_Stock_Locations_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductionSiteInputStockLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Input_Stock_Locations_Order_By>>;
  where?: InputMaybe<Production_Site_Input_Stock_Locations_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductionSiteProductGroupCalendarsArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Order_By>>;
  where?: InputMaybe<Production_Site_Product_Group_Calendar_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductionSiteProductGroupCalendars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Site_Product_Group_Calendar_Order_By>>;
  where?: InputMaybe<Production_Site_Product_Group_Calendar_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductionSitesArgs = {
  distinct_on?: InputMaybe<Array<Production_Sites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Sites_Order_By>>;
  where?: InputMaybe<Production_Sites_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductionSites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Production_Sites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Production_Sites_Order_By>>;
  where?: InputMaybe<Production_Sites_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsRollingInventoriesArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventories_Order_By>>;
  where?: InputMaybe<Rolling_Inventories_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsRollingInventories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventories_Order_By>>;
  where?: InputMaybe<Rolling_Inventories_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsRollingInventoryActorsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Actors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Actors_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsRollingInventoryActors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Actors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Actors_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsRollingInventoryItemsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsRollingInventoryItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Items_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsS3ObjectsArgs = {
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsS3Objects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSalesChannelsArgs = {
  distinct_on?: InputMaybe<Array<Sales_Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Channels_Order_By>>;
  where?: InputMaybe<Sales_Channels_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSalesChannels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sales_Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Channels_Order_By>>;
  where?: InputMaybe<Sales_Channels_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSalesTeamUsersArgs = {
  distinct_on?: InputMaybe<Array<Sales_Team_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Team_Users_Order_By>>;
  where?: InputMaybe<Sales_Team_Users_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSalesTeamUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sales_Team_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Team_Users_Order_By>>;
  where?: InputMaybe<Sales_Team_Users_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSalesTeamsArgs = {
  distinct_on?: InputMaybe<Array<Sales_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Teams_Order_By>>;
  where?: InputMaybe<Sales_Teams_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSalesTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sales_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Teams_Order_By>>;
  where?: InputMaybe<Sales_Teams_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsStockLocationsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Locations_Order_By>>;
  where?: InputMaybe<Stock_Locations_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsStockLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Locations_Order_By>>;
  where?: InputMaybe<Stock_Locations_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsStockTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsStockTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transactions_Order_By>>;
  where?: InputMaybe<Stock_Transactions_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSupplierAddressesArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Addresses_Order_By>>;
  where?: InputMaybe<Supplier_Addresses_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSupplierAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Addresses_Order_By>>;
  where?: InputMaybe<Supplier_Addresses_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSupplierOrderGoodsIncomeItemContainersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSupplierOrderGoodsIncomeItemContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Item_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSupplierOrderGoodsIncomeItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSupplierOrderGoodsIncomeItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Goods_Income_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSupplierOrderItemReturnCausesArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Item_Return_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Item_Return_Causes_Order_By>>;
  where?: InputMaybe<Supplier_Order_Item_Return_Causes_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSupplierOrderItemReturnCauses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Item_Return_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Item_Return_Causes_Order_By>>;
  where?: InputMaybe<Supplier_Order_Item_Return_Causes_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSupplierOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSupplierOrderItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSupplierOrderReturnItemContainersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Item_Containers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSupplierOrderReturnItemContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Item_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Item_Containers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSupplierOrderReturnItemsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSupplierOrderReturnItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Return_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Return_Items_Order_By>>;
  where?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSupplierOrderReturnedContainersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returned_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returned_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returned_Containers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSupplierOrderReturnedContainers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returned_Containers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returned_Containers_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returned_Containers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSupplierOrderReturnsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returns_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSupplierOrderReturns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Returns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Returns_Order_By>>;
  where?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSupplierOrdersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSupplierOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSuppliersArgs = {
  distinct_on?: InputMaybe<Array<Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Suppliers_Order_By>>;
  where?: InputMaybe<Suppliers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsSuppliers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Suppliers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Suppliers_Order_By>>;
  where?: InputMaybe<Suppliers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTemporalProductsArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTemporalProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Products_Order_By>>;
  where?: InputMaybe<Temporal_Products_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTemporalSalesTeamsCustomersArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Order_By>>;
  where?: InputMaybe<Temporal_Sales_Teams_Customers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTemporalSalesTeamsCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Sales_Teams_Customers_Order_By>>;
  where?: InputMaybe<Temporal_Sales_Teams_Customers_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTemporalVatTypesArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Vat_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Vat_Types_Order_By>>;
  where?: InputMaybe<Temporal_Vat_Types_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTemporalVatTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Vat_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Vat_Types_Order_By>>;
  where?: InputMaybe<Temporal_Vat_Types_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTenantAddressesArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Addresses_Order_By>>;
  where?: InputMaybe<Tenant_Addresses_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTenantAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Addresses_Order_By>>;
  where?: InputMaybe<Tenant_Addresses_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTenantBankAccountsArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Bank_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Bank_Accounts_Order_By>>;
  where?: InputMaybe<Tenant_Bank_Accounts_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTenantBankAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Bank_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Bank_Accounts_Order_By>>;
  where?: InputMaybe<Tenant_Bank_Accounts_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTenantCompanyDetailsArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Company_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Company_Details_Order_By>>;
  where?: InputMaybe<Tenant_Company_Details_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTenantCompanyDetails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Company_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Company_Details_Order_By>>;
  where?: InputMaybe<Tenant_Company_Details_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTenantConfigurationsArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Configurations_Order_By>>;
  where?: InputMaybe<Tenant_Configurations_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTenantConfigurations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Configurations_Order_By>>;
  where?: InputMaybe<Tenant_Configurations_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTenantLanguagesArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Languages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Languages_Order_By>>;
  where?: InputMaybe<Tenant_Languages_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTenantLanguages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Languages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Languages_Order_By>>;
  where?: InputMaybe<Tenant_Languages_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTenantPermissionsArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Permissions_Order_By>>;
  where?: InputMaybe<Tenant_Permissions_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTenantPermissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Permissions_Order_By>>;
  where?: InputMaybe<Tenant_Permissions_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTodosArgs = {
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTodos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTourRoutesArgs = {
  distinct_on?: InputMaybe<Array<Tour_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tour_Routes_Order_By>>;
  where?: InputMaybe<Tour_Routes_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTourRoutes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tour_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tour_Routes_Order_By>>;
  where?: InputMaybe<Tour_Routes_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsToursArgs = {
  distinct_on?: InputMaybe<Array<Tours_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tours_Order_By>>;
  where?: InputMaybe<Tours_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsTours_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tours_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tours_Order_By>>;
  where?: InputMaybe<Tours_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsUserSalesGoalsArgs = {
  distinct_on?: InputMaybe<Array<User_Sales_Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Sales_Goals_Order_By>>;
  where?: InputMaybe<User_Sales_Goals_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsUserSalesGoals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Sales_Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Sales_Goals_Order_By>>;
  where?: InputMaybe<User_Sales_Goals_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsVatTypesArgs = {
  distinct_on?: InputMaybe<Array<Vat_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vat_Types_Order_By>>;
  where?: InputMaybe<Vat_Types_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsVatTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vat_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vat_Types_Order_By>>;
  where?: InputMaybe<Vat_Types_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsWorkCalendarsArgs = {
  distinct_on?: InputMaybe<Array<Work_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Work_Calendar_Order_By>>;
  where?: InputMaybe<Work_Calendar_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsWorkCalendars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Work_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Work_Calendar_Order_By>>;
  where?: InputMaybe<Work_Calendar_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsWorkstationHardwareDeviceConnectionsArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Order_By>>;
  where?: InputMaybe<Workstation_Hardware_Device_Connections_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsWorkstationHardwareDeviceConnections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Order_By>>;
  where?: InputMaybe<Workstation_Hardware_Device_Connections_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsWorkstationPickingCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Picking_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Picking_Categories_Order_By>>;
  where?: InputMaybe<Workstation_Picking_Categories_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsWorkstationPickingCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Picking_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Picking_Categories_Order_By>>;
  where?: InputMaybe<Workstation_Picking_Categories_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsWorkstationsArgs = {
  distinct_on?: InputMaybe<Array<Workstations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstations_Order_By>>;
  where?: InputMaybe<Workstations_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsWorkstations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workstations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstations_Order_By>>;
  where?: InputMaybe<Workstations_Bool_Exp>;
};

/** aggregated selection of "tenants" */
export type Tenants_Aggregate = {
  __typename: 'tenants_aggregate';
  aggregate?: Maybe<Tenants_Aggregate_Fields>;
  nodes: Array<Tenants>;
};

export type Tenants_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenants_Aggregate_Bool_Exp_Count>;
};

export type Tenants_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenants_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenants" */
export type Tenants_Aggregate_Fields = {
  __typename: 'tenants_aggregate_fields';
  avg?: Maybe<Tenants_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tenants_Max_Fields>;
  min?: Maybe<Tenants_Min_Fields>;
  stddev?: Maybe<Tenants_Stddev_Fields>;
  stddev_pop?: Maybe<Tenants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tenants_Stddev_Samp_Fields>;
  sum?: Maybe<Tenants_Sum_Fields>;
  var_pop?: Maybe<Tenants_Var_Pop_Fields>;
  var_samp?: Maybe<Tenants_Var_Samp_Fields>;
  variance?: Maybe<Tenants_Variance_Fields>;
};


/** aggregate fields of "tenants" */
export type Tenants_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tenants" */
export type Tenants_Aggregate_Order_By = {
  avg?: InputMaybe<Tenants_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenants_Max_Order_By>;
  min?: InputMaybe<Tenants_Min_Order_By>;
  stddev?: InputMaybe<Tenants_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tenants_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tenants_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tenants_Sum_Order_By>;
  var_pop?: InputMaybe<Tenants_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tenants_Var_Samp_Order_By>;
  variance?: InputMaybe<Tenants_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Tenants_Avg_Fields = {
  __typename: 'tenants_avg_fields';
  companyLogoImageId?: Maybe<Scalars['Float']>;
  mainLanguageId?: Maybe<Scalars['Float']>;
  tenantAddressId?: Maybe<Scalars['Float']>;
  tenantCompanyDetailId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tenants" */
export type Tenants_Avg_Order_By = {
  companyLogoImageId?: InputMaybe<Order_By>;
  mainLanguageId?: InputMaybe<Order_By>;
  tenantAddressId?: InputMaybe<Order_By>;
  tenantCompanyDetailId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tenants". All fields are combined with a logical 'AND'. */
export type Tenants_Bool_Exp = {
  _and?: InputMaybe<Array<Tenants_Bool_Exp>>;
  _not?: InputMaybe<Tenants_Bool_Exp>;
  _or?: InputMaybe<Array<Tenants_Bool_Exp>>;
  abacusExports?: InputMaybe<Abacus_Exports_Bool_Exp>;
  abacusExports_aggregate?: InputMaybe<Abacus_Exports_Aggregate_Bool_Exp>;
  accounts?: InputMaybe<Accounts_Bool_Exp>;
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Bool_Exp>;
  companyLogoImage?: InputMaybe<Images_Bool_Exp>;
  companyLogoImageId?: InputMaybe<Bigint_Comparison_Exp>;
  contactAssignedRoles?: InputMaybe<Contact_Assigned_Roles_Bool_Exp>;
  contactAssignedRoles_aggregate?: InputMaybe<Contact_Assigned_Roles_Aggregate_Bool_Exp>;
  contactAvailabilities?: InputMaybe<Contact_Availability_Bool_Exp>;
  contactAvailabilities_aggregate?: InputMaybe<Contact_Availability_Aggregate_Bool_Exp>;
  contactAvailabilityRanges?: InputMaybe<Contact_Availability_Ranges_Bool_Exp>;
  contactAvailabilityRanges_aggregate?: InputMaybe<Contact_Availability_Ranges_Aggregate_Bool_Exp>;
  contacts?: InputMaybe<Contacts_Bool_Exp>;
  contacts_aggregate?: InputMaybe<Contacts_Aggregate_Bool_Exp>;
  costPriceAveragePeriods?: InputMaybe<Cost_Price_Average_Periods_Bool_Exp>;
  costPriceAveragePeriods_aggregate?: InputMaybe<Cost_Price_Average_Periods_Aggregate_Bool_Exp>;
  creditNoteItems?: InputMaybe<Credit_Note_Items_Bool_Exp>;
  creditNoteItems_aggregate?: InputMaybe<Credit_Note_Items_Aggregate_Bool_Exp>;
  creditNotes?: InputMaybe<Credit_Notes_Bool_Exp>;
  creditNotes_aggregate?: InputMaybe<Credit_Notes_Aggregate_Bool_Exp>;
  customerAddresses?: InputMaybe<Customer_Addresses_Bool_Exp>;
  customerAddresses_aggregate?: InputMaybe<Customer_Addresses_Aggregate_Bool_Exp>;
  customerBillingSettings?: InputMaybe<Customer_Billing_Settings_Bool_Exp>;
  customerBillingSettings_aggregate?: InputMaybe<Customer_Billing_Settings_Aggregate_Bool_Exp>;
  customerDeliveryAvailabilities?: InputMaybe<Customer_Delivery_Availability_Bool_Exp>;
  customerDeliveryAvailabilities_aggregate?: InputMaybe<Customer_Delivery_Availability_Aggregate_Bool_Exp>;
  customerGroupPricings?: InputMaybe<Customer_Group_Pricing_Bool_Exp>;
  customerGroupPricings_aggregate?: InputMaybe<Customer_Group_Pricing_Aggregate_Bool_Exp>;
  customerGroups?: InputMaybe<Customer_Groups_Bool_Exp>;
  customerGroups_aggregate?: InputMaybe<Customer_Groups_Aggregate_Bool_Exp>;
  customerInvoiceOrders?: InputMaybe<Customer_Invoice_Orders_Bool_Exp>;
  customerInvoiceOrders_aggregate?: InputMaybe<Customer_Invoice_Orders_Aggregate_Bool_Exp>;
  customerInvoiceVersions?: InputMaybe<Customer_Invoice_Versions_Bool_Exp>;
  customerInvoiceVersions_aggregate?: InputMaybe<Customer_Invoice_Versions_Aggregate_Bool_Exp>;
  customerInvoices?: InputMaybe<Customer_Invoices_Bool_Exp>;
  customerInvoices_aggregate?: InputMaybe<Customer_Invoices_Aggregate_Bool_Exp>;
  customerOrderContainerReturnItems?: InputMaybe<Customer_Order_Container_Return_Items_Bool_Exp>;
  customerOrderContainerReturnItems_aggregate?: InputMaybe<Customer_Order_Container_Return_Items_Aggregate_Bool_Exp>;
  customerOrderContainerReturns?: InputMaybe<Customer_Order_Container_Returns_Bool_Exp>;
  customerOrderContainerReturns_aggregate?: InputMaybe<Customer_Order_Container_Returns_Aggregate_Bool_Exp>;
  customerOrderItemAdjustments?: InputMaybe<Customer_Order_Item_Adjustments_Bool_Exp>;
  customerOrderItemAdjustments_aggregate?: InputMaybe<Customer_Order_Item_Adjustments_Aggregate_Bool_Exp>;
  customerOrderItemContainerExpectations?: InputMaybe<Customer_Order_Item_Container_Expectations_Bool_Exp>;
  customerOrderItemContainerExpectations_aggregate?: InputMaybe<Customer_Order_Item_Container_Expectations_Aggregate_Bool_Exp>;
  customerOrderItemFulfillmentPlans?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Bool_Exp>;
  customerOrderItemFulfillmentPlans_aggregate?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Aggregate_Bool_Exp>;
  customerOrderItemReturnCauses?: InputMaybe<Customer_Order_Item_Return_Causes_Bool_Exp>;
  customerOrderItemReturnCauses_aggregate?: InputMaybe<Customer_Order_Item_Return_Causes_Aggregate_Bool_Exp>;
  customerOrderItems?: InputMaybe<Customer_Order_Items_Bool_Exp>;
  customerOrderItems_aggregate?: InputMaybe<Customer_Order_Items_Aggregate_Bool_Exp>;
  customerOrderPickedContainers?: InputMaybe<Customer_Order_Picked_Container_Bool_Exp>;
  customerOrderPickedContainers_aggregate?: InputMaybe<Customer_Order_Picked_Container_Aggregate_Bool_Exp>;
  customerOrderReturnItemContainers?: InputMaybe<Customer_Order_Return_Item_Containers_Bool_Exp>;
  customerOrderReturnItemContainers_aggregate?: InputMaybe<Customer_Order_Return_Item_Containers_Aggregate_Bool_Exp>;
  customerOrderReturnItems?: InputMaybe<Customer_Order_Return_Items_Bool_Exp>;
  customerOrderReturnItems_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Bool_Exp>;
  customerOrderReturns?: InputMaybe<Customer_Order_Returns_Bool_Exp>;
  customerOrderReturns_aggregate?: InputMaybe<Customer_Order_Returns_Aggregate_Bool_Exp>;
  customerOrders?: InputMaybe<Customer_Orders_Bool_Exp>;
  customerOrders_aggregate?: InputMaybe<Customer_Orders_Aggregate_Bool_Exp>;
  customerPaymentTerms?: InputMaybe<Customer_Payment_Terms_Bool_Exp>;
  customerPaymentTerms_aggregate?: InputMaybe<Customer_Payment_Terms_Aggregate_Bool_Exp>;
  customerProductAvailabilities?: InputMaybe<Customer_Product_Availabilities_Bool_Exp>;
  customerProductAvailabilities_aggregate?: InputMaybe<Customer_Product_Availabilities_Aggregate_Bool_Exp>;
  customerTemplateSettings?: InputMaybe<Customer_Template_Settings_Bool_Exp>;
  customerTemplateSettings_aggregate?: InputMaybe<Customer_Template_Settings_Aggregate_Bool_Exp>;
  customers?: InputMaybe<Customers_Bool_Exp>;
  customers_aggregate?: InputMaybe<Customers_Aggregate_Bool_Exp>;
  cuttingPatternOutputs?: InputMaybe<Cutting_Pattern_Outputs_Bool_Exp>;
  cuttingPatternOutputsDrafts?: InputMaybe<Cutting_Pattern_Outputs_Draft_Bool_Exp>;
  cuttingPatternOutputsDrafts_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Draft_Aggregate_Bool_Exp>;
  cuttingPatternOutputs_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Aggregate_Bool_Exp>;
  cuttingPatterns?: InputMaybe<Cutting_Patterns_Bool_Exp>;
  cuttingPatternsDrafts?: InputMaybe<Cutting_Patterns_Draft_Bool_Exp>;
  cuttingPatternsDrafts_aggregate?: InputMaybe<Cutting_Patterns_Draft_Aggregate_Bool_Exp>;
  cuttingPatterns_aggregate?: InputMaybe<Cutting_Patterns_Aggregate_Bool_Exp>;
  dismantlingPlanCuttingPatternChoices?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Bool_Exp>;
  dismantlingPlanCuttingPatternChoices_aggregate?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_Bool_Exp>;
  dismantlingPlanDemands?: InputMaybe<Dismantling_Plan_Demands_Bool_Exp>;
  dismantlingPlanDemands_aggregate?: InputMaybe<Dismantling_Plan_Demands_Aggregate_Bool_Exp>;
  dismantlingPlanInstructions?: InputMaybe<Dismantling_Plan_Instructions_Bool_Exp>;
  dismantlingPlanInstructions_aggregate?: InputMaybe<Dismantling_Plan_Instructions_Aggregate_Bool_Exp>;
  dismantlingPlanRemainingPieces?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Bool_Exp>;
  dismantlingPlanRemainingPieces_aggregate?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Aggregate_Bool_Exp>;
  dismantlingPlans?: InputMaybe<Dismantling_Plans_Bool_Exp>;
  dismantlingPlans_aggregate?: InputMaybe<Dismantling_Plans_Aggregate_Bool_Exp>;
  ediDeliveryNoteTypes?: InputMaybe<Edi_Delivery_Note_Types_Bool_Exp>;
  ediDeliveryNoteTypes_aggregate?: InputMaybe<Edi_Delivery_Note_Types_Aggregate_Bool_Exp>;
  ediInvoiceTypes?: InputMaybe<Edi_Invoice_Types_Bool_Exp>;
  ediInvoiceTypes_aggregate?: InputMaybe<Edi_Invoice_Types_Aggregate_Bool_Exp>;
  emailFrom?: InputMaybe<String_Comparison_Exp>;
  emails?: InputMaybe<Emails_Bool_Exp>;
  emails_aggregate?: InputMaybe<Emails_Aggregate_Bool_Exp>;
  exchangeRates?: InputMaybe<Exchange_Rates_Bool_Exp>;
  exchangeRates_aggregate?: InputMaybe<Exchange_Rates_Aggregate_Bool_Exp>;
  gs1LogisticTypes?: InputMaybe<Gs1_Logistic_Types_Bool_Exp>;
  gs1LogisticTypes_aggregate?: InputMaybe<Gs1_Logistic_Types_Aggregate_Bool_Exp>;
  hardwareDevices?: InputMaybe<Hardware_Devices_Bool_Exp>;
  hardwareDevices_aggregate?: InputMaybe<Hardware_Devices_Aggregate_Bool_Exp>;
  hierarchicalGoodsIncomes?: InputMaybe<Hierarchical_Goods_Incomes_Bool_Exp>;
  hierarchicalGoodsIncomes_aggregate?: InputMaybe<Hierarchical_Goods_Incomes_Aggregate_Bool_Exp>;
  images?: InputMaybe<Images_Bool_Exp>;
  images_aggregate?: InputMaybe<Images_Aggregate_Bool_Exp>;
  journalEntries?: InputMaybe<Journal_Entries_Bool_Exp>;
  journalEntries_aggregate?: InputMaybe<Journal_Entries_Aggregate_Bool_Exp>;
  jscriptTemplates?: InputMaybe<Jscript_Templates_Bool_Exp>;
  jscriptTemplates_aggregate?: InputMaybe<Jscript_Templates_Aggregate_Bool_Exp>;
  leichmehlTemplateTypes?: InputMaybe<Leichmehl_Template_Types_Bool_Exp>;
  leichmehlTemplateTypes_aggregate?: InputMaybe<Leichmehl_Template_Types_Aggregate_Bool_Exp>;
  leichmehlTemplates?: InputMaybe<Leichmehl_Templates_Bool_Exp>;
  leichmehlTemplates_aggregate?: InputMaybe<Leichmehl_Templates_Aggregate_Bool_Exp>;
  lotNumberProducts?: InputMaybe<Lot_Number_Products_Bool_Exp>;
  lotNumberProducts_aggregate?: InputMaybe<Lot_Number_Products_Aggregate_Bool_Exp>;
  lotNumbers?: InputMaybe<Lot_Numbers_Bool_Exp>;
  lotNumbers_aggregate?: InputMaybe<Lot_Numbers_Aggregate_Bool_Exp>;
  mainLanguage?: InputMaybe<Tenant_Languages_Bool_Exp>;
  mainLanguageId?: InputMaybe<Bigint_Comparison_Exp>;
  notifications?: InputMaybe<Notifications_Bool_Exp>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  partialDeliveries?: InputMaybe<Partial_Deliveries_Bool_Exp>;
  partialDeliveries_aggregate?: InputMaybe<Partial_Deliveries_Aggregate_Bool_Exp>;
  phones?: InputMaybe<Phones_Bool_Exp>;
  phones_aggregate?: InputMaybe<Phones_Aggregate_Bool_Exp>;
  pickedItemContainers?: InputMaybe<Picked_Item_Containers_Bool_Exp>;
  pickedItemContainers_aggregate?: InputMaybe<Picked_Item_Containers_Aggregate_Bool_Exp>;
  pickedItems?: InputMaybe<Picked_Items_Bool_Exp>;
  pickedItems_aggregate?: InputMaybe<Picked_Items_Aggregate_Bool_Exp>;
  pickingCategories?: InputMaybe<Picking_Categories_Bool_Exp>;
  pickingCategories_aggregate?: InputMaybe<Picking_Categories_Aggregate_Bool_Exp>;
  pickingDeviationReasons?: InputMaybe<Picking_Deviation_Reasons_Bool_Exp>;
  pickingDeviationReasons_aggregate?: InputMaybe<Picking_Deviation_Reasons_Aggregate_Bool_Exp>;
  postings?: InputMaybe<Postings_Bool_Exp>;
  postings_aggregate?: InputMaybe<Postings_Aggregate_Bool_Exp>;
  priceLists?: InputMaybe<Price_Lists_Bool_Exp>;
  priceLists_aggregate?: InputMaybe<Price_Lists_Aggregate_Bool_Exp>;
  productAllowedContainers?: InputMaybe<Product_Allowed_Containers_Bool_Exp>;
  productAllowedContainers_aggregate?: InputMaybe<Product_Allowed_Containers_Aggregate_Bool_Exp>;
  productGroupMembers?: InputMaybe<Product_Group_Members_Bool_Exp>;
  productGroupMembers_aggregate?: InputMaybe<Product_Group_Members_Aggregate_Bool_Exp>;
  productGroups?: InputMaybe<Product_Groups_Bool_Exp>;
  productGroups_aggregate?: InputMaybe<Product_Groups_Aggregate_Bool_Exp>;
  productLabelSettings?: InputMaybe<Product_Label_Settings_Bool_Exp>;
  productLabelSettings_aggregate?: InputMaybe<Product_Label_Settings_Aggregate_Bool_Exp>;
  productPackagingConfigurations?: InputMaybe<Product_Packaging_Configurations_Bool_Exp>;
  productPackagingConfigurations_aggregate?: InputMaybe<Product_Packaging_Configurations_Aggregate_Bool_Exp>;
  productRecipeIngredients?: InputMaybe<Product_Recipe_Ingredients_Bool_Exp>;
  productRecipeIngredients_aggregate?: InputMaybe<Product_Recipe_Ingredients_Aggregate_Bool_Exp>;
  productRecipeSteps?: InputMaybe<Product_Recipe_Steps_Bool_Exp>;
  productRecipeSteps_aggregate?: InputMaybe<Product_Recipe_Steps_Aggregate_Bool_Exp>;
  productRecipes?: InputMaybe<Product_Recipes_Bool_Exp>;
  productRecipes_aggregate?: InputMaybe<Product_Recipes_Aggregate_Bool_Exp>;
  productS3Objects?: InputMaybe<Product_S3_Objects_Bool_Exp>;
  productS3Objects_aggregate?: InputMaybe<Product_S3_Objects_Aggregate_Bool_Exp>;
  productSalesChannelMembers?: InputMaybe<Product_Sales_Channel_Members_Bool_Exp>;
  productSalesChannelMembers_aggregate?: InputMaybe<Product_Sales_Channel_Members_Aggregate_Bool_Exp>;
  productStockLocations?: InputMaybe<Product_Stock_Locations_Bool_Exp>;
  productStockLocations_aggregate?: InputMaybe<Product_Stock_Locations_Aggregate_Bool_Exp>;
  productSuppliers?: InputMaybe<Product_Suppliers_Bool_Exp>;
  productSuppliers_aggregate?: InputMaybe<Product_Suppliers_Aggregate_Bool_Exp>;
  productUnitSalesPriceConfigs?: InputMaybe<Product_Unit_Sales_Price_Config_Bool_Exp>;
  productUnitSalesPriceConfigs_aggregate?: InputMaybe<Product_Unit_Sales_Price_Config_Aggregate_Bool_Exp>;
  productUnits?: InputMaybe<Product_Units_Bool_Exp>;
  productUnits_aggregate?: InputMaybe<Product_Units_Aggregate_Bool_Exp>;
  productionSiteInputStockLocations?: InputMaybe<Production_Site_Input_Stock_Locations_Bool_Exp>;
  productionSiteInputStockLocations_aggregate?: InputMaybe<Production_Site_Input_Stock_Locations_Aggregate_Bool_Exp>;
  productionSiteProductGroupCalendars?: InputMaybe<Production_Site_Product_Group_Calendar_Bool_Exp>;
  productionSiteProductGroupCalendars_aggregate?: InputMaybe<Production_Site_Product_Group_Calendar_Aggregate_Bool_Exp>;
  productionSites?: InputMaybe<Production_Sites_Bool_Exp>;
  productionSites_aggregate?: InputMaybe<Production_Sites_Aggregate_Bool_Exp>;
  products?: InputMaybe<Products_Bool_Exp>;
  products_aggregate?: InputMaybe<Products_Aggregate_Bool_Exp>;
  rollingInventories?: InputMaybe<Rolling_Inventories_Bool_Exp>;
  rollingInventories_aggregate?: InputMaybe<Rolling_Inventories_Aggregate_Bool_Exp>;
  rollingInventoryActors?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
  rollingInventoryActors_aggregate?: InputMaybe<Rolling_Inventory_Actors_Aggregate_Bool_Exp>;
  rollingInventoryItems?: InputMaybe<Rolling_Inventory_Items_Bool_Exp>;
  rollingInventoryItems_aggregate?: InputMaybe<Rolling_Inventory_Items_Aggregate_Bool_Exp>;
  s3Objects?: InputMaybe<S3_Objects_Bool_Exp>;
  s3Objects_aggregate?: InputMaybe<S3_Objects_Aggregate_Bool_Exp>;
  salesChannels?: InputMaybe<Sales_Channels_Bool_Exp>;
  salesChannels_aggregate?: InputMaybe<Sales_Channels_Aggregate_Bool_Exp>;
  salesTeamUsers?: InputMaybe<Sales_Team_Users_Bool_Exp>;
  salesTeamUsers_aggregate?: InputMaybe<Sales_Team_Users_Aggregate_Bool_Exp>;
  salesTeams?: InputMaybe<Sales_Teams_Bool_Exp>;
  salesTeams_aggregate?: InputMaybe<Sales_Teams_Aggregate_Bool_Exp>;
  shortName?: InputMaybe<String_Comparison_Exp>;
  stockLocations?: InputMaybe<Stock_Locations_Bool_Exp>;
  stockLocations_aggregate?: InputMaybe<Stock_Locations_Aggregate_Bool_Exp>;
  stockTransactions?: InputMaybe<Stock_Transactions_Bool_Exp>;
  stockTransactions_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Bool_Exp>;
  supplierAddresses?: InputMaybe<Supplier_Addresses_Bool_Exp>;
  supplierAddresses_aggregate?: InputMaybe<Supplier_Addresses_Aggregate_Bool_Exp>;
  supplierOrderGoodsIncomeItemContainers?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Bool_Exp>;
  supplierOrderGoodsIncomeItemContainers_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Aggregate_Bool_Exp>;
  supplierOrderGoodsIncomeItems?: InputMaybe<Supplier_Order_Goods_Income_Items_Bool_Exp>;
  supplierOrderGoodsIncomeItems_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Bool_Exp>;
  supplierOrderItemReturnCauses?: InputMaybe<Supplier_Order_Item_Return_Causes_Bool_Exp>;
  supplierOrderItemReturnCauses_aggregate?: InputMaybe<Supplier_Order_Item_Return_Causes_Aggregate_Bool_Exp>;
  supplierOrderItems?: InputMaybe<Supplier_Order_Items_Bool_Exp>;
  supplierOrderItems_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Bool_Exp>;
  supplierOrderReturnItemContainers?: InputMaybe<Supplier_Order_Return_Item_Containers_Bool_Exp>;
  supplierOrderReturnItemContainers_aggregate?: InputMaybe<Supplier_Order_Return_Item_Containers_Aggregate_Bool_Exp>;
  supplierOrderReturnItems?: InputMaybe<Supplier_Order_Return_Items_Bool_Exp>;
  supplierOrderReturnItems_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Bool_Exp>;
  supplierOrderReturnedContainers?: InputMaybe<Supplier_Order_Returned_Containers_Bool_Exp>;
  supplierOrderReturnedContainers_aggregate?: InputMaybe<Supplier_Order_Returned_Containers_Aggregate_Bool_Exp>;
  supplierOrderReturns?: InputMaybe<Supplier_Order_Returns_Bool_Exp>;
  supplierOrderReturns_aggregate?: InputMaybe<Supplier_Order_Returns_Aggregate_Bool_Exp>;
  supplierOrders?: InputMaybe<Supplier_Orders_Bool_Exp>;
  supplierOrders_aggregate?: InputMaybe<Supplier_Orders_Aggregate_Bool_Exp>;
  suppliers?: InputMaybe<Suppliers_Bool_Exp>;
  suppliers_aggregate?: InputMaybe<Suppliers_Aggregate_Bool_Exp>;
  temporalProducts?: InputMaybe<Temporal_Products_Bool_Exp>;
  temporalProducts_aggregate?: InputMaybe<Temporal_Products_Aggregate_Bool_Exp>;
  temporalSalesTeamsCustomers?: InputMaybe<Temporal_Sales_Teams_Customers_Bool_Exp>;
  temporalSalesTeamsCustomers_aggregate?: InputMaybe<Temporal_Sales_Teams_Customers_Aggregate_Bool_Exp>;
  temporalVatTypes?: InputMaybe<Temporal_Vat_Types_Bool_Exp>;
  temporalVatTypes_aggregate?: InputMaybe<Temporal_Vat_Types_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  tenantAddress?: InputMaybe<Tenant_Addresses_Bool_Exp>;
  tenantAddressId?: InputMaybe<Bigint_Comparison_Exp>;
  tenantAddresses?: InputMaybe<Tenant_Addresses_Bool_Exp>;
  tenantAddresses_aggregate?: InputMaybe<Tenant_Addresses_Aggregate_Bool_Exp>;
  tenantBankAccounts?: InputMaybe<Tenant_Bank_Accounts_Bool_Exp>;
  tenantBankAccounts_aggregate?: InputMaybe<Tenant_Bank_Accounts_Aggregate_Bool_Exp>;
  tenantCompanyDetail?: InputMaybe<Tenant_Company_Details_Bool_Exp>;
  tenantCompanyDetailId?: InputMaybe<Bigint_Comparison_Exp>;
  tenantCompanyDetails?: InputMaybe<Tenant_Company_Details_Bool_Exp>;
  tenantCompanyDetails_aggregate?: InputMaybe<Tenant_Company_Details_Aggregate_Bool_Exp>;
  tenantConfigurations?: InputMaybe<Tenant_Configurations_Bool_Exp>;
  tenantConfigurations_aggregate?: InputMaybe<Tenant_Configurations_Aggregate_Bool_Exp>;
  tenantLanguages?: InputMaybe<Tenant_Languages_Bool_Exp>;
  tenantLanguages_aggregate?: InputMaybe<Tenant_Languages_Aggregate_Bool_Exp>;
  tenantName?: InputMaybe<String_Comparison_Exp>;
  tenantPermissions?: InputMaybe<Tenant_Permissions_Bool_Exp>;
  tenantPermissions_aggregate?: InputMaybe<Tenant_Permissions_Aggregate_Bool_Exp>;
  todos?: InputMaybe<Todos_Bool_Exp>;
  todos_aggregate?: InputMaybe<Todos_Aggregate_Bool_Exp>;
  tourRoutes?: InputMaybe<Tour_Routes_Bool_Exp>;
  tourRoutes_aggregate?: InputMaybe<Tour_Routes_Aggregate_Bool_Exp>;
  tours?: InputMaybe<Tours_Bool_Exp>;
  tours_aggregate?: InputMaybe<Tours_Aggregate_Bool_Exp>;
  userSalesGoals?: InputMaybe<User_Sales_Goals_Bool_Exp>;
  userSalesGoals_aggregate?: InputMaybe<User_Sales_Goals_Aggregate_Bool_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
  users_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
  vatTypes?: InputMaybe<Vat_Types_Bool_Exp>;
  vatTypes_aggregate?: InputMaybe<Vat_Types_Aggregate_Bool_Exp>;
  workCalendars?: InputMaybe<Work_Calendar_Bool_Exp>;
  workCalendars_aggregate?: InputMaybe<Work_Calendar_Aggregate_Bool_Exp>;
  workstationHardwareDeviceConnections?: InputMaybe<Workstation_Hardware_Device_Connections_Bool_Exp>;
  workstationHardwareDeviceConnections_aggregate?: InputMaybe<Workstation_Hardware_Device_Connections_Aggregate_Bool_Exp>;
  workstationPickingCategories?: InputMaybe<Workstation_Picking_Categories_Bool_Exp>;
  workstationPickingCategories_aggregate?: InputMaybe<Workstation_Picking_Categories_Aggregate_Bool_Exp>;
  workstations?: InputMaybe<Workstations_Bool_Exp>;
  workstations_aggregate?: InputMaybe<Workstations_Aggregate_Bool_Exp>;
};

/** aggregate max on columns */
export type Tenants_Max_Fields = {
  __typename: 'tenants_max_fields';
  companyLogoImageId?: Maybe<Scalars['bigint']>;
  emailFrom?: Maybe<Scalars['String']>;
  mainLanguageId?: Maybe<Scalars['bigint']>;
  shortName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  tenantAddressId?: Maybe<Scalars['bigint']>;
  tenantCompanyDetailId?: Maybe<Scalars['bigint']>;
  tenantName?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "tenants" */
export type Tenants_Max_Order_By = {
  companyLogoImageId?: InputMaybe<Order_By>;
  emailFrom?: InputMaybe<Order_By>;
  mainLanguageId?: InputMaybe<Order_By>;
  shortName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  tenantAddressId?: InputMaybe<Order_By>;
  tenantCompanyDetailId?: InputMaybe<Order_By>;
  tenantName?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenants_Min_Fields = {
  __typename: 'tenants_min_fields';
  companyLogoImageId?: Maybe<Scalars['bigint']>;
  emailFrom?: Maybe<Scalars['String']>;
  mainLanguageId?: Maybe<Scalars['bigint']>;
  shortName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  tenantAddressId?: Maybe<Scalars['bigint']>;
  tenantCompanyDetailId?: Maybe<Scalars['bigint']>;
  tenantName?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "tenants" */
export type Tenants_Min_Order_By = {
  companyLogoImageId?: InputMaybe<Order_By>;
  emailFrom?: InputMaybe<Order_By>;
  mainLanguageId?: InputMaybe<Order_By>;
  shortName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  tenantAddressId?: InputMaybe<Order_By>;
  tenantCompanyDetailId?: InputMaybe<Order_By>;
  tenantName?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "tenants". */
export type Tenants_Order_By = {
  abacusExports_aggregate?: InputMaybe<Abacus_Exports_Aggregate_Order_By>;
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Order_By>;
  companyLogoImage?: InputMaybe<Images_Order_By>;
  companyLogoImageId?: InputMaybe<Order_By>;
  contactAssignedRoles_aggregate?: InputMaybe<Contact_Assigned_Roles_Aggregate_Order_By>;
  contactAvailabilities_aggregate?: InputMaybe<Contact_Availability_Aggregate_Order_By>;
  contactAvailabilityRanges_aggregate?: InputMaybe<Contact_Availability_Ranges_Aggregate_Order_By>;
  contacts_aggregate?: InputMaybe<Contacts_Aggregate_Order_By>;
  costPriceAveragePeriods_aggregate?: InputMaybe<Cost_Price_Average_Periods_Aggregate_Order_By>;
  creditNoteItems_aggregate?: InputMaybe<Credit_Note_Items_Aggregate_Order_By>;
  creditNotes_aggregate?: InputMaybe<Credit_Notes_Aggregate_Order_By>;
  customerAddresses_aggregate?: InputMaybe<Customer_Addresses_Aggregate_Order_By>;
  customerBillingSettings_aggregate?: InputMaybe<Customer_Billing_Settings_Aggregate_Order_By>;
  customerDeliveryAvailabilities_aggregate?: InputMaybe<Customer_Delivery_Availability_Aggregate_Order_By>;
  customerGroupPricings_aggregate?: InputMaybe<Customer_Group_Pricing_Aggregate_Order_By>;
  customerGroups_aggregate?: InputMaybe<Customer_Groups_Aggregate_Order_By>;
  customerInvoiceOrders_aggregate?: InputMaybe<Customer_Invoice_Orders_Aggregate_Order_By>;
  customerInvoiceVersions_aggregate?: InputMaybe<Customer_Invoice_Versions_Aggregate_Order_By>;
  customerInvoices_aggregate?: InputMaybe<Customer_Invoices_Aggregate_Order_By>;
  customerOrderContainerReturnItems_aggregate?: InputMaybe<Customer_Order_Container_Return_Items_Aggregate_Order_By>;
  customerOrderContainerReturns_aggregate?: InputMaybe<Customer_Order_Container_Returns_Aggregate_Order_By>;
  customerOrderItemAdjustments_aggregate?: InputMaybe<Customer_Order_Item_Adjustments_Aggregate_Order_By>;
  customerOrderItemContainerExpectations_aggregate?: InputMaybe<Customer_Order_Item_Container_Expectations_Aggregate_Order_By>;
  customerOrderItemFulfillmentPlans_aggregate?: InputMaybe<Customer_Order_Item_Fulfillment_Plans_Aggregate_Order_By>;
  customerOrderItemReturnCauses_aggregate?: InputMaybe<Customer_Order_Item_Return_Causes_Aggregate_Order_By>;
  customerOrderItems_aggregate?: InputMaybe<Customer_Order_Items_Aggregate_Order_By>;
  customerOrderPickedContainers_aggregate?: InputMaybe<Customer_Order_Picked_Container_Aggregate_Order_By>;
  customerOrderReturnItemContainers_aggregate?: InputMaybe<Customer_Order_Return_Item_Containers_Aggregate_Order_By>;
  customerOrderReturnItems_aggregate?: InputMaybe<Customer_Order_Return_Items_Aggregate_Order_By>;
  customerOrderReturns_aggregate?: InputMaybe<Customer_Order_Returns_Aggregate_Order_By>;
  customerOrders_aggregate?: InputMaybe<Customer_Orders_Aggregate_Order_By>;
  customerPaymentTerms_aggregate?: InputMaybe<Customer_Payment_Terms_Aggregate_Order_By>;
  customerProductAvailabilities_aggregate?: InputMaybe<Customer_Product_Availabilities_Aggregate_Order_By>;
  customerTemplateSettings_aggregate?: InputMaybe<Customer_Template_Settings_Aggregate_Order_By>;
  customers_aggregate?: InputMaybe<Customers_Aggregate_Order_By>;
  cuttingPatternOutputsDrafts_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Draft_Aggregate_Order_By>;
  cuttingPatternOutputs_aggregate?: InputMaybe<Cutting_Pattern_Outputs_Aggregate_Order_By>;
  cuttingPatternsDrafts_aggregate?: InputMaybe<Cutting_Patterns_Draft_Aggregate_Order_By>;
  cuttingPatterns_aggregate?: InputMaybe<Cutting_Patterns_Aggregate_Order_By>;
  dismantlingPlanCuttingPatternChoices_aggregate?: InputMaybe<Dismantling_Plan_Cutting_Pattern_Choices_Aggregate_Order_By>;
  dismantlingPlanDemands_aggregate?: InputMaybe<Dismantling_Plan_Demands_Aggregate_Order_By>;
  dismantlingPlanInstructions_aggregate?: InputMaybe<Dismantling_Plan_Instructions_Aggregate_Order_By>;
  dismantlingPlanRemainingPieces_aggregate?: InputMaybe<Dismantling_Plan_Remaining_Pieces_Aggregate_Order_By>;
  dismantlingPlans_aggregate?: InputMaybe<Dismantling_Plans_Aggregate_Order_By>;
  ediDeliveryNoteTypes_aggregate?: InputMaybe<Edi_Delivery_Note_Types_Aggregate_Order_By>;
  ediInvoiceTypes_aggregate?: InputMaybe<Edi_Invoice_Types_Aggregate_Order_By>;
  emailFrom?: InputMaybe<Order_By>;
  emails_aggregate?: InputMaybe<Emails_Aggregate_Order_By>;
  exchangeRates_aggregate?: InputMaybe<Exchange_Rates_Aggregate_Order_By>;
  gs1LogisticTypes_aggregate?: InputMaybe<Gs1_Logistic_Types_Aggregate_Order_By>;
  hardwareDevices_aggregate?: InputMaybe<Hardware_Devices_Aggregate_Order_By>;
  hierarchicalGoodsIncomes_aggregate?: InputMaybe<Hierarchical_Goods_Incomes_Aggregate_Order_By>;
  images_aggregate?: InputMaybe<Images_Aggregate_Order_By>;
  journalEntries_aggregate?: InputMaybe<Journal_Entries_Aggregate_Order_By>;
  jscriptTemplates_aggregate?: InputMaybe<Jscript_Templates_Aggregate_Order_By>;
  leichmehlTemplateTypes_aggregate?: InputMaybe<Leichmehl_Template_Types_Aggregate_Order_By>;
  leichmehlTemplates_aggregate?: InputMaybe<Leichmehl_Templates_Aggregate_Order_By>;
  lotNumberProducts_aggregate?: InputMaybe<Lot_Number_Products_Aggregate_Order_By>;
  lotNumbers_aggregate?: InputMaybe<Lot_Numbers_Aggregate_Order_By>;
  mainLanguage?: InputMaybe<Tenant_Languages_Order_By>;
  mainLanguageId?: InputMaybe<Order_By>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  partialDeliveries_aggregate?: InputMaybe<Partial_Deliveries_Aggregate_Order_By>;
  phones_aggregate?: InputMaybe<Phones_Aggregate_Order_By>;
  pickedItemContainers_aggregate?: InputMaybe<Picked_Item_Containers_Aggregate_Order_By>;
  pickedItems_aggregate?: InputMaybe<Picked_Items_Aggregate_Order_By>;
  pickingCategories_aggregate?: InputMaybe<Picking_Categories_Aggregate_Order_By>;
  pickingDeviationReasons_aggregate?: InputMaybe<Picking_Deviation_Reasons_Aggregate_Order_By>;
  postings_aggregate?: InputMaybe<Postings_Aggregate_Order_By>;
  priceLists_aggregate?: InputMaybe<Price_Lists_Aggregate_Order_By>;
  productAllowedContainers_aggregate?: InputMaybe<Product_Allowed_Containers_Aggregate_Order_By>;
  productGroupMembers_aggregate?: InputMaybe<Product_Group_Members_Aggregate_Order_By>;
  productGroups_aggregate?: InputMaybe<Product_Groups_Aggregate_Order_By>;
  productLabelSettings_aggregate?: InputMaybe<Product_Label_Settings_Aggregate_Order_By>;
  productPackagingConfigurations_aggregate?: InputMaybe<Product_Packaging_Configurations_Aggregate_Order_By>;
  productRecipeIngredients_aggregate?: InputMaybe<Product_Recipe_Ingredients_Aggregate_Order_By>;
  productRecipeSteps_aggregate?: InputMaybe<Product_Recipe_Steps_Aggregate_Order_By>;
  productRecipes_aggregate?: InputMaybe<Product_Recipes_Aggregate_Order_By>;
  productS3Objects_aggregate?: InputMaybe<Product_S3_Objects_Aggregate_Order_By>;
  productSalesChannelMembers_aggregate?: InputMaybe<Product_Sales_Channel_Members_Aggregate_Order_By>;
  productStockLocations_aggregate?: InputMaybe<Product_Stock_Locations_Aggregate_Order_By>;
  productSuppliers_aggregate?: InputMaybe<Product_Suppliers_Aggregate_Order_By>;
  productUnitSalesPriceConfigs_aggregate?: InputMaybe<Product_Unit_Sales_Price_Config_Aggregate_Order_By>;
  productUnits_aggregate?: InputMaybe<Product_Units_Aggregate_Order_By>;
  productionSiteInputStockLocations_aggregate?: InputMaybe<Production_Site_Input_Stock_Locations_Aggregate_Order_By>;
  productionSiteProductGroupCalendars_aggregate?: InputMaybe<Production_Site_Product_Group_Calendar_Aggregate_Order_By>;
  productionSites_aggregate?: InputMaybe<Production_Sites_Aggregate_Order_By>;
  products_aggregate?: InputMaybe<Products_Aggregate_Order_By>;
  rollingInventories_aggregate?: InputMaybe<Rolling_Inventories_Aggregate_Order_By>;
  rollingInventoryActors_aggregate?: InputMaybe<Rolling_Inventory_Actors_Aggregate_Order_By>;
  rollingInventoryItems_aggregate?: InputMaybe<Rolling_Inventory_Items_Aggregate_Order_By>;
  s3Objects_aggregate?: InputMaybe<S3_Objects_Aggregate_Order_By>;
  salesChannels_aggregate?: InputMaybe<Sales_Channels_Aggregate_Order_By>;
  salesTeamUsers_aggregate?: InputMaybe<Sales_Team_Users_Aggregate_Order_By>;
  salesTeams_aggregate?: InputMaybe<Sales_Teams_Aggregate_Order_By>;
  shortName?: InputMaybe<Order_By>;
  stockLocations_aggregate?: InputMaybe<Stock_Locations_Aggregate_Order_By>;
  stockTransactions_aggregate?: InputMaybe<Stock_Transactions_Aggregate_Order_By>;
  supplierAddresses_aggregate?: InputMaybe<Supplier_Addresses_Aggregate_Order_By>;
  supplierOrderGoodsIncomeItemContainers_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Item_Containers_Aggregate_Order_By>;
  supplierOrderGoodsIncomeItems_aggregate?: InputMaybe<Supplier_Order_Goods_Income_Items_Aggregate_Order_By>;
  supplierOrderItemReturnCauses_aggregate?: InputMaybe<Supplier_Order_Item_Return_Causes_Aggregate_Order_By>;
  supplierOrderItems_aggregate?: InputMaybe<Supplier_Order_Items_Aggregate_Order_By>;
  supplierOrderReturnItemContainers_aggregate?: InputMaybe<Supplier_Order_Return_Item_Containers_Aggregate_Order_By>;
  supplierOrderReturnItems_aggregate?: InputMaybe<Supplier_Order_Return_Items_Aggregate_Order_By>;
  supplierOrderReturnedContainers_aggregate?: InputMaybe<Supplier_Order_Returned_Containers_Aggregate_Order_By>;
  supplierOrderReturns_aggregate?: InputMaybe<Supplier_Order_Returns_Aggregate_Order_By>;
  supplierOrders_aggregate?: InputMaybe<Supplier_Orders_Aggregate_Order_By>;
  suppliers_aggregate?: InputMaybe<Suppliers_Aggregate_Order_By>;
  temporalProducts_aggregate?: InputMaybe<Temporal_Products_Aggregate_Order_By>;
  temporalSalesTeamsCustomers_aggregate?: InputMaybe<Temporal_Sales_Teams_Customers_Aggregate_Order_By>;
  temporalVatTypes_aggregate?: InputMaybe<Temporal_Vat_Types_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
  tenantAddress?: InputMaybe<Tenant_Addresses_Order_By>;
  tenantAddressId?: InputMaybe<Order_By>;
  tenantAddresses_aggregate?: InputMaybe<Tenant_Addresses_Aggregate_Order_By>;
  tenantBankAccounts_aggregate?: InputMaybe<Tenant_Bank_Accounts_Aggregate_Order_By>;
  tenantCompanyDetail?: InputMaybe<Tenant_Company_Details_Order_By>;
  tenantCompanyDetailId?: InputMaybe<Order_By>;
  tenantCompanyDetails_aggregate?: InputMaybe<Tenant_Company_Details_Aggregate_Order_By>;
  tenantConfigurations_aggregate?: InputMaybe<Tenant_Configurations_Aggregate_Order_By>;
  tenantLanguages_aggregate?: InputMaybe<Tenant_Languages_Aggregate_Order_By>;
  tenantName?: InputMaybe<Order_By>;
  tenantPermissions_aggregate?: InputMaybe<Tenant_Permissions_Aggregate_Order_By>;
  todos_aggregate?: InputMaybe<Todos_Aggregate_Order_By>;
  tourRoutes_aggregate?: InputMaybe<Tour_Routes_Aggregate_Order_By>;
  tours_aggregate?: InputMaybe<Tours_Aggregate_Order_By>;
  userSalesGoals_aggregate?: InputMaybe<User_Sales_Goals_Aggregate_Order_By>;
  users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  vatTypes_aggregate?: InputMaybe<Vat_Types_Aggregate_Order_By>;
  workCalendars_aggregate?: InputMaybe<Work_Calendar_Aggregate_Order_By>;
  workstationHardwareDeviceConnections_aggregate?: InputMaybe<Workstation_Hardware_Device_Connections_Aggregate_Order_By>;
  workstationPickingCategories_aggregate?: InputMaybe<Workstation_Picking_Categories_Aggregate_Order_By>;
  workstations_aggregate?: InputMaybe<Workstations_Aggregate_Order_By>;
};

/** select columns of table "tenants" */
export enum Tenants_Select_Column {
  /** column name */
  CompanyLogoImageId = 'companyLogoImageId',
  /** column name */
  EmailFrom = 'emailFrom',
  /** column name */
  MainLanguageId = 'mainLanguageId',
  /** column name */
  ShortName = 'shortName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  TenantAddressId = 'tenantAddressId',
  /** column name */
  TenantCompanyDetailId = 'tenantCompanyDetailId',
  /** column name */
  TenantName = 'tenantName'
}

/** aggregate stddev on columns */
export type Tenants_Stddev_Fields = {
  __typename: 'tenants_stddev_fields';
  companyLogoImageId?: Maybe<Scalars['Float']>;
  mainLanguageId?: Maybe<Scalars['Float']>;
  tenantAddressId?: Maybe<Scalars['Float']>;
  tenantCompanyDetailId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tenants" */
export type Tenants_Stddev_Order_By = {
  companyLogoImageId?: InputMaybe<Order_By>;
  mainLanguageId?: InputMaybe<Order_By>;
  tenantAddressId?: InputMaybe<Order_By>;
  tenantCompanyDetailId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tenants_Stddev_Pop_Fields = {
  __typename: 'tenants_stddev_pop_fields';
  companyLogoImageId?: Maybe<Scalars['Float']>;
  mainLanguageId?: Maybe<Scalars['Float']>;
  tenantAddressId?: Maybe<Scalars['Float']>;
  tenantCompanyDetailId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tenants" */
export type Tenants_Stddev_Pop_Order_By = {
  companyLogoImageId?: InputMaybe<Order_By>;
  mainLanguageId?: InputMaybe<Order_By>;
  tenantAddressId?: InputMaybe<Order_By>;
  tenantCompanyDetailId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tenants_Stddev_Samp_Fields = {
  __typename: 'tenants_stddev_samp_fields';
  companyLogoImageId?: Maybe<Scalars['Float']>;
  mainLanguageId?: Maybe<Scalars['Float']>;
  tenantAddressId?: Maybe<Scalars['Float']>;
  tenantCompanyDetailId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tenants" */
export type Tenants_Stddev_Samp_Order_By = {
  companyLogoImageId?: InputMaybe<Order_By>;
  mainLanguageId?: InputMaybe<Order_By>;
  tenantAddressId?: InputMaybe<Order_By>;
  tenantCompanyDetailId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tenants" */
export type Tenants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenants_Stream_Cursor_Value_Input = {
  companyLogoImageId?: InputMaybe<Scalars['bigint']>;
  emailFrom?: InputMaybe<Scalars['String']>;
  mainLanguageId?: InputMaybe<Scalars['bigint']>;
  shortName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  tenantAddressId?: InputMaybe<Scalars['bigint']>;
  tenantCompanyDetailId?: InputMaybe<Scalars['bigint']>;
  tenantName?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Tenants_Sum_Fields = {
  __typename: 'tenants_sum_fields';
  companyLogoImageId?: Maybe<Scalars['bigint']>;
  mainLanguageId?: Maybe<Scalars['bigint']>;
  tenantAddressId?: Maybe<Scalars['bigint']>;
  tenantCompanyDetailId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "tenants" */
export type Tenants_Sum_Order_By = {
  companyLogoImageId?: InputMaybe<Order_By>;
  mainLanguageId?: InputMaybe<Order_By>;
  tenantAddressId?: InputMaybe<Order_By>;
  tenantCompanyDetailId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Tenants_Var_Pop_Fields = {
  __typename: 'tenants_var_pop_fields';
  companyLogoImageId?: Maybe<Scalars['Float']>;
  mainLanguageId?: Maybe<Scalars['Float']>;
  tenantAddressId?: Maybe<Scalars['Float']>;
  tenantCompanyDetailId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tenants" */
export type Tenants_Var_Pop_Order_By = {
  companyLogoImageId?: InputMaybe<Order_By>;
  mainLanguageId?: InputMaybe<Order_By>;
  tenantAddressId?: InputMaybe<Order_By>;
  tenantCompanyDetailId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tenants_Var_Samp_Fields = {
  __typename: 'tenants_var_samp_fields';
  companyLogoImageId?: Maybe<Scalars['Float']>;
  mainLanguageId?: Maybe<Scalars['Float']>;
  tenantAddressId?: Maybe<Scalars['Float']>;
  tenantCompanyDetailId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tenants" */
export type Tenants_Var_Samp_Order_By = {
  companyLogoImageId?: InputMaybe<Order_By>;
  mainLanguageId?: InputMaybe<Order_By>;
  tenantAddressId?: InputMaybe<Order_By>;
  tenantCompanyDetailId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tenants_Variance_Fields = {
  __typename: 'tenants_variance_fields';
  companyLogoImageId?: Maybe<Scalars['Float']>;
  mainLanguageId?: Maybe<Scalars['Float']>;
  tenantAddressId?: Maybe<Scalars['Float']>;
  tenantCompanyDetailId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tenants" */
export type Tenants_Variance_Order_By = {
  companyLogoImageId?: InputMaybe<Order_By>;
  mainLanguageId?: InputMaybe<Order_By>;
  tenantAddressId?: InputMaybe<Order_By>;
  tenantCompanyDetailId?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type Time_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['time']>;
  _gt?: InputMaybe<Scalars['time']>;
  _gte?: InputMaybe<Scalars['time']>;
  _in?: InputMaybe<Array<Scalars['time']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['time']>;
  _lte?: InputMaybe<Scalars['time']>;
  _neq?: InputMaybe<Scalars['time']>;
  _nin?: InputMaybe<Array<Scalars['time']>>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "todo_actions" */
export type Todo_Actions = {
  __typename: 'todo_actions';
  /** An array relationship */
  todos: Array<Todos>;
  /** An aggregate relationship */
  todos_aggregate: Todos_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "todo_actions" */
export type Todo_ActionsTodosArgs = {
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


/** columns and relationships of "todo_actions" */
export type Todo_ActionsTodos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};

/** aggregated selection of "todo_actions" */
export type Todo_Actions_Aggregate = {
  __typename: 'todo_actions_aggregate';
  aggregate?: Maybe<Todo_Actions_Aggregate_Fields>;
  nodes: Array<Todo_Actions>;
};

/** aggregate fields of "todo_actions" */
export type Todo_Actions_Aggregate_Fields = {
  __typename: 'todo_actions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Todo_Actions_Max_Fields>;
  min?: Maybe<Todo_Actions_Min_Fields>;
};


/** aggregate fields of "todo_actions" */
export type Todo_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Todo_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "todo_actions". All fields are combined with a logical 'AND'. */
export type Todo_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Todo_Actions_Bool_Exp>>;
  _not?: InputMaybe<Todo_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Todo_Actions_Bool_Exp>>;
  todos?: InputMaybe<Todos_Bool_Exp>;
  todos_aggregate?: InputMaybe<Todos_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Todo_Actions_Enum {
  Call = 'CALL',
  Other = 'OTHER'
}

/** Boolean expression to compare columns of type "todo_actions_enum". All fields are combined with logical 'AND'. */
export type Todo_Actions_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Todo_Actions_Enum>;
  _in?: InputMaybe<Array<Todo_Actions_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Todo_Actions_Enum>;
  _nin?: InputMaybe<Array<Todo_Actions_Enum>>;
};

/** aggregate max on columns */
export type Todo_Actions_Max_Fields = {
  __typename: 'todo_actions_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Todo_Actions_Min_Fields = {
  __typename: 'todo_actions_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "todo_actions". */
export type Todo_Actions_Order_By = {
  todos_aggregate?: InputMaybe<Todos_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "todo_actions" */
export enum Todo_Actions_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "todo_actions" */
export type Todo_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Todo_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Todo_Actions_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "todo_recurrences" */
export type Todo_Recurrences = {
  __typename: 'todo_recurrences';
  /** An array relationship */
  todos: Array<Todos>;
  /** An aggregate relationship */
  todos_aggregate: Todos_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "todo_recurrences" */
export type Todo_RecurrencesTodosArgs = {
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


/** columns and relationships of "todo_recurrences" */
export type Todo_RecurrencesTodos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};

/** aggregated selection of "todo_recurrences" */
export type Todo_Recurrences_Aggregate = {
  __typename: 'todo_recurrences_aggregate';
  aggregate?: Maybe<Todo_Recurrences_Aggregate_Fields>;
  nodes: Array<Todo_Recurrences>;
};

/** aggregate fields of "todo_recurrences" */
export type Todo_Recurrences_Aggregate_Fields = {
  __typename: 'todo_recurrences_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Todo_Recurrences_Max_Fields>;
  min?: Maybe<Todo_Recurrences_Min_Fields>;
};


/** aggregate fields of "todo_recurrences" */
export type Todo_Recurrences_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Todo_Recurrences_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "todo_recurrences". All fields are combined with a logical 'AND'. */
export type Todo_Recurrences_Bool_Exp = {
  _and?: InputMaybe<Array<Todo_Recurrences_Bool_Exp>>;
  _not?: InputMaybe<Todo_Recurrences_Bool_Exp>;
  _or?: InputMaybe<Array<Todo_Recurrences_Bool_Exp>>;
  todos?: InputMaybe<Todos_Bool_Exp>;
  todos_aggregate?: InputMaybe<Todos_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Todo_Recurrences_Enum {
  Biweekly = 'BIWEEKLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Never = 'NEVER',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

/** Boolean expression to compare columns of type "todo_recurrences_enum". All fields are combined with logical 'AND'. */
export type Todo_Recurrences_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Todo_Recurrences_Enum>;
  _in?: InputMaybe<Array<Todo_Recurrences_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Todo_Recurrences_Enum>;
  _nin?: InputMaybe<Array<Todo_Recurrences_Enum>>;
};

/** aggregate max on columns */
export type Todo_Recurrences_Max_Fields = {
  __typename: 'todo_recurrences_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Todo_Recurrences_Min_Fields = {
  __typename: 'todo_recurrences_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "todo_recurrences". */
export type Todo_Recurrences_Order_By = {
  todos_aggregate?: InputMaybe<Todos_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "todo_recurrences" */
export enum Todo_Recurrences_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "todo_recurrences" */
export type Todo_Recurrences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Todo_Recurrences_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Todo_Recurrences_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "todo_states" */
export type Todo_States = {
  __typename: 'todo_states';
  /** An array relationship */
  todos: Array<Todos>;
  /** An aggregate relationship */
  todos_aggregate: Todos_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "todo_states" */
export type Todo_StatesTodosArgs = {
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


/** columns and relationships of "todo_states" */
export type Todo_StatesTodos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};

/** aggregated selection of "todo_states" */
export type Todo_States_Aggregate = {
  __typename: 'todo_states_aggregate';
  aggregate?: Maybe<Todo_States_Aggregate_Fields>;
  nodes: Array<Todo_States>;
};

/** aggregate fields of "todo_states" */
export type Todo_States_Aggregate_Fields = {
  __typename: 'todo_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Todo_States_Max_Fields>;
  min?: Maybe<Todo_States_Min_Fields>;
};


/** aggregate fields of "todo_states" */
export type Todo_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Todo_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "todo_states". All fields are combined with a logical 'AND'. */
export type Todo_States_Bool_Exp = {
  _and?: InputMaybe<Array<Todo_States_Bool_Exp>>;
  _not?: InputMaybe<Todo_States_Bool_Exp>;
  _or?: InputMaybe<Array<Todo_States_Bool_Exp>>;
  todos?: InputMaybe<Todos_Bool_Exp>;
  todos_aggregate?: InputMaybe<Todos_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Todo_States_Enum {
  Done = 'DONE',
  Todo = 'TODO'
}

/** Boolean expression to compare columns of type "todo_states_enum". All fields are combined with logical 'AND'. */
export type Todo_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Todo_States_Enum>;
  _in?: InputMaybe<Array<Todo_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Todo_States_Enum>;
  _nin?: InputMaybe<Array<Todo_States_Enum>>;
};

/** aggregate max on columns */
export type Todo_States_Max_Fields = {
  __typename: 'todo_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Todo_States_Min_Fields = {
  __typename: 'todo_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "todo_states". */
export type Todo_States_Order_By = {
  todos_aggregate?: InputMaybe<Todos_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "todo_states" */
export enum Todo_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "todo_states" */
export type Todo_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Todo_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Todo_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "todos" */
export type Todos = {
  __typename: 'todos';
  /** An object relationship */
  Tenant: Tenants;
  action: Todo_Actions_Enum;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customer?: Maybe<Customers>;
  customerId?: Maybe<Scalars['bigint']>;
  dueDate: Scalars['date'];
  id: Scalars['bigint'];
  notes?: Maybe<Scalars['String']>;
  recurrence: Todo_Recurrences_Enum;
  state: Todo_States_Enum;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  userId: Scalars['bigint'];
  version: Scalars['bigint'];
};

/** aggregated selection of "todos" */
export type Todos_Aggregate = {
  __typename: 'todos_aggregate';
  aggregate?: Maybe<Todos_Aggregate_Fields>;
  nodes: Array<Todos>;
};

export type Todos_Aggregate_Bool_Exp = {
  count?: InputMaybe<Todos_Aggregate_Bool_Exp_Count>;
};

export type Todos_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Todos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Todos_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "todos" */
export type Todos_Aggregate_Fields = {
  __typename: 'todos_aggregate_fields';
  avg?: Maybe<Todos_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Todos_Max_Fields>;
  min?: Maybe<Todos_Min_Fields>;
  stddev?: Maybe<Todos_Stddev_Fields>;
  stddev_pop?: Maybe<Todos_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Todos_Stddev_Samp_Fields>;
  sum?: Maybe<Todos_Sum_Fields>;
  var_pop?: Maybe<Todos_Var_Pop_Fields>;
  var_samp?: Maybe<Todos_Var_Samp_Fields>;
  variance?: Maybe<Todos_Variance_Fields>;
};


/** aggregate fields of "todos" */
export type Todos_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Todos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "todos" */
export type Todos_Aggregate_Order_By = {
  avg?: InputMaybe<Todos_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Todos_Max_Order_By>;
  min?: InputMaybe<Todos_Min_Order_By>;
  stddev?: InputMaybe<Todos_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Todos_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Todos_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Todos_Sum_Order_By>;
  var_pop?: InputMaybe<Todos_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Todos_Var_Samp_Order_By>;
  variance?: InputMaybe<Todos_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "todos" */
export type Todos_Arr_Rel_Insert_Input = {
  data: Array<Todos_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Todos_On_Conflict>;
};

/** aggregate avg on columns */
export type Todos_Avg_Fields = {
  __typename: 'todos_avg_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "todos" */
export type Todos_Avg_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "todos". All fields are combined with a logical 'AND'. */
export type Todos_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Todos_Bool_Exp>>;
  _not?: InputMaybe<Todos_Bool_Exp>;
  _or?: InputMaybe<Array<Todos_Bool_Exp>>;
  action?: InputMaybe<Todo_Actions_Enum_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer?: InputMaybe<Customers_Bool_Exp>;
  customerId?: InputMaybe<Bigint_Comparison_Exp>;
  dueDate?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  recurrence?: InputMaybe<Todo_Recurrences_Enum_Comparison_Exp>;
  state?: InputMaybe<Todo_States_Enum_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "todos" */
export enum Todos_Constraint {
  /** unique or primary key constraint on columns "id" */
  TodosPkey = 'todos_pkey'
}

/** input type for incrementing numeric columns in table "todos" */
export type Todos_Inc_Input = {
  customerId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  userId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "todos" */
export type Todos_Insert_Input = {
  action?: InputMaybe<Todo_Actions_Enum>;
  customer?: InputMaybe<Customers_Obj_Rel_Insert_Input>;
  customerId?: InputMaybe<Scalars['bigint']>;
  dueDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  recurrence?: InputMaybe<Todo_Recurrences_Enum>;
  state?: InputMaybe<Todo_States_Enum>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Todos_Max_Fields = {
  __typename: 'todos_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerId?: Maybe<Scalars['bigint']>;
  dueDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "todos" */
export type Todos_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  dueDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Todos_Min_Fields = {
  __typename: 'todos_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerId?: Maybe<Scalars['bigint']>;
  dueDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "todos" */
export type Todos_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  dueDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "todos" */
export type Todos_Mutation_Response = {
  __typename: 'todos_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Todos>;
};

/** on_conflict condition type for table "todos" */
export type Todos_On_Conflict = {
  constraint: Todos_Constraint;
  update_columns?: Array<Todos_Update_Column>;
  where?: InputMaybe<Todos_Bool_Exp>;
};

export type Todos_On_Day_Args = {
  day?: InputMaybe<Scalars['date']>;
};

/** Ordering options when selecting data from "todos". */
export type Todos_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  action?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customer?: InputMaybe<Customers_Order_By>;
  customerId?: InputMaybe<Order_By>;
  dueDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  recurrence?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: todos */
export type Todos_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "todos" */
export enum Todos_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  DueDate = 'dueDate',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  Recurrence = 'recurrence',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "todos" */
export type Todos_Set_Input = {
  action?: InputMaybe<Todo_Actions_Enum>;
  customerId?: InputMaybe<Scalars['bigint']>;
  dueDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  recurrence?: InputMaybe<Todo_Recurrences_Enum>;
  state?: InputMaybe<Todo_States_Enum>;
  userId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Todos_Stddev_Fields = {
  __typename: 'todos_stddev_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "todos" */
export type Todos_Stddev_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Todos_Stddev_Pop_Fields = {
  __typename: 'todos_stddev_pop_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "todos" */
export type Todos_Stddev_Pop_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Todos_Stddev_Samp_Fields = {
  __typename: 'todos_stddev_samp_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "todos" */
export type Todos_Stddev_Samp_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "todos" */
export type Todos_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Todos_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Todos_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Todo_Actions_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerId?: InputMaybe<Scalars['bigint']>;
  dueDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  recurrence?: InputMaybe<Todo_Recurrences_Enum>;
  state?: InputMaybe<Todo_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Todos_Sum_Fields = {
  __typename: 'todos_sum_fields';
  customerId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "todos" */
export type Todos_Sum_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "todos" */
export enum Todos_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  DueDate = 'dueDate',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  Recurrence = 'recurrence',
  /** column name */
  State = 'state',
  /** column name */
  UserId = 'userId',
  /** column name */
  Version = 'version'
}

export type Todos_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Todos_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Todos_Set_Input>;
  /** filter the rows which have to be updated */
  where: Todos_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Todos_Var_Pop_Fields = {
  __typename: 'todos_var_pop_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "todos" */
export type Todos_Var_Pop_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Todos_Var_Samp_Fields = {
  __typename: 'todos_var_samp_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "todos" */
export type Todos_Var_Samp_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Todos_Variance_Fields = {
  __typename: 'todos_variance_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "todos" */
export type Todos_Variance_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

export type Topologically_Sorted_Cutting_Patterns_Args = {
  _root_cp_product_id?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "topologically_sorted_cutting_patterns_results" */
export type Topologically_Sorted_Cutting_Patterns_Results = {
  __typename: 'topologically_sorted_cutting_patterns_results';
  /** An object relationship */
  cuttingPattern: Cutting_Patterns;
  cuttingPatternId: Scalars['bigint'];
  cuttingPatternName?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "topologically_sorted_cutting_patterns_results" */
export type Topologically_Sorted_Cutting_Patterns_Results_Aggregate = {
  __typename: 'topologically_sorted_cutting_patterns_results_aggregate';
  aggregate?: Maybe<Topologically_Sorted_Cutting_Patterns_Results_Aggregate_Fields>;
  nodes: Array<Topologically_Sorted_Cutting_Patterns_Results>;
};

export type Topologically_Sorted_Cutting_Patterns_Results_Aggregate_Bool_Exp = {
  count?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Aggregate_Bool_Exp_Count>;
};

export type Topologically_Sorted_Cutting_Patterns_Results_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "topologically_sorted_cutting_patterns_results" */
export type Topologically_Sorted_Cutting_Patterns_Results_Aggregate_Fields = {
  __typename: 'topologically_sorted_cutting_patterns_results_aggregate_fields';
  avg?: Maybe<Topologically_Sorted_Cutting_Patterns_Results_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Topologically_Sorted_Cutting_Patterns_Results_Max_Fields>;
  min?: Maybe<Topologically_Sorted_Cutting_Patterns_Results_Min_Fields>;
  stddev?: Maybe<Topologically_Sorted_Cutting_Patterns_Results_Stddev_Fields>;
  stddev_pop?: Maybe<Topologically_Sorted_Cutting_Patterns_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Topologically_Sorted_Cutting_Patterns_Results_Stddev_Samp_Fields>;
  sum?: Maybe<Topologically_Sorted_Cutting_Patterns_Results_Sum_Fields>;
  var_pop?: Maybe<Topologically_Sorted_Cutting_Patterns_Results_Var_Pop_Fields>;
  var_samp?: Maybe<Topologically_Sorted_Cutting_Patterns_Results_Var_Samp_Fields>;
  variance?: Maybe<Topologically_Sorted_Cutting_Patterns_Results_Variance_Fields>;
};


/** aggregate fields of "topologically_sorted_cutting_patterns_results" */
export type Topologically_Sorted_Cutting_Patterns_Results_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "topologically_sorted_cutting_patterns_results" */
export type Topologically_Sorted_Cutting_Patterns_Results_Aggregate_Order_By = {
  avg?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Max_Order_By>;
  min?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Min_Order_By>;
  stddev?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Sum_Order_By>;
  var_pop?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Var_Samp_Order_By>;
  variance?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Topologically_Sorted_Cutting_Patterns_Results_Avg_Fields = {
  __typename: 'topologically_sorted_cutting_patterns_results_avg_fields';
  cuttingPatternId?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "topologically_sorted_cutting_patterns_results" */
export type Topologically_Sorted_Cutting_Patterns_Results_Avg_Order_By = {
  cuttingPatternId?: InputMaybe<Order_By>;
  depth?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "topologically_sorted_cutting_patterns_results". All fields are combined with a logical 'AND'. */
export type Topologically_Sorted_Cutting_Patterns_Results_Bool_Exp = {
  _and?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Bool_Exp>>;
  _not?: InputMaybe<Topologically_Sorted_Cutting_Patterns_Results_Bool_Exp>;
  _or?: InputMaybe<Array<Topologically_Sorted_Cutting_Patterns_Results_Bool_Exp>>;
  cuttingPattern?: InputMaybe<Cutting_Patterns_Bool_Exp>;
  cuttingPatternId?: InputMaybe<Bigint_Comparison_Exp>;
  cuttingPatternName?: InputMaybe<String_Comparison_Exp>;
  depth?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Topologically_Sorted_Cutting_Patterns_Results_Max_Fields = {
  __typename: 'topologically_sorted_cutting_patterns_results_max_fields';
  cuttingPatternId?: Maybe<Scalars['bigint']>;
  cuttingPatternName?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "topologically_sorted_cutting_patterns_results" */
export type Topologically_Sorted_Cutting_Patterns_Results_Max_Order_By = {
  cuttingPatternId?: InputMaybe<Order_By>;
  cuttingPatternName?: InputMaybe<Order_By>;
  depth?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Topologically_Sorted_Cutting_Patterns_Results_Min_Fields = {
  __typename: 'topologically_sorted_cutting_patterns_results_min_fields';
  cuttingPatternId?: Maybe<Scalars['bigint']>;
  cuttingPatternName?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "topologically_sorted_cutting_patterns_results" */
export type Topologically_Sorted_Cutting_Patterns_Results_Min_Order_By = {
  cuttingPatternId?: InputMaybe<Order_By>;
  cuttingPatternName?: InputMaybe<Order_By>;
  depth?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "topologically_sorted_cutting_patterns_results". */
export type Topologically_Sorted_Cutting_Patterns_Results_Order_By = {
  cuttingPattern?: InputMaybe<Cutting_Patterns_Order_By>;
  cuttingPatternId?: InputMaybe<Order_By>;
  cuttingPatternName?: InputMaybe<Order_By>;
  depth?: InputMaybe<Order_By>;
};

/** select columns of table "topologically_sorted_cutting_patterns_results" */
export enum Topologically_Sorted_Cutting_Patterns_Results_Select_Column {
  /** column name */
  CuttingPatternId = 'cuttingPatternId',
  /** column name */
  CuttingPatternName = 'cuttingPatternName',
  /** column name */
  Depth = 'depth'
}

/** aggregate stddev on columns */
export type Topologically_Sorted_Cutting_Patterns_Results_Stddev_Fields = {
  __typename: 'topologically_sorted_cutting_patterns_results_stddev_fields';
  cuttingPatternId?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "topologically_sorted_cutting_patterns_results" */
export type Topologically_Sorted_Cutting_Patterns_Results_Stddev_Order_By = {
  cuttingPatternId?: InputMaybe<Order_By>;
  depth?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Topologically_Sorted_Cutting_Patterns_Results_Stddev_Pop_Fields = {
  __typename: 'topologically_sorted_cutting_patterns_results_stddev_pop_fields';
  cuttingPatternId?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "topologically_sorted_cutting_patterns_results" */
export type Topologically_Sorted_Cutting_Patterns_Results_Stddev_Pop_Order_By = {
  cuttingPatternId?: InputMaybe<Order_By>;
  depth?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Topologically_Sorted_Cutting_Patterns_Results_Stddev_Samp_Fields = {
  __typename: 'topologically_sorted_cutting_patterns_results_stddev_samp_fields';
  cuttingPatternId?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "topologically_sorted_cutting_patterns_results" */
export type Topologically_Sorted_Cutting_Patterns_Results_Stddev_Samp_Order_By = {
  cuttingPatternId?: InputMaybe<Order_By>;
  depth?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "topologically_sorted_cutting_patterns_results" */
export type Topologically_Sorted_Cutting_Patterns_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Topologically_Sorted_Cutting_Patterns_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Topologically_Sorted_Cutting_Patterns_Results_Stream_Cursor_Value_Input = {
  cuttingPatternId?: InputMaybe<Scalars['bigint']>;
  cuttingPatternName?: InputMaybe<Scalars['String']>;
  depth?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Topologically_Sorted_Cutting_Patterns_Results_Sum_Fields = {
  __typename: 'topologically_sorted_cutting_patterns_results_sum_fields';
  cuttingPatternId?: Maybe<Scalars['bigint']>;
  depth?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "topologically_sorted_cutting_patterns_results" */
export type Topologically_Sorted_Cutting_Patterns_Results_Sum_Order_By = {
  cuttingPatternId?: InputMaybe<Order_By>;
  depth?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Topologically_Sorted_Cutting_Patterns_Results_Var_Pop_Fields = {
  __typename: 'topologically_sorted_cutting_patterns_results_var_pop_fields';
  cuttingPatternId?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "topologically_sorted_cutting_patterns_results" */
export type Topologically_Sorted_Cutting_Patterns_Results_Var_Pop_Order_By = {
  cuttingPatternId?: InputMaybe<Order_By>;
  depth?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Topologically_Sorted_Cutting_Patterns_Results_Var_Samp_Fields = {
  __typename: 'topologically_sorted_cutting_patterns_results_var_samp_fields';
  cuttingPatternId?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "topologically_sorted_cutting_patterns_results" */
export type Topologically_Sorted_Cutting_Patterns_Results_Var_Samp_Order_By = {
  cuttingPatternId?: InputMaybe<Order_By>;
  depth?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Topologically_Sorted_Cutting_Patterns_Results_Variance_Fields = {
  __typename: 'topologically_sorted_cutting_patterns_results_variance_fields';
  cuttingPatternId?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "topologically_sorted_cutting_patterns_results" */
export type Topologically_Sorted_Cutting_Patterns_Results_Variance_Order_By = {
  cuttingPatternId?: InputMaybe<Order_By>;
  depth?: InputMaybe<Order_By>;
};

/** columns and relationships of "tour_routes" */
export type Tour_Routes = {
  __typename: 'tour_routes';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customer: Customers;
  customerId: Scalars['bigint'];
  id: Scalars['bigint'];
  tenant: Scalars['String'];
  /** An object relationship */
  tour: Tours;
  tourId: Scalars['bigint'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};

/** aggregated selection of "tour_routes" */
export type Tour_Routes_Aggregate = {
  __typename: 'tour_routes_aggregate';
  aggregate?: Maybe<Tour_Routes_Aggregate_Fields>;
  nodes: Array<Tour_Routes>;
};

export type Tour_Routes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tour_Routes_Aggregate_Bool_Exp_Count>;
};

export type Tour_Routes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tour_Routes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tour_Routes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tour_routes" */
export type Tour_Routes_Aggregate_Fields = {
  __typename: 'tour_routes_aggregate_fields';
  avg?: Maybe<Tour_Routes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tour_Routes_Max_Fields>;
  min?: Maybe<Tour_Routes_Min_Fields>;
  stddev?: Maybe<Tour_Routes_Stddev_Fields>;
  stddev_pop?: Maybe<Tour_Routes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tour_Routes_Stddev_Samp_Fields>;
  sum?: Maybe<Tour_Routes_Sum_Fields>;
  var_pop?: Maybe<Tour_Routes_Var_Pop_Fields>;
  var_samp?: Maybe<Tour_Routes_Var_Samp_Fields>;
  variance?: Maybe<Tour_Routes_Variance_Fields>;
};


/** aggregate fields of "tour_routes" */
export type Tour_Routes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tour_Routes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tour_routes" */
export type Tour_Routes_Aggregate_Order_By = {
  avg?: InputMaybe<Tour_Routes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tour_Routes_Max_Order_By>;
  min?: InputMaybe<Tour_Routes_Min_Order_By>;
  stddev?: InputMaybe<Tour_Routes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tour_Routes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tour_Routes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tour_Routes_Sum_Order_By>;
  var_pop?: InputMaybe<Tour_Routes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tour_Routes_Var_Samp_Order_By>;
  variance?: InputMaybe<Tour_Routes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tour_routes" */
export type Tour_Routes_Arr_Rel_Insert_Input = {
  data: Array<Tour_Routes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tour_Routes_On_Conflict>;
};

/** aggregate avg on columns */
export type Tour_Routes_Avg_Fields = {
  __typename: 'tour_routes_avg_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tourId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tour_routes" */
export type Tour_Routes_Avg_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tourId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tour_routes". All fields are combined with a logical 'AND'. */
export type Tour_Routes_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Tour_Routes_Bool_Exp>>;
  _not?: InputMaybe<Tour_Routes_Bool_Exp>;
  _or?: InputMaybe<Array<Tour_Routes_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer?: InputMaybe<Customers_Bool_Exp>;
  customerId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  tour?: InputMaybe<Tours_Bool_Exp>;
  tourId?: InputMaybe<Bigint_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "tour_routes" */
export enum Tour_Routes_Constraint {
  /** unique or primary key constraint on columns "id" */
  TourRoutesPkey = 'tour_routes_pkey',
  /** unique or primary key constraint on columns "tenant", "customer_id" */
  TourRoutesTenantCustomerIdKey = 'tour_routes_tenant_customer_id_key'
}

/** input type for incrementing numeric columns in table "tour_routes" */
export type Tour_Routes_Inc_Input = {
  customerId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  tourId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "tour_routes" */
export type Tour_Routes_Insert_Input = {
  customer?: InputMaybe<Customers_Obj_Rel_Insert_Input>;
  customerId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  tour?: InputMaybe<Tours_Obj_Rel_Insert_Input>;
  tourId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Tour_Routes_Max_Fields = {
  __typename: 'tour_routes_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  tourId?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "tour_routes" */
export type Tour_Routes_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  tourId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tour_Routes_Min_Fields = {
  __typename: 'tour_routes_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  tourId?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "tour_routes" */
export type Tour_Routes_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  tourId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tour_routes" */
export type Tour_Routes_Mutation_Response = {
  __typename: 'tour_routes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tour_Routes>;
};

/** on_conflict condition type for table "tour_routes" */
export type Tour_Routes_On_Conflict = {
  constraint: Tour_Routes_Constraint;
  update_columns?: Array<Tour_Routes_Update_Column>;
  where?: InputMaybe<Tour_Routes_Bool_Exp>;
};

/** Ordering options when selecting data from "tour_routes". */
export type Tour_Routes_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customer?: InputMaybe<Customers_Order_By>;
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  tour?: InputMaybe<Tours_Order_By>;
  tourId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tour_routes */
export type Tour_Routes_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "tour_routes" */
export enum Tour_Routes_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Id = 'id',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  TourId = 'tourId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "tour_routes" */
export type Tour_Routes_Set_Input = {
  customerId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  tourId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Tour_Routes_Stddev_Fields = {
  __typename: 'tour_routes_stddev_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tourId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tour_routes" */
export type Tour_Routes_Stddev_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tourId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tour_Routes_Stddev_Pop_Fields = {
  __typename: 'tour_routes_stddev_pop_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tourId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tour_routes" */
export type Tour_Routes_Stddev_Pop_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tourId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tour_Routes_Stddev_Samp_Fields = {
  __typename: 'tour_routes_stddev_samp_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tourId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tour_routes" */
export type Tour_Routes_Stddev_Samp_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tourId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tour_routes" */
export type Tour_Routes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tour_Routes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tour_Routes_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerId?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  tourId?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Tour_Routes_Sum_Fields = {
  __typename: 'tour_routes_sum_fields';
  customerId?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  tourId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "tour_routes" */
export type Tour_Routes_Sum_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tourId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "tour_routes" */
export enum Tour_Routes_Update_Column {
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Id = 'id',
  /** column name */
  TourId = 'tourId',
  /** column name */
  Version = 'version'
}

export type Tour_Routes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tour_Routes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tour_Routes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tour_Routes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tour_Routes_Var_Pop_Fields = {
  __typename: 'tour_routes_var_pop_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tourId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tour_routes" */
export type Tour_Routes_Var_Pop_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tourId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tour_Routes_Var_Samp_Fields = {
  __typename: 'tour_routes_var_samp_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tourId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tour_routes" */
export type Tour_Routes_Var_Samp_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tourId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tour_Routes_Variance_Fields = {
  __typename: 'tour_routes_variance_fields';
  customerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tourId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tour_routes" */
export type Tour_Routes_Variance_Order_By = {
  customerId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tourId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "tour_state_transitions" */
export type Tour_State_Transitions = {
  __typename: 'tour_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: Tour_States_Enum;
  toState: Tour_States_Enum;
};

/** aggregated selection of "tour_state_transitions" */
export type Tour_State_Transitions_Aggregate = {
  __typename: 'tour_state_transitions_aggregate';
  aggregate?: Maybe<Tour_State_Transitions_Aggregate_Fields>;
  nodes: Array<Tour_State_Transitions>;
};

/** aggregate fields of "tour_state_transitions" */
export type Tour_State_Transitions_Aggregate_Fields = {
  __typename: 'tour_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tour_State_Transitions_Max_Fields>;
  min?: Maybe<Tour_State_Transitions_Min_Fields>;
};


/** aggregate fields of "tour_state_transitions" */
export type Tour_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tour_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tour_state_transitions". All fields are combined with a logical 'AND'. */
export type Tour_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Tour_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<Tour_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<Tour_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<Tour_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<Tour_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type Tour_State_Transitions_Max_Fields = {
  __typename: 'tour_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Tour_State_Transitions_Min_Fields = {
  __typename: 'tour_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "tour_state_transitions". */
export type Tour_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "tour_state_transitions" */
export enum Tour_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "tour_state_transitions" */
export type Tour_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tour_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tour_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<Tour_States_Enum>;
  toState?: InputMaybe<Tour_States_Enum>;
};

/** columns and relationships of "tour_states" */
export type Tour_States = {
  __typename: 'tour_states';
  /** An array relationship */
  tours: Array<Tours>;
  /** An aggregate relationship */
  tours_aggregate: Tours_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "tour_states" */
export type Tour_StatesToursArgs = {
  distinct_on?: InputMaybe<Array<Tours_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tours_Order_By>>;
  where?: InputMaybe<Tours_Bool_Exp>;
};


/** columns and relationships of "tour_states" */
export type Tour_StatesTours_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tours_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tours_Order_By>>;
  where?: InputMaybe<Tours_Bool_Exp>;
};

/** aggregated selection of "tour_states" */
export type Tour_States_Aggregate = {
  __typename: 'tour_states_aggregate';
  aggregate?: Maybe<Tour_States_Aggregate_Fields>;
  nodes: Array<Tour_States>;
};

/** aggregate fields of "tour_states" */
export type Tour_States_Aggregate_Fields = {
  __typename: 'tour_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tour_States_Max_Fields>;
  min?: Maybe<Tour_States_Min_Fields>;
};


/** aggregate fields of "tour_states" */
export type Tour_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tour_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tour_states". All fields are combined with a logical 'AND'. */
export type Tour_States_Bool_Exp = {
  _and?: InputMaybe<Array<Tour_States_Bool_Exp>>;
  _not?: InputMaybe<Tour_States_Bool_Exp>;
  _or?: InputMaybe<Array<Tour_States_Bool_Exp>>;
  tours?: InputMaybe<Tours_Bool_Exp>;
  tours_aggregate?: InputMaybe<Tours_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Tour_States_Enum {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE'
}

/** Boolean expression to compare columns of type "tour_states_enum". All fields are combined with logical 'AND'. */
export type Tour_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Tour_States_Enum>;
  _in?: InputMaybe<Array<Tour_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Tour_States_Enum>;
  _nin?: InputMaybe<Array<Tour_States_Enum>>;
};

/** aggregate max on columns */
export type Tour_States_Max_Fields = {
  __typename: 'tour_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Tour_States_Min_Fields = {
  __typename: 'tour_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "tour_states". */
export type Tour_States_Order_By = {
  tours_aggregate?: InputMaybe<Tours_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "tour_states" */
export enum Tour_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "tour_states" */
export type Tour_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tour_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tour_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "tours" */
export type Tours = {
  __typename: 'tours';
  /** An object relationship */
  Tenant: Tenants;
  allowedTenants: Scalars['jsonb'];
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  customerOrders: Array<Customer_Orders>;
  /** An aggregate relationship */
  customerOrders_aggregate: Customer_Orders_Aggregate;
  departureTime?: Maybe<Scalars['time']>;
  description?: Maybe<Scalars['String']>;
  domainId: Scalars['String'];
  excludeFromTourPlan?: Maybe<Scalars['Boolean']>;
  id: Scalars['bigint'];
  name: Scalars['String'];
  state: Tour_States_Enum;
  tenant: Scalars['String'];
  /** An array relationship */
  tourRoutes: Array<Tour_Routes>;
  /** An aggregate relationship */
  tourRoutes_aggregate: Tour_Routes_Aggregate;
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
};


/** columns and relationships of "tours" */
export type ToursAllowedTenantsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "tours" */
export type ToursCustomerOrdersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** columns and relationships of "tours" */
export type ToursCustomerOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Orders_Order_By>>;
  where?: InputMaybe<Customer_Orders_Bool_Exp>;
};


/** columns and relationships of "tours" */
export type ToursTourRoutesArgs = {
  distinct_on?: InputMaybe<Array<Tour_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tour_Routes_Order_By>>;
  where?: InputMaybe<Tour_Routes_Bool_Exp>;
};


/** columns and relationships of "tours" */
export type ToursTourRoutes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tour_Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tour_Routes_Order_By>>;
  where?: InputMaybe<Tour_Routes_Bool_Exp>;
};

/** aggregated selection of "tours" */
export type Tours_Aggregate = {
  __typename: 'tours_aggregate';
  aggregate?: Maybe<Tours_Aggregate_Fields>;
  nodes: Array<Tours>;
};

export type Tours_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tours_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tours_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tours_Aggregate_Bool_Exp_Count>;
};

export type Tours_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tours_Select_Column_Tours_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tours_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tours_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tours_Select_Column_Tours_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tours_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tours_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tours_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tours_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tours" */
export type Tours_Aggregate_Fields = {
  __typename: 'tours_aggregate_fields';
  avg?: Maybe<Tours_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tours_Max_Fields>;
  min?: Maybe<Tours_Min_Fields>;
  stddev?: Maybe<Tours_Stddev_Fields>;
  stddev_pop?: Maybe<Tours_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tours_Stddev_Samp_Fields>;
  sum?: Maybe<Tours_Sum_Fields>;
  var_pop?: Maybe<Tours_Var_Pop_Fields>;
  var_samp?: Maybe<Tours_Var_Samp_Fields>;
  variance?: Maybe<Tours_Variance_Fields>;
};


/** aggregate fields of "tours" */
export type Tours_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tours_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tours" */
export type Tours_Aggregate_Order_By = {
  avg?: InputMaybe<Tours_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tours_Max_Order_By>;
  min?: InputMaybe<Tours_Min_Order_By>;
  stddev?: InputMaybe<Tours_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tours_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tours_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tours_Sum_Order_By>;
  var_pop?: InputMaybe<Tours_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tours_Var_Samp_Order_By>;
  variance?: InputMaybe<Tours_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tours_Append_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Tours_Avg_Fields = {
  __typename: 'tours_avg_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tours" */
export type Tours_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tours". All fields are combined with a logical 'AND'. */
export type Tours_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Tours_Bool_Exp>>;
  _not?: InputMaybe<Tours_Bool_Exp>;
  _or?: InputMaybe<Array<Tours_Bool_Exp>>;
  allowedTenants?: InputMaybe<Jsonb_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerOrders?: InputMaybe<Customer_Orders_Bool_Exp>;
  customerOrders_aggregate?: InputMaybe<Customer_Orders_Aggregate_Bool_Exp>;
  departureTime?: InputMaybe<Time_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  domainId?: InputMaybe<String_Comparison_Exp>;
  excludeFromTourPlan?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<Tour_States_Enum_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  tourRoutes?: InputMaybe<Tour_Routes_Bool_Exp>;
  tourRoutes_aggregate?: InputMaybe<Tour_Routes_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "tours" */
export enum Tours_Constraint {
  /** unique or primary key constraint on columns "id" */
  ToursPkey = 'tours_pkey',
  /** unique or primary key constraint on columns "tenant", "domain_id" */
  ToursTenantDomainId = 'tours_tenant_domain_id',
  /** unique or primary key constraint on columns "name", "tenant" */
  ToursTenantNameKey = 'tours_tenant_name_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tours_Delete_At_Path_Input = {
  allowedTenants?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tours_Delete_Elem_Input = {
  allowedTenants?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tours_Delete_Key_Input = {
  allowedTenants?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "tours" */
export type Tours_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "tours" */
export type Tours_Insert_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
  customerOrders?: InputMaybe<Customer_Orders_Arr_Rel_Insert_Input>;
  departureTime?: InputMaybe<Scalars['time']>;
  description?: InputMaybe<Scalars['String']>;
  domainId?: InputMaybe<Scalars['String']>;
  excludeFromTourPlan?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Tour_States_Enum>;
  tourRoutes?: InputMaybe<Tour_Routes_Arr_Rel_Insert_Input>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Tours_Max_Fields = {
  __typename: 'tours_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  domainId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "tours" */
export type Tours_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tours_Min_Fields = {
  __typename: 'tours_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  domainId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "tours" */
export type Tours_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tours" */
export type Tours_Mutation_Response = {
  __typename: 'tours_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tours>;
};

/** input type for inserting object relation for remote table "tours" */
export type Tours_Obj_Rel_Insert_Input = {
  data: Tours_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tours_On_Conflict>;
};

/** on_conflict condition type for table "tours" */
export type Tours_On_Conflict = {
  constraint: Tours_Constraint;
  update_columns?: Array<Tours_Update_Column>;
  where?: InputMaybe<Tours_Bool_Exp>;
};

/** Ordering options when selecting data from "tours". */
export type Tours_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  allowedTenants?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrders_aggregate?: InputMaybe<Customer_Orders_Aggregate_Order_By>;
  departureTime?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  domainId?: InputMaybe<Order_By>;
  excludeFromTourPlan?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  tourRoutes_aggregate?: InputMaybe<Tour_Routes_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tours */
export type Tours_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tours_Prepend_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "tours" */
export enum Tours_Select_Column {
  /** column name */
  AllowedTenants = 'allowedTenants',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DepartureTime = 'departureTime',
  /** column name */
  Description = 'description',
  /** column name */
  DomainId = 'domainId',
  /** column name */
  ExcludeFromTourPlan = 'excludeFromTourPlan',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** select "tours_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tours" */
export enum Tours_Select_Column_Tours_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ExcludeFromTourPlan = 'excludeFromTourPlan'
}

/** select "tours_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tours" */
export enum Tours_Select_Column_Tours_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ExcludeFromTourPlan = 'excludeFromTourPlan'
}

/** input type for updating data in table "tours" */
export type Tours_Set_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
  departureTime?: InputMaybe<Scalars['time']>;
  description?: InputMaybe<Scalars['String']>;
  domainId?: InputMaybe<Scalars['String']>;
  excludeFromTourPlan?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Tour_States_Enum>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Tours_Stddev_Fields = {
  __typename: 'tours_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tours" */
export type Tours_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tours_Stddev_Pop_Fields = {
  __typename: 'tours_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tours" */
export type Tours_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tours_Stddev_Samp_Fields = {
  __typename: 'tours_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tours" */
export type Tours_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tours" */
export type Tours_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tours_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tours_Stream_Cursor_Value_Input = {
  allowedTenants?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  departureTime?: InputMaybe<Scalars['time']>;
  description?: InputMaybe<Scalars['String']>;
  domainId?: InputMaybe<Scalars['String']>;
  excludeFromTourPlan?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Tour_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Tours_Sum_Fields = {
  __typename: 'tours_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "tours" */
export type Tours_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "tours" */
export enum Tours_Update_Column {
  /** column name */
  AllowedTenants = 'allowedTenants',
  /** column name */
  DepartureTime = 'departureTime',
  /** column name */
  Description = 'description',
  /** column name */
  DomainId = 'domainId',
  /** column name */
  ExcludeFromTourPlan = 'excludeFromTourPlan',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  State = 'state',
  /** column name */
  Version = 'version'
}

export type Tours_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tours_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tours_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tours_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tours_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tours_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tours_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tours_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tours_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tours_Var_Pop_Fields = {
  __typename: 'tours_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tours" */
export type Tours_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tours_Var_Samp_Fields = {
  __typename: 'tours_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tours" */
export type Tours_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tours_Variance_Fields = {
  __typename: 'tours_variance_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tours" */
export type Tours_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "units" */
export type Units = {
  __typename: 'units';
  /** An array relationship */
  productUnits: Array<Product_Units>;
  /** An aggregate relationship */
  productUnits_aggregate: Product_Units_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "units" */
export type UnitsProductUnitsArgs = {
  distinct_on?: InputMaybe<Array<Product_Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Units_Order_By>>;
  where?: InputMaybe<Product_Units_Bool_Exp>;
};


/** columns and relationships of "units" */
export type UnitsProductUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Units_Order_By>>;
  where?: InputMaybe<Product_Units_Bool_Exp>;
};

/** aggregated selection of "units" */
export type Units_Aggregate = {
  __typename: 'units_aggregate';
  aggregate?: Maybe<Units_Aggregate_Fields>;
  nodes: Array<Units>;
};

/** aggregate fields of "units" */
export type Units_Aggregate_Fields = {
  __typename: 'units_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Units_Max_Fields>;
  min?: Maybe<Units_Min_Fields>;
};


/** aggregate fields of "units" */
export type Units_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Units_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "units". All fields are combined with a logical 'AND'. */
export type Units_Bool_Exp = {
  _and?: InputMaybe<Array<Units_Bool_Exp>>;
  _not?: InputMaybe<Units_Bool_Exp>;
  _or?: InputMaybe<Array<Units_Bool_Exp>>;
  productUnits?: InputMaybe<Product_Units_Bool_Exp>;
  productUnits_aggregate?: InputMaybe<Product_Units_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Units_Enum {
  Box = 'BOX',
  Custom = 'CUSTOM',
  Kg = 'KG',
  L = 'L',
  Pallet = 'PALLET',
  Piece = 'PIECE'
}

/** Boolean expression to compare columns of type "units_enum". All fields are combined with logical 'AND'. */
export type Units_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Units_Enum>;
  _in?: InputMaybe<Array<Units_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Units_Enum>;
  _nin?: InputMaybe<Array<Units_Enum>>;
};

/** aggregate max on columns */
export type Units_Max_Fields = {
  __typename: 'units_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Units_Min_Fields = {
  __typename: 'units_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "units". */
export type Units_Order_By = {
  productUnits_aggregate?: InputMaybe<Product_Units_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "units" */
export enum Units_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "units" */
export type Units_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Units_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Units_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

export type Upsert_Price_List_Cgps_Args = {
  _price_list_id?: InputMaybe<Scalars['bigint']>;
};

export type Upsert_Sscc_For_Picked_Item_Args = {
  _picked_item_id?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "user_sales_goals" */
export type User_Sales_Goals = {
  __typename: 'user_sales_goals';
  /** An object relationship */
  Tenant: Tenants;
  goalAmount: Scalars['numeric'];
  goalCurrency: Scalars['String'];
  goalMonth: Scalars['date'];
  id: Scalars['bigint'];
  tenant: Scalars['String'];
  /** An object relationship */
  user: Users;
  userId: Scalars['bigint'];
};

/** aggregated selection of "user_sales_goals" */
export type User_Sales_Goals_Aggregate = {
  __typename: 'user_sales_goals_aggregate';
  aggregate?: Maybe<User_Sales_Goals_Aggregate_Fields>;
  nodes: Array<User_Sales_Goals>;
};

export type User_Sales_Goals_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Sales_Goals_Aggregate_Bool_Exp_Count>;
};

export type User_Sales_Goals_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Sales_Goals_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Sales_Goals_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_sales_goals" */
export type User_Sales_Goals_Aggregate_Fields = {
  __typename: 'user_sales_goals_aggregate_fields';
  avg?: Maybe<User_Sales_Goals_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Sales_Goals_Max_Fields>;
  min?: Maybe<User_Sales_Goals_Min_Fields>;
  stddev?: Maybe<User_Sales_Goals_Stddev_Fields>;
  stddev_pop?: Maybe<User_Sales_Goals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Sales_Goals_Stddev_Samp_Fields>;
  sum?: Maybe<User_Sales_Goals_Sum_Fields>;
  var_pop?: Maybe<User_Sales_Goals_Var_Pop_Fields>;
  var_samp?: Maybe<User_Sales_Goals_Var_Samp_Fields>;
  variance?: Maybe<User_Sales_Goals_Variance_Fields>;
};


/** aggregate fields of "user_sales_goals" */
export type User_Sales_Goals_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Sales_Goals_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_sales_goals" */
export type User_Sales_Goals_Aggregate_Order_By = {
  avg?: InputMaybe<User_Sales_Goals_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Sales_Goals_Max_Order_By>;
  min?: InputMaybe<User_Sales_Goals_Min_Order_By>;
  stddev?: InputMaybe<User_Sales_Goals_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Sales_Goals_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Sales_Goals_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Sales_Goals_Sum_Order_By>;
  var_pop?: InputMaybe<User_Sales_Goals_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Sales_Goals_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Sales_Goals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_sales_goals" */
export type User_Sales_Goals_Arr_Rel_Insert_Input = {
  data: Array<User_Sales_Goals_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Sales_Goals_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Sales_Goals_Avg_Fields = {
  __typename: 'user_sales_goals_avg_fields';
  goalAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_sales_goals" */
export type User_Sales_Goals_Avg_Order_By = {
  goalAmount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_sales_goals". All fields are combined with a logical 'AND'. */
export type User_Sales_Goals_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<User_Sales_Goals_Bool_Exp>>;
  _not?: InputMaybe<User_Sales_Goals_Bool_Exp>;
  _or?: InputMaybe<Array<User_Sales_Goals_Bool_Exp>>;
  goalAmount?: InputMaybe<Numeric_Comparison_Exp>;
  goalCurrency?: InputMaybe<String_Comparison_Exp>;
  goalMonth?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_sales_goals" */
export enum User_Sales_Goals_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserSalesGoalsPkey = 'user_sales_goals_pkey',
  /** unique or primary key constraint on columns "user_id", "goal_month" */
  UserSalesGoalsUserIdGoalMonthKey = 'user_sales_goals_user_id_goal_month_key'
}

/** input type for incrementing numeric columns in table "user_sales_goals" */
export type User_Sales_Goals_Inc_Input = {
  goalAmount?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['bigint']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_sales_goals" */
export type User_Sales_Goals_Insert_Input = {
  goalAmount?: InputMaybe<Scalars['numeric']>;
  goalCurrency?: InputMaybe<Scalars['String']>;
  goalMonth?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type User_Sales_Goals_Max_Fields = {
  __typename: 'user_sales_goals_max_fields';
  goalAmount?: Maybe<Scalars['numeric']>;
  goalCurrency?: Maybe<Scalars['String']>;
  goalMonth?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "user_sales_goals" */
export type User_Sales_Goals_Max_Order_By = {
  goalAmount?: InputMaybe<Order_By>;
  goalCurrency?: InputMaybe<Order_By>;
  goalMonth?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Sales_Goals_Min_Fields = {
  __typename: 'user_sales_goals_min_fields';
  goalAmount?: Maybe<Scalars['numeric']>;
  goalCurrency?: Maybe<Scalars['String']>;
  goalMonth?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "user_sales_goals" */
export type User_Sales_Goals_Min_Order_By = {
  goalAmount?: InputMaybe<Order_By>;
  goalCurrency?: InputMaybe<Order_By>;
  goalMonth?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_sales_goals" */
export type User_Sales_Goals_Mutation_Response = {
  __typename: 'user_sales_goals_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Sales_Goals>;
};

/** on_conflict condition type for table "user_sales_goals" */
export type User_Sales_Goals_On_Conflict = {
  constraint: User_Sales_Goals_Constraint;
  update_columns?: Array<User_Sales_Goals_Update_Column>;
  where?: InputMaybe<User_Sales_Goals_Bool_Exp>;
};

/** Ordering options when selecting data from "user_sales_goals". */
export type User_Sales_Goals_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  goalAmount?: InputMaybe<Order_By>;
  goalCurrency?: InputMaybe<Order_By>;
  goalMonth?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_sales_goals */
export type User_Sales_Goals_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "user_sales_goals" */
export enum User_Sales_Goals_Select_Column {
  /** column name */
  GoalAmount = 'goalAmount',
  /** column name */
  GoalCurrency = 'goalCurrency',
  /** column name */
  GoalMonth = 'goalMonth',
  /** column name */
  Id = 'id',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_sales_goals" */
export type User_Sales_Goals_Set_Input = {
  goalAmount?: InputMaybe<Scalars['numeric']>;
  goalCurrency?: InputMaybe<Scalars['String']>;
  goalMonth?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type User_Sales_Goals_Stddev_Fields = {
  __typename: 'user_sales_goals_stddev_fields';
  goalAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_sales_goals" */
export type User_Sales_Goals_Stddev_Order_By = {
  goalAmount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Sales_Goals_Stddev_Pop_Fields = {
  __typename: 'user_sales_goals_stddev_pop_fields';
  goalAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_sales_goals" */
export type User_Sales_Goals_Stddev_Pop_Order_By = {
  goalAmount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Sales_Goals_Stddev_Samp_Fields = {
  __typename: 'user_sales_goals_stddev_samp_fields';
  goalAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_sales_goals" */
export type User_Sales_Goals_Stddev_Samp_Order_By = {
  goalAmount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_sales_goals" */
export type User_Sales_Goals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Sales_Goals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Sales_Goals_Stream_Cursor_Value_Input = {
  goalAmount?: InputMaybe<Scalars['numeric']>;
  goalCurrency?: InputMaybe<Scalars['String']>;
  goalMonth?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type User_Sales_Goals_Sum_Fields = {
  __typename: 'user_sales_goals_sum_fields';
  goalAmount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "user_sales_goals" */
export type User_Sales_Goals_Sum_Order_By = {
  goalAmount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** update columns of table "user_sales_goals" */
export enum User_Sales_Goals_Update_Column {
  /** column name */
  GoalAmount = 'goalAmount',
  /** column name */
  GoalCurrency = 'goalCurrency',
  /** column name */
  GoalMonth = 'goalMonth',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'userId'
}

export type User_Sales_Goals_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Sales_Goals_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Sales_Goals_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Sales_Goals_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Sales_Goals_Var_Pop_Fields = {
  __typename: 'user_sales_goals_var_pop_fields';
  goalAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_sales_goals" */
export type User_Sales_Goals_Var_Pop_Order_By = {
  goalAmount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Sales_Goals_Var_Samp_Fields = {
  __typename: 'user_sales_goals_var_samp_fields';
  goalAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_sales_goals" */
export type User_Sales_Goals_Var_Samp_Order_By = {
  goalAmount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Sales_Goals_Variance_Fields = {
  __typename: 'user_sales_goals_variance_fields';
  goalAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_sales_goals" */
export type User_Sales_Goals_Variance_Order_By = {
  goalAmount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_state_transitions" */
export type User_State_Transitions = {
  __typename: 'user_state_transitions';
  action: Scalars['String'];
  eventName?: Maybe<Scalars['String']>;
  fromState: User_States_Enum;
  toState: User_States_Enum;
};

/** aggregated selection of "user_state_transitions" */
export type User_State_Transitions_Aggregate = {
  __typename: 'user_state_transitions_aggregate';
  aggregate?: Maybe<User_State_Transitions_Aggregate_Fields>;
  nodes: Array<User_State_Transitions>;
};

/** aggregate fields of "user_state_transitions" */
export type User_State_Transitions_Aggregate_Fields = {
  __typename: 'user_state_transitions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_State_Transitions_Max_Fields>;
  min?: Maybe<User_State_Transitions_Min_Fields>;
};


/** aggregate fields of "user_state_transitions" */
export type User_State_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_State_Transitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_state_transitions". All fields are combined with a logical 'AND'. */
export type User_State_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<User_State_Transitions_Bool_Exp>>;
  _not?: InputMaybe<User_State_Transitions_Bool_Exp>;
  _or?: InputMaybe<Array<User_State_Transitions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  fromState?: InputMaybe<User_States_Enum_Comparison_Exp>;
  toState?: InputMaybe<User_States_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type User_State_Transitions_Max_Fields = {
  __typename: 'user_state_transitions_max_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_State_Transitions_Min_Fields = {
  __typename: 'user_state_transitions_min_fields';
  action?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "user_state_transitions". */
export type User_State_Transitions_Order_By = {
  action?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  fromState?: InputMaybe<Order_By>;
  toState?: InputMaybe<Order_By>;
};

/** select columns of table "user_state_transitions" */
export enum User_State_Transitions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  EventName = 'eventName',
  /** column name */
  FromState = 'fromState',
  /** column name */
  ToState = 'toState'
}

/** Streaming cursor of the table "user_state_transitions" */
export type User_State_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_State_Transitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_State_Transitions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  fromState?: InputMaybe<User_States_Enum>;
  toState?: InputMaybe<User_States_Enum>;
};

/** columns and relationships of "user_states" */
export type User_States = {
  __typename: 'user_states';
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "user_states" */
export type User_StatesUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "user_states" */
export type User_StatesUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "user_states" */
export type User_States_Aggregate = {
  __typename: 'user_states_aggregate';
  aggregate?: Maybe<User_States_Aggregate_Fields>;
  nodes: Array<User_States>;
};

/** aggregate fields of "user_states" */
export type User_States_Aggregate_Fields = {
  __typename: 'user_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_States_Max_Fields>;
  min?: Maybe<User_States_Min_Fields>;
};


/** aggregate fields of "user_states" */
export type User_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_states". All fields are combined with a logical 'AND'. */
export type User_States_Bool_Exp = {
  _and?: InputMaybe<Array<User_States_Bool_Exp>>;
  _not?: InputMaybe<User_States_Bool_Exp>;
  _or?: InputMaybe<Array<User_States_Bool_Exp>>;
  users?: InputMaybe<Users_Bool_Exp>;
  users_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum User_States_Enum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

/** Boolean expression to compare columns of type "user_states_enum". All fields are combined with logical 'AND'. */
export type User_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_States_Enum>;
  _in?: InputMaybe<Array<User_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<User_States_Enum>;
  _nin?: InputMaybe<Array<User_States_Enum>>;
};

/** aggregate max on columns */
export type User_States_Max_Fields = {
  __typename: 'user_states_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_States_Min_Fields = {
  __typename: 'user_states_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "user_states". */
export type User_States_Order_By = {
  users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "user_states" */
export enum User_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "user_states" */
export type User_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_States_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename: 'users';
  /** An object relationship */
  Tenant: Tenants;
  auth0Identity: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  customerInvoiceLoggedActions: Array<Customer_Invoice_Logged_Actions>;
  /** An aggregate relationship */
  customerInvoiceLoggedActions_aggregate: Customer_Invoice_Logged_Actions_Aggregate;
  /** An array relationship */
  customerInvoiceVersionLoggedActions: Array<Customer_Invoice_Version_Logged_Actions>;
  /** An aggregate relationship */
  customerInvoiceVersionLoggedActions_aggregate: Customer_Invoice_Version_Logged_Actions_Aggregate;
  /** An array relationship */
  customerLoggedActions: Array<Customer_Logged_Actions>;
  /** An aggregate relationship */
  customerLoggedActions_aggregate: Customer_Logged_Actions_Aggregate;
  /** An array relationship */
  customerOrderItemLoggedActions: Array<Customer_Order_Item_Logged_Actions>;
  /** An aggregate relationship */
  customerOrderItemLoggedActions_aggregate: Customer_Order_Item_Logged_Actions_Aggregate;
  /** An array relationship */
  customerOrderLoggedActions: Array<Customer_Order_Logged_Actions>;
  /** An aggregate relationship */
  customerOrderLoggedActions_aggregate: Customer_Order_Logged_Actions_Aggregate;
  /** An array relationship */
  dismantlingPlanLoggedActions: Array<Dismantling_Plan_Logged_Actions>;
  /** An aggregate relationship */
  dismantlingPlanLoggedActions_aggregate: Dismantling_Plan_Logged_Actions_Aggregate;
  email: Scalars['String'];
  id: Scalars['bigint'];
  lastLoginAt: Scalars['timestamptz'];
  name: Scalars['String'];
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  /** An array relationship */
  partialDeliveryLoggedActions: Array<Partial_Delivery_Logged_Actions>;
  /** An aggregate relationship */
  partialDeliveryLoggedActions_aggregate: Partial_Delivery_Logged_Actions_Aggregate;
  phoneNumber?: Maybe<Scalars['String']>;
  /** An array relationship */
  pickedItemLoggedActions: Array<Picked_Item_Logged_Actions>;
  /** An aggregate relationship */
  pickedItemLoggedActions_aggregate: Picked_Item_Logged_Actions_Aggregate;
  /** An array relationship */
  productLoggedActions: Array<Product_Logged_Actions>;
  /** An aggregate relationship */
  productLoggedActions_aggregate: Product_Logged_Actions_Aggregate;
  /** An object relationship */
  profileImage?: Maybe<Images>;
  profileImageId?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  publicLoggedActions: Array<Public_Logged_Actions>;
  /** An aggregate relationship */
  publicLoggedActions_aggregate: Public_Logged_Actions_Aggregate;
  /** An array relationship */
  rollingInventoryActors: Array<Rolling_Inventory_Actors>;
  /** An aggregate relationship */
  rollingInventoryActors_aggregate: Rolling_Inventory_Actors_Aggregate;
  /** An array relationship */
  s3Objects: Array<S3_Objects>;
  /** An aggregate relationship */
  s3Objects_aggregate: S3_Objects_Aggregate;
  /** An array relationship */
  salesTeamUsers: Array<Sales_Team_Users>;
  /** An aggregate relationship */
  salesTeamUsers_aggregate: Sales_Team_Users_Aggregate;
  state: User_States_Enum;
  /** An array relationship */
  stockTransactionLoggedActions: Array<Stock_Transaction_Logged_Actions>;
  /** An aggregate relationship */
  stockTransactionLoggedActions_aggregate: Stock_Transaction_Logged_Actions_Aggregate;
  /** An array relationship */
  supplierOrderItemLoggedActions: Array<Supplier_Order_Item_Logged_Actions>;
  /** An aggregate relationship */
  supplierOrderItemLoggedActions_aggregate: Supplier_Order_Item_Logged_Actions_Aggregate;
  /** An array relationship */
  supplierOrderLoggedActions: Array<Supplier_Order_Logged_Actions>;
  /** An aggregate relationship */
  supplierOrderLoggedActions_aggregate: Supplier_Order_Logged_Actions_Aggregate;
  /** An array relationship */
  supplierOrders: Array<Supplier_Orders>;
  /** An aggregate relationship */
  supplierOrders_aggregate: Supplier_Orders_Aggregate;
  tenant: Scalars['String'];
  /** An array relationship */
  todos: Array<Todos>;
  /** An aggregate relationship */
  todos_aggregate: Todos_Aggregate;
  /** An array relationship */
  userSalesGoals: Array<User_Sales_Goals>;
  /** An aggregate relationship */
  userSalesGoals_aggregate: User_Sales_Goals_Aggregate;
  version: Scalars['bigint'];
};


/** columns and relationships of "users" */
export type UsersCustomerInvoiceLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCustomerInvoiceLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCustomerInvoiceVersionLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Version_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Version_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCustomerInvoiceVersionLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Invoice_Version_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Invoice_Version_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCustomerLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCustomerLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCustomerOrderItemLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCustomerOrderItemLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Order_Item_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCustomerOrderLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Order_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCustomerOrderLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Order_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_Logged_Actions_Order_By>>;
  where?: InputMaybe<Customer_Order_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersDismantlingPlanLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Logged_Actions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersDismantlingPlanLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dismantling_Plan_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dismantling_Plan_Logged_Actions_Order_By>>;
  where?: InputMaybe<Dismantling_Plan_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPartialDeliveryLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Partial_Delivery_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Delivery_Logged_Actions_Order_By>>;
  where?: InputMaybe<Partial_Delivery_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPartialDeliveryLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partial_Delivery_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partial_Delivery_Logged_Actions_Order_By>>;
  where?: InputMaybe<Partial_Delivery_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPickedItemLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Picked_Item_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPickedItemLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picked_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picked_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Picked_Item_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProductLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Product_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Logged_Actions_Order_By>>;
  where?: InputMaybe<Product_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProductLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Logged_Actions_Order_By>>;
  where?: InputMaybe<Product_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPublicLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Public_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Logged_Actions_Order_By>>;
  where?: InputMaybe<Public_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPublicLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Public_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Logged_Actions_Order_By>>;
  where?: InputMaybe<Public_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersRollingInventoryActorsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Actors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Actors_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersRollingInventoryActors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Actors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Actors_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersS3ObjectsArgs = {
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersS3Objects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<S3_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<S3_Objects_Order_By>>;
  where?: InputMaybe<S3_Objects_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSalesTeamUsersArgs = {
  distinct_on?: InputMaybe<Array<Sales_Team_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Team_Users_Order_By>>;
  where?: InputMaybe<Sales_Team_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSalesTeamUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sales_Team_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sales_Team_Users_Order_By>>;
  where?: InputMaybe<Sales_Team_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersStockTransactionLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transaction_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transaction_Logged_Actions_Order_By>>;
  where?: InputMaybe<Stock_Transaction_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersStockTransactionLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transaction_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transaction_Logged_Actions_Order_By>>;
  where?: InputMaybe<Stock_Transaction_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSupplierOrderItemLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Supplier_Order_Item_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSupplierOrderItemLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Item_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Item_Logged_Actions_Order_By>>;
  where?: InputMaybe<Supplier_Order_Item_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSupplierOrderLoggedActionsArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Logged_Actions_Order_By>>;
  where?: InputMaybe<Supplier_Order_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSupplierOrderLoggedActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Order_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Order_Logged_Actions_Order_By>>;
  where?: InputMaybe<Supplier_Order_Logged_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSupplierOrdersArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSupplierOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supplier_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supplier_Orders_Order_By>>;
  where?: InputMaybe<Supplier_Orders_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTodosArgs = {
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTodos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Todos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todos_Order_By>>;
  where?: InputMaybe<Todos_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUserSalesGoalsArgs = {
  distinct_on?: InputMaybe<Array<User_Sales_Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Sales_Goals_Order_By>>;
  where?: InputMaybe<User_Sales_Goals_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUserSalesGoals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Sales_Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Sales_Goals_Order_By>>;
  where?: InputMaybe<User_Sales_Goals_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

export type Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Users_Aggregate_Bool_Exp_Count>;
};

export type Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  avg?: InputMaybe<Users_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
  stddev?: InputMaybe<Users_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Users_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Users_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Users_Sum_Order_By>;
  var_pop?: InputMaybe<Users_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Users_Var_Samp_Order_By>;
  variance?: InputMaybe<Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename: 'users_avg_fields';
  id?: Maybe<Scalars['Float']>;
  profileImageId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "users" */
export type Users_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  profileImageId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  auth0Identity?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerInvoiceLoggedActions?: InputMaybe<Customer_Invoice_Logged_Actions_Bool_Exp>;
  customerInvoiceLoggedActions_aggregate?: InputMaybe<Customer_Invoice_Logged_Actions_Aggregate_Bool_Exp>;
  customerInvoiceVersionLoggedActions?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Bool_Exp>;
  customerInvoiceVersionLoggedActions_aggregate?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Aggregate_Bool_Exp>;
  customerLoggedActions?: InputMaybe<Customer_Logged_Actions_Bool_Exp>;
  customerLoggedActions_aggregate?: InputMaybe<Customer_Logged_Actions_Aggregate_Bool_Exp>;
  customerOrderItemLoggedActions?: InputMaybe<Customer_Order_Item_Logged_Actions_Bool_Exp>;
  customerOrderItemLoggedActions_aggregate?: InputMaybe<Customer_Order_Item_Logged_Actions_Aggregate_Bool_Exp>;
  customerOrderLoggedActions?: InputMaybe<Customer_Order_Logged_Actions_Bool_Exp>;
  customerOrderLoggedActions_aggregate?: InputMaybe<Customer_Order_Logged_Actions_Aggregate_Bool_Exp>;
  dismantlingPlanLoggedActions?: InputMaybe<Dismantling_Plan_Logged_Actions_Bool_Exp>;
  dismantlingPlanLoggedActions_aggregate?: InputMaybe<Dismantling_Plan_Logged_Actions_Aggregate_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  lastLoginAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notifications?: InputMaybe<Notifications_Bool_Exp>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  partialDeliveryLoggedActions?: InputMaybe<Partial_Delivery_Logged_Actions_Bool_Exp>;
  partialDeliveryLoggedActions_aggregate?: InputMaybe<Partial_Delivery_Logged_Actions_Aggregate_Bool_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  pickedItemLoggedActions?: InputMaybe<Picked_Item_Logged_Actions_Bool_Exp>;
  pickedItemLoggedActions_aggregate?: InputMaybe<Picked_Item_Logged_Actions_Aggregate_Bool_Exp>;
  productLoggedActions?: InputMaybe<Product_Logged_Actions_Bool_Exp>;
  productLoggedActions_aggregate?: InputMaybe<Product_Logged_Actions_Aggregate_Bool_Exp>;
  profileImage?: InputMaybe<Images_Bool_Exp>;
  profileImageId?: InputMaybe<Bigint_Comparison_Exp>;
  publicLoggedActions?: InputMaybe<Public_Logged_Actions_Bool_Exp>;
  publicLoggedActions_aggregate?: InputMaybe<Public_Logged_Actions_Aggregate_Bool_Exp>;
  rollingInventoryActors?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
  rollingInventoryActors_aggregate?: InputMaybe<Rolling_Inventory_Actors_Aggregate_Bool_Exp>;
  s3Objects?: InputMaybe<S3_Objects_Bool_Exp>;
  s3Objects_aggregate?: InputMaybe<S3_Objects_Aggregate_Bool_Exp>;
  salesTeamUsers?: InputMaybe<Sales_Team_Users_Bool_Exp>;
  salesTeamUsers_aggregate?: InputMaybe<Sales_Team_Users_Aggregate_Bool_Exp>;
  state?: InputMaybe<User_States_Enum_Comparison_Exp>;
  stockTransactionLoggedActions?: InputMaybe<Stock_Transaction_Logged_Actions_Bool_Exp>;
  stockTransactionLoggedActions_aggregate?: InputMaybe<Stock_Transaction_Logged_Actions_Aggregate_Bool_Exp>;
  supplierOrderItemLoggedActions?: InputMaybe<Supplier_Order_Item_Logged_Actions_Bool_Exp>;
  supplierOrderItemLoggedActions_aggregate?: InputMaybe<Supplier_Order_Item_Logged_Actions_Aggregate_Bool_Exp>;
  supplierOrderLoggedActions?: InputMaybe<Supplier_Order_Logged_Actions_Bool_Exp>;
  supplierOrderLoggedActions_aggregate?: InputMaybe<Supplier_Order_Logged_Actions_Aggregate_Bool_Exp>;
  supplierOrders?: InputMaybe<Supplier_Orders_Bool_Exp>;
  supplierOrders_aggregate?: InputMaybe<Supplier_Orders_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  todos?: InputMaybe<Todos_Bool_Exp>;
  todos_aggregate?: InputMaybe<Todos_Aggregate_Bool_Exp>;
  userSalesGoals?: InputMaybe<User_Sales_Goals_Bool_Exp>;
  userSalesGoals_aggregate?: InputMaybe<User_Sales_Goals_Aggregate_Bool_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "auth0_identity", "tenant" */
  UsersAuth0IdentityKey = 'users_auth0_identity_key',
  /** unique or primary key constraint on columns "email", "tenant" */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  profileImageId?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  auth0Identity?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  lastLoginAt?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profileImage?: InputMaybe<Images_Obj_Rel_Insert_Input>;
  profileImageId?: InputMaybe<Scalars['bigint']>;
  rollingInventoryActors?: InputMaybe<Rolling_Inventory_Actors_Arr_Rel_Insert_Input>;
  s3Objects?: InputMaybe<S3_Objects_Arr_Rel_Insert_Input>;
  salesTeamUsers?: InputMaybe<Sales_Team_Users_Arr_Rel_Insert_Input>;
  state?: InputMaybe<User_States_Enum>;
  supplierOrders?: InputMaybe<Supplier_Orders_Arr_Rel_Insert_Input>;
  todos?: InputMaybe<Todos_Arr_Rel_Insert_Input>;
  userSalesGoals?: InputMaybe<User_Sales_Goals_Arr_Rel_Insert_Input>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename: 'users_max_fields';
  auth0Identity?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lastLoginAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profileImageId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  auth0Identity?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastLoginAt?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  profileImageId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename: 'users_min_fields';
  auth0Identity?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lastLoginAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profileImageId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  auth0Identity?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastLoginAt?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  profileImageId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  auth0Identity?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerInvoiceLoggedActions_aggregate?: InputMaybe<Customer_Invoice_Logged_Actions_Aggregate_Order_By>;
  customerInvoiceVersionLoggedActions_aggregate?: InputMaybe<Customer_Invoice_Version_Logged_Actions_Aggregate_Order_By>;
  customerLoggedActions_aggregate?: InputMaybe<Customer_Logged_Actions_Aggregate_Order_By>;
  customerOrderItemLoggedActions_aggregate?: InputMaybe<Customer_Order_Item_Logged_Actions_Aggregate_Order_By>;
  customerOrderLoggedActions_aggregate?: InputMaybe<Customer_Order_Logged_Actions_Aggregate_Order_By>;
  dismantlingPlanLoggedActions_aggregate?: InputMaybe<Dismantling_Plan_Logged_Actions_Aggregate_Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastLoginAt?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  partialDeliveryLoggedActions_aggregate?: InputMaybe<Partial_Delivery_Logged_Actions_Aggregate_Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  pickedItemLoggedActions_aggregate?: InputMaybe<Picked_Item_Logged_Actions_Aggregate_Order_By>;
  productLoggedActions_aggregate?: InputMaybe<Product_Logged_Actions_Aggregate_Order_By>;
  profileImage?: InputMaybe<Images_Order_By>;
  profileImageId?: InputMaybe<Order_By>;
  publicLoggedActions_aggregate?: InputMaybe<Public_Logged_Actions_Aggregate_Order_By>;
  rollingInventoryActors_aggregate?: InputMaybe<Rolling_Inventory_Actors_Aggregate_Order_By>;
  s3Objects_aggregate?: InputMaybe<S3_Objects_Aggregate_Order_By>;
  salesTeamUsers_aggregate?: InputMaybe<Sales_Team_Users_Aggregate_Order_By>;
  state?: InputMaybe<Order_By>;
  stockTransactionLoggedActions_aggregate?: InputMaybe<Stock_Transaction_Logged_Actions_Aggregate_Order_By>;
  supplierOrderItemLoggedActions_aggregate?: InputMaybe<Supplier_Order_Item_Logged_Actions_Aggregate_Order_By>;
  supplierOrderLoggedActions_aggregate?: InputMaybe<Supplier_Order_Logged_Actions_Aggregate_Order_By>;
  supplierOrders_aggregate?: InputMaybe<Supplier_Orders_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
  todos_aggregate?: InputMaybe<Todos_Aggregate_Order_By>;
  userSalesGoals_aggregate?: InputMaybe<User_Sales_Goals_Aggregate_Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Auth0Identity = 'auth0Identity',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  LastLoginAt = 'lastLoginAt',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  ProfileImageId = 'profileImageId',
  /** column name */
  State = 'state',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  auth0Identity?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  lastLoginAt?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profileImageId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<User_States_Enum>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename: 'users_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  profileImageId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "users" */
export type Users_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  profileImageId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename: 'users_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profileImageId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "users" */
export type Users_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  profileImageId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename: 'users_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profileImageId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "users" */
export type Users_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  profileImageId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  auth0Identity?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  lastLoginAt?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profileImageId?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<User_States_Enum>;
  tenant?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename: 'users_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  profileImageId?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "users" */
export type Users_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  profileImageId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Auth0Identity = 'auth0Identity',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  LastLoginAt = 'lastLoginAt',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  ProfileImageId = 'profileImageId',
  /** column name */
  State = 'state',
  /** column name */
  Version = 'version'
}

export type Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename: 'users_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profileImageId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "users" */
export type Users_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  profileImageId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename: 'users_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profileImageId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "users" */
export type Users_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  profileImageId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename: 'users_variance_fields';
  id?: Maybe<Scalars['Float']>;
  profileImageId?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "users" */
export type Users_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  profileImageId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "vat_types" */
export type Vat_Types = {
  __typename: 'vat_types';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  creditNoteItems: Array<Credit_Note_Items>;
  /** An aggregate relationship */
  creditNoteItems_aggregate: Credit_Note_Items_Aggregate;
  /** Returns a matching temporal vat type which matches the current date. */
  currentTemporalVatTypes?: Maybe<Array<Temporal_Vat_Types>>;
  id: Scalars['bigint'];
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  /** An array relationship */
  temporalVatTypes: Array<Temporal_Vat_Types>;
  /** An aggregate relationship */
  temporalVatTypes_aggregate: Temporal_Vat_Types_Aggregate;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** Returns the current VAT % for this VAT type, for backward compatibility. */
  vatPct?: Maybe<Scalars['numeric']>;
  vatType: Scalars['String'];
  version: Scalars['bigint'];
};


/** columns and relationships of "vat_types" */
export type Vat_TypesCreditNoteItemsArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Items_Order_By>>;
  where?: InputMaybe<Credit_Note_Items_Bool_Exp>;
};


/** columns and relationships of "vat_types" */
export type Vat_TypesCreditNoteItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credit_Note_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Credit_Note_Items_Order_By>>;
  where?: InputMaybe<Credit_Note_Items_Bool_Exp>;
};


/** columns and relationships of "vat_types" */
export type Vat_TypesCurrentTemporalVatTypesArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Vat_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Vat_Types_Order_By>>;
  where?: InputMaybe<Temporal_Vat_Types_Bool_Exp>;
};


/** columns and relationships of "vat_types" */
export type Vat_TypesProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


/** columns and relationships of "vat_types" */
export type Vat_TypesProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


/** columns and relationships of "vat_types" */
export type Vat_TypesTemporalVatTypesArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Vat_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Vat_Types_Order_By>>;
  where?: InputMaybe<Temporal_Vat_Types_Bool_Exp>;
};


/** columns and relationships of "vat_types" */
export type Vat_TypesTemporalVatTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Temporal_Vat_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Temporal_Vat_Types_Order_By>>;
  where?: InputMaybe<Temporal_Vat_Types_Bool_Exp>;
};

/** aggregated selection of "vat_types" */
export type Vat_Types_Aggregate = {
  __typename: 'vat_types_aggregate';
  aggregate?: Maybe<Vat_Types_Aggregate_Fields>;
  nodes: Array<Vat_Types>;
};

export type Vat_Types_Aggregate_Bool_Exp = {
  count?: InputMaybe<Vat_Types_Aggregate_Bool_Exp_Count>;
};

export type Vat_Types_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Vat_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Vat_Types_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vat_types" */
export type Vat_Types_Aggregate_Fields = {
  __typename: 'vat_types_aggregate_fields';
  avg?: Maybe<Vat_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vat_Types_Max_Fields>;
  min?: Maybe<Vat_Types_Min_Fields>;
  stddev?: Maybe<Vat_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Vat_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vat_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Vat_Types_Sum_Fields>;
  var_pop?: Maybe<Vat_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Vat_Types_Var_Samp_Fields>;
  variance?: Maybe<Vat_Types_Variance_Fields>;
};


/** aggregate fields of "vat_types" */
export type Vat_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vat_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vat_types" */
export type Vat_Types_Aggregate_Order_By = {
  avg?: InputMaybe<Vat_Types_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vat_Types_Max_Order_By>;
  min?: InputMaybe<Vat_Types_Min_Order_By>;
  stddev?: InputMaybe<Vat_Types_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Vat_Types_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Vat_Types_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Vat_Types_Sum_Order_By>;
  var_pop?: InputMaybe<Vat_Types_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Vat_Types_Var_Samp_Order_By>;
  variance?: InputMaybe<Vat_Types_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Vat_Types_Avg_Fields = {
  __typename: 'vat_types_avg_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vat_types" */
export type Vat_Types_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vat_types". All fields are combined with a logical 'AND'. */
export type Vat_Types_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Vat_Types_Bool_Exp>>;
  _not?: InputMaybe<Vat_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Vat_Types_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  creditNoteItems?: InputMaybe<Credit_Note_Items_Bool_Exp>;
  creditNoteItems_aggregate?: InputMaybe<Credit_Note_Items_Aggregate_Bool_Exp>;
  currentTemporalVatTypes?: InputMaybe<Temporal_Vat_Types_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  products?: InputMaybe<Products_Bool_Exp>;
  products_aggregate?: InputMaybe<Products_Aggregate_Bool_Exp>;
  temporalVatTypes?: InputMaybe<Temporal_Vat_Types_Bool_Exp>;
  temporalVatTypes_aggregate?: InputMaybe<Temporal_Vat_Types_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  vatPct?: InputMaybe<Numeric_Comparison_Exp>;
  vatType?: InputMaybe<String_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Vat_Types_Max_Fields = {
  __typename: 'vat_types_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vatType?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "vat_types" */
export type Vat_Types_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vatType?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vat_Types_Min_Fields = {
  __typename: 'vat_types_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vatType?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "vat_types" */
export type Vat_Types_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vatType?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "vat_types". */
export type Vat_Types_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creditNoteItems_aggregate?: InputMaybe<Credit_Note_Items_Aggregate_Order_By>;
  currentTemporalVatTypes_aggregate?: InputMaybe<Temporal_Vat_Types_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  products_aggregate?: InputMaybe<Products_Aggregate_Order_By>;
  temporalVatTypes_aggregate?: InputMaybe<Temporal_Vat_Types_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vatPct?: InputMaybe<Order_By>;
  vatType?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** select columns of table "vat_types" */
export enum Vat_Types_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VatType = 'vatType',
  /** column name */
  Version = 'version'
}

/** aggregate stddev on columns */
export type Vat_Types_Stddev_Fields = {
  __typename: 'vat_types_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vat_types" */
export type Vat_Types_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Vat_Types_Stddev_Pop_Fields = {
  __typename: 'vat_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vat_types" */
export type Vat_Types_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Vat_Types_Stddev_Samp_Fields = {
  __typename: 'vat_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vat_types" */
export type Vat_Types_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "vat_types" */
export type Vat_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vat_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vat_Types_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vatType?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Vat_Types_Sum_Fields = {
  __typename: 'vat_types_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "vat_types" */
export type Vat_Types_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Vat_Types_Var_Pop_Fields = {
  __typename: 'vat_types_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vat_types" */
export type Vat_Types_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Vat_Types_Var_Samp_Fields = {
  __typename: 'vat_types_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vat_types" */
export type Vat_Types_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Vat_Types_Variance_Fields = {
  __typename: 'vat_types_variance_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vat_types" */
export type Vat_Types_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "work_calendar" */
export type Work_Calendar = {
  __typename: 'work_calendar';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  day: Scalars['date'];
  /** An array relationship */
  fullWorkCalendars: Array<Full_Work_Calendar>;
  /** An aggregate relationship */
  fullWorkCalendars_aggregate: Full_Work_Calendar_Aggregate;
  holiday: Scalars['Boolean'];
  id: Scalars['bigint'];
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
  weekday: Scalars['Boolean'];
};


/** columns and relationships of "work_calendar" */
export type Work_CalendarFullWorkCalendarsArgs = {
  distinct_on?: InputMaybe<Array<Full_Work_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Full_Work_Calendar_Order_By>>;
  where?: InputMaybe<Full_Work_Calendar_Bool_Exp>;
};


/** columns and relationships of "work_calendar" */
export type Work_CalendarFullWorkCalendars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Full_Work_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Full_Work_Calendar_Order_By>>;
  where?: InputMaybe<Full_Work_Calendar_Bool_Exp>;
};

/** aggregated selection of "work_calendar" */
export type Work_Calendar_Aggregate = {
  __typename: 'work_calendar_aggregate';
  aggregate?: Maybe<Work_Calendar_Aggregate_Fields>;
  nodes: Array<Work_Calendar>;
};

export type Work_Calendar_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Work_Calendar_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Work_Calendar_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Work_Calendar_Aggregate_Bool_Exp_Count>;
};

export type Work_Calendar_Aggregate_Bool_Exp_Bool_And = {
  arguments: Work_Calendar_Select_Column_Work_Calendar_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Work_Calendar_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Work_Calendar_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Work_Calendar_Select_Column_Work_Calendar_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Work_Calendar_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Work_Calendar_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Work_Calendar_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Work_Calendar_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "work_calendar" */
export type Work_Calendar_Aggregate_Fields = {
  __typename: 'work_calendar_aggregate_fields';
  avg?: Maybe<Work_Calendar_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Work_Calendar_Max_Fields>;
  min?: Maybe<Work_Calendar_Min_Fields>;
  stddev?: Maybe<Work_Calendar_Stddev_Fields>;
  stddev_pop?: Maybe<Work_Calendar_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Work_Calendar_Stddev_Samp_Fields>;
  sum?: Maybe<Work_Calendar_Sum_Fields>;
  var_pop?: Maybe<Work_Calendar_Var_Pop_Fields>;
  var_samp?: Maybe<Work_Calendar_Var_Samp_Fields>;
  variance?: Maybe<Work_Calendar_Variance_Fields>;
};


/** aggregate fields of "work_calendar" */
export type Work_Calendar_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Work_Calendar_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "work_calendar" */
export type Work_Calendar_Aggregate_Order_By = {
  avg?: InputMaybe<Work_Calendar_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Work_Calendar_Max_Order_By>;
  min?: InputMaybe<Work_Calendar_Min_Order_By>;
  stddev?: InputMaybe<Work_Calendar_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Work_Calendar_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Work_Calendar_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Work_Calendar_Sum_Order_By>;
  var_pop?: InputMaybe<Work_Calendar_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Work_Calendar_Var_Samp_Order_By>;
  variance?: InputMaybe<Work_Calendar_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Work_Calendar_Avg_Fields = {
  __typename: 'work_calendar_avg_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "work_calendar" */
export type Work_Calendar_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "work_calendar". All fields are combined with a logical 'AND'. */
export type Work_Calendar_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Work_Calendar_Bool_Exp>>;
  _not?: InputMaybe<Work_Calendar_Bool_Exp>;
  _or?: InputMaybe<Array<Work_Calendar_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  day?: InputMaybe<Date_Comparison_Exp>;
  fullWorkCalendars?: InputMaybe<Full_Work_Calendar_Bool_Exp>;
  fullWorkCalendars_aggregate?: InputMaybe<Full_Work_Calendar_Aggregate_Bool_Exp>;
  holiday?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
  weekday?: InputMaybe<Boolean_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "work_calendar" */
export type Work_Calendar_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "work_calendar_logged_actions" */
export type Work_Calendar_Logged_Actions = {
  __typename: 'work_calendar_logged_actions';
  action?: Maybe<Scalars['String']>;
  changedFields?: Maybe<Scalars['jsonb']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  relid?: Maybe<Scalars['oid']>;
  role?: Maybe<Scalars['String']>;
  rowData?: Maybe<Scalars['jsonb']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "work_calendar_logged_actions" */
export type Work_Calendar_Logged_ActionsChangedFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "work_calendar_logged_actions" */
export type Work_Calendar_Logged_ActionsRowDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "work_calendar_logged_actions" */
export type Work_Calendar_Logged_Actions_Aggregate = {
  __typename: 'work_calendar_logged_actions_aggregate';
  aggregate?: Maybe<Work_Calendar_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Work_Calendar_Logged_Actions>;
};

/** aggregate fields of "work_calendar_logged_actions" */
export type Work_Calendar_Logged_Actions_Aggregate_Fields = {
  __typename: 'work_calendar_logged_actions_aggregate_fields';
  avg?: Maybe<Work_Calendar_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Work_Calendar_Logged_Actions_Max_Fields>;
  min?: Maybe<Work_Calendar_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Work_Calendar_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Work_Calendar_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Work_Calendar_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Work_Calendar_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Work_Calendar_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Work_Calendar_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Work_Calendar_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "work_calendar_logged_actions" */
export type Work_Calendar_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Work_Calendar_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Work_Calendar_Logged_Actions_Avg_Fields = {
  __typename: 'work_calendar_logged_actions_avg_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "work_calendar_logged_actions". All fields are combined with a logical 'AND'. */
export type Work_Calendar_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Work_Calendar_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Work_Calendar_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Work_Calendar_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  changedFields?: InputMaybe<Jsonb_Comparison_Exp>;
  entityId?: InputMaybe<Bigint_Comparison_Exp>;
  eventAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  relid?: InputMaybe<Oid_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  rowData?: InputMaybe<Jsonb_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Work_Calendar_Logged_Actions_Max_Fields = {
  __typename: 'work_calendar_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Work_Calendar_Logged_Actions_Min_Fields = {
  __typename: 'work_calendar_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['bigint']>;
  eventAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "work_calendar_logged_actions". */
export type Work_Calendar_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  changedFields?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  eventAt?: InputMaybe<Order_By>;
  relid?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  rowData?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "work_calendar_logged_actions" */
export enum Work_Calendar_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ChangedFields = 'changedFields',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EventAt = 'eventAt',
  /** column name */
  Relid = 'relid',
  /** column name */
  Role = 'role',
  /** column name */
  RowData = 'rowData',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UserId = 'userId'
}

/** aggregate stddev on columns */
export type Work_Calendar_Logged_Actions_Stddev_Fields = {
  __typename: 'work_calendar_logged_actions_stddev_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Work_Calendar_Logged_Actions_Stddev_Pop_Fields = {
  __typename: 'work_calendar_logged_actions_stddev_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Work_Calendar_Logged_Actions_Stddev_Samp_Fields = {
  __typename: 'work_calendar_logged_actions_stddev_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "work_calendar_logged_actions" */
export type Work_Calendar_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Work_Calendar_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Work_Calendar_Logged_Actions_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  changedFields?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['bigint']>;
  eventAt?: InputMaybe<Scalars['timestamptz']>;
  relid?: InputMaybe<Scalars['oid']>;
  role?: InputMaybe<Scalars['String']>;
  rowData?: InputMaybe<Scalars['jsonb']>;
  tableName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Work_Calendar_Logged_Actions_Sum_Fields = {
  __typename: 'work_calendar_logged_actions_sum_fields';
  entityId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Work_Calendar_Logged_Actions_Var_Pop_Fields = {
  __typename: 'work_calendar_logged_actions_var_pop_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Work_Calendar_Logged_Actions_Var_Samp_Fields = {
  __typename: 'work_calendar_logged_actions_var_samp_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Work_Calendar_Logged_Actions_Variance_Fields = {
  __typename: 'work_calendar_logged_actions_variance_fields';
  entityId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Work_Calendar_Max_Fields = {
  __typename: 'work_calendar_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  day?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "work_calendar" */
export type Work_Calendar_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Work_Calendar_Min_Fields = {
  __typename: 'work_calendar_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  day?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "work_calendar" */
export type Work_Calendar_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "work_calendar" */
export type Work_Calendar_Mutation_Response = {
  __typename: 'work_calendar_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Work_Calendar>;
};

/** Ordering options when selecting data from "work_calendar". */
export type Work_Calendar_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  fullWorkCalendars_aggregate?: InputMaybe<Full_Work_Calendar_Aggregate_Order_By>;
  holiday?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  weekday?: InputMaybe<Order_By>;
};

/** primary key columns input for table: work_calendar */
export type Work_Calendar_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "work_calendar" */
export enum Work_Calendar_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Day = 'day',
  /** column name */
  Holiday = 'holiday',
  /** column name */
  Id = 'id',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version',
  /** column name */
  Weekday = 'weekday'
}

/** select "work_calendar_aggregate_bool_exp_bool_and_arguments_columns" columns of table "work_calendar" */
export enum Work_Calendar_Select_Column_Work_Calendar_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Holiday = 'holiday',
  /** column name */
  Weekday = 'weekday'
}

/** select "work_calendar_aggregate_bool_exp_bool_or_arguments_columns" columns of table "work_calendar" */
export enum Work_Calendar_Select_Column_Work_Calendar_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Holiday = 'holiday',
  /** column name */
  Weekday = 'weekday'
}

/** input type for updating data in table "work_calendar" */
export type Work_Calendar_Set_Input = {
  day?: InputMaybe<Scalars['date']>;
  holiday?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
  weekday?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Work_Calendar_Stddev_Fields = {
  __typename: 'work_calendar_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "work_calendar" */
export type Work_Calendar_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Work_Calendar_Stddev_Pop_Fields = {
  __typename: 'work_calendar_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "work_calendar" */
export type Work_Calendar_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Work_Calendar_Stddev_Samp_Fields = {
  __typename: 'work_calendar_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "work_calendar" */
export type Work_Calendar_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "work_calendar" */
export type Work_Calendar_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Work_Calendar_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Work_Calendar_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  day?: InputMaybe<Scalars['date']>;
  holiday?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
  weekday?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Work_Calendar_Sum_Fields = {
  __typename: 'work_calendar_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "work_calendar" */
export type Work_Calendar_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

export type Work_Calendar_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Work_Calendar_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Work_Calendar_Set_Input>;
  /** filter the rows which have to be updated */
  where: Work_Calendar_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Work_Calendar_Var_Pop_Fields = {
  __typename: 'work_calendar_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "work_calendar" */
export type Work_Calendar_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Work_Calendar_Var_Samp_Fields = {
  __typename: 'work_calendar_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "work_calendar" */
export type Work_Calendar_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Work_Calendar_Variance_Fields = {
  __typename: 'work_calendar_variance_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "work_calendar" */
export type Work_Calendar_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "working_days" */
export type Working_Days = {
  __typename: 'working_days';
  /** An array relationship */
  contactAvailabilities: Array<Contact_Availability>;
  /** An aggregate relationship */
  contactAvailabilities_aggregate: Contact_Availability_Aggregate;
  /** An array relationship */
  customerDeliveryAvailabilities: Array<Customer_Delivery_Availability>;
  /** An aggregate relationship */
  customerDeliveryAvailabilities_aggregate: Customer_Delivery_Availability_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "working_days" */
export type Working_DaysContactAvailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<Contact_Availability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Availability_Order_By>>;
  where?: InputMaybe<Contact_Availability_Bool_Exp>;
};


/** columns and relationships of "working_days" */
export type Working_DaysContactAvailabilities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Availability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Availability_Order_By>>;
  where?: InputMaybe<Contact_Availability_Bool_Exp>;
};


/** columns and relationships of "working_days" */
export type Working_DaysCustomerDeliveryAvailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Delivery_Availability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Delivery_Availability_Order_By>>;
  where?: InputMaybe<Customer_Delivery_Availability_Bool_Exp>;
};


/** columns and relationships of "working_days" */
export type Working_DaysCustomerDeliveryAvailabilities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Delivery_Availability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Delivery_Availability_Order_By>>;
  where?: InputMaybe<Customer_Delivery_Availability_Bool_Exp>;
};

/** aggregated selection of "working_days" */
export type Working_Days_Aggregate = {
  __typename: 'working_days_aggregate';
  aggregate?: Maybe<Working_Days_Aggregate_Fields>;
  nodes: Array<Working_Days>;
};

/** aggregate fields of "working_days" */
export type Working_Days_Aggregate_Fields = {
  __typename: 'working_days_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Working_Days_Max_Fields>;
  min?: Maybe<Working_Days_Min_Fields>;
};


/** aggregate fields of "working_days" */
export type Working_Days_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Working_Days_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "working_days". All fields are combined with a logical 'AND'. */
export type Working_Days_Bool_Exp = {
  _and?: InputMaybe<Array<Working_Days_Bool_Exp>>;
  _not?: InputMaybe<Working_Days_Bool_Exp>;
  _or?: InputMaybe<Array<Working_Days_Bool_Exp>>;
  contactAvailabilities?: InputMaybe<Contact_Availability_Bool_Exp>;
  contactAvailabilities_aggregate?: InputMaybe<Contact_Availability_Aggregate_Bool_Exp>;
  customerDeliveryAvailabilities?: InputMaybe<Customer_Delivery_Availability_Bool_Exp>;
  customerDeliveryAvailabilities_aggregate?: InputMaybe<Customer_Delivery_Availability_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Working_Days_Enum {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

/** Boolean expression to compare columns of type "working_days_enum". All fields are combined with logical 'AND'. */
export type Working_Days_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Working_Days_Enum>;
  _in?: InputMaybe<Array<Working_Days_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Working_Days_Enum>;
  _nin?: InputMaybe<Array<Working_Days_Enum>>;
};

/** aggregate max on columns */
export type Working_Days_Max_Fields = {
  __typename: 'working_days_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Working_Days_Min_Fields = {
  __typename: 'working_days_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "working_days". */
export type Working_Days_Order_By = {
  contactAvailabilities_aggregate?: InputMaybe<Contact_Availability_Aggregate_Order_By>;
  customerDeliveryAvailabilities_aggregate?: InputMaybe<Customer_Delivery_Availability_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "working_days" */
export enum Working_Days_Select_Column {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "working_days" */
export type Working_Days_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Working_Days_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Working_Days_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections = {
  __typename: 'workstation_hardware_device_connections';
  /** An object relationship */
  Tenant: Tenants;
  connectionType: Hardware_Device_Connection_Types_Enum;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  hardwareDevice: Hardware_Devices;
  hardwareDeviceId: Scalars['bigint'];
  tenant: Scalars['String'];
  /** An object relationship */
  workstation: Workstations;
  workstationId: Scalars['bigint'];
};

/** aggregated selection of "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_Aggregate = {
  __typename: 'workstation_hardware_device_connections_aggregate';
  aggregate?: Maybe<Workstation_Hardware_Device_Connections_Aggregate_Fields>;
  nodes: Array<Workstation_Hardware_Device_Connections>;
};

export type Workstation_Hardware_Device_Connections_Aggregate_Bool_Exp = {
  count?: InputMaybe<Workstation_Hardware_Device_Connections_Aggregate_Bool_Exp_Count>;
};

export type Workstation_Hardware_Device_Connections_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Workstation_Hardware_Device_Connections_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_Aggregate_Fields = {
  __typename: 'workstation_hardware_device_connections_aggregate_fields';
  avg?: Maybe<Workstation_Hardware_Device_Connections_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Workstation_Hardware_Device_Connections_Max_Fields>;
  min?: Maybe<Workstation_Hardware_Device_Connections_Min_Fields>;
  stddev?: Maybe<Workstation_Hardware_Device_Connections_Stddev_Fields>;
  stddev_pop?: Maybe<Workstation_Hardware_Device_Connections_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Workstation_Hardware_Device_Connections_Stddev_Samp_Fields>;
  sum?: Maybe<Workstation_Hardware_Device_Connections_Sum_Fields>;
  var_pop?: Maybe<Workstation_Hardware_Device_Connections_Var_Pop_Fields>;
  var_samp?: Maybe<Workstation_Hardware_Device_Connections_Var_Samp_Fields>;
  variance?: Maybe<Workstation_Hardware_Device_Connections_Variance_Fields>;
};


/** aggregate fields of "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_Aggregate_Order_By = {
  avg?: InputMaybe<Workstation_Hardware_Device_Connections_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Workstation_Hardware_Device_Connections_Max_Order_By>;
  min?: InputMaybe<Workstation_Hardware_Device_Connections_Min_Order_By>;
  stddev?: InputMaybe<Workstation_Hardware_Device_Connections_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Workstation_Hardware_Device_Connections_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Workstation_Hardware_Device_Connections_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Workstation_Hardware_Device_Connections_Sum_Order_By>;
  var_pop?: InputMaybe<Workstation_Hardware_Device_Connections_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Workstation_Hardware_Device_Connections_Var_Samp_Order_By>;
  variance?: InputMaybe<Workstation_Hardware_Device_Connections_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_Arr_Rel_Insert_Input = {
  data: Array<Workstation_Hardware_Device_Connections_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Workstation_Hardware_Device_Connections_On_Conflict>;
};

/** aggregate avg on columns */
export type Workstation_Hardware_Device_Connections_Avg_Fields = {
  __typename: 'workstation_hardware_device_connections_avg_fields';
  hardwareDeviceId?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_Avg_Order_By = {
  hardwareDeviceId?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "workstation_hardware_device_connections". All fields are combined with a logical 'AND'. */
export type Workstation_Hardware_Device_Connections_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Bool_Exp>>;
  _not?: InputMaybe<Workstation_Hardware_Device_Connections_Bool_Exp>;
  _or?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Bool_Exp>>;
  connectionType?: InputMaybe<Hardware_Device_Connection_Types_Enum_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  hardwareDevice?: InputMaybe<Hardware_Devices_Bool_Exp>;
  hardwareDeviceId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  workstation?: InputMaybe<Workstations_Bool_Exp>;
  workstationId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "workstation_hardware_device_connections" */
export enum Workstation_Hardware_Device_Connections_Constraint {
  /** unique or primary key constraint on columns "workstation_id", "connection_type", "hardware_device_id" */
  WorkstationHardwareDeviceConnectionsPkey = 'workstation_hardware_device_connections_pkey'
}

/** input type for incrementing numeric columns in table "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_Inc_Input = {
  hardwareDeviceId?: InputMaybe<Scalars['bigint']>;
  workstationId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_Insert_Input = {
  connectionType?: InputMaybe<Hardware_Device_Connection_Types_Enum>;
  hardwareDevice?: InputMaybe<Hardware_Devices_Obj_Rel_Insert_Input>;
  hardwareDeviceId?: InputMaybe<Scalars['bigint']>;
  workstation?: InputMaybe<Workstations_Obj_Rel_Insert_Input>;
  workstationId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Workstation_Hardware_Device_Connections_Max_Fields = {
  __typename: 'workstation_hardware_device_connections_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  hardwareDeviceId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  workstationId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  hardwareDeviceId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Workstation_Hardware_Device_Connections_Min_Fields = {
  __typename: 'workstation_hardware_device_connections_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  hardwareDeviceId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  workstationId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  hardwareDeviceId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_Mutation_Response = {
  __typename: 'workstation_hardware_device_connections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Workstation_Hardware_Device_Connections>;
};

/** on_conflict condition type for table "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_On_Conflict = {
  constraint: Workstation_Hardware_Device_Connections_Constraint;
  update_columns?: Array<Workstation_Hardware_Device_Connections_Update_Column>;
  where?: InputMaybe<Workstation_Hardware_Device_Connections_Bool_Exp>;
};

/** Ordering options when selecting data from "workstation_hardware_device_connections". */
export type Workstation_Hardware_Device_Connections_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  connectionType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  hardwareDevice?: InputMaybe<Hardware_Devices_Order_By>;
  hardwareDeviceId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  workstation?: InputMaybe<Workstations_Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: workstation_hardware_device_connections */
export type Workstation_Hardware_Device_Connections_Pk_Columns_Input = {
  connectionType: Hardware_Device_Connection_Types_Enum;
  hardwareDeviceId: Scalars['bigint'];
  workstationId: Scalars['bigint'];
};

/** select columns of table "workstation_hardware_device_connections" */
export enum Workstation_Hardware_Device_Connections_Select_Column {
  /** column name */
  ConnectionType = 'connectionType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  HardwareDeviceId = 'hardwareDeviceId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  WorkstationId = 'workstationId'
}

/** input type for updating data in table "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_Set_Input = {
  connectionType?: InputMaybe<Hardware_Device_Connection_Types_Enum>;
  hardwareDeviceId?: InputMaybe<Scalars['bigint']>;
  workstationId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Workstation_Hardware_Device_Connections_Stddev_Fields = {
  __typename: 'workstation_hardware_device_connections_stddev_fields';
  hardwareDeviceId?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_Stddev_Order_By = {
  hardwareDeviceId?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Workstation_Hardware_Device_Connections_Stddev_Pop_Fields = {
  __typename: 'workstation_hardware_device_connections_stddev_pop_fields';
  hardwareDeviceId?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_Stddev_Pop_Order_By = {
  hardwareDeviceId?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Workstation_Hardware_Device_Connections_Stddev_Samp_Fields = {
  __typename: 'workstation_hardware_device_connections_stddev_samp_fields';
  hardwareDeviceId?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_Stddev_Samp_Order_By = {
  hardwareDeviceId?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workstation_Hardware_Device_Connections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workstation_Hardware_Device_Connections_Stream_Cursor_Value_Input = {
  connectionType?: InputMaybe<Hardware_Device_Connection_Types_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  hardwareDeviceId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  workstationId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Workstation_Hardware_Device_Connections_Sum_Fields = {
  __typename: 'workstation_hardware_device_connections_sum_fields';
  hardwareDeviceId?: Maybe<Scalars['bigint']>;
  workstationId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_Sum_Order_By = {
  hardwareDeviceId?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** update columns of table "workstation_hardware_device_connections" */
export enum Workstation_Hardware_Device_Connections_Update_Column {
  /** column name */
  ConnectionType = 'connectionType',
  /** column name */
  HardwareDeviceId = 'hardwareDeviceId',
  /** column name */
  WorkstationId = 'workstationId'
}

export type Workstation_Hardware_Device_Connections_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Workstation_Hardware_Device_Connections_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workstation_Hardware_Device_Connections_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workstation_Hardware_Device_Connections_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Workstation_Hardware_Device_Connections_Var_Pop_Fields = {
  __typename: 'workstation_hardware_device_connections_var_pop_fields';
  hardwareDeviceId?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_Var_Pop_Order_By = {
  hardwareDeviceId?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Workstation_Hardware_Device_Connections_Var_Samp_Fields = {
  __typename: 'workstation_hardware_device_connections_var_samp_fields';
  hardwareDeviceId?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_Var_Samp_Order_By = {
  hardwareDeviceId?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Workstation_Hardware_Device_Connections_Variance_Fields = {
  __typename: 'workstation_hardware_device_connections_variance_fields';
  hardwareDeviceId?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "workstation_hardware_device_connections" */
export type Workstation_Hardware_Device_Connections_Variance_Order_By = {
  hardwareDeviceId?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** columns and relationships of "workstation_picking_categories" */
export type Workstation_Picking_Categories = {
  __typename: 'workstation_picking_categories';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  pickingCategory: Picking_Categories;
  pickingCategoryId: Scalars['bigint'];
  tenant: Scalars['String'];
  /** An object relationship */
  workstation: Workstations;
  workstationId: Scalars['bigint'];
};

/** aggregated selection of "workstation_picking_categories" */
export type Workstation_Picking_Categories_Aggregate = {
  __typename: 'workstation_picking_categories_aggregate';
  aggregate?: Maybe<Workstation_Picking_Categories_Aggregate_Fields>;
  nodes: Array<Workstation_Picking_Categories>;
};

export type Workstation_Picking_Categories_Aggregate_Bool_Exp = {
  count?: InputMaybe<Workstation_Picking_Categories_Aggregate_Bool_Exp_Count>;
};

export type Workstation_Picking_Categories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Workstation_Picking_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Workstation_Picking_Categories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "workstation_picking_categories" */
export type Workstation_Picking_Categories_Aggregate_Fields = {
  __typename: 'workstation_picking_categories_aggregate_fields';
  avg?: Maybe<Workstation_Picking_Categories_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Workstation_Picking_Categories_Max_Fields>;
  min?: Maybe<Workstation_Picking_Categories_Min_Fields>;
  stddev?: Maybe<Workstation_Picking_Categories_Stddev_Fields>;
  stddev_pop?: Maybe<Workstation_Picking_Categories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Workstation_Picking_Categories_Stddev_Samp_Fields>;
  sum?: Maybe<Workstation_Picking_Categories_Sum_Fields>;
  var_pop?: Maybe<Workstation_Picking_Categories_Var_Pop_Fields>;
  var_samp?: Maybe<Workstation_Picking_Categories_Var_Samp_Fields>;
  variance?: Maybe<Workstation_Picking_Categories_Variance_Fields>;
};


/** aggregate fields of "workstation_picking_categories" */
export type Workstation_Picking_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workstation_Picking_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "workstation_picking_categories" */
export type Workstation_Picking_Categories_Aggregate_Order_By = {
  avg?: InputMaybe<Workstation_Picking_Categories_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Workstation_Picking_Categories_Max_Order_By>;
  min?: InputMaybe<Workstation_Picking_Categories_Min_Order_By>;
  stddev?: InputMaybe<Workstation_Picking_Categories_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Workstation_Picking_Categories_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Workstation_Picking_Categories_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Workstation_Picking_Categories_Sum_Order_By>;
  var_pop?: InputMaybe<Workstation_Picking_Categories_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Workstation_Picking_Categories_Var_Samp_Order_By>;
  variance?: InputMaybe<Workstation_Picking_Categories_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "workstation_picking_categories" */
export type Workstation_Picking_Categories_Arr_Rel_Insert_Input = {
  data: Array<Workstation_Picking_Categories_Insert_Input>;
};

/** aggregate avg on columns */
export type Workstation_Picking_Categories_Avg_Fields = {
  __typename: 'workstation_picking_categories_avg_fields';
  pickingCategoryId?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "workstation_picking_categories" */
export type Workstation_Picking_Categories_Avg_Order_By = {
  pickingCategoryId?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "workstation_picking_categories". All fields are combined with a logical 'AND'. */
export type Workstation_Picking_Categories_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Workstation_Picking_Categories_Bool_Exp>>;
  _not?: InputMaybe<Workstation_Picking_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Workstation_Picking_Categories_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  pickingCategory?: InputMaybe<Picking_Categories_Bool_Exp>;
  pickingCategoryId?: InputMaybe<Bigint_Comparison_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  workstation?: InputMaybe<Workstations_Bool_Exp>;
  workstationId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "workstation_picking_categories" */
export type Workstation_Picking_Categories_Inc_Input = {
  pickingCategoryId?: InputMaybe<Scalars['bigint']>;
  workstationId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "workstation_picking_categories" */
export type Workstation_Picking_Categories_Insert_Input = {
  pickingCategory?: InputMaybe<Picking_Categories_Obj_Rel_Insert_Input>;
  pickingCategoryId?: InputMaybe<Scalars['bigint']>;
  workstation?: InputMaybe<Workstations_Obj_Rel_Insert_Input>;
  workstationId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Workstation_Picking_Categories_Max_Fields = {
  __typename: 'workstation_picking_categories_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  pickingCategoryId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  workstationId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "workstation_picking_categories" */
export type Workstation_Picking_Categories_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  pickingCategoryId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Workstation_Picking_Categories_Min_Fields = {
  __typename: 'workstation_picking_categories_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  pickingCategoryId?: Maybe<Scalars['bigint']>;
  tenant?: Maybe<Scalars['String']>;
  workstationId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "workstation_picking_categories" */
export type Workstation_Picking_Categories_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  pickingCategoryId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "workstation_picking_categories" */
export type Workstation_Picking_Categories_Mutation_Response = {
  __typename: 'workstation_picking_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Workstation_Picking_Categories>;
};

/** Ordering options when selecting data from "workstation_picking_categories". */
export type Workstation_Picking_Categories_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  pickingCategory?: InputMaybe<Picking_Categories_Order_By>;
  pickingCategoryId?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  workstation?: InputMaybe<Workstations_Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** select columns of table "workstation_picking_categories" */
export enum Workstation_Picking_Categories_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  PickingCategoryId = 'pickingCategoryId',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  WorkstationId = 'workstationId'
}

/** input type for updating data in table "workstation_picking_categories" */
export type Workstation_Picking_Categories_Set_Input = {
  pickingCategoryId?: InputMaybe<Scalars['bigint']>;
  workstationId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Workstation_Picking_Categories_Stddev_Fields = {
  __typename: 'workstation_picking_categories_stddev_fields';
  pickingCategoryId?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "workstation_picking_categories" */
export type Workstation_Picking_Categories_Stddev_Order_By = {
  pickingCategoryId?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Workstation_Picking_Categories_Stddev_Pop_Fields = {
  __typename: 'workstation_picking_categories_stddev_pop_fields';
  pickingCategoryId?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "workstation_picking_categories" */
export type Workstation_Picking_Categories_Stddev_Pop_Order_By = {
  pickingCategoryId?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Workstation_Picking_Categories_Stddev_Samp_Fields = {
  __typename: 'workstation_picking_categories_stddev_samp_fields';
  pickingCategoryId?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "workstation_picking_categories" */
export type Workstation_Picking_Categories_Stddev_Samp_Order_By = {
  pickingCategoryId?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "workstation_picking_categories" */
export type Workstation_Picking_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workstation_Picking_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workstation_Picking_Categories_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  pickingCategoryId?: InputMaybe<Scalars['bigint']>;
  tenant?: InputMaybe<Scalars['String']>;
  workstationId?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Workstation_Picking_Categories_Sum_Fields = {
  __typename: 'workstation_picking_categories_sum_fields';
  pickingCategoryId?: Maybe<Scalars['bigint']>;
  workstationId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "workstation_picking_categories" */
export type Workstation_Picking_Categories_Sum_Order_By = {
  pickingCategoryId?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

export type Workstation_Picking_Categories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Workstation_Picking_Categories_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workstation_Picking_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workstation_Picking_Categories_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Workstation_Picking_Categories_Var_Pop_Fields = {
  __typename: 'workstation_picking_categories_var_pop_fields';
  pickingCategoryId?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "workstation_picking_categories" */
export type Workstation_Picking_Categories_Var_Pop_Order_By = {
  pickingCategoryId?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Workstation_Picking_Categories_Var_Samp_Fields = {
  __typename: 'workstation_picking_categories_var_samp_fields';
  pickingCategoryId?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "workstation_picking_categories" */
export type Workstation_Picking_Categories_Var_Samp_Order_By = {
  pickingCategoryId?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Workstation_Picking_Categories_Variance_Fields = {
  __typename: 'workstation_picking_categories_variance_fields';
  pickingCategoryId?: Maybe<Scalars['Float']>;
  workstationId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "workstation_picking_categories" */
export type Workstation_Picking_Categories_Variance_Order_By = {
  pickingCategoryId?: InputMaybe<Order_By>;
  workstationId?: InputMaybe<Order_By>;
};

/** columns and relationships of "workstations" */
export type Workstations = {
  __typename: 'workstations';
  /** An object relationship */
  Tenant: Tenants;
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  ip: Scalars['inet'];
  name: Scalars['String'];
  pcName?: Maybe<Scalars['String']>;
  /** An array relationship */
  rollingInventoryActors: Array<Rolling_Inventory_Actors>;
  /** An aggregate relationship */
  rollingInventoryActors_aggregate: Rolling_Inventory_Actors_Aggregate;
  tenant: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  version: Scalars['bigint'];
  /** An array relationship */
  workstationHardwareDeviceConnections: Array<Workstation_Hardware_Device_Connections>;
  /** An aggregate relationship */
  workstationHardwareDeviceConnections_aggregate: Workstation_Hardware_Device_Connections_Aggregate;
  /** An array relationship */
  workstationPickingCategories: Array<Workstation_Picking_Categories>;
  /** An aggregate relationship */
  workstationPickingCategories_aggregate: Workstation_Picking_Categories_Aggregate;
};


/** columns and relationships of "workstations" */
export type WorkstationsRollingInventoryActorsArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Actors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Actors_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
};


/** columns and relationships of "workstations" */
export type WorkstationsRollingInventoryActors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rolling_Inventory_Actors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rolling_Inventory_Actors_Order_By>>;
  where?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
};


/** columns and relationships of "workstations" */
export type WorkstationsWorkstationHardwareDeviceConnectionsArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Order_By>>;
  where?: InputMaybe<Workstation_Hardware_Device_Connections_Bool_Exp>;
};


/** columns and relationships of "workstations" */
export type WorkstationsWorkstationHardwareDeviceConnections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Hardware_Device_Connections_Order_By>>;
  where?: InputMaybe<Workstation_Hardware_Device_Connections_Bool_Exp>;
};


/** columns and relationships of "workstations" */
export type WorkstationsWorkstationPickingCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Picking_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Picking_Categories_Order_By>>;
  where?: InputMaybe<Workstation_Picking_Categories_Bool_Exp>;
};


/** columns and relationships of "workstations" */
export type WorkstationsWorkstationPickingCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workstation_Picking_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workstation_Picking_Categories_Order_By>>;
  where?: InputMaybe<Workstation_Picking_Categories_Bool_Exp>;
};

/** aggregated selection of "workstations" */
export type Workstations_Aggregate = {
  __typename: 'workstations_aggregate';
  aggregate?: Maybe<Workstations_Aggregate_Fields>;
  nodes: Array<Workstations>;
};

export type Workstations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Workstations_Aggregate_Bool_Exp_Count>;
};

export type Workstations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Workstations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Workstations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "workstations" */
export type Workstations_Aggregate_Fields = {
  __typename: 'workstations_aggregate_fields';
  avg?: Maybe<Workstations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Workstations_Max_Fields>;
  min?: Maybe<Workstations_Min_Fields>;
  stddev?: Maybe<Workstations_Stddev_Fields>;
  stddev_pop?: Maybe<Workstations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Workstations_Stddev_Samp_Fields>;
  sum?: Maybe<Workstations_Sum_Fields>;
  var_pop?: Maybe<Workstations_Var_Pop_Fields>;
  var_samp?: Maybe<Workstations_Var_Samp_Fields>;
  variance?: Maybe<Workstations_Variance_Fields>;
};


/** aggregate fields of "workstations" */
export type Workstations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workstations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "workstations" */
export type Workstations_Aggregate_Order_By = {
  avg?: InputMaybe<Workstations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Workstations_Max_Order_By>;
  min?: InputMaybe<Workstations_Min_Order_By>;
  stddev?: InputMaybe<Workstations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Workstations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Workstations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Workstations_Sum_Order_By>;
  var_pop?: InputMaybe<Workstations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Workstations_Var_Samp_Order_By>;
  variance?: InputMaybe<Workstations_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Workstations_Avg_Fields = {
  __typename: 'workstations_avg_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "workstations" */
export type Workstations_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "workstations". All fields are combined with a logical 'AND'. */
export type Workstations_Bool_Exp = {
  Tenant?: InputMaybe<Tenants_Bool_Exp>;
  _and?: InputMaybe<Array<Workstations_Bool_Exp>>;
  _not?: InputMaybe<Workstations_Bool_Exp>;
  _or?: InputMaybe<Array<Workstations_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  ip?: InputMaybe<Inet_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pcName?: InputMaybe<String_Comparison_Exp>;
  rollingInventoryActors?: InputMaybe<Rolling_Inventory_Actors_Bool_Exp>;
  rollingInventoryActors_aggregate?: InputMaybe<Rolling_Inventory_Actors_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Bigint_Comparison_Exp>;
  workstationHardwareDeviceConnections?: InputMaybe<Workstation_Hardware_Device_Connections_Bool_Exp>;
  workstationHardwareDeviceConnections_aggregate?: InputMaybe<Workstation_Hardware_Device_Connections_Aggregate_Bool_Exp>;
  workstationPickingCategories?: InputMaybe<Workstation_Picking_Categories_Bool_Exp>;
  workstationPickingCategories_aggregate?: InputMaybe<Workstation_Picking_Categories_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "workstations" */
export enum Workstations_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkstationsPkey = 'workstations_pkey',
  /** unique or primary key constraint on columns "ip", "tenant" */
  WorkstationsTenantIpKey = 'workstations_tenant_ip_key',
  /** unique or primary key constraint on columns "name", "tenant" */
  WorkstationsTenantNameKey = 'workstations_tenant_name_key'
}

/** input type for incrementing numeric columns in table "workstations" */
export type Workstations_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "workstations" */
export type Workstations_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  ip?: InputMaybe<Scalars['inet']>;
  name?: InputMaybe<Scalars['String']>;
  pcName?: InputMaybe<Scalars['String']>;
  rollingInventoryActors?: InputMaybe<Rolling_Inventory_Actors_Arr_Rel_Insert_Input>;
  version?: InputMaybe<Scalars['bigint']>;
  workstationHardwareDeviceConnections?: InputMaybe<Workstation_Hardware_Device_Connections_Arr_Rel_Insert_Input>;
  workstationPickingCategories?: InputMaybe<Workstation_Picking_Categories_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Workstations_Max_Fields = {
  __typename: 'workstations_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  pcName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "workstations" */
export type Workstations_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pcName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Workstations_Min_Fields = {
  __typename: 'workstations_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  pcName?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "workstations" */
export type Workstations_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pcName?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "workstations" */
export type Workstations_Mutation_Response = {
  __typename: 'workstations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Workstations>;
};

/** input type for inserting object relation for remote table "workstations" */
export type Workstations_Obj_Rel_Insert_Input = {
  data: Workstations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Workstations_On_Conflict>;
};

/** on_conflict condition type for table "workstations" */
export type Workstations_On_Conflict = {
  constraint: Workstations_Constraint;
  update_columns?: Array<Workstations_Update_Column>;
  where?: InputMaybe<Workstations_Bool_Exp>;
};

/** Ordering options when selecting data from "workstations". */
export type Workstations_Order_By = {
  Tenant?: InputMaybe<Tenants_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ip?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pcName?: InputMaybe<Order_By>;
  rollingInventoryActors_aggregate?: InputMaybe<Rolling_Inventory_Actors_Aggregate_Order_By>;
  tenant?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  workstationHardwareDeviceConnections_aggregate?: InputMaybe<Workstation_Hardware_Device_Connections_Aggregate_Order_By>;
  workstationPickingCategories_aggregate?: InputMaybe<Workstation_Picking_Categories_Aggregate_Order_By>;
};

/** primary key columns input for table: workstations */
export type Workstations_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "workstations" */
export enum Workstations_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Name = 'name',
  /** column name */
  PcName = 'pcName',
  /** column name */
  Tenant = 'tenant',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "workstations" */
export type Workstations_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  ip?: InputMaybe<Scalars['inet']>;
  name?: InputMaybe<Scalars['String']>;
  pcName?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Workstations_Stddev_Fields = {
  __typename: 'workstations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "workstations" */
export type Workstations_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Workstations_Stddev_Pop_Fields = {
  __typename: 'workstations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "workstations" */
export type Workstations_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Workstations_Stddev_Samp_Fields = {
  __typename: 'workstations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "workstations" */
export type Workstations_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "workstations" */
export type Workstations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workstations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workstations_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  ip?: InputMaybe<Scalars['inet']>;
  name?: InputMaybe<Scalars['String']>;
  pcName?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Workstations_Sum_Fields = {
  __typename: 'workstations_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "workstations" */
export type Workstations_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "workstations" */
export enum Workstations_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Name = 'name',
  /** column name */
  PcName = 'pcName',
  /** column name */
  Version = 'version'
}

export type Workstations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Workstations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workstations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workstations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Workstations_Var_Pop_Fields = {
  __typename: 'workstations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "workstations" */
export type Workstations_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Workstations_Var_Samp_Fields = {
  __typename: 'workstations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "workstations" */
export type Workstations_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Workstations_Variance_Fields = {
  __typename: 'workstations_variance_fields';
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "workstations" */
export type Workstations_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "xid". All fields are combined with logical 'AND'. */
export type Xid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['xid']>;
  _gt?: InputMaybe<Scalars['xid']>;
  _gte?: InputMaybe<Scalars['xid']>;
  _in?: InputMaybe<Array<Scalars['xid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['xid']>;
  _lte?: InputMaybe<Scalars['xid']>;
  _neq?: InputMaybe<Scalars['xid']>;
  _nin?: InputMaybe<Array<Scalars['xid']>>;
};

/** Boolean expression to compare columns of type "xml". All fields are combined with logical 'AND'. */
export type Xml_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['xml']>;
  _gt?: InputMaybe<Scalars['xml']>;
  _gte?: InputMaybe<Scalars['xml']>;
  _in?: InputMaybe<Array<Scalars['xml']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['xml']>;
  _lte?: InputMaybe<Scalars['xml']>;
  _neq?: InputMaybe<Scalars['xml']>;
  _nin?: InputMaybe<Array<Scalars['xml']>>;
};
