import { useAppState } from "@app/modules/app-state/context";

import { usePauseOnVisibilityChangeEffect } from "@app/modules/graphql/pause-behavior";
import { devtoolsExchange } from "@urql/devtools";
import { cacheExchange } from "@urql/exchange-graphcache";
import { createClient as createWSClient } from "graphql-ws";
import type { ReactNode } from "react";
import { useMemo } from "react";
import {
	createClient,
	fetchExchange,
	Provider,
	subscriptionExchange,
} from "urql";
import { optimistic } from "./optimistic";
import { updates } from "./updates";

const API_PATH = "/v1/graphql";

export const keysWithoutId = [
	"public_tenant_info_result",
	"products_aggregate",
	"products_aggregate_fields",
	"suppliers_aggregate",
	"suppliers_aggregate_fields",
	"customers_aggregate",
	"customers_aggregate_fields",
	"customer_orders_aggregate",
	"customer_orders_aggregate_fields",
	"search_products_for_customer_results",
	"search_products_for_customer_results_aggregate",
	"search_products_for_customer_results_aggregate_fields",
	"global_search_result",
	"product_group_members",
	"inventory_items",
	"customer_order_state_transitions",
	"customer_order_item_state_transitions",
	"product_unit_conversion_result",
	"customer_pricing_config_aggregate",
	"customer_pricing_config_aggregate_fields",
	"tours_aggregate",
	"tours_aggregate_fields",
	"tour_routes_aggregate_fields",
	"tour_routes_aggregate",
	"product_sales_channel_members",
	"topologically_sorted_cutting_patterns_results",
	"dismantling_plan_state_transitions",
	"dismantleable_products_results",
	"inventory_items_aggregate",
	"inventory_items_aggregate_fields",
	"aggregated_inventory_items_by_location",
	"aggregated_inventory_items_by_location_aggregate",
	"aggregated_inventory_items_by_location_aggregate_fields",
	"aggregated_demand_plan_results",
	"price_lists_aggregate",
	"price_lists_aggregate_fields",
	"inventory_items_by_location",
	"credit_notes_aggregate",
	"credit_notes_aggregate_fields",
	"customer_invoices_aggregate",
	"customer_invoices_aggregate_fields",
	"customer_invoice_state_transitions",
	"customer_invoice_state_transitions_fields",
	"cutting_pattern_outputs_aggregate",
	"cutting_pattern_outputs_aggregate_fields",
	"cutting_pattern_outputs_draft_aggregate",
	"cutting_pattern_outputs_draft_aggregate_fields",
	"inventory_items_by_location_aggregate",
	"inventory_items_by_location_aggregate_fields",
	"supplier_orders_aggregate",
	"supplier_orders_aggregate_fields",
	"supplier_order_items_aggregate",
	"supplier_order_items_aggregate_fields",
	"supplier_order_state_transitions",
	"supplier_order_state_transitions_fields",
	"picked_items_aggregate",
	"picked_items_aggregate_fields",
	"picked_items_sum_fields",
	"customer_addresses_aggregate",
	"customer_addresses_aggregate_fields",
	"supplier_addresses_aggregate",
	"supplier_addresses_aggregate_fields",
	"list_purchased_products_result_aggregate",
	"list_purchased_products_result_aggregate_fields",
	"picked_item_containers",
	"list_purchased_products_result",
	"stock_transactions_aggregate",
	"stock_transactions_aggregate_fields",
	"supplier_delivery_request_items_aggregate",
	"supplier_delivery_request_items_aggregate_fields",
	"supplier_delivery_request_items_sum_fields",
	"product_s3_objects",
	"product_s3_objects_aggregate",
	"product_s3_objects_aggregate_fields",
	"product_allowed_containers",
	"product_allowed_containers_fields",
	"customer_order_return_items_aggregate",
	"customer_order_return_items_aggregate_fields",
	"customer_order_returns_aggregate",
	"customer_order_returns_aggregate_fields",
	"customer_order_items_aggregate",
	"customer_order_items_aggregate_fields",
	"customer_order_item_adjustments_aggregate",
	"customer_order_item_adjustments_aggregate_fields",
	"customer_order_container_returns_aggregate",
	"customer_order_container_returns_aggregate_fields",
	"customer_order_container_return_items_aggregate",
	"customer_order_container_return_items_aggregate_fields",
	"customer_order_picked_container_aggregate",
	"customer_order_picked_container_aggregate_fields",
	"customer_order_picked_container_sum_fields",
	"emails_aggregate",
	"emails_aggregate_fields",
	"demands_for_coi_results",
	"credit_note_state_transitions",
	"dismantling_plan_demands_aggregate",
	"dismantling_plan_demands_aggregate_fields",
	"dismantling_plan_demands_sum_fields",
	"temporal_products_aggregate",
	"temporal_products_aggregate_fields",
	"rolling_inventory_items_aggregate",
	"rolling_inventory_items_aggregate_fields",
	"rolling_inventory_items_sum_fields",
	"rolling_inventory_lot_summary_items",
	"rolling_inventory_lot_summary_items",
	"rolling_inventories_aggregate",
	"rolling_inventories_aggregate_fields",
	"temporal_sales_teams_customers_aggregate",
	"temporal_sales_teams_customers_aggregate_fields",
	"role_permissions",
	"sales_teams_aggregate",
	"sales_teams_aggregate_fields",
	"postings_aggregate_fields",
	"postings_min_fields",
	"postings_aggregate",
	"supplier_order_goods_income_items_aggregate",
	"supplier_order_goods_income_items_aggregate_fields",
	"customer_invoice_versions_aggregate",
	"customer_invoice_versions_aggregate_fields",
	"work_calendar_aggregate",
	"work_calendar_aggregate_fields",
	"jscript_templates_aggregate",
	"jscript_templates_aggregate_fields",
	"supplier_order_goods_income_items_sum_fields",
	"supplier_order_return_items_aggregate",
	"supplier_order_return_items_aggregate_fields",
	"supplier_order_return_items_sum_fields",
	"supplier_order_returned_containers_aggregate",
	"supplier_order_returned_containers_aggregate_fields",
	"supplier_order_returned_containers_sum_fields",
	"supplier_order_returns_aggregate",
	"supplier_order_returns_aggregate_fields",
	"product_suppliers_aggregate",
	"product_suppliers_aggregate_fields",
	"aggregated_demand_plan_incoming_goods_results",
	"aggregated_demand_plan_outgoing_goods_results",
	"product_groups_aggregate",
	"product_groups_aggregate_fields",
	"dismantling_plan_priorities",
	"notifications_aggregate",
	"notifications_aggregate_fields",
	"daily_price_lists",
	"calculate_cost_price_for_product_results",
	"temporal_product_comparison_result",
	"cutting_patterns_aggregate",
	"cutting_patterns_aggregate_fields",
	"cutting_patterns_max_fields",
	"cutting_patterns_draft_aggregate",
	"cutting_patterns_draft_aggregate_fields",
	"cutting_patterns_draft_max_fields",
	"customer_group_pricing_aggregate",
	"customer_group_pricing_aggregate_fields",
	"stock_locations_aggregate",
	"stock_locations_aggregate_fields",
	"production_site_output_stock_locations_aggregate",
	"production_site_output_stock_locations_aggregate_fields",
	"workstation_picking_categories",
	"workstation_hardware_device_connections",
	"journal_entries_aggregate",
	"journal_entries_aggregate_fields",
	"customer_order_item_extras",
	"sales_team_users",
	"production_site_product_group_calendar_aggregate",
	"production_site_product_group_calendar_aggregate_fields",
	"full_work_calendar",
	"full_work_calendar_aggregate",
	"full_work_calendar_aggregate_fields",
	"exchange_rates_aggregate",
	"exchange_rates_aggregate_fields",
	"customer_groups_aggregate",
	"customer_groups_aggregate_fields",
	"users_aggregate",
	"users_aggregate_fields",
	"hardware_devices_aggregate",
	"hardware_devices_aggregate_fields",
].reduce((acc, selection) => {
	acc[selection] = () => null;
	return acc;
}, {} as Record<string, () => null>);

export interface UrqlClientProviderProps {
	children?: ReactNode;
	/** Changing the `refreshKey` will invalidate the complete cache and refetch everything.  */
	refreshKey?: number;
}

function UrqlClientProvider({ children, refreshKey }: UrqlClientProviderProps) {
	const { accessToken, activeRole = "" } = useAppState();

	// INFO:
	// Creating a new client completely resets the cache.
	// As a result every query will automatically refetch.
	const client = useMemo(() => {
		if (!accessToken) {
			return createClient({
				url: API_PATH,
				exchanges: [devtoolsExchange, fetchExchange],
			});
		}

		const headers = {
			Authorization: `Bearer ${accessToken}`,
			"x-hasura-role": activeRole,
		};
		const { location } = window;
		const wsProtocol = location.protocol === "https:" ? "wss:" : "ws:";
		const wsUrl = `${wsProtocol}//${location.host}${API_PATH}`;

		const wsClient = createWSClient({
			url: wsUrl,
			connectionParams: { headers },
		});

		return createClient({
			url: API_PATH,
			requestPolicy: "cache-and-network",
			exchanges: [
				devtoolsExchange,
				cacheExchange({
					updates,
					optimistic,
					keys: {
						...keysWithoutId,
						tenants: (result) => result?.tenant?.toString() ?? null,
						tenant_configurations: (result) =>
							result?.tenant?.toString() ?? null,
					},
				}),
				fetchExchange,
				subscriptionExchange({
					forwardSubscription: (request) => {
						const input = { ...request, query: request.query || "" };
						return {
							subscribe: (sink) => {
								const dispose = wsClient.subscribe(input, sink);
								return {
									unsubscribe: dispose,
								};
							},
						};
					},
				}),
			],
			fetchOptions: {
				headers,
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accessToken, activeRole, refreshKey]);

	usePauseOnVisibilityChangeEffect();

	return <Provider value={client}>{children}</Provider>;
}

export { UrqlClientProvider };
